import React from "react";
import { Box, Card, Grid, Heading, Stack, Text } from "grommet";
import { DocumentDownload } from "grommet-icons";
import {StatusDateAppelV2} from "../../../elements/status_date_appel";
import ApporteurGestionnaire from "./apporteur_gestionaire";
import TrustiBot from "../../../trustibot";
import ApporteurModification from "./apporteur_gestionaire/modification";
import Simulation from "./simulation";
import InfoVente from "./info_vente";
import Accountability from "./accountability";
import { ClientCard, DashboardRecapTitle } from "../../../elements/new_design_elements";
import { PanelAppelV2 } from "../../../elements/panel_appel";
import { EmailEventTrackerV2 } from "../../../elements/email_tracker";
import { ApporteurGestionnaireV2 } from "../../../elements/apporteur_gestionaire";
import { ApporteurModificationV2 } from "../../../elements/apporteur_gestionaire/modification";
import DateRecap from "../../../elements/date_recap";

const Recap = ({ devis, children, submit, sendDevis, toEditMode, close, updateDevis, onSave, showMessage, reload }) => {
  console.log(devis);
  return (
    <Box
      direction="row"
      gap='small'
      style={{ width: "100vw" }}
    >
      <TrustiBot></TrustiBot>
      <Box
        gap="small"
        align="center"
        alignSelf="center"
        pad="0 20px"
        width="xlarge"
      >
        <DashboardRecapTitle title="Suivi Prospection" />
        <DateRecap deal={devis}/>
        <PanelAppelV2 devis={devis} updateDevis={updateDevis} onSave={onSave} showMessage={showMessage}/>
        <StatusDateAppelV2 devis={devis} />
        <DashboardRecapTitle title="Simulation" />
        <Simulation devis={devis} />
        <DashboardRecapTitle title="Information client" />
        <ClientCard assure={devis.client || {}} />
        <DashboardRecapTitle title="Information vente" />
        <InfoVente devis={devis} />
        {/* <Assurance devis={devis} /> */}
        <DashboardRecapTitle title="GED" />
        <Grid columns={["1/2", "1/2"]} width="100%">
          {devis.file_identite && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_identite}`}
            >
              <DocumentDownload />
              Carte d'Identité
            </a>
          )}
          {devis.file_iban && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_iban}`}
            >
              <DocumentDownload />
              RIB
            </a>
          )}
          {devis.client_file_others ? (
            devis.client_file_others.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                  <DocumentDownload />
                  {e && e.split("/").pop()}
                </a>
              );
            })
          ) : (
            <></>
          )}
          {devis.file_tableau_amortissement && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_tableau_amortissement}`}
            >
              <DocumentDownload />
              Tableau d’amortissement
            </a>
          )}
          {devis.file_attestation_assurance && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_attestation_assurance}`}
            >
              <DocumentDownload />
              Attestation assurance
            </a>
          )}
          {devis.file_offre_de_pret && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_offre_de_pret}`}
            >
              <DocumentDownload />
              Offre de prêt
            </a>
          )}
          {devis.file_devis && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_devis}`}
            >
              <DocumentDownload />
              Devis
            </a>
          )}
          {devis.file_devis_signed && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_devis_signed}`}
            >
              <DocumentDownload />
              Devis (signé)
            </a>
          )}
          {devis.file_dda && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_dda}`}
            >
              <DocumentDownload />
              DDA
            </a>
          )}
          {devis.file_devis_ipid && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_devis_ipid}`}
            >
              <DocumentDownload />
              Devis IPID
            </a>
          )}
          {devis.file_devis_condition && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_devis_condition}`}
            >
              <DocumentDownload />
              Questionnaire devis
            </a>
          )}
          {devis.file_devis_condition_signed && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_devis_condition_signed}`}
            >
              <DocumentDownload />
              Questionnaire devis (signé)
            </a>
          )}
          {devis.file_rib && (
            <a target={"_blank"} rel="noreferrer" href={`${devis.file_rib}`}>
              <DocumentDownload />
              RIB
            </a>
          )}
          {devis.file_contrat && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_contrat}`}
            >
              <DocumentDownload />
              Contrat
            </a>
          )}
          {devis.file_contrat_ipid && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_contrat_ipid}`}
            >
              <DocumentDownload />
              Contrat IPID
            </a>
          )}
          {devis.file_contrat_condition && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_contrat_condition}`}
            >
              <DocumentDownload />
              Contrat conditions
            </a>
          )}
          {devis.file_contrat_signed && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_contrat_signed}`}
            >
              <DocumentDownload />
              Contrat signé
            </a>
          )}
          {devis.file_others ? (
            devis.file_others.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                  <DocumentDownload />
                  {e && e.split("/").pop()}
                </a>
              );
            })
          ) : (
            <></>
          )}
        </Grid>
        <DashboardRecapTitle title="Suivi de mail" />
        <Box width="100%">
          <EmailEventTrackerV2 messageIds={devis.emailEventIds} />
        </Box>
        <DashboardRecapTitle title="Comptabilité" />
        <Accountability devis={devis} />
        <DashboardRecapTitle title="Apporteur" />
        <Grid columns={["1/2", "1/2"]} width="100%">
          {(devis.apporteur || devis.affiliation) && <Box width={'xlarge'} >
            <ApporteurGestionnaireV2 apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
          </Box>}
          <ApporteurModificationV2
							devis={devis}
							apporteur={devis.apporteur}
							showMessage={showMessage}
							onReload={reload}
						/>
        </Grid>
        <DashboardRecapTitle title="Actions" />
        {children}
      </Box>
    </Box>
  );
};

export default Recap;

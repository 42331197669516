
import { Box, Button, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import React from 'react';
import { getSimpleDateFR } from '../../../services/utils';
import AddDevis from '../../elements/add_devis';
import Refresh from '../../elements/refresh';
import SearchComponent from '../../elements/search';
import Entreprise from './entreprise';
import service from './service';

class Entreprises extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            entreprises: [],
            filters: {
                start: 0,
                limit: -1,
                _query: undefined,
            }
        }
    }

    componentDidMount = () => {
        this.loadEntreprises();
    };

    loadEntreprises = () => {
        this.setState({ entreprises: [] }, () => {
            service.getEntreprises(this.state.filters)
                .then(res => {
                    this.setState({ entreprises: res.data })
                })
                .catch(err => {
                    console.log('err', err);
                    this.props.showMessage('Erreur inattendu');
                })
        })
    }

    onClose = () => { this.setState({ popupOpen: false }) }

    openPopup = (entreprise) => {
        this.setState({ selectedEntreprise: entreprise, popupOpen: true });
    }

    addEntreprise = () => this.openPopup({ assure: {}, location: {} })

    search = (query) => {
        service.getEntreprises({query})
            .then(res => {
                if (res.data?.results) {
                    this.setState({ entreprises: res.data?.results })
                } else if (res.data) {
                    this.setState({ entreprises: res.data })
                }
            })
            .catch(err => {
                console.log('err', err);
                this.props.showMessage('Erreur inattendu');
            })
    }

    updateFilter = (field, value) => {
        let filters = this.state.filters;
        filters[field] = value;
        this.setState({ filters: Object.assign({}, filters) });
    }

    render() {
        return (
            this.state.popupOpen ?
                <Entreprise
                    showMessage={this.props.showMessage}
                    entreprise={this.state.selectedEntreprise}
                    onClose={this.onClose}
                    reload={this.loadEntreprises}
                    showDeal={true}
                />
                :
                <Box style={{ width: "100%" }}
                    align="center" justify="center"
                    alignSelf="center"
                    alignContent="center" gap="medium">
                    <Box width="xlarge" gap="medium">

                        <Box justify="center" direction='row' alignSelf="center" align="center" gap="medium">
                            <Refresh refresh={this.loadEntreprises} />
                            <SearchComponent
                                search={value => this.search(value)}
                                query={this.state.filters._query}
                                updateQuery={value => this.updateFilter('_query', value)}
                            />
                            <AddDevis onAdd={this.addEntreprise} />
                        </Box>
                        {<Box style={{ height: 30 }} />}
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableCell><Text><strong>Création</strong>/<br />Modification</Text></TableCell>
                                    <TableCell><Text>Raison social</Text></TableCell>
                                    <TableCell><Text>Type société</Text></TableCell>
                                    <TableCell><Text>Adresse</Text></TableCell>
                                    <TableCell><Text>Email</Text></TableCell>
                                    <TableCell><Text>Statut du lead</Text></TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {this.state.entreprises.map((entreprise, index) => (
                                    <TableRow key={index + 1}>
                                        <TableCell>
                                            <Text size="small"><strong>{getSimpleDateFR(entreprise.createdAt)}</strong><br />{getSimpleDateFR(entreprise.updatedAt)}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{entreprise.raison_social}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{entreprise.type_societe}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{`${entreprise.address}, ${entreprise.zip}, ${entreprise.city}`}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{entreprise.email}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{entreprise.statut_du_lead}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Button label="voir" primary onClick={() => this.openPopup(entreprise)} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
        )
    }
}


export default Entreprises;
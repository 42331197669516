import React from "react";
import { Accordion, AccordionPanel, Box, Button, Card, Grid, Text, CheckBox } from "grommet";
import LineModification from "../../elements/line_container/line_modification";
import ToggleButton, { MultipleLineCheckBoxes } from "../../elements/toggle_button/toggle_button";
import PrimaryButton from "../../elements/primary_button";
import { AddCircle, Checkmark, Close, Edit, Trash } from "grommet-icons";
import { getAppConfig, getDashboardStats, saveAppConfig } from "../service";
import { SectionHeader, renderPanelHeader } from "../../elements/ux_template/layout";
import CustomInput from "../../elements/custom_input";
import { PRODUCTS } from "../../apporteur_pro/service";
class DashboardMyTrustiway extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            partenaires: 0,
            utilisateurs: 0,
            ca: 0,
            config: null,
            viewOnly: true,
            activeIndex: [0, 1]
        }
    }

    componentDidMount = () => {
        getAppConfig().then(res => {
            console.log(res.data);
            if (res.data.length > 0) {
                this.setState({ config: res.data[0], partenaires: res.data[0].partenaires.length });
            }
        });

        getDashboardStats().then(res => {
            console.log(res.data);
            this.setState({ utilisateurs: res.data.users, ca: res.data.ca });
        });
    }

    onConfigChange = (field, value, needUpdateChange) => {
        let config = this.state.config;
        config[field] = value;
        if (needUpdateChange) {
            this.setState({ dataChanged: true, config: Object.assign({}, config) });
        } else {
            this.setState({ config: Object.assign({}, config) });
        }
    }

    onSubmit = () => {
        console.log('submit');
        saveAppConfig(this.state.config).then(res => {
            this.props.showMessage('Configuration sauvegardée', 'success');
            this.setState({ viewOnly: true, dataChanged: false });
        }
        ).catch(err => {
            console.log(err);
            this.props.showMessage('Erreur lors de la sauvegarde', 'error');
        }
        );
    }

    render() {
        return (
            <Box fill justify="center" align="center" style={{ paddingTop: 10, background: '#EBEFF4' }}>
                <Text size="xxlarge" style={{ padding: 10 }}><b>Dashboard My Trustiway</b></Text>
                <Box width={'full'} justify="center" align="center" gap="medium" direction="row" style={{ paddingTop: 10 }}>
                    <Card style={{ borderRadius: 20, width: 300, background: 'white' }} align="center" >
                        <Text size="medium" style={{ padding: 30 }}><b>Partenaires</b></Text>
                        <Text size="large" style={{ paddingBottom: 20 }}><b>{this.state.partenaires}</b></Text>
                        <Button onClick={() => {
                            if (this.props.goto)
                                this.props.goto(2);
                        }} size="small" style={{ backgroundColor: "#E82C70", width: "100%", color: 'white', textAlign: 'center' }}>Voir plus</Button>
                    </Card>
                    <Card style={{ borderRadius: 20, width: 300, background: 'white' }} align="center" >
                        <Text size="medium" style={{ padding: 30 }}><b>Utilisateurs</b></Text>
                        <Text size="large" style={{ paddingBottom: 20 }}><b>{this.state.utilisateurs}</b></Text>
                        <Button onClick={() => {
                            if (this.props.goto)
                                this.props.goto(1);
                        }} size="small" style={{ backgroundColor: "#E82C70", width: "100%", color: 'white', textAlign: 'center' }}>Voir plus</Button>
                    </Card>
                    <Card style={{ borderRadius: 20, width: 300, background: 'white' }} align="center" >
                        <Text size="medium" style={{ padding: 30 }}><b>CA Trustiway mois en cours</b></Text>
                        <Text size="large" style={{ paddingBottom: 20 }}><b>{`${this.state.ca}`} €</b></Text>
                        <Button onClick={() => {
                            if (this.props.goto)
                                this.props.goto(3);
                        }} size="small" style={{ backgroundColor: "#E82C70", width: "100%", color: 'white', textAlign: 'center' }}>Voir plus</Button>
                    </Card>
                </Box>
                {this.state.config != null && <Box width={'xlarge'} margin={'medium'}>

                    <Accordion multiple fill activeIndex={this.state.activeIndex} gap="small" onActive={(newActiveIndex) => this.setState({ activeIndex: newActiveIndex })}>

                        <AccordionPanel header={renderPanelHeader('Paramètres de l’application', this.state.activeIndex.includes(0))} >
                            <Box background={'white'} >
                                <SectionHeader title={"Version de l’application"} round={false} color={'#E92C70'} />
                                <Box pad={'small'} >
                                    <LineModification text="Version app" field='version'
                                        disabled={this.state.viewOnly}
                                        value={this.state.config.version}
                                        direction='column'
                                        onChange={(field, value) => this.state.viewOnly ? null : this.onConfigChange(field, value)}
                                    />
                                    <Box width={'large'}>
                                        <Text size="xsmall"
                                            style={{ color: '#707070' }}
                                        >
                                            Forcer mise à jour de l'application
                                        </Text>
                                        <ToggleButton
                                            disabled={this.state.viewOnly}
                                            groups={[
                                                {
                                                    label: "OFF",
                                                    selected:
                                                        !this.state.config.force_update,
                                                    onClick: () => {
                                                        this.onConfigChange(
                                                            "force_update",
                                                            false
                                                        );
                                                    },
                                                },
                                                {
                                                    label: "ON",
                                                    selected:
                                                        this.state.config.force_update,
                                                    onClick: () => {
                                                        this.onConfigChange(
                                                            "force_update",
                                                            true
                                                        );
                                                    },
                                                },

                                            ]}
                                        />
                                    </Box>


                                    {/* <LineModification text="Tarif abonnement" field='default_tarif'
                            disabled={this.state.viewOnly}
                            value={this.state.config.default_tarif}
                            direction='column'
                            onChange={(field, value) => this.state.viewOnly ? null : this.onConfigChange(field, value)}
                        />

                        <LineModification text="Tarif abonnement parrain" field='default_tarif_parrain'
                            disabled={this.state.viewOnly}
                            value={this.state.config.default_tarif_parrain}
                            direction='column'
                            onChange={(field, value) => this.state.viewOnly ? null : this.onConfigChange(field, value)}
                        /> */}


                                </Box>
                                <SectionHeader title={"Notification"} round={false} color={'#E92C70'} />
                                <Box pad={'small'} >
                                    <Box width={'large'}>
                                        <Text size="small">Data tags: {"{{ client.prenom }} {{ client.nom }} {{ commission }}"}</Text>
                                    </Box>
                                    <LineModification text='Push notification - Encours' field='push_content_on_progress'
                                        value={this.state.config.push_content_on_progress}
                                        direction='column'
                                        disabled={this.state.viewOnly}
                                        onChange={(field, value) => this.state.viewOnly ? null : this.onConfigChange(field, value)}
                                    />
                                    <LineModification text='Push notification - Clôturé' field='push_content_contract'
                                        value={this.state.config.push_content_contract}
                                        direction='column'
                                        disabled={this.state.viewOnly}
                                        onChange={(field, value) => this.state.viewOnly ? null : this.onConfigChange(field, value)}
                                    />
                                    <LineModification text='Push notification - Clôturé - Full Réseaux' field='push_content_contract_full_reseaux'
                                        value={this.state.config.push_content_contract_full_reseaux}
                                        direction='column'
                                        disabled={this.state.viewOnly}
                                        onChange={(field, value) => this.state.viewOnly ? null : this.onConfigChange(field, value)}
                                    />
                                </Box>

                                <SectionHeader title={"Produits disponible sur My Trustiway APP (hors réseau)"} round={false} color={'#E92C70'} />

                                <Grid columns={["25%", "50%", "25%"]} fill round={{ size: 'small' }} gap='small' pad={'small'} align='start' justify="between" border={{ color: 'brand' }} direction="row" >

                                    <Box >
                                        <Box>
                                            <Text textAlign="start" size="large" margin={'small'}><b>Immobilier</b> </Text>
                                            <CheckBox
                                                disabled={this.state.viewOnly}
                                                label={<Text size='small'>{"Recommandation Immo"}</Text>}
                                                checked={this.state.config.product_immo_visible}
                                                onChange={(event) => this.onConfigChange('product_immo_visible', event.target.checked)} >
                                            </CheckBox>
                                        </Box>
                                        <ProductsCheckBoxes settings={this.state.config} onProductChange={this.onConfigChange} group={'Crédit'} isView={this.state.viewOnly} />
                                    </Box>
                                    <ProductsCheckBoxes size={2} settings={this.state.config} onProductChange={this.onConfigChange} group={'Assurance'} isView={this.state.viewOnly} />

                                    <Box >
                                        <ProductsCheckBoxes settings={this.state.config} onProductChange={this.onConfigChange} group={'Retraite'} isView={this.state.viewOnly} />
                                        <ProductsCheckBoxes settings={this.state.config} onProductChange={this.onConfigChange} group={'Épargne'} isView={this.state.viewOnly} />
                                        <ProductsCheckBoxes settings={this.state.config} onProductChange={this.onConfigChange} group={'Prévoyance'} isView={this.state.viewOnly} />
                                    </Box>
                                </Grid>

                                {!this.state.viewOnly &&
                                    <Box direction="row" align="center" justify="center" gap="medium" pad={'medium'}>
                                        <PrimaryButton
                                            label={`Valider`}
                                            icon={<Checkmark />}
                                            background="#59A4F4"
                                            onClick={this.onSubmit}
                                        />
                                        <PrimaryButton
                                            label={`Annuler`}
                                            icon={<Close />}
                                            background="#6c757d"
                                            onClick={() => this.setState({ viewOnly: true })}
                                        />
                                    </Box>
                                }

                                {this.state.viewOnly &&
                                    <Box direction="row" align="center" justify="center" gap="medium" pad={'medium'}>
                                        <PrimaryButton
                                            label={`Modifier`}
                                            icon={<Edit />}
                                            background='#E82C70'
                                            onClick={() => this.setState({ viewOnly: false })}
                                        />
                                    </Box>
                                }

                            </Box>
                        </AccordionPanel>

                        <AccordionPanel header={renderPanelHeader('Paramètres des commissions de l’application', this.state.activeIndex.includes(1))} >
                            <Box background={'white'} >
                                <SectionHeader title={"Commission de l’assurance"} round={false} color={'#E92C70'} />

                                <Box pad={'small'} >
                                    <Text>Tableau des rémunérations des apporteurs <b style={{ color: '#E92C70' }}>hors d’un réseau</b></Text>
                                    <Box direction="row" gap="medium">
                                        <Box >
                                            <Text>Apporteur</Text>
                                            <CustomInput
                                                type='number'

                                                step={0.01}
                                                value={this.state.config.default_taux_commission_apporteur || 0}
                                                onChange={event => this.state.viewOnly ? null : this.onConfigChange('default_taux_commission_apporteur', event.target.value)}
                                                icon={<Text>%</Text>}
                                                reverse
                                            />
                                        </Box>
                                        <Box >
                                            <Text>Apporteur immobilier</Text>
                                            <CustomInput
                                                type='number'

                                                step={0.01}
                                                value={this.state.config.default_taux_commission_apporteur_immobilier || 0}
                                                onChange={event => this.state.viewOnly ? null : this.onConfigChange('default_taux_commission_apporteur_immobilier', event.target.value)}
                                                icon={<Text>%</Text>}
                                                reverse
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box pad={'small'} >
                                    <Text>Tableau des rémunérations des apporteurs <b style={{ color: '#E92C70' }}>d’un réseau</b></Text>
                                    <Box direction="row" gap="medium">
                                        <Box >
                                            <Text>Réseau mère</Text>
                                            <CustomInput
                                                type='number'
                                                step={0.01}
                                                value={this.state.config.default_taux_commission_network_mother || 0}
                                                onChange={event => this.state.viewOnly ? null : this.onConfigChange('default_taux_commission_network_mother', event.target.value)}
                                                icon={<Text>%</Text>}
                                                reverse
                                            />
                                        </Box>
                                        <Box >
                                            <Text>Réseau</Text>
                                            <CustomInput
                                                type='number'
                                                step={0.01}
                                                value={this.state.config.default_taux_commission_network || 0}
                                                onChange={event => this.state.viewOnly ? null : this.onConfigChange('default_taux_commission_network', event.target.value)}
                                                icon={<Text>%</Text>}
                                                reverse
                                            />
                                        </Box>
                                        <Box >
                                            <Text>Apporteur</Text>
                                            <CustomInput
                                                type='number'
                                                step={0.01}
                                                value={this.state.config.default_taux_commission_apporteur_network || 0}
                                                onChange={event => this.state.viewOnly ? null : this.onConfigChange('default_taux_commission_apporteur_network', event.target.value)}
                                                icon={<Text>%</Text>}
                                                reverse
                                            />
                                        </Box>
                                    </Box>

                                    {!this.state.viewOnly &&
                                        <Box direction="row" align="center" justify="center" gap="medium" pad={'medium'}>
                                            <PrimaryButton
                                                label={`Valider`}
                                                icon={<Checkmark />}
                                                background="#59A4F4"
                                                onClick={this.onSubmit}
                                            />
                                            <PrimaryButton
                                                label={`Annuler`}
                                                icon={<Close />}
                                                background="#6c757d"
                                                onClick={() => this.setState({ viewOnly: true })}
                                            />
                                        </Box>
                                    }

                                    {this.state.viewOnly &&
                                        <Box direction="row" align="center" justify="center" gap="medium" pad={'medium'}>
                                            <PrimaryButton
                                                label={`Modifier`}
                                                icon={<Edit />}
                                                background='#E82C70'
                                                onClick={() => this.setState({ viewOnly: false })}
                                            />
                                        </Box>
                                    }

                                </Box>

                                <SectionHeader title={"Commission du crédit"} round={false} color={'#E92C70'} />
                                {this.state.dataChanged && <Box fill background={'yellow'} justify="end" direction="row" pad='xxsmall'>
                                    <PrimaryButton background="transparent" icon={<Checkmark color="green" />} reverse onClick={this.onSubmit} label={'Valider'} color='green'></PrimaryButton>
                                </Box>}
                                <SectionHeader title={"Crédit immobilier"} round={false} color={'#59A4F4'} />
                                <Text style={{ marginLeft: 15 }}>Tableau des rémunérations des apporteurs <b style={{ color: '#E92C70' }}>hors d’un réseau</b></Text>
                                <CreditCommissionElement config={this.state.config} updateAndSave={this.onConfigChange} onSave={this.onSubmit} creditType='CommissionImmobilier' />
                                <Text style={{ marginLeft: 15 }}>Tableau des rémunérations des apporteurs <b style={{ color: '#E92C70' }}>d’un réseau</b></Text>
                                <CreditCommissionElement config={this.state.config} updateAndSave={this.onConfigChange} onSave={this.onSubmit} creditType='CommissionImmobilierNetwork' network={true} />
                                <SectionHeader title={"Regroupement de crédit"} round={false} color={'#59A4F4'} />
                                <Text style={{ marginLeft: 15 }}>Tableau des rémunérations des apporteurs <b style={{ color: '#E92C70' }}>hors d’un réseau</b></Text>
                                <CreditCommissionElement config={this.state.config} updateAndSave={this.onConfigChange} onSave={this.onSubmit} creditType='CommissionRegroupement' />
                                <Text style={{ marginLeft: 15 }}>Tableau des rémunérations des apporteurs <b style={{ color: '#E92C70' }}>d’un réseau</b></Text>
                                <CreditCommissionElement config={this.state.config} updateAndSave={this.onConfigChange} onSave={this.onSubmit} creditType='CommissionRegroupementNetwork' network={true} />
                                <SectionHeader title={"Crédit à la consommation"} round={false} color={'#59A4F4'} />
                                <Text style={{ marginLeft: 15 }}>Tableau des rémunérations des apporteurs <b style={{ color: '#E92C70' }}>hors d’un réseau</b></Text>
                                <CreditCommissionElement config={this.state.config} updateAndSave={this.onConfigChange} onSave={this.onSubmit} creditType='CommissionConsommation' valueType="pourcentage" />
                                <Text style={{ marginLeft: 15 }}>Tableau des rémunérations des apporteurs <b style={{ color: '#E92C70' }}>d’un réseau</b></Text>
                                <CreditCommissionElement config={this.state.config} updateAndSave={this.onConfigChange} onSave={this.onSubmit} creditType='CommissionConsommationNetwork' valueType="pourcentage" network={true} />
                            </Box>
                        </AccordionPanel>
                    </Accordion>



                </Box>}
            </Box>
        )
    }
}

const ProductsCheckBoxes = ({ settings, group, onProductChange, isView, size = 1 }) => {
    return <Box>
        <Text textAlign="start" size="large" margin={'small'}><b>{group}</b> </Text>
        <MultipleLineCheckBoxes
            disabled={isView}
            size={size}
            activeColor='#E82C70'
            groups={(PRODUCTS).filter(e => e.group === group).map(value => ({
                label: value.name,
                selected: (settings.product || []).filter(p => p.name === value.name_config && p.visible).length > 0,
                onClick: () => {
                    if (isView) return;
                    let prods = settings.product || [];
                    if (prods.map(p => p.name).includes(value.name_config)) {
                        for (let i = 0; i < prods.length; i++) {
                            if (prods[i]['name'] === value.name_config)
                                prods[i]['visible'] = !(prods[i]['visible'] || false);
                        }
                    } else {
                        // nothing
                    }
                    onProductChange('product', prods.map(s => s));
                },
            }))}
        />
    </Box>
}

const CreditCommissionElement = ({ config, onSave, updateAndSave, creditType, valueType = "fixe", network = false }) => {

    const updateCommission = (field, value, index, type) => {
        let _commissions = config[type];
        _commissions[index][field] = value;
        updateAndSave(type, _commissions, true);
    }

    const deleteCommission = (index, type) => {
        let _commissions = config[type];
        _commissions.splice(index, 1);
        updateAndSave(type, _commissions);
        onSave();
    }

    const withNetwork = (creditType) => {
        switch (creditType) {
            case "CommissionImmobilierNetwork":
            case "CommissionRegroupementNetwork":
            case "CommissionConsommationNetwork":
                return true;
            default:
                return false;
        }
    }

    const bindValueType = () => {
        if (valueType === 'pourcentage') {
            return "%";
        }
        return "€";
    }

    return (<Box fill gap="small" pad={"small"} >
        <Grid
            fill
            columns={["20%", "20%", "20%", "20%", "20%"]}
            style={{ borderBottomWidth: 1, borderBottomColor: "#eee", borderBottomStyle: 'solid', paddingBottom: 5 }}
            gap={'xsmall'}
            align="center"
        >
            <Box><Text weight={'bold'}>À partir de</Text></Box>
            <Box><Text weight={'bold'}>À inférieure de</Text></Box>
            <Box><Text weight={'bold'}>{withNetwork(creditType) ? "Réseau" : "Apporteur"}</Text></Box>
            <Box><Text weight={'bold'}>{network ? "Apporteur" : "Apporteur immobilier"}</Text></Box>
            <Box><Text weight={'bold'}>Supprimer</Text></Box>
        </Grid>
        {config[creditType] ? (
            config[creditType].map((e, _idx) => {
                return e && (
                    <Grid
                        key={`${creditType}_${_idx}`} fill
                        columns={["20%", "20%", "20%", "20%", "20%"]}
                        gap={'xsmall'}
                        align="center"
                    >
                        <Box>
                            <CustomInput
                                value={e.valeur_superieure}
                                type="number"
                                icon={<Text>€</Text>}
                                reverse
                                onChange={event => updateCommission('valeur_superieure', event.target.value, _idx, creditType)}
                            />
                        </Box>
                        <Box>
                            {(_idx !== config[creditType].length - 1) && <CustomInput
                                value={e.valeur_inferieure}
                                type="number"
                                icon={<Text>€</Text>}
                                reverse
                                onChange={event => updateCommission('valeur_inferieure', event.target.value, _idx, creditType)}
                            />}
                            {(_idx === config[creditType].length - 1) && <CustomInput
                                value={"Infini"}
                                disabled={true}
                                icon={<Text>€</Text>}
                                reverse

                            />}
                        </Box>
                        <Box>
                            {withNetwork(creditType) ? <CustomInput
                                value={e.apporteur_network}
                                type="number"
                                icon={<Text>{bindValueType()}</Text>}
                                reverse
                                onChange={event => updateCommission('apporteur_network', event.target.value, _idx, creditType)}
                            />
                                : <CustomInput
                                    value={e.apporteur}
                                    type="number"
                                    icon={<Text>{bindValueType()}</Text>}
                                    reverse
                                    onChange={event => updateCommission('apporteur', event.target.value, _idx, creditType)}
                                />}
                        </Box>
                        <Box>
                            {withNetwork(creditType) ? <CustomInput
                                value={e.apporteur}
                                type="number"
                                icon={<Text>{bindValueType()}</Text>}
                                reverse
                                onChange={event => updateCommission('apporteur', event.target.value, _idx, creditType)}
                            />
                                : <CustomInput
                                    value={e.apporteur_immobilier}
                                    type="number"
                                    icon={<Text>{bindValueType()}</Text>}
                                    reverse
                                    onChange={event => updateCommission('apporteur_immobilier', event.target.value, _idx, creditType)}
                                />}
                        </Box>
                        <Box >
                            <Button background={'transparent'} icon={<Trash color="brand" />} onClick={() => {
                                if (window.confirm("Êtes-vous sûr de vouloir supprimer cette tranche ?")) {
                                    deleteCommission(_idx, creditType);
                                }
                            }}  ></Button>
                        </Box>

                    </Grid>
                )
            })
        ) : <></>}
        <Box align="start">
            <PrimaryButton icon={<AddCircle />} onClick={() => {
                let _commissionsImmo = config[creditType] || [];

                switch (creditType) {
                    case "CommissionImmobilier":
                        _commissionsImmo.push({ type: 'immobilier', type_value: 'fixe', valeur_superieure: 0 });
                        break;
                    case "CommissionImmobilierNetwork":
                        _commissionsImmo.push({ type: 'immobilier_network', type_value: 'fixe', valeur_superieure: 0 });
                        break;
                    case "CommissionRegroupement":
                        _commissionsImmo.push({ type: 'regroupement', type_value: 'fixe', valeur_superieure: 0 });
                        break;
                    case "CommissionRegroupementNetwork":
                        _commissionsImmo.push({ type: 'regroupement_network', type_value: 'fixe', valeur_superieure: 0 });
                        break;
                    case "CommissionConsommation":
                        _commissionsImmo.push({ type: 'consommation', type_value: 'pourcentage', valeur_superieure: 0 });
                        break;
                    case "CommissionConsommationNetwork":
                        _commissionsImmo.push({ type: 'consommation_network', type_value: 'pourcentage', valeur_superieure: 0 });
                        break;

                    default:
                        break;
                }

                updateAndSave(creditType, _commissionsImmo);
            }}  ></PrimaryButton>
        </Box>

    </Box>);
}

export default DashboardMyTrustiway;
import { Box, Tab, Tabs } from "grommet";
import React from "react";
import { fetchUser, getGeneralObjectifs, updateGlobalObjectif, updateUserObjectif } from "../service";
import IndicateurConfig from "./indicator_config";


const products = [
    "Trusti Emprunteur",
    "Trusti PER",
    "Trusti Crédit",
];

const teams = [
    'Commercial Trustiway',
    "Alternance",
    "Mandataire",
];

class Stats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allUsers: this.props.commerciaux,
            selectedUser: undefined,
            general_ojectifs: [],
            user: {}
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        getGeneralObjectifs()
            .then(res => {
                if (res.data && res.data.value) {
                    let objs = res.data.value;
                    this.setState({ general_ojectifs: objs })
                }
            })
    }

    getUser = () => {
        let userId = this.state.filter.commercial_id;
        if (!userId || userId === 'All') return;
        fetchUser(userId)
            .then(res => this.setState({ user: res.data }));
    }

    updateGlobalObjectif = objs => {
        updateGlobalObjectif(objs)
            .then(res => {
                this.setState({ general_ojectifs: res.data })
            })
    }

    onCommercialSelected = (user) => {
        fetchUser(user.id)
            .then(res => {
                this.setState({ user: res.data });
            });
    }

    updateUserObjectif = objs => {
        let user = this.state.user;
        updateUserObjectif(user.id, objs)
            .then(res => {
                user.objectifs = res.data;
                this.setState({ user: Object.assign({}, user) });
            })
    }

    

    render() {
        return (
            <Box
                style={{ width: "100%" }}
                gap="medium"
                pad={'small'}
                justify="center"
                alignContent="center"
                alignSelf="center"
                align="center"
            >
                <Tabs>
                    <Tab title="Customisation objectif contrat">
                        <Box width={'xlarge'} justify="center" align="center">
                            <IndicateurConfig
                                objectives={this.state.general_ojectifs}
                                onObjectivesUpdate={objs => this.updateGlobalObjectif(objs)}
                            />
                        </Box>
                    </Tab>
                    <Tab title="Customisation objectif">
                        <Box width={'xlarge'} justify="center" align="center">
                            <IndicateurConfig
                                objectives={this.state.user.objectifs || []}
                                commerciaux={this.state.allUsers}
                                onCommercialSelected={this.onCommercialSelected}
                                onObjectivesUpdate={this.updateUserObjectif}
                            />
                        </Box>
                    </Tab>
                </Tabs>

            </Box>
        )
    }
}

export default Stats;
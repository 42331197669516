import React, { useEffect, useState } from "react";
import {
	Box,
	Layer,
	Text,
} from "grommet";
import { getContextBot } from "../../../services/storage_service";
import OpportunityTestModal from "./modal";
import PrimaryButton from "../primary_button";
import { getAllOpportunityConfigs, getLastOpportunityConfigs } from "../../../services/opportunity_service";

const OpportunityTest = ({  refreshReports, showMessage }) => {
	const [showModal, setShowModal] = React.useState(false);
	const [loading, setLoading] = useState(false);
	const [client, setClient] = useState(false);
	const [listOpportunityConfigs, setListOpportunityConfigs] = useState([]);
	const [lastOpportunityTest, setLastOpportunityTest] = useState(false);
	

	const loadData = () => {
		const _contextBot = getContextBot();
		let _client = null;
		var contract_id = null;
		var commercial = null;
		if (_contextBot) {
			if (_contextBot.client) {
				_client = _contextBot.client.id;
				setClient(_client)
			}
			if (_contextBot.id) {
				contract_id = _contextBot.id;
			}
			if (_contextBot.selectedCommercial) {
				commercial = _contextBot.selectedCommercial;
			}
		}
		setLoading(true);
		try {
			getAllOpportunityConfigs().then((res) => {
				setListOpportunityConfigs(res.data);
				console.log(res.data);
				setLoading(false);
			}).catch(e => {
				console.log("error = ", e);
			});
			getLastOpportunityConfigs(_client).then((res) => {
				console.log(res.data);
				if(res.data){
					if (res.data.length > 0) {
						setLastOpportunityTest(res.data[0]);
						setLoading(false);		
					}
				}
			}).catch(e => {
				console.log("error = ", e);
			});
		} catch (error) {
			console.log("error", error);
			setLoading(false);
		}
	};

	useEffect(() => {
		loadData();
		// eslint-disable-next-line
	}, []);

	const onSubmit = () => {
		console.log("on submit");
		showMessage && showMessage("L'Opportunity Test a été sauvegardé avec succès", "success");
		setShowModal(false);
		loadData();
	}

	return (

		<Box  >
			<PrimaryButton label={<Text weight={'bold'} style={{ textTransform: 'uppercase' }}>Démarrer l’opportunity test</Text>} onClick={()=>setShowModal(true)} />
			{showModal && (
				<Layer pad="medium" onClickOutside={() => setShowModal(false)} onEsc={() => setShowModal(false)} >
					{/* <Box width="xlarge"> */}
					<Box justify="center" align="center" pad={'medium'} style={{maxHeight: "90vh", overflow:"scroll"}}>
						<OpportunityTestModal
							refreshReports={refreshReports}
							opportunityData={listOpportunityConfigs}
							lastTest={lastOpportunityTest}
							showMessage={showMessage}
							onSubmit={onSubmit}
							onClose={() => setShowModal(false)}
							client={client}
						/>
					</Box>
					{/* </Box> */}
				</Layer>
			)}
		</Box>

	);
};

export default OpportunityTest;

import { Box, DataTable, Layer } from "grommet";
import { Checkmark, Close, Edit, Trash, View } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { getSimpleDateFR, toCurrency } from "../../../../services/utils";
import { CellContainer } from "../containers/text-containser";
import CustomSelect from "../custom-select";
import { CustomDateInput } from "../date_input";
import CustomTextInput from "../text-input";
import { TextLabel } from "../text-label";


export default function Table({ data, headers, onChangeSubmit }) {
    const [values, setValues] = useState(data);
    useEffect(() => {
        setValues(data);
    }, [data]);
    return (
        <Box fill pad={{ top: '1rem' }}>
            <DataTable
                border={{ header: { color: 'white', side: 'bottom' } }}
                pad={{ horizontal: 'xxsmall', vertical: 'xxsmall' }}
                data={values}
                columns={headers.map(header => genColumn(header, () => onChangeSubmit(values)))}
            />
        </Box>
    )
}

const genColumn = (header, onChangeSubmit) => {
    return {
        property: header.key,
        header: header.type === 'action' ? <Box style={{ width: '10px' }}></Box> : <Box
            fill
            justify="center"
            style={{
                backgroundColor: '#E82C70',
                color: "white !important",
                paddingLeft: "0.375rem",
                paddingBottom: "0.125rem",
                paddingTop: "0.125rem",
            }}
        ><TextLabel variant={"title1"} label={header.label || "*"} color={'white'} /></Box>,
        render: item => (
            <TableCell
                item={item}
                onChangeSubmit={onChangeSubmit}
                header={header}
            />
        )
    }
}

const TableCell = ({ item, onChangeSubmit, header }) => {
    const [value, setValue] = useState(item[header.key]);
    const [datePopup, setDatePopup] = useState(false);
    const [textPopup, setTextPopup] = useState(false);
    const [selectPopup, setSelectPopup] = useState(false);

    useEffect(() => {
        setValue(item[header.key]);
    }, [item]);

    const openPopup = (type) => {
        switch (type) {
            case 'date':
                return setDatePopup(true);
            case 'text':
                return setTextPopup(true);
            case 'select':
                return setSelectPopup(true);
            default:
                break;
        }
    }

    const onSubmit = (value) => {
        item[header.key] = value;
        onChangeSubmit(item);
    }
    return (
        <CellContainer>
            {header.type === 'action' ?
                (<Box direction='row' justify="start" gap="xsmall" align="center">
                    {item.actions?.map((action, index) => (
                        <Box pad={{ bottom: 'xxsmall' }} key={index}>{getAction(action, index)}</Box>
                    ))}
                </Box>) :
                <Box direction='row' justify='between'>
                    <TextLabel variant={"body1"} label={getDisplayValue(header.type, header.subType, value)} />
                    {!header.readOnly && <Edit size='small' onClick={() => openPopup(header.type)} />}
                </Box>
            }

            {datePopup && <PopupDate
                label={header.label}
                value={value || undefined}
                onChange={(e) => setValue(e.value)}
                onValidate={() => {
                    onSubmit(value);
                    setDatePopup(false)
                }}
                onCancel={() => {
                    setDatePopup(false);
                    setValue(item[header.key] || '-');
                }}
            />}

            {textPopup && <PopupTextInput
                value={value}
                label={item.label}
                onChange={(e) => setValue(e.target.value)}
                onValidate={() => {
                    onSubmit(value);
                    setTextPopup(false)
                }}
                onCancel={() => {
                    setTextPopup(false);
                    setValue(item[header.key] || '-');
                }}
            />}

            {selectPopup && <PopupSelect
                options={header.options}
                value={value}
                label={header.label}
                onChange={({ option }) => setValue(option)}
                onValidate={() => {
                    onSubmit(value);
                    setSelectPopup(false)
                }}
                onCancel={() => {
                    setSelectPopup(false);
                    setValue(item[header.key] || '-');
                }}
            />}
        </CellContainer>
    )
}

const getAction = (action, index) => {
    switch (action.type) {
        case 'view':
            return (
                <View key={index} color="brand" size="small" onClick={action.onClick} style={{ cursor: 'pointer' }} />
            )
        case 'delete':
            return (
                <Trash key={index} color="brand" size="small" onClick={action.onClick} style={{ cursor: 'pointer' }} />
            )
    }
}


const PopupSelect = ({ options, value, onChange, onValidate, onCancel, label }) => (
    <Layer>
        <Box pad={'medium'} gap='small' justify='center' align='start'>
            <TextLabel variant={"title1"} label={label} />
            <Box fill direction='row' justify='between' align='center' gap='small'>
                <CustomSelect
                    options={options}
                    value={value}
                    onChange={onChange}
                    editMode
                />
                <Box direction='row' gap='small'>
                    <Checkmark size='small' onClick={onValidate} color='brand' />
                    <Close size='small' onClick={onCancel} />
                </Box>
            </Box>
        </Box>
    </Layer>
)


const PopupDate = ({ value, onChange, onValidate, onCancel, label }) => (
    <Layer>
        <Box pad={'medium'} gap='small' justify='center' align='start'>
            <TextLabel variant={"title1"} label={label} />
            <Box fill direction='row' justify='between' align='center' gap='small'>
                <CustomDateInput value={value} onChange={onChange} editMode />
                <Box direction='row' gap='small'>
                    <Checkmark size='small' onClick={onValidate} color='brand' />
                    <Close size='small' onClick={onCancel} />
                </Box>
            </Box>
        </Box>
    </Layer>
)
const PopupTextInput = ({ value, onChange, onValidate, onCancel, label }) => (
    <Layer>
        <Box pad={'medium'} gap='small' justify='center' align='start'>
            <TextLabel variant={"title1"} label={label} />
            <Box fill direction='row' justify='between' align='center' gap='small'>
                <CustomTextInput value={value} onChange={onChange} editMode />
                <Box direction='row' gap='small'>
                    <Checkmark size='small' onClick={onValidate} color='brand' />
                    <Close size='small' onClick={onCancel} />
                </Box>
            </Box>
        </Box>
    </Layer>
)

const getDisplayValue = (type, subType, value) => {
    switch (type) {
        case 'date':
            return value ? getSimpleDateFR(value) : '-';
        case 'text':
            if (subType === 'currency') {
                return toCurrency(value);
            }
            return value || '-';
        case 'select':
            return value || '-';
        default:
            return '-';
    }
}
const axios = require("axios");
const storageService = require("../../../../services/storage_service");
const { baseAPI } = require("../../../../config/config.json");
const moment = require("moment");
const HEADERS = {
	Authorization: `Bearer ${storageService.getToken()}`,
};
const TYPE_VIEW_NAME = {
	upcoming: "Tous",
	today: "Aujourd'hui",
	late: "En retard",
	done: "Terminé",
};

const createAppointment = (appointment) => {
	return axios.post(`${baseAPI}/public/appointment`, appointment, {
		headers: HEADERS,
	});
};

const deleteAppointment = (appointment) => {
	return axios.delete(
		`${baseAPI}/public/appointment/${appointment.id}`,
		{},
		{ headers: HEADERS },
	);
};

const updateAppointment = (appointment) => {
	return axios.put(`${baseAPI}/appointment/${appointment.id}`, appointment, {
		headers: HEADERS,
	});
};

const getAppointments = (client, typeView, contract_id, commercial_id) => {
	var params = client ? { client } : {};
	if(contract_id){
		if(contract_id.includes(',')){
			params.contract_id_in = contract_id.split(',');
		}else{
			params.contract_id = contract_id;
		}
	}
	if(commercial_id){
		params.commercial = commercial_id;
	}
	if(storageService.hasAdministrativeRole()){
		switch (typeView) {
			case TYPE_VIEW_NAME.upcoming:
				params["_where[status_ne]"] = "Fait";
				break;
			case TYPE_VIEW_NAME.today:
				params["_where[0][begin_gte]"] = moment().utc().startOf("day").toISOString();
				params["_where[1][begin_lte]"] = moment().utc().endOf("day").toISOString();
				break;
			case TYPE_VIEW_NAME.late:
				params["_where[0][begin_lt]"] = moment().toISOString();
				params["_where[1][status_ne]"] = "Fait";
				break;
			case TYPE_VIEW_NAME.done:
				params["_where[0][status]"] = "Fait";
				break;
			default:
				params["_where[_or][0][0][begin_gte]"] = moment().utc().startOf("day").toISOString();
				params["_where[_or][0][1][begin_lte]"] = moment().utc().endOf("day").toISOString();
				params["_where[_or][1][0][begin_lt]"] = moment().toISOString();
				params["_where[_or][1][1][status_ne]"] = "Fait";
				break;
		}
	}else{
		switch (typeView) {
			case TYPE_VIEW_NAME.upcoming:
				params["_where[0][status_ne]"] = "Fait";
				break;
			case TYPE_VIEW_NAME.today:
				params["_where[0][0][begin_gte]"] = moment().utc().startOf("day").toISOString();
				params["_where[0][1][begin_lte]"] = moment().utc().endOf("day").toISOString();
				break;
			case TYPE_VIEW_NAME.late:
				params["_where[0][0][begin_lt]"] = moment().toISOString();
				params["_where[0][1][status_ne]"] = "Fait";
				break;
			case TYPE_VIEW_NAME.done:
				params["_where[0][0][status]"] = "Fait";
				break;
			default:
				params["_where[0][_or][0][0][begin_gte]"] = moment().utc().startOf("day").toISOString();
				params["_where[0][_or][0][1][begin_lte]"] = moment().utc().endOf("day").toISOString();
				params["_where[0][_or][1][0][begin_lt]"] = moment().toISOString();
				params["_where[0][_or][1][1][status_ne]"] = "Fait";
				break;
		}
		params["_where[1][commercial]"] = storageService.getUserId();
		
	}
	params["_sort"] = "begin:ASC";
	params["_limit"] = "-1";

	return axios.get(`${baseAPI}/public/appointment`, {
		params,
		headers: HEADERS,
	});
};

const getPassedAppointments = (client) => {
	let params = client ? { client } : {};
	params["_where[0][begin_lt]"] = moment().toISOString();

	return axios.get(`${baseAPI}/public/appointment`, {
		params,
		headers: HEADERS,
	});
};

const getDevisInfo = (id, type) => {
	return axios.get(`${baseAPI}/public/${type.replace(/_/g, '-')}/${id}`, {
	  headers: HEADERS,
	});
  };

module.exports = {
	createAppointment,
	updateAppointment,
	deleteAppointment,
	getAppointments,
	getPassedAppointments,
	getDevisInfo,
	TYPE_VIEW_NAME,
};

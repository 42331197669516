import React, { Fragment, useState } from "react";
import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Input,
	FormControl,
	Textarea,
	useToast,
	InputGroup,
	InputRightAddon,
} from "@chakra-ui/core";
import PrimaryButton from "../../../elements/primary_button";
import { Box, Card, Layer } from "grommet";
import {
	getClientName,
} from "../../../outils/client_util";
import {
	createAppointment,
	deleteAppointment,
	updateAppointment,
} from "../service/rdv_rappel";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getContextBot, getUser } from "../../../../services/storage_service";
import moment from "moment";
import { AddCircle } from "grommet-icons";

const RdvModal = ({ rdvData, label, type, callback, icon, onOpenedDialog}) => {
	

	const toast = useToast();
	const _contextBot = getContextBot();
	const _commercial = getUser();

	const [isOpen, setIsOpen] = useState(false);
	const onOpen = () => {
		setIsOpen(true);
		onOpenedDialog(true);
	};

	const onClose = () => {
		setIsOpen(false);
		onOpenedDialog(false);
	};

	// console.log("rdvData", rdvData);

	const onDelete = (e) => {
		if (rdvData) {
			deleteAppointment(rdvData)
				.then((res) => {
					toast({
						title: "Réussi",
						description: "Rdv est bien supprimé",
						status: "success",
						duration: 3000,
						isClosable: true,
					});
					onClose();
					callback();
				})
				.catch((err) => {
					toast({
						title: "Erreur",
						description: err.message,
						status: "error",
						duration: 3000,
						isClosable: true,
					});
				});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		const title = form.elements["title"].value;
		const begin_date = form.elements["begin_date"].value;
		const begin_time = form.elements["begin_time"].value;
		const duration = form.elements["duration"].value;
		const note = form.elements["note"].value;
		const begin_date_time = moment(
			`${begin_date} ${begin_time}`,
			"YYYY-MM-DD HH:mm",
		).toDate();
		const end_date_time = moment(begin_date_time)
			.add(duration, "minutes")
			.toDate();

		if (rdvData) {
			// update
			rdvData.title = title;
			rdvData.note = note;
			rdvData.begin = begin_date_time;
			rdvData.duration = duration;
			rdvData.end = end_date_time;
			updateAppointment(rdvData)
				.then(() => {
					toast({
						title: "Réussi",
						description: "Note est bien modifié",
						status: "success",
						duration: 3000,
						isClosable: true,
					});
					onClose();
					callback();
				})

				.catch((err) => {
					toast({
						title: "Erreur",
						description: err.message,
						status: "error",
						duration: 3000,
						isClosable: true,
					});
				});
		} else {
			// create new

			var _params = {
				title: title,
				note: note,
				begin: begin_date_time,
				duration: duration,
				end: end_date_time,
				commercial: _commercial?._id,
				type: type
			};
			if (_contextBot) {
				_params.client = _contextBot.client.id || _contextBot.client;
				_params.contract_name = _contextBot.product
				_params.contract_id = _contextBot.id;
			}
			createAppointment(_params)
				.then(() => {
					toast({
						title: "Réussi",
						description: "RDV est bien créé",
						status: "success",
						duration: 3000,
						isClosable: true,
					});
					onClose();
					callback();
				})
				.catch((err) => {
					toast({
						title: "Erreur",
						description: err.message,
						status: "error",
						duration: 3000,
						isClosable: true,
					});
				});
		}
	};

	return (
		<Fragment>
      {icon ? (
			  <PrimaryButton label={label} onClick={onOpen} background="none" icon={<AddCircle color="white" />} />
      ) : (
			  <PrimaryButton label={label} onClick={onOpen} />
      )}
			<Modal
				isOpen={isOpen}
				size="xl"
				onClose={() => {
					onClose();
					callback();
				}}
				closeOnOverlayClick={true}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{rdvData ? "Modification" : "Nouveau " + type}
					</ModalHeader>
					<ModalCloseButton />
					<form id="form" onSubmit={handleSubmit}>
						<ModalBody>
							<Box gap={"small"}>
								<FormControl isRequired>
									<Input
										type="text"
										id="title"
										placeholder="Titre"
										defaultValue={
											rdvData
												? rdvData.title
												: _commercial?.prenom +
												  " " +
												  _commercial?.nom +
												  " X " +
												  (rdvData
														? getClientName(rdvData.client)
														: _contextBot
														? getClientName(_contextBot.client)
														: "")
										}
									/>
								</FormControl>
								<Textarea
										id="note"
										minH="280px"
										size="xl"
										resize="vertical"
										defaultValue={rdvData ? rdvData.note : ""}
									/>
								<Box direction="row" justify="stretch">
									<FormControl isRequired>
										<Input
											type="date"
											id="begin_date"
											placeholder="Date"
											defaultValue={
												rdvData
													? moment(rdvData.begin).format("YYYY-MM-DD")
													: ""
											}
										/>
									</FormControl>
									<FormControl isRequired>
										<Input
											type="time"
											id="begin_time"
											placeholder="Heure"
											defaultValue={
												rdvData ? moment(rdvData.begin).format("HH:mm") : ""
											}
										/>
									</FormControl>
									<FormControl isRequired>
										<InputGroup>
											<Input
												type="number"
												id="duration"
												placeholder="Durée"
												defaultValue={
													rdvData ? rdvData.duration : type === "RDV" ? 30 : 5
												}
											/>
											<InputRightAddon children="minute(s)" />
										</InputGroup>
									</FormControl>
								</Box>
								<Box direction="row" justify="between">
									<FormControl>
										<Input
											type="text"
											id="contract_name"
											placeholder="Produit"
											isDisabled={true}
											value={
												rdvData
													? rdvData.contract_name
													: _contextBot
													? _contextBot.product
													: ""
											}
										/>
									</FormControl>
									<FormControl>
										<Input
											type="text"
											id="client_name"
											placeholder="Client"
											isDisabled={true}
											value={
												rdvData
													? getClientName(rdvData.client)
													: _contextBot
													? getClientName(_contextBot.client)
													: ""
											}
										/>
									</FormControl>
								</Box>
							</Box>
						</ModalBody>

						<ModalFooter>
							<Box direction="row" justify="between">
								{rdvData && (
									<Button
										type="reset"
										variantColor="primary"
										mr={3}
										onClick={onDelete}
									>
										Supprimer
									</Button>
								)}

								<Button type="submit" variantColor="green">
									Valider
								</Button>
							</Box>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>
		</Fragment>
	);
};

export default RdvModal;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";
import {
  CONTRAT_CLOTURE,
  CONTRAT_ENVOYE,
  DEAL_POSSIBLE,
  DEAL_POSSIBLE_REMPLI,
  DEVIS,
  DEVIS_VALIDE,
  EN_ATTENTE_DE_FORMALITE_MEDICALE,
  EN_ATTENTE_DE_RESILIATION,
  PROSPECT,
} from "../../../../services/devis_contrat_status";
import { getSimpleDateFR, getSimpleTimeFR } from "../../../../services/utils";
import BlockUi from "react-block-ui";
import { getAppelsByStatus } from "../../../../services/appointment_service";
import PrimaryButton from "../../../elements/primary_button";
import { Refresh } from "grommet-icons";

const InfoVenteElem = ({ title, children }) => {
  return (
    <Box margin="0 0 20px 0">
      <Text weight={600}>{title}</Text>
      <Box width="50px" height={"3px"} background="bluebrand" margin="0 0 6px 0" />
      <Box>
        {children}
      </Box>
    </Box>
  );
};

const InfoVenteMark = ({ text }) => {
  if (!text)
    return null;
  return (
    <Box margin="6px 0" background="brand" pad="5px 25px" width="max-content" style={{ borderRadius: "10px" }}>
      <Text color="white" weight={600} textAlign="center">{text}</Text>
    </Box>
  );
};



const InfoVente = ({ devis }) => {
  console.log("devis", devis);
  return (
    <Box>
      <Grid
        columns={["1/3", "1/3", "1/3"]} gap="small" pad={"small"} width="100%"
      >
        <InfoVenteElem title="Situation familiale">
          <Text>Situation familiale et maritale</Text>
          <InfoVenteMark text={devis.familial_situation} />
          <Text>Enfant(s) à charge</Text>
          <InfoVenteMark text={devis.children_count} />
        </InfoVenteElem>
        <InfoVenteElem title="Situation professionnel">
          <Text>Situation professionnelle</Text>
          <InfoVenteMark text={devis.profession_situation} />
          <Text>Revenus du foyer</Text>
          <Text weight={600}>{devis.revenue_annuel} €</Text>
        </InfoVenteElem>
        <InfoVenteElem title="Type de résidence">
          <Text>Situation actuelle</Text>
          <InfoVenteMark text={devis.location_situation} />
          <Text>Indiquer le loyer mensuel</Text>
          <Text weight={600}>{devis.rent_amount} €</Text>
        </InfoVenteElem>
        <InfoVenteElem title="Patrimoine">
          <Text>Valeur du patrimoine immobilier</Text>
          <InfoVenteMark text={devis.patrimoines.find((e) => e.label === "Immobilier")?.value} />
          <Text>Valeur du patrimoine financier</Text>
          <InfoVenteMark text={devis.patrimoines.find((e) => e.label === "Financier")?.value} />
        </InfoVenteElem>
        <InfoVenteElem title="Emprunts">
          <Text>Emprunts en cours</Text>
          <Text weight={600}>{devis.loan_period === 0 ? "Non" : "Oui"} {devis.loan_period} €</Text>
        </InfoVenteElem>
        <InfoVenteElem title="Projection départ à la retraite">
          <Text>Âge souhaité de départ à la retraite</Text>
          <Text weight={600}>{devis.retirement_age} Ans</Text>
        </InfoVenteElem>
        <InfoVenteElem title="Définissons du projet">
          <Text>Montant du premier versement</Text>
          <Text weight={600}>{devis.first_payment} €</Text>
          <Text>Montant des versements mensuels</Text>
          <Text weight={600}>{devis.monthly_payment} € / mensuel</Text>
        </InfoVenteElem>
        <InfoVenteElem title="Date d'effet souhaité">
          <Text margin="0 0 10px 0">{devis.date_effet?.split('T')[0]}</Text>
        </InfoVenteElem>
        <InfoVenteElem title="Préférence gestion">
          <Text margin="0 0 10px 0">{devis.preference_gestion}</Text>
        </InfoVenteElem>
        <InfoVenteElem title="Taux indexation cotisations">
          <Text>{devis.index_rate} %</Text>
        </InfoVenteElem>
        <InfoVenteElem title="Option de sortie">
          <Text>{devis.exit_option}</Text>
        </InfoVenteElem>
        <InfoVenteElem title="Taux de sortie partielle en rente">
          <Text>{devis.exit_rate} %</Text>
        </InfoVenteElem>
      </Grid>
      <Box pad="small">
      <InfoVenteElem title="Questions légales">
        <Grid columns={["1/2", "1/2"]} gap="small" margin="10px 0 0 0">
          <Box gap="xsmall">
            <Text>Supposons que votre investissement perde 10% en 6 mois, comment réagissez-vous ?</Text>
            <InfoVenteMark text={<Text>{devis.quiz_1}</Text>} />
          </Box>
          <Box gap="xsmall">
            <Text>Sur l’assurance-vie, le capital placé est bloqué pendant 8 ans ?</Text>
            <InfoVenteMark text={<Text>{devis.quiz_2}</Text>} />
          </Box>
          <Box gap="xsmall">
            <Text>Un tracker (ETF ou fonds indiciel) est un placement à capital garanti ?</Text>
            <InfoVenteMark text={<Text>{devis.quiz_3}</Text>} />
          </Box>
          <Box gap="xsmall">
            <Text>SupVia un mandat d’arbitrage, vous déléguez les décisions d’investissement à Trustiway ?</Text>
            <InfoVenteMark text={<Text>{devis.quiz_4}</Text>} />
          </Box>
          <Box gap="xsmall">
            <Text>Avez-vous déjà investi dans des actions ou des obligations, en direct ou via des fonds d’investissement (SICAV, FCP ou ETF) ?</Text>
            <InfoVenteMark text={<Text>{devis.quiz_5}</Text>} />
          </Box>
        </Grid>
      </InfoVenteElem>
      </Box>
    </Box>
  );
}

export default InfoVente;

import React from "react";
import { Box, Button, Card, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { getAbonnementByUser } from "../service";
import { getSimpleDateFR, getSorted } from "../../../services/utils";
import AppUserInfo from "./user_info";
import AppUserDetail from "./user_detail";
import { SmallLine } from "../../elements/line_container";


class AppAbonnementByUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userAbonnements: [],
      popupOpen: false,
      selectedUser: {},
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    };
  }
  componentDidMount = () => {
    this.loadAppUsers();
  };

  loadAppUsers = () => {
    this.setState({ userAbonnements: [] }, () => {
      getAbonnementByUser(this.props.user?.id)
        .then((res) => {
          this.setState({ userAbonnements: res.data });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false });
  };

  openUser = (user) => {
    this.setState({ selectedUser: user, popupOpen: true });
  };

  addUser = () => this.openUser({});

  search = (query) => {
    getAbonnementByUser(this.props.user?.id, query)
      .then(res => {
        if (res.data?.results) {
          this.setState({ userAbonnements: res.data?.results })
        } else if (res.data) {
          this.setState({ userAbonnements: res.data })
        }
      })
      .catch(err => {
        console.log('err', err);
        this.props.showMessage('Erreur inattendu');
      })
  }

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  render() {
    return (<Box
      gap="small"
      width="large"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Card pad={'small'} width='large' gap={'small'}>
        <Text size="large" ><b>Informations abonnement</b></Text>

        <SmallLine leftLabel={'Abonnement'} bold={true}
          rightChildren={<Text size="small" >{this.props.user.app_abonnement ? 'Payant' : 'Gratuit' }</Text>}
        />
        <SmallLine leftLabel={'Mensualité'} bold={true}
          rightChildren={<Text size="small" >{(this.props.user?.app_abonnement || "0")} €</Text>}
        />

      </Card>
      <Card pad={'small'} width='large' gap={'small'}>
        <Text size="large" ><b>Échéancier</b></Text>

        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>
                <Text size="small">Création/<br />Modification</Text>
              </TableCell>
              <TableCell>
                <Text size="small">Montant</Text>
              </TableCell>
              <TableCell>
                <Text size="small">Status</Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          {this.state.userAbonnements.length > 0 && <TableBody>
            {getSorted(this.state.userAbonnements, this.state.filter.sort).map((userAbonnement, index) => (
              <TableRow key={index + 1}>
                <TableCell>
                  <Text size="small"><strong>{getSimpleDateFR(userAbonnement.createdAt)}</strong><br />{getSimpleDateFR(userAbonnement.updatedAt)}</Text>
                </TableCell>
                <TableCell>
                  <Text>{(userAbonnement.data?.amount / 100).toFixed(2)} €</Text>
                </TableCell>
                <TableCell>
                  <Text>{userAbonnement.status}</Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>}
        </Table>
        {this.state.userAbonnements.length === 0 && <Box alignContent="center" align="center"><Text size="small">Aucun donné</Text></Box>}
      </Card>
    </Box>);
    ;
  }
}

export default AppAbonnementByUser;


import React from "react";
import { Box, Button, Text, TextInput, } from "grommet";
import LineModification from "../../elements/line_container/line_modification";
import PrimaryButton from "../../elements/primary_button";
import { Previous } from "grommet-icons";
import { createPushNotifications } from "../service";
import { DateTimeInput } from "../../elements/date_input";
import CustomSelect from '../../elements/select_fill';

class AppPushNotificationCreate extends React.Component {

    state = {
        repeat: "Une seule fois"
    }

    componentDidMount = () => {
        // getAppUsersValidated()
        //     .then((res) => {
        //         this.setState({
        //             apporteurs: res.data.map((e) => {
        //                 return { ...e, name: `${e.prenom} ${e.nom}` };
        //             })
        //         });
        //     })
        //     .catch((err) => {
        //         console.log("err", err);
        //         this.props.showMessage("Erreur inattendu", 'error');
        //     });

    }

    createValidation = () => {
        createPushNotifications(this.state).then((res) => {
            this.props.showMessage && this.props.showMessage('Push notification créé.', 'success');
            this.props.onClose && this.props.onClose();
        })
            .catch((err) => {
                console.log("err", err);
                this.props.showMessage && this.props.showMessage("Erreur inattendu", 'error');
            });

    };

    render() {

        return (
            <Box
                style={{ width: "100%" }}
                gap="small"
                width="xlarge"
                alignSelf="center"
                align="center"
                pad="small"
                justify="center"
            >
                <Box direction="row" align="between">
                    <Box pad="small" width="xsmall">
                        <PrimaryButton icon={<Previous />} onClick={() => {
                            this.props.onClose && this.props.onClose();
                        }} />
                    </Box>
                    <Text size="xxlarge" style={{ padding: 10 }}><b> Création Push Notification</b></Text>
                </Box>
                <LineModification text="Titre" field='title'
                    value={this.state.title}
                    direction='column'
                    onChange={(field, value) => this.setState({ title: value })}
                />
                <LineModification text="Contenu" field='content'
                    value={this.state.content}
                    direction='column'
                    onChange={(field, value) => this.setState({ content: value })}
                />

                <Box direction="column" gap="xsmall" justify="start" align="center" width={'large'}>
                    <Box style={{ width: '100%' }}>
                        <Text size="xsmall" color={'#707070'}>Date d'envoi</Text>
                    </Box>
                    <Box style={{ width: '100%' }}>
                        <DateTimeInput
                            style={{
                                background: '#EDEDED', borderRadius: 10, border: 'none', borderColor: 'transparent'
                            }}
                            value={this.state.date_to_send}
                            onChange={(date) => this.setState({ date_to_send: date })}
                        />
                    </Box>

                </Box>
                <Box direction={'column'} gap="xsmall" justify="start" align="center" width={'large'}>
                    <Box style={{ width: '100%' }}>
                        <Text size="xsmall" color={'#707070'}>Récurrente</Text>
                    </Box>
                    <Box style={{ width: '100%' }}>
                        <CustomSelect
                            options={[
                                {name :"Une seule fois"},
                                {name :"Tous les jour"},
                                {name :"Tous les 2 jours"},
                                {name :"Tous les 3 jours"},
                                {name :"Une fois par semaine"},
                                {name :"Une fois par mois"}
                              ]}
                            labelKey={"name"}
                            valueKey={{ key: "name", reduce: true }}
                            value={this.state.repeat}
                            onChange={({ option }) => {
                                console.log(option);
                                this.setState({ repeat: option.name });
                            }}
                        />
                    </Box>
                </Box> 

                <Button
                    label="Valider"
                    primary
                    onClick={() => {
                        console.log(this.state);
                        console.log('Valider')
                        this.createValidation();
                    }}
                />
            </Box>
        )
    }
}

export default AppPushNotificationCreate;

import { Box, Card } from "grommet";
import { Refresh } from "grommet-icons";
import { Text } from "grommet/components/Text";
import moment from "moment";
import React from "react";
import { getEquipes } from "../../services/admin_service";
import service from "../../services/dashboard";
import {
  A_RAPPELER_PLUS_TARD,
  CONTRAT_ENVOYE, DEAL_POSSIBLE,
  DEAL_POSSIBLE_REMPLI, DEVIS, DEVIS_VALIDE, EN_ATTENTE_BANQUE, EN_ATTENTE_DE_FORMALITE_MEDICALE, EN_ATTENTE_DE_RESILIATION, PROSPECT
} from "../../services/devis_contrat_status";
import { getUser, hasAdministrativeRole, hasClassiqueManagerRole } from "../../services/storage_service";
import { getMonthOptions } from "../../services/utils";
import PrimaryButton from "../elements/primary_button";
import CustomSelect from "../elements/select_fill";
import { products } from "../outils/client_util";
import StatusReport from "./status_report";

const _DEFAULT_STATE = {
  commerciaux: [],
  futureAppts: [],
  prospects: {
    label: PROSPECT,
    reports: null,
  },
  deals: {
    label: DEAL_POSSIBLE,
    reports: null,
  },
  dealsRappeler: {
    label: "Deals " + A_RAPPELER_PLUS_TARD,
    reports: null,
  },
  dealsRempli: {
    label: DEAL_POSSIBLE_REMPLI,
    reports: null,
  },
  devisList: {
    label: DEVIS,
    reports: null,
  },
  devisValidedList: {
    label: DEVIS_VALIDE,
    reports: null,
  },
  waitingResiliationList: {
    label: EN_ATTENTE_DE_RESILIATION,
    reports: null,
  },
  waitingMedicalList: {
    label: EN_ATTENTE_DE_FORMALITE_MEDICALE,
    reports: null,
  },
  contratEnvoyedList: {
    label: CONTRAT_ENVOYE,
    reports: null,
  },
  enAttenteBanqueList: {
    label: EN_ATTENTE_BANQUE,
    reports: null,
  }
};

const nbr_appel_filter = (filter, deal, suffix) => {
  if (!filter.nbr_appel && filter.nbr_appel !== 0) return true;
  return deal['nbr_appel_' + suffix] === filter.nbr_appel;
}
const filterOnStatus = (allData, status, nb_status, filter) =>
  allData.filter((report) => report.status === status && nbr_appel_filter(filter, report, nb_status));

const buildReports = (_allData, filter) => {
  return ({
    prospects: { label: PROSPECT, reports: filterOnStatus(_allData, PROSPECT, 'prospect', filter) },
    deals: { label: DEAL_POSSIBLE, reports: filterOnStatus(_allData, DEAL_POSSIBLE, 'deal_possible', filter) },
    dealsRappeler: { label: "Deals " + A_RAPPELER_PLUS_TARD, reports: filterOnStatus(_allData, A_RAPPELER_PLUS_TARD, 'deal_possible', filter) },
    dealsRempli: { label: DEAL_POSSIBLE_REMPLI, reports: filterOnStatus(_allData, DEAL_POSSIBLE_REMPLI, 'deal_rempli', filter) },
    devisList: { label: DEVIS, reports: filterOnStatus(_allData, DEVIS, 'devis', filter) },
    devisValidedList: { label: DEVIS_VALIDE, reports: filterOnStatus(_allData, DEVIS_VALIDE, 'devis_valide', filter) },
    contratEnvoyedList: { label: CONTRAT_ENVOYE, reports: filterOnStatus(_allData, CONTRAT_ENVOYE, 'contrat', filter) },
    waitingResiliationList: {
      label: EN_ATTENTE_DE_RESILIATION,
      reports: filterOnStatus(_allData, EN_ATTENTE_DE_RESILIATION, 'resiliation', filter)
    },
    waitingMedicalList: {
      label: EN_ATTENTE_DE_FORMALITE_MEDICALE,
      reports: filterOnStatus(_allData, EN_ATTENTE_DE_FORMALITE_MEDICALE, 'formalite_medicale', filter)
    },
    enAttenteBanqueList: { label: EN_ATTENTE_BANQUE, reports: _allData.filter((report) => report.status === EN_ATTENTE_BANQUE, filter) },

  })
}
class Reports extends React.Component {
  constructor(props) {
    super(props);
    console.log('ok:', props.commerciaux);
    const endDate = new Date();
    endDate.setHours(endDate.getHours() + 2, 0, 0, 0);
    this.state = {
      ..._DEFAULT_STATE, filter: {
        startDate: moment(new Date())
          .subtract(11, "year")
          .startOf("month")
          .toDate(),
        endDate,
        product: props.products ? [props.products[0]] : undefined,
        commercial_ids: props.commerciaux && !props.personal ? "All" : undefined,
      },
      sources: [],
      allData: [],
      equipes: [],
      selectedEquipe: undefined,
      selectedCommercial: undefined
    };
  }

  componentDidMount() {
    let without = { name: 'Sans commercial', id: 'EMPTY' };
    const onlyCommerciaux = this.props.commerciaux;
    this.setState({ commerciaux: [without].concat(onlyCommerciaux) });
    this.loadData();

    getEquipes()
      .then(res => this.setState({ equipes: res.data }))
      .catch(err => console.log(err));
  }

  loadData = async () => {

    service
      .getReports(`${PROSPECT},${DEAL_POSSIBLE},${A_RAPPELER_PLUS_TARD},${DEAL_POSSIBLE_REMPLI},${DEVIS},${DEVIS_VALIDE},${CONTRAT_ENVOYE},${EN_ATTENTE_DE_RESILIATION},${EN_ATTENTE_DE_FORMALITE_MEDICALE},${EN_ATTENTE_BANQUE}`, this.state.filter).then((res) => {

        const _allData = res.data;
        const sources = _allData.map(d => d.source);
        const reports = buildReports(_allData, this.state.filter);
        this.setState({
          ...reports,
          sources: [...new Set(sources), "(Tous)"].map(v => ({ text: v ? v : '(Vide)', value: v })),
          allData: _allData
        })

      });

  };

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    if (value === "Tous") filter[field] = "";
    this.setState({ filter: Object.assign({}, filter) }, () => this.loadData());
  };

  render() {
    return (
      <Box
        style={{ width: "100%" }}
        margin="medium"
        pad="medium"
        gap="medium"
        justify="center"
        alignContent="center"
        alignSelf="center"
        align="center"
      >
        <Card
          width="xxlarge"
          justify="around"
          margin="medium"
          pad="small"
          direction="row"
        >
          <PrimaryButton icon={<Refresh />} reverse
            onClick={() => {
              this.setState(_DEFAULT_STATE);
              this.loadData();
            }
            }
          />
          <Box gap="xsmall">
            <Text>Select d'un mois</Text>
            <CustomSelect
              options={getMonthOptions().filter(
                (s) => s.name !== "12 derniers mois"
              )}
              labelKey={"name"}
              valueKey={{ key: "name", reduce: true }}
              onChange={({ option }) => {
                let filter = this.state.filter;
                filter.startDate = option.startDate;
                filter.endDate = option.endDate;
                filter.sort = "Produit";
                this.setState(
                  {
                    filter: Object.assign({}, filter),
                    selectedMonth: option.name,
                  },
                  () => this.loadData()
                );
              }}
            />
          </Box>
          <Box gap="xsmall">
            <Text>Produit</Text>
            <CustomSelect
              options={this.props.products || products}
              onChange={({ option }) => this.updateFilter("product", option)}
            />
          </Box>
          <Box gap="xsmall">
            <Text>Source</Text>
            <CustomSelect
              options={this.state.sources}
              labelKey={"text"}
              valueKey={{ key: "text", reduce: true }}
              onChange={({ option }) => {
                let source = option.value;
                let allData = this.state.allData.filter(d => source === 'Tous' || d.source === source);
                this.setState(buildReports(allData, this.state.filter));
              }}
            />
          </Box>
          <Box gap="xsmall">
            <Text>Nb Appel</Text>
            <CustomSelect
              options={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
              onChange={({ option }) => this.updateFilter("nbr_appel", option)}
            />
          </Box>
          {(hasClassiqueManagerRole() || hasAdministrativeRole()) ? (
            <Box gap="xsmall">
              <Text>Equipe</Text>
              <CustomSelect
                options={this.state.equipes}
                value={this.state.selectedEquipe}
                onChange={({ option }) => {
                  this.setState({ selectedEquipe: option?.name, selectedCommercial: undefined });
                  if (!option) {
                    this.updateFilter("commercial_ids", "All");
                    return;
                  }
                  let ids = [];
                  option.membres.forEach(m => ids.push(m.id));
                  this.updateFilter("commercial_ids", ids)
                }}
                labelKey={'name'}
                valueKey={{ key: 'name', reduce: true }}
                clear
              />
            </Box>
          ) :
            (getUser()?.binome_id ? <Box gap="xsmall">
              <Text>Binôme</Text>
              <CustomSelect
                options={[
                  { id: getUser().id, name: 'Vous' },
                  { id: getUser().binome_id, name: 'Votre binôme' },
                  { id: 'All', name: 'Tous' }
                ]}
                onChange={({ value: nextValue }) => {
                  let ids = nextValue === 'All' ? [getUser().id, getUser().binome_id] : [nextValue];
                  this.updateFilter("commercial_ids", ids);
                }}
                labelKey={'name'}
                valueKey={{ key: 'id', reduce: true }}
                value={
                  this.state.filter.commercial_ids?.length === 2 ? 'All' : 
                  this.state.filter.commercial_ids?.length === 1 ? this.state.filter.commercial_ids[0] : undefined
                }
              />
            </Box> : null)}
          {this.state.commerciaux && !this.props.personal && !this.state.selectedEquipe && (
            <Box gap="xsmall">
              <Text>Commercial</Text>
              <CustomSelect
                options={this.state.commerciaux}
                value={this.state.selectedCommercial}
                onChange={({ option }) => {
                  this.setState({ selectedCommercial: option?.name, selectedEquipe: undefined })
                  if (!option?.id) {
                    this.updateFilter("commercial_ids", "All");
                    return;
                  }
                  this.updateFilter("commercial_ids", [option.id])
                }}
                labelKey={"name"}
                valueKey={{ key: "name", reduce: true }}
                clear
              />
            </Box>
          )}
        </Card>

        <Box direction="column" gap="medium" justify="center" width={'full'}>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.prospects.reports}
              status={this.state.prospects.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'prospect'}
              refreshReports={this.loadData}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.deals.reports}
              status={this.state.deals.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'deal_possible'}
              refreshReports={this.loadData}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.dealsRappeler.reports}
              status={this.state.dealsRappeler.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'deal_possible'}
              refreshReports={this.loadData}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.dealsRempli.reports}
              status={this.state.dealsRempli.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'deal_rempli'}
              refreshReports={this.loadData}
            />
          </Card>

          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.devisList.reports}
              status={this.state.devisList.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'devis'}
              refreshReports={this.loadData}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.devisValidedList.reports}
              status={this.state.devisValidedList.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'devis_valide'}
              refreshReports={this.loadData}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.waitingMedicalList.reports}
              status={this.state.waitingMedicalList.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'formalite_medicale'}
              refreshReports={this.loadData}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.waitingResiliationList.reports}
              status={this.state.waitingResiliationList.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'resiliation'}
              refreshReports={this.loadData}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.contratEnvoyedList.reports}
              status={this.state.contratEnvoyedList.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'contrat'}
              refreshReports={this.loadData}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.enAttenteBanqueList.reports}
              status={this.state.enAttenteBanqueList.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              refreshReports={this.loadData}
            />
          </Card>
        </Box>
      </Box>
    );
  }
}

export default Reports;

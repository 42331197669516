import { Box, Card, Grid, Menu } from "grommet";
import { Previous, Send, Update } from "grommet-icons";
import React from "react";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { uploadFilesMulti } from "../../../../services/storage_service";
import { Container } from "../../../elements/refont/containers/text-containser";
import RoundedButton from "../../../elements/refont/rounded-button";
import { TextLabel } from "../../../elements/refont/text-label";
import { sendSinistreForm, updateSinistre } from "../service";
import InfoRemboursement from "./calcul_remboursement/annulation_v2";
import TableauRemboursement from "./calcul_remboursement/remboursement";
import TableDommage from "./calcul_remboursement/table-dommage";
import { generateRBTRef } from "./calcul_remboursement/utils";
import { AssureInfo, ContratInfo, InfoBancaire, SinistreInfo } from "./components";
import { InfoDocuments } from "./ged_v2";
import GarantieDommageImmobilier from "./sinistre-type/garantie-dommage-immobilier";
import RemboursementDommage from "./calcul_remboursement/remboursement-dommage";



export default function SinistreV2({
    sinistre,
    onReturn,
    onSinistreChange,
    showMessage,
}) {
    const [loading, setLoading] = React.useState(false);
    const handleSave = (values, postSave) => {
        setLoading(true)
        // pre generate refRBT
        generateRBTRef(values);
        updateSinistre(values)
            .then(res => {
                onSinistreChange(res.data);
                showMessage('Sinistre a été mis à jour avec succès', 'success');
                if (postSave) postSave();
                setLoading(false);
            })
            .catch(err => {
                showMessage('Erreur inattendu', 'error')
                setLoading(false);
            });
    }

    const sendEmail = () => {
        setLoading(true);
        sendSinistreForm(sinistre)
            .then(_ => {
                showMessage('Email a été envoyé avec succès', 'success');
                setLoading(false);
            })
            .catch(_ => {
                showMessage('Erreur inattendu', 'error');
                setLoading(false);
            });

    }

    const handleUploadFiles = (files, callback) => {
        setLoading(true);
        uploadFilesMulti(files)
            .then((result) => {
                console.log("result = ", result.data);
                callback(result.data);
                setLoading(false);
            })
            .catch((err) => {
                showMessage("Erreur inattendu télécharger.", "error");
                callback([]);
                setLoading(false);
            });
        // }
    };

    const changeStatus = (status) => {
        sinistre.status = status;
        handleSave(sinistre);
    }
    return (
        <BlockUi tag="div" blocking={loading}>
            <Container>
                <Card background={'white'} direction={'row'} justify="between" align="center"
                    pad="xsmall" gap="small" elevation="xsmall" margin={{ bottom: "small" }}
                >
                    <TextLabel variant={"header3"} label={`SINISTRE - ${sinistre.reference}`} />
                    <Box direction="row" justify="center" align="center">
                        <TextLabel label={`Satut sinistre : `} variant={"body1"} />&nbsp;
                        <Box background={"#28a745"} pad={"xsmall"} round="xsmall">
                            <TextLabel label={sinistre.status} variant={"body1"} color={"white"} />
                        </Box>
                    </Box>
                </Card>
                <Box direction={'row'} justify="between" align="center"
                    pad="xsmall" gap="small" margin={{ bottom: "small" }}
                >
                    <RoundedButton
                        icon={<Previous size="small" />}
                        label={"Retour"}
                        onClick={() => onReturn()}
                    />
                    <Box direction="row" justify="center" align="center" gap="xsmall">
                        <RoundedButton
                            icon={<Send size="small" />}
                            label={"Envoie email"}
                            onClick={() => sendEmail()}
                        />

                        <Menu
                            dropProps={{
                                align: { top: 'bottom', left: 'left' },
                            }}
                            items={
                                [
                                    "Clos refus",
                                    "Clos terminé",
                                    "En remboursement compagnie",
                                    "À rembourser",
                                    "En cours d’analyse",
                                    "En cours d’analyse terminée"
                                ].map((s, i) => ({
                                    label: <TextLabel variant={'body1'} key={i} label={s} />,//<Text size="small" key={i}>{s}</Text>,
                                    onClick: () => changeStatus(s)
                                }))
                            }
                        >
                            <Box direction="row" align="center"
                                justify="center" gap="small">
                                <RoundedButton
                                    icon={<Update size="small" />}
                                    label={"Change statut"}
                                />
                            </Box>
                        </Menu>
                    </Box>
                </Box>


                <Box gap="small" fill>
                    <Grid columns={{
                        count: 3,
                        size: 'auto'
                    }}
                        gap={'small'}
                    >
                        <Box gap="small">
                            <SinistreInfo sinistre={sinistre}
                                onChangeSubmit={handleSave}
                            />
                            <InfoDocuments
                                sinistre={sinistre}
                                onUploadFiles={handleUploadFiles}
                                onChangeSubmit={handleSave}
                            />
                        </Box>
                        <Box gap="small">
                            <AssureInfo
                                sinistre={sinistre}
                                onChangeSubmit={handleSave}
                            />
                            <InfoBancaire
                                sinistre={sinistre}
                                onChangeSubmit={handleSave}
                            />
                        </Box>
                        <Box gap="small">
                            <ContratInfo
                                sinistre={sinistre}
                                onChangeSubmit={handleSave}
                            />
                            {sinistre.type === "Dommage mobilier" && (
                                <GarantieDommageImmobilier sinistre={sinistre} onChangeSubmit={handleSave} />)}
                        </Box>
                    </Grid>
                    {sinistre.type === "Dommage mobilier" && (
                        <TableDommage sinistre={sinistre}
                            onChangeSubmit={handleSave}
                        />
                    )}
                    {sinistre.type === "Dommage mobilier" && (
                        <RemboursementDommage
                            sinistre={sinistre}
                            onChangeSubmit={handleSave}
                        />
                    )}

                    <Grid
                        columns={['1/3', '2/3']}
                        gap={'small'}
                    >
                    {sinistre.type === "Annulation de séjour" && (
                        <Grid
                            columns={['1/3', '2/3']}
                            gap={'small'}
                        >
                            <Box key={1}>
                                <InfoRemboursement
                                    sinistre={sinistre}
                                    onChangeSubmit={handleSave}
                                />
                            </Box>
                            <Box key={2}>
                                <TableauRemboursement
                                    sinistre={sinistre}
                                    onChangeSubmit={handleSave}
                                />
                            </Box>

                        </Grid>)}
                    </Grid>
                </Box>
            </Container>
        </BlockUi>
    )
}
import {
    Box,
    Menu, Text
} from "grommet";
import { Archive } from "grommet-icons";
import React from 'react';
import { modifyDeal } from "../../../services/common_service";
import PrimaryButton from "../primary_button";
import { EDITION, EN_ATTENTE_DE_FORMALITE_MEDICALE, ETUDE } from "../../../services/devis_contrat_status";

const INACTIVE_STATUS = [
    "À rappeler plus tard",
    "Résilié",
    "Archivé",
    "Perdu"
];

const INACTIVE_STATUS_EMPRUNTEUR = [
    "À rappeler plus tard",
    ETUDE,
    EN_ATTENTE_DE_FORMALITE_MEDICALE,
    EDITION,
    "Résilié",
    "Archivé",
    "Perdu"
]

const DealStatusChange = ({ deal, path, onReload, showMessage }) => {
    const changeStatus = s => {
        if (s === 'Résilié') {
            deal.date_resiliation = new Date();
        }
        deal.status = s;
        modifyDeal(deal, path)
            .then(res => {
                showMessage('Statut changé avec succès', 'success');
                onReload();
            })
            .catch(err => {
                console.log(err);
                showMessage('Error inattendu', 'error');
            })
    }
    return (
        <Menu
            dropProps={{
                align: { top: 'bottom', left: 'left' },
            }}
            items={
                (path === "emprunteur-horsligne" ? INACTIVE_STATUS_EMPRUNTEUR : INACTIVE_STATUS).map((s, i) => ({
                    label: <Text size="small" color={'#707070'} key={i}>{s}</Text>,
                    onClick: () => changeStatus(s)
                }))
            }
        >
            <Box direction="row" align="center"
                justify="center" gap="small">
                <PrimaryButton background="#000" icon={<Archive />} label={'Change statut'} />
            </Box>
        </Menu>
    )
}

export default DealStatusChange;

export {
    INACTIVE_STATUS
}

import { Box, Card, Text } from 'grommet';
import React from 'react';
import {
    PROSPECT,
    NOUVEAU,
    NOUVEAU_REMPLI,
    DOSSIER_EN_INSTRUCTION,
    DOSSIER_ENVOYE_A_LA_BANQUE,
    PRET_ACCORDE_PAR_LA_BANQUE,
    OFFRE_ACCEPTE_PAR_CLIENT,
    REALISE,
    FACTURE,
    ABONDONS,
    PERDU,
    ARCHIVE,
    EN_ETUDE_DE_ANALYSTE,
    EN_ETUDE_DE_PRE_ANALYSTE
} from '../../../../services/credit_status';
import CustomSelect from '../../../elements/select_fill';
import { A_RAPPELER_PLUS_TARD, CONTRAT_CLOTURE, RESILIE } from '../../../../services/devis_contrat_status';

const CreditStatusSearch = ({ search, status }) => {

    return (
        <Box direction='row' gap='small'
            alignSelf="center" style={{ width: '100%' }}>
            <Card pad="xsmall" justify="center" alignContent="center"
                gap="small"
                align="center" direction="row">
                <Text size="xsmall">Filtre par phase</Text>
                <CustomSelect
                    size="xsmall"
                    options={[
                        PROSPECT,
                        NOUVEAU,
                        NOUVEAU_REMPLI,
                        A_RAPPELER_PLUS_TARD,
                        DOSSIER_EN_INSTRUCTION,
                        EN_ETUDE_DE_PRE_ANALYSTE,
                        EN_ETUDE_DE_ANALYSTE,
                        DOSSIER_ENVOYE_A_LA_BANQUE,
                        PRET_ACCORDE_PAR_LA_BANQUE,
                        OFFRE_ACCEPTE_PAR_CLIENT,
                        REALISE,
                        FACTURE,
                        CONTRAT_CLOTURE,
                        ABONDONS,
                        PERDU,
                        ARCHIVE,
                        RESILIE
                    ]}
                    value={status}
                    onChange={({ option }) => search(option)}
                    clear
                />
            </Card>
        </Box>
    )
}
export default CreditStatusSearch;
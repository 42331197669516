import { Box, FileInput, TextArea } from 'grommet';
import { AddCircle, CaretPrevious, Save } from 'grommet-icons';
import React, { useState } from "react";
import { uploadFile } from '../../../../services/storage_service';
import AddressAutoComplet from "../../../elements/address_autocomplete";
import CustomInput from '../../../elements/custom_input';
import DateInput from '../../../elements/date_input';
import PrimaryButton from '../../../elements/primary_button';
import CustomSelect from '../../../elements/select_fill';
import StandardText from "../../../elements/standard_text";
import ToggleButton from '../../../elements/toggle_button/toggle_button';
import SectionDivider from "../../../voyages/components/session_devider";

const doUploadFile = (files, callback, showError) => {
    const fileList = files;
    if (fileList) {
        for (let i = 0; i < fileList.length; i += 1) {
            const file = fileList[i];
            console.log(file.name);
            uploadFile(file)
                .then((result) => {
                    console.log("result = ", result.data);
                    callback(result.data);
                })
                .catch((err) => {
                    showError("Erreur inattendu télécharger.");
                    callback([]);
                });
        }
    }
};

const Info = ({ deal, onDealChange, showMessage, prev, save }) => {

    return (
        <Box
            style={{ width: "100%" }}
            justify="center"
            alignContent="center"
            align="center"
        >
            <Box
                width={'large'}
                justify="center"
                alignContent="center"
                align="center"
                gap="medium"
            >

                <Box >
                    <StandardText style={{ fontWeight: 'bold' }} label='Type de produit' />
                    <SectionDivider />
                </Box>
                <Box gap="xxsmall" align="center" width={'full'}>
                    <Line
                        left={<StandardText size={'small'} label={'Produit'} />}
                        right={
                            <CustomSelect
                                size={'small'}
                                options={[
                                    "Trusti Obsèque",
                                    "Trusti Décès",
                                    "Trusti Accident de la vie",
                                    "Trusti Dépandance",
                                    "Trusti Hospi",
                                    "Trusti Protection Juridique"
                                ]}
                                value={deal.sub_product}
                                onChange={({ option }) => onDealChange('sub_product', option)}
                            />
                        }
                    />
                </Box>

                <Box >
                    <StandardText style={{ fontWeight: 'bold' }} label='Renseigner informations' />
                    <SectionDivider />
                </Box>

                <Box gap="xxsmall" align="center" width={'full'}>
                    <Line
                        left={<StandardText size={'small'} label={"Date d’effet souhaité"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_effet}
                                onChange={(date) => onDealChange("date_effet", date)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Date de résiliation"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_resiliation}
                                onChange={(date) => onDealChange("date_resiliation", date)}
                            />
                        }
                    />
                    <LineInput label="Code postal"
                        value={deal.code_postal}
                        onDealChange={val => onDealChange('code_postal', val)}
                    />
                    <Line
                        left={<StandardText size={'small'} label={'Regime'} />}
                        right={
                            <CustomSelect
                                size={'small'}
                                options={[
                                    "Salarié",
                                    "Retraité salarié",
                                    "TNS indépendant",
                                    "Retraité TNS",
                                    "Exploitant agricole",
                                    "Salarié agricole",
                                    "Alsace moselle",
                                    "Sans emploi",
                                    "Fonctionnaire",
                                    "Etudiant",
                                    "Enseignant",
                                    "Expatrié"
                                ]}
                                value={deal.regime}
                                onChange={({ option }) => onDealChange('regime', option)}
                            />
                        }
                    />
                    <LineToggle label={'Souhaitez-vous ajouter un bénéficiaire ? '}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: value ? deal.beneficiaires && deal.beneficiaires.length >= 1 :
                                    value === false ? deal.beneficiaires && deal.beneficiaires.length === 0 : false,
                                onClick: () => {
                                    let r = value ? onDealChange('beneficiaires', [{}]) : onDealChange('beneficiaires', [])
                                }
                            }
                        ))}
                    />
                    {deal.beneficiaires && deal.beneficiaires.length ?
                        <Beneficiaires
                            beneficiaires={deal.beneficiaires}
                            onDealChange={onDealChange}
                        /> : null
                    }
                    <Box pad={'small'}>
                        <FileInput
                            size={'xxsmall'}
                            messages={{
                                browse: "Choisir",
                                dropPrompt: "Ajouter un autre document",
                            }}
                            multiple={false}
                            onChange={(event) => {
                                doUploadFile(
                                    event.target.files,
                                    async (data) => {
                                        if (data.length > 0) {
                                            let _uploaded = data[0];
                                            let _files = deal.file_others || [];
                                            _files.push(_uploaded.url);
                                            onDealChange("file_others", _files);
                                        }
                                    },
                                    mes => showMessage(mes, 'error')
                                );
                            }}
                        />
                    </Box>
                </Box>

                <Box pad={'medium'} direction='row' gap="medium">
                    < PrimaryButton icon={<CaretPrevious />} label='Retour' background={'#707070'} onClick={prev} />
                    < PrimaryButton icon={<Save />} label='Enregistrer' onClick={save} />
                </Box>
            </Box>
        </Box>
    )
}

const Beneficiaires = ({ beneficiaires, onDealChange }) => {

    const onBeChange = (be, field, value) => {
        be[field] = value;
        onDealChange('beneficiaires', beneficiaires.map(i => i));
    }

    const addBeneficiaire = () => {
        beneficiaires.push({});
        onDealChange('beneficiaires', beneficiaires.map(i => i));
    }

    return (
        <Box width={'full'} gap='xsmall'>
            {beneficiaires.map(
                be => (
                    <Box width={'full'} gap='xsmall'>
                        <LineToggle label={'Type de bénéficiaire'}
                            groups={["Conjoint", "Enfant"].map(value => (
                                {
                                    label: value,
                                    selected: be.type_beneficiaire === value,
                                    onClick: () => onBeChange(be, 'type_beneficiaire', value),
                                }
                            ))}
                        />
                        <Line
                            left={<StandardText size={'small'} label={"Date de naissance"} />}
                            right={
                                <DateInput size={'small'}
                                    value={be.beneficiaire_date_naissance}
                                    onChange={(date) => onBeChange(be, "beneficiaire_date_naissance", date)}
                                />
                            }
                        />
                    </Box>
                )
            )}
            <Box direction='row' gap='small' onClick={addBeneficiaire}>
                <AddCircle color='red' />
                <StandardText size={'small'} label='Ajouter un autre bénéficiaire' />
            </Box>
        </Box>
    )
}

const Line = ({ left, right }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                {left}
            </Box>
            <Box width={'full'}>
                {right}
            </Box>
        </Box>
    )
}
const LineInput = ({ label, placeholder, value, onDealChange }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <CustomInput size='xsmall' placeholder={placeholder}
                    value={value}
                    onChange={event => onDealChange(event.target.value)}
                />
            </Box>
        </Box>
    )
}
const LineToggle = ({ label, groups = [] }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <ToggleButton
                    groups={groups}
                />
            </Box>
        </Box>
    )
}
export default Info;
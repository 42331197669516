const axios = require('axios');
const storageService = require('./storage_service');
const { baseAPI } = require('../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};


const getObjectifCADayData = (startDate, endDate, commercial_ids, produits) => {
    return axios.get(`${baseAPI}/objectif_ca_day`, { params: { startDate, endDate, commercial_ids, produits }, headers: HEADERS });
}

const getIndicator = (startDate, endDate, commercial_ids, produits) => {
    return axios.get(`${baseAPI}/dashboard`, { params: { startDate, endDate, commercial_ids, produits }, headers: HEADERS });
}

const getObjectifDayData = (startDate, endDate, commercial_ids, statuts) => {
    let params = { startDate, endDate, commercial_ids, statuts };
    if(!commercial_ids){
        delete params.commercial_ids;
    }
    if(!statuts){
        delete params.statuts;
    }
    return axios.get(`${baseAPI}/objectif_day`, { params, headers: HEADERS });
}

const getReports = (status, filter) => {
    return axios.get(`${baseAPI}/reports`, { params: { status, ...filter }, headers: HEADERS });
}

const getReportsAll = (filter) => {
    return axios.get(`${baseAPI}/reports`, { params: { ...filter }, headers: HEADERS });
}

const getCommission = (filter, commercial_id) => {
    return axios.get(`${baseAPI}/commission`, { params: { commercial_id, ...filter }, headers: HEADERS });
}

const getCommissionTauxConfig = () => {
    return axios.get(`${baseAPI}/users/self/commission-taux`, { headers: HEADERS });
}

const getUsers = (query) => {
    let params = {_q: query};
    return axios.get(`${baseAPI}/users`, { params, headers: HEADERS });
}

const getGestionCollabs = () => {
    return axios.get(`${baseAPI}/managed-collaborateurs`, { headers: HEADERS });
}

const getSourceList = () => {
    return axios.get(`${baseAPI}/sources`, { headers: HEADERS });
}
const changDealOwner = (deal, newCommercialId) => {
    return axios.post(`${baseAPI}/commerciales/deal/replace`, {deal, newCommercialId}, { headers: HEADERS });
}

module.exports = {
    getIndicator,
    getObjectifCADayData,
    getReports,
    getUsers,
    getGestionCollabs,
    getCommission,
    getCommissionTauxConfig,
    getReportsAll,
    getObjectifDayData,
    getSourceList,
    changDealOwner,
}
import { Box, Button, Card, DataTable, Layer, Text } from 'grommet';
import { Add } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { getSimpleDateTimeFR, toCurrency } from '../../../../services/utils';
import AddDevis from '../../../elements/add_devis';
import { CustomInputV2 } from '../../../elements/custom_input';
import CustomPagination from '../../../elements/custom_pagination';
import Refresh from '../../../elements/refresh';
import CustomSelect from '../../../elements/select_fill';
import products from '../../utils/types';
import { countGaranties, createGarantie, getCompanies, getGaranties, getVestute, saveVestuste } from '../service';

class Vestute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            vestute: {},
            selected: null,
        }
    }

    componentDidMount() {
        this.loadVestute();
    }

    loadVestute = () => {
        getVestute().then((res) => {
            if(res.data.length > 0) {
                this.setState({ vestute: res.data[0] });
            }
                
        }).catch((err) => {
            console.log(err);
        })
    }

    render() {
        return (
            <Box style={{ width: "100%" }}
                align="center" justify="center"
                alignSelf="center"
                alignContent="center" gap="medium">
                <Box style={{ width: "100%" }} gap="small" pad={"smal"} justify='center' align='center'>
                    <Box direction='row' justify="start" alignSelf="start" align="start" gap="medium" width={'full'}
                    >
                        <AddDevis onAdd={() => this.setState({ selected: {} })} />
                    </Box>
                    
                    <DataTable
                        columns={[
                            {
                                property: 'equipement',
                                header: <Text size="small">Equipement</Text>,
                                render: vest => <Text size="small">{vest.equipement}</Text>
                            },
                            {
                                property: 'dure_vie',
                                header: <Text size="small">Durée de vie</Text>,
                                render: vest => <Text size="small">{vest.dure_vie}</Text>
                            },
                            {
                                property: 'franchise_en_ans',
                                header: <Text size="small">Franchise en ans</Text>,
                                render: vest => <Text size="small">{vest.franchise_en_ans}</Text>
                            },
                            {
                                property: 'abattement',
                                header: <Text size="small">Abattement/an</Text>,
                                render: vest => <Text size="small">{vest.abattement}</Text>
                            },
                            {
                                property: 'residuelle',
                                header: <Text size="small">Résiduelle</Text>,
                                render: vest => <Text size="small">{vest.residuelle}</Text>
                            },
                            {
                                header: '',
                                render: vest => <Button label="voir" primary onClick={() => this.setState({ selected: vest })} />
                            },
                        ]}
                        data={(this.state.vestute?.value || [])}
                    />
                </Box >

                {this.state.selected && <EditVestite
                    selected={this.state.selected}
                    onClose={() => this.setState({ selected: null })}
                    onSaved={(vestute) => {
                        this.setState({ vestute: vestute });
                    }}
                    showMessage={this.props.showMessage}
                    vestute={this.state.vestute}
                />}
            </Box >
        );
    }
}

// edit vestite
const EditVestite = ({ vestute, selected, onClose, onSaved, showMessage }) => {
    const [values, setValues] = useState(selected);

    const save = () => {
        if(!values.id) {
            values.id = Math.random().toString(36).substring(7);
        }
        const value = vestute.value || [];
        const index = value.findIndex((v) => v.id === selected.id);
        if (index > -1) {
            value[index] = values;
        } else {
            value.push(values);
        }
        saveVestuste({...vestute, value}).then((res) => {
            onSaved(res.data);
            onClose();
        })
        .catch((err) => {
            showMessage("Erreur lors de l'enregistrement",'error' );
        });
    }

    return(
        <Layer
            onEsc={onClose}
            onClickOutside={onClose}
        >
            <Box pad="medium" gap="small">
                <Text size="large" >Vestute</Text>
                <LineColumn
                    leftLabel="Equipement"
                    rightChildren={<CustomInputV2
                        value={values.equipement}
                        onChange={(e) => setValues({ ...values, equipement: e.target.value })}
                    />}
                />
                <LineColumn
                    leftLabel="Durée de vie"
                    rightChildren={<CustomInputV2
                        value={values.dure_vie}
                        onChange={(e) => setValues({ ...values, dure_vie: e.target.value })}
                    />}
                />
                <LineColumn
                    leftLabel="Franchise en ans"
                    rightChildren={<CustomInputV2
                        value={values.franchise_en_ans}
                        onChange={(e) => setValues({ ...values, franchise_en_ans: e.target.value })}
                    />}
                />
                <LineColumn
                    leftLabel="Abattement/an"
                    rightChildren={<CustomInputV2
                        value={values.abattement}
                        onChange={(e) => setValues({ ...values, abattement: e.target.value })}
                    />}
                />
                <LineColumn
                    leftLabel="Résiduelle"
                    rightChildren={<CustomInputV2
                        value={values.residuelle}
                        onChange={(e) => setValues({ ...values, residuelle: e.target.value })}
                    />}
                />
                <Box direction="row" gap="small" justify="end">
                    <Button label="Enregistrer" primary onClick={save} />
                    <Button label="Annuler" onClick={onClose} />
                </Box>
            </Box>
        </Layer>
    )
}



const LineColumn = ({ leftLabel, rightChildren }) => (
    <Box gap="xxsmall" style={{ width: '100%' }} >
        <Text size="small" >{leftLabel} :</Text>
        {rightChildren}
    </Box>
)


export default Vestute;
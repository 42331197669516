import { Box, CheckBox, FileInput, Text, TextArea } from 'grommet';
import { CaretPrevious, DocumentDownload, Save } from 'grommet-icons';
import React from "react";
import { uploadFile } from '../../../../services/storage_service';
import AddressAutoComplet from "../../../elements/address_autocomplete";
import CustomInput from '../../../elements/custom_input';
import DateInput from '../../../elements/date_input';
import PrimaryButton from '../../../elements/primary_button';
import CustomSelect from '../../../elements/select_fill';
import StandardText from "../../../elements/standard_text";
import ToggleButton from '../../../elements/toggle_button/toggle_button';
import SectionDivider from "../../../voyages/components/session_devider";

const doUploadFile = (files, callback, showError) => {
    const fileList = files;
    if (fileList) {
        for (let i = 0; i < fileList.length; i += 1) {
            const file = fileList[i];
            console.log(file.name);
            uploadFile(file)
                .then((result) => {
                    console.log("result = ", result.data);
                    callback(result.data);
                })
                .catch((err) => {
                    showError("Erreur inattendu télécharger.");
                    callback([]);
                });
        }
    }
};

const Info = ({ deal, onDealChange, showMessage, prev, save }) => {

    return (
        <Box
            style={{ width: "100%" }}
            justify="center"
            alignContent="center"
            align="center"
        >
            <Box
                width={'large'}
                justify="center"
                alignContent="center"
                align="center"
                gap="medium"
            >

                <Box >
                    <SectionDivider title={'Informations souscripteur'} />
                </Box>

                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                    <LineSelect label="Le souscripteur est"
                        value={deal.souscripteur_type}
                        options={[
                            "Un syndic professionnel",
                            "Une société",
                            "Un administrateur de bien",
                            "Un syndic bénévole",
                            "Un particulier"
                        ]}
                        onDealChange={option => onDealChange('souscripteur_type', option)}
                    />
                    {deal.souscripteur_type === "Un syndic professionnel" ||
                        deal.souscripteur_type === "Une société" ||
                        deal.souscripteur_type === "Un administrateur de bien" ?
                        <LineInput label="Raison social"
                            value={deal.souscripteur_raison_social}
                            onDealChange={val => onDealChange('souscripteur_raison_social', val)}
                        /> : null
                    }
                    <LineSelect label="Civilité"
                        value={deal.souscripteur_civilite}
                        options={[
                            "Madame", "Monsieur"
                        ]}
                        onDealChange={option => onDealChange('souscripteur_civilite', option)}
                    />
                    <LineInput label="Prénom"
                        value={deal.souscripteur_prenom}
                        onDealChange={val => onDealChange('souscripteur_prenom', val)}
                    />
                    <LineInput label="Nom"
                        value={deal.souscripteur_nom}
                        onDealChange={val => onDealChange('souscripteur_nom', val)}
                    />
                </Box>
                <Box >
                    <SectionDivider title={'Informations assuré'} />
                </Box>
                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                    <LineSelect label="La qualité de l’assuré"
                        value={deal.qualite_assure}
                        options={[
                            "Syndicat de copropriétaires",
                            "Propriétaire Unique Non Occupant",
                            "ASL",
                            "AFUL"
                        ]}
                        onDealChange={option => onDealChange('qualite_assure', option)}
                    />
                    <LineInput label="Quel est le nom de la copropriété ?"
                        value={deal.copropriete_nom}
                        onDealChange={val => onDealChange('copropriete_nom', val)}
                    />
                    <Box width={'full'}>
                        <StandardText size={'small'} label="Quelle est l'adresse de la copropriété ?" />
                    </Box>
                    <AddressAutoComplet
                        contact={deal}
                        updateContact={onDealChange}
                        direction='in-line'
                    />
                </Box>
                <Box >
                    <SectionDivider title={'Informations du risque'} />
                </Box>
                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                    <LineSelect label="Quel est l’usage global de la copropriété ?"
                        value={deal.usage_global_copropriete}
                        options={[
                            "Habitation",
                            "Habitation (et commerces et bureaux <25%)",
                            "Habitation (et commerces et bureaux >25%)",
                            "100% Bureaux",
                            "100% Commerces",
                            "Résidence hôtelière/hôtel",
                            "Résidence étudiante",
                            "Résidences de vacances",
                            "Autres"
                        ]}
                        onDealChange={option => onDealChange('usage_global_copropriete', option)}
                    />
                    <LineSelect label="Quand le bâtiment a-t-il été construit ?"
                        value={deal.quand_construit}
                        options={[
                            "Moins de 3 ans",
                            "Plus de 3 ans et moins de 10 ans",
                            "Plus de 10 ans et moins de 30 ans"
                        ]}
                        onDealChange={option => onDealChange('quand_construit', option)}
                    />

                    <LineInput label="Superficie totale développée"
                        value={deal.surface_total}
                        onDealChange={val => onDealChange('surface_total', val)}
                    />
                    <LineInput label="Dont surface commerciale"
                        value={deal.surface_commerciale}
                        onDealChange={val => onDealChange('surface_commerciale', val)}
                    />
                    <LineInput label="Dont surface pour les bureaux"
                        value={deal.surface_bureaux}
                        onDealChange={val => onDealChange('surface_bureaux', val)}
                    />
                    <LineSelect label="De quel type de copropriété s’agit-il ?"
                        value={deal.type_copropriete}
                        options={[
                            "Immeuble de copropriété",
                            "Copropriété horizontale",
                            "Immeuble locatif avec occupant unique",
                            "Immeuble locatif avec occupants multiples",
                            "Château",
                            "Parkings seuls"
                        ]}
                        onDealChange={option => onDealChange('type_copropriete', option)}
                    />
                    <LineSelect label="La copropriété est-elle occupée … ?"
                        value={deal.copropriete_occupe_par}
                        options={[
                            "Par des copropriétaires et des locataires",
                            "En totalité par des copropriétaires",
                            "En totalité par des locataires",
                            "Partiellement par le propriétaire",
                            "En cours de rénovation",
                            "Vide ou désaffectée",
                            "Je ne sais pas"
                        ]}
                        onDealChange={option => onDealChange('copropriete_occupe_par', option)}
                    />
                    <LineSelect label="*Quelle est la nature des activités commerciales ?"
                        value={deal.activite_commerciale}
                        options={[
                            "Activités politiques, syndicales ou religieuses",
                            "Agence bancaire",
                            "Ambassade, Consulats",
                            "Blanchisserie, Pressing, Teinturerie",
                            "Bureaux",
                            "Garagiste, carrossier, vente et monte de pneumatique, atelier de réparation auto & associés",
                            "Supérette ou supermarché > 1000 m²",
                            "Commerce de droguerie, peintures",
                            "Commerces, supérettes < 1000 m²",
                            "Dépôt vente ou garde de meuble, entrepôts",
                            "Discothèques, clubs privés, bars à fermetures tardives avec ou sans pistes de danses, casinos, Cabarets, bars à thème, bowling ou établissements de nuit",
                            "Bijoutier, Joaillier, Magasin de négoces de métaux précieux",
                            "Menuiserie, Ebénisterie, travaux et stockage de bois, papier, carton, matières inflammables",
                            "Restaurant traditionnel, pizzeria hors restauration rapide (sandwich, burger, pizza, kebab et tacos)",
                            "Stations-service",
                            "Travaux métaux, vêtements : confection, stockage, fourrures",
                            "Autres"
                        ]}
                        onDealChange={option => onDealChange('activite_commerciale', option)}
                    />

                    <LineToggle label={'Y a-t-il des activités aggravantes dans la copropriété et/ou aux abords immédiats (à moins de 10 m) de la copropriété assurée ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.activite_aggravante === value,
                                onClick: () => onDealChange('activite_aggravante', value),
                            }
                        ))}
                    />

                    <LineToggle label={'La copropriété est-elle située à moins de 5 km d’un risque majeur ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.moins_5km === value,
                                onClick: () => onDealChange('moins_5km', value),
                            }
                        ))}
                    />

                    <LineToggle label={'Comment qualifiez-vous la catégorie de la copropriété ?'}
                        groups={["Inférieure à 28m", "Entre 28m et 50m", "Supérieure à 50m"].map(value => (
                            {
                                label: value,
                                selected: deal.categorie_copropriete === value,
                                onClick: () => onDealChange('categorie_copropriete', value),
                            }
                        ))}
                    />

                    <LineToggle label={'La copropriété ou des parties de la copropriété sont-elles classées monument historique ou à l’inventaire supplémentaire ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.monument_historique === value,
                                onClick: () => onDealChange('monument_historique', value),
                            }
                        ))}
                    />
                    <LineToggle label={'La copropriété se situe-t-elle dans une Zone Industrielle ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.zone_industrielle === value,
                                onClick: () => onDealChange('zone_industrielle', value),
                            }
                        ))}
                    />
                    <LineToggle label={'La copropriété se situe-t-elle dans une Zone d’Activité Commerciale ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.zone_commerciale === value,
                                onClick: () => onDealChange('zone_commerciale', value),
                            }
                        ))}
                    />

                </Box>
                <Box >
                    <SectionDivider title={'Description de la copropriété'} />
                </Box>
                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                    <LineSelect label="De combien de bâtiments est composée la copropriété ?"
                        value={deal.nb_batiment_copropriete}
                        options={Array(9).fill().map((i, x) => x + 1)}
                        onDealChange={option => onDealChange('nb_batiment_copropriete', option)}
                    />
                    <LineSelect label="Combien de niveaux composent l’immeuble, en comptant le rez-de-chaussée ?"
                        value={deal.nb_niveau}
                        options={Array(9).fill().map((i, x) => x + 1)}
                        onDealChange={option => onDealChange('nb_niveau', option)}
                    />
                    <LineSelect label="Combien de sous-sols composent l’immeuble ?"
                        value={deal.nb_sous_sol}
                        options={Array(9).fill().map((i, x) => x)}
                        onDealChange={option => onDealChange('nb_sous_sol', option)}
                    />
                    <LineToggle label={'Comment qualifiez-vous la catégorie de la copropriété ?f'}
                        groups={["Modeste", "Confortable / Standard", "Confortable / Standard XXX"].map(value => (
                            {
                                label: value,
                                selected: deal.categorie_confort_copropriete === value,
                                onClick: () => onDealChange('categorie_confort_copropriete', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Y a-t-il un gardien dans la copropriété ?'}
                        groups={['Oui', 'Non', 'Je ne sais pas'].map(value => (
                            {
                                label: value,
                                selected: deal.gardien === value,
                                onClick: () => onDealChange('gardien', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Certains bâtiments sont-ils sous la même toiture ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.meme_toiture === value,
                                onClick: () => onDealChange('meme_toiture', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Certains bâtiments sont-ils distants de moins de 10 mètres ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.moins_10m === value,
                                onClick: () => onDealChange('moins_10m', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Certains bâtiments sont-ils communicants (y compris par les sous-sols) ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.communicants === value,
                                onClick: () => onDealChange('communicants', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Certains bâtiments font-ils partie d’un groupe d’immeuble communicants de plus de 20 000 m² ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.communicants_plus_20km === value,
                                onClick: () => onDealChange('communicants_plus_20km', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Y a-t-il un contrat d’entretien en cours pour la copropriété et ses équipements ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.entretien_en_cours === value,
                                onClick: () => onDealChange('entretien_en_cours', value),
                            }
                        ))}
                    />
                    <LineSelect label="*Quel est le type de matériaux utilisés sur l’ensemble de l’immeuble ?"
                        value={deal.type_materiaux}
                        options={[
                            "au moins 90% de matériaux durs",
                            "de 50 à 90% de matériaux durs",
                            "moins de 50% de matériaux durs"
                        ]}
                        onDealChange={option => onDealChange('type_materiaux', option)}
                    />
                    <LineSelect label="De quel type d'énergie' s’agit-il ?"
                        value={deal.type_energie}
                        options={[
                            "Individuel Electrique",
                            "Individuel Gaz",
                            "Individuel Autres",
                            "Collectif Electrique",
                            "Collectif Fuel",
                            "Collectif Autres",
                            "Sans"
                        ]}
                        onDealChange={option => onDealChange('type_energie', option)}
                    />
                    <LineToggle label={'Quel est le type des toitures ?'}
                        groups={['Traditionnelles', 'En terrasse', 'En terrasse végétale'].map(value => (
                            {
                                label: value,
                                selected: deal.type_toiture === value,
                                onClick: () => onDealChange('type_toiture', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Quel est le revêtement du sol ?'}
                        groups={['Parquet', 'Moquet', 'Carrelage', "Autres"].map(value => (
                            {
                                label: value,
                                selected: deal.revetement_sol === value,
                                onClick: () => onDealChange('revetement_sol', value),
                            }
                        ))}
                    />

                    <Box direction='row' width={'full'} gap='small'>
                        <StandardText size={'small'} label='La copropriété comporte-t-elle :' />
                    </Box>
                    <Box direction='row' width={'full'} gap='small'>
                        {[
                            [
                                "Panneaux solaires",
                                "Panneaux solaires",
                                "Ascenseur",
                            ],
                            [
                                "Piscine extérieure",
                                "Piscine intérieure",
                                "Jardins suspendus ou sur les toit-terrasses",
                            ]
                        ].map((gr, k1) => (
                            <Box key={k1} width={'full'} gap='small' pad={'small'}>
                                {gr.map((l, k2) => (
                                    <Box key={k2} direction='row' gap='xsmall'>
                                        <CheckBox checked={deal.copropriete_comporte === l}
                                            onChange={e => onDealChange('copropriete_comporte', l)}
                                        />
                                        <StandardText label={l} size='small' />
                                    </Box>
                                ))}
                            </Box>
                        ))
                        }
                    </Box>


                    <LineToggle label={'Y a-t-il une alarme incendie ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.alarme_incendie === value,
                                onClick: () => onDealChange('alarme_incendie', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Y a-t-il des extincteurs ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.extincteurs === value,
                                onClick: () => onDealChange('extincteurs', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Y a-t-il des détecteurs de fumée ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.detecteur_fumee === value,
                                onClick: () => onDealChange('detecteur_fumee', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Y a-t-il une télésurveillance contre le vol ?'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.telesurveillance === value,
                                onClick: () => onDealChange('telesurveillance', value),
                            }
                        ))}
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Date d’effet souhaité"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_effet}
                                onChange={(date) => onDealChange("date_effet", date)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Date de résiliation"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_resiliation}
                                onChange={(date) => onDealChange("date_resiliation", date)}
                            />
                        }
                    />
                    
                    <Box pad={'small'} gap='small'>
                        {deal.file_kbis && (
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                href={`${deal.file_kbis}`}
                            >
                                <DocumentDownload />
                                KBIS souscripteur
                            </a>
                        )}
                        {deal.file_releve_sinistralite && (
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                href={`${deal.file_releve_sinistralite}`}
                            >
                                <DocumentDownload />
                                Relevé de sinistralité
                            </a>
                        )}
                    </Box>
                    <Box pad={'small'} gap='small'>
                        {deal.souscripteur_type === "Un syndic professionnel" ||
                            deal.souscripteur_type === "Une société" ||
                            deal.souscripteur_type === "Un administrateur de bien" ? <FileInput
                            messages={{
                                browse: "Choisir",
                                dropPrompt: "KBIS",
                            }}
                            multiple={false}
                            onChange={(event) => {
                                doUploadFile(
                                    event.target.files,
                                    async (data) => {
                                        if (data.length > 0) {
                                            let _uploaded = data[0];
                                            onDealChange("file_kbis", _uploaded.url);
                                        }
                                    },
                                    showMessage
                                );
                            }}
                        /> : null}
                        <FileInput
                            messages={{
                                browse: "Choisir",
                                dropPrompt: "Relevé de sinistralité",
                            }}
                            multiple={false}
                            onChange={(event) => {
                                doUploadFile(
                                    event.target.files,
                                    async (data) => {
                                        if (data.length > 0) {
                                            let _uploaded = data[0];
                                            onDealChange("file_releve_sinistralite", _uploaded.url);
                                        }
                                    },
                                    showMessage
                                );
                            }}
                        />
                    </Box>

                    <Box pad={'small'}>
                        <FileInput
                            size={'xxsmall'}
                            messages={{
                                browse: "Choisir",
                                dropPrompt: "Ajouter un autre document",
                            }}
                            multiple={false}
                            onChange={(event) => {
                                doUploadFile(
                                    event.target.files,
                                    async (data) => {
                                        if (data.length > 0) {
                                            let _uploaded = data[0];
                                            let _files = deal.file_others || [];
                                            _files.push(_uploaded.url);
                                            onDealChange("file_others", _files);
                                        }
                                    },
                                    mes => showMessage(mes, 'error')
                                );
                            }}
                        />
                    </Box>
                </Box>

                <Box pad={'medium'} direction='row' gap="medium">
                    < PrimaryButton icon={<CaretPrevious />} label='Retour' background={'#707070'} onClick={prev} />
                    < PrimaryButton icon={<Save />} label='Enregistrer' onClick={save} />
                </Box>
            </Box>
        </Box >
    )
}

const Line = ({ left, right }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                {left}
            </Box>
            <Box width={'full'}>
                {right}
            </Box>
        </Box>
    )
}

const LineInput = ({ label, placeholder, value, onDealChange }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <CustomInput size='xsmall' placeholder={placeholder}
                    value={value}
                    onChange={event => onDealChange(event.target.value)}
                />
            </Box>
        </Box>
    )
}

const LineSelect = ({ label, options = [], value, onDealChange }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <CustomSelect
                    size={'small'}
                    options={options}
                    value={value}
                    onChange={({ option }) => onDealChange(option)}
                />
            </Box>
        </Box>
    )
}

const LineToggle = ({ label, groups = [] }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <ToggleButton
                    groups={groups}
                />
            </Box>
        </Box>
    )
}

export default Info;
import { Box, Button, Card, FileInput, Heading } from "grommet";
import { Checkmark, DocumentDownload, Trash } from "grommet-icons";
import React, { useState } from "react";
import { uploadContractPDF, uploadFile } from "../../../../services/storage_service";
import { FRACTIONNEMENT_TYPE } from "../../../../services/types";
import CustomInput from "../../../elements/custom_input";
import DateInput from "../../../elements/date_input";
import Line from "../../../elements/line_container";
import PrimaryButton from "../../../elements/primary_button";
import CustomSelect from "../../../elements/select_fill";
import ToggleButton from "../../../elements/toggle_button/toggle_button";

const doUploadContratPDF = (files, callback, showError) => {
  const fileList = files;
  for (let i = 0; i < fileList.length; i += 1) {
    const file = fileList[i];
    console.log(file.name);
    uploadContractPDF(file)
      .then((result) => {
        // console.log("doUploadContratPDF = ", result.data);
        callback(result.data);
      })
      .catch((err) => {
        showError(
          "Erreur: Le fichier téléchargé n'est pas un PDF modifiable.",
          "error"
        );
        callback([]);
      });
  }
};

const doUploadFile = (files, callback, showError) => {
  const fileList = files;
  if(!fileList) return;
  for (let i = 0; i < fileList.length; i += 1) {
    const file = fileList[i];
    console.log(file.name);
    uploadFile(file)
      .then((result) => {
        console.log("result = ", result.data);
        callback(result.data);
      })
      .catch((err) => {
        showError("Erreur inattendu télécharger.");
        callback([]);
      });
  }
};

const onAmountChanged = (event, callback, showError, callbackNoChanged) => {
  if (!event.target.value) {
    callback(0);
    return;
  }
  event.target.value = event.target.value
    .replace(/[^0-9.,]/g, "")
    .replace(/,/g, ".")
    .replace(/(\..*?)\..*/g, "$1");
  if (event.target.value.slice(-1) === ".") {
    // skip callback to next step
    callbackNoChanged(event.target.value);
    return;
  }
  const _arrA = event.target.value.split(".");
  if (_arrA.length > 1) {
    if (_arrA[1].replace(/0/g, "") === "") {
      // skip callback to next step
      callbackNoChanged(event.target.value);
      return;
    }
  }
  const value = parseFloat(event.target.value || "0");
  if (isNaN(value)) {
    showError("Valeur non valid");
    return;
  } else {
    callback(value);
  }
};

const DevisContratForm = ({
  devis,
  updateDevis,
  isModeContrat,
  allCompanies,
  sendEmailDevisContrat,
  submitModification,
  showMessage,
}) => {
  const [fraisDossier, setFraisDossier] = useState(devis.frais_dossier || 0);
  const [fraisDossierPreinput, setFraisDossierPreinput] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Box
      gap="small"
      width="100%"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Box justify="center" alignSelf="center">
        <Heading level={3} textAlign="center">
          {isModeContrat ? `Envoyer le contrat` : `Envoyer le devis`}
        </Heading>
      </Box>

      <Box width="large" gap="medium" margin="medium">
        <Box gap="xsmall">
          <Line
            leftLabel={`Prime TTC`}
            rightChildren={
              <CustomInput
                value={devis.prime_ttc}
                onChange={(event) => {
                  onAmountChanged(
                    event,
                    (amount) => {
                      updateDevis("prime_ttc", amount);
                    },
                    showMessage,
                    (nochanged) => {
                      updateDevis("prime_ttc", nochanged);
                    }
                  );
                }}
              />
            }
          />
          <Line
            leftLabel={`Prime HT / ${
              devis.fractionnement_type === FRACTIONNEMENT_TYPE.annual
                ? "an"
                : "mois"
            }`}
            rightChildren={
              <CustomInput
                value={devis.prime_ht}
                onChange={(event) => {
                  onAmountChanged(
                    event,
                    (amount) => {
                      updateDevis("prime_ht", amount);
                    },
                    showMessage,
                    (nochanged) => {
                      updateDevis("prime_ht", nochanged);
                    }
                  );
                }}
              />
            }
          />
          <Line
            leftLabel={`Frais de dossier / an`}
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: "0€",
                    selected: fraisDossier === 0,
                    onClick: () => {
                      setFraisDossier(0);
                      setFraisDossierPreinput(true);
                      updateDevis("frais_dossier", 0);
                    },
                  },
                  {
                    label: "12€",
                    selected: fraisDossier === 12,
                    onClick: () => {
                      setFraisDossier(12);
                      setFraisDossierPreinput(true);
                      updateDevis("frais_dossier", 12);
                    },
                  },
                  {
                    label: "24€",
                    selected: fraisDossier === 24,
                    onClick: () => {
                      setFraisDossier(24);
                      setFraisDossierPreinput(true);
                      updateDevis("frais_dossier", 24);
                    },
                  },
                  {
                    label: "36€",
                    selected: fraisDossier === 36,
                    onClick: () => {
                      setFraisDossier(36);
                      setFraisDossierPreinput(true);
                      updateDevis("frais_dossier", 36);
                    },
                  },
                  {
                    label: "48€",
                    selected: fraisDossier === 48,
                    onClick: () => {
                      setFraisDossier(48);
                      setFraisDossierPreinput(true);
                      updateDevis("frais_dossier", 48);
                    },
                  },
                  {
                    label: "60€",
                    selected: fraisDossier === 60,
                    onClick: () => {
                      setFraisDossier(60);
                      setFraisDossierPreinput(true);
                      updateDevis("frais_dossier", 60);
                    },
                  },
                  {
                    label: "72€",
                    selected: fraisDossier === 72,
                    onClick: () => {
                      setFraisDossier(72);
                      setFraisDossierPreinput(true);
                      updateDevis("frais_dossier", 72);
                    },
                  },
                  {
                    label: "84€",
                    selected: fraisDossier === 84,
                    onClick: () => {
                      setFraisDossier(84);
                      setFraisDossierPreinput(true);
                      updateDevis("frais_dossier", 84);
                    },
                  },
                  {
                    label: "96€",
                    selected: fraisDossier === 96,
                    onClick: () => {
                      setFraisDossier(96);
                      setFraisDossierPreinput(true);
                      updateDevis("frais_dossier", 96);
                    },
                  },
                  {
                    label: "108€",
                    selected: fraisDossier === 108,
                    onClick: () => {
                      setFraisDossier(108);
                      setFraisDossierPreinput(true);
                      updateDevis("frais_dossier", 108);
                    },
                  },
                  {
                    label: "Autre",
                    selected: fraisDossierPreinput === false,
                    onClick: () => {
                      setFraisDossierPreinput(false);
                    },
                  },
                ]}
              />
            }
          />

          {fraisDossierPreinput === false && (
            <Line
              leftLabel="Indiquer les frais de dossier"
              rightChildren={
                <CustomInput
                  value={devis.frais_dossier}
                  onChange={(event) => {
                    onAmountChanged(
                      event,
                      (amount) => {
                        setFraisDossier(amount);
                        updateDevis("frais_dossier", amount);
                      },
                      showMessage,
                      (nochanged) => {
                        updateDevis("frais_dossier", nochanged);
                      }
                    );
                  }}
                />
              }
            />
          )}

          {devis.fractionnement_type === FRACTIONNEMENT_TYPE.monthly && (
            <>
              <Line
                leftLabel="Première prime"
                rightChildren={
                  <CustomInput
                    value={devis.prime_premiere}
                    onChange={(event) => {
                      onAmountChanged(
                        event,
                        (amount) => {
                          updateDevis("prime_premiere", amount);
                        },
                        showMessage,
                        (nochanged) => {
                          updateDevis("prime_premiere", nochanged);
                        }
                      );
                    }}
                  />
                }
              />
              <Line
                leftLabel="Premier prélèvement Trustiway"
                rightChildren={
                  <CustomInput
                    disabled={true}
                    value={(
                      parseFloat(devis.prime_premiere || 0) +
                      parseFloat(devis.frais_dossier || 0)
                    ).toFixed(2)}
                  />
                }
              />
            </>
          )}

          <Line
            leftLabel="Taux de com"
            rightChildren={
              <CustomInput
                onChange={(event) => {
                  onAmountChanged(
                    event,
                    (amount) => {
                      updateDevis("taux_commision", amount);
                    },
                    showMessage,
                    (nochanged) => {
                      updateDevis("taux_commision", nochanged);
                    }
                  );
                }}
                value={devis.taux_commision || 0}
              />
            }
          />

          {isModeContrat && (
            <Line
              leftLabel="Date à laquelle vous souhaitez débuter votre assurance"
              rightChildren={
                <DateInput
                  placeholder="Date début"
                  value={devis.begin_date}
                  onChange={(date) => updateDevis("begin_date", date)}
                />
              }
            />
          )}

          <Line
            leftLabel="Compagnie"
            rightChildren={
              <CustomSelect
                style={{ width: "100%" }}
                placeholder="Compagnie"
                options={allCompanies ? allCompanies.map((e) => e.label) : []}
                value={devis.compagnie}
                onChange={({ option }) => updateDevis("compagnie", option)}
              />
            }
          />

          <Line
            leftLabel="Franchise générale"
            rightChildren={
              <CustomInput
                onChange={(event) => {
                  updateDevis("franchise", event.target.value);
                }}
                value={devis.franchise || ""}
              />
            }
          />

          <Line
            leftLabel="Numéro de contrat"
            rightChildren={
              <CustomInput
                onChange={(event) => {
                  updateDevis("numero_contrat", event.target.value);
                }}
                value={devis.numero_contrat || ""}
              />
            }
          />
          <Line
            leftLabel="Date d'effet"
            rightChildren={
              <DateInput
                value={devis.date_effet || null}
                onChange={(date) => updateDevis("date_effet", date)}
              />
            }
          />

          <Card align="center" pad={'small'} direction='row' gap="medium">
            <Box width={'full'}>
              <Line
                leftLabel="IBAN"
                rightChildren={
                  <CustomInput
                    value={devis.iban}
                    onChange={(event) => {
                      updateDevis("iban", event.target.value);
                    }}
                  />
                }
              />
            </Box>
            <Box width={'full'}>

              <Line
                leftLabel="BIC"
                rightChildren={
                  <CustomInput
                    value={devis.bic}
                    onChange={(event) => {
                      updateDevis("bic", event.target.value);
                    }}
                  />
                }
              />

            </Box>
          </Card>

          <Card align="center" pad="small">
            <strong>Télécharger les documents</strong>
            Glissez-déposez des fichiers ici
            <Box direction="column" pad="small" gap="small">
              <Box direction="row" pad="small" gap="large" justify="between">
                <Box direction="row" gap="small" overflow="hidden">
                  {((!isModeContrat && devis.file_devis) ||
                    (isModeContrat && devis.file_contrat)) && (
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href={`${
                        isModeContrat ? devis.file_contrat : devis.file_devis
                      }`}
                    >
                      <DocumentDownload />
                      {isModeContrat ? "Contrat" : "Devis"}
                    </a>
                  )}
                </Box>
                <FileInput
                  messages={{
                    browse: "Choisir",
                    dropPrompt: isModeContrat ? "Contrat" : "Devis",
                  }}
                  multiple={false}
                  onChange={(event) => {
                    setIsLoading(true);
                    if (isModeContrat) {
                      doUploadContratPDF(
                        event.target.files,
                        async (data) => {
                          if (data.url) {
                            updateDevis("file_contrat", data.url);
                          }
                          setIsLoading(false);
                        },
                        showMessage
                      );
                    } else {
                      doUploadFile(
                        event.target.files,
                        async (data) => {
                          if (data.length > 0) {
                            let _uploaded = data[0];
                            updateDevis("file_devis", _uploaded.url);
                          }
                          setIsLoading(false);
                        },
                        showMessage
                      );
                    }
                  }}
                />
              </Box>
              <Box direction="row" pad="small" gap="large" justify="between">
                <Box direction="row" gap="small" overflow="hidden">
                  {((!isModeContrat && devis.file_devis_ipid) ||
                    (isModeContrat && devis.file_contrat_ipid)) && (
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href={`${
                        isModeContrat
                          ? devis.file_contrat_ipid
                          : devis.file_devis_ipid
                      }`}
                    >
                      <DocumentDownload />
                      {isModeContrat ? "Ipid" : "Ipid"}
                    </a>
                  )}
                </Box>
                <FileInput
                  messages={{
                    browse: "Choisir",
                    dropPrompt: "Ipid",
                  }}
                  multiple={false}
                  onChange={(event) => {
                    setIsLoading(true);
                    doUploadFile(
                      event.target.files,
                      (data) => {
                        if (data.length > 0) {
                          let _uploaded = data[0];
                          updateDevis(
                            isModeContrat
                              ? "file_contrat_ipid"
                              : "file_devis_ipid",
                            _uploaded.url
                          );
                        }
                        setIsLoading(false);
                      },
                      showMessage
                    );
                  }}
                />
              </Box>
              <Box direction="row" pad="small" gap="large" justify="between">
                <Box direction="row" gap="small" overflow="hidden">
                  {devis.file_contrat_condition && (
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href={`${devis.file_contrat_condition}`}
                    >
                      <DocumentDownload />
                      {"Conditions générales"}
                    </a>
                  )}
                </Box>
                <FileInput
                  messages={{
                    browse: "Choisir",
                    dropPrompt: "Conditions générales",
                  }}
                  multiple={false}
                  onChange={(event) => {
                    setIsLoading(true);
                    doUploadFile(
                      event.target.files,
                      (data) => {
                        if (data.length > 0) {
                          let _uploaded = data[0];
                          updateDevis(
                            isModeContrat
                              ? "file_contrat_condition"
                              : "file_devis_condition",
                            _uploaded.url
                          );
                        }
                        setIsLoading(false);
                      },
                      showMessage
                    );
                  }}
                />
              </Box>
              {devis.file_others ? (
                devis.file_others.map((e, _idx) => {
                  return (
                    <Box
                      key={`file_${_idx}`}
                      direction="row"
                      pad="small"
                      gap="small"
                      justify="between"
                    >
                      <Box direction="row" gap="small" overflow="hidden">
                        {e && (
                          <Button
                            onClick={() => {
                              console.log("remove index = ", _idx);
                              let _files = devis.file_others;
                              _files.splice(_idx, 1);
                              updateDevis("file_others", _files);
                            }}
                          >
                            <Trash color="red" />
                          </Button>
                        )}
                        {e && (
                          <a target={"_blank"} rel="noreferrer" href={`${e}`}>
                            <DocumentDownload />
                            {e.split("/").pop()}
                          </a>
                        )}
                      </Box>
                      <FileInput
                        messages={{
                          browse: "Choisir",
                          dropPrompt: "Ajouter un autre document",
                        }}
                        multiple={false}
                        onChange={(event) => {
                          setIsLoading(true);
                          doUploadFile(
                            event.target.files,
                            async (data) => {
                              if (data.length > 0) {
                                let _uploaded = data[0];
                                let _files = devis.file_others || [];
                                _files[_idx] = _uploaded.url;
                                updateDevis("file_others", _files);
                              }
                              setIsLoading(false);
                            },
                            showMessage
                          );
                        }}
                      />
                    </Box>
                  );
                })
              ) : (
                <></>
              )}
              <FileInput
                messages={{
                  browse: "Choisir",
                  dropPrompt: "Ajouter un autre document",
                }}
                multiple={false}
                onChange={(event) => {
                  setIsLoading(true);
                  doUploadFile(
                    event.target.files,
                    async (data) => {
                      if (data.length > 0) {
                        let _uploaded = data[0];
                        let _files = devis.file_others || [];
                        _files.push(_uploaded.url);
                        updateDevis("file_others", _files);
                      }
                      setIsLoading(false);
                    },
                    showMessage
                  );
                }}
              />
            </Box>
          </Card>
          <Box
            align="center"
            margin="medium"
            direction="row"
            gap="small"
            justify="center"
          >
            <PrimaryButton
              disabled={isLoading}
              label={`Sauvegarder`}
              icon={<Checkmark />}
              background="#59A4F4"
              onClick={() => {
                submitModification(devis);
              }}
            />
            <PrimaryButton
              disabled={isLoading}
              label={`Valider & Envoyer`}
              icon={<Checkmark />}
              onClick={() => {
                sendEmailDevisContrat(devis);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DevisContratForm;

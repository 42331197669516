
import React, { useState } from "react";
import { Box, Text, Heading, TextInput, Card, Layer, Button } from "grommet";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import CustomSelect from "../select_fill";
import { Checkmark, Close, Trash } from "grommet-icons";
import PrimaryButton from "../primary_button";
import { updateCredit } from "../../../services/credit_service";

const AnalysteDealChange = ({ 
    deal, 
    commerciaux, 
    refreshReports, 
    showMessage, 
    onClose,
    prop_id
}) => {
    const [loading, setLoading] = useState(false);
    const [newAnalysteId, setNewAnalysteId] = useState(deal ? deal[prop_id] : undefined);

    const changeOwner = async () => {
        if (!newAnalysteId || newAnalysteId === deal[prop_id]) {
            showMessage("Pas de changement", "warning");
            return;
        }
        setLoading(true);
        try {
            deal[prop_id] = newAnalysteId;
            console.log('prop_id', prop_id, deal[prop_id])
            await updateCredit(deal);
            refreshReports();
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
        onClose();
        showMessage("Changé avec succès", "success");
    }

    const removeOwner = async () => {
        setLoading(true);
        try {
            deal[prop_id] = null;
            await updateCredit(deal);
            refreshReports();
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
        onClose();
        showMessage("Changé avec succès", "success");
    }

    return (
        <BlockUi tag="div" blocking={loading}>
            <Card width={'large'} pad='medium' gap="small">
                <Box style={{ position: 'absolute', right: 10, top: 10 }}>
                    <Close onClick={onClose} style={{ cursor: 'pointer' }} />
                </Box>

                <Box direction="row" gap="medium" justify="between" style={{width: 'auto'}} pad={'xsmall'}>
                    <Box >
                        <CustomText label={"Analyste du deal"} />
                    </Box>
                    <Box width={'full'}>
                        <CustomSelect
                            placeholder={'Analyste'}
                            options={commerciaux}
                            onChange={({ option }) => setNewAnalysteId(option.id)}
                            labelKey={'name'}
                            valueKey={{ key: 'name', reduce: true }}
                            value={commerciaux.find(c => c.id === (newAnalysteId || deal[prop_id]))?.name}
                        />
                    </Box>
                    <Box direction="row" gap="xsmall" >
                        <Button icon={<Checkmark color="brand" />} onClick={changeOwner} />
                        <Button icon={<Trash />} color={'black'} onClick={removeOwner} />
                    </Box>
                </Box>
            </Card>
        </BlockUi>
    )
}


const CustomText = ({ label }) => (
    <Text style={{ fontWeight: "bold" }} size="small" textAlign="end">
        {label}
    </Text>
);
export default AnalysteDealChange;
import {
  Box
} from "grommet";
import React from "react";

import service from "../../../services/sante_service";
import { getSorted } from '../../../services/utils';
import Refresh from "../../elements/refresh";
import Sante from "./sante/index";

import AddDevis from '../../elements/add_devis';
import DateSort from '../../elements/date_sort';
import SearchComponent from '../../elements/search';
import StatusFilter from '../../elements/status_filter';
import ViewTable from "../../elements/view_table";

class Santes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: this.props.contact || {},
      santes: [],
      popupOpen: false,
      selectedSante: {
        assures: [],
      },
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    };
  }

  componentDidMount = () => {
    this.loadSantes();
  };

  loadSantes = () => {
    this.setState({ santes: [] }, () => {
      service
        .getSantes(this.state.contact.id)
        .then((res) => {
          this.setState({ santes: res.data });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });

    })
  };

  onClose = () => {
    this.setState({ popupOpen: false });
  };

  openSante = (sante) => {
    this.setState({ selectedSante: sante, popupOpen: true });
  };

  addSante = () => this.openSante({ assures: [], date_of_birth: new Date() });

  search = (query, status) => {
    service.getSantes(this.state.contact.id, query, status)
      .then(res => {
        if (res.data?.results) {
          this.setState({ santes: res.data?.results })
        } else if (res.data) {
          this.setState({ santes: res.data })
        }
      })
      .catch(err => {
        console.log('err', err);
        this.props.showMessage('Erreur inattendu');
      })
  }

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  render() {
    return this.state.popupOpen ? (
      <Sante
        showMessage={this.props.showMessage}
        devis={this.state.selectedSante}
        onClose={this.onClose}
        reload={this.loadSantes}
      />
    ) : (
      <Box
        style={{ width: "100%" }}
        align="center"
        justify="center"
        alignSelf="center"
        alignContent="center"
        gap="medium"
      >
        <Box width="full" gap="medium" pad={'small'} align='center' justify='center'>
          <Box width="xlarge" gap="medium" align='center' justify='center'>

            {!this.state.contact.id &&
              <>
                <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
                  <Refresh refresh={this.loadSantes} />
                  <SearchComponent
                    search={value => {
                      this.search(value, this.state.filter.status);
                    }}
                    query={this.state.filter._query}
                    updateQuery={value => this.updateFilter('_query', value)}
                  />
                  <AddDevis onAdd={this.addSante} />
                </Box>
                <Box justify="between" direction='row' align="center" gap="medium" alignSelf="center">
                  <StatusFilter
                    status={this.state.filter.status}
                    search={value => {
                      this.updateFilter('status', value);
                      this.search(this.state.filter._query, value);
                    }}
                  />
                  <DateSort
                    onChange={value => this.updateFilter('sort', value)}
                  />

                </Box>
              </>
            }
            {this.state.contact.id && <Box style={{ height: 30 }} />}

          </Box>
          <ViewTable
            reports={getSorted(this.state.santes, this.state.filter.sort)}
            commission_taux={this.props.commission_taux}
            onDevisSelected={this.openSante}
            commerciaux={this.props.commerciaux}
          />
        </Box>
      </Box>
    );
  }
}

export default Santes;

import React from "react";
import { Box, Button, Card, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { getAppUsersTeams } from "../service";
import { getSimpleDateFR, getSorted } from "../../../services/utils";
import Refresh from "../../elements/refresh";
import SearchComponent from "../../elements/search";
import DateSort from "../../elements/date_sort";
import { AddCircle, StatusGoodSmall } from "grommet-icons";
import AppUserInfo from "./user_info";
import PrimaryButton from "../../elements/primary_button";
import TeamDetail from "./team_detail";


class AppUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appUsers: [],
      popupOpen: false,
      selectedUser: {},
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    };
  }
  componentDidMount = () => {
    this.loadAppUsers();
  };

  loadAppUsers = () => {
    this.setState({ appUsers: [] }, () => {
      getAppUsersTeams()
        .then((res) => {
          this.setState({ appUsers: res.data });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false });
    this.loadAppUsers();
  };

  openUser = (user) => {
    this.setState({ selectedUser: user, popupOpen: true });
  };

  addUser = () => this.openUser({});

  search = (query) => {
    getAppUsersTeams(query)
      .then(res => {
        if (res.data?.results) {
          this.setState({ appUsers: res.data?.results })
        } else if (res.data) {
          this.setState({ appUsers: res.data })
        }
      })
      .catch(err => {
        console.log('err', err);
        this.props.showMessage('Erreur inattendu');
      })
  }

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  render() {
    return this.state.popupOpen ? (
      <Box style={{ width: "100%" }}
        align="center" justify="center"
        alignSelf="center"
        alignContent="center" gap="medium">
        <TeamDetail
          showMessage={this.props.showMessage}
          user={this.state.selectedUser}
          onClose={this.onClose}
          reload={this.loadAppUsers}
        />
      </Box>
    ) : (
      <Box style={{ width: "100%" }}
        align="center" justify="center"
        alignSelf="center"
        alignContent="center" gap="medium">
        <Text size="xxlarge" style={{ padding: 10 }}><b>Équipes</b></Text>
        <Box gap="medium">
          <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
            <Refresh refresh={this.loadAppUsers} />
            <SearchComponent
              search={value => {
                this.search(value, this.state.filter._query);
              }}
              query={this.state.filter._query}
              updateQuery={value => this.updateFilter('_query', value)}
            />
            <DateSort
              onChange={value => this.updateFilter('sort', value)}
            />
          </Box>


          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Text>Équipe</Text>
                </TableCell>
                <TableCell>
                  <Text>Équipe origine</Text>
                </TableCell>
                <TableCell>
                  <Text>Nom du chef</Text>
                </TableCell>
                <TableCell>
                  <Text>Prénom du chef</Text>
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell><Text>État inscription</Text></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getSorted(this.state.appUsers, this.state.filter.sort).map((appUser, index) => (
                <TableRow key={index + 1}>
                  <TableCell>
                    <Text>{appUser.code_parrain}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.affiliated_by ? `${appUser.affiliated_by.code_parrain}` : ''}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.nom}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.prenom}</Text>
                  </TableCell>
                  <TableCell>
                    <Text size="small">{appUser.email}</Text>
                  </TableCell>
                  <TableCell>
                    <Card direction="row" gap="xsmall" pad="xsmall" align="center">
                      <StatusGoodSmall color={'green'} />
                      <Text size="small">
                        <strong>Validé</strong>
                      </Text>
                    </Card>
                  </TableCell>
                  <TableCell>
                    <Button
                      label="voir"
                      primary
                      onClick={() => this.openUser(appUser)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  }
}



export default AppUser;

import React from "react";
import BlockUi from "react-block-ui";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  Layer
} from "grommet";
import RecapStatus from "../elements/devis_status";
import TrustiHabitation from "../ventes/habitations/habitation";
import Pet from "../ventes/pets/pet";
import Sante from "../ventes/santes/sante";
import TrustiMH from "../ventes/trusti-mhs/trusti_mh";
import TrustiVoiture from "../ventes/trusti_voitures/trusti_voiture";
import TrustiMoto from "../ventes/trusti_motos/trusti_moto";
import Emprunteur from "../ventes/emprunteur-horslignes/emprunteur-horsligne";
import TrustiPNO from "../ventes/trusti-pnos/trusti_pno";
import TrustiPER from "../ventes/trusti-per/trusti_per";
import TrustiPNOHorsligne from "../ventes/trusti-pno-horslignes/trusti_pno_horsligne";
import PNOGite from "../ventes/pno-gites/gite";
import {
  getApporteurName,
  getDateCurrentStatusField,
  getSimpleDateFR,
  getSimpleDateTimeFR,
} from "../../services/utils";
import CustomSelect from "../elements/select_fill";
import {
  EN_ATTENTE_BANQUE,
  EN_ATTENTE_DE_FORMALITE_MEDICALE,
  EN_ATTENTE_DE_RESILIATION,
} from "../../services/devis_contrat_status";
import moment from "moment";
import DomageOuvrage from "../professionels/domagne_ouvrages/domage_ouvrage";
import MultirisquePro from "../professionels/multirisque_pros/multirisque_pro";
import ResponsabiliteCivile from "../professionels/responsabilite_civiles/responsabilite_civile";
import Prevoyance from "../professionels/prevoyances/prevoyance";
import ProImmeuble from "../professionels/immeubles/immeuble";
import ProPNO from "../professionels/pro_pnos/pro_pno";
import ProSante from "../professionels/pro_santes/pro_sante";
import CyberRisque from "../professionels/cyber_risques/cyber_risque";
import TrustiMHTempo from "../ventes/trusti-mh-tempos/trusti-mh-tempo";
import TrustiGLI from "../ventes/trusti-glis/trusti_gli";
import Immeuble from "../ventes/trusti-immeubles/trusti-immeuble";
import { Edit } from "grommet-icons";
import { hasAdminRole, hasClassiqueManagerRole } from "../../services/storage_service";
import CommercialDealChange from "../elements/change_commercial";

import CreditEmprunteur from "../ventes/credit/credit/index";

const getPopupContainer = (devis, onClose, reload, reloadAll, showMessage) => {
  let name = devis.product;
  console.log(name, "name ", devis);
  switch (name) {
    case "Trusti Habitation en ligne":
    case "Trusti Habitation en ligne - clôturé":
      return (
        <TrustiHabitation
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
          contacts={[]}
        />
      );
    case "Trusti PNO en ligne":
    case "Trusti PNO en ligne - clôturé":
      return (
        <TrustiPNO
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
        />
      );
    case "Trusti Emprunteur":
      return (
        <Emprunteur
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
        />
      );
    case "Trusti Moto":
      return (
        <TrustiMoto
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
        />
      );
    case "Trusti Voiture":
      return (
        <TrustiVoiture
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
        />
      );
    case "Trusti Chien/Chat":
      return (
        <Pet
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
        />
      );
    case "Trusti Santé":
      return (
        <Sante
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
        />
      );
    case "Trusti PNO Gîte":
      return (
        <PNOGite
          showMessage={showMessage}
          devis={devis}
          gite={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
        />
      );
    case "Trusti Habitation":
      return (
        <TrustiMH
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
        />
      );
    case "Trusti PNO":
      return (
        <TrustiPNOHorsligne
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
        />
      );

    case "Trusti PER":
      return (
        <TrustiPER
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
        />
      );

    case "Trusti MH tempo enligne":
      return (
        <TrustiMHTempo
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
        />
      );

    case "Trusti Dommage Ouvrage":
      return (
        <DomageOuvrage
          showMessage={showMessage}
          deal={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          onRefresh={() => {
            reload();
          }}
        />
      );
    case "Trusti MRP":
      return (
        <MultirisquePro
          showMessage={showMessage}
          deal={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          onRefresh={() => {
            reload();
          }}
        />
      );

    case "Trusti RC":
      return (
        <ResponsabiliteCivile
          showMessage={showMessage}
          deal={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          onRefresh={() => {
            reload();
          }}
        />
      );

    case "Trusti PREV":
      return (
        <Prevoyance
          showMessage={showMessage}
          deal={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          onRefresh={() => {
            reload();
          }}
        />
      );

    case "Trusti Immeuble pro":
      return (
        <ProImmeuble
          showMessage={showMessage}
          deal={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          onRefresh={() => {
            reload();
          }}
        />
      );

    case "Trusti PNO pro":
      return (
        <ProPNO
          showMessage={showMessage}
          deal={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          onRefresh={() => {
            reload();
          }}
        />
      );

    case "Trusti Santé pro":
      return (
        <ProSante
          showMessage={showMessage}
          deal={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          onRefresh={() => {
            reload();
          }}
        />
      );

    case "Trusti Cyber Risque":
      return (
        <CyberRisque
          showMessage={showMessage}
          deal={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          onRefresh={() => {
            reload();
          }}
        />
      );

    case "Trusti GLI":
      return (
        <TrustiGLI
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
          contacts={[]}
        />
      );

    case "Trusti Immeuble":
      return (
        <Immeuble
          showMessage={showMessage}
          deal={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
          contacts={[]}
        />
      );

    case "Trusti Crédit":
      return (
        <CreditEmprunteur
          showMessage={showMessage}
          devis={devis}
          onClose={() => {
            onClose(false);
            reloadAll();
          }}
          reload={() => {
            reload();
          }}
          contacts={[]}
        />
      );

    default:
      return <Box />;
  }
};

const getColorByDate = (date) => {
  const now = new Date();
  const today = moment(now).format("YYYY-MM-DD");
  if (moment(date).isBefore(moment()))
    return "red";
  if (moment(date).format("YYYY-MM-DD") === today) return "green";
  if (moment(date) < moment(now).add(7, "days"))
    return "orange";
  return "#000";
};

const StatusReport = ({ reports, status, commerciaux, all_commerciaux, reload, reloadAll, showMessage, nbr_appel, locking, refreshReports }) => {
  const [open, setOpen] = React.useState(false);
  const [devis, setDevis] = React.useState();
  const [sort, setSort] = React.useState("STATUS_DESC");
  const [selectedDeal, setSelectedDeal] = React.useState();

  const _OPTIONS = [
    { name: "Date du status en cours - Croissant", value: "STATUS_ASC" },
    { name: "Date du status en cours - Décroissant", value: "STATUS_DESC" },
    { name: "Date du dernier appel - Croissant", value: "APPEL_ASC" },
    { name: "Date du dernier appel - Décroissant", value: "APPEL_DESC" }
  ];

  return (
    <Box fill style={{ height: 610, width: "100%" }}>
      {open && (
        <Layer fill style={{ overflowY: "scroll", height: "100vh", width: '100vw' }}>
          {/* <Box width="xlarge"> */}
          <Box fill style={{ marginTop: 20, marginBottom: 40 }}>
            {getPopupContainer(devis, setOpen, reloadAll, reloadAll, showMessage)}
          </Box>
          {/* </Box> */}
        </Layer>
      )}
      {selectedDeal && (
        <Layer pad="medium" >
          {/* <Box width="xlarge"> */}
          <Box justify="center" align="center" pad={'medium'}>
            <CommercialDealChange
              commerciaux={commerciaux}
              deal={selectedDeal}
              refreshReports={refreshReports}
              showMessage={showMessage}
              onClose={() => setSelectedDeal(null)}
            />
          </Box>
          {/* </Box> */}
        </Layer>
      )}
      <BlockUi
        tag="div"
        blocking={!reports}
        style={{ width: "100%", height: "100%" }}
      >
        <Box gap="small" pad="small" direction="column">
          <Text style={{ fontWeight: "bold" }} size="large" textAlign="center">
            List des {status}
          </Text>
          <Card
            gap="small"
            direction="row"
            justify="around"
            pad="xsmall"
            background={"#fff"}
          >
            <Box>
              <Text textAlign="center" size="small">
                Nombre des {status}
              </Text>
              <Text
                textAlign="center"
                size="small"
                style={{ fontWeight: "bold" }}
              >
                {reports?.length}
              </Text>
            </Box>
            <Box justify="center" alignSefl="center">
              <RecapStatus status={status} />
            </Box>
          </Card>
          <Box justify="end" align="end" direction="row">
            <Card
              pad="xsmall"
              justify="center"
              alignContent="center"
              gap="small"
              background={"#fff"}
              align="center"
              direction="row"
            >
              <Text size="xsmall">Trier par </Text>
              <CustomSelect
                size="xsmall"
                options={_OPTIONS}
                onChange={({ option }) => {
                  setSort(option.value);
                }}
                value={_OPTIONS.filter(e => e.value === sort)[0]["name"]}
                labelKey={"name"}
                valueKey={{ key: "name", reduce: true }}
                style={{ width: 260 }}
              />
            </Card>
          </Box>
          <Box>
            <Table
              fill="horizontal"
              style={{
                width: "100%",
                background: "#fff",
                marginBottom: 0
              }}
            >
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Source
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Apporteur
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Partenaire
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"120px"}>
                    <Text size="small" weight="bold">
                      Commercial
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Création
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Modification
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Assuré
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Produit
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Statut
                    </Text>
                  </TableCell>
                  {nbr_appel &&
                    <TableCell scope="col" width={"80px"}>
                      <Text size="small" weight="bold">
                        Nbr appel
                      </Text>
                    </TableCell>
                  }
                  {nbr_appel &&
                    <TableCell scope="col" width={"80px"}>
                      <Text size="small" weight="bold">
                        Dernier appel
                      </Text>
                    </TableCell>
                  }

                </TableRow>
              </TableHeader>
            </Table>
            <Box
              style={{
                overflowY: "scroll",
                height: 310,
                width: "100%",
                marginTop: 0,
                marginBottom: 0,
              }}
              background={"#fff"}
              fill="horizontal"
            >
              <Table
                fill="horizontal"
                style={{
                  width: "100%",
                }}
              >
                <TableBody>
                  {(reports || [])
                    .sort((s1, s2) => sortReports(s1, s2, sort, status))
                    .map((devis, index) => {
                      // console.log("devis = ", devis);
                      let _client = devis.client || devis.entreprise || {};
                      let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                      return (
                        <TableRow
                          key={index + 1}
                          style={{ backgroundColor: _bgColor, cursor: 'pointer' }}
                          onClick={() => {
                            setDevis(devis);
                            setOpen(true);
                          }}
                        >
                          <TableCell width={"80px"}>
                            <Text size="small">{devis.source} </Text>
                          </TableCell>
                          <TableCell width={"80px"}>
                            <Text size="small">
                              {getApporteurName(devis)}
                            </Text>
                          </TableCell>
                          <TableCell width={"80px"}>
                            <Text size="small">{devis.apporteur?.apporteur_pro?.raisonSocial ?? ''}</Text>
                          </TableCell>
                          <TableCell width={"120px"}>
                            <Text size="small">{all_commerciaux?.find(d => d.id == devis.commercial_id)?.name} {' '}
                              {(hasAdminRole() || hasClassiqueManagerRole()) &&
                                <Edit color="brand"
                                  size="small"
                                  style={{ cursor: 'pointer' }}
                                  onClick={e => {
                                    setSelectedDeal(devis);
                                    e.stopPropagation();
                                  }}
                                />}
                            </Text>


                          </TableCell>
                          <TableCell width={"80px"}>
                            <Text size="small">
                              {getSimpleDateFR(devis.createdAt)}
                            </Text>
                          </TableCell>
                          <TableCell width={"80px"}>
                            <Text size="small">
                              {getSimpleDateFR(devis.updatedAt)}
                            </Text>
                          </TableCell>
                          <TableCell width={"80px"}>
                            <Text size="small">
                              {(_client.raisonSocial || _client.raison_social)
                                ? (_client.raisonSocial || _client.raison_social)
                                : `${_client.lastname} ${_client.firstname}`}
                            </Text>
                          </TableCell>
                          <TableCell width={"80px"}>
                            <Text size="small">{devis.product} </Text>
                          </TableCell>
                          <TableCell width={"80px"}>
                            <RecapStatus status={devis.status} size='small' />
                          </TableCell>
                          {nbr_appel &&
                            <TableCell width={"80px"}>
                              <Text size="small">{devis['nbr_appel_' + nbr_appel]} </Text>
                            </TableCell>
                          }
                          {nbr_appel &&
                            <TableCell width={"80px"}>
                              <Text size="small">{getSimpleDateTimeFR(devis['date_dernier_appel'])} </Text>
                            </TableCell>
                          }
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
      </BlockUi>
    </Box>
  );
};

const sortReports = (s1, s2, sort, status) => {
  var prop = getDateCurrentStatusField(s1);
  let _orderBy = sort.indexOf("DESC") >= 0 ? "DESC" : "ASC";

  if (sort.indexOf("APPEL") >= 0) {
    prop = "date_dernier_appel";
  }
  return _orderBy === "ASC"
    ? s1[prop] > s2[prop]
      ? 1
      : -1
    : _orderBy === "DESC"
      ? s1[prop] > s2[prop]
        ? -1
        : 1
      : s1.product > s2.product
        ? 1
        : -1;
};

const hasBPADate = (status) =>
  status === "Devis validé" ||
  status === "Contrat envoyé" ||
  status === "Contrat clôturé" ||
  status === EN_ATTENTE_DE_RESILIATION ||
  status === EN_ATTENTE_BANQUE ||
  status === EN_ATTENTE_DE_FORMALITE_MEDICALE;

export default StatusReport;

export {
  getPopupContainer
}

import React from "react";
import { Box, Button, Card, Text } from "grommet";
import { AddCircle, Phone, SubtractCircle } from "grommet-icons";
import { createAppointment, getLastAppointmentHorsSystem, deleteAppointment } from "../../../../services/appointment_service";
import { getUserId } from "../../../../services/storage_service";
import moment from "moment";
import { getSimpleDateTimeFR } from "../../../../services/utils";


class PanelAdminHorsSystem extends React.Component {

	constructor(props) {
		super(props);
		this.state = { appels: null }
	}

	componentDidMount() {
		this.loadData();
	}

	loadData = () => {
		getLastAppointmentHorsSystem(this.props.filter)
			.then(res => {
				// console.log(res.data);
				this.setState({ appels: res.data });
			});
	}

	addOneCall = () => {

		// get last time click from localstorage
		const lastClick = window.localStorage.getItem("lastClick");
		// if last click is less than 1à second ago
		if (lastClick && new Date() - new Date(lastClick) < 10000) {
			alert("Vous avez déjà cliqué il y a moins de 10 secondes");
			return;
		}
		// Save time of click
		window.localStorage.setItem("lastClick", new Date());

		var startDate = moment(new Date());
		var _params = {
			title: `Appel hors système`,
			begin: startDate,
			duration: 5,
			end: startDate.add(5, "minutes"),
			commercial: getUserId(),
			type: "Appel",
			automatic: false,
			status: 'Fait',
			rappel_by_email: false,
			hors_system: true
		}
		createAppointment(_params).then(res => {
			// refresh data
			this.props.onRefresh();
			this.loadData();
		});
	}

	removeOneCall = () => {
		// get last time click from localstorage
		const lastClick = window.localStorage.getItem("lastClick");
		// if last click is less than 1à second ago
		if (lastClick && new Date() - new Date(lastClick) < 10000) {
			alert("Vous avez déjà cliqué il y a moins de 10 secondes");
			return;
		}
		// Save time of click
		window.localStorage.setItem("lastClick", new Date());
		if (this.state.appels && this.state.appels.length > 0) {
			deleteAppointment(this.state.appels[0]).then(res => {
				// refresh data
				this.props.onRefresh();
				this.loadData();
			});
		}
	}


	render() {
		return (<Box
			direction="column"
			gap="small"
			pad="small"
			justify="center"
			alignContent="center"
			align="center"

		>
			<Card direction="column" pad="xsmall" align="center">
				<Text textAlign="center"><b>Compteur d'appel<br />prospection<br />hors système</b></Text>
				<Box
					direction="row"
					gap="small"
					pad="small"
					justify="center"
					alignContent="center"
					align="center"
				>
					<Phone color="#000" />
					<Text size="xlarge" weight={"bolder"} color="#59A4F4">
						{this.state.appels ? this.state.appels.length : 0}
					</Text>
					<Button
						onClick={() => {
							this.addOneCall();
						}}
					>
						<AddCircle color="brand" />
					</Button>
					<Button
						onClick={() => {
							this.removeOneCall();
						}}
					>
						<SubtractCircle color="brand" />
					</Button>
				</Box>
				<Box direction="column" gap="xsmall" pad="xsmall" align="center">
					<Text size="small" weight={"bold"}>
						Date du dernier appel:
					</Text>
					<Text size="small">
						{this.state.appels && this.state.appels.length > 0 && getSimpleDateTimeFR(this.state.appels[0].begin)}
					</Text>
				</Box>
			</Card>
		</Box>)
			;
	}
}
export default PanelAdminHorsSystem;

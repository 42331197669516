import React from 'react';
import { Box, Heading } from 'grommet';
import ContactSelect, { ContactSelectV2 } from '../../../contacts/contact_modal/client';
import PrimaryButton from '../../../elements/primary_button';
import { CaretNext } from 'grommet-icons';

const ClientInfo = ({ devis, updateDevis, onNext, onPrev }) => {

    return (
        <Box gap="small" width="xlarge" alignSelf="center" align="center" pad="small" justify="center">
            <Box justify="center" alignSelf="center">
                <Heading level={3} textAlign="center">{`Informations du client`}</Heading>
            </Box>

            <ContactSelectV2
                contact={devis.client || {}}
                updateContact={updateDevis}
                addressIncluded={true}
                birthDayIncluded={true}
            />

            <Box style={{ height: 40 }} />
            <Box direction='row' align="center" justify="center" gap="medium">

                <PrimaryButton
                    label={`Suivant`}
                    onClick={onNext}
                    reverse={true}
                    icon={<CaretNext />}
                />

            </Box>
        </Box>
    )
}

export default ClientInfo;
import { Box, Layer, Text } from "grommet";
import {
    Close, Trash
} from "grommet-icons";
import React, { useState } from "react";
import CustomInput from "../custom_input";
import PrimaryButton from "../primary_button";

const PopupConfirmation = ({
    primaryAction,
    primaryActionText,
    primaryActionIcon,
    secondaryAction,
    secondaryActionText,
    secondaryActionIcon,
    message,
    close,
}) => (
    <Layer>
        <Box pad='medium' justify="between" alignContent="center" align="center" style={{ height: 150 }}>
            <Close color="brand" onClick={close} style={{ position: 'absolute', top: 5, right: 5, cursor: 'pointer' }} size='small' />
            <Box>
                <Text>{message}</Text>
            </Box>
            <Box direction="row" gap="small">
                <PrimaryButton
                    label={primaryActionText}
                    icon={primaryActionIcon}
                    onClick={primaryAction}
                    background="#E82C70"
                />
                <PrimaryButton
                    label={secondaryActionText}
                    icon={secondaryActionIcon}
                    background="#59A4F4"
                    onClick={secondaryAction}
                />
            </Box>
        </Box>
    </Layer>
)

const TypeToConfirmation = ({
    confirmDelete,
    pharse,
    close,
}) => {
    const [deletable, setDeletable] = useState(false);
    const [value, setValue] = useState('')
    return (
        <Layer>
            <Box pad='medium' justify="between" alignContent="center" align="center" style={{ height: 250 }} gap='medium'>
                <Close color="brand" onClick={close} style={{ position: 'absolute', top: 5, right: 5, cursor: 'pointer' }} size='small' />
                <Box>
                    <Text>Veuillez tapper la pharse <b>{pharse}</b> pour supprimer</Text>
                </Box>
                <Box>
                    <CustomInput placeholder={'Taper pour confirmer'} size='small'
                        value={value}
                    onChange={event => {
                        setDeletable(event.target.value === pharse);
                        setValue(event.target.value);
                    }} />
                </Box>
                <Box direction="row" gap="small">
                    <PrimaryButton
                        label={'Fermer'}
                        icon={<Close />}
                        background="#59A4F4"
                        onClick={close}
                    />
                    <PrimaryButton
                        disabled={!deletable}
                        label={'Supprimer'}
                        icon={<Trash/>}
                        onClick={confirmDelete}
                        background="#E82C70"
                    />
                </Box>
            </Box>
        </Layer>
    )
}

export {
    TypeToConfirmation
}

export default PopupConfirmation;
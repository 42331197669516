import { Box, Card, CheckBox, FileInput, Heading, Text } from "grommet";
import React, { useState } from "react";
import MarksSlider from "../../../elements/slider/marks_slider";
import CustomInput from "../../../elements/custom_input";
import Line from "../../../elements/line_container";
import CustomSelect from "../../../elements/select_fill";
import ToggleButton from "../../../elements/toggle_button/toggle_button";
import { Checkmark, DocumentDownload } from "grommet-icons";
import PrimaryButton from "../../../elements/primary_button";
import { ASSURANCE_PRET_TYPES } from "../../../../types/emprunteur_type";
import BlockText from "../../../elements/line_container/block_text";
import { EMPRUNTEUR_TYPE } from "../../../../services/types";
import DateInput from "../../../elements/date_input";
import {
	uploadContractPDF,
	uploadFile,
} from "../../../../services/storage_service";

const doUploadContratPDF = (files, callback, showError) => {
	const fileList = files;
	for (let i = 0; i < fileList.length; i += 1) {
		const file = fileList[i];
		console.log(file.name);
		uploadContractPDF(file)
			.then((result) => {
				// console.log("doUploadContratPDF = ", result.data);
				callback(result.data);
			})
			.catch((err) => {
				showError(
					"Erreur: Le fichier téléchargé n'est pas un PDF modifiable.",
					"error",
				);
				callback([]);
			});
	}
};

const doUploadFile = (files, callback, showError) => {
	const fileList = files;
	for (let i = 0; i < fileList.length; i += 1) {
		const file = fileList[i];
		console.log(file.name);
		uploadFile(file)
			.then((result) => {
				console.log("result = ", result.data);
				callback(result.data);
			})
			.catch((err) => {
				showError("Erreur inattendu télécharger.");
				callback([]);
			});
	}
};

const onAmountChanged = (event, callback, showError, callbackNoChanged) => {
	if (!event.target.value) {
		callback(0);
		return;
	}
	event.target.value = event.target.value
		.replace(/[^0-9.,]/g, "")
		.replace(/,/g, ".")
		.replace(/(\..*?)\..*/g, "$1");
	if (event.target.value.slice(-1) === ".") {
		// skip callback to next step
		callbackNoChanged(event.target.value);
		return;
	}
	const _arrA = event.target.value.split(".");
	if (_arrA.length > 1) {
		if (_arrA[1].replace(/0/g, "") === "") {
			// skip callback to next step
			callbackNoChanged(event.target.value);
			return;
		}
	}
	const value = parseFloat(event.target.value || "0");
	if (isNaN(value)) {
		showError("Valeur non valid");
		return;
	} else {
		callback(value);
	}
};

const DevisContratForm = ({
	devis,
	updateDevisOrigin,
	isModeContrat,
	allCompanies,
	sendEmailDevisContrat,
	submitModification,
	showMessage,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const calcul = () => {
		var _old_prime_moyenne_mensuel = isNaN(devis.old_prime_mensuel || 0)
			? 0
			: devis.old_prime_mensuel || 0;
		var _new_prime_moyenne_mensuel = isNaN(devis.new_prime_mensuel || 0)
			? 0
			: devis.new_prime_mensuel || 0;
		if (
			devis.old_prime_mensuel &&
			devis.old_prime_mensuel_last &&
			devis.old_pret_type !== ASSURANCE_PRET_TYPES.constant
		) {
			_old_prime_moyenne_mensuel =
				(devis.old_prime_mensuel + devis.old_prime_mensuel_last) / 2;
		}
		if (typeof _old_prime_moyenne_mensuel.toFixed !== "function") {
			_old_prime_moyenne_mensuel = 0.0;
		}
		updateDevisOrigin(
			"old_prime_moyenne_mensuel",
			_old_prime_moyenne_mensuel.toFixed(2),
		);
		if (
			devis.new_prime_mensuel &&
			devis.new_prime_mensuel_last &&
			devis.new_pret_type !== ASSURANCE_PRET_TYPES.constant
		) {
			_new_prime_moyenne_mensuel =
				(devis.new_prime_mensuel + devis.new_prime_mensuel_last) / 2;
		}
		if (typeof _new_prime_moyenne_mensuel.toFixed !== "function") {
			_new_prime_moyenne_mensuel = 0;
		}
		updateDevisOrigin(
			"new_prime_moyenne_mensuel",
			_new_prime_moyenne_mensuel.toFixed(2),
		);

		if (devis.old_prime_total && devis.new_prime_total) {
			if (typeof devis.new_prime_total.toFixed !== "function") {
				return;
			}
			if (typeof devis.old_prime_total.toFixed !== "function") {
				return;
			}
			const _eco = devis.old_prime_total - devis.new_prime_total;

			var _t = 0;
			if (_eco <= 5000) {
				_t = 10;
			} else if (_eco <= 10000) {
				_t = 8.5;
			} else if (_eco <= 15000) {
				_t = 7.5;
			} else if (_eco <= 20000) {
				_t = 6.5;
			} else {
				_t = 5.5;
			}

			var _frais_dossier_total = (_eco * _t) / 100;

			if (devis.frais_libre) {
				_frais_dossier_total = devis.frais_dossier_total || 0;
			}

			if (devis.pourcentage_reduction > 0) {
				_frais_dossier_total =
					_frais_dossier_total -
					(_frais_dossier_total * devis.pourcentage_reduction) / 100;
			}

			updateDevisOrigin("taux_frais_dossier", _t.toFixed(2));
			if (!devis.frais_libre) {
				updateDevisOrigin("frais_dossier_total", _frais_dossier_total.toFixed(2));
			}
			updateDevisOrigin("frais_dossier", _frais_dossier_total.toFixed(2));


			updateDevisOrigin("prime_ttc", devis.new_prime_total.toFixed(2));
			updateDevisOrigin("economie_total", _eco.toFixed(2));
			updateDevisOrigin(
				"economie_total_pourcentage",
				((_eco / devis.old_prime_total) * 100).toFixed(2),
			);

			updateDevisOrigin(
				"economie_total_moin_frais_dossier",
				(_eco - _frais_dossier_total).toFixed(2),
			);
			updateDevisOrigin(
				"economie_total_moin_frais_dossier_pourcentage",
				(((_eco - _frais_dossier_total) / devis.old_prime_total) * 100).toFixed(
					2,
				),
			);

			const _frais_dossier_assureur =
				45 *
				(1 +
					(devis.emprunteur_type === EMPRUNTEUR_TYPE.co_emprunteur
						? devis.emprunteurs
							? devis.emprunteurs.length
							: 0
						: 0));
			updateDevisOrigin(
				"frais_dossier_assureur",
				_frais_dossier_assureur.toFixed(2),
			);

			var _acompte_a_regler =
				_frais_dossier_total * (parseFloat(devis.pourcentage_acompte) / 100);
			const _frais_dossier_restant = _frais_dossier_total - _acompte_a_regler;

			var _fractionnement_frais_dossier = 0;
			var _nombre_de_prelevement = 0;

			if (devis.fractionnement_manuel !== true) {
				if (
					Math.round(
						_frais_dossier_restant /
						(_old_prime_moyenne_mensuel - _new_prime_moyenne_mensuel),
					) > 24
				) {
					devis.fractionnement_manuel = true;
					devis.nombre_de_prelevement = 12;
					updateDevisOrigin("fractionnement_manuel", true);
				}
			}

			if (devis.fractionnement_manuel) {
				if (devis.nombre_de_prelevement) {
					_nombre_de_prelevement =
						devis.nombre_de_prelevement > 24 ? 12 : devis.nombre_de_prelevement;
				} else {
					_nombre_de_prelevement = 12; // maxi 12
				}
				_fractionnement_frais_dossier =
					_frais_dossier_restant / _nombre_de_prelevement;
			} else {
				_nombre_de_prelevement = Math.round(
					_frais_dossier_restant /
					(_old_prime_moyenne_mensuel - _new_prime_moyenne_mensuel),
				);
				_fractionnement_frais_dossier =
					_frais_dossier_restant / _nombre_de_prelevement;
			}

			if (_acompte_a_regler === 0 && _nombre_de_prelevement > 1) {
				_acompte_a_regler = _frais_dossier_restant / _nombre_de_prelevement;
			}

			updateDevisOrigin("acompte_a_regler", _acompte_a_regler.toFixed(2));
			updateDevisOrigin(
				"frais_dossier_restant",
				_frais_dossier_restant.toFixed(2),
			);


			updateDevisOrigin(
				"fractionnement_frais_dossier",
				_fractionnement_frais_dossier.toFixed(2),
			);
			updateDevisOrigin("nombre_de_prelevement", _nombre_de_prelevement);
			updateDevisOrigin(
				"prime_et_frais_mensuel",
				(_fractionnement_frais_dossier + _new_prime_moyenne_mensuel).toFixed(2),
			);
		}
	};

	const updateDevis = (key, value) => {
		updateDevisOrigin(key, value);
		setTimeout(() => {
			calcul();
		}, 100);
	};

	// defaut date effet
	if (!devis.date_effet) {
		let _now = new Date();
		let _date = new Date(_now.getFullYear(), _now.getMonth() + 1, 10);
		updateDevisOrigin("date_effet", _date);
	}

	if (!devis.hasOwnProperty("pourcentage_acompte")) {
		updateDevisOrigin("pourcentage_acompte", "20");
	}

	return (
		<Box
			gap="small"
			width="100%"
			alignSelf="center"
			align="center"
			pad="small"
			justify="center"
		>
			<Heading level={3} textAlign="center">
				<strong>{isModeContrat ? "CONTRAT" : "DEVIS"}</strong>
				<br />
				<br />
				Renseigner les informations du prêt
			</Heading>

			<Box gap="medium">
				<Card pad="small" direction="row">
					<Line
						leftLabel="Europe ou Hors Europe"
						rightChildren={
							<ToggleButton
								groups={[
									{
										label: "Hors Europe",
										selected: devis.outside_europe === true,
										onClick: () => {
											updateDevisOrigin("outside_europe", true);
											updateDevis("pourcentage_acompte", 0);
										},
									},
									{
										label: "Europe",
										selected: devis.outside_europe !== true,
										onClick: () => {
											updateDevisOrigin("outside_europe", false);
										},
									},
								]}
							/>
						}
					/>
					<Box direction="column" gap="small" fill="horizontal">
						<Line
							leftLabel="Frais libre"
							rightChildren={
								<ToggleButton
									groups={[
										{
											label: "OUI",
											selected: devis.frais_libre === true,
											onClick: () => {
												updateDevisOrigin("frais_libre", true);
												// updateDevis("pourcentage_acompte", 0);
											},
										},
										{
											label: "NON",
											selected: devis.frais_libre !== true,
											onClick: () => {
												updateDevisOrigin("frais_libre", false);
											},
										},
									]}
								/>
							}
						/>
						{devis.frais_libre && (
							<Line
								leftLabel={`Frais dossier`}
								rightChildren={
									<CustomInput
										value={devis.frais_dossier_total}
										onChange={(event) => {
											onAmountChanged(
												event,
												(amount) => {
													updateDevis("frais_dossier_total", amount);
												},
												showMessage,
												(nochanged) => {
													updateDevisOrigin("frais_dossier_total", nochanged);
												},
											);
										}}
									/>
								}
							/>
						)}
					</Box>
				</Card>
				<Box gap="xsmall" direction="row">
					<Card gap="xsmall" pad="medium">
						<Line
							leftLabel="L’ancienne assurance du prêt est"
							rightChildren={
								<ToggleButton
									groups={[
										{
											label: ASSURANCE_PRET_TYPES.variable,
											selected:
												devis.old_pret_type === ASSURANCE_PRET_TYPES.variable,
											onClick: () => {
												updateDevis(
													"old_pret_type",
													ASSURANCE_PRET_TYPES.variable,
												);
											},
										},
										{
											label: ASSURANCE_PRET_TYPES.constant,
											selected:
												devis.old_pret_type === ASSURANCE_PRET_TYPES.constant,
											onClick: () => {
												updateDevis(
													"old_pret_type",
													ASSURANCE_PRET_TYPES.constant,
												);
											},
										},
									]}
								/>
							}
						/>
						<Line
							leftLabel={`Prime total assurance (€)`}
							rightChildren={
								<CustomInput
									value={devis.old_prime_total}
									onChange={(event) => {
										onAmountChanged(
											event,
											(amount) => {
												updateDevis("old_prime_total", amount);
											},
											showMessage,
											(nochanged) => {
												updateDevisOrigin("old_prime_total", nochanged);
											},
										);
									}}
								/>
							}
						/>
						<Line
							leftLabel={`Prime mensuelle assurance (€)`}
							rightChildren={
								<CustomInput
									value={devis.old_prime_mensuel}
									onChange={(event) => {
										onAmountChanged(
											event,
											(amount) => {
												updateDevis("old_prime_mensuel", amount);
											},
											showMessage,
											(nochanged) => {
												updateDevisOrigin("old_prime_mensuel", nochanged);
											},
										);
									}}
								/>
							}
						/>
						{devis.old_pret_type !== ASSURANCE_PRET_TYPES.constant && (
							<Line
								leftLabel={`Dernière prime mensuelle (€)`}
								rightChildren={
									<CustomInput
										value={devis.old_prime_mensuel_last}
										onChange={(event) => {
											onAmountChanged(
												event,
												(amount) => {
													updateDevis("old_prime_mensuel_last", amount);
												},
												showMessage,
												(nochanged) => {
													updateDevisOrigin(
														"old_prime_mensuel_last",
														nochanged,
													);
												},
											);
										}}
									/>
								}
							/>
						)}
						{devis.old_pret_type !== ASSURANCE_PRET_TYPES.constant && (
							<Line
								leftLabel={`Prime moyenne mensuelle  (€)`}
								rightChildren={
									<CustomInput
										value={
											devis.old_prime_moyenne_mensuel
												? devis.old_prime_moyenne_mensuel
												: "-"
										}
										disabled={true}
										style={{ background: "#70A4EC", color: "black" }}
									/>
								}
							/>
						)}
					</Card>
					<Card gap="xsmall" pad="medium">
						<Line
							leftLabel="La nouvelle assurance du prêt est :"
							rightChildren={
								<ToggleButton
									groups={[
										{
											label: ASSURANCE_PRET_TYPES.variable,
											selected:
												devis.new_pret_type === ASSURANCE_PRET_TYPES.variable,
											onClick: () => {
												updateDevis(
													"new_pret_type",
													ASSURANCE_PRET_TYPES.variable,
												);
											},
										},
										{
											label: ASSURANCE_PRET_TYPES.constant,
											selected:
												devis.new_pret_type === ASSURANCE_PRET_TYPES.constant,
											onClick: () => {
												updateDevis(
													"new_pret_type",
													ASSURANCE_PRET_TYPES.constant,
												);
											},
										},
									]}
								/>
							}
						/>
						<Line
							leftLabel={`Prime total assurance (€)`}
							rightChildren={
								<CustomInput
									value={devis.new_prime_total}
									onChange={(event) => {
										onAmountChanged(
											event,
											(amount) => {
												updateDevis("new_prime_total", amount);
											},
											showMessage,
											(nochanged) => {
												updateDevisOrigin("new_prime_total", nochanged);
											},
										);
									}}
								/>
							}
						/>

						<Line
							leftLabel={`Prime mensuelle assurance (€)`}
							rightChildren={
								<CustomInput
									value={devis.new_prime_mensuel}
									onChange={(event) => {
										onAmountChanged(
											event,
											(amount) => {
												updateDevis("new_prime_mensuel", amount);
											},
											showMessage,
											(nochanged) => {
												updateDevisOrigin("new_prime_mensuel", nochanged);
											},
										);
									}}
								/>
							}
						/>
						{devis.new_pret_type !== ASSURANCE_PRET_TYPES.constant && (
							<Line
								leftLabel={`Dernière prime mensuelle (€)`}
								rightChildren={
									<CustomInput
										value={devis.new_prime_mensuel_last}
										onChange={(event) => {
											onAmountChanged(
												event,
												(amount) => {
													updateDevis("new_prime_mensuel_last", amount);
												},
												showMessage,
												(nochanged) => {
													updateDevisOrigin(
														"new_prime_mensuel_last",
														nochanged,
													);
												},
											);
										}}
									/>
								}
							/>
						)}
						{devis.new_pret_type !== ASSURANCE_PRET_TYPES.constant && (
							<Line
								leftLabel={`Prime moyenne mensuelle  (€)`}
								rightChildren={
									<CustomInput
										value={
											devis.new_prime_moyenne_mensuel
												? devis.new_prime_moyenne_mensuel
												: "-"
										}
										disabled={true}
										style={{ background: "#70A4EC", color: "black" }}
									/>
								}
							/>
						)}
					</Card>
				</Box>
				<Box gap="xsmall" direction="row">
					<Card gap="xsmall" pad="medium" width={"100%"}>
						<Box
							gap="medium"
							style={{ width: "100%" }}
							align="center"
							alignSelf="center"
						>
							<Text
								style={{ fontWeight: "bold", textTransform: "uppercase" }}
								size="small"
								textAlign="end"
							>
								Pourcentage d’acompte à régler par le client ?
							</Text>
							<MarksSlider
								disabled={devis.outside_europe}
								step={0.05}
								max={0.8}
								min={0.0}
								value={parseFloat(devis.pourcentage_acompte || 0) / 100}
								marks={[
									{ value: 0.0, label: "0%" },
									{ value: 0.1, label: "10%" },
									{ value: 0.2, label: "20%" },
									{ value: 0.3, label: "30%" },
									{ value: 0.4, label: "40%" },
									{ value: 0.5, label: "50%" },
									{ value: 0.6, label: "60%" },
									{ value: 0.7, label: "70%" },
									{ value: 0.8, label: "80%" },
								]}
								onChange={(event, newValue) =>
									updateDevis(
										"pourcentage_acompte",
										(newValue * 100).toFixed(0),
									)
								}
							/>
						</Box>
					</Card>

					<Card gap="xsmall" pad="medium" width={"100%"}>
						<Box
							gap="medium"
							style={{ width: "100%" }}
							align="center"
							alignSelf="center"
						>
							<Text
								style={{ fontWeight: "bold", textTransform: "uppercase" }}
								size="small"
								textAlign="end"
							>
								Pourcentage de réduction ?
							</Text>
							<MarksSlider
								step={0.01}
								max={0.99}
								min={0.0}
								value={parseFloat(devis.pourcentage_reduction || 0) / 100}
								marks={[
									{ value: 0.0, label: "0%" },
									{ value: 0.1, label: "10%" },
									{ value: 0.2, label: "20%" },
									{ value: 0.3, label: "30%" },
									{ value: 0.4, label: "40%" },
									{ value: 0.5, label: "50%" },
									{ value: 0.6, label: "60%" },
									{ value: 0.7, label: "70%" },
									{ value: 0.8, label: "80%" },
									{ value: 0.9, label: "90%" },
									{ value: 0.99, label: "99%" },
								]}
								onChange={(event, newValue) =>
									updateDevis(
										"pourcentage_reduction",
										(newValue * 100).toFixed(0),
									)
								}
							/>
						</Box>
					</Card>
				</Box>
				<Line
					leftLabel="Régler les frais manuellement ou automatiquement?"
					rightChildren={
						<ToggleButton
							groups={[
								{
									label: "Manuellement",
									selected: devis.fractionnement_manuel === true,
									onClick: () => {
										updateDevisOrigin("fractionnement_manuel", true);
										updateDevis("nombre_de_prelevement", 12);
									},
								},
								{
									label: "Automatiquement",
									selected: devis.fractionnement_manuel !== true,
									onClick: () => {
										updateDevis("fractionnement_manuel", false);
									},
								},
							]}
						/>
					}
				/>
				{devis.fractionnement_manuel && (
					<Card gap="xsmall" pad="medium" background={"#fff3f5"}>
						<Box
							gap="medium"
							style={{ width: "100%" }}
							align="center"
							alignSelf="center"
						>
							<Text
								style={{ fontWeight: "bold", textTransform: "uppercase" }}
								size="small"
								textAlign="end"
							>
								Nombre de prélèvements à régler par le client ?
							</Text>
							<MarksSlider
								step={1}
								max={12}
								min={1}
								value={devis.nombre_de_prelevement}
								marks={[
									{ value: 1, label: "1" },
									{ value: 2, label: "2" },
									{ value: 3, label: "3" },
									{ value: 4, label: "4" },
									{ value: 5, label: "5" },
									{ value: 6, label: "6" },
									{ value: 7, label: "7" },
									{ value: 8, label: "8" },
									{ value: 9, label: "9" },
									{ value: 10, label: "10" },
									{ value: 11, label: "11" },
									{ value: 12, label: "12" },
								]}
								onChange={(event, newValue) => {
									updateDevisOrigin("fractionnement_manuel", true);
									updateDevis("nombre_de_prelevement", newValue);
								}}
							/>
						</Box>
					</Card>
				)}

				<Card>
					<Box pad="small" align="center" background={"#F2F2F2"}>
						<Text>
							<b>Résultat</b>
						</Text>
						<Box direction="row">
							<BlockText
								label="Économie totale"
								value={
									(devis.economie_total ? devis.economie_total : "-") + " €"
								}
							></BlockText>
							<BlockText
								label="Économie totale - frais de dossier"
								value={
									(devis.economie_total_moin_frais_dossier
										? devis.economie_total_moin_frais_dossier
										: "-") + " €"
								}
							></BlockText>
							<BlockText
								label="Économie totale en %"
								value={
									(devis.economie_total_pourcentage
										? devis.economie_total_pourcentage
										: "-") + " %"
								}
							></BlockText>
							<BlockText
								label="Économie totale - frais de dossier en %"
								value={
									(devis.economie_total_moin_frais_dossier_pourcentage
										? devis.economie_total_moin_frais_dossier_pourcentage
										: "-") + " %"
								}
							></BlockText>
						</Box>
					</Box>
					<Box pad="small" align="center" background={"#DADADA"}>
						<Text>
							<b>Frais de dossier</b>
						</Text>
						<Box direction="row" width={"100%"}>
							<BlockText
								width={"25%"}
								label="Taux frais de dossier"
								value={
									(devis.taux_frais_dossier ? devis.taux_frais_dossier : "-") +
									" %"
								}
							></BlockText>
							<BlockText
								width={"25%"}
								label="Frais de dossier total"
								value={
									(devis.frais_dossier_total
										? devis.frais_dossier_total
										: "-") + " €"
								}
							></BlockText>
							<BlockText
								width={`${devis.pourcentage_acompte}` !== "0" ? "25%" : "50%"}
								label={
									`${devis.pourcentage_acompte}` !== "0"
										? "Acompte à regler"
										: "Echéance frais de dossier"
								}
								value={
									(devis.acompte_a_regler ? devis.acompte_a_regler : "-") + " €"
								}
							></BlockText>
							{`${devis.pourcentage_acompte}` !== "0" && (
								<BlockText
									width={"25%"}
									label="Frais de dossier restant"
									value={
										(devis.frais_dossier_restant
											? devis.frais_dossier_restant
											: "-") + " €"
									}
								></BlockText>
							)}
						</Box>
						<Box direction="row" width={"100%"}>
							<BlockText
								width={"25%"}
								label="Taux acompte"
								value={
									(devis.pourcentage_acompte
										? devis.pourcentage_acompte
										: "0") + " %"
								}
							></BlockText>
							<BlockText
								width={"25%"}
								label="Nombre de prélèvements"
								value={
									devis.nombre_de_prelevement
										? devis.nombre_de_prelevement
										: "-"
								}
							></BlockText>
							<BlockText
								width={"50%"}
								label={
									<>
										<strong>Nouvelle prime</strong> (frais dossier inclus)
									</>
								}
								value={
									(devis.prime_et_frais_mensuel
										? devis.prime_et_frais_mensuel
										: "-") + " €"
								}
								desc={
									<>
										dont{" "}
										<strong>
											{devis.fractionnement_frais_dossier
												? devis.fractionnement_frais_dossier
												: "-"}{" "}
											€
										</strong>{" "}
										frais de dossier pendant{" "}
										<strong>
											{devis.nombre_de_prelevement
												? `${devis.pourcentage_acompte}` !== "0"
													? devis.nombre_de_prelevement
													: devis.nombre_de_prelevement - 1
												: "-"}{" "}
										</strong>
										mois.
									</>
								}
								bg="#FE007A"
							></BlockText>
						</Box>
					</Box>
				</Card>
				<Box gap="xsmall">
					<Card align="center" pad="small">
						<strong>
							Information du {isModeContrat ? "contrat" : "devis"}
						</strong>
						<Box width={"large"} gap="small" margin={"small"}>
							<Line
								leftLabel="Fournisseur"
								rightChildren={
									<CustomSelect
										style={{ width: "100%" }}
										placeholder="Fournisseur"
										options={["UTWIN", "IASSUR", "APIVIA", "APRIL", "UGIP", "Néoliane"]}
										value={devis.fournisseur}
										onChange={({ option }) =>
											updateDevis("fournisseur", option)
										}
									/>
								}
							/>
							<Line
								leftLabel="Assureur"
								rightChildren={
									<CustomSelect
										style={{ width: "100%" }}
										placeholder="Assureur"
										options={[
											"AFI ESCA",
											"ALLIANZ",
											"AXA",
											"APRIL",
											"AXERIA",
											"CARDIF",
											"CKB",
											"CNP assurance",
											"FIDELIDAD",
											"GENERALI",
											"GROUPEMNCAP",
											"MACIF",
											"MUTLOG",
											"Malakoff Humanis",
											"NEOLIANE",
											"Prévoir Vie",
											"UGIP"
										]}
										value={devis.assureur}
										onChange={({ option }) => updateDevis("assureur", option)}
									/>
								}
							/>
							<Line
								leftLabel={`Première prime année (€)`}
								rightChildren={
									<CustomInput
										value={devis.premiere_prime_annee}
										onChange={(event) => {
											onAmountChanged(
												event,
												(amount) => {
													updateDevisOrigin("premiere_prime_annee", amount);
													if (
														typeof amount.toFixed === "function" &&
														devis.taux_commision &&
														typeof devis.taux_commision.toFixed === "function"
													) {
														let _commision =
															(amount * devis.taux_commision) / 100;
														updateDevisOrigin(
															"commision",
															_commision.toFixed(2),
														);
													}
												},
												showMessage,
												(nochanged) => {
													updateDevisOrigin("premiere_prime_annee", nochanged);
												},
											);
										}}
									/>
								}
							/>
							<Line
								leftLabel={`Taux de commision (%)`}
								rightChildren={
									<CustomInput
										value={devis.taux_commision}
										onChange={(event) => {
											onAmountChanged(
												event,
												(amount) => {
													updateDevisOrigin("taux_commision", amount);
													if (
														typeof amount.toFixed === "function" &&
														devis.premiere_prime_annee &&
														typeof devis.premiere_prime_annee.toFixed ===
														"function"
													) {
														let _commision =
															(amount * devis.premiere_prime_annee) / 100;
														updateDevisOrigin(
															"commision",
															_commision.toFixed(2),
														);
													}
												},
												showMessage,
												(nochanged) => {
													updateDevisOrigin("taux_commision", nochanged);
												},
											);
										}}
									/>
								}
							/>

							<Line
								leftLabel={`Taux effectif global`}
								rightChildren={
									<CustomInput
										value={devis.taux_effectif_global}
										onChange={(event) => {
											updateDevisOrigin(
												"taux_effectif_global",
												event.target.value,
											);
										}}
									/>
								}
							/>

							<Line
								leftLabel={`Taux endettement`}
								rightChildren={
									<CustomInput
										value={devis.taux_endettement}
										onChange={(event) => {
											updateDevisOrigin(
												"taux_endettement",
												event.target.value,
											);
										}}
									/>
								}
							/>

							{/* <Line
                leftLabel={`Commision (€)`}
                rightChildren={
                  <CustomInput style={{color: "#000", opacity: 0.8}} disabled={true} value={devis.commision} />
                }
              /> */}
							{isModeContrat && (
								<Line
									leftLabel="Numéro de contrat"
									rightChildren={
										<CustomInput
											value={devis.numero_contrat}
											onChange={(ev) =>
												updateDevisOrigin("numero_contrat", ev.target.value)
											}
										/>
									}
								/>
							)}
							<Line
								leftLabel="Date d'effet"
								rightChildren={
									<DateInput
										value={devis.date_effet || null}
										onChange={(date) => updateDevisOrigin("date_effet", date)}
									/>
								}
							/>
						</Box>
						<Box direction="row">
							<Card
								width={"50%"}
								pad={"medium"}
								margin={"small"}
								style={{ paddingRight: 80 }}
							>
								<center>
									<b>Garantie</b>
								</center>
								<Box
									gap="medium"
									direction="row"
									justify="between"
									style={{ width: "100%" }}
								>
									<CheckBox
										style={{
											alignItems: "center",
											alignContent: "center",
											justifyContent: "center",
										}}
										justify="center"
										label={`Décès`}
										checked={devis.garantie_deces}
										onChange={(event) => {
											updateDevisOrigin("garantie_deces", event.target.checked);
										}}
									/>
								</Box>
								<Box
									gap="medium"
									direction="row"
									justify="between"
									style={{ width: "100%" }}
								>
									<CheckBox
										style={{
											alignItems: "center",
											alignContent: "center",
											justifyContent: "center",
										}}
										justify="center"
										label={`PTIA`}
										checked={devis.garantie_ptia}
										onChange={(event) => {
											updateDevisOrigin("garantie_ptia", event.target.checked);
										}}
									/>
								</Box>
								<Box
									gap="medium"
									direction="row"
									justify="between"
									style={{ width: "100%" }}
								>
									<CheckBox
										style={{
											alignItems: "center",
											alignContent: "center",
											justifyContent: "center",
										}}
										justify="center"
										label={`ITT`}
										checked={devis.garantie_itt}
										onChange={(event) => {
											updateDevisOrigin("garantie_itt", event.target.checked);
										}}
									/>
								</Box>
								<Box
									gap="medium"
									direction="row"
									justify="between"
									style={{ width: "100%" }}
								>
									<CheckBox
										style={{
											alignItems: "center",
											alignContent: "center",
											justifyContent: "center",
										}}
										justify="center"
										label={`IPT`}
										checked={devis.garantie_ipt}
										onChange={(event) => {
											updateDevisOrigin("garantie_ipt", event.target.checked);
										}}
									/>
								</Box>

								<Box
									gap="medium"
									direction="row"
									justify="between"
									style={{ width: "100%" }}
								>
									<CheckBox
										style={{
											alignItems: "center",
											alignContent: "center",
											justifyContent: "center",
										}}
										justify="center"
										label={`IPP`}
										checked={devis.garantie_ipp}
										onChange={(event) => {
											updateDevisOrigin("garantie_ipp", event.target.checked);
										}}
									/>
								</Box>

								<Box
									gap="medium"
									direction="row"
									justify="between"
									style={{ width: "100%" }}
								>
									<CheckBox
										style={{
											alignItems: "center",
											alignContent: "center",
											justifyContent: "center",
										}}
										justify="center"
										label={`PE`}
										checked={devis.garantie_pe}
										onChange={(event) => {
											updateDevisOrigin("garantie_pe", event.target.checked);
										}}
									/>
								</Box>
							</Card>
							<Card width={"50%"} pad={"medium"} margin={"small"}>
								<center>
									<b>Option</b>
								</center>
								<CheckBox
									style={{
										alignItems: "center",
										alignContent: "center",
										justifyContent: "center",
									}}
									justify="center"
									label="Affections dorsales et psy sans hospitalisation"
									checked={devis.option_affections_dorsales}
									onChange={(event) => {
										updateDevisOrigin(
											"option_affections_dorsales",
											event.target.checked,
										);
									}}
								/>
								<CheckBox
									style={{
										alignItems: "center",
										alignContent: "center",
										justifyContent: "center",
									}}
									justify="center"
									label="Indemnisation forfaitaire"
									checked={devis.option_indemnisation_forfaitaire}
									onChange={(event) => {
										updateDevisOrigin(
											"option_indemnisation_forfaitaire",
											event.target.checked,
										);
									}}
								/>
							</Card>
						</Box>
						<Card align="center" pad={'small'} direction='row' gap="medium">
							<Box width={'full'}>
								<Line
									leftLabel="IBAN"
									rightChildren={
										<CustomInput
											value={devis.iban}
											onChange={(event) => {
												updateDevis("iban", event.target.value);
											}}
										/>
									}
								/>
							</Box>
							<Box width={'full'}>

								<Line
									leftLabel="BIC"
									rightChildren={
										<CustomInput
											value={devis.bic}
											onChange={(event) => {
												updateDevis("bic", event.target.value);
											}}
										/>
									}
								/>

							</Box>
						</Card>

						{!isModeContrat && (
							<Card width={"medium"} pad={"medium"} gap="small">
								<center>
									<strong>Télécharger les documents</strong>
									<br />
									Glissez-déposez des fichiers ici
								</center>
								<Box direction="column" pad="small" gap="small">
									<Box direction="row" pad="small" gap="large">
										{((!isModeContrat && devis.file_devis) ||
											(isModeContrat && devis.file_contrat)) && (
												<a
													target={"_blank"}
													rel="noreferrer"
													href={`${isModeContrat ? devis.file_contrat : devis.file_devis
														}`}
												>
													<DocumentDownload />
													{isModeContrat ? "Contrat" : "Devis"}
												</a>
											)}

										<FileInput
											messages={{
												browse: "Choisir",
												dropPrompt: isModeContrat ? "Contrat" : "Devis",
											}}
											multiple={false}
											onChange={(event) => {
												setIsLoading(true);
												if (isModeContrat) {
													doUploadContratPDF(
														event.target.files,
														async (data) => {
															if (data.url) {
																updateDevis("file_contrat", data.url);
															}
															setIsLoading(false);
														},
														showMessage,
													);
												} else {
													doUploadFile(
														event.target.files,
														async (data) => {
															if (data.length > 0) {
																let _uploaded = data[0];
																updateDevis("file_devis", _uploaded.url);
															}
															setIsLoading(false);
														},
														showMessage,
													);
												}
											}}
										/>
									</Box>
								</Box>
							</Card>
						)}
					</Card>

					<Box
						align="center"
						margin="medium"
						direction="row"
						gap="small"
						justify="center"
					>
						<PrimaryButton
							disabled={isLoading}
							label={`Sauvegarder`}
							icon={<Checkmark />}
							background="#59A4F4"
							onClick={() => {
								submitModification(devis);
							}}
						/>
						<PrimaryButton
							disabled={isLoading}
							label={`Valider & Envoyer`}
							icon={<Checkmark />}
							onClick={() => {
								sendEmailDevisContrat(devis);
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default DevisContratForm;

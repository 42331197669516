import React from 'react';
import { Box, Tabs, Tab } from 'grommet';
import service from '../../services/dashboard';
import Reports from './reports';
import { navigate } from 'gatsby-link';
import { verifyConnected, logout } from '../../services/login_service';
import { hasAdministrativeRole, hasClassiqueManagerRole } from '../../services/storage_service';
import ReportsClosed from './reports_closed';
import PerduReports from './reports_perdu';
import { getCollabs } from '../../services/admin_service';

class DashboardCredit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indicator: undefined,
            commerciaux: [],
            all_commerciaux: []
        }
    }

    componentDidMount() {
        verifyConnected()
            .then(res => {
            })
            .catch(err => {
                let res = err.response;
                if (res && res.status === 401) {
                    logout();
                    navigate("/login");
                }
            })
        service.getGestionCollabs()
            .then(res => this.setState({ commerciaux: res.data }));

        getCollabs()
            .then(res => this.setState({ all_commerciaux: res.data.map(c => ({ id: c.id, name: `${c.prenom} ${c.nom}` })) }))
            .catch(err => console.log(err));
    }

    render() {
        return (

            <Box style={{ width: "100%" }}
                align="center" justify="center"
                alignSelf="center"
                pad={'medium'}
                alignContent="center" gap="medium">
                <Tabs style={{ width: '100%' }}>
                    {!hasAdministrativeRole() ?
                        <Tab title="Vos statistiques de crédit">
                            <Reports key={2}
                                all_commerciaux={this.state.all_commerciaux}
                                commerciaux={this.state.commerciaux} indicator personal={true}
                                showMessage={this.props.showMessage}
                            />
                        </Tab>
                        :
                        <Tab title="Statistique de crédit de l'équipe">
                            <Reports key={1} commerciaux={this.state.commerciaux}
                                all_commerciaux={this.state.all_commerciaux}
                                showMessage={this.props.showMessage}
                                indicator apptHidden={true} />
                        </Tab>
                    }
                    {hasClassiqueManagerRole() &&
                        <Tab title="Statistique de crédit de l'équipe">
                            <Reports key={1} commerciaux={this.state.commerciaux}
                                all_commerciaux={this.state.all_commerciaux}
                                showMessage={this.props.showMessage}
                                indicator apptHidden={true} />
                        </Tab>
                    }
                    {(hasAdministrativeRole() || hasClassiqueManagerRole()) &&
                        <Tab title="Les crédits clôturés">
                            <ReportsClosed
                                all_commerciaux={this.state.all_commerciaux}
                                showMessage={this.props.showMessage}
                                commerciaux={this.state.commerciaux} indicator apptHidden={true} />
                        </Tab>
                    }
                    {
                        <Tab title="Les crédits perdus">
                            <PerduReports
                                all_commerciaux={this.state.all_commerciaux}
                                showMessage={this.props.showMessage}
                                commerciaux={this.state.commerciaux} indicator apptHidden={true} />
                        </Tab>
                    }
                </Tabs>

            </Box>
        )
    }
}

export default DashboardCredit;

import { Box, Button } from 'grommet';
import contactService from '../../../services/contact_service';
import service from '../../../services/pno_service';
import { filterOnPaymentStatus, getSimpleDateFR, getSorted, toCurrency } from '../../../services/utils';
import AddDevis from '../../elements/add_devis';
import { isSubscribed } from '../../elements/contract_details';
import DateSort from '../../elements/date_sort';

import { Download } from 'grommet-icons';
import moment from 'moment';
import React from 'react';
import Refresh from '../../elements/refresh';
import SearchComponent from '../../elements/search';
import StatusFilter, { SimpleFilter } from '../../elements/status_filter';
import ViewTable from '../../elements/view_table';
import ContratView from '../enligne-components/contrat_view';
import TrustiPNO from './trusti_pno';
import { isExportable } from '../../../services/mh_enligne_service';
import { updateDeal } from '../../../services/common_service';
import { CONTRAT_CLOTURE } from '../../../services/devis_contrat_status';

const processData = data => {
    data.forEach(d => {
        if (d.status !== CONTRAT_CLOTURE) return;
        if (d.stripe_subscription_id) {
            d.display_status_stripe = (d.stripe_sub_status === 'trialing' || d.stripe_sub_status === 'active') ?
                "Abo. en cours" :
                (d.stripe_sub_status === 'canceled') ? "Abo. Annulé" : "Abo. en cours : PB paiement";
        } else {
            d.display_status_stripe = "Déconnecté";
        }
        if (!d.stripe_subscriptions?.length) return;
        const activeSub = d.stripe_subscriptions.find(d => d.subData[0]?.status === 'trialing' || d.subData[0]?.status === 'active');
        if (activeSub) d.stripe_link = "https://dashboard.stripe.com/customers/" + activeSub.customer_id;
    })
    return data;
}

class TrustiPNOs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: this.props.contact || {},
            contacts: [],
            trustiPNOs: [],
            popupOpen: false,
            selectedTrustiPNO: {},
            filter: {
                _query: undefined,
                status: undefined,
                sort: undefined
            }
        }
    }

    componentDidMount = () => {
        this.loadTrustiPNOs();
        contactService.getContact()
            .then(res => {
                this.setState({ contacts: res.data?.results })
            }).catch(err => console.log(err));
    };

    loadTrustiPNOs = () => {
        this.setState({ trustiPNOs: [] }, () => {
            service.getTrustiPNOs(this.state.contact.id)
                .then(res => {
                    this.setState({ trustiPNOs: processData(res.data) })
                })
                .catch(err => {
                    console.log('err', err);
                    this.props.showMessage('Erreur inattendu');
                })
        })
    }

    onClose = () => { this.setState({ popupOpen: false }) }

    openPNO = (trustiPNO) => {
        this.setState({ selectedTrustiPNO: trustiPNO, popupOpen: true });
    }

    addPNOe = () => this.openPNO({ assure: {}, location: {} })

    search = (query, status) => {
        service.getTrustiPNOs(this.state.contact.id, query, status)
            .then(res => {
                if (res.data?.results) {
                    this.setState({ trustiPNOs: processData(res.data?.results) })
                } else if (res.data) {
                    this.setState({ trustiPNOs: processData(res.data) })
                }
            })
            .catch(err => {
                console.log('err', err);
                this.props.showMessage('Erreur inattendu');
            })
    }

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter: Object.assign({}, filter) });
    }

    export = () => {
        let headers = [
            { label: "Statut", prop: 'status' },
            { label: "N° ALIMENT", prop: 'contractNumber' },
            { label: "NOM DU PNO*", prop: 'client.lastname' },
            { label: "PRENOM", prop: 'client.firstname' },
            { label: "ADRESSE DE QUERABILITE (adresse postale du PNO)", prop: 'client.address' },
            { label: 'ADRESSE DU RISQUE', prop: 'address' },
            { label: 'CP', prop: 'zip' },
            { label: 'VILLE', prop: 'city' },
            { label: "DATE D'ADHESION", prop: 'effectiveDate' },
            { label: 'DATE DE SORTIE', prop: 'date_resiliation' },
            { label: 'SURFACE', prop: 'surface' },
            { label: 'NATURE DU BIEN ASSURE', prop: 'meuble' },
            { label: 'PRIME', prop: 'ttc' },
            { label: 'PRIX PAYÉ', prop: 'paid_price' },
            { label: 'PRORATA', prop: '' }
        ]

        this.setState({ loading: true });

        let csvContent = headers.map(s => s.label).join(';') + '\n' +
            this.state.trustiPNOs.filter(p => isExportable(p)).map(deal => {
                return headers.map(s => s.prop).map(prop => {
                    if (!prop) return '';
                    if (prop === 'meuble') {
                        return deal.rawResponse ?
                            `${deal.rawResponse.Type} ${deal.rawResponse.capital_mobilier ? 'meublé(e)' : 'non meublé'}` :
                            '';
                    }
                    if (prop === 'ttc') return toCurrency(deal.rawResponse?.prix_achat_ttc);
                    if (prop === 'effectiveDate') return deal.effectiveDate ? moment(deal.effectiveDate).add(3, 'hours').format('DD/MM/YYYY') : '';
                    if (prop === 'date_resiliation') return deal.date_resiliation ? moment(deal.date_resiliation).add(3, 'hours').format('DD/MM/YYYY') : '';
                    if (prop.includes('client')) {
                        let realProp = prop.replace('client.', '');
                        return realProp === 'address' ?
                            `${deal.client?.address}, ${deal.client?.zip}, ${deal.client?.city},` :
                            (deal.client || {})[realProp];
                    }
                    if (prop === 'paid_price') {
                        const price = deal.estimatedPrice;
                        const promo = deal.promo ? deal.promo.value : 0;
                        return toCurrency(parseFloat(price) - parseFloat(promo));
                    }
                    return deal[prop];
                }).join(';');
            }).join('\n');
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        var blob = new Blob(["\uFEFF" + csvContent], { encoding: "UTF-8", type: "text/csv;charset=UTF-8" }),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `Souscriptions_${getSimpleDateFR(new Date()).replace(/\//g, '-')}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);

        this.setState({ loading: false });
        this.props.showMessage('Exporté !', 'success');
    }

    updateDeal = (deal, data) => {
        updateDeal(deal.id, deal.model_type, data)
            .then(_ => {
                this.props.showMessage('Succès', 'success');
                const index = this.state.trustiPNOs.map(p => p.id).indexOf(deal.id);
                const dealToUpdate = this.state.trustiPNOs[index];
                this.state.trustiPNOs[index] = { ...dealToUpdate, ...data };
                this.setState({ trustiPNOs: [...this.state.trustiPNOs] })
            })
    }

    reloadSelected = _updated => {
        const index = this.state.trustiPNOs.map(p => p.id).indexOf(_updated.id);
        const dealToUpdate = this.state.trustiPNOs[index];
        this.state.trustiPNOs[index] = { ...dealToUpdate, ..._updated };
        this.setState({ trustiPNOs: processData([...this.state.trustiPNOs]) })
        const selected = this.state.trustiPNOs.find(p => p.id === _updated.id);
        this.setState({ selectedTrustiPNO: selected })
    }

    render() {
        return (
            this.state.popupOpen ?
                (isSubscribed(this.state.selectedTrustiPNO?.status) ?
                    <ContratView
                        sub={this.state.selectedTrustiPNO}
                        title="Souscription PNO"
                        assurance_type="Trusti PNO"
                        showMessage={this.props.showMessage}
                        onClose={this.onClose}
                        onReload={this.loadTrustiPNOs}
                        reloadDevis={this.reloadSelected}
                    /> : <TrustiPNO
                        showMessage={this.props.showMessage}
                        devis={this.state.selectedTrustiPNO}
                        onClose={this.onClose}
                        reload={this.loadTrustiPNOs}
                        contacts={this.state.contacts}
                    />)
                :
                <Box style={{ width: "100%" }}
                    align="center" justify="center"
                    alignSelf="center"
                    alignContent="center" gap="medium">
                    <Box width="full" gap="medium" pad={'small'} align='center' justify='center'>
                        <Box width="xlarge" gap="medium" align='center' justify='center'>

                            {!this.state.contact.id &&
                                <>
                                    <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
                                        <Refresh refresh={this.loadTrustiPNOs} />
                                        <SearchComponent
                                            search={value => {
                                                this.search(value, this.state.filter.status);
                                            }}
                                            query={this.state.filter._query}
                                            updateQuery={value => this.updateFilter('_query', value)}
                                        />
                                        <Box direction='row' align='center' gap='small'>
                                            <AddDevis onAdd={this.addPNOe} />
                                            <Box round="full" overflow="hidden" pad="xsmall">
                                                <Button primary icon={<Download />} reverse onClick={this.export} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box justify="between" direction='row' align="center" gap="medium" alignSelf="center" width={'full'}>
                                        <Box width={'full'}>
                                            <StatusFilter
                                                status={this.state.filter.status}
                                                search={value => {
                                                    this.updateFilter('status', value);
                                                    this.search(this.state.filter._query, value);
                                                }}
                                            />
                                        </Box>
                                        <Box width={'full'}>
                                            <DateSort
                                                onChange={value => this.updateFilter('sort', value)}
                                            />
                                        </Box>
                                        <SimpleFilter
                                            label={"Statut de paiement"}
                                            options={[
                                                "Déconnecté",
                                                "Abo. Annulé",
                                                "Abo. en cours",
                                                "Abo. en cours : PB paiement",
                                            ]}
                                            filter={option => this.updateFilter('display_status_stripe', option)}
                                            clear={true}
                                        />

                                    </Box>
                                </>
                            }
                        </Box>
                        {this.state.contact.id && <Box style={{ height: 30 }} />}
                        <ViewTable
                            reports={getSorted(filterOnPaymentStatus(this.state.trustiPNOs, this.state.filter.display_status_stripe), this.state.filter.sort)}
                            commission_taux={this.props.commission_taux}
                            onDevisSelected={this.openPNO}
                            commerciaux={this.props.commerciaux}
                            updateDeal={this.updateDeal}
                        />
                    </Box>
                </Box>
        )
    }
}

export default TrustiPNOs;
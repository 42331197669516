import { Box, Button, CheckBox, Grid } from "grommet";
import {
	CaretLeftFill,
	DocumentMissing,
	DocumentText,
	DocumentVerified,
	Money,
	Send,
	Update
} from "grommet-icons";
import React from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import service from "../../../../services/assurance_vie_service";
import {
	convertToDealPossible,
} from "../../../../services/common_service";
import {
	getAllCompanies,
	sendContratEmail,
	sendDevisEmail,
} from "../../../../services/contact_service";
import {
	CONTRAT_CLOTURE,
	CONTRAT_ENVOYE,
	DEAL_POSSIBLE,
	DEVIS,
	DEVIS_VALIDE,
	EN_ATTENTE_DE_RESILIATION,
	PROSPECT,
	EN_ATTENTE_BANQUE,
	isDealClosable,
} from "../../../../services/devis_contrat_status";
import {
	clearContextBot,
	saveContextBot,
} from "../../../../services/storage_service";
import DealStatusChange from "../../../elements/deal_status_change";
import PrimaryButton from "../../../elements/primary_button";
import { updateClient } from "../../../outils/client_util";
import DevisContratForm from "./devis_contrat";
import Info from "./info";
import Recap from "./recap";

const STEPS = {
  info: "Info",
  recap: "Recap",
  devis: "Devis",
  contrat: "Contrat",
};

const showMessage = (message, type) => {
  toast[type](message);
};

const verifyData = (devis) => {
  let result = true;
  if (!devis.same_address) {
    result = result && !!devis.zip && !!devis.address && !!devis.city;
  }
  result =
    result &&
    !!devis.email &&
    !!devis.phone &&
    !!devis.address &&
    !!devis.zip &&
    !!devis.city &&
    !!devis.civility &&
    !!devis.effectiveDate;

  if (devis.civility === "Société") {
    result = result && !!devis.raisonSocial;
  } else {
    result = result && !!devis.firstname && !!devis.lastname;
  }
  console.log("3", result);

  return result;
};

class AssuranceVie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: props.devis.id ? STEPS.recap : STEPS.info,
      loading: false,
      devis: props.devis.id
        ? props.devis
        : {
            client: {
              civilite: "",
              firstname: "",
              lastname: "",
              email: "",
              phone: "",
              address: "",
              zip: "",
              location_city: "",
              location_address: "",
              location_zip: "",
              city: "",
            },
            begin_date: undefined,
            effectiveDate: new Date(),
          },
    };
    saveContextBot(props.devis);
  }
  setLoading = (value) => {
    this.setState({ loading: value });
  };
  updateDevis = (field, value) => {
    let devis = this.state.devis;
    if(field === 'client') {
      let client = devis.client || {};
      value = {...client, ...value};
    }
    devis[field] = value;
    this.setState({ devis: Object.assign({}, devis) });
  };

  submit = async () => {
    let handler,
      devis = this.state.devis;
    let updatedClient = await updateClient(devis, this.props.showMessage);
    if (!updatedClient) {
      this.props.showMessage(
        "Erreur lors de création/mis à jour de client",
        "error"
      );
      return;
    }
    if (this.state.devis.id) {
      handler = service.modify(this.state.devis);
    } else {
      handler = service.submit(this.state.devis);
    }
    await handler
      .then((res) => {
        this.props.showMessage("Info a été sauvegardé avec succès", "success");
        const nextStep = this.state.step !== STEPS.devis && this.state.step !== STEPS.contrat ? STEPS.recap : this.state.step;
        this.setState({ devis: {...res.data, need_generate_appel: 0}, step: nextStep });
        this.props.reload();
      })
      .catch((err) => {
        console.log(err.response?.data);
        this.props.showMessage("Erreur inattendu", "error");
        devis.client = updatedClient;
      });
  };

  toRecap = () => {
    if (!verifyData(this.state.devis)) {
      showMessage("Veuillez remplir tous les champs", "error");
      return;
    }
    this.setState({ step: STEPS.recap });
  };

  sendForm = async () => {
    service
      .sendForm(this.state.devis)
      .then((res) => {
        this.props.showMessage(
          "Le formulaire a été envoyé avec succès",
          "success"
        );
        this.setState({ devis: res.data });
      })
      .catch((err) => this.props.showMessage("Erreur inattendu", "error"));
  };

  sendSimulation = async () => {
    service
      .sendSimulation(this.state.devis)
      .then((res) => {
        this.props.showMessage(
          "La simulation a été envoyée avec succès",
          "success"
        );
        this.setState({ devis: res.data });
      })
      .catch((err) => this.props.showMessage("Erreur inattendu", "error"));
  };

  sendEmailDevis = (devis, callback) => {
    if (!devis.date_effet || new Date(devis.date_effet) < new Date()) {
      this.props.showMessage(
        "La date d'effet doit être supérieure à la date du jour",
        "error"
      );
      if (callback) callback();
      return;
    }
    devis.status = DEVIS;
    devis.date_devis = new Date();
    devis.model_type = "assurance_vie";
    devis.frais_dossier = devis.frais_dossier || 0;
    service
      .modify(devis)
      .then((res) => {
        this.setLoading(false);
        this.setState({ step: STEPS.recap, devis: res.data });
        sendDevisEmail(devis)
          .then((res) => {
            this.props.showMessage("Devis a été envoyé", "success");
            this.setState({ devis: res.data });
            if (callback) callback();
          })
          .catch((err) => {
            this.props.showMessage("Echec d'envoyer devis", "error");
            if (callback) callback();
          });
      })
      .catch((err) => {
        console.log(err);
        this.props.showMessage("Erreur inattendu", "error");
        this.setLoading(false);
        if (callback) callback();
      });
  };

  sendEmailContrat = (devis, callback) => {
    if (!devis.date_effet || new Date(devis.date_effet) < new Date()) {
      this.props.showMessage(
        "La date d'effet doit être supérieure à la date du jour",
        "error"
      );
      if (callback) callback();
      return;
    }
    if (!devis.file_contrat) {
      this.props.showMessage("Le fichier de contrat manquant", "error");
      return;
    }
    devis.status = CONTRAT_ENVOYE;
    devis.date_contrat = new Date();
    service
      .modify(devis)
      .then(async (res) => {
        //yousign
        // if (devis.file_contrat) {
        //yousign
        // await service
        //   .sendYousign({
        //     id: devis.id,
        //   })
        //   .then(({ data }) => {
        // this.setLoading(false);
        // const _updatedData = { ...res.data, yousign_id: data.yousign_id };
        // this.setState({ step: STEPS.recap, devis: _updatedData });
        sendContratEmail(res.data)
          .then((res) => {
            this.props.showMessage("Contrat a été envoyé", "success");
            this.setState({ devis: {...res.data, need_generate_appel: 0}, step: STEPS.recap });
            if (callback) callback();
          })
          .catch((err) => {
            this.props.showMessage("Echec d'envoyer contrat", "error");
            if (callback) callback();
          });
        // })
        // .catch((err) => {
        //   console.log(err.response.data.error);
        //   this.props.showMessage(
        //     `Erreur: ${err.response.data.error}`,
        //     "error"
        //   );
        //   this.setLoading(false);
        // });
        // } else {
        //   this.props.showMessage("Echec d'envoyer contrat", "error");
        // }
      })
      .catch((err) => {
        console.log(err);
        this.props.showMessage("Erreur inattendu", "error");
        this.setLoading(false);
        if (callback) callback();
      });
  };

  sendRelanceSignature = () => {
    service
      .sendRelanceSignature(this.state.devis)
      .then((res) => this.props.showMessage("Email a été envoyé", "success"))
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer formulaire", "error")
      );
  };

  sendRelancePaiement = () => {
    service
      .sendRelancePayment(this.state.devis)
      .then((res) => this.props.showMessage("Email a été envoyé", "success"))
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer formulaire", "error")
      );
  };

  componentDidMount = () => {
    getAllCompanies()
      .then((res) => {
        let _allComs = res.data?.options;
        console.log("_allComs =", _allComs);
        this.setState({ allCompanies: _allComs });
      })
      .catch((err) => console.log("err = ", err));
  };

  sendPresentation = () => {
    service
      .sendPresentation(this.state.devis)
      .then((res) => {
        this.props.showMessage("Présentation a été envoyé", "success");
        this.props.reload();
        this.setState({ devis: res.data });
      })
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer présentation", "error")
      );
  };

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.loading}>
        <Box pad="small" direction="row" gap="20px" margin="0 0 20px 0" align="center" >
          <Box>
            <PrimaryButton
              icon={<CaretLeftFill />}
              label="Retour"
              onClick={() => {
                clearContextBot();
                this.props.onClose && this.props.onClose();
              }}
            />
          </Box>
          <Button
            color="brand"
            icon={<Update />}
            onClick={() => {
              this.props.reload();
            }}
          />
        </Box>
        {this.state.step === STEPS.info && (
          <Info
            devis={this.state.devis}
            updateDevis={this.updateDevis}
            toRecap={this.toRecap}
            showMessage={showMessage}
			onNext={this.submit}
          />
        )}
        {this.state.step === STEPS.recap && (
          <Recap
            devis={this.state.devis}
            sendDevis={this.sendDevis}
            submit={this.submit}
            toEditMode={() => this.setState({ step: STEPS.info })}
            updateDevis={this.updateDevis}
            onSave={this.submit}
            showMessage={showMessage}
            reload={this.props.reload}
            setIsLoading={this.setLoading}
          >
            <Grid
              columns={["1/3", "1/3", "1/3"]}
              width="calc(100% - 20px)"
              gap="small"
            >
              <Box gap="small">
                <PrimaryButton
                  onClick={this.sendPresentation}
                  label={`Envoyer une présentation`}
                  icon={<Send />}
                />
                {this.state.devis.quiz_1 &&
                  !isDealClosable(this.state.devis) && (
                    <PrimaryButton
                      label={`Envoyer un devis`}
                      icon={<DocumentText />}
                      background="#64AEF6"
                      onClick={() => this.setState({ step: STEPS.devis })}
                    />
                  )}
                {([DEVIS_VALIDE, CONTRAT_ENVOYE, CONTRAT_CLOTURE].includes(
                  this.state.devis.status
                ) ||
                  this.state.devis.resiliation_done) && (
                  <PrimaryButton
                    label={`Envoyer un contrat`}
                    icon={<DocumentVerified />}
                    background="#64AEF6"
                    onClick={() => this.setState({ step: STEPS.contrat })}
                  />
                )}
              </Box>
              <Box gap="small">
                <PrimaryButton
                  label={`Envoyer formulaire`}
                  icon={<Send />}
                  background="#28a745"
                  onClick={this.sendForm}
                />
                {this.state.devis?.status === PROSPECT && (
                  <PrimaryButton
                    label="Convertir à Deal possible"
                    icon={<Update />}
                    onClick={() => {
                      let devis = this.state.devis;
                      devis.status = DEAL_POSSIBLE;
                      convertToDealPossible(devis, "assurance-vie").then((res) =>
                        this.setState({ devis: res.data })
                      );
                    }}
                  />
                )}
                {[CONTRAT_ENVOYE, CONTRAT_CLOTURE, EN_ATTENTE_BANQUE].includes(
                  this.state.devis.status
                ) && (
                  <>
                    <CheckBox
                      style={{
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                      justify="center"
                      label="Contrat signé"
                      checked={this.state.devis.contract_signed}
                      onChange={(event) => {
                        this.updateDevis(
                          "contract_signed",
                          event.target.checked
                        );
                        if (
                          (this.state.devis.contract_paid ||
                            !(this.state.devis.frais_dossier > 0)) &&
                          event.target.checked
                        ) {
                          this.updateDevis("status", CONTRAT_CLOTURE);
                        } else {
                          this.updateDevis("status", CONTRAT_ENVOYE);
                        }
                        this.submit();
                      }}
                    />
                    <PrimaryButton
                      label={`Relance signature`}
                      icon={<DocumentVerified />}
                      onClick={() => {
                        this.sendRelanceSignature();
                      }}
                    />
                    {this.state.devis.frais_dossier > 0 && (
                      <>
                        <CheckBox
                          style={{
                            alignItems: "center",
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                          justify="center"
                          label="Contrat payé"
                          checked={this.state.devis.contract_paid}
                          onChange={(event) => {
                            this.updateDevis(
                              "contract_paid",
                              event.target.checked
                            );
                            if (
                              this.state.devis.contract_signed &&
                              event.target.checked
                            ) {
                              this.updateDevis("status", CONTRAT_CLOTURE);
                            } else {
                              this.updateDevis("status", CONTRAT_ENVOYE);
                            }
                            this.submit();
                          }}
                        />
                        <PrimaryButton
                          label={`Relance paiement`}
                          icon={<Money />}
                          onClick={() => {
                            this.sendRelancePaiement();
                          }}
                        />
                      </>
                    )}
                  </>
                )}
                {[DEVIS_VALIDE].includes(this.state.devis.status) && (
                  <PrimaryButton
                    label={`Envoyer vers résiliation`}
                    icon={<DocumentMissing />}
                    background="#000"
                    onClick={() => {
                      this.updateDevis("status", EN_ATTENTE_DE_RESILIATION);
                      this.updateDevis("date_resiliation", new Date());
                      this.submit();
                    }}
                  />
                )}
                {([EN_ATTENTE_DE_RESILIATION].includes(
                  this.state.devis.status
                ) ||
                  this.state.devis.resiliation_done) && (
                  <Box
                    direction="row"
                    margin="small"
                    gap="medium"
                    align="center"
                    justify="center"
                  >
                    <CheckBox
                      style={{
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                      justify="center"
                      label="Résiliation effectuée"
                      checked={this.state.devis.resiliation_done}
                      disabled={this.state.devis.resiliation_done}
                      onChange={(event) => {
                        this.updateDevis(
                          "resiliation_done",
                          event.target.checked
                        );
                        this.submit();
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box gap="small">
                <DealStatusChange
                  deal={this.state.devis}
                  showMessage={this.props.showMessage}
                  onReload={this.props.reload}
                  path={"assurance-vie"}
                />
              </Box>
            </Grid>
          </Recap>
        )}
        {(this.state.step === STEPS.devis ||
          this.state.step === STEPS.contrat) && (
          <DevisContratForm
            contact={this.state.contact}
            devis={this.state.devis || {}}
            updateDevis={this.updateDevis}
            allCompanies={this.state.allCompanies}
            showMessage={this.props.showMessage}
            submitModification={this.submit}
            isModeContrat={this.state.step === STEPS.contrat}
            onClose={() => this.setState({step: STEPS.recap})}
            sendEmailDevisContrat={
              this.state.step === STEPS.contrat
                ? this.sendEmailContrat
                : this.sendEmailDevis
            }
          />
        )}
      </BlockUi>
    );
  }
}
export default AssuranceVie;

import { Box } from "grommet";
import {
	Previous,
	Edit,
	Send,
} from "grommet-icons";
import React from "react";
import service, {
	sendYousign,
} from "../../../../services/credit_service";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Info from "./info";
import PrimaryButton from "../../../elements/primary_button";
import {
	getAllCompanies,
	sendContratEmail,
	sendDevisEmail,
} from "../../../../services/contact_service";
import {
	CONTRAT_ENVOYE,
	DEVIS
} from "../../../../services/devis_contrat_status";
import DevisContratForm from "./devis_contrat";
import { EMPRUNTEUR_TYPE } from "../../../../services/types";
import { updateClient } from "../../../outils/client_util";
import {
	clearContextBot,
	saveContextBot,
} from "../../../../services/storage_service";
import { sendPresentation, sendEnvelopEmp } from "../../../../services/common_service";
import CreditDealStatusChange from "./credit_status_change";
import BanqueInfo from "./bank_info";
import { CLOTURE, DOSSIER_ENVOYE_A_LA_BANQUE, FACTURE, NOUVEAU, OFFRE_ACCEPTE_PAR_CLIENT, PRET_ACCORDE_PAR_LA_BANQUE, REALISE } from "../../../../services/credit_status";
import RecapRegroupe from "./recap";
import RecapCredit from "./recap_credit";
import { getGestionCollabs } from "../../../../services/dashboard";

const STEPS = {
	info: "Info",
	recap: "Recap",
	devis: "Devis",
	contrat: "Contrat",
};
class Emprunteur extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			devis: this.props.devis,
			originalDevis: Object.assign({}, this.props.devis),
			step: this.props.devis.id ? STEPS.recap : STEPS.info,
			loading: false,
			needToSave: false,
			analystes: [],
		};
		saveContextBot(props.devis);
	}

	setLoading = (value) => {
		this.setState({ loading: value });
	};

	setNeedToSave = (value) => {
		this.setState({ needToSave: value });
	};

	updateDevis = (field, value) => {
		let devis = this.state.devis;
		if (field === 'client') {
			let client = devis.client || {};
			value = { ...client, ...value };
		}
		devis[field] = value;
		this.setState({ devis: Object.assign({}, devis) });
		console.log(devis);
		if (field === 'sub_product') {
			this.setNeedToSave(true);
		}
	};

	sendEmailDevis = (devis) => {
		if (!devis.date_effet || new Date(devis.date_effet) < new Date()) {
			this.props.showMessage(
				"La date d'effet doit être supérieure à la date du jour",
				"error",
			);
			return;
		}
		devis.status = DEVIS;
		devis.date_devis = new Date();
		devis.model_type = "emprunteur_horsligne";
		devis.frais_dossier = devis.frais_dossier || 0;
		service
			.updateCredit(devis)
			.then((res) => {
				this.setLoading(false);
				this.setState({ step: STEPS.recap, devis: { ...res.data, need_generate_appel: 0 } });
				this.props.reloadDeal && this.props.reloadDeal({ ...res.data, need_generate_appel: 0 });
				sendDevisEmail(devis)
					.then((res) =>
						this.props.showMessage("Dossier a été envoyé", "success"),
					)
					.catch((err) =>
						this.props.showMessage("Echec d'envoyer devis", "error"),
					);
			})
			.catch((err) => {
				console.log(err);
				this.props.showMessage("Erreur inattendu", "error");
				this.setLoading(false);
			});
	};

	sendEmailContrat = (devis) => {
		if (!devis.date_effet || new Date(devis.date_effet) < new Date()) {
			this.props.showMessage(
				"La date d'effet doit être supérieure à la date du jour",
				"error",
			);
			return;
		}
		devis.status = CONTRAT_ENVOYE;
		devis.date_contrat = new Date();
		service
			.updateCredit(devis)
			.then(async (res) => {
				//yousign
				if (devis.file_contrat) {
					//yousign
					await sendYousign({
						id: devis.id,
					})
						.then(({ data }) => {
							this.setLoading(false);
							const _updatedData = { ...res.data, yousign_id: data.yousign_id };
							this.setState({ step: STEPS.recap, devis: _updatedData });
							this.props.reloadDeal && this.props.reloadDeal(_updatedData);
							sendContratEmail(_updatedData)
								.then((res) =>
									this.props.showMessage("Contrat a été envoyé", "success"),
								)
								.catch((err) =>
									this.props.showMessage("Echec d'envoyer contrat", "error"),
								);
						})
						.catch((err) => {
							console.log(err.response.data.error);
							this.props.showMessage(
								`Erreur: ${err.response.data.error}`,
								"error",
							);
							this.setLoading(false);
						});
				} else {
					this.setLoading(false);
					const _updatedData = { ...res.data };
					this.setState({ step: STEPS.recap, devis: _updatedData });
					sendContratEmail(_updatedData)
						.then((res) =>
							this.props.showMessage("Contrat a été envoyé", "success"),
						)
						.catch((err) =>
							this.props.showMessage("Echec d'envoyer contrat", "error"),
						);
				}
			})
			.catch((err) => {
				console.log(err);
				this.props.showMessage("Erreur inattendu", "error");
				this.setLoading(false);
			});
	};

	validate = (devis) => {
		console.log("validate = ", devis);
		if (!devis.nationality) return "nationalité";
		if (!devis.birth_country) return "pays de naissance";
		if (!devis.birth_city) return "ville de naissance";
		if (!devis.zip) return "code postal";
		if (!devis.professional_status) return "STATUT PROFESSIONNEL";
		if (!devis.risk_profession) return "PROFESSION À RISQUE";

		if (!devis.objet_financement) return "Objet du financement";
		if (!devis.banque) return "nom de votre banque";

		if (devis.emprunteur_type === EMPRUNTEUR_TYPE.co_emprunteur) {
			for (let i = 0; i < devis.emprunteurs.length; i++) {
				const element = devis.emprunteurs[i];

				if (!element.firstname) return "prénom";
				if (!element.lastname) return "nom";
				if (!element.email) return "email";

				if (!element.nationality) return "nationalité";
				if (!element.birth_country) return "pays de naissance";
				if (!element.birth_city) return "ville de naissance";
				if (!element.zip) return "code postal";
				if (!element.professional_status) return "STATUT PROFESSIONNEL";
				if (!element.risk_profession) return "PROFESSION À RISQUE";
			}
		}

		return null;
	};

	validateOffre = (devis) => {
		if (!devis.banque) return "Banque";
		if (!devis.emprunte_total && !devis.montant) return "Montant emprunté";
		// if (!devis.duree) return "Nombre d’année";
		if (!devis.commission_total) return "Commission (Assurance + Crédit)";
		if (!devis.frais_dossier) return "Frais de dossier";
		// if (!devis.fractionnement) return "Fractionnement";
		// if (!devis.frais_dossier_l) return "Mensualité des frais de dossier";
		// if (!devis.date_effet) return "Date du début prélèvement";
		// if (!devis.sub_product) return "Produit";
		return null;
	}

	submitModification = async (options = {}) => {
		let devis = this.state.devis;
		let err = false;
		if (devis.status === CLOTURE) {
			err = this.validateOffre(this.state.devis);
		}
		if (err && !options.skipValidation) {
			this.props.showMessage(`${err.toUpperCase()} est obligatoire`, "error");
		} else {
			let updatedClient = await updateClient(devis, this.props.showMessage);
			if (!updatedClient) {
				this.props.showMessage("Erreur lors de création/mis à jour de client", "error");
				return;
			}
			// override devis date of birth by client date of birth
			devis.date_of_birth = updatedClient.date_of_birth;
			if (!devis.id) {
				devis.status = NOUVEAU;
				await service
					.createCredit(devis)
					.then((res) => {
						this.props.reloadDeal && this.props.reloadDeal(res.data);
						this.props.showMessage("Dossier a été bien créé", "success");
						this.setLoading(false);
						this.setNeedToSave(false);
						this.setState({ step: STEPS.recap, devis: res.data });
					})
					.catch((err) => {
						console.log(err.response?.data);
						this.props.showMessage("Erreur inattendu", "error");
						this.setLoading(false);
						this.setNeedToSave(false);
						devis.client = updatedClient;
					});
			} else {
				await service
					.updateCredit(devis)
					.then((res) => {
						this.props.reloadDeal && this.props.reloadDeal({ ...res.data, need_generate_appel: 0 });
						this.props.showMessage("Dossier a été bien mis à jour", "success");
						this.setLoading(false);
						this.setNeedToSave(false);
						this.setState({ step: STEPS.recap, devis: { ...res.data, need_generate_appel: 0 } });
					})
					.catch((err) => {
						console.log(err.response?.data);
						this.props.showMessage("Erreur inattendu", "error");
						this.setLoading(false);
						this.setNeedToSave(false);
						devis.client = updatedClient;
					});
			}
		}

	};

	openEditPopup = () => {
		this.setState({ selectedDevis: this.state.devis, editMode: true });
	};

	openDeletePopup = () => {
		this.setState({ selectedDevis: this.state.devis, deletePopupOpen: true });
	};

	resetChange = () => {
		this.setState({
			devis: Object.assign({}, this.state.originalDevis),
			step: STEPS.recap,
		});
	};

	sendForm = async () => {
		service
			.sendForm(this.state.devis)
			.then((res) => {
				this.props.showMessage("Formulaire a été envoyé", "success");
				this.setState({ devis: res.data });
			})
			.catch((err) =>
				this.props.showMessage("Echec d'envoyer formulaire", "error"),
			);
	};

	validationData = () => {
		return this.validateOffre(this.state.devis);
	};

	sendFormAccepte = async () => {
		// valide if we have all info
		let err = this.validateOffre(this.state.devis);
		if (err) {
			this.props.showMessage(`${err.toUpperCase()} est obligatoire`, "error");
		} else {

			if (this.state.devis.status === DOSSIER_ENVOYE_A_LA_BANQUE) {
				// update status
				let devis = this.state.devis;
				devis.status = PRET_ACCORDE_PAR_LA_BANQUE;
				await service
					.updateCredit(devis)
					.then((res) => {
						this.props.reloadDeal && this.props.reloadDeal({ ...res.data, need_generate_appel: 0 });
						this.props.showMessage("Dossier a été bien mis à jour", "success");
						this.setLoading(false);
						this.setState({ step: STEPS.recap, devis: { ...res.data, need_generate_appel: 0 } });
					})
					.catch((err) => {
						console.log(err.response?.data);
						this.props.showMessage("Erreur inattendu", "error");
						this.setLoading(false);
					});
			}

			service
				.sendForm({ ...this.state.devis, need_accepte: true })
				.then((res) => {
					this.props.showMessage("Offre a été envoyé", "success");
					this.setState({ devis: res.data });
					this.props.reloadDeal && this.props.reloadDeal(res.data);
				})
				.catch((err) =>
					this.props.showMessage("Echec d'envoyer Offre", "error"),
				);
		}
	};
	sendRelanceSignature = () => {
		service
			.sendRelanceSignature(this.state.devis)
			.then((res) => {
				this.props.showMessage("Email a été envoyé", "success");
				this.setState({ devis: res.data });
				this.props.reloadDeal && this.props.reloadDeal(res.data);
			})
			.catch((err) =>
				this.props.showMessage("Echec d'envoyer formulaire", "error"),
			);
	};

	sendRelancePaiement = () => {
		service
			.sendRelancePayment(this.state.devis)
			.then((res) => {
				this.props.showMessage("Email a été envoyé", "success");
				this.setState({ devis: res.data });
				this.props.reloadDeal && this.props.reloadDeal(res.data);
			})
			.catch((err) =>
				this.props.showMessage("Echec d'envoyer formulaire", "error"),
			);
	};

	componentDidMount = () => {
		getAllCompanies()
			.then((res) => {
				let _allComs = res.data?.options;
				console.log("_allComs =", _allComs);
				this.setState({ allCompanies: _allComs });
			})
			.catch((err) => console.log("err = ", err));

		getGestionCollabs()
			.then((res) => this.setState({ analystes: res.data }));

	};

	sendPresentation = (index) => {
		if (index === 1) {
			sendPresentation(this.state.devis, { offre_pret: true })
				.then((res) => {
					this.props.showMessage("Présentation a été envoyé", "success");
					this.props.reloadDeal && this.props.reloadDeal(res.data);
					this.setState({ devis: res.data })
				})
				.catch((err) =>
					this.props.showMessage("Echec d'envoyer présentation", "error")
				);

		} else {
			sendPresentation(this.state.devis)
				.then((res) => {
					this.props.showMessage("Présentation a été envoyé", "success");
					this.props.reloadDeal && this.props.reloadDeal(res.data);
					this.setState({ devis: res.data })
				})
				.catch((err) =>
					this.props.showMessage("Echec d'envoyer présentation", "error")
				);
		}
	};

	sendEmailEnvelop = (mail_type) => {
		console.log('send envenlop: ', this.state.devis);
		sendEnvelopEmp(this.state.devis, { sub_product: this.state.devis.sub_product, mail_type })
			.then((res) => {
				this.props.showMessage("Email a été envoyé", "success");
				this.props.reloadDeal && this.props.reloadDeal(res.data);
				this.setState({ devis: res.data })
			})
			.catch((err) =>
				this.props.showMessage("Echec d'envoyer email", "error")
			);
	}

	reloadStatus = (deal) => {
		console.log('deal = ', deal);
		this.setState({ devis: deal });
		this.props.reloadDeal && this.props.reloadDeal(deal);
	}

	render() {
		return (
			<BlockUi tag="div" blocking={this.state.loading}>
				<Box fill direction="row" justify='stretch' align="start" style={{
					background: (this.state.devis.sub_product === 'Regroupement de crédit' && this.state.step !== STEPS.info) ? '#EBEFF4' : 'white'
				}}>
					<Box style={{ marginTop: 40 }}>
						<PrimaryButton
							icon={<Previous />}
							label={"Retour"}
							onClick={() => {
								clearContextBot();
								this.props.onClose && this.props.onClose();
							}}
						/>
					</Box>
					<Box justify="center" align="center" fill>
						{this.state.step === STEPS.info && (
							<Info
								devis={this.state.devis || {}}
								updateDevis={this.updateDevis}
								onNext={() => this.submitModification({ skipValidation: true })}
								contact={this.state.contacts}
								showMessage={this.props.showMessage}
							/>
						)}
						{(this.state.step === STEPS.devis ||
							this.state.step === STEPS.contrat) && (
								<DevisContratForm
									contact={this.state.contact}
									devis={this.state.devis || {}}
									updateDevisOrigin={this.updateDevis}
									allCompanies={this.state.allCompanies}
									showMessage={this.props.showMessage}
									submitModification={this.submitModification}
									isModeContrat={this.state.step === STEPS.contrat}
									sendEmailDevisContrat={
										this.state.step === STEPS.contrat
											? this.sendEmailContrat
											: this.sendEmailDevis
									}
								/>
							)}
						{this.state.step === STEPS.recap && this.state.devis.sub_product === 'Regroupement de crédit' && (
							<RecapRegroupe
								devis={this.state.devis}
								close={this.props.onClose}
								toEditMode={() => this.setState({ step: STEPS.info })}
								sendForm={this.sendForm}
								updateDevis={this.updateDevis}
								onSave={this.submitModification}
								showMessage={this.props.showMessage}
								needToSave={this.state.needToSave}
								setIsLoading={this.setLoading}
								reload={(_devis) => {
									this.setState({ devis: _devis });
								}}
								analystes={this.state.analystes}
							/>
						)}

						{this.state.step === STEPS.recap && this.state.devis.sub_product !== 'Regroupement de crédit' && (
							<Box>
								<RecapCredit
									devis={this.state.devis}
									close={this.props.onClose}
									toEditMode={() => this.setState({ step: STEPS.info })}
									sendForm={this.sendForm}
									updateDevis={this.updateDevis}
									onSave={this.submitModification}
									showMessage={this.props.showMessage}
									reload={(_devis) => { 
										this.setState({ devis: _devis });
									}}
								/>
								<BanqueInfo
									devis={this.state.devis}
									updateDevis={this.updateDevis}
								/>
							</Box>
						)}
						<Box style={{ height: 40 }} />

						<Box direction="row" align="center" justify="center" gap="medium">
							{this.state.step === STEPS.recap && (
								<PrimaryButton
									label={`Envoyer formulaire`}
									icon={<Send />}
									background="#68A0A0"
									onClick={this.sendForm}
								/>
							)}
							{this.state.step === STEPS.recap && [DOSSIER_ENVOYE_A_LA_BANQUE, PRET_ACCORDE_PAR_LA_BANQUE, OFFRE_ACCEPTE_PAR_CLIENT, REALISE, FACTURE, CLOTURE].includes(this.state.devis.status) && (
								<PrimaryButton
									label={`Envoyer offre`}
									icon={<Send />}
									background="#28a745"
									onClick={this.sendFormAccepte}
								/>
							)}
							{this.state.step === STEPS.recap && (
								<PrimaryButton
									label={`Valider`}
									icon={<Edit />}
									onClick={() => this.submitModification()}
								/>
							)}
							{this.state.step === STEPS.recap && (
								<PrimaryButton
									label={`Envoyer mail approche`}
									icon={<Send />}
									onClick={() => this.sendEmailEnvelop("approche")}
								/>
							)}

							{this.state.step === STEPS.recap && (
								<PrimaryButton
									label={`Dossier incomplet, demander les pièces du client`}
									icon={<Send />}
									onClick={() => this.sendEmailEnvelop("not_conform")}
								/>
							)}
							{/*
							{this.state.step === STEPS.recap && (
								<PrimaryButton
									label={`Envoyer mail envoie en banque`}
									icon={<Send />}
									onClick={() => this.sendEmailEnvelop("en_banque")}
								/>
							)} */}

							{this.state.step === STEPS.recap && (
								<PrimaryButton
									label={`Demander les pièces du client`}
									icon={<Send />}
									onClick={() => this.sendEmailEnvelop("upload_documents")}
								/>
							)}

							{this.state.step === STEPS.recap &&
								<CreditDealStatusChange
									validation={this.validationData}
									deal={this.state.devis}
									showMessage={this.props.showMessage}
									onReload={this.reloadStatus}
									path={'credits'}
								/>
							}

						</Box>

						<Box style={{ height: 100 }} />
					</Box>
				</Box>
			</BlockUi>
		);
	}
}

export default Emprunteur;

const axios = require('axios');
const storageService = require('./storage_service');
const { baseAPI } = require('../config/config.json');
const { EN_ETUDE_DE_ANALYSTE, EN_ETUDE_DE_PRE_ANALYSTE } = require('./credit_status');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const createCredit = (devis) => {
    let commercial_id = storageService.getCommercialId();
    if (commercial_id) {
        devis.commercial_id = commercial_id;
    }
    return axios.post(`${baseAPI}/credits`, devis, { headers: HEADERS });
}

const getCredits = (client_id, query, status, sub_product, sub_product_detail) => {
    let commercial_id = storageService.getCommercialId();
    let params = { commercial_id, status, sub_product, _limit: -1 };
    if (sub_product_detail) {
        params.sub_product_detail = sub_product_detail;
    }
    if (!params.status && !query) {
        delete params.status;
        params["status_nin"] = ["Archivé", "Perdu", "Abandons"];
    }
    let binome_id = storageService.getBinomeId();
    if (binome_id) {
        delete params.commercial_id;
        params['_where[_or][7][commercial_id_in]'] = [commercial_id, binome_id];
    }
    if (storageService.hasAdministrativeRole() ||
        storageService.hasCreditManagerRole() ||
        storageService.hasClassiqueManagerRole() ||
        storageService.hasChefAnalysteRole()
    ) {
        delete params.commercial_id;
        delete params['_where[_or][7][commercial_id_in]']
    }
    if (client_id) {
        params['_where[1][client.id]'] = client_id;
        delete params.commercial_id;
    }
    let _where = "";
    if (query) {
        _where = `?_where[_or][0][client.email_contains]=${query}&_where[_or][1][client.firstname_contains]=${query}&_where[_or][2][client.lastname_contains]=${query}&_where[_or][3][apporteur.email_contains]=${query}&_where[_or][4][apporteur.prenom_contains]=${query}&_where[_or][5][apporteur.nom_contains]=${query}&_where[_or][6][id]=${query}`
        delete params.commercial_id;
    }
    // preanalyste filter
    if(storageService.hasPreAnalysteRole() ||
        storageService.hasAnalysteRole()) {
        delete params.commercial_id;
        if (params.commercial_ids === "All") {
            delete params.commercial_ids;
        } else {
            params.commercial_id_in = params.commercial_ids;
            delete params.commercial_ids;
        }
    }
    if(storageService.hasPreAnalysteRole()) {
        params.status_in = [EN_ETUDE_DE_PRE_ANALYSTE];
        params.pre_analyste_id = storageService.getUserId();
    }
    if(storageService.hasAnalysteRole()) {
        if(_where) {
            _where += `&_where[_or][0][status_in]=${[EN_ETUDE_DE_PRE_ANALYSTE]}&_where[_or][1][analyste_id]=${storageService.getUserId()}`
        } else {
            _where = `?_where[_or][0][status_in]=${[EN_ETUDE_DE_PRE_ANALYSTE]}&_where[_or][1][analyste_id]=${storageService.getUserId()}`
        }
    }
    return axios.get(`${baseAPI}/credits${_where}`, { params, headers: HEADERS });
}

const getCreditsDashboard = (filter, isClosed = false) => {
    let commercial_id = storageService.getCommercialId();
    let _params = { ...filter, commercial_id };
    if (storageService.hasAdministrativeRole() ||
        storageService.hasCreditManagerRole() ||
        storageService.hasClassiqueManagerRole() ||
        storageService.hasChefAnalysteRole()) {
        delete _params.commercial_id;
        if (_params.commercial_ids === "All") {
            delete _params.commercial_ids;
        } else {
            _params.commercial_id_in = _params.commercial_ids;
            delete _params.commercial_ids;
        }
    }

    // binome filter
    if (_params.commercial_ids?.length) {
        delete _params.commercial_id;
        _params.commercial_id_in = _params.commercial_ids;
        delete _params.commercial_ids;
    }
    // console.log('_params = ', _params);

    if (_params.startDate && _params.endDate) {
        if (isClosed) {
            _params.date_cloture_gte = _params.startDate;
            _params.date_cloture_lte = _params.endDate;
        } else {
            _params.createdAt_gte = _params.startDate;
            _params.createdAt_lte = _params.endDate;
        }

        delete _params.startDate;
        delete _params.endDate;
    }
    let _where = "";
    if (_params.query) {
        _where = `?_where[_or][0][client.email_contains]=${_params.query}&_where[_or][1][client.firstname_contains]=${_params.query}&_where[_or][2][client.lastname_contains]=${_params.query}&_where[_or][3][apporteur.email_contains]=${_params.query}&_where[_or][4][apporteur.prenom_contains]=${_params.query}&_where[_or][5][apporteur.nom_contains]=${_params.query}&_where[_or][6][id]=${_params.query}`
        delete _params.query;
    }


    // preanalyste filter
    if(storageService.hasPreAnalysteRole() ||
        storageService.hasAnalysteRole()) {
        delete _params.commercial_id;
        if (_params.commercial_ids === "All") {
            delete _params.commercial_ids;
        } else {
            _params.commercial_id_in = _params.commercial_ids;
            delete _params.commercial_ids;
        }
    }
    if(storageService.hasPreAnalysteRole()) {
        _params.status_in = [EN_ETUDE_DE_PRE_ANALYSTE];
        _params.pre_analyste_id = storageService.getUserId();
    }
    if(storageService.hasAnalysteRole()) {
        _params.status_in = [EN_ETUDE_DE_ANALYSTE];
        _params.analyste_id = storageService.getUserId();
    }

    return axios.get(`${baseAPI}/credits${_where}`, { params: { ..._params, _limit: -1 }, headers: HEADERS });
}

const getCreditsById = (id) => {
    return axios.get(`${baseAPI}/credits/${id}`, { headers: HEADERS });
}

const updateCredit = (devis) => {
    return axios.put(`${baseAPI}/public/credits/${devis.id}`, devis, { headers: HEADERS });
}

const closeAndCopyCredit = (devis) => {
    return axios.post(`${baseAPI}/credits/close_and_copy`, { id: `${devis.id}` }, { headers: HEADERS });
}

const sendForm = (devis) => {
    return axios.post(`${baseAPI}/commerciale/credit/send`, devis, { headers: HEADERS });
}

const creditLinks = (params) => {
    return axios.post(`${baseAPI}/credits/get_links`, params, { headers: HEADERS });
}

const sendYousign = (body) => {
    return axios.post(`${baseAPI}/credits/sendYousign`, body, { headers: HEADERS });
}

const sendRelanceSignature = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-relance-signature`, devis, { headers: HEADERS });
}
const sendRelancePayment = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-relance-payment`, devis, { headers: HEADERS });
}

const getCommissionTauxCreditConfig = () => {
    return axios.get(`${baseAPI}/api-admin/commission-taux-credit/default`, { headers: HEADERS });
}

module.exports = {
    getCommissionTauxCreditConfig,
    createCredit,
    updateCredit,
    getCredits,
    sendForm,
    sendYousign,
    sendRelanceSignature,
    sendRelancePayment,
    getCreditsById,
    getCreditsDashboard,
    closeAndCopyCredit,
    creditLinks
}
import {
  Box, Grid, Text
} from "grommet";
import React from "react";

const InfoVenteMark = ({ text }) => {
  if (!text)
    return null;
  return (
    <Box margin="6px 0" background="brand" pad="5px 25px" width="max-content" style={{ borderRadius: "10px" }}>
      <Text color="white" weight={600} textAlign="center">{text}</Text>
    </Box>
  );
};

const InfoAssurance = ({ devis }) => {
  const _changeAssurance = devis.type_projet === "Je souhaite changer d'assurance";
  return (
    <Box width="full">
      <Grid
        columns={["1/2", "1/2"]} gap="small" pad={"small"} width="100%"
      >
        <Box>
          <Text color="grey">Quel est le crédit à assurer ?</Text>
          <InfoVenteMark text={devis.objet_financement} />
        </Box>
        <Box>
          <Text color="grey">Pour quel projet ?</Text>
          <InfoVenteMark text={devis.type_projet} />
        </Box>
        <Box>
          <Text color="grey">Indiquer le montant total du prêt (Interêt compris)</Text>
          <Text color="grey" weight={700}>{devis.montant} €</Text>
        </Box>
        <Box>
          <Text color="grey">Date de début du prêt</Text>
          <Text color="grey" weight={700}>{devis.date_du_pret?.split('T')[0]}</Text>
        </Box>
        <Box>
          <Text color="grey">Le taux d’intérêt annuel</Text>
          <Text color="grey" weight={700}>{devis.taux} %</Text>
        </Box>
        <Box>
          <Text color="grey">Durée du pret</Text>
          <Text color="grey" weight={700}>{devis.duree} ans</Text>
        </Box>
        {_changeAssurance && <Box>
          <Text color="grey">Connaissez-vous le montant de votre assurance ?</Text>
          <Text color="grey" weight={700}>{devis.montant_assurance_connu ? "Oui" : "Non"}</Text>
        </Box>}
        {_changeAssurance && <Box>
          <Text color="grey">Le montant total de l’assurance</Text>
          <Text color="grey" weight={700}>{devis.montant_total_assurance} €</Text>
        </Box>}
      </Grid>
    </Box>
  );
}

export default InfoAssurance;

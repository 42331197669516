import React from "react";
import { Box, Button, Card, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { getAppUsers, getMisEnRelationByUser } from "./service";
import { getSimpleDateFR, getSorted } from "../../services/utils";
import SearchComponent from "../elements/search";
import DateSort from "../elements/date_sort";
import { AddCircle, StatusGoodSmall } from "grommet-icons";
import PrimaryButton from "../elements/primary_button";
import AppUserDetail from "./user_detail";
import Refresh from "../elements/refresh";
import { saveContextBot } from "../../services/storage_service";


class AppUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appUsers: [],
      popupOpen: false,
      selectedUser: {},
      showCreateNew: false,
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    };
    console.log('props = ', props);
  }
  componentDidMount = () => {
    this.loadAppUsers();
  };

  loadAppUsers = () => {
    console.log('props = ', this.props);
    this.setState({ appUsers: [] }, () => {
      var _params = {
        with: 'relation'
      };
      if (this.props.domain) {
        _params = { email_contains: this.props.domain, _params };
      }
      getAppUsers(_params)
        .then((res) => {
          this.setState({ appUsers: res.data });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false, showCreateNew: false });
    this.loadAppUsers();
  };

  openUser = async (user) => {

    //set context relation:
    var qs = require('qs');
    getMisEnRelationByUser(user?.id, qs.stringify({model_id_null: false})).then((res) => {
      let _data = res.data;
      let _contractIds = _data?.map(_m => _m.model_id).join(',') || 'no_ids';
      saveContextBot({ id: _contractIds });
      this.setState({ selectedUser: user, popupOpen: true });
    })
      .catch((err) => {
        console.log("err", err);
        this.props.showMessage("Erreur inattendu");
      });
  };

  addUser = () => this.openUser({});

  search = (query) => {
    var _params = {
      with: 'relation'
    };
    if (query)
      _params = { _q: query, ..._params };
    getAppUsers(_params)
      .then(res => {
        if (res.data?.results) {
          this.setState({ appUsers: res.data?.results })
        } else if (res.data) {
          this.setState({ appUsers: res.data })
        }
      })
      .catch(err => {
        console.log('err', err);
        this.props.showMessage('Erreur inattendu');
      })
  }

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  render() {
    return this.state.popupOpen ? (
      <AppUserDetail
        showMessage={this.props.showMessage}
        user={this.state.selectedUser}
        onClose={this.onClose}
        reload={this.loadAppUsers}
      />
    ) : (
      <Box style={{ width: "100%" }}
        align="center" justify="center"
        alignSelf="center"
        alignContent="center" gap="medium">
        <Text size="xxlarge" style={{ padding: 10 }}><b>App Apporteurs</b></Text>
        <Box gap="medium">
          <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
            <Refresh refresh={this.loadAppUsers} />
            <SearchComponent
              search={value => {
                this.search(value, this.state.filter._query);
              }}
              query={this.state.filter._query}
              updateQuery={value => this.updateFilter('_query', value)}
            />
            <DateSort
              onChange={value => this.updateFilter('sort', value)}
            />
          </Box>


          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Text>Création/<br />Modification</Text>
                </TableCell>
                <TableCell>
                  <Text>Nom</Text>
                </TableCell>
                <TableCell>
                  <Text>Prénom</Text>
                </TableCell>
                <TableCell>
                  <Text>Phone</Text>
                </TableCell>
                <TableCell>
                  <Text>Date de naissance</Text>
                </TableCell>
                <TableCell>
                  <Text>Email</Text></TableCell>
                <TableCell>
                  <Text>Immobilier</Text></TableCell>
                <TableCell><Text>Équipe de</Text></TableCell>
                <TableCell><Text>Interlocuteur</Text></TableCell>
                <TableCell><Text>État inscription</Text></TableCell>
                <TableCell><Text>Total mis en relation</Text></TableCell>
                <TableCell><Text>Mis en relation ce moi-ci</Text></TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getSorted(this.state.appUsers, this.state.filter.sort).map((appUser, index) => (
                <TableRow key={index + 1} onClick={() => this.openUser(appUser)} style={{ cursor: 'pointer' }}>
                  <TableCell>
                    <Text size="small"><strong>{getSimpleDateFR(appUser.createdAt)}</strong><br />{getSimpleDateFR(appUser.updatedAt)}</Text>
                  </TableCell>
                  <TableCell>
                  {appUser.gotObjectif ? <StatusGoodSmall color={'green'} />: <></>}<Text>{appUser.nom}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.prenom}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.phone}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{getSimpleDateFR(appUser.birthday)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.email}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.domain_activity}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.affiliated_by ? `${appUser.affiliated_by.prenom} ${appUser.affiliated_by.nom}` : ''}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.interlocuteur?.prenom || ''} {appUser.interlocuteur?.nom || ''}</Text>
                  </TableCell>
                  <TableCell>
                    <Card direction="row" gap="xsmall" pad="xsmall" align="center">
                      <StatusGoodSmall color={appUser.app_validated ? 'green' : 'orange'} />
                      <Text size="small">
                        <strong>{appUser.app_validated ? 'Validé' : 'En attend'}</strong>
                      </Text>
                    </Card>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.total_mis_en_relation ?? 0}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.this_month_mis_en_relation ?? 0}</Text>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  }
}



export default AppUsers;

import { Box, FileInput, Text, TextArea } from 'grommet';
import { CaretPrevious, Save } from 'grommet-icons';
import React, { useState } from "react";
import { uploadFile } from '../../../../services/storage_service';
import AddressAutoComplet from "../../../elements/address_autocomplete";
import CustomInput from '../../../elements/custom_input';
import DateInput from '../../../elements/date_input';
import PrimaryButton from '../../../elements/primary_button';
import CustomSelect from '../../../elements/select_fill';
import StandardText from "../../../elements/standard_text";
import ToggleButton from '../../../elements/toggle_button/toggle_button';
import SectionDivider from "../../../voyages/components/session_devider";
import metiers from './metiers';

const doUploadFile = (files, callback, showError) => {
    const fileList = files;
    if (fileList) {
        for (let i = 0; i < fileList.length; i += 1) {
            const file = fileList[i];
            console.log(file.name);
            uploadFile(file)
                .then((result) => {
                    console.log("result = ", result.data);
                    callback(result.data);
                })
                .catch((err) => {
                    showError("Erreur inattendu télécharger.");
                    callback([]);
                });
        }
    }
};

const Info = ({ deal, onDealChange, showMessage, prev, save }) => {

    const [domainOptions, setDomainOptions] = useState(Object.keys(metiers));
    const [metierOptions, setMetierOptions] = useState([]);

    return (
        <Box
            style={{ width: "100%" }}
            justify="center"
            alignContent="center"
            align="center"
        >
            <Box
                width={'large'}
                justify="center"
                alignContent="center"
                align="center"
                gap="medium"
            >

                <Box >
                    <SectionDivider title={'Informations sur votre activité'} />
                </Box>

                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                    <LineSelect label="Votre domaine d’activité"
                        value={deal.domain_activite}
                        options={domainOptions}
                        onSearch={(text) => {
                            const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                            const exp = new RegExp(escapedText, 'i');
                            setDomainOptions(Object.keys(metiers).filter((o) => exp.test(o)));
                        }}
                        onDealChange={option => {
                            onDealChange('domain_activite', option);
                            setMetierOptions(metiers[option])
                            onDealChange('metier', undefined)
                        }}
                    />
                    <LineSelect label="Votre métier"
                        value={deal.metier}
                        options={metierOptions}
                        onSearch={(text) => {
                            const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                            const exp = new RegExp(escapedText, 'i');
                            setMetierOptions(metiers[deal.domain_activite].filter((o) => exp.test(o)));
                        }}
                        onDealChange={option => onDealChange('metier', option)}
                    />
                    <LineInput label="Précision sur votre activité"
                        value={deal.precision_activite}
                        onDealChange={val => onDealChange('precision_activite', val)}
                    />
                </Box>
                <Box >
                    <SectionDivider title={'Informations sur votre entreprise'} />
                </Box>
                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                    <Line
                        left={<StandardText size={'small'} label={"Date de création"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_creation}
                                onChange={(date) => onDealChange("date_creation", date)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Date de résiliation"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_resiliation}
                                onChange={(date) => onDealChange("date_resiliation", date)}
                            />
                        }
                    />
                    <LineInput label="Effectif de l’entreprise"
                        value={deal.effectif_entreprise}
                        onDealChange={val => onDealChange('effectif_entreprise', val)}
                    />
                    <LineInput label="Chiffre d’affaires annuel HT"
                        value={deal.ca_ht}
                        placeholder='€'
                        onDealChange={val => onDealChange('ca_ht', val)}
                    />
                    <Line
                        left={<StandardText size={'small'} label={"L’entreprise est-elle en situation de procédure collective "} />}
                        right={
                            <ToggleButton
                                groups={[true, false].map(value => (
                                    {
                                        label: value ? 'Oui' : 'Non',
                                        selected: deal.situation_procedure_collective === value,
                                        onClick: () => onDealChange('situation_procedure_collective', value),
                                    }
                                ))}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Le précédent contrat a-t-il été résilié par le précédent assureur"} />}
                        right={
                            <ToggleButton
                                groups={[true, false].map(value => (
                                    {
                                        label: value ? 'Oui' : 'Non',
                                        selected: deal.resilie_assureur === value,
                                        onClick: () => onDealChange('resilie_assureur', value),
                                    }
                                ))}
                            />
                        }
                    />
                    {deal.resilie_assureur &&
                        <LineSelect label="Motif de résiliation"
                            value={deal.motif_resiliation}
                            options={["Sinistre", "Non paiement"]}
                            onDealChange={option => onDealChange('motif_resiliation', option)}
                        />
                    }
                    <LineSelect label="Nombre de sinistres les 24 derniers mois"
                        value={deal.nombre_sinistre}
                        options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                        onDealChange={option => onDealChange('nombre_sinistre', option)}
                    />
                </Box>
                <Box >
                    <SectionDivider title={'Description de vos locaux'} />
                </Box>

                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                    <AddressAutoComplet
                        contact={deal}
                        updateContact={onDealChange}
                        direction='in-line'
                    />

                    <LineInput label="Superficie des locaux"
                        value={deal.surface_local}
                        placeholder='m2'
                        onDealChange={val => onDealChange('surface_local', val)}
                    />

                    <LineInput label="Valeur du contenu"
                        value={deal.valeur_contenu}
                        placeholder='€'
                        onDealChange={val => onDealChange('valeur_contenu', val)}
                    />
                    <LineSelect label="Qualité de l’occupant"
                        value={deal.qualite_occupant}
                        options={["Propriétaire", "Copropriétaire", "Locataire", "Sous Locataire", "Gérant libre", "Occupant à titre gratuit"]}
                        onDealChange={option => onDealChange('qualite_occupant', option)}
                    />

                    <LineToggle label={'Zone Artisanale, Commerciale ou Industrielle'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.zone_industrielle === value,
                                onClick: () => onDealChange('zone_industrielle', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Occupation d’un RDC'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.occupant_rdc === value,
                                onClick: () => onDealChange('occupant_rdc', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Stockage produits dangereux'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.produit_dangereux === value,
                                onClick: () => onDealChange('produit_dangereux', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Chambre(s) frigorifique(s) > à 25% de la superficie occupée'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.chambre_frigo_plus_25_surface === value,
                                onClick: () => onDealChange('chambre_frigo_plus_25_surface', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Le local professionnel est-il conforme aux déclarations suivantes'}
                        groups={[true, false].map(value => (
                            {
                                label: value ? 'Oui' : 'Non',
                                selected: deal.conforme_declaration === value,
                                onClick: () => onDealChange('conforme_declaration', value),
                            }
                        ))}
                    />

                    {deal.conforme_declaration ?
                        <Box width={'full'}>
                            <StandardText size={'small'}
                                label={
                                    `Le local professionnel est situé dans un bâtiment construit et couvert en matériaux dur pour plus de 75%
                                <br/><br/>
                                Le local professionnel n’est pas situé dans :<br/><br/>
                                
                                - un ensemble à caractère industriel <br/>
                                - un château, ni un manoir, ni un risque classé ou répertorié<br/>
                                - une galerie marchande d’un supermarché ou d’un hypermarché<br/>
                                - un centre commercial de plus de 3 000 m²<br/>
                                - un bâtiment ou groupe de bâtiments en communication d’une surface supérieure à 20 000 m²<br/>
                                - un bâtiment d’une hauteur supérieure à 28 mètres<br/>`
                                }
                            />
                        </Box> :
                        <Box gap="xxsmall" width={'full'} pad='xsmall'>
                            <b><StandardText size={'small'} label="Le local professionnel n’est pas situé dans"/></b>
                            <LineToggle label={'un ensemble à caractère industriel : '}
                                groups={['Oui', 'Non'].map(value => (
                                    {
                                        label: value,
                                        selected: deal.ensemble_cacractere_industriel === value,
                                        onClick: () => onDealChange('ensemble_cacractere_industriel', value),
                                    }
                                ))}
                            />
                            <LineToggle label={'ni un château, ni un manoir, ni un risque classé ou répertorié : '}
                                groups={['Oui', 'Non'].map(value => (
                                    {
                                        label: value,
                                        selected: deal.non_risque_classe === value,
                                        onClick: () => onDealChange('non_risque_classe', value),
                                    }
                                ))}
                            />
                            <LineToggle label={'une galerie marchande d’un supermarché ou d’un hypermarché : '}
                                groups={['Oui', 'Non'].map(value => (
                                    {
                                        label: value,
                                        selected: deal.galerie_marchande === value,
                                        onClick: () => onDealChange('galerie_marchande', value),
                                    }
                                ))}
                            />
                            <LineToggle label={'un centre commercial de plus de 3 000 m² : '}
                                groups={['Oui', 'Non'].map(value => (
                                    {
                                        label: value,
                                        selected: deal.centre_commercial === value,
                                        onClick: () => onDealChange('centre_commercial', value),
                                    }
                                ))}
                            />
                            <LineToggle label={'un bâtiment ou groupe de bâtiments en communication d’une surface supérieure à 20 000 m² : '}
                                groups={['Oui', 'Non'].map(value => (
                                    {
                                        label: value,
                                        selected: deal.surface_superieur_20km === value,
                                        onClick: () => onDealChange('surface_superieur_20km', value),
                                    }
                                ))}
                            />
                            <LineToggle label={'un bâtiment d’une hauteur supérieure à 28 mètres : '}
                                groups={['Oui', 'Non'].map(value => (
                                    {
                                        label: value,
                                        selected: deal.hauteur_superieur_28m === value,
                                        onClick: () => onDealChange('hauteur_superieur_28m', value),
                                    }
                                ))}
                            />
                        </Box>
                    }

                    <LineSelect label="Protection vol"
                        value={deal.protection_vol}
                        options={["Clos couvert", "Alarme", "Protection mecanique", "Protection mecanique + Alarme"]}
                        onDealChange={option => onDealChange('protection_vol', option)}
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Date d’effet souhaité"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_effet}
                                onChange={(date) => onDealChange("date_effet", date)}
                            />
                        }
                    />
                    <Box pad={'small'}>
                        <FileInput
                            size={'xxsmall'}
                            messages={{
                                browse: "Choisir",
                                dropPrompt: "Ajouter un autre document",
                            }}
                            multiple={false}
                            onChange={(event) => {
                                doUploadFile(
                                    event.target.files,
                                    async (data) => {
                                        if (data.length > 0) {
                                            let _uploaded = data[0];
                                            let _files = deal.file_others || [];
                                            _files.push(_uploaded.url);
                                            onDealChange("file_others", _files);
                                        }
                                    },
                                    mes => showMessage(mes, 'error')
                                );
                            }}
                        />
                    </Box>
                </Box>

                <Box pad={'medium'} direction='row' gap="medium">
                    < PrimaryButton icon={<CaretPrevious />} label='Retour' background={'#707070'} onClick={prev} />
                    < PrimaryButton icon={<Save />} label='Enregistrer' onClick={save} />
                </Box>
            </Box>
        </Box >
    )
}

const Line = ({ left, right }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                {left}
            </Box>
            <Box width={'full'}>
                {right}
            </Box>
        </Box>
    )
}

const LineInput = ({ label, placeholder, value, onDealChange }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <CustomInput size='xsmall' placeholder={placeholder}
                    value={value}
                    onChange={event => onDealChange(event.target.value)}
                />
            </Box>
        </Box>
    )
}

const LineSelect = ({ label, options = [], value, onDealChange, onSearch }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <CustomSelect
                    size={'small'}
                    options={options}
                    value={value}
                    onChange={({ option }) => onDealChange(option)}
                    onSearch={onSearch}
                />
            </Box>
        </Box>
    )
}

const LineToggle = ({ label, groups = [] }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <ToggleButton
                    groups={groups}
                />
            </Box>
        </Box>
    )
}

export default Info;
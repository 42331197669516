const axios = require('axios');
const storageService = require('./storage_service');
const { baseAPI } = require('../config/config.json');
const { CONTRAT_CLOTURE, EN_ATTENTE_BANQUE, EN_ATTENTE_DE_RESILIATION, RESILIE } = require('./devis_contrat_status');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const estimateDevis = devis => {
    return axios.post(`${baseAPI}/dh/estimate`, devis, { headers: HEADERS });
}

const updateDevis = devis => {
    return axios.put(`${baseAPI}/public/devis-habitation/${devis.id}`, devis, { headers: HEADERS });
}

const createDevis = devis => {
    return axios.post(`${baseAPI}/public/devis-habitation`, devis, { headers: HEADERS });
}

const deleteDevis = devis => {
    return axios.delete(`${baseAPI}/public/devis-habitation${devis.id}`, devis, { headers: HEADERS });
}

const sendDevis = devis => {
    return axios.post(`${baseAPI}/public/devis-habitations/send-devis`, devis, { headers: HEADERS });
}

const generateContrat = (id, model_type, do_not_emailing = false, year = false) => {
    return axios.post(`${baseAPI}/enligne-products/attestations`, {id, model_type, do_not_emailing, year}, { headers: HEADERS });
}

const sendForm = devis => {
    return axios.post(`${baseAPI}/public/devis-habitations/send-form`, devis, { headers: HEADERS });
}

const isExportable = c => true;

const createNewSubFromCancelledSub = (sub_id, pno_id) => {
    return axios.post(`${baseAPI}/public/pno-seul/new-subscription`, {sub_id, pno_id}, { headers: HEADERS });
}

module.exports = {
    estimateDevis, 
    updateDevis,
    createDevis,
    deleteDevis,
    sendDevis,
    generateContrat,
    sendForm,
    isExportable,
    createNewSubFromCancelledSub
}
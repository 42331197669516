import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
    Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box, Card, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import moment from 'moment';
import React from 'react';
import BlockUi from 'react-block-ui';
import { Bar } from 'react-chartjs-2';
import service from '../../services/dashboard';
import { CONTRAT_CLOTURE } from '../../services/devis_contrat_status';
import { getCommercialId, getUser, hasAdministrativeRole, hasRightToViewCommissions } from '../../services/storage_service';
import { getMonthOptions, getSimpleDateFR, toCurrency } from '../../services/utils';
import ContractDetails from "../elements/contract_details";
import RecapStatus from '../elements/devis_status';
import CustomSelect from '../elements/select_fill';
import { getClientAddress } from '../outils/client_util';
import { ContractCreditDetails } from '../ventes/credit/credit/detail_recap';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'left',
        },
        datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: value => value ? toCurrency(value) : '',
            font: {
                weight: 'bold'
            }
        }
    }
};

const products = [
    'Tous',
    'Trusti Habitation en ligne',
    'Trusti PNO en ligne',
    'Trusti Emprunteur',
    'Trusti Moto',
    'Trusti Voiture',
    'Trusti Chien/Chat',
    'Trusti Santé',
    'Trusti PNO Gîte',
    'Trusti Habitation',
    'Trusti PNO',
    'Trusti PER',
    'Trusti MH tempo enligne',
    'Trusti Dommage Ouvrage',
    'Trusti MRP',
    'Trusti RC',
    'Trusti PREV',
    'Trusti Cyber Risque',
    'Trusti Santé pro',
    'Trusti Immeuble pro',
    'Trusti PNO pro',
    'Trusti Crédit',
    'Trusti GLI',
    'Trusti Immeuble',
]


class CommissionReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            filter: {
                startDate: moment(new Date()).startOf('month').toDate(),
                endDate: new Date(),
                product: '',
                status: `${CONTRAT_CLOTURE}`,
                sort: 'Produit',
                commercial_id: hasAdministrativeRole() ? 'All' : getCommercialId()
            },
            commission: {
                totalCommission: 0,
                graphData: undefined
            },
            commission_taux: undefined,
            months: getMonthOptions().filter(s => s.name !== '12 derniers mois'),
            selectedMonth: undefined,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        service.getCommission(this.state.filter)
            .then(res => {
                this.setState({ commission: res.data, loading: false });
            });
        service.getCommissionTauxConfig()
            .then(res => {
                this.setState({ commission_taux: res.data });
            })
    }

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;

        this.setState({ filter: Object.assign({}, filter) }, () => this.loadData())
    }

    getManagedCollabs = () => {
        if(hasAdministrativeRole()) return this.props.commerciaux;
        return this.props.commerciaux.filter(p => p.equipe_id === getUser()?.equipe_id);
    }

    render() {
        return (
            <Box width="xxlarge">
                <Box justify="center" alignSelf='center'>

                    <Card width='xlarge' justify='around' margin='medium' pad='small' direction='row'>
                        {isInGlobalFilter(this.state.filter) &&
                            <Box gap='xsmall'>
                                <Text>Trier par</Text>
                                <CustomSelect
                                    options={['Produit', 'Mois']}
                                    onChange={({ option }) => {
                                        this.updateFilter('sort', option);
                                    }}
                                />
                            </Box>
                        }
                        <Box gap='xsmall'>
                            <Text>Selection d'un mois</Text>
                            <CustomSelect
                                options={this.state.months}
                                labelKey={"name"}
                                value={this.state.selectedMonth || this.state.months[0].name}
                                valueKey={{ key: "name", reduce: true }}
                                onChange={({ option }) => {
                                    let filter = this.state.filter;
                                    filter.startDate = option.startDate;
                                    filter.endDate = option.endDate;
                                    filter.sort = 'Produit';
                                    this.setState({ filter: Object.assign({}, filter), selectedMonth: option.name }, () => this.loadData());
                                }}
                            />
                        </Box>
                        <Box gap='xsmall'>
                            <Text>Filtrage sur Produit</Text>
                            <CustomSelect key={2} options={products} multiple={false}
                                value={this.state.filter.product || products[0]}
                                onChange={({ option }) => {
                                    this.updateFilter('product', option === 'Tous' ? '' : option);
                                }} />
                        </Box>

                        {this.props.commerciaux && hasRightToViewCommissions() &&
                            <Box gap='xsmall'>
                                <Text>Commercial</Text>
                                <CustomSelect
                                    options={this.getManagedCollabs()}
                                    onChange={({ option }) => this.updateFilter('commercial_id', option.id)}
                                    labelKey={'name'}
                                    valueKey={{ key: 'name', reduce: true }}
                                />
                            </Box>
                        }
                    </Card>
                </Box>
                <Box justify="center" alignSelf='center' margin="xsmall">
                    <Card pad="small" >
                        <Text textAlign="center">Total de mes commission</Text>
                        <Text textAlign="center" size="large" style={{ fontWeight: 'bold', color: '#E82C70' }}>{toCurrency(this.state.commission.totalCommission)}</Text>
                    </Card>
                </Box>
                {this.state.commission.graphData &&
                    <Box width="xlarge" justify="center" align="center" alignSelf="center" margin="medium">
                        <Bar
                            options={options}
                            data={this.state.filter.sort === 'Mois' ?
                                {
                                    labels: this.state.commission.graphData.map(s => s.date),
                                    datasets: [
                                        {
                                            label: `Commission par ${this.state.filter.sort}`,
                                            backgroundColor: "#3e95cd",
                                            data: this.state.commission.graphData.map(s => s.value)
                                        }
                                    ]
                                } :
                                {
                                    labels: this.state.commission.graphData.map(s => s.product),
                                    datasets: [
                                        {
                                            label: `Commission par ${this.state.filter.sort}`,
                                            backgroundColor: ["#3e95cd", "#3e95cd", "#3e95cd", "#3e95cd", "#3e95cd", "#3e95cd", "#3e95cd", "#3e95cd", "#3e95cd", "#3e95cd"],
                                            data: this.state.commission.graphData.map(s => s.value)
                                        }
                                    ]
                                }}
                            plugins={[ChartDataLabels]}
                        />
                    </Box>
                }
                <BlockUi tag="div" blocking={this.state.loading} style={{ width: '100%', height: '100%' }} >
                    <Box gap='small' pad='small'>
                        <Text style={{ fontWeight: 'bold' }} size='large' textAlign="center">Mes commissions</Text>

                        <Box pad='small' style={{ overflowY: 'scroll', width: '100%', maxHeight: 580, marginBottom: 20 }}>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableCell><Text><strong>Création</strong>/<br />Modification</Text></TableCell>
                                        <TableCell><Text>Produit</Text></TableCell>
                                        <TableCell><Text>Fournisseur</Text></TableCell>
                                        <TableCell><Text>Assuré</Text></TableCell>
                                        <TableCell><Text>Adresse</Text></TableCell>
                                        <TableCell><Text>Statut</Text></TableCell>
                                        <TableCell><Text>Contrat</Text></TableCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {
                                        this.state.commission?.all_contracts?.map((devis, index) => {
                                            let _client = devis.client || devis.entreprise || {};
                                            let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                                            return (
                                                <TableRow key={index + 1}
                                                    style={{ backgroundColor: _bgColor }}>
                                                    <TableCell>
                                                        <Text size="small"><strong>{getSimpleDateFR(devis.createdAt)}</strong><br />{getSimpleDateFR(devis.updatedAt)}</Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text>{devis.product}</Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text>{devis.fournisseur}</Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text>
                                                            {(_client.raisonSocial || _client.raison_social)
                                                                ? (_client.raisonSocial || _client.raison_social)
                                                                : `${_client.lastname} ${_client.firstname}`}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text>{`${getClientAddress(_client)} `}</Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <RecapStatus status={devis.status} />
                                                    </TableCell>
                                                    <TableCell>
                                                        {devis.model_type === 'credit' ?
                                                        <ContractCreditDetails devis={devis} commission_taux={this.state.commission_taux}  /> :
                                                        <ContractDetails devis={devis} commission_taux={this.state.commission_taux} 
                                                        />
                                                            }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </BlockUi>
            </Box>
        )
    }
}

const isInGlobalFilter = filter => {
    let start = moment().subtract(11, 'month').startOf('month').format('MM/YYYY');
    let end = moment().endOf('month').format('MM/YYYY');
    return start === moment(filter.startDate).format('MM/YYYY') &&
        end === moment(filter.endDate).format('MM/YYYY');
}

export default CommissionReport;
const axios = require('axios');
const storageService = require('./storage_service');
const { baseAPI } = require('../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const findApporteurs = (query) => {
    let params = {
        "_where[0][role.name]": "Apporteur",
        _q: query
    }
    if(!query) delete params._q;
    return axios.get(`${baseAPI}/apporteurs`, {params, headers: HEADERS });
}

module.exports = {
    findApporteurs
}
const axios = require('axios');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const buildParams = (search_params) => {
    const { start, sort, query, limit, status } = search_params;
    let params = {
        _start: start || 0,
        _limit: limit || 50,
    }
    if(status) {
        params.status = status;
    }
    if (query) {
        params['_q'] = query || '';
    }
    if(sort) {
        params._sort = sort || 'updatedAt:desc';
    }
    return params;
}

const getAllImmeubles = (filter) => {
    let params = buildParams(filter);
    return axios.get(`${baseAPI}/public/trusti-immeuble`, { params, headers: HEADERS });
}
const countImmeubles = (filter) => {
    let params = buildParams(filter);
    return axios.get(`${baseAPI}/trusti_immeuble/count`, { params, headers: HEADERS })
}

const createImmeuble = (body) => {
    body.commercial_id = storageService.getCommercialId()
    return axios.post(`${baseAPI}/public/trusti-immeuble`, body, { headers: HEADERS });
}

const updateImmeuble = (body) => {
    return axios.put(`${baseAPI}/public/trusti-immeuble/${body.id}`, body, { headers: HEADERS });
}

const sendRelancePayment = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-relance-payment`, devis, { headers: HEADERS });
}

const sendForm = (body) => {
    return axios.post(`${baseAPI}/commerciale/trusti-immeuble/send`, body, { headers: HEADERS });
}

module.exports = {
    sendForm,
    getAllImmeubles,
    countImmeubles,
    createImmeuble,
    sendRelancePayment,
    updateImmeuble
}
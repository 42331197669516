import { Box, Button, Card, Heading, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { Update } from "grommet-icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getEmailEventReports } from "../../../services/common_service";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

export const EmailEventTrackerV2 = ({ messageIds }) => {

    const [events, setEvents] = useState([]);
    const [blocking, setBlocking] = useState(false);

    useEffect(() => {
        loadEvents();
    }, messageIds);

    const loadEvents = () => {
        setBlocking(true);
        getEmailEventReports(messageIds)
            .then(res => {
                setEvents(filterByEvent(res.data));
                setBlocking(false);
            })
            .catch(err => setBlocking(false));
    }

    return (
        <BlockUi tag="div" blocking={blocking}>
            <Box width={'100%'} justify='center' align="center">
                {events.length === 0 ?
                    (<Box width="100%" justify='center' align="center" pad={'small'}>
                        <Heading level={4}>Pas d'evenement d'email enregistré</Heading>
                    </Box>) :
                    (<Box width="100%" justify='center' align="center" pad={'small'} style={{ maxHeight: 500, overflowY: 'scroll' }}>
                        <Box width={'full'}>
                            <Table
                                fill="horizontal"
                                style={{
                                    width: "100%"
                                }}
                            >
                                <TableHeader>
                                    <TableRow>
                                        <TableCell scope="col" width={"80px"}>
                                            <Text size="small" weight="bold">
                                                Evenements
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col" width={"160px"}>
                                            <Text size="small" weight="bold">
                                                Date
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col" width={"160px"}>
                                            <Text size="small" weight="bold">
                                                Objet
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col" width={"160px"}>
                                            <Text size="small" weight="bold">
                                                A
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col" width={"160px"}>
                                            <Text size="small" weight="bold">
                                                De
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Button icon={<Update color='brand' size="15px" />} onClick={loadEvents} />
                                        </TableCell>
                                    </TableRow>
                                </TableHeader>

                            </Table>
                            <Box
                                style={{
                                    overflowY: "scroll",
                                    height: 310,
                                    width: "100%",
                                    marginTop: 0,
                                    marginBottom: 0,
                                }}>
                                <Table
                                    fill="horizontal"
                                    style={{
                                        width: "100%"
                                    }}
                                >

                                    <TableBody>
                                        {(events)
                                            .map((event, index) => {
                                                let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                                                return (
                                                    <TableRow
                                                        key={index + 1}
                                                        style={{ backgroundColor: _bgColor }}
                                                    >
                                                        <TableCell scope="col" width={"80px"}>
                                                            <EventType event={event.event} />
                                                        </TableCell>
                                                        <TableCell scope="col" width={"160px"}>
                                                            <Text size="small">{moment(event._date).format('DD-MM-YYYY HH:mm:ss')}</Text>
                                                        </TableCell>
                                                        <TableCell scope="col" width={"160px"}>
                                                            <Text size="small">{event.subject}</Text>
                                                        </TableCell>
                                                        <TableCell scope="col" width={"160px"}>
                                                            <Text size="small">{event.email}</Text>
                                                        </TableCell>
                                                        <TableCell scope="col" width={"160px"}>
                                                            <Text size="small">{event.from}</Text>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </Box>)
                }
            </Box>
        </BlockUi>
    )
}

const filterByEvent = (events) => {
    const results = [];
    const isSameEventEmail = (e1, e2) => e1.templateId === e2.templateId && e1.event === e2.event;
    for (const e of events) {
        const existing = results.find(e1 => isSameEventEmail(e, e1));
        if (!existing) {
            results.push(e);
        }
    }
    return results;
}

const EmailEventTracker = ({ messageIds }) => {

    const [events, setEvents] = useState([]);
    const [blocking, setBlocking] = useState(false);

    useEffect(() => {
        loadEvents();
    }, messageIds);

    const loadEvents = () => {
        setBlocking(true);
        getEmailEventReports(messageIds)
            .then(res => {
                setEvents(filterByEvent(res.data));
                setBlocking(false);
            })
            .catch(err => setBlocking(false));
    }

    return (
        <BlockUi tag="div" blocking={blocking}>
            <Box width={'full'} justify='center' align="center" style={{ marginTop: 20 }}>
                {events.length === 0 ?
                    (<Box fill background={'white'} justify='center' align="center" pad={'small'}>
                        <Heading level={4}>Pas d'evenement d'email enregistré</Heading>
                    </Box>) :
                    (<Box fill background={'white'} width={'full'} justify='center' align="center" pad={'small'} style={{ maxHeight: 500, overflowY: 'scroll' }}>
                        <Box width={'full'} align='center' justify="between" direction="row">
                            <Box width={'small'} justify='end'>
                                <Button icon={<Update color='brand' />} onClick={loadEvents} />
                            </Box>
                            <Heading level={4}>Evenements des emails envoyés</Heading>
                            <Box width={'small'}></Box>
                        </Box>
                        <Box width={'full'}>
                            <Table
                                fill="horizontal"
                                style={{
                                    width: "100%"
                                }}
                            >
                                <TableHeader>
                                    <TableRow>
                                        <TableCell scope="col" width={"80px"}>
                                            <Text size="small" weight="bold">
                                                Evenements
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col" width={"160px"}>
                                            <Text size="small" weight="bold">
                                                Date
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col" width={"160px"}>
                                            <Text size="small" weight="bold">
                                                Objet
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col" width={"160px"}>
                                            <Text size="small" weight="bold">
                                                A
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col" width={"160px"}>
                                            <Text size="small" weight="bold">
                                                De
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                </TableHeader>

                            </Table>
                            <Box
                                style={{
                                    overflowY: "scroll",
                                    height: 310,
                                    width: "100%",
                                    marginTop: 0,
                                    marginBottom: 0,
                                }}>
                                <Table
                                    fill="horizontal"
                                    style={{
                                        width: "100%"
                                    }}
                                >

                                    <TableBody>
                                        {(events)
                                            .map((event, index) => {
                                                let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                                                return (
                                                    <TableRow
                                                        key={index + 1}
                                                        style={{ backgroundColor: _bgColor }}
                                                    >
                                                        <TableCell scope="col" width={"80px"}>
                                                            <EventType event={event.event} />
                                                        </TableCell>
                                                        <TableCell scope="col" width={"160px"}>
                                                            <Text size="small">{moment(event._date).format('DD-MM-YYYY HH:mm:ss')}</Text>
                                                        </TableCell>
                                                        <TableCell scope="col" width={"160px"}>
                                                            <Text size="small">{event.subject}</Text>
                                                        </TableCell>
                                                        <TableCell scope="col" width={"160px"}>
                                                            <Text size="small">{event.email}</Text>
                                                        </TableCell>
                                                        <TableCell scope="col" width={"160px"}>
                                                            <Text size="small">{event.from}</Text>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </Box>)
                }
            </Box>
        </BlockUi>
    )
}

const EventType = ({ event }) => {
    const { text, color } = getEventDeco(event);
    return (
        <Text textAlign="center" size="small" style={{ padding: '0.5rem', borderRadius: '2rem', color: '#fff', backgroundColor: color }}>{text}</Text>
    )
}
const getEventDeco = (event) => {
    switch (event) {
        case "unsubscribe":
            return { text: "Désinscrit", color: "#f3bb94" };
        case "error":
            return { text: "Error", color: "#cd7867" };
        case "deferred":
            return { text: "Différé", color: "#efa381" };
        case "blocked":
            return { text: "Bloqué", color: "#f7d3a6" };
        case "soft_bounce":
            return { text: "Soft bounce", color: "#ea8b6f" };
        case "hard_bounce":
            return { text: "Hard bounce", color: "#e6735c" };
        case "clicks":
            return { text: "Cliqué", color: "#9cc980" };
        case "proxy_open":
            return { text: "Chargé par proxy", color: "#66d2dc" };
        case "opened":
        case "open":
            return { text: "1ère ouverture", color: "#66b2dc" };
        case "delivered":
            return { text: "Délivré", color: "#4388ae" };
        case "sent":
        case "requests":
            return { text: "Envoyé", color: "#98a0ab" };
        default:
            return { text: event, color: "#98a0ab" };
    }
}

export default EmailEventTracker;
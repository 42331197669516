import { Box, Card, Stack } from 'grommet';
import { DocumentDownload, DocumentText, DocumentVerified, Edit, Send, Update } from 'grommet-icons';
import React from "react";
import { convertToDealPossible } from '../../../../services/common_service';
import { CONTRAT_CLOTURE, CONTRAT_ENVOYE, DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS, DEVIS_VALIDE, PROSPECT } from '../../../../services/devis_contrat_status';
import { getSimpleDateFR } from '../../../../services/utils';
import ContactRecap from '../../../contacts/contact_select_v2/recap';
import ApporteurGestionnaire from '../../../elements/apporteur_gestionaire';
import ApporteurModification from '../../../elements/apporteur_gestionaire/modification';
import CodePromo from '../../../elements/code_promo';
import DateRecap from '../../../elements/date_recap';
import DealStatusChange from '../../../elements/deal_status_change';
import RecapStatus from '../../../elements/devis_status';
import EmailEventTracker from '../../../elements/email_tracker';
import PanelAppel from '../../../elements/panel_appel';
import PrimaryButton from '../../../elements/primary_button';
import StandardText from '../../../elements/standard_text';
import StatusDateAppel from '../../../elements/status_date_appel';
import StripeCustomer from '../../../elements/stripe_customer';
import TrustiBot from '../../../trustibot';
import SectionDivider from '../../../voyages/components/session_devider';

const Recap = ({ deal, onEdit, showMessage, toDevis, toContrat, onDealReload, reload, onSendForm, onDealChange, onSave }) => {

    return (
        <Box
            direction="row"
            gap='small'
            style={{ width: "100vw" }}
        >
            <TrustiBot></TrustiBot>
            <Box
                // style={{ width: "100%" }}
                justify="center"
                alignContent="center"
                align="center"
            >
                <Box width={'xlarge'} gap='xsmall'>
                    <Box direction="row" fill="horizontal" justify="center" alignContent="center">
                        <Box>
                            <Card alignSelf="center">
                                <StatusDateAppel devis={deal} />
                            </Card>
                        </Box>
                        <Box>
                            <Box alignSelf="end">
                                <PanelAppel devis={deal} updateDevis={onDealChange} onSave={onSave} showMessage={showMessage} />
                            </Box>
                        </Box>
                    </Box>
                    <Box width="large" gap="small" justify='center' align='center' alignSelf='center'>
                        <Card style={{ width: "100%" }} pad="small" gap="small" justify='center'>
                            <Box width={'medium'} alignSelf='center'>
                                <StandardText size={'small'} label={"ID: " + deal.id} style={{ fontWeight: 'bold' }} textAlign='center' />
                            </Box>
                        </Card>
                    </Box>
                    <Box gap="small" width={'full'}>
                        <DateRecap deal={deal} />
                    </Box>
                    <Box width={'large'}>
                        <CodePromo devis={deal} />
                    </Box>
                    <Box width="large" justify='center' align='center' alignSelf='center'>
                        <Box width="full" >
                            <StripeCustomer devisId={deal.id} />
                        </Box>
                    </Box>
                </Box>
                <Box
                    width={'large'}
                    justify="center"
                    alignContent="center"
                    align="center"
                    gap="medium"
                >
                    <ContactRecap
                        contact={deal.client}
                    />

                    <DealRecap
                        updateDevis={onDealChange}
                        onSave={onSave}
                        deal={deal}
                    />

                </Box>
                {(deal.apporteur || deal.affiliation) && <Card pad={"small"} width={'xlarge'} >
                    <ApporteurGestionnaire apporteur={deal.apporteur} affiliation={deal.affiliation} source={deal.source} />
                </Card>}
                <Box width={'xlarge'}>
                    <EmailEventTracker messageIds={deal.emailEventIds} />
                </Box>
                <ApporteurModification
                    devis={deal}
                    apporteur={deal.apporteur}
                    showMessage={showMessage}
                    onReload={reload}
                    path={'pro-do'}
                />
                <Box direction='row' gap='medium' pad={'medium'}>
                    <PrimaryButton icon={<Edit />} label='Modifier' onClick={onEdit} />
                    {[DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS].includes(deal.status) &&
                        <PrimaryButton icon={<Send />} label='Envoyer formulaire' background={'#70A4EC'} onClick={onSendForm} />
                    }
                    {[DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS, DEVIS_VALIDE].includes(deal.status) &&
                        <PrimaryButton icon={<DocumentText />} label='Envoyer devis' background={'#70A4EC'} onClick={toDevis} />
                    }
                    {[DEVIS_VALIDE, CONTRAT_ENVOYE, CONTRAT_CLOTURE].includes(deal.status) &&
                        <PrimaryButton
                            label={`Envoyer un contrat`}
                            icon={<DocumentVerified />}
                            background="#64AEF6"
                            onClick={toContrat}
                        />
                    }
                    {deal.status === PROSPECT &&
                        <PrimaryButton
                            icon={<Update />}
                            onClick={() => {
                                deal.status = DEAL_POSSIBLE;
                                convertToDealPossible(deal, 'pro-do')
                                    .then(res => onDealReload(res.data));
                            }}
                            label="Convertir à Deal possible"
                        />
                    }
                    <DealStatusChange
                        deal={deal}
                        showMessage={showMessage}
                        onReload={reload}
                        path={'pro-do'}
                    />
                </Box>
            </Box>
        </Box>
    )
}

const DealRecap = ({ deal }) => {

    return (
        <Box
            width={'large'}
            justify="center"
            alignContent="center"
            align="center"
            gap="medium"
            pad={'xsmall'}
        >

            <Box >
                <StandardText style={{ fontWeight: 'bold' }} label='Information ouvrage' />
                <SectionDivider />
            </Box>
            <Box gap="xsmall" align="center" width={'full'}>
                <LineDisplay
                    label={"Adresse de l'ouvrage"}
                    value={`${deal.address}, ${deal.zip}, ${deal.city}`}
                />
                <LineDisplay
                    label={"Type chantier"}
                    value={`${deal.type_chantier}`}
                />
                <LineDisplay
                    label={"Type d'opération à assurer"}
                    value={`${deal.type_operation}`}
                />
                <LineDisplay
                    label={"Coût de construction de l'opération TTC"}
                    value={`${deal.cout_construction}`}
                />
                <LineDisplay
                    label={"Rénovation"}
                    value={deal.renovation ? 'Oui' : deal.renovation === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Y'a t'il une extension d'un bâtiment existant"}
                    value={deal.extension ? 'Oui' : deal.extension === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Reprise en sous oeuvre des fondations existantes"}
                    value={deal.reprise ? 'Oui' : deal.reprise === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Y’a t’il une intervention sur les structures porteuse"}
                    value={deal.intervention ? 'Oui' : deal.intervention === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Description de l'opération"}
                    value={`${deal.description_construction}`}
                />
                <LineDisplay
                    label={"Surface totale du bâtiment"}
                    value={`${deal.surface_total} m2`}
                />
                <LineDisplay
                    label={"Nombre d’étages"}
                    value={`${deal.nombre_etage}`}
                />
                <LineDisplay
                    label={"Sous-sol"}
                    value={deal.sous_sol ? 'Oui' : deal.sous_sol === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Destination de l’opération"}
                    value={`${deal.destination_operation}`}
                />
                <LineDisplay
                    label={"Nombre de logement concernés"}
                    value={`${deal.nombre_logement}`}
                />
                <LineDisplay
                    label={"Bailleur social ou locatif"}
                    value={deal.bailleur_social_locatif ? 'Oui' : deal.bailleur_social_locatif === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Utilisation de techniques de construction non courantes"}
                    value={deal.utilisation_non_courante ? 'Oui' : deal.utilisation_non_courante === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Maitrise d’oeuvre"}
                    value={deal.maitrise_oeuvre ? 'Oui' : deal.maitrise_oeuvre === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Etude de sol"}
                    value={deal.etude_sol ? 'Oui' : deal.etude_sol === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Début prévisionnel des travaux"}
                    value={deal.debut_travaux ? getSimpleDateFR(deal.debut_travaux) : 'Inconnu'}
                />
                <LineDisplay
                    label={"Date de réception prévisionnelle"}
                    value={deal.date_reception_previsionnelle ? getSimpleDateFR(deal.date_reception_previsionnelle) : 'Inconnu'}
                />
            </Box>
            <Box >
                <StandardText style={{ fontWeight: 'bold' }} label='Document' />
                <SectionDivider />
            </Box>
            <Box width="xlarge" direction="row" gap="small">
                <Card style={{ width: "100%" }} pad="medium">
                    {deal.entreprise?.file_kbis && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${deal.entreprise?.file_kbis}`}
                        >
                            <DocumentDownload />
                            KBIS
                        </a>
                    )}
                    {deal.entreprise?.file_statut && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${deal.entreprise?.file_statut}`}
                        >
                            <DocumentDownload />
                            Statut
                        </a>
                    )}
                    {deal.file_others ? (
                        deal.file_others.map((e, _idx) => {
                            return (
                                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                                    <DocumentDownload />
                                    {e && e.split("/").pop()}
                                </a>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </Card>
            </Box>
        </Box>
    )
}

const LineDisplay = ({ label, value }) => (
    <Box gap="xxsmall" direction='row' width={'full'}>
        <StandardText size={'small'} label={label + ' : '} />
        <StandardText size={'small'} style={{ fontWeight: 'bold' }} label={value} />
    </Box>
)

export default Recap;
import { Box, Tab, Tabs } from 'grommet';
import React from 'react';
import VoyageGaranties from './components/garanties';
import Vestute from './components/vestute';

const VoyageConfigs = ({showMessage}) => {

    return(
        <Box style={{ width: "100%" }}
                align="center" justify="center"
                alignSelf="center"
                pad={'medium'}
                alignContent="center" gap="medium">
                <Tabs style={{ width: '100%' }}>
                    <Tab title="Garanties">
                        <VoyageGaranties 
                            showMessage={showMessage}
                        />
                    </Tab>
                    <Tab title="Vestute">
                        <Vestute 
                            showMessage={showMessage}
                        />
                    </Tab>
                </Tabs>

            </Box>
    )
}


export default VoyageConfigs;
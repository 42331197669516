import { Box, CheckBox, Grid, Layer, Select } from "grommet";
import React, { useState } from 'react';
import { getAgences } from "../../agences/service";
import { getContact } from "../../../../services/contact_service";
import { getSubscriptions } from "../../souscriptions/service";
import StandardText from "../../../elements/standard_text";
import { toCurrency } from "../../../../services/utils";

export function AgenceSearch({ onAgenceSelected, onClose }) {
    const [agences, setAgences] = useState([]);

    const onSearch = text => {
        getAgences(0, text)
            .then(res => setAgences(res.data));
    }

    return (
        <Layer onClickOutside={onClose}>
            <Box width={'medium'} pad={'small'}>
                <Select
                    size="small"
                    labelKey={"raisonSocial"}
                    placeholder="Recherche une agence"
                    options={agences}
                    onSearch={onSearch}
                    onChange={({ option }) => onAgenceSelected(option)}
                    clear={{ position: 'top' }}
                />
            </Box>
        </Layer>
    )
}
const SubOption = React.memo(({ label }) => (
    <Box direction="row" gap="small" align="center" pad="xsmall">
        <Grid columns={["10%","20%","10%","10%","18%","8%","8%","8%", "8%"]} fill>
            {label.split('|').map(s => (s || '').trim()).map((s, index) => (
                <StandardText key={index + 1} label={s} size={"small"} />
            ))}         
        </Grid>
    </Box>
));

export function SubSearch({ onSubSelected, onClose }) {
    const [subs, setSubs] = useState([]);

    const onSearch = text => {
        getSubscriptions({ start: 0, query: text })
            .then(res => {
                res.data.forEach(s => {
                    //Numéro du contrat, agence, Nom, Prénom, mail, date de début, date de fin et date de souscription, prix du séjour
                    s.label = `${s.contrat_numero} | ${s.agence} | ${s.nom_locataire} | ${s.prenom_du_locataire} | ${s.mail_locataire}
                     | ${s.date_debut} | ${s.date_fin} | ${s.date_de_souscription} | ${toCurrency(s.prix_sejour)}`
                })
                setSubs(res.data);
            });
    }

    return (
        <Layer onClickOutside={onClose}>
            <Box width={'xlarge'} pad={'small'}>
                <Select
                    size="small"
                    placeholder="Recherche une souscription"
                    onSearch={onSearch}
                    labelKey={"label"}
                    valueKey={{ key: 'value', reduce: true }}
                    options={subs}
                    onChange={({ option }) => onSubSelected(option)}
                >
                    {(option, index) => (<SubOption label={option.label || "test"} />)}
                </Select>
            </Box>
        </Layer>
    )
}

export function ClientSearch({ onClientSelected, onClose }) {
    const [clients, setClients] = useState([]);

    const onSearch = text => {
        getContact(0, text)
            .then(res => {
                const _clients = res.data;
                _clients.forEach(c => {
                    c.fullName = `${c.lastname} ${c.firstname} (${c.email})`
                })
                setClients(res.data);
            });
    }

    return (
        <Layer onClickOutside={onClose}>
            <Box width={'medium'} pad={'small'}>
                <Select
                    size="small"
                    placeholder="Recherche une client"
                    labelKey={"fullName"}
                    options={clients}
                    onSearch={onSearch}
                    onChange={({ option }) => onClientSelected(option)}
                />
            </Box>
        </Layer>
    )
}


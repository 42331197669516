import React from "react";
import { Box, Card, Image, Text, Grommet, ResponsiveContext } from "grommet";

const theme = {
  card: {
    border: {
      color: "red",
      size: "medium",
      style: "double",
    },
    container: {},
  },
};

const ClickableCards = ({ cards }) => {
  const cardComponents = cards.map((card, index) =>
    card.inactive ? null : (
      <ResponsiveContext.Consumer key={index}>
        {(size) => {
            const maxSize = (size !== 'small') ? (size === 'medium' ? 110 : 140) : (160 / cards.length )
          return (
            <Box key={card.text} pad={size !== 'small' ? 'medium' : 'small'}>
              <Card
                margin={'small'}
                pad={'small'}
                align="center"
                onClick={card.onClick}
                style={{
                  borderStyle: card.selected,
                  textAlign: "center",
                  width: maxSize,
                  height: maxSize,
                }}
                justify="center"
              >
                <Image src={card.img} style={{ height: maxSize / 3 }} />
                <Text
                  margin="small"
                  justify="center"
                  style={{ fontWeight: "bold" }}
                  size={'xsmall'}
                >
                  {card.text}
                </Text>
                {card.subText && <Text margin="xsmall" justify="center" style={{ fontSize: 10 }}>
                  {card.subText}
                </Text>}
              </Card>
            </Box>
          );
        }}
      </ResponsiveContext.Consumer>
    )
  );
  return (
    <Grommet theme={theme}>
      <Box direction="row" justify="center">
        {cardComponents}
      </Box>
    </Grommet>
  );
};

export default ClickableCards;

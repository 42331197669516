import { Box, FileInput } from 'grommet';
import { CaretPrevious, Save } from 'grommet-icons';
import React from "react";
import { uploadFile } from '../../../../services/storage_service';
import CustomInput from '../../../elements/custom_input';
import DateInput from '../../../elements/date_input';
import PrimaryButton from '../../../elements/primary_button';
import CustomSelect from '../../../elements/select_fill';
import StandardText from "../../../elements/standard_text";
import SectionDivider from "../../../voyages/components/session_devider";

const doUploadFile = (files, callback, showError) => {
    const fileList = files;
    if (fileList) {
        for (let i = 0; i < fileList.length; i += 1) {
            const file = fileList[i];
            console.log(file.name);
            uploadFile(file)
                .then((result) => {
                    console.log("result = ", result.data);
                    callback(result.data);
                })
                .catch((err) => {
                    showError("Erreur inattendu télécharger.");
                    callback([]);
                });
        }
    }
};

const Info = ({ deal, onDealChange, showMessage, prev, save }) => {

    return (
        <Box
            style={{ width: "100%" }}
            justify="center"
            alignContent="center"
            align="center"
        >
            <Box
                width={'large'}
                justify="center"
                alignContent="center"
                align="center"
                gap="medium"
            >

                <Box >
                    <StandardText style={{ fontWeight: 'bold' }} label='Informations sur votre activité' />
                    <SectionDivider />
                </Box>

                <Box gap="xxsmall" align="center" width={'full'}>

                    <LineInput label="Précision sur votre activité"
                        value={deal.precision_activite}
                        onDealChange={val => onDealChange('precision_activite', val)}
                    />
                </Box>
                <Box >
                    <StandardText style={{ fontWeight: 'bold' }} label='Informations sur votre entreprise' />
                    <SectionDivider />
                </Box>

                <Box gap="xxsmall" align="center" width={'full'}>
                    <Line
                        left={<StandardText size={'small'} label={"Date de création"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_creation}
                                onChange={(date) => onDealChange("date_creation", date)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Date de résiliation"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_resiliation}
                                onChange={(date) => onDealChange("date_resiliation", date)}
                            />
                        }
                    />

                    <Line
                        left={<StandardText size={'small'} label={'Forme juridique'} />}
                        right={
                            <CustomSelect
                                size={'small'}
                                options={[
                                    "Association Loi 1901 / Fédération",
                                    "CUMA (Coopérative d’Utilisation de Matériel Agricole)",
                                    "Coopérative",
                                    "EARL (Entreprise Agricole à Responsabilité Limitée)",
                                    "EURL (Entreprise Unipersonnelle à Responsabilité Limitée)",
                                    "Entrepreneur Individuel",
                                    "Fondation",
                                    "GAEC (Groupement Agricole d’Exploitation en Commun)",
                                    "GIE (Groupement d’Intérêt Economique)",
                                    "SA (Société Anonyme)",
                                    "SARL (Société à Responsabilité Limitée)",
                                    "SAS (Société par Action Simplifiée)",
                                    "SASU (Société par Action Simplifiée Unipersonnelle)",
                                    "SCA (Société en Commandite par Action)",
                                    "SCEA (Société Civile d’Exploitation Agricole)",
                                    "SCI (Société Civile Immobilière)",
                                    "SCM (Société Civile de Moyens)",
                                    "SCP (Société Civile Professionnelle)",
                                    "SELAFA (Société d’Exercice Libéral A Forme Anonyme)",
                                    "SELARL (Société d’Exercice Libéral à Responsabilité Limitée)",
                                    "SEM (Société d’Economie Mixte)",
                                    "SNC (Société en Nom Collectif)",
                                    "Société Civile",
                                    "Société en Commandite"
                                ]}
                                value={deal.forme_juridique}
                                onChange={({ option }) => onDealChange('forme_juridique', option)}
                            />
                        }
                    />

                    <LineInput label="Effectif de l’entreprise"
                        value={deal.effectif_entreprise}
                        onDealChange={val => onDealChange('effectif_entreprise', val)}
                    />
                    <LineInput label="Chiffre d’affaires annuel HT"
                        value={deal.ca_ht}
                        placeholder='€'
                        onDealChange={val => onDealChange('ca_ht', val)}
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Date d’effet souhaité"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_effet}
                                onChange={(date) => onDealChange("date_effet", date)}
                            />
                        }
                    />
                    <Box pad={'small'}>
                        <FileInput
                            size={'xxsmall'}
                            messages={{
                                browse: "Choisir",
                                dropPrompt: "Ajouter un autre document",
                            }}
                            multiple={false}
                            onChange={(event) => {
                                doUploadFile(
                                    event.target.files,
                                    async (data) => {
                                        if (data.length > 0) {
                                            let _uploaded = data[0];
                                            let _files = deal.file_others || [];
                                            _files.push(_uploaded.url);
                                            onDealChange("file_others", _files);
                                        }
                                    },
                                    mes => showMessage(mes, 'error')
                                );
                            }}
                        />
                    </Box>
                </Box>


                <Box pad={'medium'} direction='row' gap="medium">
                    < PrimaryButton icon={<CaretPrevious />} label='Retour' background={'#707070'} onClick={prev} />
                    < PrimaryButton icon={<Save />} label='Enregistrer' onClick={save} />
                </Box>
            </Box>
        </Box>
    )
}

const Line = ({ left, right }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                {left}
            </Box>
            <Box width={'full'}>
                {right}
            </Box>
        </Box>
    )
}



const LineInput = ({ label, placeholder, value, onDealChange }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <CustomInput size='xsmall' placeholder={placeholder}
                    value={value}
                    onChange={event => onDealChange(event.target.value)}
                />
            </Box>
        </Box>
    )
}

export default Info;
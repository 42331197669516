import { Box, Tab, Tabs } from "grommet";
import React from "react";
import serviceContact, { getClosedContractByClient, getDealsByStatusAndClient } from "../../../services/contact_service";
import { getCommissionTauxConfig, getUsers } from "../../../services/dashboard";
import {
	clearContextBot,
	saveContextBot
} from "../../../services/storage_service";
import { getPopupContainer } from "../../dashboard/status_report";
import ViewTable from "../../elements/view_table";
import ClientInfo from "./info";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { DEVIS } from "../../../services/devis_contrat_status";

class ContactModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contact: props.selectedContact || { properties: {} },
			creationMode: props.creationMode,
			closedContracts: [],
			devisDeals: [],
			commission_taux: undefined,
			selectedContract: undefined,
			commerciaux: [],
			loading: false
		};
		saveContextBot({ client: props.selectedContact || { properties: {} } });
	}

	componentDidMount = () => {
		if (!this.state.contact.id) return;
		getClosedContractByClient(this.state.contact.id)
			.then(res => this.setState({ closedContracts: res.data }));
		getDealsByStatusAndClient(this.state.contact.id, DEVIS)
			.then(res => this.setState({ devisDeals: res.data }));
		getCommissionTauxConfig()
			.then(res => {
				this.setState({ commission_taux: res.data });
			})
		this.setState({ loading: true });
		getUsers()
			.then(res => this.setState({ commerciaux: res.data, loading: false }))
			.catch(err => this.setState({ loading: false }))
	}

	submitModification = (contact) => {
		let handler = null;
		if (contact.id) {
			handler = serviceContact.updateContact;
		} else {
			handler = serviceContact.createContact;
		}
		delete contact.updatedAt;
		delete contact.createdAt;

		handler(contact)
			.then((res) => {
				this.setState({ contact: res.data });
				this.props.refresh();
				this.props.showMessage("Contact a été mis à jour", "success");
			})
			.catch((err) => {
				console.log(err.response.data);
				let message;
				if (err.response && err.response.data) {
					message = err.response.data.message;
				}

				this.props.showMessage(message || "Error inattendu", "error");
			});
	};

	deleteContact = (contact) => {
		serviceContact
			.deleteContact(contact.id)
			.then((res) => {
				this.props.refresh();
				this.props.onClose();
				this.props.showMessage("Contact a été supprimé", "success");
			})
			.catch((err) => console.log(err));
	};

	updateContact = (field, value) => {
		let contact = this.state.contact;
		contact[field] = value;
		this.setState({ contact: Object.assign({}, contact) });
	};

	render() {
		return (
			<Box
				// style={{ width: "100%" }}
				alignContent="center"
				gap="medium"
				pad="large"
			>
				<BlockUi tag="div" blocking={this.state.loading}>
					<Tabs>
						<Tab title="Client">
							<ClientInfo
								creationMode={this.props.creationMode}
								contact={this.state.contact}
								submitModification={this.submitModification}
								updateContact={this.updateContact}
								deleteContact={this.deleteContact}
								showMessage={this.props.showMessage}
								onClose={() => {
									clearContextBot();
									this.props.onClose && this.props.onClose();
								}}
								commerciaux={this.state.commerciaux}
								onRefresh={this.props.refresh}
							/>
						</Tab>
						<Tab title="Devis">

							{this.state.selectedDevis ?
								getPopupContainer(this.state.selectedDevis,
									() => this.setState({ selectedDevis: undefined }),
									() => { },
									() => { },
									this.props.showMessage
								)
								:
								<Box>
									<Box pad='small' style={{ overflowY: 'scroll', width: '100%', marginBottom: 20 }}>
										<ViewTable
											reports={this.state.devisDeals}
											commission_taux={this.state.commission_taux}
											onDevisSelected={devis => this.setState({ selectedDevis: devis })}
											commerciaux={this.state.commerciaux}
										/>
									</Box>
								</Box>}
						</Tab>
						<Tab title="Contrats">

							{this.state.selectedContract ?
								getPopupContainer(this.state.selectedContract,
									() => this.setState({ selectedContract: undefined }),
									() => { },
									() => { },
									this.props.showMessage
								)
								:
								<Box>
									<Box pad='small' style={{ overflowY: 'scroll', width: '100%', marginBottom: 20 }}>
										<ViewTable
											reports={this.state.closedContracts}
											commission_taux={this.state.commission_taux}
											onDevisSelected={devis => this.setState({ selectedContract: devis })}
											commerciaux={this.state.commerciaux}
										/>
									</Box>
								</Box>}
						</Tab>
					</Tabs>
				</BlockUi>
			</Box>
		);
	}
}
export default ContactModal;

import { Box, Layer } from "grommet";
import { AddCircle } from "grommet-icons";
import React from "react";
import { getSimpleDateFR, toCurrency, toPercentage } from "../../../../../services/utils";
import PopupConfirmation from "../../../../elements/popup_confirmation";
import { CardContainer, LineReadOnlyText } from "../../../../elements/refont/containers/text-containser";
import Table from "../../../../elements/refont/table_v2";
import { TextLabel } from "../../../../elements/refont/text-label";
import { getVestute } from "../../../configs/service";
import { calculateTotal, calculateValeurResiduelle, calculateValeurRetenue, calculateVetuste } from "./utils-dommage";

const _headers = [
    { label: "", key: "", type: "action", },
    { label: "Catégorie", key: "categorie", type: 'select', options: [] },
    { label: "Date d'achat", key: "date_achat", type: 'date' },
    { label: "Date de survenance", key: "date_survenance", type: 'date' },
    { label: "Prix d'achat d'origine", key: "prix_achat_origine", type: 'text', subType: 'currency' },
    { label: "Montant de réparations", key: "montant_reparations", type: 'text', subType: 'currency' },
    { label: "Total à pris en charge", key: "total_pris_en_charge", readOnly: true, type: 'text', subType: 'currency' },
]

const newLine = () => _headers.reduce((acc, header) => {
    acc[header.key] = null;
    return acc;
}, {});

export default function TableDommage({ sinistre, onChangeSubmit }) {
    const [headers, setHeaders] = React.useState(_headers);
    const [values, setValues] = React.useState({ ...sinistre });
    const [tableVestute, setTableVestute] = React.useState([]);
    const [dommages, setDommages] = React.useState([]);

    const [viewIndex, setViewIndex] = React.useState(-1);
    const [deleteIndex, setDeleteIndex] = React.useState(-1);


    React.useEffect(() => {
        getVestute().then((res) => {
            if (res.data.length > 0) {
                setTableVestute(res.data[0].value || []);
                headers[1].options = res.data[0].value.map(c => c.equipement);
                setHeaders([...headers]);
            }
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    React.useEffect(() => {
        setValues({ ...sinistre });
    }, [sinistre]);

    React.useEffect(() => {
        if(!tableVestute.length) return;
        const _dommages = (values.dommages || []).map((dommage, index) => {
            dommage.total_pris_en_charge = calculateTotal(dommage, tableVestute);
            if(!dommage.total_pris_en_charge) {
                console.log('dommage', dommage, tableVestute);
            }
            dommage.vetuste = calculateVetuste(dommage, tableVestute);
            dommage.actions = [
                { type: 'view', onClick: () => setViewIndex(index) },
                { type: 'delete', onClick: () => setDeleteIndex(index) }
            ]
            console.log('dommage', dommage);
            return dommage;
        });
        setDommages(_dommages);
    }, [values, tableVestute]);

    const onSubmit = (values) => {
        // prevent saving unused data in dommages;
        const dommages = values.dommages.map(dommage => {
            const { actions, total_pris_en_charge, vetuste, ..._dommage } = dommage;
            delete dommage[""];
            delete dommage.actions;
            delete dommage.total_pris_en_charge;
            delete dommage.vetuste;
            return _dommage;
        });
        onChangeSubmit({ ...values, dommages });
    }

    return (
        <CardContainer>
            <Box direction={'row'} gap={'small'} justify="between" align="center">
                <Box fill background={"white"} direction="column">
                    <Box direction={'row'} gap={'small'} justify="between" align="center">
                        <TextLabel label={"Tableau de dommages"} variant={"header3"} />
                    </Box>
                    <Table
                        data={dommages}
                        headers={headers}
                        onChangeSubmit={dommages => onSubmit({ ...values, dommages })}
                    />

                    <Box pad={{ top: 'xsmall' }} gap="xsmall"
                        style={{ boxShadow: "none" }}
                    >
                        <AddCircle color="brand" size="small"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                const dommages = values.dommages || [];
                                setValues({ ...values, dommages: [...dommages, newLine()] });
                            }}
                        />

                    </Box>
                </Box>
                {deleteIndex > -1 && (
                    <PopupConfirmation
                        message="Voulez-vous supprimer cette ligne ?"
                        primaryAction={() => {
                            const _dommages = [...values.dommages];
                            _dommages.splice(deleteIndex, 1);
                            setDeleteIndex(-1);
                            onSubmit({ ...values, dommages: _dommages });
                        }}
                        secondaryAction={() => setDeleteIndex(-1)}
                        primaryActionText={"Supprimer"}
                        secondaryActionText={"Annuler"}
                    />)}
                {viewIndex > -1 && (
                    <ViewDommage
                        dommage={dommages[viewIndex]}
                        equipement={tableVestute?.find(c => c.equipement === dommages[viewIndex].categorie)}
                        vestute_value={calculateVetuste(dommages[viewIndex], tableVestute)}
                        total_pris_en_charge={calculateTotal(dommages[viewIndex], tableVestute)}
                        residuelle={calculateValeurResiduelle(dommages[viewIndex], tableVestute)}
                        valueRetenue={calculateValeurRetenue(dommages[viewIndex], tableVestute)}
                        onClose={() => setViewIndex(-1)}
                    />)}
            </Box>
        </CardContainer>
    )
}

const ViewDommage = ({
    dommage,
    equipement,
    vestute_value,
    total_pris_en_charge,
    residuelle,
    valueRetenue,
    onClose

}) => (
    <Layer onClickOutside={onClose}>
        <Box pad={'medium'} width={'medium'} gap='small' justify='center' align='start'>
            <Box fill background={"white"} direction="column" width={'medium'}>
                <Box direction={'row'} gap={'small'} justify="between" align="center">
                    <TextLabel label={dommage.categorie} variant={"header3"} />
                </Box>
                <Box pad={{ vertical: '0.5rem' }}>
                    <LineReadOnlyText
                        label="Durée de vie"
                        value={equipement?.dure_vie}
                    />
                    <LineReadOnlyText
                        label="Franchise en ans"
                        value={equipement?.franchise_en_ans}
                    />
                    <LineReadOnlyText
                        label="Abattement/an"
                        value={equipement?.abattement}
                    />
                    <LineReadOnlyText
                        label="Résiduelle"
                        value={equipement?.residuelle}
                    />
                    <LineReadOnlyText
                        label="Date d'achat"
                        value={getSimpleDateFR(dommage?.date_achat)}
                    />
                    <LineReadOnlyText
                        label="Date de survenance"
                        value={getSimpleDateFR(dommage?.date_survenance)}
                    />
                    <LineReadOnlyText
                        label="Prix d'achat d'origine"
                        value={toCurrency(dommage?.prix_achat_origine)}
                    />
                    <LineReadOnlyText
                        label="Montant de réparations"
                        value={toCurrency(dommage?.montant_reparations)}
                    />
                    <LineReadOnlyText
                        label="Vétusté"
                        value={toPercentage(vestute_value)}
                    />
                    <LineReadOnlyText
                        label="Valeur résiduelle"
                        value={toCurrency(residuelle)}
                    />
                    <LineReadOnlyText
                        label="Valeur retenue"
                        value={toCurrency(valueRetenue)}
                    />
                    <LineReadOnlyText
                        label="Total à pris en charge"
                        value={toCurrency(total_pris_en_charge)}
                    />
                </Box>
            </Box>
        </Box>

    </Layer>
)
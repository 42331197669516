import React from "react";
import { Box, Card, Heading, Text } from "grommet";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import PrimaryButton from "../../../elements/primary_button";
import {
    Previous,
} from "grommet-icons";
import Line from "../../../elements/line_container";
import ReactJson from 'react-json-view'
import { getSimpleDateTimeFR } from "../../../../services/utils";



class LogsDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            log: props.log,
            originalLog: undefined,
        };
    }

    render() {
        console.log("this.props.log = ", this.props.log);
        return (
            <Box
                style={{ width: "100%" }}
            >
                <BlockUi tag="div" blocking={this.state.loading}>
                    <Box style={{ width: "100%" }} justify="center"
                        alignContent="center"
                        align="center"
                    >
                        <Box width="xlarge" justify="center">
                            <Box pad="small" width="xsmall">
                                <PrimaryButton
                                    icon={<Previous />}
                                    onClick={() => {
                                        this.props.onClose && this.props.onClose();
                                    }}
                                />
                            </Box>
                            <Box>
                                <Heading level={3}>Logs détail</Heading>
                                <Line leftLabel={"Date de création"}
                                    rightChildren={<Text>{getSimpleDateTimeFR(this.props.log.createdAt)}</Text>}
                                ></Line>
                                <Line leftLabel={"Nom"}
                                    rightChildren={<Text>{this.props.log.api_name}</Text>}
                                ></Line>
                                <Line leftLabel={"Méthode"}
                                    rightChildren={<Text>{this.props.log.method}</Text>}
                                ></Line>
                                <Line leftLabel={"Statut"}
                                    rightChildren={<Text>{this.props.log.status}</Text>}
                                ></Line>
                                <Line leftLabel={"Header"}
                                    rightChildren={<Card pad={'small'}><ReactJson src={this.props.log.header}
                                        name={null}
                                        displayDataTypes={false}
                                        indentWidth={2}
                                        collapsed={false}
                                        style={{ fontSize: 'small' }}
                                    /></Card>}
                                ></Line>
                                <Line leftLabel={"Params"}
                                    rightChildren={<Card pad={'small'}><ReactJson src={this.props.log.params}
                                        name={null}
                                        displayDataTypes={false}
                                        indentWidth={2}
                                        collapsed={false}
                                        style={{ fontSize: 'small' }}
                                    /></Card>}
                                ></Line>
                                <Line leftLabel={"Response"}
                                    rightChildren={<Card pad={'small'}><ReactJson src={this.props.log.response}
                                        name={null}
                                        displayDataTypes={false}
                                        indentWidth={2}
                                        collapsed={false}
                                        style={{ fontSize: 'small' }}
                                    /></Card>}
                                ></Line>
                            </Box>

                        </Box>

                        <Box style={{ height: 40 }} />
                    </Box>
                </BlockUi>

            </Box>
        );
    }
}


export default LogsDetail;

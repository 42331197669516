import React from "react";
import { Box, Button, Card, Image, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { getAppConfig, getAppComptablePayouts, getRequestPayOut, putRequestPayOut, putPayOutPaid } from "../service";
import { getSimpleDateFR, getSorted } from "../../../services/utils";
import Refresh from "../../elements/refresh";
import SearchComponent from "../../elements/search";
import DateSort from "../../elements/date_sort";
import { CircleAlert, EmptyCircle, Square, StatusGood, StatusGoodSmall } from "grommet-icons";
import AppComptablePayoutInfo from "./info";
import { getClientName } from "../../outils/client_util";


class AppComptablePayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appComptables: [],
      popupOpen: false,
      selectedPartenaire: {},
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    };
  }
  componentDidMount = () => {
    this.loadAppComptablePayouts();
  };

  loadAppComptablePayouts = () => {
    this.setState({ appComptables: [] }, () => {
      getRequestPayOut()
        .then((res) => {
          this.setState({ appComptables: res.data });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false });
  };

  openPartenaire = (partenaire) => {
    this.setState({ selectedPartenaire: partenaire, popupOpen: true });
  };

  addPartenaire = () => this.openPartenaire({});

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  updateStatusPayout(id) {
    putPayOutPaid({id: id, status: 'Confirmé' }).then((res) => {
      this.loadAppComptablePayouts();
    })
      .catch((err) => {
        console.log("err", err);
        this.props.showMessage("Erreur inattendu");
      });
  }

  render() {
    return this.state.popupOpen ? (
      // <Text>App User</Text>
      <AppComptablePayoutInfo
        showMessage={this.props.showMessage}
        partenaire={this.state.selectedPartenaire}
        onPartenaireChange={(field, value) => {
          let partenaire = this.state.selectedPartenaire;
          partenaire[field] = value;
          this.setState({ selectedPartenaire: Object.assign({}, partenaire) });
        }}
        savePartenaire={(partenaire) => {
          if (partenaire._id) {
            // this.updatePartenaire(partenaire);
          } else {
            // this.addPartenaire(partenaire);
          }
        }}

        onClose={this.onClose}
        reload={this.loadAppComptablePayouts}
      />
    ) : (
      <Box style={{ width: "100%" }}
        align="center" justify="center"
        alignSelf="center"
        alignContent="center" gap="medium">
        <Text size="xxlarge" style={{ padding: 10 }}><b>Retrait</b></Text>
        <Box width="xlarge" gap="medium">
          <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
            <Refresh refresh={this.loadAppComptablePayouts} />
            <SearchComponent
              search={value => {
                this.search(value, this.state.filter._query);
              }}
              query={this.state.filter._query}
              updateQuery={value => this.updateFilter('_query', value)}
            />
            <DateSort
              onChange={value => this.updateFilter('sort', value)}
            />
          </Box>


          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Text>Création/<br />Modification</Text>
                </TableCell>
                <TableCell>
                  <Text>Montant</Text>
                </TableCell>
                <TableCell>Apporteur</TableCell>
                <TableCell>
                  <Text>RIB</Text>
                </TableCell>
                <TableCell>
                  <Text>BIC</Text>
                </TableCell>
                <TableCell>
                  <Text>Status</Text>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getSorted(this.state.appComptables, this.state.filter.sort).map((appComptable, index) => (
                <TableRow key={index + 1}>
                  <TableCell>
                    <Text size="small"><strong>{getSimpleDateFR(appComptable.createdAt)}</strong><br />{getSimpleDateFR(appComptable.updatedAt)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appComptable.amount}€</Text>
                  </TableCell>

                  <TableCell>
                    <Text>{appComptable.user?.prenom} {appComptable.user?.nom}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appComptable.user?.iban}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appComptable.user?.bic}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appComptable.status}</Text>
                  </TableCell>

                  <TableCell>
                    {appComptable.status !== 'Confirmé' && <Button
                      label="Virement fait"
                      primary
                      onClick={() => {
                        if (window.confirm('Vous êtes sûr que le virement fait ?')) {
                          this.updateStatusPayout(appComptable.id);
                        }
                      }}
                    />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  }
}



export default AppComptablePayout;

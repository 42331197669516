const TEAG = 0.0371;
// present: - total credit
// payment: mensualite

function calculateMensualiteMini(present) {
    const periods = 300;// 25 years
    return calculateMensualite(periods, present);
}

function calculCapaciteEmprunt(periods, payment) {
    const rate = TEAG / 12;
    return (((1 - Math.pow(1 + rate, periods)) / rate) * payment) / Math.pow(1 + rate, periods);
}

const getDureeDuRemboursement = (payment, present) => {
    const rate = TEAG / 12;
    const num = payment;
    const den = present * rate + payment;
    return Math.log(num / den) / Math.log(1 + rate);
}

function calculateMensualite(periods, totalCredit) {
    const present = - totalCredit;
    const rate = TEAG / 12;

    // Return payment
    var term = Math.pow(1 + rate, periods);
    const result = present * rate / (1 - 1 / term);
    return parseInt(-result.toFixed(0));
}


module.exports = {
    calculateMensualite
}
import React from "react";
import { Box, Card, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { getRemunerationByUser } from "../service";
import { getSimpleDateFR, getSorted } from "../../../services/utils";
import { SmallLine } from "../../elements/line_container";


class AppRemunerationByUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRemunerations: [],
      popupOpen: false,
      selectedUser: {},
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      },
      my_remuneration: 0,
      team_remuneration: 0,

    };
  }
  componentDidMount = () => {
    this.loadAppUsers();
  };

  bindData = data => {
    var _my, _team = 0;
    for (let i = 0; i < data?.length; i++) {
      const element = data[i];
      if (element.level === 0) {
        _my += element.amount;
      } else {
        _team += element.amount;
      }
    }
    this.setState({
      userRemunerations: data,
      my_remuneration: _my,
      team_remuneration: _team
    })
  }

  loadAppUsers = () => {
    this.setState({ userRemunerations: [] }, () => {
      getRemunerationByUser(this.props.user?.id)
        .then((res) => {
          this.bindData(res.data);
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false });
  };

  openUser = (user) => {
    this.setState({ selectedUser: user, popupOpen: true });
  };

  addUser = () => this.openUser({});

  search = (query) => {
    getRemunerationByUser(this.props.user?.id, query)
      .then(res => {
        this.bindData(res.data);
      })
      .catch(err => {
        console.log('err', err);
        this.props.showMessage('Erreur inattendu');
      })
  }



  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  render() {
    return (<Box
      gap="small"
      width="large"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Card pad={'small'} width='large' gap={'small'}>
        <Text size="large" ><b>Informations</b></Text>

        <SmallLine leftLabel={'Total de mes rémunérations'} bold={true}
          rightChildren={<Text size="small" >{this.state.my_remuneration} €</Text>}
        />
        <SmallLine leftLabel={'Total rémunération équipe'} bold={true}
          rightChildren={<Text size="small" >{this.state.team_remuneration} €</Text>}
        />

      </Card>
      <Card pad={'small'} width='large' gap={'small'}>
        <Text size="large" ><b>Tableau de mes rémunérations</b></Text>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>
                <Text size="small">Création/<br />Modification</Text>
              </TableCell>
              <TableCell>
                <Text size="small">Montant</Text>
              </TableCell>
              <TableCell>
                <Text size="small">Produit</Text>
              </TableCell>
              <TableCell>
                <Text size="small">Status</Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          {this.state.userRemunerations.length > 0 && <TableBody>
            {getSorted(this.state.userRemunerations.filter(e => e.level === 0), this.state.filter.sort).map((userRemuneration, index) => (
              <TableRow key={index + 1}>
                <TableCell>
                  <Text size="small"><strong>{getSimpleDateFR(userRemuneration.createdAt)}</strong><br />{getSimpleDateFR(userRemuneration.updatedAt)}</Text>
                </TableCell>
                <TableCell>
                  <Text>{(userRemuneration.amount ).toFixed(2)} €</Text>
                </TableCell>
                <TableCell>
                  <Text>{userRemuneration.produit}</Text>
                </TableCell>
                <TableCell>
                  <Text>{userRemuneration.status}</Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>}
        </Table>
        {this.state.userRemunerations.length === 0 && <Box alignContent="center" align="center"><Text size="small">Aucun donné</Text></Box>}
      </Card>
      <Card pad={'small'} width='large' gap={'small'}>
        <Text size="large" ><b>Tableau rémunération equipe</b></Text>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>
                <Text size="small">Création/<br />Modification</Text>
              </TableCell>
              <TableCell>
                <Text size="small">Montant</Text>
              </TableCell>
              <TableCell>
                <Text size="small">Produit</Text>
              </TableCell>
              <TableCell>
                <Text size="small">Niveau</Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          {this.state.userRemunerations.length > 0 && <TableBody>
            {getSorted(this.state.userRemunerations.filter(e => e.level > 0), this.state.filter.sort).map((userRemuneration, index) => (
              <TableRow key={index + 1}>
                <TableCell>
                  <Text size="small"><strong>{getSimpleDateFR(userRemuneration.createdAt)}</strong><br />{getSimpleDateFR(userRemuneration.updatedAt)}</Text>
                </TableCell>
                <TableCell>
                  <Text>{(userRemuneration.amount / 100).toFixed(2)} €</Text>
                </TableCell>
                <TableCell>
                  <Text>{userRemuneration.produit}</Text>
                </TableCell>
                <TableCell>
                  <Text>{userRemuneration.level}</Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>}
        </Table>
        {this.state.userRemunerations.length === 0 && <Box alignContent="center" align="center"><Text size="small">Aucun donné</Text></Box>}
      </Card>
    </Box>);
    ;
  }
}

export default AppRemunerationByUser;

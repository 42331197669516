
import { Box, FileInput, Heading, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import { Close, Copy, Download, Upload, Validate } from 'grommet-icons';
import moment from 'moment';
import React from 'react';
import BlockUi from 'react-block-ui';
import "react-block-ui/style.css";
import { getUser, uploadFile } from '../../../../../../services/storage_service';
import { getSimpleDateFR, getSimpleDateTimeFR } from '../../../../../../services/utils';
import PopupConfirmation from '../../../../../elements/popup_confirmation';
import PrimaryButton from '../../../../../elements/primary_button';
import { MultipleLineToggleButtons } from '../../../../../elements/toggle_button/toggle_button';
import { uploadMassSubscription } from '../../../../souscriptions/service';
import products from '../../../../utils/types';
import { importSubscription } from '../../../service';

const PROPERTIES = [
    { label: 'Nom', prop: 'nom_locataire' },
    { label: 'Prénom', prop: 'prenom_du_locataire' },
    { label: 'Email', prop: 'mail_locataire' },
    { label: 'Téléphone client', prop: 'telephone_locataire' },
    { label: 'Adresse client', prop: 'adresse_du_locataire' },
    { label: 'Adresse location', prop: 'adresse_du_risque' },
    { label: 'Liste des bénéficiaire', prop: 'liste_beneficiaires' },
    { label: 'Compagnie', prop: 'compagnie' },
    { label: 'PolicyID', prop: 'policyID' },
    { label: 'Date de souscription', prop: 'date_de_souscription' },
    { label: 'Date de début séjour', prop: 'date_de_debut_de_sejour' },
    { label: 'Date de fin séjour', prop: 'date_de_fin_de_sejour' },
    { label: 'Prix location', prop: 'prix_sejour' },
]

const toRef = ref => ref.toLowerCase().replace(/ /g, '-');

class Import extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            subscriptions: [],
            agence: props.agence,
            selectedProduct: undefined,
            popup: false,
            loading: false
        }
    }

    upload = () => {
        if (!this.state.selectedProduct) {
            this.props.showMessage('Veuillez sélectionner un produit', 'error');
            return;
        }
        let subs = this.state.subscriptions;
        if (subs.length === 0) {
            this.props.showMessage('Veuillez remplir le fichier', 'error');
            return;
        }
        if (subs.map(s => s.prix_sejour).filter(s => !s || isNaN(parseFloat(s))).length > 0) {
            this.props.showMessage('Veuillez remplir prix séjour', 'error');
            return;
        }
        let product = this.state.agence.voyage_products.find(p => p.type === 'Fichier' && p.product === this.state.selectedProduct);
        let ref = `IMP-${toRef(this.state.agence.raisonSocial)}-${moment(new Date()).format('DD-MM-YYYY')}-${Math.random().toString().substring(2, 6)}`;
        subs.forEach(s => {
            s.produit = this.state.selectedProduct;
            s.prix_sejour = parseFloat((s.prix_sejour.replace(',', '.')));
            s.vent_cannal = 'Fichier';
            s.contrat_numero = 'TV-' + Math.random().toString().substring(2, 16);
            s.ref_import = ref;
        });
        this.setState({ loading: true });
        uploadMassSubscription({ subs, product, agence: this.state.agence })
            .then(res => {
                this.setState({ loading: false, subscriptions: [], popup: false });
                this.props.showMessage('Uploadé avec succès', 'success');
                this.props.onClose();
            })
            .catch(err => {
                this.props.showMessage('Erreur inattendu', 'error');
                console.log(err);
                this.setState({ loading: false });
            })
    }

    downloadTemplate = () => {
        window.open('https://storage.googleapis.com/trustiway-bo-dev.appspot.com/template_import_7417650781/template_import_7417650781.xlsx', '_blank');
    }

    uploadTemplate = () => {

    }

    onPaste = async (prop) => {
        let subs = this.state.subscriptions;
        const text = await navigator.clipboard.readText();
        let values = text.split('\n').map(s => s ? s.replace('\r', '') : '');

        if (subs.length === 0) {
            values.forEach(v => {
                let sub = {};
                sub[prop] = v;
                subs.push(sub);
            })
        } else if (values.length > subs.length) {
            values.forEach((val, index) => {
                if (index < subs.length) {
                    subs[index][prop] = val;
                } else {
                    let sub = {};
                    sub[prop] = val;
                    subs.push(sub);
                }
            })
        } else {
            subs.forEach((sub, index) => sub[prop] = values.length > index ? values[index] : undefined);
        }

        this.setState({ subscriptions: subs.map(s => s) });
    }

    doUploadFile = (event) => {
        let files = event.target.files;
        if (!files || files.length === 0) return;

        let file = files[0];
        if (!file.name.includes('.xlsx')) {
            this.props.showMessage('Veuillez sélectionner un fichier template xlsx', 'error');
            event.target.value = null;
            return;
        }
        if (!this.state.selectedProduct) {
            this.props.showMessage('Veuillez sélectionner un produit', 'error');
            return;
        }
        let ref_import = `IMP-${toRef(this.state.agence.raisonSocial)}-${moment(new Date()).format('DD-MM-YYYY')}-${Math.random().toString().substring(2, 6)}`;
        this.setState({ loading: true })
        let product = this.state.agence.voyage_products.find(p => p.type === 'Fichier' && p.product === this.state.selectedProduct);
        uploadFile(file)
            .then((result) => {
                // console.log("result = ", result.data);
                let uploadedFile = result.data[0];
                let body = {
                    ref_import,
                    product,
                    name: uploadedFile.name,
                    url: uploadedFile.url,
                    createdAt: uploadedFile.createdAt,
                    uploadedBy: getUser().prenom + ' ' + getUser().nom
                }
                importSubscription(this.state.agence.id, body)
                    .then(res => {
                        this.setState({ loading: false, agence: res.data });
                        this.props.showMessage('Fichier uploadé avec succès', 'success');

                    }).catch(err => {
                        this.props.showMessage('Erreur lors uploading fichier, veuillez ressayer!', 'error');
                        console.log(err);
                        this.setState({ loading: false });

                    })
            })
            .catch((err) => {
                this.props.showMessage('Erreur lors uploading fichier, veuillez ressayer!', 'error');
                console.log(err);
                this.setState({ loading: false });

                event.target.value = null;
            });
    };

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box style={{ width: "100%" }}
                    align="center" justify="center"
                    alignSelf="center"
                    alignContent="center" gap="medium">
                    <Box width="xlarge" gap="medium" pad={"small"} justify='center' align='center'>
                        <Heading level={3} textAlign='center'>
                            Import Facturations
                        </Heading>
                        <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
                            <MultipleLineToggleButtons
                                groups={this.state.agence.voyage_products.filter(p => p.type === 'Fichier').map(p => p.product).map(value => ({
                                    label: value,
                                    selected: value === this.state.selectedProduct,
                                    onClick: () => {
                                        this.setState({ selectedProduct: value })
                                    },
                                }))}
                            />
                        </Box>
                        {
                            this.state.agence.voyage_products.filter(p => p.type === 'Fichier').length > 0 &&
                            <Box width='large'>
                                <Box direction='row' justify="center" gap='medium'>
                                    <PrimaryButton label={'Télécharger le template'}
                                        background='#59a4f4'
                                        color={'#fff'}
                                        icon={<Download />}
                                        onClick={this.downloadTemplate}
                                    />
                                    {this.state.selectedProduct &&
                                        <Box>
                                            <FileInput
                                                messages={{
                                                    browse: "Uploader un fichier",
                                                    dropPrompt: "",
                                                }}
                                                multiple={false}
                                                onChange={(event) => {
                                                    // setIsLoading(true);

                                                    this.doUploadFile(event);
                                                }}
                                            />
                                        </Box>
                                    }
                                </Box>
                            </Box>

                        }
                        {this.state.popup &&
                            <PopupConfirmation
                                close={() => this.setState({ popup: false })}
                                message='Voulez-vous uploader ces infos ?'
                                primaryAction={this.upload}
                                primaryActionIcon={<Validate />}
                                primaryActionText='Oui'
                                secondaryAction={() => this.setState({ popup: false })}
                                secondaryActionIcon={<Close />}
                                secondaryActionText='Non'
                            />
                        }
                    </Box>
                    <Box pad={'small'}>
                        {this.state.agence.uploaded_files &&

                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableCell><Text size='small' weight="bold">Date importée</Text></TableCell>
                                        <TableCell><Text size='small' weight="bold">Nom du fichier</Text></TableCell>
                                        <TableCell><Text size='small' weight="bold">Nombre du souscription</Text></TableCell>
                                        <TableCell><Text size='small' weight="bold">Importé par</Text></TableCell>
                                        <TableCell><Text size='small' weight="bold">Importé Ref</Text></TableCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {
                                        this.state.agence.uploaded_files.map(file => (
                                            <TableRow>
                                                <TableCell><Text size='small'>{getSimpleDateTimeFR(file.createdAt)}</Text></TableCell>
                                                <TableCell><a href={file.url}><Text size='small'>{file.name}</Text></a></TableCell>
                                                <TableCell><Text size='small'>{file.total}</Text></TableCell>
                                                <TableCell><Text size='small'>{file.uploadedBy}</Text></TableCell>
                                                <TableCell><Text size='small'>{file.ref_import}</Text></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        }
                    </Box>
                </Box>
            </BlockUi>
        )
    }
}

export default Import;
const axios = require('axios');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const buildParams = (search_params) => {
    const { start, sort, query, limit, status } = search_params;
    let params = {
        _start: start || 0,
        _limit: limit || 50,
    }
    if(status) {
        params.status = status;
    }
    if (query) {
        params['_q'] = query || '';
    }
    if(sort) {
        params._sort = sort || 'updatedAt:desc';
    }
    return params;
}

const getAllProRCs = (filter) => {
    let params = buildParams(filter);
    return axios.get(`${baseAPI}/public/pro-rc`, { params, headers: HEADERS });
}
const countProRCs = (filter) => {
    let params = buildParams(filter);
    return axios.get(`${baseAPI}/pro_rc/count`, { params, headers: HEADERS })
}

const createProRC = (body) => {
    body.commercial_id = storageService.getCommercialId()
    return axios.post(`${baseAPI}/public/pro-rc`, body, { headers: HEADERS });
}

const updateProRC = (body) => {
    return axios.put(`${baseAPI}/public/pro-rc/${body.id}`, body, { headers: HEADERS });
}


const sendForm = (body) => {
    return axios.post(`${baseAPI}/commerciale/pro-rc/send`, body, { headers: HEADERS });
}

module.exports = {
    sendForm,
    getAllProRCs,
    countProRCs,
    createProRC,
    updateProRC
}
import { Box, Button, Card, Heading } from "grommet";
import { Checkmark, Stripe, Update } from "grommet-icons";
import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { getCustomerId } from "../../../services/common_service";
import { getPNOSubscription } from "../../../services/pno_service";
import CustomInput from "../custom_input";
import PrimaryButton from "../primary_button";
import StandardText from "../standard_text";

const StripeCustomer = ({ devisId }) => {
  const [customer, setCustomer] = useState(null);
  const [blocking, setBlocking] = useState(false);

  useEffect(() => {
    loadEvents();
  }, devisId);

  const loadEvents = () => {
    setBlocking(true);
    getCustomerId(devisId)
      .then((res) => {
        console.log(res.data);
        setCustomer(res.data.customerId);
        setBlocking(false);
      })
      .catch((err) => setBlocking(false));
  };

  return (
    <BlockUi tag="div" blocking={blocking}>
      <Box width={"full"} justify="center" align="center">
        {!customer ? (
          <Card width={"full"} justify="center" align="center">
            <Heading level={5}>Pas d'info client Stripe</Heading>
          </Card>
        ) : (
          <Card width={"full"} justify="center" align="center">
            <Heading level={5}>
              Client Stripe :{" "}
              <a
                href={`https://dashboard.stripe.com/customers/${customer}`}
                target="_blank"
              >
                {customer}
              </a>
            </Heading>
          </Card>
        )}
      </Box>
    </BlockUi>
  );
};

export default StripeCustomer;

export const StripeSubscription = ({ devis, reloadDevis }) => {
  const [blocking, setBlocking] = useState(false);
  const [sub_id, setSubId] = useState(devis.stripe_subscription_id);
  useEffect(() => {
    setSubId(devis.stripe_subscription_id);
  }, [devis]);

  const verifySubscripton = () => {
    setBlocking(true);
    getPNOSubscription({ sub_id, pno_id: devis.id })
      .then((res) => {
        const { sub, pno } = res.data;
        if (sub.status) {
            reloadDevis(pno)
        }
        setBlocking(false);
      })
      .catch((err) => {
        setBlocking(false);
      });
  };

  return (
    <BlockUi tag="div" blocking={blocking}>
      <Box width={"full"} justify="between" align="center" direction="row">
        <Card
          width={"large"}
          justify="between"
          align="center"
          pad={"small"}
          gap="small"
        >
          <StandardText label={"Abonnement Id :"} size={"small"} bold />
          <Box width={"medium"}>
            <CustomInput
              value={sub_id}
              onChange={(val) => setSubId(val.target.value)}
            />
          </Box>
          <PrimaryButton
            icon={<Checkmark color="white" />}
            color={"brand"}
            style={{ backgroundColor: "brand" }}
            onClick={verifySubscripton}
          />
        </Card>
      </Box>
    </BlockUi>
  );
};
export const StripeSubscriptionStatus = ({ label, link, restoreSub }) => {
  return (
    <Box width={"full"} justify="center" align="center" direction="row">
      <Card
        width={"full"}
        justify="between"
        align="center"
        gap="small"
        pad={{horizontal: 'small'}}
        direction="row"
      >
        <Box width={'large'} direction="row">
            <StandardText label={"Abonnement statut: "} />&nbsp; 
            <StandardText label={label}  bold 
              color={label === 'Abo. en cours' ? "#33BC00" : "#FC0000"}
            />
        </Box>

        <Box direction="row" justify="end">
          <Button icon={<Stripe />} 
              onClick={() => window.open('https://dashboard.stripe.com/subscriptions/' + link, "_blank")}
          />
          {label !== "Abo. en cours" && (
            <Button icon={<Update color="#E82C70" />} onClick={restoreSub} />
          )}
        </Box>
      </Card>
    </Box>
  );
};

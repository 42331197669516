const axios = require('axios');
const storageService = require('../../../services/storage_service');
const { baseAPI } = require('../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};


const fetchUser = (id) => {
    return axios.get(`${baseAPI}/users/${id}`, { headers: HEADERS })
}

const getGeneralObjectifs = () => {
    return axios.get(`${baseAPI}/trusti-config/global-objectif`, { headers: HEADERS })
}

const updateGlobalObjectif = (objs) => {
    return axios.post(`${baseAPI}/trusti-config/global-objectif`, objs, { headers: HEADERS })
}

const updateUserObjectif = (id, objs) => {
    return axios.patch(`${baseAPI}/users_commerciales/${id}/objectifs`, objs, { headers: HEADERS })
}

const replaceCommercial = (source, targets) => {
    return axios.post(`${baseAPI}/commerciales/replace`, {source, targets}, { headers: HEADERS })
}

module.exports = {
    updateUserObjectif,
    getGeneralObjectifs,
    updateGlobalObjectif,
    fetchUser,
    replaceCommercial
}
import {
    Box
} from "grommet";
import React from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import AddDevis from "../../elements/add_devis";
import CustomPagination from "../../elements/custom_pagination";
import CustomSearch from "../../elements/custom_search";
import Refresh from "../../elements/refresh";
import { ViewTableWithoutPagination } from "../../elements/view_table";
import Immeuble from "./trusti-immeuble";
import { countImmeubles, getAllImmeubles } from "./services";

class TrustiImmeubles extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            total: 0,
            startIndex: 0,
            filter: {},
            deals: [],
            users: this.props.commerciaux || [],
            selectedDeal: undefined,
            popupOpen: false
        }
    }

    componentDidMount = () => {
        this.searchAllImmeubles();
    }

    refresh = () => this.searchAllImmeubles();

    searchAllImmeubles = () => {
        getAllImmeubles(this.state.filter)
            .then(res => this.setState({ deals: res.data }))
        countImmeubles(this.state.filter)
            .then(res => this.setState({ total: res.data }));
    }

    onNextPage = ({ startIndex }) => {
        let filter = this.state.filter;
        filter.start = startIndex
        this.setState({ filter: Object.assign({}, filter) }, () => this.searchAllImmeubles());
    }

    onSort = optionSort => {
        let filter = this.state.filter;
        filter.sort = `${optionSort.property}:${optionSort.direction}`;
        this.setState({ filter: Object.assign({}, filter) }, this.searchAllImmeubles);
    }

    open = (deal) => {
        this.setState({ selectedDeal: deal, popupOpen: true })
    }

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter: Object.assign({}, filter) });
    }


    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box
                    style={{ width: "100%" }}
                    justify="center"
                    alignContent="center"
                    align="center"
                    pad='medium'
                >
                    <Box
                        width={'xxlarge'}
                        justify="center"
                        alignContent="center"
                        align="center"
                        pad={'medium'}
                    >

                        {
                            this.state.popupOpen ?
                                <Immeuble
                                    deal={this.state.selectedDeal}
                                    onRefresh={this.searchAllImmeubles}
                                    showMessage={this.props.showMessage}
                                    onClose={() => this.setState({ popupOpen: false })}
                                /> :
                                <Box
                                    width={'xxlarge'}
                                    justify="center"
                                    alignContent="center"
                                    align="center"
                                    pad={'medium'}
                                >
                                    <Box direction='row' justify="between" alignSelf="center" align="center" gap="medium" width={'full'} pad='small'>
                                        <Refresh refresh={this.refresh} />
                                        <Box width={'medium'}>
                                            <CustomSearch
                                                query={this.state.filter.query}
                                                onQueryChange={query => this.updateFilter('query', query)}
                                                onSearch={this.searchAllImmeubles}
                                                status={this.state.filter.status}
                                                onStatusChange={status => {
                                                    let filter = this.state.filter;
                                                    filter.status = status;
                                                    this.setState({ filter: Object.assign({}, filter) }, this.searchAllImmeubles);
                                                }}
                                            />
                                        </Box>
                                        <AddDevis onAdd={() => this.setState({ selectedDeal: {}, popupOpen: true })} />
                                    </Box>
                                    <Box style={{ width: "100%" }}>
                                        <CustomPagination total={this.state.total} onNextPage={this.onNextPage} startIndex={this.state.startIndex} />
                                    </Box>
                                    <ViewTableWithoutPagination
                                        reports={this.state.deals}
                                        commission_taux={this.props.commission_taux}
                                        onDevisSelected={this.open}
                                        commerciaux={this.props.commerciaux}
                                    />
                                </Box>
                        }

                    </Box>
                </Box>
            </BlockUi>
        )
    }
}

export default TrustiImmeubles;
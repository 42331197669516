import { Box, Button } from 'grommet';
import React from 'react';

const activeStyle = {background: '#59a4f4', border: 'none', cursor: 'pointer', color: '#fff', paddingTop: 5, paddingBottom: 5, paddingLeft: 20, paddingRight: 20,};
const inactiveStyle = {background: '#EDEDED', border: 'none', cursor: 'pointer', color: '#a2a2a3', paddingTop: 5, paddingBottom: 5, paddingLeft: 20, paddingRight: 20,};

const RadioButtonGroup = ({groups}) => {
    return (
        <Box direction="row" gap="xsmall">
            {
                groups.map((button, index) => 
                    <Button 
                        primary 
                        onClick={button.onClick}
                        style={button.selected ? activeStyle : inactiveStyle} 
                        key={index} label={button.label}/>
                )
            }
        </Box>
    );
}

export default RadioButtonGroup;
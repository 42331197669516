import { Box, Grid, Select, Text, TextInput } from "grommet";
import { Checkmark } from "grommet-icons";
import React, { useState } from "react";
import PrimaryButton from "../../../../elements/primary_button";
import ToggleButton from "../../../../elements/toggle_button/toggle_button";
import ChoiceSelect from "../../../trusti-per/trusti_per/ChoiceSelect";

const inputStyle = {
  background: "#EDEDED",
  fontSize: 14,
  fontWeight: 400,
  borderRadius: 10,
  border: "none",
};

const DealInfo = ({ devis, updateDevis, onSave }) => {
  const [dataChanged, setDataChanged] = useState(false);
  const [hasLoan, setHasLoan] = useState();

  const updateData = (field, value) => {
    if(devis[field] === value) {
      return;
    }
    setDataChanged(true);
    updateDevis(field, value);
  };

  const SectionHeader = ({ title, color, round }) => {
    return (
      <Box
        fill
        gap="small"
        pad="xxsmall"
        background={color || "brand"}
        style={round ? { borderRadius: 12 } : {}}
      >
        <Text color="white" style={{ fontWeight: "bold", marginLeft: 20 }}>
          {title}
        </Text>
      </Box>
    );
  };

  const submitModification = () => {
    setDataChanged(false);
    onSave({ skipValidation: true });
  };

  return (
    <Box fill background={"white"}>
      {dataChanged && (
        <Box
          fill
          background={"yellow"}
          justify="end"
          direction="row"
          pad="xxsmall"
        >
          <PrimaryButton
            background="transparent"
            icon={<Checkmark color="green" />}
            reverse
            onClick={submitModification}
            label={"Valider"}
            color="green"
          ></PrimaryButton>
        </Box>
      )}
      <SectionHeader title={"Situation familiale"} />
      <Box direction="row">
        <Box pad={"small"} fill gap="xsmall">
          <ChoiceSelect
            title="Sélectionner votre situation familiale et maritale ?"
            choices={[
              "Célibataire",
              "Marié(e)",
              "Pacsé(e)",
              "En concubinage",
              "Divorcé(e)",
              "Veuf(ve)",
              "Séparé(e) de corps",
            ]}
            freeGrid
            onChange={(choice) => {
              updateData("familial_situation", choice);
            }}
            default={devis.familial_situation}
          />
          <ChoiceSelect
            title="Combien avez vous d'enfant(s) à charge ?"
            choices={["Aucun", "1", "2", "3", "4 ou plus"]}
            freeGrid
            onChange={(choice) => {
              updateData("children_count", choice);
            }}
            default={devis.children_count}
          />
        </Box>
      </Box>

      <SectionHeader title={"Situation professionnel"} />
      <Box>
        <Box pad={"small"} fill gap="xsmall">
          <ChoiceSelect
            title="Quelle est votre situation professionnelle ?"
            choices={[
              "Actif(ve)",
              "Indépendant(e)/Libéral(e) (TNS)",
              "Retraité(e)",
              "Étudiant(e)",
              "Cumul emploi-retraite",
              "En recherche d'emploi",
              "Sans profession",
              "Inactif (autre)",
            ]}
            freeGrid
            onChange={(choice) => {
              updateData("profession_situation", choice);
            }}
            default={devis.profession_situation}
          />
        </Box>
        <Box pad={"small"} fill gap="xsmall">
          <Text weight={400} size={"14px"} margin="10px 0 10px 0">
            Quels sont les revenus de votre foyer ?
          </Text>
          <Box direction="row" align="center">
            <Box width="30%">
              <TextInput
                type="number"
                style={inputStyle}
                icon={<span>€</span>}
                defaultValue={devis.revenue_annuel}
                reverse
                onChange={(event) =>
                  updateData("revenue_annuel", event.target.value)
                }
              />
            </Box>
            <Text weight={400} size={"14px"} style={{ flexShrink: 1 }} margin="0 0 0 20px">
              Revenu brut annuel
            </Text>
          </Box>
        </Box>
      </Box>

      <SectionHeader title={"Type de résidence"} />
      <Box direction="row">
        <Box pad={"small"} fill gap="xsmall">
          <ChoiceSelect
            title="Quels est votre situation actuelle ?"
            choices={["Propriétaire", "Locataire", "Hébergé(e)"]}
            icons={["🏠", "🔑", "🏢"]}
            freeGrid
            onChange={(choice) => {
              updateData("location_situation", choice);
            }}
            default={devis.location_situation}
          />
          {devis.location_situation === "Locataire" && (
            <>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">
                Quel est votre loyer actuel ?
              </Text>
              <Box direction="row" align="center" width="30%">
                <TextInput
                  type="number"
                  style={inputStyle}
                  icon={<span>€</span>}
                  defaultValue={devis.rent_amount}
                  reverse
                  onChange={(event) =>
                    updateData("rent_amount", event.target.value)
                  }
                />
              </Box>
            </>
          )}
        </Box>
      </Box>

      <SectionHeader title={"Patrimoine"} />
      <Box direction="row">
        <Box pad={"small"} fill gap="xsmall">
          <ChoiceSelect
            title="Renseigner la valeur de votre patrimoine immobilier"
            choices={[
              "Moins de 100 000 €",
              "De 100 000 € à 500 000 €",
              "De 500 000 € à 800 000 €",
              "De 800 000 € à 1 000 000 €",
              "1 000 000 € et plus",
            ]}
            onChange={(choice) => {
              let newPatrimoines = devis.patrimoines;
              if (!newPatrimoines) {
                newPatrimoines = [];
                devis.patrimoines = [];
              }
              const p = newPatrimoines?.find((p) => p.label === "Immobilier");
              if (p) {
                p.value = choice;
              } else {
                newPatrimoines.push({ label: "Immobilier", value: choice });
              }
              updateData("patrimoines", newPatrimoines);
            }}
            default={
              devis.patrimoines?.find((p) => p.label === "Immobilier")?.value
            }
          />

          <ChoiceSelect
            title="Renseigner la valeur de votre patrimoine financier"
            choices={[
              "Moins de 50 000 €",
              "De 50 000 € à 100 000 €",
              "De 100 000 € à 500 000 €",
              "De 500 000 € à 950 000 €",
              "950 000 € et plus",
            ]}
            onChange={(choice) => {
              const newPatrimoines = devis.patrimoines;
              const p = newPatrimoines?.find((p) => p.label === "Financier");
              if (p) {
                p.value = choice;
              } else {
                newPatrimoines.push({ label: "Financier", value: choice });
              }
              updateData("patrimoines", newPatrimoines);
            }}
            default={
              devis.patrimoines?.find((p) => p.label === "Financier")?.value
            }
          />
        </Box>
      </Box>

      <SectionHeader title={"Situation financière"} />
      <Box direction="row">
        <Box pad={"small"} fill gap="xsmall">
          <Text weight={400} size={"14px"} margin="10px 0 10px 0">
            Avez vous des emprunts en cours de remboursement ?
          </Text>

          <Box direction="row" gap="small" align="center">
            <ToggleButton
              fontSize="14px"
              groups={[
                {
                  label: "Oui",
                  selected: hasLoan === true,
                  onClick: () => setHasLoan(true),
                },
                {
                  label: "Non",
                  selected: !hasLoan,
                  onClick: () => setHasLoan(false),
                },
              ]}
            />

            {hasLoan && (
              <Box width="30%">
                <TextInput
                  type="number"
                  style={inputStyle}
                  icon={<span>€</span>}
                  defaultValue={devis.loan_period}
                  reverse
                  onChange={(event) =>
                    updateData("loan_period", event.target.value)
                  }
                />
              </Box>
            )}

            {hasLoan && (
              <Text weight={400} size={"14px"}>
                Renseigner le montant total de vos emprunts
              </Text>
            )}
          </Box>
        </Box>
      </Box>

      <SectionHeader title={"Projection départ à la retraite"} />
      <Box direction="row">
        <Box pad={"small"} fill gap="xsmall">
          <Text weight={400} size={"14px"} margin="10px 0 10px 0">
            Quel serait l'âge souhaité de votre départ à la retraite
          </Text>
          <Box direction="row" align="center" width="50%">
            <TextInput
              type="number"
              style={inputStyle}
              icon={<span>An(s)</span>}
              defaultValue={devis.retirement_age}
              reverse
              onChange={(event) =>
                updateData("retirement_age", event.target.value)
              }
            />
          </Box>
        </Box>
      </Box>

      <SectionHeader title={"Définition du projet"} />
      <Box direction="row">
        <Box pad={"small"} fill gap="xsmall">
          <Grid columns={["1/2", "1/2"]} gap="small">
            <Box>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">
                Renseigner le montant de votre premier versement
              </Text>
              <TextInput
                type="number"
                style={inputStyle}
                icon={<span>€</span>}
                defaultValue={devis.first_payment}
                reverse
                onChange={(event) =>
                  updateData("first_payment", event.target.value)
                }
              />
            </Box>
            <Box>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">
                Renseigner le montant de vos versements mensuels
              </Text>
              <TextInput
                type="number"
                style={inputStyle}
                icon={<span>€</span>}
                defaultValue={devis.monthly_payment}
                reverse
                onChange={(event) =>
                  updateData("monthly_payment", event.target.value)
                }
              />
            </Box>
          </Grid>
        </Box>
      </Box>

      <SectionHeader title={"Profil investisseur"} />
      <Box direction="row">
        <Box pad={"small"} fill gap="xsmall">
          <Grid columns={["1/2", "1/2"]} gap="small">
            <Select
              options={["Prudent", "Équilibre", "Dynamique"]}
              value={devis.profil_investisseur}
              size="small"
              onChange={({ option }) =>
                updateData("profil_investisseur", option)
              }
            />
            <Box/>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DealInfo;

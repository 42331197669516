import { Box, Grid, Text } from "grommet";
import { DocumentDownload } from "grommet-icons";
import React from "react";
import { getSimpleDateFR } from "../../../../services/utils";
import { RISK_PROFESSIONS } from "../../../../types/emprunteur_type";
import { ApporteurGestionnaireV2 } from "../../../elements/apporteur_gestionaire";
import { ApporteurModificationV2 } from "../../../elements/apporteur_gestionaire/modification";
import DateRecap from "../../../elements/date_recap";
import { EmailEventTrackerV2 } from "../../../elements/email_tracker";
import BlockText from "../../../elements/line_container/block_text";
import { ClientCard, DashboardRecapTitle } from "../../../elements/new_design_elements";
import { PanelAppelV2 } from "../../../elements/panel_appel";
import { StatusDateAppelV2 } from "../../../elements/status_date_appel";
import TrustiBot from "../../../trustibot";
import Accountability from "./accountability";
import InfoAssurance from "./info_assurance";
import EmprunteurInfo from "./info_emprunteur";
import InfoVente from "./info_vente";
import { Simulation } from "./simulation";
import DateRealisation from "./date_realisation";

const isRiskProfession = (value) => {
  return value !== null && value !== RISK_PROFESSIONS[0].description;
};

const LineResult = ({ leftLabel, rightChildren, widthLeft, widthRight, size }) => (
  <Box>
    <Text color="grey">{leftLabel}</Text>
    <Box width="100%">
      {rightChildren}
    </Box>
  </Box>
)


const Recap = ({ children, devis, toEditMode, close, onSave, sendForm, updateDevis, showMessage, reload }) => {
  console.log(devis);
  return (
    <Box
      direction="row"
      gap='small'
      style={{ width: "100vw" }}
    >
      <TrustiBot></TrustiBot>
      <Box
        gap="small"
        // align="center"
        alignSelf="center"
        pad="0 20px"
        width="xlarge"
      >
        <DashboardRecapTitle title="Suivi Prospection" />
        <DateRecap deal={devis} />
        <PanelAppelV2 devis={devis} updateDevis={updateDevis} onSave={onSave} showMessage={showMessage} />
        <StatusDateAppelV2 devis={devis} />
        <DashboardRecapTitle title="Simulation" />
        <Simulation devis={devis} />
        <DashboardRecapTitle title="Information client" />
        <ClientCard assure={devis.client} />
        {/* <DashboardRecapTitle title="Résultats" />
        <Box pad="small">
          <Box direction="row" width={"100%"}>
            <BlockText
              label="Économie totale"
              value={(devis.economie_total ? devis.economie_total : "-") + " €"}
              width={"12.5%"}
            ></BlockText>
            <BlockText
              label="Économie totale - frais de dossier"
              value={
                (devis.economie_total_moin_frais_dossier
                  ? devis.economie_total_moin_frais_dossier
                  : "-") + " €"
              }
              width={"12.5%"}
            ></BlockText>
            <BlockText
              label="Économie totale en %"
              value={
                (devis.economie_total_pourcentage
                  ? devis.economie_total_pourcentage
                  : "-") + " %"
              }
              width={"12.5%"}
            ></BlockText>
            <BlockText
              label="Économie totale - frais de dossier en %"
              value={
                (devis.economie_total_moin_frais_dossier_pourcentage
                  ? devis.economie_total_moin_frais_dossier_pourcentage
                  : "-") + " %"
              }
              width={"12.5%"}
            ></BlockText>
            <BlockText
              width={"25%"}
              label="Taux frais de dossier"
              value={
                (devis.taux_frais_dossier ? devis.taux_frais_dossier : "-") +
                " %"
              }
            ></BlockText>
            <BlockText
              width={"25%"}
              label="Frais de dossier total"
              value={
                (devis.frais_dossier_total ? devis.frais_dossier_total : "-") +
                " €"
              }
            ></BlockText>
          </Box>
          <Box direction="row" width={"100%"}>
            <BlockText
              width={`${devis.pourcentage_acompte}` !== "0" ? "25%" : "50%"}
              label={
                `${devis.pourcentage_acompte}` !== "0"
                  ? "Acompte à regler"
                  : "Echéance frais de dossier"
              }
              value={
                (devis.acompte_a_regler ? devis.acompte_a_regler : "-") + " €"
              }
            ></BlockText>
            {`${devis.pourcentage_acompte}` !== "0" && (
              <BlockText
                width={"25%"}
                label="Frais de dossier restants"
                value={
                  (devis.frais_dossier_restant
                    ? devis.frais_dossier_restant
                    : "-") + " €"
                }
              ></BlockText>
            )}
            <BlockText
              width={"25%"}
              label="Taux acompte"
              value={
                (devis.pourcentage_acompte ? devis.pourcentage_acompte : "0") +
                " %"
              }
            ></BlockText>
            <BlockText
              width={"25%"}
              label="Nombre de prélèvements"
              value={
                devis.nombre_de_prelevement ? devis.nombre_de_prelevement : "-"
              }
            ></BlockText>
          </Box>
          <Box direction="row" width={"100%"}>
            <BlockText
              width={"50%"}
              label={
                <>
                  <strong>Nouvelle prime totale</strong>
                </>
              }
              value={
                (devis.new_prime_total ? devis.new_prime_total : "-") + " €"
              }
              desc={
                <>
                  au lieu de{" "}
                  <strong>
                    {devis.old_prime_total ? devis.old_prime_total : "-"} €
                  </strong>{" "}
                </>
              }
              bg="#FE007A"
            ></BlockText>
            <BlockText
              width={"50%"}
              label={
                <>
                  <strong>Nouvelle prime</strong> (frais de dossier inclus)
                </>
              }
              value={
                (devis.prime_et_frais_mensuel
                  ? devis.prime_et_frais_mensuel
                  : "-") + " €"
              }
              desc={
                <>
                  dont{" "}
                  <strong>
                    {devis.fractionnement_frais_dossier
                      ? devis.fractionnement_frais_dossier
                      : "-"}{" "}
                    €
                  </strong>{" "}
                  frais de dossier pendant{" "}
                  <strong>
                    {devis.nombre_de_prelevement
                      ? devis.nombre_de_prelevement
                      : "-"}{" "}
                    mois
                  </strong>
                </>
              }
              bg="#FE007A"
            ></BlockText>
          </Box>
        </Box> */}
        <DashboardRecapTitle title="Résultat Frais de dossier" />

        <Grid columns={["1/3", "1/3", "1/3"]} width="full" pad="small" gap="small">

          <LineResult
            leftLabel="Frais de dossier total"
            rightChildren={
              <Text size="small" style={{ fontWeight: "bold" }} color="bluebrand">
                {(devis.frais_dossier_total ? devis.frais_dossier_total : "-")}€
              </Text>
            }
          />
          <LineResult
            leftLabel="Pourcentage acompte"
            rightChildren={
              <Text size="small" style={{ fontWeight: "bold" }}>
                {(devis.pourcentage_acompte ? devis.pourcentage_acompte : "-")}%
              </Text>
            }
          />
          <LineResult
            leftLabel={`${devis.pourcentage_acompte}` !== "0"
              ? "Acompte à regler"
              : "Echéance frais de dossier"}
            rightChildren={
              <Text size="small" style={{ fontWeight: "bold" }}>
                {(devis.acompte_a_regler ? devis.acompte_a_regler : "-")}€
              </Text>
            }
          />
          <LineResult
            leftLabel="Frais de dossier restant"
            rightChildren={
              <Text size="small" style={{ fontWeight: "bold" }}>
                {(devis.frais_dossier_restant ? devis.frais_dossier_restant : "-")}€
              </Text>
            }
          />
          <LineResult
            leftLabel="Nombre d’échéances"
            rightChildren={
              <Text size="small" style={{ fontWeight: "bold" }}>
                {(devis.nombre_de_prelevement ? devis.nombre_de_prelevement : "-")}
              </Text>
            }
          />
          <LineResult
            leftLabel="Montant des échéances"
            rightChildren={
              <Text size="small" style={{ fontWeight: "bold" }}>
                {(devis.fractionnement_frais_dossier ? devis.fractionnement_frais_dossier : "-")}€
              </Text>
            }
          />
        </Grid>

        {devis.type_projet === "Je souhaite changer d'assurance" && <>
          <DashboardRecapTitle title="Résultat économie" />
          <Grid columns={["1/4", "1/4", "1/4", "1/4"]} width="full" pad="small" gap="small">
            <LineResult
              leftLabel="Économie totale"
              rightChildren={
                <Text size="small" style={{ fontWeight: "bold" }} color="bluebrand">
                  {(devis.economie_total ? devis.economie_total : "-") + " €"}
                </Text>
              }
            />
            <LineResult
              leftLabel="Économie totale en %"
              rightChildren={
                <Text size="small" style={{ fontWeight: "bold" }} color="bluebrand">
                  {(devis.economie_total_pourcentage
                    ? devis.economie_total_pourcentage
                    : "-") + " %"}
                </Text>
              }
            />
            <LineResult
              leftLabel="Économie totale - frais de dossier"
              rightChildren={
                <Text size="small" style={{ fontWeight: "bold" }} color="brand">
                  {(devis.economie_total_moin_frais_dossier
                    ? devis.economie_total_moin_frais_dossier
                    : "-") + " €"}
                </Text>
              }
            />
            <LineResult
              leftLabel="Économie totale - frais de dossier en %"
              rightChildren={
                <Text size="small" style={{ fontWeight: "bold" }} color="brand">
                  {(devis.economie_total_moin_frais_dossier_pourcentage
                    ? devis.economie_total_moin_frais_dossier_pourcentage
                    : "-") + " %"}
                </Text>
              }
            />
          </Grid>
        </>}

        <Box justify="center" align="center" width="full">
          <BlockText
            width={"50%"}
            label={
              <>
                <strong>Nouvelle prime</strong> (frais dossier inclus)
              </>
            }
            value={
              (devis.prime_et_frais_mensuel
                ? devis.prime_et_frais_mensuel
                : "-") + " €"
            }
            desc={
              <>
                dont{" "}
                <strong>
                  {devis.fractionnement_frais_dossier
                    ? devis.fractionnement_frais_dossier
                    : "-"}{" "}
                  €
                </strong>{" "}
                frais de dossier pendant{" "}
                <strong>
                  {devis.nombre_de_prelevement
                    ? `${devis.pourcentage_acompte}` !== "0"
                      ? devis.nombre_de_prelevement
                      : devis.nombre_de_prelevement - 1
                    : "-"}{" "}
                </strong>
                mois.
              </>
            }
            bg="#FE007A"
          ></BlockText>
        </Box>
        <DashboardRecapTitle title="Information vente" />
        <InfoVente devis={devis} />
        <DashboardRecapTitle title="Informations personnelles complémentaires du co-emprunteur n°1" />
        <EmprunteurInfo emprunteur={devis} />
        {devis.emprunteurs.map((emprunteur, index) => (
          <>
            <DashboardRecapTitle title={"Informations personnelles complémentaires du co-emprunteur n°" + (index + 2)} />
            <ClientCard assure={emprunteur} />
            <Text weight={700}>Informations personnelles complémentaires</Text>
            <EmprunteurInfo emprunteur={emprunteur} />
          </>
        ))}
        <DashboardRecapTitle title="Informations assurance emprunteur" />
        <InfoAssurance devis={devis} />
        <DashboardRecapTitle title="GED" />
        <Grid columns={["1/2", "1/2"]} width="100%">
          {devis.file_tableau_amortissement && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_tableau_amortissement}`}
            >
              <DocumentDownload />
              Tableau d’amortissement
            </a>
          )}
          {devis.file_attestation_assurance && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_attestation_assurance}`}
            >
              <DocumentDownload />
              Attestation assurance
            </a>
          )}
          {devis.file_offre_de_pret && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_offre_de_pret}`}
            >
              <DocumentDownload />
              Offre de prêt
            </a>
          )}
          {devis.file_devis && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_devis}`}
            >
              <DocumentDownload />
              Devis
            </a>
          )}
          {devis.file_devis_signed && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_devis_signed}`}
            >
              <DocumentDownload />
              Devis (signé)
            </a>
          )}
          {devis.file_dda && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_dda}`}
            >
              <DocumentDownload />
              DDA
            </a>
          )}
          {devis.file_devis_ipid && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_devis_ipid}`}
            >
              <DocumentDownload />
              Devis IPID
            </a>
          )}
          {devis.file_devis_condition && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_devis_condition}`}
            >
              <DocumentDownload />
              Questionnaire devis
            </a>
          )}
          {devis.file_devis_condition_signed && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_devis_condition_signed}`}
            >
              <DocumentDownload />
              Questionnaire devis (signé)
            </a>
          )}
          {devis.file_rib && (
            <a target={"_blank"} rel="noreferrer" href={`${devis.file_rib}`}>
              <DocumentDownload />
              RIB
            </a>
          )}
          {devis.file_contrat && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_contrat}`}
            >
              <DocumentDownload />
              Contrat
            </a>
          )}{devis.file_contrat_ipid && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_contrat_ipid}`}
            >
              <DocumentDownload />
              Contrat IPID
            </a>
          )}
          {devis.file_contrat_condition && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_contrat_condition}`}
            >
              <DocumentDownload />
              Contrat conditions
            </a>
          )}
          {devis.file_contrat_signed && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_contrat_signed}`}
            >
              <DocumentDownload />
              Contrat signé
            </a>
          )}
          {devis.file_others ? (
            devis.file_others.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                  <DocumentDownload />
                  {e && e.split("/").pop()}
                </a>
              );
            })
          ) : (
            <></>
          )}
        </Grid>
        <DashboardRecapTitle title="Suivi de mail" />
        <Box width="100%">
          <EmailEventTrackerV2 messageIds={devis.emailEventIds} />
        </Box>
        <DashboardRecapTitle title="Comptabilité" />
        <Accountability devis={devis} />
        <DateRealisation devis={devis} updateDevis={updateDevis} 
            showMessage={showMessage}/>
        <DashboardRecapTitle title="Apporteur" />
        <Grid columns={["1/2", "1/2"]} width="100%">
          {(devis.apporteur || devis.affiliation) && <Box width={'xlarge'} >
            <ApporteurGestionnaireV2 apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
          </Box>}
          <ApporteurModificationV2
            devis={devis}
            apporteur={devis.apporteur}
            showMessage={showMessage}
            onReload={reload}
          />
        </Grid>
        <DashboardRecapTitle title="Action" />
        {children}



        {/* {(devis.apporteur || devis.affiliation) && <Card pad={"small"} width={'xlarge'} >
          <ApporteurGestionnaire apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
        </Card>} */}
        {/* <Box width={'xlarge'}>
          <EmailEventTracker messageIds={devis.emailEventIds} />
        </Box> */}
      </Box>
    </Box>
  );
};


const CustomText = ({ label, textAlign, isBold }) => (
  <Text
    style={{ fontWeight: isBold ? "bold" : "normal" }}
    size="small"
    textAlign={textAlign || "end"}
  >
    {label || ""}
  </Text>
);
const Line = ({ leftLabel, rightLabel }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "50%" }}>
      <CustomText label={leftLabel + " :"} />
    </Box>
    <Box style={{ width: "50%" }}>
      <CustomText label={rightLabel} textAlign="start" isBold={true} />
    </Box>
  </Box>
);

const SubHeading = ({ label }) => (
  <Text textAlign="center" style={{ fontWeight: "bold" }}>
    {label}
  </Text>
);

export default Recap;

const { A_RAPPELER_PLUS_TARD } = require("./devis_contrat_status");

const PROSPECT = "Prospect";
const NOUVEAU = "Nouveau";
const NOUVEAU_REMPLI = "Nouveau rempli";
const DOSSIER_EN_INSTRUCTION = "Dossier en instruction";
const EN_ETUDE_DE_PRE_ANALYSTE = "En étude de pré-analyste";
const EN_ETUDE_DE_ANALYSTE = "En étude de l'analyste";
const DOSSIER_ENVOYE_A_LA_BANQUE = "Dossier envoyé à la banque";
const PRET_ACCORDE_PAR_LA_BANQUE = "Prêt accordé par la banque";
const OFFRE_ACCEPTE_PAR_CLIENT = "Offre accepté par le client";
const REALISE = "Réalisé";
const FACTURE = "Facturés";
const CLOTURE = "Clôturés";
const CONTRAT_CLOTURE = "Contrat clôturé";
const ABONDONS = "Abandons";
const PERDU = "Perdu";
const ARCHIVE = "Archivé";

const CreditRecapStatusColor = (status) => {
  switch (status) {
    case PROSPECT:
      return "#689CE9";
    case NOUVEAU:
      return "#68A0A0";
    case A_RAPPELER_PLUS_TARD:
      return "#17a2b8";
    case NOUVEAU_REMPLI:
      return "#FC0000";
    case DOSSIER_EN_INSTRUCTION:
      return "#FF6363";
    case DOSSIER_ENVOYE_A_LA_BANQUE:
      return "#FFAAAA";
    case PRET_ACCORDE_PAR_LA_BANQUE:
      return "#D8A340";
    case OFFRE_ACCEPTE_PAR_CLIENT:
      return "#D8CC40";
    case REALISE:
      return "#A3D840";
    case FACTURE:
      return "#87D159";
    case CLOTURE:
    case CONTRAT_CLOTURE:
      return "#2DB400";
    case ABONDONS:
    case PERDU:
    case ARCHIVE:
      return "#000";
    default:
      return "#73A6EC";
  }
};

const PROJECT_TYPE = {
  particulier: 'Particulier',
  professionnelle: 'Professionnelle',
};
const PROJECT_OBJECTIF_PARTICULIER = {
  NEUF: "Acheter un bien dans le neuf",
  ANCIEN: "Acheter un bien dans l’ancien",
  TERRAIN: "Acheter un terrain et faire construire",
  CREANCES: "Rachat de créances ou de soulte",
  RENEGOCIER: "Renégocier votre prêt en cours"
};
const PROJECT_OBJECTIF_PRO = {
  SCI: "Acheter des murs via une SCI",
  FOND: "Acheter un fond de commerce",
  ACTION: "Acheter des parts sociales ou Action (LBO/OBO)",
  CREANCES: "Rachat de créances ou de soultes"
};

const DOCUMENTS_REQUIRES = [
  { 
    "id": 1,
    "group": "IDENTITÉ",
    "name": "Carte nationale d'identité recto/verso en cours de validité OU Passeport",
  },
  {
    "id": 2,
    "group": "IDENTITÉ",
    "name": "Convention de PACS + Récépissé du PACS (si concerné)",
  },
  {
    "id": 3,
    "group": "IDENTITÉ",
    "name": "Livret de famille complet avec page vierge suivant du dernier enfant (si existant)"
  },
  {
    "id": 4,
    "group": "IDENTITÉ",
    "name": "Dernier coupon de versement des allocations"
  },
  {
    "id": 5,
    "group": "IDENTITÉ",
    "name": "En cas de divorce < 3 ans OU si présence de pension alimentaire : Jugement de divorce OU Jugement des Affaires Familiales"
  },
  {
    "id": 6,
    "group": "HABITATION",
    "subgroup": "Si vous êtes locataire :",
    "name": "Quittance de loyer (M-1)"
  },
  {
    "id": 7,
    "group": "HABITATION",
    "subgroup": "Si vous êtes locataire :",
    "name": "Justificatif de domicile de moins de 3 mois (facture électricité, téléphonie…)"
  },
  {
    "id": 8,
    "group": "HABITATION",
    "subgroup": "Si vous êtes hébergé :",
    "name": "Carte nationale d’identité de l’hébergeant"
  },
  {
    "id": 9,
    "group": "HABITATION",
    "subgroup": "Si vous êtes hébergé :",
    "name": "Attestation sur l’honneur d’hébergement"
  },
  {
    "id": 10,
    "group": "HABITATION",
    "subgroup": "Si vous êtes hébergé :",
    "name": "Justificatif de domicile de moins de 3 mois l’hébergeant et de l’hébergé"
  },
  {
    "id": 11,
    "group": "HABITATION",
    "subgroup": "Si vous êtes propriétaire :",
    "name": "Titre de propriété du bien détenu OU attestation de propriété"
  },
  {
    "id": 12,
    "group": "HABITATION",
    "subgroup": "Si vous êtes propriétaire :",
    "name": "Justificatif de domicile de moins de 3 mois (facture électricité, téléphonie…)"
  },
  {
    "id": 13,
    "group": "HABITATION",
    "subgroup": "Si vous êtes propriétaire :",
    "name": "Taxe foncière (N-1)"
  },
  {
    "id": 14,
    "group": "HABITATION",
    "subgroup": "Si vous êtes propriétaire :",
    "name": "Prêt immobilier : Offre de prêt des crédits + Tableaux amortissement avec dates d’échéances"
  },
  {
    "id": 15,
    "group": "HABITATION",
    "subgroup": "Si vous êtes propriétaire :",
    "name": "Revenus locatif : Déclaration fiscal 2044"
  },
  {
    "id": 16,
    "group": "BANQUE",
    "name": "Relevés de TOUS vos comptes bancaires sur les 3 derniers mois (y compris épargne)",
  },
  {
    "id": 17,
    "group": "BANQUE",
    "name": "Relevés des frais bancaires (disponible dans votre espace client bancaire)",
  },
  {
    "id": 18,
    "group": "BANQUE",
    "name": "Relevé d’identité bancaire (RIB)",
  },
  {
    "id": 19,
    "group": "REVENUS",
    "subgroup": "Vous êtes « Salarié » :",
    "name": "Bulletin de salaire de décembre (N-1)"
  },
  {
    "id": 20,
    "group": "REVENUS",
    "subgroup": "Vous êtes « Salarié » :",
    "name": "Bulletins de salaire (3 derniers mois)"
  },
  {
    "id": 21,
    "group": "REVENUS",
    "subgroup": "Vous êtes « Salarié » :",
    "name": "Contrat de travail dans le privé OU arrêté de titularisation dans la fonction publique (- de 3 mois)"
  },
  {
    "id": 22,
    "group": "REVENUS",
    "subgroup": "Vous êtes « Salarié » :",
    "name": "2 derniers avis d’imposition (N-2 + N-1)"
  },
  {
    "id": 23,
    "group": "REVENUS",
    "subgroup": "Vous êtes « Salarié » :",
    "name": "Déclaration fiscal de l’année en cours (Si existant)"
  },
  {
    "id": 24,
    "group": "REVENUS",
    "subgroup": "Vous êtes « Salarié » :",
    "name": "Dernier coupon de versement de allocations"
  },
  {
    "id": 25,
    "group": "REVENUS",
    "subgroup": "Vous êtes « Salarié » :",
    "name": "Si pension, justificatif de perception"
  },
  {
    "id": 26,
    "group": "REVENUS",
    "subgroup": "Vous percevez une « Pension » :",
    "name": "Justificatif de perception sur les 3 derniers mois + année précédente (si existant)"
  },
  {
    "id": 27,
    "group": "REVENUS",
    "subgroup": "Vous percevez une « Pension » :",
    "name": "2 derniers avis d’imposition (N-2 + N-1)"
  },
  {
    "id": 28,
    "group": "REVENUS",
    "subgroup": "Vous percevez une « Pension » :",
    "name": "Déclaration fiscal de l’année en cours (cerfa 2042, si existant)"
  },
  {
    "id": 29,
    "group": "REVENUS",
    "subgroup": "Vous êtes « Professionnel » :",
    "name": "KBIS + Statuts"
  },
  {
    "id": 30,
    "group": "REVENUS",
    "subgroup": "Vous êtes « Professionnel » :",
    "name": "3 derniers bilans (si existant)"
  },
  {
    "id": 31,
    "group": "REVENUS",
    "subgroup": "Vous êtes « Professionnel » :",
    "name": "3 derniers avis d’impôts"
  },
  {
    "id": 32,
    "group": "REVENUS",
    "subgroup": "Vous êtes « Professionnel » :",
    "name": "3 dernières déclaration 2072 (si existant)"
  },
  {
    "id": 33,
    "group": "CHARGE",
    "name": "Justificatif des crédits en cours (y compris LOA, LLD …) : Tableaux d’amortissements, contrat etc."
  },
  {
    "id": 34,
    "group": "CHARGE",
    "name": "Dernier relevé de situation des crédits revolving (renouvelable, réserve, carte pass etc.)"
  },
  {
    "id": 35,
    "group": "CHARGE",
    "name": "Offre de prêt du précédent Regroupement de Crédits (si existant)"
  },
  {
    "id": 36,
    "group": "CHARGE",
    "name": "Dette familiale / employeur : attestation sur l’honneur et carte d’identité du créancier"
  },
  {
    "id": 37,
    "group": "CHARGE",
    "name": "Dette fiscale : Bordereau de situation fiscale (faire la demande sur le site des impôts)"
  }
]

const SITUATION_PERSONNEL = {
  PROPRIETAIRE: "Propriétaire",
  LOCATAIRE: "Locataire",
  HEBERGE: "Hébergé",
  SOCIETE: "Gérant de société ou profession libérale"
};

module.exports = {
  PROSPECT,
  NOUVEAU,
  NOUVEAU_REMPLI,
  DOSSIER_EN_INSTRUCTION,
  DOSSIER_ENVOYE_A_LA_BANQUE,
  PRET_ACCORDE_PAR_LA_BANQUE,
  OFFRE_ACCEPTE_PAR_CLIENT,
  REALISE,
  FACTURE,
  CLOTURE,
  ABONDONS,
  PERDU,
  ARCHIVE,
  CreditRecapStatusColor,
  PROJECT_TYPE,
  PROJECT_OBJECTIF_PARTICULIER,
  PROJECT_OBJECTIF_PRO,
  SITUATION_PERSONNEL,
  CONTRAT_CLOTURE,
  A_RAPPELER_PLUS_TARD,
  EN_ETUDE_DE_PRE_ANALYSTE,
  EN_ETUDE_DE_ANALYSTE,
  DOCUMENTS_REQUIRES
};

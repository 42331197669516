
import { Box, Card, Text } from 'grommet';
import React from 'react';

import service from '../../../services/credit_service';
import Refresh from '../../elements/refresh';
import Emprunteur from './credit/index';
import { getSorted } from '../../../services/utils';
import AddDevis from '../../elements/add_devis';
import DateSort from '../../elements/date_sort';
import SearchComponent from '../../elements/search';
import CreditViewTable from './credit/table_view';
import CreditStatusSearch from './credit/credit_status_search';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import CustomSelect from '../../elements/select_fill';
const storageService = require('../../../services/storage_service');

const MENU = {
    regroup: 'Regroupement de crédit',
    immo: 'Crédit Immobilier',
    conso: 'Crédit à la consommation',
    hypothecaire: 'Prêt hypothécaire',
};
class Credits extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: this.props.contact || {},
            emprunteurs: [],
            popupOpen: false,
            selectedEmprunteur: {},
            filter: {
                _query: undefined,
                status: undefined,
                sub_product: MENU.regroup,
                sort: undefined
            },
            loading: false
        }
    }

    componentDidMount = () => {
        this.loadCredits();
    };

    isAdmin = () => {
        if (storageService.hasAdministrativeRole() ||
            storageService.hasCreditManagerRole() ||
            storageService.hasClassiqueManagerRole() ||
            storageService.hasPreAnalysteRole() ||
            storageService.hasAnalysteRole() ||
            storageService.hasChefAnalysteRole()
        ) {
            return true;
        }
        return false;
    }

    loadCredits = () => {
        this.setState({ emprunteurs: [], loading: true }, () => {
            service.getCredits(this.state.contact.id, this.state.filter._query, this.state.filter.status, this.state.filter.sub_product)
                .then(res => {
                    if (this.isAdmin()) {
                        this.setState({ emprunteurs: res.data, loading: false })
                    } else {
                        this.setState({ emprunteurs: res.data.filter(e => e.commercial_id === storageService.getCommercialId()), loading: false })
                    }
                })
                .catch(err => {
                    console.log('err', err);
                    this.props.showMessage('Erreur inattendu');
                    this.setState({ loading: false });
                });
            service.getCommissionTauxCreditConfig()
                .then(res => {
                    this.setState({ commission_taux: res.data });
                })
        })
    }

    onClose = () => { this.setState({ popupOpen: false }) }

    openEmprunteur = (emprunteur) => {
        this.setState({ selectedEmprunteur: emprunteur, popupOpen: true });
    }

    addEmprunteur = () => this.openEmprunteur({ conducteur_principal: {} })

    search = (query, status, produit, product_detail) => {
        this.setState({ loading: true });
        service.getCredits(this.state.contact.id, query, status, produit, product_detail)
            .then(res => {
                if (res.data?.results) {
                    if (this.isAdmin()) {
                        this.setState({ emprunteurs: res.data?.results, loading: false })
                    } else {
                        this.setState({ emprunteurs: res.data?.results.filter(e => e.commercial_id === storageService.getCommercialId()), loading: false })
                    }

                } else if (res.data) {
                    if (this.isAdmin()) {
                        this.setState({ emprunteurs: res.data, loading: false })
                    } else {
                        this.setState({ emprunteurs: res.data.filter(e => e.commercial_id === storageService.getCommercialId()), loading: false })
                    }
                }
            })
            .catch(err => {
                console.log('err', err);
                this.setState({ loading: false });
                this.props.showMessage('Erreur inattendu');
            })
    }

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter: Object.assign({}, filter) });
    }

    render() {

        return (
            this.state.popupOpen ?
                <Emprunteur
                    showMessage={this.props.showMessage}
                    devis={this.state.selectedEmprunteur}
                    onClose={this.onClose}
                    reloadDeal={this.loadCredits}
                />
                :
                <BlockUi tag="div" keepInView={true}>
                    <Box style={{ width: "100%" }}
                        align="center" justify="center"
                        alignSelf="center"
                        alignContent="center" gap="medium">
                        <Box width={'full'} style={{ height: 40 }} justify="center" align="center" background={'#F8F8F8'}>
                            <Box width={'full'} style={{ height: 40 }} justify="center" align="center" direction="row" gap="large">
                                <SubItem
                                    key={'_credit_group'}
                                    onClick={() => {
                                        this.updateFilter('sub_product', MENU.regroup);
                                        this.search(this.state.filter._query, this.state.filter.status, MENU.regroup);
                                    }}
                                    label='Regroupement de crédit'
                                    isActive={this.state.filter.sub_product === MENU.regroup}
                                />
                                <SubItem
                                    key={'_credit_immo'}
                                    onClick={() => {
                                        this.updateFilter('sub_product', MENU.immo);
                                        this.search(this.state.filter._query, this.state.filter.status, MENU.immo);
                                    }}
                                    label='Crédit Immobilier'
                                    isActive={this.state.filter.sub_product === MENU.immo}
                                />
                                <SubItem
                                    key={'_credit_conso'}
                                    onClick={() => {
                                        this.updateFilter('sub_product', MENU.conso);
                                        this.search(this.state.filter._query, this.state.filter.status, MENU.conso);
                                    }}
                                    label='Crédit à la consommation'
                                    isActive={this.state.filter.sub_product === MENU.conso}
                                />
                                <SubItem
                                    key={'_credit_hypothecaire'}
                                    onClick={() => {
                                        this.updateFilter('sub_product', MENU.hypothecaire);
                                        this.search(this.state.filter._query, this.state.filter.status, MENU.hypothecaire);
                                    }}
                                    label='Prêt hypothécaire'
                                    isActive={this.state.filter.sub_product === MENU.hypothecaire}
                                />
                            </Box>
                        </Box>
                        <Box width="full" gap="medium" pad={'small'} align='center' justify='center'>
                            <Box width="xlarge" gap="medium" align='center' justify='center'>

                                {!this.state.contact.id &&
                                    <>
                                        <Box justify="between" direction='row' alignSelf="center" align="center" gap="medium">
                                            <Refresh refresh={this.loadCredits} />
                                            <SearchComponent
                                                search={value => {
                                                    this.search(value, this.state.filter.status, this.state.filter.sub_product);
                                                }}
                                                query={this.state.filter._query}
                                                updateQuery={value => this.updateFilter('_query', value)}
                                            />
                                            <AddDevis onAdd={this.addEmprunteur} />
                                        </Box>
                                        <Box justify="evenly" direction='row' align="center" gap="medium" alignSelf="center">
                                            <CreditStatusSearch
                                                status={this.state.filter.status}
                                                search={value => {
                                                    this.updateFilter('status', value);
                                                    this.search(this.state.filter._query, value, this.state.filter.sub_product);
                                                }}
                                            />
                                            {this.state.filter.sub_product === MENU.regroup && <Box direction='row' gap='small' alignSelf="center" style={{ width: '100%' }}>
                                                <Card pad="xsmall" justify="center" alignContent="center"
                                                    gap="small"
                                                    align="center" direction="row">
                                                    <Text size="xsmall">Filtre par produit de crédit</Text>
                                                    <CustomSelect
                                                        size="xsmall"
                                                        options={[
                                                            "Regroupement de crédits",
                                                            "Regroupement de crédits projet"
                                                        ]}
                                                        value={this.state.filter.sub_product_detail}
                                                        onChange={({ option }) => {
                                                            this.updateFilter('sub_product_detail', option);
                                                            this.search(this.state.filter._query, this.state.filter.status, MENU.regroup, option);
                                                        }}
                                                        clear
                                                    />
                                                </Card>
                                            </Box>}
                                            <Box direction='row' gap='small' alignSelf="center" style={{ width: '100%' }}>
                                                <DateSort
                                                    onChange={value => this.updateFilter('sort', value)}
                                                />
                                            </Box>

                                        </Box>
                                    </>
                                }
                            </Box>
                            {this.state.contact.id && <Box style={{ height: 30 }} />}

                            <CreditViewTable
                                reports={getSorted(this.state.emprunteurs, this.state.filter.sort)}
                                commission_taux={this.state.commission_taux}
                                onDevisSelected={this.openEmprunteur}
                                commerciaux={this.props.commerciaux}
                            />
                        </Box>
                    </Box>
                </BlockUi>
        )
    }
}

const SubItem = ({ label, onClick, isActive }) => (
    <Box onClick={onClick} style={{ boxShadow: 'none' }}>
        <Text textAlign="center" size="small" color={'#707070'} style={{ fontWeight: isActive ? 'bold' : 'normal', textDecoration: isActive ? 'underline' : 'none' }}>
            {label}
        </Text>
    </Box>
)
export default Credits;
import React from "react";
import { Box, Button, Layer, Text } from "grommet";
import service, { getGestionCollabs } from "../../services/dashboard";
import { CaretNext, CaretPrevious, Close, Google, Slack, Tasks } from "grommet-icons";
import TrustiBotNote from "./bloc_note";
import { CSSReset, theme, ThemeProvider } from "@chakra-ui/core";
import TrustiBotRdvRappel from "./rdv_rappel";
import TrustiBotGed from "./document";
import PrimaryButton from "../elements/primary_button";
import { ContactSelectSimple } from "../contacts/contact_modal/client";
import { clearContextBot, hasAdministrativeRole, saveContextBot } from "../../services/storage_service";
import CustomSelect from "../elements/select_fill";

const customTheme = {
	...theme,
	colors: {
		...theme.colors,
		primary: {
			50: "#ffe4f4",
			100: "#fdb8d7",
			200: "#f58bb8",
			300: "#ef5c96",
			400: "#e92f72",
			500: "#e92f72",
			600: "#e92f72",
			700: "#e92f72",
			800: "#e92f72",
			900: "#e92f72",
		},
	},
};
class TrustiBot extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			indicator: undefined,
			commerciaux: [],
			panelOpened: true,
			collapsed: false,
			selectedContact: {},
			selectedCommercial: null,
			updateNum: 1
		};
	}

	componentDidMount() {
		// Load client secrets from a local file.
		setTimeout(() => this.setState({ collapsed: true }), 5000);
		getGestionCollabs()
			.then(res => {
				this.setState({ commerciaux: [{ name: 'Tous', id: 'All' }, ...res.data] })
			}).catch((e)=>{
				console.log(e);
			});
	}

	getIndicator = (startDate, endDate, commercial_id) => {
		service
			.getIndicator(startDate, endDate, commercial_id)
			.then((res) => this.setState({ indicator: res.data }));
	};

	render() {
		return (
			<>
				{/* {!this.state.panelOpened && (
					<Box
						direction="row"
						align="center"
						pad="xsmall"
						style={{
							width: this.state.collapsed ? 30 : 160,
							background: "#E92C70",
							position: "fixed",
							top: "50%",
							left: 0,
							borderTopRightRadius: 30,
							borderBottomRightRadius: 30,
							zIndex: 99999,
						}}
					>
						{!this.state.collapsed && (
							<Button
								primary
								label="TrustiBot"
								onClick={() =>
									this.setState({ panelOpened: !this.state.panelOpened })
								}
							/>
						)}
						{!this.state.collapsed && (
							<CaretPrevious
								color="#fff"
								style={{ cursor: "pointer" }}
								onClick={() => this.setState({ collapsed: true })}
							/>
						)}
						{this.state.collapsed && (
							<CaretNext
								color="#fff"
								style={{ cursor: "pointer" }}
								onClick={() => this.setState({ collapsed: false })}
							/>
						)}
					</Box>
				)} */}
				{/* {this.state.panelOpened && ( */}
				<ThemeProvider theme={customTheme}>
				<CSSReset />
				{/* <Layer animation={"slide"} position="left" background="transparent"> */}
				<Box pad="0 20px">
          {!this.props.fullscreen && <Box width="100%" background="background-back" pad="5px 20px" style={{borderRadius: "8px"}}>
            <Text weight={600}>Trustibot</Text>
          </Box>}
					<Box style={{ overflow: "scroll" }}>
						{/* <TrustiBotMail></TrustiBotMail> */}
						<Box direction="row" style={{ minHeight: 60 }}>
							<Box margin={"small"} justify="center">
								<PrimaryButton
									size="small"
									icon={<Google />}
									label="Ouvrir GMAIL dans un nouvel onglet"
									onClick={() => {
										window.open("https://gmail.com", "_blank");
									}}
								></PrimaryButton>
							</Box>
							<Box margin={"small"} justify="center">
								<PrimaryButton
									size="small"
									icon={<Slack />}
									label="Slack"
									onClick={() => {
										window.open("https://trustiway.page.link/slack", "_blank");
									}}
								></PrimaryButton>
							</Box>
							<Box margin={"small"} justify="center">
								<PrimaryButton
									size="small"
									icon={<Tasks />}
									label="Trello"
									onClick={() => {
										window.open("https://trustiway.page.link/trello", "_blank");
									}}
								></PrimaryButton>
							</Box>
							{this.props.fullscreen && <Box direction="row" gap={"small"} justify="center" alignItems="center" pad={"xsmall"} style={{
								background: '#fff', borderRadius: 15,
							}}>
								<ContactSelectSimple contact={this.state.selectedContact} callbackSelected={(contact) => {
									saveContextBot({ client: contact, selectedCommercial: this.state.selectedCommercial });
									this.setState({ updateNum: this.state.updateNum + 1, selectedContact: contact });
								}} />
								<PrimaryButton
									size="xsmall"
									margin="xsmall"
									icon={<Close />}
									onClick={() => {
										clearContextBot();
										this.setState({ updateNum: this.state.updateNum + 1, selectedContact: {}, selectedCommercial: this.state.selectedCommercial })
									}}
								></PrimaryButton>
							</Box>}

							{hasAdministrativeRole() && this.props.fullscreen && <Box direction="row"
								gap={"small"}
								justify="center"
								align="center" alignSelf="center"
								alignContent="center" alignItems="center" pad={"xsmall"} style={{
									background: '#fff', borderRadius: 15, marginLeft: 20
								}}>
								<Text style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
									size={'small'} textAlign="end" >Commercial : </Text>
								<CustomSelect
									// key={3}
									options={this.state.commerciaux}
									onChange={({ option }) => {
										if (this.state.selectedContact?.id) {
											saveContextBot({ client: this.state.selectedContact, selectedCommercial: option.id !== 'All' ? option.id : null });
										} else {
											saveContextBot({ selectedCommercial: option.id !== 'All' ? option.id : null });
										}

										this.setState({ updateNum: this.state.updateNum + 1, selectedCommercial: option.id !== 'All' ? option.id : null })
									}}
									labelKey={'name'}
									valueKey={{ key: 'name', reduce: true }}
								/>
							</Box>}

						</Box>
						<Box key={this.state.updateNum} style={{ overflow: "scroll" }}>
							<TrustiBotRdvRappel commerciaux={this.state.commerciaux} onOpenedDialog={typeof this.props.onOpenedDialog === 'function' ? this.props.onOpenedDialog : ()=>{}}></TrustiBotRdvRappel>
							<TrustiBotNote commerciaux={this.state.commerciaux} onOpenedDialog={typeof this.props.onOpenedDialog === 'function' ? this.props.onOpenedDialog : ()=>{}}></TrustiBotNote>
							<TrustiBotGed commerciaux={this.state.commerciaux} onOpenedDialog={typeof this.props.onOpenedDialog === 'function' ? this.props.onOpenedDialog : ()=>{}}></TrustiBotGed>
						</Box>
					</Box>
				</Box>
				{/* </Layer> */}
				</ThemeProvider>
				{/* )} */}
			</>
		);
	}
}

export default TrustiBot;

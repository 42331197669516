
import { Box, Button, Card, DataTable, Heading, Text } from 'grommet';
import { StatusGoodSmall } from 'grommet-icons';
import React from 'react';
import { getSimpleDateTimeFR } from '../../../services/utils';
import CustomPagination from '../../elements/custom_pagination';
import DateInputString from '../../elements/date_input_string';
import CustomSelect from '../../elements/select_fill';
import Search from '../components/search';
import { countLogs, getLogs } from './service';
import LogsDetail from './components';
import Refresh from '../../elements/refresh';

class LogsApiProposition extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            popupOpen: false,
            selectedLog: {},
            total: 0,
            startIndex: 0,
            query: '',
            sort: 'createdAt:desc',
            filter: {
            }
        }
    }

    componentDidMount() {
        this.loadLogs();
    }

    loadLogs = () => {
        this.setState({ logs: [], startIndex: 0 });
        getLogs(this.state.startIndex, this.state.query, this.state.filter, this.state.sort)
            .then(res => this.setState({ logs: res.data }));
        countLogs(this.state.query, this.state.filter)
            .then(res => this.setState({ total: res.data }));
    }

    open = (log) => {
        this.setState({ selectedLog: log, popupOpen: true })
    }

    onNextPage = ({ startIndex }) => {
        this.setState({ startIndex }, () => {
            getLogs(this.state.startIndex, this.state.query, this.state.filter, this.state.sort)
                .then(res => this.setState({ logs: res.data }));
            countLogs(this.state.query)
                .then(res => this.setState({ total: res.data }));
        });
    }

    render() {
        return (
            this.state.popupOpen ?
                <LogsDetail
                    showMessage={this.props.showMessage}
                    log={this.state.selectedLog}
                    onClose={() => this.setState({ popupOpen: false })}
                    reload={this.loadLogs}
                />
                :
                <Box style={{ width: "100%" }}
                    align="center" justify="center"
                    alignSelf="center"
                    alignContent="center" gap="medium">
                    <Box style={{ width: "100%" }} gap="medium" pad={"medium"} justify='center' align='center'>
                        <Heading level={3} textAlign='center'>
                            API d'email proposition
                        </Heading>
                        <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium" width={'full'}>
                            <Refresh refresh={() => {
                                this.setState({ filter: {} }, () => this.loadLogs())
                            }}></Refresh>


                            <Card pad={'small'} gap='small'>
                                <Box direction='row' justify="between" alignSelf="center" align="center" gap="medium" width={'full'}>
                                    {/* <Search size={'small'} search={query => this.setState({ query }, this.loadLogs)} /> */}
                                    <DateInputString
                                        size={'small'}
                                        strValueInFRFormat={this.state.filter.createdAt}
                                        placeholder='Date de création'
                                        onChange={date => {
                                            let filter = this.state.filter;
                                            filter.createdAt = date;
                                            this.setState({ filter: Object.assign({}, filter) }, () => this.loadLogs());
                                        }}
                                    />

                                    <CustomSelect
                                        options={["Réussi", "Erreur"]}
                                        placeholder='Statut'
                                        value={this.state.filter.status ? this.state.filter.status === '200' ? "Réussi" : "Erreur" : ""}
                                        clear
                                        onChange={({ option }) => {
                                            let filter = this.state.filter;
                                            delete filter.status_ne;
                                            delete filter.status;
                                            if (option) {
                                                if (option === 'Réussi') {
                                                    
                                                    filter.status = '200';
                                                } else {
                                                    filter.status_ne = '200';
                                                    
                                                }
                                            }
                                            this.setState({ filter: Object.assign({}, filter) }, () => this.loadLogs());

                                        }}
                                    />

                                </Box>

                            </Card>
                        </Box>
                        <Box style={{ width: "100%" }}>
                            <CustomPagination total={this.state.total} onNextPage={this.onNextPage} startIndex={this.state.startIndex} />
                        </Box>
                        <DataTable
                            columns={[
                                {
                                    property: 'createdAt',
                                    header: <Text size="small"><strong>Création</strong></Text>,
                                    render: log => <Text size="xsmall" ><strong>{getSimpleDateTimeFR(log.createdAt)}</strong></Text>
                                },
                                {
                                    property: 'method',
                                    header: <Text size="small">Méthode</Text>,
                                    render: log => <Text size="small">{log.method}</Text>
                                },
                                {
                                    property: 'api_name',
                                    header: <Text size="small">API name</Text>,
                                    render: log => <Text size="small">{log.api_name}</Text>
                                },
                                {
                                    header: <Text size="small">URL</Text>,
                                    render: log => <Text size="small">{log.url}</Text>
                                },
                                {
                                    property: 'status',
                                    header: <Text size="small">Statut</Text>,
                                    render: log => <Marker text={log.status} color={log.status === '200' ? '#33BC00' : 'red'} />
                                },
                                {
                                    header: '',
                                    render: log => <Button label="voir" primary onClick={() => this.open(log)} />
                                },
                            ]}
                            sort={{ property: '', direction: '' }}
                            onSort={optionSort => {
                                this.setState({ sort: `${optionSort.property}:${optionSort.direction}` }, this.loadLogs);
                            }}
                            data={this.state.logs}
                        />
                    </Box >
                </Box >
        )
    }
}

const Marker = ({ text, color }) => (
    <Box direction="row" gap="xsmall" pad="xsmall" align="center">
        <StatusGoodSmall color={color} />
        <Text size="xsmall">
            <strong>{text}</strong>
        </Text>
    </Box>
)

export default LogsApiProposition;
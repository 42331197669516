import { Text } from "grommet";
import React from "react";
import { font_size, grey_500, grey_700, grey_900, line_height } from "../colors/colors";

const body1 = {
    color: grey_500,
    lineHeight: 1.375,
    fontSize: "0.75rem",
    fontWeight: 500,
    color: "rgba(100,116,139,1)"
};

const title1 = {
    color: grey_700,
    lineHeight: "1rem",
    fontSize: "0.75rem",
    fontWeight: 700,
};

const header3 = {
    color: grey_900,
    fontWeight: 900,
    fontSize: "1.25rem",
    lineHeight: "1.75rem",   
}

const TextLabel = ({ label, variant, color, ...rest }) => {
    switch(variant) {
        case "title1":
            return <Text style={{...title1, color}}>{label}</Text>
        case "body1":
            color = color || grey_500;
            return <Text style={{...body1, color}} wordBreak={'normal'} >{label}</Text>
        case "body1-bold":
            color = color || grey_500;
            return <Text style={{...body1, fontWeight: 'bold', color}} wordBreak={'normal'} >{label}</Text>
        case "header3":
            return <Text style={{...header3, color}}>{label}</Text>
        default:
            return <Text style={{...body1, color}}>{label}</Text>
    }
}

export {
    TextLabel
}
import { Box, Button } from 'grommet';
import { Update } from 'grommet-icons';
import React from 'react';

const Refresh = ({ refresh }) => (
    <Box direction='row' gap='small' alignSelf="center"  style={{width: 130}}>
        <Box round="full" overflow="hidden" pad="xsmall" >
            <Button primary icon={<Update />} onClick={refresh} />
        </Box>
    </Box>
)
export default Refresh;
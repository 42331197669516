export const PRODUCT_NAMES = {
    annulation_interruption: "Trusti Annulation / Interruption",
    annulation_interruption_neige: "Trusti Annulation / Interruption Neige",
    voyage: "Trusti Voyage",
    voyage_neige: "Trusti voyage neige",
    voyage_luxe: "Trusti Voyage Luxe",
    voyage_luxe_neige: "Trusti Voyage Neige Luxe",
    rc_dommage: "Trusti RC / Dommages",
    rc_dommage_lux: "Trusti RC / Dommages Luxe",
}

const products = [
    PRODUCT_NAMES.annulation_interruption,
    PRODUCT_NAMES.annulation_interruption_neige,
    PRODUCT_NAMES.voyage,
    PRODUCT_NAMES.voyage_neige,
    PRODUCT_NAMES.voyage_luxe,
    PRODUCT_NAMES.voyage_luxe_neige,
    PRODUCT_NAMES.rc_dommage,
    PRODUCT_NAMES.rc_dommage_lux,
]

export default products;

export const ventCanals = [
    "Lien classique",
    "Lien personnalisé",
    "Formulaire en direct",
    "API",
    "Fichier",
    "Booking Sync",
    "Booking Expert"
]

export const SINISTRES_STATUS = [
    "Nouveau",
    "En cours - Attente de documents",
    "En cours - Gestion du dossier",
    "En attente de RBT Compagnie",
    "Clos refus",
    "Clos remboursé"
]
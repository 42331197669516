import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';
import React from 'react';

const AddDevis = ({ onAdd }) => {

    return (
        <Box direction='row' gap='small' alignSelf="center" align="end" justify="end"
            alignContent="end" width="small">
            <Box round="full" overflow="hidden" pad="xsmall">
                <Button primary icon={<Add />} onClick={onAdd} />
            </Box>
        </Box>
    )
}

const AddEntity = ({ onAdd, title }) => {

    return (
        <Box direction='row' gap='small' alignSelf="center" align="end" justify="end"
            alignContent="end">
                <Button primary icon={<Add />} onClick={onAdd} label={title} />
        </Box>
    )
}
export default AddDevis;
export {
    AddEntity
}
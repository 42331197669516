
import React from 'react';
import { Box, Text } from 'grommet';
import { getSimpleDateFR, toCurrency, toCurrencyWithZero } from '../../../../services/utils';
import { getCommercialId, hasAdminRole } from '../../../../services/storage_service';
import { CONTRAT_CLOTURE, CONTRAT_ENVOYE, DEVIS, DEVIS_VALIDE, EN_ATTENTE_BANQUE, EN_ATTENTE_DE_FORMALITE_MEDICALE, EN_ATTENTE_DE_RESILIATION } from '../../../../services/devis_contrat_status';
import { CLOTURE } from '../../../../services/credit_status';

const ContractCreditDetails = ({ devis, commission_taux, display }) => {
    commission_taux = devis.pourcentage_commission?.length ? devis.pourcentage_commission : commission_taux;
    return (
        !hasDetails(devis) ? <Box /> :
            <Box pad="xsmall" style={{ border: '1px solid #c8c8c8', borderRadius: 5 }} >
                <InfoLine label="Montant crédit" value={`${devis.sub_product === 'Regroupement de crédit' ? (devis.emprunte_total) : (devis.montant || '')}€`} />
                <InfoLine label="Frais dossier" value={`${devis.frais_dossier || ''}€`} />
                {devis.sub_product !== "Regroupement de crédit" && <InfoLine label="Fractionnement" value={devis.fractionnement || ''} />}
                {devis.sub_product !== "Regroupement de crédit" && <InfoLine label="Mensualité des frais" value={`${devis.frais_dossier_mensuel || ''}€`} />}
                {(hasAdminRole()) &&
                    <InfoLine label="Trustiway com" value={toCurrencyWithZero(getCommission(devis))} color={display ? display.com : undefined} />
                }
                {hasAdminRole() &&
                    <InfoLine label="Total Trustiway" value={toCurrencyWithZero(getTotalTT(devis))} color={display ? display.total_trustiway : undefined} />
                }
                {getCommissionTaux(devis, commission_taux) > 0 && (hasAdminRole() || devis.commercial_id === getCommercialId()) &&
                    <InfoLine label="Commercial com" value={`${toCurrencyWithZero(getCommercialCommission(devis, commission_taux))}`} />
                }
                {devis.com_apporteur && <InfoLine label="Apporteur" value={`${toCurrencyWithZero(devis.com_apporteur) || ''}`} />}
                {hasAdminRole() &&
                    <>
                        {devis.com_network_mother && <InfoLine label="Réseau mère" value={toCurrency(parseFloat(devis.com_network_mother))} />}
                        {devis.com_network && <InfoLine label="Réseau" value={toCurrency(parseFloat(devis.com_network))} />}
                        {devis.com_level_1 && <InfoLine label="Niv 1" value={toCurrency(parseFloat(devis.com_level_1))} />}
                        {devis.com_level_2 && <InfoLine label="Niv 2" value={toCurrency(parseFloat(devis.com_level_2))} />}
                        {devis.com_level_3 && <InfoLine label="Niv 3" value={toCurrency(parseFloat(devis.com_level_3))} />}
                        <InfoLine label="TT Trustiway HT" value={toCurrency(getTotalTT_HT(devis, commission_taux))} />
                    </>
                }


            </Box>
    )
}


const getFraisDossier = deal => {
    let devis = deal.devis || deal;
    if (devis.model_type === 'pno_seul') {
        return devis.rawResponse?.frais_dossier || 0;
    }
    return devis.frais_dossier_total || devis.frais_dossier || 0
};

const getCommissionTaux = (devis, tauxConfig) => {
    // console.log('tauxConfig', tauxConfig);
    if (!devis.commercial_id) { // no commercial, no com
        return 0;
    }
    console.log('tauxConfig', tauxConfig);
    if (!tauxConfig) return null;
    // let { custom_taux, default_taux } = tauxConfig;
    // let { default_taux } = tauxConfig;
    // var custom_taux = null;
    // if (devis.pourcentage_commission?.length) {
    //     custom_taux = devis.pourcentage_commission;
    // }
    let totalTrustiway = getTotalTT(devis);
    // if (!custom_taux){
    //     custom_taux?.forEach(t => {
    //         Object.keys(t).forEach(key => t[key] = parseFloat(t[key]));
    //     });
    // }
    let taux = tauxConfig.find(s => s.valeur_inferieure <= totalTrustiway && s.valeur_superieure > totalTrustiway);
    //custom_taux?.find(s => s.valeur_inferieure <= totalTrustiway && s.valeur_superieure > totalTrustiway)
    // if (!taux) {
    //     taux = default_taux.find(s => s.valeur_inferieure <= totalTrustiway && s.valeur_superieure > totalTrustiway);
    // }

    if (taux) return taux.pourcentage_commercial || taux.pourcentage;
}

const getCommercialCommission = (devis, commission_taux) => {
    var _fraisDossier = 0;
    var _comCom = getCommissionTaux(devis, commission_taux);
    console.log('_comCom = ',_comCom);
    try {
        _fraisDossier = parseFloat(devis.frais_dossier);
    } catch (error) {
        _fraisDossier = 0;
    }

    if (devis.sub_product === "Regroupement de crédit") {
        if (devis.hasOwnProperty('commission_manuel')) {
            return parseFloat(devis.commission_manuel) || 0;
        } else {
            return _comCom * getTotalTT(devis) / 100;
        }

    }
    // if (_fraisDossier <= 2500) {
    //     _comCom = 0.1 * _fraisDossier;
    // } else if (_fraisDossier > 2500 && _fraisDossier <= 5000) {
    //     _comCom = 0.08 * _fraisDossier;
    // } else if (_fraisDossier > 5000) {
    //     _comCom = 0.06 * _fraisDossier;
    // }
    return _comCom * _fraisDossier / 100;

}

const getCommission = devis => {
    var _montant = 0;
    var _com = 0;
    var _comCredit = 0;
    try {
        _montant = parseFloat(devis.montant);
    } catch (error) {
        _montant = 0;
    }
    try {
        _comCredit = parseFloat(devis.commission_total);
    } catch (error) {
        _comCredit = 0;
    }
    if (devis.model_type === 'credit') {
        _com = _comCredit;
    }
    return _com
}

const getTotalTT = devis => {
    var _fraisDossier = 0;
    var _com = getCommission(devis);
    try {
        _fraisDossier = parseFloat(devis.frais_dossier);
    } catch (error) {
        _fraisDossier = 0;
    }
    return _fraisDossier + _com;
}

const getApporteurCom = devis => devis.affiliation?.retrocession ? parseFloat(devis.affiliation?.retrocession) : 0;

const getTotalTT_HT = (devis, commission_taux) => {
    return getTotalTT(devis) -
        getCommercialCommission(devis, commission_taux) -
        getApporteurCom(devis) -
        (devis.com_network_mother ? parseFloat(devis.com_network_mother) : 0) -
        (devis.com_network ? parseFloat(devis.com_network) : 0) -
        (devis.com_apporteur ? parseFloat(devis.com_apporteur) : 0) -
        (devis.com_level_1 ? parseFloat(devis.com_level_1) : 0) -
        (devis.com_level_2 ? parseFloat(devis.com_level_2) : 0) -
        (devis.com_level_3 ? parseFloat(devis.com_level_3) : 0)
}


const InfoLine = ({ label, value, color }) => {
    let style = color ? { border: '1px solid #000', borderRadius: 5, color } : {}

    return (<Text style={style} size="xsmall" >{label} : <strong>{value}</strong></Text>)
}

const hasDetails = devis => devis.status === DEVIS ||
    devis.status === DEVIS_VALIDE ||
    devis.status === CONTRAT_ENVOYE ||
    devis.status === CONTRAT_CLOTURE ||
    devis.status === CLOTURE ||
    devis.status === EN_ATTENTE_DE_FORMALITE_MEDICALE ||
    devis.status === EN_ATTENTE_DE_RESILIATION ||
    devis.status === EN_ATTENTE_BANQUE;

const getMHCommission = devis => {
    devis = devis.devis || devis;
    if (devis.model_type !== 'pno_seul' &&
        devis.model_type !== 'devis_habitation' &&
        devis.model_type !== 'trusti_mh_tempo') return;
    let isPNO = devis.model_type === 'pno_seul';
    let isTempo = devis.model_type === 'trusti_mh_tempo';
    let rawResponse = devis.rawResponse;

    let com = isPNO ? rawResponse.commission :
        isTempo ? rawResponse.total_trustiway :
            devis.option === "Trusti base" ?
                rawResponse.MontantTotalCommissions_logibase :
                rawResponse.MontantTotalCommissions_logicomfort;
    // deduire promo
    return devis.promo?.value ? com - (devis.promo.value) : com;
}

const getDateEffet = devis => getSimpleDateFR((devis.devis || devis).effectiveDate || devis.begin_date || devis.date_effet);

const isSubscribed = status => {
    console.log('status :', status);
    return status === CONTRAT_CLOTURE || status === EN_ATTENTE_BANQUE;
}

export {
    ContractCreditDetails,
    getTotalTT,
    getFraisDossier,
    getMHCommission,
    getDateEffet,
    isSubscribed,
}
import { Box, Pagination, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import React from "react";
import { useState } from "react";
import { ContractSimpliedDetails } from "../../../elements/contract_details";
import StandardText from "../../../elements/standard_text";
import PaymentStatus from '../../../elements/devis_payment_status';
import RecapStatus from "../../../elements/devis_status";
import { getSimpleDateFR, getSimpleDateTimeFR } from "../../../../services/utils";
import CreditRecapStatus from "./credit_status";
import { ContractCreditDetails } from "./detail_recap";
import { ABONDONS, CLOTURE, DOSSIER_ENVOYE_A_LA_BANQUE, DOSSIER_EN_INSTRUCTION, EN_ETUDE_DE_ANALYSTE, EN_ETUDE_DE_PRE_ANALYSTE, FACTURE, NOUVEAU, NOUVEAU_REMPLI, OFFRE_ACCEPTE_PAR_CLIENT, PRET_ACCORDE_PAR_LA_BANQUE, PROSPECT, REALISE } from "../../../../services/credit_status";
import { A_RAPPELER_PLUS_TARD } from "../../../../services/devis_contrat_status";

const CreditViewTable = ({ reports = [], commerciaux, commission_taux, onDevisSelected }) => {
    const pageSize = 20;
    const [indices, setIndices] = useState([0, pageSize]);
    const [page, setPage] = useState(1);
    return (
        <Box width='full' justify="center" align="center">
            <Box width={'full'} justify="center" align="center">
                <Box align="center" direction="row" justify="between" width={'full'}>
                    <StandardText size={'small'}
                        label={`Afficher ${indices[0] + 1 < (reports || []).length ? indices[0] + 1 : 0} - ${indices[1] < (reports || []).length ? indices[1] : (reports || []).length} de ${(reports || []).length}`}
                    />
                    <Pagination numberItems={(reports || []).length}
                        size='small'
                        step={pageSize}
                        onChange={({ startIndex, endIndex }) => {
                            console.log(startIndex);
                            setPage((startIndex / pageSize) + 1);
                            setIndices([startIndex, Math.min(endIndex, reports.length)]);
                        }}
                    />
                </Box>
                <Box
                    style={{
                        width: "100%",
                        marginTop: 0,
                        marginBottom: 0,
                    }}
                    background={"#fff"}
                    fill="horizontal"
                >
                    <Table
                        fill="horizontal"
                        style={{
                            width: "100%",
                        }}
                    >
                        <TableHeader>
                            <TableRow>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Source
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Apporteur
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Partenaire
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Commercial
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Pre-Analyste
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Analyste
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"90px"}>
                                    <Text size="small" weight="bold">
                                        Création -<br />
                                        Modification
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        BPA
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Date d'effet
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Clôture
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Assuré
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Produit
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Fournisseur
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"120px"}>
                                </TableCell>
                                {(reports || [])[0]?.model_type === 'emprunteur_horsligne' && <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">Paiements</Text>
                                </TableCell>}
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Nbr appel
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Dernier appel
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {(reports || [])
                                .slice((page - 1) * pageSize, page * pageSize)
                                .map((devis, index) => {
                                    // console.log("devis = ", devis);
                                    let _client = devis.client || devis.entreprise || {};
                                    let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                                    var nbr_appel = 'prospect';
                                    switch (devis.status) {
                                        case NOUVEAU:
                                            nbr_appel = 'nouveau';
                                            break;
                                        case NOUVEAU_REMPLI:
                                            nbr_appel = 'nouveau_rempli';
                                            break;
                                        case A_RAPPELER_PLUS_TARD:
                                            nbr_appel = 'a_rappeler_plus_tard';
                                            break;
                                        case DOSSIER_EN_INSTRUCTION:
                                            nbr_appel = 'dossier_en_instruction';
                                            break;
                                        case EN_ETUDE_DE_PRE_ANALYSTE:
                                            nbr_appel = 'en_etude_de_pre_analyste';
                                            break;
                                        case EN_ETUDE_DE_ANALYSTE:
                                            nbr_appel = 'en_etude_de_analyste';
                                            break;
                                        case DOSSIER_ENVOYE_A_LA_BANQUE:
                                            nbr_appel = 'dossier_envoye_a_la_banque';
                                            break;
                                        case PRET_ACCORDE_PAR_LA_BANQUE:
                                            nbr_appel = 'pret_accorde_par_la_banque';
                                            break;
                                        case OFFRE_ACCEPTE_PAR_CLIENT:
                                            nbr_appel = 'offre_accepte_par_client';
                                            break;
                                        case REALISE:
                                            nbr_appel = 'resilie';
                                            break;
                                        case FACTURE:
                                            nbr_appel = 'facture';
                                            break;
                                        case CLOTURE:
                                            nbr_appel = 'cloture';
                                            break;
                                        case ABONDONS:
                                            nbr_appel = 'abondons';
                                            break;
                                        default:
                                            nbr_appel = 'prospect';
                                            break;
                                    }
                                    return (
                                        <TableRow
                                            onClick={() => onDevisSelected(devis)}
                                            key={index + 1}
                                            style={{ backgroundColor: _bgColor, cursor: 'pointer' }}
                                        >
                                            <TableCell width={"80px"}>
                                                <Text size="small">{devis.source} </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">{getApporteurName(devis)}</Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">{getPartenaireName(devis)}</Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">{commerciaux?.find(d => d.id === devis.commercial_id)?.name}</Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">{commerciaux?.find(d => d.id === devis.pre_analyste_id)?.name}</Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">{commerciaux?.find(d => d.id === devis.analyste_id)?.name}</Text>
                                            </TableCell>
                                            <TableCell width={"90px"}>
                                                <Text size="small">
                                                    {getSimpleDateFR(devis.createdAt)} - <br />
                                                    {getSimpleDateFR(devis.updatedAt)}
                                                </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">
                                                    {getSimpleDateTimeFR(devis.date_bpa)}
                                                </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">
                                                    {getSimpleDateTimeFR(
                                                        devis.date_effet ||
                                                        devis.begin_date ||
                                                        devis.effectiveDate
                                                    )}
                                                </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">
                                                    {getSimpleDateTimeFR(devis.date_cloture)}
                                                </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">
                                                    {(_client.raisonSocial || _client.raison_social)
                                                        ? (_client.raisonSocial || _client.raison_social)
                                                        : `${_client.lastname} ${_client.firstname}`}
                                                </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                {devis.sub_product_detail ?
                                                    <Text size="xsmall">{devis.sub_product_detail} </Text> :
                                                    (devis.sub_product ?
                                                        <Text size="xsmall">{devis.sub_product} </Text> : <Text size="xsmall">{devis.product} </Text>)
                                                }
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">{devis.fournisseur} </Text>
                                            </TableCell>
                                            <TableCell >
                                                <ContractCreditDetails
                                                    devis={devis}
                                                    commission_taux={commission_taux}
                                                    key={devis.id}
                                                    display={{
                                                        frais: '#E52764',
                                                        accompt: '#FC0000',
                                                        com: '#33BC00',
                                                        total_trustiway: '#73AAAA'
                                                    }}
                                                />
                                            </TableCell>
                                            {devis.model_type === 'emprunteur_horsligne' && <TableCell>
                                                <PaymentStatus data={devis} contract_paid={devis.contract_paid} isEmprunteur={true} status_payment={devis.status_payment} />
                                            </TableCell>
                                            }
                                            <TableCell width={"120px"}>
                                                <CreditRecapStatus status={devis.status} size='xsmall' />
                                            </TableCell>

                                            <TableCell width={"80px"}>
                                                <Text size="small">{devis['nbr_appel_' + nbr_appel]} </Text>
                                            </TableCell>

                                            <TableCell width={"80px"}>
                                                <Text size="small">{getSimpleDateTimeFR(devis['date_dernier_appel'])} </Text>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </Box>
            </Box>
            <Box align="center" direction="row" justify="between" width={'full'}>
                <StandardText size={'small'}
                    label={`Afficher ${indices[0] + 1 < (reports || []).length ? indices[0] + 1 : 0} - ${indices[1] < (reports || []).length ? indices[1] : (reports || []).length} de ${(reports || []).length}`}
                />
                <Pagination numberItems={(reports || []).length}
                    size='small'
                    step={pageSize}
                    onChange={({ startIndex, endIndex }) => {
                        console.log(startIndex);
                        setPage((startIndex / pageSize) + 1);
                        setIndices([startIndex, Math.min(endIndex, reports.length)]);
                    }}
                />
            </Box>
        </Box>
    );
};

const CreditViewTableWithoutPagination = ({ reports = [], commerciaux, commission_taux, onDevisSelected }) => {

    return (
        <Box width='full' justify="center" align="center"
        >
            <Box
                style={{
                    width: "100%",
                    marginTop: 0,
                    marginBottom: 0,
                }}
                background={"#fff"}
            >
                <Table
                    fill="horizontal"
                    style={{
                        width: "100%",
                    }}
                >
                    <TableHeader>
                        <TableRow>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Source
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Apporteur
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Partenaire
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Commercial
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"90px"}>
                                <Text size="small" weight="bold">
                                    Création -<br />
                                    Modification
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    BPA
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Date d'effet
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Clôture
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Assuré
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Produit
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {(reports || [])
                            .map((devis, index) => {
                                // console.log("devis = ", devis);
                                let _client = devis.client || devis.entreprise || {};
                                let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                                return (
                                    <TableRow
                                        onClick={() => onDevisSelected(devis)}
                                        key={index + 1}
                                        style={{ backgroundColor: _bgColor, cursor: 'pointer' }}
                                    >
                                        <TableCell width={"80px"}>
                                            <Text size="small">{devis.source} </Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">{getApporteurName(devis)}</Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">{getPartenaireName(devis)}</Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">{commerciaux?.find(d => d.id === devis.commercial_id)?.name}</Text>
                                        </TableCell>
                                        <TableCell width={"90px"}>
                                            <Text size="small">
                                                {getSimpleDateFR(devis.createdAt)} - <br />
                                                {getSimpleDateFR(devis.updatedAt)}
                                            </Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">
                                                {getSimpleDateFR(devis.date_bpa)}
                                            </Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">
                                                {getSimpleDateFR(
                                                    devis.date_effet ||
                                                    devis.begin_date ||
                                                    devis.effectiveDate
                                                )}
                                            </Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">
                                                {getSimpleDateFR(devis.date_cloture)}
                                            </Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">
                                                {(_client.raisonSocial || _client.raison_social)
                                                    ? (_client.raisonSocial || _client.raison_social)
                                                    : `${_client.lastname} ${_client.firstname}`}
                                            </Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            {devis.sub_product_detail ?
                                                <Text size="xsmall">{devis.sub_product_detail} </Text> :
                                                (devis.sub_product ?
                                                    <Text size="xsmall">{devis.sub_product} </Text> : devis.product)
                                            }
                                        </TableCell>
                                        <TableCell >
                                            <ContractSimpliedDetails
                                                devis={devis}
                                                commission_taux={commission_taux}
                                                key={devis.id}
                                                display={{
                                                    frais: '#E52764',
                                                    accompt: '#FC0000',
                                                    com: '#33BC00',
                                                    total_trustiway: '#73AAAA'
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell width={"120px"}>
                                            <RecapStatus status={devis.status} size='xsmall' />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </Box>
        </Box>
    );
}

const getApporteurName = (devis) => {
    let apporteur = devis.apporteur;
    if (apporteur) {
        return (`${apporteur.prenom} ${apporteur.nom}`)
    }
}

const getPartenaireName = (devis) => {
    if (devis.apporteur && devis.apporteur?.apporteur_pro) {
        return devis.apporteur?.apporteur_pro?.raisonSocial ?? '';
    }
    let affiliation = devis.affiliation;
    if (affiliation) {
        return affiliation?.agence_name;
    }
    return (devis.provider_name || devis.agence_name);
}

export {
    CreditViewTableWithoutPagination
};

export default CreditViewTable;
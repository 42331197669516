import React from 'react';
import { Box, Tabs, Tab, Text } from 'grommet';
import { navigate } from 'gatsby-link';
import { verifyConnected, logout } from '../../services/login_service';
import DashboardMyTrustiway from './dashboard';
import AppUser from './users';
import AppComptable from './comptable';
import MainMenu from '../elements/menu/menu';
import AppUserValidated from './users/validated';
import AppUserNotValidated from './users/not_validated';
import AppComptablePayout from './comptable/payout';
import LeadImmobilierList from './immobilier';
import AppPushNotification from './push_notification';
import ApporteurPros from '../apporteur_pro/apporteurs';
import { getCollabs } from '../../services/admin_service';


class MyTrustiwayPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indicator: undefined,
            selectedTab: props.selectedTab || 0,
            commerciaux: []
        }
    }

    componentDidMount() {
        verifyConnected()
            .then(res => {
            })
            .catch(err => {
                let res = err.response;
                if (res && res.status === 401) {
                    logout();
                    navigate("/login");
                }
            });
        getCollabs()
            .then(res => this.setState({ commerciaux: res.data.map(c => ({ name: `${c.prenom} ${c.nom}`, ...c })) }));
    }

    render() {
        console.log("selectedTab", this.state.selectedTab);
        return (

            <Box style={{ width: "100%" }}
                align="center" justify="center"
                alignSelf="center"
                alignContent="center" gap="medium">
                <Tabs style={{ width: '100%' }} activeIndex={this.state.selectedTab} onActive={(index) => {
                        this.setState({ selectedTab: index });
                }}>
                    <Tab title="Dashboard" >
                        <DashboardMyTrustiway showMessage={this.props.showMessage} goto={(index) => this.setState({ selectedTab: index })} />
                    </Tab>
                    <Tab title='Utilisateurs'>
                        <AppUserValidated showMessage={this.props.showMessage} />
                    </Tab>
                    <Tab title="Partenaires" >
                        <Tabs style={{ width: '100%' }}>
                            <Tab title="Maison mère">
                                <ApporteurPros
                                    key={'app_mother'}
                                    showMessage={this.props.showMessage}
                                    commerciaux={this.state.commerciaux}
                                    mother={true}
                                />
                            </Tab>
                            <Tab title="Apporteurs">
                                <ApporteurPros
                                    key={'app_child'}
                                    showMessage={this.props.showMessage}
                                    commerciaux={this.state.commerciaux}
                                    mother={false}
                                />
                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab title="Comptabilité" >
                        <Box justify="center" pad={"small"} flex>
                            <Tabs>
                                <Tab title="Commission">
                                    <AppComptable key={this.state.updateNum} showMessage={this.showMessage} />
                                </Tab>
                                <Tab title="Retrait">
                                    <AppComptablePayout key={this.state.updateNum} showMessage={this.showMessage} />
                                </Tab>
                            </Tabs>
                        </Box>
                    </Tab>
                    <Tab title="Lead Mandat Immobilier" >
                        <LeadImmobilierList showMessage={this.props.showMessage} />
                    </Tab>
                    <Tab title="Retrait" >
                        <AppComptablePayout showMessage={this.props.showMessage} />
                    </Tab>
                    <Tab title="📲Push Notification" >
                        <AppPushNotification showMessage={this.props.showMessage} />
                    </Tab>


                </Tabs>

            </Box>
        )
    }
}

export default MyTrustiwayPanel;
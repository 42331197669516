import React, { Fragment, useContext, useState } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Input,
	FormControl,
	Textarea,
	useToast,
	useDisclosure,
} from "@chakra-ui/core";
import PrimaryButton from "../../../elements/primary_button";
import { Box, Card, Button, FileInput } from "grommet";
import { AddCircle, DocumentDownload, Trash } from "grommet-icons";
import {
	getClientName,
} from "../../../outils/client_util";
import {
	createBlocGed,
	deleteBlocGed,
	updateBlocGed,
} from "../service/bloc_ged";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
	getContextBot,
	getUser,
	uploadFile,
} from "../../../../services/storage_service";
import GedContext from "../context/ged/GedContext";

const GedModal = ({
	noteData = {},
	label = "Nouvelle GED",
	modeEdit = false,
	callback,
	onOpenedDialog,
	updatedContext,
  icon
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const onOpen = () => {
		setIsOpen(true);
		onOpenedDialog(true);
	};

	const onClose = () => {
		setIsOpen(false);
		onOpenedDialog(false);
	};
	const { files, setFiles, clearFiles } = useContext(GedContext);

	const toast = useToast();
	const _contextBot = getContextBot();
	const _commercial = getUser();

	const doUploadFile = (_files, _callback) => {
		const fileList = _files;
		for (let i = 0; i < fileList.length; i += 1) {
			const file = fileList[i];
			uploadFile(file)
				.then((result) => {
					// console.log("result = ", result.data);
					_callback(result.data);
				})
				.catch((err) => {
					toast({
						title: "Erreur",
						description: err.message,
						status: "error",
						duration: 3000,
						isClosable: true,
					});
					_callback([]);
				});
		}
	};

	const onDelete = (e) => {
		if (noteData) {
			deleteBlocGed(noteData)
				.then((res) => {
					toast({
						title: "Réussi",
						description: "GED est bien supprimé",
						status: "success",
						duration: 3000,
						isClosable: true,
					});
					onClose();
					callback();
				})
				.catch((err) => {
					toast({
						title: "Erreur",
						description: err.message,
						status: "error",
						duration: 3000,
						isClosable: true,
					});
				});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		const title = form.elements["title"].value;

		console.log("noteData.files = ", files);

		if (noteData && modeEdit) {
			// update
			noteData.title = title;
			noteData.files = files;

			updateBlocGed(noteData)
				.then(() => {
					toast({
						title: "Réussi",
						description: "GED est bien modifié",
						status: "success",
						duration: 3000,
						isClosable: true,
					});
					onClose();
					callback();
				})

				.catch((err) => {
					toast({
						title: "Erreur",
						description: err.message,
						status: "error",
						duration: 3000,
						isClosable: true,
					});
				});
		} else {
			// create new
			var _params = {
				title: title,
				files: files,
				commercial: _commercial._id,
			};
			if (_contextBot && _contextBot.client) {
				_params.client = _contextBot.client.id || _contextBot.client;
				_params.contract_name = _contextBot.product;
				_params.contract_id = _contextBot.id;
			}
			createBlocGed(_params)
				.then(() => {
					toast({
						title: "Réussi",
						description: "GED est bien créé",
						status: "success",
						duration: 3000,
						isClosable: true,
					});
					onClose();
					callback();
				})
				.catch((err) => {
					toast({
						title: "Erreur",
						description: err.message,
						status: "error",
						duration: 3000,
						isClosable: true,
					});
				});
		}
		clearFiles();
	};

	return (
		<Fragment>
      {icon ? (
        <PrimaryButton
          label={label}
          background="none" icon={<AddCircle color="white" />}
          onClick={() => {
            onOpen();
            if (modeEdit) {
              setFiles(noteData.files || []);
            }else{
              clearFiles();
            }
          }}
        />
      ):(
        <PrimaryButton
          label={label}
          onClick={() => {
            onOpen();
            if (modeEdit) {
              setFiles(noteData.files || []);
            }else{
              clearFiles();
            }
          }}
        />
      )}
			<Modal
				isOpen={isOpen}
				size="6xl"
				onClose={() => {
					onClose();
					callback();
				}}
				closeOnOverlayClick={true}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{modeEdit ? "Modification" : "Nouvelle GED"}
					</ModalHeader>
					<ModalCloseButton />
					<form id="form" onSubmit={handleSubmit}>
						<ModalBody>
							<Box gap={"small"}>
								<FormControl isRequired>
									<Input
										type="text"
										id="title"
										placeholder="Titre"
										defaultValue={noteData ? noteData.title : ""}
									/>
								</FormControl>
								<Card align="center" pad="small">
									<strong>Télécharger les documents</strong>
									<br />
									Glissez-déposez des fichiers ici
									<Box direction="column" pad="small" gap="small">
										{files.length > 0 ? (
											files.map((e, _idx) => {
												console.log("e = ", e);
												return (
													<Box
														key={`file_${_idx}`}
														direction="row"
														pad="small"
														gap="small"
														justify="between"
													>
														<Box direction="row" gap="small" overflow="hidden">
															{e && (
																<Button
																	onClick={() => {
																		console.log("remove index = ", _idx);
																		let _files = files;
																		_files.splice(_idx, 1);
																		setFiles(_files);
																	}}
																>
																	<Trash color="red" />
																</Button>
															)}
															{e && (
																<a
																	target={"_blank"}
																	rel="noreferrer"
																	href={`${e}`}
																>
																	<DocumentDownload />
																	{e.split("/").pop()}
																</a>
															)}
														</Box>
														<FileInput
															messages={{
																browse: "Choisir",
																dropPrompt: "Ajouter un autre document",
															}}
															multiple={false}
															onChange={(event) => {
																// setIsLoading(true);
																doUploadFile(
																	event.target.files,
																	async (data) => {
																		if (data.length > 0) {
																			let _uploaded = data[0];
																			let _files = files || [];
																			_files[_idx] = _uploaded.url;
																			setFiles(_files);
																		}
																		// setIsLoading(false);
																	},
																);
															}}
														/>
													</Box>
												);
											})
										) : (
											<></>
										)}
										<FileInput
											messages={{
												browse: "Choisir",
												dropPrompt: "Ajouter un autre document",
											}}
											multiple={false}
											onChange={(event) => {
												// setIsLoading(true);
												doUploadFile(event.target.files, async (data) => {
													if (data.length > 0) {
														let _uploaded = data[0];
														let _files = files;
														_files.push(_uploaded.url);
														setFiles(_files);
													}
													// setIsLoading(false);
												});
											}}
										/>
									</Box>
								</Card>
								<Box direction="row" justify="between">
									<FormControl>
										<Input
											type="text"
											id="contract_name"
											placeholder="Produit"
											isDisabled={true}
											value={
												noteData
													? noteData.contract_name
													: _contextBot
													? _contextBot.product
													: ""
											}
										/>
									</FormControl>
									<FormControl>
										<Input
											type="text"
											id="client_name"
											placeholder="Client"
											isDisabled={true}
											value={
												noteData
													? noteData.client
														? getClientName(noteData.client)
														: _contextBot
														? getClientName(_contextBot.client)
														: ""
													: ""
											}
										/>
									</FormControl>
								</Box>
							</Box>
						</ModalBody>

						<ModalFooter>
							<Box direction="row" justify="between">
								{noteData && (
									<PrimaryButton
										primary
										type="reset"
										color="brand"
										mr={3}
										onClick={onDelete}
									>
										Supprimer
									</PrimaryButton>
								)}

								<PrimaryButton type="submit">Valider</PrimaryButton>
							</Box>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>
		</Fragment>
	);
};

export default GedModal;

import { Box, Button, Card, Heading, Layer, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import { AddCircle, Close, Duplicate, Refresh, Save, Trash } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { deleteCollab, getCollabs, getEquipes, getRoles, saveCollab } from '../../../services/admin_service';
import { hasAdminRole, hasClassiqueManagerRole, isCommercialClassique } from '../../../services/storage_service';
import { CustomLineModification, LineDisplay } from '../../elements/line_container/line_modification';
import PopupConfirmation from "../../elements/popup_confirmation";
import PrimaryButton from '../../elements/primary_button';
import CustomSelect from '../../elements/select_fill';
import ToggleButton from "../../elements/toggle_button/toggle_button";
import { replaceCommercial } from '../service';
import { TextLabel } from '../../elements/refont/text-label';


class GestionCollab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            all_commerciaux: [],
            commerciaux: [],
            filter: {},
            roles: [],
            equipes: [],
            selectedCommercial: undefined,
            popup_delete: false,
            replacedCommercial: undefined
        }
    }

    componentDidMount = () => {
        getCollabs()
            .then(res => this.setState({ commerciaux: res.data, all_commerciaux: res.data }))
            .catch(err => console.log(err));
        getEquipes()
            .then(res => this.setState({ equipes: res.data }))
            .catch(err => console.log(err));
        getRoles()
            .then(res => this.setState({ roles: res.data }))
            .catch(err => console.log(err));
    }

    onReload = () => {
        getCollabs()
            .then(res => this.setState({ commerciaux: res.data }));
    }

    updateFilter = (field, value, callback) => {
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter: { ...filter } }, callback);
    }

    filterEquipeChange = equipe => {
        let commerciaux = this.state.all_commerciaux.filter(c => c.equipe_id === equipe.id);
        this.setState({ commerciaux });
    }

    filterRoleChange = role => {
        let commerciaux = this.state.all_commerciaux.filter(c => c.role.id === role.id);
        this.setState({ commerciaux });
    }

    render() {
        return (
            this.state.selectedCommercial ?
                <Collaborateur
                    originalCollab={this.state.selectedCommercial}
                    roles={this.state.roles}
                    equipes={this.state.equipes}
                    onReload={this.onReload}
                    onClose={() => this.setState({ selectedCommercial: undefined })}
                    viewMode={this.state.selectedCommercial?.id ? true : false}
                    showMessage={this.props.showMessage}
                    commerciaux={this.state.commerciaux}
                /> :

                this.state.replacedCommercial ?
                    <ReplaceCommercial
                        commerciaux={this.state.commerciaux.filter(c => isCommercialClassique(c) || hasClassiqueManagerRole(c) || hasAdminRole(c))}
                        replacedCommercial={this.state.replacedCommercial}
                        showMessage={this.props.showMessage}
                        onClose={() => this.setState({ replacedCommercial: null })}
                    />

                    :
                    <Box width={'full'} align="center" justify='center'>
                        {this.state.commercialToDelete &&
                            <PopupConfirmation
                                primaryAction={() => {
                                    deleteCollab(this.state.commercialToDelete.id)
                                        .then(res => {
                                            this.onReload();
                                            this.setState({ commercialToDelete: undefined })
                                        })
                                        .catch(err => this.props.showMessage("Erreur inattendu", "error"))
                                }}
                                message="Êtes-vous sûr de supprimer ce collaborateur?"
                                primaryActionText={"Supprimer"}
                                secondaryAction={() => this.setState({ commercialToDelete: undefined })}
                                secondaryActionText="Annuler"
                                close={() => this.setState({ commercialToDelete: undefined })}
                            />
                        }
                        <Box
                            width="large"
                            justify="around"
                            margin="medium"
                            direction="row"
                            pad={'small'}
                        >
                            <PrimaryButton icon={<Refresh />} reverse
                                onClick={this.onReload}
                            />
                            <CustomSelect
                                placeholder={'Rôle'}
                                options={this.state.roles}
                                labelKey={"name"}
                                valueKey={{ key: "name", reduce: true }}
                                onChange={({ option }) => this.filterRoleChange(option)}
                            />
                            <CustomSelect
                                placeholder={'Equipe'}
                                options={this.state.equipes}
                                labelKey={"name"}
                                valueKey={{ key: "name", reduce: true }}
                                onChange={({ option }) => this.filterEquipeChange(option)}
                            />
                            <PrimaryButton icon={<AddCircle />} reverse
                                onClick={() => this.setState({ selectedCommercial: {} })}
                            />
                        </Box>

                        <Box width={'xxlarge'}>
                            <Table
                                fill="horizontal"
                                style={{
                                    width: "100%",
                                }}
                            >
                                <TableHeader>
                                    <TableRow>
                                        <TableCell scope="col">
                                            <Text size="small" weight="bold">
                                                Nom
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col">
                                            <Text size="small" weight="bold">
                                                Prénom
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col">
                                            <Text size="small" weight="bold">
                                                Mail
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col">
                                            <Text size="small" weight="bold">
                                                Mail Perso
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col">
                                            <Text size="small" weight="bold">
                                                Téléphone
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col">
                                            <Text size="small" weight="bold">
                                                Téléphone Perso
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col">
                                            <Text size="small" weight="bold">
                                                Equipe
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col">
                                            <Text size="small" weight="bold">
                                                Rôle
                                            </Text>
                                        </TableCell>
                                        <TableCell scope="col">
                                            <Text size="small" weight="bold">
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {(this.state.commerciaux)
                                        .map((commercial, index) => {
                                            let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                                            return (
                                                <TableRow
                                                    key={index + 1}
                                                    style={{ backgroundColor: _bgColor }}
                                                >
                                                    <TableCell>
                                                        <Text size="small">{commercial.nom} </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text size="small">{commercial.prenom}</Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text size="small">{commercial.email}</Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text size="small">{commercial.email_perso}</Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text size="small">{commercial.phone}</Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text size="small">{commercial.phone_perso}</Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text size="small">
                                                            {this.state.equipes.find(e => commercial.equipe_id === e.id)?.name}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text size="small">
                                                            {commercial.role?.name}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <PrimaryButton
                                                            label="voir"
                                                            onClick={() => this.setState({ selectedCommercial: commercial })}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <PrimaryButton
                                                            label="remplacer deals"
                                                            onClick={() => this.setState({ replacedCommercial: commercial })}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box onClick={() => this.setState({ commercialToDelete: commercial })}><Trash color='brand' /></Box>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
        )
    }
}

const ReplaceCommercial = ({ commerciaux, replacedCommercial, showMessage, onClose }) => {
    const [blocking, setBlocking] = useState(false);
    const [selectedCommerciaux, setSelectedCommerciaux] = useState([]);

    const onChange = async () => {
        setBlocking(true);
        const targets = commerciaux.filter(c => selectedCommerciaux.includes(`${c.prenom} ${c.nom}`));
        try {
            const res = await replaceCommercial(replacedCommercial, targets);
            const message = res.data.length ? res.data.map(c => `${c.name}: ${c.value} deals`).join(', ') : ' 0 deal remplacé';
            showMessage(`Remplacé avec succès : ${message}`, 'success');
            onClose();
        } catch (error) {
            showMessage('Erreur inattendu', 'error');
        }
        setBlocking(false);
    }

    return (
        <BlockUi tag="div" blocking={blocking}>
            <Box width={'large'} pad={'medium'} gap='medium' justify='center' align='center'>
                <Heading level={4} color={'#707070'} textAlign="center">
                    Selectionner un (ou plusieurs) commercial(aux) à remplacer {' '}
                    <span style={{ fontWeight: 'bold' }}>{replacedCommercial.prenom}</span>
                </Heading>
                <Box width={'medium'} pad="small" gap='small'>
                    <CustomSelect
                        options={commerciaux.map(c => ({ ...c, name: `${c.prenom} ${c.nom}` }))}
                        onChange={({ value: nextValue }) => {
                            console.log(nextValue);
                            setSelectedCommerciaux(nextValue);
                        }}
                        multiple
                        labelKey={'name'}
                        valueKey={{ key: 'name', reduce: true }}
                    />
                </Box>
                {selectedCommerciaux.length ?
                    <Box justify='center' width={'medium'}>
                        {selectedCommerciaux.map((c, id) => (
                            <Box direction='row' key={id + 1} gap='small'>
                                <Text size='small'>{`${id + 1}. `}</Text>
                                {' '}
                                <Text size='small'>{c}</Text>
                            </Box>
                        ))}
                    </Box> : null
                }
                {selectedCommerciaux.length ?
                    <Box direction='row' justify='center' align='center' gap='small'>
                        <PrimaryButton label={'Annuler'} onClick={onClose} background={"#6c757d"} />
                        <PrimaryButton label={'Remplacer le commercial ' + replacedCommercial.prenom} onClick={onChange} />
                    </Box> : null
                }
            </Box>
        </BlockUi>
    )
}

const Collaborateur = ({ originalCollab, roles, equipes, onReload, onClose, viewMode, showMessage, commerciaux }) => {

    const [collab, setCollab] = useState(Object.assign({}, originalCollab));
    const [isView, setViewMode] = useState(viewMode);
    const [blocking, setBlocking] = useState(false);

    useEffect(() => {
        setCollab(Object.assign({}, originalCollab));
    }, [originalCollab]);

    const onCollabChange = (field, value) => {
        collab[field] = value;
        setCollab({ ...collab });
    }

    const onSave = async () => {
        let collabToSave = { ...collab };
        if (!collabToSave.role ||
            !collabToSave.civilite ||
            !collabToSave.nom ||
            !collabToSave.prenom ||
            !collabToSave.email ||
            !collabToSave.email_perso ||
            !collabToSave.phone ||
            !collabToSave.phone_perso ||
            !collabToSave.address ||
            !collabToSave.zip ||
            !collabToSave.city ||
            !collabToSave.active_mode
        ) {
            showMessage('Tous les champs sont requis', 'error');
            return;
        }
        if (collabToSave.role.type === 'communication_trustiway' || collabToSave.active_mode === 'Démo') {
            collabToSave.blocked = true;
        }
        collabToSave.role = collabToSave.role.id;
        collabToSave.username = collabToSave.email;
        // verify if email exist
        setBlocking(true);
        if (!collab.id) {
            collabToSave.password = '123456';
            collabToSave.provider = "local";
            let res = await getCollabs({ _q: collab.email });
            if (res.data?.length) {
                showMessage(`Email ${collab.email} a été déjà utilisé`, "error");
                setBlocking(false);
                return;
            }
        }

        saveCollab(collabToSave)
            .then(res => {
                showMessage('Collaborateur a été sauvegardé avec succès', 'success');
                setCollab(res.data);
                onReload();
                setBlocking(false);
                setViewMode(true);
            }).catch(err => {
                showMessage('Erreur inattendu', "error");
                setBlocking(false);
            })
    }

    const getName = (commercial) => commercial ? `${commercial.prenom} ${commercial.nom}` : '';

    return (
        <BlockUi tag="div" blocking={blocking}>
            <Box width={'large'} pad={'medium'} gap='xsmall'>
                <Box justify='center' align='center' pad={'small'}>
                    {isView ?
                        <Text size='xlarge' textAlign='center'><b>Collaborateur</b></Text> :
                        <Text size='xlarge' textAlign='center'><b>{collab?.id ? 'Modifier Collaborateur' : 'Ajouter un collaborateur'}</b></Text>
                    }
                </Box>

                <LineView
                    isView={isView}
                    text={'Civilité'}
                    value={collab.civilite}
                    rightComponent={
                        <ToggleButton
                            groups={
                                ["Monsieur", "Madame"].map(val => ({
                                    label: val,
                                    selected: collab.civilite === val,
                                    onClick: () => onCollabChange('civilite', val),
                                }))
                            }
                        />
                    }
                />
                <LineView
                    isView={isView}
                    text='Prénom'
                    value={collab.prenom}
                    onChange={event => onCollabChange('prenom', event.target.value)}
                />
                <LineView
                    isView={isView}
                    text='Nom'
                    value={collab.nom}
                    onChange={event => onCollabChange('nom', event.target.value)}
                />
                <LineView
                    isView={isView}
                    text='Email perso'
                    value={collab.email_perso}
                    onChange={event => onCollabChange('email_perso', event.target.value)}
                />
                <LineView
                    isView={isView}
                    text='Email pro'
                    value={collab.email}
                    onChange={event => onCollabChange('email', event.target.value)}
                />
                <LineView
                    isView={isView}
                    text='Phone perso'
                    value={collab.phone_perso}
                    onChange={event => onCollabChange('phone_perso', event.target.value)}
                />
                <LineView
                    isView={isView}
                    text='Phone pro'
                    value={collab.phone}
                    onChange={event => onCollabChange('phone', event.target.value)}
                />
                <LineView
                    isView={isView}
                    text='Adresse'
                    value={collab.address}
                    onChange={event => onCollabChange('address', event.target.value)}
                />
                <LineView
                    isView={isView}
                    text='Code postal'
                    value={collab.zip}
                    onChange={event => onCollabChange('zip', event.target.value)}
                />
                <LineView
                    isView={isView}
                    text='Ville'
                    value={collab.city}
                    onChange={event => onCollabChange('city', event.target.value)}
                />
                <LineView
                    isView={isView}
                    text='Rôle du collaborateur'
                    value={collab.role?.name}
                    rightComponent={
                        <CustomSelect
                            placeholder={'Rôle'}
                            options={roles}
                            labelKey={"name"}
                            value={collab.role?.name}
                            valueKey={{ key: "name", reduce: true }}
                            onChange={({ option }) => onCollabChange('role', option)}
                        />
                    }
                />
                <LineView
                    isView={isView}
                    text={'Equipe'}
                    value={getEquipe(collab.equipe_id, equipes)}
                    rightComponent={
                        <CustomSelect
                            placeholder={'Equipe'}
                            options={equipes}
                            labelKey={"name"}
                            value={getEquipe(collab.equipe_id, equipes)}
                            valueKey={{ key: "name", reduce: true }}
                            onChange={({ option }) => onCollabChange('equipe_id', option.id)}
                        />
                    }
                />
                <LineView
                    isView={isView}
                    text={'Activer le compte en mode'}
                    value={collab.active_mode}
                    rightComponent={
                        <ToggleButton
                            groups={["Démo", "Production"].map(val => ({
                                label: val,
                                selected: collab.active_mode === val,
                                onClick: () => onCollabChange('active_mode', val),
                            }))}
                        />
                    }
                />
                {collab.id &&
                    <LineView
                        isView={isView}
                        text={'Binôme'}
                        value={getName(commerciaux.find(c => c.id === collab.binome_id))}
                        rightComponent={
                            <CustomSelect
                                options={commerciaux.filter(c => c.id !== collab.id).map(c => ({ ...c, name: `${c.prenom} ${c.nom}` }))}
                                onChange={({ value: nextValue }) => {
                                    onCollabChange('binome_id', nextValue);
                                }}
                                labelKey={'name'}
                                valueKey={{ key: 'id', reduce: true }}
                                value={commerciaux.find(c => c.id === collab.binome_id)?.id}
                            />
                        }
                    />}
                <Box direction='row' gap='small' width={'full'} justify='center' pad={'medium'}>
                    {!isView && <PrimaryButton
                        label={'Sauvegarder'}
                        onClick={onSave}
                        icon={<Save />}
                    />}
                    {isView &&
                        <PrimaryButton label="Modifier"
                            onClick={() => setViewMode(false)} />}

                    <PrimaryButton
                        label={`Fermer`}
                        icon={<Close />}
                        background={"#6c757d"}
                        onClick={onClose}
                    />
                </Box>
            </Box>
        </BlockUi>
    )
}

const LineView = ({ isView, text, value, onChange, rightComponent }) => {
    return (
        isView ?
            <LineDisplay size='medium'
                field={text}
                value={value}
            /> :
            <CustomLineModification
                text={text}
                size="medium"
                value={value}
                onChange={onChange}
                rightComponent={rightComponent}
            />

    )
}


const BinomeConfig = ({ commerciaux, validate, collab }) => {

    const [values, setValues] = useState({ ...collab });

    useEffect(() => {
        setValues({ ...collab });
    }, [collab]);


    const updateBinome = () => {
        validate(values);
    }

    return (
        <Card width={"full"} justify='center' align='center' pad={"small"} gap='medium' margin={'medium'}>
            <TextLabel variant={"header3"} label={"Configuration de binôme"} />
            <Box direction="row" gap="small">
                <CustomSelect
                    options={commerciaux.map(c => ({ ...c, name: `${c.prenom} ${c.nom}` }))}
                    onChange={({ value: nextValue }) => {
                        values.binome_id = nextValue;
                        setValues({ ...values });
                    }}
                    labelKey={'name'}
                    valueKey={{ key: 'id', reduce: true }}
                    value={values.binome_id}
                />
                <Box direction="row" gap="small">
                    <PrimaryButton icon={<Duplicate />} reverse
                        onClick={() => updateBinome()}
                    />
                </Box>
            </Box>
        </Card>
    )
}

const getEquipe = (id, equipes) => equipes.find(e => e.id === id)?.name;

export default GestionCollab;

import { Box, Heading, Tab, Tabs } from "grommet";
import React from "react";
import Subscriptions from "../../../../souscriptions";
import Sinistres from "../../../../sinistres";
import AgenceInfo from '../agence_info';
import Facturations from "../facturation";
import Comptabilite from "../../../../comptabilites";

const AgenceDashboard = ({ agence, onEdit, sendConvention, showMessage, onEditInfo, commercial, onReload }) => {

    return (
        <Box
            gap="small"
            style={{ width: '100%' }}
        >
            <Box justify="center" alignSelf="center"
                width={{ style: '100%' }}>
                <Box align="center">
                    <Heading
                        level={3}
                    >{`Dashboard ${agence.raisonSocial}`}</Heading>
                </Box>
            </Box>

            <Box justify="center" alignSelf="center" >
                <Tabs>
                    <Tab title="Info agence" >
                        <AgenceInfo agence={agence} 
                            onEdit={onEdit} 
                            sendConvention={sendConvention} 
                            onEditInfo={onEditInfo}
                            commercial={commercial}
                            showMessage={showMessage}
                            onReload={onReload}
                        />
                    </Tab>
                    <Tab title="Souscriptions" >
                        <Subscriptions agence={agence} showMessage={showMessage} />
                    </Tab>
                    <Tab title="Comptabilité" >
                        <Comptabilite agence_domain={agence.domainName} showMessage={showMessage} />
                    </Tab>
                    <Tab title="Sinistres" >
                        <Sinistres agence={agence} showMessage={showMessage} />
                    </Tab>
                    <Tab title="Facturation" >
                        <Facturations agence={agence} showMessage={showMessage} />
                    </Tab>
                </Tabs>

            </Box>

        </Box>
    )
}

export default AgenceDashboard;
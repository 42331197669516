import { Box } from 'grommet';
import { SearchAdvanced, Search as IconSearch } from 'grommet-icons';
import React, { useState } from "react";
import CustomInput from '../../../elements/custom_input';

const Search = ({ search, size }) => {
    const [q, setQ] = useState();

    return (
        <Box direction='row' justify='center' align='center' style={{ background: '#EDEDED' }}
            round={{ size: 'xsmall' }}
        >
            <CustomInput
                size={size}
                style={{ shadow: 'none' }}
                value={q}
                placeholder={'Recherche'}
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                        search(q);
                    }
                }}
                onChange={event => setQ(event.target.value)}
            />
            <Box pad={'xsmall'} onClick={() => search(q)} >
                <IconSearch />
            </Box>
        </Box>
    )
}

export default Search;
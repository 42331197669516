
import React, { useState } from "react";
import { Box, Card, Image, Text, } from "grommet";

import LineModification from "../../elements/line_container/line_modification";
import PrimaryButton from "../../elements/primary_button";
import { Checkmark, Close, Edit, Previous } from "grommet-icons";

const AppComptableInfo = ({
    partenaire,
    onPartenaireChange,
    savePartenaire,
    onClose,
    upload,
    showMessage
}) => {
    const [isView, setView] = React.useState(true);
    const [desLang, setDesLang] = React.useState('');


    const onSubmit = async () => {

        savePartenaire(() => setView(true));
    }

    return (
        <Box
            gap="small"
            width="xlarge"
            alignSelf="center"
            align="center"
            pad="small"
            justify="center"
        >
            <Box direction="row" align="between">
                <Box pad="small" width="xsmall">
                    <PrimaryButton icon={<Previous />} onClick={() => {
                        onClose && onClose();
                    }} />
                </Box>
                <Text size="xxlarge" style={{ padding: 10 }}><b>{partenaire.name}</b></Text>
            </Box>

            <Card gap='small' pad={'small'}>

                <LineModification text="ID" field='id'
                    disabled={true}
                    value={partenaire.id}
                    direction='column'
                    onChange={(field, value) => isView ? null : onPartenaireChange(field, value)}
                />
                <LineModification text="Nom" field='name'
                    disabled={isView}
                    value={partenaire.name}
                    direction='column'
                    onChange={(field, value) => isView ? null : onPartenaireChange(field, value)}
                />
                <LineModification text="Domain Email" field='domain_email'
                    disabled={isView}
                    value={partenaire.domain_email}
                    direction='column'
                    onChange={(field, value) => isView ? null : onPartenaireChange(field, value)}
                />

                <LineModification text="Couleur Principale" field='color_primary'
                    disabled={isView}
                    value={partenaire.color_primary}
                    direction='column'
                    onChange={(field, value) => isView ? null : onPartenaireChange(field, value)}
                />
                <LineModification text="Couleur secondaire" field='color_secondary'
                    disabled={isView}
                    value={partenaire.color_secondary}
                    direction='column'
                    onChange={(field, value) => isView ? null : onPartenaireChange(field, value)}
                />
                <LineModification text="Tarif" field='tarif_parrain'
                    disabled={isView}
                    value={partenaire.tarif_parrain}
                    direction='column'
                    onChange={(field, value) => isView ? null : onPartenaireChange(field, value)}
                />
                {partenaire.logo && <Image src={partenaire.logo.url} height={80} width={100} />}


            </Card>

            {!isView &&
                <Box direction="row" align="center" justify="center" gap="medium" pad={'medium'}>
                    <PrimaryButton
                        label={`Valider`}
                        icon={<Checkmark />}
                        background="#59A4F4"
                        onClick={onSubmit}
                    />
                    <PrimaryButton
                        label={`Annuler`}
                        icon={<Close />}
                        background="#6c757d"
                        onClick={() => setView(true)}
                    />
                </Box>
            }

            {isView &&
                <Box direction="row" align="center" justify="center" gap="medium" pad={'medium'}>
                    <PrimaryButton
                        label={`Modifier`}
                        icon={<Edit />}
                        background='#E82C70'
                        onClick={() => setView(false)}
                    />
                </Box>
            }

        </Box>
    )
}


export default AppComptableInfo;

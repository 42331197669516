const axios = require('axios');
const moment = require('moment');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};


const getGaranties = (startIndex, filter = {}, sort) => {
    let params = {
        _start: startIndex,
        _limit: 50,
    }
    if (sort) {
        params._sort = sort;
    }
    params = buildParams(params, filter);
    return axios.get(`${baseAPI}/voyage_garanties`, { params, headers: HEADERS });
}

const buildParams = (params, filter) => {
    let buildFilter = Object.assign({}, filter);

    let finalFilter = { ...params, ...buildFilter };
    for (let key of Object.keys(finalFilter)) {
        if (!finalFilter[key]) delete finalFilter[key];
    }

    return finalFilter;
}

const createGarantie = (body) => {
    return axios.post(`${baseAPI}/voyage_garanties`, body, { headers: HEADERS });
}

const countGaranties = (filter) => {
    let params =  buildParams(params, filter);
    return axios.get(`${baseAPI}/voyage_garanties/count`, { params, headers: HEADERS });
}

const getCompanies = () => {
    return axios.get(`${baseAPI}/voyage_garanties/companies`, { headers: HEADERS });
}

const getVestute = () => {
    return axios.get(`${baseAPI}/trusti-config?name=Vestute`, { headers: HEADERS });
}

const saveVestuste = (values) => {
    return axios.put(`${baseAPI}/trusti-config/${values.id}`, values, { headers: HEADERS });
}

module.exports = {
    getGaranties,
    createGarantie,
    countGaranties,
    getCompanies,
    getVestute,
    saveVestuste,
}
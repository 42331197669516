import { Box, Card, Heading, Menu, Text } from 'grommet';
import React, { useEffect, useState } from 'react';
import { Checkmark, Close, Duplicate, Edit, Previous, Send } from "grommet-icons";
import { getSimpleDateFR, toCurrency } from '../../../../services/utils';
import { getDateEffet, getFraisDossier, getMHCommission, getPrimeTTC, getTotalTT } from '../../../elements/contract_details';
import Line from '../../../elements/line_container';
import SectionDivider from '../../../voyages/components/session_devider';
import PrimaryButton from '../../../elements/primary_button';
import RecapStatus from '../../../elements/devis_status'
import LineModification from '../../../elements/line_container/line_modification';
import { updateContact } from '../../../../services/contact_service';
import { generateContrat, createNewSubFromCancelledSub } from '../../../../services/mh_enligne_service';
import { clearContextBot, saveContextBot } from '../../../../services/storage_service';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import DealStatusChange from '../../../elements/deal_status_change';
import ApporteurGestionnaire from '../../../elements/apporteur_gestionaire';
import ApporteurModification from '../../../elements/apporteur_gestionaire/modification';
import TrustiBot from '../../../trustibot';
import StripeCustomer, { StripeSubscription, StripeSubscriptionStatus } from '../../../elements/stripe_customer';
import PopupConfirmation from '../../../elements/popup_confirmation';
import { duplicateFromMH } from '../../../../services/trusti_mh_service';

const getPath = model_type => {
    switch (model_type) {
        case "pno_seul":
            return 'pno-seul';
        case 'trusti_mh_tempo':
            return 'trusti-mh-tempo';
        case 'devis_habitation':
            return 'devis-habitation'

        default:
            break;
    }
}

const ContratView = ({ sub, title, assurance_type, showMessage, onClose, onReload, reloadDevis }) => {

    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updateNum, setUpdateNum] = useState(1);
    const [popup, setPopup] = useState(false);

    useEffect(() => {
        saveContextBot(sub);
        setUpdateNum(updateNum + 1);
    }, sub);


    const sendAttestationLocataire = (year) => {
        generateContrat(sub.id, sub.model_type, false, year)
            .then(res => {
                showMessage('Attestations envoyées', 'success');
            }).catch(err => {
                showMessage("Erreur lors de générer le nouveau contrat", 'error');
            })
    }

    const validateClient = client => {
        setLoading(true);
        updateContact(client)
            .then(res => {
                sub.client = res.data;
                generateContrat(sub.id, sub.model_type, true)
                    .then(res => {
                        let files = res.data?.files || [];
                        sub.file1 = files[0]?.url;
                        sub.file2 = files[1]?.url;
                        sub.file3 = files[2]?.url;
                        sub.file4 = files[3]?.url;
                        showMessage('Client mis à jour', 'success');
                        setEditMode(false);
                        onReload();
                        setLoading(false);
                    }).catch(err => {
                        setLoading(false);
                        showMessage("Erreur lors de générer le nouveau contrat", 'error');
                    })
            }).catch(err => {
                setLoading(false);
                console.log(err);
                showMessage("Impossible de mis à jour le client", 'error');
            })
    }

    const createHorsLigneDeal = () => {
        duplicateFromMH(sub.id)
            .then(_ => {
                showMessage("Le deal hors ligne a été créé avec succès", "success");
                setPopup(false);
            })
            .catch(_ => showMessage("Erreur inattendu", "error"));
    }

    const restoreSub = () => {
        setLoading(true);
        createNewSubFromCancelledSub(sub.stripe_subscription_id, sub.id)
            .then(res => {
                const devis = res.data;
                if(devis.id) {
                    showMessage("La souscription a été restaurée avec succès", "success");
                    reloadDevis(res.data);
                } else {
                    showMessage("Souscription introuvable", "error");
                }
                setLoading(false);
            })
            .catch(_ => {
                showMessage("Erreur inattendu", "error");
                setLoading(false);
            });
    }


    return (
        <>
            {popup && <PopupConfirmation 
                close={() => setPopup(false)}
                primaryAction={createHorsLigneDeal}
                primaryActionText={"Créer"}
                message={"Êtes - vous sûr de créer le deal Hors ligne ?"}
                secondaryAction={() => setPopup(false)}
                secondaryActionText={"Annuler"}
            />}
            {editMode ?
                (<BlockUi tag="div" blocking={loading}>
                    <EditMode
                        originalClient={sub.client}
                        onClose={() => setEditMode(false)}
                        onValidate={validateClient}
                    />
                </BlockUi>) :
                (<BlockUi tag="div">
                    <Box pad="small" width="full">
                        <Box pad="small" width="xsmall">
                            <PrimaryButton icon={<Previous />} onClick={() => {
                                clearContextBot();
                                onClose();
                            }} />
                        </Box>
                    </Box>
                    <Box
                        direction="row"
                        gap='small'
                        style={{ width: "100vw" }}
                    >
                        <Box key={updateNum} style={{ width: "50vw" }}>
                            <TrustiBot></TrustiBot>
                        </Box>
                        <Box alignSelf="center" justify="center" align='center'>
                            <Heading level={3}>{title}</Heading>
                            <Box width="large" gap="small" pad={'medium'}>
                                <Card style={{ width: "100%" }} pad="small" gap="small">
                                    <Line leftLabel="ID" rightChildren={<Text size="small">{sub?.id}</Text>} />
                                </Card>
                            </Box>
                            <Box width="large" gap="small" pad={'small'}>
                                <StripeCustomer devisId={sub.id} />
                            </Box>
                            {sub.stripe_sub_status && <Box width="large" gap="small" pad={'small'}>
                                <StripeSubscriptionStatus label={sub.display_status_stripe} link={sub.stripe_subscription_id}
                                    restoreSub={restoreSub}
                                />
                            </Box>}
                            {sub.model_type === 'pno_seul' && <Box width="large" gap="small" pad={'small'}>
                                <StripeSubscription devis={sub} reloadDevis={reloadDevis} />
                            </Box>}
                            <Box width="large" justify="center" gap="small" direction="row" >

                                <Box gap='small' style={{ width: '50%' }}>
                                    <SectionDivider title={'Informations assuré'} />
                                    <Box pad={'xsmall'}>
                                        <LineDisplay field={"Civilité"} value={sub.client?.civilite} />
                                        <LineDisplay field={"Prénom & nom"} value={`${sub.client?.firstname} ${sub.client?.lastname}`} />
                                        <LineDisplay field={"Adresse"} value={`${sub.client?.address}, ${sub.client?.zip}, ${sub.client?.city}`} />
                                        <LineDisplay field={"Mail"} value={sub.client?.email} />
                                        <LineDisplay field={"Tel."} value={sub.client?.phone} />
                                    </Box>
                                </Box>
                                <Box gap='small' style={{ width: '50%' }}>
                                    <SectionDivider title={'Apporteur'} />
                                    <Box pad={'xsmall'}>
                                        <Text size="xsmall" color={'#707070'}>{sub.agence_name || sub.affiliation?.agence_name} </Text>
                                    </Box>
                                    {(sub.apporteur || sub.affiliation) && <Card pad={"small"} width={'xlarge'} >
                                        <ApporteurGestionnaire apporteur={sub.apporteur} affiliation={sub.affiliation} source={sub.source} />
                                    </Card>}
                                </Box>
                            </Box>
                            <SectionDivider title={'Information du bien'} />
                            <Box width="large" gap="small" direction="row" >
                                <Box pad={'xsmall'}>
                                    <LineDisplay field={"Type de risque"} value={sub.residenceType} />
                                    <LineDisplay field={"Type de propriétaire"} value={`${sub.clientType}`} />
                                    <LineDisplay field={"Type de bien"} value={`${sub.bienType}`} />
                                    <LineDisplay field={"Adresse du risque"} value={`${sub.address}, ${sub.zip}, ${sub.city}`} />
                                    <LineDisplay field={"Surface"} value={`${sub.surface} m2`} />
                                    <LineDisplay field={"Nombre de pièces"} value={`${sub.totalRooms}`} />
                                </Box>

                            </Box>
                            <SectionDivider title={'Assureur'} />
                            <Box width="large" justify="center" gap="small">
                                <Box pad='xsmall'>
                                    <LineDisplay field={"Date de souscription"} value={getSimpleDateFR(sub.date_cloture || sub.date_attente_banque)} />
                                    <LineDisplay field={"Date d'effet"} value={getDateEffet(sub)} />
                                    {sub.date_resiliation && <LineDisplay field={"Date de résiliation"} value={getSimpleDateFR(sub.date_resiliation)} />}
                                    {sub.date_fin && <LineDisplay field={"Date de fin"} value={getSimpleDateFR(sub.date_fin)} />}
                                    {sub.rawResponse?.zone &&
                                        <LineDisplay field={'Zonage'} value={sub.rawResponse?.zone} />
                                    }
                                    {(sub.rawResponse?.capital_mobilier || sub.capitalMobilier) &&
                                        <LineDisplay field={'Capital mobilier'} value={(sub.rawResponse?.capital_mobilier || sub.capitalMobilier) + ' €'} />
                                    }
                                    <br />
                                    <LineDisplay field={'Compagnie'} value={sub.compagnie} />
                                    {sub.option &&
                                        <LineDisplay field={'policeIDcompagnie'} value={sub.option === 'Trusti base' ? '1H0331278' : '1H0331277'} />
                                    }
                                    <LineDisplay field={'Type contrat'} value={assurance_type} />
                                    <LineDisplay field={'N° de contrat'} value={sub.contrat_numero || sub.contractNumber} />
                                </Box>
                            </Box>
                            <SectionDivider title={'Comptabilité'} />
                            <Box width="large" justify="center" gap="small">
                                <Box direction="row" gap="medium">
                                    <Box pad={'xsmall'} style={{ width: '30%' }}>
                                        <LineDisplay field={'Total Trustiway'} value={toCurrency(getTotalTT(sub))} />
                                        <LineDisplay field={'Frais de dossier'} value={toCurrency(getFraisDossier(sub))} />
                                        <LineDisplay field={'Commission'} value={toCurrency(getMHCommission(sub))} />

                                        <br />
                                        <LineDisplay field={'Prime TTC'} value={toCurrency(getPrimeTTC(sub))} />
                                        <LineDisplay field={'Prime HT'} value={toCurrency(getPrimeTTC(sub) / 1.09)} />
                                        <LineDisplay field={'Taxe'} value={toCurrency(getPrimeTTC(sub) * (1 - 1 / 1.09))} />
                                    </Box>
                                    <Box pad={'xsmall'}>
                                        <LineDisplay field={'Statut'} value={
                                            <Box width={'small'}><RecapStatus status={sub.status} size='xsmall' /></Box>
                                        } />
                                        <br />
                                        <LineDisplay field={'Attestation'} value={''} />
                                        <LineDisplay field={''} value={sub.file1} />
                                        <LineDisplay field={''} value={sub.file2} />
                                        <LineDisplay field={''} value={sub.file3} />
                                    </Box>
                                </Box>

                            </Box>
                            <ApporteurModification
                                devis={sub}
                                apporteur={sub.apporteur}
                                showMessage={showMessage}
                                onReload={onReload}
                            />
                            <Box pad={'medium'} width='large' gap="small" justify="center" align="center" direction='row'>
                                <Box direction="row" gap="small">
                                    <Menu
                                        dropProps={{
                                            align: { top: 'bottom', left: 'left' },
                                        }}
                                        items={
                                            [
                                                {title: "De l'année en cours", value: "CURRENT_YEAR"}, 
                                                {title: "De l'année prochaine", value: "NEXT_YEAR"}, 
                                            ].map((s, i) => ({
                                                label: <Text size="small" color={'#707070'} key={i}>{s.title}</Text>,
                                                onClick: () => sendAttestationLocataire(s.value)
                                            }))
                                        }
                                    >
                                        <Box direction="row" align="center"
                                            justify="center" gap="small">
                                            <PrimaryButton background='#70A4EC' icon={<Send />} label={"Envoyer l'attestation"} />
                                        </Box>
                                    </Menu>
                                </Box>
                                <Box direction="row" gap="small">
                                    <PrimaryButton size='small' icon={<Edit />} label="Modifier" onClick={() => setEditMode(true)} />
                                </Box>
                                {sub.model_type === 'devis_habitation' && <Box direction="row" gap="small">
                                    <PrimaryButton size='small' icon={<Duplicate />} label="Create Hors ligne deal" onClick={() => setPopup(true)} />
                                </Box>}
                            </Box>
                            <Box pad={'medium'} width='large' gap="small" justify="center" align="center" direction='row'>
                                <Box direction="row" gap="small">
                                    <DealStatusChange
                                        deal={sub}
                                        path={getPath(sub.model_type)}
                                        onReload={onReload}
                                        showMessage={showMessage}
                                    />
                                </Box>

                            </Box>

                        </Box>
                    </Box>
                </BlockUi >)
            }
        </>
    )
}

const EditMode = ({ originalClient, onClose, onValidate }) => {

    const [client, setClient] = useState(Object.assign({}, originalClient));

    const onClientChange = (field, value) => {
        client[field] = value;
        setClient(Object.assign({}, client));
    }

    const validateChange = () => {
        onValidate(client);
    }
    return (
        <Box
            width={'large'}
            justify="center"
            alignContent="center"
            align="center"
            pad='medium'
        >
            <SectionDivider title={'Information assuré'} />
            <Box justify="start" align="start" alignContent="start" alignSelf="start" pad={'small'} gap='xsmall'>
                <LineModification text='Civilité' field='civilite'
                    value={client.civilite}
                    onChange={onClientChange}
                />
                <LineModification text='Prénom' field='firstname'
                    value={client.firstname}
                    onChange={onClientChange}
                />
                <LineModification text='Nom' field='lastname'
                    value={client.lastname}
                    onChange={onClientChange}
                />
                <LineModification text='Adresse' field='address'
                    value={client.address}
                    onChange={onClientChange}
                />
                <LineModification text='Code postal' field='zip'
                    value={client.zip}
                    onChange={onClientChange}
                />
                <LineModification text='Ville' field='city'
                    value={client.city}
                    onChange={onClientChange}
                />
                <LineModification text='Mail' field='email'
                    value={client.email}
                    onChange={onClientChange}
                />
                <LineModification text='Tel.' field='phone'
                    value={client.phone}
                    onChange={onClientChange}
                />

            </Box>


            <Box pad={'medium'} width='large' gap="small" justify="center" align="center">
                <Box direction="row" gap="small">
                    <PrimaryButton size='small' icon={<Checkmark />} label="Validate" onClick={() => validateChange()} />
                    <PrimaryButton background={'#6c757d'} size='small' icon={<Close />} label='Annuler' onClick={() => onClose()} />
                </Box>
            </Box>
        </Box>
    )
}

const LineDisplay = ({ field, value }) => (
    <Text size="xsmall" color={'#707070'}>
        {field ? `${field} : ` : ""}
        <b> {value} </b>
    </Text>
)

export default ContratView;
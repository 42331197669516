import React from "react";
import BlockUi from "react-block-ui";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  Layer
} from "grommet";
import RecapStatus from "../elements/devis_status";
import {
  getApporteurName,
  getDateCurrentStatusField,
  getSimpleDateFR,
  getSimpleDateTimeFR,
} from "../../services/utils";
import CustomSelect from "../elements/select_fill";
import {
  EN_ATTENTE_BANQUE,
  EN_ATTENTE_DE_FORMALITE_MEDICALE,
  EN_ATTENTE_DE_RESILIATION,
} from "../../services/devis_contrat_status";

import { Edit } from "grommet-icons";
import { hasAdminAnalysteRole, hasAdminRole, hasAdministrativeRole, hasClassiqueManagerRole, hasCreditManagerRole, hasPreAnalysteRole } from "../../services/storage_service";
import CommercialDealChange from "../elements/change_commercial";
import Emprunteur from "../ventes/credit/credit/index";
import { getCollabs } from "../../services/admin_service";
import { EN_ETUDE_DE_ANALYSTE, EN_ETUDE_DE_PRE_ANALYSTE } from "../../services/credit_status";
import AnalysteDealChange from "../elements/change_commercial/pre-analyste";

const getPopupContainer = (devis, onClose, refreshStateValue, reloadAll, showMessage) => {
  let name = devis.product;
  console.log(devis.name, "name ", devis);
  switch (name) {
    case "Trusti Crédit":
      return (
        <Emprunteur
          showMessage={showMessage}
          devis={devis}
          onClose={onClose}
          reload={reloadAll}
          reloadDeal={refreshStateValue}
        />
      );
    default:
      return <Box />;
  }
};


const StatusReport = ({ 
  reports, 
  status, 
  commerciaux, 
  all_commerciaux, 
  refreshStateValue, 
  reloadAll, 
  showMessage, 
  nbr_appel, 
  locking, 
  refreshReports,
  administratives
}) => {
  const [open, setOpen] = React.useState(false);
  const [devis, setDevis] = React.useState();
  const [sort, setSort] = React.useState("STATUS_DESC");
  const [selectedDeal, setSelectedDeal] = React.useState();
  const [analysteDeal, setAnalysteDeal] = React.useState();
  const [preAnalysteDeal, setPreAnalysteDeal] = React.useState();
  // const [all_commerciaux, setAllCommerciaux] = React.useState([]);

  // React.useEffect(() => {
  //   getCollabs()
  //     .then(res => setAllCommerciaux(res.data.map(c => ({ id: c.id, name: `${c.prenom} ${c.nom}` }))))
  //     .catch(err => console.log(err));
  // }, []);

  console.log('administratives = ', administratives);

  const _OPTIONS = [
    { name: "Date du status en cours - Croissant", value: "STATUS_ASC" },
    { name: "Date du status en cours - Décroissant", value: "STATUS_DESC" },
    { name: "Date du dernier appel - Croissant", value: "APPEL_ASC" },
    { name: "Date du dernier appel - Décroissant", value: "APPEL_DESC" }
  ];

  return (
    <Box fill >
      {open && (
        <Layer full='horizontal' style={{ overflowY: "scroll" }}>
          {getPopupContainer(devis, setOpen, refreshStateValue, reloadAll, showMessage)}
        </Layer>
      )}
      {selectedDeal && (
        <Layer pad="medium" >
          {/* <Box width="xlarge"> */}
          <Box justify="center" align="center" pad={'medium'}>
            <CommercialDealChange
              commerciaux={commerciaux}
              deal={selectedDeal}
              refreshReports={refreshReports}
              showMessage={showMessage}
              onClose={() => setSelectedDeal(null)}
            />
          </Box>
          {/* </Box> */}
        </Layer>
      )}
      {analysteDeal && (
        <Layer pad="medium" >
          {/* <Box width="xlarge"> */}
          <Box justify="center" align="center" pad={'medium'}>
            <AnalysteDealChange
              commerciaux={(administratives || []).filter(a => !hasPreAnalysteRole(a))}
              deal={analysteDeal}
              refreshReports={refreshReports}
              showMessage={showMessage}
              onClose={() => setAnalysteDeal(null)}
              prop_id={"analyste_id"}
            />
          </Box>
          {/* </Box> */}
        </Layer>
      )}
      {preAnalysteDeal && (
        <Layer pad="medium" >
          {/* <Box width="xlarge"> */}
          <Box justify="center" align="center" pad={'medium'}>
            <AnalysteDealChange
              commerciaux={administratives || []}
              deal={preAnalysteDeal}
              refreshReports={refreshReports}
              showMessage={showMessage}
              onClose={() => setPreAnalysteDeal(null)}
              prop_id={"pre_analyste_id"}
            />
          </Box>
          {/* </Box> */}
        </Layer>
      )}
      <BlockUi
        tag="div"
        blocking={!reports}
        style={{ width: "100%", height: "100%" }}
      >
        <Box gap="small" pad="small" direction="column">
          <Text style={{ fontWeight: "bold" }} size="large" textAlign="center">
            List des {status}
          </Text>
          <Card
            gap="small"
            direction="row"
            justify="around"
            pad="xsmall"
            background={"#fff"}
          >
            <Box>
              <Text textAlign="center" size="small">
                Nombre des {status}
              </Text>
              <Text
                textAlign="center"
                size="small"
                style={{ fontWeight: "bold" }}
              >
                {reports?.length}
              </Text>
            </Box>
            <Box justify="center" alignSefl="center">
              <RecapStatus status={status} />
            </Box>
          </Card>
          <Box justify="end" align="end" direction="row">
            <Card
              pad="xsmall"
              justify="center"
              alignContent="center"
              gap="small"
              background={"#fff"}
              align="center"
              direction="row"
            >
              <Text size="xsmall">Trier par </Text>
              <CustomSelect
                size="xsmall"
                options={_OPTIONS}
                onChange={({ option }) => {
                  setSort(option.value);
                }}
                value={_OPTIONS.filter(e => e.value === sort)[0]["name"]}
                labelKey={"name"}
                valueKey={{ key: "name", reduce: true }}
                style={{ width: 260 }}
              />
            </Card>
          </Box>
          <Box>
            <Table
              fill="horizontal"
              style={{
                width: "100%",
                background: "#fff",
                marginBottom: 0
              }}
            >
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Source
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Apporteur
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Partenaire
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"120px"}>
                    <Text size="small" weight="bold">
                      Commercial
                    </Text>
                  </TableCell>
                  {(status === EN_ETUDE_DE_PRE_ANALYSTE || status === EN_ETUDE_DE_ANALYSTE) && <TableCell scope="col" width={"120px"}>
                    <Text size="small" weight="bold">
                      Pré-Analyse
                    </Text>
                  </TableCell>}
                  {status === EN_ETUDE_DE_ANALYSTE && <TableCell scope="col" width={"120px"}>
                    <Text size="small" weight="bold">
                      Analyse
                    </Text>
                  </TableCell>}
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Création
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Modification
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Assuré
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Produit
                    </Text>
                  </TableCell>
                  <TableCell scope="col" width={"80px"}>
                    <Text size="small" weight="bold">
                      Statut
                    </Text>
                  </TableCell>
                  {nbr_appel &&
                    <TableCell scope="col" width={"80px"}>
                      <Text size="small" weight="bold">
                        Nbr appel
                      </Text>
                    </TableCell>
                  }
                  {nbr_appel &&
                    <TableCell scope="col" width={"80px"}>
                      <Text size="small" weight="bold">
                        Dernier appel
                      </Text>
                    </TableCell>
                  }

                </TableRow>
              </TableHeader>
            </Table>
            <Box
              style={{
                overflowY: "scroll",
                height: 310,
                width: "100%",
                marginTop: 0,
                marginBottom: 0,
              }}
              background={"#fff"}
              fill="horizontal"
            >
              <Table
                fill="horizontal"
                style={{
                  width: "100%",
                }}
              >
                <TableBody>
                  {(reports || [])
                    .sort((s1, s2) => sortReports(s1, s2, sort, status))
                    .map((devis, index) => {
                      // console.log("devis = ", devis);
                      let _client = devis.client || devis.entreprise || {};
                      let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                      return (
                        <TableRow
                          key={index + 1}
                          style={{ backgroundColor: _bgColor, cursor: 'pointer' }}
                          onClick={() => {
                            setDevis(devis);
                            setOpen(true);
                          }}
                        >
                          <TableCell width={"80px"}>
                            <Text size="small">{devis.source} </Text>
                          </TableCell>
                          <TableCell width={"80px"}>
                            <Text size="small">
                              {getApporteurName(devis)}
                            </Text>
                          </TableCell>
                          <TableCell width={"80px"}>
                            <Text size="small">{devis.apporteur?.apporteur_pro?.raisonSocial ?? ''}</Text>
                          </TableCell>
                          <TableCell width={"120px"}>
                            <Text size="small">{all_commerciaux?.find(d => d.id == devis.commercial_id)?.name} {' '}
                              {(hasAdminRole() || hasClassiqueManagerRole()) &&
                                <Edit color="brand"
                                  size="small"
                                  style={{ cursor: 'pointer' }}
                                  onClick={e => {
                                    setSelectedDeal(devis);
                                    e.stopPropagation();
                                  }}
                                />}
                            </Text>
                          </TableCell>
                          {(status === EN_ETUDE_DE_PRE_ANALYSTE || status === EN_ETUDE_DE_ANALYSTE) && <TableCell width={"120px"}>
                            <Text size="small">{administratives?.find(d => d.id == devis.pre_analyste_id)?.name} {' '}
                              {hasAdminAnalysteRole() &&
                                <Edit color="brand"
                                  size="small"
                                  style={{ cursor: 'pointer' }}
                                  onClick={e => {
                                    setPreAnalysteDeal(devis);
                                    e.stopPropagation();
                                  }}
                                />}
                            </Text>
                          </TableCell>}
                          {status === EN_ETUDE_DE_ANALYSTE && <TableCell width={"120px"}>
                            <Text size="small">{administratives?.find(d => d.id == devis.analyste_id)?.name} {' '}
                            {hasAdminAnalysteRole() &&
                                <Edit color="brand"
                                  size="small"
                                  style={{ cursor: 'pointer' }}
                                  onClick={e => {
                                    setAnalysteDeal(devis);
                                    e.stopPropagation();
                                  }}
                                />}
                            </Text>
                          </TableCell>}
                          <TableCell width={"80px"}>
                            <Text size="small">
                              {getSimpleDateFR(devis.createdAt)}
                            </Text>
                          </TableCell>
                          <TableCell width={"80px"}>
                            <Text size="small">
                              {getSimpleDateFR(devis.updatedAt)}
                            </Text>
                          </TableCell>
                          <TableCell width={"80px"}>
                            <Text size="small">
                              {(_client.raisonSocial || _client.raison_social)
                                ? (_client.raisonSocial || _client.raison_social)
                                : `${_client.lastname} ${_client.firstname}`}
                            </Text>
                          </TableCell>
                          <TableCell width={"80px"}>
                            {devis.sub_product_detail ?
                              <Text size="xsmall">{devis.sub_product_detail} </Text> :
                              (devis.sub_product ?
                                <Text size="xsmall">{devis.sub_product} </Text> : null)
                            }
                          </TableCell>
                          <TableCell width={"80px"}>
                            <RecapStatus status={devis.status} size='small' />
                          </TableCell>
                          {nbr_appel &&
                            <TableCell width={"80px"}>
                              <Text size="small">{devis['nbr_appel_' + nbr_appel]} </Text>
                            </TableCell>
                          }
                          {nbr_appel &&
                            <TableCell width={"80px"}>
                              <Text size="small">{getSimpleDateTimeFR(devis['date_dernier_appel'])} </Text>
                            </TableCell>
                          }
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
      </BlockUi>
    </Box>
  );
};

const sortReports = (s1, s2, sort, status) => {
  var prop = getDateCurrentStatusField(s1);
  let _orderBy = sort.indexOf("DESC") >= 0 ? "DESC" : "ASC";

  if (sort.indexOf("APPEL") >= 0) {
    prop = "date_dernier_appel";
  }
  return _orderBy === "ASC"
    ? s1[prop] > s2[prop]
      ? 1
      : -1
    : _orderBy === "DESC"
      ? s1[prop] > s2[prop]
        ? -1
        : 1
      : s1.product > s2.product
        ? 1
        : -1;
};

const hasBPADate = (status) =>
  status === "Devis validé" ||
  status === "Contrat envoyé" ||
  status === "Contrat clôturé" ||
  status === EN_ATTENTE_DE_RESILIATION ||
  status === EN_ATTENTE_BANQUE ||
  status === EN_ATTENTE_DE_FORMALITE_MEDICALE;

export default StatusReport;

export {
  getPopupContainer
}

import {
    Box,
    Button,
    DataTable, Text
} from "grommet";
import React from "react";
import { A_RAPPELER_PLUS_TARD, CONTRAT_CLOTURE, CONTRAT_ENVOYE, DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS, DEVIS_VALIDE, EN_ATTENTE_BANQUE, PROSPECT } from "../../../services/devis_contrat_status";
import SearchComponent from "../search";
import CustomSelect from "../select_fill";

const CustomSearch = ({ query, onQueryChange, onSearch, status, onStatusChange }) => {
    return (
        <Box gap="small" width={'full'}>
            <SearchComponent
                size='small'
                width={'full'}
                search={onSearch}
                query={query}
                updateQuery={value => onQueryChange(value)}
            />
            <Box width={'full'} direction='row' gap="small">
                <CustomSelect
                    size="xsmall"
                    placeholder={'Statut du deal'}
                    options={[
                        PROSPECT,
                        DEAL_POSSIBLE,
                        A_RAPPELER_PLUS_TARD,
                        DEAL_POSSIBLE_REMPLI,
                        DEVIS,
                        DEVIS_VALIDE,
                        CONTRAT_ENVOYE,
                        EN_ATTENTE_BANQUE,
                        CONTRAT_CLOTURE
                    ]}
                    value={status}
                    onChange={({ option }) => onStatusChange(option)}
                />
            </Box>
        </Box>
    )
}

export default CustomSearch;
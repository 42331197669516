import { Box, Grid, Select, Text, TextInput } from "grommet";
import React, { useState } from "react";
import { getContact } from "../../services/contact_service";
import { getSimpleDateFR } from "../../services/utils";
import ChoiceSelect from "../ventes/trusti-per/trusti_per/ChoiceSelect";
import { AddressAutoCompletV2 } from "./address_autocomplete";
import CustomInput from "./custom_input";
import ToggleButton from "./toggle_button/toggle_button";

export const DashboardRecapTitle = ({ title, background, color }) => (
  <Box width="100%" background={background || "background-back"} pad="5px 20px" style={{ borderRadius: "8px" }}>
    <Text weight={600} color={color || 'black'}><b>{title}</b></Text>
  </Box>
);

const ClientLine = ({ leftLabel, rightLabel }) => (
  <Grid
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
    columns={["35%", "65%"]}
  >
    <Box>
      <ClientCustomText label={leftLabel} textAlign="start" />
    </Box>
    <Box>
      <ClientCustomText label={rightLabel} textAlign="start" fontWeight="normal" />
    </Box>
  </Grid>
);

const ClientCustomText = ({ label, textAlign, fontWeight }) => (
  <Text
    style={{ fontWeight: fontWeight || 600 }}
    size="small"
    textAlign={textAlign || "end"}
  >
    {label}
  </Text>
);

export const ClientCard = ({ assure }) => {
  const _cv = assure.civility || assure.civilite;
  return (
    <Box pad="small" width="100%">
      {/* <Box direction="column" width="100%" margin="0 0 20px 0">
        <Text size="small">Indiquer le type de contact</Text>
      </Box> */}
      <Grid gap="small" style={{ width: "100%" }} columns={["1/2", "1/2"]}>
        <Box>
          <ClientLine leftLabel="Civilité" rightLabel={assure.civilite} />
          {_cv !== "Société" && (
            <ClientLine leftLabel="Prénom" rightLabel={assure.firstname} />
          )}
          {_cv !== "Société" && (
            <ClientLine leftLabel="Nom" rightLabel={assure.lastname} />
          )}
          {_cv === "Société" && (
            <ClientLine leftLabel="Raison social" rightLabel={assure.raisonSocial} />
          )}
          <ClientLine leftLabel="Mail" rightLabel={assure.email} />
          <ClientLine leftLabel="Téléphone" rightLabel={assure.phone} />
          <ClientLine leftLabel="Date de naissance" rightLabel={assure.date_of_birth ? getSimpleDateFR(assure.date_of_birth) : ''} />
        </Box>
        <Box>
          <ClientLine
            leftLabel="Adresse"
            rightLabel={`${assure.address}, ${assure.zip}, ${assure.city}`}
          />
        </Box>
      </Grid>
    </Box>
  );
};

const InfoClientCardLine = ({ leftLabel, rightChildren }) => false ?
  (
    <Box
      style={{ width: "100%" }}
      pad='small'
    >
      <Box>
        <InfoClientCardCustomText label={leftLabel ? leftLabel + " :" : ""} textAlign='start' />
      </Box>
      <Box >{rightChildren}</Box>
    </Box>
  ) : (
    <Box
      direction="column"
      style={{ width: "100%" }}
      align="start"
      alignSelf="start"
    >
      <Box style={{ width: "100%" }}>
        <InfoClientCardCustomText textAlign="start" label={leftLabel} />
      </Box>
      <Box style={{ width: "100%" }}>{rightChildren}</Box>
    </Box>
  );
const initContacts = (contact) => {
  if (!contact || !contact.hubspot_contact_id) return [];
  contact.description = `${contact.firstname || ""} ${contact.lastname || ""} (${contact.email
    })`;
  return [contact];
};

const renderContact = (s) =>
  s
    ? `${s.firstname || ""} ${s.lastname || ""} (${s.email
    })`
    : "";

const CIVILITY = {
  madame: "Madame",
  monsieur: "Monsieur",
  societe: "Société",
};

const inputStyle = { background: '#EDEDED', fontSize: 14, fontWeight: 400, borderRadius: 10, border: 'none' };

const InfoClientCardCustomText = ({ label }) => (
  <Text size="small" textAlign="start">
    {label}
  </Text>
)

export const InfoClientCard = ({ devis, updateDevis }) => {
  const _client = devis.client || {};

  const [isContact, setIsContact] = useState(_client.id ? true : false);
  const [contacts, setContacts] = useState(initContacts(_client.id ? _client : null));
  const [contactDesc, setContactDesc] = useState(
    _client.firstname +
    " " +
    _client.lastname +
    " " +
    `(${_client.email || ""})`
  );

  const updateClient = (field, value) => {
    let _client = devis.client;
    _client[field] = value;
    updateDevis("client", _client);
  };

  let timeoutId;


  const searchContact = (query) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      getContact(0, query)
        .then((res) => {
          setContacts(
            res.data.map((s) => {
              s.description = renderContact(s);
              return s;
            })
          );
        })
        .catch((err) => console.log(err));
    }, 500);
  };

  return (
    <>
      <DashboardRecapTitle title="Informations client" />
      <Grid width="calc(100% - 40px)" gap="small" margin="20px 0" columns={["1/2", "1/2"]}>
        {/* <Box gap="small"> */}
        <InfoClientCardLine
          leftLabel="Le client est-il existant ?"
          rightChildren={
            <ChoiceSelect
              choices={["Oui", "Non"]}
              freeGrid
              onChange={(choice) => {
                setIsContact(choice === "Oui");
              }}
            />
          }
        />
        {isContact ? <InfoClientCardLine
          leftLabel="Rechercher contact"
          rightChildren={
            <Select
              options={contacts}
              value={contactDesc}
              labelKey={"description"}
              valueKey={{ key: "description", reduce: true }}
              onChange={({ value: nextValue }) => {
                setContactDesc(nextValue);
                let value = contacts.find((s) => renderContact(s) === nextValue);
                setContactDesc(nextValue);
                // updateClient('client', value)
                updateClient("id", value.id);
                updateClient("civilite", value.civilite);
                updateClient("contact_type", value.contact_type);
                updateClient("hubspot_contact_id", value.hubspot_contact_id);
                updateClient("firstname", value.firstname);
                updateClient("lastname", value.lastname);
                updateClient("email", value.email);
                updateClient("phone", value.phone);
                updateClient("address", value.address);
                updateClient("zip", value.zip);
                updateClient("city", value.city);
                updateClient("intitule", value.intitule);
                updateClient("date_of_birth", value.date_of_birth);
              }
              }
              onSearch={(text) => {
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );
                searchContact(escapedText);
              }}
            />
          }
        /> : <Box></Box>}
        <InfoClientCardLine
          leftLabel="Civilité"
          rightChildren={
            <ToggleButton
              fontSize="14px"
              groups={[
                {
                  label: CIVILITY.madame,
                  selected: _client.civilite === CIVILITY.madame,
                  onClick: () =>
                    updateClient("civilite", CIVILITY.madame),
                },
                {
                  label: CIVILITY.monsieur,
                  selected: _client.civilite === CIVILITY.monsieur,
                  onClick: () =>
                    updateClient("civilite", CIVILITY.monsieur),
                },
              ]}
            />
          }
        />
        <Box></Box>
        <InfoClientCardLine
          leftLabel="Nom"
          rightChildren={
            <CustomInput
              value={_client.lastname}
              onChange={(event) =>
                updateClient("lastname", event.target.value)
              }
            />
          }
        />
        <InfoClientCardLine
          leftLabel="Prénom"
          rightChildren={
            <CustomInput
              value={_client.firstname}
              onChange={(event) =>
                updateClient("firstname", event.target.value)
              }
            />
          }
        />
        <InfoClientCardLine
          leftLabel="Téléphone"
          rightChildren={
            <CustomInput
              value={_client.phone}
              onChange={(event) =>
                updateClient("phone", event.target.value)
              }
            />
          }
        />
        <InfoClientCardLine
          leftLabel="Mail"
          rightChildren={
            <CustomInput
              value={_client.email}
              onChange={(event) =>
                updateClient("email", event.target.value)
              }
            />
          }
        />
        <InfoClientCardLine
          leftLabel="Date de naissance"
          rightChildren={
            <TextInput
              type="date"
              style={inputStyle}
              defaultValue={_client.date_of_birth?.split("T")[0]}
              reverse
              onChange={(event) => {
                updateClient("date_of_birth", event.target.value)
              }}
            />
          }
        />
        <Box></Box>
        {/* </Box> */}
        {/* <Box> */}
        <AddressAutoCompletV2
          contact={_client}
          updateContact={updateClient}
        />
        {/* </Box> */}
      </Grid>
    </>
  )
}
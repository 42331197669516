import { Box } from "grommet";
import { Checkmark, Close, Edit } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { toCurrency } from "../../../../../services/utils";
import { CardContainer, LineTextContainer, TitleContainer } from "../../../../elements/refont/containers/text-containser";
import CustomSelect from "../../../../elements/refont/custom-select";
import { CustomDateInput } from "../../../../elements/refont/date_input";
import CustomTextInput from "../../../../elements/refont/text-input";
import { TextLabel } from "../../../../elements/refont/text-label";

import {
    getEligibleRBT,
    getFranchiseDisplay,
    getFranchiseValue,
    getLocataireRembourseValue,
    getMaximumRemboursement,
    getMonttantPriseEnCharge,
    getNbDatesReloue,
    getPropOuAgenceRembourseType,
    getPropOuAgenceRembourseValue,
    statut
} from '../calcul_remboursement/utils';
import { getSinistreGaranties } from "../../service";

const getImplineReloue = (sinistre) => {
    if (sinistre.nombre_participant_annule && sinistre.nombre_participant !== sinistre.nombre_participant_annule) return "Non";
    return sinistre.reloue;
}

export default function GarantieDommageImmobilier({sinistre, onChangeSubmit }) {
    const [values, setValues] = useState({ ...sinistre, reloue: getImplineReloue(sinistre) });
    const [garanties, setGaranties] = useState({});
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if(values.subscription?.id !== sinistre.subscription?.id || !garanties.garanties) {
            getDefaultGaranties(sinistre.subscription.produit_reference)
        }
        setValues({ ...values });

    }, [sinistre]);

    const getDefaultGaranties = (productRef) => {
        getSinistreGaranties(productRef)
            .then(res => setGaranties(res.data))
            .catch(err => console.error(err));
    }

    const handleReset = () => {
        setValues({ ...sinistre });
        setIsEdit(false);
    }
    const onSinistreChange = (field, value) => {
        values[field] = value;
        setValues({ ...values });
    }

    return (
        <CardContainer>
            <Box direction={'row'} gap={'small'} justify="between" align="center">
                <Box fill background={"white"} direction="column">
                    <Box direction={'row'} gap={'small'} justify="between" align="center">
                        <TextLabel label={"Garanties"} variant={"header3"} />
                    </Box>
                    <Box pad={{ top: '1rem' }}>
                        <TitleContainer>
                            <TextLabel variant={"title1"} label={"Garanties companie"} color={'white'} />
                        </TitleContainer>
                    </Box>
                    <Box pad={{ vertical: '0.5rem' }}>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1-bold"} label={"Compagnie :"} />
                                <CustomTextInput
                                    value={garanties.garanties?.compagny || '-'}
                                />
                            </Box>
                        </LineTextContainer>
                        {garanties.garanties?.garanties?.map((garantie, index) => (
                            <LineTextContainer key={index}>
                                <Box direction="row" align="center" gap="small">
                                    <TextLabel variant={"body1"} label={garantie.name + " :"} />
                                    <CustomTextInput
                                        value={`Plafond : ${toCurrency(garantie.plafond)}  -  Franchise : ${toCurrency(garantie.franchise)}`}
                                    />
                                </Box>
                            </LineTextContainer>
                        ))}
                        
                    </Box>
                    <Box pad={{ top: '0.5rem' }}>
                        <TitleContainer>
                            <TextLabel variant={"title1"} label={"Garanties options"} color={'white'} />
                        </TitleContainer>
                    </Box>
                    <Box pad={{ vertical: '0.5rem' }}>
                        {garanties.options?.map((garantie, index) => (
                            <LineTextContainer key={index}>
                                <Box direction="row" align="center" gap="small">
                                    <TextLabel variant={"body1"} label={garantie.name + " :"} />
                                    <CustomTextInput
                                        value={`Plafond : ${toCurrency(garantie.plafond)}  -  Franchise : ${toCurrency(garantie.franchise)}`}
                                    />
                                </Box>
                            </LineTextContainer>
                        ))}
                    </Box>
                    <Box pad={{ top: '0.5rem' }}>
                        <TitleContainer>
                            <TextLabel variant={"title1"} label={"Garanties paramètres"} color={'white'} />
                        </TitleContainer>
                    </Box>
                    <Box pad={{ vertical: '0.5rem' }}>
                    {garanties.parameters?.map((garantie, index) => (
                            <LineTextContainer key={index}>
                                <Box direction="row" align="center" gap="small">
                                    <TextLabel variant={"body1"} label={garantie.name + " :"} />
                                    <CustomTextInput
                                        value={`Plafond : ${toCurrency(garantie.plafond)}  -  Franchise : ${toCurrency(garantie.franchise)}`}
                                    />
                                </Box>
                            </LineTextContainer>
                        ))}
                    </Box>
                </Box>
            </Box>
        </CardContainer >
    )
}
import { Box, Text, Heading, Button, FileInput, Card, Grid, ResponsiveContext, TextInput, Select } from "grommet";
import React, { useState } from "react";
import ContactSelect, { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import CustomSelect from "../../../elements/select_fill";
import { uploadFile } from "../../../../services/storage_service";
import { DocumentDownload, Trash } from "grommet-icons";
import ToggleButton from "../../../elements/toggle_button/toggle_button";
import QuizSelect from "./QuizSelect";
import ChoiceSelect from "./ChoiceSelect";
import { getContact } from "../../../../services/contact_service";
import { usePlacesWidget } from "react-google-autocomplete";
import { InfoClientCard } from "../../../elements/new_design_elements";
import moment from "moment";

const inputStyle = { background: '#EDEDED', fontSize: 14, fontWeight: 400, borderRadius: 10, border: 'none' };

const CustomInput = ({ value, onChange, placeholder, disabled }) => {
  return (
    <TextInput
      placeholder={placeholder}
      style={{ width: '100%', ...inputStyle }}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

const activeStyle = {
  background: "#59a4f4",
  border: "none",
  cursor: "pointer",
  color: "#fff",
};
const inactiveStyle = {
  background: "#EDEDED",
  border: "none",
  cursor: "pointer",
  color: "#a2a2a3",
};

const PER_OBJECTIF_TYPE = {
  init_period: "Un versement initiale + un versement périodique",
  init: "Un versement initiale seul",
  period: "Un versement périodique seul",
};

const durationOptions = () => {
  var _out = [];
  for (let i = 5; i <= 60; i++) {
    _out.push(i + ` ans`);
  }
  return _out;
};


const doUploadFile = (files, callback, showError) => {
  const fileList = files;
  if (fileList) {
    for (let i = 0; i < fileList.length; i += 1) {
      const file = fileList[i];
      console.log(file.name);
      uploadFile(file)
        .then((result) => {
          console.log("result = ", result.data);
          callback(result.data);
        })
        .catch((err) => {
          showError("Erreur inattendu télécharger.");
          callback([]);
        });
    }
  }
};

const onAmountChanged = (event, callback, showError, callbackNoChanged) => {
  if (!event.target.value) {
    callback(0);
    return;
  }
  event.target.value = event.target.value
    .replace(/[^0-9.,]/g, "")
    .replace(/,/g, ".")
    .replace(/(\..*?)\..*/g, "$1");
  if (event.target.value.slice(-1) === ".") {
    // skip callback to next step
    callbackNoChanged(event.target.value);
    return;
  }
  const _arrA = event.target.value.split(".");
  if (_arrA.length > 1) {
    if (_arrA[1].replace(/0/g, "") === "") {
      // skip callback to next step
      callbackNoChanged(event.target.value);
      return;
    }
  }
  const value = parseFloat(event.target.value || "0");
  if (isNaN(value)) {
    showError("Valeur non valid");
    return;
  } else {
    callback(value);
  }
};

const InfoTitle = ({ title }) => (
  <Box width="100%" background="background-back" pad="5px 20px" style={{ borderRadius: "8px" }}>
    <Text size="small" weight={700}>{title}</Text>
  </Box>
);

const FormTitle = ({ title }) => (
  <Box margin="30px 0 10px 0">
    <Text weight={600} size="16px" color="#484848">{title}</Text>
    <Box
      width="50px" height="3px" background="bluebrand"
    />
  </Box>
);

const InLineComponent = ({ left, right }) => {
  return (
    <Box direction="row" gap="large" align="center" width={'full'}>
      <Box style={{ width: '65%' }} >
        {left}
      </Box>
      <Box width={'full'}>
        {right}
      </Box>
    </Box>
  )
}


const Info = ({ devis, updateDevis, toRecap, showMessage }) => {
  const [hasLoan, setHasLoan] = useState(false);
  if (!devis.effectiveDate) {
    devis.effectiveDate = new Date();
  }

  return (
    <ResponsiveContext.Consumer>
      {size =>
      (
        <Box
          gap="small"
          style={{ width: "100%" }}
          alignSelf="center"
          align="center"
          justify="center"
          width="large"
        >
          <Box justify="center" alignSelf="center">
            <Heading level={3} textAlign="center">Trusti PER</Heading>
          </Box>

          <InfoClientCard devis={devis} updateDevis={updateDevis} />

          <InfoTitle title="Information vente" />

          <Box width="100%" pad="small">
            <FormTitle title="Situation familiale" />

            <ChoiceSelect
              title="Sélectionner votre situation familiale et maritale"
              choices={["Célibataire", "Marié(e)", "Pacsé(e)", "En concubinage", "Divorcé(e)", "Veuf(ve)", "Séparé(e) de corps"]}
              freeGrid
              onChange={(choice) => {
                updateDevis("familial_situation", choice)
              }}
              default={devis.familial_situation}
            />
            <ChoiceSelect
              title="Combien avez vous d'enfant(s) à charge ?"
              choices={["Aucun", "1", "2", "3", "4 ou plus"]}
              freeGrid
              onChange={(choice) => {
                updateDevis("children_count", choice)
              }}
              default={devis.children_count}
            />

            <FormTitle title="Situation professionnelle" />

            <ChoiceSelect
              title="Quelle est votre situation professionnelle ?"
              choices={["Actif(ve)", "Indépendant(e)/Libéral(e) (TNS)", "Retraité(e)", "Étudiant(e)", "Cumul emploi-retraite", "En recherche d'emploi", "Sans profession", "Inactif (autre)"]}
              freeGrid
              onChange={(choice) => {
                updateDevis("profession_situation", choice)
              }}
              default={devis.profession_situation}
            />
            <Text weight={400} size={"14px"} margin="10px 0 10px 0">Quels sont les revenus de votre foyer ?</Text>
            <Box direction="row" align="center">
              <Box width="30%">
                <TextInput
                  type="number"
                  style={inputStyle}
                  icon={<span>€</span>}
                  defaultValue={devis.revenue_annuel}
                  reverse
                  onChange={(event) =>
                    updateDevis("revenue_annuel", event.target.value)
                  }
                />
              </Box>
              <Text size="xsmall" style={{ flexShrink: 1 }} margin="0 0 0 20px">Revenu brut annuel</Text>
            </Box>

            <FormTitle title="Type de résidence" />

            <ChoiceSelect
              title="Quels est votre situation actuelle ?"
              choices={["Propriétaire", "Locataire", "Hébergé(e)"]}
              icons={["🏠", "🔑", "🏢"]}
              freeGrid
              onChange={(choice) => {
                updateDevis("location_situation", choice)
              }}
              default={devis.location_situation}
            />
            {devis.location_situation === "Locataire" && <>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">Quel est votre loyer actuel ?</Text>
              <Box direction="row" align="center" width="30%">
                <TextInput
                  type="number"
                  style={inputStyle}
                  icon={<span>€</span>}
                  defaultValue={devis.rent_amount}
                  reverse
                  onChange={(event) =>
                    updateDevis("rent_amount", event.target.value)
                  }
                />
              </Box>
            </>}

            <FormTitle title="Patrimoine" />

            <ChoiceSelect
              title="Renseigner la valeur de votre patrimoine immobilier"
              choices={["Moins de 100 000 €", "De 100 000 € à 500 000 €", "De 500 000 € à 800 000 €", "De 800 000 € à 1 000 000 €", "1 000 000 € et plus"]}
              onChange={(choice) => {
                let newPatrimoines = devis.patrimoines;
                if(!newPatrimoines) {
                  newPatrimoines = [];
                  devis.patrimoines = [];
                }
                const p = newPatrimoines?.find((p) => p.label === "Immobilier");
                if (p) {
                  p.value = choice
                } else {
                  newPatrimoines.push({ label: "Immobilier", value: choice })
                }
                updateDevis("patrimoines", newPatrimoines)
              }}
              default={devis.patrimoines?.find((p) => p.label === "Immobilier")?.value}
            />

            <ChoiceSelect
              title="Renseigner la valeur de votre patrimoine financier"
              choices={["Moins de 50 000 €", "De 50 000 € à 100 000 €", "De 100 000 € à 500 000 €", "De 500 000 € à 950 000 €", "950 000 € et plus"]}
              onChange={(choice) => {
                const newPatrimoines = devis.patrimoines;
                const p = newPatrimoines?.find((p) => p.label === "Financier");
                if (p) {
                  p.value = choice
                } else {
                  newPatrimoines.push({ label: "Financier", value: choice })
                }
                updateDevis("patrimoines", newPatrimoines)
              }}
              default={devis.patrimoines?.find((p) => p.label === "Financier")?.value}
            />

            <FormTitle title="Situation financière" />

            <Text weight={400} size={"14px"} margin="10px 0 10px 0">Avez vous des emprunts en cours de remboursement ?</Text>

            <Box direction="row" gap="small" align="center">
              <ToggleButton size={size}
                fontSize="14px"
                groups={[
                  {
                    label: "Oui",
                    selected: hasLoan === true,
                    onClick: () =>
                      setHasLoan(true),
                  },
                  {
                    label: "Non",
                    selected: !hasLoan,
                    onClick: () =>
                      setHasLoan(false),
                  },
                ]}
              />

              {hasLoan && <Box width="30%">
                <TextInput
                  type="number"
                  style={inputStyle}
                  icon={<span>€</span>}
                  defaultValue={devis.loan_period}
                  reverse
                  onChange={(event) =>
                    updateDevis("loan_period", event.target.value)
                  }
                />
              </Box>}

              <Text size="xsmall">
                Renseigner le montant total de vos emprunts
              </Text>
            </Box>

            <FormTitle title="Projection départ à la retraite" />

            <Text weight={400} size={"14px"} margin="10px 0 10px 0">Quel serait l'âge souhaité de votre départ à la retraite</Text>
            <Box direction="row" align="center" width="50%">
              <TextInput
                type="number"
                style={inputStyle}
                icon={<span>An(s)</span>}
                defaultValue={devis.retirement_age}
                reverse
                onChange={(event) =>
                  updateDevis("retirement_age", event.target.value)
                }
              />
            </Box>

            <FormTitle title="Définition du projet" />

            <Grid columns={["1/2", "1/2"]} gap="small">
              <Box>
                <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner le montant de votre premier versement</Text>
                <TextInput
                  type="number"
                  style={inputStyle}
                  icon={<span>€</span>}
                  defaultValue={devis.first_payment}
                  reverse
                  onChange={(event) =>
                    updateDevis("first_payment", event.target.value)
                  }
                />
              </Box>
              <Box>
                <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner le montant de vos versements mensuels</Text>
                <TextInput
                  type="number"
                  style={inputStyle}
                  icon={<span>€</span>}
                  defaultValue={devis.monthly_payment}
                  reverse
                  onChange={(event) =>
                    updateDevis("monthly_payment", event.target.value)
                  }
                />
              </Box>
            </Grid>

            <FormTitle title="Date d'effet souhaité" />


            <Grid columns={["1/2", "1/2"]} gap="small">
              <TextInput
                type="date"
                style={inputStyle}
                defaultValue={devis.date_effet?.split("T")[0]}
                reverse
                onChange={(event) =>
                  updateDevis("date_effet", event.target.value)
                }
              />
            </Grid>

            <FormTitle title="Date de résiliation" />


            <Grid columns={["1/2", "1/2"]} gap="small">
              <TextInput
                type="date"
                style={inputStyle}
                defaultValue={devis.date_resiliation ? moment(devis.date_resiliation).toISOString().split("T")[0] : undefined}
                reverse
                onChange={(event) =>
                  updateDevis("date_resiliation", event.target.value)
                }
              />
            </Grid>

            <Grid columns={["1/2", "1/2"]} gap="small">
              <Box>
                <FormTitle title="Préférence gestion" />
                <Select
                  options={["Libre", "Gestion pilotée Loi Pacte"]}
                  value={devis.preference_gestion}
                  size="small"
                  onChange={({ option }) => updateDevis("preference_gestion", option)}
                />
              </Box>
              <Box>
                <FormTitle title="Profil investisseur" />
                <Select
                  options={["Prudent", "Équilibre", "Dynamique"]}
                  value={devis.profil_investisseur}
                  size="small"
                  onChange={({ option }) => updateDevis("profil_investisseur", option)}
                />
              </Box>
              <Box>
                <FormTitle title="Option de sortie" />
                <Select
                options={
                  devis.profession_situation === "Indépendant(e)/Libéral(e) (TNS)" ?
                  ["Rente à terme (irrevocable)", "Rente et/ou Capital à terme (choix à la liquidation)"] :
                  ["Rente à terme (irrevocable)" ]}
                  value={devis.exit_option}
                  size="small"
                  onChange={({ option }) => updateDevis("exit_option", option)}
                />
              </Box>
              <Box>
                <FormTitle title="Taux de sortie partielle en rente" />
                <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner en pourcentage ce que vous souhaitez sortir en rente de votre PER</Text>
                <TextInput
                  type="number"
                  style={inputStyle}
                  icon={<span>%</span>}
                  defaultValue={devis.exit_rate}
                  reverse
                  onChange={(event) =>
                    updateDevis("exit_rate", event.target.value)
                  }
                />
              </Box>
              <Box>
                <FormTitle title="Taux indexation cotisations" />
                <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner en pourcentage l'évolution de vos primes versées jusqu'à votre départ en retraite</Text>
                <TextInput
                  type="number"
                  style={inputStyle}
                  icon={<span>%</span>}
                  defaultValue={devis.index_rate}
                  reverse
                  onChange={(event) =>
                    updateDevis("index_rate", event.target.value)
                  }
                />
              </Box>
            </Grid>
          </Box>

          <InfoTitle title="Questions légales" />

          <Box width="100%" pad="small">
            <Text size="small" margin="0 0 20px">Vous y êtes presque !</Text>
            <Text size="small">Conformément à la réglementation, nous devons évaluer vos connaissances financières.</Text>
            <Text size="small">Vos réponses ne sont pas bloquantes pour la suite.</Text>

            <QuizSelect
              title="Supposons que votre investissement perde 10% en 6 mois, comment réagissez-vous ?"
              choices={["A - J'effectue un nouveau virement", "B - Je récupère une partie du montant investi", "C - Je récupère la totalité du montant investi", "D - Je patiente sans paniquer"]}
              onChange={(choice) => {
                updateDevis("quiz_1", choice)
              }}
              default={devis.quiz_1}
            />

            <QuizSelect
              title="Sur l'assurance-vie, le capital placé est bloqué pendant 8 ans ?"
              choices={["A - Vrai", "B - Faux", "C - Je ne sais pas"]}
              onChange={(choice) => {
                updateDevis("quiz_2", choice)
              }}
              default={devis.quiz_2}
            />

            <QuizSelect
              title="Un tracker (ETF ou fonds indiciel) est un placement à capital garanti ?"
              choices={["A - Vrai", "B - Faux", "C - Je ne sais pas"]}
              onChange={(choice) => {
                updateDevis("quiz_3", choice)
              }}
              default={devis.quiz_3}
            />

            <QuizSelect
              title="Via un mandat d'arbitrage, vous déléguez les décisions d'investissement à Trustiway ?"
              choices={["A - Vrai", "B - Faux", "C - Je ne sais pas"]}
              onChange={(choice) => {
                updateDevis("quiz_4", choice)
              }}
              default={devis.quiz_4}
            />

            <QuizSelect
              title="Avez-vous déjà investi dans des actions ou des obligations, en direct ou via des fonds d'investissement (SICAV, FCP ou ETF) ?"
              choices={["A - Oui", "B - Non"]}
              onChange={(choice) => {
                updateDevis("quiz_5", choice)
              }}
              default={devis.quiz_5}
            />
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Info;


import React, { useState } from "react";
import { Box, Card, Text, } from "grommet";
import { SmallLine } from "../../elements/line_container";
import { getClientAddress } from "../../outils/client_util";
import ToggleButton from "../../elements/toggle_button/toggle_button";
import { saveAppUser } from "../service";
import { getApporteurPros } from "../../apporteur_pro/service";

class AppUserInfo extends React.Component {
    state = {
        user: {}
    };
    constructor(props) {
        super(props);
        // console.log(props);
        this.state = {
            indicator: undefined,
            user: props.user || {},
            partenaires: []
        }
    }

    componentDidMount() {
        getApporteurPros(0, null).then(res => this.setState({ partenaires: res.data })).catch(err => console.log(err));
    }

    getPartenaire = ()=>{
        let domainEmail = this.props.user?.email?.split('@').pop();
        let p = this.state.partenaires.filter(e => e.domainName === domainEmail || e.domain_email === domainEmail);
        if(p && p.length > 0){
            let _p = p[0];
            return _p.raisonSocial;
        }
        return '';
    }

    onValidateUser = async (validate) => {
        try {
            await saveAppUser({ user_id: this.state.user.id, app_validated: validate });
            this.props.showMessage && this.props.showMessage('Succès', 'success');
            this.setState({ user: { ...this.state.user, app_validated: validate } });
        } catch (error) {
            this.props.showMessage && this.props.showMessage(error, 'error');
        }
    }

    render() {
        console.log(this.props.user);
        return (
            <Box
                gap="small"
                width="xlarge"
                alignSelf="center"
                align="center"
                pad="small"
                justify="center"
            >
                <Card pad={'small'} width='large' gap={'small'}>
                    <Text size="large" ><b>Informations d'utilisateur</b></Text>

                    <Box gap='small' direction="row">
                        <SmallLine leftLabel={'Nom'} bold={true}
                            rightChildren={<Text size="small" >{this.state.user.nom}</Text>}
                        />
                        <SmallLine leftLabel={'Prénom'} bold={true}
                            rightChildren={<Text size="small" >{this.state.user.prenom}</Text>}
                        />
                    </Box>

                    <SmallLine leftLabel={'Adresse'} bold={true}
                        rightChildren={<Text size="small" >{getClientAddress(this.state.user)}</Text>}
                    />
                    <SmallLine leftLabel={'Email'} bold={true}
                        rightChildren={<Text size="small" >{this.state.user.email}</Text>}
                    />
                    <SmallLine leftLabel={'Partenaire'} bold={true}
                        rightChildren={<Text size="small" >{this.getPartenaire()}</Text>}
                    />
                    <SmallLine leftLabel={'Code parrain'} bold={true}
                        rightChildren={<Text size="small" >{this.state.user.code_parrain}</Text>}
                    />
                </Card>
                <Card pad={'small'} width='large' gap={'small'}>
                    <Text size="large" ><b>Informations rémunération</b></Text>
                    <SmallLine leftLabel={'IBAN'} bold={true}
                        rightChildren={<Text size="small">{this.state.user.iban}</Text>}
                    />
                    <SmallLine leftLabel={'BIC'} bold={true}
                        rightChildren={<Text size="small">{this.state.user.bic}</Text>}
                    />

                </Card>
                <Card pad={'small'} width='large' gap={'small'}>
                    <Text size="large" ><b>Validation</b></Text>

                    <SmallLine leftLabel={'Validé le compte'} bold={true}
                        rightChildren={<ToggleButton
                            groups={
                                [false, true].map(val => ({
                                    label: val ? 'Oui' : 'Non',
                                    selected: this.state.user.app_validated === val,
                                    onClick: () => this.onValidateUser(val),
                                }))
                            }
                        />}
                    />

                </Card>

            </Box>
        )
    }
}


export default AppUserInfo;

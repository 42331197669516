import React from "react";
import { Box, Heading, Text, Card, Stack } from "grommet";
import { getSimpleDateFR } from "../../../../services/utils";
import RecapStatus from "../../../elements/devis_status";
import { DocumentDownload } from "grommet-icons";
import StatusDateAppel from "../../../elements/status_date_appel";
import PanelAppel from "../../../elements/panel_appel";
import EmailEventTracker from "../../../elements/email_tracker";
import StripeCustomer from "../../../elements/stripe_customer";
import CodePromo from "../../../elements/code_promo";
import ApporteurGestionnaire from "../../../elements/apporteur_gestionaire";
import TrustiBot from "../../../trustibot";
import DateRecap from "../../../elements/date_recap";

const Recap = ({ devis, toEditMode, close, onSave, sendForm, updateDevis }) => {
  return (
    <Box
      direction="row"
      gap='small'
      style={{ width: "100vw" }}
    >
      <TrustiBot></TrustiBot>
      <Box
        gap="medium"
        // style={{ width: "100%" }}
        align="center"
        alignSelf="center"
        pad="small"
      >
        <Heading level="2" color="#59A4F4" textAlign="center">
          Trusti Chien / Chat
        </Heading>
        <Box direction="row" fill="horizontal" justify="center" alignContent="center">
          <Box align="center">
            <StatusDateAppel devis={devis} />
          </Box>
          <Box>
            <Box alignSelf="end">
              <RecapStatus status={devis.status} />
              <PanelAppel devis={devis} updateDevis={updateDevis} onSave={onSave} />
            </Box>
          </Box>
        </Box>
        <Box  gap="small" width={'full'} justify='center' align="center">
          <Card  pad="small" gap="small" width={'full'} >
            <Text textAlign="center"><b>ID:</b> {devis.id}</Text> 
          </Card>
        </Box>
        <Box  gap="small" width={'full'}>
          <DateRecap deal={devis} />
        </Box>
        <CodePromo devis={devis} />
        <Box width={'xlarge'}>
          <StripeCustomer devisId={devis.id} />
        </Box>
        <Box width="xlarge" gap="small">
          <Box width="xlarge" direction="row" gap="small" justify="center">
            <Card pad="small" width="100%">
              <Assure assure={devis.client || {}} />
            </Card>
            <Card pad="small" width="100%">
              <Assurance devis={devis} />
            </Card>
          </Box>
        </Box>

        <Box width="xlarge" direction="row" gap="small">
          <Card style={{ width: "100%" }} pad="medium">
            {devis.file_tableau_amortissement && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_tableau_amortissement}`}
              >
                <DocumentDownload />
                Tableau d’amortissement
              </a>
            )}
            {devis.file_offre_de_pret && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_offre_de_pret}`}
              >
                <DocumentDownload />
                Offre de prêt
              </a>
            )}
            {devis.file_devis && (
              <a target={"_blank"} rel="noreferrer" href={`${devis.file_devis}`}>
                <DocumentDownload />
                Devis
              </a>
            )}
            {devis.file_rib && (
              <a target={"_blank"} rel="noreferrer" href={`${devis.file_rib}`}>
                <DocumentDownload />
                RIB
              </a>
            )}
            {devis.file_contrat && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_contrat}`}
              >
                <DocumentDownload />
                Contrat
              </a>
            )}
            {devis.file_contrat_signed && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_contrat_signed}`}
              >
                <DocumentDownload />
                Contrat signé
              </a>
            )}
            {devis.file_others ? (
              devis.file_others.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                    <DocumentDownload />
                    {e && e.split("/").pop()}
                  </a>
                );
              })
            ) : (
              <></>
            )}
          </Card>
        </Box>
        {(devis.apporteur || devis.affiliation) && <Card pad={"small"} width={'xlarge'} >
          <ApporteurGestionnaire apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
        </Card>}
        <Box width={'xlarge'}>
          <EmailEventTracker messageIds={devis.emailEventIds} />
        </Box>
        <Box style={{ height: 40 }} />
      </Box>
    </Box>
  );
};

const Assurance = ({ devis }) => {
  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label="Informations de l'assurance" />
      <Line leftLabel="Animal" rightLabel={devis.animal_type} />
      <Line leftLabel="Nom" rightLabel={devis.name} />
      <Line leftLabel="Genre" rightLabel={devis.genre} />
      <Line
        leftLabel="Date de naissance"
        rightLabel={getSimpleDateFR(devis.pet_date_of_birth)}
      />
      <Line leftLabel="Race" rightLabel={devis.race} />
      {devis.animal_type === "Chien" && (
        <Line
          leftLabel="Aveugle ou handichien"
          rightLabel={
            devis.handicape === true
              ? "Oui"
              : devis.handicape === false
                ? "Non"
                : ""
          }
        />
      )}
      <Line leftLabel="Couverture" rightLabel={devis.assurance_type} />
    </Box>
  );
};

const CustomText = ({ label, textAlign, isBold }) => (
  <Text
    style={{ fontWeight: isBold ? "bold" : "normal" }}
    size="small"
    textAlign={textAlign || "end"}
  >
    {label}
  </Text>
);
const Line = ({ leftLabel, rightLabel }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "50%" }}>
      <CustomText label={leftLabel + " :"} />
    </Box>
    <Box style={{ width: "50%" }}>
      <CustomText label={rightLabel} textAlign="start" isBold={true} />
    </Box>
  </Box>
);
const Assure = ({ assure }) => {
  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label={`Informations de l'assuré`} />
      <Line leftLabel="Civilité" rightLabel={assure.civilite} />
      {assure.civilite !== "Société" && (
        <Line leftLabel="Prénom" rightLabel={assure.firstname} />
      )}
      {assure.civilite !== "Société" && (
        <Line leftLabel="Nom" rightLabel={assure.lastname} />
      )}
      {assure.civilite === "Société" && (
        <Line leftLabel="Raison social" rightLabel={assure.raisonSocial} />
      )}
      <Line leftLabel="Mail" rightLabel={assure.email} />
      <Line leftLabel="Tel" rightLabel={assure.phone} />
      <Line
        leftLabel="Adresse postale"
        rightLabel={`${assure.address}, ${assure.zip}, ${assure.city}`}
      />
      <Line
        leftLabel="Date de naissance"
        rightLabel={`${assure.date_of_birth ? getSimpleDateFR(assure.date_of_birth) : ""
          }`}
      />
    </Box>
  );
};
const SubHeading = ({ label }) => (
  <Text textAlign="center" style={{ fontWeight: "bold" }}>
    {label}
  </Text>
);

export default Recap;

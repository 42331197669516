import { Box, Card, FileInput, Text } from "grommet";
import React, { useState } from "react";
import { DocumentDownload } from "grommet-icons";
import Line from "../../../elements/line_container";
import DateInput from "../../../elements/date_input";
import CustomInput from "../../../elements/custom_input";
import { uploadFile } from "../../../../services/storage_service";
import CustomSelect from "../../../elements/select_fill";

const doUploadFile = (files, callback, showError) => {
  const fileList = files;
  if (fileList) {
    for (let i = 0; i < fileList.length; i += 1) {
      const file = fileList[i];
      console.log(file.name);
      uploadFile(file)
        .then((result) => {
          console.log("result = ", result.data);
          callback(result.data);
        })
        .catch((err) => {
          showError("Erreur inattendu télécharger.");
          callback([]);
        });
    }
  }
};

const BanqueInfo = ({
  devis,
  updateDevis,
  onNext,
  contact,
  showMessage,
}) => {
  const [assures, setAssures] = useState(devis?.emprunteurs || [{}]);
  const [isLoading, setIsLoading] = useState(false);


  return (
    <Box
      gap="small"
      width="xlarge"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >

      <Box width="large" gap="xsmall">
        <Text
          style={{ fontSize: 20, padding: 20, fontWeight: "bold" }}
          textAlign="start"
        >Informations de l’offre</Text>
        <Line
          leftLabel={'Produit'}
          rightChildren={
            <CustomSelect
              size={'small'}
              options={[
                "Crédit Immobilier",
                "Regroupement de crédit",
                "Crédit à la consommation",
                "Prêt hypothécaire",
              ]}
              value={devis.sub_product}
              onChange={({ option }) => updateDevis('sub_product', option)}
            />
          }
        />
        <Line
          leftLabel="Banque"
          rightChildren={
            <CustomInput
              value={devis.banque}
              onChange={(ev) => updateDevis("banque", ev.target.value)}
            />
          }
        />
        <Line
          leftLabel="Montant emprunté"
          rightChildren={
            <CustomInput
              value={devis.montant}
              onChange={(ev) => updateDevis("montant", ev.target.value)}
            />
          }
        />
        <Line
          leftLabel="Nombre d’année"
          rightChildren={
            <CustomInput
              value={devis.duree}
              onChange={(ev) => updateDevis("duree", ev.target.value)}
            />
          }
        />
        <Line
          leftLabel="Taux"
          rightChildren={
            <CustomInput
              value={devis.taux}
              onChange={(ev) => updateDevis("taux", ev.target.value)}
            />
          }
        />

      </Box>

      <Box width="large" gap="xsmall">
        <Text
          style={{ fontSize: 20, padding: 20, fontWeight: "bold" }}
          textAlign="start"
        >Comptabilité</Text>
        <Line
          leftLabel="Frais de dossier"
          rightChildren={
            <CustomInput
              value={devis.frais_dossier}
              onChange={(ev) => updateDevis("frais_dossier", ev.target.value)}
            />
          }
        />
        <Line
          leftLabel="Commission"
          rightChildren={
            <CustomInput
              value={devis.commission_total}
              onChange={(ev) => updateDevis("commission_total", ev.target.value)}
            />
          }
        />
        <Line
          leftLabel="Fractionnement"
          rightChildren={
            <CustomInput
              value={devis.fractionnement}
              onChange={(ev) => updateDevis("fractionnement", ev.target.value)}
            />
          }
        />
        <Line
          leftLabel="Mensualité des frais de dossier"
          rightChildren={
            <CustomInput
              value={devis.frais_dossier_mensuel}
              onChange={(ev) => updateDevis("frais_dossier_mensuel", ev.target.value)}
            />
          }
        />
        <Line
          leftLabel="Date du début prélèvement"
          rightChildren={
            <DateInput
              value={devis.date_effet || null}
              onChange={(date) => updateDevis("date_effet", date)}
            />
          }
        />
        <Line
          leftLabel="Date de résiliation"
          rightChildren={
            <DateInput
              value={devis.date_resiliation || null}
              onChange={(date) => updateDevis("date_resiliation", date)}
            />
          }
        />
      </Box>

      <Box width="large" gap="xsmall">
        <Card margin={"small"} pad={"medium"} gap="small">
          <Card align="center" pad="small">
            <strong>Télécharger les documents</strong>
            <br />
            Glissez-déposez des fichiers ici
            <Box direction="column" pad="small" gap="small">
              <Box direction="row" pad="small" gap="large">
                {devis.file_offre_de_pret && (
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={`${devis.file_offre_de_pret}`}
                  >
                    <DocumentDownload />
                    Offre de prêt
                  </a>
                )}
                <FileInput
                  messages={{
                    browse: "Choisir",
                    dropPrompt: "Offre de prêt",
                  }}
                  multiple={false}
                  onChange={(event) => {
                    setIsLoading(true);
                    doUploadFile(
                      event.target.files,
                      async (data) => {
                        if (data.length > 0) {
                          let _uploaded = data[0];
                          updateDevis("file_offre_de_pret", _uploaded.url);
                        }
                        setIsLoading(false);
                      },
                      showMessage
                    );
                  }}
                />
              </Box>
              <Box direction="row" pad="small" gap="large">
                {devis.file_tableau_amortissement && (
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={`${devis.file_tableau_amortissement}`}
                  >
                    <DocumentDownload />
                    Tableau d’amortissement
                  </a>
                )}
                <FileInput
                  messages={{
                    browse: "Choisir",
                    dropPrompt: "Tableau d’amortissement",
                  }}
                  multiple={false}
                  onChange={(event) => {
                    setIsLoading(true);
                    doUploadFile(
                      event.target.files,
                      (data) => {
                        if (data.length > 0) {
                          let _uploaded = data[0];
                          updateDevis(
                            "file_tableau_amortissement",
                            _uploaded.url
                          );
                        }
                        setIsLoading(false);
                      },
                      showMessage
                    );
                  }}
                />
              </Box>
            </Box>
          </Card>
        </Card>
      </Box>


    </Box>
  );
};

export default BanqueInfo;

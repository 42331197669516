import React from "react";
import { Box, Button, Card, Image, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { getAppConfig, getAppComptables, getRemunerations } from "../service";
import { getMonthOptions, getSimpleDateFR, getSorted } from "../../../services/utils";
import Refresh from "../../elements/refresh";
import SearchComponent from "../../elements/search";
import DateSort from "../../elements/date_sort";
import CustomSelect from '../../elements/select_fill';
import { getClientName } from "../../outils/client_util";
import moment from 'moment';

class AppComptable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appComptables: [],
      popupOpen: false,
      selectedPartenaire: {},
      totalCommissionNetwork: 0,
      totalCommission: 0,
      totalCommissionPaid: 0,
      totalCommissionPayoutRequested: 0,
      numContrats: 0,
      startDate: moment(new Date()).startOf('month').toDate(),
      endDate: new Date(),
      query: null,
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined,
      }
    };
  }

  componentDidMount = () => {
    this.loadAppComptables();
  };

  loadAppComptables = (_startDate, _endDate, _query = null) => {
    if (!_startDate || !_endDate) {
      _startDate = this.state.startDate;
      _endDate = this.state.endDate;
    }
    this.setState({ appComptables: [] }, () => {
      getRemunerations(_startDate, _endDate, _query)
        .then((res) => {
          this.setState({
            appComptables: res.data.filter(e => e.type !== 'PayOut'),
            totalCommissionNetwork: res.data.reduce((sum, value) => {
              return value.level === 0 ? (parseFloat(`${value.product?.com_network}`) || 0) + sum : sum;
            }, 0) || 0,
            totalCommission: res.data.reduce((sum, value) => {
              return value.type !== 'PayOut' ? value.amount + sum : sum;
            }, 0),
            totalCommissionPaid: res.data.reduce((sum, value) => {
              return value.type === 'PayOut' && value.status === 'Confirmé' ? value.amount + sum : sum;
            }, 0),
            totalCommissionPayoutRequested: res.data.reduce((sum, value) => {
              return value.type === 'PayOut' && value.status !== 'Confirmé' ? value.amount + sum : sum;
            }, 0),
            numContrats: res.data.filter(e => e.type !== 'PayOut' && e.level === 0).length
          });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false });
  };

  openPartenaire = (partenaire) => {
    this.setState({ selectedPartenaire: partenaire, popupOpen: true });
  };

  addPartenaire = () => this.openPartenaire({});

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }


  render() {
    console.log("this.state.totalCommissionNetwork=",this.state.totalCommissionNetwork);
    return (
      <Box style={{ width: "100%" }} margin={{ top: "medium" }}
        align="center" justify="center"
        alignSelf="center"
        alignContent="center" gap="medium">
        <Text size="xlarge" style={{ padding: 10 }}><b>Comptabilité</b></Text>
        <Box width="xlarge" gap="medium">
          <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
            <Refresh refresh={this.loadAppComptables} />
            <Box direction='row' gap='small'
              alignSelf="center" >
              <Box pad="xsmall" justify="center" alignContent="center"
                gap="small"
                align="center" direction="row">
                <Text size="xsmall">Select d'un mois</Text>
                <CustomSelect
                  options={getMonthOptions().filter(
                    (s) => s.name !== "12 derniers mois"
                  )}
                  labelKey={"name"}
                  valueKey={{ key: "name", reduce: true }}
                  placeholder='mois en cours'
                  defaultValue={getMonthOptions()[0]}
                  onChange={({ option }) => {
                    this.setState({
                      startDate: option.startDate,
                      endDate: option.endDate,
                      query: null
                    });
                    this.loadAppComptables(option.startDate, option.endDate);
                  }}
                />
              </Box>
            </Box>
            <SearchComponent
              search={value => {
                this.setState({
                  query: value
                });
                console.log("query = ", value);
                this.loadAppComptables(this.state.startDate, this.state.endDate, value);
              }}
              query={this.state.query}
              updateQuery={value => this.setState({ query: value })}
            />
            <DateSort
              onChange={value => this.updateFilter('sort', value)}
            />
          </Box>

          <Box direction="row" align="center" justify="center" alignSelf="center" alignContent="center">
            <Card pad='small' margin="small" align="center" justify="center" alignSelf="center" alignContent="center">
              <Text size="small">Total commission réseau</Text>
              <Text weight={'bold'}>{`${(this.state.totalCommissionNetwork || 0)?.toFixed(2)} €`}</Text>
            </Card>
            <Card pad='small' margin="small" align="center" justify="center" alignSelf="center" alignContent="center">
              <Text size="small">Total commission</Text>
              <Text weight={'bold'}>{`${this.state.totalCommission.toFixed(2)} €`}</Text>
            </Card>
            <Card pad='small' margin="small" align="center" justify="center" alignSelf="center" alignContent="center">
              <Text size="small">Total commission demandé</Text>
              <Text weight={'bold'}>{`${this.state.totalCommissionPayoutRequested.toFixed(2)} €`}</Text>
            </Card>
            <Card pad='small' margin="small" align="center" justify="center" alignSelf="center" alignContent="center">
              <Text size="small">Total commission payé</Text>
              <Text weight={'bold'}>{`${this.state.totalCommissionPaid.toFixed(2)} €`}</Text>
            </Card>
            <Card pad='small' margin="small" align="center" justify="center" alignSelf="center" alignContent="center">
              <Text size="small">Nb contrat</Text>
              <Text weight={'bold'}>{`${this.state.numContrats}`}</Text>
            </Card>
          </Box>

          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Text>Création/<br />Modification</Text>
                </TableCell>
                <TableCell>
                  <Text>Client</Text>
                </TableCell>
                <TableCell><Text>Apporteur</Text></TableCell>
                <TableCell><Text>Utilisateur</Text></TableCell>
                <TableCell>
                  <Text>Produit</Text>
                </TableCell>
                <TableCell><Text>Niveau</Text></TableCell>
                <TableCell>
                  <Text color={'green'} weight='bold'>Commission apporteur</Text>
                </TableCell>
                <TableCell>
                  <Text color={'green'} weight='bold'>Commission réseau</Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getSorted(this.state.appComptables, this.state.filter.sort).map((appComptable, index) => (
                <TableRow key={index + 1}>
                  <TableCell>
                    <Text size="small"><strong>{getSimpleDateFR(appComptable.createdAt)}</strong><br />{getSimpleDateFR(appComptable.updatedAt)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{getClientName(appComptable.client)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appComptable.apporteur?.prenom} {appComptable.apporteur?.nom}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appComptable.user?.prenom} {appComptable.user?.nom}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appComptable.produit}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appComptable.level}</Text>
                  </TableCell>
                  <TableCell>
                    <Text color={'green'} weight='bold'>{appComptable.amount}€</Text>
                  </TableCell>
                  <TableCell>
                    {appComptable.level === 0 && <Text color={'green'} weight='bold'>{(parseFloat(appComptable.product?.com_network || "0"))?.toFixed(2)}€</Text>}
                  </TableCell>
                  {/*                   
                  <TableCell>
                    <Button
                      label="voir"
                      primary
                      onClick={() => this.openPartenaire(appComptable)}
                    />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  }
}



export default AppComptable;

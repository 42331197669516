import { Box, FileInput, Text, TextArea } from 'grommet';
import { AddCircle, CaretPrevious, Save } from 'grommet-icons';
import React from "react";
import { uploadFile } from '../../../../services/storage_service';
import AddressAutoComplet from "../../../elements/address_autocomplete";
import CustomInput from '../../../elements/custom_input';
import DateInput from '../../../elements/date_input';
import PrimaryButton from '../../../elements/primary_button';
import CustomSelect from '../../../elements/select_fill';
import StandardText from "../../../elements/standard_text";
import ToggleButton from '../../../elements/toggle_button/toggle_button';
import SectionDivider from "../../../voyages/components/session_devider";

const doUploadFile = (files, callback, showError) => {
    const fileList = files;
    if (fileList) {
        for (let i = 0; i < fileList.length; i += 1) {
            const file = fileList[i];
            console.log(file.name);
            uploadFile(file)
                .then((result) => {
                    console.log("result = ", result.data);
                    callback(result.data);
                })
                .catch((err) => {
                    showError("Erreur inattendu télécharger.");
                    callback([]);
                });
        }
    }
};

const Info = ({ deal, onDealChange, showMessage, prev, save }) => {

    return (
        <Box
            style={{ width: "100%" }}
            justify="center"
            alignContent="center"
            align="center"
        >
            <Box
                width={'large'}
                justify="center"
                alignContent="center"
                align="center"
                gap="medium"
            >

                <Box >
                    <SectionDivider title={'Description de vos locaux'} />
                </Box>

                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>

                    <AddressAutoComplet
                        contact={deal}
                        updateContact={onDealChange}
                        direction='in-line'
                    />
                    <LotAdding lots={deal.lots} onDealChange={onDealChange} />

                    <LineToggle label={'Présence façade en mur rideaux'}
                        groups={["Oui", "Non"].map(value => (
                            {
                                label: value,
                                selected: deal.facade_mur_rideau === value,
                                onClick: () => onDealChange('facade_mur_rideau', value),
                            }
                        ))}
                    />

                    <LineToggle label={'Souhaitez-vous modifier le déclaratif pour le lot assuré ?'}
                        groups={["Oui", "Non"].map(value => (
                            {
                                label: value,
                                selected: deal.modifier_declaratif === value,
                                onClick: () => onDealChange('modifier_declaratif', value),
                            }
                        ))}
                    />
                    {deal.modifier_declaratif === 'Oui' ?
                        <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                            <LineToggle label={'Les bâtiments sont-ils construits et couverts en matériaux dur pour plus de 75%'}
                                groups={["Oui", "Non"].map(value => (
                                    {
                                        label: value,
                                        selected: deal.materiaux_dur === value,
                                        onClick: () => onDealChange('materiaux_dur', value),
                                    }
                                ))}
                            />
                            <LineToggle label={'es lots sont-ils occupés'}
                                groups={["Oui", "Non"].map(value => (
                                    {
                                        label: value,
                                        selected: deal.lots_occupes === value,
                                        onClick: () => onDealChange('lots_occupes', value),
                                    }
                                ))}
                            />
                            <LineToggle label={'Les bâtiments sont-ils en cours de réhabilitation'}
                                groups={["Oui", "Non"].map(value => (
                                    {
                                        label: value,
                                        selected: deal.encours_rehabilitation === value,
                                        onClick: () => onDealChange('encours_rehabilitation', value),
                                    }
                                ))}
                            />
                            <LineToggle label={'Les bâtiments sont-ils soumis à un arrêté de péril'}
                                groups={["Oui", "Non"].map(value => (
                                    {
                                        label: value,
                                        selected: deal.arrete_peril === value,
                                        onClick: () => onDealChange('arrete_peril', value),
                                    }
                                ))}
                            />
                            <LineToggle label={'Les bâtiments sont-ils un château, un manoir, un risque classé ou répertorié'}
                                groups={["Oui", "Non"].map(value => (
                                    {
                                        label: value,
                                        selected: deal.risque_classe === value,
                                        onClick: () => onDealChange('risque_classe', value),
                                    }
                                ))}
                            />
                            <LineToggle label={'Les lots sont-ils occupés par une activité aggravante ?'}
                                groups={["Oui", "Non"].map(value => (
                                    {
                                        label: value,
                                        selected: deal.occupe_aggravant === value,
                                        onClick: () => onDealChange('occupe_aggravant', value),
                                    }
                                ))}
                            />
                        </Box> :
                        <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                            <LineDisplay label={"Les bâtiments sont-ils construits et couverts en matériaux dur pour plus de 75%"}
                                value={deal.materiaux_dur}
                            />
                            <LineDisplay label={"Les lots sont-ils occupés"}
                                value={deal.lots_occupes}
                            />
                            <LineDisplay label={"Les bâtiments sont-ils en cours de réhabilitation"}
                                value={deal.encours_rehabilitation}
                            />
                            <LineDisplay label={"Les bâtiments sont-ils soumis à un arrêté de péril"}
                                value={deal.arrete_peril}
                            />
                            <LineDisplay label={"Les bâtiments sont-ils un château, un manoir, un risque classé ou répertorié"}
                                value={deal.risque_classe}
                            />
                            <LineDisplay label={"Les lots sont-ils occupés par une activité aggravante ?"}
                                value={deal.occupe_aggravant}
                            />
                        </Box>
                    }
                </Box>
                <Box >
                    <SectionDivider title={'Antécédents du site'} />
                </Box>
                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                    <LineToggle label={'Votre précédent contrat a-t-il été résilié par le précédent assureur ?'}
                        groups={["Oui", "Non"].map(value => (
                            {
                                label: value,
                                selected: deal.resilie_assureur === value,
                                onClick: () => onDealChange('resilie_assureur', value),
                            }
                        ))}
                    />
                    {deal.resilie_assureur === 'Oui' &&
                        <LineSelect label="Motif de résiliation"
                            value={deal.motif_resiliation}
                            options={["Sinistre", "Non paiement", "Aggravation du risque", "Autres motif"]}
                            onDealChange={option => onDealChange('motif_resiliation', option)}
                        />
                    }
                    <Box width={'full'}>
                        <StandardText style={{ fontWeight: 'bold' }} size='xsmall' label={"Prévention innondation"} />
                    </Box>
                    <LineToggle label={"L’ensemble des canalisations équipées de clapets anti-retours"}
                        size='xsmall'
                        groups={["Oui", "Non"].map(value => (
                            {
                                label: value,
                                selected: deal.canalisations_equipees === value,
                                onClick: () => onDealChange('canalisations_equipees', value),
                            }
                        ))}
                    />
                    <LineToggle label={"Tableau électrique et compteur installés à plus de 1,20 m"}
                        size='xsmall'
                        groups={["Oui", "Non"].map(value => (
                            {
                                label: value,
                                selected: deal.compteur_installe_plus_12 === value,
                                onClick: () => onDealChange('compteur_installe_plus_12', value),
                            }
                        ))}
                    />
                    <LineToggle label={"Equipements collectifs (chauffage, téléphone, climatisation) protégés de l’eau"}
                        size='xsmall'
                        groups={["Oui", "Non"].map(value => (
                            {
                                label: value,
                                selected: deal.equipements_proteges === value,
                                onClick: () => onDealChange('equipements_proteges', value),
                            }
                        ))}
                    />
                    <LineToggle label={"Dispositif de protection des sous-sols et RDC"}
                        size='xsmall'
                        groups={["Oui", "Non"].map(value => (
                            {
                                label: value,
                                selected: deal.protection_sous_sol === value,
                                onClick: () => onDealChange('protection_sous_sol', value),
                            }
                        ))}
                    />
                </Box>
                <Box >
                    <SectionDivider title={'Les capitaux assurés & Engagement'} />
                </Box>
                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>

                    <LineInput label="Valeur du mobilier à usage collectif "
                        value={deal.valeur_mobilier}
                        placeholder='€'
                        onDealChange={val => onDealChange('valeur_mobilier', val)}
                    />
                </Box>
                <Box >
                    <SectionDivider title={'Franchise'} />
                </Box>
                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                    <LineSelect label="Franchises dommages"
                        value={deal.franchise_dommage}
                        options={["0 €", "300 €", "600 €", "1200 €"]}
                        onDealChange={option => onDealChange('franchise_dommage', option)}
                    />
                    <Box pad={'small'}>
                        <FileInput
                            size={'xxsmall'}
                            messages={{
                                browse: "Choisir",
                                dropPrompt: "Ajouter un autre document",
                            }}
                            multiple={false}
                            onChange={(event) => {
                                doUploadFile(
                                    event.target.files,
                                    async (data) => {
                                        if (data.length > 0) {
                                            let _uploaded = data[0];
                                            let _files = deal.file_others || [];
                                            _files.push(_uploaded.url);
                                            onDealChange("file_others", _files);
                                        }
                                    },
                                    mes => showMessage(mes, 'error')
                                );
                            }}
                        />
                    </Box>
                </Box>
                <Box >
                    <SectionDivider title={'Date de résiliation '} />
                </Box>
                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>

                    <Line
                        left={<StandardText size={'small'} label={"Date de résiliation"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_resiliation}
                                onChange={(date) => onDealChange("date_resiliation", date)}
                            />
                        }
                    />
                </Box>

                <Box pad={'medium'} direction='row' gap="medium">
                    < PrimaryButton icon={<CaretPrevious />} label='Retour' background={'#707070'} onClick={prev} />
                    < PrimaryButton icon={<Save />} label='Enregistrer' onClick={save} />
                </Box>
            </Box>
        </Box >
    )
}

const LineDisplay = ({ label, value }) => (
    <Box gap="xxsmall" direction='row' width={'full'}>
        <StandardText size={'small'} label={label + ' : '} />
        <StandardText size={'small'} style={{ fontWeight: 'bold' }} label={value} />
    </Box>
)

const Line = ({ left, right }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                {left}
            </Box>
            <Box width={'full'}>
                {right}
            </Box>
        </Box>
    )
}

const LineInput = ({ label, placeholder, value, onDealChange }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <CustomInput size='xsmall' placeholder={placeholder}
                    value={value}
                    onChange={event => onDealChange(event.target.value)}
                />
            </Box>
        </Box>
    )
}

const LineSelect = ({ label, options = [], value, onDealChange }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <CustomSelect
                    size={'small'}
                    options={options}
                    value={value}
                    onChange={({ option }) => onDealChange(option)}
                />
            </Box>
        </Box>
    )
}

const LineToggle = ({ label, groups = [], size = 'small' }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={size} label={label} />
            </Box>
            <Box width={'full'}>
                <ToggleButton
                    groups={groups}
                />
            </Box>
        </Box>
    )
}

const LotAdding = ({ lots, onDealChange }) => {

    const onLotChange = (lot, field, value) => {
        lot[field] = value;
        let _lots = lots;
        if (!_lots) _lots = [lot];
        onDealChange('lots', _lots.map(l => l));
    }

    const onAddingLot = () => {
        let _lots = lots;
        if (!_lots) _lots = [{}];
        _lots.push({});
        onDealChange('lots', _lots.map(l => l));
    }

    return (
        <Box width={'full'} gap='xsmall'>
            {(lots || [{}]).map((lot, index) => (
                <Box width={'full'} gap='xsmall' key={index}>
                    <Box width={'full'}>
                        <StandardText style={{ fontWeight: 'bold' }} label={`Lot N° ${index + 1}`} />
                    </Box>
                    <LineInput label="Superficie des locaux"
                        value={lot.surface_local}
                        placeholder='m2'
                        onDealChange={val => onLotChange(lot, 'surface_local', val)}
                    />
                    <LineSelect label="Sélectionner un étage"
                        value={lot.etage}
                        options={["Rez-de-chaussée",
                            "1er Étage",
                            "Supérieur au 1er étage"]}
                        onDealChange={option => onLotChange(lot, 'etage', option)}
                    />
                    <LineInput label="Précision de l’activité"
                        value={lot.precision_activite}
                        onDealChange={val => onLotChange(lot, 'precision_activite', val)}
                    />
                </Box>
            ))}
            <Box width={'full'} onClick={onAddingLot} direction='row' gap='small'>
                <AddCircle color='red' />
                <StandardText size={'small'} label='Ajouter un lot' />
            </Box>
        </Box>
    )
}

export default Info;
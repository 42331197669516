
import { Box, FileInput } from 'grommet';
import { Close, DocumentDownload, Save } from "grommet-icons";
import React, { useState } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { createContact, updateContact } from "../../../../services/contact_service";
import { uploadFile } from '../../../../services/storage_service';
import CustomInput from "../../../elements/custom_input";
import PrimaryButton from "../../../elements/primary_button";
import CustomSelect from '../../../elements/select_fill';
import StandardText from "../../../elements/standard_text";
import ToggleButton from "../../../elements/toggle_button/toggle_button";
import SectionDivider from "../../../voyages/components/session_devider";
import { createEntreprise, getEntreprises, updateEntreprise } from "../service";
import { Interlocuteurs } from "./info";

const lead_status = [
    { key: "NEW", value: "Nouveau" },
    { key: "OPEN", value: "Ouvert" },
    { key: "IN_PROGRESS", value: "En cours" },
    { key: "OPEN_DEAL", value: "Transaction ouverte" },
    { key: "UNQUALIFIED", value: "Non qualifié" },
    { key: "ATTEMPTED_TO_CONTACT", value: "Tentative de contact" },
    { key: "CONNECTED", value: "Connecté" },
    { key: "BAD_TIMING", value: "Mauvais timing" },
];

const findLeadStatusKey = (status) => {
    let lead = lead_status.find(
        (k) => k.value.toUpperCase() === (status || "").toUpperCase()
    );
    return lead ? lead.key : "";
};

const findLeadStatusValue = (status) => {
    if (!status) return null;
    let lead = lead_status.find(
        (k) => k.key.toUpperCase() === (status || "").toUpperCase()
    );
    return lead ? lead.value : "";
};
const initEntreprises = entreprise => {
    if (!entreprise) return [];
    entreprise.description = `${entreprise.raison_social} (${entreprise.email})`
    return [entreprise];
}
const doUploadFile = (files, callback, showError) => {
    const fileList = files;
    if (fileList) {
        for (let i = 0; i < fileList.length; i += 1) {
            const file = fileList[i];
            console.log(file.name);
            uploadFile(file)
                .then((result) => {
                    console.log("result = ", result.data);
                    callback(result.data);
                })
                .catch((err) => {
                    showError("Erreur inattendu télécharger.", "error");
                    callback([]);
                });
        }
    }
};

class SelectEntreprise extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            entreprise: Object.assign({}, this.props.entreprise || {}),
            originalEntreprise: this.props.entreprise || {},
            existing: this.props.entreprise?.id ? true : false,
            entreprises: initEntreprises(this.props.entreprise)
        }
    }

    onChange = (field, value) => {
        let en = this.state.entreprise;
        en[field] = value;
        this.setState({ entreprise: en });
    }

    submit = async () => {
        let entreprise = this.state.entreprise;

        this.setState({ loading: true });
        let interlocuteurs = entreprise.interlocuteurs || [];

        let tmp = interlocuteurs.map(s => s);
        let interlocuteurIds = [];

        for (let inter of interlocuteurs) {
            if (!inter.id && typeof inter === 'object') {
                try {
                    let res = await createContact(inter);
                    interlocuteurIds.push(res.data.id);
                } catch (err) {
                    let body = err.response.data || {};
                    let message = body.err === 'USER_EXISTING' ? 'Un utilisateur existant avec email ' + inter.email :
                        body.err === 'ACCOUNT_WITH_SAME_EMAIL_EXISTING' ? `Un souscription en direct compte existant avec email ${inter.email}` :
                            body.err === 'EMAIL_NOT_VALID' ? 'Email non valid' : 'Erreur inattendu';
                    this.props.showMessage(message, 'error');
                    entreprise.interlocuteurs = tmp;
                    console.log(err);
                    this.setState({ loading: false });
                }
            } else if (typeof inter === 'object') {
                interlocuteurIds.push(inter.id);
            } else {
                interlocuteurIds.push(inter);
            }
        }

        entreprise.interlocuteurs = interlocuteurIds;

        if (!entreprise.id) {
            try {
                let res = await createEntreprise(entreprise);
                this.props.showMessage('Entreprise créée/modifié avec succès', 'success');
                this.setState({ entreprise: res.data, loading: false });
                if (this.props.onSubmit) {
                    this.props.onSubmit(res.data);
                }
            } catch (err) {
                let body = err.response.data || {};
                let message = body.err === 'USER_EXISTING' ? 'Un entreprise existant avec email ' + entreprise.email :
                    body.err === 'ACCOUNT_WITH_SAME_EMAIL_EXISTING' ? `Un souscription en direct compte existant avec email ${entreprise.email}` :
                        body.err === 'EMAIL_NOT_VALID' ? 'Email non valid' : 'Erreur inattendu';
                this.props.showMessage(message, 'error');
                entreprise.interlocuteurs = tmp;
                console.log(err);
                this.setState({ loading: false });
            }
        } else {
            this.setState({ entreprise, loading: false });
            if (this.props.onSubmit) {
                this.props.onSubmit(entreprise);
            }
        }

    }

    cancel = () => {
        this.setState({ entreprise: Object.assign({}, this.state.originalEntreprise) });
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box
                    style={{ width: "100%" }}
                    justify="center"
                    alignContent="center"
                    align="center"
                    pad='medium'
                >
                    <Box
                        width={'large'}
                        justify="center"
                        alignContent="center"
                        align="center"
                        gap="medium"
                    >
                        <Box >
                            <StandardText style={{ fontWeight: 'bold' }} label='Renseigner les informations de la société' />
                            <SectionDivider />
                        </Box>
                        <Box gap="xxsmall" align="center" width={'full'}>

                            <Line
                                left={<StandardText size={'small'} label="L'entreprise est-il créé ?" />}
                                right={
                                    <ToggleButton
                                        groups={[true, false].map(value => (
                                            {
                                                label: value ? 'Oui' : 'Non',
                                                selected: this.state.existing === value,
                                                onClick: () => { value ? this.setState({ existing: value }) : this.setState({ existing: value, entreprise: {} }) },
                                            }
                                        ))}
                                    />
                                }
                            />
                            {this.state.existing &&
                                <ExistingEntreprise
                                    originalEntreprises={this.state.entreprises}
                                    value={this.state.entreprise}
                                    onSelect={entreprise => this.setState({ entreprise })}
                                />


                            }
                            <CustomizedInputLine
                                label={'Nom de la société'}
                                value={this.state.entreprise.raison_social}
                                field='raison_social'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <CustomizedInputLine
                                label={'Numéro de SIRET'}
                                value={this.state.entreprise.siret}
                                field='siret'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <Line left={<StandardText size={'small'} label="Type de société" />}
                                right={
                                    <CustomSelect
                                        disabled={this.state.existing}
                                        size={'small'}
                                        options={[
                                            "Classique",
                                            "Voyage",
                                            "Apporteur My Trustiway"
                                        ]}
                                        value={this.state.entreprise.type_societe}
                                        onChange={({ value: nextValue }) => this.onChange('type_societe', nextValue)}
                                    />
                                }
                            />
                            <CustomizedInputLine
                                label={'Téléphone'}
                                value={this.state.entreprise.phone}
                                field='phone'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <CustomizedInputLine
                                label={'Email'}
                                value={this.state.entreprise.email}
                                field='email'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <CustomizedInputLine
                                label={'Adresse principale'}
                                value={this.state.entreprise.address}
                                field='address'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <CustomizedInputLine
                                label={'Code postal'}
                                value={this.state.entreprise.zip}
                                field='zip'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <CustomizedInputLine
                                label={'Ville'}
                                value={this.state.entreprise.city}
                                field='city'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <LineWithText leftLabel="Indice de confiance sur le seller"
                                right={
                                    <CustomSelect size='small'
                                        disabled={this.state.existing}
                                        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                        value={this.state.entreprise.indice_de_confiance_sur_le_seller}
                                        onChange={({ option }) =>
                                            this.onChange("indice_de_confiance_sur_le_seller", option)
                                        }
                                    />
                                }
                            />
                            <LineWithText leftLabel="Statut du lead"
                                right={
                                    <CustomSelect
                                        disabled={this.state.existing}
                                        options={lead_status.map((l) => l.value)}
                                        value={findLeadStatusValue(this.state.entreprise.statut_du_lead)}
                                        onChange={({ option }) =>
                                            this.onChange("statut_du_lead", findLeadStatusKey(option))
                                        }
                                    />
                                }
                            />
                            <LineWithText
                                leftLabel="Ne pas envoyer email"
                                right={
                                    <ToggleButton
                                        disabled={this.state.existing}
                                        size={'xsmall'}
                                        groups={[
                                            {
                                                label: '❌ Ne pas envoyer email',
                                                selected: this.state.entreprise.do_not_emailing === true,
                                                onClick: () => this.onChange("do_not_emailing", true),
                                            },
                                            {
                                                label: '✅ Email OK',
                                                selected: this.state.entreprise.do_not_emailing !== true,
                                                onClick: () => this.onChange("do_not_emailing", false),
                                            },
                                        ]}
                                    />
                                }
                            />


                        </Box>

                        <Box >
                            <StandardText style={{ fontWeight: 'bold' }} label="Renseigner les informations de l'interlocuteur" />
                            <SectionDivider />
                        </Box>
                        <Box gap="xxsmall" align="center" width={'full'}>
                            <Interlocuteurs
                                disabled={this.state.existing}
                                interlocuteurs={this.state.entreprise.interlocuteurs || []}
                                onEntrepriseChange={this.onChange}
                                showMessage={this.props.showMessage}
                            />
                        </Box>
                    </Box>
                    {this.state.existing ?

                        <Box pad={'small'} gap='small'>
                            {this.state.entreprise.file_kbis && (
                                <a target={"_blank"} rel="noreferrer" href={`${this.state.entreprise.file_kbis}`}>
                                    <DocumentDownload />
                                    KBIS
                                </a>
                            )}
                            {this.state.entreprise.file_statut && (
                                <a target={"_blank"} rel="noreferrer" href={`${this.state.entreprise.file_statut}`}>
                                    <DocumentDownload />
                                    Statut
                                </a>
                            )}
                        </Box> :
                        <Box pad={'small'} gap='small'>
                            <FileInput
                                messages={{
                                    browse: "Choisir",
                                    dropPrompt: "KBIS",
                                }}
                                multiple={false}
                                onChange={(event) => {
                                    doUploadFile(
                                        event.target.files,
                                        async (data) => {
                                            if (data.length > 0) {
                                                let _uploaded = data[0];
                                                this.onChange("file_kbis", _uploaded.url);
                                            }
                                        },
                                        this.props.showMessage
                                    );
                                }}
                            />
                            <FileInput
                                messages={{
                                    browse: "Choisir",
                                    dropPrompt: "Statut",
                                }}
                                multiple={false}
                                onChange={(event) => {
                                    doUploadFile(
                                        event.target.files,
                                        async (data) => {
                                            if (data.length > 0) {
                                                let _uploaded = data[0];
                                                this.onChange("file_statut", _uploaded.url);
                                            }
                                        },
                                        this.props.showMessage
                                    );
                                }}
                            />
                        </Box>

                    }

                    <Box pad={'medium'} direction='row' gap="medium">
                        < PrimaryButton icon={<Close />} label='Annuler' background={'#707070'} onClick={this.props.onCancel}/>
                        < PrimaryButton icon={<Save />} label='Sauvegarder' onClick={this.submit} />
                    </Box>
                </Box>
            </BlockUi>
        )
    }
}

const ExistingEntreprise = ({ originalEntreprises, value, onSelect }) => {
    const [timeoutId, setTimeoutId] = useState();
    const [entreprises, setEntreprises] = useState(originalEntreprises);
    console.log(entreprises);
    const [_value, setValue] = useState(`${value?.raison_social} (${value?.email})`)

    const searchEntreprise = (query) => {
        clearTimeout(timeoutId);
        let _id = setTimeout(() => {
            getEntreprises({ query })
                .then((res) => {
                    setEntreprises(
                        res.data.map((s) => {
                            s.description = `${s.raison_social} (${s.email})`
                            return s;
                        })
                    );
                })
                .catch((err) => console.log(err));
        }, 500);
        setTimeoutId(_id);
    };

    const onEntrepriseInfoSelect = (info) => {
        setValue(info)
        let found = entreprises.find(s => `${s.raison_social} (${s.email})` === info);
        found.description = `${found.raison_social} (${found.email})`
        onSelect(found);
    }
    return (
        <Line
            left={<StandardText size={'small'} label="Recherche entreprise" />}
            right={
                <CustomSelect
                    options={entreprises}
                    size={'small'}
                    value={_value}
                    labelKey={"description"}
                    valueKey={{ key: "description", reduce: true }}
                    onChange={({ value: nextValue }) => onEntrepriseInfoSelect(nextValue)}
                    onSearch={(text) => {
                        const escapedText = text.replace(
                            /[-\\^$*+?.()|[\]{}]/g,
                            "\\$&"
                        );
                        searchEntreprise(escapedText);
                    }}
                />
            }
        />
    )
}

const CustomizedInputLine = ({ label, field, value, onChange, disabled }) => (
    <Line
        left={<StandardText size={'small'} label={label} />}
        right={
            <CustomInput size="small" value={value}
                onChange={event => onChange(field, event.target.value)}
                disabled={disabled}
                placeholder={label}
            />
        }
    />
)

const Line = ({ left, right }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '35%' }} >
                {left}
            </Box>
            <Box width={'full'}>
                {right}
            </Box>
        </Box>
    )
}

const LineWithText = ({ leftLabel, right }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '35%' }} >
                <StandardText size={'small'} label={leftLabel} />
            </Box>
            <Box width={'full'}>
                {right}
            </Box>
        </Box>
    )
}


export default SelectEntreprise;

import React from 'react';
import { Box, Text } from 'grommet';
import { getSimpleDateFR, toCurrency, toCurrencyWithZero } from '../../../services/utils';
import { getCommercialId, hasAdminRole } from '../../../services/storage_service';
import { CONTRAT_CLOTURE, CONTRAT_ENVOYE, DEVIS, DEVIS_VALIDE, EN_ATTENTE_BANQUE, EN_ATTENTE_DE_FORMALITE_MEDICALE, EN_ATTENTE_DE_RESILIATION } from '../../../services/devis_contrat_status';
import { CLOTURE } from '../../../services/credit_status';

const ContractSimpliedDetails = ({ devis, commission_taux, display, noborder }) => {
    return (
        !hasDetails(devis) ? <Box /> :
            <Box pad="xsmall" style={{ border: noborder ? 'none' : '1px solid #c8c8c8', borderRadius: 5 }} >
                {devis.model_type !== 'trusti_per' && <InfoLine label="Fractionnement"
                    value={
                        devis.fractionnement_type ||
                        (devis.devis || devis).abonnement ||
                        (devis.fractionnement_manuel !== undefined ? (devis.fractionnement_manuel ? 'Manuellement' : 'Automatique') : '')
                    }
                />}
                <InfoLine label="Prime TTC/an" value={toCurrencyWithZero(getPrimeTTC(devis))} />
                {devis.model_type === 'trusti_per' && <InfoLine label="Premier versement" value={toCurrencyWithZero(devis.first_payment)} />}
                {getFraisDossier(devis) > 0 &&
                    <InfoLine label="Frais dossier" value={toCurrencyWithZero(getFraisDossier(devis))} color={display && devis.revenue_type === 'date_bpa' && !isEmprunteur(devis) ? display.frais : undefined} />
                }
                {isEmprunteur(devis) &&
                    <>
                        {getRealAcompte(devis) &&
                            <InfoLine label="Acompte" value={toCurrencyWithZero(getRealAcompte(devis))} color={display && devis.revenue_type === 'date_bpa' ? display.accompt : undefined} />
                        }
                        {devis.fractionnement_frais_dossier &&
                            <InfoLine label="Mensualité des frais" value={toCurrencyWithZero(devis.fractionnement_frais_dossier)} color={display && devis.revenue_type === 'frais_mensuel' ? display.frais : undefined} />
                        }
                    </>
                }
                {(hasAdminRole()) &&
                    <InfoLine label="Trustiway com" value={toCurrencyWithZero(getCommission(devis))} color={display && isCom(devis) ? display.com : undefined} />
                }
                {hasAdminRole() &&
                    <InfoLine label="Total Trustiway" value={toCurrencyWithZero(getTotalTT(devis))} color={display && devis.revenue_type === 'date_bpa' ? display.total_trustiway : undefined} />
                }
                {getCommissionTaux(devis, commission_taux) > 0 && (hasAdminRole() || devis.commercial_id === getCommercialId()) &&
                    <InfoLine label="Commercial com" value={`${toCurrencyWithZero(getCommercialCommission(devis, commission_taux))}`} />
                }
                {isApportable(devis) &&
                    <InfoLine label="Apporteur" value={toCurrency(getApporteurCom(devis))} />
                }
                {devis.source &&
                    <InfoLine label="Source" value={devis.source} />
                }
                {hasAdminRole() &&
                    <>
                        {devis.com_network_mother && <InfoLine label="Réseau mère" value={toCurrency(parseFloat(devis.com_network_mother))} />}
                        {devis.com_network && <InfoLine label="Réseau" value={toCurrency(parseFloat(devis.com_network))} />}
                        {devis.com_apporteur && <InfoLine label="Apporteur" value={toCurrency(parseFloat(devis.com_apporteur))} />}
                        {devis.com_level_1 && <InfoLine label="Niv 1" value={toCurrency(parseFloat(devis.com_level_1))} />}
                        {devis.com_level_2 && <InfoLine label="Niv 2" value={toCurrency(parseFloat(devis.com_level_2))} />}
                        {devis.com_level_3 && <InfoLine label="Niv 3" value={toCurrency(parseFloat(devis.com_level_3))} />}
                        <InfoLine label="TT Trustiway HT" value={toCurrency(getTotalTT_HT(devis, commission_taux))} />
                    </>
                }


            </Box>
    )
}

const ContractDetails = ({ devis, commission_taux, display, commercialName, need_product_name }) => {
    return (
        !hasDetails(devis) ? <Box /> :
            <Box width="small" pad="small" style={{ border: '1px solid #c8c8c8', borderRadius: 5 }} >
                {devis.status !== 'Devis' &&
                    <>
                        <InfoLine label="Date BPA" value={getSimpleDateFR(devis.date_bpa)} />
                        <InfoLine label="Date d'effet" value={getDateEffet(devis)} />
                        {devis.model_type !== 'trusti_per' && <InfoLine label="Fractionnement"
                            value={
                                devis.fractionnement_type ||
                                (devis.devis || devis).abonnement ||
                                (devis.fractionnement_manuel !== undefined ? (devis.fractionnement_manuel ? 'Manuellement' : 'Automatique') : '')
                            }
                        />}

                    </>
                }
                {devis.date_cloture &&
                    <InfoLine label="Date clôturé" value={getSimpleDateFR(devis.date_cloture)} />
                }
                {need_product_name &&
                    <>
                        <InfoLine label="Type de contract" value={devis.product} />
                        <InfoLine label="Numéro de contrat" value={devis.numero_contrat || devis.contractNumber} />
                    </>
                }
                <InfoLine label="Compagnie" value={devis.compagnie || (devis.devis || devis).assureur} />
                <InfoLine label="Prime TTC/an" value={toCurrencyWithZero(getPrimeTTC(devis))} />
                <InfoLine label="Frais dossier" value={toCurrencyWithZero(getFraisDossier(devis))} color={display && devis.revenue_type === 'date_bpa' && !isEmprunteur(devis) ? display.frais : undefined} />
                {devis.status !== 'Devis' && isEmprunteur(devis) &&
                    <>
                        <InfoLine label="Acompte" value={toCurrencyWithZero(getRealAcompte(devis))} color={display && devis.revenue_type === 'date_bpa' ? display.accompt : undefined} />
                        <InfoLine label="Mensualité des frais" value={toCurrencyWithZero(devis.fractionnement_frais_dossier)} color={display && devis.revenue_type === 'frais_mensuel' ? display.frais : undefined} />
                    </>
                }
                {hasAdminRole() &&
                    <InfoLine label="Trustiway com" value={toCurrencyWithZero(getCommission(devis))} color={display && isCom(devis) ? display.com : undefined} />
                }
                {
                    hasAdminRole() && <>
                        <InfoLine label="Total Trustiway" value={toCurrencyWithZero(getTotalTT(devis))} color={display && devis.revenue_type === 'date_bpa' ? display.total_trustiway : undefined} />
                    </>
                }
                {getCommissionTaux(devis, commission_taux) > 0 && (hasAdminRole() || devis.commercial_id === getCommercialId()) &&
                    <InfoLine label="Commercial com" value={`${toCurrencyWithZero(getCommercialCommission(devis, commission_taux))}`} />
                }
                {commercialName &&
                    <InfoLine label="Commercial" value={commercialName} />
                }
                {devis.promo &&
                    <InfoLine label="Promo" value={toCurrency(devis.promo.value)} />
                }
                {hasAdminRole() &&
                    <>
                        {isApportable(devis) && devis.status === CONTRAT_CLOTURE &&
                            <InfoLine label="Apporteur" value={toCurrency(getApporteurCom(devis))} />
                        }
                        {devis.com_network_mother && <InfoLine label="Réseau mère" value={toCurrency(parseFloat(devis.com_network_mother))} />}
                        {devis.com_network && <InfoLine label="Réseau" value={toCurrency(parseFloat(devis.com_network))} />}
                        {devis.com_apporteur && <InfoLine label="Apporteur" value={toCurrency(parseFloat(devis.com_apporteur))} />}
                        {devis.com_level_1 && <InfoLine label="Niv 1" value={toCurrency(parseFloat(devis.com_level_1))} />}
                        {devis.com_level_2 && <InfoLine label="Niv 2" value={toCurrency(parseFloat(devis.com_level_2))} />}
                        {devis.com_level_3 && <InfoLine label="Niv 3" value={toCurrency(parseFloat(devis.com_level_3))} />}
                        {<InfoLine label="TT Trustiway HT" value={toCurrency(getTotalTT_HT(devis, commission_taux))} />}
                    </>
                }
            </Box>
    )
}

const isApportable = devis => devis.affiliation;

const getFraisDossier = deal => {
    let devis = deal.devis || deal;
    if (devis.model_type === 'pno_seul') {
        return devis.rawResponse?.frais_dossier || 0;
    }
    return devis.frais_dossier_total || devis.frais_dossier || 0
};

const getRealAcompte = devis => {
    return isEmpPaymetOneShot(devis) ? devis.frais_dossier_total : devis.acompte_a_regler;
}

const isEmpPaymetOneShot = d => {
    return d.acompte_a_regler === d.frais_dossier_total ||
        (d.fractionnement_frais_dossier === d.frais_dossier_total);
}

const isCom = devis => {
    return devis.revenue_type === 'date_effet' || (devis.model_type === 'credit' && devis.commission);
}

const isEmprunteur = devis => devis.model_type === 'emprunteur_horsligne';

const getCommissionTaux = (devis, tauxConfig) => {
    if (!devis.commercial_id) { // no commercial, no com
        return 0;
    }
    if (!tauxConfig) return null;
    let { custom_taux, default_taux, default_credit_taux, custom_taux_credit } = tauxConfig;
    if (!custom_taux) {
        custom_taux = default_taux;
    }
    if (devis.is_rac_deal) {
        custom_taux = custom_taux_credit?.length ? custom_taux_credit : default_credit_taux;
    }

    if (devis.pourcentage_commission?.length && (devis.status === CONTRAT_CLOTURE || devis.status === EN_ATTENTE_BANQUE)) {
        custom_taux = devis.pourcentage_commission;
    }
    custom_taux.forEach(t => {
        Object.keys(t).forEach(key => t[key] = parseFloat(t[key]));
    })

    let totalTrustiway = getTotalTT(devis);
    let taux = custom_taux.find(s => s.valeur_inferieure <= totalTrustiway && s.valeur_superieure > totalTrustiway)

    if (!taux) {
        taux = default_taux.find(s => s.valeur_inferieure <= totalTrustiway && s.valeur_superieure > totalTrustiway);
        if (devis.model_type === 'credit' && default_credit_taux) {
            taux = default_credit_taux.find(s => s.valeur_inferieure <= totalTrustiway && s.valeur_superieure > totalTrustiway);
        }
    }
    if (devis.model_type === 'credit') {
        console.log(taux, tauxConfig);
    }
    if (taux) return taux.pourcentage;
}

const getCommercialCommission = (devis, tauxConfig) => {
    // special case
    if (devis.model_type === 'assurance_vie') {
        return 60;
    }
    let taux = getCommissionTaux(devis, tauxConfig);
    return getTotalTT(devis) * taux / 100;
}

const getPrimeTTC = devis => {
    devis = devis.devis || devis;
    if (devis.model_type === 'pno_seul' || devis.model_type === 'devis_habitation' || devis.model_type === 'trusti_mh_tempo') {
        return devis.estimatedPrice;
    }
    if (isEmprunteur(devis)) {
        return devis.premiere_prime_annee;
    }

    if (devis.model_type === 'trusti_per') {
        return devis.monthly_payment * 12;
    }

    return isOneTimeCount(devis.fractionnement_type) ? devis.prime_ttc : devis.prime_ttc * 12;
}

const isOneTimeCount = fractionnement_type => fractionnement_type === 'Annuel' ||
    fractionnement_type === 'annuel' ||
    fractionnement_type === 'one-shot';

const getTotalCommission = devis => {
    let mhCom = getMHCommission(devis);
    if (mhCom) return mhCom;

    if (isEmprunteur(devis)) {
        return getEmprunteurCommission(devis);
    }

    if (devis.model_type === 'trusti_per') {
        var loan_period = devis.monthly_payment * 12;
        return (loan_period * (devis.taux_commission_periodique || 0) / 100) + (loan_period * (devis.taux_sur_commission || 0) / 100) + (devis.first_payment * (devis.taux_commission_initial || 0) / 100) + ((devis.first_payment * (devis.taux_commission_initial || 0) / 100) * (devis.taux_sur_commission || 0) / 100);
    }
    if (devis.model_type === 'credit' && devis.commission_total) {
        return parseFloat(devis.commission_total);
    }

    let primht = isOneTimeCount(devis.fractionnement_type) ? devis.prime_ht : devis.prime_ht * 12;
    return primht * (devis.taux_commision / 100);
}

const getCommission = devis => {
    let totalCom = getTotalCommission(devis);
    if (devis.model_type === 'trusti_per') {
        return totalCom;
    }
    return isAnnual(devis) || isEmprunteur(devis) ? totalCom : totalCom;
}

const isAnnual = devis => {
    let fractionnement_type = (devis.fractionnement_type ||
        (devis.devis || devis).abonnement);
    return isOneTimeCount(fractionnement_type);
};

const getTotalTT = devis => {
    devis = devis.devis || devis;
    if (devis.model_type === 'pno_seul') {
        return devis.rawResponse?.total_trustiway;
    }
    return parseFloat(getFraisDossier(devis)) +
        parseFloat(getTotalCommission(devis) || 0) - getApporteurCom(devis);
}

const getApporteurCom = devis => devis.affiliation?.retrocession ? parseFloat(devis.affiliation?.retrocession) : 0;

const getTotalTT_HT = (devis, tauxConfig) => {
    return getTotalTT(devis) -
        getCommercialCommission(devis, tauxConfig) -
        getApporteurCom(devis) -
        (devis.com_network_mother ? parseFloat(devis.com_network_mother) : 0) -
        (devis.com_network ? parseFloat(devis.com_network) : 0) -
        (devis.com_apporteur ? parseFloat(devis.com_apporteur) : 0) -
        (devis.com_level_1 ? parseFloat(devis.com_level_1) : 0) -
        (devis.com_level_2 ? parseFloat(devis.com_level_2) : 0) -
        (devis.com_level_3 ? parseFloat(devis.com_level_3) : 0)
}


const InfoLine = ({ label, value, color }) => {
    let style = color ? { border: '1px solid #000', borderRadius: 5, color } : {}

    return (<Text style={style} size="xsmall" >{label} : <strong>{value}</strong></Text>)
}

const hasDetails = devis => devis.status === DEVIS ||
    devis.status === DEVIS_VALIDE ||
    devis.status === CONTRAT_ENVOYE ||
    devis.status === CONTRAT_CLOTURE ||
    devis.status === CLOTURE ||
    devis.status === EN_ATTENTE_DE_FORMALITE_MEDICALE ||
    devis.status === EN_ATTENTE_DE_RESILIATION ||
    devis.status === EN_ATTENTE_BANQUE;

const getMHCommission = devis => {
    devis = devis.devis || devis;
    if (devis.model_type !== 'pno_seul' &&
        devis.model_type !== 'devis_habitation' &&
        devis.model_type !== 'trusti_mh_tempo') return;
    let isPNO = devis.model_type === 'pno_seul';
    let isTempo = devis.model_type === 'trusti_mh_tempo';
    let rawResponse = devis.rawResponse;

    let com = isPNO ? rawResponse?.commission :
        isTempo ? rawResponse?.total_trustiway :
            devis.option === "Trusti base" ?
                rawResponse?.MontantTotalCommissions_logibase :
                rawResponse?.MontantTotalCommissions_logicomfort;
    // deduire promo
    return devis.promo?.value ? com - (devis.promo.value) : com;
}

const getEmprunteurCommission = devis => {
    return (devis.taux_commision > 1 ? devis.taux_commision / 100 : devis.taux_commision) * devis.premiere_prime_annee;
}

const getDateEffet = devis => getSimpleDateFR((devis.devis || devis).effectiveDate || devis.begin_date || devis.date_effet);

const isSubscribed = status => {
    console.log('status :', status);
    return status === CONTRAT_CLOTURE || status === EN_ATTENTE_BANQUE;
}

export default ContractDetails;

export {
    ContractSimpliedDetails,
    getTotalTT,
    getPrimeTTC,
    getFraisDossier,
    getMHCommission,
    getDateEffet,
    isSubscribed,
    getCommission,
    getCommercialCommission,
    getTotalTT_HT,
}
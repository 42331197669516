
import { Box, FileInput, Grid, Image, Layer, Menu, Stack, Text, TextArea, Select, CheckBox } from "grommet";
import { Add, AddCircle, Checkmark, Close, DocumentDownload, Edit, FormCheckmark, Group, Key, Send, Trash } from "grommet-icons";
import React, { useState } from "react";
import configs from '../../../../config/config.json';
import { getCommercialId } from "../../../../services/storage_service";
import { toArrayOfArray } from "../../../../services/utils";
import { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import AddressAutoComplet from "../../../elements/address_autocomplete";
import LineModification from "../../../elements/line_container/line_modification";
import PrimaryButton from "../../../elements/primary_button";
import CustomSelect from "../../../elements/select_fill";
import StandardText from "../../../elements/standard_text";
import ToggleButton, { MultipleLineCheckBoxes, MultipleLineToggleButtons } from "../../../elements/toggle_button/toggle_button";
import Interlocuteur from "../../../voyages/agences/agence/components/elements/interlocuter";
import { PRODUCTS, VENT_CANALS, getApporteurPros, sendApporteurPresentation } from "../../service";
import { DashboardRecapTitle } from "../../../elements/new_design_elements";
import { createAccountAdmin, createApporteurUsers, resendEmailInscription, resetPasswordPartenaire, validateEmailsInvitation } from "../../../my_trustiway/service";
import AppAffilieByApporteur from "../../../my_trustiway/users/affilie_by_apporteur_pro";

const groupEnligne = (p1, p2) => (
    p1.name.includes('en ligne') && p2.name.includes('en ligne') ? (p1.name > p2.name ? - 1 : 1) :
        p1.name.includes('en ligne') ? -1 : 1
)

const validateApporteur = apporteur => {
    if (!apporteur.raisonSocial) return 'Raison social';

}

const hasProduct = (products, productName) => products.find(p => p.name === productName);

const getCommercial = (id, commerciaux) => {
    if (!id) id = getCommercialId();
    let com = commerciaux.find(c => c.id === id);
    return getComName(com);
}
const getComName = com => com?.prenom + ' ' + com?.nom;
const getRandomId = (commerciaux) => {
    let index = Math.floor(Math.random() * commerciaux.length);
    let com = commerciaux[index];
    return com.id;
}

const ApporteurProInfo = ({
    apporteur,
    onApporteurChange,
    saveApporteur,
    onCancel,
    upload,
    sendConvention,
    commerciaux,
    showMessage
}) => {
    const [isView, setView] = React.useState(apporteur.id ? true : false);
    const [desLang, setDesLang] = React.useState('');

    const onProductChange = (p, field, value) => {
        p[field] = value;
        onApporteurChange('products', apporteur.products.map(s => s))
    }

    const onSubmit = async () => {
        let error = validateApporteur(apporteur);
        if (error) {
            showMessage(error, 'error');
            return;
        }
        saveApporteur(() => setView(true));
    }

    const resendInscription = () => {
        resendEmailInscription(apporteur.id).then(res => {
            console.log("resendEmailInscription = ", res.data);
            showMessage("Email envoyé.");
            // onApporteurChange('interlocuteurs', interlocuteurs);
        }).catch(err => {
            showMessage('Erreur inattendu', 'error');
            console.log(err);
        });

    }

    const createAccount = () => {
        createAccountAdmin(apporteur.id).then(res => {
            showMessage("Compte créé");
        }).catch(err => {
            showMessage('Erreur inattendu', 'error');
            console.log(err);
        });
    }

    const resetPassword = () => {
        resetPasswordPartenaire(apporteur.id).then(res => {
            console.log("reset password = ", res.data);
            showMessage("Sent reset password");
            // onApporteurChange('interlocuteurs', interlocuteurs);
        }).catch(err => {
            showMessage('Erreur inattendu', 'error');
            console.log(err);
        });
    }

    console.log(apporteur);

    return (
        <Stack fill anchor="top">

            <Box fill
                gap="small"
                alignSelf="center"
                align="start"
                pad="small"
                justify="center"
            >
                <Box justify="center" alignSelf="center" >
                    <Box direction="row" fill justify="between" align="center">
                        <Box align="start" >
                            <Text size="xlarge"><b>{`Informations de`} {apporteur.company_mother ? "la maison mère" : "l'apporteur"}</b></Text>
                            <Text size="small">Voici le formulaire qui réunis les informations que nous avons besoins pour effectuer une étude personnalisée.</Text>
                        </Box>
                        {/* <Box align='end' >
                            {apporteur.id && <AgenceStatus convention={apporteur.convention} status={apporteur.status} />}
                        </Box> */}
                    </Box>

                    {!apporteur.company_mother && <DashboardRecapTitle title="Réseau" />}

                    {!apporteur.company_mother && <Box pad={'small'} gap="small" align="center"  >
                        <Box direction='row' fill align='center' justify='between'>
                            <Text >L’apporteur fait-il partis d’un réseau client ?</Text>
                            <ToggleButton
                                disabled={isView}
                                groups={[
                                    {
                                        label: "OUI",
                                        selected: apporteur.has_network,
                                        onClick: () => onApporteurChange("has_network", true)
                                    },
                                    {
                                        label: "NON",
                                        selected: !apporteur.has_network,
                                        onClick: () => onApporteurChange("has_network", false)
                                    },
                                ]}
                            />
                        </Box>
                        {apporteur.has_network && <Box fill align='start' justify='stretch' pad={'small'}>
                            <Box direction='row' fill align='center' justify='between' gap='small' >
                                {!isView && <ParentApporteur apporteur={apporteur} onApporteurChange={onApporteurChange} isView={isView} />}

                            </Box>
                            <Box direction='row' fill align='center' justify='between' gap='small' >
                                <LineModification text='Raison social de la maison mère'
                                    value={apporteur.apporteur_parent?.raisonSocial}
                                    direction='column'
                                    disabled={true}
                                />
                                <LineModification text='SIRET de la maison mère'
                                    value={apporteur.apporteur_parent?.siret}
                                    direction='column'
                                    disabled={true}
                                />
                            </Box>
                            {/* <LineModification text='Mail tête reseaux de la maison mère'
                                value={apporteur.apporteur_parent?.email}
                                direction='column'
                                disabled={true}
                            /> */}
                        </Box>}
                    </Box>}

                    <DashboardRecapTitle title="Information de l’entreprise" />

                    <Box pad={'small'} gap="small" align="start" fill >

                        <LineModification text='Raison social' field='raisonSocial'
                            value={apporteur.raisonSocial}
                            direction='column'
                            onChange={(field, value) => isView ? null : onApporteurChange(field, value)}
                        />
                        <Box direction="row" gap="small">
                            <Box gap="xsmall" justify="start" align="center" >
                                <Box style={{ width: '100%' }}>
                                    <Text size="xsmall" color={'#707070'}>Form juridique</Text>
                                </Box>
                                <Box style={{ width: '100%' }}>
                                    <CustomSelect
                                        options={isView ? [apporteur.formJuridique] : [
                                            'SAS', 'SA', 'EI', 'SARL', 'Site PAP', 'EURL'
                                        ]}
                                        value={apporteur.formJuridique}
                                        onChange={({ option }) => {
                                            if (isView) return;
                                            onApporteurChange("formJuridique", option)
                                        }}
                                    />
                                </Box>
                            </Box>
                            <LineModification text='Siret' field='siret' width="medium"
                                value={apporteur.siret}
                                direction='column'
                                onChange={(field, value) => isView ? null : onApporteurChange(field, value)}
                            />
                        </Box>

                        <AddressAutoComplet
                            contact={apporteur}
                            disabled={isView}
                            updateContact={onApporteurChange}
                            direction='row'
                        />
                        <Box direction="row" gap="small">

                            <Box gap="xsmall" justify="start" align="center" >
                                <Box style={{ width: '100%' }}>
                                    <Text size="xsmall" color={'#707070'}>Type de société</Text>
                                </Box>
                                <Box style={{ width: '100%' }}>
                                    <CustomSelect
                                        options={isView ? [apporteur.societeType] : [
                                            'Apporteur', 'Conciergerie saisonnière', 'Camping', 'Channel manger', 'Site PAP',
                                            'Hôtel', 'Gîte', 'Propriétaire', 'Réseaux National', 'Startup', 'Réseaux Régionnal'
                                        ]}
                                        value={apporteur.societeType}
                                        onChange={({ option }) => {
                                            if (isView) return;
                                            onApporteurChange("societeType", option)
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box gap="xsmall" justify="start" align="center" >
                                <Box style={{ width: '100%' }}>
                                    <Text size="xsmall" color={'#707070'}>Volume d'affaire</Text>
                                </Box>
                                <Box style={{ width: '100%' }}>
                                    <CustomSelect
                                        options={isView ? [apporteur.affaireVolume] : [
                                            'Petit volume', 'Moyen volume', 'Gros volume'
                                        ]}
                                        value={apporteur.affaireVolume}
                                        onChange={({ option }) => {
                                            if (isView) return;
                                            onApporteurChange("affaireVolume", option);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>


                        {apporteur.reference === 'blagent-20' && <Box direction="row" gap="small">
                            <LineModification text='Max de prospects le matin' field='num_import_morning'
                                value={apporteur.num_import_morning}
                                direction='column'
                                type="number"
                                onChange={(field, value) => isView ? null : onApporteurChange(field, value)}
                            />
                            <LineModification text="Max de prospects l'après midi" field='num_import_afternoon'
                                value={apporteur.num_import_afternoon}
                                direction='column'
                                type="number"
                                onChange={(field, value) => isView ? null : onApporteurChange(field, value)}
                            />
                        </Box>}

                    </Box>
                    {!isView &&
                        <Box pad={'small'} justify="between" direction='row' gap="medium">
                            <Box direction="row" gap="small" justify="center" align="center" alignSelf="center">
                                <FileInput
                                    messages={{
                                        browse: "Choisir",
                                        dropPrompt: "Logo",
                                    }}
                                    size={'xsmall'}
                                    multiple={false}
                                    onChange={(event) => upload('logo', event.target.files)}
                                />
                            </Box>
                            <Box direction="row" gap="small" justify="center" align="center" alignSelf="center">
                                {/*    <FileInput
                                    messages={{
                                        browse: "Choisir",
                                        dropPrompt: "Image illustration",
                                    }}
                                    size={'xsmall'}
                                    multiple={false}
                                    onChange={(event) => upload('illustration', event.target.files)}
                                /> */}
                            </Box>
                        </Box>
                    }
                    <Box pad={'small'} justify="between" direction='row' gap="medium">
                        <Box>
                            <Text>Logo</Text>
                            <Box gap="small" justify="center" align="center" alignSelf="center" border={{ size: 'medium', style: 'dotted', backgroundColor: '#DEDEDE' }}>

                                <Box direction='row' gap="small">
                                    {apporteur.logo && <Image src={apporteur.logo} width={100} />}
                                    {!isView && apporteur.logo && <Trash color="brand" style={{ cursor: 'pointer' }} onClick={() => onApporteurChange('logo', null)} />}
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            {/* <Text>Image illustration</Text>
                            <Box gap="small" justify="center" align="center" alignSelf="center" border={{ size: 'medium', style: 'dotted' }}>

                                <Box gap='small' direction="row">
                                    {apporteur.illustration && <Image src={apporteur.illustration} width={100} />}
                                    {!isView && apporteur.illustration && <Trash color="brand" style={{ cursor: 'pointer' }} onClick={() => onApporteurChange('illustration', null)} />}
                                </Box>
                            </Box> */}
                        </Box>
                    </Box>


                    <DashboardRecapTitle title="Détail de l’entreprise" />
                    <Box pad={'small'} gap="small" align="start"  >
                        {/* <LineModification text='Nom de domaine' field='domainName'
                            value={apporteur.domainName}
                            direction='column'
                            onChange={(field, value) => isView ? null : onApporteurChange(field, value)}
                        /> */}
                        <Box direction="row" gap="small">
                            <LineModification text='Nombre de salariés' field='num_salaries'
                                value={apporteur.num_salaries}
                                direction='column'
                                onChange={(field, value) => isView ? null : onApporteurChange(field, value)}
                            />
                            {/* <LineModification text='Mail tête reseaux' field='email'
                                value={apporteur.email}
                                direction='column'
                                onChange={(field, value) => isView ? null : onApporteurChange(field, value)}
                            /> */}
                        </Box>
                        <Box direction="row" gap="small" justify="between" fill>
                            <Box direction="row" gap="small">
                                <LineModification width="fill" text='Couleur Principale' field='color_primary'
                                    value={apporteur.color_primary}
                                    direction='column'
                                    onChange={(field, value) => isView ? null : onApporteurChange(field, value)}
                                />
                                <Box style={{ width: 50, height: 50, borderRadius: 25, backgroundColor: `${apporteur.color_primary}`, alignSelf: "end" }} ></Box>
                            </Box>
                            <Box direction="row" gap="small">
                                <Box style={{ width: 50, height: 50, borderRadius: 25, backgroundColor: `${apporteur.color_secondary}`, alignSelf: "end" }} ></Box>
                                <LineModification width="fill" text='Couleur Secondaire' field='color_secondary'
                                    value={apporteur.color_secondary}
                                    direction='column'
                                    onChange={(field, value) => isView ? null : onApporteurChange(field, value)}
                                />

                            </Box>
                        </Box>
                    </Box>

                    <DashboardRecapTitle title="Information de l’interlocuteur" />
                    <Interlocuteurs interlocuteurs={apporteur.interlocuteurs} onApporteurChange={onApporteurChange} showMessage={showMessage} isView={isView} />

                    <DashboardRecapTitle title="Mode de Rémunération" />
                    {apporteur.company_mother && <Box pad={'small'} gap="small" align="start">

                        <Box direction='row' fill align='center' justify='between'>
                            <Text >Le réseau de la maison mère impose-t-il un système de rémunération fixe aux franchisés ?</Text>
                            <ToggleButton
                                disabled={isView}
                                groups={[
                                    {
                                        label: "OUI",
                                        selected: apporteur.taux_fixed_for_all,
                                        onClick: () => onApporteurChange("taux_fixed_for_all", true)
                                    },
                                    {
                                        label: "NON",
                                        selected: !apporteur.taux_fixed_for_all,
                                        onClick: () => onApporteurChange("taux_fixed_for_all", false)
                                    },
                                ]}
                            />
                        </Box>

                    </Box>}
                    {(!apporteur.company_mother && apporteur.apporteur_parent?.taux_fixed_for_all) && <Box>
                        <Text>La modification de rémunération est désactivé. (Le réseau de la maison mère est avec le système de rémunération fixe.)</Text>
                    </Box>}
                    {((apporteur.company_mother && apporteur.taux_fixed_for_all) || !apporteur.company_mother) && <Box pad={'small'} gap="small" align="start">
                        <ToggleButton gap='xlarge'
                            disabled={isView || (!apporteur.company_mother && apporteur.apporteur_parent?.taux_fixed_for_all)}
                            groups={apporteur.company_mother ? [
                                {
                                    label: "Full réseaux",
                                    selected: apporteur.mode_remuneration === "Full réseaux",
                                    onClick: () => {
                                        onApporteurChange("mode_remuneration", "Full réseaux");
                                        onApporteurChange("taux_commission", 0.15);
                                    }
                                },
                                {
                                    label: "Full apporteurs",
                                    selected: apporteur.mode_remuneration === 'Full apporteurs',
                                    onClick: () => {
                                        onApporteurChange("mode_remuneration", "Full apporteurs");
                                        onApporteurChange("taux_commission", 0.15);
                                    }
                                },
                                {
                                    label: "Full split",
                                    selected: apporteur.mode_remuneration === 'Full split',
                                    onClick: () => onApporteurChange("mode_remuneration", "Full split")
                                }
                            ] : [
                                {
                                    label: "Full réseaux",
                                    selected: apporteur.mode_remuneration === "Full réseaux",
                                    onClick: () => {
                                        onApporteurChange("mode_remuneration", "Full réseaux");
                                        onApporteurChange("taux_commission", 0.15);
                                    }
                                },
                                {
                                    label: "Full apporteurs",
                                    selected: apporteur.mode_remuneration === 'Full apporteurs',
                                    onClick: () => {
                                        onApporteurChange("mode_remuneration", "Full apporteurs");
                                        onApporteurChange("taux_commission", 0.15);
                                    }
                                },
                                {
                                    label: "Full split",
                                    selected: apporteur.mode_remuneration === 'Full split',
                                    onClick: () => onApporteurChange("mode_remuneration", "Full split")
                                },
                                {
                                    label: "Obsolète",
                                    desc: "10% réseau fixé, pas de maison mère",
                                    selected: apporteur.mode_remuneration === 'Obsolète',
                                    onClick: () => onApporteurChange("mode_remuneration", "Obsolète")
                                }
                            ]}
                        />
                        {apporteur.mode_remuneration === 'Full split' && <Box align="center" fill>
                            <Box direction="row" gap="small" alignSelf="end">
                                <LineModification text='Taux commission réseau' field='taux_split_network'
                                    value={apporteur.taux_split_network || '0.5'}
                                    width="medium"
                                    direction='column'
                                    disabled={isView || (!apporteur.company_mother && apporteur.apporteur_parent?.taux_fixed_for_all)}
                                    onChange={(field, value) => {
                                        if (!isView) {
                                            onApporteurChange(field, value);
                                            if (parseFloat(value) < 1) {
                                                onApporteurChange("taux_split_apporteur", (1 - parseFloat(value)).toFixed(2))
                                            }
                                        }
                                    }}
                                />
                                <LineModification text='Taux commission apporteur' field='taux_split_apporteur'
                                    value={apporteur.taux_split_apporteur || '0.5'}
                                    width="medium"
                                    direction='column'
                                    disabled={true}
                                    onChange={(field, value) => isView ? null : onApporteurChange(field, value)}
                                />
                            </Box>
                            <Text size="xsmall">Remplir 0.5 pour 50%. Le taux doit supérieur que zéro (0)</Text>

                        </Box>}
                    </Box>}

                    <DashboardRecapTitle title="Mode de diffusion" />
                    <Box pad={'small'} gap="small" >
                        <Text size="small">Méthode de vente :</Text>
                        <MultipleLineToggleButtons
                            size={4}
                            groups={(isView ? (apporteur.ventCanals || []) : VENT_CANALS).map(value => ({
                                label: value,
                                selected: (apporteur.ventCanals || []).includes(value),
                                onClick: () => {
                                    if (isView) return;
                                    let ventCanals = apporteur.ventCanals || [];
                                    if (ventCanals.includes(value)) {
                                        ventCanals = ventCanals.filter(v => v !== value);
                                    } else {
                                        ventCanals.push(value);
                                    }
                                    onApporteurChange('ventCanals', ventCanals.map(s => s));
                                },
                            }))}
                        />
                    </Box>



                </Box>

                <Box pad={'small'} gap="small"  >
                    {!apporteur.company_mother && <DashboardRecapTitle title={'Application'} background={"brand"} color={"white"} />}
                    {apporteur.id && !apporteur.company_mother && <UserAccountCreation apporteur={apporteur} showMessage={showMessage} />}

                    {!apporteur.id && !apporteur.company_mother && <Text>Tu peux créer des utilisateurs après valider l'apporteur</Text>}

                    <DashboardRecapTitle title={'Produits disponible sur My Trustiway APP et le pont'} background={"brand"} color={"white"} />

                    <Grid columns={["25%", "50%", "25%"]} fill round={{ size: 'small' }} gap='small' pad={'small'} align='start' justify="between" border={{ color: 'brand' }} direction="row" >

                        <Box >
                            <Box>
                                <Text textAlign="start" size="large" margin={'small'}><b>Immobilier</b> </Text>
                                <CheckBox
                                    disabled={isView}
                                    label={<Text size='small'>{"Recommandation Immo"}</Text>}
                                    checked={apporteur.with_immobilier_reco}
                                    onChange={(event) => onApporteurChange('with_immobilier_reco', event.target.checked)} >
                                </CheckBox>
                            </Box>
                            <ProductsCheckBoxes apporteur={apporteur} group={'Crédit'} onApporteurChange={onApporteurChange} isView={isView} />
                        </Box>
                        <ProductsCheckBoxes size={2} apporteur={apporteur} group={'Assurance'} onApporteurChange={onApporteurChange} isView={isView} />

                        <Box >
                            <ProductsCheckBoxes apporteur={apporteur} group={'Retraite'} onApporteurChange={onApporteurChange} isView={isView} />
                            <ProductsCheckBoxes apporteur={apporteur} group={'Épargne'} onApporteurChange={onApporteurChange} isView={isView} />
                            <ProductsCheckBoxes apporteur={apporteur} group={'Prévoyance'} onApporteurChange={onApporteurChange} isView={isView} />
                        </Box>
                    </Grid>

                    <DashboardRecapTitle title="Taux commission apporteur " />

                    <Box pad={'small'} gap="small"  >
                        <Text textAlign="start" size="small" ><b>Les assurances et autres produits hors Crédit</b> </Text>
                        <Box  >
                            <LineModification text='Taux commission' field='taux_commission'
                                value={apporteur.taux_commission}
                                direction='column'
                                disabled={isView}
                                onChange={(field, value) => isView ? null : onApporteurChange(field, value)}
                            />
                            {!apporteur.company_mother && <Text size="xsmall">Remplir 0.2 pour 20%. Le taux doit supérieur que zéro (0)</Text>}
                            {apporteur.company_mother && <Text size="xsmall">Remplir 0.1 pour 10%. Le taux doit supérieur que zéro (0)</Text>}
                        </Box>
                        <Text textAlign="start" size="small" ><b>Activer les commissions de crédit</b> </Text>
                        <Box >
                            <ToggleButton
                                disabled={isView}
                                groups={[
                                    {
                                        label: "OUI",
                                        selected: apporteur.active_commission_credit,
                                        onClick: () => onApporteurChange("active_commission_credit", true)
                                    },
                                    {
                                        label: "NON",
                                        selected: !apporteur.active_commission_credit,
                                        onClick: () => onApporteurChange("active_commission_credit", false)
                                    },
                                ]}
                            />
                        </Box>
                    </Box>

                    <DashboardRecapTitle title="Espace partenaire" />

                    <Box pad={'small'} gap="small"  >
                        <Box direction="row" gap="small">
                            <LineModification text='Email' field='user_email'
                                value={apporteur.user_email}
                                direction='column'
                                disabled={isView}
                                onChange={(field, value) => isView ? null : onApporteurChange(field, value)}
                            />
                            <LineModification text='Mot de passe temporaire' field='init_password'
                                value={apporteur.init_password}
                                direction='column'
                                disabled={true}
                            />
                        </Box>
                        {isView &&
                            <Box direction="row" gap="small">
                                <PrimaryButton icon={<Key />}
                                    onClick={resetPassword}
                                    label="Réinitialiser mot de passe"
                                />
                                <PrimaryButton icon={<FormCheckmark />}
                                    onClick={resendInscription}
                                    label="Renvoyer l'email d'inscription"
                                />
                                {!apporteur.user_id &&
                                    <PrimaryButton icon={<FormCheckmark />}
                                        onClick={createAccount}
                                        label="Créer un compte depuis email"
                                    />}
                            </Box>
                        }
                        <Box>
                            <CanalTitle text="Espace Partenaire :" />
                            <Text size="small">
                                <a target={'_blank'} href={`https://${configs.baseAPI.includes('dev') ? "dev." : ""}partenaire.trustiway.com`}>
                                    {`https://${configs.baseAPI.includes('dev') ? "dev." : ""}partenaire.trustiway.com`}
                                </a>
                            </Text>
                        </Box>
                    </Box>
                </Box>

                {/* <Box pad={'small'} gap="small"  >
                    <SectionDivider title={"Renseigner les informations des produits d’assurances"} />
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableCell><Text size="small">Produit</Text></TableCell>
                                <TableCell><Text size="small">Code promo</Text></TableCell>
                                <TableCell><Text size="small">Montant</Text></TableCell>
                                <TableCell><Text size="small">Rémun.</Text></TableCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {
                                (apporteur.products || []).sort(groupEnligne).map((p, index) => (
                                    <TableRow key={index + 1} style={{ background: '#f8f8f8' }}>
                                        <TableCell > <Text size="small">{p.name}</Text> </TableCell>
                                        <TableCell>
                                            <CustomInput size="xsmall"
                                                value={p.code_promo}
                                                onChange={event => {
                                                    if (isView) return;
                                                    onProductChange(p, 'code_promo', event.target.value.toUpperCase())
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <CustomInput placeholder={'€'} size="xsmall"
                                                value={p.montant}
                                                onChange={event => {
                                                    if (isView) return;
                                                    onProductChange(p, 'montant', event.target.value.replace(',', '.'))
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <CustomInput placeholder={'€'} size="xsmall"
                                                value={p.renumeration}
                                                onChange={event => {
                                                    if (isView) return;
                                                    onProductChange(p, 'renumeration', event.target.value.replace(',', '.'))
                                                }}
                                            />
                                        </TableCell>
                                        {p.name === "PNO en ligne" &&
                                            <TableCell>
                                                <CustomInput placeholder={'€ Frais dossier'} size="xsmall"
                                                    value={p.frais_dossier}
                                                    onChange={event => {
                                                        if (isView) return;
                                                        onProductChange(p, 'frais_dossier', event.target.value.replace(',', '.'))
                                                    }}
                                                />
                                            </TableCell>}
                                    </TableRow>
                                ))
                            }

                        </TableBody>
                    </Table>
                </Box> */}

                {isView &&
                    <Box pad={'small'} gap="medium" fill >
                        <DashboardRecapTitle title={"Boite à outils des canaux de vente"} />
                        {/* <Box pad={'small'} gap="small" >
                            <CanalTitle text="Lien personnalisé" />
                            {hasProduct(apporteur.products, "MRH en ligne") &&
                                <Text size="small">
                                    {`MRH en ligne : `}
                                    <a target={'_blank'} href={`https://www.trustiway.com/fr/classique/trusti-habitation?ref=${apporteur.reference}`}>
                                        {`https://www.trustiway.com/fr/classique/trusti-habitation?ref=${apporteur.reference}`}
                                    </a>
                                </Text>
                            }
                            {hasProduct(apporteur.products, "PNO en ligne") &&
                                <Text size="small">
                                    {`PNO en ligne : `}
                                    <a target={'_blank'} href={`https://www.trustiway.com/fr/classique/trusti-proprietaire-non-occupant?ref=${apporteur.reference}`}>
                                        {`https://www.trustiway.com/fr/classique/trusti-proprietaire-non-occupant?ref=${apporteur.reference}`}
                                    </a>
                                </Text>
                            }
                            {hasProduct(apporteur.products, "MRH Tempo en ligne") &&
                                <Text size="small">
                                    {`MRH Tempo en ligne : `}

                                    <a target={'_blank'} href={`https://www.trustiway.com/fr/classique/trusti-habitation-temporaire?ref=${apporteur.reference}`}>
                                        {`https://www.trustiway.com/fr/classique/trusti-habitation-temporaire?ref=${apporteur.reference}`}
                                    </a>

                                </Text>
                            }
                            {hasProduct(apporteur.products, "Regroupement de crédit") &&
                                <Text size="small">
                                    {`Regroupement de crédit : `}
                                    <a target={'_blank'} href={`https://www.trustiway.com/fr/credit/rachat-de-credit/simulateur-de-regroupement-de-credits?ref=${apporteur.reference}`}>
                                        {`https://www.trustiway.com/fr/credit/rachat-de-credit/simulateur-de-regroupement-de-credits?ref=${apporteur.reference}`}
                                    </a>
                                </Text>
                            }
                        </Box> */}
                        <Box pad={'small'} gap="xsmall" >
                            <CanalTitle text="API document :" />
                            <Text size="small">
                                Doc API : <a href="https://bit.ly/trustiway-api-all-products-doc">Télécharger</a>
                            </Text>
                            <Text size="small">
                                API key : {`${configs.mh_api_key}`}
                            </Text>
                            <Text size="small">
                                agenceReference : <b>{`${apporteur.reference}`}</b>
                            </Text>
                        </Box>

                        {apporteur.ventCanals?.includes("Pont") &&
                            <Box pad={'small'} gap="xsmall" >
                                <CanalTitle text="Lient pont :" />
                                <Text size="small">
                                    {`Lien : `}
                                    <a target={'_blank'} href={`${configs.host_pont}?ref=${apporteur.reference}`}>
                                        {`${configs.host_pont}?ref=${apporteur.reference}`}
                                    </a>
                                </Text>
                            </Box>
                        }
                        {/* {apporteur.ventCanals.includes("App") &&
                            <Box pad={'small'} gap="xsmall" >
                                <CanalTitle text="Identifiant app :" />
                                <Text size="small">
                                    <b>username: </b> {apporteur.interlocuteurs[0]?.email}<br />
                                </Text>
                            </Box>
                        } */}
                    </Box>
                }

                <Box pad={'small'} gap="small" fill >
                    <DashboardRecapTitle title={'Gestionnaire de l’apporteur'} />
                    <ApporteurDuLead
                        commerciaux={commerciaux}
                        onApporteurChange={onApporteurChange}
                        apporteur={apporteur}
                        isView={isView}
                    />
                </Box>

                <Box pad={'small'} gap="small" fill >
                    <DashboardRecapTitle title={'Commercial gestionnaire des leads entrant'} />
                    <PotentialOwners
                        commerciaux={commerciaux.filter(e => e.role?.type === 'commerciale_classique')}
                        onApporteurChange={onApporteurChange}
                        apporteur={apporteur}
                        isView={isView}
                    />
                </Box>

                {!isView &&
                    <Box direction="row" align="center" justify="center" gap="medium" pad={'medium'} fill>
                        <PrimaryButton
                            label={`Valider`}
                            icon={<Checkmark />}
                            background="#59A4F4"
                            onClick={onSubmit}
                        />
                        <PrimaryButton
                            label={`Annuler`}
                            icon={<Close />}
                            background="#6c757d"
                            onClick={() => apporteur.id ? setView(true) : onCancel()}
                        />
                    </Box>
                }

                {isView &&
                    <Box fill>
                        <Box direction="row" align="center" justify="center" gap="medium" pad={'medium'}>
                            <PrimaryButton
                                label={`Modifier`}
                                icon={<Edit />}
                                background='#E82C70'
                                onClick={() => setView(false)}
                            />
                            {apporteur.convention !== 'Signée' && <PrimaryButton
                                label={`Marquer convention signée`}
                                icon={<Send />}
                                background="#59A4F4"
                                onClick={sendConvention}
                            />}
                            <PrimaryButton
                                label={`Fermer`}
                                icon={<Close />}
                                background="#6c757d"
                                onClick={onCancel}
                            />
                        </Box>

                        <Box direction="row" align="center"
                            justify="center" gap="small">
                            <PrimaryButton
                                label={`Envoyer une présentation`}
                                icon={<Send />}
                                onClick={() => {
                                    sendApporteurPresentation(apporteur).then((res) => {
                                        showMessage("Présentation a été envoyé", "success");
                                    })
                                        .catch((err) =>
                                            showMessage("Échec d'envoyer présentation", "error")
                                        );
                                }}
                            />
                            <PrimaryButton
                                label={`Diffusion Apporteur Trustiway`}
                                icon={<Send />}
                                onClick={() => {
                                    sendApporteurPresentation({ ...apporteur, option: 296 }).then((res) => {
                                        showMessage("Diffusion Apporteur Trustiway a été envoyé", "success");
                                    })
                                        .catch((err) =>
                                            showMessage("Échec d'envoyer Diffusion Apporteur Trustiway", "error")
                                        );
                                }}
                            />
                            <Menu
                                dropProps={{
                                    align: { top: 'bottom', left: 'left' },
                                }}
                                items={
                                    ["Pont", "Lien de l'APP à télécharger", "Pont + APP"].map((s, i) => ({
                                        label: <Text size="small" color={'#707070'} key={i}>{s}</Text>,
                                        onClick: () => {
                                            sendApporteurPresentation({ ...apporteur, option: (i + 1) }).then((res) => {
                                                showMessage("L'apport a été envoyé", "success");
                                            })
                                                .catch((err) =>
                                                    showMessage("Échec d'envoyer l'apport", "error")
                                                );
                                        }
                                    }))
                                }
                            >
                                <Box direction="row" align="center"
                                    justify="center" gap="small">
                                    <PrimaryButton
                                        label={`Faire de l'apport`}
                                        icon={<DocumentDownload />}
                                    />
                                </Box>
                            </Menu>
                        </Box>
                    </Box>
                }

            </Box>
        </Stack>

    )
}

export const ProductsCheckBoxes = ({ apporteur, group, onApporteurChange, isView, size = 1 }) => {
    return <Box>
        <Text textAlign="start" size="large" margin={'small'}><b>{group}</b> </Text>
        <MultipleLineCheckBoxes
            disabled={isView}
            size={size}
            activeColor='#E82C70'
            groups={(PRODUCTS).filter(e => e.group === group).map(value => ({
                label: value.name,
                selected: (apporteur.products || []).map(p => p.name).includes(value.name),
                onClick: () => {
                    if (isView) return;
                    let prods = apporteur.products || [];
                    if (prods.map(p => p.name).includes(value.name)) {
                        prods = prods.filter(p => p.name !== value.name);
                    } else {
                        prods.push({ ...value });
                    }
                    onApporteurChange('products', prods.map(s => s));
                },
            }))}
        />
    </Box>
}

const ApporteurDuLead = ({ apporteur, isView, onApporteurChange, commerciaux }) => {

    const [selectedCommercial, setCommercial] = useState();

    const onRemove = (id) => {
        onApporteurChange('apporteur_lead', null);
    }
    const onAdd = (commercial) => {
        setCommercial(null);
        onApporteurChange('apporteur_lead', commercial);
    }
    return (
        <Box pad={'xsmall'} gap='small' width={'full'}>
            {
                (apporteur.apporteur_lead && (
                    <Box direction="row" gap='small'>
                        <StandardText style={{ fontWeight: 'bold' }} label={getComName(apporteur.apporteur_lead)} size='small' />
                        {!isView &&
                            <Box onClick={() => onRemove(apporteur.apporteur_lead.id)}
                                direction='row' gap="small"
                            >
                                <Trash color="brand" />
                                <StandardText label={'Retirer'} size='small' />
                            </Box>
                        }
                    </Box>
                ))
            }
            {!isView &&
                <Box direction="row" gap="medium" align='center'>
                    <CustomSelect
                        options={commerciaux}
                        value={selectedCommercial?.name}
                        onChange={({ option }) => {
                            console.log(option);
                            setCommercial(option)
                        }}
                        labelKey={'name'}
                        valueKey={{ key: 'name', reduce: true }}
                    />

                    {selectedCommercial && <PrimaryButton icon={<FormCheckmark />}
                        onClick={() => onAdd(selectedCommercial)}
                        label="Valider"
                    />}
                </Box>
            }
        </Box>
    )
}

const ParentApporteur = ({ apporteur, isView, onApporteurChange }) => {

    const [selectedParent, setSelectedParent] = useState();
    const [parents, setParents] = useState([]);
    let timeoutId;

    const loadParents = (str) => {

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            getApporteurPros(0, str, {}, null, true).then(res => {
                setParents(res.data);
            }).catch(err => setParents([]));
        }, 500);


    }

    return (
        <Box pad={'xsmall'} gap='small' fill>
            {!isView &&
                <Box >
                    <Text size="small" >Rechercher la maison mère </Text>
                    <Select
                        options={parents}
                        value={selectedParent?.raisonSocial}
                        labelKey={"raisonSocial"}
                        valueKey={{ key: "raisonSocial", reduce: true }}
                        onChange={({ value: nextValue }) => {
                            setSelectedParent(nextValue);
                            let value = parents.find((s) => s.raisonSocial === nextValue);
                            if (value) {
                                onApporteurChange('apporteur_parent', value)
                                if (value.taux_fixed_for_all) {
                                    onApporteurChange('mode_remuneration', value.mode_remuneration);
                                    onApporteurChange('taux_split_network', value.taux_split_network);
                                    onApporteurChange('taux_split_apporteur', value.taux_split_apporteur);
                                    onApporteurChange('active_commission_credit', value.active_commission_credit);
                                    onApporteurChange('taux_fixed_for_all', value.taux_fixed_for_all);
                                }
                            }
                        }}
                        onSearch={(text) => {
                            const escapedText = text.replace(
                                /[-\\^$*+?.()|[\]{}]/g,
                                "\\$&"
                            );
                            loadParents(escapedText);
                        }}
                    />
                </Box>
            }
        </Box>
    )
}

const PotentialOwners = ({ apporteur, isView, onApporteurChange, commerciaux }) => {

    const [selectedCommercial, setCommercial] = useState();

    const onRemove = (id) => {
        let owners = apporteur.potential_owners || [];
        owners = owners.filter(o => o.id !== id);
        onApporteurChange('potential_owners', owners);
    }
    const onAdd = (commercial) => {
        setCommercial(null);
        let owners = apporteur.potential_owners || [];
        if (!commercial) {
            let possibles = commerciaux.filter(c => !owners.map(c => c.id).includes(c.id));
            if (possibles.length > 0) {
                commercial = possibles[Math.floor(Math.random() * possibles.length)];
            }
        }
        if (!commercial) return;
        if (!owners.map(c => c.id).includes(commercial.id)) {
            owners.push(commercial);
        }
        onApporteurChange('potential_owners', owners.map(s => s));
        setCommercial(null);
    }
    return (
        <Box pad={'xsmall'} gap='small' width={'full'}>
            {
                (apporteur.potential_owners || []).map((commercial, index) => (
                    <Box direction="row" gap='small' key={index}>
                        <StandardText style={{ fontWeight: 'bold' }} label={getComName(commercial)} size='small' />
                        {!isView &&
                            <Box onClick={() => onRemove(commercial.id)}
                                direction='row' gap="small"
                            >
                                <Trash color="brand" />
                                <StandardText label={'Retirer'} size='small' />
                            </Box>
                        }
                    </Box>
                ))
            }
            {!isView &&
                <Box direction="row" gap="medium" align='center'>
                    <CustomSelect
                        options={commerciaux}
                        value={selectedCommercial?.name}
                        onChange={({ option }) => {
                            console.log(option);
                            setCommercial(option)
                        }}
                        labelKey={'name'}
                        valueKey={{ key: 'name', reduce: true }}
                    />
                    <PrimaryButton icon={<FormCheckmark />}
                        onClick={() => onAdd(selectedCommercial)}
                        label={selectedCommercial?.name ? "Valider" : "Ajouter en aléatoire"}
                    />
                </Box>
            }
        </Box>
    )
}


const Interlocuteurs = ({ interlocuteurs = [], onApporteurChange, showMessage, isView }) => {
    const [interlocuteur, setInterlocuteur] = React.useState({});

    const onIterChange = (field, value) => {
        setInterlocuteur(Object.assign({}, value));
    }

    const addInterlocuteur = () => {
        console.log(interlocuteur)
        if (
            !interlocuteur.firstname ||
            !interlocuteur.lastname ||
            !interlocuteur.phone ||
            !interlocuteur.email
        ) {
            showMessage('Information d\'interlocuteur n\'est pas complet', 'error');
            return;
        }
        interlocuteurs.push(interlocuteur);
        setInterlocuteur({});
        onApporteurChange('interlocuteurs', interlocuteurs.map(s => s));
    }

    const removeInterlocuteur = idx => {
        interlocuteurs.splice(idx, 1)
        onApporteurChange('interlocuteurs', interlocuteurs);
    }

    const onReceivingMailChange = idx => {
        let iter = interlocuteurs[idx];
        iter.receiving_mail = iter.receiving_mail ? false : true;
        onApporteurChange('interlocuteurs', interlocuteurs.map(i => i));
    }

    return (
        <Box pad={'small'} gap="small"  >
            <Box pad={'small'} gap="small" >
                {
                    toArrayOfArray(interlocuteurs).map((interG, index) => (
                        <Box key={index} pad={'small'} gap="large" direction="row" >
                            {
                                interG.map((iter, idx) => (
                                    <Interlocuteur key={idx} idx={idx + index * 3} iter={iter} remove={() => removeInterlocuteur(idx + index * 3)}
                                        onReceivingMailChange={() => onReceivingMailChange(idx + index * 3)}
                                        disabled={isView}
                                    />
                                ))
                            }
                        </Box>
                    ))
                }
            </Box>

            {!isView &&
                <ContactSelectV2
                    contact={interlocuteur}
                    updateContact={onIterChange}
                    addressIncluded={false}
                    title='Interlocuteur'
                    size="xsmall"
                    isPro={true}
                />
            }
            {!isView &&
                <Box gap="small" width={"medium"} direction="row" align="center" justify="center">
                    <PrimaryButton icon={<Add />}
                        onClick={addInterlocuteur}
                        label="Ajouter un interlocuteur"
                    />
                </Box>
            }
        </Box>
    )
}

const UserAccountCreation = ({ apporteur, showMessage }) => {
    const [emails, setEmails] = React.useState("");
    const [emailsValid, setEmailsValid] = React.useState([]);
    const [emailsUserExistToAdd, setEmailsUserExistToAdd] = React.useState([]);
    const [emailsUserExistCanNotAdd, setEmailsUserExistCanNotAdd] = React.useState([]);
    const [emailsNonValid, setEmailsNonValid] = React.useState([]);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showAffiliates, setShowAffiliates] = React.useState(false);


    const checkAndSendEmails = () => {
        let arrEmails = emails.split("\n").map(e => e.trim());
        let arrEmailsValid = [];
        let arrEmailsNonValid = [];

        for (let i = 0; i < arrEmails.length; i++) {
            const em = arrEmails[i];
            if (em) {
                if (validateEmail(em)) {
                    arrEmailsValid.push(em);
                } else {
                    arrEmailsNonValid.push(em);
                }
            }
        }

        // validate arrEmailsValid
        validateEmailsInvitation({
            emails: arrEmailsValid,
            apporteur_pro: apporteur.id
        }).then((res) => {
            if (res.data) {
                setEmailsNonValid(arrEmailsNonValid);
                setEmailsValid(res.data.emailsInvitation);
                setEmailsUserExistToAdd(res.data.emailsUserExistToAdd);
                setEmailsUserExistCanNotAdd(res.data.emailsUserExistCanNotAdd);
                setShowConfirm(true);
            }
        }).catch((err) => {
            showMessage("Échec d'envoyer email", "error");
            setShowConfirm(false);
        });

    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const sendEmailsConnexion = () => {
        if (emailsValid.length > 0) {
            createApporteurUsers({
                emails: emailsValid,
                emailsUserExistToAdd: emailsUserExistToAdd,
                apporteur_pro: apporteur.id,
                invited_by: getCommercialId()
            }).then(() => {
                showMessage("Les emails ont été envoyé", "success");
                setShowConfirm(false);
                setEmails("");
            }).catch((err) => {
                showMessage("Échec d'envoyer email", "error");
                setShowConfirm(false);
            });
        }
    }

    return (
        <Box pad={'small'} gap="small"  >
            <Box align="end">
                <PrimaryButton icon={<Group />} label={'Voir les utilisateurs'} onClick={() => {
                    setShowAffiliates(true);
                    //loadAffiliates
                }} />

            </Box>
            <Box gap="xsmall" justify="start" align="start" >
                <Text size="xsmall" >{'Indiquer les mails pour créer les comptes utilisateur de l’apporteur '}</Text>
                <TextArea placeholder="Renseignez les adresses e-mail des apporteurs une par une, à la ligne. 
Exemple :
kevin@trustiway.com
landry@trustiway.com 
etc…
"
                    style={{ minHeight: 120, background: '#EDEDED', borderRadius: 10, border: 'none', }} size="xsmall" value={emails} onChange={event => setEmails(event.target.value)} />
            </Box>
            <PrimaryButton disabled={emails === ""} background={"green"} label={'Valider la saisie et envoyer le mail de connexion'} onClick={() => {
                checkAndSendEmails();
            }} />

            {showAffiliates && <Layer style={{ width: "90%" }} onClickOutside={() => setShowAffiliates(false)} onEsc={() => setShowAffiliates(false)} >
                <AppAffilieByApporteur apporteurId={apporteur.id} showMessage={showMessage} resendEmail={(_user) => {
                    createApporteurUsers({
                        emails: [_user.email],
                        apporteur_pro: apporteur.id,
                        invited_by: getCommercialId()
                    }).then(() => {
                        showMessage("L'email a été envoyé", "success");
                    }).catch((err) => {
                        showMessage("Échec d'envoyer email", "error");
                    });
                }} />
            </Layer>}



            {showConfirm && <Layer full='horizontal' pad='medium' onClickOutside={() => setShowConfirm(false)} onEsc={() => setShowConfirm(false)}>

                <Grid columns={["25%", "25%", "25%", "25%"]} pad={"medium"} gap="small" fill>

                    <Box fill>
                        <Text><b>Les emails replis valides</b></Text>
                        <Box style={{ height: 200, overflowY: 'scroll' }} border={{ color: "green" }} pad={'small'}>
                            {emailsValid.map(v => <Text>{v}</Text>)}
                        </Box>
                    </Box>
                    <Box fill>
                        <Text><b>Les comptes existants ajoutable</b></Text>
                        <Box style={{ height: 200, overflowY: 'scroll' }} border={{ color: "green" }} pad={'small'}>
                            {emailsUserExistToAdd.map(v => <Text>{v}</Text>)}
                        </Box>
                    </Box>
                    {emailsUserExistCanNotAdd.length > 0 && <Box fill>
                        <Text><b>Les comptes existants pas disponible</b></Text>
                        <Box style={{ height: 200, overflowY: 'scroll' }} border={{ color: "red" }} pad={'small'}>
                            {emailsUserExistCanNotAdd.map(v => <Text>{v}</Text>)}
                        </Box>
                    </Box>}

                    {emailsNonValid.length > 0 && <Box fill>
                        <Text><b>Les emails replis non valides</b></Text>
                        <Box style={{ height: 200, overflowY: 'scroll' }} border={{ color: "red" }} pad={'small'}>
                            {emailsNonValid.map(v => <Text color={'red'}>{v}</Text>)}
                        </Box>
                    </Box>}

                </Grid>
                <Box direction="row" gap="small" pad="small" align="center" justify="center">
                    <PrimaryButton background={"lightgray"} label={'Annuler'} onClick={() => {
                        setShowConfirm(false);
                    }} />
                    <PrimaryButton
                        disabled={emailsValid.length <= 0}
                        background={"green"} label={'Envoyer le mail de connexion'} onClick={() => {
                            sendEmailsConnexion();
                        }} />
                </Box>

            </Layer>}


        </Box>
    )
}

const CanalTitle = ({ text }) => (
    <Text size="small"
        style={{ textDecoration: 'underline', fontWeight: 'bold', color: '#707070' }}
    >
        {text}
    </Text>
)

export default ApporteurProInfo;

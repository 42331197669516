import React, { useEffect, useState } from "react";
import {
	Box,
	Card,
	CheckBox,
	Spinner,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
	Text,
} from "grommet";
import { getClientName } from "../../outils/client_util";
import { getSimpleDateTimeFR } from "../../../services/utils";
import {
	getAppointments,
	getBlocNotes,
	TYPE_VIEW_NAME,
	updateAppointment,
} from "./service/rdv_rappel";
import RadioButtonGroup from "../../elements/radio_group";
import { getContextBot } from "../../../services/storage_service";
import RdvModal from "./Components/RdvModal";
import moment from "moment";
import { useToast } from "@chakra-ui/core";
import { StatusGood, StatusGoodSmall } from "grommet-icons";
import ClientModal from "./Components/ClientModal";
import DevisModal from "./Components/DevisModal";
import CustomSelect from "../../elements/select_fill";

const TrustiBotRdvRappel = ({ onOpenedDialog }) => {
	const [loading, setLoading] = useState(false);
	const [listRdvRappels, setListRdvRappels] = useState([]);
	const [typeView, setTypeView] = useState(TYPE_VIEW_NAME.upcoming); // upcoming, today, late, done

	const toast = useToast();

	const loadData = (type = TYPE_VIEW_NAME.upcoming) => {
		setTypeView(type);
		const _contextBot = getContextBot();
		let client = null;
		var contract_id = null;
		var commercial = null;
		if (_contextBot) {
			if (_contextBot.client) {
				client = _contextBot.client.id;
			}
			if (_contextBot.id) {
				contract_id = _contextBot.id;
			}
			if (_contextBot.selectedCommercial) {
				commercial = _contextBot.selectedCommercial;
			}
		}
		setLoading(true);
		try {
			getAppointments(client, type, contract_id, commercial).then((res) => {
				setListRdvRappels(res.data);
				setLoading(false);
			}).catch(e => {
				console.log("error = ", e);
			});
		} catch (error) {
			console.log("error", error);
			setLoading(false);
		}
	};

	useEffect(() => {
		loadData();
		// eslint-disable-next-line
	}, []);

	const updateRdvRappel = (rdv, status) => {
		let rdvData = { id: rdv.id };
		rdvData.status = status ? "Fait" : "En attente";
		updateAppointment(rdvData)
			.then(() => {
				toast({
					title: "Réussi",
					description: "RDV / Rappel est bien modifié",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				loadData(typeView);
			})
			.catch((err) => {
				toast({
					title: "Erreur",
					description: err.message,
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			});
	};

	const getColorByTime = (rdv) => {
		const now = new Date();
		const today = moment(now).format("YYYY-MM-DD");
		if (moment(rdv.begin).isBefore(moment()) && rdv.status !== "Fait")
			return "red";
		if (moment(rdv.begin).format("YYYY-MM-DD") === today) return "#16BC00";
		if (moment(rdv.begin) < moment(now).add(7, "days") && rdv.status !== "Fait")
			return "#E3AC17";
		return "#000";
	};

	return (
		<Box margin="0 0 50px 0">
			<Box direction="row" justify="between" background="brand" style={{ borderRadius: "15px 15px 0 0" }}>
				<Text margin={"small"} size="medium">
					<b>Rappel ⚠️ {"&"} RDV 🗓</b>
				</Text>
				<Box justify="center" direction="row">
					<Box style={{ height: "50px", marginTop: 5, marginLeft: 5 }} direction="row" align="center" gap="small">
						<Text weight={600} margin="0 10px 0 0">Statut:</Text>
						<CustomSelect
							defaultValue={TYPE_VIEW_NAME.upcoming}
							options={[
								{
									label: TYPE_VIEW_NAME.upcoming,
									selected: typeView === TYPE_VIEW_NAME.upcoming,
									onClick: () => loadData(TYPE_VIEW_NAME.upcoming),
								},
								{
									label: TYPE_VIEW_NAME.today,
									selected: typeView === TYPE_VIEW_NAME.today,
									onClick: () => loadData(TYPE_VIEW_NAME.today),
								},
								{
									label: TYPE_VIEW_NAME.late,
									selected: typeView === TYPE_VIEW_NAME.late,
									onClick: () => loadData(TYPE_VIEW_NAME.late),
								},
								{
									label: TYPE_VIEW_NAME.done,
									selected: typeView === TYPE_VIEW_NAME.done,
									onClick: () => loadData(TYPE_VIEW_NAME.done),
								},
							]}
							onChange={({ option }) => {
								option.onClick();
							}}
							labelKey={'label'}
							valueKey={{ key: 'label', reduce: true }}
						/>
					</Box>
					<Box justify="center">
						<RdvModal
							callback={loadData}
							onOpenedDialog={onOpenedDialog}
							type="RDV"
							icon
							label={<Text weight={400}>Ajouter un <b>RDV</b></Text>}
						></RdvModal>
					</Box>
					<Box justify="center">
						<RdvModal
							onOpenedDialog={onOpenedDialog}
							callback={loadData}
							type="Rappel"
							icon
							label={<Text weight={400}>Ajouter un <b>rappel</b></Text>}
						></RdvModal>
					</Box>
				</Box>
			</Box>
			<Table>
				<TableHeader style={{ backgroundColor: "#F5F5F5", borderRadius: "0 0 10px 10px" }}>
					<TableRow>
						<TableCell width={"70px"}>&nbsp;&nbsp;</TableCell>
						<TableCell width={"30px"}>
							<Text size="small">
								Statut
							</Text>
						</TableCell>
						<TableCell width={"220px"}>
							<Text size="small">
								Commercial
							</Text>
						</TableCell>
						<TableCell width={"220px"}>
							<Text size="small">
								Objet
							</Text>
						</TableCell>
						<TableCell width={"100px"}>
							<Text size="small">
								Client
							</Text>
						</TableCell>
						<TableCell width={"200px"}>
							<Text size="small">
								Produit
							</Text>
						</TableCell>
						<TableCell width={"140px"}>
							<Text size="small">
								Rappel RDV
							</Text>
						</TableCell>
						<TableCell width={"140px"}>
							<Text size="small">
								Création / Modification
							</Text>
						</TableCell>
					</TableRow>
				</TableHeader>
			</Table>
			<Box style={{ overflowY: "scroll", maxHeight: 500 }}>
				{loading ? (
					<Spinner />
				) : (
					<Table>
						<TableBody>
							{listRdvRappels
								.sort((a, b) => (a.begin > b.begin ? 1 : -1))
								.map((_rdv, index) => {
									// let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
									return (
										<TableRow
											key={index + 1}
											style={{ color: getColorByTime(_rdv), fontWeight: 600 }}
										>
											<TableCell width={"70px"}>
												<RdvModal
													label="Voir"
													type={_rdv.type}
													rdvData={_rdv}
													callback={loadData}
													onOpenedDialog={onOpenedDialog}
												></RdvModal>
											</TableCell>
											<TableCell width={"30px"}>
												{!_rdv.automatic && <CheckBox
													primary
													size="small"
													checked={_rdv.status === "Fait"}
													onChange={(event) => {
														updateRdvRappel(_rdv, event.target.checked);
													}}
												>
													{({ checked }) => {
														return checked ? (
															<StatusGood color="brand"></StatusGood>
														) : (
															<StatusGoodSmall color="brand"></StatusGoodSmall>
														);
													}}
												</CheckBox>}
											</TableCell>
											<TableCell width={"220px"}>
												<Text size="small" color={getColorByTime(_rdv)}>
													{`${_rdv.commercial?.nom || ''} ${_rdv.commercial?.prenom || ''}`}
												</Text>
											</TableCell>
											<TableCell width={"220px"}>
												<Text size="small" color={getColorByTime(_rdv)}>
													{_rdv.type === "RDV" ? "🗓 " : "⚠️ "}
													{_rdv.title}
												</Text>
											</TableCell>
											<TableCell width={"100px"}>
												{/* <Text size="small">{getClientName(_rdv.client)}</Text>
													 */}
												{_rdv.client && <ClientModal label={getClientName(_rdv.client)} client={_rdv.client} callback={() => { }} ></ClientModal>}
											</TableCell>
											<TableCell width={"200px"}>
												<Text size="small">
													{_rdv.contract_name && <DevisModal label={_rdv.contract_name} devis_id={_rdv.contract_id} devis_name={_rdv.contract_name} callback={() => { }} ></DevisModal>}
													<br />
													<i>{_rdv.contract_id}</i>
												</Text>
											</TableCell>
											<TableCell width={"140px"}>
												<Text size="small">
													<b>{getSimpleDateTimeFR(_rdv.begin)}</b>
												</Text>
											</TableCell>
											<TableCell width={"140px"}>
												<Text size="small">
													{getSimpleDateTimeFR(_rdv.createdAt)}
													<br />
													<i>{getSimpleDateTimeFR(_rdv.updatedAt)}</i>
												</Text>
											</TableCell>

										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				)}
			</Box>
			{/* <Box justify="start" direction="row" margin={"xsmall"}>
					<Card
						background={"green"}
						style={{ width: 60, marginRight: 10 }}
					></Card>{" "}
					<Text size="small">À faire aujourd'hui</Text>
					<Card
						background={"orange"}
						style={{ width: 60, marginRight: 10, marginLeft: 100 }}
					></Card>{" "}
					<Text size="small">À faire dans la semaine</Text>
					<Card
						background={"red"}
						style={{ width: 60, marginRight: 10, marginLeft: 100 }}
					></Card>{" "}
					<Text size="small">En retard</Text>
				</Box> */}
		</Box>
	);
};

export default TrustiBotRdvRappel;

import { Box, Heading, Text } from "grommet";
import DateInput from "../../../elements/date_input";
import React, { useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import {
  BIEN_TYPE,
  CONSTRUCTION_TYPE,
  COVER_TYPE,
  FLOOR,
  FLOOR_MH,
  FRACTIONNEMENT_TYPE,
  LEVEL,
  OUT_HOUSE_DURATION,
  RESIDENCE_TYPE,
} from "../../../../services/types";
import ContactSelect, { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import ClickableCards from "../../../elements/clickable_cards";
import CustomInput from "../../../elements/custom_input";
import house from "../../../../images/house.png";
import appt from "../../../../images/appt.png";
import Line from "../../../elements/line_container";
import StandardText from "../../../elements/standard_text";
import CustomSelect from "../../../elements/select_fill";
import ToggleButton from "../../../elements/toggle_button/toggle_button";
import moment from "moment";
import { AddCircle, Trash } from "grommet-icons";
import PrimaryButton from "../../../elements/primary_button";

const YES_NO = [true, false];
const REMPLACEMENT_TYPE = {
  aucun: "Aucun",
  neuf: "Valeur de remplacement à neuf",
  neuf_integral: "Valeur à neuf intégrale",
};
const FOYER_TYPE = ["1", "2 et plus"];

const MHInfo = ({ devis, updateDevis, showMessage }) => {
  const [valueObjectOptions, setValueObjectOptions] = React.useState([]);

  return (
    <Box
      gap="small"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Box justify="center" alignSelf="center">
        <Heading level={3} textAlign="center">{`Informations du bien`}</Heading>
      </Box>

      <ContactSelectV2
        contact={devis.client || {}}
        updateContact={updateDevis}
        addressIncluded={true}
      />
      <Box width="large" gap="medium" margin="medium">
        <Box gap="xsmall">
          <Text style={{ fontWeight: "bold" }} textAlign="center">
            Renseigner l'information du bien
          </Text>

          <Line
            leftLabel="Quel type de logement"
            rightChildren={
              <ClickableCards
                cards={[
                  {
                    img: house,
                    text: BIEN_TYPE.maison,
                    selected:
                      devis.bien_type === BIEN_TYPE.maison ? "solid" : "none",
                    onClick: () => updateDevis("bien_type", BIEN_TYPE.maison),
                  },
                  {
                    img: appt,
                    text: BIEN_TYPE.appartement,
                    selected:
                      devis.bien_type === BIEN_TYPE.appartement
                        ? "solid"
                        : "none",
                    onClick: () =>
                      updateDevis("bien_type", BIEN_TYPE.appartement),
                  },
                ]}
              />
            }
          />
          <Box style={{ width: "100%" }} gap="xsmall">
            <Line
              leftLabel="Adresse du bien"
              rightChildren={
                <ReactGoogleAutocomplete
                  value={devis.location_address}
                  style={{
                    background: "#EDEDED",
                    padding: 11,
                    borderRadius: 10,
                    fontSize: 16,
                    border: "none",
                    width: "100%",
                    height: 44,
                  }}
                  apiKey={"AIzaSyB9n7vwM3pC2E0O_X96m3VSbloepeaDOsA"}
                  placeholder=""
                  onPlaceSelected={(place) => {
                    let parts = place.address_components;
                    console.log(place);
                    let _address = parts
                      .filter(
                        (s) =>
                          s.types &&
                          (s.types.includes("street_number") ||
                            s.types.includes("route"))
                      )
                      .map((s) => s.long_name)
                      .join(" ");
                    let _zip = parts
                      .filter((s) => s.types && s.types.includes("postal_code"))
                      .map((s) => s.long_name)
                      .join("");
                    let _city = parts
                      .filter((s) => s.types && s.types.includes("locality"))
                      .map((s) => s.long_name)
                      .join("");
                    updateDevis("location_address", _address);
                    updateDevis("location_city", _city);
                    updateDevis("location_zip", _zip);
                  }}
                  options={{
                    types: ["address"],
                    componentRestrictions: { country: "fr" },
                  }}
                  onChange={(event) => {
                    updateDevis("location_address", event.target.value);
                  }}
                />
              }
            />
            <Line
              leftLabel="Code postal"
              rightChildren={
                <CustomInput
                  value={devis.location_zip}
                  onChange={(event) =>
                    updateDevis("location_zip", event.target.value)
                  }
                />
              }
            />
            <Line
              leftLabel="Ville"
              rightChildren={
                <CustomInput
                  value={devis.location_city}
                  onChange={(event) =>
                    updateDevis("location_city", event.target.value)
                  }
                />
              }
            />
          </Box>

          <Line
            leftLabel="Le client est"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: "Locataire",
                    selected: !devis.house_owner,
                    onClick: () => updateDevis("house_owner", false),
                  },
                  {
                    label: "Propriétaire",
                    selected: devis.house_owner,
                    onClick: () => updateDevis("house_owner", true),
                  },
                ]}
              />
            }
          />

          <Line
            leftLabel="Type de résidence"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: RESIDENCE_TYPE.principale,
                    selected:
                      devis.residence_type === RESIDENCE_TYPE.principale,
                    onClick: () =>
                      updateDevis("residence_type", RESIDENCE_TYPE.principale),
                  },
                  {
                    label: RESIDENCE_TYPE.secondaire,
                    selected:
                      devis.residence_type === RESIDENCE_TYPE.secondaire,
                    onClick: () =>
                      updateDevis("residence_type", RESIDENCE_TYPE.secondaire),
                  },
                ]}
              />
            }
          />
          {devis.bien_type === BIEN_TYPE.appartement && (
            <Line
              leftLabel="Etage"
              rightChildren={
                <ToggleButton
                  fontSize={14}
                  groups={[
                    {
                      label: FLOOR_MH.rdc,
                      selected: devis.appt_floor === FLOOR.rdc,
                      onClick: () => updateDevis("appt_floor", FLOOR.rdc),
                    },
                    {
                      label: FLOOR_MH.first_stage,
                      selected: devis.appt_floor === FLOOR.first_stage,
                      onClick: () =>
                        updateDevis("appt_floor", FLOOR.first_stage),
                    },
                    {
                      label: FLOOR_MH.intermediaire,
                      selected: devis.appt_floor === FLOOR.intermediaire,
                      onClick: () =>
                        updateDevis("appt_floor", FLOOR.intermediaire),
                    },
                    {
                      label: FLOOR_MH.dernier,
                      selected: devis.appt_floor === FLOOR.dernier,
                      onClick: () => updateDevis("appt_floor", FLOOR.dernier),
                    },
                  ]}
                />
              }
            />
          )}
          {devis.bien_type === BIEN_TYPE.maison && (
            <Line
              leftLabel="Niveau"
              rightChildren={
                <ToggleButton
                  fontSize={14}
                  groups={[
                    {
                      label: LEVEL.one_level_wtho_ss,
                      selected: devis.house_level === LEVEL.one_level_wtho_ss,
                      onClick: () =>
                        updateDevis("house_level", LEVEL.one_level_wtho_ss),
                    },
                    {
                      label: LEVEL.one_level_wth_ss,
                      selected: devis.house_level === LEVEL.one_level_wth_ss,
                      onClick: () =>
                        updateDevis("house_level", LEVEL.one_level_wth_ss),
                    },
                    {
                      label: LEVEL.sev_level_wtho_ss,
                      selected: devis.house_level === LEVEL.sev_level_wtho_ss,
                      onClick: () =>
                        updateDevis("house_level", LEVEL.sev_level_wtho_ss),
                    },
                    {
                      label: LEVEL.sev_level_wth_ss,
                      selected: devis.house_level === LEVEL.sev_level_wth_ss,
                      onClick: () =>
                        updateDevis("house_level", LEVEL.sev_level_wth_ss),
                    },
                  ]}
                />
              }
            />
          )}

          <Line
            leftLabel="Nombre de pièces"
            rightChildren={
              <CustomSelect
                style={{ width: "100%" }}
                placeholder="Nombre de pièce"
                options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                value={devis.total_rooms}
                onChange={({ option }) => updateDevis("total_rooms", option)}
              />
            }
          />
          <Line
            leftLabel="Surface"
            rightChildren={
              <CustomInput
                size={"small"}
                placeholder="m2"
                value={devis.surface}
                onChange={(event) => updateDevis("surface", event.target.value)}
              />
            }
          />
          <Line
            leftLabel="Capitaux mobiliers"
            rightChildren={
              <CustomInput
                size={"small"}
                placeholder="€"
                value={devis.capital}
                onChange={(event) => updateDevis("capital", event.target.value)}
              />
            }
          />
          <Line
            leftLabel="dont objet de valeur"
            rightChildren={
              <CustomInput
                size={"small"}
                placeholder="€"
                value={devis.value_object}
                onChange={(event) => updateDevis("value_object", event.target.value)}
              />
            }
          />

          {devis.residence_type === RESIDENCE_TYPE.principale && (
            <Line
              leftLabel="Période d'inhabitation"
              rightChildren={
                <ToggleButton
                  fontSize={14}
                  groups={[
                    {
                      label: OUT_HOUSE_DURATION.short,
                      selected:
                        devis.out_house_duration === OUT_HOUSE_DURATION.short,
                      onClick: () =>
                        updateDevis(
                          "out_house_duration",
                          OUT_HOUSE_DURATION.short
                        ),
                    },
                    {
                      label: OUT_HOUSE_DURATION.medium,
                      selected:
                        devis.out_house_duration === OUT_HOUSE_DURATION.medium,
                      onClick: () =>
                        updateDevis(
                          "out_house_duration",
                          OUT_HOUSE_DURATION.medium
                        ),
                    },
                    {
                      label: OUT_HOUSE_DURATION.long,
                      selected:
                        devis.out_house_duration === OUT_HOUSE_DURATION.long,
                      onClick: () =>
                        updateDevis(
                          "out_house_duration",
                          OUT_HOUSE_DURATION.long
                        ),
                    },
                  ]}
                />
              }
            />
          )}
          <Line
            leftLabel="Type de construction"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: CONSTRUCTION_TYPE.wood,
                    selected:
                      devis.construction_type === CONSTRUCTION_TYPE.wood,
                    onClick: () =>
                      updateDevis("construction_type", CONSTRUCTION_TYPE.wood),
                  },
                  {
                    label: CONSTRUCTION_TYPE.other,
                    selected:
                      devis.construction_type === CONSTRUCTION_TYPE.other,
                    onClick: () =>
                      updateDevis("construction_type", CONSTRUCTION_TYPE.other),
                  },
                ]}
              />
            }
          />
          <Line
            leftLabel="Type de couverture"
            rightChildren={
              <CustomSelect
                style={{ width: "100%" }}
                placeholder="Type de couverture"
                options={COVER_TYPE}
                value={devis.cover_type}
                onChange={({ option }) => updateDevis("cover_type", option)}
              />
            }
          />
          <Line
            leftLabel="La cliente effectue-t-elle de la location saisonnière ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: devis.location_saisonniere === value,
                  onClick: () => updateDevis("location_saisonniere", value),
                }))}
              />
            }
          />
          <Line
            leftLabel="La cliente souhaite-t-elle une assurance scolaire pour leur enfants ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: devis.scolaire === value,
                  onClick: () => updateDevis("scolaire", value),
                }))}
              />
            }
          />
          {devis.scolaire &&
            <Line
              leftLabel=""
              rightChildren={
                <Enfants
                  devis={devis}
                  updateDevis={updateDevis}
                  showMessage={showMessage}
                />
              }
            />
          }
          <Line
            leftLabel="Y a-t-il un foyer ouvert ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: devis.opened_foyer === value,
                  onClick: () => updateDevis("opened_foyer", value),
                }))}
              />
            }
          />
          {devis.opened_foyer && (
            <Line
              leftLabel="Nombre de foyer ouvert"
              rightChildren={
                <ToggleButton
                  groups={FOYER_TYPE.map((value) => ({
                    label: value,
                    selected: devis.opened_foyer_number === value,
                    onClick: () => updateDevis("opened_foyer_number", value),
                  }))}
                />
              }
            />
          )}
          <Line
            leftLabel="Y a-t-il un foyer fermé ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: devis.closed_foyer === value,
                  onClick: () => updateDevis("closed_foyer", value),
                }))}
              />
            }
          />
          {devis.closed_foyer && (
            <Line
              leftLabel="Nombre de foyer fermé"
              rightChildren={
                <ToggleButton
                  groups={FOYER_TYPE.map((value) => ({
                    label: value,
                    selected: devis.closed_foyer_number === value,
                    onClick: () => updateDevis("closed_foyer_number", value),
                  }))}
                />
              }
            />
          )}
          <Line
            leftLabel="Y a-t-il une piscine ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: devis.piscine === value,
                  onClick: () => updateDevis("piscine", value),
                }))}
              />
            }
          />
          {devis.piscine && (
            <Line
              leftLabel="Capital piscine et installations"
              rightChildren={
                <CustomInput
                  size={"small"}
                  placeholder="€"
                  value={devis.piscine_capital}
                  onChange={(event) =>
                    updateDevis("piscine_capital", event.target.value)
                  }
                />
              }
            />
          )}
          <Line
            leftLabel="Y a-t-il des installations extérieures ou terrasse ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: devis.external_installation === value,
                  onClick: () => updateDevis("external_installation", value),
                }))}
              />
            }
          />
          {devis.external_installation && (
            <Line
              leftLabel="Capital installations extérieures ou terrasses"
              rightChildren={
                <CustomInput
                  size={"small"}
                  placeholder="€"
                  value={devis.external_installation_capital}
                  onChange={(event) =>
                    updateDevis(
                      "external_installation_capital",
                      event.target.value
                    )
                  }
                />
              }
            />
          )}
          <Line
            leftLabel="Y a-t-il des installations énergie développement durable ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: devis.energy_installation === value,
                  onClick: () => updateDevis("energy_installation", value),
                }))}
              />
            }
          />
          {devis.energy_installation && (
            <Line
              leftLabel="Capital installations Dev Durable"
              rightChildren={
                <CustomInput
                  size={"small"}
                  placeholder="€"
                  value={devis.energy_installation_capital}
                  onChange={(event) =>
                    updateDevis(
                      "energy_installation_capital",
                      event.target.value
                    )
                  }
                />
              }
            />
          )}
          <Line
            leftLabel="Y a-t-il une véranda ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: devis.veranda === value,
                  onClick: () => updateDevis("veranda", value),
                }))}
              />
            }
          />
          {devis.veranda && (
            <Line
              leftLabel="dont surface"
              rightChildren={
                <CustomInput
                  size={"small"}
                  placeholder="m2"
                  value={devis.veranda_surface}
                  onChange={(event) =>
                    updateDevis("veranda_surface", event.target.value)
                  }
                />
              }
            />
          )}
          <Line
            leftLabel="Y a-t-il une cave ou un box ou un garage ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: devis.box === value,
                  onClick: () => updateDevis("box", value),
                }))}
              />
            }
          />
          {devis.box && (
            <Line
              leftLabel="dont surface"
              rightChildren={
                <CustomInput
                  size={"small"}
                  placeholder="m2"
                  value={devis.box_surface}
                  onChange={(event) =>
                    updateDevis("box_surface", event.target.value)
                  }
                />
              }
            />
          )}
          {devis.bien_type === BIEN_TYPE.appartement && (
            <Line
              leftLabel="Surface dépendance(s) mitoyenne(s) ?"
              rightChildren={
                <ToggleButton
                  groups={YES_NO.map((value) => ({
                    label: value === true ? "Oui" : "Non",
                    selected: devis.dependence_mitoyenne === value,
                    onClick: () => updateDevis("dependence_mitoyenne", value),
                  }))}
                />
              }
            />
          )}
          {devis.dependence_mitoyenne &&
            devis.bien_type === BIEN_TYPE.appartement && (
              <Line
                leftLabel="dont surface"
                rightChildren={
                  <CustomInput
                    size={"small"}
                    placeholder="m2"
                    value={devis.dependence_mitoyenne_surface}
                    onChange={(event) =>
                      updateDevis(
                        "dependence_mitoyenne_surface",
                        event.target.value
                      )
                    }
                  />
                }
              />
            )}
          {devis.bien_type === BIEN_TYPE.appartement && (
            <Line
              leftLabel="Surface dépendance(s) non mitoyenne(s) ?"
              rightChildren={
                <ToggleButton
                  groups={YES_NO.map((value) => ({
                    label: value === true ? "Oui" : "Non",
                    selected: devis.dependence_non_mitoyenne === value,
                    onClick: () =>
                      updateDevis("dependence_non_mitoyenne", value),
                  }))}
                />
              }
            />
          )}
          {devis.dependence_non_mitoyenne &&
            devis.bien_type === BIEN_TYPE.appartement && (
              <Line
                leftLabel="dont surface"
                rightChildren={
                  <CustomInput
                    size={"small"}
                    placeholder="m2"
                    value={devis.dependence_non_mitoyenne_surface}
                    onChange={(event) =>
                      updateDevis(
                        "dependence_non_mitoyenne_surface",
                        event.target.value
                      )
                    }
                  />
                }
              />
            )}
          <Line
            leftLabel="Année de construction"
            rightChildren={
              <CustomInput
                size={"small"}
                value={devis.construction_year}
                onChange={(event) =>
                  updateDevis("construction_year", event.target.value)
                }
              />
            }
          />
          <Line
            leftLabel="Quel type de remplacement du mobilier souhaites le client ?"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: REMPLACEMENT_TYPE.aucun,
                    selected:
                      devis.remplacement_type === REMPLACEMENT_TYPE.aucun,
                    onClick: () =>
                      updateDevis("remplacement_type", REMPLACEMENT_TYPE.aucun),
                  },
                  {
                    label: REMPLACEMENT_TYPE.neuf,
                    selected:
                      devis.remplacement_type === REMPLACEMENT_TYPE.neuf,
                    onClick: () =>
                      updateDevis("remplacement_type", REMPLACEMENT_TYPE.neuf),
                  },
                  {
                    label: REMPLACEMENT_TYPE.neuf_integral,
                    selected:
                      devis.remplacement_type ===
                      REMPLACEMENT_TYPE.neuf_integral,
                    onClick: () =>
                      updateDevis(
                        "remplacement_type",
                        REMPLACEMENT_TYPE.neuf_integral
                      ),
                  },
                ]}
              />
            }
          />

          <Line
            leftLabel="Le client a-t-il déjà eu des sinistres ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: devis.sinistre === value,
                  onClick: () => updateDevis("sinistre", value),
                }))}
              />
            }
          />

          <Line
            leftLabel="Type de fractionnement"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: FRACTIONNEMENT_TYPE.annual,
                    selected:
                      devis.fractionnement_type === FRACTIONNEMENT_TYPE.annual,
                    onClick: () =>
                      updateDevis(
                        "fractionnement_type",
                        FRACTIONNEMENT_TYPE.annual
                      ),
                  },
                  {
                    label: FRACTIONNEMENT_TYPE.monthly,
                    selected:
                      devis.fractionnement_type === FRACTIONNEMENT_TYPE.monthly,
                    onClick: () =>
                      updateDevis(
                        "fractionnement_type",
                        FRACTIONNEMENT_TYPE.monthly
                      ),
                  },
                ]}
              />
            }
          />

          <Line
            leftLabel="Date à laquelle vous souhaitez débuter votre assurance"
            rightChildren={
              <DateInput
                placeholder="Date début"
                value={devis.begin_date}
                onChange={(date) => updateDevis("begin_date", date)}
              />
            }
          />

          <Line
            leftLabel="Date de résiliation"
            rightChildren={
              <DateInput
                placeholder="Date de résiliationt"
                value={devis.date_resiliation}
                onChange={(date) => updateDevis("date_resiliation", date)}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

const Enfants = ({ devis, updateDevis, showMessage }) => {

  const [enfant, setEnfant] = useState({});

  const onEnfantChange = (field, value) => {
    enfant[field] = value;
    setEnfant({ ...enfant });
  }

  const addEnfant = () => {
    if (!enfant.nom || !enfant.prenom || !enfant.date_de_naissance) {
      showMessage('Tous les champs sont requis', 'error');
      return;
    }
    enfant.date_de_naissance = moment(enfant.date_de_naissance).format('DD/MM/YYYY');
    let enfants = devis.enfants || [];
    enfants.push(enfant);
    updateDevis('enfants', enfants.map(s => s));
    setEnfant({});
  }

  const onRemove = (e) => {
    let enfants = devis.enfants || [];
    enfants = enfants.filter(en => e.nom !== en.nom && en.prenom !== e.prenom);
    updateDevis('enfants', enfants.map(s => s));
  }

  return (
    <Box width={'full'} gap='xsmall' pad={'small'}>
      <Box pad={'small'} >
        {(devis.enfants || []).map((c, index) => (
          <Box direction="row" gap="small" align="center" key={index}>
            <StandardText style={{ fontWeight: 'bold' }} label={`${c.nom} ${c.prenom}   ${c.date_de_naissance}`}
              size='small'
            />
            <Box align="center" onClick={() => onRemove(c)}>
              <Trash color="brand" size="small" />
            </Box>
          </Box>
        ))}
      </Box>

      <StandardText label="Comment s'appelle-t-il ?" size={'small'} />
      <Box direction="row" gap="small">
        <CustomInput placeholder={'Nom'}
          size={'small'}
          value={enfant.nom}
          onChange={event => onEnfantChange('nom', event.target.value)}
        />
        <CustomInput placeholder={'Prénom'}
          size={'small'}
          value={enfant.prenom}
          onChange={event => onEnfantChange('prenom', event.target.value)}
        />
      </Box>
      <StandardText label="Indiquer sa date de naissance" size={'small'} />
      <Box direction="row" gap="small">
        <DateInput
          size={'small'}
          value={enfant.date_de_naissance}
          onChange={(date) => onEnfantChange("date_de_naissance", date)}
        />
      </Box>
      <Box width={'small'}><PrimaryButton label={"Ajouter cet enfant"}
        onClick={addEnfant}
      /></Box>

    </Box>
  )
}

export default MHInfo;

import React from "react";
import { Box, Button, Card, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { getLeadImmobiliers } from "../service";
import { getSimpleDateFR, getSorted } from "../../../services/utils";
import Refresh from "../../elements/refresh";
import SearchComponent from "../../elements/search";
import DateSort from "../../elements/date_sort";
import { AddCircle, StatusGoodSmall } from "grommet-icons";
class LeadImmobilierList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      popupOpen: false,
      selectedUser: {},
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    };
  }
  componentDidMount = () => {
    this.loadLeadImmobiliers();
  };

  loadLeadImmobiliers = () => {
    this.setState({ leads: [] }, () => {
      getLeadImmobiliers()
        .then((res) => {
          this.setState({ leads: res.data });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false });
    this.loadLeadImmobiliers();
  };

  openUser = (user) => {
    this.setState({ selectedUser: user, popupOpen: true });
  };

  addUser = () => this.openUser({});

  search = (query) => {
    getLeadImmobiliers(query)
      .then(res => {
        if (res.data?.results) {
          this.setState({ leads: res.data?.results })
        } else if (res.data) {
          this.setState({ leads: res.data })
        }
      })
      .catch(err => {
        console.log('err', err);
        this.props.showMessage('Erreur inattendu');
      })
  }

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  render() {
    return (
      <Box style={{ width: "100%" }}
        align="center" justify="center"
        alignSelf="center"
        alignContent="center" gap="medium">
        <Text size="xxlarge" style={{ padding: 10 }}><b>Lead Mandat Immobilier</b></Text>
        <Box gap="medium">
          <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
            <Refresh refresh={this.loadLeadImmobiliers} />
            <SearchComponent
              search={value => {
                this.search(value, this.state.filter._query);
              }}
              query={this.state.filter._query}
              updateQuery={value => this.updateFilter('_query', value)}
            />
            <DateSort
              onChange={value => this.updateFilter('sort', value)}
            />
          </Box>


          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Text>Création/<br />Modification</Text>
                </TableCell>
                <TableCell>
                  <Text>Nom</Text>
                </TableCell>
                <TableCell>
                  <Text>Prénom</Text>
                </TableCell>
                <TableCell>
                  <Text>Phone</Text>
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Apporteur</TableCell>
                <TableCell>Agent</TableCell>
                <TableCell>Prix</TableCell>
                <TableCell>Commission</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getSorted(this.state.leads, this.state.filter.sort).map((lead, index) => (
                <TableRow key={index + 1}>
                  <TableCell>
                    <Text size="small"><strong>{getSimpleDateFR(lead.createdAt)}</strong><br />{getSimpleDateFR(lead.updatedAt)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.client?.lastname}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.client?.firstname}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.client?.phone}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.client?.email}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.status}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.apporteur ? `${lead.apporteur.prenom} ${lead.apporteur.nom}` : ''}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.team_leader ? `${lead.team_leader.prenom} ${lead.team_leader.nom}` : ''}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.immobilier_prix}€</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.immobilier_commission}€</Text>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  }
}
export default LeadImmobilierList;

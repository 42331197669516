import { Box, Tab, Tabs } from "grommet";
import React from "react";
import Contact from "./contacts";
import Entreprises from "./entreprises/entreprises";

class MyContacts extends React.Component {
  render() {
    return (
      <Box
        align="center"
        justify="center"
        style={{ width: "100%", paddingTop: 20 }}
      >
        <Tabs>
          <Tab title="Contact">
            <Contact showMessage={this.props.showMessage} />
          </Tab>
          <Tab title="Entreprise">
            <Entreprises showMessage={this.props.showMessage} />
          </Tab>
        </Tabs>
      </Box>
    );
  }
}

export default MyContacts;

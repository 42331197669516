import { CircularProgress } from '@material-ui/core';
import { Box, Card, CheckBox, DataTable, Grid, Text } from 'grommet';
import { Calculator, Checkmark, Clear, Close, DocumentExcel, Edit, Filter, StatusGoodSmall, Trash, User } from "grommet-icons";
import moment from 'moment';
import React, { useState } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { getGestionCollabs } from '../../../services/dashboard';
import { hasAdminRole, hasVoyageManagerRole } from '../../../services/storage_service';
import { getSimpleDateFR, toCurrency } from '../../../services/utils';
import CustomInput from '../../elements/custom_input';
import CustomPagination from '../../elements/custom_pagination';
import DateInputString from '../../elements/date_input_string';
import PopupConfirmation, { TypeToConfirmation } from '../../elements/popup_confirmation';
import PrimaryButton from '../../elements/primary_button';
import CustomSelect from '../../elements/select_fill';
import { clearCache, getAgences } from '../agences/service';
import VoyageFilter from '../components/filter/voyage_filter';
import Search from '../components/search';
import SubscriptionDetail, { calculeComptableVoyage } from '../components/subscription_detail';
import ViewMode, { DISPLAY_MODE } from '../components/view_mode';
import products from '../utils/types';
import { billingSubscriptions, bulkDelete, countSubscriptions, getSubscriptions, getSubsReport, patchSubscription, selectAll } from './service';
import Subscription from './souscription';
import DateInput from '../../elements/date_input';

class Subscriptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptions: [],
            displayMode: DISPLAY_MODE.line,
            query: '',
            selectedSub: undefined,
            filterPopup: false,
            startIndex: 0,
            total: 0,
            users: [],
            agences: [],
            filter: {
            },
            selectedSubs: [],
            loading: false,
            report: {},
            sort: 'date_de_souscription:desc'
        };
    }

    componentDidMount() {
        this.reload();
        getGestionCollabs()
            .then(res => this.setState({ users: res.data }))
            .catch(err => console.log(err));
        getAgences(0, '')
            .then(res => this.setState({ agences: res.data }));
    }

    reload = () => {
        this.setState({ loading: true });
        this.setState({ startIndex: 0 }, () => getSubscriptions({ agence: this.props.agence, query: this.state.query, filter: this.state.filter, sort: this.state.sort })
            .then(res => {
                this.setState({ subscriptions: res.data, loading: false });
                countSubscriptions({ agence: this.props.agence, query: this.state.query, filter: this.state.filter })
                .then(res => {
                    this.setState({ total: res.data });
                });
            }).catch(err => {
                console.log(err);
                this.setState({ loading: false });
                countSubscriptions({ agence: this.props.agence, query: this.state.query, filter: this.state.filter })
                .then(res => {
                    this.setState({ total: res.data });
                });
            }))
        this.getSimpleReport();
    }

    getSimpleReport = () => {
        if (this.props.agence) {
            getSubsReport({ agence: this.props.agence, query: this.state.query, filter: this.state.filter })
                .then(res => this.setState({ report: res.data }));
        }
    }

    onNextPage = ({ startIndex }) => {
        this.setState({ startIndex, loading: true }, () => {
            getSubscriptions({ agence: this.props.agence, query: this.state.query, start: startIndex, filter: this.state.filter, sort: this.state.sort })
                .then(res => {
                    this.setState({ subscriptions: res.data, loading: false });
                }).catch(err => this.setState({loading: false}))
        });
    }

    selectSub = sub => this.setState({ selectedSub: sub })

    search = q => {
        this.setState({ query: q }, () => {
            this.reload();
        })
    }

    searchAgence = q => {
        getAgences(0, q)
            .then(res => this.setState({ agences: res.data }));
    }

    export = async () => {
        this.setState({ loading: true });
        try {
            let total_subs = [];
            let start = 0, limit = 500;
            let res = await getSubscriptions({ agence: this.props.agence, query: this.state.query, filter: this.state.filter, limit, start });
            while (res.data.length > 0) {
                total_subs = total_subs.concat(res.data);
                start = start + res.data.length;
                res = await getSubscriptions({ agence: this.props.agence, query: this.state.query, filter: this.state.filter, limit, start });
            }
            let headers = [
                { label: "Id", prop: 'id' },
                { label: "N° contrat", prop: 'contrat_numero' },
                { label: "Etat", prop: 'status_payment' },
                { label: "Produit", prop: 'produit' },
                { label: 'Partenaire', prop: 'owner' },
                { label: 'Propriétaire', prop: 'commercial_id' },
                { label: 'Nom', prop: 'nom_locataire' },
                { label: 'Prénom', prop: 'prenom_du_locataire' },
                { label: 'Adresse client', prop: 'adresse_du_locataire' },
                { label: 'Adresse location', prop: 'adresse_du_risque' },
                { label: 'Mail client', prop: 'mail_locataire' },
                { label: 'Date de souscription', prop: 'date_de_souscription' },
                { label: 'Date début de séjour', prop: 'date_de_debut_de_sejour' },
                { label: 'Date fin de séjour', prop: 'date_de_fin_de_sejour' },
                { label: 'Prix loc.', prop: 'prix_sejour', numeric: true },
                { label: 'Prime TTC', prop: 'prime_ttc', numeric: true },
                { label: 'Taxe', prop: 'taxe', numeric: true },
                { label: 'Prime HT', prop: 'prime_ht', numeric: true },
                { label: 'Commission', prop: 'commission_annuelle', numeric: true },
                { label: 'Part Trustiway', prop: 'part_trustiway', numeric: true },
                { label: 'Retro client', prop: 'retrocession_client', numeric: true },
                { label: 'Part Trustiway - net', prop: 'part_trustiway_net', numeric: true },
                { label: 'Part Compagnie', prop: 'part_compagnie', numeric: true },
                { label: 'Part Compagnie - com', prop: 'part_compagnie_com', numeric: true },
                { label: 'Prime hors com.', prop: 'prime_hors_com', numeric: true },
                { label: 'Total Trustiway', prop: 'total_trustiway', numeric: true },
                { label: 'Moyen de paiement', prop: 'mode_de_payment' }
            ]
            let users = this.state.users;
            const csvContent = headers.map(s => s.label).join(';') + '\n' + total_subs.map(sub => {
                calculeComptableVoyage(sub);
                sub.prime_hors_com = parseFloat((sub.prime_ttc - (sub.retrocession_client || 0)).toFixed(2));
                sub.taxe = parseFloat(sub.prime_ttc - sub.prime_ht).toFixed(2);
                sub.part_trustiway_net = parseFloat(sub.part_trustiway + sub.commission_annuelle - (sub.retrocession_client || 0)).toFixed(2);
                sub.part_compagnie_com = parseFloat(sub.part_compagnie - sub.commission_annuelle).toFixed(2);

                return headers.map(s => {
                    let prop = s.prop;
                    if (prop === 'owner') return sub.owner ? sub.owner.raisonSocial.replace(/(\r\n|\n|\r)/gm, " ").replace(/;/g, '') : '';
                    if (prop === 'commercial_id') {
                        let commerical = sub.commercial_id ? users.find(p => p.id === sub.commercial_id) : null;
                        return commerical ? commerical.name.replace(/(\r\n|\n|\r)/gm, " ") : ''
                    }
                    let text = (sub[prop] || '') + '';
                    if(s.numeric) {
                        text = parseFloat(text || 0).toFixed(2).replace('.', ',');
                        console.log('text: ', text);
                    }
                    return text.replace(/(\r\n|\n|\r)/gm, " ").replace(/;/g, ',');
                }).join(';');
            }).join('\n');


            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var blob = new Blob(["\uFEFF" + csvContent], { encoding: "UTF-8", type: "text/csv;charset=UTF-8" }),
                url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `Souscriptions_${getSimpleDateFR(new Date()).replace(/\//g, '-')}.csv`;
            a.click();
            window.URL.revokeObjectURL(url);

            this.setState({ loading: false });
            this.props.showMessage('Exporté !', 'success');
        } catch (err) {
            this.setState({ loading: false });
            console.log(err);
            this.props.showMessage('Erreur inattendu', 'error');
        }

    }

    selectAll = async (all, checked) => {
        console.log(all, checked);
        if (!checked) {
            this.setState({ selectedSubs: [] });
            return;
        }
        if (all) {
            this.setState({ loading: true });
            let res = await selectAll({ agence: this.props.agence, query: this.state.query, filter: this.state.filter });
            console.log(res.data.length);
            this.setState({ loading: false, selectedSubs: res.data });
        } else {
            this.setState({ selectedSubs: this.state.subscriptions.map(s => s.id) });
        }
    }

    selectOne = (id, checked) => {
        let selectedSubs = this.state.selectedSubs;
        if (checked) {
            selectedSubs.push(id);
        } else {
            selectedSubs = selectedSubs.filter(sId => sId !== id);
        }
        this.setState({ selectedSubs })
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box
                    style={{ width: "100%" }}
                    justify="center"
                    alignContent="center"
                    align="center"
                    pad='medium'
                >
                    {this.state.selectedSub &&
                        <Subscription subscription={this.state.selectedSub}
                            onClose={() => this.setState({ selectedSub: undefined })}
                            showMessage={this.props.showMessage}
                            onReload={(updatedSub) => {
                                this.reload();
                                this.setState({ selectedSub: updatedSub })
                            }}
                        />
                    }
                    {!this.state.selectedSub &&
                        <Box
                            style={{ width: "100%" }}
                            justify="center"
                            alignContent="center"
                            align="center"
                            pad='medium'
                            id='header-id'
                        >
                            {this.props.agence && (<Box direction='row' justify='center' align='center' gap='medium'>
                                <Card pad={'small'}>
                                    <Text size='small' textAlign='center'>
                                        Total Trustiway <br />
                                        {this.state.report.total_trustiway !== undefined ? <b>{toCurrency(this.state.report.total_trustiway)}</b> : <CircularProgress size={20} />}
                                    </Text>
                                </Card>
                                <Card pad={'small'}>
                                    <Text size='small' textAlign='center'>
                                        Total Prime <br />
                                        {this.state.report.prime !== undefined ? <b>{toCurrency(this.state.report.prime)}</b> : <CircularProgress size={20} />}
                                    </Text>
                                </Card>
                                <Card pad={'small'}>
                                    <Text size='small' textAlign='center'>
                                        Total Prime Hors Com.<br />
                                        {this.state.report.prime_hors_com !== undefined ? <b>{toCurrency(this.state.report.prime_hors_com)}</b> : <CircularProgress size={20}/>}
                                    </Text>
                                </Card>
                                <Card pad={'small'} onClick={async () => {
                                    await clearCache();
                                    this.setState({report: {}})
                                    this.getSimpleReport();
                                }}>
                                    <Clear color='brand'/>
                                </Card>
                            </Box>)}
                            <Box direction="row" pad={'small'} gap='small' style={{ width: "100%" }} align="center">
                                <ViewMode
                                    currentMode={this.state.displayMode}
                                    toLineMode={() => this.setState({ displayMode: DISPLAY_MODE.line })}
                                    toGridMode={() => this.setState({ displayMode: DISPLAY_MODE.grid })}
                                />
                                <Search
                                    size={'small'}
                                    search={this.search}
                                />
                                <CustomSelect
                                    options={this.state.users.map(s => s.name)}
                                    placeholder='Propriétaire de la transaction'
                                    value={this.state.filter.commercial_id ? this.state.users.find(s => s.id === this.state.filter.commercial_id)?.name : null}
                                    onChange={({ option }) => {
                                        let filter = this.state.filter;
                                        filter.commercial_id = this.state.users.find(s => s.name === option).id;
                                        this.setState({ filter: Object.assign({}, filter) }, () => this.reload());
                                    }}
                                />

                                <Box>
                                    <DateInputString
                                        size={'small'}
                                        strValueInFRFormat={this.state.filter.date_de_souscription}
                                        placeholder='Date de souscription'
                                        onChange={date => {
                                            let filter = this.state.filter;
                                            filter.date_de_souscription = date;
                                            this.setState({ filter: Object.assign({}, filter) }, () => this.reload());
                                        }}
                                    />
                                </Box>
                                <Box direction='row' gap='small' align='center'>
                                    <PrimaryButton label={'Plus de filtres'} onClick={() => this.setState({ filterPopup: true })}
                                        reverse icon={<Filter />}
                                    />
                                    {Object.keys(this.state.filter).length > 0 &&
                                        <Clear color='#707070' onClick={() => this.setState({ filter: {} }, () => this.reload())} style={{ cursor: 'pointer' }} />
                                    }
                                </Box>
                                {this.state.filterPopup &&
                                    <VoyageFilter
                                        filter={this.state.filter}
                                        commerciaux={this.state.users}
                                        agences={this.state.agences.map(a => a.raisonSocial)}
                                        onFilterApply={filter => {
                                            this.setState({ filter }, () => this.reload())
                                        }}
                                        onClose={() => this.setState({ filterPopup: false })}
                                        onAgenceSearch={this.searchAgence}
                                    />
                                }
                                <PrimaryButton background={'#59A4F4'} label='Exporter' icon={<DocumentExcel />}
                                    onClick={this.export}
                                />
                            </Box>
                            <Box direction="row" pad={'small'} gap='small' style={{ width: "100%" }} align="center" justify='center'>

                            </Box>
                            {this.state.displayMode === DISPLAY_MODE.line ?
                                <LineView
                                    subscriptions={this.state.subscriptions}
                                    selectSub={this.selectSub}
                                    onNextPage={this.onNextPage}
                                    total={this.state.total}
                                    startIndex={this.state.startIndex}
                                    users={this.state.users}
                                    showMessage={this.props.showMessage}
                                    reload={this.reload}
                                    selectedSubs={this.state.selectedSubs}
                                    selectAll={this.selectAll}
                                    selectOne={this.selectOne}
                                    block={() => this.setState({ loading: true })}
                                    unblock={() => this.setState({ loading: false })}
                                    onSort={optionSort => {
                                        this.setState({ sort: `${optionSort.property}:${optionSort.direction}` }, this.reload);
                                    }}
                                /> :
                                <GridView
                                    subscriptions={this.state.subscriptions}
                                    selectSub={this.selectSub}
                                    onNextPage={this.onNextPage}
                                    total={this.state.total}
                                    startIndex={this.state.startIndex}
                                />
                            }
                        </Box>

                    }

                </Box>
            </BlockUi>
        );
    }
}

const GridView = ({ subscriptions = [], selectSub, total, startIndex, onNextPage }) => {
    return (
        <Box style={{ width: "100%" }}>
            <CustomPagination total={total} onNextPage={onNextPage} startIndex={startIndex} />
            <Box style={{ height: 10 }} />
            <Grid
                columns={{
                    count: 8,
                    size: 'auto',
                }}
                gap="xsmall"
            >
                {products.map((name, idx) => {
                    let productSubs = subscriptions.filter(sub => sub.produit === name);
                    return (
                        <Box key={idx} gap='xsmall' border='right'>
                            <ProductTitle title={name} />
                            {
                                productSubs
                                    .map((s, id2) => (
                                        <SubscriptionCard sub={s} key={id2} selectSub={selectSub} />
                                    ))
                            }
                            <Box style={{ height: 100 }} />
                            <Box style={{ position: 'absolute', bottom: 0 }} background='#F7F7F7' pad={'small'} align="center" justify="center" alignContent="center" alignSelf="center" >
                                <Box direction="row" gap="small" justify="center" align="center">
                                    <Text size="xsmall" style={{ fontWeight: 'bold' }}>nb: {productSubs.length}</Text>
                                    <Text size="xsmall" style={{ fontWeight: 'bold' }}>Total: {toCurrency(productSubs.map(s => s.prime_ttc).reduce((a, s) => a + s, 0), true)}</Text>
                                </Box>
                            </Box>
                        </Box>
                    )
                })

                }
            </Grid>
        </Box >
    )
}

const ProductTitle = ({ title }) => (
    <Box round={'xsmall'} background='#F7F7F7' pad={'xsmall'} align='center' justify="center">
        <Text textAlign="center" size="xsmall" style={{ fontWeight: 'bold' }}>{title.replace('Trusti ', '')}</Text>
    </Box>
)

const SubscriptionCard = ({ sub, selectSub }) => (
    <Box pad='xsmall'>
        <Card pad={'xsmall'}>
            <Box direction="row" gap="small" justify='between'>
                <Box>
                    <Text size="xsmall">{`${sub.prenom_du_locataire} ${sub.nom_locataire}`}</Text>
                    <Text size="xsmall">{`${sub.owner?.raisonSocial || sub.agence}`}</Text>
                </Box>
                <Box style={{ minWidth: 35 }} justify="center" align='center'>
                    <Text size="xsmall">{`${toCurrency(sub.prime_ttc, true)}`}</Text>
                    <Box background="brand" round={{ size: 'small' }} onClick={() => selectSub(sub)} style={{ minWidth: 35 }} >
                        <Text size="xsmall" textAlign="center" style={{ fontWeight: 'bold' }}>Voir</Text>
                    </Box>
                </Box>
            </Box>
            <StatusPayment status={sub.status_payment} />
        </Card>
    </Box>
)


const MODIFIABLE_FIELDS = [
    { label: 'Produit', prop: 'produit' },
    { label: 'Etat', prop: 'status_payment' },
    { label: 'Date d\'import', prop: 'date_import' },
    { label: 'Date début de séjour', prop: 'date_de_debut_de_sejour' },
    { label: 'Date fin de séjour', prop: 'date_de_fin_de_sejour' },
    { label: 'Adresse location', prop: 'adresse_du_risque' },
    { label: 'Adresse client', prop: 'adresse_du_locataire' },
]
const LineView = ({
    subscriptions = [],
    selectSub,
    onNextPage,
    total,
    startIndex,
    users = [],
    showMessage,
    reload,
    selectedSubs,
    selectAll,
    selectOne,
    block,
    unblock,
    onSort
}) => {

    const [popup, setPopup] = useState(false);
    const [typePopup, setTypePopup] = useState(false);
    const [allChecked, setAllChecked] = useState(false);

    const [actionMode, setActionMode] = useState(false);
    const [edit, setEdit] = useState(false);
    const [attribuer, setAttribuer] = useState(false);
    const [remove, setRemove] = useState(false);

    const [field, setField] = useState();
    const [fieldValue, setFieldValue] = useState();

    const [commercialId, setCommercialId] = useState();

    const onValidate = () => {

        let ids = selectedSubs;
        let handler;
        if (edit) {
            if (!field || !fieldValue) {
                showMessage('Veuillez remplir les valeurs', 'error');
                return;
            }
            let realField = MODIFIABLE_FIELDS.find(s => s.label === field);
            handler = patchSubscription(realField.prop, fieldValue, ids);
        } else if (attribuer) {
            if (!commercialId) {
                showMessage('Veuillez sélectionner un commerciale', 'error');
                return;
            }
            handler = patchSubscription('commercial_id', commercialId, ids);
        } else if (remove) {
            handler = bulkDelete(ids);
        }

        block();
        handler.then(res => {
            showMessage('Souscription modifiée avec succès', 'success');
            reload();
            setActionMode(false);
            setTypePopup(false);
            unblock();
        })
            .catch(err => {
                console.log(err);
                showMessage('Erreur inattendu', 'error');
                unblock();
            })
    }


    return (
        <Box style={{ width: "100%" }}>
            <CustomPagination total={total} onNextPage={onNextPage} startIndex={startIndex} />
            {popup &&
                <PopupConfirmation
                    close={() => setPopup(false)}
                    message='Voulez-vous séléctionner tous les pages ou la page actuelle ?'
                    primaryAction={() => {
                        selectAll(true, allChecked);
                        setPopup(false);
                    }}
                    primaryActionText='Tous les pages'
                    secondaryAction={() => {
                        selectAll(false, allChecked);
                        setPopup(false);
                    }}
                    secondaryActionText='Page actuelle'
                />
            }
            {typePopup &&
                <TypeToConfirmation
                    confirmDelete={onValidate}
                    pharse='souscriptions'
                    close={() => { setActionMode(false); setTypePopup(false); }}
                />
            }
            {selectedSubs.length > 0 && !actionMode &&
                <Box gap='small' direction='row' align='center'>
                    <Text size='xsmall' color={'#707070'}><b>{selectedSubs.length} entrées sélectionnées - </b></Text>
                    <PrimaryButton icon={<Edit />}
                        background="#59A4F4"
                        color={'#fff'}
                        label={'Modifier'}
                        onClick={() => {
                            setActionMode(true); setEdit(true);
                            setAttribuer(false); setRemove(false);
                        }}
                    />
                    <PrimaryButton icon={<User />}
                        background="#59A4F4"
                        color={'#fff'}
                        label={'Attribuer'}
                        onClick={() => {
                            setActionMode(true); setEdit(false);
                            setAttribuer(true); setRemove(false);
                        }}
                    />
                    {hasAdminRole() &&
                        <PrimaryButton icon={<Trash />}
                            label={'Supprimer'}
                            onClick={() => {
                                setActionMode(true); setEdit(false);
                                setAttribuer(false); setRemove(true);
                            }}
                        />
                    }
                    <PrimaryButton icon={<Calculator />}
                        label={'Facturation'}
                        background="#59A4F4"
                        onClick={() => {
                            billingSubscriptions(selectedSubs).then(res => {
                                showMessage('Souscription modifiée avec succès', 'success');
                                reload();
                                setActionMode(false);
                                unblock();
                            })
                                .catch(err => {
                                    console.log(err);
                                    showMessage('Erreur inattendu', 'error');
                                    unblock();
                                })
                        }}
                    />
                </Box>
            }
            {actionMode &&
                <Box gap='small' direction='row' align='center'>
                    {edit &&
                        <Box direction='row' gap='small' align='center'>
                            <CustomSelect placeholder={'Sélectionner une propriété à modifier'}
                                size='xsmall'
                                options={MODIFIABLE_FIELDS.map(s => s.label)}
                                value={field}
                                onChange={({ value: nextValue }) => setField(nextValue)}
                                style={{ minWidth: 200 }}
                            />
                            {field === 'Etat' ?
                                <CustomSelect placeholder={'Sélectionner une valeur'}
                                    size='xsmall'
                                    options={[
                                        "Proposition envoyé",
                                        "En attente de paiement",
                                        "Payé",
                                        "Remboursement prime",
                                    ]}
                                    value={fieldValue}
                                    onChange={({ value: nextValue }) => setFieldValue(nextValue)}
                                    style={{ minWidth: 200 }}
                                /> :
                                field?.startsWith('Date') ?
                                <DateInput size={'small'}
                                    placeholder={'Valeur à modifier'}
                                    value={fieldValue}
                                    onChange={date => setFieldValue(new Date(date).toISOString().split('T')[0])}
                                />:
                                <CustomInput placeholder={'Valeur à modifier'}
                                    size='xsmall'
                                    style={{ minWidth: 250 }}
                                    value={fieldValue}
                                    onChange={event => setFieldValue(event.target.value)}
                                />
                            }
                        </Box>
                    }
                    {attribuer &&
                        <Box direction='row' gap='small'>
                            <CustomSelect placeholder={'Sélectionner un propriétaire'}
                                size='xsmall'
                                options={users.map(s => `${s.name}`)}
                                onChange={({ value: nextValue }) => {
                                    let user = users.find(s => `${s.name}` === nextValue);
                                    console.log(user);
                                    setCommercialId(user.id)
                                }}
                                style={{ minWidth: 200 }}
                            />
                        </Box>
                    }
                    {remove &&
                        <Box direction='row' gap='small' align='center'>
                            <Text size='small'>
                                Êtes-vous sure de vouloir supprimer <b>{selectedSubs.length} éléments ?</b>
                            </Text>
                        </Box>
                    }
                    <Box direction='row' gap='small'>
                        <PrimaryButton icon={<Checkmark />}
                            background="#59A4F4"
                            color={'#fff'}
                            label={'Valider'}
                            onClick={() => {
                                if (remove) {
                                    setTypePopup(true);
                                } else {
                                    onValidate();
                                }
                            }}
                        />
                        <PrimaryButton icon={<Close />}
                            label={'Annuler'}
                            background="#6c757d"
                            onClick={() => {
                                setActionMode(false);
                            }}
                        />
                    </Box>
                </Box>

            }
            <DataTable
                columns={[
                    {
                        header: <CheckBox
                            checked={selectedSubs.length > 0}
                            size='xsmall'
                            onChange={event => {
                                let value = event.target.checked;
                                setAllChecked(value);
                                if (value) {
                                    setPopup(true);
                                } else {
                                    selectAll(true, false);
                                }
                            }}
                        />,
                        render: sub => <CheckBox
                            checked={selectedSubs.includes(sub.id)}
                            size='small'
                            onChange={event => selectOne(sub.id, event.target.checked)}
                        />
                    },
                    {
                        property: 'status_payment',
                        header: <Text size="xsmall"><b>Etat</b></Text>,
                        render: sub => <StatusPayment status={sub.status_payment} />
                    },
                    {
                        property: 'produit',
                        header: <Text size="xsmall"><b>Produit</b></Text>,
                        render: sub => <Text size="xsmall">{sub.produit}</Text>
                    },
                    {
                        property: 'agence',
                        header: <Text size="xsmall"><b>Partenaire</b></Text>,
                        render: sub => <Text size="xsmall">{sub.owner?.raisonSocial}</Text>
                    },
                    {
                        property: 'mail_du_proprietaire',
                        header: <Text size="xsmall"><b>Mail du propriétaire</b></Text>,
                        render: sub => <Text size="xsmall">{sub.mail_du_proprietaire}</Text>
                    },
                    {
                        property: 'commercial_id',
                        header: <Text size="xsmall"><b>Commerciale</b></Text>,
                        render: sub => <Text size="xsmall">{users.find(u => u.id === sub.commercial_id)?.name}</Text>
                    },
                    {
                        property: 'nom_locataire',
                        header: <Text size="xsmall"><b>Nom</b></Text>,
                        render: sub => <Text size="xsmall">{sub.nom_locataire}</Text>
                    },
                    {
                        property: 'prenom_du_locataire',
                        header: <Text size="xsmall"><b>Prénom</b></Text>,
                        render: sub => <Text size="xsmall">{sub.prenom_du_locataire}</Text>
                    },
                    {
                        property: 'adresse_du_locataire',
                        header: <Text size="xsmall"><b>Adresse client</b></Text>,
                        render: sub => <Text size="xsmall">{sub.adresse_du_locataire}</Text>
                    },
                    {
                        property: 'adresse_du_risque',
                        header: <Text size="xsmall"><b>Adresse location</b></Text>,
                        render: sub => <Text size="xsmall">{sub.adresse_du_risque}</Text>
                    },
                    {
                        property: 'date_de_souscription',
                        header: <Text size="xsmall"><b>Date de souscription</b></Text>,
                        render: sub => <Text size="xsmall">{toFRDate(sub.date_de_souscription)}</Text>
                    },
                    {
                        property: 'date_import',
                        header: <Text size="xsmall"><b>Date d'import</b></Text>,
                        render: sub => <Text size="xsmall">{toFRDate(sub.date_import)}</Text>
                    },
                    {
                        property: 'contrat_numero',
                        header: <Text size="xsmall"><b>N° contrat</b></Text>,
                        render: sub => <Text size="xsmall">{sub.contrat_numero}</Text>
                    },
                    {
                        property: 'date_de_debut_de_sejour',
                        header: <Text size="xsmall"><b>Date de début</b></Text>,
                        render: sub => <Text size="xsmall">{toFRDate(sub.date_de_debut_de_sejour)}</Text>
                    },
                    {
                        property: 'date_de_fin_de_sejour',
                        header: <Text size="xsmall"><b>Date de fin</b></Text>,
                        render: sub => <Text size="xsmall">{toFRDate(sub.date_de_fin_de_sejour)}</Text>
                    },
                    {
                        property: 'prix_sejour',
                        header: <Text size="xsmall"><b>Prix loc.</b></Text>,
                        render: sub => <Text size="xsmall">{toCurrency(sub.prix_sejour)}</Text>
                    },
                    {
                        header: <Text size="xsmall"><b>Comptabilité</b></Text>,
                        render: sub => <SubscriptionDetail sub={sub}/>
                    },
                    {
                        property: 'mode_de_payment',
                        header: <Text size="xsmall"><b>Moyen de paiement</b></Text>,
                        render: sub => <Text size="xsmall">{sub.mode_de_payment}</Text>
                    },
                    {
                        property: 'ref_import',
                        header: <Text size="xsmall"><b>Nom d'import</b></Text>,
                        render: sub => <Text size="xsmall">{sub.ref_import}</Text>
                    },
                    {
                        header: '',
                        render: sub => <Box background="brand" round={{ size: 'xsmall' }} onClick={() => selectSub(sub)} pad={'xsmall'} >
                            <Text size="xsmall" textAlign="center" style={{ fontWeight: 'bold' }}>Voir</Text>
                        </Box>
                    },
                ]}
                data={subscriptions}
                onSort={onSort}
                sort={{ property: '', direction: '' }}
            />
        </Box>
    )
}

const toFRDate = date => {
    if (!date) return date;
    let d = moment(date, 'YYYY-MM-DD');
    return d.isValid() ? d.format('DD/MM/YYYY') : date;
}

const StatusPayment = ({ status }) => (
    <Box direction="row" gap="xsmall" pad="xsmall" align="center">
        <StatusGoodSmall size="small" color={getStatusColor(status)} />
        <Text size="xsmall">
            <strong>{status === 'En attente de paiement' ? 'En attente' : status || 'En attente'}</strong>
        </Text>
    </Box>
)
const getStatusColor = status => {
    switch (status) {
        case "Remboursement prime":
            return "#000";
        case "Payé":
            return "#33BC00";
        case "En attente de paiement":
        case "En facturation":
            return "#FF9D19";
        default:
            return "#FF9D19";
    }
}

export default Subscriptions;

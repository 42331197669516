import { Box, Card, Text } from 'grommet';
import React from 'react';
import CustomSelect from '../select_fill';

const DateSort = ({onChange}) => {
    return (
        <Box direction='row' gap='small'
            alignSelf="center" >
            <Card pad="xsmall" justify="center" alignContent="center"
                gap="small"
                align="center" direction="row">
                <Text size="xsmall">Trier par date de création</Text>
                <CustomSelect
                    size="xsmall"
                    options={[
                        { name: "Croissant", value: "ASC" },
                        { name: "Décroissant", value: "DESC" }
                    ]}
                    onChange={({ option }) => onChange(option.value)}
                    labelKey={'name'}
                    valueKey={{ key: 'name', reduce: true }}
                />
            </Card>
        </Box>
    )
}

export default DateSort;
const axios = require('axios');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const buildParams = (search_params) => {
    const { start, sort, query, limit, status } = search_params;
    let params = {
        _start: start || 0,
        _limit: limit || 50,
    }
    if(status) {
        params.status = status;
    }
    if (query) {
        params['_q'] = query || '';
    }
    if(sort) {
        params._sort = sort || 'updatedAt:desc';
    }
    return params;
}

const getAllProSantes = (filter) => {
    let params = buildParams(filter);
    return axios.get(`${baseAPI}/public/pro-sante`, { params, headers: HEADERS });
}
const countProSantes = (filter) => {
    let params = buildParams(filter);
    return axios.get(`${baseAPI}/pro_sante/count`, { params, headers: HEADERS })
}

const createProSante = (body) => {
    body.commercial_id = storageService.getCommercialId()
    return axios.post(`${baseAPI}/public/pro-sante`, body, { headers: HEADERS });
}

const updateProSante = (body) => {
    return axios.put(`${baseAPI}/public/pro-sante/${body.id}`, body, { headers: HEADERS });
}


const sendForm = (body) => {
    return axios.post(`${baseAPI}/commerciale/pro-sante/send`, body, { headers: HEADERS });
}

module.exports = {
    sendForm,
    getAllProSantes,
    countProSantes,
    createProSante,
    updateProSante
}
// resource:
// https://docs.google.com/spreadsheets/d/1SEWGKuksHzZTy5t5SLyJdRATymFXZu-gNp2Fj0BvTqc/edit#gid=1940720837
// situation
// revenue, situation, nb d'enfant, age de retrait, date de naissance, performance choix
const moment = require('moment');

const tranche_impot = [
    {min: 0, max: 10225, tmi: 0},
    {min: 10226, max: 26070, tmi: 0.11},
    {min: 26071, max: 74545, tmi: 0.13},
    {min: 74546, max: 160335, tmi: 0.41},
    {min: 160336, max: Infinity, tmi: 0.45},
]

const PROFILES = [
    {text: 'Prudent', value: 0.0346},
    {text: 'Modéré', value: 0.0556},
    {text: 'Optimiste', value: 0.0761},
]
const plafond_impot = 8200;
const PASS_2021 = 41136;
const minimum_impot = 4052;

const calculatePart = (deal) => {
    const nbEnfant = parseInt(deal.children_count) || 0;
    
    const situation = deal.familial_situation;
    const partByEnfant = nbEnfant > 2 ? (nbEnfant - 2) + 1 : nbEnfant * 0.5;
    const parBySituation = (situation === 'Marié(e)' || situation === 'Pacsé(e)') ? 2 : situation === 'Parent isolé' ? 1.5 : 1;
    return parseFloat((parBySituation + partByEnfant).toFixed(2));
}

const calculateQuotienImpot = (deal) => {
    const parts = calculatePart(deal);
    const revenue_annuel = parseFloat(deal.revenue_annuel || 0);
    return parseFloat((revenue_annuel / parts).toFixed(2)); 
}

const calculateImpot = deal => {
    const parts = calculatePart(deal);
    const revenue_annuel = parseFloat(deal.revenue_annuel || 0);
    const effectiveTranches = tranche_impot.filter(t => t.min < revenue_annuel);
    let impot = 0;
    for(const tranche of effectiveTranches) {
        const max = (tranche.max < revenue_annuel ? tranche.max : revenue_annuel)
        impot +=  (max - tranche.min) * tranche.tmi;
    }
    return parseFloat((parts * impot).toFixed(2));
}

const calculateGainImpotTotalAnnuel = (deal) => {
    const revenue_annuel = parseFloat(deal.revenue_annuel || 0);
    const effectiveTranches = tranche_impot.filter(t => t.min < revenue_annuel);
    const maxTMI = effectiveTranches[effectiveTranches.length - 1]?.tmi;
    const gain = parseFloat(deal.monthly_payment) * 12 * maxTMI;
    return parseFloat(gain.toFixed(2));
}

const getNombreContrat = deal => {
    const retirement_age = parseInt(deal.retirement_age);
    const date_of_birth = deal.client.date_of_birth;
    const diff = moment(date_of_birth).add(retirement_age, 'years').diff(moment(), 'years', true);
    return Math.round(diff);
}

const calculateCotisationTotal = deal => {
    const totalYear = getNombreContrat(deal);
    const annuel = parseFloat(deal.monthly_payment) * 12;
    const value = annuel * totalYear + parseFloat(deal.first_payment);
    return parseFloat(value.toFixed(2));
}

const calculateImpotApresReduction = deal => {
    const impot = calculateImpot(deal);
    const gainImpotTotal = calculateGainImpotTotalAnnuel(deal);
    const deductibleImpot = gainImpotTotal > plafond_impot ? plafond_impot : gainImpotTotal;
    return parseFloat((impot - deductibleImpot).toFixed(2));
}

const calculateGainTotal = deal => {
    const cotisationTotal = calculateCotisationTotal(deal);
    const totalYear = getNombreContrat(deal);
    let total = 0;
    let lastYear = 0;

    const first_payment = parseFloat(deal.first_payment);
    const annuel = parseFloat(deal.monthly_payment) * 12;
    const seletectedProfile = PROFILES.find(p => p.text === deal.profil_investisseur);
    const profile = seletectedProfile?.value || PROFILES[0].value;

    for(let i = 1; i <= totalYear; i++) {
        // total
        // first year: (first_payment + annuel) * profile + annuel + first_payment
        // second year: first_year*profile + annuel + first_year
        // n year: (n-1_year * profile) + annuel + (n-1_year) 
        
        // interet
        // first year: total - first_payment - annuel
        // second year: total - last_year - annuel
        // n year : total_n - total_n-1 - annuel
        let value = 0;
        let interet = 0;
        if(i === 1) {
            value = (first_payment + annuel) * profile + annuel + first_payment;
            interet = value - first_payment - annuel;
        } else {
            value = lastYear * profile + annuel + lastYear;
            interet = value - lastYear - annuel;
        }
        lastYear = value;
        total += interet;
    }
    total = parseFloat(total.toFixed(2));
    return parseFloat((total + cotisationTotal).toFixed(2));
}

const calculateVersementTotal = deal => {
    const annuel = parseFloat(deal.monthly_payment) * 12;
    const totalYear = getNombreContrat(deal);
    const value = annuel * totalYear + parseFloat(deal.first_payment);
    return parseFloat(value.toFixed(2));
}

const calculateGainImpotTotal = deal => {
    const nombreContrat = getNombreContrat(deal);
    const value = nombreContrat * calculateGainImpotTotalAnnuel(deal)
    return parseFloat(value.toFixed(2));
}

const calculateInvestissementPerso = deal => {
    const annuel = parseFloat(deal.monthly_payment) * 12;
    const totalYear = getNombreContrat(deal);
    const totalInvestissementSansAccompte = annuel * totalYear;

    const value = totalInvestissementSansAccompte - calculateGainImpotTotal(deal);
    return parseFloat(value.toFixed(2));
}

const calculateGainPER = deal => {
    const value = calculateGainTotal(deal) - (calculateGainImpotTotal(deal) + calculateInvestissementPerso(deal));

    return parseFloat(value.toFixed(2));
}

const calculateSimulation = deal => {
    const result = {
        versement_totaux: calculateVersementTotal(deal),
        total_per: calculateGainTotal(deal),
        economie_impot_annuelle: calculateGainImpotTotalAnnuel(deal),
        gain_impot_total: calculateGainImpotTotal(deal),
        investissement_perso: calculateInvestissementPerso(deal),
        gain_per: calculateGainPER(deal),
        cotisation_total: calculateCotisationTotal(deal),
        annuel: parseFloat(deal.monthly_payment) * 12
    }

    console.log('input: ', {
        'premier versement': deal.first_payment,
        total_contrat_year: getNombreContrat(deal),
        annuel: deal.monthly_payment * 12,
        age_retrait: deal.retirement_age,
        date_of_birth: deal.client.date_of_birth,
    }, 'output: ', result);
    return result;
}

module.exports = {
    calculateSimulation
}



import React, { useState } from "react";
import { Box, Card, Text, } from "grommet";
import { SmallLine } from "../elements/line_container";
import { getClientAddress } from "../outils/client_util";
import ToggleButton from "../elements/toggle_button/toggle_button";
import { saveAppUser } from "./service";
import { getApporteurPros } from "../apporteur_pro/service";
import { DashboardRecapTitle } from "../elements/new_design_elements";
import { getSimpleDateFR, getSimpleDateTimeFR } from "../../services/utils";
import { StatusGoodSmall } from "grommet-icons";

class AppUserInfo extends React.Component {
    state = {
        user: {}
    };
    constructor(props) {
        super(props);
        // console.log(props);
        this.state = {
            indicator: undefined,
            user: props.user || {},
            partenaires: []
        }
    }

    componentDidMount() {
        getApporteurPros(0, null).then(res => this.setState({ partenaires: res.data })).catch(err => console.log(err));
    }

    getPartenaire = () => {
        let domainEmail = this.props.user?.email?.split('@').pop();
        let p = this.state.partenaires.filter(e => e.domainName === domainEmail || e.domain_email === domainEmail);
        if (p && p.length > 0) {
            let _p = p[0];
            return _p.raisonSocial;
        }
        return '';
    }

    onValidateUser = async (validate) => {
        try {
            await saveAppUser({ user_id: this.state.user.id, app_validated: validate });
            this.props.showMessage && this.props.showMessage('Succès', 'success');
            this.setState({ user: { ...this.state.user, app_validated: validate } });
        } catch (error) {
            this.props.showMessage && this.props.showMessage(error, 'error');
        }
    }

    render() {
        console.log(this.props.user);
        return (
            <Box
                gap="small"
                width="xlarge"
                alignSelf="center"
                align="center"
                pad="small"
                justify="center"
            >

                <DashboardRecapTitle title="Information Compte" />

                <SmallLine leftLabel={'Création / Modification'} bold={true}
                    rightChildren={<Text size="small"><strong>{getSimpleDateTimeFR(this.state.user.createdAt)}</strong><br />{getSimpleDateTimeFR(this.state.user.updatedAt)}</Text>} />

                <SmallLine leftLabel={'Etat inscription'} bold={true}
                    rightChildren={
                        <Card direction="row" gap="xsmall" pad="xsmall" align="center">
                            <StatusGoodSmall color={this.state.user.app_validated ? 'green' : 'orange'} />
                            <Text size="small">
                                <strong>{this.state.user.app_validated ? 'Validé' : 'En attend'}</strong>
                            </Text>
                        </Card>}
                />

                <DashboardRecapTitle title="Profil" />
                <SmallLine leftLabel={'Nom'} bold={true}
                    rightChildren={<Text size="small" >{this.state.user.nom}</Text>}
                />
                <SmallLine leftLabel={'Prénom'} bold={true}
                    rightChildren={<Text size="small" >{this.state.user.prenom}</Text>}
                />
                <SmallLine leftLabel={'Adresse'} bold={true}
                    rightChildren={<Text size="small" >{getClientAddress(this.state.user)}</Text>}
                />
                <SmallLine leftLabel={'Email'} bold={true}
                    rightChildren={<Text size="small" >{this.state.user.email}</Text>}
                />
                <SmallLine leftLabel={'Phone'} bold={true}
                    rightChildren={<Text size="small" >{this.state.user.phone}</Text>}
                />
                <SmallLine leftLabel={'Secteur'} bold={true}
                    rightChildren={<Text size="small" >{this.state.user.domain_activity}</Text>}
                />
                <SmallLine leftLabel={'IBAN'} bold={true}
                    rightChildren={<Text size="small">{this.state.user.iban}</Text>}
                />
                <SmallLine leftLabel={'BIC'} bold={true}
                    rightChildren={<Text size="small">{this.state.user.bic}</Text>}
                />
                <SmallLine leftLabel={'Code parrain'} bold={true}
                    rightChildren={<Text size="small" >{this.state.user.code_parrain}</Text>}
                />
                <SmallLine leftLabel={'Équipe'} bold={true}
                    rightChildren={<Text size="small" ><Text>{this.state.user.affiliated_by ? `${this.state.user.affiliated_by.prenom} ${this.state.user.affiliated_by.nom}` : ''}</Text></Text>}
                />

                <DashboardRecapTitle title="Réseau" />

                <SmallLine leftLabel={'Partenaire'} bold={true}
                    rightChildren={<Text size="small" >{this.getPartenaire()}</Text>}
                />
                <SmallLine leftLabel={'Gestionnaire'} bold={true}
                    rightChildren={<Text size="small" >{this.state.user.interlocuteur?.prenom || ''} {this.state.user.interlocuteur?.nom || ''}</Text>}
                />
                



            </Box>
        )
    }
}


export default AppUserInfo;

const axios = require('axios');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = { 
    'Authorization': `Bearer ${storageService.getToken()}`
};


const createEntreprise = (entreprise) => {
    entreprise.commercial = storageService.getCommercialId();
    return axios.post(`${baseAPI}/public/entreprises`, entreprise, {headers: HEADERS});
}

const updateEntreprise = (entreprise) => {
    return axios.put(`${baseAPI}/public/entreprises/${entreprise.id}`, entreprise, {headers: HEADERS});
}

const getEntreprises = (search_params={}) => {
    const { start, sort } = search_params;
    let limit = search_params.limit || 50;
    let params = {
        _start: start,
        _limit: limit,
        _q: search_params.query,
        _sort: 'raison_social,ASC',
        commercial: storageService.getCommercialId()
    }
    if(storageService.hasAdministrativeRole()) {
        delete params.commercial;
    }
    if(sort) {
        params._sort = sort;
    }
    return axios.get(`${baseAPI}/public/entreprises`, {params, headers: HEADERS});
}

const getEntrepriseDeals = id => {
    return axios.get(`${baseAPI}/entreprise/${id}/deals`, {headers: HEADERS});
}


module.exports = {
    getEntreprises,
    createEntreprise,
    updateEntreprise,
    getEntrepriseDeals,
}
import { Box, Grid, Layer } from 'grommet';
import { Checkmark, Close, Edit } from 'grommet-icons';
import React, { useEffect } from 'react';
import { getSimpleDateFR, toCurrency } from '../../../../../services/utils';
import { CardContainer, LineTextContainer } from '../../../../elements/refont/containers/text-containser';
import { TextLabel } from '../../../../elements/refont/text-label';

import { CustomDateInput } from '../../../../elements/refont/date_input';
import {
    getFranchiseValue,
    getLocataireRembourseType,
    getLocataireRembourseValue,
    getPercentHorsFranchise,
    getPropOuAgenceRembourseType,
    getPropOuAgenceRembourseValue,
    getRestFranchise,
    getRestLocataireRembourse,
    getRestMontant,
    getRestRemboursement,
} from './utils';

export default function TableauRemboursement({ sinistre, onChangeSubmit }) {

    const [datePopup1, setDatePopup1] = React.useState(false);
    const [datePopup2, setDatePopup2] = React.useState(false);
    const [values, setValues] = React.useState({ ...sinistre });

    useEffect(() => {
        setValues({ ...sinistre });
    }, [sinistre]);

    return (
        <CardContainer>
            <Box direction={'row'} gap={'small'} justify="between" align="center">
                <Box fill background={"white"} direction="column">
                    <Box direction={'row'} gap={'small'} justify="between" align="center">
                        <TextLabel label={"Tableau de remboursement"} variant={"header3"} />
                    </Box>
                    <Grid fill
                        columns={{
                            count: 7,
                            size: 'auto',
                        }}
                        justify="center" gap={"xsmall"}
                        pad={{ top: '1rem' }}
                    >

                        <Box fill>
                            <HeaderContainer>
                                <TextLabel variant={"title1"} label={"On rembourse"} color={'white'} />
                            </HeaderContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={"Provision"} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={getLocataireRembourseType(sinistre)} />
                            </LineTextContainer>
                            {getPropOuAgenceRembourseType(sinistre) &&
                                <LineTextContainer>
                                    <TextLabel variant={"body1"} label={getPropOuAgenceRembourseType(sinistre)} />
                                </LineTextContainer>
                            }
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={"Montant total RBT"} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={"Rest"} />
                            </LineTextContainer>
                        </Box>
                        <Box fill>
                            <HeaderContainer>
                                <TextLabel variant={"title1"} label={"A qui ?"} color={'white'} />
                            </HeaderContainer>
                            <LineTextContainer style={{ border: 'none' }}>
                                <TextLabel variant={"body1"} label={"-"} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={"Locataire"} />
                            </LineTextContainer>
                            {getPropOuAgenceRembourseType(sinistre) &&
                                <LineTextContainer>
                                    <TextLabel variant={"body1"} label={"Propriétaire ou Agence"} />
                                </LineTextContainer>
                            }
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={"-"} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={"-"} />
                            </LineTextContainer>
                        </Box>
                        <Box fill>
                            <HeaderContainer>
                                <TextLabel variant={"title1"} label={"Montant"} color={'white'} />
                            </HeaderContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={toCurrency(sinistre.montant_sejour)} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={toCurrency(getLocataireRembourseValue(sinistre))} />
                            </LineTextContainer>
                            {getPropOuAgenceRembourseType(sinistre) &&
                                <LineTextContainer>
                                    <TextLabel variant={"body1"} label={toCurrency(getPropOuAgenceRembourseValue(sinistre))} />
                                </LineTextContainer>
                            }
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={toCurrency(getLocataireRembourseValue(sinistre) + (getPropOuAgenceRembourseType(sinistre) ? getPropOuAgenceRembourseValue(sinistre) : 0))} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={toCurrency(getRestMontant(sinistre))} />
                            </LineTextContainer>
                        </Box>
                        <Box fill>
                            <HeaderContainer>
                                <TextLabel variant={"title1"} label={"Franchise"} color={'white'} />
                            </HeaderContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={toCurrency(getFranchiseValue(sinistre) * sinistre.montant_sejour)} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={toCurrency(getFranchiseValue(sinistre) * getLocataireRembourseValue(sinistre))} />
                            </LineTextContainer>
                            {getPropOuAgenceRembourseType(sinistre) &&
                                <LineTextContainer>
                                    <TextLabel variant={"body1"} label={toCurrency(getPropOuAgenceRembourseValue(sinistre) * getFranchiseValue(sinistre))} />
                                </LineTextContainer>
                            }
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={toCurrency(getFranchiseValue(sinistre) * (getLocataireRembourseValue(sinistre) + (getPropOuAgenceRembourseType(sinistre) ? getPropOuAgenceRembourseValue(sinistre) : 0)))} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={toCurrency(getRestFranchise(sinistre))} />
                            </LineTextContainer>
                        </Box>
                        <Box fill>
                            <HeaderContainer>
                                <TextLabel variant={"title1"} label={"Montant à RBT"} color={'white'} />
                            </HeaderContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={toCurrency(sinistre.montant_sejour * getPercentHorsFranchise(sinistre))} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={toCurrency(getRestLocataireRembourse(sinistre))} />
                            </LineTextContainer>
                            {getPropOuAgenceRembourseType(sinistre) &&
                                <LineTextContainer>
                                    <TextLabel variant={"body1"} label={toCurrency(getPropOuAgenceRembourseValue(sinistre) * getPercentHorsFranchise(sinistre))} />
                                </LineTextContainer>
                            }
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={toCurrency(getPercentHorsFranchise(sinistre) * (getLocataireRembourseValue(sinistre) + (getPropOuAgenceRembourseType(sinistre) ? getPropOuAgenceRembourseValue(sinistre) : 0)))} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={toCurrency(getRestRemboursement(sinistre))} />
                            </LineTextContainer>
                        </Box>

                        <Box fill>
                            <HeaderContainer>
                                <TextLabel variant={"title1"} label={"Date du RBT"} color={'white'} />
                            </HeaderContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={"-"} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <Box direction='row' justify='between'>
                                    <TextLabel variant={"body1"} label={
                                        getSimpleDateFR(values.rbt_rembourse_1_date_rbt) || '-'} />
                                    <Edit size='small' onClick={() => setDatePopup1(true)} />
                                </Box>
                            </LineTextContainer>
                            {getPropOuAgenceRembourseType(sinistre) &&
                                <LineTextContainer>
                                    <Box direction='row' justify='between'>
                                        <TextLabel variant={"body1"} label={
                                            getSimpleDateFR(values.rbt_rembourse_2_date_rbt) ||
                                            '-'
                                        } />
                                        <Edit size='small' onClick={() => setDatePopup2(true)} />
                                    </Box>
                                </LineTextContainer>
                            }
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={"-"} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={"-"} />
                            </LineTextContainer>
                        </Box>

                        <Box fill>
                            <HeaderContainer>
                                <TextLabel variant={"title1"} label={"Ref RBT"} color={'white'} />
                            </HeaderContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={"-"} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <Box direction='row' justify='between'>
                                    <TextLabel variant={"body1"} label={
                                        sinistre.rbt_rembourse_1_ref_rbt || '-'} />
                                </Box>
                            </LineTextContainer>
                            {getPropOuAgenceRembourseType(sinistre) &&
                                <LineTextContainer>
                                    <Box direction='row' justify='between'>
                                        <TextLabel variant={"body1"} label={
                                            sinistre.rbt_rembourse_2_ref_rbt || '-'
                                        } />
                                    </Box>
                                </LineTextContainer>
                            }
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={"-"} />
                            </LineTextContainer>
                            <LineTextContainer>
                                <TextLabel variant={"body1"} label={"-"} />
                            </LineTextContainer>
                        </Box>


                    </Grid>
                </Box>
            </Box>
            {datePopup1 && <PopupDate

                value={values.rbt_rembourse_1_date_rbt}
                onChange={(e) => {
                    setValues({ ...values, rbt_rembourse_1_date_rbt: e.value });
                }}
                onValidate={() => { onChangeSubmit(values, () => setDatePopup1(false)) }}
                onCancel={() => {
                    setDatePopup1(false);
                    setValues({ ...sinistre });
                }}
            />}
            {datePopup2 && <PopupDate
                value={sinistre.rbt_rembourse_2_date_rbt}
                onChange={(e) => {
                    setValues({ ...values, rbt_rembourse_2_date_rbt: e.value });
                }}
                onValidate={() => { onChangeSubmit(values, () => setDatePopup2(false)) }}
                onCancel={() => {
                    setDatePopup2(false);
                    setValues({ ...sinistre });
                }}
            />}
        </CardContainer>
    )
}

const PopupDate = ({ value, onChange, onValidate, onCancel }) => (
    <Layer>
        <Box pad={'medium'} gap='small' justify='center' align='start'>
            <TextLabel variant={"title1"} label={"Date du RBT"} />
            <Box fill direction='row' justify='between' align='center' gap='small'>
                <CustomDateInput value={value} onChange={onChange} editMode />
                <Box direction='row' gap='small'>
                    <Checkmark size='small' onClick={onValidate} color='brand' />
                    <Close size='small' onClick={onCancel} />
                </Box>
            </Box>
        </Box>
    </Layer>
)

const HeaderContainer = ({ children }) => {
    return <Box
        justify="center"
        style={{
            backgroundColor: '#E82C70',
            color: "white !important",
            paddingLeft: "0.375rem",
            paddingBottom: "0.125rem",
            paddingTop: "0.125rem",
        }}
    >{children}</Box>;
}
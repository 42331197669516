import React, { Fragment, useState } from "react";
import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Input,
	FormControl,
	Textarea,
	useToast
} from "@chakra-ui/core";
import PrimaryButton from "../../../elements/primary_button";
import { Box, Card, Layer } from "grommet";
import {
	getClientName,
} from "../../../outils/client_util";
import {
	createBlocNote,
	deleteBlocNote,
	updateBlocNote,
} from "../service/bloc_note";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getContextBot, getUser } from "../../../../services/storage_service";
import { AddCircle } from "grommet-icons";

const NoteModal = ({ noteData, label = "Nouvelle Note", callback, icon, onOpenedDialog }) => {
	const [isOpen, setIsOpen] = useState(false);
	const onOpen = () => {
		setIsOpen(true);
		onOpenedDialog(true);
	};

	const onClose = () => {
		setIsOpen(false);
		onOpenedDialog(false);
	};
	const toast = useToast();
	const _contextBot = getContextBot();
	const _commercial = getUser();

	// console.log("noteData", noteData);

	const onDelete = (e) => {
		if (noteData) {
			deleteBlocNote(noteData)
				.then((res) => {
					toast({
						title: "Réussi",
						description: "Note est bien supprimé",
						status: "success",
						duration: 3000,
						isClosable: true,
					});
					onClose();
					callback();
				})
				.catch((err) => {
					toast({
						title: "Erreur",
						description: err.message,
						status: "error",
						duration: 3000,
						isClosable: true,
					});
				});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		const title = form.elements["title"].value;
		const note = form.elements["note"].value;

		if (noteData?.id) {
			// update
			noteData.title = title;
			noteData.note = note;
			updateBlocNote(noteData)
				.then(() => {
					toast({
						title: "Réussi",
						description: "Note est bien modifié",
						status: "success",
						duration: 3000,
						isClosable: true,
					});
					onClose();
					callback();
				})

				.catch((err) => {
					toast({
						title: "Erreur",
						description: err.message,
						status: "error",
						duration: 3000,
						isClosable: true,
					});
				});
		} else {
			// create new

			var _params = {
				title: title,
				note: note,
				commercial: _commercial._id,
			};
			if (_contextBot) {
				_params.client = _contextBot.client.id || _contextBot.client;
				_params.contract_name = _contextBot? (_contextBot.sub_product_detail || _contextBot.sub_product || _contextBot.product) : null;
				_params.contract_id = _contextBot.id;
			}
			createBlocNote(_params)
				.then(() => {
					toast({
						title: "Réussi",
						description: "Note est bien créé",
						status: "success",
						duration: 3000,
						isClosable: true,
					});
					onClose();
					callback();
				})
				.catch((err) => {
					toast({
						title: "Erreur",
						description: err.message,
						status: "error",
						duration: 3000,
						isClosable: true,
					});
				});
		}
	};

	return (
		<Fragment>
      {icon ? (
        <PrimaryButton label={label} onClick={onOpen} background="none" icon={<AddCircle color="white" />} />
      ) : (
        <PrimaryButton label={label} onClick={onOpen} />
      )}
			<Modal
				isOpen={isOpen}
				size="5xl"
				onClose={() => {
					onClose();
					callback();
				}}
				closeOnOverlayClick={true}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{noteData?.title ? "Modification" : "Nouvelle note"}
					</ModalHeader>
					<ModalCloseButton />
					<form id="form" onSubmit={handleSubmit}>
						<ModalBody>
							<Box gap={"small"}>
								<FormControl isRequired>
									<Input
										type="text"
										id="title"
										placeholder="Titre"
										defaultValue={noteData ? noteData.title : ""}
									/>
								</FormControl>
								<FormControl isRequired>
									{/* <CKEditor
										id="note"
										editor={ClassicEditor}
										data={noteData ? noteData.note : ""}
										onReady={(editor) => {
											// You can store the "editor" and use when it is needed.
											console.log("Editor is ready to use!", editor);
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											console.log({ event, editor, data });
										}}
									/> */}
									<Textarea
										id="note"
										minH="450px"
										size="xl"
										resize="vertical"
										defaultValue={noteData ? noteData.note : ""}
									/>
								</FormControl>
								<Box direction="row" justify="between">
									<FormControl>
										<Input
											type="text"
											id="contract_name"
											placeholder="Produit"
											isDisabled={true}
											value={
												noteData?.contract_name
													? noteData.contract_name
													: _contextBot
													? (_contextBot.sub_product_detail || _contextBot.sub_product || _contextBot.product)
													: ""
											}
										/>
									</FormControl>
									<FormControl>
										<Input
											type="text"
											id="client_name"
											placeholder="Client"
											isDisabled={true}
											value={
												noteData?.client
													? getClientName(noteData.client)
													: _contextBot
													? getClientName(_contextBot.client)
													: ""
											}
										/>
									</FormControl>
								</Box>
							</Box>
						</ModalBody>

						<ModalFooter>
							<Box direction="row" justify="between">
								{noteData?.id && (
									<Button
										type="reset"
										variantColor="primary"
										mr={3}
										onClick={onDelete}
									>
										Supprimer
									</Button>
								)}

								<Button type="submit" variantColor="green">
									Valider
								</Button>
							</Box>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>
		</Fragment>
	);
};

export default NoteModal;


import React, { useState } from "react";
import { Box, Card, Tab, Tabs, Text, } from "grommet";
import LineModification from "../../elements/line_container/line_modification";
import PrimaryButton from "../../elements/primary_button";
import { Previous } from "grommet-icons";
import { SmallLine } from "../../elements/line_container";
import { getClientAddress } from "../../outils/client_util";
import AppAffilieByUser from "./affilie_by_user";
import AppUserInfo from "./user_info";
import TeamDetail from "./team_detail";
import AppAbonnementByUser from "./abonnement_by_user";
import AppRemunerationByUser from "./remuneration_by_user";

class AppUserDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indicator: undefined,
            selectedTab: props.selectedTab || 0,
        }

    }

    

    render() {

        return (
            <Box
                style={{ width: "100%" }}
                align="center" justify="center"
                alignSelf="center"
                pad={'medium'}
                alignContent="center" gap="medium"
            >
                <Box direction="row" align="between">
                    <Box pad="small" width="xsmall">
                        <PrimaryButton icon={<Previous />} onClick={() => {
                            this.props.onClose && this.props.onClose();
                        }} />
                    </Box>
                    <Text size="xxlarge" style={{ padding: 10 }}><b> Utilisateur - {this.props.user.nom}</b></Text>
                </Box>

                <Box gap="medium">
                    <Tabs style={{ width: '100%' }} activeIndex={this.state.selectedTab} onActive={(index) => {
                        this.setState({ selectedTab: index });
                    }}>
                        <Tab title="Information" >
                            <AppUserInfo showMessage={this.props.showMessage} user={this.props.user} />
                        </Tab>
                        <Tab title="Abonnement" >
                            <AppAbonnementByUser showMessage={this.props.showMessage} user={this.props.user} />
                        </Tab>
                        <Tab title="Affiliés" >
                            <TeamDetail
                                showMessage={this.props.showMessage}
                                user={this.props.user}
                            />
                        </Tab>
                        <Tab title="Commission" >
                            <AppRemunerationByUser showMessage={this.props.showMessage} user={this.props.user} />
                        </Tab>

                    </Tabs>

                </Box>

            </Box>
        )
    }
}


export default AppUserDetail;

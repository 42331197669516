import { Box, Card, Image, Text, TextArea } from "grommet";
import { DocumentVerified, Edit, FormCheckmark, FormTrash, Info, Key } from "grommet-icons";
import React, { useState } from "react";
import bookingsync from '../../../../../../images/booking-sync.png';
import { toArrayOfArray, toCurrency } from "../../../../../../services/utils";
import CustomInput from "../../../../../elements/custom_input";
import Line, { SmallLine } from "../../../../../elements/line_container";
import PrimaryButton from "../../../../../elements/primary_button";
import { MultipleLineToggleButtons } from "../../../../../elements/toggle_button/toggle_button";
import SectionDivider from "../../../../components/session_devider";
import products, { ventCanals } from "../../../../utils/types";
import { getDisplayedTaux } from "../../config";
import AgenceStatus, { AgenceStatusChange } from "../elements/agence_status";
import Interlocuteur from "../elements/interlocuter";
import configs from '../../../../../../config/config.json';
import LineModification from "../../../../../elements/line_container/line_modification";
import { resendEmailInscription, resetPasswordPartenaire } from "../../../service";

const getBase = () => {
    return configs.baseAPI.includes('localhost') || configs.baseAPI.includes('dev') ? "dev." : ""
}

const AgenceInfo = ({ agence = {}, onEdit, onEditInfo, sendConvention, commercial, onReload, showMessage }) => {

    console.log('age: ', agence)
    const [desLang, setDesLang] = useState('');

    const onDelete = () => {

    }


    const resetPassword = () => {
        resetPasswordPartenaire(agence.id).then(res => {
            console.log("reset password = ", res.data);
            showMessage("Sent reset password");
            // onApporteurChange('interlocuteurs', interlocuteurs);
        }).catch(err => {
            showMessage('Erreur inattendu', 'error');
            console.log(err);
        });
    }

    const resendInscription = () => {
        resendEmailInscription(agence.id).then(res => {
            console.log("resendEmailInscription = ", res.data);
            showMessage("Email envoyé.");
            // onApporteurChange('interlocuteurs', interlocuteurs);
        }).catch(err => {
            showMessage('Erreur inattendu', 'error');
            console.log(err);
        });

    }
    return (
        <>
            <AgenceStatus convention={agence.convention} status={agence.status} />
            <Box
                gap="small"
                width="large"
                alignSelf="center"
                align="center"
                pad="small"
                justify="center"
            >
                <Box width="xlarge" gap="small">
                    <Card style={{ width: "100%" }} pad="small" gap="small">
                        <Line leftLabel="ID" rightChildren={<Text size="small">{agence.id}</Text>} />
                        <Line leftLabel="Commercial" rightChildren={<Text size="small">{commercial}</Text>} />
                    </Card>
                </Box>
                <SectionDivider title={"Information Agence"} />
                <Box direction="row" gap="medium" width={'large'}>
                    <Box direction="row" gap="small" style={{ width: '50%' }}>
                        <SmallText text={'Raison social : '} />
                        <SmallText text={agence.raisonSocial} />
                    </Box>
                    <Box direction="row" justify="between" gap="small">
                        <SmallText text={'Adresse : '} textAlign='lelf' />
                        <SmallText text={`${agence.address} ${agence.zip} ${agence.city}`} />
                    </Box>
                </Box>
                <Box direction="row" gap="medium" width={'large'}>
                    <Box direction="row" gap="small" style={{ width: '50%' }}>
                        <SmallText text={'Form juridique : '} />
                        <SmallText text={agence.formJuridique} />
                    </Box>
                    <Box direction="row" justify="between" gap="small">
                        <SmallText text={'Siret : '} textAlign='lelf' />
                        <SmallText text={`${agence.siret}`} />
                    </Box>
                </Box>
                <Box direction="row" gap="medium" width={'large'}>
                    <Box direction="row" gap="small" style={{ width: '50%' }}>
                        <SmallText text={'Nom de domaine : '} />
                        <SmallText text={agence.domainName} />
                    </Box>
                    <Box direction="row" justify="between" gap="small">
                        <SmallText text={'Type de société : '} textAlign='lelf' />
                        <SmallText text={`${agence.societeType}`} />
                    </Box>
                </Box>
                <Box direction="row" gap="medium" width={'large'}>
                    <Box direction="row" gap="small" style={{ width: '50%' }}>
                        <SmallText text={'Channel manager : '} />
                        <SmallText text={agence.managerChannel} />
                    </Box>
                    <Box direction="row" justify="between" gap="small">
                        <SmallText text={'Volume d\'affaire : '} textAlign='lelf' />
                        <SmallText text={`${agence.affaireVolume}`} />
                    </Box>
                </Box>
                <Box direction="row" gap="medium" width={'large'}>
                    <Box gap="small" style={{ width: '50%' }}>
                        <SmallText text={'Image logo : '} />
                        <Box width={'small'}>
                            {agence.logo && <Image src={agence.logo} />}
                        </Box>
                    </Box>
                    <Box justify="between" gap="small">
                        <SmallText text={'Image illustration : '} />
                        <Box width={'small'}>
                            {agence.illustration && <Image src={agence.illustration} />}
                        </Box>
                    </Box>
                </Box>
                <Box style={{ width: '100%' }} direction='row' gap="medium" align="center">
                    <Text size="xsmall" color={'#707070'}>Description du site</Text>
                    <PrimaryButton label={'🇫🇷 Fr'} background={desLang === '' ? '#E82C70' : '#fff'} color={desLang === '' ? '#fff' : '#000'}
                        onClick={() => setDesLang('')}
                    />
                    <PrimaryButton label={'🇺🇸 En'} background={desLang === '_en' ? '#E82C70' : '#fff'} color={desLang === '_en' ? '#fff' : '#000'}
                        onClick={() => setDesLang('_en')}
                    />

                </Box>
                <Box pad={'xsmall'} width={'large'} style={{ height: 300 }}>
                    <TextArea size="xsmall" value={agence[`descriptionSite${desLang}`]} fill />
                </Box>

                <SectionDivider title={"Information de l'interlocuteur"} />
                <Box pad={'small'} gap="small" width={"large"}>
                    {
                        toArrayOfArray(agence.interlocuteurs || []).map((interG, index) => (
                            <Box key={index} pad={'small'} gap="large" width={"large"} direction="row" >
                                {
                                    interG.map((iter, idx) => (
                                        <Interlocuteur key={idx + index * 3} idx={idx + index * 3} iter={iter} onReceivingMailChange={() => { }} />
                                    ))
                                }
                            </Box>
                        ))
                    }
                </Box>
                <SectionDivider title={"Canal de vente"} />
                <MultipleLineToggleButtons
                    key={1}
                    size={3}
                    groups={agence.ventCanals?.map(value => ({
                        label: value,
                        selected: true
                    }))}
                />
                <SectionDivider title={"Produits"} />
                <MultipleLineToggleButtons
                    key={new Date().getTime()}
                    size={3}
                    groups={agence.products?.map(value => ({
                        label: value.product,
                        selected: true
                    }))}
                />
                <Box direction="row" gap="small" justify="between" alignSelf="start" align="start">
                    <Box gap="xsmall">
                        <Text size="small">Taux base:</Text>
                        <Box direction="row" gap="small" >
                            <Box width={'small'}>
                                <CustomInput value={agence.classicBaseTaux} size='xsmall' />
                            </Box>
                        </Box>
                    </Box>
                    {agence.products?.map(p => p.product).includes(products[6]) &&
                        <Box gap="xsmall" >
                            <Text size="small">Taux RC :</Text>
                            <Box direction="row" gap="small" >
                                <Box width={'small'}>
                                    <CustomInput value={agence.rcBaseTaux} size='xsmall' />
                                </Box>
                            </Box>
                        </Box>
                    }
                    {agence.products?.map(p => p.product).includes(products[7]) &&
                        <Box gap="xsmall" >
                            <Text size="small">Taux RC Lux:</Text>
                            <Box direction="row" gap="small" >
                                <Box width={'small'}>
                                    <CustomInput value={agence.rcLuxTaux} size='xsmall' />
                                </Box>
                            </Box>
                        </Box>
                    }
                </Box>

                <SmallLine leftLabel={'Taux package voyage'} bold={true}
                    rightChildren={<Text size="small" style={{ fontWeight: 'bold' }}>Retrocession : </Text>}
                />
                {
                    (agence.products || []).map((p, idx) => (
                        <SmallLine key={idx} align="start"
                            leftLabel={`${p.product} ::(${p.taux_displayed || getDisplayedTaux(p)} %)::`}
                            rightChildren={
                               <ProductConfigView p={p} />
                            }
                        />
                    ))
                }

                <SectionDivider title={"Espace partenaire"} />
                <Box width={'large'} justify="start" gap="small">
                    <Box gap='xsmall' align='center' width={'full'}>
                        <Box direction="row" gap="small">
                            <LineModification text='Email' field='user_email'
                                value={agence.user_email}
                                direction='column'
                                disabled={true}
                                onChange={(field, value) => null}
                            />
                            <LineModification text='Mot de passe temporaire' field='init_password'
                                value={agence.init_password}
                                direction='column'
                                disabled={true}
                            />
                        </Box>
                        <Box direction="row" gap="small" width={'full'}>
                            <PrimaryButton icon={<Key />}
                                onClick={resetPassword}
                                label="Réinitialiser mot de passe"
                            />
                            <PrimaryButton icon={<FormCheckmark />}
                                onClick={resendInscription}
                                label="Renvoyer l'email d'inscription"
                            />
                        </Box>
                    </Box>
                    <Box>
                        <CanalTitle text="Espace Partenaire :" />
                        <Text size="small">
                            <a target={'_blank'} href={`https://${getBase()}partenaire-voyage.trustiway.com`}>
                                {`https://${getBase()}partenaire-voyage.trustiway.com`}
                            </a>
                        </Text>
                    </Box>
                </Box>
                <SectionDivider title={"Boite à outils des canaux de vente"} />
                <Box width={'large'} justify='start' gap="small">
                    {agence.booking_sync_account &&
                        <Box>
                            <Box direction="row" gap="small" align="center">
                                <Image src={bookingsync} />
                            </Box>
                            <Box pad={'small'} gap='xsmall'>
                                <Text size="small">{`ID BookingSync : ${agence.booking_sync_account?.account_id}`}</Text>
                                <Text size="small">{`Client : ${agence.booking_sync_account?.active ? 'Active' : 'Desactive'}`}</Text>
                                <Text size="small">{`Client : ${agence.booking_sync_account?.option_trustiway}`}</Text>
                            </Box>
                        </Box>
                    }
                    {agence.voyage_products.filter(p => p.type === 'Lien').length > 0 &&
                        <Box>
                            <Text size="small"><b>Lien produits :</b> </Text>
                            {
                                agence.voyage_products.filter(p => p.type === 'Lien').map(p => (
                                    <Text size="xsmall">{p.link}</Text>
                                ))
                            }
                            <br />
                            <Text size="small"><b>Lien classique :</b> </Text>
                            {
                                agence.voyage_products.filter(p => p.type === 'Lien').map(p => (
                                    <Text size="xsmall">{p.link_agence}</Text>
                                ))
                            }

                            {agence.ventCanals.includes(ventCanals[1]) &&
                                <>
                                    <br />
                                    <Text size="small"><b>Lien personnalisé :</b> </Text>
                                    {
                                        agence.voyage_products.filter(p => p.link_agence_personnalise).map(p => (
                                            <Box style={{ paddingTop: 5 }}>
                                                <Text size="xsmall">
                                                    <b>{p.product} :</b> <br /><span>{p.link_agence_personnalise}</span>
                                                    {p.link_agence_personnalise_en && <span>
                                                        <br />
                                                        <u>English: <br /></u>{p.link_agence_personnalise_en}
                                                    </span>}
                                                </Text>
                                            </Box>
                                        ))
                                    }
                                </>
                            }
                        </Box>
                    }
                    {agence.voyage_products.filter(p => p.type === "Formulaire en direct").length > 0 && agence.formulaire_direct &&
                        <Box>
                            <Text size="small"><b>Formulaire en direct: </b></Text>
                            {<Box>
                                <Text size="xsmall"><b>Compte client :</b></Text>
                                <Text size="xsmall">Identifiant : <b> {agence.formulaire_direct.email}</b></Text>
                                <Text size="xsmall">Mot de passe initial : <b> {agence.formulaire_direct?.init_password}</b></Text>
                            </Box>
                            }
                        </Box>
                    }
                    {agence.voyage_products.filter(p => p.type === "API").length > 0 &&
                        <Box>
                            <Text size="small"><b>Lien d'API: </b></Text>
                            <Text size="small"><i>PreProd (clé: 6d14e4e049478800da6277cf38040090)</i></Text>
                            <Text size="xsmall"><b>Note : Il faut créer le même agence sur dev </b></Text>
                            {agence.voyage_products.filter(p => p.type === 'API' && p.link).map(p => (
                                <Text size="xsmall">{p.link.replace('api.trustiway', 'api.dev.trustiway')}</Text>
                            ))
                            }
                            <Box pad={'xsmall'} />
                            <Text size="small"><i>Prod (clé: a1f6e4e9b6cd4b382d5d0c3422022d56)</i></Text>
                            {agence.voyage_products.filter(p => p.type === 'API' && p.link).map(p => (
                                <Text size="xsmall">{p.link}</Text>
                            ))
                            }
                            <Text size="xsmall">
                                <a href="https://storage.googleapis.com/trustiway-bo-dev.appspot.com/API_doc_13_03_22_40e0b07096/API_doc_13_03_22_40e0b07096.docx"><b>Doc API</b></a>
                            </Text>
                            <Box pad={'xsmall'} />
                            <Text size="small"><b>Lien d'API Proposition: </b></Text>
                            <Text size="xsmall">
                                <a href="https://storage.googleapis.com/trustiway-bo-dev.appspot.com/API_Proposision_20210924_5385e4de2d/API_Proposision_20210924_5385e4de2d.pdf"><b>Doc API Proposition</b></a>
                            </Text>
                        </Box>
                    }
                </Box>
                <Box direction="row" align="center" justify="center" gap="medium" pad={'small'}>
                    <PrimaryButton
                        label={`Modifier`}
                        icon={<Edit />}
                        background="#59A4F4"
                        onClick={onEdit}
                    />
                    <PrimaryButton
                        label={`Modifier info`}
                        icon={<Info />}
                        background="#59A4F4"
                        onClick={onEditInfo}
                    />
                </Box>

                <Box direction="row" align="center" justify="center" gap="medium" pad={'xsmall'}>
                    <AgenceStatusChange
                        agence={agence}
                        onReload={agence => onReload(agence)}
                        showMessage={showMessage}
                    />
                    {agence.convention !== "Signée" && <PrimaryButton
                        label={`Marquer convention signée`}
                        icon={<DocumentVerified />}
                        background="#E82C70"
                        onClick={sendConvention}
                    />}
                    <PrimaryButton
                        label={`Supprimer`}
                        icon={<FormTrash />}
                        background="#E82C70"
                        onClick={onDelete}
                    />
                </Box>
            </Box>

        </>
    )
}



const CanalTitle = ({ text }) => (
    <Text size="small"
        style={{ textDecoration: 'underline', fontWeight: 'bold', color: '#707070' }}
    >
        {text}
    </Text>
)

const SmallText = ({ text, style = {}, props }) => (
    <Text size="small" style={{ ...style }} {...props}>{text}</Text>
)

function ProductConfigView({ p }) {

    return <Box gap="xsmall">
        <Box direction="row" gap="small" justify="center" align="center">
            <CustomInput placeholder={'%'} value={p.taux_retro_agence}
                onChange={e => {}}
                size='xsmall' />
            <Box><Text size="xsmall">Max {p.max_retro}%</Text></Box>
        </Box>
        <Box gap="xxsmall">
            <Box direction="row" gap="small" align="center" >
                <Text size='xsmall'><strong>Garantie:</strong></Text>
            </Box>
            {p.garantie_principal && <Box direction="row" gap="medium" align="center" >
                <Text size="small">{p.garantie_principal.compagny}</Text>
                <Box >
                    {p.garantie_principal.garanties?.map((gar, index) => {
                        return (
                            <>
                                <Text key={index} size={"xsmall"}>
                                    {index + 1}. {gar.name} - {toCurrency(gar.plafond)} - {toCurrency(gar.franchise)}
                                </Text>
                            </>
                        );
                    })}
                </Box>
            </Box>}

        </Box>
        <Box gap="xxsmall">
            <Box direction="row" gap="small" align="center" >
                <Text size='xsmall'><strong>Option à la vente:</strong></Text>
            </Box>
            {p.garanties_option?.length && <Box gap="xsmall" >
                {p.garanties_option?.map((option, index) => (
                    <Text key={index} size={"xsmall"}>
                        {index + 1}. {option.name} - {toCurrency(option.plafond)} - {toCurrency(option.franchise)}
                    </Text>))}
            </Box>}
        </Box>
        <Box gap="xxsmall">
            <Box direction="row" gap="small" align="center" >
                <Text size='xsmall'><strong>Option à la vente:</strong></Text>
            </Box>
            {p.garanties_parametres?.length && <Box gap="xsmall" >
                {p.garanties_parametres?.map((option, index) => (
                    <Text key={index} size={"xsmall"}>
                        {index + 1}. {option.name} - {toCurrency(option.plafond)} - {toCurrency(option.franchise)}
                    </Text>))}
            </Box>}
        </Box>
    </Box>;
}


export default AgenceInfo;
const firstList = [
    "Décès",
    "Maladie grave N.C. avt. souscription",
    "Événement aléatoire",
    "Accident corporel grave",
    "Maladie grave C. avt. souscription",
    "Grossesses"
];
const secondList = [
    "Tentative d’effraction",
    "Effraction + vol d’effets personnels",
    "Dégât des eaux",
    "Incendie",
    "Dommage du mobilier"
]

export const getNatureList = type => type === "Dommage mobilier" ? secondList :
    type === "Recours compagnie" ? [...firstList, ...secondList] : firstList;

import React, { useState } from "react";
import { Box, Card, Text, } from "grommet";
import LineModification from "../../elements/line_container/line_modification";
import PrimaryButton from "../../elements/primary_button";
import { Previous } from "grommet-icons";
import { SmallLine } from "../../elements/line_container";
import { getClientAddress } from "../../outils/client_util";
import AppAffilieByUser from "./affilie_by_user";

const TeamDetail = ({
    user,
    onUserChange,
    saveUser,
    onClose,
    upload,
    showMessage
}) => {
    const [isView, setView] = React.useState(true);

    return (
        <Box
            gap="small"
            width="xlarge"
            alignSelf="center"
            align="center"
            pad="small"
            justify="center"
        >
            {onClose && <Box direction="row" align="between">
                <Box pad="small" width="xsmall">
                    <PrimaryButton icon={<Previous />} onClick={() => {
                        onClose && onClose();
                    }} />
                </Box>
                <Text size="xxlarge" style={{ padding: 10 }}><b> Équipe {user.code_parrain}</b></Text>
            </Box>}

            <Card pad={'small'} width='large' gap={'small'}>
                <Text size="large" ><b>Informations chef équipe</b></Text>

                <Box gap='small' direction="row">
                    <SmallLine leftLabel={'Nom'} bold={true}
                        rightChildren={<Text size="small" >{user.nom}</Text>}
                    />
                    <SmallLine leftLabel={'Prénom'} bold={true}
                        rightChildren={<Text size="small" >{user.prenom}</Text>}
                    />
                </Box>

                <SmallLine leftLabel={'Adresse'} bold={true}
                    rightChildren={<Text size="small" >{getClientAddress(user)}</Text>}
                />
                <SmallLine leftLabel={'Email'} bold={true}
                    rightChildren={<Text size="small" >{user.email}</Text>}
                />
                <SmallLine leftLabel={'Partenaire'} bold={true}
                    rightChildren={<Text size="small" >{user.partenaire?.name}</Text>}
                />
                <SmallLine leftLabel={'Code parrain'} bold={true}
                    rightChildren={<Text size="small" >{user.code_parrain}</Text>}
                />
            </Card>
            <Card pad={'small'} width='large' gap={'small'}>
                <Text size="large" ><b>Informations équipe</b></Text>
                <SmallLine leftLabel={'Équipe'} bold={true}
                    rightChildren={<Text size="small">{user.code_parrain}</Text>}
                />
                <SmallLine leftLabel={'Équipe origine'} bold={true}
                    rightChildren={<Text size="small">{user.affiliated_by ? `${user.affiliated_by.code_parrain}` : ''}</Text>}
                />

            </Card>
            <Card pad={'small'} width='large' gap={'small'}>
                <Text size="large" ><b>Informations affiliés</b></Text>

                <AppAffilieByUser userId={user.id} showMessage={showMessage} />

            </Card>

            {/* <Text size="xxlarge" style={{ padding: 10 }}><b>Rémunération</b></Text> */}

        </Box>
    )
}


export default TeamDetail;

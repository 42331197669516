import React, { Fragment } from "react";

import PrimaryButton from "../../../elements/primary_button";
import { Box, Button, Card, Layer, Text } from "grommet";
import {
	getClientName,
} from "../../../outils/client_util";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getContextBot, getUser } from "../../../../services/storage_service";
import moment from "moment";
import ContactModal from "../../../contacts/contact_modal/contact_modal";
import { useDisclosure } from "@chakra-ui/core";
import { Close } from "grommet-icons";

const ClientModal = ({ client, label, callback }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();



	return (
		<Fragment>
			<PrimaryButton background={"transparent"} color={"#000"} onClick={onOpen} label={label} ></PrimaryButton>
			{isOpen &&
				<Layer
					full='vertical'
					onEsc={onClose}
				>
					<Button
						plain={true}
						icon={<Close />}
						onClick={onClose}
						style={{ position: "absolute", top: 5, right: 5 }}
					/>
					<Box flex overflow={'auto'} >
						<ContactModal
							selectedContact={client}
							creationMode={false}
							showMessage={() => { }}
							onClose={onClose}
							refresh={callback}
						/>
					</Box>
				</Layer>}
		</Fragment>
	);
};

export default ClientModal;

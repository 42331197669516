import React from "react";
import { Box, Card, Text } from "grommet";
import {
  StatusGoodSmall
} from "grommet-icons";
import { DEAL_POSSIBLE, RecapStatusColor } from "../../services/devis_contrat_status";


const RecapStatus = ({ status, size }) => (
  !size ?
  <Box gap="xsmall" pad="xsmall" align="center">
    <Card direction="row" gap="xsmall" pad="xsmall" align="center">
      <StatusGoodSmall color={RecapStatusColor(status)} />
      <Text size="small">
        <strong>{status || DEAL_POSSIBLE}</strong>
      </Text>
    </Card>
  </Box>:
  <Box>
    <Card direction="row" gap="xsmall" pad="xsmall" align="center">
      <StatusGoodSmall size="small" color={RecapStatusColor(status)} />
      <Text size="xsmall">
        <strong>{status || DEAL_POSSIBLE}</strong>
      </Text>
    </Card>
  </Box>
);

export default RecapStatus;


import { Box, Text } from "grommet";
import React from "react";

export const SectionHeader = ({ title, color, round, actions }) => {
    return (
        <Box fill direction="row" gap="small" pad='xxsmall' justify="between" background={color || 'brand'} style={round ? { borderRadius: 12 } : {}}>
            <Text color='white' style={{ fontWeight: "bold", marginLeft: 20 }}>
                {title}
            </Text>
            {actions}
        </Box>
    )
};

export const renderPanelHeader = (title, active) => (
    <Box fill direction="row" justify="between" pad="xsmall" background={'#CECECE'} style={{ paddingLeft: 20, paddingRight: 20, borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
      <strong>
        <Text>{title}</Text>
      </strong>
      <Text>{active ? 'Fermez ce volet  ►' : 'Ouvriez ce volet  ▼'}</Text>
    </Box>
  );
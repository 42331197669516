import { Box, Pagination, Text } from 'grommet';
import React from "react";

const CustomPagination = ({ startIndex, total, onNextPage }) => {
    const getToIndex = () => {
        let endIndex = startIndex + 50;
        return total > endIndex ? endIndex : total;
    }
    return (
        <Box align="center" direction="row" justify="between">
            <Text size='xsmall'>
                Afficher {total === 0 ? 0 : startIndex + 1} - {getToIndex()} de {total}
            </Text>
            <Pagination numberItems={total} onChange={onNextPage} step={50} size='small' page={Math.floor(startIndex / 50) + 1} />
        </Box>
    )
}

export default CustomPagination;
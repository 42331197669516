import { Box, Card, Grid } from "grommet";
import { DocumentDownload } from "grommet-icons";
import React from "react";

const FileRecap = ({devis}) => {
    return (
        <Box width="full" direction="row" gap="small">
            <Card style={{ width: "100%" }} pad="medium">
                <Grid columns={["1/2", "1/2"]} width="100%">
                    {devis.file_identite && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_identite}`}
                        >
                            <DocumentDownload />
                            Carte d'Identité
                        </a>
                    )}
                    {devis.file_iban && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_iban}`}
                        >
                            <DocumentDownload />
                            RIB
                        </a>
                    )}
                    {devis.client_file_others ? (
                        devis.client_file_others.map((e, _idx) => {
                            return (
                                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                                    <DocumentDownload />
                                    {e && e.split("/").pop()}
                                </a>
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {devis.file_tableau_amortissement && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_tableau_amortissement}`}
                        >
                            <DocumentDownload />
                            Tableau d’amortissement
                        </a>
                    )}
                    {devis.file_attestation_assurance && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_attestation_assurance}`}
                        >
                            <DocumentDownload />
                            Attestation assurance
                        </a>
                    )}
                    {devis.file_offre_de_pret && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_offre_de_pret}`}
                        >
                            <DocumentDownload />
                            Offre de prêt
                        </a>
                    )}
                    {devis.file_devis && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_devis}`}
                        >
                            <DocumentDownload />
                            Devis
                        </a>
                    )}
                    {devis.file_devis_signed && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_devis_signed}`}
                        >
                            <DocumentDownload />
                            Devis (signé)
                        </a>
                    )}
                    {devis.file_dda && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_dda}`}
                        >
                            <DocumentDownload />
                            DDA
                        </a>
                    )}
                    {devis.file_devis_ipid && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_devis_ipid}`}
                        >
                            <DocumentDownload />
                            Devis IPID
                        </a>
                    )}
                    {devis.file_devis_condition && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_devis_condition}`}
                        >
                            <DocumentDownload />
                            Questionnaire devis
                        </a>
                    )}
                    {devis.file_devis_condition_signed && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_devis_condition_signed}`}
                        >
                            <DocumentDownload />
                            Questionnaire devis (signé)
                        </a>
                    )}
                    {devis.file_rib && (
                        <a target={"_blank"} rel="noreferrer" href={`${devis.file_rib}`}>
                            <DocumentDownload />
                            RIB
                        </a>
                    )}
                    {devis.file_contrat && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_contrat}`}
                        >
                            <DocumentDownload />
                            Contrat
                        </a>
                    )}
                    {devis.file_contrat_ipid && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_contrat_ipid}`}
                        >
                            <DocumentDownload />
                            Contrat IPID
                        </a>
                    )}
                    {devis.file_contrat_condition && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_contrat_condition}`}
                        >
                            <DocumentDownload />
                            Contrat conditions
                        </a>
                    )}
                    {devis.file_contrat_signed && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${devis.file_contrat_signed}`}
                        >
                            <DocumentDownload />
                            Contrat signé
                        </a>
                    )}
                    {devis.file_others ? (
                        devis.file_others.map((e, _idx) => {
                            return (
                                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                                    <DocumentDownload />
                                    {e && e.split("/").pop()}
                                </a>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </Grid>
            </Card>
        </Box>
    )
}

export default FileRecap;

import { Box, Button } from 'grommet';
import contactService from '../../../services/contact_service';
import { getSimpleDateFR, getSorted } from '../../../services/utils';
import AddDevis from '../../elements/add_devis';
import { isSubscribed } from '../../elements/contract_details';
import DateSort from '../../elements/date_sort';
import service from './service';

import { Download } from 'grommet-icons';
import React from 'react';
import Refresh from '../../elements/refresh';
import SearchComponent from '../../elements/search';
import StatusFilter from '../../elements/status_filter';
import ViewTable from '../../elements/view_table';
import ContratView from '../enligne-components/contrat_view';
import TrustiMHTempo from './trusti-mh-tempo';
import { isExportable } from '../../../services/mh_enligne_service';



class TrustiMHTempos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: this.props.contact || {},
            contacts: [],
            mhTempos: [],
            popupOpen: false,
            selectedTrustiMHTempo: {},
            filter: {
                _query: undefined,
                status: undefined,
                sort: undefined
            }
        }
    }

    componentDidMount = () => {
        this.loadTrustiMHTempos();
        contactService.getContact()
            .then(res => {
                this.setState({ contacts: res.data?.results })
            }).catch(err => console.log(err));
    };

    loadTrustiMHTempos = () => {
        this.setState({ mhTempos: [] }, () => {
            service.getTrustiMHTempos(this.state.contact.id)
                .then(res => {
                    this.setState({ mhTempos: res.data })
                })
                .catch(err => {
                    console.log('err', err);
                    this.props.showMessage('Erreur inattendu');
                })
        })
    }

    onClose = () => { this.setState({ popupOpen: false }) }

    openMhTempo = (mhTempo) => {
        this.setState({ selectedTrustiMHTempo: mhTempo, popupOpen: true });
    }

    addMhTempo = () => this.openMhTempo({ assure: {}, location: {} })

    search = (query, status) => {
        service.getTrustiMHTempos(this.state.contact.id, query, status)
            .then(res => {
                if (res.data?.results) {
                    this.setState({ mhTempos: res.data?.results })
                } else if (res.data) {
                    this.setState({ mhTempos: res.data })
                }
            })
            .catch(err => {
                console.log('err', err);
                this.props.showMessage('Erreur inattendu');
            })
    }

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter: Object.assign({}, filter) });
    }

    export = () => {
        let headers = [
            { label: "Statut", prop: 'status' },
            { label: "Nom du souscripteur", prop: 'trustiway' },
            { label: "Numéro BIA", prop: 'contractNumber' },
            { label: "Numéro de l'aliment", prop: 'numero_aliment' },
            { label: "Civilité du locataire", prop: 'client.civilite' },
            { label: "Nom du locataire", prop: 'client.lastname' },
            { label: "Prénom", prop: 'client.firstname' },
            { label: "Adresse", prop: 'address' },
            { label: 'Code postal', prop: 'zip' },
            { label: 'Ville', prop: 'city' },
            { label: 'Nature du risque', prop: 'zone' },
            { label: 'Nombre de pièces principales', prop: 'totalRooms' },
            { label: 'Superficie en m2', prop: 'surface' },
            { label: 'Capital incendie et vol', prop: 'capitalMobilier' },
            { label: "Date de premier effet de l'aliment", prop: 'date_effet_aliment_text' },
            { label: "Date fin des garanties de l'aliment", prop: 'date_fin_aliemnt_text' },
            { label: 'Cotisation annuelle TTC en euros', prop: 'estimatedPrice' },
            { label: 'Cotisation période TTC en euros', prop: 'estimatedPriceMonthly' },
            { label: 'Civilité du colocataire 2', prop: '' },
            { label: 'Nom du colocataire 2', prop: '' },
            { label: 'Prénom du colocataire 2', prop: '' },
            { label: 'Date de debut', prop: 'effectiveDate' },
            { label: 'Date de fin', prop: 'date_fin' }
        ]

        this.setState({ loading: true });

        let csvContent = headers.map(s => s.label).join(';') + '\n' +
            this.state.mhTempos.filter(p => isExportable(p)).map(deal => {
                deal.trustiway = 'TRUSTIWAY';
                deal.numero_aliment = !deal.option ? '' : deal.option === 'Trusti base' ? '1H0331278' : '1H0331277';
                if(deal.rawResponse?.zone) {
                    deal.zone = deal.rawResponse.zone.split(' ')[1];
                    deal.estimatedPriceMonthly = (deal.estimatedPrice/12).toFixed(2);
                }

                return headers.map(s => s.prop).map(prop => {
                    if (!prop) return '';
                    if (prop === 'effectiveDate') return deal.effectiveDate ? getSimpleDateFR(deal.effectiveDate) : '';
                    if (prop === 'date_fin') return deal.date_fin ? getSimpleDateFR(deal.date_fin) : '';
                    if (prop.includes('client')) {
                        let realProp = prop.replace('client.', '');
                        return (deal.client || {})[realProp];
                    }
                    return deal[prop];
                }).join(';');
            }).join('\n');
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        var blob = new Blob(["\uFEFF" + csvContent], { encoding: "UTF-8", type: "text/csv;charset=UTF-8" }),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `Souscriptions_${getSimpleDateFR(new Date()).replace(/\//g, '-')}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);

        this.setState({ loading: false });
        this.props.showMessage('Exporté !', 'success');
    }
    render() {
        return (
            this.state.popupOpen ?
                (isSubscribed(this.state.selectedTrustiMHTempo?.status) ?
                    <ContratView
                        sub={this.state.selectedTrustiMHTempo}
                        title="Souscription MH Tempo"
                        assurance_type="Trusti MH Tempo"
                        showMessage={this.props.showMessage}
                        onClose={this.onClose}
                        onReload={this.loadTrustiMHTempos}
                    /> :
                    <TrustiMHTempo
                        showMessage={this.props.showMessage}
                        devis={this.state.selectedTrustiMHTempo}
                        onClose={this.onClose}
                        reload={this.loadTrustiMHTempos}
                        contacts={this.state.contacts}
                    />
                )
                :
                <Box style={{ width: "100%" }}
                    align="center" justify="center"
                    alignSelf="center"
                    alignContent="center" gap="medium">
                    <Box width="full" gap="medium" pad={'small'} align='center' justify='center'>
                        <Box width="xlarge" gap="medium" align='center' justify='center'>

                            {!this.state.contact.id &&
                                <>
                                    <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
                                        <Refresh refresh={this.loadTrustiMHTempos} />
                                        <SearchComponent
                                            search={value => {
                                                this.search(value, this.state.filter.status);
                                            }}
                                            query={this.state.filter._query}
                                            updateQuery={value => this.updateFilter('_query', value)}
                                            width='full'
                                        />
                                        <AddDevis onAdd={this.addMhTempo} />

                                        <Box direction='row' gap='small' alignSelf="center" style={{ width: 130 }}>
                                            <Box round="full" overflow="hidden" pad="xsmall" >
                                                <Button primary icon={<Download color='#fff' />} onClick={this.export} color='#70A4EC' />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box justify="between" direction='row' align="center" gap="medium" alignSelf="center">
                                        <StatusFilter
                                            status={this.state.filter.status}
                                            search={value => {
                                                this.updateFilter('status', value);
                                                this.search(this.state.filter._query, value);
                                            }}
                                        />
                                        <DateSort
                                            onChange={value => this.updateFilter('sort', value)}
                                        />

                                    </Box>
                                </>
                            }
                        </Box>
                        {this.state.contact.id && <Box style={{ height: 30 }} />}
                        <ViewTable
                            reports={getSorted(this.state.mhTempos, this.state.filter.sort)}
                            commission_taux={this.props.commission_taux}
                            onDevisSelected={this.openMhTempo}
                            commerciaux={this.props.commerciaux}
                        />
                    </Box>
                </Box>
        )
    }
}

export default TrustiMHTempos;
import {
    Box,
    Card
} from "grommet";
import React from 'react';
import { getSimpleDateFR } from "../../../services/utils";
import StandardText from "../standard_text";


const DateRecap = ({ deal }) => {
    return (
        deal.date_resiliation ?
            <Box width={'full'}>
                {deal.date_resiliation ?
                    <Card style={{ width: "100%" }} pad="small" gap="small" direction="row" justify="center" align="center">
                        <StandardText
                            style={{ color: 'red', fontWeight: 'bold' }}
                            label={'Date de résiliation : ' + getSimpleDateFR(deal.date_resiliation)} size='small' />
                    </Card> : null
                }
            </Box> : null

    )
}

export default DateRecap;
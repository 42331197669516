import {
  Box
} from "grommet";
import React from "react";

import service from "../../../services/trusti_mh_service";
import { getSorted } from '../../../services/utils';
import Refresh from "../../elements/refresh";
import TrustiMH from "./trusti_mh/index";

import AddDevis from '../../elements/add_devis';
import DateSort from '../../elements/date_sort';
import SearchComponent from '../../elements/search';
import StatusFilter from '../../elements/status_filter';
import ViewTable from "../../elements/view_table";

class TrustiMHs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: this.props.contact || {},
      trustiMHs: [],
      popupOpen: false,
      selectedTrustiMH: {},
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    };
  }

  componentDidMount = () => {
    this.loadTrustiMHs();
  };

  loadTrustiMHs = () => {
    this.setState({ trustiMHs: [] }, () => {
      service
        .getTrustiMHs(this.state.contact.id)
        .then((res) => {
          this.setState({ trustiMHs: res.data });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false });
  };

  openMH = (trustiMH) => {
    this.setState({ selectedTrustiMH: trustiMH, popupOpen: true });
  };

  addMH = () => this.openMH({});

  search = (query, status) => {
    service.getTrustiMHs(this.state.contact.id, query, status)
      .then(res => {
        if (res.data?.results) {
          this.setState({ trustiMHs: res.data?.results })
        } else if (res.data) {
          this.setState({ trustiMHs: res.data })
        }
      })
      .catch(err => {
        console.log('err', err);
        this.props.showMessage('Erreur inattendu');
      })
  }

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  render() {
    return this.state.popupOpen ? (
      <TrustiMH
        showMessage={this.props.showMessage}
        devis={this.state.selectedTrustiMH}
        onClose={this.onClose}
        reload={this.loadTrustiMHs}
      />
    ) : (
      <Box style={{ width: "100%" }}
        align="center" justify="center"
        alignSelf="center"
        alignContent="center" gap="medium">
        <Box width="full" gap="medium" pad={'small'} align='center' justify='center'>
          <Box width="xlarge" gap="medium" align='center' justify='center'>

            {!this.state.contact.id &&
              <>
                <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
                  <Refresh refresh={this.loadTrustiMHs} />
                  <SearchComponent
                    search={value => {
                      this.search(value, this.state.filter.status);
                    }}
                    query={this.state.filter._query}
                    updateQuery={value => this.updateFilter('_query', value)}
                  />
                  <AddDevis onAdd={this.addMH} />
                </Box>
                <Box justify="between" direction='row' align="center" gap="medium" alignSelf="center">
                  <StatusFilter
                    status={this.state.filter.status}
                    search={value => {
                      this.updateFilter('status', value);
                      this.search(this.state.filter._query, value);
                    }}
                  />
                  <DateSort
                    onChange={value => this.updateFilter('sort', value)}
                  />

                </Box>
              </>
            }
          </Box>
          {this.state.contact.id && <Box style={{ height: 30 }} />}
          <ViewTable
            reports= {getSorted(this.state.trustiMHs, this.state.filter.sort)}
            commission_taux={this.props.commission_taux}
            onDevisSelected={this.openMH}
            commerciaux={this.props.commerciaux}
          />
        </Box>
      </Box>
    );
  }
}

export default TrustiMHs;

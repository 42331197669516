import { Box, Button, Grid, Paragraph, Text } from "grommet"
import React, { useEffect, useState } from "react";

const GridLayout = (props) => (
  <Grid
    gap="small"
    columns={["1/3", "1/3", "1/3"]}
    style={{margin: "10px 0", width: "calc(100% - 30px)"}}
  >
    {props.children}
  </Grid>
)

const FreeLayout = (props) => (
  <Box
    direction="row"
    gap="medium"
    wrap
  >
    {props.children}
  </Box>
)

const ChoiceSelect = (props) => {
  const [selected, setSelected] = useState(null);

  const choiceLayout = props.freeGrid ? FreeLayout : GridLayout;

  const selectChoice = (choice) => {
    setSelected(choice);
    props.onChange(choice);
  }

  useEffect(() => {
    if (props.default)
      selectChoice(props.default);
  }, [props.default]);

  return (
    <Box>
      {props.title && <Text weight={400} size={"14px"} margin="10px 0 0 0">{props.title}</Text>}
      {choiceLayout({
        children: <>{props.choices.map((choice, index) => (
          <Button
            key={choice}
            label={choice + (props.icons && props.icons[index] ? (" " + props.icons[index]) : "")}
            onClick={() => selectChoice(choice)}
            primary
            style={{
              opacity: selected === choice ? "1" : "0.5",
              boxShadow: selected === choice ? "0px 3px 10px #00000022" : "none",
              width: props.freeGrid ? "fit-content" : "100%",
              fontSize: "14px",
              padding: "0px 30px",
              fontWeight: 600,
              borderRadius: "10px",
              margin: props.freeGrid ? "10px 10px 0 0" : "0",
            }}
          />
          ))}
        </>
      })}
    </Box>
  )
}

export default ChoiceSelect;
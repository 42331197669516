
import React from "react";
import { Box, Heading, Tab, Tabs, Text, } from "grommet";
import PrimaryButton from "../elements/primary_button";
import { CaretLeftFill } from "grommet-icons";
import AppUserInfo from "./user_info";
import TrustiBot from "../trustibot";
import { clearContextBot } from "../../services/storage_service";
import RelationList from "./relation";

class AppUserDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indicator: undefined,
            selectedTab: props.selectedTab || 0,
        }
    }

    render() {
        console.log(this.props.user);
        return (
            <Box
                style={{ width: "100%" }}
            >
                <Box pad="small" direction="row" gap="20px" margin="0 0 20px 0">
                    <Box>
                        <PrimaryButton icon={<CaretLeftFill />} label="Retour" onClick={() => {
                            clearContextBot();
                            this.props.onClose && this.props.onClose();
                        }} />
                    </Box>
                    <Box>
                        <Heading level="3">
                            Utilisateur - {this.props.user?.prenom} {this.props.user?.nom}
                        </Heading>
                    </Box>
                </Box>
                <Box
                    direction="row"
                    gap='xsmall'
                    style={{ width: "100vw" }}
                >
                    <TrustiBot></TrustiBot>
                    <Box gap="medium">
                        <Tabs style={{ width: '100%' }} activeIndex={this.state.selectedTab} onActive={(index) => {
                            this.setState({ selectedTab: index });
                        }}>
                            <Tab title="Utilisateur" >
                                <AppUserInfo showMessage={this.props.showMessage} user={this.props.user} />
                            </Tab>
                            <Tab title="Mis en relation" >
                                <RelationList showMessage={this.props.showMessage} user={this.props.user} />
                            </Tab>
                        </Tabs>

                    </Box>
                </Box>
            </Box>
        )
    }
}


export default AppUserDetail;

import React from 'react';
import { Box, Grommet, TextArea } from 'grommet';
import { TextLabel } from '../text-label';

const customTheme = {
    global: {
        control: {
            border: {
                radius: '16px'
            },
        },
        input: {
            width: '400px',
        }
    },
    textArea: {
        extend: {
            fontSize: "0.75rem",
            padding: "0.5rem",
            lineHeight: "1.2rem",
            color: "rgba(100,116,139,1)",
        }
    }
};



export default function CustomTextArea({ value, onChange, editMode }) {
    return (
        editMode ?
            <Grommet theme={customTheme}>
                <Box  align="center" justify="center">
                    <TextArea
                        value={value}
                        onChange={onChange}
                        style={{
                            fontSize: "0.75rem",
                            padding: "0.5rem",
                            lineHeight: "1.2rem",
                            color: "rgba(100,116,139,1)",
                            width: "100%"
                        }}
                        resize={false}
                        rows={5}
                    />
                </Box>
            </Grommet>
            :
            <TextLabel label={value} variant={"body1"} />
    )
}

import React from 'react';
import { Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, Card, Heading } from 'grommet';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import CustomSelect from '../../elements/select_fill';
import { getMonthOptions, toCurrency } from '../../../services/utils';
import ReportsDeals, { DealStatusReport } from './reports_deals';
import { hasClassiqueManagerRole, hasAdministrativeRole, isCommercialClassique } from '../../../services/storage_service';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'left',
        },
        datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: value => value ? !isNaN(parseFloat(value)) ? parseFloat(value).toFixed(0) : value : '',
            font: {
                weight: 'bold'
            }
        }
    }
};

const ObjectifDayIndicator = ({ indicator, updateObjectifDayIndicator, commerciaux, hideFilter = false, isCA = false, equipes }) => {

    const [startDate, setStartDate] = React.useState(moment(new Date()).startOf('month').toDate());
    const [endDate, setEndDate] = React.useState(new Date());

    const [commercial_id, setCommercialId] = React.useState('');
    const [statuts, setStatuts] = React.useState([]);
    const [equipe, setEquipe] = React.useState('');

    const allCommerciaux = [
        { name: "Commerciaux Classiques", id: commerciaux.filter(isCommercialClassique).map(c => c.id) },
        { name: "Managers Classiques", id: commerciaux.filter(c => c.role?.name === "Manager Classique").map(c => c.id) },
        ...commerciaux,
    ]

    const dealFilters = d => {

        let commercialFilter = true;
        if (commercial_id) {
            if (typeof commercial_id.push === 'function') {
                commercialFilter = commercial_id.includes(d.commercial_id);
            } else {
                commercialFilter = commercial_id === d.commercial_id;
            }
        }
        let equipeFilter = true;
        if (equipe) {
            let ids = [equipe.manager.id];
            equipe.membres.forEach(m => ids.push(m.id));
            equipeFilter = ids.includes(d.commercial_id);
        }
        return commercialFilter && equipeFilter;
    }
    return (
        <Box width='full' style={{ paddingBottom: 100, paddingTop: 30 }} gap='small' justify='center' align='center'>
            <Box width='xlarge' >
                <Text size='large' weight={'bold'} textAlign="center">Objectif Day</Text>
                {!hideFilter &&
                    <Card width='xlarge' justify='around' margin='medium' pad='small' direction='row'>
                        <Box gap='xsmall'>
                            <Text>Sélectionner d'un mois</Text>
                            <CustomSelect
                                options={getMonthOptions().filter(
                                    (s) => s.name !== "12 derniers mois"
                                )}
                                labelKey={"name"}
                                valueKey={{ key: "name", reduce: true }}
                                placeholder='mois en cours'
                                defaultValue={getMonthOptions()[0]}
                                onChange={({ option }) => {
                                    setStartDate(option.startDate);
                                    setEndDate(option.endDate);
                                    updateObjectifDayIndicator(option.startDate, option.endDate, commercial_id, statuts);
                                }}
                            />
                        </Box>
                        <Box gap='xsmall'>
                            <Text>Sélectionner d'une phase</Text>
                            <CustomSelect
                                options={["Prospect",
                                    "Deal possible",
                                    "Deal possible rempli",
                                    "Devis",
                                    "Devis validé",
                                    "En attente de résiliation",
                                    "En attente de formalité médicale",
                                    "Contrat envoyé",
                                    "En attente de la banque",
                                    "Contrat clôturé",
                                    "Résilié",
                                    "Archivé",
                                    "Perdu"]}
                                multiple
                                value={statuts}
                                onChange={({ value: nextValue }) => {
                                    setStatuts(nextValue)
                                    updateObjectifDayIndicator(startDate, endDate, commercial_id, nextValue);
                                }}
                                clear
                            />
                        </Box>
                        {(hasAdministrativeRole() || hasClassiqueManagerRole()) &&
                            <Box gap='xsmall'>
                                <Text>Equipe</Text>
                                <CustomSelect
                                    options={equipes}
                                    onChange={({ option }) => {
                                        setEquipe(option);
                                        if (!option) {
                                            updateObjectifDayIndicator(startDate, endDate, undefined, statuts);
                                            return;
                                        }
                                        let ids = [];
                                        option.membres.forEach(m => ids.push(m.id));
                                        updateObjectifDayIndicator(startDate, endDate, ids, statuts);
                                    }}
                                    labelKey={'name'}
                                    valueKey={{ key: 'name', reduce: true }}
                                    clear
                                />
                            </Box>
                        }
                        {(hasAdministrativeRole() || hasClassiqueManagerRole()) && !equipe?.id &&
                            <Box gap='xsmall'>
                                <Text>Commercial</Text>
                                <CustomSelect
                                    options={allCommerciaux}
                                    onChange={({ option }) => {
                                        option = option || {};
                                        setCommercialId(option.id)
                                        if (typeof option.id?.push === 'function') {
                                            updateObjectifDayIndicator(startDate, endDate, option.id, statuts);
                                        } else {
                                            updateObjectifDayIndicator(startDate, endDate, [option.id], statuts);
                                        }
                                    }}
                                    labelKey={'name'}
                                    valueKey={{ key: 'name', reduce: true }}
                                    clear
                                />
                            </Box>
                        }
                    </Card>
                }
                <Bar
                    height={600}
                    width={1134}
                    options={{
                        ...options,
                        scales: {
                            x: {
                                stacked: false,
                            },
                            y: {
                                stacked: false,
                                max: indicator?.chart_data?.maxValue + 3,
                            }
                        }
                    }}
                    data={{
                        labels: indicator?.chart_data?.keyLabels,
                        datasets: indicator?.chart_data?.dataSets
                    }}
                    plugins={[ChartDataLabels]}
                />
            </Box>
            <Box style={{ width: "100%", marginTop: 100 }}
                overflow={{ horizontal: "scroll" }}
                align="center" justify="center"
                alignSelf="center"
                alignContent="center" gap="medium">

                <Box width={'full'} pad='medium'>
                    <DealStatusReport
                        reports={indicator?.all_devis?.filter(dealFilters)}
                        commerciaux={commerciaux}
                        reloadAll={() => { }}
                        showMessage={() => { }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

const CustomText = ({ label }) => <Text textAlign="end">{label}</Text>

export default ObjectifDayIndicator;
const axios = require('axios');
const storageService = require('./storage_service');
const { baseAPI } = require('../config/config.json');
const HEADERS = { 
    'Authorization': `Bearer ${storageService.getToken()}`
};

const submit = (devis) => {
    return axios.post(`${baseAPI}/public/pno-seul`, devis, { headers: HEADERS });
}

const getTrustiPNOs = (client_id, _query, status) => {
    let commercial_id = storageService.getCommercialId();
    let params = { commercial_id, _q: _query, status, _limit: -1 };
    let binome_id = storageService.getBinomeId();
    if(binome_id) {
        delete params.commercial_id;
        params['_where[0][commercial_id_in]'] = [commercial_id, binome_id];
    }
    if(storageService.hasAdministrativeRole()) {
        delete params.commercial_id;
        delete params['_where[0][commercial_id_in]']
    }
    if(client_id) {
        params['_where[1][client.id]']=client_id;
        delete params.commercial_id;
    }
    return axios.get(`${baseAPI}/public/pno-seul`, { params, headers: HEADERS });
}

const modify = (devis) => {
    return axios.put(`${baseAPI}/public/pno-seul/${devis.id}`, devis, { headers: HEADERS });
}

const sendForm = (devis) => {
    return axios.post(`${baseAPI}/commerciale/pno-seul/send`, devis, { headers: HEADERS });
}

const getPrice = devis => {
    devis.isFromCommercial = true;
    return axios.post(`${baseAPI}/public/pno-seul/price`, devis, { headers: HEADERS });
}

const getPNOSubscription = params => {
    return axios.get(`${baseAPI}/public/pno-seul/subscription`, { headers: HEADERS,  params });
}

module.exports = {
    submit,
    modify,
    getTrustiPNOs,
    sendForm,
    getPrice,
    getPNOSubscription,
}
import React from "react";
import { Box, Button, Card, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { deleteAppUsers, getAppUsers, getAppUsersValidated } from "../service";
import { getSimpleDateFR, getSorted } from "../../../services/utils";
import Refresh from "../../elements/refresh";
import SearchComponent from "../../elements/search";
import DateSort from "../../elements/date_sort";
import { AddCircle, StatusGoodSmall, Trash, View } from "grommet-icons";
import AppUserInfo from "./user_info";
import PrimaryButton from "../../elements/primary_button";
import AppUserDetail from "./user_detail";
import AppUserCreate from "./create";


class AppUserValidated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appUsers: [],
      popupOpen: false,
      selectedUser: {},
      showCreateNew: false,
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    };
    console.log('props = ', props);
  }
  componentDidMount = () => {
    this.loadAppUsers();
  };

  loadAppUsers = () => {
    console.log('props = ', this.props);
    this.setState({ appUsers: [] }, () => {
      var _params = null;
      if (this.props.domain) {
        _params = { email_contains: this.props.domain };
      }
      getAppUsersValidated(_params)
        .then((res) => {
          this.setState({ appUsers: res.data });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false, showCreateNew: false });
    this.loadAppUsers();
  };

  openUser = (user) => {
    this.setState({ selectedUser: user, popupOpen: true });
  };

  addUser = () => this.openUser({});

  search = (query) => {
    var _params = null;
    if (query)
      _params = { _query: query };
    getAppUsersValidated(_params)
      .then(res => {
        if (res.data?.results) {
          this.setState({ appUsers: res.data?.results })
        } else if (res.data) {
          this.setState({ appUsers: res.data })
        }
      })
      .catch(err => {
        console.log('err', err);
        this.props.showMessage('Erreur inattendu');
      })
  }

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  render() {
    return this.state.popupOpen ? (
      <AppUserDetail
        showMessage={this.props.showMessage}
        user={this.state.selectedUser}
        onClose={this.onClose}
        reload={this.loadAppUsers}
      />
    ) : this.state.showCreateNew ? (
      <AppUserCreate
        showMessage={this.props.showMessage}
        onUserChange={(_data) => { console.log('onUserChange = ', _data) }}
        saveUser={(_data) => { console.log('saveUser = ', _data) }}
        onClose={this.onClose} />
    ) : (
      <Box style={{ width: "100%" }}
        align="center" justify="center"
        alignSelf="center"
        alignContent="center" gap="medium">
        <Text size="xxlarge" style={{ padding: 10 }}><b>{this.props.domain ? this.props.domain : 'Utilisateurs'}</b></Text>
        <Box gap="medium">
          <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
            <Refresh refresh={this.loadAppUsers} />
            <SearchComponent
              search={value => {
                this.search(value, this.state.filter._query);
              }}
              query={this.state.filter._query}
              updateQuery={value => this.updateFilter('_query', value)}
            />
            <DateSort
              onChange={value => this.updateFilter('sort', value)}
            />
            <PrimaryButton
              label={`Ajouter un utilisateur`}
              icon={<AddCircle />}
              background='#E82C70'
              onClick={() => this.setState({ showCreateNew: true })}
            />
          </Box>


          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Text>Création/<br />Modification</Text>
                </TableCell>
                <TableCell>
                  <Text>Nom</Text>
                </TableCell>
                <TableCell>
                  <Text>Prénom</Text>
                </TableCell>
                <TableCell>
                  <Text>Date de naissance</Text>
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Immobilier</TableCell>
                <TableCell>Équipe de</TableCell>
                <TableCell>Interlocuteur</TableCell>
                <TableCell><Text>Abonnement</Text></TableCell>
                <TableCell><Text>État inscription</Text></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getSorted(this.state.appUsers, this.state.filter.sort).map((appUser, index) => (
                <TableRow key={index + 1}>
                  <TableCell>
                    <Text size="small"><strong>{getSimpleDateFR(appUser.createdAt)}</strong><br />{getSimpleDateFR(appUser.updatedAt)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.nom}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.prenom}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{getSimpleDateFR(appUser.birthday)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.email}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.domain_activity}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.affiliated_by ? `${appUser.affiliated_by.prenom} ${appUser.affiliated_by.nom}` : ''}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appUser.interlocuteur?.prenom || ''} {appUser.interlocuteur?.nom || ''}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>Oui</Text>
                  </TableCell>
                  <TableCell>
                    <Card direction="row" gap="xsmall" pad="xsmall" align="center">
                      <StatusGoodSmall color={'green'} />
                      <Text size="small">
                        <strong>Validé</strong>
                      </Text>
                    </Card>
                  </TableCell>
                  <TableCell>
                    <Button
                      icon={<View />}
                      label=""
                      primary
                      onClick={() => this.openUser(appUser)}
                    />
                    <Button
                      label=""
                      icon={<Trash />}
                      primary
                      onClick={() => {
                        if (window.confirm("Vous êtes sûr que vous voulez supprimer cet utilisateur ? Tous les ventes, les mises en relation associés avec cet utilisateur seront détachés.")) {
                          deleteAppUsers(appUser.id).then(res => {
                            this.loadAppUsers();
                            this.props.showMessage("Utilisateur supprimé", "success");
                          }).catch(err => {
                            console.log('err', err);
                            this.props.showMessage('Erreur inattendu');
                          })
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  }
}



export default AppUserValidated;

import { Box } from 'grommet';
import { Previous, Save, Send } from "grommet-icons";
import React from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { sendPresentation } from '../../../../services/common_service';
import { createContact, getAllCompanies, sendContratEmail, sendDevisEmail, updateContact } from "../../../../services/contact_service";
import { CONTRAT_ENVOYE, DEVIS } from '../../../../services/devis_contrat_status';
import { FRACTIONNEMENT_TYPE } from "../../../../services/types";
import { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import RecapStatus from "../../../elements/devis_status";
import PrimaryButton from "../../../elements/primary_button";
import StandardText from "../../../elements/standard_text";
import { createProPREV, sendFormParticulier, updateProPREV } from "../services";
import DevisContratForm from './devis_contrat';
import Info from "./info";
import Recap from "./recap";
import { clearContextBot, saveContextBot } from '../../../../services/storage_service';

const STEP = {
    client: 'client',
    info: 'info',
    recap: 'recap',
    devis: 'devis',
    contrat: 'contrat',
}

const getNextStep = currentStep => {
    switch (currentStep) {
        case STEP.client: return STEP.info;
        case STEP.info: return STEP.recap;
        default:
            break;
    }
}
class PrevoyanceParticuliers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deal: this.props.deal || {client: {}},
            step: this.props.deal?.id ? STEP.recap : STEP.client
        }
        saveContextBot(props.deal);
    }

    componentDidMount = () => {
        getAllCompanies()
            .then((res) => {
                let _allComs = res.data?.options;
                this.setState({ allCompanies: _allComs });
            })
            .catch((err) => console.log("err = ", err));
    }

    onDealChange = (field, value) => {
        let deal = this.state.deal;
        deal[field] = value;
        this.setState({ deal: Object.assign({}, deal) });
    }

    onDealSave = async () => {
        let deal = this.state.deal;
        let client = deal?.client;
        console.log('client', client);
        if(!client || !client.firstname || !client.lastname || !client.email || !client.contact_type) {
            this.props.showMessage("Veuillez remplir tous infos du client", "error");
            return;
        }
        const clientHandler = client.id ? updateContact : createContact;
        const clientRes = await clientHandler(client);
        client = clientRes.data;
        deal.client = client.id;
        try {
            const dealHandler = deal.id ? updateProPREV : createProPREV;
            let res = await dealHandler(deal);
            deal = res.data;
            this.setState({ deal: Object.assign({}, deal), step: getNextStep(this.state.step) })
            this.props.onRefresh();
        } catch (error) {
            console.log(error);
            this.props.showMessage('Error lors de création/mis à jour client', 'error');
            deal.client = client;
            this.setState({ deal })
        }
    }

    setLoading = val => this.setState({ loading: val });

    sendEmailDevis = (devis) => {
        if (!devis.date_effet || (new Date(devis.date_effet) < new Date())) {
            this.props.showMessage("La date d'effet doit être supérieure à la date du jour", "error");
            return;
        }
        if(!devis.file_devis) {
            this.props.showMessage("Le fichier de devis manquant", "error");
            return;
        }
        devis.status = DEVIS;
        devis.date_devis = new Date();
        devis.begin_date = devis.date_effet;
        devis.frais_dossier = devis.frais_dossier || 0;
        updateProPREV(devis)
            .then((res) => {
                this.setLoading(false);
                this.setState({ step: STEP.recap, devis: res.data });
                sendDevisEmail(devis)
                    .then((res) => {
                        this.props.showMessage("Devis a été envoyé", "success");
                        this.setState({ deal: res.data })
                    })
                    .catch((err) =>
                        this.props.showMessage("Echec d'envoyer devis", "error")
                    );
            })
            .catch((err) => {
                console.log(err);
                this.props.showMessage("Erreur inattendu", "error");
                this.setLoading(false);
            });
    };

    sendEmailContrat = (devis) => {
        if (!devis.date_effet || (new Date(devis.date_effet) < new Date())) {
            this.props.showMessage("La date d'effet doit être supérieure à la date du jour", "error");
            return;
        }
        if (!devis.file_contrat) {
            this.props.showMessage("Le fichier de contrat manquant", "error");
            return;
        }
        devis.status = CONTRAT_ENVOYE;
        devis.date_contrat = new Date();
        updateProPREV(devis)
            .then(async (res) => {
                this.setState({ step: STEP.recap, devis: res.data });
                sendContratEmail(res.data)
                    .then((res) => {
                        this.props.showMessage("Contrat a été envoyé", "success");
                        this.setState({ deal: res.data })
                    })
                    .catch((err) =>
                        this.props.showMessage("Echec d'envoyer contrat", "error")
                    );
            })
            .catch((err) => {
                console.log(err);
                this.props.showMessage("Erreur inattendu", "error");
                this.setLoading(false);
            });
    };

    onDealReload = deal => this.setState({ deal });

    onSendForm = () => {
        let deal = this.state.deal;
        sendFormParticulier(deal)
            .then(res => {
                this.props.showMessage("Formulaire envoyé avec succès", "success");
                this.setState({ deal: res.data });
            })
            .catch(err => {
                console.log(err);
                this.props.showMessage("Error lors de l'envoie de formulaire");
            })
    }

    sendPresentation = () => {
        sendPresentation(this.state.deal)
            .then((res) => {
                this.props.showMessage("Présentation a été envoyé", "success");
                this.props.onRefresh();
                this.setState({ deal: res.data })
            })
            .catch((err) =>
                this.props.showMessage("Echec d'envoyer présentation", "error")
            );
    };

    contactChange = (field, value) => {

    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box
                    style={{ width: "100%" }}
                    justify="center"
                    alignContent="center"
                    align="center"
                    pad='medium'
                >
                    <StandardText style={{ fontWeight: 'bold' }} size='large' label='Formulaire Prévoyance' />
                    <Box width="full" justify="between" direction="row">
                        <Box pad="small" width={'xsmall'}>
                            <PrimaryButton icon={<Previous />} onClick={() => {
                                clearContextBot();
                                this.props.onClose && this.props.onClose();
                            }} />
                        </Box>
                        {this.state.deal.id &&
                            <Box width={'small'}>
                                <RecapStatus status={this.state.deal.status} />
                            </Box>
                        }
                    </Box>
                    <Box
                        width={'xxlarge'}
                        justify="center"
                        alignContent="center"
                        align="center"
                    >
                        {this.state.step === STEP.client &&
                            <ContactSelectV2
                                contact={this.state.deal.client || {}}
                                updateContact={this.onDealChange}
                                addressIncluded={true}
                                title='Information client'
                                size="small"
                                birthDayIncluded={true}
                            />
                        }

                        {this.state.step === STEP.info &&
                            <Info
                                deal={this.state.deal}
                                onDealChange={this.onDealChange}
                                prev={() => this.setState({ step: STEP.client })}
                                save={this.onDealSave}
                            />
                        }

                        {this.state.step === STEP.recap &&
                            <Recap
                                deal={this.state.deal}
                                onEdit={() => this.setState({ step: STEP.client })}
                                showMessage={this.props.showMessage}
                                toDevis={() => {
                                    let deal = this.state.deal;
                                    deal.fractionnement_type = FRACTIONNEMENT_TYPE.annual;
                                    this.setState({deal: {...deal}, step: STEP.devis})
                                }}
                                toContrat={() => this.setState({ step: STEP.contrat })}
                                onDealReload={this.onDealReload}
                                reload={this.props.onRefresh}
                                onSendForm={this.onSendForm}
                            />
                        }

                        {(this.state.step === STEP.devis || this.state.step === STEP.contrat) &&
                            <DevisContratForm
                                devis={this.state.deal}
                                updateDevis={this.onDealChange}
                                onEdit={() => this.setState({ step: STEP.client })}
                                showMessage={this.props.showMessage}
                                toDevis={() => this.setState({ step: STEP.devis })}
                                submitModification={this.onDealSave}
                                isModeContrat={this.state.step === STEP.contrat}
                                allCompanies={this.state.allCompanies}
                                sendEmailDevisContrat={
                                    this.state.step === STEP.contrat
                                        ? this.sendEmailContrat
                                        : this.sendEmailDevis
                                }
                            />
                        }

                        <Box
                            direction="row"
                            margin="small"
                            align="center"
                            justify="center"
                            gap="medium"
                            pad={'medium'}
                        >

                            {this.state.step === STEP.recap &&
                                <PrimaryButton
                                    onClick={this.sendPresentation}
                                    label={`Envoyer une présentation`}
                                    icon={<Send />}
                                />
                            }

                            {this.state.step === STEP.client &&
                                <PrimaryButton
                                    onClick={this.onDealSave}
                                    reverse
                                    label={`Sauvegarder`}
                                    icon={<Save />}
                                />
                            }
                        </Box>


                    </Box>
                </Box>
            </BlockUi>
        )
    }
}

export default PrevoyanceParticuliers;
const { createContact, getContactById, updateContact } = require("../../services/contact_service");
const { isValidEmail, isValidPhone } = require("../../services/utils");

const getClientAddress = client => {
    return `${client?.address || ''} ${client?.zip || ''} ${client?.city || ''}`
}

const getClientName = client => {
    return client?.raisonSocial ?
        client?.raisonSocial :
        client?.raison_social ?
            client?.raison_social :
            `${client?.lastname} ${client?.firstname}`;
}

const getClientInfo = deal => {
    let client = deal.client || deal.entreprise;
    return getClientName(client);
}

const getProductFromModel = model_name => {
    switch (model_name) {
        case 'devis_habitation':
            return 'Trusti Habitation en ligne';
        case 'pno_seul':
            return 'Trusti PNO en ligne';
        case 'emprunteur_horsligne':
            return 'Trusti Emprunteur';
        case 'trusti_moto':
            return 'Trusti Moto';
        case 'trusti_voiture':
            return 'Trusti Voiture';
        case 'pet':
            return 'Trusti Chien/Chat';
        case 'sante':
            return 'Trusti Santé';
        case 'pno_git':
            return 'Trusti PNO Gîte';
        case 'trusti_mh':
            return 'Trusti Habitation';
        case 'trusti_pno_horsligne':
            return 'Trusti PNO';
        case 'trusti_per':
            return 'Trusti PER';
        default: return model_name;
    }
}

const products = [
    "Trusti Habitation en ligne",
    "Trusti PNO en ligne",
    "Trusti Emprunteur",
    "Trusti Moto",
    "Trusti Voiture",
    "Trusti Chien/Chat",
    "Trusti Santé",
    "Trusti PNO Gîte",
    "Trusti Habitation",
    "Trusti PNO",
    "Trusti PER",
    "Trusti MH tempo enligne",
    "Trusti RC",
    "Trusti MRP",
    "Trusti PREV",
    "Trusti Cyber Risque",
    "Trusti Santé pro",
    "Trusti Immeuble pro",
    "Trusti PNO pro",
    "Trusti Immeuble",
    "Trusti GLI",
    "Trusti Dommage Ouvrage",
    "Trusti Crédit",
    "Tous",
];

const updateClient = async (devis, showMessage) => {
    if (!devis.client) return false;
    if (typeof devis.client === 'string') {
        return await getContactById(devis.client);
    }
    const client = devis.client;
    if(!isValidEmail(client)) {
        showMessage("L'email du client n'est pas valid", 'warning');
        return false;
    }
    if(!isValidPhone(client)) {
        showMessage("Le numéro de téphone du client n'est pas valid", 'warning');
        return false;
    }
    let handler = devis.client.id ? updateContact : createContact;
    let contact = await handler(devis.client)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err.response.data);
            let message;
            if (err.response && err.response.data) {
                message = err.response.data.message;
            }

            showMessage(message || "Error inattendu", "error");
        });
    if (!contact) return false;
    devis.client = contact.id;
    return contact;
}

module.exports = {
    getClientName,
    getClientAddress,
    updateClient,
    getClientInfo,
    products,
    getProductFromModel
}

import { Box, Card, Text } from 'grommet';
import React from 'react';
import CustomSelect from '../select_fill';
import { A_RAPPELER_PLUS_TARD, CONTRAT_CLOTURE, CONTRAT_ENVOYE, DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS, DEVIS_VALIDE, EN_ATTENTE_BANQUE, PROSPECT, RESILIE } from '../../../services/devis_contrat_status';

const SearchComponent = ({ search, status }) => {

    return (
        <Box direction='row' gap='small'
            alignSelf="center" >
            <Card pad="xsmall" justify="center" alignContent="center"
                gap="small"
                align="center" direction="row">
                <Text size="xsmall">Filtre par phase</Text>
                <CustomSelect
                    size="xsmall"
                    clear
                    options={[
                        PROSPECT,
                        DEAL_POSSIBLE,
                        A_RAPPELER_PLUS_TARD,
                        DEAL_POSSIBLE_REMPLI,
                        DEVIS,
                        DEVIS_VALIDE,
                        CONTRAT_ENVOYE,
                        EN_ATTENTE_BANQUE,
                        CONTRAT_CLOTURE,
                        RESILIE
                    ]}
                    value={status}
                    onChange={({ option }) => search(option)}
                />
            </Card>
        </Box>
    )
}
export default SearchComponent;

export function SimpleFilter({label, options, filter, ...props}) {

    return(
        <Box direction='row' gap='small'
            alignSelf="center" style={{ width: '100%' }}>
            <Card pad="xsmall" justify="center" alignContent="center"
                gap="small"
                align="center" direction="row">
                <Text size="xsmall">{label}</Text>
                <CustomSelect
                    size="xsmall"
                    options={options}
                    onChange={({ option }) => filter(option)}
                    {...props}
                    clear={true}
                />
            </Card>
        </Box>
        
    )
}
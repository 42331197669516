import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";
import {
  CONTRAT_CLOTURE,
  CONTRAT_ENVOYE,
  DEAL_POSSIBLE,
  DEAL_POSSIBLE_REMPLI,
  DEVIS,
  DEVIS_VALIDE,
  EN_ATTENTE_DE_FORMALITE_MEDICALE,
  EN_ATTENTE_DE_RESILIATION,
  PROSPECT,
} from "../../../../services/devis_contrat_status";
import { getSimpleDateFR, getSimpleTimeFR } from "../../../../services/utils";
import BlockUi from "react-block-ui";
import { getAppelsByStatus } from "../../../../services/appointment_service";
import PrimaryButton from "../../../elements/primary_button";
import { Refresh } from "grommet-icons";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import configs from '../../../../config/config.json';
import { calculateSimulation } from "./calcul";

export const displayAmount = (amount, digits) => {
  if (amount === undefined)
    return "0";
  if (!digits)
    digits = 0;
  return Intl.NumberFormat('fr-FR', { minimumFractionDigits: digits, maximumFractionDigits: digits }).format(amount);
}

const ArrowRight = () => (
  <svg width="13" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.8807 8.58234C19.6843 8.93027 19.6843 10.0697 18.8807 10.4177L1.39736 17.9882C0.737076 18.2741 -8.2134e-07 17.7901 -7.89888e-07 17.0705L-1.28051e-07 1.92947C-9.65998e-08 1.20994 0.737076 0.725896 1.39736 1.01181L18.8807 8.58234Z" fill="#8C8C8C" /></svg>
)

const ArrowLeft = () => (
  <svg width="13" style={{ transform: "rotate(180deg)" }} height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.8807 8.58234C19.6843 8.93027 19.6843 10.0697 18.8807 10.4177L1.39736 17.9882C0.737076 18.2741 -8.2134e-07 17.7901 -7.89888e-07 17.0705L-1.28051e-07 1.92947C-9.65998e-08 1.20994 0.737076 0.725896 1.39736 1.01181L18.8807 8.58234Z" fill="#8C8C8C" /></svg>
)

const ArrowLine = () => (
  <Box direction="row" justify="between" align="center">
    <ArrowLeft />
    <Box height="1px" width="100%" background="#8C8C8C" />
    <ArrowRight />
  </Box>
)

const computeGain = (init_payment, retirementRest, monthlyPayment, profile) => {
  /*const startYearly = props.per.monthly_payment * 12;
    let yearly = startYearly;
    let totalFinal = 0;
    for (let i = 0; i < retirementRest; i++) {
      const total = (yearly + props.per.first_payment) * profiles[profile] + yearly + (yearly !== startYearly ? startYearly : 0);
      const gain = total - yearly - startYearly;
      totalFinal += gain;
      yearly = total;
      data.push({
        name: (currentYear + i),
        uv: startYearly * (i + 1) + props.per.first_payment,
        pv: totalFinal + startYearly,
      });
    }

    setFinalTotalGain(totalFinal + startYearly + totalCotisation);
    */
  const startYearly = monthlyPayment * 12;
  let totalGain = 0;
  let yearly = startYearly;
  for (let year = 1; year < retirementRest + 1; year++) {
    const total = (init_payment + yearly) * profile + yearly + (yearly !== startYearly ? startYearly : 0);
    const gain = total - yearly - startYearly;

    totalGain += gain;
    yearly = total;
  };
  return totalGain + startYearly;
}

const taxesLevels = [{
  min: 0,
  max: 10225,
  rate: 0
}, {
  min: 10226,
  max: 26070,
  rate: 0.11
}, {
  min: 26071,
  max: 74545,
  rate: 0.3
}, {
  min: 74546,
  max: 160335,
  rate: 0.41
}, {
  min: 160336,
  max: 1000000000,
  rate: 0.45
}];

const PASS_2021 = 41136;
const MINIMUM_TAXES = 4052;

const getTaxesCeiling = (monthlyPayment, income) => {
  if (income * 0.1 < MINIMUM_TAXES)
    return MINIMUM_TAXES;
  else if (income * 0.1 > (PASS_2021 * 0.1) * 8)
    return (PASS_2021 * 0.1) * 8;
  return income * 0.1;
}

export const getTaxes = (monthlyPayment, income, quotient) => {
  const yearly = monthlyPayment * 12;
  const quotientIncome = income / quotient;
  let taxes = 0;

  for (let i = 0; i < taxesLevels.length; i++) {
    const level = taxesLevels[i];
    if (quotientIncome >= level.min && quotientIncome <= level.max) {
      taxes = yearly * level.rate;
      break;
    }
  }
  if (taxes > getTaxesCeiling(monthlyPayment, income)) {
    return 30000;
  }
  return taxes;
}

const Simulation = ({ devis }) => {
  /*const age = new Date().getFullYear() - new Date(devis.client.date_of_birth).getFullYear();
  const retirementRest = devis.retirement_age - age;
  const totalCotisation = devis.monthly_payment * 12 * retirementRest + devis.first_payment;
  const totalGain = computeGain(devis.first_payment, retirementRest, devis.monthly_payment, 0.0556) + totalCotisation;
  const gain_percent = Math.floor((totalCotisation / totalGain) * 100);
  const economies_percent = gain_percent * 0.3;
  const economies = getTaxes(devis.monthly_payment, Number(devis.revenue_annuel), 1);*/

  const simulation = calculateSimulation(devis);
  const totalCotisation = simulation.cotisation_total;
  const totalGain = simulation.total_per;
  const gain_percent = Math.floor((totalCotisation / totalGain) * 100);
  const economies_percent = gain_percent * 0.3;
  const economies = simulation.gain_impot_total;
  const percent_deduction_impot = (simulation.annuel ? (simulation.economie_impot_annuelle/simulation.annuel*100).toFixed(0) : 0);

  return (
    <Box width="100%">
      <Grid columns={["1/4", "1/4", "1/4", "1/4"]} gap="small" margin="0 0 20px 0">
        <Box>
          <Text size="small">Versement totaux</Text>
          <Text color="bluebrand" size="xlarge" weight="bold">{displayAmount(totalCotisation, 2)} €</Text>
        </Box>
        <Box>
          <Text size="small">Projection en fin de contrat</Text>
          <Text color="brand" size="xlarge" weight="bold">{displayAmount(totalGain, 2)} €</Text>
        </Box>
        <Box>
          <Text size="small">Hypothèse de rendement annuel</Text>
          <Text color="bluebrand" size="xlarge" weight="bold">{devis.rate_return * 100} %</Text>
        </Box>
        <Box>
          <Text size="small">Profil investisseur</Text>
          <Text color="bluebrand" size="xlarge" weight="bold">{devis.profil_investisseur}</Text>
        </Box>
      </Grid>
      <Grid
        columns={["1/2", "1/2"]} gap="small" width="100%"
      >
        <Box>
          <Box width="100%" background="brand" pad="5px 20px" style={{borderRadius: "8px"}}>
            <Text color="white" weight={600}>Avantage fiscal</Text>
          </Box>
          <Grid columns={["1/3", "2/3"]} gap="small" pad={"small"} width="100%">
            <Box height={"150px"} width={"150px"}>
              <CircularProgressbarWithChildren value={30} strokeWidth={5} styles={{
                path: {
                  stroke: `#16BC00`,
                  strokeLinecap: 'butt',
                },
                trail: {
                  stroke: '#59A4F4',
                },
              }}>
                <Box
                  width={"calc(100% - 20px)"}
                  height={"calc(100% - 20px)"}
                  align={"center"}
                  justify={"center"}
                  style={{
                    borderRadius: "50%",
                    position: "absolute",
                    top: 10,
                    left: 10,
                    border: "7px solid #59A4F4",
                  }}
                >
                  <Text size="small" weight={600} color="bluebrand">Côut PER</Text>
                  <Text size="small" weight={600} color="bluebrand">{displayAmount(devis.monthly_payment * 12)} €</Text>
                </Box>
              </CircularProgressbarWithChildren>
            </Box>
            <Box>
              <Text color="brand" size="small" margin="0 0 10px 0">
                <b>Déduction impôt</b><br />
                <b>{simulation.economie_impot_annuelle} €</b> représente <b>{percent_deduction_impot} %</b> du coût total de votre PER
              </Text>
              <Text color="bluebrand" size="small">
                <b>Investissement PER</b><br />
                <b>{simulation.annuel - simulation.economie_impot_annuelle} €</b> représente <b>{100-percent_deduction_impot} %</b> du coût total de votre PER
              </Text>
            </Box>
          </Grid>
        </Box>
        <Box>
          <Box width="100%" background="brand" pad="5px 20px" style={{borderRadius: "8px"}}>
            <Text color="white" weight={600}>Résultat PER</Text>
          </Box>
          <Grid margin="10px 0 0 0" columns={[`${gain_percent / 2 + 25}%`, `${100 - (gain_percent / 2 + 25)}%`]}>
            <Box border="right" pad="5px">
              <Text color="bluebrand" size={"xsmall"} alignSelf="center">{gain_percent}% - {displayAmount(totalCotisation, 0)} €</Text>
              <Box style={{
                borderRadius: "10px 0 0 10px",
              }} margin="10px 0 0 0" pad="3px" background="bluebrand" align="center" >
                <Text color="white" size="xsmall" weight={600}>Investissement Perso</Text>
              </Box>
              {simulation.annuel > 0 ? <Grid margin="10px 0 0 0" columns={["60%", "40%"]}>
                <Box border="right" pad="5px">
                  <Box pad="3px" background="transparent" height="29px" align="center" />
                  <ArrowLine />
                  <Text color="bluebrand" size={"xsmall"} alignSelf="center">{(gain_percent - economies_percent).toFixed(1)}% - {displayAmount(((gain_percent - economies_percent) / 100) * totalGain, 0)} €</Text>
                </Box>
                <Box pad="5px">
                  <Box pad="3px" background="greenbrand" align="center" >
                    <Text color="white" size="xsmall" textAlign="center" weight={600}>Économie d'impôt</Text>
                  </Box>
                  <Text color="greenbrand" size={"xsmall"} alignSelf="center">{economies_percent.toFixed(1)}% - {displayAmount((economies_percent / 100) * totalGain)} €</Text>
                </Box>
              </Grid> : null}
            </Box>
            <Box pad="5px">
              <Text color="brand" size={"xsmall"} alignSelf="center">{100 - gain_percent}% - {displayAmount(totalGain - totalCotisation, 0)} €</Text>
              <Box style={{
                borderRadius: "0 10px 10px 0",
              }} margin="10px 0 0 0" pad="3px" background="brand" align="center"
              >
                <Text color="white" size="xsmall" weight={600}>Rendement PER</Text>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Grid>
      <Box direction="row" margin="20px 0 0 0">
        <Button label="Voir la simulation" primary onClick={() => {
          configs.host.includes("dev") ?
            window.open("https://dev.form.trustiway.com/landings/per/result?id=" + devis.id, "_blank")
          :
            window.open("https://form.trustiway.com/landings/per/result?id=" + devis.id, "_blank");
        }} />
      </Box>
    </Box>
  );
}

export default Simulation;

import {
    Box, Card, Layer, Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text
} from "grommet";
import { Clear } from "grommet-icons";
import React from "react";
import { getApporteurName, getMonthOptions, getSimpleDateFR } from "../../../services/utils";
import { getPopupContainer } from "../../dashboard/status_report";
import DateInputString from "../../elements/date_input_string";
import RecapStatus from "../../elements/devis_status";
import StatusFilter from '../../elements/status_filter';
import CustomSelect from "../../elements/select_fill";
import Search from "../../voyages/components/search";
import { getSouscriptions, PRODUCTS } from "../service";
import SearchComponent from "../../elements/search";
import BlockUi from 'react-block-ui';

const getCommercialName = (devis, commerciaux) => {
    let commercial = commerciaux.find(d => d.id === devis.commercial_id);

    return commercial ? commercial.prenom + ' ' + commercial.nom : "";
}
class Souscriptions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            souscriptions: [],
            apporteur: props.apporteur,
            devis: undefined,
            filter: {},
            open: false, 
            blocking: true
        }
    }

    componentDidMount = () => {
        this.loadSouscriptions();
    }

    loadSouscriptions = () => {
        this.setState({ blocking: true });
        let params = {
            ref: this.state.apporteur?.reference,
            ...this.state.filter
        }
        getSouscriptions(params)
            .then(res => this.setState({ souscriptions: res.data, blocking: false }))
            .catch(err => console.log(err));
    }

    search = (q) => {
        let filter = this.state.filter;
        filter.query = q;
        this.setState({ filter: this.state.filter }, () => this.loadSouscriptions())
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.blocking}>
            <Box
                pad={'medium'}
                justify='center' align="center"
                width={'full'}
            >
                {this.state.open && (
                    <Layer pad="medium" style={{ overflowY: "scroll", height: "100vh" }}>
                        <Box width="xlarge">
                            <Box style={{ marginTop: 20, marginBottom: 40 }}>
                                {
                                    getPopupContainer(
                                        this.state.devis,
                                        () => this.setState({ devis: undefined, open: false }),
                                        this.loadSouscriptions,
                                        this.loadSouscriptions,
                                        this.props.showMessage
                                    )
                                }
                            </Box>
                        </Box>
                    </Layer>
                )}
                <Box direction="row" pad={'medium'} gap='small' style={{ width: "100%" }} align="center" justify="center">
                    <StatusFilter
                        status={this.state.filter.status || null}
                        search={value => {
                            let filter = this.state.filter;
                            filter.status = value;
                            this.setState({ filter: Object.assign({}, filter) }, () => this.loadSouscriptions());
                        }}
                    />

                    <Box direction='row' gap='small'
                        alignSelf="center" >
                        <Card pad="xsmall" justify="center" alignContent="center"
                            gap="small"
                            align="center" direction="row">
                            <Text size="xsmall">Date de Création</Text>
                            <CustomSelect
                                placeholder={'Tous les mois'}
                                options={getMonthOptions().filter(
                                    (s) => s.name !== "12 derniers mois"
                                )}
                                labelKey={"name"}
                                valueKey={{ key: "name", reduce: true }}
                                onChange={({ option }) => {
                                    let filter = this.state.filter;
                                    if (option) {
                                        filter.createdAt_gte = option.startDate;
                                        filter.createdAt_lte = option.endDate;
                                    }else{
                                        delete filter.createdAt_gte;
                                        delete filter.createdAt_lte;
                                    }
                                    this.setState(
                                        {
                                            filter: Object.assign({}, filter),
                                        },
                                        () => this.loadSouscriptions()
                                    );
                                }}
                                clear
                            />
                        </Card>
                    </Box>


                    <Search
                        size={'small'}
                        search={this.search}
                    />
                    <CustomSelect
                        options={this.props.commerciaux.map(s => s.name)}
                        placeholder='Propriétaire de la transaction'
                        value={this.state.filter.commercial_id ? this.props.commerciaux.find(s => s.id === this.state.filter.commercial_id)?.name : null}
                        onChange={({ option }) => {
                            let filter = this.state.filter;
                            filter.commercial_id = this.props.commerciaux.find(s => s.name === option).id;
                            this.setState({ filter: Object.assign({}, filter) }, () => this.loadSouscriptions());
                        }}
                    />

                    <Box>
                        <CustomSelect
                            options={PRODUCTS.map(s => s.name)}
                            placeholder='Produit'
                            value={this.state.filter.product}
                            onChange={({ option }) => {
                                let filter = this.state.filter;
                                filter.product = option;
                                this.setState({ filter: Object.assign({}, filter) }, () => this.loadSouscriptions());
                            }}
                        />
                    </Box>



                    {Object.keys(this.state.filter).length > 0 &&
                        <Box onClick={() => this.setState({ filter: {} }, this.loadSouscriptions)}>
                            <Clear color="brand" />
                        </Box>
                    }
                </Box>

                <Box width={'full'}>
                    <Table
                        fill="horizontal"
                        style={{
                            width: "100%",
                            background: "#fff",
                            marginBottom: 0,
                        }}
                    >
                        <TableHeader>
                            <TableRow>
                                <TableCell scope="col">
                                    <Text size="small" weight="bold">
                                        Source
                                    </Text>
                                </TableCell>
                                <TableCell scope="col">
                                    <Text size="small" weight="bold">
                                        Apporteur
                                    </Text>
                                </TableCell>
                                <TableCell scope="col">
                                    <Text size="small" weight="bold">
                                        Commercial
                                    </Text>
                                </TableCell>
                                <TableCell scope="col">
                                    <Text size="small" weight="bold">
                                        Création
                                    </Text>
                                </TableCell>
                                <TableCell scope="col">
                                    <Text size="small" weight="bold">
                                        Modification
                                    </Text>
                                </TableCell>
                                <TableCell scope="col">
                                    <Text size="small" weight="bold">
                                        Assuré
                                    </Text>
                                </TableCell>
                                <TableCell scope="col">
                                    <Text size="small" weight="bold">
                                        Produit
                                    </Text>
                                </TableCell>
                                <TableCell scope="col">
                                    <Text size="small" weight="bold">
                                        Statut
                                    </Text>
                                </TableCell>
                                <TableCell scope="col">
                                    <Text size="small" weight="bold">
                                        &nbsp;
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {(this.state.souscriptions)
                                .map((devis, index) => {
                                    // console.log("devis = ", devis);
                                    let _client = devis.client || {};
                                    let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                                    return (
                                        <TableRow
                                            key={index + 1}
                                            style={{ backgroundColor: _bgColor, cursor: 'pointer' }}
                                            onClick={() => {
                                                this.setState({ devis, open: true })
                                            }}
                                        >
                                            <TableCell>
                                                <Text size="small">{devis.source} </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text size="small">{getApporteurName(devis)} </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text size="small">{getCommercialName(devis, this.props.commerciaux)}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text size="small">
                                                    {getSimpleDateFR(devis.createdAt)}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text size="small">
                                                    {getSimpleDateFR(devis.updatedAt)}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text size="small">
                                                    {(_client.raisonSocial || _client.raison_social)
                                                        ? (_client.raisonSocial || _client.raison_social)
                                                        : `${_client.lastname} ${_client.firstname}`}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text size="small">{devis.product} </Text>
                                            </TableCell>
                                            <TableCell>
                                                <RecapStatus status={devis.status} size='xsmall' />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>

                </Box>

                </Box>
                </BlockUi>
        )
    }
}

export default Souscriptions;
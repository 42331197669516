import { Box } from "grommet";
import React from "react";
import { CardContainer } from "../../../../elements/refont/containers/text-containser";
import Table from "../../../../elements/refont/table_v2";
import { TextLabel } from "../../../../elements/refont/text-label";
import { getVestute } from "../../../configs/service";
import { getSinistreGaranties } from "../../service";
import { calculateTotal } from "./utils-dommage";

const headers = [
    { label: "Remboursement", key: "remboursement", type: 'text', readOnly: true },
    { label: "A qui ?", key: "a_qui", type: 'text', readOnly: true },
    { label: "Montant", key: "montant", type: 'text', subType: 'currency', readOnly: true },
    { label: "Franchise", key: "franchise", type: 'text', subType: 'currency', readOnly: true },
    { label: "Montant à RBT", key: "montant_rbt", type: 'text', subType: 'currency', readOnly: true },
    { label: "Date de remboursement prévu", key: "rbt_rembourse_1_date_rbt", type: 'date' },
    { label: "Ref RBT", key: "rbt_rembourse_1_ref_rbt", type: 'text', readOnly: true },
]

export default function RemboursementDommage({ sinistre, onChangeSubmit }) {
    const [vestute, setVestute] = React.useState([]);
    const [remboursement, setRemboursement] = React.useState({});
    const [trustiwayGarantie, setTrustiwayGarantie] = React.useState({});

    React.useEffect(() => {
        getVestute().then((res) => {
            if (res.data.length > 0) {
                setVestute(res.data[0].value || []);
            }
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    React.useEffect(() => {
        if(!sinistre.subscription?.produit_reference) return;
        getSinistreGaranties(sinistre.subscription.produit_reference)
            .then(res => {
                const {parameters} = res.data;
                if(parameters?.length) {
                    setTrustiwayGarantie(parameters[0]);
                }
            })
            .catch(err => console.error(err));
    }, [sinistre]);

    React.useEffect(() => {
        if (!vestute.length) return;
        const montant = sinistre.dommages?.map(dommage => calculateTotal(dommage, vestute))?.reduce((acc, value) => acc + value, 0);
        console.log('montant', sinistre.dommages?.map(dommage => calculateTotal(dommage, vestute)), sinistre.dommages);
        const franchise = trustiwayGarantie?.franchise || 0;
        const plafond = trustiwayGarantie?.plafond || 0;
        const montant_rbt = montant - franchise > plafond ? plafond : montant - franchise;
        const _remboursement = {
            a_qui: "Locataire",
            montant,
            franchise,
            montant_rbt,
            rbt_rembourse_1_date_rbt: sinistre.rbt_rembourse_1_date_rbt,
            rbt_rembourse_1_ref_rbt: sinistre.rbt_rembourse_1_ref_rbt
        }
        setRemboursement(_remboursement);
    }, [vestute, sinistre, trustiwayGarantie]);


    const onSubmit = (remboursements) => {
        const value = remboursements[0];
        let { rbt_rembourse_1_date_rbt } = value;
        onChangeSubmit({
            ...sinistre,
            rbt_rembourse_1_date_rbt,
        })
    }

    return (
        <CardContainer>
            <Box direction={'row'} gap={'small'} justify="between" align="center">
                <Box fill background={"white"} direction="column">
                    <Box direction={'row'} gap={'small'} justify="between" align="center">
                        <TextLabel label={"Tableau de remboursements"} variant={"header3"} />
                    </Box>
                    <Table
                        data={[remboursement]}
                        headers={headers}
                        onChangeSubmit={data => onSubmit(data)}
                    />
                </Box>
            </Box>
        </CardContainer>
    )
}
import { Box, Text } from "grommet";
import React, { useEffect, useState } from "react";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { getApporteurPros } from "../../apporteur_pro/service";

const ApporteurGestionnaire = ({ apporteur, affiliation, source }) => {

    const [partenaires, setPartenaires] = useState([]);
    const [partenaire, setPartenaire] = useState(null);
    const [blocking, setBlocking] = useState(false);

    useEffect(() => {
        if (apporteur)
            loadApporteur();
    }, apporteur);

    const loadApporteur = () => {
        setBlocking(true);
        let domainEmail = apporteur?.email?.split('@').pop();

        getApporteurPros(0, null)
            .then(res => {
                setPartenaires(res.data);
                let p = res.data.filter(e => e.domainName === domainEmail || e.domain_email === domainEmail);
                if (p && p.length > 0) {
                    let _p = p[0];
                    setPartenaire(_p.raisonSocial);
                }
                setBlocking(false);
            })
            .catch(err => setBlocking(false));
    }

    return (
        <BlockUi tag="div" blocking={blocking}>
            <Box width={'medium'} gap="small" pad={'small'} background={'white'} justify='stretch' align="center" >
                <Text textAlign="center" style={{ fontWeight: "bold" }}>Apporteur gestionnaire :</Text>
                {(partenaire || affiliation) && <Line leftLabel="Apporteur" rightLabel={partenaire || affiliation.agence_name} />}
                {apporteur && <Line leftLabel="Utilisateur" rightLabel={`${apporteur.prenom} ${apporteur.nom}`} />}
                {apporteur && <Line leftLabel="Téléphone" rightLabel={apporteur.phone} />}
                {apporteur && <Line leftLabel="Mail" rightLabel={apporteur.email} />}
                {source && <Line leftLabel="Source" rightLabel={source} />}
            </Box>
        </BlockUi>
    )
}

export const ApporteurGestionnaireV2 = ({ apporteur, affiliation, source }) => {

    const [partenaires, setPartenaires] = useState([]);
    const [partenaire, setPartenaire] = useState(null);
    const [blocking, setBlocking] = useState(false);

    useEffect(() => {
        if (apporteur)
            loadApporteur();
    }, apporteur);

    const loadApporteur = () => {
        setBlocking(true);
        let domainEmail = apporteur?.email?.split('@').pop();

        getApporteurPros(0, null)
            .then(res => {
                setPartenaires(res.data);
                let p = res.data.filter(e => e.domainName === domainEmail || e.domain_email === domainEmail);
                if (p && p.length > 0) {
                    let _p = p[0];
                    setPartenaire(_p.raisonSocial);
                }
                setBlocking(false);
            })
            .catch(err => setBlocking(false));
    }

    return (
        <BlockUi tag="div" blocking={blocking}>
            <Box width={'full'} style={{ marginTop: 20 }}>
                {(partenaire || affiliation) && <LineV2 label="Apporteur" value={partenaire || affiliation.agence_name} />}
                {apporteur && <LineV2 label="Utilisateur" value={`${apporteur.prenom} ${apporteur.nom}`} />}
                {apporteur && <LineV2 label="Téléphone" value={apporteur.phone} />}
                {apporteur && <LineV2 label="Mail" value={apporteur.email} />}
                {source && <LineV2 label="Source" value={source} />}
            </Box>
        </BlockUi>
    )
}

const LineV2 = ({ label, value, color }) => {
    let style = color ? { border: '1px solid #000', borderRadius: 5, color } : {}

    return (<Text style={style} size="xsmall" >{label} : <strong>{value}</strong></Text>)
}

const Line = ({ leftLabel, rightLabel }) => (
    <Box
        direction="row"
        gap="medium"
        style={{ width: "100%" }}
        align="center"
        alignSelf="center"
    >
        <Box style={{ width: "50%" }}>
            <CustomText label={leftLabel + " :"} />
        </Box>
        <Box style={{ width: "50%" }}>
            <CustomText label={rightLabel} textAlign="start" isBold={true} />
        </Box>
    </Box>
);
const CustomText = ({ label, textAlign, isBold }) => (
    <Text
        style={{ fontWeight: isBold ? "bold" : "normal" }}
        size="small"
        textAlign={textAlign || "end"}
    >
        {label || ""}
    </Text>
);
export default ApporteurGestionnaire;
const moment = require('moment');

export const statut = {
    facture_locataire: 'Facturé au locataire',
    non_facture_locataire: 'Non Facturé au locataire'
}

export const getFranchiseDisplay = (sinistre) => {
    if (!sinistre.type_assurance?.includes(" Luxe") && sinistre.montant_sejour < 20000) return 0;
    return 20;
}
export const getFranchiseValue = (sinistre) => getFranchiseDisplay(sinistre) / 100;

export const getPercentHorsFranchise = (sinistre) => 1 - getFranchiseValue(sinistre);

export const getMaximumRemboursement = (sinistre) => {
    if (sinistre.reloue === 'Non') return sinistre.montant_sejour;
    if (sinistre.reloue === 'Pas de reclamation') return sinistre.acompte;
    if (sinistre.reloue === 'Oui') {
        const nbJours = moment(sinistre.date_fin).diff(moment(sinistre.date_debut), 'days');
        console.log('début: ', nbJours);
        const average = parseFloat(sinistre.montant_sejour) / nbJours;
        const solde = parseFloat(sinistre.montant_sejour) - parseFloat(sinistre.acompte);
        const nbJoursReloue = getNbDatesReloue(sinistre);

        const totalTheorique = average * nbJoursReloue;
        const max =  totalTheorique < solde ? totalTheorique : solde;
        console.log('totalTheorique', totalTheorique, solde, max);
        return parseFloat(max.toFixed(2));
    }
}

export const getLocataireRembourseType = (sinistre) => {
    return sinistre.solde_statut === statut.facture_locataire ? 'Acompte + Solde' : "Acompte";
}

export const getLocataireRembourseValue = (sinistre) => {
    const rembourseType = getLocataireRembourseType(sinistre);
    switch (rembourseType) {
        case 'Acompte': return parseFloat(sinistre.acompte);
        case 'Solde': return parseFloat(sinistre.solde);
        case 'Acompte + Solde':
            return parseFloat(sinistre.montant_sejour / sinistre.nombre_participant * sinistre.nombre_participant_annule);
    }
}

export const getRestLocataireRembourse = (sinistre) => {
    return getLocataireRembourseValue(sinistre) * getPercentHorsFranchise(sinistre);
}

export const getPropOuAgenceRembourseType = (sinistre) => {
    if (getLocataireRembourseType(sinistre) !== "Acompte") return "";
    const propAgenceType = sinistre.reloue === 'Oui' ? "Solde relocation" :
        sinistre.reloue === 'Non' ? "Solde non relocation" : "";
    return propAgenceType;
}

export const getPropOuAgenceRembourseValue = (sinistre) => {
    const type = getPropOuAgenceRembourseType(sinistre);
    switch (type) {
        case 'Acompte': return parseFloat(sinistre.acompte);
        case 'Solde relocation': return getMonttantPriseEnCharge(sinistre);
        case 'Solde non relocation': return parseFloat(sinistre.montant_sejour - sinistre.acompte);
    }
}

export const getRestMontant = (sinistre) => {
    return sinistre.montant_sejour - (getLocataireRembourseValue(sinistre) + (getPropOuAgenceRembourseValue(sinistre) || 0));
}

export const getRestFranchise = (sinistre) => {
    return getRestMontant(sinistre) * getFranchiseValue(sinistre);
}

export const getRestRemboursement = (sinistre) => {
    return getRestMontant(sinistre) * (1 - getFranchiseValue(sinistre));
}

export const getMontantReloue = (sinistre) => {
    if (!sinistre.montant_sejour ||
        !sinistre.date_debut ||
        !sinistre.date_fin
    ) return;
    const nbDate = getNbDatesReloue(sinistre);
    const average = parseFloat(sinistre.montant_sejour) / getTotalSejour(sinistre);
    return parseFloat((nbDate * average).toFixed(2));
}

export const getTotalSejour = (sinistre) => (moment(sinistre.date_fin).diff(moment(sinistre.date_debut), 'days'))

export const getNbDatesReloue = (sinistre) => {
    if (moment(sinistre.date_debut_reloue).isAfter(moment(sinistre.date_fin))) return 0;
    if (moment(sinistre.date_fin_reloue).isAfter(moment(sinistre.date_fin))) {
        return moment(sinistre.date_fin).diff(moment(sinistre.date_debut_reloue), 'day');
    }
    return moment(sinistre.date_fin_reloue).diff(moment(sinistre.date_debut_reloue), 'day')
};

export const getEligibleRBT = (sinistre) => {
    return parseFloat(sinistre.montant_sejour) * (1 - getFranchiseValue(sinistre)) /
        parseInt(sinistre.nombre_participant || 1) *
        parseInt(sinistre.nombre_participant_annule || 1)
}

export const generateRBTRef = sinistre => {
    if (!sinistre.rbt_rembourse_1_ref_rbt) {
        sinistre.rbt_rembourse_1_ref_rbt = sinistre.reference + '-RBT1';
    }
    if (!sinistre.rbt_rembourse_2_ref_rbt) {
        sinistre.rbt_rembourse_2_ref_rbt = sinistre.reference + '-RBT2';
    }
}

export const getMonttantPriseEnCharge = (sinistre) => {
    const max_reloue_diff = getMaximumRemboursement(sinistre) - parseFloat(sinistre.montant_sejour_reloue);
    return max_reloue_diff > 0 ? max_reloue_diff : 0;
}


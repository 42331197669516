import { Box, Card, Pagination, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import React, { useEffect } from "react";
import { useState } from "react";
import { getSimpleDateFR, getSimpleDateTimeFR } from "../../../services/utils";
import { ContractSimpliedDetails } from "../contract_details";
import RecapStatus from "../devis_status";
import StandardText from "../standard_text";
import PaymentStatus from '../devis_payment_status';
import { getClientInfo } from "../../outils/client_util";
import { CONTRAT_CLOTURE } from "../../../services/devis_contrat_status";
import { Checkmark, StatusGoodSmall, Validate } from "grommet-icons";
import { getBinomeId, getUser } from "../../../services/storage_service";
import { fetchUser } from "../../admin/service";

const ViewTable = ({ reports = [], commerciaux, commission_taux, onDevisSelected, updateDeal }) => {
    const pageSize = 20;
    const [indices, setIndices] = useState([0, pageSize]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        console.log(getUser());
    }, []);
    const getCommercialName = (devis) => {
        let name = commerciaux?.find(d => d.id === devis.commercial_id)?.name;
        if(!name && getUser().binome?.id === devis.commercial_id) {
            name = getUser().binome?.prenom + ' ' + getUser().binome?.nom;
        }
        return name;
    }

    return (
        <Box width='full' justify="center" align="center">
            <Box width={'full'} justify="center" align="center">
                <Box align="center" direction="row" justify="between" width={'full'}>
                    <StandardText size={'small'}
                        label={`Afficher ${indices[0] + 1 < (reports || []).length ? indices[0] + 1 : 0} - ${indices[1] < (reports || []).length ? indices[1] : (reports || []).length} de ${(reports || []).length}`}
                    />
                    <Pagination numberItems={(reports || []).length}
                        size='small'
                        step={pageSize}
                        onChange={({ startIndex, endIndex }) => {
                            console.log(startIndex);
                            setPage((startIndex / pageSize) + 1);
                            setIndices([startIndex, Math.min(endIndex, reports.length)]);
                        }}
                    />
                </Box>
                <Box
                    style={{
                        width: "100%",
                        marginTop: 0,
                        marginBottom: 0,
                    }}
                    background={"#fff"}
                    fill="horizontal"
                >
                    <Table
                        fill="horizontal"
                        style={{
                            width: "100%",
                        }}
                    >
                        <TableHeader>
                            <TableRow>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Source
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Apporteur
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Partenaire
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Commercial
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"90px"}>
                                    <Text size="small" weight="bold">
                                        Création -<br />
                                        Modification
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        BPA
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Date d'effet
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Clôture
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Assuré
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Produit
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Fournisseur
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        Compagnie
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                        N° Contrat
                                    </Text>
                                </TableCell>
                                <TableCell scope="col" width={"120px"}>
                                </TableCell>
                                {(reports || [])[0]?.model_type === 'emprunteur_horsligne' && <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">Paiements</Text>
                                </TableCell>}
                                <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">
                                    </Text>
                                </TableCell>
                                {(reports || [])[0]?.model_type === 'pno_seul' && (reports || [])[0]?.status === CONTRAT_CLOTURE && <TableCell scope="col" width={"80px"}>
                                    <Text size="small" weight="bold">Abonnement</Text>
                                </TableCell>}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {(reports || [])
                                .slice((page - 1) * pageSize, page * pageSize)
                                .map((devis, index) => {
                                    // console.log("devis = ", devis);
                                    let _client = devis.client || devis.entreprise || {};
                                    let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                                    return (
                                        <TableRow
                                            onClick={() => onDevisSelected(devis)}
                                            key={index + 1}
                                            style={{ backgroundColor: _bgColor, cursor: 'pointer' }}
                                        >
                                            <TableCell width={"80px"}>
                                                <Text size="small">{devis.source} </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">{getApporteurName(devis)}</Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">{getPartenaireName(devis)}</Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">{getCommercialName(devis)}</Text>
                                            </TableCell>
                                            <TableCell width={"90px"}>
                                                <Text size="small">
                                                    {getSimpleDateFR(devis.createdAt)} - <br />
                                                    {getSimpleDateFR(devis.updatedAt)}
                                                </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">
                                                    {getSimpleDateTimeFR(devis.date_bpa)}
                                                </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">
                                                    {getSimpleDateTimeFR(
                                                        devis.date_effet ||
                                                        devis.begin_date ||
                                                        devis.effectiveDate
                                                    )}
                                                </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">
                                                    {getSimpleDateTimeFR(devis.date_cloture)}
                                                </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">
                                                    {(_client.raisonSocial || _client.raison_social)
                                                        ? (_client.raisonSocial || _client.raison_social)
                                                        : `${_client.lastname} ${_client.firstname}`}
                                                </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">{devis.product} </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">{devis.fournisseur} </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">
                                                    {devis.compagnie || (devis.devis || devis).assureur}
                                                </Text>
                                            </TableCell>
                                            <TableCell width={"80px"}>
                                                <Text size="small">
                                                    {devis.numero_contrat || devis.contractNumber}
                                                </Text>
                                            </TableCell>
                                            <TableCell >
                                                <ContractSimpliedDetails
                                                    devis={devis}
                                                    commission_taux={commission_taux}
                                                    key={devis.id}
                                                    display={{
                                                        frais: '#E52764',
                                                        accompt: '#FC0000',
                                                        com: '#33BC00',
                                                        total_trustiway: '#73AAAA'
                                                    }}
                                                />
                                            </TableCell>
                                            {devis.model_type === 'emprunteur_horsligne' && <TableCell>
                                                <PaymentStatus data={devis} contract_paid={devis.contract_paid} isEmprunteur={true} status_payment={devis.status_payment} />
                                            </TableCell>
                                            }

                                            <TableCell width={"120px"}>
                                                <RecapStatus status={devis.status} size='xsmall' />
                                                {devis.sub_status && devis.status !== CONTRAT_CLOTURE && (
                                                    <Card pad="xsmall"><Text style={{ fontSize: 9 }}><b>{devis.sub_status}</b></Text></Card>
                                                )}
                                            </TableCell>
                                            {devis.status === CONTRAT_CLOTURE && devis.model_type === 'pno_seul' && devis.display_status_stripe && (
                                                <TableCell>
                                                    <Card pad="xsmall" direction="row" align="center" justify="center" gap="xsmall"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (typeof window !== 'undefined' && devis.stripe_subscription_id) {
                                                                window.open("https://dashboard.stripe.com/subscriptions/" + devis.stripe_subscription_id, '_blank')
                                                            }
                                                        }}
                                                    >
                                                        <StatusGoodSmall size="small" color={devis.display_status_stripe === 'Abo. en cours' ? "#33BC00" : "#FC0000"} />
                                                        <Text style={{ fontSize: 9 }}>
                                                            <b>{devis.display_status_stripe}</b>
                                                        </Text>
                                                    </Card>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </Box>
            </Box>
            <Box align="center" direction="row" justify="between" width={'full'}>
                <StandardText size={'small'}
                    label={`Afficher ${indices[0] + 1 < (reports || []).length ? indices[0] + 1 : 0} - ${indices[1] < (reports || []).length ? indices[1] : (reports || []).length} de ${(reports || []).length}`}
                />
                <Pagination numberItems={(reports || []).length}
                    size='small'
                    step={pageSize}
                    onChange={({ startIndex, endIndex }) => {
                        console.log(startIndex);
                        setPage((startIndex / pageSize) + 1);
                        setIndices([startIndex, Math.min(endIndex, reports.length)]);
                    }}
                />
            </Box>
        </Box>
    );
};

const ViewTableWithoutPagination = ({ reports = [], commerciaux, commission_taux, onDevisSelected }) => {

    return (
        <Box width='full' justify="center" align="center"
        >
            <Box
                style={{
                    width: "100%",
                    marginTop: 0,
                    marginBottom: 0,
                }}
                background={"#fff"}
            >
                <Table
                    fill="horizontal"
                    style={{
                        width: "100%",
                    }}
                >
                    <TableHeader>
                        <TableRow>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Source
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Apporteur
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Partenaire
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Commercial
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"90px"}>
                                <Text size="small" weight="bold">
                                    Création -<br />
                                    Modification
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    BPA
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Date d'effet
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Clôture
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Assuré
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"110px"}>
                                <Text size="small" weight="bold">
                                    Produit
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    Compagnie
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                    N° Contrat
                                </Text>
                            </TableCell>
                            <TableCell scope="col" width={"80px"}>
                                <Text size="small" weight="bold">
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {(reports || [])
                            .map((devis, index) => {
                                // console.log("devis = ", devis);
                                let _client = devis.client || devis.entreprise || {};
                                let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                                return (
                                    <TableRow
                                        onClick={() => onDevisSelected(devis)}
                                        key={index + 1}
                                        style={{ backgroundColor: _bgColor, cursor: 'pointer' }}
                                    >
                                        <TableCell width={"80px"}>
                                            <Text size="small">{devis.source} </Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">{getApporteurName(devis)}</Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">{getPartenaireName(devis)}</Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">{commerciaux?.find(d => d.id === devis.commercial_id)?.name}</Text>
                                        </TableCell>
                                        <TableCell width={"90px"}>
                                            <Text size="small">
                                                {getSimpleDateFR(devis.createdAt)} - <br />
                                                {getSimpleDateFR(devis.updatedAt)}
                                            </Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">
                                                {getSimpleDateTimeFR(devis.date_bpa)}
                                            </Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">
                                                {getSimpleDateTimeFR(
                                                    devis.date_effet ||
                                                    devis.begin_date ||
                                                    devis.effectiveDate
                                                )}
                                            </Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">
                                                {getSimpleDateTimeFR(devis.date_cloture)}
                                            </Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">
                                                {getClientInfo(devis)}
                                            </Text>
                                        </TableCell>
                                        <TableCell width={"110px"}>
                                            {devis.sub_product_detail ?
                                                <Text size="xsmall">{devis.sub_product_detail} </Text> :
                                                (devis.sub_product ?
                                                    <Text size="xsmall">{devis.sub_product} </Text> : <Text size="xsmall">{devis.product} </Text>)
                                            }
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">
                                                {devis.compagnie || (devis.devis || devis).assureur}
                                            </Text>
                                        </TableCell>
                                        <TableCell width={"80px"}>
                                            <Text size="small">
                                                {devis.numero_contrat || devis.contractNumber}
                                            </Text>
                                        </TableCell>
                                        <TableCell >
                                            <ContractSimpliedDetails
                                                devis={devis}
                                                commission_taux={commission_taux}
                                                key={devis.id}
                                                display={{
                                                    frais: '#E52764',
                                                    accompt: '#FC0000',
                                                    com: '#33BC00',
                                                    total_trustiway: '#73AAAA'
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell width={"120px"}>
                                            <RecapStatus status={devis.status} size='xsmall' />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </Box>
        </Box>
    );
}

const getApporteurName = (devis) => {
    let apporteur = devis.apporteur;
    if (apporteur) {
        return (`${apporteur.prenom} ${apporteur.nom}`)
    }
}

const getPartenaireName = (devis) => {
    if (devis.apporteur && devis.apporteur?.apporteur_pro) {
        return devis.apporteur?.apporteur_pro?.raisonSocial ?? '';
    }
    let affiliation = devis.affiliation;
    if (affiliation) {
        return affiliation?.agence_name;
    }
    return (devis.provider_name || devis.agence_name);
}


export {
    ViewTableWithoutPagination
};

export default ViewTable;
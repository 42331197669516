import React from "react";
import { Box, Heading, Text, Card } from "grommet";
import { getSimpleDateFR, toCurrency } from "../../../../services/utils";
import { DocumentDownload } from "grommet-icons";
import EmailEventTracker from "../../../elements/email_tracker";
import { PROJECT_OBJECTIF_PARTICULIER, SITUATION_PERSONNEL } from "../../../../services/credit_status";
import ApporteurGestionnaire from "../../../elements/apporteur_gestionaire";
import TrustiBot from "../../../trustibot";
import DateRecap from "../../../elements/date_recap";
import StandardText from "../../../elements/standard_text";
import { calculateMensualite } from "./utils";
import PrimaryButton from "../../../elements/primary_button";
import SectionDivider from "../../../voyages/components/session_devider";
import CreditRecapStatusV1 from "./credit_status_v1";
import CreditPanelAppelV1 from "./credit_panel_appel_v1";
import CreditStatusDateAppelV1 from "./status_date_appel_v1";
import ApporteurModification from "../../../elements/apporteur_gestionaire/modification";
import { getCreditsById } from "../../../../services/credit_service";

const RecapCredit = ({ devis, toEditMode, close, onSave, sendForm, updateDevis, showMessage, reload }) => {
  console.log(devis);
  return (
    <Box
      direction="row"
      gap='small'
      style={{ width: "100vw", paddingTop: 20 }}
    >
      <TrustiBot></TrustiBot>
      <Box
        gap="medium"
        // style={{ width: "100%" }}
        align="center"
        alignSelf="center"
        pad="small"
      >
        <Heading level="3" textAlign="center">
          TRUSTI CRÉDIT
        </Heading>
        <Box direction="row" fill="horizontal" justify="center" alignContent="center">
          <Box align="center">
            <CreditStatusDateAppelV1 devis={devis} />
          </Box>
          <Box>
            <Box alignSelf="end">
              <CreditRecapStatusV1 status={devis.status} />
              <CreditPanelAppelV1 devis={devis} updateDevis={updateDevis} onSave={onSave} />
            </Box>
          </Box>
        </Box>
        <Box gap="small" width={'full'} justify='center' align="center">
          <Card pad="small" gap="small" width={'full'} >
            <Text textAlign="center"><b>ID:</b> {devis.id}</Text>
          </Card>
        </Box>
        <Box gap="small" width={'full'}>
          <DateRecap deal={devis} />
        </Box>

        <Box width="xlarge" gap="small" direction={"row"}>
          <Card style={{ width: "100%" }} pad="small">
            <Pret devis={devis} size={"small"} />
          </Card>
        </Box>

        <Box width="xlarge" gap="small" direction={"row"}>
          <Card style={{ width: "100%" }} pad="small">
            {devis.simulation ? <Simulation id={devis.id} simulation={devis.simulation} size={"small"} /> : null}
          </Card>
        </Box>

        <Box width="xlarge" direction={"row"} gap="small">
          <Card style={{ width: "100%" }} pad="small" gap="small">
            <Assure assure={devis.emprunteur ? devis.emprunteur : devis.client} size={"small"} />
          </Card>
          {devis.co_emprunteurs.map((assure, index) => (
            <Card key={`${index}`} pad="small" style={{ width: "100%" }}>
              <Assure
                assure={assure}
                title={`Information co-emprunteur n°${index + 2}`}
                size={"small"} />
            </Card>
          ))}
        </Box>
        <Box width="xlarge" direction={"row"} gap="small">
          <Card style={{ width: "100%" }} pad="small">
            {devis.file_offre_de_pret ? (
              <a target={"_blank"} rel="noreferrer" href={`${devis.file_offre_de_pret}`}>
                <DocumentDownload /> Offre de prêt -
                {devis.file_offre_de_pret.split("/").pop()}
              </a>
            )
              : <></>}
            {devis.file_tableau_amortissement ? (
              <a target={"_blank"} rel="noreferrer" href={`${devis.file_tableau_amortissement}`}>
                <DocumentDownload /> Tableau d’amortissement -
                {devis.file_tableau_amortissement.split("/").pop()}
              </a>
            )
              : <></>}

            {devis.file_rib ? (
              <a target={"_blank"} rel="noreferrer" href={`${devis.file_rib}`}>
                <DocumentDownload /> RIB
                {devis.file_rib.split("/").pop()}
              </a>
            )
              : <></>}

          </Card>
        </Box>


        <Card pad={"small"} width={'xlarge'} >
          <ApporteurGestionnaire apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
          <ApporteurModification
            devis={devis}
            apporteur={devis.apporteur}
            showMessage={showMessage}
            onReload={() => {
              getCreditsById(devis.id).then(res => {
                reload(res.data);
              });
            }}
          />
        </Card>
        <Box width={'xlarge'}>
          <EmailEventTracker messageIds={devis.emailEventIds} />
        </Box>
      </Box>
    </Box>
  );
};

const Pret = ({ devis }) => {
  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label="Informations de projet" />
      <Line
        leftLabel="Votre projet"
        rightLabel={devis.project_objectif}
      />
      {devis.project_objectif === PROJECT_OBJECTIF_PARTICULIER.NEUF && (
        <Box>
          <Line
            leftLabel="Promesse de vente ou promesse d’achat"
            rightLabel={devis.file_promesse_vente ? (
              devis.file_promesse_vente.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />

          <Line
            leftLabel="Diagnostic de performance Énergétique"
            rightLabel={devis.file_dpe ? (
              devis.file_dpe.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Réaliserez-vous des travaux ?"
            rightLabel={<Text>{devis.avec_travaux === true ? 'OUI' : 'NON'}</Text>}
          />
          {devis.avec_travaux && (<Box>
            <Line
              leftLabel="Devis estimatifs et descriptifs"
              rightLabel={devis.file_travaux_devis ? (
                devis.file_travaux_devis.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
            <Line
              leftLabel="Le ou les baux de location"
              rightLabel={devis.file_contrat_bail ? (
                devis.file_contrat_bail.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
            <Line
              leftLabel="Vos attestations de valeur (notaire ou agence)"
              rightLabel={devis.file_attestation_valeur ? (
                devis.file_attestation_valeur.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />

          </Box>)}
        </Box>)}
      {devis.project_objectif === PROJECT_OBJECTIF_PARTICULIER.ANCIEN && (
        <Box>
          <Line
            leftLabel="Contrat de réservation complet"
            rightLabel={devis.file_contrat_reservation ? (
              devis.file_contrat_reservation.map((e, _idx) => {
                return e && (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="La notice descriptive"
            rightLabel={devis.file_notice_descriptive ? (
              devis.file_notice_descriptive.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Le plan intérieur"
            rightLabel={devis.file_plan_interieur ? (
              devis.file_plan_interieur.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />

          <Line
            leftLabel="Réaliserez-vous des travaux ?"
            rightLabel={<Text>{devis.avec_travaux === true ? 'OUI' : 'NON'}</Text>}
          />
          {devis.avec_travaux && (<Box>
            <Line
              leftLabel="Vos devis estimatifs et descriptifs"
              rightLabel={devis.file_travaux_devis ? (
                devis.file_travaux_devis.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
            <Line
              leftLabel="Le plan de masse et le plan de situation"
              rightLabel={devis.file_plan_masse ? (
                devis.file_plan_masse.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
            <Line
              leftLabel="Permis de construire ou récépissé de permis de construire"
              rightLabel={devis.file_permis_construire ? (
                devis.file_permis_construire.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />

          </Box>)}
        </Box>)}
      {devis.project_objectif === PROJECT_OBJECTIF_PARTICULIER.TERRAIN && (
        <Box>
          <Line
            leftLabel="Compromis de vente du terrain"
            rightLabel={devis.file_promesse_vente ? (
              devis.file_promesse_vente.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Contrat de construction"
            rightLabel={devis.file_contrat_construction ? (
              devis.file_contrat_construction.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="La notice descriptive"
            rightLabel={devis.file_notice_descriptive ? (
              devis.file_notice_descriptive.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Le plan intérieur"
            rightLabel={devis.file_plan_interieur ? (
              devis.file_plan_interieur.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Le plan de masse et le plan de situation"
            rightLabel={devis.file_plan_masse ? (
              devis.file_plan_masse.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Permis de construire ou récépissé de permis de construire"
            rightLabel={devis.file_permis_construire ? (
              devis.file_permis_construire.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />

          <Line
            leftLabel="Garantie dommage ouvrage et CCMI constructeur"
            rightLabel={devis.file_garantie_dommage_ouvrage ? (
              devis.file_garantie_dommage_ouvrage.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />

        </Box>)}
      {devis.project_objectif === PROJECT_OBJECTIF_PARTICULIER.CREANCES && (
        <Box>
          <Line
            leftLabel="Tableau d’amortissement des prêts en cours"
            rightLabel={devis.file_amortissement_credit_encours ? (
              devis.file_amortissement_credit_encours.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Décompte de remboursement par anticipation"
            rightLabel={devis.file_decompte_remboursement ? (
              devis.file_decompte_remboursement.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Projet de partage ou de liquidation"
            rightLabel={devis.file_projet_partage_liquidation ? (
              devis.file_projet_partage_liquidation.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />

        </Box>)}
      {devis.project_objectif === PROJECT_OBJECTIF_PARTICULIER.RENEGOCIER && (
        <Box>
          <Line
            leftLabel="Tableau d’amortissement des prêts en cours"
            rightLabel={devis.file_amortissement_credit_encours ? (
              devis.file_amortissement_credit_encours.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Décompte de remboursement par anticipation"
            rightLabel={devis.file_decompte_remboursement ? (
              devis.file_decompte_remboursement.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />

        </Box>)}
    </Box>
  );
};
const Assure = ({ assure, title }) => {
  let assureInfo = assure || {};
  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label={title || "Informations de l'emprunteur"} />
      <Line
        leftLabel="Civilité"
        rightLabel={assureInfo.civilite || assureInfo.civility}
      />
      {assureInfo.civilite !== "Société" && (
        <Line leftLabel="Prénom" rightLabel={assureInfo.firstname} />
      )}
      {assureInfo.civilite !== "Société" && (
        <Line leftLabel="Nom" rightLabel={assureInfo.lastname} />
      )}
      {assureInfo.civilite === "Société" && (
        <Line leftLabel="Raison social" rightLabel={assureInfo.raisonSocial} />
      )}
      <Line leftLabel="Mail" rightLabel={assureInfo.email} />
      <Line leftLabel="Tel" rightLabel={assureInfo.phone} />
      {!title && (
        <Line
          leftLabel="Adresse postale"
          rightLabel={`${assureInfo.address}, ${assureInfo.zip}, ${assureInfo.city}`}
        />
      )}
      <Line
        leftLabel="Date de naissance"
        rightLabel={`${assureInfo.date_of_birth
          ? getSimpleDateFR(assureInfo.date_of_birth)
          : getSimpleDateFR(assureInfo.birthDate)
          }`}
      />
      <Line
        leftLabel="Crédit en cours"
        rightLabel={<Text size={"small"}>{assureInfo.credit_encours === true ? 'OUI' : 'NON'}</Text>}
      />
      <Line
        leftLabel="Le client détient-il un patrimoine immobilier"
        rightLabel={<Text size={"small"}>{assureInfo.patrimoine === true ? 'OUI' : 'NON'}</Text>}
      />
      {/* ====== */}

      <Box width="large" gap="xsmall">
        <Text style={{ fontSize: 24, padding: 20, fontWeight: "bold" }} textAlign="start" >
          Documents identités
        </Text>
        <Line
          leftLabel="Carte d’identité ou passeport"
          rightLabel={assureInfo.file_cni_passeport ? (
            assureInfo.file_cni_passeport.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Livret de famille (y compris les pages des enfants)"
          rightLabel={assureInfo.file_livret_famille ? (
            assureInfo.file_livret_famille.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Contrat de mariage complet"
          rightLabel={assureInfo.file_contrat_mariage ? (
            assureInfo.file_contrat_mariage.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Contrat PACS complet"
          rightLabel={assureInfo.file_contrat_pacs ? (
            assureInfo.file_contrat_pacs.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Jugement de divorce + acte de liquidation complet"
          rightLabel={assureInfo.file_divorce ? (
            assureInfo.file_divorce.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />


      </Box>
      <Box width="large" gap="xsmall">
        <Text style={{ fontSize: 24, padding: 20, fontWeight: "bold" }} textAlign="start" >
          Informations sur logement
        </Text>
        <Line
          leftLabel="Vous êtes"
          rightLabel={<Text size={"small"}>{assureInfo.situation_personnelle}</Text>}
        />
        {assureInfo.situation_personnelle === SITUATION_PERSONNEL.PROPRIETAIRE && (
          <Box>
            <Line
              leftLabel="Le titre de propriété complet"
              rightLabel={assureInfo.file_titre_proprietaire ? (
                assureInfo.file_titre_proprietaire.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
            <Line
              leftLabel="Taxe foncière N-1 (Recto et Verso)"
              rightLabel={assureInfo.file_taxe_fonciere ? (
                assureInfo.file_taxe_fonciere.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />

            <Line
              leftLabel="Le mandat de vente"
              rightLabel={assureInfo.file_mandat_vente ? (
                assureInfo.file_mandat_vente.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
            <Line
              leftLabel="L’accord de prêt des acquéreurs (si existant)"
              rightLabel={assureInfo.file_accord_pret_acquereur ? (
                assureInfo.file_accord_pret_acquereur.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
          </Box>)}
        {assureInfo.situation_personnelle === SITUATION_PERSONNEL.LOCATAIRE && (
          <Box>
            <Line
              leftLabel="Dernières quittances de loyer"
              rightLabel={assureInfo.file_quittance_loyer ? (
                assureInfo.file_quittance_loyer.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
            <Line
              leftLabel="Contrat bail complet"
              rightLabel={assureInfo.file_contrat_bail ? (
                assureInfo.file_contrat_bail.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />

          </Box>)}
        {assureInfo.situation_personnelle === SITUATION_PERSONNEL.HEBERGE && (
          <Box>
            <Line
              leftLabel="Une d’attestation d’hébergement avec date de début"
              rightLabel={assureInfo.file_attestation_herbergement ? (
                assureInfo.file_attestation_herbergement.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
            <Line
              leftLabel="la carte d’identité recto/verso des hébergeants"
              rightLabel={assureInfo.file_cni_passeport_hebergeants ? (
                assureInfo.file_cni_passeport_hebergeants.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
            <Line
              leftLabel="Justificatif de domicile EDF ou Eaux des hébergeants"
              rightLabel={assureInfo.file_justificatif_domicile_hebergeants ? (
                assureInfo.file_justificatif_domicile_hebergeants.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
            <Line
              leftLabel="Taxe d’habitation des hébergeants"
              rightLabel={assureInfo.file_taxe_habitation_hebergeants ? (
                assureInfo.file_taxe_habitation_hebergeants.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />

          </Box>)}
        {assureInfo.situation_personnelle === SITUATION_PERSONNEL.SOCIETE && (
          <Box>
            <Line
              leftLabel="Les 3 dernières liasses fiscales (Bilans / 2035 / 2072…)"
              rightLabel={assureInfo.file_liasses_fiscales ? (
                assureInfo.file_liasses_fiscales.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
            <Line
              leftLabel="RIB"
              rightLabel={assureInfo.file_rib_pro ? (
                assureInfo.file_rib_pro.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
            <Line
              leftLabel="Les 3 derniers Relevés bancaires Pro"
              rightLabel={assureInfo.file_reveles_bancaires_pro ? (
                assureInfo.file_reveles_bancaires_pro.map((e, _idx) => {
                  return (
                    <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                      <DocumentDownload />
                      {e && e.file_url.split("/").pop()}
                    </a>
                  );
                })) : <></>}
            />
          </Box>)}
      </Box>

      <Box width="large" gap="xsmall">
        <Line
          leftLabel="Taxe d’habitation Recto et Verso"
          rightLabel={assureInfo.file_taxe_habitation ? (
            assureInfo.file_taxe_habitation.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Justificatif de domicile EDF ou Télécom récent"
          rightLabel={assureInfo.file_justificatif_domicile ? (
            assureInfo.file_justificatif_domicile.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />

      </Box>

      <Box width="large" gap="xsmall">
        <Text style={{ fontSize: 24, padding: 20, fontWeight: "bold" }} textAlign="start" >
          Informations sur revenu
        </Text>
        <Line
          leftLabel="Les 3 derniers bulletins de paie"
          rightLabel={assureInfo.file_3_derniers_bulletins ? (
            assureInfo.file_3_derniers_bulletins.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Le bulletins de paie de décembre N-1"
          rightLabel={assureInfo.file_bulletin_dernier_decembre ? (
            assureInfo.file_bulletin_dernier_decembre.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Les 2 derniers Avis d’imposition complet"
          rightLabel={assureInfo.file_avis_impots ? (
            assureInfo.file_avis_impots.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Contrat de travail initial ou Attestation employeur (avec mention de l’ancienneté / le salaire de base et les primes  / mention CDI) ou justificatif de titularisation pour les Fonctionnaires"
          rightLabel={assureInfo.file_contrat_travail ? (
            assureInfo.file_contrat_travail.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Justificatifs de congés payés (Pro BTP…)"
          rightLabel={assureInfo.file_justificatif_conges_paye ? (
            assureInfo.file_justificatif_conges_paye.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Justificatifs de retraite"
          rightLabel={assureInfo.file_justificatif_retraite ? (
            assureInfo.file_justificatif_retraite.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />

        <Line
          leftLabel="Justificatifs de pensions reçues diverse"
          rightLabel={assureInfo.file_justificatif_pensions_recus ? (
            assureInfo.file_justificatif_pensions_recus.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Justificatifs d’allocations familiales"
          rightLabel={assureInfo.file_justificatif_allocations ? (
            assureInfo.file_justificatif_allocations.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Certificat de grossesse"
          rightLabel={assureInfo.file_certificat_grossesse ? (
            assureInfo.file_certificat_grossesse.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />

      </Box>

      <Box width="large" gap="xsmall">
        <Text style={{ fontSize: 24, padding: 20, fontWeight: "bold" }} textAlign="start" >
          Informations bancaires
        </Text>
        <Line
          leftLabel="Les 3 derniers mois de relevés de comptes courants (tous les comptes personnels et comptes joints)"
          rightLabel={assureInfo.file_reveles_bancaires_courant ? (
            assureInfo.file_reveles_bancaires_courant.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="Un dernier relevé des comptes épargne"
          rightLabel={assureInfo.file_reveles_bancaires_epargne ? (
            assureInfo.file_reveles_bancaires_epargne.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />
        <Line
          leftLabel="RIB"
          rightLabel={assureInfo.file_rib ? (
            assureInfo.file_rib.map((e, _idx) => {
              return (
                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                  <DocumentDownload />
                  {e && e.file_url.split("/").pop()}
                </a>
              );
            })) : <></>}
        />

      </Box>

      <Box width="large" gap="xsmall">
        <Text style={{ fontSize: 24, padding: 20, fontWeight: "bold" }} textAlign="start" >
          Situation financière
        </Text>

        {assureInfo.credit_encours && <Box>
          <Line
            leftLabel="Tableaux d’amortissement datés des crédits en cours et offres de prêts initiales"
            rightLabel={assureInfo.file_amortissement_credit_encours ? (
              assureInfo.file_amortissement_credit_encours.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Derniers relevés des crédits « Renouvelables » ou carte magasin ou paiement en plusieurs fois"
            rightLabel={assureInfo.file_releves_credit_encours ? (
              assureInfo.file_releves_credit_encours.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />

        </Box>}
        <Line size={"small"}
          leftLabel="Détenez-vous un Patrimoine (Locatif / Viager / Résidence Secondaire)"
          rightLabel={
            <Text size="small">{assureInfo.patrimoine === true ? "OUI" : "NON"}</Text>
          }
        />
        {assureInfo.patrimoine && <Box>
          <Line
            leftLabel="Titres de propriété ou attestation de propriété avec prix"
            rightLabel={assureInfo.file_patrimoine_titre_proprietaire ? (
              assureInfo.file_patrimoine_titre_proprietaire.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Taxes foncières N-1 (Recto et Verso)"
            rightLabel={assureInfo.file_patrimoine_taxe_fonciere ? (
              assureInfo.file_patrimoine_taxe_fonciere.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Déclaration 2044 complète ou justificatif micro-foncier"
            rightLabel={assureInfo.file_patrimoine_declaration_2044 ? (
              assureInfo.file_patrimoine_declaration_2044.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Crédit (Offres + Tableau d’amortissement)"
            rightLabel={assureInfo.file_patrimoine_credit ? (
              assureInfo.file_patrimoine_credit.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Dernières quittances de loyer"
            rightLabel={assureInfo.file_patrimoine_quittance_loyer ? (
              assureInfo.file_patrimoine_quittance_loyer.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Le ou les baux de location"
            rightLabel={assureInfo.file_patrimoine_bail_location ? (
              assureInfo.file_patrimoine_bail_location.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
          <Line
            leftLabel="Attestations de valeur (notaire ou agence)"
            rightLabel={assureInfo.file_patrimoine_attestation_valeur ? (
              assureInfo.file_patrimoine_attestation_valeur.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e.file_url}`}>
                    <DocumentDownload />
                    {e && e.file_url.split("/").pop()}
                  </a>
                );
              })) : <></>}
          />
        </Box>}
      </Box>
      {/* ====== */}
    </Box>
  );
};

const CustomText = ({ label, textAlign, isBold }) => (
  <Text
    style={{ fontWeight: isBold ? "bold" : "normal" }}
    size="small"
    textAlign={textAlign || "end"}
  >
    {label || ""}
  </Text>
);
const Line = ({ leftLabel, rightLabel }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "50%" }}>
      <CustomText label={leftLabel + " :"} />
    </Box>
    <Box style={{ width: "50%" }}>
      <CustomText label={rightLabel} textAlign="start" isBold={true} />
    </Box>
  </Box>
);

const SubHeading = ({ label }) => (
  <Text textAlign="center" style={{ fontWeight: "bold" }}>
    {label}
  </Text>
);

const Simulation = ({ id, simulation }) => {
  const totalCredit = parseInt(simulation.montant_total) + parseInt(simulation.credit_actuel) + parseInt(simulation.montant_supplementaire)
  const mensualite = calculateMensualite(simulation.duree, totalCredit);


  return (
    <Box width={'full'} pad='small' gap="small">
      <StandardText label={'Simulation'} style={{ fontWeight: 'bold' }} />
      <SectionDivider title={"Résultat de la simulation"} />
      <Box width={'full'} direction='row' justify="between">

        <Box pad={'medium'} direction='row' gap="medium" align="center" justify="between">
          <Box >
            <StandardText size={'large'} style={{ fontWeight: 'bold' }} label={"Nouvelle mensualité"} />
            <Box direction="row" justify="between" align="center">
              <Box direction="row">
                <StandardText size={'large'} style={{ fontWeight: 'bold' }} label={toCurrency(mensualite, true)} />
                <Box justify="end">
                  <StandardText size={'small'} style={{ fontWeight: 'bold' }} label={"/ mois"} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box pad={'medium'} direction='row' gap="medium" align="center" justify="between">
          <Box gap="small" justify="center" align="center">
            <StandardText textAlign="center" size={'small'} label='Montant des crédits à regrouper' />
            <StandardText size={'large'} style={{ fontWeight: 'bold' }} color='#59A4F4'
              label={toCurrency(simulation.credit_actuel + simulation.montant_supplementaire, true)}
            />
          </Box>
          <Box gap="small" justify="center" align="center">
            <StandardText textAlign="center" size={'small'} label='Durée du remboursement' />
            <StandardText size={'large'} style={{ fontWeight: 'bold' }} color='#59A4F4'
              label={simulation.duree + " mois"}
            />
          </Box>
          <Box gap="small" justify="center" align="center">
            <StandardText textAlign="center" size={'small'} label='TAEG' />
            <StandardText size={'large'} style={{ fontWeight: 'bold' }} color='#59A4F4'
              label={"3,71%"}
            />
          </Box>
        </Box>
      </Box>

      {parseInt(simulation.mensualite_total) - mensualite > 0 ?
        <Box gap="medium">
          <SectionDivider title={"Avantage"} />
          <Box width={'full'} align='center' direction="row" justify="between">
            <Box gap="small" direction="row" align="center" width={'medium'} >
              <Box justify="center" align="center">
                <StandardText style={{ fontWeight: 'bold', fontSize: 40 }} label={'👌🏼'} />
              </Box>
              <Box align="center">
                <StandardText size={'small'} label={"Vous créer une enveloppe de trésorie de"} />
                <StandardText size={'large'} style={{ fontWeight: 'bold' }} label={toCurrency(simulation.montant_supplementaire, true)} />
              </Box>
            </Box>
            <Box width={'medium'} direction='row' >
              <Box justify="center" align="center">
                <StandardText style={{ fontWeight: 'bold', fontSize: 40 }} label={'👍🏼'} />
              </Box>
              <Box align="center">
                <StandardText size={'small'} label={"Vous réaliserez une economie de"} />
                <StandardText size={'large'} style={{ fontWeight: 'bold' }} label={toCurrency(parseInt(simulation.mensualite_total) - mensualite, true) + ' / mois'} />
              </Box>
            </Box>
          </Box>
        </Box> : null
      }
      <SectionDivider title={"Information rachat de crédit pour simulation"} />
      <Box width={'full'} direction='row' justify="between" gap="medium">
        <Box gap='medium'>
          <Box width={'full'}>
            <StandardText label={"Êtes vous propriétaire ?"} size='xsmall' />
            <Box width={'small'}>
              <PrimaryButton size="small"
                label={(simulation.propouloc === "Propriétaire" || simulation.propouloc === "Proprietaire") ? 'Oui' : 'Non'}
              />
            </Box>
          </Box>
          <Box width={'small'}>
            <PrimaryButton size='small' label={"Simulation"} width='small'
              background="#59A4F4"
              onClick={() => window.open(`https://form.trustiway.com/trusti-forms/landing-pages/credit?id=${id}`)}
            />

          </Box>
        </Box>
        <Box width={'full'} gap="small">

          <Box width={'full'} gap='small' direction="row">
            <Box width={'full'}>
              <StandardText label={"Les revenus de foyer"} size='small' />
              <StandardText label={`${toCurrency(simulation.revenue, true)} / mois`} style={{ fontWeight: 'bold', lineHeight: 2 }} />
            </Box>
            <Box width={'full'}>
              <StandardText label={"Montant supplémentaire nécessaire en trésorerie"} size='small' />
              <StandardText label={toCurrency(simulation.montant_supplementaire, true)} style={{ fontWeight: 'bold' }} />
            </Box>
          </Box>
          <SectionDivider title={'Crédit consommation'} />
          <Box width={'full'} gap='small' direction="row">
            <Box width={'full'}>
              <StandardText label={"Montant global de vos mensualités de crédits conso actuels."} size='small' />
              <StandardText label={`${toCurrency(simulation.totalCurrentInstalments, true)} / mois`} style={{ fontWeight: 'bold', lineHeight: 2 }} />
            </Box>
            <Box width={'full'}>
              <StandardText label={"Montant total de vos crédits à la conso actuels."} size='small' />
              <StandardText
                label={toCurrency(simulation.credit_actuel, true)}
                style={{ fontWeight: 'bold' }}
              />
            </Box>
          </Box>
          <SectionDivider title={'Crédit immobilier'} />
          <Box width={'full'} gap='small' direction="row">
            <Box width={'full'}>
              <StandardText label={"Mensualité de vos Crédits immobilier"} size='small' />
              <StandardText label={`${toCurrency(simulation.mensualite_total, true)} / mois`} style={{ fontWeight: 'bold', lineHeight: 2 }} />
            </Box>
            <Box width={'full'}>
              <StandardText label={"Crédit immobilier en cours total à rembourser."} size='small' />
              <StandardText label={toCurrency(simulation.montant_total, true)} style={{ fontWeight: 'bold' }} />
            </Box>
          </Box>
          <SectionDivider title={'Informations personnelles'} />
          <Box width={'full'} gap='small' direction="row">
            <Box width={'full'}>
              <StandardText label={"Êtes-vous propriétaire ou locataire"} size='small' />
              <StandardText label={`${simulation.propouloc}`} style={{ fontWeight: 'bold', lineHeight: 2 }} />
            </Box>
            <Box width={'full'}>
              <StandardText label={"Quelle est votre situation professionnell"} size='small' />
              <StandardText label={simulation.stuationpro} style={{ fontWeight: 'bold' }} />
            </Box>
          </Box>
          <Box width={'full'} gap='small' direction="row">
            <Box width={'full'}>
              <StandardText label={"Empruntez-vous seul ?"} size='small' />
              <StandardText label={`${simulation.sbancaire} `} style={{ fontWeight: 'bold', lineHeight: 2 }} />
            </Box>
            <Box width={'full'}>
              <StandardText label={"Faites-vous l’objet d’un fichage bancaire"} size='small' />
              <StandardText label={simulation.fbank} style={{ fontWeight: 'bold' }} />
            </Box>
          </Box>
        </Box>
      </Box>

    </Box>
  )
}

export default RecapCredit;

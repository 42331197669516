import { Box, Heading } from "grommet";
import { Checkmark } from "grommet-icons";
import React from "react";
import { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import PrimaryButton from "../../../elements/primary_button";

const ClientInfo = ({ devis, updateDevis, onNext, isLoading }) => {
  
  return (
    <Box
      gap="small"
      width="full"
      align="center"
      pad="small"
      justify="center"
    >
      <Box justify="center" alignSelf="center">
        <Heading
          level={3}
          textAlign="center"
        >{`Informations de client`}</Heading>
      </Box>

      <ContactSelectV2
        contact={devis.client || {}}
        updateContact={updateDevis}
        addressIncluded={true}
        birthDayIncluded={true}
      />
      <Box style={{ height: 40 }} />
      <Box direction="row" align="center" justify="center" gap="medium">
        <PrimaryButton
          disabled={isLoading}
          label={`Valider`}
          icon={<Checkmark />}
          onClick={onNext}
        />
      </Box>
    </Box>
  );
};

export default ClientInfo;


import React from 'react';
import { Box, Heading, Table, TableBody, TableCell, TableHeader, TableRow, Text, Button, Card } from 'grommet';
import { toCurrency } from '../../services/utils';
import { Checkmark, Close, Trash, Add, Edit } from 'grommet-icons';
import service from '../../services/admin_service';
import CustomInput from '../elements/custom_input';

const validateNewItem = item => {
    if (isNaN(parseFloat(item.valeur_inferieure)) || isNaN(parseFloat(item.valeur_superieure)) ||
        isNaN(parseFloat(item.pourcentage_alternance)) ||
        isNaN(parseFloat(item.pourcentage_commercial)) ||
        isNaN(parseFloat(item.pourcentage_mandataire))) {
        return 'Veuillez remplir tous les valeurs numériques';
    }

    console.log("item.valeur_superieure = ", item.valeur_superieure);

    if ((parseFloat(item.valeur_superieure) < parseFloat(item.valeur_inferieure)) && parseFloat(item.valeur_superieure) !== -1) {
        return 'Hmmm, la valeur inférieure est supérieure que la valeur supérieure';
    }
}

class CommissionAdminCredit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            commissions_percentage: [],
            creationMode: false,
            newItem: {},
            missingInterval: undefined
        }
    }

    componentDidMount = () => {
        this.reloadData();
    }

    reloadData = () => {
        service.getCommissionCreditPercentage()
            .then(res => this.setState({ commissions_percentage: res.data.sort((s1, s2) => s1.valeur_inferieure < s2.valeur_inferieure ? -1 : 1) }))

        service.getMissingCreditInterval()
            .then(res => this.setState({ missingInterval: res.data }))
    }

    delete = item => {
        service.deleteCommissionCreditPercentage(item.id)
            .then(res => this.reloadData());
    }

    updateNewItem = (field, value) => {
        let item = this.state.newItem;
        item[field] = value.replace(',', '.');
        this.setState({ newItem: Object.assign({}, item) });
    }

    createNewItem = async () => {
        let item = this.state.newItem;
        let message = validateNewItem(item);
        if (message) {
            this.props.showMessage(message, "error");
            return;
        }

        if(item.id) {
            await service.updateIntervalCredit(item);
            this.setState({newItem: {}})
            this.reloadData();
            this.props.showMessage('Mis à jour avec succès', 'success')
            return;
        }

        service.createCommissionCreditPercentage(item)
            .then(res => {
                this.setState({ newItem: {} })
                this.reloadData();
                this.props.showMessage('Ajouté avec succès', 'success')
            })
    }

    render() {
        return (
            <Box width={"xlarge"} margin={"medium"} justify='center' align='center' alignSelf='center' alignContent='center'>
                <Heading level={2} textAlign='center'>Pourcentage de commission de crédit</Heading>
                {!this.state.creationMode &&
                    <Box round="full" overflow="hidden" pad="medium">
                        <Button primary icon={<Add />} onClick={() => this.setState({ creationMode: true })} />
                    </Box>
                }
                {this.state.creationMode &&
                    <Box width={"xlarge"} margin={"medium"} justify='center' align='center' alignSelf='center' alignContent='center'>
                        <Card pad="small" gap='xsmall'>
                            <Box pad={"xsmall"} gap='xsmall' justify='between' direction='row'>
                                <FieldItem
                                    label={"À partir de (€)"}
                                    value={this.state.newItem.valeur_inferieure === 0 ? '0' : this.state.newItem.valeur_inferieure}
                                    onChange={e => this.updateNewItem('valeur_inferieure', e.target.value)}
                                />
                                <FieldItem
                                    label={"À inférieure de (€)"}
                                    value={this.state.newItem.valeur_superieure}
                                    onChange={e => this.updateNewItem('valeur_superieure', e.target.value)}
                                />
                                <FieldItem
                                    label={"Commercial (%)"}
                                    value={this.state.newItem.pourcentage_commercial}
                                    onChange={e => this.updateNewItem('pourcentage_commercial', e.target.value)}
                                />
                                <FieldItem
                                    label={"Alternance (%)"}
                                    value={this.state.newItem.pourcentage_alternance}
                                    onChange={e => this.updateNewItem('pourcentage_alternance', e.target.value)}
                                />
                                <FieldItem
                                    label={"Mandataire (%)"}
                                    value={this.state.newItem.pourcentage_mandataire}
                                    onChange={e => this.updateNewItem('pourcentage_mandataire', e.target.value)}
                                />
                            </Box>
                            <Box direction='row' justify='center' alignContent='center'>
                                <Box round="full" overflow="hidden" pad="medium">
                                    <Button primary icon={<Checkmark />} onClick={() => this.createNewItem()} />
                                </Box>
                                <Box round="full" overflow="hidden" pad="medium">
                                    <Button icon={<Close />} onClick={() => this.setState({ newItem: {}, creationMode: false })} />
                                </Box>
                            </Box>
                        </Card>

                    </Box>
                }

                {this.state.missingInterval &&
                    <Box margin={"medium"}>
                        {this.state.missingInterval.dupplicate &&
                            <Text color={"red"}>Vous avez déclaré une dupplication d'interval : <strong>{toCurrency(this.state.missingInterval.end)} à {' '} {toCurrency(this.state.missingInterval.start)}</strong></Text>
                        }
                        {!this.state.missingInterval.dupplicate &&
                            <Text>Vous n'avez pas déclaré le taux pour l'interval : <strong>{toCurrency(this.state.missingInterval.start)} à {' '}
                                {this.state.missingInterval.end === -1 ? 'inifinity' : toCurrency(this.state.missingInterval.end)}</strong>
                            </Text>
                        }
                    </Box>
                }
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableCell><Text>À partir de</Text></TableCell>
                            <TableCell><Text>À inférieure de</Text></TableCell>
                            <TableCell><Text>Commercial</Text></TableCell>
                            <TableCell><Text>Alternance</Text></TableCell>
                            <TableCell><Text>Mandataire</Text></TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            this.state.commissions_percentage
                                .filter(c => !c.commercial_id)
                                .map((interval, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Text>{toCurrency(interval.valeur_inferieure)}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{interval.valeur_superieure === -1 ? 'inifinity' : toCurrency(interval.valeur_superieure)}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{interval.pourcentage_commercial}%</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{interval.pourcentage_alternance}%</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{interval.pourcentage_mandataire}%</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Button primary icon={<Edit />} onClick={() => this.setState({ creationMode: true, newItem: interval })} />
                                        </TableCell>
                                        <TableCell>
                                            <Button primary icon={<Trash />} onClick={() => this.delete(interval)} />
                                        </TableCell>
                                    </TableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            </Box>
        )
    }
}

const FieldItem = ({ label, value, onChange }) => (
    <Box pad={"small"} gap='xsmall'>
        <Text>{label}</Text>
        <CustomInput value={value} onChange={onChange} />
    </Box>
)

export default CommissionAdminCredit;
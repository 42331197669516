const axios = require('axios');
const storageService = require('./storage_service');
const { baseAPI } = require('../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};


const getCommisisonPercentage = () => {
    return axios.get(`${baseAPI}/api-admin/commission-taux`, { headers: HEADERS })
}



const getEquipes = () => {
    return axios.get(`${baseAPI}/equipes`, { headers: HEADERS })
}

const getRoles = () => {
    return axios.get(`${baseAPI}/roles`, { headers: HEADERS })
}

const getUserCommissionTaux = (commercial_id, produit) => {
    let params = { commercial_id, produit }
    return axios.get(`${baseAPI}/users/commission-taux`, { headers: HEADERS, params })
}

const updateUserCommissionTaux = (body) => {
    return axios.put(`${baseAPI}/users/commission-taux`,
        body,
        { headers: HEADERS }
    )
}

const getCollabs = (params) => {
    // if (!params || Object.keys(params).length === 0) {
    //     params = { 'role.category': 'interne' };
    // }
    return axios.get(`${baseAPI}/collaborateurs_internes/`, { params, headers: HEADERS });
}

const deleteCollab = (id) => {
    return axios.delete(`${baseAPI}/collaborateurs/${id}`, { headers: HEADERS })
}

const saveCollab = (body) => {
    return !body.id ?
        axios.post(`${baseAPI}/collaborateurs/`,
            body,
            { headers: HEADERS }
        ) :
        axios.put(`${baseAPI}/collaborateurs/${body.id}`,
            body,
            { headers: HEADERS }
        )
}

const saveEquipe = (body) => {
    return !body.id ?
        axios.post(`${baseAPI}/equipes/`,
            body,
            { headers: HEADERS }
        ) :
        axios.put(`${baseAPI}/equipes/${body.id}`,
            body,
            { headers: HEADERS }
        )
}

const deleteEquipe = (id) => {
    return axios.delete(`${baseAPI}/equipes/${id}`, { headers: HEADERS })
}

const getAdminCommission = (filter, commercial_id) => {
    return axios.get(`${baseAPI}/api-admin/commission`, { params: { commercial_id, ...filter }, headers: HEADERS });
}

const getAdminAppels = (filter, commercial_id) => {
    return axios.get(`${baseAPI}/api-admin/appels`, { params: { commercial_id, ...filter }, headers: HEADERS });
}

const createCommissionPercentage = (body) => {
    return axios.post(`${baseAPI}/api-admin/commission-taux`, body, { headers: HEADERS })
}

const updateInterval = (item) => {
    return axios.put(`${baseAPI}/api-admin/commission-taux/${item.id}`, item, { headers: HEADERS })
}

const deleteCommissionPercentage = (id) => {
    return axios.delete(`${baseAPI}/api-admin/commission-taux/${id}`, {}, { headers: HEADERS })
}

const getMissingInterval = () => {
    return axios.get(`${baseAPI}/api-admin/commission-taux/validate`, {}, { headers: HEADERS })
}

const getCommissionCreditPercentage = () => {
    return axios.get(`${baseAPI}/api-admin/commission-taux-credit`, { headers: HEADERS })
}
const createCommissionCreditPercentage = (body) => {
    return axios.post(`${baseAPI}/api-admin/commission-taux-credit`, body, { headers: HEADERS })
}

const updateIntervalCredit = (item) => {
    return axios.put(`${baseAPI}/api-admin/commission-taux-credit/${item.id}`, item, { headers: HEADERS })
}

const deleteCommissionCreditPercentage = (id) => {
    return axios.delete(`${baseAPI}/api-admin/commission-taux-credit/${id}`, {}, { headers: HEADERS })
}

const getMissingCreditInterval = () => {
    return axios.get(`${baseAPI}/api-admin/commission-taux-credit/validate`, {}, { headers: HEADERS })
}

const getEquipeMembers = () => {
    return axios.get(`${baseAPI}/membres`, { headers: HEADERS })
}

module.exports = {
    getCommissionCreditPercentage,
    createCommissionCreditPercentage,
    updateIntervalCredit,
    deleteCommissionCreditPercentage,
    getMissingCreditInterval,
    deleteCommissionPercentage,
    getCommisisonPercentage,
    getUserCommissionTaux,
    updateUserCommissionTaux,
    createCommissionPercentage,
    getMissingInterval,
    getAdminCommission,
    getAdminAppels,
    saveCollab,
    deleteCollab,
    getEquipes,
    saveEquipe,
    deleteEquipe,
    getCollabs,
    getEquipeMembers,
    getRoles,
    updateInterval
}
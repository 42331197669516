import React, { useState } from "react";
import { Box, TextArea, Text, Card, Stack, Accordion, AccordionPanel, RadioButtonGroup, FileInput, Button, Grid, CheckBox } from "grommet";
import { getSimpleDateFR, toCurrency } from "../../../../services/utils";
import { AddCircle, Checkmark, CloudUpload, DocumentExcel, DocumentPdf, DocumentWord, Download, DownloadOption, Folder, Refresh, Trash, View } from "grommet-icons";
import EmailEventTracker from "../../../elements/email_tracker";
import CreditStatusDateAppel from "./status_date_appel";
import CreditRecapStatus from "./credit_status";
import ApporteurGestionnaire from "../../../elements/apporteur_gestionaire";
import CreditPanelAppel from "./credit_panel_appel";
import DateRecap from "../../../elements/date_recap";
import { calculateMensualite } from "./utils";
import PrimaryButton from "../../../elements/primary_button";
import Assure from "../assure/assure";
import CustomInput from "../../../elements/custom_input";
import moment from "moment";
import { uploadFile, uploadFilesMulti } from "../../../../services/storage_service";
import CustomSelect from "../../../elements/select_fill";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';
import BanqueInfoRac from "./bank_info_rac";
import { creditLinks, getCreditsById } from "../../../../services/credit_service";
import ApporteurModification from "../../../elements/apporteur_gestionaire/modification";
import ContactButtonPopup from "../../../contacts/contact_modal/button_popup";
import DateInput from "../../../elements/date_input";
import { SectionHeader, renderPanelHeader } from "../../../elements/ux_template/layout";
import { DOCUMENTS_REQUIRES, EN_ETUDE_DE_ANALYSTE, EN_ETUDE_DE_PRE_ANALYSTE } from "../../../../services/credit_status";

const RecapRegroupe = ({ devis, analystes, close, onSave, sendForm, updateDevis, showMessage, needToSave, setIsLoading, reload }) => {

  const [activeIndex, setActiveIndex] = useState([0, 1, 2, 7]);
  console.log(devis);
  console.log('activeIndex = ', activeIndex);

  const onSaveData = (_options) => {
    updateDevis('need_generate_appel', 0);
    onSave(_options);
  }

  if (!devis.emprunteur?.firstname || !devis.emprunteur?.email) {
    updateDevis('emprunteur', {
      firstname: devis.client?.firstname,
      lastname: devis.client?.lastname,
      civilite: devis.client?.civilite,
      email: devis.client?.email,
      phone: devis.client?.phone,
      date_of_birth: devis.client?.date_of_birth
    });
  }

  if (!devis.credit_immobilier_encours) {
    if (devis.simulation?.proprietaire === 'Oui') {
      updateDevis('credit_immobilier_encours', 'Oui');
    }
  }

  return (
    <Box
      gap="medium"
      align="center"
      alignSelf="center"
      pad="small"
      fill
    >
      {needToSave && <Box fill background={'yellow'} justify="end" direction="row" pad='xxsmall'>
        <PrimaryButton background="transparent" icon={<Checkmark color="green" />} reverse onClick={onSaveData} label={'Valider'} color='green'></PrimaryButton>
      </Box>}
      <Card background={'white'} direction={'row'} justify="between" alignContent="start" align="start" pad="small" style={{ width: '100%' }}>
        <Box alignContent="start" align="start">
          <Text weight={'bold'} size="medium">Trusti Crédit</Text>
          <Text size="small">{devis.sub_product_detail ?
            <Text size="xsmall">{devis.sub_product_detail} </Text> :
            (devis.sub_product ?
              <Text size="xsmall">{devis.sub_product} </Text> : null)
          }</Text>
        </Box>
        <Box alignContent="start" align="start">
          <DateRecap deal={devis} />
          <Text textAlign="center"><b>ID:</b> {devis.id}</Text>
          {devis.status === EN_ETUDE_DE_PRE_ANALYSTE || devis.status === EN_ETUDE_DE_ANALYSTE ?
            <Text size="small" textAlign="center"><b>Pré-analyse:</b> {analystes?.find(c => c.id === devis.pre_analyste_id)?.name}</Text> : null
          }
          {devis.status === EN_ETUDE_DE_ANALYSTE ?
            <Text size="small" textAlign="center"><b>Analyse:</b> {analystes?.find(c => c.id === devis.analyste_id)?.name}</Text> : null
          }
        </Box>
        <Box direction='row' alignContent="center" align="center"><Text textAlign="center">Statut: </Text><CreditRecapStatus status={devis.status} /></Box>
      </Card>

      <Accordion multiple fill activeIndex={activeIndex} gap="small" onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}>
        <AccordionPanel
          header={renderPanelHeader('Suivi Prospection', activeIndex.includes(0))}
        >
          <Box gap="small" pad="small" background={'white'}>
            <CreditPanelAppel devis={devis} updateDevis={updateDevis} onSave={onSave} showMessage={showMessage} />
            <CreditStatusDateAppel devis={devis} />
          </Box>
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader('Informations client', activeIndex.includes(1))}
        >
          <Box gap="small" pad="small" background={'white'}>
            <ClientInfo devis={devis} updateDevis={updateDevis} reload={() => {
              getCreditsById(devis.id).then(res => {
                reload(res.data);
              });
            }} showMessage={showMessage} size={"small"} />
          </Box>
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader('Simulation', activeIndex.includes(2))}
        >
          <Box gap="small" pad="small" background={'white'}>
            {devis.simulation ? <Simulation id={devis.id} simulation={devis.simulation} size={"small"} /> : <Box><Text>Pas de simulation</Text></Box>}
          </Box>
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader('Emprunteur', activeIndex.includes(3))}
        >
          <Assure
            assure={devis.emprunteur || {}}
            updateDevis={(_data) => {
              updateDevis("emprunteur", _data);
            }}
            onSave={onSaveData}
          />
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader('Co Emprunteur', activeIndex.includes(4))}
        >
          <Assure
            assure={devis.co_emprunteurs?.length > 0 ? devis.co_emprunteurs[0] : {}}
            updateDevis={(_data) => {
              updateDevis("co_emprunteurs", [_data]);
            }}
            onSave={onSaveData}
          />
        </AccordionPanel>
        <AccordionPanel
          header={renderPanelHeader('Informations personnelles', activeIndex.includes(5))}
        >
          <InfoPerso devis={devis} updateDevis={updateDevis} onSave={onSaveData} showMessage={showMessage} />
        </AccordionPanel>


        <AccordionPanel
          header={renderPanelHeader('Documents demandés', activeIndex.includes(6))}
        >
          <DocumentsRequired devis={devis} updateDevis={updateDevis} onSave={onSaveData} showMessage={showMessage} setIsLoading={setIsLoading} />
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader('Gestion électronique documentaire (GED)', activeIndex.includes(6))}
        >
          <InfoDocuments devis={devis} updateDevis={updateDevis} onSave={onSaveData} showMessage={showMessage} setIsLoading={setIsLoading} />
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader('Analyse financière RAC', activeIndex.includes(7))}
        >
          <AnalyseRAC devis={devis} updateDevis={updateDevis} onSave={onSaveData} showMessage={showMessage} setIsLoading={setIsLoading} />
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader('Note de synthèse', activeIndex.includes(8))}
        >
          <NodeInfo
            devis={devis} setIsLoading={setIsLoading}
          />
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader('Information offre', activeIndex.includes(9))}
        >
          <BanqueInfoRac devis={devis} updateDevis={updateDevis} onSave={onSaveData} />
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader('Suivi de mail', activeIndex.includes(10))}
        >
          <EmailEventTracker messageIds={devis.emailEventIds} />
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader('Apporteur', activeIndex.includes(11))}
        >
          <Box direction="row" pad="medium" justify="between" background={'white'}>
            <ApporteurGestionnaire apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
            <ApporteurModification
              devis={devis}
              apporteur={devis.apporteur}
              showMessage={showMessage}
              onReload={() => {
                getCreditsById(devis.id).then(res => {
                  reload(res.data);
                });
              }}
            />
          </Box>
        </AccordionPanel>

      </Accordion>

    </Box>

  );
};

const ClientInfo = ({ devis, updateDevis, reload, showMessage }) => {
  let assureInfo = devis.client || {};
  return (
    <Box gap="small" direction="row" >
      <Box gap="small" fill >
        <Line
          textAlign='start'
          leftLabel="Civilité"
          rightLabel={assureInfo.civilite || assureInfo.civility}
        />
        {assureInfo.civilite !== "Société" && (
          <Line textAlign='start' leftLabel="Prénom" rightLabel={assureInfo.firstname} />
        )}
        {assureInfo.civilite !== "Société" && (
          <Line textAlign='start' leftLabel="Nom" rightLabel={assureInfo.lastname} />
        )}
        {assureInfo.civilite === "Société" && (
          <Line textAlign='start' leftLabel="Raison social" rightLabel={assureInfo.raisonSocial} />
        )}
        <Line textAlign='start' leftLabel="Mail" rightLabel={assureInfo.email} />
        <Line textAlign='start' leftLabel="Téléphone" rightLabel={assureInfo.phone} />

        <Line textAlign='start'
          leftLabel="Date de naissance"
          rightLabel={`${assureInfo.date_of_birth
            ? getSimpleDateFR(assureInfo.date_of_birth)
            : getSimpleDateFR(assureInfo.birthDate)
            }`}
        />

      </Box>
      <Box gap="small" fill align="center" >
        <Line
          leftLabel="Adresse postale"
          rightLabel={`${assureInfo.address}, ${assureInfo.zip}, ${assureInfo.city}`}
        />
        <Line
          leftLabel="Crédit en cours"
          rightLabel={<Text size={"small"}>{assureInfo.credit_encours === true ? 'OUI' : 'NON'}</Text>}
        />
        <Line
          leftLabel="Le client détient-il un patrimoine immobilier"
          rightLabel={<Text size={"small"}>{assureInfo.patrimoine === true ? 'OUI' : 'NON'}</Text>}
        />

        <Box style={{ height: 50 }}></Box>

        <ContactButtonPopup contact={devis.client} updateDevis={(field, value) => {

          let _client = devis.client;
          _client[field] = value;
          updateDevis('client', _client);

        }} reload={reload} showMessage={showMessage} />
      </Box>
    </Box>
  );
};

const CustomText = ({ label, textAlign, isBold }) => (
  <Text
    style={{ fontWeight: isBold ? "bold" : "normal" }}
    size="small"
    textAlign={textAlign || "end"}
  >
    {label || ""}
  </Text>
);
const Line = ({ leftLabel, rightLabel, textAlign }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "50%" }}>
      <CustomText label={leftLabel + " :"} textAlign={textAlign || 'end'} />
    </Box>
    <Box style={{ width: "50%" }}>
      <CustomText label={rightLabel} textAlign="start" isBold={true} />
    </Box>
  </Box>
);


const Simulation = ({ id, simulation }) => {
  const totalCredit = parseFloat(simulation.montant_total) + parseInt(simulation.credit_actuel) + parseFloat(simulation.montant_supplementaire)
  const mensualite = calculateMensualite(simulation.duree, totalCredit);
  return (
    <Box fill pad='small' gap="small">
      <Box background="#F7F7F7">
        <SectionHeader title={"Résultat de la simulation"} color="#72ACFF" round={true} />
        <Box fill direction='row' justify="between" gap="small" pad={"medium"}>
          <Box fill>
            <Text size={'large'}  >Nouvelle mensualité</Text>
            <Text size={'large'} weight={'bold'} >{`${toCurrency(mensualite, true)}/mois`} </Text>
          </Box>
          <Box fill>
            <Text size={'large'}  >{"Montant des crédits à regrouper"} </Text>
            <Text size={'large'} weight={'bold'}  >{`${toCurrency(parseFloat(simulation.credit_actuel) + parseFloat(simulation.montant_supplementaire), true)}`} </Text>
          </Box>
          <Box fill>
            <Text size={'large'}  >"Durée du remboursement</Text>
            <Text size={'large'} weight={'bold'}  >{simulation.duree + " mois"}</Text>
          </Box>
          <Box fill>
            <Text size={'large'}  >TAEG</Text>
            <Text size={'large'} weight={'bold'}  >{"3,71%"}</Text>
          </Box>
        </Box>
      </Box>

      <Box fill direction='row' justify="between" gap="small" style={{ marginTop: 20 }}>

        <Box background="#F7F7F7" fill>
          <SectionHeader title={"Crédit Consommation 🚗🪑"} color="#CECECE" round={true} />
          <Box fill direction='row' justify="between" gap="small" pad={"medium"}>
            <Box fill>
              <Text size={'large'}  >Mensualité</Text>
              <Text size={'large'} weight={'bold'} >{`${toCurrency(simulation.totalCurrentInstalments, true)} / mois`}</Text>
            </Box>
            <Box fill>
              <Text size={'large'}  >CRD</Text>
              <Text size={'large'} weight={'bold'}  >{toCurrency(simulation.credit_actuel, true)}</Text>
            </Box>
          </Box>
        </Box>

        <Box background="#F7F7F7" fill >
          <SectionHeader title={"Crédit Immobilier 🏠"} color="#CECECE" round={true} />
          <Box fill direction='row' justify="between" gap="small" pad={"medium"}>
            <Box fill>
              <Text size={'large'}  >Mensualité</Text>
              <Text size={'large'} weight={'bold'} >{`${toCurrency(simulation.mensualite_total, true)} / mois`}</Text>
            </Box>
            <Box fill>
              <Text size={'large'}  >CRD</Text>
              <Text size={'large'} weight={'bold'}  >{toCurrency(simulation.montant_total, true)}</Text>
            </Box>
          </Box>
        </Box>

        <Box background="#F7F7F7" fill>
          <SectionHeader title={"Trésorerie"} color="#CECECE" round={true} />
          <Box fill direction='row' justify="between" gap="small" pad={"medium"}>
            <Box fill>
              <Text size={'large'}  >Montant nécessaire en trésorerie</Text>
              <Text size={'large'} weight={'bold'} >{toCurrency(simulation.montant_supplementaire, true)}</Text>
            </Box>
          </Box>
        </Box>

      </Box>

      <Box background="#F7F7F7" fill style={{ marginTop: 20 }}>
        <SectionHeader title={"Information rachat de crédit pour simulation"} round={true} />
        <Box width={'full'} direction='row' justify="between" gap="medium" pad={"small"}>

          <Box fill>
            <Text size={'large'}  >Êtes vous propriétaire ?</Text>
            <Text size={'large'} weight={'bold'}  >{(simulation.propouloc === "Proprietaire" || simulation.propouloc === "Propriétaire") ? 'Oui' : 'Non'}</Text>

            <Text size={'large'} style={{ marginTop: 10 }}  >Êtes-vous propriétaire ou locataire</Text>
            <Text size={'large'} weight={'bold'}  >{simulation.propouloc}</Text>

            <Text size={'large'} style={{ marginTop: 10 }} >Empruntez-vous seul ?</Text>
            <Text size={'large'} weight={'bold'}  >{`${simulation.sbancaire || '"non défini"'}`}</Text>

          </Box>

          <Box fill>
            <Text size={'large'}  >Les revenus de foyer</Text>
            <Text size={'large'} weight={'bold'}  >{`${toCurrency(simulation.revenue, true)} / mois`}</Text>

            <Text size={'large'} style={{ marginTop: 10 }} >Quelle est votre situation professionnel</Text>
            <Text size={'large'} weight={'bold'}  >{simulation.stuationpro || '"non défini"'}</Text>

            <Text size={'large'} style={{ marginTop: 10 }} >Faites-vous l’objet d’un fichage bancaire</Text>
            <Text size={'large'} weight={'bold'}  >{simulation.fbank || '"non défini"'}</Text>

          </Box>

          {/* <Box width={'small'}>
              <PrimaryButton size='small' label={"Simulation"} width='small'
                background="#59A4F4"
                onClick={() => window.open(`https://form.trustiway.com/trusti-forms/landing-pages/credit?id=${id}`)}
              />
            </Box> */}

        </Box>
      </Box>



    </Box>
  )
}

const InfoDocuments = ({ devis, onSave, updateDevis, setIsLoading, showMessage }) => {

  const [dataChanged, setDataChanged] = useState(false);

  const updateAndSave = (field, value) => {
    console.log(value);
    updateDevis(field, value);
    setDataChanged(true);
  }

  const submitModification = () => {
    setDataChanged(false);
    onSave({ skipValidation: true });
  }
  return (
    <Box>
      {dataChanged && <Box fill background={'yellow'} justify="end" direction="row" pad='xxsmall'>
        <PrimaryButton background="transparent" icon={<Checkmark color="green" />} reverse onClick={submitModification} label={'Valider'} color='green'></PrimaryButton>
      </Box>}
      <Box fill background={"white"} direction="column">
        <SectionHeader title={"Document"} />
        <Box fill >
          <Box direction="row" fill background={'#F0F0F0'} margin={'small'} style={{ borderRadius: 12, paddingLeft: 20 }}>
            <Box width={'small'}><Text >Document</Text></Box>
            <Box width={'small'}><Text >Le nom de votre fichier</Text></Box>
          </Box>
        </Box>

        {devis.files_dossier ? (
          devis.files_dossier.map((e, _idx) => {
            return (
              <Box
                key={`file_${_idx}`}
                gap="small"
                justify="between"
              >{e && e.file_url && (
                <Box direction="row" gap="small" overflow="hidden" align="center">
                  <Box width={'small'} style={{ paddingLeft: 20 }}>
                    <CustomSelect
                      style={{ width: '100%' }}
                      placeholder='Type de document'
                      options={['BANQUE', 'REVENUS', 'PRET', 'ETAT CIVIL', 'RAC']}
                      value={e.type}
                      onChange={({ option }) => {
                        let _files = devis['files_dossier'];
                        _files[_idx]["type"] = option;
                        updateAndSave('files_dossier', _files);
                      }}
                    /></Box>
                  <Box width={'medium'}>
                    <CustomInput
                      value={e.name}
                      onChange={event => {
                        let _files = devis['files_dossier'];
                        _files[_idx]["name"] = event.target.value;
                        updateAndSave('files_dossier', _files);
                      }}
                    />
                  </Box>

                  <PrimaryButton background="#72ACFF" icon={<View />} onClick={() => {
                    window.open(e.file_url, "_blank");
                  }} label={'Visualiser'} ></PrimaryButton>

                  <PrimaryButton background="#72ACFF" icon={<Download />} onClick={() => {

                    var filename = e.name?.includes(".") ? e.name : `${e.name}.${e.file_url && (e.file_url.split("/").pop()).split(".")?.pop()}`;
                    // loading a file and add it in a zip file
                    console.log(e.file_url);
                    console.log(filename);

                    saveAs(e.file_url, filename);


                  }} label={'Télécharger'} ></PrimaryButton>

                  <PrimaryButton
                    onClick={() => {
                      if (window.confirm("Êtes-vous sûr de vouloir supprimer ce fichier ?")) {
                        console.log("remove index = ", _idx);
                        let _files = devis['files_dossier'];
                        _files.splice(_idx, 1);
                        updateDevis('files_dossier', _files);
                        onSave();
                      }
                    }}
                    icon={<Trash />}
                    label={'Supprimer'}
                  ></PrimaryButton>

                </Box>)}
              </Box>
            );
          })
        ) : (
          <></>
        )}
        <Box fill style={{ marginBottom: 20, marginTop: 30, paddingLeft: 20, paddingRight: 20 }}>
          <Stack fill style={{ background: '#eee' }}>
            <Box fill align="center" justify="center" style={{ height: 60 }} >
              <CloudUpload size="medium" />
            </Box>

            <FileInput
              key={(devis['files_dossier'] || []).length}
              messages={{
                browse: "Déposez ou cliquer pour télécharger vos fichiers",
                dropPrompt: "Déposez ou cliquer pour télécharger vos fichiers"
              }}
              multiple
              maxSize={20}
              onChange={async (event, { files }) => {
                setIsLoading(true);
                await doUploadFile(
                  event.target.files,
                  `${devis.id}`,
                  async (_data) => {
                    if (_data.length > 0) {
                      let _files = devis['files_dossier'] || [];
                      for (let i = 0; i < _data.length; i++) {
                        const _uploaded = _data[i];
                        _files.push({ __component: "upload-documents.upload-documents", type: '', file_url: _uploaded.url, name: _uploaded.url.split("/").pop().replace(`${devis.id}_`, ""), source_id: `${devis.id}`, source: `${devis.model_type}` });
                      }
                      updateDevis('files_dossier', _files.map(e => { return { ...e, __component: "upload-documents.upload-documents" } }));
                      onSave();
                    }

                  },
                  showMessage
                );
                setIsLoading(false);
              }}
            />

          </Stack>
          <Box pad={'xsmall'}>
            <Text textAlign="center">Pour télécharger vos documents dans la GED, veuillez vous assurer que vos fichiers sont au format PDF. Si vos fichiers ne sont pas en PDF, convertissez-les facilement en utilisant l'option "modifier PDF".</Text>
          </Box>
          <Box direction="row" align="center" justify="stretch" margin={'small'} gap="small">
            <Box width={"medium"}><PrimaryButton
              onClick={() => {
                if (window.confirm("Êtes-vous sûr de vouloir supprimer tous les fichiers ?")) {
                  updateDevis('files_dossier', []);
                  onSave();
                }
              }}
              icon={<Trash />}
              label={'supprimer tous les fichiers'}
            ></PrimaryButton></Box>
            <Box fill><PrimaryButton
              disabled={devis.files_dossier?.length > 0 ? false : true}
              background="#72ACFF" icon={<DownloadOption />} onClick={() => {
                var zip = new JSZip();
                var count = 0;
                var zipFilename = `Dossier-${devis.client?.firstname}-${devis.client?.lastname}-${devis.id}.zip`;
                var urls = devis.files_dossier;

                urls.forEach(function (file) {
                  var filename = file.name?.includes(".") ? file.name : `${file.name}.${file.file_url && (file.file_url.split("/").pop()).split(".")?.pop()}`;
                  // loading a file and add it in a zip file
                  JSZipUtils.getBinaryContent(file.file_url, function (err, data) {
                    if (err) {
                      throw err; // or handle the error
                    }
                    zip.file(`${file.type}/${filename}`, data, { binary: true });
                    count++;
                    if (count == urls.length) {
                      zip.generateAsync({ type: 'blob' }).then(function (content) {
                        saveAs(content, zipFilename);
                      });
                    }
                  });
                });
              }} label={'Télécharger tous les documents'} ></PrimaryButton></Box>
            <Box width={"medium"}>
              <PrimaryButton background="#72ACFF" icon={<DocumentPdf />} onClick={() => {
                window.open("https://www.ilovepdf.com/fr", "_blank");
              }} label={'Modifier PDF'} ></PrimaryButton></Box>
          </Box>

        </Box>
      </Box>
    </Box>);

}

const DocumentsRequired = ({ devis, onSave, updateDevis, setIsLoading, showMessage }) => {

  const [dataChanged, setDataChanged] = useState(false);

  const [comment, setComment] = useState(devis.comment || '');


  const updateChanged = (doc, typeData, value, needToSave = true) => {
    const _docs = devis.documents_requires || [];
    const _idx = _docs.findIndex(e => e?.id === doc?.id);

    if (_idx >= 0) {
      _docs[_idx] = { ..._docs[_idx], [typeData]: value };
    } else {
      _docs.push({ ...doc, [typeData]: value });
    }
    updateDevis("documents_requires", _docs);
    if (needToSave)
      setDataChanged(true);
  }

  const isValueChecked = (doc, typeData) => {
    let _docs = devis.documents_requires || [];
    let _idx = _docs.findIndex(e => e?.id === doc?.id);
    if (_idx >= 0) {
      return _docs[_idx][typeData] || false;
    }
    return false;
  }

  const submitModification = () => {
    setDataChanged(false);
    onSave({ skipValidation: true });
  }

  let _currentGroup = "";
  let _showGroup = false;
  let _currentSubGroup = "";
  let _showSubGroup = false;

  // check if none of doc in the list is required
  let _needFillRequired = true;
  for (let i = 0; i < DOCUMENTS_REQUIRES.length; i++) {
    const _doc = DOCUMENTS_REQUIRES[i];
    if (isValueChecked(_doc, "required")) {
      _needFillRequired = false;
      break;
    }
  }

  if (_needFillRequired) {
    for (let i = 0; i < DOCUMENTS_REQUIRES.length; i++) {
      const _doc = DOCUMENTS_REQUIRES[i];
      updateChanged(_doc, "required", true, false);
    }
    onSave({ skipValidation: true });
    console.log('needFillRequired = ', _needFillRequired);
  }


  return (
    <Box>
      {dataChanged && <Box fill background={'yellow'} justify="end" direction="row" pad='xxsmall'>
        <PrimaryButton background="transparent" icon={<Checkmark color="green" />} reverse onClick={submitModification} label={'Valider'} color='green'></PrimaryButton>
      </Box>}
      <Box fill background={"white"} direction="column" gap="xsmall">

        {DOCUMENTS_REQUIRES && (
          DOCUMENTS_REQUIRES.map((_doc, _idx) => {
            if (_doc.group !== _currentGroup) {
              _currentGroup = _doc.group;
              _showGroup = true;
            } else {
              _showGroup = false
            }
            if (_doc.subgroup && _doc.subgroup !== _currentSubGroup) {
              _currentSubGroup = _doc.subgroup;
              _showSubGroup = true;
            } else {
              _showSubGroup = false
            }
            return (
              <>
                {_showGroup && <SectionHeader title={_doc.group} />}
                {_showGroup && <Box fill >
                  <Box direction="row" background={'#F0F0F0'} margin={'small'} style={{ borderRadius: 12, paddingLeft: 20, marginRight: 80 }}>
                    <Box width={'xsmall'} style={{ justifyContent: 'center' }}><Text ><b>Demander</b></Text></Box>
                    <Box width={'xsmall'} style={{ justifyContent: 'center' }}><Text ><b>Envoyé par client</b></Text></Box>
                    <Box width={'xsmall'} style={{ justifyContent: 'center' }}><Text ><b>Validé</b></Text></Box>
                    <Box width={'xsmall'} style={{ justifyContent: 'center', width: 80 }}><Text ><b>Non conforme</b></Text></Box>
                    <Box style={{ justifyContent: 'center' }}><Text ><b>Type des documents</b></Text></Box>
                  </Box>
                </Box>}
                {_showSubGroup && <Box direction="row" fill >
                  <Box width={'xsmall'}></Box>
                  <Box width={'xsmall'}></Box>
                  <Box width={'xsmall'}></Box>
                  <Box width={'xsmall'}></Box>
                  <Box style={{ justifyContent: 'center' }}><Text ><b>{_doc.subgroup}</b></Text></Box>
                </Box>}
                <Box direction="row" fill>
                  <Box width={'xsmall'} style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <CheckBox
                      checked={isValueChecked(_doc, "required")}
                      onChange={(event) => {
                        updateChanged(_doc, "required", event.target.checked);
                      }}
                      size="xsmall"
                      color="grey"
                    ></CheckBox>
                  </Box>
                  <Box width={'xsmall'} style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <CheckBox
                      checked={isValueChecked(_doc, "client_sent")}
                      onChange={(event) => {
                        updateChanged(_doc, "client_sent", event.target.checked);
                      }}
                      size="xsmall"
                      color="grey"
                    ></CheckBox>
                  </Box>
                  <Box width={'xsmall'} style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <CheckBox
                      checked={isValueChecked(_doc, "accepted")}
                      onChange={(event) => {
                        updateChanged(_doc, "accepted", event.target.checked);
                      }}
                      size="xsmall"
                      color="grey"
                    ></CheckBox>
                  </Box>
                  <Box width={'xsmall'} style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <CheckBox
                      checked={isValueChecked(_doc, "not_conform")}
                      onChange={(event) => {
                        updateChanged(_doc, "not_conform", event.target.checked);
                      }}
                      size="xsmall"
                      color="grey"
                    ></CheckBox>
                  </Box>
                  <Box style={{ justifyContent: 'center' }}><Text >{_doc.name}</Text></Box>
                </Box>
              </>
            );
          })
        )}

      </Box>
      <Box fill background={"white"} direction="column" gap="xsmall">
        <SectionHeader title='Commentaires' />
        <Box fill pad="small">
          <TextArea
            placeholder={'Commentaires'}
            style={{ background: '#EDEDED', borderRadius: 10, border: 'none', padding: '5px 14px' }}
            value={comment}
            onChange={(event) => {
              setDataChanged(true);
              updateDevis("comment", event.target.value);
              setComment(event.target.value);

            }}
          />
        </Box>
      </Box>
      {dataChanged && <Box fill background={'yellow'} justify="end" direction="row" pad='xxsmall'>
        <PrimaryButton background="transparent" icon={<Checkmark color="green" />} reverse onClick={submitModification} label={'Valider'} color='green'></PrimaryButton>
      </Box>}
    </Box>);

}

const AnalyseRAC = ({ devis, onSave, updateDevis }) => {

  const [dataChanged, setDataChanged] = useState(false);

  const updateAndSave = (field, value) => {
    console.log(value);
    updateDevis(field, value);
    setDataChanged(true);
  }

  const submitModification = () => {
    calculMontants();
    setDataChanged(false);
    onSave({ skipValidation: true });
  }


  const PMT = (rate, periods, presentValue, futureValue = 0, type = 0) => {
    if (rate === 0) {
      return -(presentValue + futureValue) / periods;
    }

    const pvif = Math.pow(1 + rate, periods);
    let pmt;

    if (type === 1) {
      pmt = (rate * presentValue * (1 + rate) + futureValue) / ((1 + rate) * (pvif - 1));
    } else {
      pmt = (rate * presentValue * pvif + futureValue) / (pvif - 1);
    }

    return -pmt;
  }

  const formatNumber = (_val) => {
    if (dataChanged)
      return _val;
    if (_val) {
      if (typeof _val.toFixed === 'function') {
        return _val.toFixed(2);
      } else {
        return parseFloat(_val).toFixed(2);
      }
    }
    return _val;
  }

  const calculMontants = () => {
    let _crdImmo = 0;
    let _crdConso = 0;
    let _mensuel = 0;
    let _payerAutres = 0;
    if (devis.credit_immobilier_encours === 'Oui') {
      for (let i = 0; i < devis.credits_immobilier?.length; i++) {
        const c = devis.credits_immobilier[i];
        if (c.action === 'à racheter') {
          _crdImmo += parseFloat(c.crd) || 0;
          _mensuel += parseFloat(c.montant_mensuel) || 0;
        } else if (c.action === 'à conserver') {
          _payerAutres += parseFloat(c.montant_mensuel) || 0
        }
      }
    }
    for (let i = 0; i < devis.credits_consommation?.length; i++) {
      const c = devis.credits_consommation[i];
      if (c.action === 'à racheter') {
        _crdConso += parseFloat(c.crd) || 0;
        _mensuel += parseFloat(c.montant_mensuel) || 0;
      } else if (c.action === 'à conserver') {
        _payerAutres += parseFloat(c.montant_mensuel) || 0
      }
    }
    updateAndSave("montant_immobilier_encours", _crdImmo);
    updateAndSave("montant_consommation_encours", _crdConso);
    updateAndSave("montant_autres_charges", _payerAutres);
    updateAndSave("montant_rembourser_mensuel", _mensuel);
    let _total_need = _crdImmo + _crdConso + (parseFloat(devis.montant_need) || 0);
    updateAndSave("emprunte_total", _total_need);
    let _mensuel_assurance = _total_need * (parseFloat(devis.taux_assurance) || 0) / 12 / 100;
    updateAndSave("cout_assurance_mensuel", _mensuel_assurance);
    let _nouvelle_mensuel = PMT((parseFloat(devis.taux_regroupement) || 0) / 12 / 100, (parseFloat(devis.duration_expected) || 0), -_total_need);
    updateAndSave("nouvelle_mensuel", _nouvelle_mensuel);

    let _interet_credit = ((parseFloat(devis.duration_expected) || 0) * _nouvelle_mensuel) - _total_need;
    updateAndSave("interet_credit", _interet_credit);
    let _cout_total_assurance = _total_need * (parseFloat(devis.taux_assurance) || 0) / 100 * (parseFloat(devis.duration_expected) || 0) / 12;
    updateAndSave("cout_total_assurance", _cout_total_assurance);
    updateAndSave("cout_total_credit", (_nouvelle_mensuel * (parseFloat(devis.duration_expected) || 0)) + _cout_total_assurance);

    updateAndSave("teav", (_mensuel + _payerAutres + (parseFloat(devis.montant_loyer) || 0)) / (parseFloat(devis.revenue_foyer_total) || 0) * 100);
    updateAndSave("teap", (_nouvelle_mensuel + _payerAutres + (parseFloat(devis.montant_loyer) || 0)) / (parseFloat(devis.revenue_foyer_total) || 0) * 100);

    updateAndSave("avant_reste_vivre", (parseFloat(devis.revenue_foyer_total) || 0) - (_mensuel + _payerAutres + (parseFloat(devis.montant_loyer) || 0)));
    updateAndSave("apres_reste_vivre", (parseFloat(devis.revenue_foyer_total) || 0) - (_nouvelle_mensuel + _payerAutres + (parseFloat(devis.montant_loyer) || 0)));
  }


  return (
    <Box>
      <Box fill background={"white"} direction="column" >
        <SectionHeader title={"Résultat"} actions={<PrimaryButton background="transparent" icon={<Refresh color="white" />} onClick={() => {
          calculMontants();
          setDataChanged(false);
          onSave();
        }} label={'Rafraîchir calcul'} color='white'></PrimaryButton>} />
        <Box pad={"small"} gap="small" >
          <Box fill direction="row" gap="small" >
            <Box fill background={'#F0F0F0'}>
              <SectionHeader title={"Avant regroupement de crédit"} round={true} color={'#72ACFF'} />
              <Box fill gap="small" pad={"small"} >
                <Box direction="row" justify="between">
                  <Text >Taux endettement avant RAC (TEAV)</Text>
                  <Text >{devis.teav ? formatNumber(devis.teav) : '-'} %</Text>
                </Box>
                <Box direction="row" justify="between">
                  <Text >Reste à vivre</Text>
                  <Text >{devis.avant_reste_vivre ? formatNumber(devis.avant_reste_vivre) : '-'} €</Text>
                </Box>
                <Box direction="row" justify="between">
                  <Text >Anciènne mensualité (RAC)</Text>
                  <Text >{devis.montant_rembourser_mensuel ? formatNumber(devis.montant_rembourser_mensuel) : '-'} €</Text>
                </Box>

              </Box>
            </Box>

            <Box fill background={'#F0F0F0'}>
              <SectionHeader title={"Après regroupement de crédit"} round={true} color={'#E92C70'} />
              <Box fill gap="small" pad={"small"}>
                <Box direction="row" justify="between">
                  <Text >Taux endettement après RAC (TEAP)</Text>
                  <Text >{devis.teap ? formatNumber(devis.teap) : '-'} %</Text>
                </Box>
                <Box direction="row" justify="between">
                  <Text >Reste à vivre</Text>
                  <Text >{devis.apres_reste_vivre ? formatNumber(devis.apres_reste_vivre) : '-'} €</Text>
                </Box>
                <Box direction="row" justify="between">
                  <Text >Nouvelle mensualité (RAC)</Text>
                  <Text >{devis.nouvelle_mensuel ? formatNumber(devis.nouvelle_mensuel) : '-'} €</Text>
                </Box>
              </Box>

            </Box>
          </Box>
          <Box fill gap="small" >
            <Box fill background={'#F0F0F0'}>
              <SectionHeader title={"Crédit"} round={true} color={'#72ACFF'} />
              <Box direction="row" gap="medium">
                <Box fill gap="small" pad={"small"} >
                  <Box direction="row" justify="between">
                    <Text >Enveloppe emprunté</Text>
                    <Text >{devis.emprunte_total ? devis.emprunte_total : '-'} €</Text>
                  </Box>
                  <Box direction="row" justify="between">
                    <Text >Intérêt du crédit</Text>
                    <Text >{devis.interet_credit ? formatNumber(devis.interet_credit) : '-'} €</Text>
                  </Box>
                  <Box direction="row" justify="between">
                    <Text >Coût total assurance</Text>
                    <Text >{devis.cout_total_assurance ? formatNumber(devis.cout_total_assurance) : '-'} €</Text>
                  </Box>
                  <Box direction="row" justify="between">
                    <Text >Coût total du crédit (Intérêt + assurance)</Text>
                    <Text >{devis.cout_total_credit ? formatNumber(devis.cout_total_credit) : '-'} €</Text>
                  </Box>
                </Box>
                <Box fill gap="small" pad={"small"} >
                  <Box direction="row" justify="between">
                    <Text >Coût assurance mensuel</Text>
                    <Text color='brand' weight={'bold'}>{devis.cout_assurance_mensuel ? formatNumber(devis.cout_assurance_mensuel) : '-'} €</Text>
                  </Box>
                  <Box direction="row" justify="between">
                    <Text >Nouvelle mensualité</Text>
                    <Text color='brand' weight={'bold'}>{devis.nouvelle_mensuel ? formatNumber(devis.nouvelle_mensuel) : '-'} €</Text>
                  </Box>
                  <Box direction="row" justify="between">
                    <Text >Taux prêt</Text>
                    <Text >{devis.taux_regroupement ? formatNumber(devis.taux_regroupement) : '-'} %</Text>
                  </Box>
                  <Box direction="row" justify="between">
                    <Text >Durée du prêt</Text>
                    <Text >{devis.duration_expected || '-'} mois</Text>
                    <Text >{devis.duration_expected ? (parseFloat(devis.duration_expected) / 12).toFixed(1) : '-'} ans</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <SectionHeader title={"Information du client"} />
        {dataChanged && <Box fill background={'yellow'} justify="end" direction="row" pad='xxsmall'>
          <PrimaryButton background="transparent" icon={<Checkmark color="green" />} reverse onClick={submitModification} label={'Valider'} color='green'></PrimaryButton>
        </Box>}
        <Box pad={"small"} gap="small" >

          <Box direction='column' fill align='center' >
            <Box fill><Text  >Existe-t-il un crédit immobilier en cours pour votre client ?</Text></Box>
            <Box fill>
              <RadioButtonGroup
                style={{ fontSize: 14 }}
                direction='row'
                name="radio"
                options={[{ "label": "Oui", "value": "Oui" }, { "label": "Non", "value": "Non" }]}
                value={devis.credit_immobilier_encours}
                onChange={(event) => updateAndSave('credit_immobilier_encours', event.target.value)}
              />
            </Box>
          </Box>

          <Box direction='row' gap="large" fill>
            <Box fill>
              <Box fill justify="stretch"><Text  ><b>Revenu mensuel total du foyer</b> de votre client</Text></Box>
              <Box fill>
                <CustomInput
                  type='number'
                  step={0.01}
                  value={devis.revenue_foyer_total || 0}
                  onChange={event => updateAndSave('revenue_foyer_total', event.target.value)}
                  icon={<Text>€</Text>}
                  reverse
                />
              </Box>
            </Box>
            <Box fill>
              <Box fill><Text  ><b>Montant de trésorerie</b> votre client souhaite-t-il obtenir</Text></Box>
              <Box fill>
                <CustomInput
                  type='number'
                  step={0.01}
                  value={devis.montant_need || (devis.montant ? parseFloat(devis.montant) : 0) || 0}
                  onChange={event => updateAndSave('montant_need', event.target.value)}
                  icon={<Text>€</Text>}
                  reverse
                />
              </Box>
            </Box>
          </Box>

          <Box direction='row' gap="large" fill>
            <Box fill>
              <Box fill justify="stretch"><Text  >La somme de toutes les charges <b>(loyer, pension alimentaire, box, etc.)</b> est de </Text></Box>
              <Box fill>
                <CustomInput
                  type='number'
                  step={0.01}
                  value={formatNumber(devis.montant_loyer) || 0}
                  onChange={event => updateAndSave('montant_loyer', event.target.value)}
                  icon={<Text>€</Text>}
                  reverse
                />
              </Box>
            </Box>
            <Box fill>
              <Box fill><Text  ><b>Mensualité actuelle</b> (<b>Conso + Immo</b> (si rac avec Immo))</Text></Box>
              <Box fill>
                <CustomInput
                  type='number'
                  step={0.01}
                  value={formatNumber(devis.montant_rembourser_mensuel) || 0}
                  onChange={event => updateAndSave('montant_rembourser_mensuel', event.target.value)}
                  icon={<Text>€</Text>}
                  reverse
                />
              </Box>
            </Box>
          </Box>

          <Box direction='row' gap="large" fill>
            <Box fill>
              <Box fill justify="stretch"><Text  >CRD des <b>crédits à la consommation</b> en cours</Text></Box>
              <Box fill>
                <CustomInput
                  type='number'
                  step={0.01}
                  value={formatNumber(devis.montant_consommation_encours) || 0}
                  onChange={event => updateAndSave('montant_consommation_encours', event.target.value)}
                  icon={<Text>€</Text>}
                  reverse
                />
              </Box>
            </Box>
            <Box fill>
              <Box fill><Text  >CRD des <b>crédits immobiliers</b> en cours</Text></Box>
              <Box fill>
                <CustomInput
                  type='number'
                  step={0.01}
                  value={formatNumber(devis.montant_immobilier_encours) || 0}
                  onChange={event => updateAndSave('montant_immobilier_encours', event.target.value)}
                  icon={<Text>€</Text>}
                  reverse
                />
              </Box>
            </Box>
          </Box>

          <Box direction='row' gap="large" fill>
            <Box fill>
              <Box fill justify="stretch"><Text  >La somme des crédits à conserver est de</Text></Box>
              <Box fill>
                <CustomInput
                  type='number'
                  step={0.01}
                  value={formatNumber(devis.montant_autres_charges) || 0}
                  onChange={event => updateAndSave('montant_autres_charges', event.target.value)}
                  icon={<Text>€</Text>}
                  reverse
                />
              </Box>
            </Box>
            <Box fill>
              <Box fill><Text  >Sur <b>combien de mois le client veut étaler le prêt ?</b></Text></Box>
              <Box fill>
                <CustomInput
                  type='number'
                  step={0.01}
                  value={devis.duration_expected || (devis.duree ? formatNumber(devis.duree) : 0)}
                  onChange={event => updateAndSave('duration_expected', event.target.value)}
                  icon={<Text>Mois</Text>}
                  reverse
                />
              </Box>
            </Box>
          </Box>

          <Box direction='row' gap="large" fill>
            <Box fill>
              <Box fill justify="stretch"><Text  ><b>Taux est disponible</b> pour ce <b>regroupement de crédits</b></Text></Box>
              <Box fill>
                <CustomInput
                  type='number'
                  step={0.01}
                  value={formatNumber(devis.taux_regroupement) || 0}
                  onChange={event => updateAndSave('taux_regroupement', event.target.value)}
                  icon={<Text>%</Text>}
                  reverse
                />
              </Box>
            </Box>
            <Box fill>
              <Box fill><Text  >Taux en assurance</Text></Box>
              <Box fill>
                <CustomInput
                  type='number'
                  step={0.01}
                  value={formatNumber(devis.taux_assurance) || 0}
                  onChange={event => updateAndSave('taux_assurance', event.target.value)}
                  icon={<Text >%</Text>}
                  reverse
                />
              </Box>
            </Box>
          </Box>

          {(devis.credit_immobilier_encours === "Oui" || (devis.simulation?.proprietaire === "Oui")) &&
            <Box>
              <SectionHeader title={"Crédit immobilier"} round={true} color={'#72ACFF'} />

              <CreditElement devis={devis} updateAndSave={updateAndSave} onSave={onSave} creditType='credits_immobilier' calculMontants={calculMontants} />

            </Box>}
          <Box>
            <SectionHeader title={"Crédit consommation"} round={true} color={'#72ACFF'} />
            <CreditElement devis={devis} updateAndSave={updateAndSave} onSave={onSave} creditType='credits_consommation' calculMontants={calculMontants} />
          </Box>
        </Box>

      </Box>
    </Box>);

}

const CreditElement = ({ devis, onSave, updateAndSave, calculMontants, creditType }) => {

  const updateCredit = (field, value, index, type) => {
    let _credits = devis[type];
    _credits[index][field] = value;
    updateAndSave(type, _credits);
    calculMontants();
  }

  const deleteCredit = (index, type) => {
    let _credits = devis[type];
    _credits.splice(index, 1);
    updateAndSave(type, _credits);
    calculMontants();
    onSave();
  }

  return (<Box fill gap="small" pad={"small"} >
    <Grid
      fill
      columns={["3%", "10%", "10%", "10%", "10%", "10%", "10%", "10%", "10%", "10%", "7%"]}
      style={{ borderBottomWidth: 1, borderBottomColor: "#eee", borderBottomStyle: 'solid', paddingBottom: 5 }}
      gap={'xsmall'}
      align="center"
    >
      <Box><Text weight={'bold'}>N°</Text></Box>
      <Box><Text weight={'bold'}>Organisme</Text></Box>
      <Box><Text weight={'bold'}>Nature</Text></Box>
      <Box><Text weight={'bold'}>Action</Text></Box>
      <Box><Text weight={'bold'}>Montant init.</Text></Box>
      <Box><Text weight={'bold'}>Date début</Text></Box>
      <Box><Text weight={'bold'}>Date fin</Text></Box>
      <Box><Text weight={'bold'}>CRD</Text></Box>
      <Box><Text weight={'bold'}>IRA</Text></Box>
      <Box><Text weight={'bold'}>Mensualité</Text></Box>
      <Box><Text weight={'bold'}></Text></Box>

    </Grid>
    {devis[creditType] ? (
      devis[creditType].map((e, _idx) => {
        return e && (
          <Grid
            key={`${creditType}_${_idx}`} fill
            columns={["3%", "10%", "10%", "10%", "10%", "10%", "10%", "10%", "10%", "10%", "7%"]}
            gap={'xsmall'}
            align="center"
          >
            <Box><Text >N°{_idx + 1}</Text></Box>
            <Box>
              <CustomInput
                value={e.organisme}
                onChange={event => updateCredit('organisme', event.target.value, _idx, creditType)}
              />
            </Box>
            <Box>
              <CustomInput
                value={e.nature}
                onChange={event => updateCredit('nature', event.target.value, _idx, creditType)}
              />
            </Box>
            <Box>
              <CustomSelect
                placeholder='Action'
                options={['à solder', 'à racheter', 'à conserver']}
                value={e.action}
                onChange={({ option }) => {
                  updateCredit('action', option, _idx, creditType);
                }}
              />
            </Box>
            <Box>
              <CustomInput
                value={e.montant_init}
                onChange={event => updateCredit('montant_init', event.target.value, _idx, creditType)}
                type="number"
                icon={<Text>€</Text>}
                reverse
              />
            </Box>
            <Box>
              <CustomInput
                value={e.date_start}
                onChange={event => updateCredit('date_start', event.target.value, _idx, creditType)}
              />
            </Box>
            <Box>
              <CustomInput
                value={e.date_end}
                onChange={event => updateCredit('date_end', event.target.value, _idx, creditType)}
              />
            </Box>
            <Box>
              <CustomInput
                value={e.crd}
                onChange={event => updateCredit('crd', event.target.value, _idx, creditType)}
                type="number"
                icon={<Text>€</Text>}
                reverse
              />
            </Box>
            <Box>
              <CustomInput
                value={e.ira}
                onChange={event => updateCredit('ira', event.target.value, _idx, creditType)}

              />
            </Box>
            <Box>
              <CustomInput
                value={e.montant_mensuel}
                onChange={event => updateCredit('montant_mensuel', event.target.value, _idx, creditType)}
                type="number"
                icon={<Text>€</Text>}
                reverse
              />
            </Box>
            <Box >
              <Button background={'transparent'} icon={<Trash color="brand" />} onClick={() => {
                if (window.confirm("Êtes-vous sûr de vouloir supprimer ce crédit ?")) {
                  deleteCredit(_idx, creditType);
                }
              }}  ></Button>
            </Box>

          </Grid>
        )
      })
    ) : <></>}
    <Box align="center">
      <PrimaryButton background="#72ACFF" icon={<AddCircle />} onClick={() => {
        let _creditsImmo = devis[creditType] || [];
        _creditsImmo.push({ nature: creditType === 'credits_immobilier' ? 'Immo' : 'Conso' });
        updateAndSave(creditType, _creditsImmo);
      }} label={'Ajouter un credit'} ></PrimaryButton>
    </Box>

  </Box>);
}

const InfoPerso = ({ devis, onSave, updateDevis }) => {

  const [dataChanged, setDataChanged] = useState(false);
  const [childrenDates, setChildrenDates] = useState(devis.children_birthdays);


  const _now = moment().toDate().toISOString();


  const updateAndSave = (field, value) => {
    console.log(value);
    updateDevis(field, value);
    setDataChanged(true);
    if (field === "children_total") {

      if (value > childrenDates.length) {
        for (let i = 0; i < value; i++) {
          if (i >= childrenDates.length) {
            let _childrenDates = childrenDates;
            _childrenDates.push(_now);
            setChildrenDates(_childrenDates);
          }
        }
      } else if (value < childrenDates.length) {
        let _childrenDates = childrenDates.splice(0, value);
        setChildrenDates(_childrenDates);
      }
      updateDevis('children_birthdays', childrenDates);
    }
  }

  const updateChildrenBirthdays = (index, value) => {
    if (value instanceof Date && !isNaN(value)) {
      console.log('index ', index, '  value  = ', value);
      let _childrenDates = childrenDates;
      _childrenDates[index] = value;
      console.log('children_birthdays ', _childrenDates);
      updateAndSave('children_birthdays', _childrenDates);
    }
  }

  const submitModification = () => {
    setDataChanged(false);
    onSave({ skipValidation: true });
  }

  return (
    <Box>
      {dataChanged && <Box fill background={'yellow'} justify="end" direction="row" pad='xxsmall'>
        <PrimaryButton background="transparent" icon={<Checkmark color="green" />} reverse onClick={submitModification} label={'Valider'} color='green'></PrimaryButton>
      </Box>}
      <Box fill gap="small" background={"white"}>
        <SectionHeader title={"Situation familiale"} />

        <Box pad={'small'} fill gap='xsmall'>

          <Box direction='column' fill align='center' gap='xsmall'>
            <Box fill><Text  >Quelle est la situation de votre client ?</Text></Box>
            <Box fill>
              <RadioButtonGroup
                style={{ fontSize: 14 }}
                direction='row'
                name="radio"
                options={[{ "label": "Célibataire", "value": "Célibataire" }, { "label": "Veuf", "value": "Veuf" }, { "label": "Divorcé", "value": "Divorcé" }, { "label": "Séparé ou divorce en cours", "value": "Séparé ou divorce en cours" }, { "label": "Union libre", "value": "Union libre" }, { "label": "Pacsé", "value": "Pacsé" }, { "label": "Mariés", "value": "Mariés" }]}
                value={devis.situation_family}
                onChange={(event) => updateAndSave('situation_family', event.target.value)}
              />
            </Box>
          </Box>

          <Box direction='row' fill align='center' justify='between'>
            <Box width={'medium'}><Text  >Si mariés, sous quel régime :</Text></Box>
            <Box fill>
              <CustomInput
                value={devis.regime_mariage}
                onChange={event => updateAndSave('regime_mariage', event.target.value)}
              />
            </Box>
          </Box>

          <Box direction='row' fill justify='between'>

            <Box direction='column' fill>
              <Box width={'medium'}><Text  >Combien d’enfant a votre client ?</Text></Box>
              <Box width={'small'}>
                <CustomInput
                  type='number'
                  step={0.01}
                  value={devis.children_total}
                  onChange={event => updateAndSave('children_total', event.target.value)}
                />
              </Box>
            </Box>
            <Box pad={'small'} style={{ width: 200 }}></Box>
            <Box direction='column' fill >
              {childrenDates.map((e, idx) => <Box key={`child_${idx}`} direction='row' fill align='center' justify='between'>
                <Text  >Enfant n°{idx + 1}, date de naissance</Text>
                <DateInput calendarProps={{
                  locale: "fr-FR",
                  size: "small",
                }} value={e} onChange={date => updateChildrenBirthdays(idx, date)} style={{ background: '#EDEDED', borderRadius: 10, border: 'none' }} />
              </Box>)}

            </Box>

          </Box>
          <Box direction='row' gap="small">
            <Box direction='column' align='center' gap='xsmall'>
              <Box fill><Text  >Votre client perçoit-il une pension pour ces enfants ?</Text></Box>
              <Box fill>
                <RadioButtonGroup
                  style={{ fontSize: 14 }}
                  direction='row'
                  name="radio"
                  options={[{ "label": "Oui", "value": "Oui" }, { "label": "Non", "value": "Non" }]}
                  value={devis.children_pension}
                  onChange={(event) => updateAndSave('children_pension', event.target.value)}
                />
              </Box>
            </Box>
            <Box direction='row' align='center' gap="small" >
              <Box><Text  >Si oui, combien ?</Text></Box>
              <Box >
                <CustomInput
                  value={devis.children_pension_amount}
                  onChange={event => updateAndSave('children_pension_amount', event.target.value)}
                />
              </Box>
            </Box>
          </Box>

        </Box>


        <SectionHeader title={"Domicile"} />
        <Box pad={'small'} fill gap='xsmall'>
          <Box fill>
            <RadioButtonGroup
              style={{ fontSize: 14 }}
              direction='row'
              name="radio"
              options={[{ "label": "Accédant", "value": "Accédant" }, { "label": "Propriétaire acquitté", "value": "Propriétaire acquitté" }, { "label": "En pleine propriété", "value": "En pleine propriété" }, { "label": "Locataire", "value": "Locataire" }, { "label": "Logé à titre gratuit", "value": "Logé à titre gratuit" }, { "label": "Autres", "value": "Autres" }]}
              value={devis.domicile_type}
              onChange={(event) => updateAndSave('domicile_type', event.target.value)}
            />
          </Box>
          <Box direction='row' fill align='center' justify='between'>
            <Box width={'small'}><Text  >Si Logé à titre gratuit :</Text></Box>
            <Box fill>
              <CustomInput
                value={devis.domicile_type_free}
                onChange={event => updateAndSave('domicile_type_free', event.target.value)}
              />
            </Box>
          </Box>
          <Box direction='row' fill align='center' justify='between'>
            <Box width={'small'}><Text  >Si autres :</Text></Box>
            <Box fill>
              <CustomInput
                value={devis.domicile_type_other}
                onChange={event => updateAndSave('domicile_type_other', event.target.value)}
              />
            </Box>
          </Box>
        </Box>

        <SectionHeader title={"Précisions bien(s) si acc/propriétaire"} />
        <Box pad={'small'} fill gap='xsmall'>
          <Box fill><Text  >Quel type de residence possède votre client ?</Text></Box>
          <Box fill>
            <RadioButtonGroup
              style={{ fontSize: 14 }}
              direction='row'
              name="radio"
              options={[{ "label": "Résidence principale", "value": "Résidence principale" }, { "label": "Résidence secondaire", "value": "Résidence secondaire" }, { "label": "Résidence locative", "value": "Résidence locative" }]}
              value={devis.resident_type}
              onChange={(event) => updateAndSave('resident_type', event.target.value)}
            />
          </Box>
          {devis.resident_type === 'Résidence principale' && <Box fill gap='xsmall'>
            <Box direction='row' fill align='center' justify='between'>
              <Box width={'medium'}><Text  >Si résidence principale, indiquer la valeur :</Text></Box>
              <Box fill>
                <CustomInput
                  value={devis.resident_principal_value}
                  onChange={event => updateAndSave('resident_principal_value', event.target.value)}
                />
              </Box>
            </Box>
            <Box direction='row' fill align='center' justify='between'>
              <Box width={'small'}><Text  >Indiquer l’adresse :</Text></Box>
              <Box fill>
                <CustomInput
                  value={devis.resident_principal_address}
                  onChange={event => updateAndSave('resident_principal_address', event.target.value)}
                />
              </Box>
            </Box>
          </Box>}
          {devis.resident_type === 'Résidence secondaire' && <Box fill gap='xsmall'>
            <Box direction='row' fill align='center' justify='between'>
              <Box width={'medium'}><Text  >Si résidence secondaire, indiquer la valeur :</Text></Box>
              <Box fill>
                <CustomInput
                  value={devis.resident_secondaire_value}
                  onChange={event => updateAndSave('resident_secondaire_value', event.target.value)}
                />
              </Box>
            </Box>
            <Box direction='row' fill align='center' justify='between'>
              <Box width={'small'}><Text  >Indiquer l’adresse :</Text></Box>
              <Box fill>
                <CustomInput
                  value={devis.resident_secondaire_address}
                  onChange={event => updateAndSave('resident_secondaire_address', event.target.value)}
                />
              </Box>
            </Box>
          </Box>}
          <Box direction='row' fill align='center' justify='between'>
            <Box width={'small'}><Text  >Détails si SCI :</Text></Box>
            <Box fill>
              <CustomInput
                value={devis.detail_sci}
                onChange={event => updateAndSave('detail_sci', event.target.value)}
              />
            </Box>
          </Box>
          <Box direction='row' fill align='center' justify='between'>
            <Box width={'small'}><Text  >Coordonnées notaire :</Text></Box>
            <Box fill>
              <CustomInput
                value={devis.contact_notaire}
                onChange={event => updateAndSave('contact_notaire', event.target.value)}
              />
            </Box>
          </Box>


        </Box>

      </Box>
    </Box>);
}

const NodeInfo = ({ devis, setIsLoading }) => {


  return (
    <Box fill gap="small" background={"white"} direction="row" pad={'medium'} justify="center">
      <PrimaryButton background={'blue'} icon={<DocumentWord />} label={'Lien Doc'} onClick={() => {
        setIsLoading(true);
        creditLinks({ id: devis.id, type: 'doc' }).then(res => {
          let _url = res.data.url;
          setIsLoading(false);
          window.open(_url, '_blank');
        });


      }}></PrimaryButton>
      <PrimaryButton background={'green'} icon={<DocumentExcel />} label={'Lien Sheet'} onClick={() => {
        setIsLoading(true);
        creditLinks({ id: devis.id, type: 'xls' }).then(res => {
          let _url = res.data.url;
          setIsLoading(false);
          window.open(_url, '_blank');
        });
      }}></PrimaryButton>

    </Box>);
}

const doUploadFile = async (files, name_prefix, callback, showError) => {

  // for (let i = 0; i < fileList.length; i += 1) {
  //   const file = fileList[i];
  //   console.log(file.name);
  return uploadFilesMulti(files, name_prefix)
    .then((result) => {
      console.log("result = ", result.data);
      callback(result.data);
    })
    .catch((err) => {
      showError("Erreur inattendu télécharger.");
      callback([]);
    });
  // }
};

export default RecapRegroupe;

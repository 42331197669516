
import { Box, Button, Card, DataTable, Heading, Text } from 'grommet';
import { StatusGoodSmall } from 'grommet-icons';
import React from 'react';
import { getGestionCollabs } from '../../../services/dashboard';
import { getSimpleDateFR } from '../../../services/utils';
import AddDevis from '../../elements/add_devis';
import CustomInput from '../../elements/custom_input';
import CustomPagination from '../../elements/custom_pagination';
import DateInputString from '../../elements/date_input_string';
import CustomSelect from '../../elements/select_fill';
import Search from '../components/search';
import { ventCanals } from '../utils/types';
import Agence from './agence';
import { countAgences, getAgences } from './service';
import { AGENCE_STATUS, AgenceStatusChange, getStatusColor } from './agence/components/elements/agence_status';

class Agences extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            agences: [],
            popupOpen: false,
            selectedAgence: {},
            total: 0,
            users: [],
            startIndex: 0,
            query: '',
            sort: 'raisonSocial:asc',
            filter: {
            }
        }
    }

    componentDidMount() {
        this.loadAgences();
        getGestionCollabs()
            .then(res => this.setState({ users: res.data }))
            .catch(err => console.log(err));
    }

    loadAgences = () => {
        this.setState({ agences: [], startIndex: 0 });
        getAgences(this.state.startIndex, this.state.query, this.state.filter, this.state.sort)
            .then(res => this.setState({ agences: res.data }));
        countAgences(this.state.query, this.state.filter)
            .then(res => this.setState({ total: res.data }));
    }

    addAgence = () => {
        this.setState({ selectedAgence: {}, popupOpen: true })
    }

    open = (agence) => {
        this.setState({ selectedAgence: agence, popupOpen: true })
    }

    onNextPage = ({ startIndex }) => {
        this.setState({ startIndex }, () => {
            getAgences(this.state.startIndex, this.state.query, this.state.filter, this.state.sort)
                .then(res => this.setState({ agences: res.data }));
            countAgences(this.state.query)
                .then(res => this.setState({ total: res.data }));
        });
    }

    render() {
        return (
            this.state.popupOpen ?
                <Agence
                    showMessage={this.props.showMessage}
                    agence={this.state.selectedAgence}
                    onClose={() => this.setState({ popupOpen: false })}
                    reload={this.loadAgences}
                    users={this.state.users}
                />
                :
                <Box style={{ width: "100%" }}
                    align="center" justify="center"
                    alignSelf="center"
                    alignContent="center" gap="medium">
                    <Box style={{ width: "100%" }} gap="medium" pad={"medium"} justify='center' align='center'>
                        <Heading level={3} textAlign='center'>
                            Dashboard client voyages
                        </Heading>
                        <Box direction='row' justify="between" alignSelf="center" align="center" gap="medium" width={'full'}>
                            <Search size={'small'} search={query => this.setState({ query }, this.loadAgences)} />

                            <Card pad={'small'} gap='small'>
                                <Box direction='row' justify="between" alignSelf="center" align="center" gap="medium" width={'full'}>
                                    <CustomSelect
                                        options={this.state.users.map(s => s.name)}
                                        placeholder='Propriétaire'
                                        value={this.state.filter.commercial_id ? this.state.users.find(s => s.id === this.state.filter.commercial_id)?.name : null}
                                        onChange={({ option }) => {
                                            let filter = this.state.filter;
                                            filter.commercial_id = this.state.users.find(s => s.name === option).id;
                                            this.setState({ filter: Object.assign({}, filter) }, () => this.loadAgences());
                                        }}
                                    />
                                    <CustomSelect
                                        options={ventCanals}
                                        placeholder='Canal de vente'
                                        value={this.state.filter.ventCanal}
                                        onChange={({ option }) => {
                                            let filter = this.state.filter;
                                            filter.ventCanal = option;
                                            this.setState({ filter: Object.assign({}, filter) }, () => this.loadAgences());
                                        }}
                                    />
                                    <CustomSelect
                                        options={Object.keys(AGENCE_STATUS).map(k => AGENCE_STATUS[k])}
                                        placeholder='Statut'
                                        value={this.state.filter.status}
                                        onChange={({ option }) => {
                                            let filter = this.state.filter;
                                            filter.status = option;
                                            this.setState({ filter: Object.assign({}, filter) }, () => this.loadAgences());
                                        }}
                                    />

                                </Box>
                                <Box direction='row' justify="between" alignSelf="center" align="center" gap="medium" width={'full'}>
                                    <Box style={{ width: '33%' }}>
                                        <DateInputString
                                            size={'small'}
                                            strValueInFRFormat={this.state.filter.createdAt}
                                            placeholder='Date de création'
                                            onChange={date => {
                                                let filter = this.state.filter;
                                                filter.createdAt = date;
                                                this.setState({ filter: Object.assign({}, filter) }, () => this.loadAgences());
                                            }}
                                        />
                                    </Box>
                                    <Box style={{ width: '33%' }}>
                                        <CustomInput
                                            placeholder={'Taux de base'}
                                            size='small'
                                            value={this.state.filter.classicBaseTaux}
                                            onChange={event => {
                                                let filter = this.state.filter;
                                                filter.classicBaseTaux = event.target.value.replace(',', '.');
                                                this.setState({ filter: Object.assign({}, filter) }, () => this.loadAgences());
                                            }}
                                        />
                                    </Box>
                                    <Box style={{ width: '33%' }}>
                                        <CustomInput
                                            placeholder={'Taux RC'}
                                            size='small'
                                            value={this.state.filter.rcBaseTaux}
                                            onChange={event => {
                                                let filter = this.state.filter;
                                                filter.rcBaseTaux = event.target.value.replace(',', '.');
                                                this.setState({ filter: Object.assign({}, filter) }, () => this.loadAgences());
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Card>
                            <AddDevis onAdd={this.addAgence} />
                        </Box>
                        <Box style={{ width: "100%" }}>
                            <CustomPagination total={this.state.total} onNextPage={this.onNextPage} startIndex={this.state.startIndex} />
                        </Box>
                        <DataTable
                            columns={[
                                {
                                    property: 'createdAt',
                                    header: <Text size="small"><strong>Création</strong>/<br />Modification</Text>,
                                    render: agence => <Text size="xsmall" ><strong>{getSimpleDateFR(agence.createdAt)}</strong><br />{getSimpleDateFR(agence.updatedAt)}</Text>
                                },
                                {
                                    header: <Text size="small">Commerical</Text>,
                                    render: agence => <Text size="small">{this.state.users.find(u => u.id === agence.commercial_id)?.name}</Text>
                                },
                                {
                                    property: 'raisonSocial',
                                    header: <Text size="small">Agence</Text>,
                                    render: agence => <Text size="small">{agence.raisonSocial}</Text>
                                },
                                {
                                    property: 'status',
                                    header: <Text size="small">Statut</Text>,
                                    render: agence => <Marker text={agence.status} color={getStatusColor(agence.status)} />,
                                },
                                {
                                    header: <Text size="small">Methode de vente</Text>,
                                    render: agence => <VenteMode canals={agence.ventCanals} />
                                },
                                {
                                    property: 'convention',
                                    header: <Text size="small">'Convention'</Text>,
                                    render: agence => <Marker text={agence.convention} color={getStatusColor(agence.convention)} />
                                },
                                {
                                    header: '',
                                    render: agence => <Box width={'small'} gap='small' direction='row' align='center' justify='center'>
                                        <Box><Button label="voir" primary onClick={() => this.open(agence)} /></Box>
                                        <AgenceStatusChange 
                                            agence={agence}
                                            onReload={a => this.setState({agences: [...this.state.agences]})}
                                            showMessage={this.props.showMessage}
                                        />
                                    </Box>
                                },
                            ]}
                            sort={{ property: '', direction: '' }}
                            onSort={optionSort => {
                                this.setState({ sort: `${optionSort.property}:${optionSort.direction}` }, this.loadAgences);
                            }}
                            data={this.state.agences}
                        />
                    </Box >
                </Box >
        )
    }
}

const VenteMode = ({ canals }) => (
    <Box width="small" pad="xsmall" style={{ border: '1px solid #c8c8c8', borderRadius: 5 }} >
        {
            ventCanals.map((canal, idx) => (
                <Canal key={idx} canal={canal} color={canals.includes(canal) ? '#33BC00' : '#FF3600'} />
            ))
        }
    </Box>
)

const Canal = ({ canal, color }) => (
    <Box direction="row" gap="xsmall" align="center">
        <StatusGoodSmall color={color} size='small' />
        <Text size="xsmall">
            <strong>{canal}</strong>
        </Text>
    </Box>
)

const Marker = ({ text, color }) => (
    <Box direction="row" gap="xsmall" pad="xsmall" align="center">
        <StatusGoodSmall color={color} />
        <Text size="xsmall">
            <strong>{text}</strong>
        </Text>
    </Box>
)

export default Agences;

// text colors

// body1
const grey_500 = "#64748B";

// title
const grey_700 = "#4D5875";

// header
const grey_900 = "#1E293B"


export {
    grey_500,
    grey_700,
    grey_900
}
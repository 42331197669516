import React from "react";
import { Box, Button, Text } from 'grommet';
import { Menu, Grid as GridIcon } from "grommet-icons";


const DISPLAY_MODE = {
    line: 'line',
    grid: 'grid'
}

const ViewMode = ({ currentMode, toGridMode, toLineMode }) => {
    return (
        <Box direction="row" gap="small" justify="center" align="center">
            <Text size="small">Mode de visualisation</Text>
            <Box direction="row" round={{ size: 'small' }} background='white' border>
                <Box background={currentMode === DISPLAY_MODE.line ? '#F5F5F5' : '#fff'} round={{ size: 'small' }}>
                    <Button icon={<Menu />} onClick={toLineMode} />
                </Box>
                <Box background={currentMode === DISPLAY_MODE.grid ? '#F5F5F5' : '#fff'} round={{ size: 'small' }}>
                    <Button icon={<GridIcon />} onClick={toGridMode} />
                </Box>

            </Box>
        </Box>
    )
}

export default ViewMode;

export {
    DISPLAY_MODE
}
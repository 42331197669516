const axios = require('axios');
const moment = require('moment');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const createAgence = (agence) => {
    return axios.post(`${baseAPI}/v1/commercials/agences`, agence, { headers: HEADERS });
}

const clearCache = () => {
    return axios.post(`${baseAPI}/v1/commercials/subscriptions/clear-cache`, {}, { headers: HEADERS });
}

const updateAgence = (agence) => {
    return axios.put(`${baseAPI}/v1/commercials/agences/${agence.id}`, agence, { headers: HEADERS });
}

const updateAgenceInfo = (agence) => {
    return axios.put(`${baseAPI}/agences/${agence.id}`, agence, { headers: HEADERS });
}

const sendConvention = (id, test_mail=false) => {
    return axios.post(`${baseAPI}/v1/commercials/agences/send-convention`, {id, test_mail}, { headers: HEADERS });
}

const markSigned = (id, model_type) => {
    return axios.post(`${baseAPI}/v1/commercials/agences/sign`, {id, model_type}, { headers: HEADERS });
}

const getAgences = (startIndex, query, filter = {}, sort) => {
    let params = {
        _start: startIndex ||  0,
        _limit: 50,
        _q: query
    }
    if(sort) {
        params._sort = sort;
    }
    params = buildParams(params, filter);
    return axios.get(`${baseAPI}/v1/commercials/agences`, { params, headers: HEADERS });
}

const buildParams = (params, filter) => {
    let buildFilter = Object.assign({}, filter);
    if(buildFilter.ventCanal) {
        buildFilter['_where[0][ventCanals]'] = buildFilter.ventCanal;
        delete buildFilter.ventCanal;
    }
    if(buildFilter.createdAt) {
        buildFilter['_where[0][createdAt_lt]']=  `${moment(buildFilter.createdAt).add(1, 'day').format('YYYY-MM-DD')}T00:00:00.000Z`
        buildFilter['_where[1][createdAt_gt]]']=  `${buildFilter.createdAt}T00:00:00.000Z`;
        delete buildFilter.createdAt;
    }

    let finalFilter = {...params, ...buildFilter};
    for(let key of Object.keys(finalFilter)) {
        if(!finalFilter[key]) delete finalFilter[key];
    }

    return finalFilter;
}

const countAgences = (query, filter) => {
    let params = {
        _q: query
    }
    params = buildParams(params, filter);
    return axios.get(`${baseAPI}/v1/commercials/agences/count`, { params, headers: HEADERS });
}

const importSubscription = (id, body) => {
    return axios.post(`${baseAPI}/v1/commercials/agences/${id}/subscriptions/upload`, body, { headers: HEADERS });
}
const resetPasswordPartenaire = (id) => {
    return axios.post(`${baseAPI}/agences/reset_password`, { id }, { headers: HEADERS });
}

const resendEmailInscription = (id) => {
    return axios.post(`${baseAPI}/agences/resend_inscription`, { id }, { headers: HEADERS });
}

module.exports = {
    createAgence,
    updateAgence,
    sendConvention,
    markSigned,
    getAgences,
    updateAgenceInfo,
    countAgences,
    importSubscription,
    clearCache,
    resetPasswordPartenaire,
    resendEmailInscription,
}
import { Box } from "grommet";
import { Checkmark, Close, Previous, Update } from "grommet-icons";
import React from "react";
import service from "../../../../services/mh_enligne_service";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Recap from "./recap";
import HabitationInfo from "./info";
import PrimaryButton from "../../../elements/primary_button";
import { updateClient } from "../../../outils/client_util";
import { clearContextBot, getCommercialId, saveContextBot } from "../../../../services/storage_service";
import { isSubscribed } from "../../../elements/contract_details";
import ContratView from "../../enligne-components/contrat_view";
import { DEAL_POSSIBLE, PROSPECT } from "../../../../services/devis_contrat_status";
import { convertToDealPossible, sendPresentation } from "../../../../services/common_service";

const STEPS = {
  info: "Info",
  recap: "Recap",
};

class TrustiHabitation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devis: this.props.devis,
      originalDevis: Object.assign({}, this.props.devis),
      step: this.props.devis.id ? STEPS.recap : STEPS.info,
      deletePopupOpen: false,
      loading: false,
    };
    saveContextBot(props.devis);
  }

  setLoading = (value) => {
    this.setState({ loading: value });
  };

  updateDevis = (field, value) => {
    let devis = this.state.devis;
    if(field === 'client') {
      let client = devis.client || {};
      value = {...client, ...value};
    }
    devis[field] = value;
    this.setState({ devis: Object.assign({}, devis) });
  };

  submitModification = async () => {
    let devis = Object.assign({}, this.state.devis);
    
    let updatedClient = await updateClient(devis, this.props.showMessage);
    if (!updatedClient) {
      this.props.showMessage('Erreur lors de création/mis à jour de client', 'error');
      return;
    }
    if (devis.apporteur && devis.apporteur.id) {
      devis.apporteur = devis.apporteur.id;
      devis.apporteur_id = devis.apporteur.id;
    }

    if (!devis.id) {
      devis.commercial_id = getCommercialId();
      service
        .createDevis(devis)
        .then((res) => {
          this.props.reload();
          this.props.showMessage("Devis a été bien créé", "success");
          this.setLoading(false);
          this.setState({ step: STEPS.recap, devis: res.data });
        })
        .catch((err) => {
          console.log(err);
          this.props.showMessage("Erreur inattendu", "error");
          this.setLoading(false);
          devis.client = updatedClient;
        });
    } else {
      service
        .updateDevis(devis)
        .then((res) => {
          this.props.reload();
          this.props.showMessage("Devis a été bien mis à jour", "success");
          this.setLoading(false);
          this.setState({ step: STEPS.recap, devis: {...res.data, need_generate_appel: 0} });
        })
        .catch((err) => {
          this.props.showMessage("Erreur inattendu", "error");
          this.setLoading(false);
          console.log(err.response.data);
          devis.client = updatedClient;
        });
    }
  };

  openEditPopup = () => {
    this.setState({ selectedDevis: this.state.devis, editMode: true });
  };

  openDeletePopup = () => {
    this.setState({ selectedDevis: this.state.devis, deletePopupOpen: true });
  };

  deleteDevis = () => {
    this.setLoading(true);
    service
      .deleteDevis(this.state.contact.id, this.state.selectedDevis.id)
      .then((res) => {
        this.reloadDevis();
        this.props.showMessage("Devis a été supprimé", "success");
        this.setState({ deletePopupOpen: false, loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.props.showMessage("Erreur inattendu", "error");
        this.setLoading(false);
      });
  };

  estimateDevis = async () => {
    this.setLoading(true);
    let devis = this.state.devis;

    return service
      .estimateDevis(devis)
      .then((res) => {
        this.setLoading(false);
        let devis = res.data;
        console.log('devis: ', devis);
        this.setState({ devis: devis });
        this.props.reload();
      })
      .catch((err) => {
        this.props.showMessage("Erreur in attendu", "error");
        this.setLoading(false);
      });
  };

  sendDevis = () => {
    this.setLoading(true);
    service
      .sendDevis(this.state.devis)
      .then((res) => {
        this.props.showMessage("Devis a été envoyé avec succès", "success");
        this.setLoading(false);
        this.setState({ devis: res.data });
        this.props.reload();
      })
      .catch((err) => {
        console.log(err);
        this.props.showMessage("Erreur inattendu", "error");
        this.setLoading(false);
      });
  };

  resetChange = () => {
    this.setState({
      devis: Object.assign({}, this.state.originalDevis),
      step: STEPS.recap,
    });
  };

  sendForm = () => {
    service
      .sendForm(this.state.devis)
      .then((res) => {
        this.props.showMessage("Formulaire a été envoyé", "success");
        this.props.reload();
        this.setState({devis: res.data})
      })
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer formulaire", "error")
      );
  };

  sendPresentation = () => {
    sendPresentation(this.state.devis)
      .then((res) => {
        this.props.showMessage("Présentation a été envoyé", "success");
        this.props.reload();
        this.setState({devis: res.data})
      })
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer présentation", "error")
      );
  };

  render() {
    return (
      isSubscribed(this.state.devis?.status) ?
        <ContratView
          sub={this.state.devis}
          title="Souscription assurance habitation"
          assurance_type="Trusti MH"
          showMessage={this.props.showMessage}
          onClose={this.props.onClose}
          onReload={this.props.loadTrustiHabitations}
        /> :
        <BlockUi tag="div" blocking={this.state.loading}>
          <Box alignSelf="center" justify="center">
            <Box pad="small" width="xsmall">
              <PrimaryButton icon={<Previous />} onClick={() => {
                clearContextBot();
                this.props.onClose && this.props.onClose();
              }} />
            </Box>
            {this.state.step === STEPS.info && (
              <HabitationInfo
                contact={this.state.contact}
                devis={this.state.devis || {}}
                updateDevis={this.updateDevis}
                showMessage={this.props.showMessage}
                toViewMode={() => this.setState({ step: STEPS.recap })}
                estimateDevis={(devis) => this.estimateDevis(devis)}
              />
            )}
            {this.state.step === STEPS.recap && (
              <Recap
                devis={this.state.devis}
                toEditMode={() => this.setState({ step: STEPS.info })}
                sendDevis={this.sendDevis}
                estimateDevis={this.estimateDevis}
                updateDevis={this.updateDevis}
                close={this.props.onClose}
                sendForm={this.sendForm}
                sendPresentation={this.sendPresentation}
                onSave={this.submitModification}
                showMessage={this.props.showMessage}
                onReload={this.props.reload}
                blockingHandler={val => this.setLoading(val)}
              />
            )}
            <Box direction="row" align="center" justify="center" gap="medium">
              {this.state.step === STEPS.info && (
                <PrimaryButton
                  label={`Sauvegarder`}
                  icon={<Checkmark />}
                  background="#59A4F4"
                  onClick={this.submitModification}
                />
              )}
              {this.state.step === STEPS.info && (
                <PrimaryButton
                  label={`Reset`}
                  icon={<Close />}
                  background={"#6c757d"}
                  onClick={() => {
                    if(this.state.devis?.id) {
                      this.resetChange();
                    };
                  }}
                />
              )}
              {this.state.devis?.status === PROSPECT &&
                <PrimaryButton
                  icon={<Update />}
                  onClick={() => {
                    let devis = this.state.devis;
                    devis.status = DEAL_POSSIBLE;
                    devis.product = 'Trusti Habitation en ligne';
                    convertToDealPossible(devis, 'devis-habitation')
                      .then(res => this.setState({ devis: res.data }));
                  }}
                  label="Convertir à Deal possible"
                />
              }
            </Box>
            <Box style={{ height: 100 }} />
          </Box>
        </BlockUi>

    );
  }
}

export default TrustiHabitation;

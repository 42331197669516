import React, { useState } from "react";
import {
    Box,
    Card, ResponsiveContext, Text
} from "grommet";
import VenteParticuliers from "./particuliers";
import VenteProfessionels from "./professionels";

const TAB_CAT = {
    particulier: 'particuliers',
    professionnel: 'professionnels'
}

const TAB_VENTE = {
    enligne: 'enligne',
    horsligne: 'horsligne'
}


const AssuranceDashboard = ({ showMessage, commerciaux }) => {

    const [selectedCatTab, setSelectedCatTab] = useState(TAB_CAT.particulier);
    const [selectedVenteTab, setSelectedVenteTab] = useState(TAB_VENTE.enligne);

    const isActiveCat = tab => selectedCatTab === tab;
    const isActiveVent = tab => selectedVenteTab === tab;

    return (
      <ResponsiveContext.Consumer>
        {size => (
        <Box width={'full'} justify="center" align="center">
            <Box width={'full'} >
                <Box style={{  position: 'absolute', left: 50, marginTop: 45}} align="center" justify="center" gap="small" direction={"row"}>
                    <Box direction="row">
                        <Card round={{ corner: "left", size: 'medium' }}
                            style={{ height: 40 }} pad='small'
                            onClick={() => setSelectedCatTab(TAB_CAT.particulier)}
                            background={isActiveCat(TAB_CAT.particulier) ? '#70A4EC' : '#fff'}
                            justify='center'
                        >
                            <Text size="small" color={isActiveCat(TAB_CAT.particulier) ? '#fff':'#707070'}>{isActiveCat(TAB_CAT.particulier) ? <b>Particuliers</b> : "Particuliers"}</Text>
                        </Card>
                        <Card round={{ corner: "right", size: 'medium' }}
                            style={{ height: 40 }} pad='small'
                            onClick={() => setSelectedCatTab(TAB_CAT.professionnel)}
                            background={isActiveCat(TAB_CAT.professionnel) ? '#70A4EC' : '#fff'}
                            justify='center'
                        >
                            <Text size="small" color={isActiveCat(TAB_CAT.professionnel) ? '#fff':'#707070'}>{isActiveCat(TAB_CAT.professionnel) ? <b>Professionels</b> : "Professionels"}</Text>
                        </Card>
                    </Box>
                    <Box direction="row">
                        <Card round={{ corner: "left", size: 'medium' }}
                            style={{ height: 40 }} pad='small'
                            onClick={() => setSelectedVenteTab(TAB_VENTE.enligne)}
                            background={isActiveVent(TAB_VENTE.enligne) ? '#E82C70' : '#fff'}
                            justify='center'
                        >
                            <Text size="small" color={isActiveVent(TAB_VENTE.enligne) ? '#fff':'#707070'}>{isActiveVent(TAB_VENTE.enligne) ? <b>En ligne</b> : "En ligne"}</Text>
                        </Card>
                        <Card round={{ corner: "right", size: 'medium' }}
                            style={{ height: 40 }} pad='small'
                            onClick={() => setSelectedVenteTab(TAB_VENTE.horsligne)}
                            background={isActiveVent(TAB_VENTE.horsligne) ? '#E82C70' : '#fff'}
                            justify='center'
                        >
                            <Text size="small" color={isActiveVent(TAB_VENTE.horsligne) ? '#fff':'#707070'}>{isActiveVent(TAB_VENTE.horsligne) ? <b>Hors ligne</b> : "Hors ligne"}</Text>
                        </Card>
                    </Box>
                </Box>
                {selectedCatTab === TAB_CAT.particulier &&
                    <VenteParticuliers
                        showMessage={showMessage}
                        commerciaux={commerciaux}
                        tab={selectedVenteTab}
                    />
                }
                {selectedCatTab === TAB_CAT.professionnel &&
                    <VenteProfessionels
                        showMessage={showMessage}
                        commerciaux={commerciaux}
                        tab={selectedVenteTab}
                    />
                }
            </Box>
        </Box>
        )}
        </ResponsiveContext.Consumer>
    )
}

export default AssuranceDashboard;
import React from "react";
import { Box, Card, Text } from "grommet";
import {
  StatusGoodSmall
} from "grommet-icons";
import { CreditRecapStatusColor } from "../../../../services/credit_status";

const CreditRecapStatusV1 = ({ status, size }) => (
  !size ?
  <Box gap="xsmall" pad="xsmall" align="center">
    <Card direction="row" gap="xsmall" pad="xsmall" align="center">
      <StatusGoodSmall color={CreditRecapStatusColor(status)} />
      <Text size="small">
        <strong>{status}</strong>
      </Text>
    </Card>
  </Box>:
  <Box>
    <Card direction="row" gap="xsmall" pad="xsmall" align="center">
      <StatusGoodSmall size="small" color={CreditRecapStatusColor(status)} />
      <Text size="xsmall">
        <strong>{status}</strong>
      </Text>
    </Card>
  </Box>
);

export default CreditRecapStatusV1;

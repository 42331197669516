import { Box, Button, Card, FileInput, Heading, Text } from "grommet";
import DateInput from "../../../elements/date_input";
import React, { useState } from "react";
import Line from "../../../elements/line_container";
import CustomSelect from "../../../elements/select_fill";
import ToggleButton from "../../../elements/toggle_button/toggle_button";
import MarksSlider from "../../../elements/slider/marks_slider";
import PrimaryButton from "../../../elements/primary_button";
import {
  CaretPrevious,
  Checkmark,
  Close,
  DocumentDownload,
  Trash,
} from "grommet-icons";
import { uploadFile } from "../../../../services/storage_service";

const YES_NO = [true, false];
const bonus = {
  background: "#28a745",
  cursor: "default",
  color: "#fff",
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 20,
  paddingRight: 20,
};
const malus = {
  background: "#E92C70",
  cursor: "default",
  color: "#fff",
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 20,
  paddingRight: 20,
};
const PROFESSIONS = [
  "Agriculteur",
  "Artisan",
  "Chef de entreprise",
  "Commerçant",
  "Étudiant",
  "Fonctionnaires ou assimilé",
  "Enseignants",
  "Profession libérale",
  "Demandeurs d'emploi",
  "Retraité",
  "Salarié",
  "Salarié cadre",
  "VRP",
];
const RESILISATION_CAUSE = [
  "Sinistres",
  "Non paiement de prime",
  "Défaut de pièces",
  "Fausse déclaration",
  "Aggravation de risque",
  "Nullité de contrat",
];

const SINISTRE_CAUSES = [
  "Materiel",
  "Corporel",
  "Bris de glace",
  "Vol ou tentative de vol",
  "Incendie",
  "Autre (catastrophes naturelles, tempête, etc.)",
];

const DURATIONS = [
  "Moins de 12 mois",
  "De 12 à 23 mois",
  "De 24 à 35 mois",
  "36 mois et plus",
];

const doUploadFile = (files, callback, showError) => {
    const fileList = files;
    if(fileList){
      for (let i = 0; i < fileList.length; i += 1) {
        const file = fileList[i];
        console.log(file.name);
        uploadFile(file)
          .then((result) => {
            console.log("result = ", result.data);
            callback(result.data);
          })
          .catch((err) => {
            showError("Erreur inattendu télécharger.");
            callback([]);
          });
      }
    }
  };

const DriverInfo = ({ devis, updateDevis, submit, onReset, onPrev, showMessage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateConductor = (field, value) => {
    let conducteur_principal = devis.conducteur_principal || {};
    conducteur_principal[field] = value;
    updateDevis(
      "conducteur_principal",
      Object.assign({}, conducteur_principal)
    );
  };

  const updateSinistreNombre = (nombre) => {
    let conducteur_principal = devis.conducteur_principal || {};
    conducteur_principal.sinistre_3ans_nombre = nombre;
    conducteur_principal.sinistres = Array(nombre)
      .fill()
      .map(() => ({}));

    console.log(conducteur_principal);
    updateDevis(
      "conducteur_principal",
      Object.assign({}, conducteur_principal)
    );
  };

  const updateSinistre = (field, value, index) => {
    let conducteur_principal = devis.conducteur_principal || {};
    let sinistre = conducteur_principal.sinistres[index];
    sinistre[field] = value;

    updateDevis(
      "conducteur_principal",
      Object.assign({}, conducteur_principal)
    );
  };
  let driver = devis.conducteur_principal || {};

  if (!driver.bonus_malus_moto) {
    driver.bonus_malus_moto = 1.0;
  }
  if (!driver.bonus_malus_auto) {
    driver.bonus_malus_auto = 1.0;
  }

  if (!devis.license_date) {
    devis.license_date = new Date();
  }

  if (driver.has_permis_a) {
    if (!driver.permis_a_date) {
      driver.permis_a_date = new Date();
    }
  }

  if (driver.has_permis_a1) {
    if (!driver.permis_a1_date) {
      driver.permis_a1_date = new Date();
    }
  }

  if (driver.has_permis_a2) {
    if (!driver.permis_a2_date) {
      driver.permis_a2_date = new Date();
    }
  }

  if (driver.has_permis_b) {
    if (!driver.permis_b_date) {
      driver.permis_b_date = new Date();
    }
  }

  if (driver.license_problem_alcool) {
    if (!driver.license_problem_alcool_date) {
      driver.license_problem_alcool_date = new Date();
    }
  }

  if (driver.license_problem) {
    if (!driver.license_problem_date) {
      driver.license_problem_date = new Date();
    }
  }

  return (
    <Box
      gap="small"
      width="xlarge"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Box justify="center" alignSelf="center">
        <Heading
          level={3}
          textAlign="center"
        >{`Informations du conducteur principal`}</Heading>
      </Box>
      <Box width="large" gap="medium" margin="medium">
        <Box gap="xsmall">
          <Line
            leftLabel="Date d'obtention du permis"
            rightChildren={
              <DateInput
                value={devis.license_date}
                onChange={(date) => updateDevis("license_date", date)}
              />
            }
          />
          <Line
            leftLabel="Le client a-t-il obtenu le permis A ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: driver.has_permis_a === value,
                  onClick: () => updateConductor("has_permis_a", value),
                }))}
              />
            }
          />
          {driver.has_permis_a && (
            <Line
              leftLabel="Date d'obtention du permis"
              rightChildren={
                <DateInput
                  value={driver.permis_a_date}
                  onChange={(date) => updateConductor("permis_a_date", date)}
                />
              }
            />
          )}
          <Line
            leftLabel="Le client a-t-il obtenu le permis A1 ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: driver.has_permis_a1 === value,
                  onClick: () => updateConductor("has_permis_a1", value),
                }))}
              />
            }
          />
          {driver.has_permis_a1 && (
            <Line
              leftLabel="Date d'obtention du permis"
              rightChildren={
                <DateInput
                  value={driver.permis_a1_date}
                  onChange={(date) => updateConductor("permis_a1_date", date)}
                />
              }
            />
          )}
          <Line
            leftLabel="Le client a-t-il obtenu le permis A2 ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: driver.has_permis_a2 === value,
                  onClick: () => updateConductor("has_permis_a2", value),
                }))}
              />
            }
          />
          {driver.has_permis_a2 && (
            <Line
              leftLabel="Date d'obtention du permis"
              rightChildren={
                <DateInput
                  value={driver.permis_a2_date}
                  onChange={(date) => updateConductor("permis_a2_date", date)}
                />
              }
            />
          )}
          {driver.has_permis_a2 && (
            <Line
              leftLabel="Puissance max 35 KW"
              rightChildren={
                <ToggleButton
                  groups={YES_NO.map((value) => ({
                    label: value === true ? "Oui" : "Non",
                    selected:
                      driver.a2_puissance_max_35 === value,
                    onClick: () =>
                      updateConductor("a2_puissance_max_35", value),
                  }))}
                />
              }
            />
          )}
          <Line
            leftLabel="Le client a-t-il obtenu le permis B ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: driver.has_permis_b === value,
                  onClick: () => updateConductor("has_permis_b", value),
                }))}
              />
            }
          />
          {driver.has_permis_b && (
            <Line
              leftLabel="Date d'obtention du permis"
              rightChildren={
                <DateInput
                  value={driver.permis_b_date}
                  onChange={(date) => updateConductor("permis_b_date", date)}
                />
              }
            />
          )}
          <Line
            leftLabel="Indiquer le Bonus / Malus moto"
            rightChildren={
              <Box direction="row" justify="between">
                <MarksSlider
                  step={0.01}
                  max={3.5}
                  min={0.5}
                  value={driver.bonus_malus_moto || 1.0}
                  marks={[
                    { value: 0.5, label: "0.5" },
                    { value: 1.0, label: "1.0" },
                    { value: 1.5, label: "1.5" },
                    { value: 2.0, label: "2.0" },
                    { value: 2.5, label: "2.5" },
                    { value: 3.0, label: "3.0" },
                    { value: 3.5, label: "3.5" },
                  ]}
                  onChange={(event, newValue) =>
                    updateConductor("bonus_malus_moto", newValue)
                  }
                />
                <Box justify="center">
                  <Button
                    style={
                      driver.bonus_malus_moto > 1.0
                        ? malus
                        : bonus
                    }
                  >
                    {driver.bonus_malus_moto > 1.0
                      ? `Malus : `
                      : `Bonus : `}
                    {driver.bonus_malus_moto}
                  </Button>
                </Box>
              </Box>
            }
          />
          <Line
            leftLabel="Indiquer le Bonus / Malus auto"
            rightChildren={
              <Box direction="row" justify="between">
                <MarksSlider
                  step={0.01}
                  max={3.5}
                  min={0.5}
                  value={driver.bonus_malus_auto || 1.0}
                  marks={[
                    { value: 0.5, label: "0.5" },
                    { value: 1.0, label: "1.0" },
                    { value: 1.5, label: "1.5" },
                    { value: 2.0, label: "2.0" },
                    { value: 2.5, label: "2.5" },
                    { value: 3.0, label: "3.0" },
                    { value: 3.5, label: "3.5" },
                  ]}
                  onChange={(event, newValue) =>
                    updateConductor("bonus_malus_auto", newValue)
                  }
                />
                <Box justify="center">
                  <Button
                    style={
                      driver.bonus_malus_auto > 1.0
                        ? malus
                        : bonus
                    }
                  >
                    {driver.bonus_malus_auto > 1.0
                      ? `Malus : `
                      : `Bonus : `}
                    {driver.bonus_malus_auto}
                  </Button>
                </Box>
              </Box>
            }
          />
          <Line
            leftLabel="Situation familiale ?"
            rightChildren={
              <ToggleButton
                groups={["Seul", "En couple"].map((value) => ({
                  label: value,
                  selected:
                    driver.situation_familliale === value,
                  onClick: () => updateConductor("situation_familliale", value),
                }))}
              />
            }
          />
          <Line
            leftLabel="Profession ?"
            rightChildren={
              <CustomSelect
                options={PROFESSIONS}
                value={driver.profession}
                onChange={({ option }) => updateConductor("profession", option)}
              />
            }
          />
          <Line
            leftLabel="Avez-vous été assuré(s) en Moto en cours des 3 dernières années ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: driver.assure_3ans === value,
                  onClick: () => updateConductor("assure_3ans", value),
                }))}
              />
            }
          />
          {driver.assure_3ans && (
            <Box gap="xsmall">
              <Text size="small">Sur quelle cylindrée ?</Text>
              <Line
                leftLabel="80 à 125 cm3"
                rightChildren={
                  <ToggleButton
                    groups={YES_NO.map((value) => ({
                      label: value === true ? "Oui" : "Non",
                      selected:
                        driver.cylindre_80_125 === value,
                      onClick: () => updateConductor("cylindre_80_125", value),
                    }))}
                  />
                }
              />
              {driver.cylindre_80_125 && (
                <Box justify="end" align="end" style={{ marginBottom: 20 }}>
                  <ToggleButton
                    groups={DURATIONS.map((value) => ({
                      label: value,
                      selected:
                        driver.cylindre_80_125_duration ===
                        value,
                      onClick: () =>
                        updateConductor("cylindre_80_125_duration", value),
                    }))}
                  />
                </Box>
              )}
              <Line
                leftLabel="125 à 399 cm3"
                rightChildren={
                  <ToggleButton
                    groups={YES_NO.map((value) => ({
                      label: value === true ? "Oui" : "Non",
                      selected:
                        driver.cylindre_125_399 === value,
                      onClick: () => updateConductor("cylindre_125_399", value),
                    }))}
                  />
                }
              />
              {driver.cylindre_125_399 && (
                <Box justify="end" align="end" style={{ marginBottom: 20 }}>
                  <ToggleButton
                    groups={DURATIONS.map((value) => ({
                      label: value,
                      selected:
                        driver.cylindre_125_399_duration ===
                        value,
                      onClick: () =>
                        updateConductor("cylindre_125_399_duration", value),
                    }))}
                  />
                </Box>
              )}
              <Line
                leftLabel="400 à 599 cm3"
                rightChildren={
                  <ToggleButton
                    groups={YES_NO.map((value) => ({
                      label: value === true ? "Oui" : "Non",
                      selected:
                        driver.cylindre_400_599 === value,
                      onClick: () => updateConductor("cylindre_400_599", value),
                    }))}
                  />
                }
              />
              {driver.cylindre_400_599 && (
                <Box justify="end" align="end" style={{ marginBottom: 20 }}>
                  <ToggleButton
                    groups={DURATIONS.map((value) => ({
                      label: value,
                      selected:
                        driver.cylindre_400_599_duration ===
                        value,
                      onClick: () =>
                        updateConductor("cylindre_400_599_duration", value),
                    }))}
                  />
                </Box>
              )}
              <Line
                leftLabel="600 cm3 et +"
                rightChildren={
                  <ToggleButton
                    groups={YES_NO.map((value) => ({
                      label: value === true ? "Oui" : "Non",
                      selected:
                        driver.cylindre_600_plus === value,
                      onClick: () =>
                        updateConductor("cylindre_600_plus", value),
                    }))}
                  />
                }
              />
              {driver.cylindre_600_plus && (
                <Box justify="end" align="end" style={{ marginBottom: 20 }}>
                  <ToggleButton
                    groups={DURATIONS.map((value) => ({
                      label: value,
                      selected: driver.cylindre_600_plus_duration === value,
                      onClick: () =>
                        updateConductor("cylindre_600_plus_duration", value),
                    }))}
                  />
                </Box>
              )}
            </Box>
          )}
          <Line
            leftLabel="Avez-vous eu une ou des interruptions d’assurance sur les 3 dernières années ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: driver.interruption === value,
                  onClick: () => updateConductor("interruption", value),
                }))}
              />
            }
          />
          {driver.interruption && (
            <Line
              leftLabel=" Pendant combien de temps (mois) ?"
              rightChildren={
                <MarksSlider
                  step={1}
                  max={36}
                  min={0}
                  value={driver.interruption_duree || 0}
                  marks={[
                    { value: 0, label: "0" },
                    { value: 6, label: "6" },
                    { value: 12, label: "12" },
                    { value: 18, label: "18" },
                    { value: 24, label: "24" },
                    { value: 30, label: "30" },
                    { value: 36, label: "36" },
                  ]}
                  onChange={(event, newValue) =>
                    updateConductor("interruption_duree", newValue)
                  }
                />
              }
            />
          )}
          <Line
            leftLabel="Avez-vous fait l’objet d’une résiliation par un précédent assureur ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: driver.resiliation === value,
                  onClick: () => updateConductor("resiliation", value),
                }))}
              />
            }
          />
          {driver.resiliation && (
            <Line
              leftLabel="Nature de cause ?"
              rightChildren={
                <CustomSelect
                  options={RESILISATION_CAUSE}
                  value={driver.resiliation_cause}
                  onChange={({ option }) =>
                    updateConductor("resiliation_cause", option)
                  }
                />
              }
            />
          )}
          <Line
            leftLabel="Avez-vous eu des sinistres auto au cours des 3 dernières années ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected: driver.sinistre_3ans === value,
                  onClick: () => {
                    if (value === false) {
                      updateSinistreNombre(undefined);
                    }
                    updateConductor("sinistre_3ans", value);
                  },
                }))}
              />
            }
          />
          {driver.sinistre_3ans && (
            <Line
              leftLabel="Nombre total de sinistres déclarés ?"
              rightChildren={
                <CustomSelect
                  options={[1, 2, 3, 4, 5, 6, 7]}
                  value={driver.sinistre_3ans_nombre}
                  onChange={({ option }) => updateSinistreNombre(option)}
                />
              }
            />
          )}
          {driver.sinistre_3ans_nombre &&
            Array(driver.sinistre_3ans_nombre)
              .fill()
              .map((v, index) => index)
              .map((val) => (
                <Sinistre
                  key={val}
                  index={val}
                  sinistre={driver.sinistres[val]}
                  updateSinistre={(field, value) =>
                    updateSinistre(field, value, val)
                  }
                />
              ))}

          <Line
            leftLabel="Avez-vous fait l’objet, sur les 60 derniers mois, d’un retrait (suspension ou annulation) de votre permis de conduire pour une infraction au Code de la Route ?"
            rightChildren={
              <ToggleButton
                groups={[
                  "Aucun retrait de permis",
                  "Suspension",
                  "Annulation",
                ].map((value) => ({
                  label: value,
                  selected:
                    driver.license_problem === value,
                  onClick: () => updateConductor("license_problem", value),
                }))}
              />
            }
          />
          {driver.license_problem &&
            driver.license_problem !==
              "Aucun retrait de permis" && (
              <Line
                leftLabel="pour contrôle positif d’alcoolémie ?"
                rightChildren={
                  <ToggleButton
                    groups={YES_NO.map((value) => ({
                      label: value === true ? "Oui" : "Non",
                      selected:
                        driver.license_problem_alcool ===
                        value,
                      onClick: () =>
                        updateConductor("license_problem_alcool", value),
                    }))}
                  />
                }
              />
            )}
          {driver.license_problem_alcool && (
            <Line
              leftLabel="Date de contrôle"
              rightChildren={
                <DateInput
                  value={driver.license_problem_alcool_date}
                  onChange={(date) =>
                    updateConductor("license_problem_alcool_date", date)
                  }
                />
              }
            />
          )}
          {driver.license_problem_alcool && (
            <Line
              leftLabel="Taux constaté"
              rightChildren={
                <MarksSlider
                  step={0.01}
                  max={1.5}
                  min={0}
                  value={
                    driver.license_problem_alcool_taux || 0
                  }
                  marks={[
                    { value: 0, label: "0" },
                    { value: 0.25, label: "0.25" },
                    { value: 0.5, label: "0.5" },
                    { value: 0.75, label: "0.75" },
                    { value: 1.0, label: "1.0" },
                    { value: 1.25, label: "1.25" },
                    { value: 1.5, label: "1.5" },
                  ]}
                  onChange={(event, newValue) =>
                    updateConductor("license_problem_alcool_taux", newValue)
                  }
                />
              }
            />
          )}
          {driver.license_problem_alcool && (
            <Line
              leftLabel="Contrôle alcoolémie avec accident"
              rightChildren={
                <ToggleButton
                  groups={YES_NO.map((value) => ({
                    label: value === true ? "Oui" : "Non",
                    selected: driver.license_problem_alcool_accident === value,
                    onClick: () =>
                      updateConductor("license_problem_alcool_accident", value),
                  }))}
                />
              }
            />
          )}
          {driver.license_problem_alcool && (
            <Line
              leftLabel="S’agit-il d’une récidive"
              rightChildren={
                <ToggleButton
                  groups={YES_NO.map((value) => ({
                    label: value === true ? "Oui" : "Non",
                    selected: driver.license_problem_alcool_recidive === value,
                    onClick: () =>
                      updateConductor("license_problem_alcool_recidive", value),
                  }))}
                />
              }
            />
          )}
          {driver.license_problem &&
            driver.license_problem !==
              "Aucun retrait de permis" && (
              <Line
                leftLabel="Pour usage de stupéfiants ou de drogue"
                rightChildren={
                  <ToggleButton
                    groups={YES_NO.map((value) => ({
                      label: value === true ? "Oui" : "Non",
                      selected:
                        driver.license_problem_drougue ===
                        value,
                      onClick: () =>
                        updateConductor("license_problem_drougue", value),
                    }))}
                  />
                }
              />
            )}
          {driver.license_problem &&
            driver.license_problem !==
              "Aucun retrait de permis" && (
              <Line
                leftLabel="pour refus de se soumettre à un contrôle de dépistage d’alcoolémie ou stupéfiants"
                rightChildren={
                  <ToggleButton
                    groups={YES_NO.map((value) => ({
                      label: value === true ? "Oui" : "Non",
                      selected: driver.license_problem_refus_control === value,
                      onClick: () =>
                        updateConductor("license_problem_refus_control", value),
                    }))}
                  />
                }
              />
            )}
          {driver.license_problem &&
            driver.license_problem !==
              "Aucun retrait de permis" && (
              <Line
                leftLabel="pour refus d’obtempérer"
                rightChildren={
                  <ToggleButton
                    groups={YES_NO.map((value) => ({
                      label: value === true ? "Oui" : "Non",
                      selected: driver.license_problem_refus_obtemperer === value,
                      onClick: () =>
                        updateConductor(
                          "license_problem_refus_obtemperer",
                          value
                        ),
                    }))}
                  />
                }
              />
            )}
          {driver.license_problem &&
            driver.license_problem !==
              "Aucun retrait de permis" && (
              <Line
                leftLabel="pour délit de fuite"
                rightChildren={
                  <ToggleButton
                    groups={YES_NO.map((value) => ({
                      label: value === true ? "Oui" : "Non",
                      selected:
                        driver.license_problem_fuite ===
                        value,
                      onClick: () =>
                        updateConductor("license_problem_fuite", value),
                    }))}
                  />
                }
              />
            )}
          {driver.license_problem &&
            driver.license_problem !==
              "Aucun retrait de permis" && (
              <Line
                leftLabel="pour défault d'assurance"
                rightChildren={
                  <ToggleButton
                    groups={YES_NO.map((value) => ({
                      label: value === true ? "Oui" : "Non",
                      selected: driver.license_problem_fake_assurance === value,
                      onClick: () =>
                        updateConductor(
                          "license_problem_fake_assurance",
                          value
                        ),
                    }))}
                  />
                }
              />
            )}
          {driver.license_problem &&
            driver.license_problem !==
              "Aucun retrait de permis" && (
              <Line
                leftLabel="pour autre infraction au Code de la Route ?"
                rightChildren={
                  <ToggleButton
                    groups={YES_NO.map((value) => ({
                      label: value === true ? "Oui" : "Non",
                      selected: driver.license_problem_autre_infraction === value,
                      onClick: () =>
                        updateConductor(
                          "license_problem_autre_infraction",
                          value
                        ),
                    }))}
                  />
                }
              />
            )}
          {driver.license_problem &&
            driver.license_problem !==
              "Aucun retrait de permis" && (
              <Line
                leftLabel="Date d'effet du retrait ?"
                rightChildren={
                  <DateInput
                    value={driver.license_problem_date}
                    onChange={(date) =>
                      updateConductor("license_problem_date", date)
                    }
                  />
                }
              />
            )}
          {driver.license_problem === "Suspension" && (
            <Line
              leftLabel="Duration de la suspension"
              rightChildren={
                <MarksSlider
                  step={1}
                  max={24}
                  min={0}
                  value={driver.suspension_duree || 0}
                  marks={[
                    { value: 0, label: "0" },
                    { value: 6, label: "6" },
                    { value: 12, label: "12" },
                    { value: 18, label: "18" },
                    { value: 24, label: "24" },
                  ]}
                  onChange={(event, newValue) =>
                    updateConductor("suspension_duree", newValue)
                  }
                />
              }
            />
          )}
          <Line
            leftLabel="Avez-vous fait l’objet, sur les 60 derniers mois, d’une sanction pour conduite en état alcoolique et/ou usage de stupéfiants ?"
            rightChildren={
              <ToggleButton
                groups={YES_NO.map((value) => ({
                  label: value === true ? "Oui" : "Non",
                  selected:
                    driver.saction_conduite_alcoolique ===
                    value,
                  onClick: () =>
                    updateConductor("saction_conduite_alcoolique", value),
                }))}
              />
            }
          />
        </Box>
      </Box>

      <Card size="xlarge" align="center" justify="center" pad="medium">
        <Box direction="row" pad="small" justify="between" gap="large" width={"100%"}>
          {devis.file_permis && (
            <a target={"_blank"} rel="noreferrer" href={`${devis.file_permis}`}>
              <DocumentDownload />
              Permis de conduire
            </a>
          )}
          <FileInput
            messages={{
              browse: "Choisir",
              dropPrompt: "Permis de conduire",
            }}
            multiple={false}
            onChange={(event) => {
              setIsLoading(true);
              doUploadFile(
                event.target.files,
                (data) => {
                  if (data.length > 0) {
                    let _uploaded = data[0];
                    updateDevis("file_permis", _uploaded.url);
                  }
                  setIsLoading(false);
                },
                showMessage
              );
            }}
          />
        </Box>
        <Box direction="row" pad="small" justify="between" gap="large"  width={"100%"}>
          {devis.file_carte_grise && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_carte_grise}`}
            >
              <DocumentDownload />
              Carte grise
            </a>
          )}
          <FileInput
            messages={{
              browse: "Choisir",
              dropPrompt: "Carte grise",
            }}
            multiple={false}
            onChange={(event) => {
              setIsLoading(true);
              doUploadFile(
                event.target.files,
                (data) => {
                  if (data.length > 0) {
                    let _uploaded = data[0];
                    updateDevis("file_carte_grise", _uploaded.url);
                  }
                  setIsLoading(false);
                },
                showMessage
              );
            }}
          />
        </Box>
        {/* Releve Information files */}
        {devis.file_releve_information ? (
          devis.file_releve_information.map((e, _idx) => {
            return (
              <Box
                key={`file_${_idx}`}
                direction="row"
                pad="small"
                gap="small"
                justify="between"
                align="center"
                width={"100%"}
              >
                <Box direction="row" gap="small" overflow="hidden">
                  {e && (
                    <Button
                      onClick={() => {
                        let _files = devis.file_releve_information;
                        _files.splice(_idx, 1);
                        updateDevis("file_releve_information", _files);
                      }}
                    >
                      <Trash color="red" />
                    </Button>
                  )}
                  {e && (
                    <a target={"_blank"} rel="noreferrer" href={`${e}`}>
                      <DocumentDownload />
                      Relevé d'information {_idx + 1} - {e.split("/").pop()}
                    </a>
                  )}
                </Box>
                <FileInput
                  messages={{
                    browse: "Choisir",
                    dropPrompt: "Relevé d'information",
                  }}
                  multiple={false}
                  onChange={(event) => {
                    setIsLoading(true);
                    doUploadFile(
                      event.target.files,
                      async (data) => {
                        if (data.length > 0) {
                          let _uploaded = data[0];
                          let _files = devis.file_releve_information || [];
                          _files[_idx] = _uploaded.url;
                          updateDevis("file_releve_information", _files);
                        }
                        setIsLoading(false);
                      },
                      showMessage
                    );
                  }}
                />
              </Box>
            );
          })
        ) : (
          <></>
        )}
        <FileInput
          messages={{
            browse: "Choisir",
            dropPrompt: "Ajouter votre relevé d'information moto",
          }}
          multiple={false}
          onChange={(event) => {
            setIsLoading(true);
            doUploadFile(
              event.target.files,
              async (data) => {
                if (data.length > 0) {
                  let _uploaded = data[0];
                  let _files = devis.file_releve_information || [];
                  _files.push(_uploaded.url);
                  updateDevis("file_releve_information", _files);
                }
                setIsLoading(false);
              },
              showMessage
            );
          }}
        />
        {/* End Releve Information files */}
        {devis.file_others ? (
          devis.file_others.map((e, _idx) => {
            return (
              <Box
                key={`file_${_idx}`}
                direction="row"
                pad="small"
                gap="small"
                justify="between"
                align="center"
                width={"100%"}
              >
                <Box direction="row" gap="small" overflow="hidden">
                  {e && (
                    <Button
                      onClick={() => {
                        console.log("remove index = ", _idx);
                        let _files = devis.file_others;
                        _files.splice(_idx, 1);
                        updateDevis("file_others", _files);
                      }}
                    >
                      <Trash color="red" />
                    </Button>
                  )}
                  {e && (
                    <a target={"_blank"} rel="noreferrer" href={`${e}`}>
                      <DocumentDownload />
                      {e.split("/").pop()}
                    </a>
                  )}
                </Box>
                <FileInput
                  messages={{
                    browse: "Choisir",
                    dropPrompt: "Ajouter un autre document",
                  }}
                  multiple={false}
                  onChange={(event) => {
                    setIsLoading(true);
                    doUploadFile(
                      event.target.files,
                      async (data) => {
                        if (data.length > 0) {
                          let _uploaded = data[0];
                          let _files = devis.file_others || [];
                          _files[_idx] = _uploaded.url;
                          updateDevis("file_others", _files);
                        }
                        setIsLoading(false);
                      },
                      showMessage
                    );
                  }}
                />
              </Box>
            );
          })
        ) : (
          <></>
        )}
        <FileInput
          messages={{
            browse: "Choisir",
            dropPrompt: "Ajouter un autre document",
          }}
          multiple={false}
          onChange={(event) => {
            setIsLoading(true);
            doUploadFile(
              event.target.files,
              async (data) => {
                if (data.length > 0) {
                  let _uploaded = data[0];
                  let _files = devis.file_others || [];
                  _files.push(_uploaded.url);
                  updateDevis("file_others", _files);
                }
                setIsLoading(false);
              },
              showMessage
            );
          }}
        />
      </Card>

      <Box style={{ height: 40 }} />
      <Box direction="row" align="center" justify="center" gap="medium">
        <PrimaryButton
          disabled={isLoading}
          label={`Précédent`}
          background="#59A4F4"
          icon={<CaretPrevious />}
          onClick={onPrev}
        />
        <PrimaryButton
          disabled={isLoading}
          label={`Sauvegarder`}
          icon={<Checkmark />}
          onClick={submit}
        />
        {devis.id && (
          <PrimaryButton
            disabled={isLoading}
            label={`Reset`}
            icon={<Close />}
            background={"#6c757d"}
            onClick={onReset}
          />
        )}
      </Box>
    </Box>
  );
};

const Sinistre = ({ index, sinistre, updateSinistre }) => {
  if (!sinistre.date) {
    sinistre.date = new Date();
  }
  return (
    <Box width="large" gap="medium">
      <Text textAlign="center" style={{ fontWeight: "bold", fontSize: 12 }}>
        Sinistre {index + 1}
      </Text>
      <Box gap="xsmall">
        <Line
          leftLabel=" Date de survenance ?"
          rightChildren={
            <DateInput
              value={sinistre.date}
              onChange={(date) => updateSinistre("date", date)}
            />
          }
        />
        <Line
          leftLabel="Type de véhicule ?"
          rightChildren={
            <ToggleButton
              groups={['Trusti Voiture', 'Trusti Moto'].map((value) => ({
                label: value,
                selected: sinistre.type_vehicule === value,
                onClick: () => updateSinistre("type_vehicule", value),
              }))}
            />
          }
        />
        <Line
          leftLabel="Nature du sinistre ?"
          rightChildren={
            <CustomSelect
              options={SINISTRE_CAUSES}
              value={sinistre.cause}
              onChange={({ option }) => updateSinistre("cause", option)}
            />
          }
        />
        {sinistre.type_vehicule === 'Trusti Moto' || sinistre.cause === "Materiel" ? (
          <Line
            leftLabel="Responsabilité ?"
            rightChildren={
              <ToggleButton
                groups={["Non Responsable", "Responsable"].map((value) => ({
                  label: value,
                  selected: sinistre.responsable === value,
                  onClick: () => updateSinistre("responsable", value),
                }))}
              />
            }
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default DriverInfo;

import { Box, Button, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import { AddCircle, Subtract, SubtractCircle, Trash } from 'grommet-icons';
import React, { useState } from "react";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { deleteEquipe, getCollabs, getEquipes, getRoles, saveEquipe } from "../../../services/admin_service";
import AddEquipe from '../../elements/add_devis';
import CustomInput from '../../elements/custom_input';
import Line from '../../elements/line_container';
import { LineDisplay } from '../../elements/line_container/line_modification';
import PopupConfirmation from '../../elements/popup_confirmation';
import PrimaryButton from '../../elements/primary_button';
import Refresh from '../../elements/refresh';
import CustomSelect from '../../elements/select_fill';


class GestionEquipe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            equipes: [],
            selectedEquipe: undefined,
            commerciaux: [],
            roles: [],
            equipeToDelete: undefined
        }
    }

    componentDidMount = () => {
        this.onReload();
        getCollabs()
            .then(res => {
                let collabs = res.data.map(u => ({ ...u, name: `${u.prenom || ""} ${u.nom || ""}` }));
                this.setState({ commerciaux: collabs })
            });
        getRoles()
            .then(res => this.setState({ roles: res.data }));
    }

    onReload = () => {
        getEquipes().then(res => this.setState({ equipes: res.data }))
            .catch(err => console.log(err));
    }

    render() {
        return (
            this.state.selectedEquipe ?
                <Equipe
                    selectedEquipe={this.state.selectedEquipe}
                    commerciaux={this.state.commerciaux}
                    onReload={this.onReload}
                    showMessage={this.props.showMessage}
                    onClose={() => this.setState({ selectedEquipe: undefined })}
                    roles={this.state.roles}
                /> :
                <Box width={'full'} align="center" justify='center'>
                    {this.state.equipeToDelete &&
                        <PopupConfirmation
                            primaryAction={() => {
                                deleteEquipe(this.state.equipeToDelete.id)
                                    .then(res => {
                                        this.onReload();
                                        this.setState({ equipeToDelete: undefined })
                                    })
                                    .catch(err => this.props.showMessage("Erreur inattendu", "error"))
                            }}
                            message="Êtes-vous sûr de supprimer cette equipe?"
                            primaryActionText={"Supprimer"}
                            secondaryAction={() => this.setState({ equipeToDelete: undefined })}
                            secondaryActionText="Annuler"
                            close={() => this.setState({ equipeToDelete: undefined })}
                        />
                    }
                    <Box
                        width="xlarge"
                        justify="around"
                        margin="medium"
                        direction="row"
                    >
                        <Refresh refresh={this.onReload} />
                        <AddEquipe onAdd={() => this.setState({ selectedEquipe: { membres: [{}, {}, {}] } })} />
                    </Box>
                    <Box width={'large'} justify='center' align="center">
                        <Table
                            fill="horizontal"
                            style={{
                                width: "100%",
                            }}
                        >
                            <TableHeader>
                                <TableRow>
                                    <TableCell scope="col">
                                        <Text size="small" weight="bold">
                                            Equipe
                                        </Text>
                                    </TableCell>
                                    <TableCell scope="col">
                                        <Text size="small" weight="bold">
                                            Chef équipe
                                        </Text>
                                    </TableCell>
                                    <TableCell scope="col">
                                        <Text size="small" weight="bold">
                                            Nb de membre
                                        </Text>
                                    </TableCell>
                                    <TableCell scope="col">
                                        <Text size="small" weight="bold">
                                        </Text>
                                    </TableCell>
                                    <TableCell scope="col">
                                        <Text size="small" weight="bold">
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {(this.state.equipes)
                                    .map((equipe, index) => {
                                        let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                                        return (
                                            <TableRow
                                                key={index + 1}
                                                style={{ backgroundColor: _bgColor }}
                                            >
                                                <TableCell>
                                                    <Text size="small">{equipe.name} </Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text size="small">{getName(equipe.manager)}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text size="small">{equipe.membres?.length}</Text>
                                                </TableCell>
                                                <TableCell>
                                                    <PrimaryButton
                                                        label="voir"
                                                        onClick={() => this.setState({ selectedEquipe: equipe })}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Box onClick={() => this.setState({ equipeToDelete: equipe })}><Trash color='brand' /></Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
        )
    }
}

const getName = collab => `${collab?.prenom} ${collab?.nom}`

const Equipe = ({ selectedEquipe, commerciaux, onReload, showMessage, onClose, roles }) => {

    const [isView, setViewMode] = useState(selectedEquipe?.id ? true : false);
    const [equipe, setEquipe] = useState({ ...selectedEquipe });
    const [blocking, setBlocking] = useState(false);

    const onEquipeChange = (field, value) => {
        console.log(value);
        equipe[field] = value;
        setEquipe({ ...equipe });
    }
    const onMembreChange = (index, commercial) => {
        let membres = equipe.membres;
        membres[index] = commercial;
        setEquipe({ ...equipe });
    }
    const removeMembre = index => {
        let array = equipe.membres || [];
        array.splice(index, 1);
        setEquipe({...equipe});
    }
    const onSave = () => {
        if (!equipe.name || !equipe.manager) {
            showMessage("Tous les champs sont requis", "error");
            return;
        }
        let membres = equipe.membres.filter(m => m.id && m.id !== equipe.manager.id);
        if (!membres.length === 0) {
            showMessage("Au moin un membre", "error");
            return;
        }
        let equipeToSave = { ...equipe };

        equipeToSave.membres = Array.from(new Set(membres.map(m => m.id)));
        equipeToSave.manager = equipe.manager.id;
        setBlocking(true);
        saveEquipe(equipeToSave)
            .then(res => {
                setBlocking(false);
                showMessage("Equipe est sauvegardé avec succès", "success");
                onClose();
                onReload();
            })
            .catch(err => {
                setBlocking(false);
                showMessage("Error in attendu", "error");
                console.log(err);
            })
    }
    return (
        <BlockUi tag="div" blocking={blocking}>
            <Box width={'large'} gap='medium' justify='center' align='center' pad={'medium'}>
                <Box width={'medium'} gap='small' >
                    {isView ?
                        <Text size='xxlarge' textAlign='center'><b>Equipe {equipe.name}</b></Text> :
                        <Text size='xxlarge' textAlign='center'><b>{equipe?.id ? 'Modifier Equipe' : 'Ajouter une équipe'}</b></Text>
                    }
                    {isView ?
                        <Box /> :
                        <Line
                            leftLabel={"Nom de l'équipe"}
                            rightChildren={
                                <CustomInput
                                    size='small'
                                    placeholder={"Nom de l'équipe"}
                                    value={equipe.name}
                                    onChange={event => onEquipeChange('name', event.target.value)}
                                />
                            }
                        />
                    }
                    {isView ?
                        <LineDisplay size='small' field={"Chef d'équipe"} value={getName(equipe.manager)} /> :
                        <Line
                            leftLabel={"Chef d'équipe"}
                            rightChildren={
                                <CustomSelect
                                    placeholder={'Manager'}
                                    options={commerciaux.filter(c => !c.equipe_id || c.equipe_id === equipe?.id)}
                                    value={getDisplayCommercial(equipe.manager)}
                                    labelKey={"name"}
                                    valueKey={{ key: "name", reduce: true }}
                                    onChange={({ option }) => onEquipeChange("manager", option)}
                                />
                            }
                        />
                    }
                    {(equipe.membres || []).map((membre, index) => (
                        isView ?
                            <LineDisplay size='small' field={'Membre N°' + (index + 1)} value={getName(membre)} /> :
                            <Line
                                leftLabel={'Membre N°' + (index + 1)}
                                rightChildren={
                                    <Box direction='row' gap='small' align='center'>
                                        <CustomSelect
                                            placeholder={'Commercial'}
                                            options={commerciaux.filter(c => !c.equipe_id || c.equipe_id === equipe?.id)}
                                            labelKey={"name"}
                                            value={getDisplayCommercial(membre)}
                                            valueKey={{ key: "name", reduce: true }}
                                            onChange={({ option }) => onMembreChange(index, option)}
                                        />
                                        {equipe.membres.length > 1 &&
                                            <Button icon={<SubtractCircle color='brand'/>}
                                                onClick={() => removeMembre(index)}
                                            />
                                        }
                                    </Box>
                                }
                            />
                    ))}

                    {!isView &&
                        <Box direction='row' gap='small' justify='center' align='center'
                            onClick={() => onMembreChange(equipe.membres.length, {})}
                        >
                            <AddCircle color='brand' />
                            <Text size='small'>Ajouter un membre</Text>
                        </Box>
                    }
                </Box>
                <Box direction='row' gap='small'>
                    {isView ?
                        <Box gap='small' direction='row'>
                            <PrimaryButton label={'Modifier'} onClick={() => setViewMode(false)} />
                            <PrimaryButton label={'Fermer'} background={"#6c757d"} onClick={onClose} />
                        </Box> :
                        <Box gap='small' direction='row'>
                            <PrimaryButton label={"Sauvegarde"} onClick={onSave} />
                            <PrimaryButton label={'Fermer'} background={"#6c757d"} onClick={onClose} />
                        </Box>
                    }
                </Box>

            </Box>
        </BlockUi>
    )
}

const getDisplayCommercial = u => !u ? undefined : u.name || `${u.prenom || ""} ${u.nom || ""}`

export default GestionEquipe;

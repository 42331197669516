import { Box, Text } from 'grommet';
import React from 'react';
import SectionDivider from '../../voyages/components/session_devider';

const ContactRecap = ({ contact={}, includeDetail=true }) => {

    return (
        <Box width={'large'} justify="center" align='center' gap='small'  pad='xsmall'>
            <SectionDivider title={'Informations contact'} />
            <Box width={'large'} gap='small' direction='row' justify='between'>
                <Box gap='small' style={{ width: '50%' }}>
                    <Box pad={'xsmall'} gap='small'>
                        <LineDisplay field={"Nom"} value={contact.lastname} />
                        <LineDisplay field={"Prénom"} value={contact.firstname} />
                        <LineDisplay field={"Type de client"} value={`${contact.contact_type}`} />
                        <LineDisplay field={"Téléphone"} value={contact.phone} />
                        <LineDisplay field={"Email"} value={contact.email} />
                        {includeDetail && <LineDisplay field={"Indice de confiance sur Trustiway"} value={contact.indice_de_confiance_sur_trustiway} />}
                        {includeDetail && <LineDisplay field={"Indice de confiance sur Seller"} value={contact.indice_de_confiance_sur_le_seller} />}
                        {includeDetail && <LineDisplay field={"Status du lead"} value={contact.statut_du_lead} />}
                    </Box>
                </Box>
                <Box gap='small' style={{ width: '50%' }}>
                    <Box pad={'xsmall'} gap='small'>
                        <LineDisplay field={"Adresse"} value={`${contact.address}, ${contact.zip}, ${contact.city}`} />
                        <LineDisplay field={"Ne pas envoyer email"} value={contact.do_not_emailing === true ? '❌ Ne pas envoyer email' : '✅ Email OK'} />
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}



const LineDisplay = ({ field, value }) => (
    <Text size="small" color={'#707070'}>
        {field} :
        <b> {value} </b>
    </Text>
)

export default ContactRecap;
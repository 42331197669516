import { Box, Card } from "grommet";
import { Refresh } from "grommet-icons";
import { Text } from "grommet/components/Text";
import moment from "moment";
import React from "react";
import { getEquipes } from "../../services/admin_service";
import { getGestionCollabs } from "../../services/dashboard";
import serviceCredit from "../../services/credit_service";
import {
  PROSPECT,
  NOUVEAU,
  NOUVEAU_REMPLI,
  DOSSIER_EN_INSTRUCTION,
  DOSSIER_ENVOYE_A_LA_BANQUE,
  PRET_ACCORDE_PAR_LA_BANQUE,
  OFFRE_ACCEPTE_PAR_CLIENT,
  REALISE,
  FACTURE,
  EN_ETUDE_DE_ANALYSTE,
  CLOTURE,
  ARCHIVE,
  PERDU,
  ABONDONS,
  CONTRAT_CLOTURE,
  EN_ETUDE_DE_PRE_ANALYSTE
} from "../../services/credit_status";
import { getUser, hasAdministrativeRole, hasAnalysteRole, hasChefAnalysteRole, hasClassiqueManagerRole, hasOnlyAdministrativeRole, hasPreAnalysteRole, isCommercialClassique } from "../../services/storage_service";
import { getMonthOptions } from "../../services/utils";
import PrimaryButton from "../elements/primary_button";
import CustomSelect from "../elements/select_fill";
import StatusReport from "./status_report";
import { A_RAPPELER_PLUS_TARD } from "../../services/devis_contrat_status";

const _DEFAULT_STATE = {
  commerciaux: [],
  all_commerciaux: [],
  futureAppts: [],
  prospects: {
    label: PROSPECT,
    reports: null,
  },
  nouveau: {
    label: NOUVEAU,
    reports: null,
  },
  rappeler: {
    label: A_RAPPELER_PLUS_TARD,
    reports: null,
  },
  nouveauRempli: {
    label: NOUVEAU_REMPLI,
    reports: null,
  },
  dossierEnInstruction: {
    label: DOSSIER_EN_INSTRUCTION,
    reports: null,
  },
  preAnalyste: {
    label: EN_ETUDE_DE_PRE_ANALYSTE,
    reports: null,
  },
  analyste: {
    label: EN_ETUDE_DE_ANALYSTE,
    reports: null,
  },
  dossierEnvoyeALaBanque: {
    label: DOSSIER_ENVOYE_A_LA_BANQUE,
    reports: null,
  },
  pretAccordeParLaBanque: {
    label: PRET_ACCORDE_PAR_LA_BANQUE,
    reports: null,
  },
  offreAccepteParClient: {
    label: OFFRE_ACCEPTE_PAR_CLIENT,
    reports: null,
  },
  realise: {
    label: REALISE,
    reports: null,
  },
  facture: {
    label: FACTURE,
    reports: null,
  }
};

const nbr_appel_filter = (filter, deal, suffix) => {
  if (!filter.nbr_appel && filter.nbr_appel !== 0) return true;
  return deal['nbr_appel_' + suffix] === filter.nbr_appel;
}
const filterOnStatus = (allData, status, nb_status, filter) =>
  allData.filter((report) => report.status === status && nbr_appel_filter(filter, report, nb_status));

const buildReports = (_allData, filter) => {
  return ({
    prospects: { label: PROSPECT, reports: filterOnStatus(_allData, PROSPECT, 'prospect', filter) },
    nouveau: { label: NOUVEAU, reports: filterOnStatus(_allData, NOUVEAU, 'nouveau', filter) },
    rappeler: { label: A_RAPPELER_PLUS_TARD, reports: filterOnStatus(_allData, A_RAPPELER_PLUS_TARD, 'nouveau', filter) },
    nouveauRempli: { label: NOUVEAU_REMPLI, reports: filterOnStatus(_allData, NOUVEAU_REMPLI, 'nouveau_rempli', filter) },
    dossierEnInstruction: { label: DOSSIER_EN_INSTRUCTION, reports: filterOnStatus(_allData, DOSSIER_EN_INSTRUCTION, 'dossier_en_instruction', filter) },
    preAnalyste: { label: EN_ETUDE_DE_PRE_ANALYSTE, reports: filterOnStatus(_allData, EN_ETUDE_DE_PRE_ANALYSTE, 'en_etude_de_pre_analyste', filter) },
    analyste: { label: EN_ETUDE_DE_ANALYSTE, reports: filterOnStatus(_allData, EN_ETUDE_DE_ANALYSTE, 'en_etude_de_analyste', filter) },
    dossierEnvoyeALaBanque: { label: DOSSIER_ENVOYE_A_LA_BANQUE, reports: filterOnStatus(_allData, DOSSIER_ENVOYE_A_LA_BANQUE, 'dossier_envoye_a_la_banque', filter) },
    pretAccordeParLaBanque: { label: PRET_ACCORDE_PAR_LA_BANQUE, reports: filterOnStatus(_allData, PRET_ACCORDE_PAR_LA_BANQUE, 'pret_accorde_par_la_banque', filter) },
    offreAccepteParClient: {
      label: OFFRE_ACCEPTE_PAR_CLIENT,
      reports: filterOnStatus(_allData, OFFRE_ACCEPTE_PAR_CLIENT, 'offre_accepte_par_client', filter)
    },
    realise: {
      label: REALISE,
      reports: filterOnStatus(_allData, REALISE, 'realise', filter)
    },
    facture: { label: FACTURE, reports: _allData.filter((report) => report.status === FACTURE, filter) },

  })
}
class Reports extends React.Component {
  constructor(props) {
    super(props);
    const endDate = new Date();
    endDate.setHours(endDate.getHours() + 2, 0, 0, 0);
    this.state = {
      ..._DEFAULT_STATE, filter: {
        status_nin: [ABONDONS, PERDU, ARCHIVE, CONTRAT_CLOTURE, CLOTURE],
        startDate: moment(new Date())
          .subtract(11, "year")
          .startOf("month")
          .toDate(),
        endDate,
        commercial_ids: props.commerciaux && !props.personal ? "All" : undefined,
        all_commerciaux: props.all_commerciaux,
        sub_product: undefined
      },
      sources: [],
      allData: [],
      equipes: [],
      selectedEquipe: undefined,
      selectedCommercial: undefined,
      commerciaux: [],
      preAnalystes: [],
      analystes: [],
    };
  }

  componentDidMount() {
    let without = { name: 'Sans commercial', id: 'EMPTY' };
    getGestionCollabs()
      .then(res => {
        const onlyCommerciaux = res.data.filter(c => isCommercialClassique(c) || hasClassiqueManagerRole(c))
        const preAnalystes = res.data.filter(c => hasPreAnalysteRole(c));
        const analystes = res.data.filter(c => hasAnalysteRole(c));
        const chefAnalystes = res.data.filter(c => hasChefAnalysteRole(c));
        this.setState({
          commerciaux: [without].concat(onlyCommerciaux),
          preAnalystes: [...preAnalystes, ...analystes, ...chefAnalystes],
          analystes: [...analystes, ...chefAnalystes],
        });
        this.loadData();
      });

    getEquipes()
      .then(res => this.setState({ equipes: res.data }))
      .catch(err => console.log(err));
  }

  loadData = async () => {
    const filter = { ...this.state.filter };
    if (filter.all_commerciaux) {
      console.log('yeup all_commerciaux', filter.all_commerciaux);
      delete filter.all_commerciaux;
    }
    serviceCredit
      .getCreditsDashboard(filter).then((res) => {
        const _allData = res.data;
        const sources = _allData.map(d => d.source);
        const reports = buildReports(_allData, this.state.filter);
        this.setState({
          ...reports,
          sources: [...new Set(sources), "(Tous)"].map(v => ({ text: v ? v : '(Vide)', value: v })),
          allData: _allData
        })

      });

  };

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    if (value === "Tous") filter[field] = "";
    this.setState({ filter: Object.assign({}, filter) }, () => this.loadData());
  };

  refreshStateValue = (deal) => {
    const _allData = this.state.allData;
    const index = _allData.map(d => d.id).indexOf(deal.id);
    _allData[index] = deal;
    const reports = buildReports(_allData, this.state.filter);
    this.setState({
      ...reports,
      allData: [..._allData]
    })
  }

  render() {
    return (
      <Box
        style={{ width: "100%" }}
        margin="medium"
        pad="medium"
        gap="medium"
        justify="center"
        alignContent="center"
        alignSelf="center"
        align="center"
      >
        <Card
          width="xxlarge"
          justify="around"
          margin="medium"
          pad="small"
          direction="row"
        >
          <PrimaryButton icon={<Refresh />} reverse
            onClick={() => {
              this.setState(_DEFAULT_STATE);
              this.loadData();
            }
            }
          />
          <Box gap="xsmall">
            <Text>Select d'un mois</Text>
            <CustomSelect
              options={getMonthOptions().filter(
                (s) => s.name !== "12 derniers mois"
              )}
              labelKey={"name"}
              valueKey={{ key: "name", reduce: true }}
              clear
              onChange={({ option }) => {
                let filter = this.state.filter;
                if (option) {
                  filter.startDate = option.startDate;
                  filter.endDate = option.endDate;
                  filter._sort = "createdAt:DESC";
                } else {
                  const endDate = new Date();
                  endDate.setHours(endDate.getHours() + 2, 0, 0, 0);
                  filter.startDate = moment(new Date())
                    .subtract(11, "year")
                    .startOf("month")
                    .toDate();
                  filter.endDate = endDate;
                }
                this.setState(
                  {
                    filter: Object.assign({}, filter)
                  },
                  () => this.loadData()
                );
              }}
            />
          </Box>
          <Box gap="xsmall">
            <Text>Source</Text>
            <CustomSelect
              options={this.state.sources}
              labelKey={"text"}
              valueKey={{ key: "text", reduce: true }}
              onChange={({ option }) => {
                let source = option.value;
                // console.log('source = ', source);
                let allData = this.state.allData.filter(d => d.source === source);
                if (source === '(Tous)') {
                  allData = this.state.allData;
                }
                this.setState(buildReports(allData, this.state.filter));
              }}
            />
          </Box>
          <Box gap="xsmall">
            <Text>Nb Appel</Text>
            <CustomSelect
              options={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
              clear
              onChange={({ option }) => {
                let filter = this.state.filter;
                if (option) {
                  filter.nbr_appel = option;
                } else {
                  delete filter.nbr_appel;
                }
                this.setState(buildReports(this.state.allData, filter));
              }}
            />
          </Box>
          <Box gap="xsmall" >
            <Text >Produit de crédit</Text>
            <CustomSelect
              size="xsmall"
              options={[
                "Crédit Immobilier",
                "Regroupement de crédit",
                "Crédit à la consommation",
                "Prêt hypothécaire",
              ]}
              value={this.state.filter.sub_product}
              onChange={({ option }) => {
                if (option) {
                  let allData = this.state.allData.filter(d => d.sub_product === option);
                  this.setState(buildReports(allData, this.state.filter));
                } else {
                  this.setState(buildReports(this.state.allData, this.state.filter));
                }
              }}
              clear
            />
          </Box>
          <Box gap='xsmall' >
            <Text >Sous produit de crédit</Text>
            <CustomSelect
              size="xsmall"
              options={[
                "Regroupement de crédits",
                "Regroupement de crédits projet"
              ]}
              value={this.state.filter.sub_product_detail}
              onChange={({ option }) => {
                if (option) {
                  let allData = this.state.allData.filter(d => d.sub_product_detail === option);
                  this.setState(buildReports(allData, this.state.filter));
                } else {
                  this.setState(buildReports(this.state.allData, this.state.filter));
                }
              }}
              clear
            />
          </Box>
          {(hasClassiqueManagerRole() || hasAdministrativeRole()) ? (
            <Box gap="xsmall">
              <Text>Equipe</Text>
              <CustomSelect
                options={this.state.equipes}
                value={this.state.selectedEquipe}
                onChange={({ option }) => {
                  this.setState({ selectedEquipe: option?.name, selectedCommercial: undefined });
                  // console.log('option = ', option);
                  if (option === undefined) {
                    this.updateFilter("commercial_ids", "All");
                    return;
                  }
                  let ids = [option?.manager?.id];
                  option.membres.forEach(m => ids.push(m.id));
                  this.updateFilter("commercial_ids", ids)
                }}
                labelKey={'name'}
                valueKey={{ key: 'name', reduce: true }}
                clear
              />
            </Box>
          ) :
            (getUser()?.binome_id ?
              <Box gap="xsmall">
                <Text>Binôme</Text>
                <CustomSelect
                  options={[
                    { id: getUser().id, name: 'Vous' },
                    { id: getUser().binome_id, name: 'Votre binôme' },
                    { id: 'All', name: 'Tous' }
                  ]}
                  onChange={({ value: nextValue }) => {
                    let ids = nextValue === 'All' ? [getUser().id, getUser().binome_id] : [nextValue];
                    this.updateFilter("commercial_ids", ids);
                  }}
                  labelKey={'name'}
                  valueKey={{ key: 'id', reduce: true }}
                  value={
                    this.state.filter.commercial_ids?.length === 2 ? 'All' :
                      this.state.filter.commercial_ids?.length === 1 ? this.state.filter.commercial_ids[0] : undefined
                  }
                />
              </Box> : null
            )}
          {this.state.commerciaux && !this.props.personal && !this.state.selectedEquipe && (
            <Box gap="xsmall">
              <Text>Commercial</Text>
              <CustomSelect
                options={this.state.commerciaux}
                value={this.state.selectedCommercial}
                onChange={({ option }) => {
                  this.setState({ selectedCommercial: option?.name, selectedEquipe: undefined })
                  if (option === undefined) {
                    this.updateFilter("commercial_ids", "All");
                    return;
                  }
                  this.updateFilter("commercial_ids", option.id === 'EMPTY' ? [] : [option.id])
                }}
                labelKey={"name"}
                valueKey={{ key: "name", reduce: true }}
                clear
              />
            </Box>
          )}
        </Card>

        <Box direction="column" gap="medium" justify="center" width={'full'}>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.prospects.reports}
              status={this.state.prospects.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'prospect'}
              refreshReports={() => this.setState({ prospects: { ...this.state.prospects } })}
              refreshStateValue={this.refreshStateValue}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.nouveau.reports}
              status={this.state.nouveau.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'nouveau'}
              refreshReports={() => this.setState({ nouveau: { ...this.state.nouveau } })}
              refreshStateValue={this.refreshStateValue}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.rappeler.reports}
              status={this.state.rappeler.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'nouveau'}
              refreshReports={() => this.setState({ rappeler: { ...this.state.rappeler } })}
              refreshStateValue={this.refreshStateValue}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.nouveauRempli.reports}
              status={this.state.nouveauRempli.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'nouveau_rempli'}
              refreshReports={() => this.setState({ nouveauRempli: { ...this.state.nouveauRempli } })}
              refreshStateValue={this.refreshStateValue}
            />
          </Card>

          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.dossierEnInstruction.reports}
              status={this.state.dossierEnInstruction.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'dossier_en_instruction'}
              refreshReports={() => this.setState({ dossierEnInstruction: { ...this.state.dossierEnInstruction } })}
              refreshStateValue={this.refreshStateValue}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.preAnalyste.reports}
              status={this.state.preAnalyste.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'en_etude_de_pre_analyste'}
              administratives={this.state.preAnalystes}
              refreshReports={() => this.setState({ preAnalyste: { ...this.state.preAnalyste } })}
              refreshStateValue={this.refreshStateValue}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.analyste.reports}
              status={this.state.analyste.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'en_etude_de_analyste'}
              administratives={this.state.preAnalystes}
              refreshReports={() => this.setState({ analyste: { ...this.state.analyste } })}
              refreshStateValue={this.refreshStateValue}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.dossierEnvoyeALaBanque.reports}
              status={this.state.dossierEnvoyeALaBanque.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'dossier_envoye_a_la_banque'}
              refreshReports={() => this.setState({ dossierEnvoyeALaBanque: { ...this.state.dossierEnvoyeALaBanque } })}
              refreshStateValue={this.refreshStateValue}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.pretAccordeParLaBanque.reports}
              status={this.state.pretAccordeParLaBanque.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'pret_accorde_par_la_banque'}
              refreshReports={() => this.setState({ pretAccordeParLaBanque: { ...this.state.pretAccordeParLaBanque } })}
              refreshStateValue={this.refreshStateValue}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.offreAccepteParClient.reports}
              status={this.state.offreAccepteParClient.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'offre_accepte_par_client'}
              refreshReports={() => this.setState({ offreAccepteParClient: { ...this.state.offreAccepteParClient } })}
              refreshStateValue={this.refreshStateValue}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.realise.reports}
              status={this.state.realise.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'realise'}
              refreshReports={() => this.setState({ realise: { ...this.state.realise } })}
              refreshStateValue={this.refreshStateValue}
            />
          </Card>
          <Card pad="small" gap="medium" direction="row" background="#eee">
            <StatusReport showMessage={this.props.showMessage}
              reports={this.state.facture.reports}
              status={this.state.facture.label}
              reloadAll={this.loadData}
              commerciaux={this.state.commerciaux}
              all_commerciaux={this.props.all_commerciaux}
              nbr_appel={'facture'}
              refreshReports={() => this.setState({ facture: { ...this.state.facture } })}
              refreshStateValue={this.refreshStateValue}
            />
          </Card>
        </Box>
      </Box>
    );
  }
}

export default Reports;

const axios = require('axios');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const estimateDevis = devis => {
    return axios.post(`${baseAPI}/public/trusti-mh-tempo/price`, devis, { headers: HEADERS });
}

const getTrustiMHTempos = (client_id, _query, status) => {
    let commercial_id = storageService.getCommercialId();
    let params = { commercial_id, _q: _query, status, _limit: -1 };
    let binome_id = storageService.getBinomeId();
    if(binome_id) {
        delete params.commercial_id;
        params['_where[0][commercial_id_in]'] = [commercial_id, binome_id];
    }
    if(storageService.hasAdministrativeRole()) {
        delete params.commercial_id;
        delete params['_where[0][commercial_id_in]']
    }
    if(client_id) {
        params['_where[1][client.id]']=client_id;
        delete params.commercial_id;
    }

    return axios.get(`${baseAPI}/public/trusti-mh-tempo`, { params, headers: HEADERS });
}

const updateDevis = devis => {
    return axios.put(`${baseAPI}/public/trusti-mh-tempo/${devis.id}`, devis, { headers: HEADERS });
}

const createDevis = devis => {
    devis.commercial_id = storageService.getCommercialId();
    return axios.post(`${baseAPI}/public/trusti-mh-tempo`, devis, { headers: HEADERS });
}

const deleteDevis = devis => {
    return axios.delete(`${baseAPI}/public/trusti-mh-tempo${devis.id}`, devis, { headers: HEADERS });
}

const sendForm = devis => {
    return axios.post(`${baseAPI}/commerciale/trusti-mh-tempo/send`, devis, { headers: HEADERS });
}

module.exports = {
    getTrustiMHTempos, 
    estimateDevis, 
    updateDevis,
    createDevis,
    deleteDevis,
    sendForm
}
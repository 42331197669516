import {
    Box, Card, Heading, Text
} from "grommet";
import React from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { getCommissionTauxConfig } from "../../../services/dashboard";
import AddDevis from "../../elements/add_devis";
import CustomPagination from "../../elements/custom_pagination";
import CustomSearch from "../../elements/custom_search";
import Refresh from "../../elements/refresh";
import StandardText from "../../elements/standard_text";
import { ViewTableWithoutPagination } from "../../elements/view_table";
import Prevoyance from "./prevoyance";
import PrevoyanceParticuliers from "./prevoyance_particuliers";
import { countProPREVs, getPrevDeals } from "./services";

const TAB = {
    pro: 'pro',
    particulier: 'particulier'
}
class Prevoyances extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            total: 0,
            startIndex: 0,
            filter: {
                query: '',
                status: undefined,
                sort: undefined,
                entreprise_null: false,
                client_null: true
            },
            proDeals: [],
            particulierDeals: [],
            users: this.props.commerciaux || [],
            selectedDeal: undefined,
            popupOpen: false,
            currentTab: TAB.pro,
            commission_taux: {}

        }
    }

    componentDidMount = () => {
        this.searchDeals();
        if (!this.props.commission_taux) {
            getCommissionTauxConfig()
                .then(res => {
                    this.setState({ commission_taux: res.data });
                })
        }
    }

    refresh = () => this.searchDeals();

    searchDeals = () => {
        getPrevDeals(this.state.filter)
            .then(res => this.setState({ proDeals: res.data }))
        countProPREVs(this.state.filter)
            .then(res => this.setState({ total: res.data }));
    }

    onNextPage = ({ startIndex }) => {
        let filter = this.state.filter;
        filter.start = startIndex
        this.setState({ filter: Object.assign({}, filter) }, () => this.searchDeals());
    }

    onSort = optionSort => {
        let filter = this.state.filter;
        filter.sort = `${optionSort.property}:${optionSort.direction}`;
        this.setState({ filter: Object.assign({}, filter) }, this.searchDeals);
    }

    open = (deal) => {
        this.setState({ selectedDeal: deal, popupOpen: true })
    }

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter: Object.assign({}, filter) });
    }

    changeTab = (val) => {
        if (val === this.state.currentTab) return;
        this.setState({ currentTab: val });
        const filter = this.state.filter;
        switch (val) {
            case TAB.pro:
                filter.client_null = true;
                filter.entreprise_null = false;
                break;
            case TAB.particulier:
                filter.client_null = false;
                filter.entreprise_null = true;
                break;
        }
        this.setState({ filter }, () => this.searchDeals());
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box style={{ width: '100%' }}>
                    <Box width={'full'} style={{ position: 'absolute', left: 50 }}  gap="small" direction={"row"}>
                        <Box direction="row">
                            <Card round={{ corner: "left", size: 'medium' }}
                                style={{ height: 40 }} pad='small'
                                onClick={() => this.changeTab(TAB.pro)}
                                background={this.state.currentTab === TAB.pro ? '#70A4EC' : '#fff'}
                                justify='center'
                            >
                                <Text size="small" color={this.state.currentTab === TAB.pro ? '#fff' : '#707070'}>{this.state.currentTab === TAB.pro ? <b>Professionels</b> : "Professionels"}</Text>
                            </Card>
                            <Card round={{ corner: "right", size: 'medium' }}
                                style={{ height: 40 }} pad='small'
                                onClick={() => this.changeTab(TAB.particulier)}
                                background={this.state.currentTab === TAB.particulier ? '#70A4EC' : '#fff'}
                                justify='center'
                            >
                                <Text size="small" color={this.state.currentTab === TAB.particulier ? '#fff' : '#707070'}>{this.state.currentTab === TAB.particulier ? <b>Particuliers</b> : "Particuliers"}</Text>
                            </Card>
                        </Box>
                    </Box>
                </Box>

                <Box
                    style={{ width: "100%" }}
                    justify="center"
                    align="center"
                >

                    <Box
                        width={'xxlarge'}
                        justify="center"
                        align="center"
                        pad={'medium'}
                    >

                        {
                            this.state.popupOpen ?
                                (this.state.currentTab === TAB.pro ?
                                    <Prevoyance
                                        deal={this.state.selectedDeal}
                                        onRefresh={this.searchDeals}
                                        showMessage={this.props.showMessage}
                                        onClose={() => this.setState({ popupOpen: false })}
                                    /> :
                                    <PrevoyanceParticuliers
                                        deal={this.state.selectedDeal}
                                        onRefresh={this.searchDeals}
                                        showMessage={this.props.showMessage}
                                        onClose={() => this.setState({ popupOpen: false })}
                                    />) :
                                <Box
                                    width={'xxlarge'}
                                    justify="center"
                                    alignContent="center"
                                    align="center"
                                    pad={'medium'}
                                >
                                    <Box direction='row' justify="between" alignSelf="center" align="center" gap="medium" width={'full'} pad='small'>
                                        <Refresh refresh={this.refresh} />
                                        <Box width={'medium'}>
                                            <CustomSearch
                                                query={this.state.filter.query}
                                                onQueryChange={query => this.updateFilter('query', query)}
                                                onSearch={this.searchDeals}
                                                status={this.state.filter.status}
                                                onStatusChange={status => {
                                                    let filter = this.state.filter;
                                                    filter.status = status;
                                                    this.setState({ filter: Object.assign({}, filter) }, this.searchDeals);
                                                }}
                                            />
                                        </Box>
                                        <AddDevis onAdd={() => this.setState({ selectedDeal: {}, popupOpen: true })} />
                                    </Box>
                                    <Box style={{ width: "100%" }}>
                                        <CustomPagination total={this.state.total} onNextPage={this.onNextPage} startIndex={this.state.startIndex} />
                                    </Box>
                                    <ViewTableWithoutPagination
                                        reports={this.state.proDeals}
                                        commission_taux={this.props.commission_taux}
                                        onDevisSelected={this.open}
                                        commerciaux={this.props.commerciaux}
                                    />
                                </Box>
                        }

                    </Box>
                </Box>
            </BlockUi>
        )
    }
}

export default Prevoyances;
const axios = require('axios');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const getGraphData = (filter) => {
    return axios.get(`${baseAPI}/v1/commercials/voyages/report`, {params: filter, headers: HEADERS });
}

module.exports = {
    getGraphData
}
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";
import {
  CONTRAT_CLOTURE,
  CONTRAT_ENVOYE,
  DEAL_POSSIBLE,
  DEAL_POSSIBLE_REMPLI,
  DEVIS,
  DEVIS_VALIDE,
  EN_ATTENTE_DE_FORMALITE_MEDICALE,
  EN_ATTENTE_DE_RESILIATION,
  PROSPECT,
} from "../../../../services/devis_contrat_status";
import { getSimpleDateFR, getSimpleTimeFR } from "../../../../services/utils";
import BlockUi from "react-block-ui";
import { getAppelsByStatus } from "../../../../services/appointment_service";
import PrimaryButton from "../../../elements/primary_button";
import { Refresh } from "grommet-icons";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { ContractSimpliedDetails } from "../../../elements/contract_details";

const InfoLine = ({ label, value, color }) => {
  let style = color ? { border: '1px solid #000', borderRadius: 5, color } : {}

  return (<Text style={style} size="xsmall" >{label} : <strong>{value}</strong></Text>)
}

const Accountability = ({ devis }) => {
  return (
    <Box width="100%">
      <Grid
        columns={["1/2", "1/2"]} gap="small" width="100%"
      >
        <Box>
          <Box width="100%" background="brand" pad="5px 20px" style={{borderRadius: "8px"}}>
            <Text color="white" weight={600}>Trustiway</Text>
          </Box>
          <ContractSimpliedDetails devis={devis} noborder />
        </Box>
        <Box>
          <Box width="100%" background="brand" pad="5px 20px" style={{borderRadius: "8px"}}>
            <Text color="white" weight={600}>Client</Text>
          </Box>
          <Box pad="5px">
            <InfoLine label="Premier versement" value={devis.first_payment + " €"} />
            <InfoLine label="Versement mensuel" value={devis.monthly_payment + " €"} />
            <InfoLine label="Date d'effet" value={new Date(devis.date_effet).toLocaleDateString()} />
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}

export default Accountability;

import { Box, Select, Text } from "grommet";
import React, { useState } from "react";
import { findApporteurs } from "../../../services/apporteur_service";

const renderApporteur = (s) =>
    s ? `${s.prenom || ""} ${s.nom || ""} (${s.email
        })`
        : "";

const initApporteurs = (apporteur) => {
    if (!apporteur || !apporteur.hubspot_apporteur_id) return [];
    apporteur.description = `${apporteur.prenom || ""} ${apporteur.nom || ""} (${apporteur.email
        })`;
    return [apporteur];
};

const ApporteurSelect = ({ onDealUpdate, apporteur, size = 'small', isUpperCase=true }) => {

    let timeoutId;

    const [apporteurs, setApporteurs] = useState(initApporteurs(apporteur));
    const [apporteurDesc, setApporteurDesc] = useState(
        apporteur ? apporteur.prenom +
        " " +
        apporteur.nom +
        " " +
        `(${apporteur.email || ""})` : ""
    );

    const searchApporteur = (query) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            findApporteurs(query)
                .then((res) => {
                    setApporteurs(
                        res.data.map((s) => {
                            s.description = renderApporteur(s);
                            return s;
                        })
                    );
                })
                .catch((err) => console.log(err));
        }, 500);
    };

    const onApporteurSelect = (text) => {
        setApporteurDesc(text);
        let value = apporteurs.find((s) => renderApporteur(s) === text);
        onDealUpdate('apporteur', value);
    }

    return (
        <Box width="large" gap="medium">
            <Box gap="xsmall">
                <Line
                    size={size}
                    isUpperCase={isUpperCase}
                    leftLabel="Apporteur"
                    rightChildren={
                        <Select
                            options={apporteurs}
                            size={size}
                            value={apporteurDesc}
                            labelKey={"description"}
                            valueKey={{ key: "description", reduce: true }}
                            onChange={({ value: nextValue }) =>
                                onApporteurSelect(nextValue)
                            }
                            onSearch={(text) => {
                                const escapedText = text.replace(
                                    /[-\\^$*+?.()|[\]{}]/g,
                                    "\\$&"
                                );
                                searchApporteur(escapedText);
                            }}
                        />
                    }
                />
            </Box>
        </Box>
    )
}

const Line = ({ leftLabel, rightChildren, widthLeft, widthRight, size, isUpperCase }) => (
    <Box direction='row' gap="medium" style={{ width: '100%' }} align="center" alignSelf="center">
        <Box style={{ width: widthLeft || '40%' }} >
            {leftLabel && <CustomText isUpperCase={isUpperCase}
                size={size} label={leftLabel + " :"} />}
        </Box>
        <Box style={{ width: widthRight || '60%' }} >
            {rightChildren}
        </Box>
    </Box>
)
const CustomText = ({ label, size, isUpperCase }) => (
    <Text style={{ fontWeight: 'bold', textTransform: isUpperCase ? 'uppercase':'none' }}
        size={size || 'small'} textAlign="end" >{label}
    </Text>
)
export default ApporteurSelect;
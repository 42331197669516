import {
    Box,
    Card, Image, Text
} from "grommet";
import React from "react";
import cyberrisque_img from '../../images/menu/cyberrisque.png';
import immeuble_img from '../../images/menu/habitation.png';
import mrp_img from '../../images/menu/mrp.png';
import pnopro_img from '../../images/menu/pno.png';
import prev_img from '../../images/menu/prev.png';
import rcpro_img from '../../images/menu/rcpro.png';
import santepro_img from '../../images/menu/santepro.png';
import { getCommissionTauxConfig } from "../../services/dashboard";
import DomageOuvrages from "../professionels/domagne_ouvrages";
import MultirisquePros from "../professionels/multirisque_pros";
import Prevoyances from "../professionels/prevoyances";
import ResponsabiliteCiviles from "../professionels/responsabilite_civiles";
import ProSantes from "../professionels/pro_santes";
import CyberRisques from "../professionels/cyber_risques";
import ProImmeubles from "../professionels/immeubles";
import ProPNOs from "../professionels/pro_pnos";

const TAB = {
    enligne: 'enligne',
    horsligne: 'horsligne'
}

const MENU = {
    online: {

    },
    offline: {
        mrp: 'mrp',
        rc_pro: 'rc_pro',
        do: 'do',
        prev: 'prev',
        sante_pro: 'sante_pro',
        cyber_r: 'cr',
        immeuble: 'immeuble',
        pnopro: 'pnopro',
    }
}

class VenteProfessionels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: props.contact || {},
            commission_taux: undefined,
            tab: TAB.horsligne,
            currentProd: MENU.offline.mrp,
        };
    }
    componentDidMount() {
        getCommissionTauxConfig()
            .then(res => {
                this.setState({ commission_taux: res.data });
            })
    }

    isActive = tab => this.state.tab === tab;

    getContent = () => {
        if(this.props.tab === TAB.enligne) return null;
        switch (this.state.currentProd) {
            case MENU.offline.mrp:
                return (
                    <MultirisquePros
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.rc_pro:
                return (
                    <ResponsabiliteCiviles
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.sante_pro:
                return (
                    <ProSantes
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.cyber_r:
                return (
                    <CyberRisques
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.immeuble:
                return (
                    <ProImmeubles
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.pnopro:
                return (
                    <ProPNOs
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            default:
                return <Box/>;
        }
    }

    to = prod => this.setState({ currentProd: prod });

    toTab = tab => {
        if (tab === this.state.tab) return;
        let defaultProd;
        if (tab === TAB.enligne) defaultProd = MENU.online;
        if (tab === TAB.horsligne) defaultProd = MENU.offline.mrp;
        this.setState({ tab, currentProd: defaultProd })
    }

    render() {
        return (
            <Box width={'full'} justify="center" align="center">
                <Box width={'full'} style={{ height: 40 }} justify="center" align="center" background={'#F8F8F8'}>
                    {this.props.tab === TAB.enligne &&
                        <Box width={'full'} style={{ height: 40 }} justify="center" align="center" direction="row" gap="large">
                            <SubItem
                                onClick={() => this.to(MENU.online)}
                                label='Aucun produit disponible'
                                isActive={this.state.currentProd === MENU.online}
                            />

                        </Box>
                    }
                    {this.props.tab === TAB.horsligne &&
                        <Box width={'full'} style={{ height: 40 }} justify="center" align="center" direction="row" gap="large">
                            <SubItem
                                onClick={() => this.to(MENU.offline.mrp)}
                                label='Trusti MRP'
                                isActive={this.state.currentProd === MENU.offline.mrp}
                                icon={mrp_img}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.rc_pro)}
                                label='Trusti RC'
                                isActive={this.state.currentProd === MENU.offline.rc_pro}
                                icon={rcpro_img}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.sante_pro)}
                                label='Trusti Santé PRO'
                                isActive={this.state.currentProd === MENU.offline.sante_pro}
                                icon={santepro_img}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.cyber_r)}
                                label='Trusti Cyber Risque'
                                isActive={this.state.currentProd === MENU.offline.cyber_r}
                                icon={cyberrisque_img}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.immeuble)}
                                label='Trusti Immeuble pro'
                                isActive={this.state.currentProd === MENU.offline.immeuble}
                                icon={immeuble_img}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.pnopro)}
                                label='Trusti PNO pro'
                                isActive={this.state.currentProd === MENU.offline.pnopro}
                                icon={pnopro_img}
                            />

                        </Box>
                    }
                </Box>
                {this.getContent()}
            </Box>
        )
    }
}

const SubItem = ({ label, onClick, isActive, icon }) => (
    <Box onClick={onClick} style={{ boxShadow: 'none' }} direction='row' gap="xsmall">
        {icon && <Image src={icon} />}
        <Text textAlign="center" size="small" color={'#707070'} style={{ fontWeight: isActive ? 'bold' : 'normal' }}>
            {label.split('::').map(s => <span>{s}<br /></span>)}
        </Text>
    </Box>
)

export default VenteProfessionels;
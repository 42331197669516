import { Box, Text, Heading, Button, FileInput, Card } from "grommet";
import React, { useState } from "react";
import CustomInput from "../../../elements/custom_input";
import ContactSelect, { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import CustomSelect from "../../../elements/select_fill";
import { uploadFile } from "../../../../services/storage_service";
import { DocumentDownload, Trash } from "grommet-icons";
import ClientInfo from "./client_information";
import LocataireInfo from "./locataire_infomation";


const Info = ({ devis, updateDevis, toRecap, showMessage }) => {


  const [isLoading, setIsLoading] = useState(false);
  if (!devis.effectiveDate) {
    devis.effectiveDate = new Date();
  }
  return (
    <Box
      gap="small"
      style={{ width: "100%" }}
      alignSelf="center"
      align="center"
      justify="center"
    >
      <Heading level="2" color="#59A4F4" textAlign="center">
        Trusti GLI
      </Heading>
      <ContactSelectV2
        contact={devis?.client || {}}
        updateContact={updateDevis}
        addressIncluded={true}
      />
      <LocataireInfo
        onDevisChange={updateDevis}
        locataires={devis?.locataires || []}
        showMessage={showMessage}
        devis={devis}
      />
    </Box>
  );
};

export default Info;

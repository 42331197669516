import React, { useEffect, useState } from "react";
import {
	Box,
	Card,
	Spinner,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
	Text,
} from "grommet";
import { getClientName } from "../../outils/client_util";
import { getSimpleDateTimeFR } from "../../../services/utils";
import { getBlocNotes } from "./service/bloc_note";
import NoteModal from "./Components/NoteModal";
import { getContextBot } from "../../../services/storage_service";

const TrustiBotNote = ({ onOpenedDialog }) => {
	const [loading, setLoading] = useState(false);
	const [listNotes, setListNotes] = useState([]);
	const [modeRAC, setModeRAC] = useState(false);

	const loadData = () => {
		const _contextBot = getContextBot();
		let client = null;
		var contract_id = null;
		var commercial = null;
		if (_contextBot) {
			if (_contextBot.client) {
				client = _contextBot.client.id;
			}
			if (_contextBot.id) {
				contract_id = _contextBot.id;
			}
			if (_contextBot.selectedCommercial) {
				commercial = _contextBot.selectedCommercial;
			}
			if (_contextBot.sub_product === "Regroupement de crédit") {
				setModeRAC(true);
			}
		}
		setLoading(true);
		try {
			getBlocNotes(client, contract_id, commercial).then((res) => {
				setListNotes(res.data);
				setLoading(false);
			}).catch(e => {
				console.log("error = ", e);
			});
		} catch (error) {
			console.log("error", error);
			setLoading(false);
		}
	};

	useEffect(() => {
		loadData();
		// eslint-disable-next-line
	}, []);

	return (
		<Box style={{ minHeight: 320 }} margin="0 0 50px 0">
			<Box direction="row" justify="between" background="brand" style={{ borderRadius: "15px 15px 0 0" }}>
				<Text margin={"small"} size="medium">
					<b>Bloc Notes</b>
				</Text>
				<Box direction="row" justify="center" margin="small">
					{modeRAC && <NoteModal
						onOpenedDialog={onOpenedDialog}
						callback={loadData} icon
						label={<Text weight={400}>Ajouter une <b>note RAC</b></Text>}
						noteData={{
							note: `Contact téléphonique : 

Disponibilité pour être rappelé : 

Explications des prêts (ex : Cofidis pour achat voiture, RAC suite séparation, etc) : 

Autre courtier (oui/non, si oui, combien ? quand ?) : 

Explications RC (ex : versement en espèce, chèque sup à 500 €, virements récurrent, etc) : 

Incident (ex : "ATD sur BS", "ATD sur RC" mais correspond à une amende") : 

Besoin tréso (montant et explication) : 

Durée souhaité : 

Autres commentaire (ex : ne souhaite pas racheté la LOA, etc) : 
`
						}}
					></NoteModal>}
					<NoteModal
						onOpenedDialog={onOpenedDialog}
						callback={loadData} icon
						label={<Text weight={400}>Ajouter une <b>note</b></Text>}
					></NoteModal>
				</Box>
			</Box>
			<Table>
				<TableHeader style={{ backgroundColor: "#F5F5F5", borderRadius: "0 0 10px 10px" }}>
					<TableRow>
						<TableCell width={"60px"}></TableCell>
						<TableCell width={"220px"}>
							<Text size="small">
								Commercial
							</Text>
						</TableCell>
						<TableCell width={"220px"}>
							<Text size="small">
								Client
							</Text>
						</TableCell>
						<TableCell width={"200px"}>
							<Text size="small">
								Produit
							</Text>
						</TableCell>
						<TableCell width={"140px"}>
							<Text size="small">
								Création
							</Text>
						</TableCell>
						<TableCell width={"140px"}>
							<Text size="small">
								Modification
							</Text>
						</TableCell>
					</TableRow>
				</TableHeader>
			</Table>
			<Box style={{ overflowY: "scroll", maxHeight: 250 }}>
				{loading ? (
					<Spinner />
				) : (
					<Table>
						<TableBody>
							{listNotes.map((_note, index) => {
								let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
								return (
									<TableRow
										key={index + 1}
										style={{ backgroundColor: _bgColor }}
									>
										<TableCell width={"60px"}>
											<NoteModal
												label="Voir"
												noteData={_note}
												callback={loadData}
												onOpenedDialog={onOpenedDialog}
											></NoteModal>
										</TableCell>
										<TableCell width={"200px"}>
											<Text size="small">
												{`${_note.commercial?.nom || ''} ${_note.commercial?.prenom || ''}`}
											</Text>
										</TableCell>
										<TableCell width={"220px"}>
											<Text size="small">{getClientName(_note.client)}</Text>
										</TableCell>
										<TableCell width={"200px"}>
											<Text size="small">
												<b>{_note.contract_name}</b>
												<br />
												<i>{_note.contract_id}</i>
											</Text>
										</TableCell>
										<TableCell width={"140px"}>
											<Text size="small">
												{getSimpleDateTimeFR(_note.createdAt)}
											</Text>
										</TableCell>
										<TableCell width={"140px"}>
											<Text size="small">
												{getSimpleDateTimeFR(_note.updatedAt)}
											</Text>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				)}
			</Box>
		</Box>
	);
};

export default TrustiBotNote;

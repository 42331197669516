import { Box, Text, Heading, TextInput, Card, Layer, Button } from "grommet";
import React, { useEffect, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import ToggleButton from "../../elements/toggle_button/toggle_button";
import CustomInput from "../../elements/custom_input";
import DateInput from "../../elements/date_input";
import CustomSelect from "../../elements/select_fill";
import PrimaryButton from "../../elements/primary_button";
import { Checkmark, Close, Trash, Validate } from "grommet-icons";
import { CONTACT_TYPE } from "../../../services/types";
import { changeCommercialOwner, getAssociatedEntreprises } from "../../../services/contact_service";
import StandardText from "../../elements/standard_text";
import Entreprise from "../entreprises/entreprise";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

const lead_status = [
  { key: "NEW", value: "Nouveau" },
  { key: "OPEN", value: "Ouvert" },
  { key: "IN_PROGRESS", value: "En cours" },
  { key: "OPEN_DEAL", value: "Transaction ouverte" },
  { key: "UNQUALIFIED", value: "Non qualifié" },
  { key: "ATTEMPTED_TO_CONTACT", value: "Tentative de contact" },
  { key: "CONNECTED", value: "Connecté" },
  { key: "BAD_TIMING", value: "Mauvais timing" },
];

const findLeadStatusKey = (status) => {
  let lead = lead_status.find(
    (k) => k.value.toUpperCase() === (status || "").toUpperCase()
  );
  return lead ? lead.key : "";
};

const findLeadStatusValue = (status) => {
  if (!status) return null;
  let lead = lead_status.find(
    (k) => k.key.toUpperCase() === (status || "").toUpperCase()
  );
  return lead ? lead.value : "";
};

const CustomText = ({ label }) => (
  <Text style={{ fontWeight: "bold" }} size="small" textAlign="end">
    {label}
  </Text>
);

const Line = ({ leftLabel, rightChildren }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "30%" }}>
      <CustomText label={leftLabel + " :"} />
    </Box>
    <Box style={{ width: "70%" }}>{rightChildren}</Box>
  </Box>
);
const CIVILITY = {
  madame: "Madame",
  monsieur: "Monsieur",
  societe: "Société",
};
const ClientInfo = ({
  contact,
  updateContact,
  submitModification,
  onClose,
  deleteContact,
  commerciaux = [],
  showMessage = () => { },
  onRefresh = () => { },
  creationMode = false
}) => {
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyBa3n60yKzDhlgz67vh9YUTwHSlrRzaa4g",
    onPlaceSelected: (place) => {
      let parts = place.address_components;
      let address = parts
        .filter(
          (s) =>
            s.types &&
            (s.types.includes("street_number") || s.types.includes("route"))
        )
        .map((s) => s.long_name)
        .join(" ");
      let postal_code = parts
        .filter((s) => s.types && s.types.includes("postal_code"))
        .map((s) => s.long_name)
        .join("");
      let city = parts
        .filter((s) => s.types && s.types.includes("locality"))
        .map((s) => s.long_name)
        .join("");
      updateContact("address", address);
      updateContact("zip", postal_code);
      updateContact("city", city);
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "fr" },
    },
  });

  const [newCommercialId, setNewCommercialId] = useState(contact?.commercial_id);
  const [loading, setLoading] = useState(false);

  const changeOwner = () => {
    if (newCommercialId === contact.commercial_id) {
      showMessage('Rien à changer', 'warning');
      return;
    }
    setLoading(true);
    changeCommercialOwner(newCommercialId, contact.id)
      .then(res => {
        contact.commercial_id = newCommercialId;
        showMessage(`Il y a ${res.data.total} deals qui sont remplacés par le nouveau commercial`, 'success');
        onRefresh();
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        showMessage('Erreur inattendu', 'error');
        setLoading(false);
      })
  }

  return (
    <Box
      gap="small"
      style={{ width: "100%" }}
      alignSelf="center"
      align="center"
      justify="center"
    >
      <Heading level="2" color="#59A4F4" textAlign="center">
        Information client
      </Heading>
      {!creationMode &&
        <EntpriseAssocie interlocuteur={contact} />}


      <BlockUi tag="div" blocking={loading}>
        <Card width={'full'} pad='medium'>
          <Box direction="row" gap="medium" justify="between">
            <Box >
              <CustomText label={"Propriétaire du contact"} />
            </Box>
            <Box width={'full'}>
              <CustomSelect
                placeholder={'Commercial'}
                options={commerciaux}
                onChange={({ option }) => setNewCommercialId(option.id)}
                labelKey={'name'}
                valueKey={{ key: 'name', reduce: true }}
                value={commerciaux.find(c => c.id === (newCommercialId || contact.id))?.name}
              />
            </Box>
            <PrimaryButton label={'Valider'} icon={<Checkmark />} onClick={changeOwner} />
          </Box>
        </Card>
      </BlockUi>
      <Box
        // width="large"
        gap="medium"
        alignSelf="center"
        align="center"
        pad="medium"
        justify="center"
        overflow={'auto'}
        style={{minHeight: 'auto'}}
      >
        <Box style={{ width: "100%", minHeight: 'auto' }} gap="xsmall" >

          <Line
            leftLabel="Type de contact *"
            rightChildren={
              <ToggleButton
                groups={Object.keys(CONTACT_TYPE).map(key => CONTACT_TYPE[key])
                  .map(value => ({
                    label: value === 'PRO' ? 'Professionnel' : 'Particulier',
                    selected: contact.contact_type === value,
                    onClick: () => updateContact("contact_type", value),
                  }))}
              />
            }
          />
          <Line
            leftLabel="Civilité *"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: CIVILITY.madame,
                    selected: contact.civilite === CIVILITY.madame,
                    onClick: () => updateContact("civilite", CIVILITY.madame),
                  },
                  {
                    label: CIVILITY.monsieur,
                    selected: contact.civilite === CIVILITY.monsieur,
                    onClick: () => updateContact("civilite", CIVILITY.monsieur),
                  },
                ]}
              />
            }
          />
          {contact.civilite !== CIVILITY.societe && (
            <Box style={{ width: "100%" }} gap="xsmall">
              <Line
                leftLabel="Nom *"
                rightChildren={
                  <CustomInput
                    value={contact.lastname}
                    onChange={(event) =>
                      updateContact("lastname", event.target.value)
                    }
                  />
                }
              />
              <Line
                leftLabel="Prénom *"
                rightChildren={
                  <CustomInput
                    value={contact.firstname}
                    onChange={(event) =>
                      updateContact("firstname", event.target.value)
                    }
                  />
                }
              />
            </Box>
          )}
          {contact.civilite === CIVILITY.societe && (
            <Line
              leftLabel="Raison social"
              rightChildren={
                <CustomInput
                  value={contact.raisonSocial}
                  onChange={(event) =>
                    updateContact("raisonSocial", event.target.value)
                  }
                />
              }
            />
          )}

          <Line
            leftLabel="Date de naissance *"
            rightChildren={
              <DateInput
                value={contact.date_of_birth}
                onChange={(date) => updateContact("date_of_birth", date)}
              />
            }
          />

          <Line
            leftLabel="Téléphone"
            rightChildren={
              <CustomInput
                value={contact.phone}
                onChange={(event) => updateContact("phone", event.target.value)}
              />
            }
          />
          <Line
            leftLabel="Email *"
            rightChildren={
              <CustomInput
                value={contact.email}
                onChange={(event) => updateContact("email", event.target.value)}
              />
            }
          />
          <Line
            leftLabel="Adresse principale *"
            rightChildren={
              <TextInput
                size={"small"}
                ref={ref}
                style={{
                  background: "#EDEDED",
                  fontSize: 16,
                  borderRadius: 10,
                  border: "none",
                }}
                value={contact.address}
                onChange={(event) =>
                  updateContact("address", event.target.value)
                }
              />
            }
          />
          <Line
            leftLabel="Code postal *"
            rightChildren={
              <CustomInput
                value={contact.zip}
                onChange={(event) => updateContact("zip", event.target.value)}
              />
            }
          />
          <Line
            leftLabel="Ville *"
            rightChildren={
              <CustomInput
                value={contact.city}
                onChange={(event) => updateContact("city", event.target.value)}
              />
            }
          />
          <Line
            leftLabel="Indeice de confiance sur Trustiway"
            rightChildren={
              <CustomSelect
                options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                value={contact.indice_de_confiance_sur_trustiway}
                onChange={({ option }) =>
                  updateContact("indice_de_confiance_sur_trustiway", option)
                }
              />
            }
          />
          <Line
            leftLabel="Indice de confiance sur le seller"
            rightChildren={
              <CustomSelect
                options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                value={contact.indice_de_confiance_sur_le_seller}
                onChange={({ option }) =>
                  updateContact("indice_de_confiance_sur_le_seller", option)
                }
              />
            }
          />
          <Line
            leftLabel="Statut du lead"
            rightChildren={
              <CustomSelect
                options={lead_status.map((l) => l.value)}
                value={findLeadStatusValue(contact.hs_lead_status)}
                onChange={({ option }) =>
                  updateContact("hs_lead_status", findLeadStatusKey(option))
                }
              />
            }
          />
          <Line
            leftLabel="Ne pas envoyer email"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: '❌ Ne pas envoyer email',
                    selected: contact.do_not_emailing === true,
                    onClick: () => updateContact("do_not_emailing", true),
                  },
                  {
                    label: '✅ Email OK',
                    selected: contact.do_not_emailing !== true,
                    onClick: () => updateContact("do_not_emailing", false),
                  },
                ]}
              />
            }
          />
        </Box>
      </Box>
      <Box direction="row" align="center" justify="center" gap="medium">
        <PrimaryButton
          label={`Valider`}
          icon={<Checkmark />}
          background="#59A4F4"
          onClick={() => submitModification(contact)}
        />
        {contact.id && (
          <PrimaryButton
            icon={<Trash />}
            onClick={() => deleteContact(contact)}
            label="Supprimer"
          />
        )}
        <PrimaryButton
          label={`Fermer`}
          icon={<Close />}
          background={"#6c757d"}
          onClick={onClose}
        />
      </Box>

      <Box style={{ height: 30 }} />
    </Box>
  );
};

const EntpriseAssocie = ({ interlocuteur }) => {

  const [entreprises, setEntreprises] = useState([]);
  const [selected, setSelected] = useState(false);

  const onClose = () => setSelected(false);

  useEffect(() => {
    if (interlocuteur) {
      getAssociatedEntreprises(interlocuteur?.id)
        .then(res => setEntreprises(res.data));
    }
  }, interlocuteur)

  return (
    !selected ?
      (<Box style={{ position: 'absolute', right: 50, top: 150 }} gap='small'>
        <StandardText label={`Entreprises (${entreprises.length})`} size='small' />
        {entreprises.map((en, index) => (
          <Card key={index} pad='small' onClick={() => setSelected(en)}>
            <StandardText label={en.raison_social} style={{ color: '#59A4F4', fontWeight: 'bold' }} size='small' />
            <StandardText label={en.type_societe} size='xsmall' />
            <StandardText label={en.email} size='xsmall' />
            <StandardText label={`${en.address}, ${en.zip}, ${en.city}`} size='xsmall' />
          </Card>
        ))}
      </Box>) :
      (<Box width={'full'}>
        <Layer
          full='vertical'
          onEsc={onClose} style={{ width: '90%' }}
        >
          <Box fill>
            <Box flex overflow={'auto'}>
              <Entreprise
                showMessage={() => { }}
                entreprise={selected}
                onClose={onClose}
                reload={() => { }}
              />
            </Box>
          </Box>

          <Button
            plain={true}
            icon={<Close />}
            onClick={onClose}
            style={{ position: "absolute", top: 5, right: 5 }}
          />
        </Layer>
      </Box>)
  )
}

export default ClientInfo;

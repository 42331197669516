import React from "react";
import {
	Box,
	Layer,
	Text,
} from "grommet";
import ClientInfo from "./info";
import { createContact, updateContact } from "../../../services/contact_service";
import PrimaryButton from "../../elements/primary_button";


const ContactButtonPopup = ({ contact, updateDevis, reload, showMessage }) => {
	const [showModal, setShowModal] = React.useState(false);


	const submitModification = (con) => {
		updateContact(con)
			.then((res) => {
				reload();
				setShowModal(false);
			})
			.catch((err) => {
				console.log(err.response?.data);
				let message;
				if (err.response && err.response?.data) {
					message = err.response?.data?.message;
				}
				showMessage(message || "Error inattendu", "error");
			});
	};


	return (

		<Box  >
			<PrimaryButton style={{width: 200}} label={<Text weight={'bold'} >Modifier <b>client</b></Text>} onClick={() => {
				setShowModal(true);
			}} />
			{showModal && (
				<Layer pad="medium" onClickOutside={() => setShowModal(false)} onEsc={() => setShowModal(false)}>
					<Box fill pad='small' gap="small"  >
						<ClientInfo
							creationMode={false}
							contact={contact}
							submitModification={submitModification}
							updateContact={updateDevis}
							deleteContact={() => { }}
							showMessage={showMessage}
							onClose={() => {
								setShowModal(false);
							}}
							commerciaux={[]}
							onRefresh={reload}
						/>
					</Box>
				</Layer>
			)}
		</Box>

	);
};

export default ContactButtonPopup;

import React from "react";
import { Box, Text } from "grommet";
import { StatusGoodSmall } from "grommet-icons";
import { CONTRAT_CLOTURE, CONTRAT_ENVOYE } from "../../services/devis_contrat_status";

const PaymentStatus = ({
  contract_paid,
  status_payment,
  isEmprunteur = false,
  data
}) => (
  (data.status === CONTRAT_ENVOYE || data.status === CONTRAT_CLOTURE) ? <Box direction="column" gap="xsmall" pad="xsmall" align="center">
    <Box direction="row" gap="xsmall" pad="xsmall" align="center">
      <StatusGoodSmall color={contract_paid ? "#33BC00" : "#FFD77C"} />
      <Text size="small">
        <strong>
          {contract_paid
            ? (isEmprunteur ? "Acompte " : "") + "Payé"
            : "En attente"}
        </strong>
      </Text>
    </Box>
    {status_payment && data.pourcentage_acompte > 0 && (
      <Box direction="row" >
        <StatusGoodSmall color={status_payment.split("/").pop() === status_payment.split("/")[0] ? "#33BC00" : "#FFD77C"} />
        <Text size="small">
          <strong> {parseInt(status_payment.split("/")[0], 10) + 1}/{parseInt(status_payment.split("/").pop(), 10) + 1}  </strong>
        </Text>
      </Box>
    )}
    {status_payment && data.pourcentage_acompte === 0 && (
      <Box direction="row" >
        <StatusGoodSmall color={status_payment.split("/").pop() === status_payment.split("/")[0] ? "#33BC00" : "#FFD77C"} />
        <Text size="small">
          <strong> {status_payment} </strong>
        </Text>
      </Box>
    )}
  </Box> : <Box></Box>
);

export default PaymentStatus;

import { Box, FileInput, TextArea } from 'grommet';
import { CaretPrevious, Save } from 'grommet-icons';
import React from "react";
import { uploadFile } from '../../../../services/storage_service';
import AddressAutoComplet from "../../../elements/address_autocomplete";
import CustomInput from '../../../elements/custom_input';
import DateInput from '../../../elements/date_input';
import PrimaryButton from '../../../elements/primary_button';
import CustomSelect from '../../../elements/select_fill';
import StandardText from "../../../elements/standard_text";
import ToggleButton from '../../../elements/toggle_button/toggle_button';
import SectionDivider from "../../../voyages/components/session_devider";

const doUploadFile = (files, callback, showError) => {
    const fileList = files;
    if (fileList) {
        for (let i = 0; i < fileList.length; i += 1) {
            const file = fileList[i];
            console.log(file.name);
            uploadFile(file)
                .then((result) => {
                    console.log("result = ", result.data);
                    callback(result.data);
                })
                .catch((err) => {
                    showError("Erreur inattendu télécharger.");
                    callback([]);
                });
        }
    }
};

const Info = ({ deal, onDealChange, showMessage, prev, save }) => {

    return (
        <Box
            style={{ width: "100%" }}
            justify="center"
            alignContent="center"
            align="center"
        >
            <Box
                width={'large'}
                justify="center"
                alignContent="center"
                align="center"
                gap="medium"
            >
                <Box >
                    <SectionDivider title={'Informations sur votre activité'} />
                </Box>

                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                    <LineInput label="Précision sur votre activité"
                        value={deal.precision_activite}
                        onDealChange={val => onDealChange('precision_activite', val)}
                    />
                </Box>
                <Box >
                    <SectionDivider title={'Informations sur votre entreprise'} />
                </Box>

                <Box gap="xxsmall" align="center" width={'full'}>
                    <LineInput label="Effectif de l’entreprise"
                        value={deal.effectif_entreprise}
                        onDealChange={val => onDealChange('effectif_entreprise', val)}
                    />

                    <Box direction='row' width={'full'}>
                        <Box style={{ width: '20%' }} />
                        <Box style={{ width: '80%' }} gap='xsmall'>
                            <LineInput label="Effectif salariés AGIRC"
                                value={deal.effectif_agirc}
                                onDealChange={val => onDealChange('effectif_agirc', val)}
                            />
                            <LineInput label="Effectif salariés non AGIRC"
                                value={deal.effectif_non_agirc}
                                onDealChange={val => onDealChange('effectif_non_agirc', val)}
                            />
                        </Box>
                    </Box>

                    <Box direction='row' width={'full'}>
                        <Box style={{ width: '30%' }}>
                            <StandardText size={'small'} label='Société représentée par' />
                        </Box>
                        <Box style={{ width: '70%' }} gap='xsmall'>
                            <LineInput label="Nom"
                                value={deal.director_nom}
                                onDealChange={val => onDealChange('director_nom', val)}
                            />
                            <LineInput label="Prénom"
                                value={deal.director_prenom}
                                onDealChange={val => onDealChange('director_prenom', val)}
                            />
                            <LineInput label="Tel"
                                value={deal.director_tel}
                                onDealChange={val => onDealChange('director_tel', val)}
                            />
                            <LineInput label="Mail"
                                value={deal.director_mail}
                                onDealChange={val => onDealChange('director_mail', val)}
                            />
                        </Box>
                    </Box>
                    <LineInput label="Chiffre d’affaires annuel HT"
                        value={deal.ca_ht}
                        placeholder='€'
                        onDealChange={val => onDealChange('ca_ht', val)}
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Date d’effet souhaité"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_effet}
                                onChange={(date) => onDealChange("date_effet", date)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Date de résiliation"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_resiliation}
                                onChange={(date) => onDealChange("date_resiliation", date)}
                            />
                        }
                    />
                </Box>
                <Box >
                    <SectionDivider title={'Garanties souhaitées'} />
                </Box>

                <Box gap="xxsmall" align="center" width={'full'}>
                    <LineToggle label={'Structure tarifaire'}
                        groups={[
                            "Isolé / Famille",
                            "Salarié / Conjoint / Enfant",
                            "Unique",
                            "Isolé"
                        ].map(value => (
                            {
                                label: value,
                                selected: deal.structure_tarifaire === value,
                                onClick: () => onDealChange('structure_tarifaire', value),
                            }
                        ))}
                    />
                    <LineToggle label={'Niveau de garantie'}
                        groups={Array(6).fill().map((value,i) => (
                            {
                                label: (i+1),
                                selected: deal.niveau_garantie === (i+1),
                                onClick: () => onDealChange('niveau_garantie', (i+1)),
                            }
                        ))}
                    />
                    <Box pad={'small'}>
                        <FileInput
                            size={'xxsmall'}
                            messages={{
                                browse: "Choisir",
                                dropPrompt: "Ajouter un autre document",
                            }}
                            multiple={false}
                            onChange={(event) => {
                                doUploadFile(
                                    event.target.files,
                                    async (data) => {
                                        if (data.length > 0) {
                                            let _uploaded = data[0];
                                            let _files = deal.file_others || [];
                                            _files.push(_uploaded.url);
                                            onDealChange("file_others", _files);
                                        }
                                    },
                                    mes => showMessage(mes, 'error')
                                );
                            }}
                        />
                    </Box>
                </Box>

                <Box pad={'medium'} direction='row' gap="medium">
                    < PrimaryButton icon={<CaretPrevious />} label='Retour' background={'#707070'} onClick={prev} />
                    < PrimaryButton icon={<Save />} label='Enregistrer' onClick={save} />
                </Box>
            </Box>
        </Box>
    )
}

const Line = ({ left, right }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                {left}
            </Box>
            <Box width={'full'}>
                {right}
            </Box>
        </Box>
    )
}

const LineInput = ({ label, placeholder, value, onDealChange }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <CustomInput size='xsmall' placeholder={placeholder}
                    value={value}
                    onChange={event => onDealChange(event.target.value)}
                />
            </Box>
        </Box>
    )
}

const LineToggle = ({ label, groups = [] }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                <StandardText size={'small'} label={label} />
            </Box>
            <Box width={'full'}>
                <ToggleButton
                    groups={groups}
                />
            </Box>
        </Box>
    )
}

export default Info;
import { Box, Card, Layer, Tab, Tabs, Text } from 'grommet';
import { Close, Edit } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { getGestionCollabs } from '../../../../services/dashboard';
import { toArrayOfArray } from '../../../../services/utils';
import { ClosedStatusReport } from '../../../dashboard/reports_closed';
import StatusReport from '../../../dashboard/status_report';
import PrimaryButton from '../../../elements/primary_button';
import Agence from '../../../voyages/agences/agence';
import Interlocuteur from '../../../voyages/agences/agence/components/elements/interlocuter';
import { getAgences } from '../../../voyages/agences/service';
import SectionDivider from '../../../voyages/components/session_devider';

const Recap = ({ entreprise, onClose, toEdit, action = true, onSelect, showMessage, contrat_deals, devis_deals, showDeal }) => {
    console.log('deal: ', devis_deals);

    const [selected, setSelected] = useState();
    const [agence, setAgence] = useState();
    const [blocking, setBlocking] = useState(false);
    const [commerciaux, setCommerciaux] = useState([])

    useEffect(() => {
        getGestionCollabs()
            .then(res => {
                setCommerciaux(res.data);
            });
    }, [])

    useEffect(() => {
        if (selected) {
            setBlocking(true);
            getAgences(0, '', { id: selected.id })
                .then(res => {
                    const _agence = res.data.length ? res.data[0] : null;
                    setAgence(_agence);
                    setBlocking(false);
                }).catch(err => setBlocking(false));
        } else {
            setAgence(null);
        }
    }, [selected]);

    return (
        <Box width={'xlarge'} justify="center" align='center' gap='small' pad='xsmall'>
            {!showDeal ?
                <RecapTab
                    entreprise={entreprise}
                    agence={agence}
                    showMessage={showMessage}
                    onSelect={onSelect}
                    onClose={onClose}
                    toEdit={toEdit}
                    action={action}
                    blocking={blocking}
                    setSelected={setSelected}
                /> :
                <Tabs>
                    <Tab title="Recap">
                        <RecapTab
                            entreprise={entreprise}
                            agence={agence}
                            showMessage={showMessage}
                            onSelect={onSelect}
                            onClose={onClose}
                            toEdit={toEdit}
                            action={action}
                            blocking={blocking}
                            setSelected={setSelected}
                        />
                    </Tab>
                    <Tab title="Devis">
                        <DevisTab
                            reports={devis_deals}
                            commerciaux={commerciaux}
                        />
                    </Tab>
                    <Tab title="Contrat">
                        <ContratTab
                            reports={contrat_deals}
                            commerciaux={commerciaux}
                        />
                    </Tab>
                </Tabs>
            }
        </Box>
    )
}

const RecapTab = ({
    entreprise,
    agence,
    showMessage,
    onSelect,
    onClose,
    toEdit,
    action,
    blocking,
    setSelected,
}) => (
    <Box width={'large'} justify="center" align='center' gap='small' pad='xsmall'>
        {entreprise.agence &&
            (<BlockUi tag={'div'} blocking={blocking}><Box style={{ position: 'absolute', right: 50, top: 150 }} gap='small'>
                <Card pad='small' onClick={() => setSelected(entreprise.agence)}>
                    <LineDisplay field="Raison social" value={entreprise.agence.raisonSocial} />
                    <LineDisplay field="Type de société" value={entreprise.agence.societeType} size='xsmall' />
                    <LineDisplay field="Statut" value={entreprise.agence.status} size='xsmall' />
                </Card>
            </Box></BlockUi>)
        }
        {agence && (<Box width={'full'}>
            <Layer
                full='vertical'
                onEsc={() => setSelected(undefined)} style={{ width: '90%' }}
            >
                <Box pad={'medium'} direction='column' style={{ overflowY: 'scroll' }}>
                    <Agence
                        showMessage={showMessage}
                        agence={agence}
                        onClose={() => setSelected(null)}
                        reload={() => { }}
                        users={[]}
                    />

                </Box>
            </Layer>
        </Box>)}
        <SectionDivider title={'Informations entreprise'} />
        <Box width={'large'} gap='small' direction='row' justify='between'>
            <Box gap='small' style={{ width: '50%' }}>
                <Box pad={'xsmall'} gap='small'>
                    <LineDisplay field={"Raison social"} value={entreprise.raison_social} />
                    <LineDisplay field={"Type de société"} value={`${entreprise.type_societe}`} />
                    <LineDisplay field={"Téléphone"} value={entreprise.phone} />
                    <LineDisplay field={"Email"} value={entreprise.email} />
                </Box>
            </Box>
            <Box gap='small' style={{ width: '50%' }}>
                <Box pad={'xsmall'} gap='small'>
                    <LineDisplay field={"Adresse"} value={`${entreprise.address}, ${entreprise.zip}, ${entreprise.city}`} />
                    <LineDisplay field={"Ne pas envoyer email"} value={entreprise.do_not_emailing === true ? '❌ Ne pas envoyer email' : '✅ Email OK'} />
                </Box>
            </Box>
        </Box>

        <SectionDivider title={"Informations de l'interlocuteur"} />
        <Box pad={'small'} gap="small" width={"large"}>
            {
                toArrayOfArray(entreprise.interlocuteurs || [], 2).map((interG, index) => (
                    <Box key={index} pad={'small'} gap="large" width={"large"} direction="row" justify='between'>
                        {
                            interG.map((iter, idx) => (
                                <Interlocuteur
                                    key={idx + index * 3}
                                    idx={idx + index * 3}
                                    iter={iter}
                                    onReceivingMailChange={() => { }}
                                    onSelect={onSelect}
                                />
                            ))
                        }
                    </Box>
                ))
            }
        </Box>
        {action &&
            <Box direction="row" align="center" justify="center" gap="medium" pad={'medium'}>
                <PrimaryButton
                    label={`Modifier`}
                    icon={<Edit />}
                    background="#59A4F4"
                    onClick={toEdit}
                />
                <PrimaryButton
                    label={`Fermer`}
                    icon={<Close />}
                    background="#6c757d"
                    onClick={onClose}
                />
            </Box>
        }
    </Box>
)

const ContratTab = ({ commerciaux, reports }) => (
    <ClosedStatusReport
        reports={reports}
        commerciaux={commerciaux}
        reloadAll={() => { }}
        showMessage={() => { }}
    />
)

const DevisTab = ({ reports, commerciaux }) => (
    <StatusReport
        reloadAll={() => { }}
        showMessage={() => { }}
        reports={reports}
        status={'DEVIS / DEVIS_VALIDE / CONTRAT_ENVOYE'}
        commerciaux={commerciaux}
        nbr_appel={'prospect'}
        refreshReports={() => { }}
    />
)



const LineDisplay = ({ field, value }) => (
    <Text size="small" color={'#707070'}>
        {field} :
        <b> {value} </b>
    </Text>
)

export default Recap;
import { Box, Heading } from "grommet";
import DateInput from "../../../elements/date_input";
import React from "react";
import CustomInput from "../../../elements/custom_input";
import Line from "../../../elements/line_container";
import CustomSelect from "../../../elements/select_fill";
import ToggleButton from "../../../elements/toggle_button/toggle_button";
import PrimaryButton from "../../../elements/primary_button";
import { CaretNext, CaretPrevious } from "grommet-icons";

const TITULAIRE_TYPES = [
  "Le souscripteur, conducteur principal",
  "Son conjoint / concubin",
  "Le souscripteur ET son conjoint/concubin",
  "Un parent (ascendant)",
  "Une société de crédit-bail ou leasing",
  "Une société (véhicule de société)",
];

const VEHICLE_USAGES = [
  "Vie privée",
  "Vie privée + trajets domicile-lieu de travail",
  "Professionnel (avec véhicule privé)",
  "Professionnel (avec véhicule de société)",
];

const FORFAIT_KM = [
  "Moins de 5 000 km",
  "Moins de 8 000 km",
  "Moins de 12 000 km",
  "Moins de 16 000 km",
  "Illimité",
];

const TrustiInfo = ({ devis, updateDevis, onNext, onPrev }) => {
  return (
    <Box
      gap="small"
      width="xlarge"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Box justify="center" alignSelf="center">
        <Heading
          level={3}
          textAlign="center"
        >{`Informations du véhicule`}</Heading>
      </Box>

      <Box width="large" gap="medium" margin="medium">
        <Box gap="xsmall">
          <Line
            leftLabel="Marque"
            rightChildren={
              <CustomInput
                value={devis.marque}
                onChange={(event) => updateDevis("marque", event.target.value)}
              />
            }
          />
          <Line
            leftLabel="Modèle"
            rightChildren={
              <CustomInput
                value={devis.model}
                onChange={(event) => updateDevis("model", event.target.value)}
              />
            }
          />
          <Line
            leftLabel="Immatriculation"
            rightChildren={
              <CustomInput
                value={devis.immatriculation}
                onChange={(event) =>
                  updateDevis("immatriculation", event.target.value)
                }
              />
            }
          />
          <Line
            leftLabel="Date de première en circulation"
            rightChildren={
              <DateInput
                value={devis.premiere_circulation}
                onChange={(date) => updateDevis("premiere_circulation", date)}
              />
            }
          />
          <Line
            leftLabel="Energie"
            rightChildren={
              <ToggleButton
                groups={["Essence", "Diesel", "Electrique", "Hybride"].map((value) => ({
                  label: value,
                  selected: devis.energy === value,
                  onClick: () => updateDevis("energy", value),
                }))}
              />
            }
          />
          <Line
            leftLabel="Puissance fiscale"
            rightChildren={
              <CustomInput
                value={devis.puissance_fiscale}
                onChange={(event) =>
                  updateDevis("puissance_fiscale", event.target.value)
                }
              />
            }
          />
          <Line
            leftLabel="Version"
            rightChildren={
              <CustomInput
                value={devis.version}
                onChange={(event) => updateDevis("version", event.target.value)}
              />
            }
          />
          <Line
            leftLabel="Type de stationnement"
            rightChildren={
              <ToggleButton
                fontSize={14}
                groups={[
                  "Garage clos",
                  "Terrain privé clos",
                  "Voie publique",
                  "Emplacement parking extérieur",
                ].map((value) => ({
                  label: value,
                  selected: devis.parking_type === value,
                  onClick: () => updateDevis("parking_type", value),
                }))}
              />
            }
          />
          <Line
            leftLabel="Code postal de garage"
            rightChildren={
              <CustomInput
                value={devis.lieu_garage}
                onChange={(event) =>
                  updateDevis("lieu_garage", event.target.value)
                }
              />
            }
          />
          <Line
            leftLabel="Ville de garage"
            rightChildren={
              <CustomInput
                value={devis.ville_garage}
                onChange={(event) =>
                  updateDevis("ville_garage", event.target.value)
                }
              />
            }
          />
          <Line
            leftLabel="Type de financement"
            rightChildren={
              <ToggleButton
                fontSize={14}
                groups={["Comptant/Crédit", "L.O.A", "L.L.D", "Autres"].map(
                  (value) => ({
                    label: value,
                    selected: devis.financement_type === value,
                    onClick: () => updateDevis("financement_type", value),
                  })
                )}
              />
            }
          />
          <Line
            leftLabel="Date d'acquisition"
            rightChildren={
              <DateInput
                value={devis.date_acquisition}
                onChange={(date) => updateDevis("date_acquisition", date)}
              />
            }
          />
          <Line
            leftLabel="Usage du véhicule"
            rightChildren={
              <CustomSelect
                options={VEHICLE_USAGES}
                value={devis.usage_vehicle}
                onChange={({ option }) => updateDevis("usage_vehicle", option)}
              />
            }
          />
          <Line
            leftLabel="Forfait kilométrique annuel"
            rightChildren={
              <CustomSelect
                options={FORFAIT_KM}
                value={devis.forfait_km_annuel}
                onChange={({ option }) =>
                  updateDevis("forfait_km_annuel", option)
                }
              />
            }
          />
          <Line
            leftLabel="Qui est le titulaire de la carte grise"
            rightChildren={
              <CustomSelect
                options={TITULAIRE_TYPES}
                value={devis.titulaire_type}
                onChange={({ option }) => updateDevis("titulaire_type", option)}
              />
            }
          />

          <Line
            leftLabel="Date d’effet souhaité"
            rightChildren={
              <DateInput
                value={devis.begin_date}
                onChange={(date) => updateDevis("begin_date", date)}
              />
            }
          />

          <Line
            leftLabel="Date de résiliation"
            rightChildren={
              <DateInput
                value={devis.date_resiliation}
                onChange={(date) => updateDevis("date_resiliation", date)}
              />
            }
          />
        </Box>
      </Box>

      <Box style={{ height: 40 }} />
      <Box direction="row" align="center" justify="center" gap="medium">
        <PrimaryButton
          icon={<CaretPrevious />}
          label={`Précédent`}
          background="#59A4F4"
          onClick={onPrev}
        />
        <PrimaryButton
          icon={<CaretNext />}
          reverse={true}
          label={`Suivant`}
          onClick={() => {
            if (!devis.begin_date) {
              updateDevis("begin_date", new Date());
            }
            if (!devis.date_acquisition) {
              updateDevis("date_acquisition", new Date());
            }
            if (!devis.premiere_circulation) {
              updateDevis("premiere_circulation", new Date());
            }
            if (!devis.license_date) {
              updateDevis("license_date", new Date());
            }
            onNext();
          }}
        />
      </Box>
    </Box>
  );
};

export default TrustiInfo;

import { Box, Card } from 'grommet';
import { Down, Next, StatusGood } from "grommet-icons";
import React, { useState } from "react";
import { getSimpleDateTimeFR } from '../../../../services/utils';
import StandardText from '../../../elements/standard_text';


const History = ({ histories }) => {

    const [_histories, setHistories] = useState(histories.sort((c1, c2) => c1.updatedAt > c2.updatedAt ? -1 : 1));


    const onCollapseChanged = history => {
        history.expanded = !history.expanded;
        setHistories(histories.map(d => d));
    }
    return (
        <Card width={'full'} align='center' justify="center" pad={'small'} style={{ overflowY: 'auto', minHeight: 'auto', maxHeight: 400 }}>
            <StandardText bold label={'Historique de modification'} />
            <Box width={'full'} pad='small' gap="xxsmall">
                {_histories.map(history => (
                    <Box width={'full'} background={'#fff'} key={history.id}
                        hoverIndicator={history.expanded ? null : {
                            background: {
                                color: '#6DB24F',
                            },
                            elevation: 'small',
                        }}
                        onClick={() => { }} pad={'xxsmall'}
                        style={{ boxShadow: 'none', minHeight: 'auto' }}
                        round={{ size: 'xsmall' }}
                    >
                        <Box direction="row" justify='between' align="center">
                            <Box direction="row" gap="xsmall" align="center" onClick={() => onCollapseChanged(history)} pad={'xsmall'}
                                style={{ boxShadow: 'none' }}
                            >
                                {history.expanded ? <Down size="small" /> : <Next size="small" />}
                                <StandardText size='small' label={getSimpleDateTimeFR(history.updatedAt)} bold/>
                            </Box>
                            <StatusGood color="#6DB24F" />
                        </Box>

                        {history.expanded ?
                            <Box width={'full'} align='center' pad={'small'}>
                                <Box width={'full'} justify='between' align='center' gap="xsmall" direction='row'>
                                    <Box justify='center' align='center' width={'full'}>
                                        <StandardText size='small' label={"Champ"} textAlign='center' bold />
                                    </Box>
                                    <Box justify='center' align='center' width={'full'}>
                                        <StandardText size='small' label={"Ancienne valeur"} bold />
                                    </Box>
                                    <Box justify='center' align='center' width={'full'}>
                                        <StandardText size='small' label={"Nouvelle valeur"} bold />
                                    </Box>
                                </Box>
                                {history.changes.map(change => (
                                    <Box width={'full'} align='center' gap="xsmall" key={change.key} direction='row'>
                                        <Box width={'full'} justify='between' align='center' gap="xsmall">
                                            <StandardText size='small' label={change.key} />
                                        </Box>
                                        <Box justify='center' align='center' width={'full'}>
                                            <StandardText size='small' label={change.old_value} />
                                        </Box>
                                        <Box justify='center' align='center' width={'full'}>
                                            <StandardText size='small' label={change.new_value} />
                                        </Box>
                                    </Box>
                                ))}

                            </Box> : null
                        }
                    </Box>
                ))}
            </Box>
        </Card>
    )
}

export default History;

import { Box, CheckBox, FileInput, Grid, Heading, Layer, Text } from "grommet";
import React, { useState } from "react";
import MarksSlider from "../../../elements/slider/marks_slider";
import { CustomInputV2 } from "../../../elements/custom_input";
import CustomSelect from "../../../elements/select_fill";
import { ToggleButtonV2 } from "../../../elements/toggle_button/toggle_button";
import { Checkmark, Close, DocumentDownload } from "grommet-icons";
import PrimaryButton from "../../../elements/primary_button";
import { ASSURANCE_PRET_TYPES } from "../../../../types/emprunteur_type";
import BlockText from "../../../elements/line_container/block_text";
import { EMPRUNTEUR_TYPE } from "../../../../services/types";
import {
  uploadContractPDF,
  uploadFile,
} from "../../../../services/storage_service";
import { DashboardRecapTitle } from "../../../elements/new_design_elements";
import moment from "moment";
import StandardText from "../../../elements/standard_text";

const doUploadContratPDF = (files, callback, showError) => {
  const fileList = files;
  for (let i = 0; i < fileList.length; i += 1) {
    const file = fileList[i];
    console.log(file.name);
    uploadContractPDF(file)
      .then((result) => {
        // console.log("doUploadContratPDF = ", result.data);
        callback(result.data);
      })
      .catch((err) => {
        showError(
          "Erreur: Le fichier téléchargé n'est pas un PDF modifiable.",
          "error",
        );
        callback([]);
      });
  }
};

const Line = ({ leftLabel, rightChildren, widthLeft, widthRight, size }) => (
  <Box>
    <Text color="grey">{leftLabel}</Text>
    <Box width="100%">
      {rightChildren}
    </Box>
  </Box>
)

const doUploadFile = (files, callback, showError) => {
  const fileList = files;
  for (let i = 0; i < fileList.length; i += 1) {
    const file = fileList[i];
    console.log(file.name);
    uploadFile(file)
      .then((result) => {
        console.log("result = ", result.data);
        callback(result.data);
      })
      .catch((err) => {
        showError("Erreur inattendu télécharger.");
        callback([]);
      });
  }
};

const onAmountChanged = (event, callback, showError, callbackNoChanged) => {
  if (!event.target.value) {
    callback(0);
    return;
  }
  event.target.value = event.target.value
    .replace(/[^0-9.,]/g, "")
    .replace(/,/g, ".")
    .replace(/(\..*?)\..*/g, "$1");
  if (event.target.value.slice(-1) === ".") {
    // skip callback to next step
    callbackNoChanged(event.target.value);
    return;
  }
  const _arrA = event.target.value.split(".");
  if (_arrA.length > 1) {
    if (_arrA[1].replace(/0/g, "") === "") {
      // skip callback to next step
      callbackNoChanged(event.target.value);
      return;
    }
  }
  const value = parseFloat(event.target.value || "0");
  if (isNaN(value)) {
    showError("Valeur non valid");
    return;
  } else {
    callback(value);
  }
};

const DevisContratForm = ({
  devis,
  updateDevisOrigin,
  isModeContrat,
  allCompanies,
  sendEmailDevisContrat,
  submitModification,
  showMessage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [creditPopup, setPopupCredit] = useState(false);

  const _changeAssurance = devis.type_projet === "Je souhaite changer d'assurance";
  var _timer = null;

  const calcul = (options = {}) => {
    var _old_prime_moyenne_mensuel = isNaN(devis.old_prime_mensuel || 0)
      ? 0
      : devis.old_prime_mensuel || 0;
    var _new_prime_moyenne_mensuel = isNaN(devis.new_prime_mensuel || 0)
      ? 0
      : devis.new_prime_mensuel || 0;
    if (
      devis.old_prime_mensuel &&
      devis.old_prime_mensuel_last &&
      devis.old_pret_type !== ASSURANCE_PRET_TYPES.constant
    ) {
      _old_prime_moyenne_mensuel =
        (devis.old_prime_mensuel + devis.old_prime_mensuel_last) / 2;
    }
    if (typeof _old_prime_moyenne_mensuel.toFixed !== "function") {
      _old_prime_moyenne_mensuel = 0.0;
    }
    updateDevisOrigin(
      "old_prime_moyenne_mensuel",
      _old_prime_moyenne_mensuel.toFixed(2),
    );
    if (
      devis.new_prime_mensuel &&
      devis.new_prime_mensuel_last &&
      devis.new_pret_type !== ASSURANCE_PRET_TYPES.constant
    ) {
      _new_prime_moyenne_mensuel =
        (devis.new_prime_mensuel + devis.new_prime_mensuel_last) / 2;
    }
    if (typeof _new_prime_moyenne_mensuel.toFixed !== "function") {
      _new_prime_moyenne_mensuel = 0;
    }
    updateDevisOrigin(
      "new_prime_moyenne_mensuel",
      _new_prime_moyenne_mensuel.toFixed(2),
    );

    let _eco = 0;
    if (devis.new_prime_total) {
      if (typeof devis.new_prime_total.toFixed !== "function") {
        return;
      }
      if (_changeAssurance && !devis.old_prime_total) {
        return;
      }
      if (_changeAssurance && (typeof devis.old_prime_total.toFixed !== "function")) {
        return;
      }

      if (_changeAssurance) {
        _eco = devis.old_prime_total - devis.new_prime_total;

        var _t = 0;
        if (_eco <= 5000) {
          _t = 10;
        } else if (_eco <= 10000) {
          _t = 8.5;
        } else if (_eco <= 15000) {
          _t = 7.5;
        } else if (_eco <= 20000) {
          _t = 6.5;
        } else {
          _t = 5.5;
        }

        updateDevisOrigin("taux_frais_dossier", _t.toFixed(2));

        updateDevisOrigin("economie_total", _eco.toFixed(2));
        updateDevisOrigin(
          "economie_total_pourcentage",
          ((_eco / devis.old_prime_total) * 100).toFixed(2),
        );

      }

      var _frais_dossier_total = 0;

      if (devis.frais_libre) {
        _frais_dossier_total = devis.frais_dossier_total || 0;
      }

      if (devis.pourcentage_reduction > 0) {
        _frais_dossier_total =
          _frais_dossier_total -
          (_frais_dossier_total * devis.pourcentage_reduction) / 100;
      }

      if (_changeAssurance && _eco) {
        updateDevisOrigin(
          "economie_total_moin_frais_dossier",
          (_eco - _frais_dossier_total).toFixed(2),
        );
        updateDevisOrigin(
          "economie_total_moin_frais_dossier_pourcentage",
          (((_eco - _frais_dossier_total) / devis.old_prime_total) * 100).toFixed(
            2,
          ),
        );
      }

      if (!devis.frais_libre) {
        updateDevisOrigin("frais_dossier_total", _frais_dossier_total.toFixed(2));
      }
      updateDevisOrigin("frais_dossier", _frais_dossier_total.toFixed(2));

      updateDevisOrigin("prime_ttc", devis.new_prime_total.toFixed(2));

      const _frais_dossier_assureur =
        45 *
        (1 +
          (devis.emprunteur_type === EMPRUNTEUR_TYPE.co_emprunteur
            ? devis.emprunteurs
              ? devis.emprunteurs.length
              : 0
            : 0));
      updateDevisOrigin(
        "frais_dossier_assureur",
        _frais_dossier_assureur.toFixed(2),
      );


      var _acompte_a_regler = _frais_dossier_total * (parseFloat(devis.pourcentage_acompte) / 100);
      if (options.with_acompte) {
        _acompte_a_regler = parseFloat(devis.acompte_a_regler);
        updateDevisOrigin("pourcentage_acompte", _acompte_a_regler * 100 / _frais_dossier_total);
      } else {
        updateDevisOrigin("acompte_a_regler", _acompte_a_regler.toFixed(2));
      }

      const _frais_dossier_restant = _frais_dossier_total - _acompte_a_regler;

      if (devis.fractionnement_manuel && devis.montant_acompte > 0) {
        _acompte_a_regler = devis.montant_acompte * ((100 - parseFloat(devis.pourcentage_reduction)) / 100)

      }

      var _fractionnement_frais_dossier = 0;
      var _nombre_de_prelevement = 0;

      if (devis.fractionnement_manuel !== true) {
        if (
          Math.round(
            _frais_dossier_restant /
            (_old_prime_moyenne_mensuel - _new_prime_moyenne_mensuel),
          ) > 24
        ) {
          devis.fractionnement_manuel = true;
          devis.nombre_de_prelevement = 24;
          updateDevisOrigin("fractionnement_manuel", true);
        }
      }

      if (devis.fractionnement_manuel) {
        if (devis.nombre_de_prelevement) {
          _nombre_de_prelevement =
            devis.nombre_de_prelevement > 24 ? 12 : devis.nombre_de_prelevement;
        } else {
          _nombre_de_prelevement = 24; // maxi 12
        }
        if (Number(devis.pourcentage_acompte) === 100) {
          _nombre_de_prelevement = 1;
        }
        if (devis.montant_echeance > 0) {
          _fractionnement_frais_dossier = devis.montant_echeance;
        } else {
          _fractionnement_frais_dossier =
            _frais_dossier_restant / _nombre_de_prelevement;
        }
      } else {
        _nombre_de_prelevement = Math.round(
          _frais_dossier_restant /
          (_old_prime_moyenne_mensuel - _new_prime_moyenne_mensuel),
        );
        _fractionnement_frais_dossier =
          _frais_dossier_restant / _nombre_de_prelevement;
      }

      updateDevisOrigin(
        "frais_dossier_restant",
        _frais_dossier_restant.toFixed(2),
      );

      updateDevisOrigin(
        "fractionnement_frais_dossier",
        _fractionnement_frais_dossier.toFixed(2),
      );
      updateDevisOrigin("nombre_de_prelevement", _nombre_de_prelevement);
      updateDevisOrigin(
        "prime_et_frais_mensuel",
        (_fractionnement_frais_dossier + _new_prime_moyenne_mensuel).toFixed(2),
      );
    }
  };

  const updateDevis = (key, value) => {
    updateDevisOrigin(key, value);
    clearInterval(_timer);
    _timer = setTimeout(() => {
      calcul();
    }, 200);
  };

  // defaut date effet
  if (!devis.date_effet) {
    let _now = new Date();
    let _date = new Date(_now.getFullYear(), _now.getMonth() + 1, 10);
    updateDevisOrigin("date_effet", _date);
  }

  if (!devis.hasOwnProperty("pourcentage_acompte")) {
    updateDevisOrigin("pourcentage_acompte", 100);
  }

  if (!devis.fractionnement_manuel) {
    updateDevisOrigin("fractionnement_manuel", true);
  }

  if (!devis.frais_libre) {
    updateDevisOrigin("frais_libre", true);
  }

  // if (!devis.acompte_a_regler) {
  //   updateDevisOrigin("acompte_a_regler", 1);
  // }

  if ((typeof devis.frais_dossier_total) !== 'number') {
    updateDevisOrigin("frais_dossier_total", 0);
  }

  return (
    <Box
      gap="small"
      width="100%"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Heading level={3} textAlign="center">
        <strong>{isModeContrat ? "Contrat" : "Devis"}</strong>
        <br />
        <br />
        Renseigner les informations du prêt
      </Heading>
      <DashboardRecapTitle title="Information client" />
      <Box pad="small" width="full">
        <Box direction="row" gap="medium" align="center">
          <Text color="grey">Ou réside le client ?</Text>
          <ToggleButtonV2
            groups={[
              {
                label: "Hors Europe",
                selected: devis.outside_europe === true,
                onClick: () => {
                  updateDevisOrigin("outside_europe", true);
                  updateDevis("pourcentage_acompte", 0);
                },
              },
              {
                label: "Europe",
                selected: devis.outside_europe !== true,
                onClick: () => {
                  updateDevisOrigin("outside_europe", false);
                },
              },
            ]}
          />
        </Box>
      </Box>
      <Grid columns={_changeAssurance ? ["1/2", "1/2"] : ["1"]} gap="small" width="full">
        {_changeAssurance && <Box>
          <DashboardRecapTitle title="Ancienne assurance du prêt" />
          <Box pad="small" width="full" gap="xsmall">
            <Line
              leftLabel="L’ancienne assurance du prêt est"
              rightChildren={
                <ToggleButtonV2
                  groups={[
                    {
                      label: ASSURANCE_PRET_TYPES.variable,
                      selected:
                        devis.old_pret_type === ASSURANCE_PRET_TYPES.variable,
                      onClick: () => {
                        updateDevis(
                          "old_pret_type",
                          ASSURANCE_PRET_TYPES.variable,
                        );
                      },
                    },
                    {
                      label: ASSURANCE_PRET_TYPES.constant,
                      selected:
                        devis.old_pret_type === ASSURANCE_PRET_TYPES.constant,
                      onClick: () => {
                        updateDevis(
                          "old_pret_type",
                          ASSURANCE_PRET_TYPES.constant,
                        );
                      },
                    },
                  ]}
                />
              }
            />
            <Line
              leftLabel={`Prime total assurance (€)`}
              rightChildren={
                <CustomInputV2
                  value={devis.old_prime_total}
                  onChange={(event) => {
                    onAmountChanged(
                      event,
                      (amount) => {
                        updateDevis("old_prime_total", amount);
                      },
                      showMessage,
                      (nochanged) => {
                        updateDevisOrigin("old_prime_total", nochanged);
                      },
                    );
                  }}
                />
              }
            />
            <Line
              leftLabel={`Prime mensuelle assurance (€)`}
              rightChildren={
                <CustomInputV2
                  value={devis.old_prime_mensuel}
                  onChange={(event) => {
                    onAmountChanged(
                      event,
                      (amount) => {
                        updateDevis("old_prime_mensuel", amount);
                      },
                      showMessage,
                      (nochanged) => {
                        updateDevisOrigin("old_prime_mensuel", nochanged);
                      },
                    );
                  }}
                />
              }
            />
            {devis.old_pret_type !== ASSURANCE_PRET_TYPES.constant && (
              <Line
                leftLabel={`Dernière prime mensuelle (€)`}
                rightChildren={
                  <CustomInputV2
                    value={devis.old_prime_mensuel_last}
                    onChange={(event) => {
                      onAmountChanged(
                        event,
                        (amount) => {
                          updateDevis("old_prime_mensuel_last", amount);
                        },
                        showMessage,
                        (nochanged) => {
                          updateDevisOrigin(
                            "old_prime_mensuel_last",
                            nochanged,
                          );
                        },
                      );
                    }}
                  />
                }
              />
            )}
            {devis.old_pret_type !== ASSURANCE_PRET_TYPES.constant && (
              <Line
                leftLabel={`Prime moyenne mensuelle  (€)`}
                rightChildren={
                  <Text
                    weight="bold"
                  >
                    {devis.old_prime_moyenne_mensuel
                      ? devis.old_prime_moyenne_mensuel
                      : "-"} €
                  </Text>
                }
              />
            )}
          </Box>
        </Box>}
        <Box>
          <DashboardRecapTitle title="Nouvelle assurance du prêt" />
          <Box pad="small" width="full" gap="xsmall">
            <Line
              leftLabel="La nouvelle assurance du prêt est :"
              rightChildren={
                <ToggleButtonV2
                  groups={[
                    {
                      label: ASSURANCE_PRET_TYPES.variable,
                      selected:
                        devis.new_pret_type === ASSURANCE_PRET_TYPES.variable,
                      onClick: () => {
                        updateDevis(
                          "new_pret_type",
                          ASSURANCE_PRET_TYPES.variable,
                        );
                      },
                    },
                    {
                      label: ASSURANCE_PRET_TYPES.constant,
                      selected:
                        devis.new_pret_type === ASSURANCE_PRET_TYPES.constant,
                      onClick: () => {
                        updateDevis(
                          "new_pret_type",
                          ASSURANCE_PRET_TYPES.constant,
                        );
                      },
                    },
                  ]}
                />
              }
            />
            <Line
              leftLabel={`Prime total assurance (€)`}
              rightChildren={
                <CustomInputV2
                  value={devis.new_prime_total}
                  onChange={(event) => {
                    onAmountChanged(
                      event,
                      (amount) => {
                        updateDevis("new_prime_total", amount);
                      },
                      showMessage,
                      (nochanged) => {
                        updateDevisOrigin("new_prime_total", nochanged);
                      },
                    );
                  }}
                />
              }
            />

            <Line
              leftLabel={`Prime mensuelle assurance (€)`}
              rightChildren={
                <CustomInputV2
                  value={devis.new_prime_mensuel}
                  onChange={(event) => {
                    onAmountChanged(
                      event,
                      (amount) => {
                        updateDevis("new_prime_mensuel", amount);
                      },
                      showMessage,
                      (nochanged) => {
                        updateDevisOrigin("new_prime_mensuel", nochanged);
                      },
                    );
                  }}
                />
              }
            />
            {devis.new_pret_type !== ASSURANCE_PRET_TYPES.constant && (
              <Line
                leftLabel={`Dernière prime mensuelle (€)`}
                rightChildren={
                  <CustomInputV2
                    value={devis.new_prime_mensuel_last}
                    onChange={(event) => {
                      onAmountChanged(
                        event,
                        (amount) => {
                          updateDevis("new_prime_mensuel_last", amount);
                        },
                        showMessage,
                        (nochanged) => {
                          updateDevisOrigin(
                            "new_prime_mensuel_last",
                            nochanged,
                          );
                        },
                      );
                    }}
                  />
                }
              />
            )}
            {devis.new_pret_type !== ASSURANCE_PRET_TYPES.constant && (
              <Line
                leftLabel={`Prime moyenne mensuelle  (€)`}
                rightChildren={
                  <Text
                    weight="bold"
                  >
                    {devis.new_prime_moyenne_mensuel
                      ? devis.new_prime_moyenne_mensuel
                      : "-"} €
                  </Text>
                }
              />
            )}
          </Box>
        </Box>
      </Grid>
      <DashboardRecapTitle title="Frais de dossier" />
      <Grid columns={["1/2", "1/2"]} gap="small" width="full" pad="small">
        {/* <Line
          leftLabel="Voulez-vous personnaliser les frais de dossier total calculés ?"
          rightChildren={
            <ToggleButtonV2
              disabled={true}
              groups={[
                {
                  label: "OUI",
                  selected: devis.frais_libre === true,
                  onClick: () => {
                    updateDevisOrigin("frais_libre", true);
                    updateDevis("pourcentage_acompte", 0);
                  },
                },
                {
                  label: "NON",
                  selected: devis.frais_libre !== true,
                  onClick: () => {
                    updateDevisOrigin("frais_libre", false);
                    calcul();
                  },
                },
              ]}
            />
          }
        /> */}
        <Line
          leftLabel="Montant total des frais de dossier"
          rightChildren={devis.frais_libre ? (
            <CustomInputV2
              value={devis.frais_dossier_total || "0"}
              reverse
              type="number"
              icon={<span>€</span>}
              onChange={(event) => {
                onAmountChanged(
                  event,
                  (amount) => {
                    if (amount === 0) {
                      updateDevisOrigin("acompte_a_regler", "0");
                      updateDevisOrigin("with_acompte", false);
                      updateDevisOrigin("pourcentage_reduction", 0);
                      updateDevisOrigin("pourcentage_acompte", 100);
                    }
                    updateDevis("frais_dossier_total", amount);
                  },
                  showMessage,
                  (nochanged) => {
                    updateDevisOrigin("frais_dossier_total", nochanged);
                  },
                );
              }}
            />
          ) : (
            <Text weight="bold">{devis.frais_dossier_total
              ? devis.frais_dossier_total
              : "-"} €</Text>
          )
          }
        />
        {/* <Line
          leftLabel="Régler les frais manuellement ou automatiquement?"
          rightChildren={
            <ToggleButtonV2
              disabled={true}
              groups={[
                {
                  label: "Manuellement",
                  selected: devis.fractionnement_manuel === true,
                  onClick: () => {
                    updateDevisOrigin("fractionnement_manuel", true);
                    updateDevis("nombre_de_prelevement", 12);
                  },
                },
                {
                  label: "Automatiquement",
                  selected: devis.fractionnement_manuel !== true,
                  onClick: () => {
                    updateDevis("fractionnement_manuel", false);
                    updateDevis("pourcentage_acompte", "20");
                  },
                },
              ]}
            />
          }
        /> */}
        <Line
          leftLabel="Régler le nombre d’échéances"
          rightChildren={devis.pourcentage_acompte !== "100" ?
            <MarksSlider
              disabled={devis.frais_dossier_total === 0}
              step={1}
              max={24}
              min={1}
              value={devis.nombre_de_prelevement}
              marks={[
                { value: 1, label: "1" },
                { value: 2, label: "" },
                { value: 3, label: "" },
                { value: 4, label: "4" },
                { value: 5, label: "" },
                { value: 6, label: "" },
                { value: 7, label: "" },
                { value: 8, label: "8" },
                { value: 9, label: "" },
                { value: 10, label: "" },
                { value: 11, label: "" },
                { value: 12, label: "12" },
                { value: 13, label: "" },
                { value: 14, label: "" },
                { value: 15, label: "" },
                { value: 16, label: "16" },
                { value: 17, label: "" },
                { value: 18, label: "" },
                { value: 19, label: "" },
                { value: 20, label: "20" },
                { value: 21, label: "" },
                { value: 22, label: "" },
                { value: 23, label: "" },
                { value: 24, label: "24" },
              ]}
              onChange={(event, newValue) => {
                updateDevisOrigin("fractionnement_manuel", true);
                updateDevis("nombre_de_prelevement", newValue);
              }}
            /> : <Text weight="bold"></Text>
          }
        />

        <Line
          leftLabel="Montant du premier versement"
          rightChildren={
            <CustomInputV2
              disabled={devis.frais_dossier_total === 0}
              value={devis.acompte_a_regler}
              onChange={(e) => {
                updateDevisOrigin("acompte_a_regler", Number(e.target.value) || 1);
                // console.log("devis.montant_total", (Number(e.target.value) / devis.frais_dossier_total * 100).toFixed(2));
                // updateDevis(
                //   "pourcentage_acompte",
                //   (Number(e.target.value) / devis.frais_dossier_total * 100).toFixed(2)
                // )
                clearInterval(_timer);
                _timer = setTimeout(() => {
                  calcul({ with_acompte: true });
                }, 200);
              }}
              reverse
              type="number"
              icon={<span>€</span>}
              style={{ width: "100%" }}
            />
          }
        />
        {devis.fractionnement_manuel && <>
          <Line
            leftLabel="Ajuster le pourcentage du premier versement"
            rightChildren={
              <MarksSlider
                disabled={devis.frais_dossier_total === 0}
                step={0.05}
                max={1}
                min={0.0}
                value={parseFloat(devis.pourcentage_acompte || 0) / 100}
                marks={[
                  { value: 0.0, label: "0%" },
                  { value: 0.1, label: "10%" },
                  { value: 0.2, label: "20%" },
                  { value: 0.3, label: "30%" },
                  { value: 0.4, label: "40%" },
                  { value: 0.5, label: "50%" },
                  { value: 0.6, label: "60%" },
                  { value: 0.7, label: "70%" },
                  { value: 0.8, label: "80%" },
                  { value: 0.9, label: "90%" },
                  { value: 1.0, label: "100%" }
                ]}
                onChange={(event, newValue) => {
                  if (newValue === 0.0) {
                    updateDevisOrigin("with_acompte", true);
                    updateDevis("acompte_a_regler", "1");
                  } else {
                    updateDevis("pourcentage_acompte", (newValue * 100).toFixed(0));
                  }
                }}
              />
            }
          />


          <Line
            leftLabel="Ajuster le pourcentage de réduction"
            rightChildren={
              <MarksSlider
                disabled={devis.frais_dossier_total === 0}
                step={0.01}
                max={0.99}
                min={0.0}
                value={parseFloat(devis.pourcentage_reduction || 0) / 100}
                marks={[
                  { value: 0.0, label: "0%" },
                  { value: 0.1, label: "10%" },
                  { value: 0.2, label: "20%" },
                  { value: 0.3, label: "30%" },
                  { value: 0.4, label: "40%" },
                  { value: 0.5, label: "50%" },
                  { value: 0.6, label: "60%" },
                  { value: 0.7, label: "70%" },
                  { value: 0.8, label: "80%" },
                  { value: 0.9, label: "90%" },
                  { value: 0.99, label: "99%" },
                ]}
                onChange={(event, newValue) =>
                  updateDevis(
                    "pourcentage_reduction",
                    (newValue * 100).toFixed(0),
                  )
                }
              />
            }
          />
          <Grid columns={["1/2", "1/2"]} width="full" pad="small" gap="small">
            <Line
              leftLabel="Pourcentage de la réduction"
              rightChildren={
                <Text size="small" style={{ fontWeight: "bold" }}>
                  {(devis.pourcentage_reduction ? devis.pourcentage_reduction : "0")}%
                </Text>
              }
            />
            <Line
              leftLabel="Montant de la réduction"
              rightChildren={
                <Text size="small" style={{ fontWeight: "bold" }}>
                  {(devis.acompte_a_regler / (1 - (devis.pourcentage_reduction ? devis.pourcentage_reduction : 0) / 100) - devis.acompte_a_regler).toFixed(2)} €
                </Text>
              }
            />
          </Grid>
        </>}
      </Grid>
      <DashboardRecapTitle title="Résultat Frais de dossier" />

      <Grid columns={["1/3", "1/3", "1/3"]} width="full" pad="small" gap="small">
        <Line
          leftLabel="Frais de dossier total"
          rightChildren={
            <Text size="small" style={{ fontWeight: "bold" }} color="bluebrand">
              {(devis.frais_dossier_total ? devis.frais_dossier_total : "0")}€
            </Text>
          }
        />
        <Line
          leftLabel="Pourcentage acompte"
          rightChildren={
            <Text size="small" style={{ fontWeight: "bold" }}>
              {(devis.pourcentage_acompte ? parseFloat(devis.pourcentage_acompte).toFixed(2) : "-")}%
            </Text>
          }
        />
        <Line
          leftLabel={`${devis.pourcentage_acompte}` !== "0"
            ? "Acompte à regler"
            : "Montant du premier versement"}
          rightChildren={
            <Text size="small" style={{ fontWeight: "bold" }}>
              {(devis.acompte_a_regler ? devis.acompte_a_regler : "0")}€
            </Text>
          }
        />
        <Line
          leftLabel="Frais de dossier restant"
          rightChildren={
            <Text size="small" style={{ fontWeight: "bold" }}>
              {(devis.frais_dossier_restant ? devis.frais_dossier_restant : "-")}€
            </Text>
          }
        />
        <Line
          leftLabel="Nombre d’échéances"
          rightChildren={
            <Text size="small" style={{ fontWeight: "bold" }}>
              {(devis.nombre_de_prelevement ? devis.nombre_de_prelevement : "-")}
            </Text>
          }
        />
        <Line
          leftLabel="Montant des échéances"
          rightChildren={
            <Text size="small" style={{ fontWeight: "bold" }}>
              {(devis.fractionnement_frais_dossier ? devis.fractionnement_frais_dossier : "-")}€
            </Text>
          }
        />
      </Grid>

      {_changeAssurance && <>
        <DashboardRecapTitle title="Résultat économie" />
        <Grid columns={["1/4", "1/4", "1/4", "1/4"]} width="full" pad="small" gap="small">
          <Line
            leftLabel="Économie totale"
            rightChildren={
              <Text size="small" style={{ fontWeight: "bold" }} color="bluebrand">
                {(devis.economie_total ? devis.economie_total : "-") + " €"}
              </Text>
            }
          />
          <Line
            leftLabel="Économie totale en %"
            rightChildren={
              <Text size="small" style={{ fontWeight: "bold" }} color="bluebrand">
                {(devis.economie_total_pourcentage
                  ? devis.economie_total_pourcentage
                  : "-") + " %"}
              </Text>
            }
          />
          <Line
            leftLabel="Économie totale - frais de dossier"
            rightChildren={
              <Text size="small" style={{ fontWeight: "bold" }} color="brand">
                {(devis.economie_total_moin_frais_dossier
                  ? devis.economie_total_moin_frais_dossier
                  : "-") + " €"}
              </Text>
            }
          />
          <Line
            leftLabel="Économie totale - frais de dossier en %"
            rightChildren={
              <Text size="small" style={{ fontWeight: "bold" }} color="brand">
                {(devis.economie_total_moin_frais_dossier_pourcentage
                  ? devis.economie_total_moin_frais_dossier_pourcentage
                  : "-") + " %"}
              </Text>
            }
          />
        </Grid>
      </>}
      <Box justify="center" align="center" width="full">
        <BlockText
          width={"50%"}
          label={
            <>
              <strong>Nouvelle prime</strong> (frais dossier inclus)
            </>
          }
          value={
            (devis.prime_et_frais_mensuel
              ? devis.prime_et_frais_mensuel
              : "-") + " €"
          }
          desc={
            <>
              dont{" "}
              <strong>
                {devis.fractionnement_frais_dossier
                  ? devis.fractionnement_frais_dossier
                  : "-"}{" "}
                €
              </strong>{" "}
              frais de dossier pendant{" "}
              <strong>
                {devis.nombre_de_prelevement
                  ? `${devis.pourcentage_acompte}` !== "0"
                    ? devis.nombre_de_prelevement
                    : devis.nombre_de_prelevement - 1
                  : "-"}{" "}
              </strong>
              mois.
            </>
          }
          bg="#FE007A"
        ></BlockText>
      </Box>
      <DashboardRecapTitle title={"Information du " + (isModeContrat ? "contrat" : "devis")} />

      <Grid width={"full"} gap="small" columns={["1/2", "1/2"]} pad="small">
        <Line
          leftLabel="Fournisseur"
          rightChildren={
            <CustomSelect
              style={{ width: "100%" }}
              placeholder="Fournisseur"
              options={["UTWIN", "IASSUR", "APIVIA", "APRIL", "UGIP", "Néoliane"]}
              value={devis.fournisseur}
              onChange={({ option }) =>
                updateDevis("fournisseur", option)
              }
            />
          }
        />
        <Line
          leftLabel="Assureur"
          rightChildren={
            <CustomSelect
              style={{ width: "100%" }}
              placeholder="Assureur"
              options={[
                "AFI ESCA",
                "ALLIANZ",
                "AXA",
                "APRIL",
                "AXERIA",
                "CARDIF",
                "CKB",
                "CNP assurance",
                "FIDELIDAD",
                "GENERALI",
                "GROUPEMNCAP",
                "MACIF",
                "MUTLOG",
                "Malakoff Humanis",
                "NEOLIANE",
                "Prévoir Vie",
                "UGIP"
              ]}
              value={devis.assureur}
              onChange={({ option }) => updateDevis("assureur", option)}
            />
          }
        />
        <Line
          leftLabel={`Première prime année (€)`}
          rightChildren={
            <CustomInputV2
              value={devis.premiere_prime_annee}
              onChange={(event) => {
                onAmountChanged(
                  event,
                  (amount) => {
                    updateDevisOrigin("premiere_prime_annee", amount);
                    if (
                      typeof amount.toFixed === "function" &&
                      devis.taux_commision &&
                      typeof devis.taux_commision.toFixed === "function"
                    ) {
                      let _commision =
                        (amount * devis.taux_commision) / 100;
                      updateDevisOrigin(
                        "commision",
                        _commision.toFixed(2),
                      );
                    }
                  },
                  showMessage,
                  (nochanged) => {
                    updateDevisOrigin("premiere_prime_annee", nochanged);
                  },
                );
              }}
            />
          }
        />
        <Line
          leftLabel={`Taux de commision (%)`}
          rightChildren={
            <CustomInputV2
              value={devis.taux_commision}
              onChange={(event) => {
                onAmountChanged(
                  event,
                  (amount) => {
                    updateDevisOrigin("taux_commision", amount);
                    if (
                      typeof amount.toFixed === "function" &&
                      devis.premiere_prime_annee &&
                      typeof devis.premiere_prime_annee.toFixed ===
                      "function"
                    ) {
                      let _commision =
                        (amount * devis.premiere_prime_annee) / 100;
                      updateDevisOrigin(
                        "commision",
                        _commision.toFixed(2),
                      );
                    }
                  },
                  showMessage,
                  (nochanged) => {
                    updateDevisOrigin("taux_commision", nochanged);
                  },
                );
              }}
            />
          }
        />

        <Line
          leftLabel={`Taux effectif global`}
          rightChildren={
            <CustomInputV2
              value={devis.taux_effectif_global}
              onChange={(event) => {
                updateDevisOrigin(
                  "taux_effectif_global",
                  event.target.value,
                );
              }}
            />
          }
        />

        <Line
          leftLabel={`Taux endettement`}
          rightChildren={
            <CustomInputV2
              value={devis.taux_endettement}
              onChange={(event) => {
                updateDevisOrigin(
                  "taux_endettement",
                  event.target.value,
                );
              }}
            />
          }
        />

        {/* <Line
                leftLabel={`Commision (€)`}
                rightChildren={
                  <CustomInput style={{color: "#000", opacity: 0.8}} disabled={true} value={devis.commision} />
                }
              /> */}
        {isModeContrat && (
          <Line
            leftLabel="Numéro de contrat"
            rightChildren={
              <CustomInputV2
                value={devis.numero_contrat}
                onChange={(ev) =>
                  updateDevisOrigin("numero_contrat", ev.target.value)
                }
              />
            }
          />
        )}
        <Line
          leftLabel="Date d'effet"
          rightChildren={
            <CustomInputV2
              type="date"
              value={devis.date_effet ? moment(devis.date_effet).add(2, 'hours').toISOString().split("T")[0] : undefined}
              onChange={(e) => updateDevisOrigin("date_effet", e.target.value)}
            />
          }
        />
      </Grid>
      <Grid width={"full"} pad="small" gap="small" columns={["1/2", "1/2"]}>
        <Box>
          <Text weight={600} margin="0 0 10px 0">
            Indiquer les garanties présente au devis
          </Text>
          <Box
            gap="medium"
            direction="row"
            justify="between"
            style={{ width: "100%" }}
          >
            <CheckBox
              style={{
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
              justify="center"
              label={`Décès`}
              checked={devis.garantie_deces}
              onChange={(event) => {
                updateDevisOrigin("garantie_deces", event.target.checked);
              }}
            />
          </Box>
          <Box
            gap="medium"
            direction="row"
            justify="between"
            style={{ width: "100%" }}
          >
            <CheckBox
              style={{
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
              justify="center"
              label={`PTIA`}
              checked={devis.garantie_ptia}
              onChange={(event) => {
                updateDevisOrigin("garantie_ptia", event.target.checked);
              }}
            />
          </Box>
          <Box
            gap="medium"
            direction="row"
            justify="between"
            style={{ width: "100%" }}
          >
            <CheckBox
              style={{
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
              justify="center"
              label={`ITT`}
              checked={devis.garantie_itt}
              onChange={(event) => {
                updateDevisOrigin("garantie_itt", event.target.checked);
              }}
            />
          </Box>
          <Box
            gap="medium"
            direction="row"
            justify="between"
            style={{ width: "100%" }}
          >
            <CheckBox
              style={{
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
              justify="center"
              label={`IPT`}
              checked={devis.garantie_ipt}
              onChange={(event) => {
                updateDevisOrigin("garantie_ipt", event.target.checked);
              }}
            />
          </Box>

          <Box
            gap="medium"
            direction="row"
            justify="between"
            style={{ width: "100%" }}
          >
            <CheckBox
              style={{
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
              justify="center"
              label={`IPP`}
              checked={devis.garantie_ipp}
              onChange={(event) => {
                updateDevisOrigin("garantie_ipp", event.target.checked);
              }}
            />
          </Box>

          <Box
            gap="medium"
            direction="row"
            justify="between"
            style={{ width: "100%" }}
          >
            <CheckBox
              style={{
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
              justify="center"
              label={`PE`}
              checked={devis.garantie_pe}
              onChange={(event) => {
                updateDevisOrigin("garantie_pe", event.target.checked);
              }}
            />
          </Box>
        </Box>
        <Box>
          <Text weight={600} margin="0 0 10px 0">
            Indiquer les options au devis
          </Text>
          <CheckBox
            style={{
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
            justify="center"
            label="Affections dorsales et psy sans hospitalisation"
            checked={devis.option_affections_dorsales}
            onChange={(event) => {
              updateDevisOrigin(
                "option_affections_dorsales",
                event.target.checked,
              );
            }}
          />
          <CheckBox
            style={{
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
            justify="center"
            label="Indemnisation forfaitaire"
            checked={devis.option_indemnisation_forfaitaire}
            onChange={(event) => {
              updateDevisOrigin(
                "option_indemnisation_forfaitaire",
                event.target.checked,
              );
            }}
          />
        </Box>
      </Grid>

      <DashboardRecapTitle title={"Document"} />

      {(
        <Box width={"full"} pad={"small"} gap="small">
          <Box direction="column" gap="small">
            <Box direction="row" gap="large">
              {((!isModeContrat && devis.file_devis) ||
                (isModeContrat && devis.file_contrat)) && (
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={`${isModeContrat ? devis.file_contrat : devis.file_devis
                      }`}
                  >
                    <DocumentDownload />
                    {isModeContrat ? "Contrat" : "Devis"}
                  </a>
                )}

              <FileInput
                messages={{
                  browse: "Choisir",
                  dropPrompt: isModeContrat ? "Contrat" : "Devis",
                }}
                multiple={false}
                onChange={(event) => {
                  setIsLoading(true);
                  if (isModeContrat) {
                    doUploadContratPDF(
                      event.target.files,
                      async (data) => {
                        if (data.url) {
                          updateDevis("file_contrat", data.url);
                        }
                        setIsLoading(false);
                      },
                      showMessage,
                    );
                  } else {
                    doUploadFile(
                      event.target.files,
                      async (data) => {
                        if (data.length > 0) {
                          let _uploaded = data[0];
                          updateDevis("file_devis", _uploaded.url);
                        }
                        setIsLoading(false);
                      },
                      showMessage,
                    );
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      )}

      <DashboardRecapTitle title={"Informations bancaires"} />

      <Grid columns={["1/2", "1/2"]} gap="small">
        <Box>
          <Text weight={600} margin="0 0 10px 0">
            IBAN
          </Text>
          <CustomInputV2
            placeholder="IBAN"
            value={devis.iban}
            onChange={(event) => {
              updateDevisOrigin("iban", event.target.value);
            }}
          />
        </Box>
        <Box>
          <Text weight={600} margin="0 0 10px 0">
            BIC
          </Text>
          <CustomInputV2
            placeholder="BIC"
            value={devis.bic}
            onChange={(event) => {
              updateDevisOrigin("bic", event.target.value);
            }}
          />
        </Box>
      </Grid>


      <Box gap="medium">
        <Box
          align="center"
          margin="medium"
          direction="row"
          gap="small"
          justify="center"
        >
          <PrimaryButton
            disabled={isLoading}
            label={`Sauvegarder`}
            icon={<Checkmark />}
            background="#59A4F4"
            onClick={() => {
              setIsLoading(true);
              submitModification(devis, () => setIsLoading(false));
            }}
          />
          <PrimaryButton
            disabled={isLoading}
            label={`Valider & Envoyer`}
            icon={<Checkmark />}
            onClick={() => {
              setPopupCredit(true);
            }}
          />
        </Box>
      </Box>
      {creditPopup && <CreditPopup
        devis={devis}
        setPopupCredit={setPopupCredit}
        setIsLoading={setIsLoading}
        sendEmailDevisContrat={devis => sendEmailDevisContrat(devis, () => setIsLoading(false))}
      />}
    </Box>
  );
};

const CreditPopup = ({ sendEmailDevisContrat, devis, setPopupCredit }) => {
  return (
    <Layer>
      <Box width={'medium'} gap="medium" pad={'small'} round={{ size: 'small' }} align="center" justify="center">
        <StandardText label={"Cette proposition est liée à un regroupement de crédit ?"} bold />
        <Box width={'full'} direction="row" gap="small" justify="center">

          <PrimaryButton label={'Annuler'}
            background={'#c8c8c8'}
            onClick={() => {
              setPopupCredit(false)
            }}
          />
          <PrimaryButton label={'Non'}
            background="#59A4F4"
            icon={<Close />}
            onClick={() => {
              sendEmailDevisContrat({ ...devis, is_rac_deal: false })
            }}
          /><PrimaryButton label={'Oui'}
            icon={<Checkmark />}
            onClick={() => {
              sendEmailDevisContrat({ ...devis, is_rac_deal: true })
            }}
          />
        </Box>
      </Box>
    </Layer>
  )
}

export default DevisContratForm;

import { Layer } from "grommet";
import React from "react";
import { Box, Button, Spinner, Text } from "grommet";
import { useState } from "react";
import DateInput from "../../../elements/date_input";
import { Checkmark, Close, Edit } from "grommet-icons";
import { updateEmprunteurHorsligne } from "../../../../services/emprunteur_horsligne_service";
import { hasAdminRole } from "../../../../services/storage_service";
import { getSimpleDateFR } from "../../../../services/utils";

export default function DateRealisation({devis, updateDevis, showMessage}) {
    const [dateRealisation, setDateRealisation] = useState(devis.date_realisation);

    const [popupOpen, setPopupOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const updateDate = async () => {
        setLoading(true);
        await  updateEmprunteurHorsligne({...devis, date_realisation: dateRealisation})
        setPopupOpen(false);
        setLoading(false);
        updateDevis('date_realisation', dateRealisation);
        showMessage('Date réalisation modifiée avec succès', 'success');
    }

    return (
        <Box pad={{horizontal: 'xsmall'}} style={{ border: 'none', borderRadius: 5 }} >
            {hasAdminRole() && devis.model_type === 'emprunteur_horsligne' && (
                    <InfoLineWithModif label="Date réalisation" value={getSimpleDateFR(devis.date_realisation)}
                        onUpdate={() => setPopupOpen(true)}
                    />
                )}
            {popupOpen && (
                    <Layer>
                        <Box direction='row' gap='small' width={400} justify='between' pad='small'
                        align='center'>
                            <Box fill><Text  >Date réalisation</Text></Box>
                            <Box fill >
                                <DateInput size='small'
                                    value={dateRealisation}
                                    onChange={(date) => setDateRealisation(date)}
                                    style={{ background: '#EDEDED' }}
                                    calendarProps={{
                                        locale: "fr-FR",
                                        size: "small",
                                    }}
                                />
                            </Box>

                            {!loading && <Button icon={<Checkmark color='brand' />} onClick={updateDate} />}
                            {loading && <Box pad={'xsmall'}><Spinner /></Box>}
                            {!loading && <Button icon={<Close  />} onClick={() => setPopupOpen(false)} />}
                        </Box>
                    </Layer>)}
        </Box>
    )
}


const InfoLineWithModif = ({ label, value, color, onUpdate }) => {
    let style = color ? { border: '1px solid #000', borderRadius: 5, color } : {}

    return (
        <Box direction='row' gap='small' align='center' >
            <Text style={style} size="xsmall" >{label} : <strong>{value}</strong></Text>
            <Button icon={<Edit size="small" />} onClick={onUpdate} />
        </Box>
    )
}
const axios = require('axios');
const storageService = require('./storage_service');
const { baseAPI } = require('../config/config.json');
const HEADERS = { 
    'Authorization': `Bearer ${storageService.getToken()}`
};

const submit = (devis) => {
    return axios.post(`${baseAPI}/public/assurance-vie`, devis, { headers: HEADERS });
}

const getAssuranceVies = (client_id, _query, status) => {
    let commercial_id = storageService.getCommercialId();
    let params = { commercial_id, _q: _query, status, _limit: -1 };

    let binome_id = storageService.getBinomeId();
    if(binome_id) {
        delete params.commercial_id;
        params['_where[0][commercial_id_in]'] = [commercial_id, binome_id];
    }
    if(storageService.hasAdministrativeRole()) {
        delete params.commercial_id;
        delete params['_where[0][commercial_id_in]']
    }
    
    if(client_id) {
        params['_where[1][client.id]']=client_id;
        delete params.commercial_id;
    }
    return axios.get(`${baseAPI}/public/assurance-vie`, { params, headers: HEADERS });
}

const modify = (devis) => {
    return axios.put(`${baseAPI}/public/assurance-vie/${devis.id}`, devis, { headers: HEADERS });
}

const sendForm = (devis) => {
    return axios.post(`${baseAPI}/commerciale/assurance-vie/send`, devis, { headers: HEADERS });
}

const sendSimulation = (devis) => {
  return axios.post(`${baseAPI}/commerciale/assurance-vie/sendSimulation`, devis, { headers: HEADERS });
}

const sendPresentation = (devis) => {
  return axios.post(`${baseAPI}/commerciale/assurance-vie/sendPresentation`, devis, { headers: HEADERS });
}

const getPrice = devis => {
    devis.isFromCommercial = true;
    return axios.post(`${baseAPI}/public/assurance-vie/price`, devis, { headers: HEADERS });
}

const sendYousign = (body) => {
    return axios.post(`${baseAPI}/public/assurance-vie/sendYousign`, body, { headers: HEADERS });
}

const sendRelanceSignature = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-relance-signature`, devis, { headers: HEADERS });
}
const sendRelancePayment = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-relance-payment`, devis, { headers: HEADERS });
}

module.exports = {
    submit,
    modify,
    getAssuranceVies,
    sendForm,
    getPrice,
    sendRelanceSignature,
    sendRelancePayment,
    sendYousign,
    sendSimulation,
    sendPresentation
}

import { Box } from 'grommet';
import React from 'react';
import contactService from '../../../services/contact_service';
import service from '../../../services/per_service';
import { getSorted } from '../../../services/utils';
import Refresh from '../../elements/refresh';
import TrustiPER from './trusti_per';

import AddDevis from '../../elements/add_devis';
import DateSort from '../../elements/date_sort';
import SearchComponent from '../../elements/search';
import StatusFilter from '../../elements/status_filter';
import ViewTable from '../../elements/view_table';

class TrustiPERs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contact: this.props.contact || {},
      contacts: [],
      trustiPERs: [],
      popupOpen: false,
      selectedTrustiPER: {},
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    }
  }

  componentDidMount = () => {
    this.loadTrustiPERs();
    contactService.getContact()
      .then(res => {
        this.setState({ contacts: res.data?.results })
      }).catch(err => console.log(err));
  };

  loadTrustiPERs = () => {
    this.setState({ trustiPERs: [] }, () => {
      service.getTrustiPERs(this.state.contact.id)
        .then(res => {
          this.setState({ trustiPERs: res.data })
          if (this.state.selectedTrustiPER) {
            let selectedTrustiPER = res.data.find(trustiPER => trustiPER.id === this.state.selectedTrustiPER.id);
            if (selectedTrustiPER) {
              this.onClose()
              this.setState({ selectedTrustiPER: selectedTrustiPER })
              this.openPNO(selectedTrustiPER);
            }
          }
        })
        .catch(err => {
          console.log('err', err);
          this.props.showMessage('Erreur inattendu');
        })
    })
  }

  onClose = () => { this.setState({ popupOpen: false }) }

  openPNO = (trustiPER) => {
    this.setState({ selectedTrustiPER: trustiPER, popupOpen: true });
  }

  addPNOe = () => this.openPNO({ assure: {}, location: {} })

  search = (query, status) => {
    service.getTrustiPERs(this.state.contact.id, query, status)
      .then(res => {
        if (res.data?.results) {
          this.setState({ trustiPERs: res.data?.results })
        } else if (res.data) {
          this.setState({ trustiPERs: res.data })
        }
      })
      .catch(err => {
        console.log('err', err);
        this.props.showMessage('Erreur inattendu');
      })
  }

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  render() {
    return (
      this.state.popupOpen ?
        <TrustiPER
          showMessage={this.props.showMessage}
          devis={this.state.selectedTrustiPER}
          onClose={this.onClose}
          reload={this.loadTrustiPERs}
          contacts={this.state.contacts}
        />
        :
        <Box style={{ width: "100%" }}
          align="center" justify="center"
          alignSelf="center"
          alignContent="center" gap="medium">
          <Box width="full" gap="medium" pad={'small'} align='center' justify='center'>
            <Box width="xlarge" gap="medium" align='center' justify='center'>

              {!this.state.contact.id &&
                <>
                  <Box justify="center" direction='row' alignSelf="center" align="center" gap="medium">
                    <Refresh refresh={this.loadTrustiPERs} />
                    <SearchComponent
                      search={value => {
                        this.search(value, this.state.filter.status);
                      }}
                      query={this.state.filter._query}
                      updateQuery={value => this.updateFilter('_query', value)}
                    />
                    <AddDevis onAdd={this.addPNOe} />
                  </Box>
                  <Box justify="between" direction='row' align="center" gap="medium" alignSelf="center">
                    <StatusFilter
                      status={this.state.filter.status}
                      search={value => {
                        this.updateFilter('status', value);
                        this.search(this.state.filter._query, value);
                      }}
                    />
                    <DateSort
                      onChange={value => this.updateFilter('sort', value)}
                    />

                  </Box>
                </>
              }
            </Box>
            {this.state.contact.id && <Box style={{ height: 30 }} />}
            <ViewTable
              reports={getSorted(this.state.trustiPERs, this.state.filter.sort)}
              commission_taux={this.props.commission_taux}
              onDevisSelected={this.openPNO}
              commerciaux={this.props.commerciaux}
            />
          </Box>
        </Box>
    )
  }
}


export default TrustiPERs;
import {
  Box, Grid, Text
} from "grommet";
import React from "react";

const InfoVenteMark = ({ text }) => {
  if (!text)
    return null;
  return (
    <Box margin="6px 0" background="brand" pad="5px 25px" width="max-content" style={{ borderRadius: "10px" }}>
      <Text color="white" weight={600} textAlign="center">{text}</Text>
    </Box>
  );
};

const InfoVente = ({ devis }) => {
  console.log("devis", devis);
  return (
    <Box width="full">
      <Grid
        columns={["1/2", "1/2"]} gap="small" pad={"small"} width="100%"
      >
        <Box>
          <Text color="grey">Avez-vous emprunté a plusieurs ?</Text>
          <InfoVenteMark text={devis.emprunteurs?.length > 1 ? "Oui" : "Non"} />
        </Box>
        <Box>
          <Text color="grey">Combien y a-t-il de co-emprunteur ?</Text>
          <InfoVenteMark text={devis.emprunteurs?.filter(a => a.firstname)?.length ?? 1} />
        </Box>
        <Box>
          <Text color="grey">Le total de tous vos crédits est-il supérieur à 200 000 € ?</Text>
          <InfoVenteMark text={devis.credit_gt_200k ? "Oui" : "Non"} />
        </Box>
      </Grid>
    </Box>
  );
}

export default InfoVente;

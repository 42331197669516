const axios = require('axios');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const moment = require('moment');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const getSinistres = (filter = { agence: '', query: '', start: 0 }) => {
    const { agence, query, start } = filter;
    const limit = 20;

    let params = {
        _start: start,
        _limit: limit
    }
    if (agence) {
        params['_where[0][agence.id]'] = agence.id;
    }
    if (query) {
        params['_q'] = query;
    }
    return axios.get(`${baseAPI}/v1/commercials/sinistres`, { params, headers: HEADERS })
}


const countSinistres = (filter = { agence: '', query: '' }) => {
    const { agence, query } = filter;

    let params = {}
    if (agence) {
        params['_where[0][agence.id]'] = agence.id;
    }
    if (query) {
        params['_q'] = query;
    }
    return axios.get(`${baseAPI}/v1/commercials/sinistres/count`, { params, headers: HEADERS })
}

const deleteSinistre = (id) => {
    return axios.delete(`${baseAPI}/v1/commercials/sinistres/${id}`, {}, { headers: HEADERS });
}

const updateSinistre = (sinistre) => {
    Object.keys(sinistre).forEach(key => {
        if(key.startsWith('date_') && sinistre[key]) {
            sinistre[key] = moment(sinistre[key]).format('YYYY-MM-DD');
        }
    })
    return axios.put(`${baseAPI}/v1/commercials/sinistres/${sinistre.id}`, sinistre, { headers: HEADERS });
}

const createSinistre = (sinistre) => {
    Object.keys(sinistre).forEach(key => {
        if(key.startsWith('date_') && sinistre[key]) {
            sinistre[key] = moment(sinistre[key]).format('YYYY-MM-DD');
        }
    })
    return axios.post(`${baseAPI}/sinistre`, sinistre, { headers: HEADERS });
}

const sendSinistreForm = (sinistre, test=false) => {
    return axios.post(`${baseAPI}/v1/commercials/sinistres/send-form`, {sinistre, test}, { headers: HEADERS });
}

const getSinistreGaranties = (productRef) => {
    return axios.get(`${baseAPI}/v1/commercials/sinistres/garanties`, { params: {productRef}, headers: HEADERS });
}

module.exports = {
    getSinistres,
    countSinistres,
    updateSinistre,
    createSinistre, 
    deleteSinistre,
    sendSinistreForm,
    getSinistreGaranties
}
import { Box, Text, Heading } from "grommet";
import DateInput from "../../../elements/date_input";
import React from "react";
import CustomInput from "../../../elements/custom_input";
import ToggleButton from "../../../elements/toggle_button/toggle_button";
import { BIEN_TYPE, FRACTIONNEMENT_TYPE } from "../../../../services/types";
import ClickableCards from "../../../elements/clickable_cards";
import house from "../../../../images/house.png";
import appt from "../../../../images/appt.png";
import Line2 from "../../../elements/line_container";

const CustomText = ({ label, textAlign }) => (
  <Text style={{ fontWeight: "bold" }} size="small" textAlign={textAlign}>
    {label}
  </Text>
);

const Line = ({ leftLabel, groups }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "40%" }}>
      <CustomText label={leftLabel + " :"} textAlign="end" />
    </Box>
    <Box style={{ width: "60%" }}>
      <ToggleButton groups={groups} />
    </Box>
  </Box>
);
const GenericLine = ({ leftLabel, rightPart }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "40%" }}>
      <CustomText label={leftLabel + " :"} textAlign="end" />
    </Box>
    <Box style={{ width: "60%" }}>{rightPart}</Box>
  </Box>
);
const LEVELS = {
  one: "1 niveau",
  two: "2 niveaux",
  three: "3 niveaux et plus",
};
const CONSTRUCTION_YEAR = {
  old: "Avant 1981",
  recent: "Depuis 1981",
  new: "En construction",
};
const CONSTRUCTION_NATURE = {
  traditionnel: "Traditionnel",
  ossature_en_bois: "Ossature en bois",
  autres_materiaux: "Autres matériaux",
};
const YES_NO = [true, false];
const DEPENDENCES = [
  "Pas de dépendance",
  "Inférieur à 100 m²",
  "De 101 m² à 200 m²",
  "De 201 m² à 300 m²",
  "De 301 m² à 400 m²",
  "De 401 m² à 500 m²",
];
const FRANCHISE = ["Maxi", "Moyen", "Mini"];
const ABONNEMENT = [FRACTIONNEMENT_TYPE.annual, FRACTIONNEMENT_TYPE.monthly];
const LocationInfo = ({ devis, updateDevis, next }) => {
  const updateLocationInfo = (field, value) => {
    let location = devis.location;
    location[field] = value;
    updateDevis("location", location);
  };
  const getTotalRooms = () => {
    let nombrePieces = parseInt(devis.location.totalRooms);
    let btw40and80 = parseInt(devis.location.btw40and80);
    let btw80and120 = parseInt(devis.location.btw80and120);
    let moreThan120 = parseInt(devis.location.moreThan120);

    return (
      (isNaN(nombrePieces) ? 0 : nombrePieces) +
      (isNaN(btw40and80) ? 0 : btw40and80) +
      (isNaN(btw80and120) ? 0 : 2 * btw80and120) +
      (isNaN(moreThan120) ? 0 : 3 * moreThan120)
    );
  };

  return (
    <Box
      gap="small"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Heading level="2" color="#59A4F4" textAlign="center">
        Assurance PNO Gîte
      </Heading>
      <Box style={{ height: 20 }} />
      <Box width="large" gap="medium">
        <Line2
          leftLabel="Quel type de logement"
          rightChildren={
            <ClickableCards
              cards={[
                {
                  img: house,
                  text: BIEN_TYPE.maison,
                  selected:
                    devis.bien_type === BIEN_TYPE.maison ? "solid" : "none",
                  onClick: () => updateDevis("bien_type", BIEN_TYPE.maison),
                },
                {
                  img: appt,
                  text: BIEN_TYPE.appartement,
                  selected:
                    devis.bien_type === BIEN_TYPE.appartement
                      ? "solid"
                      : "none",
                  onClick: () =>
                    updateDevis("bien_type", BIEN_TYPE.appartement),
                },
              ]}
            />
          }
        />
        <Line
          leftLabel={"Nombre de niveaux"}
          groups={[
            {
              label: LEVELS.one,
              selected: devis.location.level === LEVELS.one,
              onClick: () => updateLocationInfo("level", LEVELS.one),
            },
            {
              label: LEVELS.two,
              selected: devis.location.level === LEVELS.two,
              onClick: () => updateLocationInfo("level", LEVELS.two),
            },
            {
              label: LEVELS.three,
              selected: devis.location.level === LEVELS.three,
              onClick: () => updateLocationInfo("level", LEVELS.three),
            },
          ]}
        />
        <Line
          leftLabel={"Année de construction"}
          groups={[
            {
              label: CONSTRUCTION_YEAR.old,
              selected:
                devis.location.constructionYear === CONSTRUCTION_YEAR.old,
              onClick: () =>
                updateLocationInfo("constructionYear", CONSTRUCTION_YEAR.old),
            },
            {
              label: CONSTRUCTION_YEAR.recent,
              selected:
                devis.location.constructionYear === CONSTRUCTION_YEAR.recent,
              onClick: () =>
                updateLocationInfo(
                  "constructionYear",
                  CONSTRUCTION_YEAR.recent
                ),
            },
            {
              label: CONSTRUCTION_YEAR.new,
              selected:
                devis.location.constructionYear === CONSTRUCTION_YEAR.new,
              onClick: () =>
                updateLocationInfo("constructionYear", CONSTRUCTION_YEAR.new),
            },
          ]}
        />
        <Line
          leftLabel={"Nature de construction"}
          groups={[
            {
              label: CONSTRUCTION_NATURE.traditionnel,
              selected:
                devis.location.constructionNature ===
                CONSTRUCTION_NATURE.traditionnel,
              onClick: () =>
                updateLocationInfo(
                  "constructionNature",
                  CONSTRUCTION_NATURE.traditionnel
                ),
            },
            {
              label: CONSTRUCTION_NATURE.ossature_en_bois,
              selected:
                devis.location.constructionNature ===
                CONSTRUCTION_NATURE.ossature_en_bois,
              onClick: () =>
                updateLocationInfo(
                  "constructionNature",
                  CONSTRUCTION_NATURE.ossature_en_bois
                ),
            },
            {
              label: CONSTRUCTION_NATURE.autres_materiaux,
              selected:
                devis.location.constructionNature ===
                CONSTRUCTION_NATURE.autres_materiaux,
              onClick: () =>
                updateLocationInfo(
                  "constructionNature",
                  CONSTRUCTION_NATURE.autres_materiaux
                ),
            },
          ]}
        />
        <Line
          leftLabel={"Nombre de logement même propriétaire, même adresse"}
          groups={Array(7)
            .fill()
            .map((v, index) => index + 1)
            .map((value) => ({
              label: value,
              selected: devis.location.sameOwnerNumber === value,
              onClick: () => updateLocationInfo("sameOwnerNumber", value),
            }))}
        />
        <Line
          leftLabel={"Nombre de pièces"}
          groups={Array(9)
            .fill()
            .map((v, index) => index + 1)
            .map((value) => ({
              label: value,
              selected: devis.location.totalRooms === value,
              onClick: () => updateLocationInfo("totalRooms", value),
            }))}
        />
        <GenericLine
          leftLabel="Parmi ces pièces, combien ont une superficie"
          rightPart={
            <Box style={{ width: "100%" }} gap="xsmall">
              <Box
                style={{ width: "100%" }}
                direction="row"
                align="center"
                gap="small"
              >
                <Box style={{ width: "30%" }}>
                  <CustomText label="> 40 m² et ≤ 80 m² ? " textAlign="end" />
                </Box>
                <Box style={{ width: "30%" }}>
                  <CustomInput
                    value={devis.location.btw40and80}
                    onChange={(event) =>
                      updateLocationInfo("btw40and80", event.target.value)
                    }
                  />
                </Box>
              </Box>
              <Box
                style={{ width: "100%" }}
                direction="row"
                align="center"
                gap="small"
              >
                <Box style={{ width: "30%" }}>
                  <CustomText label="80 m² et ≤ 120 m² ? " textAlign="end" />
                </Box>
                <Box style={{ width: "30%" }}>
                  <CustomInput
                    value={devis.location.btw80and120}
                    onChange={(event) =>
                      updateLocationInfo("btw80and120", event.target.value)
                    }
                  />
                </Box>
              </Box>
              <Box
                style={{ width: "100%" }}
                direction="row"
                align="center"
                gap="small"
              >
                <Box style={{ width: "30%" }}>
                  <CustomText label=" > 120 m² ? " textAlign="end" />
                </Box>
                <Box style={{ width: "30%" }}>
                  <CustomInput
                    value={devis.location.moreThan120}
                    onChange={(event) =>
                      updateLocationInfo("moreThan120", event.target.value)
                    }
                  />
                </Box>
              </Box>
            </Box>
          }
        />
        <GenericLine
          leftLabel="Nombre total de pièces tarifées au contrat"
          rightPart={<CustomText label={getTotalRooms()} textAlign="start" />}
        />
        <Line
          leftLabel={"Dont véranda / balcon fermé"}
          groups={YES_NO.map((value) => ({
            label: value === true ? "Oui" : "Non",
            selected: devis.location.veranda === value,
            onClick: () => updateLocationInfo("veranda", value),
          }))}
        />
        <Line
          leftLabel={"Avez-vous une cheminée à foyer fermé ou un poêle"}
          groups={YES_NO.map((value) => ({
            label: value === true ? "Oui" : "Non",
            selected: devis.location.cheminee === value,
            onClick: () => updateLocationInfo("cheminee", value),
          }))}
        />
        <Line
          leftLabel={"Avez-vous un Spa et/ou un Jacuzzi"}
          groups={YES_NO.map((value) => ({
            label: value === true ? "Oui" : "Non",
            selected: devis.location.jacuzzi === value,
            onClick: () => updateLocationInfo("jacuzzi", value),
          }))}
        />
        <Line
          leftLabel={"Êtes-vous équipé d’énergies renouvelables"}
          groups={YES_NO.map((value) => ({
            label: value === true ? "Oui" : "Non",
            selected: devis.location.energieRenouvelable === value,
            onClick: () => updateLocationInfo("energieRenouvelable", value),
          }))}
        />
        <Line
          leftLabel={"Avez-vous une piscine"}
          groups={YES_NO.map((value) => ({
            label: value === true ? "Oui" : "Non",
            selected: devis.location.piscine === value,
            onClick: () => updateLocationInfo("piscine", value),
          }))}
        />
        <Line
          leftLabel={"Avez-vous équipé d’une alarme avec télésurveillance"}
          groups={YES_NO.map((value) => ({
            label: value === true ? "Oui" : "Non",
            selected: devis.location.teleSurveillance === value,
            onClick: () => updateLocationInfo("teleSurveillance", value),
          }))}
        />
        <GenericLine
          leftLabel={"Superficie des dépendances"}
          rightPart={
            <Box style={{ width: "100%" }} gap="small">
              <ToggleButton
                groups={DEPENDENCES.slice(0, 3).map((value) => ({
                  label: value,
                  selected: devis.location.dependence === value,
                  onClick: () => updateLocationInfo("dependence", value),
                }))}
              />
              <ToggleButton
                groups={DEPENDENCES.slice(3, 6).map((value) => ({
                  label: value,
                  selected: devis.location.dependence === value,
                  onClick: () => updateLocationInfo("dependence", value),
                }))}
              />
            </Box>
          }
        />
        <GenericLine
          leftLabel={"Indiquer le capital mobilier"}
          rightPart={
            <Box style={{ width: "30%" }}>
              <CustomInput
                value={devis.location.capital}
                placeholder="€"
                onChange={(event) =>
                  updateLocationInfo("capital", event.target.value)
                }
              />
            </Box>
          }
        />
        <Line
          leftLabel={"Choix du niveau de franchise"}
          groups={FRANCHISE.map((value) => ({
            label: value,
            selected: devis.location.franchise === value,
            onClick: () => updateLocationInfo("franchise", value),
          }))}
        />
        <GenericLine
          leftLabel={"Date à laquelle vous souhaitez débuter votre assurance"}
          rightPart={
            <DateInput
              placeholder="Date début"
              value={devis.begin_date}
              onChange={(date) => updateDevis("begin_date", date)}
            />
          }
        />

        <GenericLine
          leftLabel={"Date à laquelle vous souhaitez débuter votre assurance"}
          rightPart={
            <DateInput
              value={devis.date_resiliation}
              onChange={(date) => updateDevis("date_resiliation", date)}
            />
          }
        />
        <Line
          leftLabel={"Comment sauter vous régler votre assurance"}
          groups={ABONNEMENT.map((value) => ({
            label: value,
            selected: devis.location.abonnement === value,
            onClick: () => {
              updateLocationInfo("abonnement", value);
              updateDevis("fractionnement_type", value);
            },
          }))}
        />
        {/* <Box
        width="medium"
        align="center"
        alignSelf="center"
        margin="medium"
        gap="medium"
      >
        <PrimaryButton label="Suivant" background="#59A4F4" onClick={next} />
      </Box> */}
      </Box>
      <Box style={{ height: 100 }} />
    </Box>
  );
};

export default LocationInfo;

import { Box, Button, Card, DataTable, Layer, Text } from 'grommet';
import { Add } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { getSimpleDateTimeFR, toCurrency } from '../../../../services/utils';
import AddDevis from '../../../elements/add_devis';
import { CustomInputV2 } from '../../../elements/custom_input';
import CustomPagination from '../../../elements/custom_pagination';
import Refresh from '../../../elements/refresh';
import CustomSelect from '../../../elements/select_fill';
import products from '../../utils/types';
import { countGaranties, createGarantie, getCompanies, getGaranties } from '../service';

class VoyageGaranties extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            garanties: [],
            compagnies: [],
            filter: {},
            total: 0,
            startIndex: 0,
            selected: null
        };
    }

    componentDidMount() {
        this.loadGaranties();
    }

    loadGaranties = () => {
        getGaranties(this.state.startIndex, this.state.filter)
            .then((res) => {
                this.setState({ garanties: res.data });
            })
            .catch((err) => {
                console.error(err);
            });
        countGaranties(this.state.filter)
            .then(res => this.setState({ total: res.data }));
        
        getCompanies()
            .then(res => this.setState({ compagnies: res.data }));
    }

    render() {
        return (

            <Box style={{ width: "100%" }}
                align="center" justify="center"
                alignSelf="center"
                alignContent="center" gap="medium">
                <Box style={{ width: "100%" }} gap="small" pad={"smal"} justify='center' align='center'>
                    <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium" width={'full'}>
                        <Refresh refresh={() => {
                            this.loadGaranties();
                        }}></Refresh>


                        <Card pad={'small'} gap='small'>
                            <Box direction='row' justify="between" alignSelf="center" align="center" gap="medium" width={'full'}>
                                <CustomSelect
                                    options={this.state.compagnies}
                                    placeholder='Compagnie'
                                    clear
                                    onChange={({ option }) => {
                                        this.setState({ filter: { ...this.state.filter, compagny: option } }, () => this.loadGaranties());
                                    }}
                                />

                            </Box>
                        </Card>


                        <Card pad={'small'} gap='small'>
                            <Box direction='row' justify="between" alignSelf="center" align="center" gap="medium" width={'full'}>
                                <CustomSelect
                                    options={products}
                                    placeholder='Produit'
                                    clear
                                    onChange={({ option }) => {
                                        this.setState({ filter: { ...this.state.filter, produit: option } }, () => this.loadGaranties());
                                    }}
                                />

                            </Box>
                        </Card>

                        <AddDevis onAdd={() => this.setState({ selected: {} })} />
                    </Box>
                    <Box style={{ width: "100%" }}>
                        <CustomPagination total={this.state.total} onNextPage={this.onNextPage} startIndex={this.state.startIndex} />
                    </Box>
                    <DataTable
                        columns={[
                            {
                                property: 'createdAt',
                                header: <Text size="small"><strong>Création</strong></Text>,
                                render: garantie => <Text size="xsmall" ><strong>{getSimpleDateTimeFR(garantie.createdAt)}</strong></Text>
                            },
                            {
                                property: 'compagny',
                                header: <Text size="small">Compagnie</Text>,
                                render: garantie => <Text size="small">{garantie.compagny}</Text>
                            },
                            {
                                property: 'produit',
                                header: <Text size="small">Produit</Text>,
                                render: garantie => <Text size="small">{garantie.produit}</Text>
                            },
                            {
                                header: <Text size="small">Garanties</Text>,
                                render: garantie => <Box>
                                    {garantie.garanties?.map((gar, index) => {
                                        return (
                                            <>
                                                <Text key={index} size={"small"}>
                                                    {index + 1}. {gar.name} - {toCurrency(gar.plafond)} - {toCurrency(gar.franchise)}
                                                </Text>
                                            </>
                                        )
                                    })}
                                </Box>
                            },
                            {
                                header: '',
                                render: garantie => <Button label="voir" primary onClick={() => this.setState({selected: garantie})} />
                            },
                        ]}
                        data={this.state.garanties}
                    />
                </Box >
                {this.state.selected && <Garantie garantie={this.state.selected}
                    onClose={() => this.setState({ selected: null })}
                    onSaved={(garantie) => {
                        this.setState({ garanties: [...this.state.garanties, garantie] });
                    }}
                    showMessage={this.props.showMessage}
                />}
            </Box >
        );
    }
}

const Garantie = ({ garantie, onClose, onSaved, showMessage }) => {
    const [values, setValues] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (garantie) {
            setValues(garantie);
        }
    }, [garantie]);

    const save = () => {
        setLoading(true);
        createGarantie(values).then((res) => {
            onSaved(res.data);
            onClose();
            setLoading(false);
        })
            .catch((err) => {
                console.error(err);
                showMessage('Erreur lors de la sauvegarde', 'error');
                setLoading(false);
            });
    }

    return (
        <Layer>
            <Box width={'large'} pad={"medium"} gap='small'>
                <Line
                    leftLabel={"Compagnie"}
                    rightChildren={
                        <CustomInputV2
                            size='small'
                            value={values.compagny}
                            onChange={(e) => {
                                setValues({ ...values, compagny: e.target.value });
                            }} />
                    }
                />
                <Line
                    leftLabel={"Produit"}
                    rightChildren={
                        <CustomSelect
                            options={products}
                            placeholder='Produit'
                            size={"xsmall"}
                            onChange={({ option }) => {
                                setValues({ ...values, produit: option });
                            }}
                            value={values.produit}
                        />
                    }
                />
                <Text size="small" ><strong>GARANTIES :</strong></Text>
                {values.garanties?.map((garantie, index) => {
                    return (
                        <Box key={index} gap="small" direction='row'>
                            <LineColumn
                                leftLabel={"Nom"}
                                rightChildren={
                                    <CustomInputV2
                                        size='small'
                                        value={garantie.name}
                                        onChange={(e) => {
                                            const newGaranties = values.garanties;
                                            newGaranties[index].name = e.target.value;
                                            setValues({ ...values, garanties: newGaranties });
                                        }} />
                                }
                            />
                            <LineColumn
                                leftLabel={"Plafond"}
                                rightChildren={
                                    <CustomInputV2
                                        size='small'
                                        value={garantie.plafond}
                                        onChange={(e) => {
                                            const newGaranties = values.garanties;
                                            newGaranties[index].plafond = e.target.value;
                                            setValues({ ...values, garanties: newGaranties });
                                        }} />
                                }
                            />
                            <LineColumn
                                leftLabel={"Franchise"}
                                rightChildren={
                                    <CustomInputV2
                                        size='small'
                                        value={garantie.franchise}
                                        onChange={(e) => {
                                            const newGaranties = values.garanties;
                                            newGaranties[index].franchise = e.target.value;
                                            setValues({ ...values, garanties: newGaranties });
                                        }} />
                                }
                            />
                        </Box>
                    )
                })}
                <Box direction='row' gap='small'
                    onClick={() =>
                        setValues({
                            ...values,
                            garanties: [
                                ...(values.garanties || []),
                                { name: "", plafond: 0, franchise: 0 }
                            ]
                        })
                    }

                >
                    <Add
                        color='brand'
                    />
                    <Text size="small">Ajouter une garantie</Text>
                </Box>

                <Box direction='row' gap='small' justify='end'>
                    <Button label="Enregistrer" primary onClick={save} disabled={loading} />
                    <Button label="Annuler" onClick={onClose} />
                </Box>

            </Box>
        </Layer>
    )
}

const Line = ({ leftLabel, rightChildren, direction = 'row' }) => (
    <Box direction={direction} gap="medium" style={{ width: '100%' }} align="center" alignSelf="center">
        {direction === 'row' && <Text size="small" ><strong>{leftLabel} :</strong></Text>}
        <Box width={'70%'}>
            {rightChildren}
        </Box>
    </Box>
)

const LineColumn = ({ leftLabel, rightChildren }) => (
    <Box gap="xxsmall" style={{ width: '100%' }} >
        <Text size="small" >{leftLabel} :</Text>
        {rightChildren}
    </Box>
)

export default VoyageGaranties;
import React from "react";
import { Box, Heading, Text, Card, Stack } from "grommet";
import { getSimpleDateFR } from "../../../../services/utils";
import RecapStatus from "../../../elements/devis_status";
import { DocumentDownload } from "grommet-icons";
import StatusDateAppel from "../../../elements/status_date_appel";
import PanelAppel from "../../../elements/panel_appel";
import EmailEventTracker from "../../../elements/email_tracker";
import StripeCustomer from "../../../elements/stripe_customer";
import CodePromo from "../../../elements/code_promo";
import ApporteurGestionnaire from "../../../elements/apporteur_gestionaire";
import TrustiBot from "../../../trustibot";
import FileRecap from "../../../elements/file_recap";
import DateRecap from "../../../elements/date_recap";

const Recap = ({ devis, toEditMode, close, onSave, sendForm, updateDevis }) => {
  return (
    <Box
      direction="row"
      gap='small'
      style={{ width: "100vw" }}
    >
      <TrustiBot></TrustiBot>
      <Box
        gap="medium"
        // style={{ width: "100%" }}
        align="center"
        alignSelf="center"
        pad="small"
      >
        <Heading level="2" color="#59A4F4" textAlign="center">
          Trusti Santé
        </Heading>
        <Box direction="row" fill="horizontal" justify="center" alignContent="center">
          <Box align="center">
            <StatusDateAppel devis={devis} />
          </Box>
          <Box>
            <Box alignSelf="end">
              <RecapStatus status={devis.status} />
              <PanelAppel devis={devis} updateDevis={updateDevis} onSave={onSave} />
            </Box>
          </Box>
        </Box>
        <Box  gap="small" width={'full'} justify='center' align="center">
          <Card  pad="small" gap="small" width={'full'} >
            <Text textAlign="center"><b>ID:</b> {devis.id}</Text> 
          </Card>
        </Box>
        <Box  gap="small" width={'full'}>
          <DateRecap deal={devis} />
        </Box>
        <CodePromo devis={devis} />
        <Box width={'xlarge'}>
          <StripeCustomer devisId={devis.id} />
        </Box>
        <Box width="xlarge" gap="small">
          <Box width="xlarge" direction="row" gap="small" justify="center">
            <Card pad="small" width={"100%"}>
              <Assure assure={devis.client || {}} />
            </Card>
            <Card pad="small" width={"100%"}>
              <Assurance devis={devis} />
            </Card>
          </Box>

          {devis.assures.map((assure, index) => (
            <Card pad="small" key={index}>
              <AssureComplement assure={assure} />
            </Card>
          ))}
        </Box>

        <Box width="xlarge" direction="row" gap="small">
          <FileRecap devis={devis} />
        </Box>
        {(devis.apporteur || devis.affiliation) && <Card pad={"small"} width={'xlarge'} >
          <ApporteurGestionnaire apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
        </Card>}
        <Box width={'xlarge'}>
          <EmailEventTracker messageIds={devis.emailEventIds} />
        </Box>
        <Box style={{ height: 40 }} />
      </Box>
    </Box>
  );
};

const Assurance = ({ devis }) => {
  return (
    <Box gap="small">
      <SubHeading label="Informations complémentaires de l'assuré" />
      <Line leftLabel="Régime obligatoire" rightLabel={devis.regime} />
      <Line leftLabel="Statut" rightLabel={devis.assure_statut} />
    </Box>
  );
};

const CustomText = ({ label, textAlign, isBold }) => (
  <Text
    style={{ fontWeight: isBold ? "bold" : "normal" }}
    size="small"
    textAlign={textAlign || "end"}
  >
    {label}
  </Text>
);
const Line = ({ leftLabel, rightLabel }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "50%" }}>
      <CustomText label={leftLabel + " :"} />
    </Box>
    <Box style={{ width: "50%" }}>
      <CustomText label={rightLabel} textAlign="start" isBold={true} />
    </Box>
  </Box>
);
const Assure = ({ assure }) => {
  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label={`Informations de l'assuré`} />
      <Line leftLabel="Civilité" rightLabel={assure.civilite} />
      {assure.civilite !== "Société" && (
        <Line leftLabel="Prénom" rightLabel={assure.firstname} />
      )}
      {assure.civilite !== "Société" && (
        <Line leftLabel="Nom" rightLabel={assure.lastname} />
      )}
      {assure.civilite === "Société" && (
        <Line leftLabel="Raison social" rightLabel={assure.raisonSocial} />
      )}
      <Line leftLabel="Mail" rightLabel={assure.email} />
      <Line leftLabel="Tel" rightLabel={assure.phone} />
      <Line
        leftLabel="Adresse postale"
        rightLabel={`${assure.address}, ${assure.zip}, ${assure.city}`}
      />
      <Line
        leftLabel="Date de naissance"
        rightLabel={`${assure.date_of_birth ? getSimpleDateFR(assure.date_of_birth) : ""
          }`}
      />
    </Box>
  );
};

const AssureComplement = ({ assure }) => {
  return (
    <Box gap="small" justify="center">
      <SubHeading label={`Informations de ${assure.type || "l'assuré"}`} />
      <Line leftLabel="Nom" rightLabel={assure.lastname} />
      <Line leftLabel="Prénom" rightLabel={assure.firstname} />
      <Line
        leftLabel="Date de naissance"
        rightLabel={getSimpleDateFR(assure.date_of_birth)}
      />
      <Line leftLabel="Régime obligatoire" rightLabel={assure.regime} />
      <Line leftLabel="Statut" rightLabel={assure.assure_statut} />
    </Box>
  );
};

const SubHeading = ({ label }) => (
  <Text textAlign="center" style={{ fontWeight: "bold" }}>
    {label}
  </Text>
);

export default Recap;

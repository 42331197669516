import { Box } from "grommet";
import {
    Previous
} from "grommet-icons";
import React from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { createContact, updateContact } from "../../../../services/contact_service";
import { clearContextBot, getCommercialId, saveContextBot, uploadFile } from "../../../../services/storage_service";
import PrimaryButton from "../../../elements/primary_button";
import { createAgence, markSigned, updateAgence, updateAgenceInfo } from "../service";
import AgenceDashboard from "./components/agence_dashboard";
import AgenceEdit from "./components/agence_edit";

class Agence extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMode: props.agence.id ? true : false,
            loading: false,
            agence: props.agence,
            originalAgence: undefined,
            popup: false,
            editInfo: false
        };
        //saveContextBot(props.agence);
        if (props.agence && props.agence.interlocuteurs) {
            let interlocuteur = props.agence.interlocuteurs[0];
            if (!interlocuteur) return;
            let contactName = `${props.agence.raisonSocial} | ${interlocuteur?.firstname || ''} ${interlocuteur?.lastname || ''}`
            let client = Object.assign({}, interlocuteur);
            client.lastname = contactName;
            client.firstname = '';
            saveContextBot({ client, model_type: `Agence`, id: props.agence.id })
        }
    }

    submit = async () => {

        let agence = this.state.agence;
        let interlocuteurs = agence.interlocuteurs;
        if (!interlocuteurs || interlocuteurs.length === 0) {
            this.props.showMessage('Veuillez sélectionner un interlocuteur pour recevoir des mails', 'error');
            return;
        }
        let tmp = interlocuteurs.map(s => s);
        let agenceInterlocuteurs = [];
        let agenceEmail = '';
        for (let i = 0; i < interlocuteurs.length; i++) {
            let inter = interlocuteurs[i];
            if (inter.id) {
                agenceInterlocuteurs.push(inter.id);
                agenceEmail = agenceEmail || (inter.receiving_mail ? inter.email : '');
                try {
                    await updateContact(inter);
                } catch (error) {
                    let errMes = error.response?.data?.message || 'Problème de mis à jour de contact';
                    this.props.showMessage(errMes, 'error');
                    console.log(error.response);
                    return;
                }
            } else {
                let res;
                try {
                    res = await createContact(inter);
                } catch (error) {
                    let errMes = error.response?.data?.message || 'Problème de création de contact';
                    this.props.showMessage(errMes, 'error');
                    console.log(error.response);
                    return;
                }
                agenceEmail = agenceEmail || (res.data.receiving_mail ? res.data.email : '');
                agenceInterlocuteurs.push(res.data.id);
            }
        }

        if (!agenceEmail) {
            this.props.showMessage('Veuillez sélectionner un interlocuteur pour recevoir des mails', 'error');
            return;
        }
        if (!agence.classicBaseTaux && !this.state.editInfo && agence.id) {
            this.props.showMessage('Veuillez ajouter taux base', 'error');
            return;
        }

        agence.interlocuteurs = agenceInterlocuteurs;
        agence.email = agenceEmail;
        if (!agence.id) {
            agence.commercial_id = getCommercialId();
        }

        let handler = agence.id ? (this.state.editInfo ? updateAgenceInfo : updateAgence) : createAgence;
        this.setState({ loading: true });
        handler(agence)
            .then(res => {
                this.props.showMessage('Agence créée/modifié avec succès', 'success');
                console.log(res.data);
                this.setState({ agence: res.data, viewMode: true, loading: false, editInfo: false });
            }).catch(err => {
                let body = err.response.data || {};
                let message = body.err === 'USER_EXISTING' ? 'Un utilisateur existant avec email ' + agenceEmail :
                    body.err === 'ACCOUNT_WITH_SAME_EMAIL_EXISTING' ? `Un souscription en direct compte existant avec email ${agenceEmail}` :
                        body.err === 'EMAIL_NOT_VALID' ? 'Email non valid' : 'Erreur inattendu';
                this.props.showMessage(message, 'error');
                agence.interlocuteurs = tmp;
                console.log(err);
                this.setState({ loading: false });
            })
    };

    toRecap = () => {
        this.setState({ viewMode: true, editInfo: false });
    };

    onAgenceChange = (field, value) => {
        let agence = this.state.agence;
        agence[field] = value;
        this.setState({ agence: Object.assign({}, agence) });
    }

    upload = async (field, files) => {
        if (!files || files.length === 0) return;
        let file = files[0];
        this.setState({ loading: true });
        let res = await uploadFile(file);
        let url = res.data.length > 0 ? res.data[0].url : '';
        this.onAgenceChange(field, url);
        this.setState({ loading: false });
    }

    sendConvention = () => {
        console.log('sending:');
        const agence = this.state.agence;
        markSigned(agence.id)
            .then(_ => {
                this.props.showMessage('Convention signé', 'success');
                agence.convention = 'Signée';
                agence.status = 'Client';
                this.setState({ agence, popup: false });

            })
            .catch(err => {
                let info = err.response?.data?.message || 'Erreur inattendu';
                this.props.showMessage(info, 'error');

            })
    }

    render() {
        return (
            <Box
                style={{ width: "100%" }}
            >
                <BlockUi tag="div" blocking={this.state.loading}>
                    <Box style={{ width: "100%" }} justify="center"
                        alignContent="center"
                        align="center"
                    >
                        <Box width="xlarge" justify="center">

                            <Box pad="small" width="xsmall">
                                <PrimaryButton
                                    icon={<Previous />}
                                    onClick={() => {
                                        clearContextBot();
                                        this.props.onClose && this.props.onClose();
                                    }}
                                />
                            </Box>
                        </Box>
                        {this.state.viewMode === false ? (
                            <AgenceEdit
                                agence={this.state.agence}
                                onAgenceChange={this.onAgenceChange}
                                toRecap={this.toRecap}
                                editInfo={this.state.editInfo}
                                onSubmit={this.submit}
                                upload={this.upload}
                                showMessage={this.props.showMessage}
                                onCancel={() => {
                                    if (this.state.agence.id) {
                                        this.setState({ agence: this.state.originalAgence })
                                        this.toRecap()
                                    } else {
                                        this.props.onClose()
                                    }
                                }}
                            />
                        ) : (
                            <AgenceDashboard
                                agence={this.state.agence}
                                onEdit={() => {
                                    let agence = this.state.agence;
                                    let deepClone = JSON.parse(JSON.stringify(agence));
                                    this.setState({ originalAgence: deepClone, viewMode: false })
                                }}
                                onEditInfo={() => this.setState({ originalAgence: Object.assign({}, this.state.agence), viewMode: false, editInfo: true })}
                                sendConvention={this.sendConvention}
                                showMessage={this.props.showMessage}
                                commercial={this.props.users.find(u => u.id === this.state.agence.commercial_id)?.name}
                                onReload={() => this.setState({agence: {...this.state.agence}})}
                            />
                        )}

                        <Box style={{ height: 40 }} />
                    </Box>
                </BlockUi>

            </Box>
        );
    }
}



export default Agence;

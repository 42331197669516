import { Box, Button, DateInput, Grid, Heading, Layer, Select, Text } from "grommet";
import React, { useState } from "react";
import { getNatureList } from "./sinistre/utils";
import moment from "moment";

export default function PopupNewSinistre({ onClose, onSave, subscription }) {

    const [values, setValues] = useState({
        date_ouverture: moment().format('YYYY-MM-DD'),
        subscription: subscription
    });
    const [natures, setNatures] = useState([])

    const onTypeChange = (value) => {
        const _natures = getNatureList(value);
        setNatures(_natures.sort());
    }
    const onSinistreChange = (key, value) => {
        values[key] = value;
        setValues({ ...values });
    }

    return (
        <Layer
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <Box width={'large'} align="center"
                pad={"small"} gap="small"
            >
                <Heading level={2}>Ouverture de sinistre</Heading>
                <Box pad={{ horizontal: 'large' }} width={'full'}>
                    <Grid fill columns={['1/3', "2/3"]} justify="center">
                        <Box justify="center" width={'full'}>
                            <Text>Quel assurance ?</Text>
                        </Box>
                        <Box justify="center" width={'full'}>
                            <Select width={'full'} options={[
                                "Voyage"
                            ]}
                                defaultValue={null}
                                value={values.assurance || null}
                                style={{ backgroundColor: "#D6D6D6" }}
                                onChange={({ option }) => onSinistreChange('assurance', option)}
                            />
                        </Box>

                    </Grid>
                </Box>
                <Box pad={{ horizontal: 'large' }} width={'full'}>
                    <Heading level={3}>Information sinistre</Heading>
                    <Grid fill columns={['1/3', "2/3"]} justify="center" gap={"xsmall"}>
                        <Box justify="center" width={'full'}>
                            <Text>Date de d’ouverture</Text>
                        </Box>
                        <Box justify="center" width={'full'}>
                            <DateInput format="dd/mm/yyyy"
                                style={{ backgroundColor: "#D6D6D6" }}
                                value={values.date_ouverture}
                                onChange={e => onSinistreChange('date_ouverture', e.value)}
                            />
                        </Box>
                        <Box justify="center" width={'full'}>
                            <Text>Date de survenance</Text>
                        </Box>
                        <Box justify="center" width={'full'}>
                            <DateInput format="dd/mm/yyyy"
                                style={{ backgroundColor: "#D6D6D6" }}
                                value={values.date_survenance}
                                onChange={(e) => onSinistreChange('date_survenance', e.value)}
                            />
                        </Box>
                        <Box justify="center" width={'full'}>
                            <Text>Type de sinistre</Text>
                        </Box>
                        <Box justify="center" width={'full'}>
                            <Select width={'full'} options={[
                                "Annulation de séjour",
                                "Interruption de séjour",
                                "Dommage mobilier",
                                "Recours compagnie",
                                "Assistance rapatriement"
                            ]}
                                style={{ backgroundColor: "#D6D6D6" }}
                                value={values.type || null}
                                defaultValue={null}
                                onChange={({ option }) => {
                                    onTypeChange(option);
                                    onSinistreChange('type', option);
                                }}
                            />
                        </Box>
                        <Box justify="center" width={'full'}>
                            <Text>Nature de sinistre</Text>
                        </Box>
                        <Box justify="center" width={'full'}>
                            <Select width={'full'} options={natures}
                                style={{ backgroundColor: "#D6D6D6" }}
                                value={values.nature}
                                onChange={({ option }) => onSinistreChange('nature', option)}
                            />
                        </Box>
                    </Grid>
                    <Box pad={{ top: "large", bottom: "medium" }} width={'full'}>
                        <Button primary label="Ouvrir le sinistre" onClick={() => onSave(values)} />
                    </Box>
                </Box>
            </Box>
        </Layer>
    )
}
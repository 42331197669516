import { Card, Text } from "grommet";
import React from "react";

const BlockText = ({ width, label, value, desc, bg = "#70A4EC" }) => (
  <Card
    width={width}
    pad={"small"}
    margin={"small"}
    align="center"
    alignSelf="center"
    background={bg}
  >
    <Text
      style={{  color: "white" }}
      size="small"
      textAlign="center"
    >
      {label}
    </Text>
    <Text
      style={{fontWeight: "bold",  color: "white", textTransform: "uppercase" }}
      size="large"
      textAlign="center"
    >
      {value}
    </Text>
    {desc && (
      <Text
        style={{  color: "white" }}
        size="small"
        textAlign="center"
      >
        {desc}
      </Text>
    )}
  </Card>
);

export default BlockText;


import { Box } from 'grommet';
import React from 'react';
import contactService from '../../../services/contact_service';
import { getSorted } from '../../../services/utils';
import Refresh from '../../elements/refresh';
import service from './services';
import TrustiGLI from './trusti_gli';

import AddDevis from '../../elements/add_devis';
import DateSort from '../../elements/date_sort';
import SearchComponent from '../../elements/search';
import StatusFilter from '../../elements/status_filter';
import ViewTable from '../../elements/view_table';

class TrustiGLIs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: this.props.contact || {},
            contacts: [],
            trustiGLIs: [],
            popupOpen: false,
            selectedTrustiGLI: {},
            filter: {
                _query: undefined,
                status: undefined,
                sort: undefined
            }
        }
    }

    componentDidMount = () => {
        this.loadTrustiGLIs();
        contactService.getContact()
            .then(res => {
                this.setState({ contacts: res.data?.results })
            }).catch(err => console.log(err));
    };

    loadTrustiGLIs = () => {
        this.setState({ trustiGLIs: [] }, () => {
            service.getTrustiGLIs(this.state.contact.id)
                .then(res => {
                    this.setState({ trustiGLIs: res.data })
                })
                .catch(err => {
                    console.log('err', err);
                    this.props.showMessage('Erreur inattendu');
                })
        })
    }

    onClose = () => { this.setState({ popupOpen: false }) }

    openGLI = (trustiGLI) => {
        this.setState({ selectedTrustiGLI: trustiGLI, popupOpen: true });
    }

    addGLI = () => this.openGLI({ assure: {}, location: {} })

    search = (query, status) => {
        service.getTrustiGLIs(this.state.contact.id, query, status)
            .then(res => {
                if (res.data?.results) {
                    this.setState({ trustiGLIs: res.data?.results })
                } else if (res.data) {
                    this.setState({ trustiGLIs: res.data })
                }
            })
            .catch(err => {
                console.log('err', err);
                this.props.showMessage('Erreur inattendu');
            })
    }

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter: Object.assign({}, filter) });
    }

    render() {
        return (
            this.state.popupOpen ?
                <TrustiGLI
                    showMessage={this.props.showMessage}
                    devis={this.state.selectedTrustiGLI}
                    onClose={this.onClose}
                    reload={this.loadTrustiGLIs}
                    contacts={this.state.contacts}
                />
                :
                <Box style={{ width: "100%" }}
                    align="center" justify="center"
                    alignSelf="center"
                    alignContent="center" gap="medium">
                    <Box width="full" gap="medium" pad={'small'} align='center' justify='center'>
                        <Box width="xlarge" gap="medium" align='center' justify='center'>

                            {!this.state.contact.id &&
                                <>
                                    <Box justify="center" direction='row' alignSelf="center" align="center" gap="medium">
                                        <Refresh refresh={this.loadTrustiGLIs} />
                                        <SearchComponent
                                            search={value => {
                                                this.search(value, this.state.filter.status);
                                            }}
                                            query={this.state.filter._query}
                                            updateQuery={value => this.updateFilter('_query', value)}
                                        />
                                        <AddDevis onAdd={this.addGLI} />
                                    </Box>
                                    <Box justify="between" direction='row' align="center" gap="medium" alignSelf="center">
                                        <StatusFilter
                                            status={this.state.filter.status}
                                            search={value => {
                                                this.updateFilter('status', value);
                                                this.search(this.state.filter._query, value);
                                            }}
                                        />
                                        <DateSort
                                            onChange={value => this.updateFilter('sort', value)}
                                        />

                                    </Box>
                                </>
                            }
                        </Box>
                        {this.state.contact.id && <Box style={{ height: 30 }} />}
                        <ViewTable
                            reports={getSorted(this.state.trustiGLIs, this.state.filter.sort)}
                            commission_taux={this.props.commission_taux}
                            onDevisSelected={this.openGLI}
                            commerciaux={this.props.commerciaux}
                        />
                    </Box>
                </Box>
        )
    }
}


export default TrustiGLIs;
const axios = require('axios');
const storageService = require('../../../services/storage_service');
const { baseAPI } = require('../../../config/config.json');
const moment = require('moment');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

// const VENT_CANALS = ["API", "Lien personnalisé", "Pont", "App"]
const VENT_CANALS = ["API", "Pont", "App"]



const PRODUCTS = [
    {
        name: "MRH en ligne",
        name_config:  "Assurance habitation",
        model_type: "devis_habitation",
        vent_canal: ["API", "Lien personnalisé", "Pont", "App"],
        group: "Assurance"
    },
    {
        name: "PNO en ligne",
        name_config:  "Assurance propriétaire non occupant",
        model_type: "pno_seul",
        vent_canal: ["API", "Lien personnalisé", "Pont", "App"],
        group: "Assurance"
    },
    {
        name: "MRH Tempo en ligne",
        name_config:  "Assurance habitation temporaire",
        model_type: "trusti_mh_tempo",
        vent_canal: ["API", "Lien personnalisé", "Pont", "App"],
        group: "Assurance"
    },
    {
        name: "Emprunteur",
        name_config:  "Assurance prêt",
        model_type: "emprunteur_horsligne",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        group: "Assurance"
    },
    {
        name: "PNO hors ligne",
        name_config:  "Assurance propriétaire non occupant hors-ligne",
        model_type: "trusti_pno_horsligne",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        group: "Assurance"
    },
    {
        name: "MHR hors ligne",
        name_config:  "Assurance habitation hors-ligne",
        model_type: "trusti_mh",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        group: "Assurance"
    },
    {
        name: "Auto",
        name_config:  "Assurance auto",
        model_type: "trusti_voiture",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        group: "Assurance"
    },
    {
        name: "Moto",
        name_config:  "Assurance moto",
        model_type: "trusti_moto",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        group: "Assurance"
    },
    {
        name: "Santé",
        name_config:  "Assurance santé",
        model_type: "sante",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        group: "Assurance"
    },
    {
        name: "Chien/Chat",
        name_config:  "Chien/Chat",
        model_type: "pet",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        group: "Assurance"
    },
    {
        name: "Dommage ouvrage",
        name_config:  "Assurance dommage ouvrage",
        model_type: "pro_do",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        group: "Assurance"
    },
    {
        name: "Crédit Immobilier",
        name_config:  "Crédit Immobilier",
        model_type: "credit",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        sub_product: 'Crédit Immobilier',
        group: "Crédit"
    },
    // {
    //     name: "Regroupement de crédit",
    //     name_config:  "Regroupement de crédit",
    //     model_type: "credit",
    //     vent_canal: ["Lien personnalisé", "Pont", "App"],
    //     sub_product: 'Regroupement de crédit',
    //     group: "Crédit"
    // },
    {
        name: "Regroupement de crédits",
        name_config:  "Regroupement de crédits",
        model_type: "credit",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        sub_product: 'Regroupement de crédit',
        sub_product_detail: 'Regroupement de crédits',
        group: "Crédit"
    },
    {
        name: "Regroupement de crédits projet",
        name_config:  "Regroupement de crédits projet",
        model_type: "credit",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        sub_product: 'Regroupement de crédit',
        sub_product_detail:  "Regroupement de crédits projet",
        group: "Crédit"
    },
    {
        name: "Crédit à la consommation",
        name_config:  "Crédit à la consommation",
        model_type: "credit",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        sub_product: 'Crédit à la consommation',
        group: "Crédit"
    },
    {
        name: "Prêt hypothécaire",
        name_config:  "Prêt hypothécaire",
        model_type: "credit",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        sub_product: "Prêt hypothécaire",
        group: "Crédit"
    },
    {
        name: "Prévoyance",
        name_config:  "Assurance prévoyance",
        model_type: "pro_prev",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        group: "Prévoyance"
    },
    {
        name: "PER",
        name_config:  "Plan épargne retraite",
        model_type: "trusti_per",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        group: "Retraite"
    },
    {
        name: "Assurance vie",
        name_config:  "Assurance vie",
        model_type: "assurance_vie",
        vent_canal: ["Lien personnalisé", "Pont", "App"],
        group: "Épargne"
    }
]    

const buildApporteurParams = (params, filter) => {
    let buildFilter = Object.assign({}, filter);
    if(buildFilter.ventCanal) {
        buildFilter['_where[0][ventCanals]'] = buildFilter.ventCanal;
        delete buildFilter.ventCanal;
    }
    if(buildFilter.createdAt) {
        buildFilter['_where[0][createdAt_lt]']=  `${moment(buildFilter.createdAt).add(1, 'day').format('YYYY-MM-DD')}T00:00:00.000Z`
        buildFilter['_where[1][createdAt_gt]]']=  `${buildFilter.createdAt}T00:00:00.000Z`;
        delete buildFilter.createdAt;
    }
    console.log(buildFilter);
    if(buildFilter.affaireVolume) {
        if(buildFilter.affaireVolume !== 'Vide') {
            buildFilter['_where[0][affaireVolume]']=  buildFilter.affaireVolume
        } else {
            buildFilter['_where[0][affaireVolume_null]']=  true;
        }
        delete buildFilter.volume;
    }

    let finalFilter = {...params, ...buildFilter};
    for(let key of Object.keys(finalFilter)) {
        if(!finalFilter[key]) delete finalFilter[key];
    }

    return finalFilter;
}

const getApporteurPros = (startIndex, query, filter = {}, sort, mother) => {
    let params = {
        _start: startIndex,
        _limit: 50,
        _q: query,
    }
    if(mother){
        params.company_mother = true;
    }else{
        params.company_mother_ne = true;
    }
    if(sort) {
        params._sort = sort;
    }
    params = buildApporteurParams(params, filter);
    return axios.get(`${baseAPI}/apporteur-pros`, { params, headers: HEADERS });
}

const saveApporteurPro = (apporteur) => {
    return apporteur.id ?
     axios.put(`${baseAPI}/apporteur-pros/${apporteur.id}`, apporteur, { headers: HEADERS }) :
     axios.post(`${baseAPI}/apporteur-pros`, apporteur, { headers: HEADERS });
}

const deleteApporteurPro = (id) => {
    return axios.delete(`${baseAPI}/apporteur-pros/${id}`, { headers: HEADERS });
}

const countApporteurPros = (query, filter) => {
    let params = {
        _q: query
    }
    params = buildApporteurParams(params, filter);
    return axios.get(`${baseAPI}/apporteur-pros/count`, { params, headers: HEADERS });
}

const getSouscriptions = (search_params) => {
    return axios.get(`${baseAPI}/souscriptions_by_apporteur`, { headers: HEADERS, params: search_params });
}

const getApporteurSouscriptions = (apporteurId) => {
    return axios.get(`${baseAPI}/apporteur-pros/${apporteurId}/souscriptions`, { headers: HEADERS });
}

const sendConvention = (id, test_mail=false) => {
    return axios.post(`${baseAPI}/apporteur-pros/convention`, {id, test_mail}, { headers: HEADERS });
}

const sendApporteurPresentation = (apporteur) => {
    return axios.post(`${baseAPI}/public/apporteur/presentation`, apporteur, { headers: HEADERS });
}



module.exports = {
    getApporteurPros,
    countApporteurPros,
    saveApporteurPro,
    deleteApporteurPro,
    sendConvention,
    getSouscriptions,
    getApporteurSouscriptions,
    VENT_CANALS,
    PRODUCTS,
    sendApporteurPresentation
}
import { Box, Button, Grid, Text } from "grommet";
import React from "react";
import { displayAmount } from "../../trusti-per/trusti_per/simulation";
import { getEmprunteurResults } from "./computeEmprunteur";
import configs from '../../../../config/config.json';

const ValidateSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 46.106 46.106"
    style={{ transform: "translate(20px, 0)" }}
  >
    <g id="Group_21" data-name="Group 21" transform="translate(0)">
      <path
        id="Ellipse_44"
        data-name="Ellipse 44"
        d="M23.053,0A23.053,23.053,0,1,1,0,23.053,23.053,23.053,0,0,1,23.053,0Z"
        transform="translate(0)"
        fill="#fff"
      />
      <path
        id="Path_395"
        data-name="Path 395"
        d="M354.186,386.255a2.391,2.391,0,0,1-1.438-.478l-.026-.019-5.418-4.144a2.408,2.408,0,1,1,2.93-3.821l3.509,2.691,8.292-10.818a2.407,2.407,0,0,1,3.374-.446h0l-.051.071.053-.071a2.41,2.41,0,0,1,.445,3.375L356.1,385.313a2.408,2.408,0,0,1-1.915.939Z"
        transform="translate(-333.417 -354.407)"
        fill="#5fc951"
      />
    </g>
  </svg>
);

export const Simulation = ({ devis }) => {
  const result = getEmprunteurResults(devis);
  const _changeAssurance = devis.type_projet === "Je souhaite changer d'assurance";
  return (
    <Box>
      <Grid
        columns={["50%", "50%"]}
        // style={{ margin: size === "small" ? "20px 0 0px -28px" : "20px 0 -28px -28px" }}
        // pad={size === "small" ? "large" : "0 0 0 0"}
        align="center"
        width="full"
      >
        <Box height="100%">
          <Box
            background="brand"
            pad="medium"
            style={{ flexGrow: 1, borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}
            justify="center"
          >
            <Text color="white" weight={800}>{!_changeAssurance ? "Votre cotisation" : "L'économie"}
              <Text color="white" weight={400} size="xsmall" margin="-5px 0 0 10px">
                estimée est de
              </Text></Text>
            <Box direction="row" align="end">
              <Text color="white" size="xxlarge" weight={800}>
                {!_changeAssurance ? displayAmount(result?.cotisation, 0): displayAmount(result?.economie)} €
              </Text>
              <Text color="white" size="xsmall" weight={800} margin="0 0 0 10px">
                annuelle
              </Text>
            </Box>
          </Box>
          { _changeAssurance && <Box
            background="background-back"
            style={{ flexGrow: 1, borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}
            justify="center"
            height={"auto"}
            pad="medium"
          >
            <Text size="xxsmall" weight={600} margin="0 0 5px 0">
              Ce qui représentent une économie totale de
            </Text>
            <Box direction="row">
              <Text size="xxlarge" color="bluebrand" weight={800}>
                {displayAmount((result?.economiePourcent ?? 0) * 100)} %
              </Text>
              <ValidateSvg />
            </Box>
          </Box>}
        </Box>
        <Box pad="medium">
          <Text size="xxsmall" weight={600} margin="0 0 5px 0">
            Informations complémentaires de l’assurance de prêt :
          </Text>
          <Grid columns={["1/1"]} gap="xsmall">

            {_changeAssurance && <Text alignSelf="center" size="xsmall">
              Votre nouvelle cotisation estimée
            </Text>}
            {_changeAssurance && <Text size="large" color="bluebrand" weight={800}>
              {displayAmount(result?.cotisation, 0)} €
            </Text>}
            <Text alignSelf="center" margin="0px 0 0 0" size="xsmall">
              Soit une cotisation mensuelle de
            </Text>
            <Text size="large" color="bluebrand" weight={800}>
              {displayAmount(result?.totalMois, 0)} € / mois
            </Text>
            <Text alignSelf="center" margin="0px 0 0 0" size="xsmall">
              Taux moyen
            </Text>
            <Text size="large" color="bluebrand" weight={800}>
              {displayAmount((result?.tauxMoyen ?? 0) * 100, 0)} %
            </Text>
          </Grid>
        </Box>
      </Grid>
      <Box direction="row" margin="20px 0 0 0">
        <Button label="Voir la simulation" primary onClick={() => {
          configs.host.includes("dev") ?
            window.open("https://dev.form.trustiway.com/landings/emprunteur/result?id=" + devis.id, "_blank")
          :
            window.open("https://form.trustiway.com/landings/emprunteur/result?id=" + devis.id, "_blank");
        }} />
      </Box>
    </Box>
  )
};

import { Box, Card } from "grommet";
import { Text } from "grommet/components/Text";
import moment from "moment";
import React from "react";
import service, { getGestionCollabs } from "../../services/dashboard";
import {
    PERDU, ARCHIVE, RESILIE
} from "../../services/devis_contrat_status";
import { hasClassiqueManagerRole, isCommercialClassique } from "../../services/storage_service";
import { getMonthOptions } from "../../services/utils";
import CustomSelect from "../elements/select_fill";
import { products } from "../outils/client_util";
import StatusReport from "./status_report";

class PerduReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commerciaux: [],
            filter: {
                startDate: moment(new Date())
                    .subtract(11, "year")
                    .startOf("month")
                    .toDate(),
                endDate: new Date(),
                product: undefined,
                commercial_ids: props.commerciaux && !props.personal ? "All" : undefined,
            },
            reports: []
        };
    }

    componentDidMount() {
        let without = { name: 'Sans commercial', id: 'EMPTY' };
        getGestionCollabs()
            .then(res => {
                const onlyCommerciaux = res.data.filter(c => isCommercialClassique(c) || hasClassiqueManagerRole(c))
                this.setState({ commerciaux: [without].concat(onlyCommerciaux) });
                this.loadData();
            });
    }

    loadData = async () => {
        console.log('loadData:', Date());
        service
            .getReports(`${PERDU},${ARCHIVE},${RESILIE}`, this.state.filter).then((res) => {
                this.setState({ reports: res.data })
            });

    };

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;
        if (value === "Tous") filter[field] = "";
        this.setState({ filter: Object.assign({}, filter) }, () => this.loadData());
    };

    render() {
        return (
            <Box
                style={{ width: "100%" }}
                margin="medium"
                pad="medium"
                gap="medium"
                justify="center"
                alignContent="center"
                alignSelf="center"
                align="center"
            >
                <Card
                    width="xlarge"
                    justify="around"
                    margin="medium"
                    pad="small"
                    direction="row"
                >
                    <Box gap="xsmall">
                        <Text>Select d'un mois</Text>
                        <CustomSelect
                            options={getMonthOptions().filter(
                                (s) => s.name !== "12 derniers mois"
                            )}
                            labelKey={"name"}
                            valueKey={{ key: "name", reduce: true }}
                            onChange={({ option }) => {
                                let filter = this.state.filter;
                                filter.startDate = option.startDate;
                                filter.endDate = option.endDate;
                                filter.sort = "Produit";
                                this.setState(
                                    {
                                        filter: Object.assign({}, filter),
                                        selectedMonth: option.name,
                                    },
                                    () => this.loadData()
                                );
                            }}
                        />
                    </Box>
                    <Box gap="xsmall">
                        <Text>Produit</Text>
                        <CustomSelect
                            options={products}
                            onChange={({ option }) => this.updateFilter("product", option)}
                        />
                    </Box>
                    {this.state.commerciaux && !this.props.personal && (
                        <Box gap="xsmall">
                            <Text>Commercial</Text>
                            <CustomSelect
                                options={this.state.commerciaux}
                                onChange={({ option }) =>
                                    this.updateFilter("commercial_ids", [option.id])
                                }
                                labelKey={"name"}
                                valueKey={{ key: "name", reduce: true }}
                            />
                        </Box>
                    )}
                </Card>

                <Box direction="column" gap="medium" justify="center" width={'full'}>
                    <StatusReport showMessage={this.props.showMessage}
                        reports={this.state.reports}
                        status={`${PERDU}, ${ARCHIVE}, ${RESILIE}`}
                        reloadAll={this.loadData}
                        commerciaux={this.state.commerciaux}
                    />
                </Box>
            </Box>
        );
    }
}

export default PerduReports;

const axios = require('axios');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const buildParams = (search_params) => {
    const { start, sort, query, limit, status } = search_params;
    let params = {
        _start: start || 0,
        _limit: limit || 50,
    }
    if(status) {
        params.status = status;
    }
    if (query) {
        params['_q'] = query || '';
    }
    if(sort) {
        params._sort = sort || 'updatedAt:desc';
    }
    return params;
}

const getAllProPNOs = (filter) => {
    let params = buildParams(filter);
    return axios.get(`${baseAPI}/public/pro-pno`, { params, headers: HEADERS });
}
const countProPNOs = (filter) => {
    let params = buildParams(filter);
    return axios.get(`${baseAPI}/pro_pno/count`, { params, headers: HEADERS })
}

const createProPNO = (body) => {
    body.commercial_id = storageService.getCommercialId()
    return axios.post(`${baseAPI}/public/pro-pno`, body, { headers: HEADERS });
}

const updateProPNO = (body) => {
    return axios.put(`${baseAPI}/public/pro-pno/${body.id}`, body, { headers: HEADERS });
}

const sendRelancePayment = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-relance-payment`, devis, { headers: HEADERS });
}

const sendForm = (body) => {
    return axios.post(`${baseAPI}/commerciale/pro-pno/send`, body, { headers: HEADERS });
}

module.exports = {
    sendForm,
    getAllProPNOs,
    countProPNOs,
    createProPNO,
    sendRelancePayment,
    updateProPNO
}
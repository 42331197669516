import React from "react";
import { Box, Heading, Text, Card, Stack } from "grommet";
import { getSimpleDateFR } from "../../../../services/utils";
import RecapStatus from "../../../elements/devis_status";
import { DocumentDownload } from "grommet-icons";
import StatusDateAppel from "../../../elements/status_date_appel";
import PanelAppel from "../../../elements/panel_appel";
import EmailEventTracker from "../../../elements/email_tracker";
import StripeCustomer from "../../../elements/stripe_customer";
import CodePromo from "../../../elements/code_promo";
import ApporteurGestionnaire from "../../../elements/apporteur_gestionaire";
import TrustiBot from "../../../trustibot";
import DateRecap from "../../../elements/date_recap";


const Recap = ({ devis, toEditMode, close, sendForm, updateDevis, onSave }) => {
  console.log(devis);
  return (
    <Box
      direction="row"
      gap='small'
      style={{ width: "100vw" }}
    >
      <TrustiBot></TrustiBot>
      <Box
        gap="medium"
        // style={{ width: "100%" }}
        align="center"
        alignSelf="center"
        pad="small"
      >
        <Heading level="2" color="#59A4F4" textAlign="center">
          Trusti Voiture
        </Heading>
        <Box direction="row" fill="horizontal" justify="center" alignContent="center">
          <Box align="center">
            <StatusDateAppel devis={devis} />
          </Box>
          <Box>
            <Box alignSelf="end">
              <RecapStatus status={devis.status} />
              <PanelAppel devis={devis} updateDevis={updateDevis} onSave={onSave} />
            </Box>
          </Box>
        </Box>
        <Box  gap="small" width={'full'} justify='center' align="center">
          <Card  pad="small" gap="small" width={'full'} >
            <Text textAlign="center"><b>ID:</b> {devis.id}</Text> 
          </Card>
        </Box>
        <Box  gap="small" width={'full'}>
          <DateRecap deal={devis} />
        </Box>
        <CodePromo devis={devis} />
        <Box width={'xlarge'}>
          <StripeCustomer devisId={devis.id} />
        </Box>
        <Box width="xlarge" gap="small">
          <Box width="xlarge" direction="row" gap="small">
            <Card style={{ width: "100%" }} pad="small">
              <Assure assure={devis.client || {}} />
            </Card>
            <Card style={{ width: "100%" }} pad="small">
              <VehiculeInfo devis={devis} />
            </Card>
          </Box>

          <Card style={{ width: "100%" }} pad="small">
            <ConductorDetails devis={devis} />
          </Card>
        </Box>

        <Box width="xlarge" direction="row" gap="small">
          <Card style={{ width: "100%" }} pad="medium">
            {devis.file_tableau_amortissement && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_tableau_amortissement}`}
              >
                <DocumentDownload />
                Tableau d’amortissement
              </a>
            )}
            {devis.file_offre_de_pret && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_offre_de_pret}`}
              >
                <DocumentDownload />
                Offre de prêt
              </a>
            )}
            {devis.file_devis && (
              <a target={"_blank"} rel="noreferrer" href={`${devis.file_devis}`}>
                <DocumentDownload />
                Devis
              </a>
            )}
            {devis.file_rib && (
              <a target={"_blank"} rel="noreferrer" href={`${devis.file_rib}`}>
                <DocumentDownload />
                RIB
              </a>
            )}
            {devis.file_contrat && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_contrat}`}
              >
                <DocumentDownload />
                Contrat
              </a>
            )}
            {devis.file_contrat_signed && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_contrat_signed}`}
              >
                <DocumentDownload />
                Contrat signé
              </a>
            )}
            {devis.file_permis && (
              <a target={"_blank"} rel="noreferrer" href={`${devis.file_permis}`}>
                <DocumentDownload />
                Permis de conduire
              </a>
            )}
            {devis.file_carte_grise && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_carte_grise}`}
              >
                <DocumentDownload />
                Carte grise
              </a>
            )}
            {devis.file_releve_information ? (
              devis.file_releve_information.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                    <DocumentDownload />
                    Relevé d'information {_idx + 1} - {e && e.split("/").pop()}
                  </a>
                );
              })
            ) : (
              <></>
            )}
            {devis.file_others ? (
              devis.file_others.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                    <DocumentDownload />
                    {e && e.split("/").pop()}
                  </a>
                );
              })
            ) : (
              <></>
            )}
          </Card>
        </Box>
        {(devis.apporteur || devis.affiliation) && <Card pad={"small"} width={'xlarge'} >
          <ApporteurGestionnaire apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
        </Card>}
        <Box width={'xlarge'}>
          <EmailEventTracker messageIds={devis.emailEventIds} />
        </Box>
        <Box style={{ height: 40 }} />
      </Box>
    </Box>
  );
};

const ConductorDetails = ({ devis }) => {
  let driver = devis.conducteur_principal || {};

  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label="Informations de l'assurance" />
      <Line
        leftLabel="Permis EU"
        rightLabel={devis.license_europe ? "Oui" : "Non"}
      />
      <Line
        leftLabel="Date d'obtention du permis"
        rightLabel={getSimpleDateFR(devis.license_date)}
      />
      <Line leftLabel="Bonus / Malus" rightLabel={driver.bonus_malus} />
      <Line
        leftLabel="Situation familiale"
        rightLabel={driver.situation_familliale}
      />
      <Line leftLabel="Profession" rightLabel={driver.profession} />
      <Line
        leftLabel="Avez-vous été assuré(s) en Auto en cours des 3 dernières années"
        rightLabel={driver.assure_3ans ? "Oui" : "Non"}
      />
      {driver.assure_3ans && (
        <Line leftLabel="Assuré(s) depuis " rightLabel={driver.assure_depuis} />
      )}
      <Line
        leftLabel="Interruptions d’assurance sur les 3 dernières années"
        rightLabel={driver.interruption ? "Oui" : "Non"}
      />
      {driver.interruption && (
        <Line
          leftLabel="Assuré(s) depuis "
          rightLabel={driver.interruption_duree + " mois"}
        />
      )}
      <Line
        leftLabel="Résiliation par un précédent assureur"
        rightLabel={driver.resiliation ? "Oui" : "Non"}
      />
      {driver.resiliation && (
        <Line
          leftLabel="Nature de cause"
          rightLabel={driver.resiliation_cause}
        />
      )}
      <Line
        leftLabel="Sinistres auto au cours des 3 dernières années"
        rightLabel={driver.sinistre_3ans ? "Oui" : "Non"}
      />
      {driver.sinistre_3ans && (
        <Line
          leftLabel="Nombre total de sinistres déclarés"
          rightLabel={driver.sinistre_3ans_nombre + " fois"}
        />
      )}
      {driver.sinistres &&
        driver.sinistres.length > 0 &&
        driver.sinistres.map((s, index) => {
          return s.cause ? (
            <Box key={`sinistres_${index}`} width="large" gap="medium">
              <Text
                textAlign="center"
                style={{ fontWeight: "bold", fontSize: 12 }}
              >
                Sinistre {index + 1}
              </Text>
              <Box gap="xsmall">
                <Line
                  leftLabel="Date de survenance"
                  rightLabel={getSimpleDateFR(s.date)}
                />
                <Line leftLabel="Nature du sinistre" rightLabel={s.cause} />
                <Line leftLabel="Responsabilité" rightLabel={s.responsable} />
                <Line leftLabel="Tiers" rightLabel={s.tiers} />
              </Box>
            </Box>
          ) : null;
        })}
      <Line
        leftLabel="Un retrait (suspension ou annulation)"
        rightLabel={driver.license_problem}
      />
      <Line
        leftLabel="pour contrôle positif d’alcoolémie"
        rightLabel={driver.license_problem_alcool ? "Oui" : "Non"}
      />
      {driver.license_problem_alcool && (
        <Line
          leftLabel="Date de contrôle"
          rightLabel={getSimpleDateFR(driver.license_problem_alcool_date)}
        />
      )}
      {driver.license_problem_alcool && (
        <Line
          leftLabel="Taux constaté"
          rightLabel={driver.license_problem_alcool_taux}
        />
      )}
      {driver.license_problem_alcool && (
        <Line
          leftLabel="Contrôle alcoolémie avec accident"
          rightLabel={driver.license_problem_alcool_accident ? "Oui" : "Non"}
        />
      )}
      {driver.license_problem_alcool && (
        <Line
          leftLabel="S’agit-il d’une récidive"
          rightLabel={driver.license_problem_alcool_recidive ? "Oui" : "Non"}
        />
      )}
      {driver.license_problem &&
        driver.license_problem !== "Aucun retrait de permis" && (
          <Line
            leftLabel="Pour usage de stupéfiants ou de drogue"
            rightLabel={driver.license_problem_drougue ? "Oui" : "Non"}
          />
        )}
      {driver.license_problem &&
        driver.license_problem !== "Aucun retrait de permis" && (
          <Line
            leftLabel="pour refus de se soumettre à un contrôle de dépistage"
            rightLabel={driver.license_problem_refus_control ? "Oui" : "Non"}
          />
        )}
      {driver.license_problem &&
        driver.license_problem !== "Aucun retrait de permis" && (
          <Line
            leftLabel="pour refus d’obtempérer"
            rightLabel={driver.license_problem_refus_obtemperer ? "Oui" : "Non"}
          />
        )}
      {driver.license_problem &&
        driver.license_problem !== "Aucun retrait de permis" && (
          <Line
            leftLabel="pour délit de fuite"
            rightLabel={driver.license_problem_fuite ? "Oui" : "Non"}
          />
        )}
      {driver.license_problem &&
        driver.license_problem !== "Aucun retrait de permis" && (
          <Line
            leftLabel="pour défault d'assurance"
            rightLabel={driver.license_problem_fake_assurance ? "Oui" : "Non"}
          />
        )}
      {driver.license_problem &&
        driver.license_problem !== "Aucun retrait de permis" && (
          <Line
            leftLabel="pour autre infraction au Code de la Route"
            rightLabel={driver.license_problem_autre_infraction ? "Oui" : "Non"}
          />
        )}
      {driver.license_problem &&
        driver.license_problem !== "Aucun retrait de permis" && (
          <Line
            leftLabel="Date d'effet du retrait"
            rightLabel={getSimpleDateFR(driver.license_problem_date)}
          />
        )}
      {driver.license_problem === "Suspension" && (
        <Line
          leftLabel="Duration de la suspension"
          rightLabel={driver.suspension_duree + " mois"}
        />
      )}
    </Box>
  );
};

const CustomText = ({ label, textAlign, isBold }) => (
  <Text
    style={{ fontWeight: isBold ? "bold" : "normal" }}
    size="small"
    textAlign={textAlign || "end"}
  >
    {label}
  </Text>
);
const Line = ({ leftLabel, rightLabel }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "50%" }}>
      <CustomText label={leftLabel + " :"} />
    </Box>
    <Box style={{ width: "50%" }}>
      <CustomText label={rightLabel} textAlign="start" isBold={true} />
    </Box>
  </Box>
);
const Assure = ({ assure }) => {
  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label="Informations de l'assuré" />
      <Line leftLabel="Civilité" rightLabel={assure.civilite} />
      {assure.civilite !== "Société" && (
        <Line leftLabel="Prénom" rightLabel={assure.firstname} />
      )}
      {assure.civilite !== "Société" && (
        <Line leftLabel="Nom" rightLabel={assure.lastname} />
      )}
      {assure.civilite === "Société" && (
        <Line leftLabel="Raison social" rightLabel={assure.raisonSocial} />
      )}
      <Line leftLabel="Mail" rightLabel={assure.email} />
      <Line leftLabel="Tel" rightLabel={assure.phone} />
      <Line
        leftLabel="Adresse postale"
        rightLabel={`${assure.address}, ${assure.zip}, ${assure.city}`}
      />
      <Line
        leftLabel="Date de naissance"
        rightLabel={`${assure.date_of_birth ? getSimpleDateFR(assure.date_of_birth) : ""
          }`}
      />
    </Box>
  );
};

const VehiculeInfo = ({ devis }) => (
  <Box gap="small" style={{ width: "100%" }}>
    <SubHeading label="Informations de véhicule" />
    <Line leftLabel="Marque" rightLabel={devis.marque} />
    <Line leftLabel="Modèle" rightLabel={devis.model} />
    <Line leftLabel="Immatriculation" rightLabel={devis.immatriculation} />
    <Line
      leftLabel="Date de première en circulation"
      rightLabel={getSimpleDateFR(devis.premiere_circulation)}
    />
    <Line leftLabel="Energie" rightLabel={devis.energy} />
    <Line leftLabel="Puissance fiscale" rightLabel={devis.puissance_fiscale} />
    <Line leftLabel="Version" rightLabel={devis.version} />
    <Line leftLabel="Type de stationnement" rightLabel={devis.parking_type} />
    <Line leftLabel="Code postal de garage" rightLabel={devis.lieu_garage} />
    <Line leftLabel="Ville de garage" rightLabel={devis.ville_garage} />
    <Line
      leftLabel="Date d'acquisition"
      rightLabel={getSimpleDateFR(devis.date_acquisition)}
    />
    <Line leftLabel="Usage du véhicule" rightLabel={devis.usage_vehicle} />
    <Line
      leftLabel="Forfait kilométrique annuel"
      rightLabel={devis.forfait_km_annuel}
    />
    <Line
      leftLabel="Titulaire de la carte grise"
      rightLabel={devis.titulaire_type}
    />
    <Line
      leftLabel="Date d’effet souhaitée "
      rightLabel={getSimpleDateFR(devis.begin_date)}
    />
  </Box>
);

const SubHeading = ({ label }) => (
  <Text textAlign="center" style={{ fontWeight: "bold" }}>
    {label}
  </Text>
);

export default Recap;

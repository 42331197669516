
import { Box } from 'grommet';
import { Close, Save } from "grommet-icons";
import React, { useState } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { createContact, getContact } from '../../../services/contact_service';
import { CIVILITY, CONTACT_TYPE } from '../../../services/types';
import CustomInput from '../../elements/custom_input';
import PrimaryButton from '../../elements/primary_button';
import CustomSelect from '../../elements/select_fill';
import StandardText from '../../elements/standard_text';
import ToggleButton from '../../elements/toggle_button/toggle_button';
import SectionDivider from '../../voyages/components/session_devider';

const lead_status = [
    { key: "NEW", value: "Nouveau" },
    { key: "OPEN", value: "Ouvert" },
    { key: "IN_PROGRESS", value: "En cours" },
    { key: "OPEN_DEAL", value: "Transaction ouverte" },
    { key: "UNQUALIFIED", value: "Non qualifié" },
    { key: "ATTEMPTED_TO_CONTACT", value: "Tentative de contact" },
    { key: "CONNECTED", value: "Connecté" },
    { key: "BAD_TIMING", value: "Mauvais timing" },
];

const findLeadStatusKey = (status) => {
    let lead = lead_status.find(
        (k) => k.value.toUpperCase() === (status || "").toUpperCase()
    );
    return lead ? lead.key : "";
};

const findLeadStatusValue = (status) => {
    if (!status) return null;
    let lead = lead_status.find(
        (k) => k.key.toUpperCase() === (status || "").toUpperCase()
    );
    return lead ? lead.value : "";
};
const initContacts = contact => {
    if (!contact) return [];
    contact.description = `${contact.firstname} (${contact.email})`
    return [contact];
}
class SelectContact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            contact: Object.assign({}, this.props.contact || {}),
            originalContact: this.props.contact || {},
            existing: this.props.contact?.id ? true : false,
            contacts: initContacts(this.props.contact)
        }
    }

    onChange = (field, value) => {
        let en = this.state.contact;
        en[field] = value;
        this.setState({ contact: en });
    }

    submit = async () => {
        let contact = this.state.contact;

        this.setState({ loading: true });
        if (!contact.id) {
            try {
                let res = await createContact(contact);
                this.props.showMessage('Contact créée/modifié avec succès', 'success');
                this.setState({ contact: res.data, loading: false });
                if (this.props.onSubmit) {
                    this.props.onSubmit(res.data);
                }
            } catch (err) {
                let body = err.response.data || {};
                let message = body.err === 'USER_EXISTING' ? 'Un contact existant avec email ' + contact.email :
                    body.err === 'ACCOUNT_WITH_SAME_EMAIL_EXISTING' ? `Un souscription en direct compte existant avec email ${contact.email}` :
                        body.err === 'EMAIL_NOT_VALID' ? 'Email non valid' : 'Erreur inattendu';
                this.props.showMessage(message, 'error');
                console.log(err);
                this.setState({ loading: false });
            }
        } else {
            this.setState({ contact, loading: false });
            if (this.props.onSubmit) {
                this.props.onSubmit(contact);
            }
        }

    }

    cancel = () => {
        this.setState({ contact: Object.assign({}, this.state.originalContact) });
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box
                    style={{ width: "100%" }}
                    justify="center"
                    alignContent="center"
                    align="center"
                    pad='medium'
                >
                    <Box
                        width={'large'}
                        justify="center"
                        alignContent="center"
                        align="center"
                        gap="medium"
                    >
                        <Box >
                            <StandardText style={{ fontWeight: 'bold' }} label='Renseignez les informations du client' />
                            <SectionDivider />
                        </Box>
                        <Box gap="xxsmall" align="center" width={'full'}>

                            <Line
                                left={<StandardText size={'small'} label="Le contact est-il créé ?" />}
                                right={
                                    <ToggleButton
                                        groups={[true, false].map(value => (
                                            {
                                                label: value ? 'Oui' : 'Non',
                                                selected: this.state.existing === value,
                                                onClick: () => { value ? this.setState({ existing: value }) : this.setState({ existing: value, contact: {} }) },
                                            }
                                        ))}
                                    />
                                }
                            />
                            {this.state.existing &&
                                <ExistingContact
                                    originalContacts={this.state.contacts}
                                    value={this.state.contact}
                                    onSelect={contact => this.setState({ contact })}
                                />


                            }
                            <LineWithText
                                leftLabel="Type de contact"
                                right={
                                    <ToggleButton
                                        disabled={this.state.existing}
                                        size={'xsmall'}
                                        groups={['PERSO', 'PRO'].map(type => ({
                                            label: type === 'PRO' ? 'Professionnel' : 'Particulier',
                                            selected: this.state.contact.contact_type === type,
                                            onClick: () => this.onChange("contact_type", type),
                                        }))}
                                    />
                                }
                            />
                            <LineWithText
                                leftLabel="Civilité"
                                right={
                                    <ToggleButton
                                        disabled={this.state.existing}
                                        size={'xsmall'}
                                        groups={[CIVILITY.mr, CIVILITY.ms].map(type => ({
                                            label: type,
                                            selected: this.state.contact.civilite === type,
                                            onClick: () => this.onChange("civilite", type),
                                        }))}
                                    />
                                }
                            />
                            <LineInput
                                label={'Prénom'}
                                value={this.state.contact.firstname}
                                field='firstname'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <LineInput
                                label={'Nom'}
                                value={this.state.contact.lastname}
                                field='lastname'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <LineInput
                                label={'Téléphone'}
                                value={this.state.contact.phone}
                                field='phone'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <LineInput
                                label={'Email'}
                                value={this.state.contact.email}
                                field='email'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <LineInput
                                label={'Adresse principale'}
                                value={this.state.contact.address}
                                field='address'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <LineInput
                                label={'Code postal'}
                                value={this.state.contact.zip}
                                field='zip'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <LineInput
                                label={'Ville'}
                                value={this.state.contact.city}
                                field='city'
                                onChange={this.onChange}
                                disabled={this.state.existing}
                            />
                            <LineWithText
                                leftLabel="Indice de confiance sur Trustiway"
                                right={
                                    <CustomSelect
                                        disabled={this.state.existing}
                                        options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                                        value={this.state.contact.indice_de_confiance_sur_trustiway}
                                        onChange={({ option }) =>
                                            this.onChange("indice_de_confiance_sur_trustiway", option)
                                        }
                                    />
                                }
                            />
                            <LineWithText
                                leftLabel="Indice de confiance sur le seller"
                                right={
                                    <CustomSelect
                                        disabled={this.state.existing}
                                        options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                                        value={this.state.contact?.indice_de_confiance_sur_le_seller}
                                        onChange={({ option }) =>
                                            this.onChange("indice_de_confiance_sur_le_seller", option)
                                        }
                                    />
                                }
                            />
                            <LineWithText leftLabel="Statut du lead"
                                right={
                                    <CustomSelect
                                        disabled={this.state.existing}
                                        options={lead_status.map((l) => l.value)}
                                        value={findLeadStatusValue(this.state.contact?.statut_du_lead)}
                                        onChange={({ option }) =>
                                            this.onChange("statut_du_lead", findLeadStatusKey(option))
                                        }
                                    />
                                }
                            />
                            <LineWithText
                                leftLabel="Ne pas envoyer email"
                                right={
                                    <ToggleButton
                                        disabled={this.state.existing}
                                        size={'xsmall'}
                                        groups={[
                                            {
                                                label: '❌ Ne pas envoyer email',
                                                selected: this.state.contact.do_not_emailing === true,
                                                onClick: () => this.onChange("do_not_emailing", true),
                                            },
                                            {
                                                label: '✅ Email OK',
                                                selected: this.state.contact.do_not_emailing !== true,
                                                onClick: () => this.onChange("do_not_emailing", false),
                                            },
                                        ]}
                                    />
                                }
                            />


                        </Box>

                    </Box>

                    <Box pad={'medium'} direction='row' gap="medium">
                        < PrimaryButton icon={<Close />} label='Annuler' background={'#707070'} onClick={this.props.onCancel} />
                        < PrimaryButton icon={<Save />} label='Sauvegarder' onClick={this.submit} />
                    </Box>
                </Box>
            </BlockUi>
        )
    }
}

const ExistingContact = ({ originalContacts, value, onSelect }) => {
    const [timeoutId, setTimeoutId] = useState();
    const [contacts, setContacts] = useState(originalContacts);
    const [_value, setValue] = useState(`${value?.firstname} (${value?.email})`)

    const searchContact = (query) => {
        clearTimeout(timeoutId);
        let _id = setTimeout(() => {
            getContact(0, query).then((res) => {
                setContacts(
                    res.data.map((s) => {
                        s.description = `${s.firstname} (${s.email})`
                        return s;
                    })
                );
            })
                .catch((err) => console.log(err));
        }, 500);
        setTimeoutId(_id);
    };

    const onContactInfoSelect = (info) => {
        setValue(info)
        let found = contacts.find(s => `${s.firstname} (${s.email})` === info);
        found.description = `${found.firstname} (${found.email})`
        onSelect(found);
    }
    return (
        <Line
            left={<StandardText size={'small'} label="Recherche contact" />}
            right={
                <CustomSelect
                    options={contacts}
                    size={'small'}
                    value={_value}
                    labelKey={"description"}
                    valueKey={{ key: "description", reduce: true }}
                    onChange={({ value: nextValue }) => onContactInfoSelect(nextValue)}
                    onSearch={(text) => {
                        const escapedText = text.replace(
                            /[-\\^$*+?.()|[\]{}]/g,
                            "\\$&"
                        );
                        searchContact(escapedText);
                    }}
                />
            }
        />
    )
}

const LineInput = ({ label, field, value, onChange, disabled }) => (
    <Line
        left={<StandardText size={'small'} label={label} />}
        right={
            <CustomInput size="small" value={value}
                onChange={event => onChange(field, event.target.value)}
                disabled={disabled}
                placeholder={label}
            />
        }
    />
)

const Line = ({ left, right }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '35%' }} >
                {left}
            </Box>
            <Box width={'full'}>
                {right}
            </Box>
        </Box>
    )
}

const LineWithText = ({ leftLabel, right }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '35%' }} >
                <StandardText size={'small'} label={leftLabel} />
            </Box>
            <Box width={'full'}>
                {right}
            </Box>
        </Box>
    )
}


export default SelectContact;
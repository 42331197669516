import {
    Box,
    Menu, Text
} from "grommet";
import { Archive, Projects } from "grommet-icons";
import React from 'react';
import { modifyDeal } from "../../../services/common_service";
import PrimaryButton from "../primary_button";
import { EDITION, EN_ATTENTE_DE_FORMALITE_MEDICALE, ETUDE } from "../../../services/devis_contrat_status";

const SUB_STATUS_EMPRUNTEUR = [
    "Étude en compagnie",
    "Formalité médicale",
    "Édition",
    "Clôturé"
]

const DealSubStatusChange = ({ deal, path, onReload, showMessage }) => {
    const changeStatus = s => {
        deal.sub_status = s;
        modifyDeal(deal, path)
            .then(res => {
                showMessage('Sub statut changé avec succès', 'success');
                onReload();
            })
            .catch(err => {
                console.log(err);
                showMessage('Error inattendu', 'error');
            })
    }
    return (
        <Menu
            dropProps={{
                align: { top: 'bottom', left: 'left' },
            }}
            items={
                (SUB_STATUS_EMPRUNTEUR).map((s, i) => ({
                    label: <Text size="small" color={'#707070'} key={i}>{s}</Text>,
                    onClick: () => changeStatus(s)
                }))
            }
        >
            <Box direction="row" align="center"
                justify="center" gap="small">
                <PrimaryButton background="#000" icon={<Projects />} label={'Change phase'} />
            </Box>
        </Menu>
    )
}

export default DealSubStatusChange;

const grilleTaux = [
  {
    age: 20,
    fumeur: false,
    tauxBanque: 0.0025,
    tauxTrusti: 0.0005,
  },
  {
    age: 20,
    fumeur: true,
    tauxBanque: 0.0028,
    tauxTrusti: 0.0008,
  },
  {
    age: 30,
    fumeur: false,
    tauxBanque: 0.0029,
    tauxTrusti: 0.0009,
  },
  {
    age: 30,
    fumeur: true,
    tauxBanque: 0.0033,
    tauxTrusti: 0.0013,
  },
  {
    age: 40,
    fumeur: false,
    tauxBanque: 0.0037,
    tauxTrusti: 0.0017,
  },
  {
    age: 40,
    fumeur: true,
    tauxBanque: 0.0045,
    tauxTrusti: 0.0025,
  },
  {
    age: 50,
    fumeur: false,
    tauxBanque: 0.0051,
    tauxTrusti: 0.0031,
  },
  {
    age: 50,
    fumeur: true,
    tauxBanque: 0.0053,
    tauxTrusti: 0.0033,
  }
]


export const getEmprunteurResults = (data) => {
  console.log(data);
  if (!data.montant ||
    !data.duree ||
    !data.taux) {
    return;
  }
  const montant = Number(data.montant);
  const duree = data.duree;
  const montantTotalAssurance = data.montant_assurance_connu ? Number(data.montant_total_assurance) : 0;
  const dateDebut = new Date(data.date_du_pret ?? new Date());
  const resteDuree = duree - (new Date().getTime() - dateDebut.getTime()) / 1000 / 60 / 60 / 24 / 365.25;
  const tempsPasse = duree - resteDuree;
  let resteAPayer = montant - ((montant / (duree * 12)) * (tempsPasse * 12));
  if (resteAPayer > montant) resteAPayer = montant;

  let totalBanque = 0;
  let cotisation = 0;
  let economie = 0;
  let tauxMoyen = 0;
  let totalMois = 0;
  let emps = JSON.parse(JSON.stringify(data.emprunteurs));
  if (!emps)
    emps = [];
  emps.push(data)
  emps.forEach((emprunteur) => {
    console.log(emprunteur);
    const age = new Date().getFullYear() - new Date(emprunteur.date_of_birth).getFullYear();
    console.log(age);
    const tauxAssurance = grilleTaux.find((item) => {
      return age >= item.age - 10 && age < item.age && item.fumeur === emprunteur.fumeur;
    })
    console.log(tauxAssurance);
    if (!tauxAssurance) return;
    console.log(resteDuree);
    const prixAssuranceTrusti = resteAPayer * tauxAssurance.tauxTrusti * resteDuree;
    const prixAssuranceBanque = resteAPayer * tauxAssurance.tauxBanque * resteDuree;
    cotisation += prixAssuranceTrusti;
    economie += prixAssuranceBanque - prixAssuranceTrusti;
    tauxMoyen += tauxAssurance.tauxTrusti;
    totalBanque += prixAssuranceBanque;
    const prixMensuelTrusti = prixAssuranceTrusti / 12 / resteDuree;

    totalMois += prixMensuelTrusti;

    console.log(prixAssuranceTrusti);
  });

  tauxMoyen = tauxMoyen / (data.emprunteurs.length + 1);
  let isEconomie = false;
  console.log(montantTotalAssurance);
  if (montantTotalAssurance) {
    isEconomie = montantTotalAssurance > cotisation;
    if (montantTotalAssurance > cotisation) {
      totalBanque = Number(montantTotalAssurance);
      economie = totalBanque - cotisation;
    }
  } else {
    isEconomie = economie > 0;
  }
  let economiePourcent = economie / totalBanque;

  console.log(cotisation, economie, tauxMoyen, totalBanque, economiePourcent);

  console.log(tempsPasse);
  console.log(resteAPayer);

  return {
    cotisation,
    economie,
    tauxMoyen,
    economiePourcent,
    totalMois,
    totalBanque,
    isEconomie,
  }
}


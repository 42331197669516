import {
    Box,
    Menu, Text
} from "grommet";
import { Archive } from "grommet-icons";
import React from 'react';
import { modifyDeal } from "../../../../services/common_service";
import { ABONDONS, ARCHIVE, CLOTURE, DOSSIER_ENVOYE_A_LA_BANQUE, DOSSIER_EN_INSTRUCTION, EN_ETUDE_DE_ANALYSTE, EN_ETUDE_DE_PRE_ANALYSTE, FACTURE, NOUVEAU, NOUVEAU_REMPLI, OFFRE_ACCEPTE_PAR_CLIENT, PERDU, PRET_ACCORDE_PAR_LA_BANQUE, PROSPECT, REALISE } from "../../../../services/credit_status";
import { A_RAPPELER_PLUS_TARD, RESILIE } from "../../../../services/devis_contrat_status";
import { closeAndCopyCredit } from "../../../../services/credit_service";


const INACTIVE_STATUS = [
    PROSPECT,
    NOUVEAU,
    A_RAPPELER_PLUS_TARD,
    NOUVEAU_REMPLI,
    DOSSIER_EN_INSTRUCTION,
    EN_ETUDE_DE_PRE_ANALYSTE,
    EN_ETUDE_DE_ANALYSTE,
    DOSSIER_ENVOYE_A_LA_BANQUE,
    PRET_ACCORDE_PAR_LA_BANQUE,
    OFFRE_ACCEPTE_PAR_CLIENT,
    REALISE,
    FACTURE,
    CLOTURE,
    ABONDONS,
    PERDU,
    RESILIE,
    ARCHIVE,
]

const CreditDealStatusChange = ({ deal, path, onReload, showMessage, validation }) => {
    const doChangeStatus = s => {
        let _deal = { id: deal.id };
        var _date_field = 'prospect';
        switch (s) {
            case NOUVEAU_REMPLI:
                _date_field = 'nouveau_rempli';
                break;
            case A_RAPPELER_PLUS_TARD:
                _date_field = 'a_rappeler_plus_tard';
                break;
            case DOSSIER_EN_INSTRUCTION:
                _date_field = 'dossier_en_instruction';
                break;
            case EN_ETUDE_DE_PRE_ANALYSTE:
                _date_field = 'en_etude_de_pre_analyste';
                break;
            case EN_ETUDE_DE_ANALYSTE:
                _date_field = 'en_etude_de_analyste';
                break;
            case DOSSIER_ENVOYE_A_LA_BANQUE:
                _date_field = 'dossier_envoye_a_la_banque';
                break;
            case PRET_ACCORDE_PAR_LA_BANQUE:
                _date_field = 'pret_accorde_par_la_banque';
                break;
            case OFFRE_ACCEPTE_PAR_CLIENT:
                _date_field = 'offre_accepte_par_client';
                break;
            case REALISE:
                _date_field = 'resilie';
                break;
            case FACTURE:
                _date_field = 'facture';
                break;
            case CLOTURE:
                _date_field = 'cloture';
                break;
            case ABONDONS:
                _date_field = 'abondons';
                break;
            default:
                _date_field = 'prospect';
                break;
        }
        _deal[`date_${_date_field}`] = new Date();
        _deal.status = s;
        modifyDeal(_deal, path)
            .then(res => {
                showMessage('Statut changé avec succès', 'success');
                onReload(res.data);
            })
            .catch(err => {
                console.log(err);
                showMessage('Error inattendu', 'error');
            });
    }
    const changeStatus = s => {
        let err = false;
        if (s === CLOTURE)
            err = validation();
        if (err) {
            return showMessage(`${err.toUpperCase()} est obligatoire`, "error");
        }
        let _deal = { id: deal.id };
        if (s !== CLOTURE) {
            if (s === RESILIE) {
                _deal.date_resiliation = new Date();
            }
            doChangeStatus(s);
        } else {
            if (window.confirm('L’apporteur est-il lié a la vente ?')) {
                doChangeStatus(s);
            } else {
                closeAndCopyCredit(_deal)
                    .then(res => {
                        showMessage('Statut changé avec succès', 'success');
                        onReload(res.data);
                    })
                    .catch(err => {
                        console.log(err);
                        showMessage('Error inattendu', 'error');
                    });
            }
        }
    }
    return (
        <Menu label='Change statut'
            icon={<Archive color="#fff" />}
            reverse={false}
            primary
            style={{ background: "#000" }}
            dropProps={{
                align: { top: 'bottom', left: 'left' },
            }}
            items={
                INACTIVE_STATUS.map((s, i) => ({
                    label: <Text size="small" color={'#707070'} key={i}>{s}</Text>,
                    onClick: () => changeStatus(s)
                }))
            }
        >
        </Menu>
    )
}

export default CreditDealStatusChange;

export {
    INACTIVE_STATUS
}
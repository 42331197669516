import { Box, Button, Card, FileInput, Grid, Heading, Select, Text } from "grommet";
import React, { useState } from "react";
import DateInput from "../../../elements/date_input";
import { FRACTIONNEMENT_TYPE } from "../../../../services/types";
import CustomInput from "../../../elements/custom_input";
import CustomSelect from "../../../elements/select_fill";
import ToggleButton from "../../../elements/toggle_button/toggle_button";
import { Checkmark, DocumentDownload, Trash } from "grommet-icons";
import PrimaryButton from "../../../elements/primary_button";
import {
  uploadContractPDF,
  uploadFile,
} from "../../../../services/storage_service";
import { FRAIS_DOSSIER } from "../../../elements/code_promo";

const Line = ({ leftLabel, rightChildren, widthLeft, widthRight, size }) => (
  <Box>
    <Text color="grey">{leftLabel}</Text>
    <Box width="100%">
      {rightChildren}
    </Box>
  </Box>
)

const FormTitle = ({ title }) => (
  <Box margin="20px 0 0 0">
    <Text weight="bold" color="grey">{title}</Text>
    <Box
      width="50px" height="3px" background="brand"
    />
  </Box>
);

const doUploadContratPDF = (files, callback, showError) => {
  const fileList = files;
  for (let i = 0; i < fileList.length; i += 1) {
    const file = fileList[i];
    console.log(file.name);
    uploadContractPDF(file)
      .then((result) => {
        // console.log("doUploadContratPDF = ", result.data);
        callback(result.data);
      })
      .catch((err) => {
        showError(
          "Erreur: Le fichier téléchargé n'est pas un PDF modifiable.",
          "error"
        );
        callback([]);
      });
  }
};

const doUploadFile = (files, callback, showError) => {
  const fileList = files;
  for (let i = 0; i < fileList.length; i += 1) {
    const file = fileList[i];
    console.log(file.name);
    uploadFile(file)
      .then((result) => {
        console.log("result = ", result.data);
        callback(result.data);
      })
      .catch((err) => {
        showError("Erreur inattendu télécharger.");
        callback([]);
      });
  }
};

const onAmountChanged = (event, callback, showError, callbackNoChanged) => {
  if (!event.target.value) {
    callback(0);
    return;
  }
  event.target.value = event.target.value
    .replace(/[^0-9.,]/g, "")
    .replace(/,/g, ".")
    .replace(/(\..*?)\..*/g, "$1");
  if (event.target.value.slice(-1) === ".") {
    // skip callback to next step
    callbackNoChanged(event.target.value);
    return;
  }
  const _arrA = event.target.value.split(".");
  if (_arrA.length > 1) {
    if (_arrA[1].replace(/0/g, "") === "") {
      // skip callback to next step
      callbackNoChanged(event.target.value);
      return;
    }
  }
  const value = parseFloat(event.target.value || "0");
  if (isNaN(value)) {
    showError("Valeur non valid");
    return;
  } else {
    callback(value);
  }
};

const DevisContratForm = ({
  devis,
  updateDevis,
  isModeContrat,
  allCompanies,
  sendEmailDevisContrat,
  submitModification,
  showMessage,
}) => {
  const [fraisDossier, setFraisDossier] = useState(devis.frais_dossier || 0);
  const [fraisDossierPreinput, setFraisDossierPreinput] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // array from 0% to 99%
  const _optionsPercents = [...Array(100).keys()].map((i) => {
    return `${i}`;
  });
  const [optionsSurCommission, setOptionsSurCommission] = useState(_optionsPercents);
  const [optionsCommissionPeriodique, setOptionsCommissionPeriodique] = useState(_optionsPercents);
  const [optionsCommissionInitial, setOptionsCommissionInitial] = useState(_optionsPercents);

  return (
    <Box
      gap="small"
      width="100%"
      alignSelf="center"
      pad="small"
    >
      <Heading level={3}>
        {isModeContrat ? `Envoyer un contrat PER` : `Envoyer un devis PER`}
      </Heading>

      <Box width="large" gap="medium">
        <FormTitle title="Information contrat" />
        <Grid columns={["1/2", "1/2"]} gap="small">
          <Line
            leftLabel={`Versement initiale`}
            rightChildren={
              <CustomInput
                value={devis.first_payment}
                reverse
                icon="€"
                onChange={(event) => {
                  onAmountChanged(
                    event,
                    (amount) => {
                      updateDevis("first_payment", amount);
                    },
                    showMessage,
                    (nochanged) => {
                      updateDevis("first_payment", nochanged);
                    }
                  );
                }}
              />
            }
          />
          <Line
            leftLabel={`Versement périodique`}
            rightChildren={
              <CustomInput
                value={devis.monthly_payment}
                reverse
                icon="€"
                onChange={(event) => {
                  onAmountChanged(
                    event,
                    (amount) => {
                      updateDevis("monthly_payment", amount);
                    },
                    showMessage,
                    (nochanged) => {
                      updateDevis("monthly_payment", nochanged);
                    }
                  );
                }}
              />
            }
          />
          <Line
            leftLabel={`Taux hypothèse de rendement`}
            rightChildren={
              <CustomInput
                value={devis.rate_return}
                reverse
                icon="%"
                onChange={(event) => {
                  onAmountChanged(
                    event,
                    (amount) => {
                      updateDevis("rate_return", amount);
                    },
                    showMessage,
                    (nochanged) => {
                      updateDevis("rate_return", nochanged);
                    }
                  );
                }}
              />
            }
          />
          <Line
            leftLabel={`Résultat projection PER compagnie`}
            rightChildren={
              <CustomInput
                value={devis.result_projection_company}
                reverse
                icon="€"
                onChange={(event) => {
                  onAmountChanged(
                    event,
                    (amount) => {
                      updateDevis("result_projection_company", amount);
                    },
                    showMessage,
                    (nochanged) => {
                      updateDevis("result_projection_company", nochanged);
                    }
                  );
                }}
              />
            }
          />
        </Grid>

        <FormTitle title="Information comptabilité" />

        <Line
          leftLabel={`Frais de dossier / an`}
          rightChildren={
            <FRAIS_DOSSIER
              devis={devis}
              setFraisDossier={setFraisDossier}
              setFraisDossierPreinput={setFraisDossierPreinput}
              updateDevis={updateDevis}
              fraisDossier={fraisDossier}
              fraisDossierPreinput={fraisDossierPreinput}
            />
          }
        />

        {fraisDossierPreinput === false && (
          <Line
            leftLabel="Indiquer les frais de dossier"
            rightChildren={
              <CustomInput
                value={devis.frais_dossier}
                onChange={(event) => {
                  onAmountChanged(
                    event,
                    (amount) => {
                      setFraisDossier(amount);
                      updateDevis("frais_dossier", amount);
                    },
                    showMessage,
                    (nochanged) => {
                      updateDevis("frais_dossier", nochanged);
                    }
                  );
                }}
              />
            }
          />
        )}

        <Grid columns={["1/2", "1/2"]} gap="small">
          <Line
            leftLabel="Compagnie"
            rightChildren={
              <CustomSelect
                style={{ width: "100%" }}
                placeholder="Compagnie"
                options={allCompanies ? allCompanies.map((e) => e.label) : []}
                value={devis.compagnie}
                onChange={({ option }) => updateDevis("compagnie", option)}
              />
            }
          />
          <Box />
          <Line
            leftLabel="Taux de com versement initial"
            rightChildren={


              <Select
                options={optionsCommissionInitial}
                value={`${devis.taux_commission_initial || 0}`}
                onChange={({ option }) => updateDevis("taux_commission_initial", parseInt(option))}
                onSearch={(text) => {
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    "\\$&"
                  );
                  const exp = new RegExp(escapedText, "i");
                  setOptionsCommissionInitial(optionsCommissionInitial.filter((o) => exp.test(o)));
                }}
                icon={<Text >%</Text>}
              />
            }
          />

          <Line
            leftLabel="Taux de com versement périodique"
            rightChildren={
              <Select
                options={optionsCommissionPeriodique}
                value={`${devis.taux_commission_periodique || 0}`}
                onChange={({ option }) => updateDevis("taux_commission_periodique", parseInt(option))}
                onSearch={(text) => {
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    "\\$&"
                  );
                  const exp = new RegExp(escapedText, "i");
                  setOptionsCommissionPeriodique(optionsCommissionPeriodique.filter((o) => exp.test(o)));
                }}
                icon={<Text >%</Text>}
              />
            }
          />

          <Line
            leftLabel="Taux de sur-commission"
            rightChildren={
              <Select
                options={optionsSurCommission}
                value={`${devis.taux_sur_commission || 0}`}
                onChange={({ option }) => updateDevis("taux_sur_commission", parseInt(option))}
                onSearch={(text) => {
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    "\\$&"
                  );
                  const exp = new RegExp(escapedText, "i");
                  setOptionsSurCommission(optionsSurCommission.filter((o) => exp.test(o)));
                }}
                icon={<Text >%</Text>}
              />
            }
          />
        </Grid>

        <FormTitle title="Information contrat" />

        <Grid columns={["1/2", "1/2"]} gap="small">
          {isModeContrat && (
            <Line
              leftLabel="Numéro de contrat"
              rightChildren={
                <CustomInput
                  onChange={(event) => {
                    updateDevis("numero_contrat", event.target.value);
                  }}
                  value={devis.numero_contrat || ""}
                />
              }
            />
          )}
          <Line
            leftLabel="Date à laquelle vous souhaitez débuter votre assurance"
            rightChildren={
              <DateInput
                value={devis.date_effet || null}
                onChange={(date) => updateDevis("date_effet", date)}
              />
            }
          />
        </Grid>



        {/* <Line
          leftLabel="Périodicité"
          rightChildren={
            <ToggleButton
              groups={[
                {
                  label: "Annuel",
                  selected:
                    devis.loan_period_type === FRACTIONNEMENT_TYPE.annual,
                  onClick: () => {
                    updateDevis(
                      "loan_period_type",
                      FRACTIONNEMENT_TYPE.annual
                    );
                  },
                },
                {
                  label: "Mensuel",
                  selected:
                    devis.loan_period_type === FRACTIONNEMENT_TYPE.monthly,
                  onClick: () => {
                    updateDevis(
                      "loan_period_type",
                      FRACTIONNEMENT_TYPE.monthly
                    );
                  },
                },
              ]}
            />
          }
        /> */}

        {/* 
        {isModeContrat && (
          <Line
            leftLabel="Date à laquelle vous souhaitez débuter votre assurance"
            rightChildren={
              <DateInput
                placeholder="Date début"
                value={devis.begin_date}
                onChange={(date) => updateDevis("begin_date", date)}
              />
            }
          />
        )}



        <Line
          leftLabel="Franchise générale"
          rightChildren={
            <CustomInput
              onChange={(event) => {
                updateDevis("franchise", event.target.value);
              }}
              value={devis.franchise || ""}
            />
          }
        />

         */}
<Card align="center" pad={'small'} direction='row' gap="medium">
            <Box width={'full'}>
              <Line
                leftLabel="IBAN"
                rightChildren={
                  <CustomInput
                    value={devis.iban}
                    onChange={(event) => {
                      updateDevis("iban", event.target.value);
                    }}
                  />
                }
              />
            </Box>
            <Box width={'full'}>

              <Line
                leftLabel="BIC"
                rightChildren={
                  <CustomInput
                    value={devis.bic}
                    onChange={(event) => {
                      updateDevis("bic", event.target.value);
                    }}
                  />
                }
              />

            </Box>
          </Card>

        <FormTitle title="Document" />

        <Box direction="column" gap="small">
          <Box direction="row" gap="large" justify="between">
            {((!isModeContrat && devis.file_devis) ||
              (isModeContrat && devis.file_contrat)) && (
                <Box direction="row" overflow="hidden">
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={`${isModeContrat ? devis.file_contrat : devis.file_devis
                      }`}
                  >
                    <DocumentDownload />
                    {isModeContrat ? "Contrat" : "Devis"}
                  </a>
                </Box>
              )}
            <FileInput
              messages={{
                browse: "Choisir",
                dropPrompt: isModeContrat ? "Contrat" : "Devis",
              }}
              multiple={false}
              onChange={(event) => {
                setIsLoading(true);
                if (isModeContrat) {
                  doUploadContratPDF(
                    event.target.files,
                    async (data) => {
                      if (data.url) {
                        updateDevis("file_contrat", data.url);
                      }
                      setIsLoading(false);
                    },
                    showMessage
                  );
                } else {
                  doUploadFile(
                    event.target.files,
                    async (data) => {
                      if (data.length > 0) {
                        let _uploaded = data[0];
                        updateDevis("file_devis", _uploaded.url);
                      }
                      setIsLoading(false);
                    },
                    showMessage
                  );
                }
              }}
            />
          </Box>
          <Box direction="row" gap="large" justify="between">
            {devis.file_contrat_condition && (
              <Box direction="row" gap="small" overflow="hidden">
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={`${devis.file_contrat_condition}`}
                >
                  <DocumentDownload />
                  {"Conditions générales"}
                </a>
              </Box>
            )}
            <FileInput
              messages={{
                browse: "Choisir",
                dropPrompt: "Conditions générales",
              }}
              multiple={false}
              onChange={(event) => {
                setIsLoading(true);
                doUploadFile(
                  event.target.files,
                  (data) => {
                    if (data.length > 0) {
                      let _uploaded = data[0];
                      updateDevis(
                        isModeContrat
                          ? "file_contrat_condition"
                          : "file_devis_condition",
                        _uploaded.url
                      );
                    }
                    setIsLoading(false);
                  },
                  showMessage
                );
              }}
            />
          </Box>
          {devis.file_others ? (
            devis.file_others.map((e, _idx) => {
              return (
                <Box
                  key={`file_${_idx}`}
                  direction="row"
                  pad="small"
                  gap="small"
                  justify="between"
                >
                  <Box direction="row" gap="small" overflow="hidden">
                    {e && (
                      <Button
                        onClick={() => {
                          console.log("remove index = ", _idx);
                          let _files = devis.file_others;
                          _files.splice(_idx, 1);
                          updateDevis("file_others", _files);
                        }}
                      >
                        <Trash color="red" />
                      </Button>
                    )}
                    {e && (
                      <a target={"_blank"} rel="noreferrer" href={`${e}`}>
                        <DocumentDownload />
                        {e.split("/").pop()}
                      </a>
                    )}
                  </Box>
                  <FileInput
                    messages={{
                      browse: "Choisir",
                      dropPrompt: "Ajouter un autre document",
                    }}
                    multiple={false}
                    onChange={(event) => {
                      setIsLoading(true);
                      doUploadFile(
                        event.target.files,
                        async (data) => {
                          if (data.length > 0) {
                            let _uploaded = data[0];
                            let _files = devis.file_others || [];
                            _files[_idx] = _uploaded.url;
                            updateDevis("file_others", _files);
                          }
                          setIsLoading(false);
                        },
                        showMessage
                      );
                    }}
                  />
                </Box>
              );
            })
          ) : (
            <></>
          )}
          <FileInput
            messages={{
              browse: "Choisir",
              dropPrompt: "Ajouter un autre document",
            }}
            multiple={false}
            onChange={(event) => {
              setIsLoading(true);
              doUploadFile(
                event.target.files,
                async (data) => {
                  if (data.length > 0) {
                    let _uploaded = data[0];
                    let _files = devis.file_others || [];
                    _files.push(_uploaded.url);
                    updateDevis("file_others", _files);
                  }
                  setIsLoading(false);
                },
                showMessage
              );
            }}
          />
        </Box>
        <Box
          align="center"
          margin="medium"
          direction="row"
          gap="small"
          justify="center"
        >
          <PrimaryButton
            disabled={isLoading}
            label={`Sauvegarder`}
            icon={<span>💾</span>}
            background="#59A4F4"
            onClick={() => {
              setIsLoading(true);
              submitModification(devis, () => setIsLoading(false));
            }}
          />
          <PrimaryButton
            disabled={isLoading}
            label={`Valider & Envoyer`}
            icon={<span>✅</span>}
            onClick={() => {
              setIsLoading(true);
              sendEmailDevisContrat(devis, () => setIsLoading(false));
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DevisContratForm;

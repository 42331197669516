
import { Box, Card, CheckBox, Heading, Layer, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import { Checkmark, Close, Edit, StatusGoodSmall, Trash, Validate } from 'grommet-icons';
import React from 'react';
import BlockUi from 'react-block-ui';
import "react-block-ui/style.css";
import { getSimpleDateFR, toCurrency } from '../../../../../../services/utils';
import AddDevis from '../../../../../elements/add_devis';
import Refresh from '../../../../../elements/refresh';
import { getFacturation, updateSubImportDate, updateSubPaidStatus } from '../../../../souscriptions/service';
import Import from './import';
import DateInput from '../../../../../elements/date_input';
import moment from 'moment';

const PROPERTIES = [
    { label: 'Nom', prop: 'nom_locataire' },
    { label: 'Prénom', prop: 'prenom_du_locataire' },
    { label: 'Email', prop: 'mail_locataire' },
    { label: 'Adresse client', prop: 'adresse_du_locataire' },
    { label: 'Adresse location', prop: 'adresse_du_risque' },
    { label: 'Date de fin séjour', prop: 'date_de_fin_de_sejour' },
    { label: 'Date de début séjour', prop: 'date_de_debut_de_sejour' },
    { label: 'Prix location', prop: 'prix_sejour' },
]

class Facturations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            facturations: [],
            agence: props.agence,
            addMore: false,
            loading: false,
            facture: null
        }
    }

    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.setState({ loading: true });
        getFacturation(this.state.agence)
            .then(res => this.setState({ facturations: res.data, loading: false }))
            .catch(err => {
                this.setState({ loading: false });
            })
    }

    updatepaidStatus = (ids, paid) => {
        this.setState({ loading: true });
        updateSubPaidStatus(ids, paid)
            .then(res => {
                this.reload();
                this.setState({ loading: false, facture: null});
            }).catch(err => this.setState({ loading: false }));
    }

    deleteFacture = (ids) => {
        this.setState({ loading: true });
        updateSubPaidStatus(ids, false, true)
            .then(res => {
                this.reload();
                this.setState({ loading: false });
            }).catch(err => this.setState({ loading: false }));
    }

    changeDateImport = (date) => {
        this.setState({ loading: true });
        updateSubImportDate(this.state.facture.sub_ids, moment(date).format('YYYY-MM-DD'))
            .then(res => {
                this.reload();
                this.setState({ loading: false, facture: null });
            }).catch(err => this.setState({ loading: false }));
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box style={{ width: "100%" }}
                    align="center" justify="center"
                    alignSelf="center"
                    alignContent="center" gap="medium">
                    {this.state.addMore ?

                        <Import agence={this.state.agence} showMessage={this.props.showMessage}
                            onClose={() => this.setState({ addMore: false }, () => this.reload())}
                        /> :
                        <Box width="xlarge" gap="medium" pad={"small"} justify='center' align='center'>
                            <Heading level={3} textAlign='center'>
                                Facturations
                            </Heading>
                            <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
                                <Refresh refresh={this.reload} />
                                <AddDevis onAdd={() => this.setState({ addMore: true })} />
                            </Box>

                            <Box direction='row' gap='medium'>
                                <Card pad={'small'} align="center">
                                    <Text size='small'>
                                        Prime TTC à regler <b> {toCurrency(this.state.facturations.map(s => s.total).reduce((a, s) => a + s, 0))}</b>
                                    </Text>
                                </Card>
                                <Card pad={'small'} align="center">
                                    <Text size='small'>
                                        Total Trustiway à regler <b> {toCurrency(this.state.facturations.map(s => s.total_trustiway).reduce((a, s) => a + s, 0))}</b>
                                    </Text>
                                </Card>
                            </Box>

                            <Box width={'xlarge'}>

                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableCell><Text size='small'><strong>Date d'import</strong></Text></TableCell>
                                            <TableCell><Text size='small'><strong>Ref</strong></Text></TableCell>
                                            <TableCell><Text size='small'><strong>Etat facture</strong></Text></TableCell>
                                            <TableCell><Text size='small'><strong>Date de souscription</strong></Text></TableCell>
                                            <TableCell><Text size='small'><strong>Date de facturation</strong></Text></TableCell>
                                            <TableCell><Text size='small'><strong>Nb</strong></Text></TableCell>
                                            <TableCell><Text size='small'><strong>Produits</strong></Text></TableCell>
                                            <TableCell><Text size='small'><strong>PrimeTTC</strong></Text></TableCell>
                                            <TableCell><Text size='small'><strong>Total Trustiway</strong></Text></TableCell>
                                            <TableCell><Text size='small'>Cocher la case lorsque la facture est payée</Text></TableCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {
                                            this.state.facturations.map((facture, index) => (
                                                <TableRow key={index + 1}>
                                                    <TableCell>
                                                        <Box direction='row' gap='xsmall'>
                                                            <Text size='xsmall'><b>{facture.date_import ? getSimpleDateFR(facture.date_import) : ''}</b></Text>
                                                            <Edit size='small' color='brand' style={{ cursor: 'pointer' }} onClick={() => this.setState({ facture })} />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text size='xsmall'><b>{facture._id}</b></Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <StatusPayment status={facture.paid} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text size='xsmall'>{getSubscriptionDateRange(facture.date_de_souscription)}</Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text size='xsmall'>{facture.date_de_facturation}</Text>
                                                    </TableCell>
                                                    <TableCell>
                                                       <Text size='xsmall'>{facture.count}</Text>
                                                            
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            facture.products.map(f => (
                                                                <Text size='xsmall'>{f}<br /></Text>
                                                            ))
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text size='xsmall'><b>{toCurrency(facture.total)}</b></Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text size='xsmall'><b>{toCurrency(facture.total_trustiway)}</b></Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box direction='row' gap='small'>
                                                            <CheckBox
                                                                checked={facture.paid}
                                                                onChange={(event) => {
                                                                    this.updatepaidStatus(facture.sub_ids, event.target.checked);
                                                                }}
                                                            />

                                                            {!facture.paid &&
                                                                <Trash color='brand' style={{ cursor: 'pointer' }} onClick={() =>
                                                                    this.deleteFacture(facture.sub_ids)
                                                                } />
                                                            }

                                                        </Box>
                                                    </TableCell>

                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    }
                </Box>
                {this.state.facture &&
                <ModificationDateImport 
                    facture={this.state.facture}
                    onClose={() => this.setState({ facture: null })} 
                    onValidate={date => this.changeDateImport(date)}
                />
                }
            </BlockUi>
        )
    }
}

const ModificationDateImport = ({ facture, onClose, onValidate }) => {
    const [date, setDate] = React.useState(facture.date_import);
    return (
        <Layer
        >
            <Box gap='xsmall' pad='medium' width='medium'>
                <Heading level={4}>Modification de la date d'import</Heading>
                <Box gap='small' direction='row' justify='between' align='center'>
                    <DateInput size={'small'} value={date} onChange={setDate} />
                    <Box direction='row' gap='small'>
                        <Checkmark color='brand' onClick={() => onValidate(date)} style={{cursor: 'pointer'}} />
                        <Close onClick={onClose} style={{cursor: 'pointer'}} />
                    </Box>
                </Box>
            </Box>
        </Layer>
    )
}

const getSubscriptionDateRange = subs => {
    let uniqueDates = subs;
    return uniqueDates.length === 1 ? uniqueDates[0] : `${uniqueDates[0]} au ${uniqueDates[uniqueDates.length - 1]}`;
}
const StatusPayment = ({ status }) => (
    <Box direction="row" gap="xsmall" pad="xsmall" align="center">
        <StatusGoodSmall size="small" color={getStatusColor(status)} />
        <Text size="xsmall">
            <strong>{status === true ? 'Payé' : 'À reglé'}</strong>
        </Text>
    </Box>
)
const getStatusColor = status => status ? "#33BC00" : "#FF9D19";

export default Facturations;

import React, { useState } from "react";
import { Box, Text } from "grommet";
import { CaretDownFill, CaretRightFill } from "grommet-icons";
import { NativeMenu } from "../native_menu";

const MainMenu = ({ label, items, onClick, isActive }) => {

	const [isOpen, setOpen] = useState(false);

	return (
		<NativeMenu
			key={label}
			dropProps={{
				align: { top: 'bottom', left: 'left' },
			}}
			plain
			size='small'
			label={label}
			color={'#707070'}
			isOpen={isOpen}
			setOpen={setOpen}
			items={items}
			onClick={onClick}
		>
			<Box direction="row" gap="xxsmall" justify="center" align="center"
				onMouseLeave={() => setOpen(false)}
				onMouseEnter={() => setOpen(true)}
			>
				<Text color={'#707070'}>{isActive ? <b>{label}</b> : label}</Text>
				{isActive ?
					<CaretDownFill color={'#707070'} size="medium" /> :
					<CaretRightFill color={'#707070'} size="medium" />
				}
			</Box>
		</NativeMenu>
	)
}

export default MainMenu;
import { Box, CheckBox, Heading, Table, TableBody, TableCell, TableRow, Text } from 'grommet';
import React from 'react';
import { getAllOpportunityConfigs, saveOpportunityConfigs } from '../../../services/opportunity_service';

const PRODUCTS = [
    'Assurance emprunteur',
    'Assurance habitation',
    'Assurance propriétaire non occupant',
    'Assurance moto',
    'Assurance auto',
    'Assurance santé',
    'Assurance santé chien & chat',
    'Assurance PNO Gîte',
    'Assurance dommage ouvrage',
    // Epargne
    'Plan épargne retraite',
    'Lead Mandat Immobilier',
    'Recommandation Immo',
    // Crédit
    'Crédit Immobilier',
    // Prévoyance
    'Assurance prévoyance'
];

class GestionOpportunity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            opportunity_configs: [],
            updateNum: 1
        }
    }

    componentDidMount() {

        getAllOpportunityConfigs().then((res) => {
            this.setState({ opportunity_configs: res.data });
            console.log('getAllOpportunityConfigs = ', res.data);
        }).catch(e => {
            console.log("error = ", e);
        });
    }

    changeProduitSelected = (o, p, value) => {
        let _configs = this.state.opportunity_configs;
        console.log('o = ', o);
        console.log('p = ', p);
        console.log('value = ', value);
        for (let i = 0; i < _configs.length; i++) {
            const c = _configs[i];
            if (c.id === o.id) {
                if (value) {
                    if (!c.produits?.includes(p)) {
                        if (c.produits.length >= 0) {
                            c.produits.push(p)
                        } else {
                            c.produits = [p];
                        }
                    }
                } else {
                    c.produits.splice(c.produits.indexOf(p), 1);
                }
                _configs[i] = c;
                console.log('c = ', c);
                // save
                saveOpportunityConfigs(c).then((res) => {
                    this.props.showMessage && this.props.showMessage('Mis à jour avec succès', 'success');
                }).catch(e => {
                    console.log("error = ", e);
                    this.props.showMessage && this.props.showMessage('Error', 'error');
                });
            }

        }

        this.setState({ opportunity_configs: _configs, updateNum: this.state.updateNum + 1 });

    }

    render() {
        console.log('state : ', this.state);
        return (
            <Box style={{ width: "100%", paddingTop: 20 }}
                align="center" justify="center"
                alignSelf="center"
                alignContent="center" gap="medium">

                <Heading level={2}>Opportunité Test</Heading>
                <Text >Choisissez les produits que vous souhaitez proposer en fonction des réponses aux questions</Text>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell></TableCell>
                            {PRODUCTS.map((p, index) => (
                                <TableCell Keys={`p_${index}_`}>
                                    <Text style={{ writingMode: 'vertical-rl', textOrientation: 'mixed' }} >{p}</Text>
                                </TableCell>
                            ))}
                        </TableRow>
                        {this.state.opportunity_configs.map((o, idx) => (
                            <TableRow key={`row_${this.state.updateNum}_${idx}`} style={{ backgroundColor: idx % 2 === 0 ? '#ccc' : '#fff' }}>
                                <TableCell  >
                                    <Text>{o.question}</Text><br />
                                    <Text><i>{o.description}</i></Text>
                                </TableCell>
                                {PRODUCTS.map((p, _index) => (
                                    <TableCell Keys={`pp_${_index}`}>
                                        <CheckBox checked={o.produits?.includes(p)} onChange={(event) => {
                                            this.changeProduitSelected(o, p, event.target.checked);
                                        }}></CheckBox>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>


            </Box>
        )
    }
}

export default GestionOpportunity;
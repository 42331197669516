const axios = require('axios');
const storageService = require('./storage_service');
const { baseAPI } = require('../config/config.json');
const HEADERS = { 
    'Authorization': `Bearer ${storageService.getToken()}`
};

const createTrustiMoto = (devis) => {
    return axios.post(`${baseAPI}/public/trusti-moto`, devis, { headers: HEADERS });
}

const getTrustiMotos = (client_id, _query, status) => {
    let commercial_id = storageService.getCommercialId();
    let params = { commercial_id, _q: _query, status, _limit: -1 };
    let binome_id = storageService.getBinomeId();
    if(binome_id) {
        delete params.commercial_id;
        params['_where[0][commercial_id_in]'] = [commercial_id, binome_id];
    }
    if(storageService.hasAdministrativeRole()) {
        delete params.commercial_id;
        delete params['_where[0][commercial_id_in]']
    }
    if(client_id) {
        params['_where[1][client.id]']=client_id;
        delete params.commercial_id;
    }
    return axios.get(`${baseAPI}/public/trusti-moto`, { params, headers: HEADERS });
}

const updateTrustiMoto = (devis) => {
    return axios.put(`${baseAPI}/public/trusti-moto/${devis.id}`, devis, { headers: HEADERS });
}

const sendForm = (devis) => {
    return axios.post(`${baseAPI}/commerciale/trusti-moto/send`, devis, { headers: HEADERS });
}

const sendYousign = (body) => {
    return axios.post(`${baseAPI}/public/trusti-moto/sendYousign`, body, { headers: HEADERS });
}

const sendRelanceSignature = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-relance-signature`, devis, { headers: HEADERS });
}
const sendRelancePayment = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-relance-payment`, devis, { headers: HEADERS });
}

const sendRelanceRI = (devis) => {
    return axios.post(`${baseAPI}/commerciales/send-relance-ri`, devis, { headers: HEADERS });
}

module.exports = {
    createTrustiMoto,
    updateTrustiMoto,
    getTrustiMotos,
    sendForm,
    sendYousign,
    sendRelanceSignature,
    sendRelancePayment,
    sendRelanceRI
}
import React from "react";
import { Box, Heading, Layer, ResponsiveContext, Tab, Tabs, Text } from "grommet";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import PrimaryButton from "../../../elements/primary_button";
import {
    Close,
    DocumentVerified,
    Previous,
    Test,
} from "grommet-icons";
import { createContact, updateContact } from "../../../../services/contact_service";
import { getCommercialId, uploadFile } from "../../../../services/storage_service";
import { saveApporteurPro, sendConvention } from "../../service";
import ApporteurProInfo from "./info";
import Souscriptions from "../../souscriptions";
import { markSigned } from "../../../voyages/agences/service";
import { createApporteurUsers } from "../../../my_trustiway/service";
import AppAffilieByApporteur from "../../../my_trustiway/users/affilie_by_apporteur_pro";


class ApporteurPro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMode: props.apporteur.id ? true : false,
            loading: false,
            apporteur: props.apporteur,
            originalApporteur: undefined,
            popup: false,
        };
    }

    submit = async (callback) => {
        let apporteur = this.state.apporteur;
        let tmpCom = apporteur.potential_owners;
        let interlocuteurs = apporteur.interlocuteurs;
        if (!interlocuteurs || interlocuteurs.length === 0) {
            this.props.showMessage('Veuillez sélectionner un interlocuteur pour recevoir des mails', 'error');
            return;
        }
        let tmp = interlocuteurs.map(s => s);
        let interlocuteurIds = [];
        let apporteurEmail = '';
        for (let i = 0; i < interlocuteurs.length; i++) {
            let inter = interlocuteurs[i];
            if (inter.id) {
                interlocuteurIds.push(inter.id);
                apporteurEmail = apporteurEmail || (inter.receiving_mail ? inter.email : '');
                try {
                    await updateContact(inter);
                } catch (error) {
                    let errMes = error.response?.data?.message || 'Problème de mis à jour de contact';
                    this.props.showMessage(errMes, 'error');
                    console.log(error.response);
                    return;
                }
            } else {
                let res;
                try {
                    res = await createContact(inter);
                } catch (error) {
                    let errMes = error.response?.data?.message || 'Problème de création de contact';
                    this.props.showMessage(errMes, 'error');
                    console.log(error.response);
                    return;
                }
                apporteurEmail = apporteurEmail || (res.data.receiving_mail ? res.data.email : '');
                interlocuteurIds.push(res.data.id);
            }
        }

        if (!apporteurEmail) {
            this.props.showMessage('Veuillez sélectionner un interlocuteur pour recevoir des mails', 'error');
            return;
        }

        if (!apporteur.apporteur_lead) {
            this.props.showMessage('Veuillez sélectionner et valider une gestionnaire de l’apporteur', 'error');
            return;
        }

        apporteur.interlocuteurs = interlocuteurIds;
        apporteur.email = apporteurEmail;
        if (!apporteur.commercial_id) {
            apporteur.commercial_id = getCommercialId();
        }

        if (!tmpCom || tmpCom.length === 0) {
            apporteur.potential_owners = [apporteur.commercial_id];
        } else {
            apporteur.potential_owners = tmpCom.map(c => c.id);
        }

        this.setState({ loading: true });
        saveApporteurPro(apporteur)
            .then(res => {
                this.props.showMessage('Apporteur sauvegardé avec succès', 'success');
                this.setState({ apporteur: res.data, viewMode: true, loading: false });
                if (callback) callback();
                this.props.reload();
            }).catch(err => {
                let body = err.response.data || {};
                let message = body.err === 'USER_EXISTING' ? 'Un utilisateur existant avec email ' + apporteurEmail :
                    body.err === 'ACCOUNT_WITH_SAME_EMAIL_EXISTING' ? `Un souscription en direct compte existant avec email ${apporteurEmail}` :
                        body.err === 'EMAIL_NOT_VALID' ? 'Email non valid' : 'Erreur inattendu';
                this.props.showMessage(message, 'error');
                apporteur.interlocuteurs = tmp;
                apporteur.potential_owners = tmpCom;
                console.log(err);
                this.setState({ loading: false });
            })
    };

    toRecap = () => {
        this.setState({ viewMode: true });
    };

    onApporteurChange = (field, value) => {
        let apporteur = this.state.apporteur;
        apporteur[field] = value;
        this.setState({ apporteur: Object.assign({}, apporteur) });
    }

    upload = async (field, files) => {
        if (!files || files.length === 0) return;
        let file = files[0];
        this.setState({ loading: true });
        let res = await uploadFile(file);
        let url = res.data.length > 0 ? res.data[0].url : '';
        this.onApporteurChange(field, url);
        this.setState({ loading: false });
    }

    sendConvention = () => {
        console.log('sending:');
        const apporteur = this.state.apporteur;
        markSigned(apporteur.id, 'apporteur_pro')
            .then(_ => {
                this.props.showMessage('Convention signé', 'success');
                this.props.reload();

            })
            .catch(err => {
                let info = err.response?.data?.message || 'Erreur inattendu';
                this.props.showMessage(info, 'error');

            })
    }

    render() {
        return (
            <Box
                width={"xlarge"}
            >
                <BlockUi tag="div" blocking={this.state.loading}>
                    <Box fill >

                        <Box direction="row" fill gap="small" align="center" justify="start" >
                            <Box>
                                <PrimaryButton icon={<Previous />} label="Liste"
                                    onClick={() => {
                                        this.props.onClose && this.props.onClose();
                                    }}
                                />
                            </Box>
                            <Heading level={3}><b>{this.state.apporteur?.raisonSocial ? this.state.apporteur?.raisonSocial : (this.state.apporteur.company_mother ? `Création de la maison mère` : `Création de l’apporteur`)}</b></Heading>
                        </Box>

                        <Tabs >
                            <Tab title="Information" >
                                <ApporteurProInfo
                                    apporteur={this.state.apporteur}
                                    onApporteurChange={this.onApporteurChange}
                                    saveApporteur={this.submit}
                                    onCancel={this.props.onClose}
                                    showMessage={this.props.showMessage}
                                    upload={this.upload}
                                    commerciaux={this.props.commerciaux}
                                    sendConvention={this.sendConvention}
                                />
                            </Tab>
                            {this.state.apporteur?.id &&
                                <Tab title="Souscription" >
                                    <Souscriptions
                                        apporteur={this.state.apporteur}
                                        commerciaux={this.props.commerciaux}
                                        showMessage={this.props.showMessage}
                                    />
                                </Tab>
                            }
                            {this.state.apporteur?.id &&
                                <Tab title="Utilisateurs" style={{ width: '100%' }}>
                                    <AppAffilieByApporteur apporteurId={this.state.apporteur.id} showMessage={this.props.showMessage} resendEmail={(_user) => {
                                        createApporteurUsers({
                                            emails: [_user.email],
                                            apporteur_pro: this.state.apporteur.id
                                        }).then(() => {
                                            this.props.showMessage("L'email a été envoyé", "success");
                                        }).catch((err) => {
                                            this.props.showMessage("Échec d'envoyer email", "error");
                                        });
                                    }} />
                                </Tab>
                            }
                        </Tabs>

                        <Box style={{ height: 40 }} />
                    </Box>
                </BlockUi>

            </Box>
        );
    }
}



export default ApporteurPro;

import React from 'react';

import {
    Box,
    DateInput,
    Grommet,
} from 'grommet';
import { TextLabel } from '../text-label';
import { getSimpleDateFR } from '../../../../services/utils';

const customTheme = {
    dateInput: {
        container: {
            round: 'small'
        },
        icon: {
            size: '16px'
        }
    },
};

export const CustomDateInput = ({ value, onChange, editMode }) => (
    editMode ?
        <Grommet theme={customTheme}>
            <Box style={{ maxWidth: 500 }} align="center" justify="center">
                <DateInput
                    value={value}
                    onChange={onChange}
                    format='dd/mm/yyyy'
                    style={{
                        fontSize: "0.75rem",
                        padding: "0.5rem",
                        lineHeight: "1.2rem",
                        color: "rgba(100,116,139,1)"
                    }}
                />
            </Box>
        </Grommet>
        :
        <TextLabel label={value ? getSimpleDateFR(value) : ""} variant={"body1"} />
);
import { Box, Card } from 'grommet';
import { DocumentDownload, DocumentText, DocumentVerified, Edit, Send, Update } from 'grommet-icons';
import React from "react";
import { convertToDealPossible } from '../../../../services/common_service';
import { CONTRAT_CLOTURE, CONTRAT_ENVOYE, DEAL_POSSIBLE, DEVIS_VALIDE, DEAL_POSSIBLE_REMPLI, DEVIS, PROSPECT } from '../../../../services/devis_contrat_status';
import { getSimpleDateFR } from '../../../../services/utils';
import EntrepriseRecap from '../../../contacts/entreprises/entreprise/recap';
import ApporteurGestionnaire from '../../../elements/apporteur_gestionaire';
import ApporteurModification from '../../../elements/apporteur_gestionaire/modification';
import DateRecap from '../../../elements/date_recap';
import DealStatusChange from '../../../elements/deal_status_change';
import EmailEventTracker from '../../../elements/email_tracker';
import { IdDisplay } from '../../../elements/line_container';
import PrimaryButton from '../../../elements/primary_button';
import StandardText from '../../../elements/standard_text';
import SectionDivider from '../../../voyages/components/session_devider';
import TrustiBot from '../../../trustibot';

const Recap = ({ deal, onEdit, showMessage, toDevis, toContrat, onDealReload, reload, onSendForm }) => {

    return (
        <Box
            direction="row"
            gap='small'
            style={{ width: "100vw" }}
        >
            <TrustiBot></TrustiBot>

            <Box
                gap="medium"
                align="center"
                alignSelf="center"
                pad="small"
            >
                <Box
                    width={'large'}
                    justify="center"
                    alignContent="center"
                    align="center"
                    gap="medium"
                >
                    {deal.sub_product && <Card pad={'xsmall'}>
                        <StandardText style={{ fontWeight: 'bold' }} color='brand' label={deal.sub_product} />
                    </Card>}
                    <IdDisplay id={deal.id} />

                    <Box gap="small" width={'full'}>
                        <DateRecap deal={deal} />
                    </Box>
                    <EntrepriseRecap
                        entreprise={deal.entreprise || {}}
                        action={false}
                    />

                    <DealRecap
                        deal={deal}
                    />

                </Box>
                {(deal.apporteur || deal.affiliation) && <Card pad={"small"} width={'xlarge'} >
                    <ApporteurGestionnaire apporteur={deal.apporteur} affiliation={deal.affiliation} source={deal.source} />
                </Card>}
                <Box width={'xlarge'}>
                    <EmailEventTracker messageIds={deal.emailEventIds} />
                </Box>
                <ApporteurModification
                    devis={deal}
                    apporteur={deal.apporteur}
                    showMessage={showMessage}
                    onReload={reload}
                />
                <Box direction='row' gap='medium' pad={'medium'}>
                    <PrimaryButton icon={<Edit />} label='Modifier' onClick={onEdit} />
                    {[DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS].includes(deal.status) &&
                        <PrimaryButton icon={<Send />} label='Envoyer formulaire' background={'#70A4EC'} onClick={onSendForm} />
                    }
                    {[DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS, DEVIS_VALIDE].includes(deal.status) &&
                        <PrimaryButton icon={<DocumentText />} label='Envoyer devis' background={'#70A4EC'} onClick={toDevis} />
                    }
                    {[DEVIS_VALIDE, CONTRAT_ENVOYE, CONTRAT_CLOTURE].includes(deal.status) &&
                        <PrimaryButton
                            label={`Envoyer un contrat`}
                            icon={<DocumentVerified />}
                            background="#64AEF6"
                            onClick={toContrat}
                        />
                    }
                    {deal.status === PROSPECT &&
                        <PrimaryButton
                            icon={<Update />}
                            onClick={() => {
                                deal.status = DEAL_POSSIBLE;
                                convertToDealPossible(deal, 'pro-prev')
                                    .then(res => onDealReload(res.data));
                            }}
                            label="Convertir à Deal possible"
                        />
                    }
                    <DealStatusChange
                        deal={deal}
                        showMessage={showMessage}
                        onReload={reload}
                        path={'pro-prev'}
                    />
                </Box>
            </Box>
        </Box>
    )
}

const DealRecap = ({ deal }) => {

    return (
        <Box
            width={'large'}
            justify="center"
            alignContent="center"
            align="center"
            gap="medium"
            pad={'xsmall'}
        >
            <Box >
                <StandardText style={{ fontWeight: 'bold' }} label='Informations' />
                <SectionDivider />
            </Box>
            <Box gap="xsmall" align="center" width={'full'}>
                <LineDisplay
                    label={"Code postal"}
                    value={`${deal.code_postal}`}
                />
                <LineDisplay
                    label={"Regime"}
                    value={`${deal.regime}`}
                />
                {
                    (deal.beneficiaires || []).map(be => (
                        <Box width={'full'} gap='xsmall'>
                            <LineDisplay
                                label={"Type de bénéficiaire "}
                                value={be.type_beneficiaire}
                            />
                            <LineDisplay
                                label={"Date de naissance "}
                                value={getSimpleDateFR(be.beneficiaire_date_naissance)}
                            />
                        </Box>
                    ))
                }

                <LineDisplay
                    label={"Date d’effet souhaité"}
                    value={getSimpleDateFR(deal.date_effet)}
                />
            </Box>
            <Box >
                <StandardText style={{ fontWeight: 'bold' }} label='Document' />
                <SectionDivider />
            </Box>
            <Box width="xlarge" direction="row" gap="small">
                <Card style={{ width: "100%" }} pad="medium">
                    {deal.entreprise?.file_kbis && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${deal.entreprise?.file_kbis}`}
                        >
                            <DocumentDownload />
                            KBIS
                        </a>
                    )}
                    {deal.entreprise?.file_statut && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${deal.entreprise?.file_statut}`}
                        >
                            <DocumentDownload />
                            Statut
                        </a>
                    )}
                    {deal.file_others ? (
                        deal.file_others.map((e, _idx) => {
                            return (
                                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                                    <DocumentDownload />
                                    {e && e.split("/").pop()}
                                </a>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </Card>
            </Box>
        </Box>
    )
}

const LineDisplay = ({ label, value }) => (
    <Box gap="xxsmall" direction='row' width={'full'}>
        <StandardText size={'small'} label={label + ' : '} />
        <StandardText size={'small'} style={{ fontWeight: 'bold' }} label={value} />
    </Box>
)

export default Recap;
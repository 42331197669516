import { Box } from "grommet";
import { Checkmark, Close, Edit } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { toCurrency } from "../../../../../services/utils";
import { CardContainer, LineTextContainer, TitleContainer } from "../../../../elements/refont/containers/text-containser";
import CustomSelect from "../../../../elements/refont/custom-select";
import { CustomDateInput } from "../../../../elements/refont/date_input";
import CustomTextInput from "../../../../elements/refont/text-input";
import { TextLabel } from "../../../../elements/refont/text-label";

import {
    getEligibleRBT,
    getFranchiseDisplay,
    getFranchiseValue,
    getLocataireRembourseValue,
    getMaximumRemboursement,
    getMonttantPriseEnCharge,
    getNbDatesReloue,
    getPropOuAgenceRembourseType,
    getPropOuAgenceRembourseValue,
    statut
} from './utils';

const getImplineReloue = (sinistre) => {
    if (sinistre.nombre_participant_annule && sinistre.nombre_participant !== sinistre.nombre_participant_annule) return "Non";
    return sinistre.reloue;
}

export default function InfoRemboursement({ sinistre, onChangeSubmit }) {

    const [values, setValues] = useState({ ...sinistre, reloue: getImplineReloue(sinistre) });
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setValues({ ...values });
    }, [sinistre]);

    const handleReset = () => {
        setValues({ ...sinistre });
        setIsEdit(false);
    }
    const onSinistreChange = (field, value) => {
        values[field] = value;
        setValues({ ...values });
    }

    return (
        <CardContainer>
            <Box direction={'row'} gap={'small'} justify="between" align="center">
                <Box fill background={"white"} direction="column">
                    <Box direction={'row'} gap={'small'} justify="between" align="center">
                        <TextLabel label={"Remboursement"} variant={"header3"} />
                        {isEdit ?
                            <Box direction="row" gap="small">
                                <Checkmark size="small" color="brand" onClick={() => {
                                    onChangeSubmit(values, () => setIsEdit(false));
                                }} />
                                <Close size="small" onClick={handleReset} />
                            </Box> :
                            <Box direction="row" gap="small">
                                <Edit size="small" color="brand" onClick={() => setIsEdit(true)} />
                            </Box>
                        }
                    </Box>
                    <Box pad={{ top: '1rem' }}>
                        <TitleContainer>
                            <TextLabel variant={"title1"} label={"Information du paiement"} color={'white'} />
                        </TitleContainer>
                    </Box>
                    <Box pad={{ vertical: '0.5rem' }}>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1"} label={"Acompte :"} />
                                <CustomTextInput
                                    editMode={isEdit}
                                    value={values.acompte}
                                    onChange={e => onSinistreChange('acompte', e.target.value)}
                                />
                            </Box>
                        </LineTextContainer>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1"} label={"Etat du paiement « Acompte » :"} />
                                <CustomSelect
                                    editMode={isEdit}
                                    options={[
                                        statut.facture_locataire,
                                        statut.non_facture_locataire
                                    ]}
                                    value={values.acompte_statut || null}
                                    onChange={({ option }) => {
                                        onSinistreChange('acompte_statut', option);
                                    }}
                                />
                            </Box>
                        </LineTextContainer>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1"} label={"Solde :"} />
                                <CustomTextInput
                                    value={toCurrency(parseFloat(values.montant_sejour - (values.acompte || 0)))}
                                />
                            </Box>
                        </LineTextContainer>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1"} label={"Etat du paiement « Acompte » :"} />
                                <CustomSelect
                                    editMode={isEdit}
                                    options={[
                                        statut.facture_locataire,
                                        statut.non_facture_locataire
                                    ]}
                                    value={values.solde_statut || null}
                                    defaultValue={null}
                                    onChange={({ option }) => {
                                        onSinistreChange('solde_statut', option);
                                    }}
                                />
                            </Box>
                        </LineTextContainer>
                    </Box>
                    <Box pad={{ top: '0.5rem' }}>
                        <TitleContainer>
                            <TextLabel variant={"title1"} label={"Information agence"} color={'white'} />
                        </TitleContainer>
                    </Box>
                    <Box pad={{ vertical: '0.5rem' }}>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1"} label={"La location à t elle été reloué ? :"} />
                                <CustomSelect
                                    disabled={values.nombre_participant_annule && values.nombre_participant !== values.nombre_participant_annule}
                                    editMode={isEdit}
                                    options={[
                                        "Oui",
                                        "Non",
                                        "Pas de reclamation",
                                    ]}
                                    value={values.reloue || null}
                                    onChange={({ option }) => {
                                        onSinistreChange('reloue', option);
                                    }}
                                />
                            </Box>
                        </LineTextContainer>

                        {values.reloue === 'Oui' &&
                            <LineTextContainer>
                                <Box direction="row" align="center" gap="small">
                                    <TextLabel variant={"body1"} label={"Date de debut de relocation :"} />
                                    <CustomDateInput
                                        editMode={isEdit}
                                        value={values.date_debut_reloue}
                                        onChange={e => onSinistreChange('date_debut_reloue', e.value)}
                                    />
                                </Box>
                            </LineTextContainer>
                        }

                        {values.reloue === 'Oui' &&
                            <LineTextContainer>
                                <Box direction="row" align="center" gap="small">
                                    <TextLabel variant={"body1"} label={"Date de fin de relocation :"} />
                                    <CustomDateInput
                                        editMode={isEdit}
                                        value={values.date_fin_reloue}
                                        onChange={e => onSinistreChange('date_fin_reloue', e.value)}
                                    />
                                </Box>
                            </LineTextContainer>
                        }
                        {values.reloue === 'Oui' &&
                            <LineTextContainer>
                                <Box direction="row" align="center" gap="small">
                                    <TextLabel variant={"body1"} label={"Nb de jour relouée de la periode :"} />
                                    <CustomTextInput
                                        value={getNbDatesReloue(values)}
                                    />
                                </Box>
                            </LineTextContainer>
                        }
                        {values.reloue === 'Oui' &&
                            <LineTextContainer>
                                <Box direction="row" align="center" gap="small">
                                    <TextLabel variant={"body1"} label={"Montant de la relocation :"} />
                                    <CustomTextInput
                                        value={values.montant_sejour_reloue}
                                        onChange={e => onSinistreChange('montant_sejour_reloue', e.target.value)}
                                        editMode={isEdit}
                                    />
                                </Box>
                            </LineTextContainer>
                        }
                        {values.reloue === 'Oui' &&
                            <LineTextContainer>
                                <Box direction="row" align="center" gap="small">
                                    <TextLabel variant={"body1"} label={"Montant maximum de RBT:"} />
                                    <CustomTextInput
                                        value={getMaximumRemboursement(values)}
                                    />
                                </Box>
                            </LineTextContainer>
                        }
                        {values.reloue === 'Oui' &&
                            <LineTextContainer>
                                <Box direction="row" align="center" gap="small">
                                    <TextLabel variant={"body1"} label={"Prise en charge :"} />
                                    <CustomTextInput
                                        value={getMonttantPriseEnCharge(values)}
                                    />
                                </Box>
                            </LineTextContainer>
                        }
                    </Box>
                    <Box pad={{ top: '0.5rem' }}>
                        <TitleContainer>
                            <TextLabel variant={"title1"} label={"Information indemnisation"} color={'white'} />
                        </TitleContainer>
                    </Box>
                    <Box pad={{ vertical: '0.5rem' }}>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1"} label={"Montant sinistre (Provision) :"} />
                                <CustomTextInput
                                    value={toCurrency(values.montant_sejour)}
                                />
                            </Box>
                        </LineTextContainer>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1"} label={"Franchise :"} />
                                <CustomTextInput
                                    value={getFranchiseDisplay(values) + "%"}
                                />
                            </Box>
                        </LineTextContainer>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1"} label={"Montant franchise :"} />
                                <CustomTextInput
                                    value={toCurrency(values.montant_sejour * getFranchiseValue(values))}
                                />
                            </Box>
                        </LineTextContainer>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1"} label={"Montant éligible au RBT :"} />
                                <CustomTextInput
                                    value={toCurrency(getEligibleRBT(values))}
                                />
                            </Box>
                        </LineTextContainer>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1"} label={"Nombre de personne qui annule :"} />
                                <CustomTextInput
                                    value={values.nombre_participant_annule}
                                />
                            </Box>
                        </LineTextContainer>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1"} label={"Locataire :"} />
                                <CustomTextInput
                                    value={toCurrency(
                                        getLocataireRembourseValue(values) *
                                        parseInt(values.nombre_participant_annule || 0) /
                                        parseInt(values.nombre_participant || 1)
                                    )}
                                />
                            </Box>
                        </LineTextContainer>
                        <LineTextContainer>
                            <Box direction="row" align="center" gap="small">
                                <TextLabel variant={"body1"} label={"Propriétaire ou Agence :"} />
                                <CustomTextInput
                                    value={
                                        getPropOuAgenceRembourseType(values) ?
                                            toCurrency(getPropOuAgenceRembourseValue(values)) :
                                            '-'
                                    }
                                />
                            </Box>
                        </LineTextContainer>
                    </Box>
                </Box>
            </Box>
        </CardContainer >
    )
}

import { Box } from 'grommet';
import React from 'react';
import { getGestionCollabs } from '../../../services/dashboard';
import CommissionReport from '../../dashboard/commission_report';


class MesCommissions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            commerciaux: []
        }
    }

    componentDidMount() {
        getGestionCollabs()
            .then(res => this.setState({ commerciaux: res.data }));
    }

    render() {
        return (

            <Box style={{ width: "100%", paddingTop: 20 }}
                align="center" justify="center"
                alignSelf="center"
                alignContent="center" gap="medium">
                <CommissionReport
                    commerciaux={this.state.commerciaux}
                />

            </Box>
        )
    }
}

export default MesCommissions;
import { Box, Button, Layer, Pagination, Tab, Table, TableBody, TableCell, TableHeader, TableRow, Tabs } from "grommet";
import { Close, Download, Search } from "grommet-icons";
import { Text } from "grommet/components/Text";
import moment from "moment";
import React, { useState } from "react";
import service, { getCommissionTauxConfig, getGestionCollabs } from "../../services/dashboard";
import {
  CONTRAT_CLOTURE
} from "../../services/devis_contrat_status";
import { hasClassiqueManagerRole, isCommercialClassique } from "../../services/storage_service";
import { getMonthOptions, getSimpleDateFR, getSimpleDateTimeFR } from "../../services/utils";
import { ContractSimpliedDetails } from "../elements/contract_details";
import CustomInput from "../elements/custom_input";
import PrimaryButton from "../elements/primary_button";
import CustomSelect from "../elements/select_fill";
import StandardText from "../elements/standard_text";
import { products } from "../outils/client_util";
import { getPopupContainer } from "./status_report";

const toLine = (devis, commerciaux) => {
  let _client = devis.client || devis.entreprise || {};
  let line = `${devis.source};
  ${devis.apporteur ? (`${devis.apporteur.prenom} ${devis.apporteur.nom}`) : (devis.provider_name || devis.agence_name)};
  ${commerciaux?.find(d => d.id === devis.commercial_id)?.name};
  ${getSimpleDateFR(devis.createdAt)};
  ${getSimpleDateFR(devis.updatedAt)};
  ${getSimpleDateTimeFR(devis.date_bpa)};
  ${getSimpleDateTimeFR(devis.date_effet || devis.begin_date || devis.effectiveDate)};
  ${getSimpleDateTimeFR(devis.date_cloture)};
  ${(_client.raisonSocial || _client.raison_social) ? (_client.raisonSocial || _client.raison_social) : `${_client.lastname} ${_client.firstname}`};
  ${devis.product};
  ${devis.compagnie || (devis.devis || devis).assureur};
  ${devis.numero_contrat || devis.contractNumber};`

  return line.replace(/\n/g, '').replace(/undefined/g, '');
}

const containQuery = (devis, query) => {

  let found = false;

  for (let k of Object.keys(devis)) {
    if (!devis[k]) continue;
    if (typeof devis[k] === 'string') {
      found = devis[k].includes(query);
    }
    if (typeof devis[k] === 'object' && (k === 'client' || k === 'entreprise')) {
      found = containQuery(devis[k], query);
    }
    if (found) return true;
  }
}
class ReportsClosed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      futureAppts: [],
      filter: {
        startDate: moment(new Date())
          .subtract(11, "year")
          .startOf("month")
          .toDate(),
        endDate: new Date(),
        product: undefined,
        commercial_ids: props.commerciaux && !props.personal ? "All" : undefined,
        query: '',
      },
      contratClosedList: {
        label: CONTRAT_CLOTURE,
        reports: null,
      },
      commerciaux: [],
      commission_taux: {}
    };
  }

  componentDidMount() {
    getGestionCollabs()
      .then(res => {
        const onlyCommerciaux = res.data.filter(c => isCommercialClassique(c) || hasClassiqueManagerRole(c))
        this.setState({ commerciaux: onlyCommerciaux});
        this.loadData();
      });
    getCommissionTauxConfig()
      .then(res => {
        this.setState({ commission_taux: res.data });
      })
  }

  loadData = async () => {
    console.log('loadData:', Date());
    service
      .getReports(CONTRAT_CLOTURE, this.state.filter).then((res) => {

        let _allData = res.data;
        if (this.state.filter.query) {
          _allData = _allData.filter(d => containQuery(d, this.state.filter.query));
        }
        this.setState({
          contratClosedList: { label: CONTRAT_CLOTURE, reports: _allData },
        })

      });

  };

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    if (value === "Tous") filter[field] = "";
    this.setState({ filter: Object.assign({}, filter) }, () => this.loadData());
  };


  export = () => {
    this.setState({ loading: true });
    let headers = [
      { label: "Source", prop: "" },
      { label: "Apporteur", prop: "" },
      { label: "Commercial", prop: "" },
      { label: "Création", prop: "" },
      { label: "Modification", prop: "" },
      { label: "BPA", prop: "" },
      { label: "Date d'effet", prop: "" },
      { label: "Clôturé", prop: "" },
      { label: "Assuré", prop: "" },
      { label: "Produit", prop: "" },
      { label: "Compagnie", prop: "" },
      { label: "N° Contrat", prop: "" },
    ]
    let csvContent = headers.map(s => s.label).join(';') + '\n' +
      this.state.contratClosedList.reports
        .map(ct => toLine(ct, this.props.commerciaux))
        .join("\n")

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    var blob = new Blob(["\uFEFF" + csvContent], { encoding: "UTF-8", type: "text/csv;charset=UTF-8" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `Contrat_${getSimpleDateFR(new Date()).replace(/\//g, '-')}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);

    this.setState({ loading: false });
    this.props.showMessage('Exporté !', 'success');
  }

  render() {
    return (
      <Box
        style={{ width: "100%" }}
        margin="medium"
        pad="medium"
        gap="medium"

      >
        <Box
          width="full"
          justify="around"
          margin="medium"
          direction="row"
        >
          <Box direction="row">
            <CustomInput size="small" placeholder={'Recherche'} value={this.state.filter.query}
              onChange={event => {
                let filter = this.state.filter;
                filter.query = event.target.value;
                this.setState({ filter: Object.assign({}, filter) });
              }}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.loadData();
                }
              }}

            />
            <Button icon={<Search />} onClick={this.loadData} />
          </Box>
          <CustomSelect
            placeholder={'Tous les mois'}
            options={getMonthOptions().filter(
              (s) => s.name !== "12 derniers mois"
            )}
            labelKey={"name"}
            valueKey={{ key: "name", reduce: true }}
            onChange={({ option }) => {
              let filter = this.state.filter;
              filter.startDate = option.startDate;
              filter.endDate = option.endDate;
              filter.sort = "Produit";
              this.setState(
                {
                  filter: Object.assign({}, filter),
                  selectedMonth: option.name,
                },
                () => this.loadData()
              );
            }}
          />
          <CustomSelect
            placeholder={'Tous les produits'}
            options={products}
            onChange={({ option }) => this.updateFilter("product", option)}
          />
          {this.state.commerciaux && !this.props.personal && (
            <CustomSelect
              placeholder={'Tous les commerciaux'}
              options={this.state.commerciaux}
              onChange={({ option }) =>
                this.updateFilter("commercial_ids",[option.id])
              }
              labelKey={"name"}
              valueKey={{ key: "name", reduce: true }}
            />
          )}

          <PrimaryButton icon={<Download />} reverse
            onClick={this.export} label={<Text size="xsmall">Exporter</Text>}
          />
        </Box>

        <Box gap="medium" width={'full'}>
          <ClosedStatusReport showMessage={this.props.showMessage}
            reports={this.state.contratClosedList.reports}
            reloadAll={this.loadData}
            commerciaux={this.state.commerciaux}
            commission_taux={this.state.commission_taux}
          />
        </Box>
      </Box>
    );
  }
}

const ClosedStatusReport = ({ reports = [], commerciaux, reloadAll, showMessage, commission_taux }) => {
  const pageSize = 20;
  const [open, setOpen] = React.useState(false);
  const [devis, setDevis] = React.useState();
  const [indices, setIndices] = useState([0, pageSize]);
  const [page, setPage] = useState(1);
  return (
    <Box width='full' justify="center" align="center">
      {open && (
        <Layer
          full='vertical'
          onEsc={() => setOpen(false)} style={{ width: '90%' }}
        >
          <Box fill>
            <Box flex overflow={'auto'}>
              <Tabs>
                <Tab>
                  {getPopupContainer(devis, setOpen, reloadAll, reloadAll, showMessage)}
                </Tab>
              </Tabs>

            </Box>
          </Box>
          <Button
            plain={true}
            icon={<Close />}
            onClick={() => setOpen(false)}
            style={{ position: "absolute", top: 5, right: 5 }}
          />
        </Layer>
      )}
      <Box width={'full'} justify="center" align="center">
        <Box align="center" direction="row" justify="between" width={'full'} style={{minHeight: 40}} >
          <StandardText size={'small'}
            label={`Afficher ${indices[0] + 1 < (reports || []).length ? indices[0] + 1 : 0} - ${indices[1] < (reports || []).length ? indices[1] : (reports || []).length} de ${(reports || []).length}`}
          />
          <Pagination numberItems={(reports || []).length}
            size='small'
            step={pageSize}
            onChange={({ startIndex, endIndex }) => {
              console.log(startIndex);
              setPage((startIndex / pageSize) + 1);
              setIndices([startIndex, Math.min(endIndex, reports.length)]);
            }}
          />
        </Box>
        <Box
          style={{
            width: "100%",
            marginTop: 0,
            marginBottom: 0,
          }}
          background={"#fff"}
          fill="horizontal"
        >
          <Table
            fill="horizontal"
            style={{
              width: "100%",
            }}
          >
            <TableHeader>
              <TableRow>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    Source
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    Apporteur
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    Commercial
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    Création
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    Modification
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    BPA
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    Date d'effet
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    Date ettente banque
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    Clôture
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    Assuré
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    Produit
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    Fournisseur 
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    Compagnie
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                    N° Contrat
                  </Text>
                </TableCell>
                <TableCell scope="col" width={"80px"}>
                  <Text size="small" weight="bold">
                  </Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {(reports || [])
                .slice((page - 1) * pageSize, page * pageSize)
                .map((devis, index) => {
                  // console.log("devis = ", devis);
                  let _client = devis.client || devis.entreprise || {};
                  let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                  return (
                    <TableRow
                      key={index + 1}
                      style={{ backgroundColor: _bgColor, cursor: 'pointer'}}
                      onClick={() => {
                        setDevis(devis);
                        setOpen(true);
                      }}
                    >
                      <TableCell width={"80px"}>
                        <Text size="small">{devis.source} </Text>
                      </TableCell>
                      <TableCell width={"80px"}>
                        <Text size="small">{devis.apporteur ? (`${devis.apporteur.prenom} ${devis.apporteur.nom}`) : (devis.provider_name || devis.agence_name)}</Text>
                      </TableCell>
                      <TableCell width={"80px"}>
                        <Text size="small">{commerciaux?.find(d => d.id === devis.commercial_id)?.name}</Text>
                      </TableCell>
                      <TableCell width={"80px"}>
                        <Text size="small">
                          {getSimpleDateFR(devis.createdAt)}
                        </Text>
                      </TableCell>
                      <TableCell width={"80px"}>
                        <Text size="small">
                          {getSimpleDateFR(devis.updatedAt)}
                        </Text>
                      </TableCell>
                      <TableCell width={"80px"}>
                        <Text size="small">
                          {getSimpleDateTimeFR(devis.date_bpa)}
                        </Text>
                      </TableCell>
                      <TableCell width={"80px"}>
                        <Text size="small">
                          {getSimpleDateTimeFR(
                            devis.date_effet ||
                            devis.begin_date ||
                            devis.effectiveDate
                          )}
                        </Text>
                      </TableCell>
                      <TableCell width={"80px"}>
                        <Text size="small">
                          {getSimpleDateFR(devis.date_attente_banque)}
                        </Text>
                      </TableCell>
                      <TableCell width={"80px"}>
                        <Text size="small">
                          {getSimpleDateTimeFR(devis.date_cloture)}
                        </Text>
                      </TableCell>
                      <TableCell width={"80px"}>
                        <Text size="small">
                          {(_client.raisonSocial || _client.raison_social)
                            ? (_client.raisonSocial || _client.raison_social)
                            : `${_client.lastname} ${_client.firstname}`}
                        </Text>
                      </TableCell>
                      <TableCell width={"80px"}>
                        <Text size="small">{devis.product} </Text>
                      </TableCell>
                      <TableCell>
                          <Text size="small">{devis.fournisseur}</Text>
                      </TableCell>
                      <TableCell width={"80px"}>
                        <Text size="small">
                          {devis.compagnie || (devis.devis || devis).assureur}
                        </Text>
                      </TableCell>
                      <TableCell width={"80px"}>
                        <Text size="small">
                          {devis.numero_contrat || devis.contractNumber}
                        </Text>
                      </TableCell>
                      <TableCell >
                        <ContractSimpliedDetails
                          devis={devis}
                          commission_taux={commission_taux}
                          key={devis.id}
                          display={{
                            frais: '#E52764',
                            accompt: '#FC0000',
                            com: '#33BC00',
                            total_trustiway: '#73AAAA'
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box align="center" direction="row" justify="between" width={'full'}>
        <StandardText size={'small'} 
          label={`Afficher ${indices[0] + 1 < (reports || []).length ? indices[0] + 1 : 0} - ${indices[1] < (reports || []).length ? indices[1] : (reports || []).length} de ${(reports || []).length}`}
        />
        <Pagination numberItems={(reports || []).length}
          size='small'
          step={pageSize}
          onChange={({ startIndex, endIndex }) => {
            console.log(startIndex);
            setPage((startIndex / pageSize) + 1);
            setIndices([startIndex, Math.min(endIndex, reports.length)]);
          }}
        />
      </Box>
    </Box>
  );
};

export default ReportsClosed;

export {
  ClosedStatusReport
};


import { Box, Card, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import React from 'react';
import { getSimpleDateFR, getMonthOptions, getSimpleDateTimeFR } from '../../../../services/utils';
import CustomSelect from '../../../elements/select_fill';
import adminService from '../../../../services/admin_service';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip, 
    Legend,
    PointElement,
    LineElement,
    LineController
} from 'chart.js';
import PanelAdminHorsSystem from './panel_appel_hors_system';
import { getUserId, hasAdministrativeRole, hasClassiqueManagerRole } from '../../../../services/storage_service';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    PointElement,
    LineController,
    Title,
    Tooltip,
    Legend
);

class AdminAppelReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            appels: {
                totalAppels: undefined,
                reports: []
            },
            graphData: {},
            filter: {
                commercial_id: hasAdministrativeRole() ? 'All' : getUserId(),
                startDate: moment().startOf('month').toISOString(),
                endDate: moment().endOf('month').toISOString()
            },
            selectedMonth: 'Mois en cours',
            originalAppels: {
                totalAppels: undefined,
                reports: []
            },
            originalFilter: {
                commercial_id: hasAdministrativeRole() ? 'All' : getUserId(),
                startDate: moment().startOf('month').toISOString(),
                endDate: moment().endOf('month').toISOString()
            },
            objectifGlobal: 0,
            objectifCommercial: 0,
            optionChart: {
                responsive: true,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                },
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    datalabels: {
                        type: 'bar',
                        color: '#707070',
                        anchor: 'end',
                        align: 'end',
                        offset: 0,
                        font: {
                            weight: 'bold',
                        }
                    }
                }
            }
        }
    }

    getCommercialName = (commercial_id) => {
        let commerciaux = this.props.commerciaux;
        if (commerciaux) {
            let commercial = commerciaux.find(c => c.id === commercial_id);
            return commercial?.name;
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        adminService.getAdminAppels(this.state.filter)
            .then(res => {
                const _appels = res.data.reports;
                const _objectifTotal = res.data.defined_objectifs?.objectif;

                const startDate = moment(new Date(this.state.filter.startDate)).add(1, 'days');
                const numDaysInMonth = startDate.daysInMonth();
                let labels = [];
                for (let i = 1; i <= numDaysInMonth; i++) {
                    labels.push(i);
                }

                let dataAppelsByDate = [];
                let dataAppelsByDateHorsSys = [];
                for (let k = 0; k < labels.length; k++) {
                    const la = labels[k];
                    for (let j = 0; j < _appels.length; j++) {
                        const e = _appels[j];
                        const bg = moment(new Date(e.begin)).format('D');
                        if (bg === `${la}` && e.hors_system !== true) {
                            if (dataAppelsByDate[la - 1]) {
                                dataAppelsByDate[la - 1] = dataAppelsByDate[la - 1] + 1;
                            } else {
                                dataAppelsByDate[la - 1] = 1;
                            }
                        }
                        if (bg === `${la}` && e.hors_system === true) {
                            if (dataAppelsByDateHorsSys[la - 1]) {
                                dataAppelsByDateHorsSys[la - 1] = dataAppelsByDateHorsSys[la - 1] + 1;
                            } else {
                                dataAppelsByDateHorsSys[la - 1] = 1;
                            }
                        }
                    }
                }

                // get max from dataAppelsByDate
                var _max = 0;
                for (const dt of dataAppelsByDate) {
                    if (dt > _max) {
                        _max = dt;
                    }
                }
                var _max2 = 0;
                for (const dt of dataAppelsByDateHorsSys) {
                    if (dt > _max2) {
                        _max2 = dt;
                    }
                }
                
                _max = (_max + _max2) < _objectifTotal ? _objectifTotal : (_max + _max2);
                console.log('_max ', _max);
                this.setState({
                    appels: res.data, 
                    objectif: _objectifTotal,
                    graphData: {
                        labels,
                        dataAppelsByDate,
                        dataAppelsByDateHorsSys,
                    },
                    optionChart: {
                        responsive: true,
                        scales: {
                            x: {
                                stacked: true,
                            },
                            y: {
                                stacked: true,
                                max: _max + 3,
                            },
                        },
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            datalabels: {
                                type: 'bar',
                                color: '#707070',
                                anchor: 'end',
                                align: 'end',
                                offset: 0,
                                font: {
                                    weight: 'bold',
                                }
                            }
                        }
                    }, originalAppels: Object.assign({}, res.data)
                });
            });
    }

    updateFilter = (field, value) => {
        if (field === 'commercial_id' && value === 'All') {
            this.setState({ objectifCommercial: 0 });
        }
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter: Object.assign({}, filter) }, () => this.loadData())
    }


    render() {
        return (
            <Box width="xlarge">
                <Box justify="center" alignSelf='center'>

                    <Card width='xlarge' justify='around' margin='medium' pad='small' direction='row'>
                        <Box gap='xsmall'>
                            <Text>Selection d'un mois</Text>
                            <CustomSelect
                                options={getMonthOptions().filter(s => s.name !== '12 derniers mois')}
                                defaultValue={getMonthOptions()[0].name}
                                labelKey={"name"}
                                valueKey={{ key: "name", reduce: true }}
                                onChange={({ option }) => {
                                    let filter = this.state.originalFilter;
                                    filter.startDate = moment(new Date(option.startDate)).toISOString();
                                    filter.endDate = moment(new Date(option.endDate)).toISOString();
                                    filter.sort = option.name === '12 derniers mois' ? 'Mois' : 'Produit';
                                    this.setState({ filter: Object.assign({}, filter), selectedMonth: option.name }, () => this.loadData());
                                }}
                            />
                        </Box>

                        {(hasAdministrativeRole() || hasClassiqueManagerRole()) && this.props.commerciaux &&
                            <Box gap='xsmall'>
                                <Text>Commercial</Text>
                                <CustomSelect
                                    key={3}
                                    options={this.props.commerciaux}
                                    onChange={({ option }) => this.updateFilter('commercial_id', option.id)}
                                    labelKey={'name'}
                                    valueKey={{ key: 'name', reduce: true }}
                                />
                            </Box>
                        }
                    </Card>
                </Box>
                <Box direction='row' justify='center' alignSelf='center'>

                    <Box justify="center" alignSelf='center' margin="xsmall" gap='medium'>
                        <Card pad="small" >
                            <Text textAlign="center">Résultat objectif<br />{this.state.selectedMonth}</Text>
                            <Text textAlign="center" size="large" style={{ fontWeight: 'bold', color: '#E82C70' }}>{this.state.appels.totalAppels}</Text>
                        </Card>

                        <PanelAdminHorsSystem filter={this.state.filter} onRefresh={this.loadData} />
                    </Box>
                    {this.state.appels &&
                        <Box width="large" justify="center" align="center" alignSelf="center" margin="medium">
                            <Bar
                                options={this.state.optionChart}
                                data={getGraphData(this.state.graphData, this.state.objectif)}
                                plugins={[ChartDataLabels]}
                            />
                        </Box>
                    }
                </Box>
                <Box gap='small' pad='small'>
                    <Text style={{ fontWeight: 'bold' }} size='large' textAlign="center">Les Appels</Text>

                    <Box pad='small' style={{ overflowY: 'scroll', width: '100%', maxHeight: 580, marginBottom: 20 }}>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableCell><Text><strong>Création</strong>/<br />Modification</Text></TableCell>
                                    <TableCell><Text>Assuré</Text></TableCell>
                                    <TableCell><Text>Contrat</Text></TableCell>
                                    <TableCell><Text>Commercial</Text></TableCell>
                                    <TableCell><Text>Hors Système</Text></TableCell>
                                    <TableCell><Text>Date</Text></TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {
                                    this.state.appels.reports.map((devis, index) => (
                                        <TableRow key={index + 1}>
                                            <TableCell>
                                                <Text size="small"><strong>{getSimpleDateFR(devis.createdAt)}</strong><br />{getSimpleDateFR(devis.updatedAt)}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text>
                                                    {(devis.client || devis).raisonSocial ?
                                                        (devis.client || devis).raisonSocial :
                                                        `${(devis.client || devis).lastname || ''} ${(devis.client || devis).firstname || ''}`}

                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text> {devis.contract_name}  </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text> {`${devis.commercial ? devis.commercial.prenom : ''} ${devis.commercial ? devis.commercial.nom : ''}`}  </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text> {devis.hors_system ? 'Oui' : 'Non'}  </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text> {getSimpleDateTimeFR(devis.begin)}  </Text>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            </Box>
        )
    }
}


const getGraphData = (graphData, objectif) => {

    const backgroundColors = [
        "#33BC00", "#E52764"
    ]
    let datasets = ['Appel Prospect en cours', 'Appel prospect'].map((label, index) => ({
        label,
        type: 'bar',
        backgroundColor: backgroundColors[index],
        data: index > 0 ? graphData.dataAppelsByDateHorsSys : graphData.dataAppelsByDate
    }));

    if (objectif > 0 && graphData.labels) {
        datasets.push({
            type: 'line',
            label: 'Objectif par jour',
            borderColor: '#00000',
            borderWidth: 2,
            backgroundColor: '#00000',
            fill: true,
            data: graphData.labels.map(() => objectif),
        });
    }
    return {
        labels: graphData.labels, datasets
    }
}

export default AdminAppelReport;
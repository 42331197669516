import { Box, Button, Heading, Layer, Text } from 'grommet';
import { Add, Checkmark, Close, Save, Search, SubtractCircle } from 'grommet-icons';
import moment from 'moment';
import React, { useState } from 'react';
import CustomSelect from '../../../elements/select_fill';
import { PRODUCT_NAMES } from '../../utils/types';

const initFilters = (filter, field) => {
    let existingFilters = [];
    Object.keys(filter).forEach(key => {
        if(key.includes(field)) existingFilters = filter[key];
    })
    return existingFilters;
}

const MultipleFilters = ({ filter, onFilterApply, onClose }) => {

    const [mode_de_payments, setModeDePayments] = useState(initFilters(filter, 'mode_de_payment'));
    const [produits, setProduits] = useState(initFilters(filter, 'produit'));
    const [status_payments, setStatutPayments] = useState(initFilters(filter, 'status_payment'));

    return (
        <Box justify='center' align='center' style={{ width: 700 }} pad='small' gap='xsmall' overflow={'auto'}>

            <Box direction='row' align='center' width='large' gap='small'>
                <Box style={{ width: '40%' }}>
                    <Text size='xsmall'>{'Moyen de paiement'}</Text>
                </Box>
                <Box style={{ width: '60%' }}>
                    <CustomSelect
                        size={'xsmall'}
                        multiple={true}
                        options={[
                            "Souscription en direct",
                            "API",
                            "Chèque",
                            "Carte de crédit"
                        ]}
                        value={mode_de_payments}
                        onChange={({ value: nextValue }) => setModeDePayments(nextValue)}
                    />
                </Box>
            </Box>
            <Box direction='row' align='center' width='large' gap='small'>
                <Box style={{ width: '40%' }}>
                    <Text size='xsmall'>{'Etat'}</Text>
                </Box>
                <Box style={{ width: '60%' }}>
                    <CustomSelect
                        size={'xsmall'}
                        multiple={true}
                        options={[
                            "Proposition envoyé",
                            "En attente de paiement",
                            "En facturation",
                            "Payé",
                            "Remboursement prime",
                        ]}
                        value={status_payments}
                        onChange={({ value: nextValue }) => setStatutPayments(nextValue)}
                    />
                </Box>
            </Box>
            <Box direction='row' align='center' width='large' gap='small'>
                <Box style={{ width: '40%' }}>
                    <Text size='xsmall'>{'Produits'}</Text>
                </Box>
                <Box style={{ width: '60%' }}>
                    <CustomSelect
                        multiple={true}
                        size={'xsmall'}
                        options={Object.keys(PRODUCT_NAMES).map(k => PRODUCT_NAMES[k])}
                        value={produits}
                        onChange={({ value: nextValue }) => setProduits(nextValue)}
                    />
                </Box>
            </Box>


            <Box direction='row' gap='medium'>
                <Button primary
                    icon={<Search />}
                    style={{ background: '#59A4F4' }}
                    onClick={() => {
                        let result = {...filter};
                        if(produits.length > 0) {
                            result['_where[0][produit_in]'] = produits;
                        }
                        if(mode_de_payments.length > 0) {
                            result['_where[1][mode_de_payment_in]'] = mode_de_payments;
                        }
                        if(status_payments.length > 0) {
                            result['_where[2][status_payment_in]'] = status_payments;
                        }
                        onFilterApply(result);
                        onClose();
                    }}
                />
            </Box>
        </Box>
    )
}

export default MultipleFilters;
import { Box, Button, Card, Heading, Layer, Text, TextInput } from "grommet";
import {
  Add,
  AddCircle,
  Checkmark,
  Close,
  Currency,
  Edit,
  Previous,
  Send,
  Trash,
} from "grommet-icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getUserId, saveContextBot } from "../../../../services/storage_service";
import {
  getSimpleDateFR,
  getSimpleDateTimeFR,
  toCurrency,
} from "../../../../services/utils";
import Line from "../../../elements/line_container";
import LineModification from "../../../elements/line_container/line_modification";
import PopupConfirmation from "../../../elements/popup_confirmation";
import PrimaryButton from "../../../elements/primary_button";
import ToggleButton from "../../../elements/toggle_button/toggle_button";
import SectionDivider from "../../components/session_devider";
import {
  bulkDelete,
  createClientIfNotExist,
  sendAttestation,
  updateSubscription,
} from "../service/index";
import History from "./history";
import SinistreV2 from "../../sinistres/sinistre";
import PopupNewSinistre from "../../sinistres/sinistre-popup";
import { createSinistre } from "../../sinistres/service";

const convertToEnDate = (date = "") => {
  let splits = date.split("/");
  if (splits.length !== 3) return date;
  return `${splits[2]}-${splits[1]}-${splits[0]}`;
};

const Subscription = ({ subscription, onClose, onReload, showMessage, hideAction = false }) => {
  const [sub, setSub] = useState(subscription);
  const [editMode, setEditMode] = useState(false);
  const [beneficiaires, setBeneficiaires] = useState(
    subscription.liste_beneficiaires?.split(", ") || []
  );

  const [openSinistre, setOpensinistre] = useState(false);
  const [sinistre, setSinistre] = useState(null);

  const onModify = (field, value) => {
    sub[field] = value;
    setSub(Object.assign({}, sub));
  };

  const validateChange = () => {
    sub.liste_beneficiaires = beneficiaires.filter((s) => s).join(", ");
    if (sub.owner) {
      sub.owner = sub.owner.id;
    }
    sub.date_de_souscription = convertToEnDate(sub.date_de_souscription);
    sub.date_de_debut_de_sejour = convertToEnDate(sub.date_de_debut_de_sejour);
    sub.date_de_fin_de_sejour = convertToEnDate(sub.date_de_fin_de_sejour);
    updateSubscription(sub).then((res) => {
      setEditMode(false);
      onReload(res.data);
      showMessage("Souscription mis à jour", "success");
    });
  };

  useEffect(() => {
    if (!sub.client) {
      let addressese = (sub.adresse_du_locataire || "").split(",");
      let address = addressese[0];
      let zip =
        addressese.length > 1 ? (sub.adresse_du_locataire || "")[1] : "";
      let city =
        addressese.length > 2 ? (sub.adresse_du_locataire || "")[2] : "";
      let contact = {
        fullname: `${sub.prenom_du_locataire} ${sub.nom_locataire}`,
        civilite: sub.civilite,
        firstname: sub.prenom_du_locataire,
        lastname: sub.nom_locataire,
        email: sub.mail_locataire,
        phone: sub.telephone_locataire,
        address,
        zip,
        city,
      };
      createClientIfNotExist(sub.id, contact)
        .then((res) => {
          sub.client = res.data;
          saveContextBot({
            client: res.data,
            model_type: "Souscription",
            id: sub.id,
          });
        })
        .catch((err) => console.log(err));
    } else {
      saveContextBot({
        client: sub.client,
        model_type: "Souscription",
        id: sub.id,
      });
    }
    // eslint-disable-next-line
  }, []);

  const onSaveSinistre = (values) => {
    values.commercial_id = getUserId();
    createSinistre(values)
      .then(res => {
        setSinistre(res.data);
        showMessage('Sinistre est créé avec succès', 'success');
        setOpensinistre(false);
      })
      .catch(err => {
        showMessage('Erreur inattendu', 'error');
        console.log(err);
      });

  }

  if (openSinistre) {
    return (
      <PopupNewSinistre
        onClose={() => setOpensinistre(false)}
        onSave={onSaveSinistre}
        subscription={sub}
      />
    )
  }

  if (sinistre) {
    return (
      <Box
        fill
      >
        <SinistreV2
          sinistre={sinistre}
          onReturn={() => setSinistre(null)}
          showMessage={showMessage}
          onSinistreChange={() => {}}
        />
      </Box>
    )
  }

  return (
    <Box
      width={"xlarge"}
      justify="center"
      alignContent="center"
      align="center"
      pad="medium"
      style={{ minHeight: "auto" }}
    >
      <Box width="xlarge" justify="center">
        <Box pad="small" width="xsmall">
          <PrimaryButton icon={<Previous />} onClick={onClose} />
        </Box>
      </Box>
      <Heading level={3} color="#707070">
        Souscription
      </Heading>

      {!hideAction && <Box justify="between" direction="row" style={{ width: "100%" }}>
        <Box width={"small"} />
        <PrimaryButton
          icon={<AddCircle />}
          size="small"
          label="Ouvrir un sinistre"
          onClick={() => setOpensinistre(true)}
        />
      </Box>}

      {editMode && (
        <EditMode
          sub={sub}
          onSubChange={(field, value) => onModify(field, value)}
          validateChange={() => validateChange()}
          cancel={() => {
            setSub(subscription);
            setEditMode(false);
          }}
          beneficiaires={beneficiaires}
          onBeneficiaireChange={setBeneficiaires}
        />
      )}
      {!editMode && (
        <ViewMode
          sub={subscription}
          setEditMode={setEditMode}
          showMessage={showMessage}
          onClose={onClose}
        />
      )}
    </Box>
  );
};

const EditMode = ({
  sub,
  onSubChange,
  validateChange,
  cancel,
  beneficiaires,
  onBeneficiaireChange,
}) => {
  const onChange = (index, value) => {
    beneficiaires[index] = value;
    onBeneficiaireChange(beneficiaires.map((s) => s));
  };
  const removeBeneficiaire = (idx) => {
    beneficiaires.splice(idx, 1);
    onBeneficiaireChange(beneficiaires.map((s) => s));
  };
  const addBeneficiaire = () => {
    beneficiaires.push("");
    onBeneficiaireChange(beneficiaires.map((s) => s));
  };

  const isValidEnDate = (dateSt) => {
    if (!dateSt || dateSt.split("-").length !== 3) return false;
    return moment(dateSt, "YYYY-MM-DD").isValid();
  };
  return (
    <Box
      width={"large"}
      justify="center"
      alignContent="center"
      align="center"
      pad="medium"
    >
      <SectionDivider title={"Information assuré"} />
      <Box
        justify="start"
        align="start"
        alignContent="start"
        alignSelf="start"
        pad={"small"}
        gap="xsmall"
      >
        <LineModification
          text="Civilité"
          field="civilite"
          value={sub.civilite}
          onChange={onSubChange}
        />
        <LineModification
          text="Prénom"
          field="prenom_du_locataire"
          value={sub.prenom_du_locataire}
          onChange={onSubChange}
        />
        <LineModification
          text="Nom"
          field="nom_locataire"
          value={sub.nom_locataire}
          onChange={onSubChange}
        />
        <LineModification
          text="Adresse"
          field="adresse_du_locataire"
          value={sub.adresse_du_locataire}
          onChange={onSubChange}
        />
        <LineModification
          text="Mail"
          field="mail_locataire"
          value={sub.mail_locataire}
          onChange={onSubChange}
        />
        <LineModification
          text="Tel."
          field="telephone_locataire"
          value={sub.telephone_locataire}
          onChange={onSubChange}
        />
        <Box
          direction="row"
          gap="xsmall"
          justify="start"
          align="center"
          width={"large"}
        >
          <Box style={{ width: "50%" }}>
            <Text size="xsmall" color={"#707070"}>
              Langue
            </Text>
          </Box>
          <Box style={{ width: "50%" }}>
            <ToggleButton
              groups={["EN", "FR"].map((val) => ({
                label: val,
                selected: sub.langue === val,
                onClick: () => onSubChange("langue", val),
              }))}
            />
          </Box>
        </Box>
      </Box>
      <SectionDivider title={"Location"} />
      <Box
        justify="start"
        align="start"
        alignContent="start"
        alignSelf="start"
        pad={"small"}
        gap="xsmall"
      >
        <LineModification
          text="Adresse du risque"
          field="adresse_du_risque"
          value={sub.adresse_du_risque}
          onChange={onSubChange}
        />
        <LineModification
          text="Date de souscription"
          field="date_de_souscription"
          value={
            isValidEnDate(sub.date_de_souscription)
              ? getSimpleDateFR(sub.date_de_souscription)
              : sub.date_de_souscription
          }
          onChange={onSubChange}
        />
        <LineModification
          text="Date de début de la location"
          field="date_de_debut_de_sejour"
          value={
            isValidEnDate(sub.date_de_debut_de_sejour)
              ? getSimpleDateFR(sub.date_de_debut_de_sejour)
              : sub.date_de_debut_de_sejour
          }
          onChange={onSubChange}
        />
        <LineModification
          text="Date de fin de la location"
          field="date_de_fin_de_sejour"
          value={
            isValidEnDate(sub.date_de_fin_de_sejour)
              ? getSimpleDateFR(sub.date_de_fin_de_sejour)
              : sub.date_de_fin_de_sejour
          }
          onChange={onSubChange}
        />
        <LineModification
          text="Mail du propriétaire"
          field="mail_du_proprietaire"
          value={sub.mail_du_proprietaire}
          onChange={onSubChange}
        />
      </Box>
      <SectionDivider title={"Liste de bénéficiaires"} />
      <Box
        justify="start"
        align="start"
        alignContent="start"
        alignSelf="start"
        pad={"small"}
        gap="xsmall"
      >
        {beneficiaires.map((v, idx) => (
          <Box direction="row" gap="small">
            <Box width={"medium"}>
              <TextInput
                style={{
                  background: "#EDEDED",
                  borderRadius: 10,
                  border: "none",
                }}
                size="xsmall"
                value={v}
                onChange={(event) => onChange(idx, event.target.value)}
              />
            </Box>
            <Button
              icon={<Close color="#E82C70" />}
              onClick={() => removeBeneficiaire(idx)}
            />
          </Box>
        ))}
        <Button
          icon={<Add color="#E82C70" />}
          onClick={() => addBeneficiaire()}
        />
      </Box>

      <Box
        pad={"medium"}
        width="large"
        gap="small"
        justify="center"
        align="center"
      >
        <Box direction="row" gap="small">
          <PrimaryButton
            size="small"
            icon={<Checkmark />}
            label="Validate"
            onClick={() => validateChange()}
          />
          <PrimaryButton
            background={"#6c757d"}
            size="small"
            icon={<Close />}
            label="Annuler"
            onClick={() => cancel()}
          />
        </Box>
      </Box>
    </Box>
  );
};

const ViewMode = ({ sub, setEditMode, showMessage, onClose }) => {
  const [popup, setPopup] = useState(false);

  const [rapatriement_lie, setRapatriementLie] = useState();

  const sendAttestationLocataire = () => {
    sendAttestation(sub.id, "locataire")
      .then((res) => showMessage("Mail envoyé avec succès", "success"))
      .catch((err) => showMessage("Erreur inattendu", "error"));
  };

  const sendAttestationProprietaire = () => {
    sendAttestation(sub.id, "proprietaire")
      .then((res) => showMessage("Mail envoyé avec succès", "success"))
      .catch((err) => showMessage("Erreur inattendu", "error"));
  };

  const deleteSubScription = () => {
    bulkDelete([sub.id])
      .then((res) => {
        showMessage("Souscription supprimée", "success");
        onClose();
      })
      .catch((err) => showMessage("Erreur inattendu", "error"));
  };

  const rembourserClient = () => { };

  return (
    <Box
      width={"xlarge"}
      justify="center"
      alignContent="center"
      align="center"
      pad="medium"
      gap="small"
    >
      {popup && (
        <PopupConfirmation
          close={() => setPopup(false)}
          message="Voulez-vous supprimer cette souscription ?"
          primaryAction={() => deleteSubScription()}
          primaryActionText="Oui"
          secondaryAction={() => setPopup(false)}
          secondaryActionText="Non"
        />
      )}
      <Box width="large" gap="small">
        <Card style={{ width: "100%" }} pad="small" gap="small">
          <Line
            leftLabel="ID"
            rightChildren={<Text size="small">{sub?.id}</Text>}
          />
        </Card>
      </Box>
      <Box width="large" justify="center" gap="small" direction="row">
        <Box gap="small" style={{ width: "50%" }}>
          <SectionDivider title={"Informations assuré"} />
          <Box pad={"xsmall"}>
            <LineDisplay field={"Civilité"} value={sub.civilite} />
            <LineDisplay
              field={"Prénom & nom"}
              value={`${sub.prenom_du_locataire} ${sub.nom_locataire}`}
            />
            <LineDisplay field={"Adresse"} value={sub.adresse_du_locataire} />
            <LineDisplay field={"Mail"} value={sub.mail_locataire} />
            <LineDisplay field={"Tel."} value={sub.telephone_locataire} />
          </Box>
        </Box>
        <Box gap="small" style={{ width: "50%" }}>
          <SectionDivider title={"Agence"} />
          <Box pad={"xsmall"}>
            <Text size="xsmall" color={"#707070"}>
              {sub.owner?.raisonSocial || sub.agence}{" "}
            </Text>

            {sub.modifiee_par_api_au && (
              <LineDisplay
                field={"Modifiée par l'api le"}
                value={getSimpleDateTimeFR(sub.modifiee_par_api_au)}
              />
            )}
          </Box>
        </Box>
      </Box>
      <SectionDivider title={"Location"} />
      <Box width="large" justify="center" gap="small" direction="row">
        <Box gap="small" style={{ width: "50%" }}>
          <Box pad={"xsmall"}>
            <LineDisplay
              field={"Adresse du risque"}
              value={sub.adresse_du_risque}
            />
            <LineDisplay
              field={"Date de souscription"}
              value={`${getSimpleDateFR(sub.date_de_souscription)}`}
            />
            <LineDisplay
              field={"Date de début le la location"}
              value={getSimpleDateFR(sub.date_de_debut_de_sejour)}
            />
            <LineDisplay
              field={"Date de fin de la location"}
              value={getSimpleDateFR(sub.date_de_fin_de_sejour)}
            />
            <LineDisplay
              field={"Mail du propriétaire"}
              value={sub.mail_du_proprietaire}
            />
            <LineDisplay
              field={"Montant de la location"}
              value={toCurrency(sub.prix_sejour)}
            />
            {sub.option_billets_avion && sub.billets_avion && (
              <LineDisplay
                field={"Montant de vos billets de transport"}
                value={toCurrency(sub.billets_avion)}
              />
            )}
            {sub.option_prestations_annexes && sub.prestations_annexes && (
              <LineDisplay
                field={"Montant de vos prestations annexes"}
                value={toCurrency(sub.prestations_annexes)}
              />
            )}
          </Box>
        </Box>
        <Box gap="small" style={{ width: "50%" }}>
          <Box pad={"xsmall"} gap="xsmall">
            <Text size="xsmall">Liste des bénéficiaires :</Text>
            <Box>
              {sub.liste_beneficiaires?.split(", ").map((line, idx) => (
                <Text key={idx} size="xsmall" color={"#707070"}>
                  {line}
                </Text>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <SectionDivider title={"Assureur"} />
      <Box width="large" justify="center" gap="small">
        <Box pad="xsmall">
          <LineDisplay field={"Compagnie"} value={sub.compagnie} />
          <LineDisplay field={"Type contrat"} value={sub.produit} />
          <LineDisplay field={"PolicyID"} value={sub.policyId} />
          <LineDisplay field={"N° de contrat"} value={sub.contrat_numero} />
          {sub.ref_import && (
            <LineDisplay field={"Réf. import"} value={sub.ref_import} />
          )}
          {sub.rapatriement_lie && (
            <Box
              width={"full"}
              onClick={() => setRapatriementLie(sub.rapatriement_lie)}
            >
              <LineDisplay
                field={"N° de contrat rapatriement"}
                value={sub.rapatriement_lie.contrat_numero}
              />
            </Box>
          )}
        </Box>
      </Box>
      <SectionDivider title={"Comptabilité"} />
      <Box width="large" justify="center" gap="small">
        <Box direction="row" gap="medium">
          <Box pad={"xsmall"} style={{ width: "30%" }}>
            <LineDisplay
              field={"Prime TTC"}
              value={toCurrency(sub.prime_ttc)}
            />
            {sub.prix_de_vent_agence_ttc && (
              <LineDisplay
                field={"Prime TTC agence"}
                value={toCurrency(sub.prix_de_vent_agence_ttc)}
              />
            )}
            <LineDisplay field={"Prime HT"} value={toCurrency(sub.prime_ht)} />
            <LineDisplay field={"Taxe"} value={toCurrency(sub.taxe)} />
          </Box>
          <Box pad={"xsmall"}>
            {sub.attestation ? (
              <LineDisplay
                field={"Attestation"}
                comp={
                  <a
                    target={"_blank"}
                    href={`${sub.attestation}`}
                  >{` ${sub.attestation}`}</a>
                }
              />
            ) : null}
            <LineDisplay
              field={"Moyen de paiement"}
              value={sub.mode_de_payment}
            />
            {sub.mode_de_payment === "API" ? (
              <LineDisplay
                field={"API appelé le"}
                value={getSimpleDateTimeFR(sub.createdAt)}
              />
            ) : null}
            <LineDisplay
              field={"Statut de paiement"}
              value={sub.status_payment || "Payé"}
            />
          </Box>
        </Box>
        <Box pad={"xsmall"} gap="small">
          <Box>
            <LineDisplay
              field={"Part Trustiway"}
              value={toCurrency(sub.part_trustiway)}
            />
            <LineDisplay
              field={"Commission"}
              value={toCurrency(sub.commission_annuelle)}
            />
          </Box>
          <Box>
            <LineDisplay
              field={"Part Compagnie"}
              value={toCurrency(sub.part_compagnie)}
            />
            {sub.retrocession_client ? (
              <LineDisplay
                field={"Rétrocession client"}
                value={toCurrency(sub.retrocession_client)}
              />
            ) : null}
          </Box>
          <Box>
            <LineDisplay
              field={"Total Trustiway"}
              value={toCurrency(sub.total_trustiway)}
            />
          </Box>
        </Box>
      </Box>

      {sub.histories ? (
        <Box
          pad={"small"}
          width="xlarge"
          gap="small"
          justify="center"
          align="center"
        >
          <History histories={sub.histories} />
        </Box>
      ) : null}
      <Box
        pad={"medium"}
        width="large"
        gap="small"
        justify="center"
        align="center"
      >
        <Box direction="row" gap="small">
          <PrimaryButton
            size="small"
            icon={<Send />}
            label="Envoyer l'attestation locataire"
            onClick={() => sendAttestationLocataire()}
            background="#70A4EC"
          />
          <PrimaryButton
            size="small"
            icon={<Send />}
            label="Envoyer l'attestation proprietaire"
            onClick={() => sendAttestationProprietaire()}
            background="#70A4EC"
          />
        </Box>
        <Box direction="row" gap="small">
          <PrimaryButton
            size="small"
            icon={<Edit />}
            label="Modifier"
            onClick={() => setEditMode(true)}
          />
          <PrimaryButton
            size="small"
            icon={<Trash />}
            label="Supprimer"
            onClick={() => setPopup(true)}
          />
          {sub.mode_de_payment === "Carte de crédit" && (
            <PrimaryButton
              size="small"
              icon={<Currency />}
              label="Rembourser le client"
              onClick={() => rembourserClient()}
              background="#70A4EC"
            />
          )}
        </Box>
      </Box>
      {rapatriement_lie ? (
        <Layer>
          <Box width={"large"} overflow={{ vertical: "auto" }}>
            <Subscription
              subscription={rapatriement_lie}
              onClose={() => setRapatriementLie(null)}
              onReload={() => { }}
              showMessage={() => { }}
            />
          </Box>
        </Layer>
      ) : null}
    </Box>
  );
};

const LineDisplay = ({ field, value, comp }) => (
  <Text size="xsmall" color={"#707070"}>
    {field} :{value && <b> {value} </b>}
    {comp ? comp : null}
  </Text>
);

export default Subscription;

import React, { Fragment, useEffect } from "react";
import PrimaryButton from "../../../elements/primary_button";
import { Box, Button, Card, Layer, Text } from "grommet";
import { useDisclosure } from "@chakra-ui/core";
import { Close } from "grommet-icons";
import { getPopupContainer } from "../../../dashboard/status_report";
import { getDevisInfo } from "../service/rdv_rappel";

const DevisModal = ({ devis_id, devis_name, devis_type, label, icon, callback }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedDevis, setSelectedDevis] = React.useState(null);
	const [error, setError] = React.useState(null);

	useEffect(() => {
		if (isOpen)
			loadDevis();
	}, devis_id);

	const getModelTypeByName = (_name) => {
		switch (_name) {
			case "Trusti Habitation en ligne":
				return "devis_habitation";
			case "Trusti PNO en ligne":
				return "pno_seul";
			case "Trusti Emprunteur":
				return "emprunteur_horsligne";
			case "Trusti Moto":
				return "trusti_moto";
			case "Trusti Voiture":
				return "trusti_voiture";
			case "Trusti Chien/Chat":
				return "pet";
			case "Trusti Santé":
				return "sante";
			case "Trusti PNO Gîte":
				return "pno_git";
			case "Trusti Habitation":
				return "trusti_mh";
			case "Trusti PNO":
				return "trusti_pno_horsligne";
			case "Trusti PER":
				return "trusti_per";
			case "Trusti Immeuble":
				return "trusti_immeuble";
			case "Trusti GLI":
				return "trusti-gli";
			case "Trusti Cyber Risque":
				return "pro_cr";
			case "Trusti Santé pro":
				return "pro_sante";
			case "Trusti PNO pro":
				return "pro_pno";
			case "Trusti Immeuble pro":
				return "pro_immeuble";
			case "Trusti PREV":
				return "pro_prev";
			case "Trusti RC":
				return "pro_rc";
			case "Trusti MRP":
				return "pro_mrp";
			case "Trusti Dommage Ouvrage":
				return "pro_do";
			case "Trusti MH tempo enligne":
				return "trusti_mh_tempo";
			case "Trusti Crédit":
				return "credits";
		}
	}

	const loadDevis = () => {

		getDevisInfo(devis_id, devis_type ? devis_type : getModelTypeByName(devis_name))
			.then(res => {
				setSelectedDevis(res.data);

			})
			.catch(err => {
				console.log(err);
				setError(err);
			});
	}


	return (
		<Fragment>
			<PrimaryButton icon={icon} background={"transparent"} color={"#000"} onClick={() => {
				onOpen();
				loadDevis();
			}} label={label} ></PrimaryButton>
			{isOpen &&
				<Layer full='horizontal' onEsc={onClose}  >
					<Button
						plain={true}
						icon={<Close />}
						onClick={onClose}
						style={{ position: "absolute", top: 5, right: 5 }}
					/>
					<Box key={`${isOpen}`} flex overflow={'auto'} >
						{selectedDevis && getPopupContainer(selectedDevis,
							() =>  {onClose();},
							() => { },
							() => { },
							() => { },
						)}
						{error && <Box flex="grow" style={{ width: 500, height: 200, alignItems: "center" }}>
							<Text alignSelf="center" textAlign="center">
								<b>{devis_name}</b><br />
								<i>{devis_id}</i><br />
								{'Non trouvé.'}
							</Text>
						</Box>}
					</Box>
				</Layer>}
		</Fragment>
	);
};

export default DevisModal;

import React from "react";
import { Box, Text } from "grommet";
import { toCurrency } from "../../../../services/utils";

const SubscriptionDetail = ({ sub }) => {

    calculeComptableVoyage(sub);
    return (
        <Box pad="xsmall" style={{ border: '1px solid #c8c8c8', borderRadius: 5, width: 200 }} >

            <InfoLine label={'Prime TTC'} value={toCurrency(sub.prime_ttc)} />
            {sub.prix_de_vent_agence_ttc && <InfoLine label={'Prime TTC agence'} value={toCurrency(sub.prix_de_vent_agence_ttc)} />}
            <InfoLine label={'Taxe'} value={toCurrency(sub.prime_ttc - sub.prime_ht)} />
            <InfoLine label={'Prime HT'} value={toCurrency(sub.prime_ht)} />
            <InfoLine label={'Commission'} value={toCurrency(sub.commission_annuelle)} />
            <InfoLine label={'Part Trustiway'} value={toCurrency(sub.part_trustiway)} />
            {sub.retrocession_client ? <InfoLine label={'Rétrocession client'} value={toCurrency(sub.retrocession_client)} /> : null}

            <InfoLine label={'Part Trustiway - Net'} value={toCurrency(sub.part_trustiway + sub.commission_annuelle - (sub.retrocession_client || 0))} />
            <InfoLine label={'Part Compagnie'} value={toCurrency(sub.part_compagnie)} />
            <InfoLine label={'Part Compagnie - Com'} value={toCurrency(sub.part_compagnie - sub.commission_annuelle)} />
            <InfoLine label={'Prime Hors com'} value={toCurrency(sub.prime_ttc - (sub.retrocession_client || 0))} />
            <InfoLine label={'Total Trustiway'} value={toCurrency(sub.total_trustiway)} />
        </Box>
    )
}


const InfoLine = ({ label, value, color }) => {
    let style = color ? { border: '1px solid #000', borderRadius: 5, color } : {}

    return (<Text style={style} size="xsmall" >{label} : <strong>{value}</strong></Text>)
}
const calculeComptableVoyage = sub => {
    // calcul only for sub by link
    if(sub.mode_de_payment !== 'Chèque' && sub.mode_de_payment !== 'Carte de crédit') return;
    
    if(sub.owner?.products) {
        const product = sub.owner?.products.find(p => p.product === sub.produit && p.type === '');
        if(product) {
            console.log('product: ', product)
            sub.prime_ttc = sub.prix_sejour * (product.taux_displayed || product.taux_vent || product.taux) / 100;
            sub.prime_ht = sub.prime_ttc / product.taux_de_tax;
            sub.retrocession_client =  sub.prime_ht * parseFloat(product.taux_retro_agence || 0) / 100;
            sub.taxe = sub.prime_ttc - sub.prime_ht;
            sub.part_compagnie = sub.prix_sejour * product.part_compagnie / 100 / product.taux_de_tax;
            sub.part_trustiway = sub.prime_ht - sub.part_compagnie;
            sub.commission_annuelle = sub.part_compagnie * (product.commission_compagnie / 100);
            sub.total_trustiway = sub.part_trustiway + sub.commission_annuelle - sub.retrocession_client;
        }
    }
}

export {
    calculeComptableVoyage
}

export default SubscriptionDetail;
const axios = require('axios');
const moment = require('moment');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};


const getLogs = (startIndex, query, filter = {}, sort) => {
    let params = {
        _start: startIndex,
        _limit: 50,
        _q: query
    }
    if (sort) {
        params._sort = sort;
    }
    params = buildParams(params, filter);
    return axios.get(`${baseAPI}/api-logs`, { params, headers: HEADERS });
}

const buildParams = (params, filter) => {
    let buildFilter = Object.assign({}, filter);

    if (buildFilter.createdAt) {
        buildFilter['_where[0][createdAt_lt]'] = `${moment(buildFilter.createdAt).add(1, 'day').format('YYYY-MM-DD')}T00:00:00.000Z`
        buildFilter['_where[1][createdAt_gt]]'] = `${buildFilter.createdAt}T00:00:00.000Z`;
        delete buildFilter.createdAt;
    }

    let finalFilter = { ...params, ...buildFilter, url: '/send_email_proposition/' };
    for (let key of Object.keys(finalFilter)) {
        if (!finalFilter[key]) delete finalFilter[key];
    }

    return finalFilter;
}

const countLogs = (query, filter) => {
    let params = {
        _q: query
    }
    params = buildParams(params, filter);
    return axios.get(`${baseAPI}/api-logs/count`, { params, headers: HEADERS });
}

module.exports = {
    getLogs,
    countLogs
}
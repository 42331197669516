import React from "react";
import { Box, Tab, Tabs, } from "grommet";
import ApporteurPros from "./apporteurs";
import Souscriptions from "./souscriptions";
import { getCollabs } from "../../services/admin_service";
class GestionApporteurPro extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            commerciaux: []
        }
    }

    componentDidMount = () => {
        getCollabs()
            .then(res => this.setState({ commerciaux: res.data.map(c => ({ name: `${c.prenom} ${c.nom}`, ...c })) }));
    }

    render() {
        return (
            <Box fill justify="center" align="center" style={{ paddingTop: 10 }}>
                <Tabs style={{ width: '100%' }}>
                    
                    <Tab title="Apporteurs">
                        <ApporteurPros
                            key={'app_child'}
                            showMessage={this.props.showMessage}
                            commerciaux={this.state.commerciaux}
                            mother={false}
                        />
                    </Tab>
                    <Tab title="Maison mère">
                        <ApporteurPros
                            key={'app_mother'}
                            showMessage={this.props.showMessage}
                            commerciaux={this.state.commerciaux}
                            mother={true}
                        />
                    </Tab>
                    <Tab title="Souscriptions">
                        <Souscriptions
                            showMessage={this.props.showMessage}
                            commerciaux={this.state.commerciaux}
                        />
                    </Tab>
                </Tabs>
            </Box>
        )
    }
}

export default GestionApporteurPro;
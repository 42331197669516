import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";
import { getSimpleDateFR, getSimpleTimeFR } from "../../../../services/utils";
import { ABONDONS, A_RAPPELER_PLUS_TARD, CLOTURE, DOSSIER_ENVOYE_A_LA_BANQUE, DOSSIER_EN_INSTRUCTION, EN_ETUDE_DE_ANALYSTE, EN_ETUDE_DE_PRE_ANALYSTE, FACTURE, NOUVEAU, NOUVEAU_REMPLI, OFFRE_ACCEPTE_PAR_CLIENT, PERDU, PRET_ACCORDE_PAR_LA_BANQUE, PROSPECT, REALISE } from "../../../../services/credit_status";
import BlockUi from "react-block-ui";
import { getAppelsByStatus } from "../../../../services/appointment_service";
import PrimaryButton from "../../../elements/primary_button";
import { Refresh } from "grommet-icons";



const CreditStatusDateAppel = ({ devis }) => {
  const [appels, setAppels] = useState([]);
  const [blocking, setBlocking] = useState(false);

  useEffect(() => {
    loadAppels();
  }, []);

  const loadAppels = () => {
    setBlocking(true);

    getAppelsByStatus(devis).then(res => {
      setAppels(res.data);
      setBlocking(false);
    })
      .catch(err => setBlocking(false));
  }
  return (
    <BlockUi tag="div" blocking={blocking} >
      <Box
        direction="row" gap="small" fill
      >
        <Card background={'#F7F7F7'} fill >
          <Box background={"brand"} direction="row" justify="between" alignContent="between" style={{ paddingLeft: 20 }}>

            <Text margin={"xsmall"} size="small" weight={"bold"} color={'white'} >Historique des appels</Text>
            <PrimaryButton background="transparent" icon={<Refresh color="white" />} reverse onClick={loadAppels}></PrimaryButton>

          </Box>
          <Table>
            <TableHeader >
              <TableRow >
                <TableCell align="left">
                  <Text size="small" weight="bold">
                    N°
                  </Text></TableCell>
                <TableCell fill align="left"><Box fill>
                  <Text size="small" weight="bold">
                    Statut
                  </Text></Box>
                </TableCell>
                <TableCell align="right">
                  <Text size="small" weight="bold">
                    Date
                  </Text>
                </TableCell>
                <TableCell align="right">
                  <Text size="small" weight="bold">
                    Heure
                  </Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {appels.map((a, i) => (
                <TableRow key={`_row_${i}`} >
                  <TableCell align="left">
                    <Text size="small" weight="bold" align="left">
                      {i + 1}
                    </Text>
                  </TableCell>
                  <TableCell fill align="left">
                    <Text size="small" align="left" >
                      {a.for_status}
                    </Text>
                  </TableCell>
                  <TableCell align="right">
                    <Text size="small" weight={"bold"}>
                      {getSimpleDateFR(a.begin)}
                    </Text>
                  </TableCell>
                  <TableCell align="right">
                    <Text size="small" weight={"bold"}>
                      {getSimpleTimeFR(a.begin)}
                    </Text>
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>

        </Card>
        <Card background={'#F7F7F7'} fill >
          <Box background={"brand"} style={{ paddingLeft: 20 }}>
            <Text margin={"xsmall"} size="small" weight={"bold"} color={'white'} >Historique statuts</Text>
          </Box>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell align="left">
                  <Text size="small" weight="bold">
                    Statut
                  </Text></TableCell>
                <TableCell align="left">
                  <Text size="small" weight="bold">
                    Date
                  </Text>
                </TableCell>
                <TableCell align="right">
                  <Text size="small" weight="bold">
                    Nbr Appel
                  </Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <Text size="small" weight="bold" align="left">
                    {PROSPECT}
                    <br />
                    {NOUVEAU}
                    <br />
                    {NOUVEAU_REMPLI}
                    <br />
                    {A_RAPPELER_PLUS_TARD}
                    <br />
                    {DOSSIER_EN_INSTRUCTION}
                    <br />
                    {EN_ETUDE_DE_PRE_ANALYSTE}
                    <br />
                    {EN_ETUDE_DE_ANALYSTE}
                    <br />
                    {DOSSIER_ENVOYE_A_LA_BANQUE}
                    <br />
                    {PRET_ACCORDE_PAR_LA_BANQUE}
                    <br />
                    {OFFRE_ACCEPTE_PAR_CLIENT}
                    <br />
                    {REALISE}
                    <br />
                    {FACTURE}
                    <br />
                    {CLOTURE}
                    <br />
                    {ABONDONS}
                  </Text>
                </TableCell>
                <TableCell align="left">
                  <Text size="small" align="left">
                    {getSimpleDateFR(devis.createdAt) || "-"}
                    <br />
                    {getSimpleDateFR(devis.createdAt) || "-"}
                    <br />
                    {getSimpleDateFR(devis.date_nouveau_rempli) || "-"}
                    <br />
                    {getSimpleDateFR(devis.date_a_rappeler_plus_tard) || "-"}
                    <br />
                    {getSimpleDateFR(devis.date_dossier_en_instruction) || "-"}
                    <br />
                    {getSimpleDateFR(devis.date_en_etude_de_analyste) || "-"}
                    <br />
                    {getSimpleDateFR(devis.date_dossier_envoye_par_la_banque) || "-"}
                    <br />
                    {getSimpleDateFR(devis.date_pret_accorde_par_la_banque) || "-"}
                    <br />
                    {getSimpleDateFR(devis.date_offre_accepte_par_le_client) || "-"}
                    <br />
                    {getSimpleDateFR(devis.date_realise) || "-"}
                    <br />
                    {getSimpleDateFR(devis.date_facture) || "-"}
                    <br />
                    {getSimpleDateFR(devis.date_cloture) || "-"}
                    <br />
                    {getSimpleDateFR(devis.date_abandons) || "-"}
                  </Text>
                </TableCell>
                <TableCell align="right">
                  <Text size="small" weight={"bold"}>
                    {devis.nbr_appel_prospect || 0}
                    <br />
                    {devis.nbr_appel_nouveau || 0}
                    <br />
                    {devis.nbr_appel_nouveau_rempli || 0}
                    <br />
                    {devis.nbr_a_rappeler_plus_tard || 0}
                    <br />
                    {devis.nbr_appel_dossier_en_instruction || 0}
                    <br />
                    {devis.nbr_en_etude_de_analyste || 0}
                    <br />
                    {devis.nbr_appel_dossier_envoye_a_la_banque || 0}
                    <br />
                    {devis.nbr_appel_pret_accorde_par_la_banque || 0}
                    <br />
                    {devis.nbr_appel_offre_accepte_par_client || 0}
                    <br />
                    {devis.nbr_appel_resilie || 0}
                    <br />
                    {devis.nbr_appel_facture || 0}
                    <br />
                    {devis.nbr_appel_cloture || 0}
                    <br />
                    {devis.nbr_appel_abondons || 0}
                  </Text>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Box>
    </BlockUi>
  );
}

export default CreditStatusDateAppel;

import React from "react";
import { css } from "styled-components";

import { Box, Grommet, CheckBox } from "grommet";

const checkboxCheckStyle = css`
  background-color: #2196f3;
  border-color: #2196f3;
`;

const customToggleTheme = {
  checkBox: {
    hover: {
      border: {
        color: undefined,
      },
    },
    toggle: {
      color: "#E92C70",
      knob: {
        color: "#E92C70",
        background: "#E92C70",
      },
      extend: ({ checked }) => `
          ${checked && checkboxCheckStyle}
        `,
    },
  },
};

const SimpleCheckBox = ({ checked, onChange, ...rest }) => {
  return (
    <Grommet theme={customToggleTheme}>
      <Box>
        <CheckBox {...rest} checked={checked} onChange={onChange} />
      </Box>
    </Grommet>
  );
};

const CustomToggle = ({ checked, onChange }) => (
  <SimpleCheckBox toggle checked={checked} onChange={onChange} />
);

export default CustomToggle;

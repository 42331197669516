import { Box, FileInput, TextArea } from 'grommet';
import { CaretPrevious, Save } from 'grommet-icons';
import React from "react";
import { uploadFile } from '../../../../services/storage_service';
import AddressAutoComplet from "../../../elements/address_autocomplete";
import CustomInput from '../../../elements/custom_input';
import DateInput from '../../../elements/date_input';
import PrimaryButton from '../../../elements/primary_button';
import CustomSelect from '../../../elements/select_fill';
import StandardText from "../../../elements/standard_text";
import ToggleButton from '../../../elements/toggle_button/toggle_button';
import SectionDivider from "../../../voyages/components/session_devider";

const doUploadFile = (files, callback, showError) => {
    const fileList = files;
    if (fileList) {
        for (let i = 0; i < fileList.length; i += 1) {
            const file = fileList[i];
            console.log(file.name);
            uploadFile(file)
                .then((result) => {
                    console.log("result = ", result.data);
                    callback(result.data);
                })
                .catch((err) => {
                    showError("Erreur inattendu télécharger.");
                    callback([]);
                });
        }
    }
};

const Info = ({ deal, onDealChange, showMessage, prev, save }) => {

    return (
        <Box
            style={{ width: "100%" }}
            justify="center"
            alignContent="center"
            align="center"
        >
            <Box
                width={'large'}
                justify="center"
                alignContent="center"
                align="center"
                gap="medium"
            >

                <Box >
                    <StandardText style={{ fontWeight: 'bold' }} label='Information ouvrage' />
                    <SectionDivider />
                </Box>

                <Box gap="xxsmall" align="center" width={'full'}>
                    <AddressAutoComplet
                        contact={deal}
                        updateContact={onDealChange}
                        direction='in-line'
                    />
                    <Line
                        left={<StandardText size={'small'} label={'Type chantier'} />}
                        right={
                            <CustomSelect
                                size={'small'}
                                options={[
                                    'MAISON',
                                    'OPERATION DE PROMPOTION',
                                    'AUTRE OPERATION DE CONSTRUCTION'
                                ]}
                                value={deal.type_chantier}
                                onChange={({ option }) => onDealChange('type_chantier', option)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Type d'opération à assurer"} />}
                        right={
                            <CustomSelect
                                size={'small'}
                                options={[
                                    "CONSTRUCTION NEUVE",
                                    "RÉNOVATION",
                                    "EXTENSION"
                                ]}
                                value={deal.type_operation}
                                onChange={({ option }) => onDealChange('type_operation', option)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Coût de construction de l'opération TTC"} />}
                        right={
                            <CustomInput size='xsmall' placeholder={'€'}
                                value={deal.cout_construction}
                                onChange={event => onDealChange('cout_construction', event.target.value)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Rénovation"} />}
                        right={
                            <ToggleButton
                                groups={[true, false].map(value => (
                                    {
                                        label: value ? 'Oui' : 'Non',
                                        selected: deal.renovation === value,
                                        onClick: () => onDealChange('renovation', value),
                                    }
                                ))}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Y'a t'il une extension d'un bâtiment existant"} />}
                        right={
                            <ToggleButton
                                groups={[true, false].map(value => (
                                    {
                                        label: value ? 'Oui' : 'Non',
                                        selected: deal.extension === value,
                                        onClick: () => onDealChange('extension', value),
                                    }
                                ))}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Reprise en sous oeuvre des fondations existantes"} />}
                        right={
                            <ToggleButton
                                groups={[true, false].map(value => (
                                    {
                                        label: value ? 'Oui' : 'Non',
                                        selected: deal.reprise === value,
                                        onClick: () => onDealChange('reprise', value),
                                    }
                                ))}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Y’a t’il une intervention sur les structures porteuse"} />}
                        right={
                            <ToggleButton
                                groups={[true, false].map(value => (
                                    {
                                        label: value ? 'Oui' : 'Non',
                                        selected: deal.intervention === value,
                                        onClick: () => onDealChange('intervention', value),
                                    }
                                ))}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Description de l'opération"} />}
                        right={
                            <Box style={{ width: '100%', height: 100 }}>
                                <TextArea
                                    value={deal.description_construction}
                                    fill
                                    size="xsmall"
                                    onChange={event => onDealChange('description_construction', event.target.value)}
                                />
                            </Box>
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Surface totale du bâtiment"} />}
                        right={
                            <CustomInput size='xsmall' placeholder={'m2'}
                                value={deal.surface_total}
                                onChange={event => onDealChange('surface_total', event.target.value)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={'Nombre d’étages'} />}
                        right={
                            <CustomSelect
                                size={'small'}
                                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]}
                                value={deal.nombre_etage}
                                onChange={({ option }) => onDealChange('nombre_etage', option)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Sous-sol"} />}
                        right={
                            <ToggleButton
                                groups={[true, false].map(value => (
                                    {
                                        label: value ? 'Oui' : 'Non',
                                        selected: deal.sous_sol === value,
                                        onClick: () => onDealChange('sous_sol', value),
                                    }
                                ))}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={'Destination de l’opération'} />}
                        right={
                            <CustomSelect
                                size={'small'}
                                options={[
                                    "IMMEUBLE COLLECTIF D’HABITATION",
                                    "MAISON INDIVIDUELLE",
                                    "OPÉRATION PAVILLONNAIRE",
                                    "IMMEUBLE À USAGE DE BUREAUX",
                                    "BATIMENT COMMERCIAL",
                                    "MIXTE"
                                ]}
                                value={deal.destination_operation}
                                onChange={({ option }) => onDealChange('destination_operation', option)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={'Nombre de logement concernés'} />}
                        right={
                            <CustomSelect
                                size={'small'}
                                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]}
                                value={deal.nombre_logement}
                                onChange={({ option }) => onDealChange('nombre_logement', option)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Bailleur social ou locatif"} />}
                        right={
                            <ToggleButton
                                groups={[true, false].map(value => (
                                    {
                                        label: value ? 'Oui' : 'Non',
                                        selected: deal.bailleur_social_locatif === value,
                                        onClick: () => onDealChange('bailleur_social_locatif', value),
                                    }
                                ))}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Utilisation de techniques de construction non courantes"} />}
                        right={
                            <ToggleButton
                                groups={[true, false].map(value => (
                                    {
                                        label: value ? 'Oui' : 'Non',
                                        selected: deal.utilisation_non_courante === value,
                                        onClick: () => onDealChange('utilisation_non_courante', value),
                                    }
                                ))}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Maitrise d’oeuvre"} />}
                        right={
                            <ToggleButton
                                groups={[true, false].map(value => (
                                    {
                                        label: value ? 'Oui' : 'Non',
                                        selected: deal.maitrise_oeuvre === value,
                                        onClick: () => onDealChange('maitrise_oeuvre', value),
                                    }
                                ))}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Etude de sol"} />}
                        right={
                            <ToggleButton
                                groups={[true, false].map(value => (
                                    {
                                        label: value ? 'Oui' : 'Non',
                                        selected: deal.etude_sol === value,
                                        onClick: () => onDealChange('etude_sol', value),
                                    }
                                ))}
                            />
                        }
                    />
                    {deal.etude_sol &&
                        <Line
                            left={<StandardText size={'small'} label={"Type d'etude de sol"} />}
                            right={
                                <CustomInput size='xsmall'
                                    value={deal.type_etude_sol}
                                    onChange={event => onDealChange('type_etude_sol', event.target.value)}
                                />
                            }
                        />
                    }
                    <Line
                        left={<StandardText size={'small'} label={"Début prévisionnel des travaux"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.debut_travaux}
                                onChange={(date) => onDealChange("debut_travaux", date)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Date de réception prévisionnelle"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_reception_previsionnelle}
                                onChange={(date) => onDealChange("date_reception_previsionnelle", date)}
                            />
                        }
                    />
                    <Line
                        left={<StandardText size={'small'} label={"Date de résiliation"} />}
                        right={
                            <DateInput size={'small'}
                                value={deal.date_resiliation}
                                onChange={(date) => onDealChange("date_resiliation", date)}
                            />
                        }
                    />

                    <Box pad={'small'}>
                        <FileInput
                            size={'xxsmall'}
                            messages={{
                                browse: "Choisir",
                                dropPrompt: "Ajouter un autre document",
                            }}
                            multiple={false}
                            onChange={(event) => {
                                doUploadFile(
                                    event.target.files,
                                    async (data) => {
                                        if (data.length > 0) {
                                            let _uploaded = data[0];
                                            let _files = deal.file_others || [];
                                            _files.push(_uploaded.url);
                                            onDealChange("file_others", _files);
                                        }
                                    },
                                    mes => showMessage(mes, 'error')
                                );
                            }}
                        />
                    </Box>
                </Box>

                <Box pad={'medium'} direction='row' gap="medium">
                    < PrimaryButton icon={<CaretPrevious />} label='Retour' background={'#707070'} onClick={prev} />
                    < PrimaryButton icon={<Save />} label='Enregistrer' onClick={save} />
                </Box>
            </Box>
        </Box>
    )
}

const Line = ({ left, right }) => {
    return (
        <Box direction="row" gap="large" align="center" width={'full'}>
            <Box style={{ width: '65%' }} >
                {left}
            </Box>
            <Box width={'full'}>
                {right}
            </Box>
        </Box>
    )
}

export default Info;
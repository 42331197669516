import { Box, Button, FileInput, Text } from 'grommet';
import { Add, Checkmark, Close, DocumentDownload } from 'grommet-icons';
import React from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { uploadFile } from '../../../../services/storage_service';
import { toArrayOfArray } from '../../../../services/utils';
import CustomInput from '../../../elements/custom_input';
import { SmallLine } from '../../../elements/line_container';
import PrimaryButton from '../../../elements/primary_button';
import CustomSelect from '../../../elements/select_fill';
import ToggleButton from '../../../elements/toggle_button/toggle_button';
import Interlocuteur from '../../../voyages/agences/agence/components/elements/interlocuter';
import ContactSelect, { ContactSelectV2 } from '../../contact_modal/client';

const lead_status = [
    { key: "NEW", value: "Nouveau" },
    { key: "OPEN", value: "Ouvert" },
    { key: "IN_PROGRESS", value: "En cours" },
    { key: "OPEN_DEAL", value: "Transaction ouverte" },
    { key: "UNQUALIFIED", value: "Non qualifié" },
    { key: "ATTEMPTED_TO_CONTACT", value: "Tentative de contact" },
    { key: "CONNECTED", value: "Connecté" },
    { key: "BAD_TIMING", value: "Mauvais timing" },
];

const findLeadStatusKey = (status) => {
    let lead = lead_status.find(
        (k) => k.value.toUpperCase() === (status || "").toUpperCase()
    );
    return lead ? lead.key : "";
};

const findLeadStatusValue = (status) => {
    if (!status) return null;
    let lead = lead_status.find(
        (k) => k.key.toUpperCase() === (status || "").toUpperCase()
    );
    return lead ? lead.value : "";
};

const SectionDivider = ({ title }) => {
    return (
        <Box gap="xsmall" width={"large"} pad='xsmall' border={'bottom'}>
            <Text style={{ fontWeight: 'bold' }}>{title}</Text>
        </Box>
    )
}

const doUploadFile = (files, callback, showError) => {
    const fileList = files;
    if (fileList) {
        for (let i = 0; i < fileList.length; i += 1) {
            const file = fileList[i];
            console.log(file.name);
            uploadFile(file)
                .then((result) => {
                    console.log("result = ", result.data);
                    callback(result.data);
                })
                .catch((err) => {
                    showError("Erreur inattendu télécharger.", "error");
                    callback([]);
                });
        }
    }
};

const Info = ({ entreprise, onEntrepriseChange, showMessage, toRecap, submit }) => {

    const preSubmit = () => {
        if(!entreprise.type_societe) {
            showMessage("Type de société est obligatoire", "error");
            return;
        }
        submit();
    }
    return (
        <Box width={'full'} justify="center" align='center' >
            <Box width={'xlarge'} justify="center" align='center' >
                <Box width={'large'} justify="center" align='center' gap='small'>
                    <SectionDivider title={'Renseigner informations entreprise'} />

                    <SmallLine leftLabel={'Nom de la sociégé'}
                        rightChildren={
                            <CustomInput value={entreprise.raison_social}
                                onChange={event => onEntrepriseChange('raison_social', event.target.value)}
                            />
                        }
                    />
                    <SmallLine leftLabel={'Type de société'}
                        rightChildren={
                            <CustomSelect
                                size={'xsmall'}
                                options={[
                                    "Classique",
                                    "Voyage",
                                    "Apporteur My Trustiway"
                                ]}
                                value={entreprise.type_societe}
                                onChange={({ value: nextValue }) => onEntrepriseChange('type_societe', nextValue)}
                            />
                        }
                    />
                    <SmallLine leftLabel={'Téléphone'}
                        rightChildren={
                            <CustomInput value={entreprise.phone} size='small'
                                onChange={event => onEntrepriseChange('phone', event.target.value)}
                            />
                        }
                    />
                    <SmallLine leftLabel={'Email'}
                        rightChildren={
                            <CustomInput value={entreprise.email} size='small'
                                onChange={event => onEntrepriseChange('email', event.target.value)}
                            />
                        }
                    />
                    <SmallLine leftLabel={'Adresse principale'}
                        rightChildren={
                            <ReactGoogleAutocomplete
                                value={entreprise.address}
                                style={{
                                    background: "#EDEDED",
                                    padding: 11,
                                    borderRadius: 10,
                                    fontSize: 16,
                                    border: "none",
                                    width: "100%",
                                    height: 44,
                                }}
                                apiKey={"AIzaSyB9n7vwM3pC2E0O_X96m3VSbloepeaDOsA"}
                                placeholder=""
                                onPlaceSelected={(place) => {
                                    let parts = place.address_components;
                                    console.log(place);
                                    let _address = parts
                                        .filter(
                                            (s) =>
                                                s.types &&
                                                (s.types.includes("street_number") ||
                                                    s.types.includes("route"))
                                        )
                                        .map((s) => s.long_name)
                                        .join(" ");
                                    let _zip = parts
                                        .filter((s) => s.types && s.types.includes("postal_code"))
                                        .map((s) => s.long_name)
                                        .join("");
                                    let _city = parts
                                        .filter((s) => s.types && s.types.includes("locality"))
                                        .map((s) => s.long_name)
                                        .join("");
                                    onEntrepriseChange("address", _address);
                                    onEntrepriseChange("city", _city);
                                    onEntrepriseChange("zip", _zip);
                                }}
                                options={{
                                    types: ["address"],
                                    componentRestrictions: { country: "fr" },
                                }}
                                onChange={(event) => {
                                    onEntrepriseChange("address", event.target.value);
                                }}
                            />
                        }
                    />

                    <SmallLine leftLabel={'Code postal'}
                        rightChildren={
                            <CustomInput value={entreprise.zip} size='small'
                                onChange={event => onEntrepriseChange('zip', event.target.value)}
                            />
                        }
                    />
                    <SmallLine leftLabel={'Ville'}
                        rightChildren={
                            <CustomInput value={entreprise.city} size='small'
                                onChange={event => onEntrepriseChange('city', event.target.value)}
                            />
                        }
                    />
                    <SmallLine leftLabel="Indeice de confiance sur Trustiway"
                        rightChildren={
                            <CustomSelect size='small'
                                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                value={entreprise.indice_de_confiance_sur_trustiway}
                                onChange={({ option }) =>
                                    onEntrepriseChange("indice_de_confiance_sur_trustiway", option)
                                }
                            />
                        }
                    />
                    <SmallLine leftLabel="Indice de confiance sur le seller"
                        rightChildren={
                            <CustomSelect size='small'
                                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                value={entreprise.indice_de_confiance_sur_le_seller}
                                onChange={({ option }) =>
                                    onEntrepriseChange("indice_de_confiance_sur_le_seller", option)
                                }
                            />
                        }
                    />
                    <SmallLine leftLabel="Statut du lead"
                        rightChildren={
                            <CustomSelect
                                options={lead_status.map((l) => l.value)}
                                value={findLeadStatusValue(entreprise.statut_du_lead)}
                                onChange={({ option }) =>
                                    onEntrepriseChange("statut_du_lead", findLeadStatusKey(option))
                                }
                            />
                        }
                    />
                    <SmallLine
                        leftLabel="Ne pas envoyer email"
                        rightChildren={
                            <ToggleButton
                                groups={[
                                    {
                                        label: '❌ Ne pas envoyer email',
                                        selected: entreprise.do_not_emailing === true,
                                        onClick: () => onEntrepriseChange("do_not_emailing", true),
                                    },
                                    {
                                        label: '✅ Email OK',
                                        selected: entreprise.do_not_emailing !== true,
                                        onClick: () => onEntrepriseChange("do_not_emailing", false),
                                    },
                                ]}
                            />
                        }
                    />

                    <SectionDivider title={"Renseigner informations de l'interlocuteur"} />
                    <Interlocuteurs interlocuteurs={entreprise.interlocuteurs} onEntrepriseChange={onEntrepriseChange} showMessage={showMessage} />
                    {entreprise &&

                        <Box pad={'small'} gap='small'>
                            <Box pad={'small'} gap='small'>
                                {entreprise.file_kbis && (
                                    <a target={"_blank"} rel="noreferrer" href={`${entreprise.file_kbis}`}>
                                        <DocumentDownload />
                                        KBIS
                                    </a>
                                )}
                                {entreprise.file_statut && (
                                    <a target={"_blank"} rel="noreferrer" href={`${entreprise.file_statut}`}>
                                        <DocumentDownload />
                                        Statut
                                    </a>
                                )}
                            </Box>
                            <Box pad={'small'} gap='small'>
                                <FileInput
                                    messages={{
                                        browse: "Choisir",
                                        dropPrompt: "KBIS",
                                    }}
                                    multiple={false}
                                    onChange={(event) => {
                                        doUploadFile(
                                            event.target.files,
                                            async (data) => {
                                                if (data.length > 0) {
                                                    let _uploaded = data[0];
                                                    onEntrepriseChange("file_kbis", _uploaded.url);
                                                }
                                            },
                                            showMessage
                                        );
                                    }}
                                />
                                <FileInput
                                    messages={{
                                        browse: "Choisir",
                                        dropPrompt: "Statut",
                                    }}
                                    multiple={false}
                                    onChange={(event) => {
                                        doUploadFile(
                                            event.target.files,
                                            async (data) => {
                                                if (data.length > 0) {
                                                    let _uploaded = data[0];
                                                    onEntrepriseChange("file_statut", _uploaded.url);
                                                }
                                            },
                                            showMessage
                                        );
                                    }}
                                />
                            </Box>
                        </Box>

                    }
                    <Box direction="row" align="center" justify="center" gap="medium" pad={'medium'}>
                        <PrimaryButton
                            label={`Valider`}
                            icon={<Checkmark />}
                            background="#59A4F4"
                            onClick={preSubmit}
                        />
                        <PrimaryButton
                            label={`Annuler`}
                            icon={<Close />}
                            background="#6c757d"
                            onClick={toRecap}
                        />
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}
const Interlocuteurs = ({ interlocuteurs = [], onEntrepriseChange, showMessage, disabled }) => {
    const [interlocuteur, setInterlocuteur] = React.useState({});

    const onIterChange = (field, value) => {
        setInterlocuteur(Object.assign({}, value));
    }

    const addInterlocuteur = () => {
        console.log(interlocuteur)
        if (
            !interlocuteur.firstname ||
            !interlocuteur.lastname ||
            !interlocuteur.phone ||
            !interlocuteur.email
        ) {
            showMessage('Information d\'interlocuteur n\'est pas complet', 'error');
            return;
        }
        interlocuteurs.push(interlocuteur);
        setInterlocuteur({});
        onEntrepriseChange('interlocuteurs', interlocuteurs.map(s => s));
    }

    const removeInterlocuteur = idx => {
        interlocuteurs.splice(idx, 1)
        onEntrepriseChange('interlocuteurs', interlocuteurs);
    }

    const onReceivingMailChange = idx => {
        let iter = interlocuteurs[idx];
        iter.receiving_mail = iter.receiving_mail ? false : true;
        onEntrepriseChange('interlocuteurs', interlocuteurs.map(i => i));
    }

    return (
        <Box gap="small" width={"large"} >
            <Box pad={'small'} gap="small" width={"large"}>
                {
                    toArrayOfArray(interlocuteurs, 2).map((interG, index) => (
                        <Box key={index} pad={'small'} gap="large" width={"large"} direction="row" justify='between'>
                            {
                                interG.map((iter, idx) => (
                                    <Interlocuteur key={idx} idx={idx + index * 3} iter={iter} remove={() => removeInterlocuteur(idx + index * 3)}
                                        onReceivingMailChange={() => onReceivingMailChange(idx + index * 3)}
                                        disabled={disabled}
                                    />
                                ))
                            }
                        </Box>
                    ))
                }
            </Box>

            {!disabled &&
                <ContactSelectV2
                    contact={interlocuteur}
                    updateContact={onIterChange}
                    addressIncluded={false}
                    title='Interlocuteur'
                    size="xsmall"
                    isPro={true}
                />
            }
            <Box gap="small" width={"medium"} direction="row" align="center" justify="center">
                <PrimaryButton icon={<Add />}
                    onClick={addInterlocuteur}
                    disabled={disabled}
                    label="Ajouter un interlocuteur"
                />
            </Box>
        </Box>
    )
}

export { Interlocuteurs };

export default Info;
import React from "react";
import { Box, Button, Card, CheckBox, Grid, Image, Text } from "grommet";
import {
	CONTRAT_CLOTURE,
	CONTRAT_ENVOYE,
	DEAL_POSSIBLE,
	DEAL_POSSIBLE_REMPLI,
	DEVIS,
	DEVIS_VALIDE,
	EN_ATTENTE_BANQUE,
	EN_ATTENTE_DE_FORMALITE_MEDICALE,
	EN_ATTENTE_DE_RESILIATION,
	PROSPECT,
} from "../../services/devis_contrat_status";
import { getNumberOfAppel, getSimpleDateFR } from "../../services/utils";
import { AddCircle, Phone, SubtractCircle } from "grommet-icons";
import PhoneIcon from "../../images/phone.png";
import OpportunityTest from "./opportunity";

const updateNumberOfAppel = (devis, updateDevis, onSave, value) => {
	// get last time click from localstorage
	const lastClick = window.localStorage.getItem("lastClick");
	// if last click is less than 1à second ago
	if (lastClick && new Date() - new Date(lastClick) < 10000) {
		alert("Vous avez déjà cliqué il y a moins de 10 secondes");
		return;
	}
	// Save time of click
	window.localStorage.setItem("lastClick", new Date());

	if (!devis) return 0;
	var _val = getNumberOfAppel(devis) + value;
	if (_val < 0) _val = 0;
	switch (devis.status) {
		case PROSPECT:
			updateDevis("nbr_appel_prospect", _val);
			break;
		case DEAL_POSSIBLE:
			updateDevis("nbr_appel_deal_possible", _val);
			break;
		case DEAL_POSSIBLE_REMPLI:
			updateDevis("nbr_appel_deal_rempli", _val);
			break;
		case DEVIS:
			updateDevis("nbr_appel_devis", _val);
			break;
		case DEVIS_VALIDE:
			updateDevis("nbr_appel_devis_valide", _val);
			break;
		case EN_ATTENTE_DE_RESILIATION:
			updateDevis("nbr_appel_resiliation", _val);
			break;
		case EN_ATTENTE_DE_FORMALITE_MEDICALE:
			updateDevis("nbr_appel_formalite_medicale", _val);
			break;
		case EN_ATTENTE_BANQUE:
			updateDevis("nbr_appel_attente_banque", _val);
			break;
		case CONTRAT_ENVOYE:
			updateDevis("nbr_appel_contrat", _val);
			break;
		case CONTRAT_CLOTURE:
			updateDevis("nbr_appel_cloture", _val);
			break;
		default:
			return 0;
	}
	var _date = new Date();
	updateDevis("date_dernier_appel", _date);
	updateDevis("need_generate_appel", value);

	onSave();
};

const PanelAppel = ({ devis, updateDevis, onSave, showMessage }) =>
	// [
	// 	CONTRAT_CLOTURE,
	// 	CONTRAT_ENVOYE,
	// 	PROSPECT,
	// 	DEAL_POSSIBLE,
	// 	DEAL_POSSIBLE_REMPLI,
	// 	DEVIS,
	// 	DEVIS_VALIDE,
	// 	EN_ATTENTE_DE_FORMALITE_MEDICALE,
	// 	EN_ATTENTE_DE_RESILIATION,
	// ].includes(devis.status) && (
		<Box
			direction="column"
			gap="small"
			pad="small"
			justify="center"
			alignContent="center"
			align="center"
		>
			<Card direction="column" pad="xsmall" align="center">
				<Box
					direction="row"
					gap="small"
					pad="small"
					justify="center"
					alignContent="center"
					align="center"
				>
					<Phone color="#000" />
					<Text size="xlarge" weight={"bolder"} color="#59A4F4">
						{devis && getNumberOfAppel(devis)}
					</Text>
					<Button
						onClick={() => {
							updateNumberOfAppel(devis, updateDevis, onSave, 1);
						}}
					>
						<AddCircle color="brand" />
					</Button>
					<Button
						onClick={() => {
							updateNumberOfAppel(devis, updateDevis, onSave, -1);
						}}
					>
						<SubtractCircle color="brand" />
					</Button>
				</Box>
				<Box direction="column" gap="xsmall" pad="xsmall" align="center">
					<Text size="small" weight={"bold"}>
						Date du dernier appel:
					</Text>
					<Text size="small">
						{devis && getSimpleDateFR(devis.date_dernier_appel)}
					</Text>
				</Box>
			</Card>
			<Card direction="column" width={"250px"} pad="xsmall" align="center">
				<CheckBox
					checked={devis.rappel_automatic}
					onChange={(event) => {
						updateDevis("rappel_automatic", event.target.checked);
						onSave();
					}}
					size="small"
					label="Créer les rappels automatique par statut"
				></CheckBox>
				<OpportunityTest showMessage={showMessage} ></OpportunityTest>
			</Card>
		</Box>;
	// );

export const PanelAppelV2 = ({ devis, updateDevis, onSave, showMessage }) =>
	// [
	// 	CONTRAT_CLOTURE,
	// 	CONTRAT_ENVOYE,
	// 	PROSPECT,
	// 	DEAL_POSSIBLE,
	// 	DEAL_POSSIBLE_REMPLI,
	// 	DEVIS,
	// 	DEVIS_VALIDE,
	// 	EN_ATTENTE_DE_FORMALITE_MEDICALE,
	// 	EN_ATTENTE_DE_RESILIATION,
	// ].includes(devis.status) && (
		<Grid
			columns={["30%", "40%", "30%"]}
			gap="small"
			width="100%"
			pad="0 20px"
		>
			<Box
				direction="row"
				gap="small"
				// justify="center"
				alignContent="center"
				align="center"
			>
				<Image src={PhoneIcon} width={30} />
				<Button
					onClick={() => {
						updateNumberOfAppel(devis, updateDevis, onSave, 1);
					}}
				>
					<AddCircle color="grey" />
				</Button>
				<Button
					onClick={() => {
						updateNumberOfAppel(devis, updateDevis, onSave, -1);
					}}
				>
					<SubtractCircle color="grey" />
				</Button>
				<Text>Ajouter un appel</Text>
			</Box>
			<CheckBox
				checked={devis.rappel_automatic}
				onChange={(event) => {
					updateDevis("rappel_automatic", event.target.checked);
					onSave();
				}}
				size="small"
				color="grey"
				label="Créer les rappels automatique par statut"
			></CheckBox>
			<OpportunityTest showMessage={showMessage} ></OpportunityTest>
		</Grid>;
	// );



export default PanelAppel;

import { Box } from 'grommet';
import moment from 'moment';
import React from 'react';
import 'react-block-ui/style.css';
import BlockUi from 'react-block-ui';
import { getGestionCollabs, getObjectifDayData } from '../../../services/dashboard';
import { getEquipes } from '../../../services/admin_service';
import ObjectifDayIndicator from './objectif_day_indicator';
import { hasClassiqueManagerRole, isCommercialClassique } from '../../../services/storage_service';


class ObjectifDay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indicator: undefined,
            commerciaux: [],
            loading: false,
            equipes: []
        }
    }

    componentDidMount() {
        getGestionCollabs()
            .then(res => {
                let commerciaux = res.data.filter(c => isCommercialClassique(c) || hasClassiqueManagerRole(c));
                this.setState({ commerciaux });
            });

        let startDate = moment().startOf("month").toDate();
        let endDate = moment().endOf("month").toDate();
        this.getIndicator(startDate, endDate,);
        getEquipes()
            .then(res => this.setState({ equipes: res.data }))
            .catch(err => console.log(err));
    }

    getIndicator = (startDate, endDate, commercial_id, statuts) => {
        this.setState({ loading: true });
        getObjectifDayData(startDate, endDate, commercial_id, statuts)
            .then(res => {
                this.bindData(res.data, statuts, startDate, endDate);
            })
            .catch(err => this.setState({ loading: false }))
    }

    getDateFieldByStatus = (status) => {
        switch (status) {
            case "Deal possible rempli":
                return "date_deal_rempli";
            case "Devis":
                return "date_devis";
            case "Devis validé":
                return "date_devis_valide";
            case "En attente de résiliation":
                return "date_resiliation";
            case "En attente de formalité médicale":
                return "date_formalite_medicale";
            case "Contrat envoyé":
                return "date_contrat";
            case "En attente de la banque":
                return "date_attente_banque";
            case "Contrat clôturé":
                return "date_cloture";
            default:
                return "createdAt";
        }
    }

    bindData = (data, statuts, startDate, endDate) => {

        if (!statuts || statuts?.length <= 0) {
            statuts = [
                "Prospect",
                "Deal possible",
                "Deal possible rempli",
                "Devis",
                "Devis validé",
                "En attente de résiliation",
                "En attente de formalité médicale",
                "Contrat envoyé",
                "En attente de la banque",
                "Contrat clôturé",
                "Résilié",
                "Archivé",
                "Perdu"
            ];
        }
        console.log('status = ', statuts);
        var _keys = [];
        let _startDate = moment(startDate).toDate().getDate();
        let _endDate = moment(endDate).toDate().getDate();
        console.log('_startDate.day = ', _startDate);
        console.log('_endDate.day = ', _endDate);
        for (let i = _startDate; i <= _endDate; i++) {
            _keys.push(i);
        }
        let _dataCharts = [];
        let _max = 0;
        for (let j = 0; j < statuts.length; j++) {
            const _statut = statuts[j];
            let _arrDealStatut = data.all_devis.filter(e => e.status === _statut);
            let _arrData = [];
            for (let k = 0; k < _keys.length; k++) {
                const _day = _keys[k];
                let _v = _arrDealStatut.filter(e => {
                    return moment(e[this.getDateFieldByStatus(_statut)]).toDate().getDate() === _day;
                }).length;
                if (_v >= _max) {
                    _max = _v;
                }
                _arrData.push(_v);
            }
            _dataCharts.push(_arrData);
        }

        if (data.defined_objectifs.objectif >= _max) {
            _max = data.defined_objectifs.objectif;
        }
        const backgroundColors = [
            "#bfbfbf", "#73A6EC", "#73AAAA", "#FC0000", "#FF6E6E", "#FFD77C", "#FFF88C", "#1D3653", "#1D0053", "#33BC00", "#000", "#000", "#000"
        ]

        let data_set = _dataCharts.map((d, index) => (
            {
                label: statuts[index],
                backgroundColor: backgroundColors[index],
                data: d
            }
        ));

        data_set.push({
            type: 'line',
            label: 'Objectif par jour',
            borderColor: '#00000',
            borderWidth: 2,
            backgroundColor: '#00000',
            fill: true,
            data: _keys.map(() => data.defined_objectifs.objectif),
        })

        let _out = {
            ...data,

            chart_data: {
                maxValue: _max,
                labels: statuts,
                keyLabels: _keys,
                keys: _keys,
                data: _dataCharts,
                dataSets: data_set
            }
        };

        this.setState({ indicator: _out, loading: false });
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>

                <Box style={{ width: "100%", paddingTop: 20 }}
                    align="center" justify="center"
                    alignSelf="center"
                    alignContent="center" gap="medium">
                    {this.state.indicator &&
                        <ObjectifDayIndicator indicator={this.state.indicator} updateObjectifDayIndicator={this.getIndicator}
                            commerciaux={this.state.commerciaux}
                            equipes={this.state.equipes}
                        />
                    }

                </Box>
            </BlockUi>

        )
    }
}

export default ObjectifDay;
import { Box, Text } from 'grommet';
import React from 'react';
import StandardText from '../../../elements/standard_text';

const SectionDivider = ({ title }) => {
    return (
        <Box gap="xsmall" width={"large"} pad='xsmall' border={'bottom'}>
            <StandardText style={{ fontWeight: 'bold' }} label={title} />
        </Box>
    )
}

export default SectionDivider;
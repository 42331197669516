import { Box, Heading, } from "grommet";
import React, { useState } from "react";
import PrimaryButton from "../../../elements/primary_button";
import { Checkmark } from "grommet-icons";
import { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import Line from "../../../elements/line_container";
import CustomSelect from "../../../elements/select_fill";
import { uploadFile } from "../../../../services/storage_service";
import { RISK_PROFESSIONS } from "../../../../types/emprunteur_type";

const isRiskProfession = (value) => {
  return value !== null && value !== RISK_PROFESSIONS[0].description;
};

const doUploadFile = (files, callback, showError) => {
  const fileList = files;
  if (fileList) {
    for (let i = 0; i < fileList.length; i += 1) {
      const file = fileList[i];
      console.log(file.name);
      uploadFile(file)
        .then((result) => {
          console.log("result = ", result.data);
          callback(result.data);
        })
        .catch((err) => {
          showError("Erreur inattendu télécharger.");
          callback([]);
        });
    }
  }
};

const EmprunteurInfo = ({
  devis,
  updateDevis,
  onNext,
  contact,
  showMessage,
}) => {
  const [assures, setAssures] = useState(devis?.emprunteurs || [{}]);
  const [isLoading, setIsLoading] = useState(false);

  const _numberCoEmprunteur = (_num) => {
    var _ems = devis.emprunteurs;
    if (!_ems) {
      devis.emprunteurs = [];
      _ems = [];
    }
    console.log("_ems = ", _ems);
    const _assures = [];

    if (_ems) {
      for (let i = 0; i < _ems.length; i++) {
        const element = _ems[i];
        console.log(i, " - el num = ", _num);
        if (i < _num) {
          console.log(i, " - el = ", element);
          _assures.push(element);
        }
      }
      if (_num > _ems.length) {
        for (let j = _ems.length; j < _num; j++) {
          _assures.push({ civility: "Monsieur" });
        }
      }
    }

    setAssures(_assures);
    updateDevis("emprunteurs", _assures);
    console.log("emprunteurs = ", _assures);
  };

  // console.log("devis = ", devis);
  return (
    <Box
      gap="small"
      width="xlarge"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Box justify="center" alignSelf="center">
        <Heading
          level={3}
          textAlign="center"
        >{`Informations de l'emprunteur`}</Heading>
      </Box>

      <ContactSelectV2
        contact={devis.client || {}}
        updateContact={updateDevis}
        addressIncluded={true}
        birthDayIncluded={true}
      />
      <Box width="large" gap="medium">
        <Box gap="xsmall">
          <Line
            leftLabel={'Produit'}
            rightChildren={
              <CustomSelect
                size={'small'}
                options={[
                  "Crédit Immobilier",
                  "Regroupement de crédit",
                  "Crédit à la consommation",
                  "Prêt hypothécaire",
                ]}
                value={devis.sub_product}
                onChange={({ option }) => updateDevis('sub_product', option)}
              />
            }
          />
        </Box></Box>
      <Box style={{ height: 40 }} />
      <Box direction="row" align="center" justify="center" gap="medium">
        <PrimaryButton
          disabled={isLoading}
          label={`Valider`}
          icon={<Checkmark />}
          onClick={onNext}
        />
      </Box>
    </Box>
  );
};

export default EmprunteurInfo;

import React from "react";
import { Box, Card, Heading, Stack, Text } from "grommet";
import { getSimpleDateFR, toCurrency } from "../../../../services/utils";
import RecapStatus from "../../../elements/devis_status";
import StatusDateAppel from "../../../elements/status_date_appel";
import PanelAppel from "../../../elements/panel_appel";
import StandardText from "../../../elements/standard_text";
import { DocumentDownload } from "grommet-icons";
import EmailEventTracker from "../../../elements/email_tracker";
import StripeCustomer from "../../../elements/stripe_customer";
import ApporteurGestionnaire from "../../../elements/apporteur_gestionaire";
import TrustiBot from "../../../trustibot";
import DateRecap from "../../../elements/date_recap";

const Recap = ({ devis, updateDevis, onSave }) => {
  return (
    <Box
      direction="row"
      gap='small'
      style={{ width: "100vw" }}
    >
      <TrustiBot></TrustiBot>
      <Box
        gap="medium"
        // style={{ width: "100%" }}
        align="center"
        alignSelf="center"
        pad="medium"
        width="xlarge"
      >
        <Heading level="2" color="#59A4F4" textAlign="center">
          Trusti GLI
        </Heading>
        <Box direction="row" fill="horizontal" justify="center" alignContent="center">
          <Box align="center">
            <StatusDateAppel devis={devis} />
          </Box>
          <Box>
            <Box alignSelf="end">
              <RecapStatus status={devis.status} />
              <PanelAppel devis={devis} updateDevis={updateDevis} onSave={onSave} />
            </Box>
          </Box>
        </Box>
        <Box  gap="small" width={'full'} justify='center' align="center">
          <Card  pad="small" gap="small" width={'full'} >
            <Text textAlign="center"><b>ID:</b> {devis.id}</Text> 
          </Card>
        </Box>
        <Box  gap="small" width={'full'}>
          <DateRecap deal={devis} />
        </Box>
        <Box width={'xlarge'}>
          <StripeCustomer devisId={devis.id} />
        </Box>

        <Box align='center' justify='center' gap='small' pad={'small'}>
          <Box width={'large'} gap='small'>
            <StandardText size="xlarge" style={{ fontWeight: 'bold', color: "#E92C70" }} label="Trusti GLI" />
            <StandardText size="xlarge" style={{ fontWeight: 'bold' }} label="Vos informations" />
            <StandardText label={'Renseigner les informations du souscripteur :'} style={{ fontWeight: 'bold' }} />
            <LineDisplay
              label={"Civilité"}
              value={devis.client?.civilite}
            />

            <Box width={'full'} justify='between'>
              <LineDisplay
                label={"Prénom"}
                value={devis.client?.firstname}
              />
              <LineDisplay
                label={"Nom"}
                value={devis.client?.lastname}
              />
            </Box>
            <LineDisplay
              label={"Email"}
              value={devis.client?.email}
            />
            <LineDisplay
              label={"Téléphone"}
              value={devis.client?.phone}
            />
            <LineDisplay
              label={"Adresse"}
              value={`${devis.client?.address}, ${devis.client?.zip}, ${devis.client?.city}`}
            />
            <Box style={{ height: 10 }} />
            <StandardText style={{ fontWeight: 'bold' }} label="Informations du lot donné en location :" />

            <LineDisplay
              label={"Adresse"}
              value={`${devis.address}, ${devis.zip}, ${devis.city}`}
            />
            <LineDisplay
              label={"Bât"}
              value={`${devis.bat}`}
            />
            <LineDisplay
              label={"N° Lot"}
              value={`${devis.numero_lot}`}
            />
            <LineDisplay
              label={"Type de bien"}
              value={`${devis.type_bien}`}
            />
            <LineDisplay
              label={"Nombre de pièces principales"}
              value={`${devis.nbr_piece_principale}`}
            />
            <LineDisplay
              label={"Date du bail"}
              value={`${getSimpleDateFR(devis.date_du_bail)}`}
            />
            <LineDisplay
              label={"Montant dépôt de garantie"}
              value={`${toCurrency(devis.depot_garantie)}`}
            />
            <Box style={{ height: 10 }} />
            {
              (devis.locataires || []).map((locataire, index) => (
                <LocataireDisplay
                  key={index}
                  index={index}
                  locataire={locataire}
                />
              ))
            }
            <Box style={{ height: 30 }} />
          </Box>
        </Box>
        {(devis.apporteur || devis.affiliation) && <Card pad={"small"} width={'xlarge'} >
          <ApporteurGestionnaire apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
        </Card>}
        <Box width={'xlarge'}>
          <EmailEventTracker messageIds={devis.emailEventIds} />
        </Box>
      </Box>
    </Box>
  );
};

const LocataireDisplay = ({ locataire, index }) => (
  <Box width={'large'} gap='small'>
    <StandardText label={`Informations du ${index === 0 ? '1er' : '2ème'} locataire :`} style={{ fontWeight: 'bold' }} />
    <LineDisplay
      label={"Titulaire"}
      value={locataire.titulaire}
    />
    <Box width={'full'} justify='between'>
      <LineDisplay
        label={"Prénom"}
        value={locataire.firstname}
      />
      <LineDisplay
        label={"Nom"}
        value={locataire.lastname}
      />
    </Box>
    <LineDisplay
      label={"Email"}
      value={locataire.email}
    />
    <LineDisplay
      label={"Phone"}
      value={locataire.phone}
    />
    <LineDisplay
      label={"Date de naissance"}
      value={locataire.date_de_naissance ? getSimpleDateFR(locataire.date_de_naissance) : ''}
    />
    <LineDisplay
      label={"Nationalité"}
      value={locataire.nationality}
    />
    <LineDisplay
      label={"Salaire mensuel net"}
      value={`${toCurrency(locataire.salaire_net, true)}`}
    />
    <LineDisplay
      label={"Autre à préciser"}
      value={`${toCurrency(locataire.autre_revenu, true)}`}
    />
    <LineDisplay
      label={"Revenu net global mensuel"}
      value={`${toCurrency(locataire.autre_revenu, true)}`}
    />

    <Box style={{ height: 20 }} />
    <Box width={'full'} gap='small'>
      {locataire['id_card'] && <Box gap='small' direction="row" align="center">
        <a target={"_blank"} rel="noreferrer" href={`${locataire['id_card']}`}>
          <DocumentDownload />
          {"Carte d'identité ou passport"}
        </a>
      </Box>
      }
      {locataire['impot'] && <Box gap='small' direction="row" align="center">
        <a target={"_blank"} rel="noreferrer" href={`${locataire['impot']}`}>
          <DocumentDownload />
          {"Votre dernier avis d’impôt"}
        </a>
      </Box>
      }
      {locataire['attestation_emploi'] && <Box gap='small' direction="row" align="center">
        <a target={"_blank"} rel="noreferrer" href={`${locataire['attestation_emploi']}`}>
          <DocumentDownload />
          {"Votre attestation d’emploi"}
        </a>
      </Box>
      }
      {locataire['bulletin_salaires'] && <Box gap='small' direction="row" align="center">
        <a target={"_blank"} rel="noreferrer" href={`${locataire['bulletin_salaires']}`}>
          <DocumentDownload />
          {"Vos 3 derniers bulletins de salaire"}
        </a>
      </Box>
      }
      {locataire['carte_etudiant'] && <Box gap='small' direction="row" align="center">
        <a target={"_blank"} rel="noreferrer" href={`${locataire['carte_etudiant']}`}>
          <DocumentDownload />
          {"Carte d’étudian"}
        </a>
      </Box>
      }
      {locataire['carte_professionnelle'] && <Box gap='small' direction="row" align="center">
        <a target={"_blank"} rel="noreferrer" href={`${locataire['carte_professionnelle']}`}>
          <DocumentDownload />
          {"Carte professionnelle"}
        </a>
      </Box>
      }
      {locataire['2_derniers_impots'] && <Box gap='small' direction="row" align="center">
        <a target={"_blank"} rel="noreferrer" href={`${locataire['2_derniers_impots']}`}>
          <DocumentDownload />
          {"2 derniers avis d’imposition"}
        </a>
      </Box>
      }
      {locataire['kbis'] && <Box gap='small' direction="row" align="center">
        <a target={"_blank"} rel="noreferrer" href={`${locataire['kbis']}`}>
          <DocumentDownload />
          {"KBIS de moins de 3 mois"}
        </a>
      </Box>
      }
      {locataire['revenus_expert_comtable'] && <Box gap='small' direction="row" align="center">
        <a target={"_blank"} rel="noreferrer" href={`${locataire['revenus_expert_comtable']}`}>
          <DocumentDownload />
          {"Attestation de revenus d’expert-comptable pour l’année en cours"}
        </a>
      </Box>
      }
      {locataire['deux_bilans'] && <Box gap='small' direction="row" align="center">
        <a target={"_blank"} rel="noreferrer" href={`${locataire['deux_bilans']}`}>
          <DocumentDownload />
          {"2 derniers bilans"}
        </a>
      </Box>
      }
      {locataire['deux_comptes_exploitation'] && <Box gap='small' direction="row" align="center">
        <a target={"_blank"} rel="noreferrer" href={`${locataire['deux_comptes_exploitation']}`}>
          <DocumentDownload />
          {"Vos 2 derniers comptes d’exploitation"}
        </a>
      </Box>
      }
    </Box>
  </Box>
)

const CustomText = ({ label, textAlign }) => (
  <Text
    style={{ fontWeight: "bold" }}
    size="small"
    textAlign={textAlign || "end"}
  >
    {label}
  </Text>
);
const Line = ({ leftLabel, rightLabel }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "50%" }}>
      <CustomText label={leftLabel + " :"} />
    </Box>
    <Box style={{ width: "50%" }}>
      <CustomText label={rightLabel} textAlign="start" />
    </Box>
  </Box>
);

const LineDisplay = ({ label, value }) => (
  <Box gap="xxsmall" direction='row' width={'full'}>
    <StandardText size={'small'} label={label + ' : '} />
    <StandardText size={'small'} style={{ fontWeight: 'bold' }} label={value} />
  </Box>
)

const SubHeading = ({ label, color }) => (
  <Text
    textAlign="center"
    style={{ fontWeight: "bold", color: color || "black" }}
  >
    {label}
  </Text>
);

export default Recap;

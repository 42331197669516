import React from "react";
import { Box, Button, Card, CheckBox, Grid, Image, Text } from "grommet";
import { AddCircle, Phone, SubtractCircle } from "grommet-icons";
import { ABONDONS, ARCHIVE, A_RAPPELER_PLUS_TARD, CLOTURE, CONTRAT_CLOTURE, DOSSIER_ENVOYE_A_LA_BANQUE, DOSSIER_EN_INSTRUCTION, EN_ETUDE_DE_ANALYSTE, EN_ETUDE_DE_PRE_ANALYSTE, FACTURE, NOUVEAU, NOUVEAU_REMPLI, OFFRE_ACCEPTE_PAR_CLIENT, PRET_ACCORDE_PAR_LA_BANQUE, PROSPECT, REALISE } from "../../../../services/credit_status";
import PhoneIcon from "../../../../images/phone.png";
import OpportunityTest from "../../../elements/opportunity";
import TrustiBotButtonPopup from "../../../trustibot/button_popup";

const updateNumberOfAppel = (devis, updateDevis, onSave, value) => {
	// get last time click from localstorage
	const lastClick = window.localStorage.getItem("lastClick");
	// if last click is less than 1à second ago
	if (lastClick && new Date() - new Date(lastClick) < 10000) {
		alert("Vous avez déjà cliqué il y a moins de 10 secondes");
		return;
	}
	// Save time of click
	window.localStorage.setItem("lastClick", new Date());

	if (!devis) return 0;
	var _val = getNumberOfAppel(devis) + value;
	if (_val < 0) _val = 0;
	switch (devis.status) {
		case PROSPECT:
			updateDevis("nbr_appel_prospect", _val);
			break;
		case NOUVEAU:
			updateDevis("nbr_appel_nouveau", _val);
			break;
		case NOUVEAU_REMPLI:
			updateDevis("nbr_appel_nouveau_rempli", _val);
			break;
		case A_RAPPELER_PLUS_TARD:
			updateDevis("nbr_a_rappeler_plus_tard", _val);
			break;
		case DOSSIER_EN_INSTRUCTION:
			updateDevis("nbr_appel_dossier_en_instruction", _val);
			break;
		case EN_ETUDE_DE_PRE_ANALYSTE:
			updateDevis("nbr_en_etude_de_pre_analyste", _val);
			break;
		case EN_ETUDE_DE_ANALYSTE:
			updateDevis("nbr_en_etude_de_analyste", _val);
			break;
		case DOSSIER_ENVOYE_A_LA_BANQUE:
			updateDevis("nbr_appel_dossier_envoye_a_la_banque", _val);
			break;
		case PRET_ACCORDE_PAR_LA_BANQUE:
			updateDevis("nbr_appel_pret_accorde_par_la_banque", _val);
			break;
		case OFFRE_ACCEPTE_PAR_CLIENT:
			updateDevis("nbr_appel_offre_accepte_par_client", _val);
			break;
		case REALISE:
			updateDevis("nbr_appel_resilie", _val);
			break;
		case FACTURE:
			updateDevis("nbr_appel_facture", _val);
			break;
		case CLOTURE:
			updateDevis("nbr_appel_cloture", _val);
			break;
		case ABONDONS:
			updateDevis("nbr_appel_abondons", _val);
			break;
		default:
			return 0;
	}
	var _date = new Date();
	updateDevis("date_dernier_appel", _date);
	updateDevis("need_generate_appel", value);

	onSave({ skipValidation: true });
};

const getNumberOfAppel = (devis) => {
	if (!devis) return 0;
	switch (devis.status) {
		case PROSPECT:
			return devis.nbr_appel_prospect;
		case NOUVEAU:
			return devis.nbr_appel_nouveau;
		case NOUVEAU_REMPLI:
			return devis.nbr_appel_nouveau_rempli;
		case A_RAPPELER_PLUS_TARD:
			return devis.nbr_a_rappeler_plus_tard;
		case DOSSIER_EN_INSTRUCTION:
			return devis.nbr_appel_dossier_en_instruction;
		case EN_ETUDE_DE_PRE_ANALYSTE:
			return devis.nbr_en_etude_de_pre_analyste;
		case EN_ETUDE_DE_ANALYSTE:
			return devis.nbr_en_etude_de_analyste;
		case DOSSIER_ENVOYE_A_LA_BANQUE:
			return devis.nbr_appel_dossier_envoye_a_la_banque;
		case PRET_ACCORDE_PAR_LA_BANQUE:
			return devis.nbr_appel_pret_accorde_par_la_banque;
		case OFFRE_ACCEPTE_PAR_CLIENT:
			return devis.nbr_appel_offre_accepte_par_client;
		case REALISE:
			return devis.nbr_appel_resilie;
		case FACTURE:
			return devis.nbr_appel_facture;
		case CLOTURE:
			return devis.nbr_appel_cloture;
		case ABONDONS:
			return devis.nbr_appel_abondons;
		default:
			return 0;
	}
};



const CreditPanelAppel = ({ devis, updateDevis, onSave, showMessage }) =>
	// [
	// 	ABONDONS, CONTRAT_CLOTURE, CLOTURE, DOSSIER_ENVOYE_A_LA_BANQUE, DOSSIER_EN_INSTRUCTION, FACTURE, NOUVEAU, NOUVEAU_REMPLI, OFFRE_ACCEPTE_PAR_CLIENT, PRET_ACCORDE_PAR_LA_BANQUE, PROSPECT, REALISE, A_RAPPELER_PLUS_TARD, ARCHIVE
	// ].includes(devis.status) && (
	<Grid
		columns={["30%", "40%", "30%"]}
		gap="small"
		width="100%"
		pad="0 20px"
	>
		<Box
			direction="row"
			gap="small"
			alignContent="center"
			align="center"
			alignItems='center'
		>
			<Image src={PhoneIcon} width={30} />
			<Button
				onClick={() => {
					updateNumberOfAppel(devis, updateDevis, onSave, 1);
				}}
			>
				<AddCircle color="grey" />
			</Button>
			<Button
				onClick={() => {
					updateNumberOfAppel(devis, updateDevis, onSave, -1);
				}}
			>
				<SubtractCircle color="grey" />
			</Button>
			<Text>Ajouter un appel</Text>
		</Box>
		<CheckBox
			checked={devis.rappel_automatic}
			onChange={(event) => {
				updateDevis("rappel_automatic", event.target.checked);
				onSave();
			}}
			size="xsmall"
			color="grey"
			label={<Text alignSelf="center">Créer les rappels automatique par statut</Text>}
		></CheckBox>
		<Box gap='small'>
			<TrustiBotButtonPopup />
			<OpportunityTest showMessage={showMessage} ></OpportunityTest>
		</Box>
	</Grid>;
// );

export default CreditPanelAppel;

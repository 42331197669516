import { Box, Card, Text } from "grommet";
import React, { useEffect, useState } from "react";
import { verifyPromoCode } from "../../../services/common_service";
import { PRODUCTS } from "../../apporteur_pro/service";
import ToggleButton from "../toggle_button/toggle_button";
   
const getPromoProduct = model_type => {
    let pro = PRODUCTS.find(p => p.model_type === model_type);
    return pro?.name || '';
}

function CodePromo({ devis }) {
    const [code, setCode] = useState('');
    const [value, setValue] = useState('');

    useEffect(() => {
        if(devis.promo) {
            verifyPromoCode(devis.promo.code, getPromoProduct(devis.model_type))
                .then(res => {
                    let promo = res.data;
                    setCode(promo.code);
                    setValue(promo.value);
                    devis.promo = promo;
                })
        }
    }, [devis]);

    return (
        code ?
            <Box width="xlarge" gap="small">
                <Card style={{ width: "100%" }} pad="small" gap="small" direction="row">
                    <Box style={{ width: "50%" }}>
                        <Text size="small" textAlign='end'>Code promo:</Text>
                    </Box>
                    <Box style={{ width: "50%" }}>
                        <Text size="small" >
                            <b>{code}{' ('}{value}{' €) '}</b>
                            {devis.affiliation ? ` d'Agence ${devis.affiliation?.agence_name}` : ''}
                        </Text>
                    </Box>
                </Card>
            </Box> :
            <Box />
    )
}
const FRAIS_DOSSIER = ({devis, setFraisDossier, setFraisDossierPreinput, updateDevis, fraisDossier, fraisDossierPreinput}) => {
    
    return (
        <ToggleButton
            groups={
                getFraisDossierList(devis, setFraisDossier, setFraisDossierPreinput, updateDevis, fraisDossier, fraisDossierPreinput)
            }
        />
    )
}

const getFraisDossierList = (devis, setFraisDossier, setFraisDossierPreinput, updateDevis, fraisDossier, fraisDossierPreinput) => {
    let minValue = devis.promo ? parseFloat(devis.promo.value) : 0;
    let list = [
        {
            label: "0€",
            selected: fraisDossier === 0,
            onClick: () => {
                setFraisDossier(0);
                setFraisDossierPreinput(true);
                updateDevis("frais_dossier", 0);
            },
        },
        {
            label: "12€",
            selected: fraisDossier === 12,
            onClick: () => {
                setFraisDossier(12);
                setFraisDossierPreinput(true);
                updateDevis("frais_dossier", 12);
            },
        },
        {
            label: "24€",
            selected: fraisDossier === 24,
            onClick: () => {
                setFraisDossier(24);
                setFraisDossierPreinput(true);
                updateDevis("frais_dossier", 24);
            },
        },
        {
            label: "36€",
            selected: fraisDossier === 36,
            onClick: () => {
                setFraisDossier(36);
                setFraisDossierPreinput(true);
                updateDevis("frais_dossier", 36);
            },
        },
        {
            label: "48€",
            selected: fraisDossier === 48,
            onClick: () => {
                setFraisDossier(48);
                setFraisDossierPreinput(true);
                updateDevis("frais_dossier", 48);
            },
        },
        {
            label: "60€",
            selected: fraisDossier === 60,
            onClick: () => {
                setFraisDossier(60);
                setFraisDossierPreinput(true);
                updateDevis("frais_dossier", 60);
            },
        },
        {
            label: "72€",
            selected: fraisDossier === 72,
            onClick: () => {
                setFraisDossier(72);
                setFraisDossierPreinput(true);
                updateDevis("frais_dossier", 72);
            },
        },
        {
            label: "84€",
            selected: fraisDossier === 84,
            onClick: () => {
                setFraisDossier(84);
                setFraisDossierPreinput(true);
                updateDevis("frais_dossier", 84);
            },
        },
        {
            label: "96€",
            selected: fraisDossier === 96,
            onClick: () => {
                setFraisDossier(96);
                setFraisDossierPreinput(true);
                updateDevis("frais_dossier", 96);
            },
        },
        {
            label: "108€",
            selected: fraisDossier === 108,
            onClick: () => {
                setFraisDossier(108);
                setFraisDossierPreinput(true);
                updateDevis("frais_dossier", 108);
            },
        },
        {
            label: "Autre",
            selected: fraisDossierPreinput === false,
            onClick: () => {
                setFraisDossierPreinput(false);
            },
        },
    ]

    return list.filter((val, index) => index * 12 >= minValue);
}

export default CodePromo;

export {
    FRAIS_DOSSIER
};

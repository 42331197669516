import { Box, Button, CheckBox, Grid, Heading, Menu, Text } from "grommet";
import {
  CaretLeftFill, DocumentMissing, DocumentText,
  DocumentVerified,
  Edit,
  Money,
  Send, StatusGoodSmall, Update
} from "grommet-icons";
import React from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { convertToDealPossible, sendPresentation } from "../../../../services/common_service";
import {
  getAllCompanies,
  sendContratEmail,
  sendDevisEmail
} from "../../../../services/contact_service";
import {
  CONTRAT_CLOTURE,
  CONTRAT_ENVOYE,
  DEAL_POSSIBLE,
  DEAL_POSSIBLE_REMPLI,
  DEVIS,
  DEVIS_VALIDE, EN_ATTENTE_DE_FORMALITE_MEDICALE,
  EN_ATTENTE_DE_RESILIATION,
  isDealClosable,
  PROSPECT,
  RecapStatusColor
} from "../../../../services/devis_contrat_status";
import service, {
  sendYousign
} from "../../../../services/emprunteur_horsligne_service";
import {
  clearContextBot,
  saveContextBot
} from "../../../../services/storage_service";
import { EMPRUNTEUR_TYPE } from "../../../../services/types";
import DateInput from "../../../elements/date_input";
import DealStatusChange from "../../../elements/deal_status_change";
import Line from "../../../elements/line_container";
import PrimaryButton from "../../../elements/primary_button";
import { updateClient } from "../../../outils/client_util";
import DevisContratForm from "./devis_contrat";
import Info from "./info";
import Recap from "./recap";
import DealSubStatusChange from "../../../elements/deal_status_change/sub_status";

const STEPS = {
  info: "Info",
  recap: "Recap",
  devis: "Devis",
  contrat: "Contrat",
};
class Emprunteur extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devis: props.devis.id
        ? props.devis
        : {
          client: {
            civilite: "",
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            address: "",
            zip: "",
            location_city: "",
            location_address: "",
            location_zip: "",
            city: "",
          },
          begin_date: undefined,
          effectiveDate: new Date(),
        },
      originalDevis: Object.assign({}, this.props.devis),
      step: this.props.devis.id ? STEPS.recap : STEPS.info,
      loading: false,
    };
    console.log(props.devis);
    saveContextBot(props.devis);
  }

  setLoading = (value) => {
    this.setState({ loading: value });
  };

  updateDevis = (field, value) => {
    let devis = this.state.devis;
    if(field === 'client') {
      let client = devis.client || {};
      value = {...client, ...value};
    }
    devis[field] = value;
    this.setState({ devis: Object.assign({}, devis) });
    // console.log(devis);
  };

  sendEmailDevis = (devis) => {
    if (!devis.date_effet || new Date(devis.date_effet) < new Date()) {
      this.props.showMessage(
        "La date d'effet doit être supérieure à la date du jour",
        "error",
      );
      return;
    }
    devis.status = DEVIS;
    devis.date_devis = new Date();
    devis.model_type = "emprunteur_horsligne";
    devis.frais_dossier = devis.frais_dossier || 0;
    service
      .updateEmprunteurHorsligne(devis)
      .then((res) => {
        this.setLoading(false);
        this.setState({ step: STEPS.recap, devis: res.data });
        sendDevisEmail(devis)
          .then((res) =>
            this.props.showMessage("Devis a été envoyé", "success"),
          )
          .catch((err) =>
            this.props.showMessage("Echec d'envoyer devis", "error"),
          );
      })
      .catch((err) => {
        console.log(err);
        this.props.showMessage("Erreur inattendu", "error");
        this.setLoading(false);
      });
  };

  sendEmailContrat = (devis, callback) => {
    if (!devis.date_effet || new Date(devis.date_effet) < new Date()) {
      this.props.showMessage(
        "La date d'effet doit être supérieure à la date du jour",
        "error",
      );
      if (callback) callback();
      return;
    }
    if (!isDealClosable(this.state.devis)) {
      devis.status = CONTRAT_ENVOYE;
      devis.date_contrat = new Date();
    }
    service
      .updateEmprunteurHorsligne(devis)
      .then(async (res) => {
        //yousign
        if (devis.file_contrat) {
          //yousign
          await sendYousign({
            id: devis.id,
          })
            .then(({ data }) => {
              this.setLoading(false);
              const _updatedData = { ...res.data, yousign_id: data.yousign_id };
              this.setState({ step: STEPS.recap, devis: _updatedData });
              sendContratEmail(_updatedData)
                .then((res) => {
                  this.props.showMessage("Contrat a été envoyé", "success");

                  if (callback) callback();
                })
                .catch((err) => {
                  this.props.showMessage("Echec d'envoyer contrat", "error");
                  if (callback) callback();
                });
            })
            .catch((err) => {
              console.log(err.response.data.error);
              if (callback) callback();
              this.props.showMessage(
                `Erreur: ${err.response.data.error}`,
                "error",
              );
              this.setLoading(false);
            });
        } else {
          this.setLoading(false);
          const _updatedData = { ...res.data };
          this.setState({ step: STEPS.recap, devis: _updatedData });
          sendContratEmail(_updatedData)
            .then((res) => {
              if (callback) callback();
              this.props.showMessage("Contrat a été envoyé", "success");
            })
            .catch((err) => {
              if (callback) callback();
              this.props.showMessage("Echec d'envoyer contrat", "error")
            });
        }
      })
      .catch((err) => {
        console.log(err);
        this.props.showMessage("Erreur inattendu", "error");
        this.setLoading(false);
      });
  };

  validate = (devis) => {
    console.log("validate = ", devis);
    if (!devis.nationality) return "nationalité";
    if (!devis.birth_country) return "pays de naissance";
    if (!devis.birth_city) return "ville de naissance";
    if (!devis.zip) return "code postal";
    if (!devis.professional_status) return "STATUT PROFESSIONNEL";
    if (!devis.risk_profession) return "PROFESSION À RISQUE";

    if (!devis.objet_financement) return "Objet du financement";
    if (!devis.banque) return "nom de votre banque";

    if (devis.emprunteur_type === EMPRUNTEUR_TYPE.co_emprunteur) {
      for (let i = 0; i < devis.emprunteurs.length; i++) {
        const element = devis.emprunteurs[i];

        if (!element.firstname) return "prénom";
        if (!element.lastname) return "nom";
        if (!element.email) return "email";

        if (!element.nationality) return "nationalité";
        if (!element.birth_country) return "pays de naissance";
        if (!element.birth_city) return "ville de naissance";
        if (!element.zip) return "code postal";
        if (!element.professional_status) return "STATUT PROFESSIONNEL";
        if (!element.risk_profession) return "PROFESSION À RISQUE";
      }
    }

    return null;
  };

  submitModification = async () => {
    let devis = this.state.devis;
    devis.frais_dossier = devis.frais_dossier || 0;

    let updatedClient = await updateClient(devis, this.props.showMessage);
    if (!updatedClient) {
      this.props.showMessage("Erreur lors de création/mis à jour de client", "error");
      return;
    }
    // override devis date of birth by client date of birth
    devis.date_of_birth = updatedClient.date_of_birth;
    if (!devis.id) {
      await service
        .createEmprunteurHorsligne(devis)
        .then((res) => {
          this.props.reload();
          this.props.showMessage("Devis a été bien créé", "success");
          this.setLoading(false);
          this.setState({ step: STEPS.recap, devis: res.data });
        })
        .catch((err) => {
          console.log(err.response?.data);
          this.props.showMessage("Erreur inattendu", "error");
          this.setLoading(false);
          devis.client = updatedClient;
        });
    } else {
      await service
        .updateEmprunteurHorsligne(devis)
        .then((res) => {
          this.props.reload();
          this.props.showMessage("Devis a été bien mis à jour", "success");
          this.setLoading(false);
          this.setState({ step: STEPS.recap, devis: {...res.data, need_generate_appel: 0} });
        })
        .catch((err) => {
          console.log(err.response?.data);
          this.props.showMessage("Erreur inattendu", "error");
          this.setLoading(false);
          devis.client = updatedClient;
        });
    }
  };

  openEditPopup = () => {
    this.setState({ selectedDevis: this.state.devis, editMode: true });
  };

  openDeletePopup = () => {
    this.setState({ selectedDevis: this.state.devis, deletePopupOpen: true });
  };

  resetChange = () => {
    this.setState({
      devis: Object.assign({}, this.state.originalDevis),
      step: STEPS.recap,
    });
  };

  sendSimulation = async () => {
    service
      .sendSimulation(this.state.devis)
      .then((res) => {
        this.props.showMessage(
          "La simulation a été envoyée avec succès",
          "success"
        )
        this.setState({ devis: res.data });
      })
      .catch((err) => this.props.showMessage("Erreur inattendu", "error"));
  };

  sendForm = async () => {
    service
      .sendForm(this.state.devis)
      .then((res) => {
        this.props.showMessage("Formulaire a été envoyé", "success");
        this.setState({ devis: res.data });
      })
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer formulaire", "error"),
      );
  };
  sendRelanceSignature = () => {
    service
      .sendRelanceSignature(this.state.devis)
      .then((res) => {
        this.props.showMessage("Email a été envoyé", "success");
        this.setState({ devis: res.data });
      })
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer formulaire", "error"),
      );
  };

  sendRelancePaiement = () => {
    service
      .sendRelancePayment(this.state.devis)
      .then((res) => {
        this.props.showMessage("Email a été envoyé", "success");
        this.setState({ devis: res.data });
      })
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer formulaire", "error"),
      );
  };

  componentDidMount = () => {
    getAllCompanies()
      .then((res) => {
        let _allComs = res.data?.options;
        console.log("_allComs =", _allComs);
        this.setState({ allCompanies: _allComs });
      })
      .catch((err) => console.log("err = ", err));
  };

  sendPresentation = (index) => {
    if (index === 1) {
      sendPresentation(this.state.devis, { offre_pret: true })
        .then((res) => {
          this.props.showMessage("Présentation a été envoyé", "success");
          this.props.reload();
          this.setState({ devis: res.data })
        })
        .catch((err) =>
          this.props.showMessage("Echec d'envoyer présentation", "error")
        );

    } else {
      sendPresentation(this.state.devis)
        .then((res) => {
          this.props.showMessage("Présentation a été envoyé", "success");
          this.props.reload();
          this.setState({ devis: res.data })
        })
        .catch((err) =>
          this.props.showMessage("Echec d'envoyer présentation", "error")
        );
    }
  };

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.loading}>
        <Box pad="small" direction="row" gap="20px" margin="0 0 20px 0">
          <Box>
            <PrimaryButton icon={<CaretLeftFill />} label="Retour" onClick={() => {
              clearContextBot();
              this.props.onClose && this.props.onClose();
            }} />
          </Box>
          {this.state.step === STEPS.recap && <>
            <Box>
              <Heading level="3">
                Trusti Emprunteur
              </Heading>
              <Text>
                ID: {this.state.devis.id}
              </Text>
            </Box>
            <Box alignSelf="end" direction="row">
              <Text>Statut: </Text>
              <Box align="center" direction="row" margin="0 0 0 10px">
                <StatusGoodSmall size="small" color={RecapStatusColor(this.state.devis.status)} /> <Text margin="0 10px 0 0">{this.state.devis.status}</Text>
              </Box>
            </Box>
            <Box alignSelf="end" direction="row">
              <Text>Numéro contrat: </Text>
              <Box align="center" direction="row" margin="0 0 0 10px">
                <Text margin="0 10px 0 0">{this.state.devis.numero_contrat || this.state.devis.contractNumber}</Text>
              </Box>
            </Box>
          </>}
          <Button color="brand" icon={<Update />} onClick={() => {
            this.props.reload();
          }} />
        </Box>
        <Box justify="center" align="center">
          {this.state.step === STEPS.info && (
            <Info
              devis={this.state.devis || {}}
              updateDevis={this.updateDevis}
              onNext={this.submitModification}
              contact={this.state.contacts}
              showMessage={this.props.showMessage}
            />
          )}
          {(this.state.step === STEPS.devis ||
            this.state.step === STEPS.contrat) && (
              <DevisContratForm
                contact={this.state.contact}
                devis={this.state.devis || {}}
                updateDevisOrigin={this.updateDevis}
                allCompanies={this.state.allCompanies}
                showMessage={this.props.showMessage}
                submitModification={this.submitModification}
                isModeContrat={this.state.step === STEPS.contrat}
                sendEmailDevisContrat={
                  this.state.step === STEPS.contrat
                    ? this.sendEmailContrat
                    : this.sendEmailDevis
                }
              />
            )}
          {this.state.step === STEPS.recap && (
            <Recap
              devis={this.state.devis}
              close={this.props.onClose}
              toEditMode={() => this.setState({ step: STEPS.info })}
              sendForm={this.sendForm}
              updateDevis={this.updateDevis}
              onSave={this.submitModification}
              showMessage={this.props.showMessage}
              reload={this.props.reload}
            >
              <Grid columns={["1/3", "1/3", "1/3"]} width="calc(100% - 20px)" gap="small">
                <Box gap="small">
                  <Menu
                    dropProps={{
                      align: { top: 'bottom', left: 'left' },
                    }}
                    items={
                      ["Présentation générique", "Présentation générique + offres de prêts"].map((s, i) => ({
                        label: <Text size="small" color={'#707070'} key={i}>{s}</Text>,
                        onClick: () => this.sendPresentation(i)
                      }))
                    }
                  >
                    <Box direction="row" align="center"
                      justify="center" gap="small">
                      <PrimaryButton
                        label={`Envoyer une présentation`}
                        icon={<Send />}
                      />
                    </Box>
                  </Menu>
                  <PrimaryButton
                    label={`Envoyer simulation`}
                    icon={<Send />}
                    background="#28a745"
                    onClick={this.sendSimulation}
                  />
                  <PrimaryButton
                    label={`Envoyer formulaire`}
                    icon={<Send />}
                    background="#28a745"
                    onClick={this.sendForm}
                  />
                  {!isDealClosable(this.state.devis) &&
                    <PrimaryButton
                      label={`Envoyer un devis`}
                      icon={<DocumentText />}
                      background="#64AEF6"
                      onClick={() => this.setState({ step: STEPS.devis })}
                    />
                  }
                  {([DEVIS_VALIDE, CONTRAT_ENVOYE, CONTRAT_CLOTURE].includes(
                    this.state.devis.status,
                  ) ||
                    (this.state.devis.resiliation_done &&
                      ![DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS].includes(
                        this.state.devis.status,
                      )) ||
                    (this.state.devis.medical_done &&
                      ![
                        EN_ATTENTE_DE_RESILIATION,
                        DEAL_POSSIBLE,
                        DEAL_POSSIBLE_REMPLI,
                        DEVIS,
                      ].includes(this.state.devis.status))) && (
                      <PrimaryButton
                        label={`Envoyer un contrat`}
                        icon={<DocumentVerified />}
                        background="#64AEF6"
                        onClick={() => this.setState({ step: STEPS.contrat })}
                      />
                    )}
                </Box>
                <Box gap="small">

                  <PrimaryButton
                    label={`Modifier`}
                    icon={<Edit />}
                    onClick={() => this.setState({ step: STEPS.info })}
                  />
                  {([EN_ATTENTE_DE_FORMALITE_MEDICALE].includes(
                    this.state.devis.status,
                  ) ||
                    this.state.devis.medical_done) && (
                      <CheckBox
                        style={{
                          alignItems: "center",
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                        justify="center"
                        label="Formalité médicale effectuée"
                        checked={this.state.devis.medical_done}
                        disabled={this.state.devis.medical_done}
                        onChange={(event) => {
                          this.updateDevis("medical_done", event.target.checked);
                          this.submitModification();
                        }}
                      />
                    )}
                  {([EN_ATTENTE_DE_RESILIATION].includes(this.state.devis.status) ||
                    this.state.devis.resiliation_done) && (
                      <CheckBox
                        style={{
                          alignItems: "center",
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                        justify="center"
                        label="Résiliation effectuée"
                        checked={this.state.devis.resiliation_done}
                        disabled={this.state.devis.resiliation_done}
                        onChange={(event) => {
                          this.updateDevis("resiliation_done", event.target.checked);
                          this.submitModification();
                        }}
                      />
                    )}
                  {[DEVIS_VALIDE].includes(this.state.devis.status) &&
                    ![EN_ATTENTE_DE_FORMALITE_MEDICALE].includes(
                      this.state.devis.status,
                    ) && (
                      <PrimaryButton
                        label={EN_ATTENTE_DE_FORMALITE_MEDICALE}
                        icon={<DocumentMissing />}
                        background="#000"
                        onClick={() => {
                          this.updateDevis(
                            "status",
                            EN_ATTENTE_DE_FORMALITE_MEDICALE,
                          );
                          this.updateDevis("date_formalite_medicale", new Date());
                          this.submitModification();
                        }}
                      />
                    )}
                  {([DEVIS_VALIDE].includes(this.state.devis.status) ||
                    this.state.devis.medical_done) &&
                    !this.state.devis.resiliation_done &&
                    ![EN_ATTENTE_DE_RESILIATION].includes(
                      this.state.devis.status,
                    ) && (
                      <PrimaryButton
                        label={`Envoyer vers résiliation`}
                        icon={<DocumentMissing />}
                        background="#000"
                        onClick={() => {
                          this.updateDevis("status", EN_ATTENTE_DE_RESILIATION);
                          this.updateDevis("date_resiliation", new Date());
                          this.submitModification();
                        }}
                      />)}
                  {this.state.devis?.status === PROSPECT && (
                    <PrimaryButton
                      label="Convertir à Deal possible"
                      icon={<Update />}
                      onClick={() => {
                        let devis = this.state.devis;
                        devis.status = DEAL_POSSIBLE;
                        convertToDealPossible(devis, 'emprunteur-horsligne')
                          .then(res => this.setState({ devis: res.data }));
                      }}
                    />
                  )}
                </Box>
                <Box gap="small">
                  <DealStatusChange
                    deal={this.state.devis}
                    showMessage={this.props.showMessage}
                    onReload={this.props.reload}
                    path={'emprunteur-horsligne'}
                  />
                  {this.state.devis?.status !== CONTRAT_CLOTURE && (
                    <DealSubStatusChange
                      deal={this.state.devis}
                      showMessage={this.props.showMessage}
                      onReload={this.props.reload}
                      path={'emprunteur-horsligne'}
                    />
                  )}
                </Box>

              </Grid>
            </Recap>
          )}
          {this.state.step === STEPS.recap &&
            [CONTRAT_ENVOYE, CONTRAT_CLOTURE].includes(
              this.state.devis.status,
            ) && (
              <Box
                direction="row"
                margin="medium"
                gap="medium"
                align="center"
                justify="center"
              >
                <CheckBox
                  style={{
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                  justify="center"
                  label="Contrat payé"
                  checked={this.state.devis.contract_paid}
                  onChange={(event) => {
                    this.updateDevis("contract_paid", event.target.checked);
                    if (event.target.checked) {
                      this.updateDevis("status", CONTRAT_CLOTURE);
                    } else {
                      this.updateDevis("status", CONTRAT_ENVOYE);
                    }
                    this.submitModification();
                  }}
                />
                <PrimaryButton
                  label={`Relance des frais`}
                  icon={<Money />}
                  onClick={() => {
                    this.sendRelancePaiement();
                  }}
                />
                {this.state.devis.date_effet &&
                  new Date(this.state.devis.date_effet) > new Date() && (
                    <Box width={"medium"} gap="small" margin={"small"}>
                      <Line
                        leftLabel="Date d'effet"
                        rightChildren={
                          <DateInput
                            value={this.state.devis.date_effet}
                            onChange={(date) => {
                              this.updateDevis("date_effet", date);
                              this.submitModification();
                              // update stripe date trial
                            }}
                          />
                        }
                      />
                    </Box>
                  )}
              </Box>
            )}
          <Box style={{ height: 100 }} />
        </Box>
      </BlockUi>
    );
  }
}

export default Emprunteur;

import {
    Box,
    Card, Text
} from "grommet";
import React from "react";
import { getCommissionTauxConfig } from "../../services/dashboard";
import DomageOuvrages from "../professionels/domagne_ouvrages";
import EmprunteurHorslignes from "./emprunteur-horslignes/emprunteur-horslignes";
import TrustiHabitations from "./habitations/habitations";
import Pets from "./pets/pets";
import PNOGites from "./pno-gites/pno-gites";
import Santes from "./santes/santes";
import TrustiGLIs from "./trusti-glis/trusti_glis";
import TrustiImmeubles from "./trusti-immeubles";
import TrustiMHTempos from "./trusti-mh-tempos/trusti_mh_tempos";
import TrustiMHs from "./trusti-mhs/trusti_mhs";
import TrustiPNOHorslignes from "./trusti-pno-horslignes/trusti_pno_horslignes";
import TrustiPNOs from "./trusti-pnos/trusti_pnos";
import TrustiMotos from "./trusti_motos/trusti_motos";
import TrustiVoitures from "./trusti_voitures/trusti_voitures";

const TAB = {
    enligne: 'enligne',
    horsligne: 'horsligne'
}

const MENU = {
    online: {
        mh: 'online_mh',
        mh_tempo: 'online_mh_tempo',
        pno: 'online_pno',
        scolaire: 'online_scolaire'
    },
    offline: {
        emprunteur: 'off_emprunteur',
        mh: 'off_mh',
        pno: 'off_pno',
        sante: 'off_sante',
        auto: 'off_auto',
        moto: 'off_moto',
        gite: 'off_gite',
        pet: 'off_pet',
        do: 'pro_do',
        gli: 'gli',
        immeuble: "immeuble"
    }
}

class VenteParticuliers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: props.contact || {},
            commission_taux: undefined,
            tab: TAB.enligne,
            currentProd: MENU.online.mh
        };
    }
    componentDidMount() {
        getCommissionTauxConfig()
            .then(res => {
                this.setState({ commission_taux: res.data });
            })
    }

    isActive = tab => this.state.tab === tab;

    getContent = () => {
        switch (this.state.currentProd) {
            case MENU.online.mh:
                return (
                    <TrustiHabitations
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.online.mh_tempo:
                return (
                    <TrustiMHTempos
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )

            case MENU.online.pno:
                return (
                    <TrustiPNOs
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )


            case MENU.offline.emprunteur:
                return (
                    <EmprunteurHorslignes
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.pno:
                return (
                    <TrustiPNOHorslignes
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.pet:
                return (
                    <Pets
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.sante:
                return (
                    <Santes
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.moto:
                return (
                    <TrustiMotos
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.auto:
                return (
                    <TrustiVoitures
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.mh:
                return (
                    <TrustiMHs
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.gite:
                return (
                    <PNOGites
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.do:
                return (
                    <DomageOuvrages
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.gli:
                return (
                    <TrustiGLIs
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )
            case MENU.offline.immeuble:
                return (
                    <TrustiImmeubles
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
                        commerciaux={this.props.commerciaux}
                    />
                )

            default:
                return null;
        }
    }

    to = prod => this.setState({ currentProd: prod });

    toTab = tab => {
        if (tab === this.state.tab) return;
        let defaultProd;
        if (tab === TAB.enligne) defaultProd = MENU.online.mh;
        if (tab === TAB.horsligne) defaultProd = MENU.offline.emprunteur;
        this.setState({ tab, currentProd: defaultProd })
    }

    render() {
        return (
            <Box width={'full'} justify="center" align="center">
                <Box width={'full'} style={{ height: 40 }} justify="center" align="center" background={'#F8F8F8'}>
                    
                    {this.props.tab === TAB.enligne &&
                        <Box width={'full'} style={{ height: 40 }} justify="center" align="center" direction="row" gap="large">
                            <SubItem
                                onClick={() => this.to(MENU.online.mh)}
                                label='Trusti::Habitation'
                                isActive={this.state.currentProd === MENU.online.mh}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.online.mh_tempo)}
                                label='Trusti::Habitation tempo'
                                isActive={this.state.currentProd === MENU.online.mh_tempo}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.online.pno)}
                                label='Trusti::PNO'
                                isActive={this.state.currentProd === MENU.online.pno}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.online.scolaire)}
                                label='Trusti::Scolaire'
                                isActive={this.state.currentProd === MENU.online.scolaire}
                            />
                        </Box>
                    }
                    {this.props.tab === TAB.horsligne &&
                        <Box width={'full'} style={{ height: 40 }} justify="center" align="center" direction="row" gap="large">
                            <SubItem
                                onClick={() => this.to(MENU.offline.emprunteur)}
                                label='Trusti::Emprunteur'
                                isActive={this.state.currentProd === MENU.offline.emprunteur}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.mh)}
                                label='Trusti::Habitation'
                                isActive={this.state.currentProd === MENU.offline.mh}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.pno)}
                                label='Trusti::PNO'
                                isActive={this.state.currentProd === MENU.offline.pno}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.sante)}
                                label='Trusti::Santé'
                                isActive={this.state.currentProd === MENU.offline.sante}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.auto)}
                                label='Trusti::Auto'
                                isActive={this.state.currentProd === MENU.offline.auto}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.moto)}
                                label='Trusti::Moto'
                                isActive={this.state.currentProd === MENU.offline.moto}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.gite)}
                                label='Trusti::PNO Gites'
                                isActive={this.state.currentProd === MENU.offline.gite}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.pet)}
                                label='Trusti::Santé Chien & Chat'
                                isActive={this.state.currentProd === MENU.offline.pet}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.do)}
                                label='Trusti::Dommage ouvrage'
                                isActive={this.state.currentProd === MENU.offline.do}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.gli)}
                                label='Trusti::GLI'
                                isActive={this.state.currentProd === MENU.offline.gli}
                            />
                            <SubItem
                                onClick={() => this.to(MENU.offline.immeuble)}
                                label='Trusti::Immeuble'
                                isActive={this.state.currentProd === MENU.offline.immeuble}
                            />
                        </Box>
                    }
                </Box>
                <Box style={{ height: 40 }} />
                {this.getContent()}
            </Box>
        )
    }
}

const SubItem = ({ label, onClick, isActive }) => (
    <Box onClick={onClick} style={{ boxShadow: 'none' }}>
        <Text textAlign="center" size="small" color={'#707070'} style={{ fontWeight: isActive ? 'bold' : 'normal' }}>
            {label.split('::').map(s => <span>{s}<br /></span>)}
        </Text>
    </Box>
)

export default VenteParticuliers;
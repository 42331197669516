import { Box, Card } from 'grommet';
import { DocumentDownload, DocumentText, DocumentVerified, Edit, Send, Update } from 'grommet-icons';
import React from "react";
import { convertToDealPossible } from '../../../../services/common_service';
import { CONTRAT_CLOTURE, CONTRAT_ENVOYE, DEAL_POSSIBLE, DEVIS_VALIDE, DEAL_POSSIBLE_REMPLI, DEVIS, PROSPECT } from '../../../../services/devis_contrat_status';
import { getSimpleDateFR } from '../../../../services/utils';
import ContactRecap from '../../../contacts/contact_select_v2/recap';
import ApporteurGestionnaire from '../../../elements/apporteur_gestionaire';
import ApporteurModification from '../../../elements/apporteur_gestionaire/modification';
import DateRecap from '../../../elements/date_recap';
import DealStatusChange from '../../../elements/deal_status_change';
import EmailEventTracker from '../../../elements/email_tracker';
import { IdDisplay } from '../../../elements/line_container';
import PrimaryButton from '../../../elements/primary_button';
import StandardText from '../../../elements/standard_text';
import SectionDivider from '../../../voyages/components/session_devider';
import TrustiBot from '../../../trustibot';
import StatusDateAppel from '../../../elements/status_date_appel';
import RecapStatus from '../../../elements/devis_status';
import PanelAppel from '../../../elements/panel_appel';

const Recap = ({ deal, onEdit, showMessage, toDevis, toContrat, onDealReload, reload, onSendForm,
    updateDevis,
    onSave
}) => {

    return (
        <Box
            direction="row"
            width={'full'}
            gap='xsmall'
            style={{ width: "100vw" }}
        >
            <TrustiBot></TrustiBot>
            <Box
                gap="xsmall"
                align="center"
                alignSelf="center" width={'full'}
            >
                <Box direction="row" fill="horizontal" justify="center" alignContent="center">
                    <Box align="center">
                        <StatusDateAppel devis={deal} />
                    </Box>
                    <Box>
                        <Box alignSelf="end">
                            <RecapStatus status={deal.status} />
                            <PanelAppel devis={deal} updateDevis={updateDevis} onSave={onSave} />
                        </Box>
                    </Box>
                </Box>
                <Box
                    width={'large'}
                    justify="center"
                    alignContent="center"
                    align="center"
                    gap="medium"
                >
                    <IdDisplay id={deal.id} />

                    <Box gap="small" width={'full'}>
                        <DateRecap deal={deal} />
                    </Box>
                    <ContactRecap
                        contact={deal.client}
                    />

                    <DealRecap
                        deal={deal}
                    />

                </Box>
                {(deal.apporteur || deal.affiliation) && <Card pad={"small"} width={'xlarge'} >
                    <ApporteurGestionnaire apporteur={deal.apporteur} affiliation={deal.affiliation} source={deal.source} />
                </Card>}
                <Box width={'xlarge'}>
                    <EmailEventTracker messageIds={deal.emailEventIds} />
                </Box>


                <ApporteurModification
                    devis={deal}
                    apporteur={deal.apporteur}
                    showMessage={showMessage}
                    onReload={reload}
                />


                <Box direction='row' gap='medium' pad={'medium'}>
                    <PrimaryButton icon={<Edit />} label='Modifier' onClick={onEdit} />
                    {[DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS].includes(deal.status) &&
                        <PrimaryButton icon={<Send />} label='Envoyer formulaire' background={'#70A4EC'} onClick={onSendForm} />
                    }
                    {[DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS, DEVIS_VALIDE].includes(deal.status) &&
                        <PrimaryButton icon={<DocumentText />} label='Envoyer devis' background={'#70A4EC'} onClick={toDevis} />
                    }
                    {[DEVIS_VALIDE, CONTRAT_ENVOYE, CONTRAT_CLOTURE].includes(deal.status) &&
                        <PrimaryButton
                            label={`Envoyer un contrat`}
                            icon={<DocumentVerified />}
                            background="#64AEF6"
                            onClick={toContrat}
                        />
                    }
                    {deal.status === PROSPECT &&
                        <PrimaryButton
                            icon={<Update />}
                            onClick={() => {
                                deal.status = DEAL_POSSIBLE;
                                convertToDealPossible(deal, 'trusti-immeuble')
                                    .then(res => onDealReload(res.data));
                            }}
                            label="Convertir à Deal possible"
                        />
                    }
                    <DealStatusChange
                        deal={deal}
                        showMessage={showMessage}
                        onReload={reload}
                        path={'trusti-immeuble'}
                    />
                </Box>
            </Box>
        </Box>
    )
}

const DealRecap = ({ deal }) => {

    return (
        <Box
            width={'large'}
            justify="center"
            alignContent="center"
            align="center"
            gap="medium"
            pad={'xsmall'}
        >
            <Box >
                <SectionDivider title={'Informations souscripteur'} />
            </Box>
            <Box gap="xsmall" align="center" width={'full'} pad='xsmall'>

                <LineDisplay
                    label={"Le souscripteur est"}
                    value={`${deal.souscripteur_type}`}
                />
                {deal.souscripteur_type === "Un syndic professionnel" ||
                    deal.souscripteur_type === "Une société" ||
                    deal.souscripteur_type === "Un administrateur de bien" ?
                    <LineDisplay
                        label={"Raison social"}
                        value={`${deal.souscripteur_raison_social}`}
                    /> : null
                }
                <LineDisplay
                    label={"Civilité"}
                    value={`${deal.souscripteur_civilite}`}
                />
                <LineDisplay
                    label={"Prénom"}
                    value={`${deal.souscripteur_prenom}`}
                />
                <LineDisplay
                    label={"Nom"}
                    value={`${deal.souscripteur_nom}`}
                />
            </Box>
            <Box >
                <SectionDivider title={'Informations assuré'} />
            </Box>
            <Box gap="xsmall" align="center" width={'full'} pad='xsmall'>
                <LineDisplay
                    label={"La qualité de l’assuré"}
                    value={deal.qualite_assure}
                />
                <LineDisplay
                    label={"Quel est le nom de la copropriété"}
                    value={`${deal.copropriete_nom}`}
                />
                <LineDisplay
                    label={"Quelle est l'adresse de la copropriété "}
                    value={`${deal.address}, ${deal.zip}, ${deal.city}`}
                />
            </Box>
            <Box >
                <SectionDivider title={'Informations du risque'} />
            </Box>
            <Box gap="xsmall" align="center" width={'full'} pad='xsmall'>
                <LineDisplay
                    label={"Quel est l’usage global de la copropriété"}
                    value={deal.usage_global_copropriete}
                />
                <LineDisplay
                    label={"Quand le bâtiment a-t-il été construit"}
                    value={deal.quand_construit}
                />
                <LineDisplay
                    label={"Superficie totale développée"}
                    value={`${deal.surface_total} m2`}
                />
                <LineDisplay
                    label={"Dont surface commerciale"}
                    value={`${deal.surface_commerciale} m2`}
                />
                <LineDisplay
                    label={"Dont surface pour les bureaux"}
                    value={`${deal.surface_bureaux} m2`}
                />
                <LineDisplay
                    label={"De quel type de copropriété s’agit-il"}
                    value={deal.type_copropriete}
                />
                <LineDisplay
                    label={"La copropriété est-elle occupée"}
                    value={deal.copropriete_occupe_par}
                />
                <LineDisplay
                    label={"Quelle est la nature des activités commerciales"}
                    value={deal.activite_commerciale}
                />
                <LineDisplay
                    label={"Y a-t-il des activités aggravantes dans la copropriété et/ou aux abords immédiats (à moins de 10 m) de la copropriété assurée "}
                    value={deal.activite_aggravante ? 'Oui' : deal.activite_aggravante === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"La copropriété est-elle située à moins de 5 km d’un risque majeur"}
                    value={deal.moins_5km ? 'Oui' : deal.moins_5km === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Comment qualifiez-vous la catégorie de la copropriété"}
                    value={deal.categorie_copropriete}
                />
                <LineDisplay
                    label={"La copropriété ou des parties de la copropriété sont-elles classées monument historique ou à l’inventaire supplémentaire"}
                    value={deal.monument_historique ? 'Oui' : deal.monument_historique === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"La copropriété se situe-t-elle dans une Zone Industrielle"}
                    value={deal.zone_industrielle ? 'Oui' : deal.zone_industrielle === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"La copropriété se situe-t-elle dans une Zone d’Activité Commerciale"}
                    value={deal.zone_commerciale ? 'Oui' : deal.zone_commerciale === false ? 'Non' : 'Inconnu'}
                />
            </Box>
            <Box >
                <SectionDivider title={'Description de la copropriété'} />
            </Box>
            <Box gap="xsmall" align="center" width={'full'} pad='xsmall'>
                <LineDisplay
                    label={"De combien de bâtiments est composée la copropriété"}
                    value={`${deal.nb_batiment_copropriete}`}
                />
                <LineDisplay
                    label={"Combien de niveaux composent l’immeuble, en comptant le rez-de-chaussée"}
                    value={`${deal.nb_niveau}`}
                />
                <LineDisplay
                    label={"Combien de sous-sols composent l’immeuble"}
                    value={`${deal.nb_sous_sol}`}
                />

                <LineDisplay
                    label={"Comment qualifiez-vous la catégorie de la copropriété"}
                    value={`${deal.categorie_confort_copropriete}`}
                />
                <LineDisplay
                    label={"Y a-t-il un gardien dans la copropriété"}
                    value={`${deal.gardien}`}
                />
                <LineDisplay
                    label={"Certains bâtiments sont-ils sous la même toiture"}
                    value={deal.meme_toiture ? 'Oui' : deal.meme_toiture === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Certains bâtiments sont-ils distants de moins de 10 mètres"}
                    value={deal.moins_10m ? 'Oui' : deal.moins_10m === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Certains bâtiments sont-ils communicants (y compris par les sous-sols)"}
                    value={deal.communicants ? 'Oui' : deal.communicants === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Certains bâtiments font-ils partie d’un groupe d’immeuble communicants de plus de 20 000 m²"}
                    value={deal.communicants_plus_20km ? 'Oui' : deal.communicants_plus_20km === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Y a-t-il un contrat d’entretien en cours pour la copropriété et ses équipements"}
                    value={deal.entretien_en_cours ? 'Oui' : deal.entretien_en_cours === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Quel est le type de matériaux utilisés sur l’ensemble de l’immeuble"}
                    value={`${deal.type_materiaux}`}
                />
                <LineDisplay
                    label={"De quel type d'énergie' s’agit-il"}
                    value={`${deal.type_energie}`}
                />
                <LineDisplay
                    label={"Quel est le type des toitures"}
                    value={`${deal.type_toiture}`}
                />
                <LineDisplay
                    label={"Quel est le revêtement du sol "}
                    value={`${deal.revetement_sol}`}
                />
                <LineDisplay
                    label={"La copropriété comporte-t-elle"}
                    value={`${deal.copropriete_comporte}`}
                />
                <LineDisplay
                    label={"Y a-t-il une alarme incendie"}
                    value={deal.alarme_incendie ? 'Oui' : deal.alarme_incendie === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Y a-t-il des extincteurs"}
                    value={deal.extincteurs ? 'Oui' : deal.extincteurs === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Y a-t-il des détecteurs de fumée"}
                    value={deal.detecteur_fumee ? 'Oui' : deal.detecteur_fumee === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Y a-t-il une télésurveillance contre le vol"}
                    value={deal.telesurveillance ? 'Oui' : deal.telesurveillance === false ? 'Non' : 'Inconnu'}
                />
                <LineDisplay
                    label={"Date d’effet souhaité"}
                    value={getSimpleDateFR(deal.date_effet)}
                />
            </Box>
            <Box >
                <SectionDivider title={'Document'} />
            </Box>
            <Box width="xlarge" direction="row" gap="small" pad='xsmall'>
                <Card style={{ width: "100%" }} pad="medium">
                    {deal.file_rib && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${deal.file_rib}`}
                        >
                            <DocumentDownload />
                            RIB
                        </a>
                    )}
                    {deal.file_kbis && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${deal.file_kbis}`}
                        >
                            <DocumentDownload />
                            KBIS souscripteur
                        </a>
                    )}
                    {deal.file_releve_sinistralite && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${deal.file_releve_sinistralite}`}
                        >
                            <DocumentDownload />
                            Relevé de sinistralité
                        </a>
                    )}
                    {deal.file_others ? (
                        deal.file_others.map((e, _idx) => {
                            return (
                                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                                    <DocumentDownload />
                                    {e && e.split("/").pop()}
                                </a>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </Card>
            </Box>
        </Box>
    )
}

const LineDisplay = ({ label, value }) => (
    <Box gap="xxsmall" direction='row' width={'full'}>
        <StandardText size={'small'} label={label + ' : '} />
        <StandardText size={'small'} style={{ fontWeight: 'bold' }} label={value} />
    </Box>
)

export default Recap;
import { Box, Button, Card, Layer } from "grommet";
import { Checkmark, Close, Previous } from "grommet-icons";
import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import {
    changeCommercialOwner,
  createContact,
  updateContact,
} from "../../../../services/contact_service";
import PrimaryButton from "../../../elements/primary_button";
import ContactModal from "../../contact_modal/contact_modal";
import {
  createEntreprise,
  getEntrepriseDeals,
  updateEntreprise,
} from "../service";
import Info from "./info";
import Recap from "./recap";
import {
  CONTRAT_CLOTURE,
  CONTRAT_ENVOYE,
  DEVIS,
  DEVIS_VALIDE,
  EN_ATTENTE_BANQUE,
} from "../../../../services/devis_contrat_status";
import StandardText from "../../../elements/standard_text";
import CustomSelect from "../../../elements/select_fill";
import { getUsers } from "../../../../services/dashboard";

const STEPS = {
  info: "info",
  recap: "recap",
};

class Entreprise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: props.entreprise.id ? STEPS.recap : STEPS.info,
      loading: false,
      entreprise: props.entreprise || {},
      selectedContact: undefined,
      contrat_deals: [],
      devis_deals: [],
      commerciaux: [],
    };
  }

  componentDidMount = () => {
    if (this.state.entreprise.id) {
      getEntrepriseDeals(this.state.entreprise.id).then((res) => {
        const contrat_deals = res.data.filter((d) =>
          [CONTRAT_CLOTURE, EN_ATTENTE_BANQUE].includes(d.status)
        );
        const devis_deals = res.data.filter((d) =>
          [DEVIS, DEVIS_VALIDE, CONTRAT_ENVOYE].includes(d.status)
        );
        console.log(contrat_deals, devis_deals);
        this.setState({ contrat_deals, devis_deals });
      });
    }
    getUsers()
      .then((res) => this.setState({ commerciaux: res.data }))
      .catch((err) => console.log(err));
  };

  submit = async () => {
    let entreprise = this.state.entreprise;
    let interlocuteurs = entreprise.interlocuteurs || [];

    let tmp = interlocuteurs.map((s) => s);
    let interlocuteurIds = [];
    let receivingEmail = "";
    for (let i = 0; i < interlocuteurs.length; i++) {
      let inter = interlocuteurs[i];
      if (inter.id) {
        interlocuteurIds.push(inter.id);
        receivingEmail =
          receivingEmail || (inter.receiving_mail ? inter.email : "");
        await updateContact(inter);
      } else {
        let res = await createContact(inter);
        receivingEmail =
          receivingEmail || (res.data.receiving_mail ? res.data.email : "");
        interlocuteurIds.push(res.data.id);
      }
    }

    if (!receivingEmail) {
      this.props.showMessage(
        "Veuillez sélectionner un interlocuteur pour recevoir des mails",
        "error"
      );
      return;
    }

    entreprise.interlocuteurs = interlocuteurIds;

    let handler = entreprise.id ? updateEntreprise : createEntreprise;
    this.setState({ loading: true });
    handler(entreprise)
      .then((res) => {
        this.props.showMessage(
          "Entreprise créée/modifié avec succès",
          "success"
        );
        console.log(res.data);
        this.setState({
          entreprise: res.data,
          step: STEPS.recap,
          loading: false,
        });
      })
      .catch((err) => {
        let body = err.response.data || {};
        let message =
          body.err === "USER_EXISTING"
            ? "Un utilisateur existant avec email " + receivingEmail
            : body.err === "ACCOUNT_WITH_SAME_EMAIL_EXISTING"
            ? `Un souscription en direct compte existant avec email ${receivingEmail}`
            : body.err === "EMAIL_NOT_VALID"
            ? "Email non valid"
            : "Erreur inattendu";
        this.props.showMessage(message, "error");
        entreprise.interlocuteurs = tmp;
        console.log(err);
        this.setState({ loading: false });
      });
  };

  onEntrepriseChange = (field, value) => {
    let entreprise = this.state.entreprise || {};
    entreprise[field] = value;
    this.setState({ entreprise: Object.assign({}, entreprise) });
  };

  changeOwner = (newCommercial) => {
    this.setState({loading: true});
    changeCommercialOwner(newCommercial.id, '', this.state.entreprise.id)
        .then(res => {
            this.setState({entreprise: {...this.state.entreprise, commercial: newCommercial}, loading: false})
            this.props.showMessage(`Il y a ${res.data.total} deals qui sont remplacés par le nouveau commercial`, 'success');
        })
        .catch(err => {
            console.log(err);
            this.setState({loading: false});
            this.props.showMessage('Erreur inattendu', 'error');
        });
  };

  render() {
    return this.state.selectedContact ? (
      <Layer
        full="vertical"
        onEsc={() => this.setState({ selectedContact: null })}
        style={{ width: "90%" }}
      >
        <Box fill>
          <Box flex overflow={"auto"}>
            <ContactModal
              selectedContact={this.state.selectedContact}
              creationMode={false}
              showMessage={this.props.showMessage}
              onClose={() => this.setState({ selectedContact: null })}
              refresh={this.loadContacts}
            />
          </Box>
        </Box>

        <Button
          plain={true}
          icon={<Close />}
          onClick={() => this.setState({ selectedContact: null })}
          style={{ position: "absolute", top: 5, right: 5 }}
        />
      </Layer>
    ) : (
      <BlockUi tag="div" blocking={this.state.loading}>
        <Box pad="small" width="xsmall">
          <PrimaryButton
            icon={<Previous />}
            onClick={() => {
              this.props.onClose && this.props.onClose();
            }}
          />
        </Box>
        <Box width={"full"} align="center" justify="center">
          <ChangeOwner
            commercial={this.state.entreprise.commercial}
            changeOwner={(newCommercial) => this.changeOwner(newCommercial)}
            commerciaux={this.state.commerciaux}
          />
        </Box>
        <Box width={"full"} justify="center" align="center">
          <Box width={"xlarge"} justify="center" align="center">
            <Box width={"xlarge"} justify="center" align="center" gap="small">
              {this.state.step === STEPS.info && (
                <Info
                  entreprise={this.state.entreprise}
                  onEntrepriseChange={this.onEntrepriseChange}
                  toRecap={() => this.setState({ step: STEPS.recap })}
                  showMessage={this.props.showMessage}
                  submit={this.submit}
                />
              )}
              {this.state.step === STEPS.recap && (
                <Recap
                  entreprise={this.state.entreprise}
                  toEdit={() => this.setState({ step: STEPS.info })}
                  onClose={() => {
                    this.props.onClose && this.props.onClose();
                  }}
                  onSelect={(contact) =>
                    this.setState({ selectedContact: contact })
                  }
                  showMessage={this.props.showMessage}
                  contrat_deals={this.state.contrat_deals}
                  devis_deals={this.state.devis_deals}
                  showDeal={this.props.showDeal}
                />
              )}
            </Box>
          </Box>
        </Box>
      </BlockUi>
    );
  }
}

const ChangeOwner = ({ commercial, commerciaux, changeOwner }) => {
  const [newCommercial, setNewCommercial] = useState(commercial);
  useEffect(() => {
    if(commercial && !commercial.name) {
        commercial.name = `${commercial.prenom || ""} ${commercial.nom || ""}`;
        setNewCommercial({...commercial});
    }
  }, [commercial])
  return (
    <Card width={"large"} pad="small">
      <Box direction="row" gap="medium" justify="between">
        <Box>
          <StandardText label={"Propriétaire du contact"} />
        </Box>
        <Box width={"full"}>
          <CustomSelect
            placeholder={"Commercial"}
            options={commerciaux}
            onChange={({ option }) => setNewCommercial(option)}
            labelKey={"name"}
            valueKey={{ key: "name", reduce: true }}
            value={commercial?.name}
          />
        </Box>
        <PrimaryButton
          label={"Valider"}
          icon={<Checkmark />}
          onClick={() => changeOwner(newCommercial)}
        />
      </Box>
    </Card>
  );
};

export default Entreprise;

import { Box, Tab, Tabs } from "grommet";
import React from "react";
import ConventionVoyage from "../outils/convention_voyage";
import FactureVoyage from "../outils/facture_voyage";
import Agences from "./agences";
import Comptabilite from "./comptabilites";
import Sinistres from "./sinistres";
import Subscriptions from "./souscriptions";
import LogsApiProposition from "./logs_api";
import VoyageConfigs from "./configs";

class Voyages extends React.Component {

  componentDidMount() {
  }

  render() {
    return (
      <Box
        direction="row"
        alignContent="between"
        style={{ backgroundColor: "#F8F8F8", height: 50, width: "100%" }}
      >
        <Box alignContent="center" justify="center" style={{ width: "100%" }}>
          <Tabs>
            <Tab
              title="Souscriptions"
            >
              <Subscriptions showMessage={this.props.showMessage} />
            </Tab>
            <Tab
              title="Agences"
            >
              <Agences showMessage={this.props.showMessage} />
            </Tab>
            <Tab
              title="Sinistres"
            >
              <Sinistres showMessage={this.props.showMessage} />
            </Tab>
            <Tab
              title="Comptabilité"
            >
              <Comptabilite showMessage={this.props.showMessage} />
            </Tab>
            <Tab
              title="Convention"
            >
              <ConventionVoyage showMessage={this.props.showMessage} />
            </Tab>

            <Tab
              title="Facture"
            >
              <FactureVoyage showMessage={this.props.showMessage} />
            </Tab>
            <Tab
              title="Logs API d'email proposition"
            >
              <LogsApiProposition showMessage={this.props.showMessage} />
            </Tab>
            <Tab
              title="Configuration"
            >
              <VoyageConfigs showMessage={this.props.showMessage} />
            </Tab>
          </Tabs>
        </Box>
      </Box>
    );
  }
}

export default Voyages;

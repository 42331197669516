import { Box, Button, Card, DataTable, Heading, Text } from 'grommet';
import { Clear, Close, StatusGoodSmall, Trash, View } from 'grommet-icons';
import React from "react";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { getSimpleDateFR } from '../../../services/utils';
import { AddEntity } from '../../elements/add_devis';
import CustomPagination from '../../elements/custom_pagination';
import PopupConfirmation from '../../elements/popup_confirmation';
import CustomSelect from '../../elements/select_fill';
import Search from '../../voyages/components/search';
import { countApporteurPros, deleteApporteurPro, getApporteurPros } from "../service";
import ApporteurPro from "./apporteur";
import { getUsers } from '../../../services/dashboard';

const ventCanals = [
    "Lien personnalisé",
    "API",
    "Pont",
    "App"
]

class ApporteurPros extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            apporteurs: [],
            selectedApporteur: undefined,
            blocking: false,
            users: [],
            filter: {},
            startIndex: 0,
            query: undefined,
            sort: 'raisonSocial:asc',
            deletePopup: false,
            total: 0
        }
    }

    componentDidMount = () => {
        getApporteurPros(this.state.startIndex, this.state.query, this.state.filter, this.state.sort, this.props.mother)
            .then(res => this.setState({ apporteurs: res.data }))
            .catch(err => console.log(err));
        getUsers().then(res => this.setState({ users: res.data }))
    }
    loadApporteurs = () => {
        this.setState({ apporteurs: [], startIndex: 0 });
        getApporteurPros(this.state.startIndex, this.state.query, this.state.filter, this.state.sort, this.props.mother)
            .then(res => this.setState({ apporteurs: res.data }));
        countApporteurPros(this.state.query, this.state.filter)
            .then(res => this.setState({ total: res.data }));
    }

    addApporteur = () => {
        this.setState({ selectedApporteur: { company_mother: this.props.mother || false, taux_commission: this.props.mother ? 0.1 : 0.15 }, popupOpen: true })
    }

    open = (apporteur) => {
        this.setState({ selectedApporteur: apporteur, popupOpen: true })
    }

    onNextPage = ({ startIndex }) => {
        this.setState({ startIndex }, () => {
            getApporteurPros(this.state.startIndex, this.state.query, this.state.filter, this.state.sort, this.props.mother)
                .then(res => this.setState({ apporteurs: res.data }));
            countApporteurPros(this.state.query)
                .then(res => this.setState({ total: res.data }));
        });
    }

    deleteApporteur = () => {
        deleteApporteurPro(this.state.apporteurToDetele.id)
            .then(res => {
                this.setState({ deletePopup: false, apporteurToDetele: undefined });
                this.props.showMessage("L'apporteur a été supprimé", 'success');
                this.loadApporteurs();
            })
            .catch(err => console.log(err));
    }

    statusColor = (status) => {
        switch (status) {
            case "Deal possible":
                return "#73A6EC";
            case "Signature de convention":
                return "#FF6E6E";
            case "Apporteur confirmé sans résultat":
                return "#FFB100";
            case "Apporteur confirmé avec résultat":
                return "#33BC00";
            default:
                return "gray";
        }
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.blocking}>
                <Box
                    pad={'small'}
                    justify='center' align="center"
                    fill
                >
                    {
                        this.state.selectedApporteur ?
                            <ApporteurPro
                                apporteur={this.state.selectedApporteur}
                                showMessage={this.props.showMessage}
                                onBlock={val => this.setState({ blocking: val })}
                                onClose={() => this.setState({ selectedApporteur: undefined })}
                                reload={this.loadApporteurs}
                                commerciaux={this.props.commerciaux}
                            /> :
                            <Box justify="center" align="center" width={'full'} gap='small'>
                                <Box style={{ width: "100%", minHeight: 'auto' }} gap="medium" pad={"medium"} justify='center' align='center'>
                                    <Heading level={3} textAlign='center'>
                                        <b>{this.props.mother ? "Dashboard Maison Mère" : "Dashboard Apporteurs"}</b>
                                    </Heading>
                                    <Box direction='row' justify="between" alignSelf="center" align="center" gap="medium" width={'full'}>
                                        <Search size={'small'} search={query => this.setState({ query }, this.loadApporteurs)} />
                                        <Card pad={'small'} gap='small'>
                                            <Box direction='row' justify="between" alignSelf="center" align="center" gap="medium" width={'full'}>
                                                <CustomSelect
                                                    options={this.state.users.map(s => s.name)}
                                                    placeholder='Propriétaire'
                                                    value={this.state.filter.apporteur_lead ? this.state.users.find(s => s.id === this.state.filter.apporteur_lead)?.name : null}
                                                    onChange={({ option }) => {
                                                        let filter = this.state.filter;
                                                        filter.apporteur_lead = this.state.users.find(s => s.name === option).id;
                                                        this.setState({ filter: Object.assign({}, filter) }, () => this.loadApporteurs());
                                                    }}
                                                />
                                                <CustomSelect
                                                    options={ventCanals}
                                                    placeholder='Canal de vente'
                                                    value={this.state.filter.ventCanal}
                                                    onChange={({ option }) => {
                                                        let filter = this.state.filter;
                                                        filter.ventCanal = option;
                                                        this.setState({ filter: Object.assign({}, filter) }, () => this.loadApporteurs());
                                                    }}
                                                />
                                                <CustomSelect
                                                    options={["Prospect",
                                                        "Client",
                                                        "Deal possible",
                                                        "Signature de convention",
                                                        "Apporteur confirmé sans résultat",
                                                        "Apporteur confirmé avec résultat"]}
                                                    placeholder='Statut'
                                                    value={this.state.filter.status}
                                                    onChange={({ option }) => {
                                                        let filter = this.state.filter;
                                                        filter.status = option;
                                                        this.setState({ filter: Object.assign({}, filter) }, () => this.loadApporteurs());
                                                    }}
                                                    clear
                                                />
                                                <CustomSelect
                                                    options={['Petit volume', 'Moyen volume', 'Gros volume', "Vide"]}
                                                    placeholder='Volume affaire'
                                                    value={this.state.filter.affaireVolume}
                                                    onChange={({ option }) => {
                                                        let filter = this.state.filter;
                                                        filter.affaireVolume = option;
                                                        this.setState({ filter: Object.assign({}, filter) }, () => this.loadApporteurs());
                                                    }}
                                                />
                                                {Object.keys(this.state.filter).length > 0 &&
                                                    <Box onClick={() => this.setState({ filter: {} }, this.loadApporteurs)}>
                                                        <Clear color="brand" />
                                                    </Box>
                                                }

                                            </Box>
                                        </Card>
                                        <AddEntity onAdd={this.addApporteur} title={this.props.mother ? "Créer une maison mère" : 'Créer un apporteur'} />
                                    </Box>
                                    <Box style={{ width: "100%" }}>
                                        <CustomPagination total={this.state.total} onNextPage={this.onNextPage} startIndex={this.state.startIndex} />
                                    </Box>
                                    <DataTable
                                        columns={this.props.mother ? [
                                            {
                                                property: 'createdAt',
                                                header: <Text size="small"><strong>Création</strong>/<br />Modification</Text>,
                                                render: apporteur => <Text key={`${apporteur.id}_creation`} size="xsmall" ><strong>{getSimpleDateFR(apporteur.createdAt)}</strong><br />{getSimpleDateFR(apporteur.updatedAt)}</Text>
                                            },
                                            {
                                                property: 'raison_social',
                                                header: <Text size="small">Apporteur</Text>,
                                                render: apporteur => <Text key={`${apporteur.id}_${apporteur.raisonSocial}`} size="small">{apporteur.raisonSocial}</Text>
                                            },
                                            {
                                                property: 'apporteur_lead',
                                                header: <Text size="small">Propriétaire</Text>,
                                                render: apporteur => <Text key={`${apporteur.id}_commercial_${apporteur.apporteur_lead?.prenom}`} size="small">{apporteur.apporteur_lead?.prenom || ""} {apporteur.apporteur_lead?.nom || ""}</Text>
                                            },
                                            {
                                                property: 'status',
                                                header: <Box style={{ minHeight: 'auto' }}><Text size="small">Statut</Text></Box>,
                                                render: apporteur => <Marker key={`${apporteur.id}_status_${apporteur.status}`} text={apporteur.status} color={this.statusColor(apporteur.status)} />,
                                            },
                                            {
                                                // property: 'total_deals',
                                                header: <Text size="small">Deal reçu</Text>,
                                                render: apporteur => <Text key={`${apporteur.id}_deal_${apporteur.total_deals}`} size="small">{apporteur.total_deals || "-"}</Text>
                                            },
                                            {
                                                // property: 'total_contrats',
                                                header: <Text size="small">Nb contrats</Text>,
                                                render: apporteur => <Text key={`${apporteur.id}_contract_${apporteur.total_contrats}`} size="small">{apporteur.total_contrats || "-"}</Text>
                                            },
                                            {
                                                header: <Text size="small">Méthode de vente</Text>,
                                                render: apporteur => <VenteModeTextes key={`${apporteur.id}_ventCanals`} canals={apporteur.ventCanals} />
                                            },
                                            {
                                                header: '',
                                                render: apporteur => <Box key={`${apporteur.id}_actions`} direction='row' gap="medium">
                                                    <View color='brand' onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.open(apporteur);
                                                    }} />
                                                    <Trash color='brand' onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.setState({ deletePopup: true, apporteurToDetele: apporteur });
                                                    }} />
                                                </Box>
                                            }
                                        ] : [
                                            {
                                                property: 'createdAt',
                                                header: <Text size="small"><strong>Création</strong>/<br />Modification</Text>,
                                                render: apporteur => <Text key={`${apporteur.id}_creation`} size="xsmall" ><strong>{getSimpleDateFR(apporteur.createdAt)}</strong><br />{getSimpleDateFR(apporteur.updatedAt)}</Text>
                                            },
                                            {
                                                property: 'raison_social',
                                                header: <Text size="small">Apporteur</Text>,
                                                render: apporteur => <Text key={`${apporteur.id}_${apporteur.raisonSocial}`} size="small">{apporteur.raisonSocial}</Text>
                                            },
                                            {
                                                property: 'apporteur_lead',
                                                header: <Text size="small">Propriétaire</Text>,
                                                render: apporteur => <Text key={`${apporteur.id}_commercial_${apporteur.apporteur_lead?.prenom}`} size="small">{apporteur.apporteur_lead?.prenom || ""} {apporteur.apporteur_lead?.nom || ""}</Text>
                                            },
                                            {
                                                property: 'status',
                                                header: <Box style={{ minHeight: 'auto' }}><Text size="small">Statut</Text></Box>,
                                                render: apporteur => <Marker key={`${apporteur.id}_status_${apporteur.status}`} text={apporteur.status} color={this.statusColor(apporteur.status)} />,
                                            },
                                            {
                                                // property: 'total_deals',
                                                header: <Text size="small">Deal reçu</Text>,
                                                render: apporteur => <Text key={`${apporteur.id}_deal_${apporteur.total_deals}`} size="small">{apporteur.total_deals || "-"}</Text>
                                            },
                                            {
                                                // property: 'total_contrats',
                                                header: <Text size="small">Nb contrats</Text>,
                                                render: apporteur => <Text key={`${apporteur.id}_contract_${apporteur.total_contrats}`} size="small">{apporteur.total_contrats || "-"}</Text>
                                            },
                                            {
                                                property: 'mode_remuneration',
                                                header: <Text size="small">Mode rémunération</Text>,
                                                render: apporteur => <Text key={`${apporteur.id}_mode_remuneration`} size="small">{apporteur.mode_remuneration || ""}</Text>
                                            },
                                            {
                                                property: 'apporteur_parent',
                                                header: <Text size="small">Maison mère</Text>,
                                                render: apporteur => <Text key={`${apporteur.id}_apporteur_parent`} size="small">{apporteur.apporteur_parent?.raisonSocial || ""}</Text>
                                            },

                                            {
                                                header: <Text size="small">Méthode de vente</Text>,
                                                render: apporteur => <VenteModeTextes key={`${apporteur.id}_ventCanals`} canals={apporteur.ventCanals} />
                                            },
                                            {
                                                header: '',
                                                render: apporteur => <Box key={`${apporteur.id}_actions`} direction='row' gap="medium">
                                                    <View color='brand' onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.open(apporteur);
                                                    }} />
                                                    <Trash color='brand' onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.setState({ deletePopup: true, apporteurToDetele: apporteur });
                                                    }} />
                                                </Box>
                                            }
                                        ]}
                                        sort={{ property: 'statut', direction: 'desc' }}
                                        onClickRow={event => this.open(event.datum)}
                                        onSort={optionSort => {
                                            this.setState({ sort: `${optionSort.property}:${optionSort.direction}` }, this.loadApporteurs);
                                        }}
                                        data={this.state.apporteurs}
                                    // style={{ minHeight: 'auto' }}
                                    />
                                </Box >
                            </Box>
                    }
                    {this.state.deletePopup &&
                        <PopupConfirmation
                            message={"Vous êtes sûr que vous voulez supprimer cet apporteur ? Tous les ventes, les mises en relation, les utilisateurs associés avec cet apporteur seront détachés."}
                            primaryAction={this.deleteApporteur}
                            primaryActionIcon={<Trash />}
                            primaryActionText="Oui"
                            secondaryAction={() => {
                                this.setState({ deletePopup: false, apporteurToDetele: undefined });
                            }}
                            secondaryActionIcon={<Close />}
                            secondaryActionText="Non"
                        />
                    }
                </Box>
            </BlockUi>
        )
    }
}
const VenteModeTextes = ({ canals }) => (
    <Box direction='row'  >
        <Text>{canals?.join(' / ')}</Text>
    </Box>
)

const VenteMode = ({ canals }) => (
    <Box width="small" pad="xsmall" style={{ border: '1px solid #c8c8c8', borderRadius: 5, minHeight: 'auto' }} >
        {
            ventCanals.map((canal, idx) => (
                <Canal key={idx} canal={canal} color={canals.includes(canal) ? '#33BC00' : '#FF3600'} />
            ))
        }
    </Box>
)

const Canal = ({ canal, color }) => (
    <Box direction="row" gap="xsmall" align="center" style={{ minHeight: 'auto' }}>
        <StatusGoodSmall color={color} size='small' />
        <Text size="xsmall">
            <strong>{canal}</strong>
        </Text>
    </Box>
)

const Marker = ({ text, color }) => (
    <Box direction="row" gap="xsmall" pad="xsmall" align="center">
        <StatusGoodSmall color={color} />
        <Text size="xsmall">
            <strong>{text}</strong>
        </Text>
    </Box>
)

export default ApporteurPros;
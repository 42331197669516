const axios = require('axios');
const storageService = require('./storage_service');
const moment = require('moment');
const { baseAPI } = require('../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const createAppointment = (appointment) => {
    return axios.post(`${baseAPI}/public/appointment`, appointment, { headers: HEADERS })
}

const deleteAppointment = (appointment) => {
    return axios.delete(`${baseAPI}/public/appointment/${appointment.id}`, {}, { headers: HEADERS })
}

const getAppointments = () => {
    let params = {
        '_where[0][begin_gt]': moment().toISOString(),
        commercial_id: storageService.getCommercialId()
    }
    return axios.get(`${baseAPI}/public/appointment`, { params, headers: HEADERS })
}

const getAppointmentsByDevisId = (devisId) => {
    let params = {
        contract_id: devisId,
        _sort: 'begin:desc',
    }
    return axios.get(`${baseAPI}/public/appointment`, { params, headers: HEADERS })
}

const getLastAppointmentHorsSystem = (filter) => {
    let params = {
        hors_system: true,
        begin_gt: filter.startDate,
        begin_lt: filter.endDate,
        _sort: 'begin:desc',
        status: 'Fait',
        type: "Appel",
        _limit: -1
    }
    if(!storageService.hasAdministrativeRole()){
        params["commercial"] = storageService.getUserId();
    }
    return axios.get(`${baseAPI}/public/appointment`, { params, headers: HEADERS })
}

const getAppelsByStatus = (devis) => {
    let params = {
        contract_id: devis.id,
        _sort: 'begin:desc',
        type: "Appel",
        _limit: -1
    }
    return axios.get(`${baseAPI}/public/appointment`, { params, headers: HEADERS })
}

const getPassedAppointments = () => {
    let params = {
        '_where[0][begin_lt]': moment().toISOString(),
        commercial_id: storageService.getCommercialId()
    }
    return axios.get(`${baseAPI}/public/appointment`, { params, headers: HEADERS })
}

module.exports = {
    createAppointment,
    deleteAppointment,
    getAppointments,
    getPassedAppointments,
    getAppointmentsByDevisId,
    getLastAppointmentHorsSystem,
    getAppelsByStatus
}
import { Box } from 'grommet';
import moment from 'moment';
import React from 'react';
import 'react-block-ui/style.css';
import BlockUi from 'react-block-ui';
import { getGestionCollabs, getObjectifCADayData } from '../../../services/dashboard';
import Indicator from '../../dashboard/indicator';
import { getEquipes } from '../../../services/admin_service';
import { hasClassiqueManagerRole, isCommercialClassique } from '../../../services/storage_service';


class ObjectifCADay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indicator: undefined,
            commerciaux: [],
            loading: false,
            equipes: []
        }
    }

    componentDidMount() {
        getGestionCollabs()
            .then(res => {
                let commerciaux = res.data.filter(c => isCommercialClassique(c) || hasClassiqueManagerRole(c));
                this.setState({commerciaux});
                let startDate = moment().startOf("day").toDate();
                let endDate = moment().endOf("day").toDate();
                this.getIndicator(startDate, endDate, commerciaux.map(c => c.id));
            });

        getEquipes()
            .then(res => this.setState({ equipes: res.data }))
            .catch(err => console.log(err));
    }

    getIndicator = (startDate, endDate, commercial_id, produits) => {
        produits = produits?.length ? produits : null;
        this.setState({loading: true});
        if(!commercial_id || commercial_id.length === 0) {
            commercial_id = this.state.commerciaux.map(c => c.id);
        }
        getObjectifCADayData(startDate, endDate, commercial_id, produits)
            .then(res => this.setState({ indicator: res.data, loading: false }))
            .catch(err => this.setState({loading: false}))
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box style={{ width: "100%", paddingTop: 20 }}
                    align="center" justify="center"
                    gap="medium">
                    {this.state.indicator &&
                        <Indicator indicator={this.state.indicator.objectif_chiffre} updateIndicator={this.getIndicator}
                            commerciaux={this.state.commerciaux}
                            isCA={true}
                            equipes={this.state.equipes}
                            isDaily={true}
                        />
                    }

                </Box>
            </BlockUi>
        )
    }
}

export default ObjectifCADay;
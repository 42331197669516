import React, { useEffect, useState } from "react";
import { Box, Card, CheckBox, Grid, Heading, Text } from "grommet";
import PrimaryButton from "../primary_button";
import { Checkmark, Close, Send } from "grommet-icons";
import BlockUi from "react-block-ui";
import ToggleButton from "../toggle_button/toggle_button";
import { DateTimeInput } from "../date_input";
import { saveOpportunityTest } from "../../../services/opportunity_service";
import { getSimpleDateTimeFR } from "../../../services/utils";


const OpportunityTestModal = ({ opportunityData, lastTest, client, showMessage, onSubmit, onClose }) => {
	const [loading, setLoading] = useState(false);
	const [produits, setProduits] = useState([]);
	const [produitsSelected, setProduitsSelected] = useState([]);
	const [produitsForm, setProduitsForm] = useState([]);
	const [produitsRemind, setProduitsRemind] = useState({});
	const [updatedNum, setUpdatedNum] = useState(1);
	const [lastAnswers, setLastAnswers] = useState({});

	useEffect(() => {
		console.log('opportunityData = ', opportunityData);
		if (lastTest) {
			setLastAnswers(lastTest.opportunity_answers);
			setProduits(lastTest.produits);
			setProduitsSelected(Object.keys(lastTest.produits_sent));
			let _reminds = {};
			let _forms = [];
			for (let i = 0; i < Object.keys(lastTest.produits_sent)?.length; i++) {
				const _p = Object.keys(lastTest.produits_sent)[i];
				if (lastTest.produits_sent[_p]["remind"])
					_reminds[_p] = lastTest.produits_sent[_p]["remind"];
				if (lastTest.produits_sent[_p]["send_form"])
					_forms.push(_p);
			}
			setProduitsRemind(_reminds);
			setProduitsForm(_forms);
		}
	}, [])

	const updateAnswer = (questionId, value) => {
		let _lastAnswers = lastAnswers;
		_lastAnswers[questionId] = value;
		setLastAnswers(_lastAnswers);
		console.log('updated = ', _lastAnswers);
		console.log('updated = ', lastAnswers);
		setUpdatedNum(updatedNum + 1);
		// update produits
		updateProduitsAfterAnswered();
	}

	const updateProduitsAfterAnswered = () => {
		let _produits = produits;
		// console.log('lastAnswers  = ', lastAnswers);
		let _productsAdded = [];
		let _productsRemoved = [];
		for (let i = 0; i < opportunityData.length; i++) {
			const o = opportunityData[i];
			// console.log(' o.produits = ', o.produits);
			if (o.right_answer === lastAnswers[o.id]) {
				// add produit
				if (o.produits) {
					if (o.produits.length > 0) {
						for (let j = 0; j < o.produits.length; j++) {
							const _p = o.produits[j];
							if (!_productsAdded.includes(_p)) {
								_productsAdded.push(_p);
							}
						}
					}
				}
			} else {
				// remove produit
				if (o.produits) {
					console.log("o.produits = ", o.produits);
					if (o.produits.length > 0) {
						for (let j = 0; j < o.produits.length; j++) {
							const _p = o.produits[j];
							if (!_productsRemoved.includes(_p)) {
								_productsRemoved.push(_p);
							}
						}
					}
				}
			}
		}
		// console.log('should add ', _productsAdded);
		// console.log('should remove ', _productsRemoved);
		// console.log('_produits = ', _produits);
		//remove first
		for (let i = 0; i < _productsRemoved.length; i++) {
			const p = _productsRemoved[i];
			if (_produits.includes(p)) {
				_produits.splice(_produits.indexOf(p), 1);
			}
		}
		//then add if needed
		for (let i = 0; i < _productsAdded.length; i++) {
			const p = _productsAdded[i];
			if (!_produits.includes(p)) {
				_produits.push(p);
			}
		}
		setProduits(_produits);
		setUpdatedNum(updatedNum + 1);
	}

	const updateProduitSelected = (value) => {
		let _produitsSelected = produitsSelected;
		if (_produitsSelected.includes(value)) {
			_produitsSelected.splice(_produitsSelected.indexOf(value), 1);
		} else {
			_produitsSelected.push(value);
		}
		console.log('_produitsSelected = ', _produitsSelected);
		setProduitsSelected(_produitsSelected);
		setUpdatedNum(updatedNum + 1);
	}

	const updateProduitForm = (value) => {
		let _produitsForm = produitsForm;
		if (_produitsForm.includes(value)) {
			_produitsForm.splice(_produitsForm.indexOf(value), 1);
		} else {
			_produitsForm.push(value);
		}
		setProduitsForm(_produitsForm);
		console.log('_produitsForm = ', _produitsForm);
		setUpdatedNum(updatedNum + 1);
	}

	const _defaultDateTime = new Date();

	const updateProduitRemind = (value, date) => {
		let _produitsRemind = produitsRemind;
		if (date) {
			_produitsRemind[value] = date;
		} else {
			delete _produitsRemind[value];
		}
		setProduitsRemind(_produitsRemind);
		console.log('_produitsRemind = ', _produitsRemind);
		setUpdatedNum(updatedNum + 1);
	}

	const doSubmit = () => {
		let _produits_sent = {};
		// build product sent
		for (let i = 0; i < produitsSelected.length; i++) {
			const p = produitsSelected[i];
			_produits_sent[p] = {
				send_form: produitsForm.includes(p) ? true : false,
				remind: produitsRemind[p] ? produitsRemind[p] : false
			}
		}

		let _params = {
			client: client,
			produits: produits,
			produits_sent: _produits_sent,
			opportunity_answers: lastAnswers
		};

		if (lastTest) {
			_params.id = lastTest.id;
		}

		console.log("to save = ", _params);

		saveOpportunityTest(_params).then((res) => {
			onSubmit && onSubmit();
		}).catch(e => {
			console.log("error = ", e);
		});
	}

	console.log('produits = ', produits);


	return (
		<BlockUi tag="div" blocking={loading}>
			<Card width={'large'} pad='medium' gap="small" alignContent="center" >			
				<center><Heading level={2}>Démarrer l’opportunity test</Heading></center>
				<Box key={updatedNum} gap="xsmall" >
					{opportunityData.map((e, _idx) => {
						return (
							<Grid
								key={`question_${_idx}`}
								columns={["80%", "20%"]}
								gap="small"
								width="100%"
								pad="0 20px"
							>
								<Text style={{ fontWeight: 'bold' }}>{e.question}</Text>
								<ToggleButton
									groups={[
										{
											label: "OUI",
											selected: lastAnswers[e.id] === true,
											onClick: () => {
												console.log("OUI clicked");
												updateAnswer(e.id, true);
											},
										},
										{
											label: "NON",
											selected: lastAnswers[e.id] === false,
											onClick: () => {
												console.log("NON clicked");
												updateAnswer(e.id, false);
											},
										},
									]}
								/>
							</Grid>
						);
					})}
				</Box>

				<Box key={`${updatedNum}_products`} gap="xsmall" >
					{produits.map((p, _idx) => {
						return (
							<Grid
								key={`product_${_idx}`}
								columns={["30%", "30%", "30%", "10%"]}
								gap="small"
								width="100%"
								pad="0 20px"
							>
								<CheckBox
									style={{
										alignItems: "center",
										alignContent: "center",
										justifyContent: "center",
									}}

									label={p}
									checked={produitsSelected.includes(p)}
									onChange={(event) => {
										updateProduitSelected(p);
									}}
								/>
								<CheckBox
									style={{
										alignItems: "center",
										alignContent: "center",
										justifyContent: "center",
									}}

									label={"Envoyer formulaire"}
									checked={produitsForm.includes(p)}
									onChange={(event) => {
										updateProduitForm(p);
									}}
								/>
								<Box direction="row">
									<CheckBox
										style={{
											alignItems: "center",
											alignContent: "center",
											justifyContent: "center",
										}}

										label={""}
										checked={produitsRemind.hasOwnProperty(p)}
										onChange={(event) => {
											if (event.target.checked) {
												updateProduitRemind(p, new Date());
											} else {
												updateProduitRemind(p, false);
											}
										}}
									/>
									<DateTimeInput
										style={{
											background: '#EDEDED', borderRadius: 10, border: 'none', borderColor: 'transparent'
										}}
										value={produitsRemind.hasOwnProperty(p) ? produitsRemind[p] : _defaultDateTime}
										onChange={(date) => updateProduitRemind(p, date)}
									/>
								</Box>

								{lastTest?.id && produitsSelected.includes(p) &&
									<Text style={{fontSize: 10}}>Mis à jour le {getSimpleDateTimeFR(lastTest.updatedAt)}</Text>
								}


							</Grid>
						);
					})}
				</Box>

				<Box direction="row" gap="medium" justify="between">

					<PrimaryButton label={'Je créer les deals'} icon={<Send />} onClick={doSubmit} />
				</Box>
			</Card>
		</BlockUi>
	);
};

export default OpportunityTestModal;

import { Box } from 'grommet';
import React from 'react';
import { getGestionCollabs } from '../../../services/dashboard';
import AdminAppelReport from '../../admin/stats/appels/admin_appel_reports';
import { hasClassiqueManagerRole, isCommercialClassique } from '../../../services/storage_service';


class Appels extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            commerciaux: []
        }
    }

    componentDidMount() {
        getGestionCollabs()
            .then(res => {
                let commerciaux = res.data.filter(c => isCommercialClassique(c) || hasClassiqueManagerRole(c));
                this.setState({ commerciaux: [{ name: 'Tous', id: 'All' }, ...commerciaux] })
            });
    }

    render() {
        return (

            <Box style={{ width: "100%", paddingTop: 20 }}
                align="center" justify="center"
                alignSelf="center"
                alignContent="center" gap="medium">
                <AdminAppelReport showMessage={this.props.showMessage} commerciaux={this.state.commerciaux} />
            </Box>
        )
    }
}

export default Appels;
import { Box, Tab, Tabs } from 'grommet';
import React from 'react';
import { getGestionCollabs } from '../../../services/dashboard';
import CommissionAdmin from '../admin_commission';
import CustomizationCommission from '../customization_commission';
import GestionCollab from '../gestion_collab';
import GestionEquipe from '../gestion_equipe';
import CommissionAdminCredit from '../admin_commission_credit';

class GestionCommission extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            commerciaux: []
        }
    }

    componentDidMount() {
        getGestionCollabs()
            .then(res => {
                if(res.data.length === 0) return;
                this.setState({ commerciaux: [{ name: 'Tous', id: 'All' }, ...res.data] })
            });
    }

    render() {
        return (
            <Box style={{ width: "100%", paddingTop: 20 }}
                align="center" justify="center"
                gap="medium">
                <Tabs style={{ width: '100%' }}>
                    <Tab title="Collaborateurs">
                        <Box width={'full'} align='center' justify='center' >
                            <Box width={'xxlarge'} align='center' justify='center' >
                                <GestionCollab showMessage={this.props.showMessage}
                                    commerciaux={this.state.commerciaux}
                                />
                            </Box>
                        </Box>
                    </Tab>
                    <Tab title="Equipe">
                        <Box width={'full'} align='center' justify='center' >
                            <Box width={'xxlarge'} align='center' justify='center' >
                                <GestionEquipe showMessage={this.props.showMessage}
                                    commerciaux={this.state.commerciaux}
                                />
                            </Box>
                        </Box>
                    </Tab>
                    <Tab title="Taux Commissions Assurances">
                        <Box width={'full'} align='center' justify='center' >
                            <Box width={'large'} align='center' justify='center' >
                                <CommissionAdmin showMessage={this.props.showMessage}
                                    commerciaux={this.state.commerciaux}
                                />
                            </Box>
                        </Box>
                    </Tab>
                    <Tab title="Customisation taux assurance">
                        <Box width={'full'} align='center' justify='center' >
                            <Box width={'large'} align='center' justify='center' >
                                <CustomizationCommission showMessage={this.props.showMessage}
                                    commerciaux={this.state.commerciaux}
                                    key={1}
                                />
                            </Box>
                        </Box>
                    </Tab>
                    <Tab title="Taux Commission de Crédit">
                        <Box width={'full'} align='center' justify='center' >
                            <Box width={'large'} align='center' justify='center' >
                                <CommissionAdminCredit showMessage={this.props.showMessage}
                                    commerciaux={this.state.commerciaux}
                                />
                            </Box>
                        </Box>
                    </Tab>
                    <Tab title="Customisation taux crédit">
                        <Box width={'full'} align='center' justify='center' >
                            <Box width={'large'} align='center' justify='center' >
                                <CustomizationCommission showMessage={this.props.showMessage}
                                    commerciaux={this.state.commerciaux}
                                    produit='Crédit'
                                    key={2}
                                />
                            </Box>
                        </Box>
                    </Tab>
                </Tabs>
            </Box>
        )
    }
}

export default GestionCommission;
import { Box } from "grommet"
import { Update } from "grommet-icons";
import React from "react";
import { createHabitationDevis } from "../../../../services/contact_service";
import { PROSPECT } from "../../../../services/devis_contrat_status";
import { createMH } from "../../../../services/trusti_mh_service";
import { createPNOHorsligne } from "../../../../services/trusti_pno_horsligne_service";
import PrimaryButton from "../../../elements/primary_button";
import { submit as createPNO } from '../../../../services/pno_service';

// house_owner true ? 'Propriétaire' : 'Locataire'
const EnligneToHorsligneMapper = {
    "provider_name": "provider_name",
    "provider_id": "provider_id",
    "affiliation": "affiliation",
    "apporteur": "apporteur",
    "source": "source",
    "zip": "location_zip",
    "city": "location_city",
    "address": "location_address",
    "surface": "surface",
    "totalRooms": "total_rooms",
    "bienType": "bien_type",
    "clientType": "house_owner",
    "residenceType": "residence_type",
    "commercial_id": "commercial_id",
    "firstname": "firstname",
    "lastname": "lastname",
    "email": "email",
    "phone": "phone",
    "client": "client",
    "civilite": "civilite",
}

const MHConversion = ({ deal, showMessage, blockingHandler }) => {

    const convert = () => {
        const convertedObject = {
            status: PROSPECT
        };
        for (let key of Object.keys(EnligneToHorsligneMapper)) {
            const fieldKey = fromEnLigneToHorsligne(deal) ? EnligneToHorsligneMapper[key] : key;
            const valueKey = fromEnLigneToHorsligne(deal) ? key : EnligneToHorsligneMapper[key];
            if (key === "clientType") {
                convertedObject[fieldKey] = fromEnLigneToHorsligne(deal) ?
                    (deal.clientType === 'Propriétaire') :
                    (deal[valueKey] ? 'Propriétaire' : 'Locataire');
            } else {
                convertedObject[fieldKey] = deal[valueKey];
            }
        }
        console.log(convertedObject);
        let handler = fromEnLigneToHorsligne(deal) ? createMH : createHabitationDevis;
        blockingHandler(true);
        handler(convertedObject)
            .then(res => {
                blockingHandler(false);
                showMessage(`Le prospect de HM ${fromEnLigneToHorsligne(deal) ? 'hors ligne' : 'en ligne'} a été créé avec succès`, 'success')
            })
            .catch(err => {
                blockingHandler(false);
                showMessage(`Erreur inattendu`, 'error')
            })
    }

    const fromEnLigneToHorsligne = deal => deal.model_type === 'devis_habitation';

    return (
        <PrimaryButton
            onClick={convert}
            background="#28a745"
            label={`Converter à ${fromEnLigneToHorsligne(deal) ? 'MH Hors ligne' : 'MH en ligne'}`}
            icon={<Update />}
        />
    )
}

export default MHConversion;

const PNOEnligneToHorsligneConversion = {
    "provider_name": "provider_name",
    "provider_id": "provider_id",
    "affiliation": "affiliation",
    "apporteur": "apporteur",
    "source": "source",
    "commercial_id": "commercial_id",
    "firstname": "firstname",
    "lastname": "lastname",
    "email": "email",
    "phone": "phone",
    "client": "client",
    "bienType": "bien_type",
    "locationDuration": "location_type",
    "address": "location_address",
    "city": "location_city",
    "surface": "surface",
    "totalRooms": "total_rooms",
    "zip": "location_zip",
}

const PNOConversion = ({ deal, showMessage, blockingHandler }) => {

    const convert = () => {
        const convertedObject = {
            status: PROSPECT
        };
        for (let key of Object.keys(PNOEnligneToHorsligneConversion)) {
            const fieldKey = fromEnLigneToHorsligne(deal) ? PNOEnligneToHorsligneConversion[key] : key;
            const valueKey = fromEnLigneToHorsligne(deal) ? key : PNOEnligneToHorsligneConversion[key];
            if (key === "locationDuration") {
                convertedObject[fieldKey] = fromEnLigneToHorsligne(deal) ?
                    (deal.locationDuration === 'Location courte durée' ? 'Location courte durée' : 'Location classique' ) :
                    (deal[valueKey] === 'Location classique' ? "Location à l'année" : 'Location courte durée');
            } else {
                convertedObject[fieldKey] = deal[valueKey];
            }
        }
        console.log(convertedObject);
        let handler = fromEnLigneToHorsligne(deal) ? createPNOHorsligne : createPNO;
        blockingHandler(true);
        handler(convertedObject)
            .then(res => {
                blockingHandler(false);
                showMessage(`Le prospect de PNO ${fromEnLigneToHorsligne(deal) ? 'hors ligne' : 'en ligne'} a été créé avec succès`, 'success')
            })
            .catch(err => {
                blockingHandler(false);
                showMessage(`Erreur inattendu`, 'error')
            })
    }

    const fromEnLigneToHorsligne = deal => deal.model_type === 'pno_seul';

    return (
        <PrimaryButton
            onClick={convert}
            background="#28a745"
            label={`Converter à ${fromEnLigneToHorsligne(deal) ? 'PNO Hors ligne' : 'PNO en ligne'}`}
            icon={<Update />}
        />
    )
}

export {
    PNOConversion
}
import { Box, Layer } from "grommet";
import { Checkmark, Close, Edit, Search, View } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { CardContainer, LineTextContainer, TitleContainer } from "../../../elements/refont/containers/text-containser";
import CustomSelect from "../../../elements/refont/custom-select";
import { CustomDateInput } from "../../../elements/refont/date_input";
import CustomTextArea from "../../../elements/refont/text-area";
import CustomTextInput from "../../../elements/refont/text-input";
import { TextLabel } from "../../../elements/refont/text-label";
import { ClientSearch, SubSearch } from "./recherche";
import { getNatureList } from "./utils";
import Subscription from "../../souscriptions/souscription";


export function SinistreInfo({ sinistre, onChangeSubmit }) {
    const [values, setValues] = useState({ ...sinistre });
    const [natures, setNatures] = useState(getNatureList(sinistre.type));
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setValues({ ...sinistre });
        setNatures(getNatureList(sinistre.type));
    }, [sinistre]);

    const handleReset = () => {
        setIsEdit(false);
        setValues({ ...sinistre });
    }
    const onTypeChange = (value) => {
        const _natures = getNatureList(value);
        setNatures(_natures);
    }
    return (
        <CardContainer>
            <Box direction={'row'} gap={'small'} justify="between" align="center">
                <TextLabel label={"Sinistre"} variant={"header3"} />
                {!isEdit ?
                    <Edit onClick={() => setIsEdit(true)} color="brand" size="small" /> :
                    <Box direction="row" gap="small">
                        <Checkmark size="small" color="brand" onClick={() => onChangeSubmit(values, () => setIsEdit(false))} />
                        <Close size="small" onClick={handleReset} />
                    </Box>
                }
            </Box>
            <Box pad={{ top: '1rem' }}>
                <TitleContainer>
                    <TextLabel variant={"title1"} label={"Date du sinistre"} color={'white'} />
                </TitleContainer>
                <Box pad={{ vertical: '0.5rem' }}>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Date de d’ouverture :"} />
                            <CustomDateInput
                                editMode={isEdit}
                                value={values.date_ouverture}
                                onChange={e => {
                                    values.date_ouverture = e.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Date de survenance :"} />
                            <CustomDateInput
                                editMode={isEdit}
                                value={values.date_survenance}
                                onChange={e => {
                                    values.date_survenance = e.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                </Box>
            </Box>
            <Box pad={{ top: '1rem' }}>
                <TitleContainer>
                    <TextLabel variant={"title1"} label={"Détail du sinistre"} color={'white'} />
                </TitleContainer>
                <Box pad={{ vertical: '0.5rem' }}>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Type de sinistre :"} />
                            <CustomSelect
                                options={[
                                    "Annulation de séjour",
                                    "Interruption de séjour",
                                    "Dommage mobilier",
                                    "Recours compagnie",
                                    "Assistance rapatriement"
                                ]}
                                value={values.type}
                                onChange={({ option }) => {
                                    values.type = option;
                                    setValues({ ...values });
                                    onTypeChange(option);
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Nature de sinistre :"} />
                            <CustomSelect
                                editMode={isEdit}
                                value={values.nature}
                                options={natures}
                                onChange={({ option }) => {
                                    values.nature = option;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="column" gap="small">
                            <TextLabel variant={"body1"} label={"Déclaration circonstanciel du sinistre :"} />
                            <CustomTextArea
                                editMode={isEdit}
                                onChange={e => {
                                    values.declaration_circonstanciel = e.target.value;
                                    setValues({ ...values });
                                }}
                                value={values.declaration_circonstanciel}
                            />
                        </Box>
                    </LineTextContainer>
                </Box>
            </Box>

        </CardContainer>
    )
}
export function AssureInfo({ sinistre, onChangeSubmit }) {
    const [values, setValues] = useState({ ...sinistre });
    const [isEdit, setIsEdit] = useState(false);
    const [searchClient, setSearchClient] = useState(false);

    useEffect(() => {
        setValues({ ...sinistre });
    }, [sinistre]);

    const handleReset = () => {
        setIsEdit(false);
        setValues({ ...sinistre });
    }
    return (
        <CardContainer>
            <Box direction={'row'} gap={'small'} justify="between" align="center">
                <TextLabel label={"Assuré"} variant={"header3"} />
                {isEdit ?
                    <Box direction="row" gap="small">
                        <Search size="small" onClick={() => setSearchClient(true)} />
                        <Checkmark size="small" color="brand" onClick={() => {
                            onChangeSubmit(values, () => setIsEdit(false));
                        }} />
                        <Close size="small" onClick={handleReset} />
                    </Box> :
                    <Box direction="row" gap="small">
                        <Edit size="small" color="brand" onClick={() => setIsEdit(true)} />
                    </Box>
                }
            </Box>
            <Box pad={{ top: '1rem' }}>
                <TitleContainer>
                    <TextLabel variant={"title1"} label={"Contact"} color={'white'} />
                </TitleContainer>
                <Box pad={{ vertical: '0.5rem' }}>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Nom :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.nom_locataire}
                                onChange={e => {
                                    values.nom_locataire = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Prénom :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.prenom_du_locataire}
                                onChange={e => {
                                    values.prenom_du_locataire = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Téléphone :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.telephone_locataire}
                                onChange={e => {
                                    values.telephone_locataire = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Email :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.mail_locataire}
                                onChange={e => {
                                    values.mail_locataire = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                </Box>
            </Box>
            {searchClient &&
                <ClientSearch
                    onClose={() => setSearchClient(false)}
                    onClientSelected={client => {
                        values.prenom_du_locataire = client.firstname;
                        values.nom_locataire = client.lastname;
                        values.telephone_locataire = client.phone;
                        values.mail_locataire = client.email;
                        values.client = client.id;
                        setValues({ ...values });
                        setSearchClient(false);
                    }}
                />
            }
        </CardContainer>
    )
}


const getOptions = sub => {
    const results = [];
    if (sub.option_assitance_rapatriement) results.push('Rapatriement');
    if (sub.option_billets_avion) results.push('Billets avion');
    if (sub.option_prestations_annexes) results.push('Prestations annexes');
    return results.join(', ');
}
export function ContratInfo({ sinistre, onChangeSubmit }) {
    const [values, setValues] = useState({ ...sinistre });
    const [isEdit, setIsEdit] = useState(false);
    const [viewSub, setViewSub] = useState(false);
    const [searchSub, setSearchSub] = useState(false);

    useEffect(() => {
        setValues({ ...sinistre });
    }, [sinistre]);

    const handleReset = () => {
        setIsEdit(false);
        setValues({ ...sinistre });
    }
    return (
        <CardContainer>
            <Box direction={'row'} gap={'small'} justify="between" align="center">
                <TextLabel label={"Contrat"} variant={"header3"} />
                {isEdit ?
                    <Box direction="row" gap="small">
                        <Search size="small" onClick={() => setSearchSub(true)} />
                        <Checkmark size="small" color="brand" onClick={() => {
                            onChangeSubmit(values, () => setIsEdit(false));
                        }} />
                        <Close size="small" onClick={handleReset} />
                    </Box> :
                    <Box direction="row" gap="small">
                        {values.subscription && <View size="small" onClick={() => setViewSub(true)} />}
                        <Edit size="small" color="brand" onClick={() => setIsEdit(true)} />
                    </Box>
                }
            </Box>
            <Box pad={{ top: '1rem' }}>
                <TitleContainer>
                    <TextLabel variant={"title1"} label={"Contact"} color={'white'} />
                </TitleContainer>
                <Box pad={{ vertical: '0.5rem' }}>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Numéro de contrat :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.numero_contrat}
                                onChange={e => {
                                    values.numero_contrat = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Type assurance :"} />
                            <CustomSelect
                                editMode={isEdit}
                                value={values.type_assurance}
                                options={[
                                    "Trusti Voyage",
                                    "Trusti Voyage Luxe",
                                    "Trusti Annulation / Interruption",
                                    "Trusti Annulation / Interruption Luxe",
                                    "Trusti voyage neige",
                                    "Trusti Voyage Neige Luxe",
                                    "Trusti Annulation / Interruption Neige",
                                ]}
                                onChange={({ option }) => {
                                    values.type_assurance = option;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Option assurance :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.option_assurance}
                                onChange={e => {
                                    values.option_assurance = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Montant du séjour :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.montant_sejour}
                                onChange={e => {
                                    values.montant_sejour = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Nombre de participant :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.nombre_participant}
                                onChange={e => {
                                    values.nombre_participant = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Nombre de participant qui annule :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.nombre_participant_annule}
                                onChange={e => {
                                    values.nombre_participant_annule = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Agence :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.agence_name}
                                onChange={e => {
                                    values.agence_name = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                </Box>
            </Box>

            <Box pad={{ top: '1rem' }}>
                <TitleContainer>
                    <TextLabel variant={"title1"} label={"Séjour"} color={'white'} />
                </TitleContainer>
                <LineTextContainer>
                    <Box direction="row" align="center" gap="small">
                        <TextLabel variant={"body1"} label={"Date de début :"} />
                        <CustomDateInput
                            editMode={isEdit}
                            value={values.date_debut}
                            onChange={e => {
                                values.date_debut = e.value;
                                setValues({ ...values });
                            }}
                        />
                    </Box>
                </LineTextContainer>
                <LineTextContainer>
                    <Box direction="row" align="center" gap="small">
                        <TextLabel variant={"body1"} label={"Date de fin :"} />
                        <CustomDateInput
                            editMode={isEdit}
                            value={values.date_fin}
                            onChange={e => {
                                values.date_fin = e.value;
                                setValues({ ...values });
                            }}
                        />
                    </Box>
                </LineTextContainer>
                <LineTextContainer>
                    <Box direction="row" align="center" gap="small">
                        <TextLabel variant={"body1"} label={"Lieu de séjour :"} />
                        <CustomTextInput
                            editMode={isEdit}
                            value={values.lieu_sejour}
                            onChange={e => {
                                values.lieu_sejour = e.target.value;
                                setValues({ ...values });
                            }}
                        />
                    </Box>
                </LineTextContainer>
            </Box>


            {searchSub &&
                <SubSearch
                    onClose={() => setSearchSub(false)}
                    onSubSelected={sub => {
                        values.numero_contrat = sub.contrat_numero;
                        values.agence_name = sub.agence;
                        values.domain_agence = sub.domain_agence;
                        values.agence = sub.owner?.id;
                        values.montant_sejour = sub.prix_sejour;
                        values.date_debut = sub.date_de_debut_de_sejour;
                        values.date_fin = sub.date_de_fin_de_sejour;
                        values.type_assurance = sub.produit;
                        values.option_assurance = getOptions(sub);
                        values.lieu_sejour = `${sub.adresse_du_risque}`;
                        values.subscription = sub.id;
                        values.nombre_participant = (sub.liste_beneficiaires || "").split(',').length;
                        setValues({ ...values });
                        setSearchSub(false);
                    }}
                />
            }
            {viewSub &&
                <Layer onClickOutside={() => setViewSub(false)}>
                    <Box overflow={{ vertical: 'auto' }}>
                        <Subscription subscription={values.subscription}
                            onClose={() => setViewSub(false)}
                            showMessage={() => {}}
                            onReload={s => { }}
                            hideAction={true}
                        />
                    </Box>
                </Layer>
            }
        </CardContainer>
    )
}

export function InfoBancaire({ sinistre, onChangeSubmit }) {
    const [values, setValues] = useState({ ...sinistre });
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setValues({ ...sinistre });
    }, [sinistre]);

    const handleReset = () => {
        setIsEdit(false);
        setValues({ ...sinistre });
    }

    return (

        <CardContainer>
            <Box direction={'row'} gap={'small'} justify="between" align="center">
                <TextLabel label={"Information bancaire"} variant={"header3"} />
                {isEdit ?
                    <Box direction="row" gap="small">
                        <Checkmark size="small" color="brand" onClick={() => {
                            onChangeSubmit(values, () => setIsEdit(false));
                        }} />
                        <Close size="small" onClick={handleReset} />
                    </Box> :
                    <Box direction="row" gap="small">
                        <Edit size="small" color="brand" onClick={() => setIsEdit(true)} />
                    </Box>
                }
            </Box>
            <Box pad={{ top: '1rem' }}>
                <TitleContainer>
                    <TextLabel variant={"title1"} label={"L'Assuré"} color={'white'} />
                </TitleContainer>
                <Box pad={{ vertical: '0.5rem' }}>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"IBAN :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.iban_locataire}
                                onChange={e => {
                                    values.iban_locataire = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Nom du titulaire :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.nom_iban_locataire}
                                onChange={e => {
                                    values.nom_iban_locataire = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"BIC :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.bic_iban_locataire}
                                onChange={e => {
                                    values.bic_iban_locataire = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                </Box>
            </Box>
            <Box pad={{ top: '1rem' }}>
                <TitleContainer>
                    <TextLabel variant={"title1"} label={"Propriétaire"} color={'white'} />
                </TitleContainer>
                <Box pad={{ vertical: '0.5rem' }}>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"IBAN :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.iban_proprietaire}
                                onChange={e => {
                                    values.iban_proprietaire = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"Nom du titulaire :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.nom_iban_proprietaire}
                                onChange={e => {
                                    values.nom_iban_proprietaire = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                    <LineTextContainer>
                        <Box direction="row" align="center" gap="small">
                            <TextLabel variant={"body1"} label={"BIC :"} />
                            <CustomTextInput
                                editMode={isEdit}
                                value={values.bic_iban_proprietaire}
                                onChange={e => {
                                    values.bic_iban_proprietaire = e.target.value;
                                    setValues({ ...values });
                                }}
                            />
                        </Box>
                    </LineTextContainer>
                </Box>
            </Box>

        </CardContainer>
    )
}


const axios = require('axios');
const storageService = require('./storage_service');
const { baseAPI } = require('../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const getAllOpportunityConfigs = () => {
    return axios.get(`${baseAPI}/opportunity-configs`,  { headers: HEADERS })
}
const getLastOpportunityConfigs = (clientId) => {
    return axios.get(`${baseAPI}/opportunity-tests?client.id=${clientId}`,  { headers: HEADERS })
}

const saveOpportunityTest = (params) => {
    return axios.post(`${baseAPI}/opportunity-tests/test`, params, { headers: HEADERS });
}

const saveOpportunityConfigs = (params) => {
    return axios.put(`${baseAPI}/opportunity-configs/${params.id}`, params,  { headers: HEADERS });
}

module.exports = {
    getAllOpportunityConfigs,
    getLastOpportunityConfigs,
    saveOpportunityTest,
    saveOpportunityConfigs
}
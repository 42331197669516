import React, { useReducer } from "react";
import GedContext from "./GedContext";
import GedReducer from "./GedReducer";
import {
	CLEAR_FILES,
	SET_FILES,
	SET_LOADING
} from "../types";

const GedState = (props) => {
	const initialState = {
		files: [],
		loading: false
	};

	const [state, dispatch] = useReducer(GedReducer, initialState);

	// Clear files
	const clearFiles = () => dispatch({ type: CLEAR_FILES });

	const setFiles = (files) => dispatch({ type: SET_FILES , payload: files});

	// Set Loading
	const setLoading = () => dispatch({ type: SET_LOADING });

	return (
		<GedContext.Provider
			value={{
				files: state.files,
				loading: state.loading,
				clearFiles,
				setFiles,
				setLoading
			}}
		>
			{props.children}
		</GedContext.Provider>
	);
};

export default GedState;

import React, { useEffect, useState } from "react";
import {
	Box,
	Card,
	Spinner,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
	Text,
} from "grommet";
import { getClientName } from "../../outils/client_util";
import { getSimpleDateTimeFR } from "../../../services/utils";
import { getBlocGeds } from "./service/bloc_ged";
import GedModal from "./Components/GedModal";
import { getContextBot } from "../../../services/storage_service";
import GedState from "./context/ged/GedState";

const TrustiBotGed = ({ onOpenedDialog }) => {
	const [loading, setLoading] = useState(false);
	const [listNotes, setListNotes] = useState([]);

	const loadData = () => {
		const _contextBot = getContextBot();
		var client = null;
		var contract_id = null;
		var commercial = null;
		if (_contextBot) {
			if (_contextBot.client) {
				client = _contextBot.client.id;
			}
			if (_contextBot.id) {
				contract_id = _contextBot.id;
			}
			if (_contextBot.selectedCommercial) {
				commercial = _contextBot.selectedCommercial;
			}
		}
		setLoading(true);
		try {
			getBlocGeds(client, contract_id, commercial).then((res) => {
				setListNotes(res.data);
				setLoading(false);
			}).catch(e => {
				console.log("error = ", e);
			});
		} catch (error) {
			console.log("error", error);
			setLoading(false);
		}
	};

	const updatedContextCallback = (files) => {

		// const newListNotes = [...listNotes];
		// if(index >= listNotes.length) {
		// 	newListNotes.push(newNote);
		// }else{
		// 	newListNotes[index] = newNote;
		// }
		// setListNotes(newListNotes);
	};

	useEffect(() => {
		loadData();
		// eslint-disable-next-line
	}, []);

	return (
		<GedState>
			<Box style={{ minHeight: 320 }}>
				<Box direction="row" justify="between" background="brand" style={{ borderRadius: "15px 15px 0 0" }}>
					<Text margin={"small"} size="medium">
						<b>GED</b>
					</Text>
					<Box justify="center">
						<GedModal
							onOpenedDialog={onOpenedDialog}
							callback={loadData}
							modeEdit={false}
							label={<Text weight={400}>Ajouter un <b>document</b></Text>}
							updatedContext={(files) => {
								updatedContextCallback(files);
							}}
							icon
						></GedModal>
					</Box>
				</Box>
				<Table>
					<TableHeader style={{ backgroundColor: "#F5F5F5", borderRadius: "0 0 10px 10px" }}>
						<TableRow>
							<TableCell width={"60px"}></TableCell>
							<TableCell width={"220px"}>
								<Text size="small">
									Commercial
								</Text>
							</TableCell>
							<TableCell width={"220px"}>
								<Text size="small">
									Client
								</Text>
							</TableCell>
							<TableCell width={"200px"}>
								<Text size="small">
									Titre
								</Text>
							</TableCell>
							<TableCell width={"200px"}>
								<Text size="small">
									Produit
								</Text>
							</TableCell>
							<TableCell width={"140px"}>
								<Text size="small">
									Création
								</Text>
							</TableCell>
							<TableCell width={"140px"}>
								<Text size="small">
									Modification
								</Text>
							</TableCell>
						</TableRow>
					</TableHeader>
				</Table>
				<Box style={{ overflowY: "scroll", maxHeight: 250 }}>
					{loading ? (
						<Spinner />
					) : (
						<Table>
							<TableBody>
								{listNotes.map((_note, index) => {
									let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
									return (
										<TableRow
											key={index + 1}
											style={{ backgroundColor: _bgColor }}
										>
											<TableCell width={"60px"}>
												<GedModal
													label="Voir"
													noteData={_note}
													updatedContext={(files) => {
														updatedContextCallback(files);
													}}
													callback={loadData}
													onOpenedDialog={onOpenedDialog}
													modeEdit={true}
												></GedModal>
											</TableCell>
											<TableCell width={"200px"}>
												<Text size="small">
													{`${_note.commercial?.nom} ${_note.commercial?.prenom}`}
												</Text>
											</TableCell>
											<TableCell width={"220px"}>
												<Text size="small">{getClientName(_note.client)}</Text>
											</TableCell>
											<TableCell width={"200px"}>
												<Text size="small">{_note.title}</Text>
											</TableCell>
											<TableCell width={"200px"}>
												<Text size="small">
													<b>{_note.contract_name}</b>
													<br />
													<i>{_note.contract_id}</i>
												</Text>
											</TableCell>
											<TableCell width={"140px"}>
												<Text size="small">
													{getSimpleDateTimeFR(_note.createdAt)}
												</Text>
											</TableCell>
											<TableCell width={"140px"}>
												<Text size="small">
													{getSimpleDateTimeFR(_note.updatedAt)}
												</Text>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					)}
				</Box>
			</Box>
		</GedState>
	);
};

export default TrustiBotGed;

import {
  Box,
  Button,
  Layer,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  TextInput,
} from "grommet";
import React from "react";
import { getContact } from "../../services/contact_service";
import ContactModal from "./contact_modal/contact_modal";
import { Close } from "grommet-icons";
import { clearContextBot } from "../../services/storage_service";
import TrustiBot from "../trustibot";

const inputStyle = {
  background: "#EDEDED",
  fontSize: 14,
  borderRadius: 10,
  border: "none",
};
class Contact extends React.Component {
  static timeoutId;
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      selectedContact: {},
      contacts: [],
      total: 0,
      page: 1,
      query: "",
    };
  }

  componentDidMount() {
    this.loadContacts();
  }

  loadContacts = () => {
    getContact(this.state.page - 1, this.state.query, "contact")
      .then((res) => {
        console.log(res.data);
        let results = res.data;
        this.setState({ contacts: results, total: res.data.total });
      })
      .catch((err) => console.log(err));
  };

  // loading after 300ms
  search = (query) => {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.setState({ query: query }, () => this.loadContacts());
    }, 500);
  };

  handleContact = (contact) => {
    this.setState({ popupOpen: true, selectedContact: contact });
  };

  onClose = () => {
    this.setState({ popupOpen: false });
    clearContextBot();
  };

  createContactPopup = () => {
    this.setState({ selectedContact: null, popupOpen: true });
  };

  onPageChange = ({ page, startIndex, endIndex }) => {
    this.setState({ page: page }, () => this.loadContacts());
  };

  render() {
    return (
      <Box style={{ width: "100%" }} alignContent="center" gap="medium">
        <Box
          direction="row"
          gap="small"
          align="center"
          justify="center"
          width={'xlarge'}
          style={{ paddingTop: 20 }}
        >
          <Button
            primary
            label="Ajouter un contact"
            onClick={this.createContactPopup}
          />
          <Box width="medium">
            <TextInput
              placeholder="Recherche contact "
              onChange={(event) => this.search(event.target.value)}
              style={inputStyle}
            />
          </Box>
        </Box>

        {this.state.popupOpen && (
          <Layer
            full='vertical'
            onEsc={this.onClose}
          // style={{ width: '90%' }}
          >
            <Box
              direction="row"
              gap='small'
              style={{ width: "100vw" }}
            >
              {this.state.selectedContact !== null && <Box
                style={{ width: "50vw" }}
              >
                <TrustiBot></TrustiBot>
              </Box>}

              <Box flex overflow={'auto'} style={{ width: "50vw" }}>
                <ContactModal
                  selectedContact={this.state.selectedContact}
                  creationMode={this.state.selectedContact === null}
                  showMessage={this.props.showMessage}
                  onClose={this.onClose}
                  refresh={this.loadContacts}
                />
              </Box>

            </Box>


            <Button
              plain={true}
              icon={<Close />}
              onClick={this.onClose}
              style={{ position: "absolute", top: 5, right: 5 }}
            />
          </Layer>
        )}
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>
                <Text>Nom</Text>
              </TableCell>
              <TableCell>
                <Text>Prénom</Text>
              </TableCell>
              <TableCell>
                <Text>Mail</Text>
              </TableCell>
              <TableCell>
                <Text>Téléphone</Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.state.contacts.map((c) => (
              <TableRow key={c.id}>
                <TableCell>
                  <Text>{c.lastname}</Text>
                </TableCell>
                <TableCell>
                  <Text>{c.firstname}</Text>
                </TableCell>
                <TableCell>
                  <Text>{c.email}</Text>
                </TableCell>
                <TableCell>
                  <Text>{c.phone}</Text>
                </TableCell>
                <TableCell>
                  <Button
                    label="voir contact"
                    primary
                    onClick={() => this.handleContact(c)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          numberItems={this.state.total}
          page={this.state.page}
          alignSelf="end"
          onChange={this.onPageChange}
        />
      </Box>
    );
  }
}

export default Contact;

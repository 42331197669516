import React from 'react';
import { Box, Card, Heading, Stack, Text } from 'grommet';
import { getSimpleDateFR, toCurrency } from '../../../../services/utils';
import RecapStatus from '../../../elements/devis_status';
import StatusDateAppel from '../../../elements/status_date_appel';
import PanelAppel from '../../../elements/panel_appel';
import EmailEventTracker from '../../../elements/email_tracker';
import StripeCustomer from '../../../elements/stripe_customer';
import ApporteurGestionnaire from '../../../elements/apporteur_gestionaire';
import TrustiBot from '../../../trustibot';
import DateRecap from '../../../elements/date_recap';

const Recap = ({ devis, submit, sendDevis, toEditMode, close, updateDevis, onSave }) => {
    return (
        <Box
            direction="row"
            gap='small'
            style={{ width: "100vw" }}
        >
            <TrustiBot></TrustiBot>
            <Box
                gap="medium"
                // style={{ width: "100%" }}
                align="center"
                alignSelf="center"
                pad="small"
            >
                <Heading level="2" color="#59A4F4" textAlign="center">Trusti MRH</Heading>
                <Box direction="row" fill="horizontal" justify="center" alignContent="center">
                    <Box align="center">
                        <StatusDateAppel devis={devis} />
                    </Box>
                    <Box>
                        <Box alignSelf="end">
                            <RecapStatus status={devis.status} />
                            <PanelAppel devis={devis} updateDevis={updateDevis} onSave={onSave}  />
                        </Box>
                    </Box>
                </Box>
                <Box gap="small" width={'full'} justify='center' align="center">
                    <Card pad="small" gap="small" width={'full'} >
                        <Text textAlign="center"><b>ID:</b> {devis.id}</Text>
                    </Card>
                </Box>
                <Box gap="small" width={'full'}>
                    <DateRecap deal={devis} />
                </Box>
                <Box width={'xlarge'}>
                    <StripeCustomer devisId={devis.id} />
                </Box>
                {devis.estimatedPrice &&
                    <SubHeading
                        label={`Prix estimé à ${toCurrency(devis.estimatedPrice)}/ an`}
                        color='#E92C70'
                    />
                }{devis.estimatedPrice &&
                    <SubHeading
                        label={`dont le frais de dossier ${toCurrency(devis.frais_dossier)}`}
                        color='#E92C70'
                    />
                }
                <Box width="large" gap="small">
                    <Box width="xlarge" direction='row' gap='small' justify="center" >
                        <Card pad="small" width="large">
                            <Assure
                                assure={devis.client || {}}
                                apporteur={devis.apporteur}
                            />
                        </Card>
                    </Box>
                    <Box width="xlarge" direction='row' gap='small' justify="center" >

                        <Card pad="small" width="large">
                            <Assurance
                                devis={devis}
                            />
                        </Card>
                    </Box>
                </Box>
                {(devis.apporteur || devis.affiliation) && <Card pad={"small"} width={'xlarge'} >
                    <ApporteurGestionnaire apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
                </Card>}
                <Box width={'xlarge'}>
                    <EmailEventTracker messageIds={devis.emailEventIds} />
                </Box>
            </Box>
        </Box>
    )
}

const CustomText = ({ label, textAlign }) => <Text style={{ fontWeight: 'bold' }} size="small" textAlign={textAlign || "end"}>{label}</Text>
const Line = ({ leftLabel, rightLabel }) => (
    <Box direction='row' gap="medium" style={{ width: '100%' }} align="center" alignSelf="center">
        <Box style={{ width: '50%' }} >
            <CustomText label={leftLabel + " :"} />
        </Box>
        <Box style={{ width: '50%' }} >
            <CustomText label={rightLabel} textAlign="start" />
        </Box>
    </Box>
)
const Assure = ({ assure, apporteur }) => {
    const _cv = assure.civility || assure.civilite;
    return (
        <Box gap="small" style={{ width: '100%' }}>
            <SubHeading label="Informations de l'assuré" />
            <Line
                leftLabel='Civilité'
                rightLabel={assure.civilite}
            />
            {_cv !== 'Société' &&
                <Line
                    leftLabel='Prénom'
                    rightLabel={assure.firstname}
                />
            }
            {_cv !== 'Société' &&
                <Line
                    leftLabel='Nom'
                    rightLabel={assure.lastname}
                />
            }
            {_cv === 'Société' &&
                <Line
                    leftLabel='Raison social'
                    rightLabel={assure.raisonSocial}
                />
            }
            <Line
                leftLabel='Mail'
                rightLabel={assure.email}
            />
            <Line
                leftLabel='Tel'
                rightLabel={assure.phone}
            />
            <Line
                leftLabel='Adresse postale'
                rightLabel={`${assure.address}, ${assure.zip}, ${assure.city}`}
            />
            {apporteur &&
                <Line
                    leftLabel="Apporteur"
                    rightLabel={`${apporteur?.prenom} ${apporteur?.nom}`}
                />
            }
        </Box>
    )
}

const getLocationAddress = devis => {
    return `${devis.address}, ${devis.zip}, ${devis.city}`;
}

const Assurance = ({ devis }) => (
    <Box gap="small" style={{ width: '100%' }}>
        <SubHeading label="Informations de l'assurance" />
        <Line
            leftLabel="Date d'effet"
            rightLabel={devis.effectiveDate ? getSimpleDateFR(devis.effectiveDate) : null}
        />
        <Line
            leftLabel="Date de fin"
            rightLabel={devis.effectiveDate ? getSimpleDateFR(devis.date_fin) : null}
        />
        <Line
            leftLabel='Type de résidence'
            rightLabel={devis.residenceType}
        />
        <Line
            leftLabel='Type de logement'
            rightLabel={devis.bienType}
        />
        <Line
            leftLabel='Adresse de la location'
            rightLabel={getLocationAddress(devis)}
        />
        <Line
            leftLabel='Nombre de pièces'
            rightLabel={devis.totalRooms}
        />
        <Line
            leftLabel='Surface'
            rightLabel={devis.surface}
        />
        <Line
            leftLabel='Capital mobilier'
            rightLabel={devis.rawResponse?.capital_mobilier ? toCurrency(devis.rawResponse?.capital_mobilier) : ''}
        />
        <Line
            leftLabel='Objet de valeur'
            rightLabel={devis.rawResponse?.object_valeur ? toCurrency(devis.rawResponse?.object_valeur) : ''}
        />
        <Line
            leftLabel='Locale'
            rightLabel={devis.locale || 'fr'}
        />
    </Box>
)

const SubHeading = ({ label, color }) => (
    <Text textAlign="center" style={{ fontWeight: "bold", color: color || 'black' }}>
        {label}
    </Text>
)

export default Recap;

import { Box, Button, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import React from 'react';
import service from '../../../services/trusti_voiture_service';
import { getSimpleDateFR, getSorted } from '../../../services/utils';
import AddDevis from '../../elements/add_devis';
import ContractDetails from '../../elements/contract_details';
import DateSort from '../../elements/date_sort';
import PaymentStatus from '../../elements/devis_payment_status';
import RecapStatus from '../../elements/devis_status';
import Refresh from '../../elements/refresh';
import SearchComponent from '../../elements/search';
import StatusFilter from '../../elements/status_filter';
import ViewTable from '../../elements/view_table';
import TrustiVoiture from './trusti_voiture/index';



class TrustiVoitures extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: this.props.contact || {},
            trustiVoitures: [],
            popupOpen: false,
            selectedTrustiVoiture: {},
            filter: {
                _query: undefined,
                status: undefined,
                sort: undefined
            }
        }
    }

    componentDidMount = () => {
        this.loadTrustiVoitures();
    };

    loadTrustiVoitures = () => {
        this.setState({ trustiVoitures: [] }, () => {
            service.getTrustiVoitures(this.state.contact.id)
                .then(res => {
                    this.setState({ trustiVoitures: res.data })
                })
                .catch(err => {
                    console.log('err', err);
                    this.props.showMessage('Erreur inattendu');
                })
        })

    }

    onClose = () => { this.setState({ popupOpen: false }) }

    openTrustiVoiture = (trustiVoiture) => {
        this.setState({ selectedTrustiVoiture: trustiVoiture, popupOpen: true });
    }

    addTrustiVoiture = () => this.openTrustiVoiture({ conducteur_principal: {} })

    search = (query, status) => {
        service.getTrustiVoitures(this.state.contact.id, query, status)
            .then(res => {
                if (res.data?.results) {
                    this.setState({ trustiVoitures: res.data?.results })
                } else if (res.data) {
                    this.setState({ trustiVoitures: res.data })
                }
            })
            .catch(err => {
                console.log('err', err);
                this.props.showMessage('Erreur inattendu');
            })
    }

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter: Object.assign({}, filter) });
    }

    render() {
        return (
            this.state.popupOpen ?
                <TrustiVoiture
                    showMessage={this.props.showMessage}
                    devis={this.state.selectedTrustiVoiture}
                    onClose={this.onClose}
                    reload={this.loadTrustiVoitures}
                />
                :
                <Box style={{ width: "100%" }}
                    align="center" justify="center"
                    alignSelf="center"
                    alignContent="center" gap="medium">
                    <Box width="full" gap="medium" pad={'small'} align='center' justify='center'>
                      <Box width="xlarge" gap="medium" align='center' justify='center'>

                        {!this.state.contact.id &&
                            <>
                                <Box  direction='row' justify="center" alignSelf="center" align="center" gap="medium">
                                    <Refresh refresh={this.loadTrustiVoitures} />
                                    <SearchComponent
                                        search={value => {
                                            this.search(value, this.state.filter.status);
                                        }}
                                        query={this.state.filter._query}
                                        updateQuery={value => this.updateFilter('_query', value)}
                                    />
                                    <AddDevis onAdd={this.addTrustiVoiture} />
                                </Box>
                                <Box justify="between" direction='row' align="center" gap="medium" alignSelf="center">
                                    <StatusFilter
                                        status={this.state.filter.status}
                                        search={value => {
                                            this.updateFilter('status', value);
                                            this.search(this.state.filter._query, value);
                                        }}
                                    />
                                    <DateSort
                                        onChange={value => this.updateFilter('sort', value)}
                                    />

                                </Box>
                            </>
                        }
                        </Box>
                        {getSorted(this.state.trustiVoitures, this.state.filter.sort).id && <Box style={{ height: 30 }} />}
                        <ViewTable
                          reports={getSorted(this.state.trustiVoitures, this.state.filter.sort)}
                          commission_taux={this.props.commission_taux}
                          onDevisSelected={this.openTrustiVoiture}
                          commerciaux={this.props.commerciaux}
                        />
                    </Box>
                </Box>
        )
    }
}

export default TrustiVoitures;
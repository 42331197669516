import { Box, CheckBox } from "grommet";
import {
  Checkmark,
  Close,
  DocumentMissing,
  DocumentText,
  DocumentVerified,
  Edit,
  Money,
  Previous,
  Send,
  Update,
} from "grommet-icons";
import React from "react";

import service, {
  sendYousign,
} from "../../../../services/trusti_pno_horsligne_service";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Recap from "./recap";
import PNOHorsligneInfo from "./info";
import PrimaryButton from "../../../elements/primary_button";
import DevisContratForm from "./devis_contrat";
import {
  getAllCompanies,
  sendContratEmail,
  sendDevisEmail,
} from "../../../../services/contact_service";
import {
  CONTRAT_CLOTURE,
  CONTRAT_ENVOYE,
  DEAL_POSSIBLE,
  DEAL_POSSIBLE_REMPLI,
  DEVIS,
  DEVIS_VALIDE,
  EN_ATTENTE_DE_RESILIATION,
  isDealClosable,
  PROSPECT,
} from "../../../../services/devis_contrat_status";
import { updateClient } from "../../../outils/client_util";
import { clearContextBot, saveContextBot } from "../../../../services/storage_service";
import { convertToDealPossible, sendPresentation } from "../../../../services/common_service";
import DealStatusChange from "../../../elements/deal_status_change";
import ApporteurModification from "../../../elements/apporteur_gestionaire/modification";
import { PNOConversion } from "../../enligne-components/conversion";

const STEPS = {
  info: "Info",
  recap: "Recap",
  devis: "Devis",
  contrat: "Contrat",
};

class TrustiPNOHorsligne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devis: this.props.devis,
      originalDevis: this.props.devis,
      step: this.props.devis.id ? STEPS.recap : STEPS.info,
      loading: false,
      allCompanies: [],
    };
    saveContextBot(props.devis);
  }

  setLoading = (value) => {
    this.setState({ loading: value });
  };

  updateDevis = (field, value) => {
    let devis = this.state.devis;
    if(field === 'client') {
      let client = devis.client || {};
      value = {...client, ...value};
    }
    devis[field] = value;
    this.setState({ devis: Object.assign({}, devis) });
  };

  sendEmailDevis = (devis) => {
    if (!devis.date_effet || (new Date(devis.date_effet) < new Date())) {
      this.props.showMessage("La date d'effet doit être supérieure à la date du jour", "error");
      return;
    }
    devis.status = DEVIS;
    devis.date_devis = new Date();
    devis.model_type = "trusti_pno_horsligne";
    devis.frais_dossier = devis.frais_dossier || 0;
    service
      .updatePNOHorsligne(devis)
      .then((res) => {
        this.setLoading(false);
        this.setState({ step: STEPS.recap, devis: res.data });
        sendDevisEmail(devis)
          .then((res) => {
            this.props.showMessage("Devis a été envoyé", "success");
            this.setState({ devis: res.data });
          })
          .catch((err) =>
            this.props.showMessage("Echec d'envoyer devis", "error")
          );
      })
      .catch((err) => {
        console.log(err);
        this.props.showMessage("Erreur inattendu", "error");
        this.setLoading(false);
      });
  };

  sendEmailContrat = (devis) => {
    if (!devis.date_effet || (new Date(devis.date_effet) < new Date())) {
      this.props.showMessage("La date d'effet doit être supérieure à la date du jour", "error");
      return;
    }
    if (!devis.file_contrat) {
      this.props.showMessage("Le fichier de contrat manquant", "error");
      return;
    }
    devis.status = CONTRAT_ENVOYE;
    devis.date_contrat = new Date();
    service
      .updatePNOHorsligne(devis)
      .then(async (res) => {
        //yousign
        if (devis.file_contrat) {
          //yousign
          await sendYousign({
            id: devis.id,
          })
            .then(({ data }) => {
              this.setLoading(false);
              const _updatedData = { ...res.data, yousign_id: data.yousign_id };
              this.setState({ step: STEPS.recap, devis: _updatedData });
              sendContratEmail(_updatedData)
                .then((res) => {
                  this.props.showMessage("Contrat a été envoyé", "success");
                  this.setState({ devis: res.data });
                })
                .catch((err) =>
                  this.props.showMessage("Echec d'envoyer contrat", "error")
                );
            })
            .catch((err) => {
              console.log(err.response.data.error);
              this.props.showMessage(
                `Erreur: ${err.response.data.error}`,
                "error"
              );
              this.setLoading(false);
            });
        } else {
          this.props.showMessage("Echec d'envoyer contrat", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        this.props.showMessage("Erreur inattendu", "error");
        this.setLoading(false);
      });
  };

  submitModification = async () => {
    let devis = this.state.devis;

    if (!devis.begin_date) {
      devis.begin_date = new Date();
    }
    /*if (devis.status === DEAL_POSSIBLE) {
      devis.status = DEAL_POSSIBLE_REMPLI;
    }*/

    let updatedClient = await updateClient(devis, this.props.showMessage);
    if (!updatedClient) {
      this.props.showMessage('Erreur lors de création/mis à jour de client', 'error');
      return;
    }
    if (!devis.id) {
      service
        .createPNOHorsligne(devis)
        .then((res) => {
          this.props.reload();
          this.props.showMessage("Devis a été bien créé", "success");
          this.setLoading(false);
          this.setState({ step: STEPS.recap, devis: res.data });
        })
        .catch((err) => {
          console.log(err);
          this.props.showMessage("Erreur inattendu", "error");
          this.setLoading(false);
          devis.client = updatedClient;
        });
    } else {
      // if(devis.contract_signed && devis.contract_paid){
      //   devis.status = CONTRAT_CLOTURE;
      // }
      service
        .updatePNOHorsligne(devis)
        .then((res) => {
          this.props.reload();
          this.props.showMessage("Devis a été bien mis à jour", "success");
          this.setLoading(false);
          this.setState({ step: STEPS.recap, devis: {...res.data, need_generate_appel: 0} });
        })
        .catch((err) => {
          console.log(err);
          this.props.showMessage("Erreur inattendu", "error");
          this.setLoading(false);
          devis.client = updatedClient;
        });
    }
  };

  openEditPopup = () => {
    this.setState({ selectedDevis: this.state.devis, editMode: true });
  };

  openDeletePopup = () => {
    this.setState({ selectedDevis: this.state.devis, deletePopupOpen: true });
  };

  resetChange = () => {
    this.setState({
      devis: Object.assign({}, this.state.originalDevis),
      step: STEPS.recap,
    });
  };

  sendForm = () => {
    service
      .sendForm(this.state.devis)
      .then((res) => {
        this.props.showMessage("Formulaire a été envoyé", "success");
        this.setState({ devis: res.data });
      })
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer formulaire", "error")
      );
  };

  sendRelanceSignature = () => {
    service
      .sendRelanceSignature(this.state.devis)
      .then((res) => this.props.showMessage("Email a été envoyé", "success"))
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer formulaire", "error")
      );
  };

  sendRelancePaiement = () => {
    service
      .sendRelancePayment(this.state.devis)
      .then((res) => this.props.showMessage("Email a été envoyé", "success"))
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer formulaire", "error")
      );
  };

  componentDidMount = () => {
    getAllCompanies()
      .then((res) => {
        let _allComs = res.data?.options;
        console.log("_allComs =", _allComs);
        this.setState({ allCompanies: _allComs });
      })
      .catch((err) => console.log("err = ", err));
  };

  sendPresentation = () => {
    sendPresentation(this.state.devis)
      .then((res) => {
        this.props.showMessage("Présentation a été envoyé", "success");
        this.props.reload();
        this.setState({ devis: res.data })
      })
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer présentation", "error")
      );
  };

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.loading}>
        <Box pad="small" width="xsmall">
          <PrimaryButton icon={<Previous />} onClick={() => {
            clearContextBot();
            this.props.onClose && this.props.onClose();
          }} />
        </Box>
        {this.state.step === STEPS.info && (
          <PNOHorsligneInfo
            contact={this.state.contact}
            devis={this.state.devis || {}}
            updateDevis={this.updateDevis}
            showMessage={this.props.showMessage}
          />
        )}
        {(this.state.step === STEPS.devis ||
          this.state.step === STEPS.contrat) && (
            <DevisContratForm
              contact={this.state.contact}
              devis={this.state.devis || {}}
              updateDevis={this.updateDevis}
              allCompanies={this.state.allCompanies}
              showMessage={this.props.showMessage}
              submitModification={this.submitModification}
              isModeContrat={this.state.step === STEPS.contrat}
              sendEmailDevisContrat={
                this.state.step === STEPS.contrat
                  ? this.sendEmailContrat
                  : this.sendEmailDevis
              }
            />
          )}

        {this.state.step === STEPS.recap && (
          <Recap devis={this.state.devis} close={this.props.onClose} updateDevis={this.updateDevis}
            onSave={this.submitModification} />
        )}
        <Box style={{ height: 40 }} />
        {this.state.step === STEPS.recap &&
          <ApporteurModification
            devis={this.state.devis}
            apporteur={this.state.devis.apporteur}
            showMessage={this.props.showMessage}
            onReload={this.props.reload}
          />
        }
        {this.state.step === STEPS.recap &&
          [CONTRAT_ENVOYE, CONTRAT_CLOTURE].includes(
            this.state.devis.status
          ) && (
            <Box
              direction="row"
              margin="medium"
              gap="medium"
              align="center"
              justify="center"
            >
              <CheckBox
                style={{
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
                justify="center"
                label="Contrat signé"
                checked={this.state.devis.contract_signed}
                onChange={(event) => {
                  this.updateDevis("contract_signed", event.target.checked);
                  if ((this.state.devis.contract_paid || !(this.state.devis.frais_dossier > 0)) && event.target.checked) {
                    this.updateDevis("status", CONTRAT_CLOTURE);
                  } else {
                    this.updateDevis("status", CONTRAT_ENVOYE);
                  }
                  this.submitModification();
                }}
              />
              <PrimaryButton
                label={`Relance signature`}
                icon={<DocumentVerified />}
                onClick={() => {
                  this.sendRelanceSignature();
                }}
              />
              {this.state.devis.frais_dossier > 0 && (
                <Box direction="row" gap="medium">
                  <CheckBox
                    style={{
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                    justify="center"
                    label="Contrat payé"
                    checked={this.state.devis.contract_paid}
                    onChange={(event) => {
                      this.updateDevis("contract_paid", event.target.checked);
                      if (
                        this.state.devis.contract_signed &&
                        event.target.checked
                      ) {
                        this.updateDevis("status", CONTRAT_CLOTURE);
                      } else {
                        this.updateDevis("status", CONTRAT_ENVOYE);
                      }
                      this.submitModification();
                    }}
                  />
                  <PrimaryButton
                    label={`Relance paiement`}
                    icon={<Money />}
                    onClick={() => {
                      this.sendRelancePaiement();
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        {this.state.step === STEPS.recap &&
          ([EN_ATTENTE_DE_RESILIATION].includes(this.state.devis.status) ||
            this.state.devis.resiliation_done) && (
            <Box
              direction="row"
              margin="small"
              gap="medium"
              align="center"
              justify="center"
            >
              <CheckBox
                style={{
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
                justify="center"
                label="Résiliation effectuée"
                checked={this.state.devis.resiliation_done}
                disabled={this.state.devis.resiliation_done}
                onChange={(event) => {
                  this.updateDevis("resiliation_done", event.target.checked);
                  this.submitModification();
                }}
              />
            </Box>
          )}
        <Box direction="row" align="center" justify="center" gap="medium">
          {this.state.step === STEPS.info && (
            <PrimaryButton
              label={`Sauvegarder`}
              icon={<Checkmark />}
              background="#59A4F4"
              onClick={this.submitModification}
            />
          )}
          {this.state.step === STEPS.info && (
            <PrimaryButton
              label={`Reset`}
              icon={<Close />}
              background={"#6c757d"}
              onClick={() => {
                if (this.state.devis?.id) {
                  this.resetChange();
                };
              }}
            />
          )}
          {this.state.step === STEPS.recap && (
            <PrimaryButton
              label={`Envoyer formulaire`}
              icon={<Send />}
              background="#28a745"
              onClick={this.sendForm}
            />
          )}
          {this.state.step === STEPS.recap && (
            <PrimaryButton
              label={`Modifier`}
              icon={<Edit />}
              onClick={() => this.setState({ step: STEPS.info })}
            />
          )}
          {this.state.step === STEPS.recap &&  !isDealClosable(this.state.devis) && (
            <PrimaryButton
              label={`Envoyer un devis`}
              icon={<DocumentText />}
              background="#64AEF6"
              onClick={() => this.setState({ step: STEPS.devis })}
            />
          )}
          {this.state.step === STEPS.recap && (
            <DealStatusChange
              deal={this.state.devis}
              showMessage={this.props.showMessage}
              onReload={this.props.reload}
              path={'trusti-pno-horsligne'}
            />
          )}
          {this.state.step === STEPS.recap && this.state.devis?.status === PROSPECT && (
            <PrimaryButton
              label="Convertir à Deal possible"
              icon={<Update />}
              onClick={() => {
                let devis = this.state.devis;
                devis.status = DEAL_POSSIBLE;
                convertToDealPossible(devis, 'trusti-pno-horsligne')
                  .then(res => this.setState({ devis: res.data }));
              }}
            />
          )}
          {this.state.step === STEPS.recap &&
            ([DEVIS_VALIDE, CONTRAT_ENVOYE, CONTRAT_CLOTURE].includes(
              this.state.devis.status
            ) ||
              this.state.devis.resiliation_done) && (
              <PrimaryButton
                label={`Envoyer un contrat`}
                icon={<DocumentVerified />}
                background="#64AEF6"
                onClick={() => this.setState({ step: STEPS.contrat })}
              />
            )}
        </Box>
        <Box
          direction="row"
          margin="small"
          align="center"
          justify="center"
          gap="medium"
        >
          {this.state.step === STEPS.recap &&
            [DEVIS_VALIDE].includes(this.state.devis.status) && (
              <PrimaryButton
                label={`Envoyer vers résiliation`}
                icon={<DocumentMissing />}
                background="#000"
                onClick={() => {
                  this.updateDevis("status", EN_ATTENTE_DE_RESILIATION);
                  this.updateDevis("date_resiliation", new Date());
                  this.submitModification();
                }}
              />
            )}

          {this.state.step === STEPS.recap &&
            <PrimaryButton
              onClick={this.sendPresentation}
              label={`Envoyer une présentation`}
              icon={<Send />}
            />
          }

          {this.state.step === STEPS.recap &&
            <PNOConversion
              deal={this.state.devis}
              showMessage={this.props.showMessage}
              blockingHandler={val => this.setState({ loading: val })}
            />
          }
        </Box>
        <Box style={{ height: 100 }} />
      </BlockUi>
    );
  }
}

export default TrustiPNOHorsligne;

const {baseAPI} = require('../config/config.json');
const axios = require('axios');
const storageService = require('./storage_service');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const login = (username, password) => {
    let body = {
        identifier: username,
        password
    }
    return axios.post(`${baseAPI}/auth/local`, body, HEADERS)
}

const logout = () => {
    storageService.clearAuth();
}

const verifyConnected = () => {
    return axios.get(`${baseAPI}/status`, {headers: {
        'Authorization': `Bearer ${storageService.getToken()}`
    }})
}

module.exports = {
    login,
    verifyConnected,
    logout
}
import React from "react";
import { Box, Button, Card, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { getMisEnRelationByUser } from "./service";
import Refresh from "../elements/refresh";
import SearchComponent from "../elements/search";
import DateSort from "../elements/date_sort";
import { getSimpleDateFR, getSorted } from "../../services/utils";
import CustomSelect from "../elements/select_fill";
import { getDevisInfo } from "../trustibot/rdv_rappel/service/rdv_rappel";
import DevisModal from "../trustibot/rdv_rappel/Components/DevisModal";
import { View } from "grommet-icons";

class RelationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      popupOpen: false,
      selectedUser: {},
      selectedDeal: null,
      statut: null,
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    };
  }
  componentDidMount = () => {
    this.loadRelations();
  };

  loadRelations = () => {
    this.setState({ leads: [] }, () => {
      var params = {};
      if (this.state.statut) {
        params["status"] = this.state.statut;
      }
      var qs = require('qs');
      if (this.state.query) {
        params["_where"] =  {_or : [
          {"apporteur.nom_contains" : this.state.query},
          {"apporteur.prenom_contains" : this.state.query},
          {"client.firstname_contains" : this.state.query},
          {"client.lastname_contains" : this.state.query}
        ]};
      }
      getMisEnRelationByUser(this.props.user?.id, qs.stringify(params))
        .then((res) => {
          this.setState({ leads: res.data });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false });
    this.loadRelations();
  };

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  _optionStatus = [
    { name: "Tous les statuts", value: null },
    { name: "Envoyé", value: "Envoyé" },
    { name: "En cours", value: "En cours" },
    { name: "Perdu", value: "Perdu" },
    { name: "Clôturé", value: "Clôturé" }
  ];

  render() {
    return (
      <Box style={{ width: "100%" }} margin={{ top: "large" }}
        align="center" justify="center"
        alignSelf="center"
        alignContent="center" gap="medium">
        <Box gap="medium">
          <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
            <Refresh refresh={this.loadRelations} />
            <Box direction='row' gap='small'
              alignSelf="center" >
              <Box pad="xsmall" justify="center" alignContent="center"
                gap="small"
                align="center" direction="row">
                <Text size="xsmall">Select d'un statut</Text>
                <CustomSelect
                  options={this._optionStatus}
                  labelKey={"name"}
                  valueKey={{ key: "name", reduce: true }}
                  placeholder='Tous les statuts'
                  defaultValue={this._optionStatus[0]}
                  onChange={({ option }) => {
                    this.setState({
                      statut: option.value
                    });
                    this.loadRelations();
                  }}
                />
              </Box>
            </Box>
            <SearchComponent
              search={value => {
                this.setState({ query: value }, () => {
                  this.loadRelations();
                });
              }}
              query={this.state.query}
              updateQuery={value => this.setState({ query: value })}
            />
            <DateSort
              onChange={value => this.updateFilter('sort', value)}
            />
          </Box>


          <Table>
            <TableHeader>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Text>Création/<br />Modification</Text>
                </TableCell>
                <TableCell>
                  <Text>Nom</Text>
                </TableCell>
                <TableCell>
                  <Text>Prénom</Text>
                </TableCell>
                <TableCell>
                  <Text>Phone</Text>
                </TableCell>
                <TableCell><Text>Email</Text></TableCell>
                <TableCell><Text>Statut</Text></TableCell>
                <TableCell><Text>Apporteur</Text></TableCell>
                <TableCell><Text>Produit</Text></TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getSorted(this.state.leads, this.state.filter.sort).map((lead, index) => (
                <TableRow key={index + 1}>
                  <TableCell>
                    {lead.model_name && <DevisModal icon={<View color="#e92f72" />} devis_id={lead.model_id} devis_type={lead.model_name} callback={() => { }} ></DevisModal>}
                  </TableCell>
                  <TableCell>
                    <Text size="small"><strong>{getSimpleDateFR(lead.createdAt)}</strong><br />{getSimpleDateFR(lead.updatedAt)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.client?.lastname}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.client?.firstname}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.client?.phone}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.client?.email}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.status}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.apporteur ? `${lead.apporteur.prenom} ${lead.apporteur.nom}` : ''}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{lead.produit}</Text>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  }
}
export default RelationList;

import { Box, Card, Grid, Text, Select } from "grommet";
import { Checkmark } from "grommet-icons";
import React, { useEffect, useState } from "react";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { getAppUsersValidated, updateApporteurTransaction } from "../../my_trustiway/service";
import PrimaryButton from "../primary_button";

const ApporteurModification = ({ apporteur, devis, onReload, showMessage }) => {
    const [apporteurs, setApporteurs] = useState([]);
    const [apporteursOrigin, setApporteursOrigin] = useState([]);
    const [selectedApporteur, setSelectedApporteur] = useState(apporteur ? { ...apporteur, name: `${apporteur.prenom} ${apporteur.nom}` } : null);
    const [blocking, setBlocking] = useState(false);

    useEffect(() => {
        loadApporteur();
    }, devis);

    const loadApporteur = () => {
        setBlocking(true);
        getAppUsersValidated().then(res => {
            let _apporteurs = res.data.filter(a => a.nom || a.prenom).map(e => { return { ...e, name: `${e.prenom} ${e.nom}` } });
            setApporteurs(_apporteurs);
            setApporteursOrigin(_apporteurs);
            setBlocking(false);
        }).catch(err => setBlocking(false));
    }

    const saveApporteurTransaction = () => {
        if (selectedApporteur) {
            updateApporteurTransaction(devis, selectedApporteur)
                .then(res => {
                    showMessage('Apporteur changé avec succès', 'success');
                    onReload();
                })
                .catch(err => {
                    console.log(err);
                    showMessage('Error inattendu', 'error');
                })
        }
    }

    const searchContact = (query) => {
        console.log('query', query);
        let _apporteurs = apporteursOrigin.filter((s) => s.name.toLowerCase().includes(query.toLowerCase()));
        let _apporteursOthers = apporteursOrigin.filter((s) => !s.name.toLowerCase().includes(query.toLowerCase()));

        setApporteurs(_apporteurs.concat(_apporteursOthers));
    };


    return (
        <BlockUi tag="div" blocking={blocking}>

            <Box justify='center' align="center" style={{ marginTop: 20 }}>
                <Text textAlign="center" style={{ fontWeight: "bold" }}>{apporteur ? "Modification d'apporteur :" : "Ajouter un apporteur :"} </Text>
                <Text margin={"xsmall"} size='xsmall' >{"(La source de la vente changera à APP)"}</Text>
                <Box gap="small">
                    <Select
                        options={apporteurs}
                        value={selectedApporteur?.name || 'none'}
                        onChange={({ option }) => {
                            console.log(option);
                            setSelectedApporteur(option)
                        }}
                        labelKey={'name'}
                        valueKey={{ key: 'name', reduce: true }}
                        onSearch={(text) => {
                            const escapedText = text.replace(
                                /[-\\^$*+?.()|[\]{}]/g,
                                "\\$&"
                            );
                            searchContact(escapedText);
                        }}
                    />
                    <PrimaryButton
                        label={`Mettre à jour la transaction`}
                        icon={<Checkmark />}
                        background="#E82C70"
                        onClick={saveApporteurTransaction}
                    />
                </Box>
            </Box>

        </BlockUi >
    )
}

export const ApporteurModificationV2 = ({ apporteur, devis, onReload, showMessage }) => {
    const [apporteurs, setApporteurs] = useState([]);
    const [apporteursOrigin, setApporteursOrigin] = useState([]);
    const [selectedApporteur, setSelectedApporteur] = useState(apporteur ? { ...apporteur, name: `${apporteur.prenom} ${apporteur.nom}` } : null);
    const [blocking, setBlocking] = useState(false);

    useEffect(() => {
        loadApporteur();
    }, devis);

    const loadApporteur = () => {
        setBlocking(true);

        getAppUsersValidated().then(res => {
            let _apporteurs = res.data.filter(a => a.nom || a.prenom).map(e => { return { ...e, name: `${e.prenom} ${e.nom}` } });
            setApporteurs(_apporteurs);
            setApporteursOrigin(_apporteurs);;
            setBlocking(false);
        })
            .catch(err => setBlocking(false));
    }

    const saveApporteurTransaction = () => {
        if (selectedApporteur) {
            updateApporteurTransaction(devis, selectedApporteur)
                .then(res => {
                    showMessage('Apporteur changé avec succès', 'success');
                    onReload();
                })
                .catch(err => {
                    console.log(err);
                    showMessage('Error inattendu', 'error');
                })
        }
    }
    const searchContact = (query) => {
        console.log('query', query);
        let _apporteurs = apporteursOrigin.filter((s) => s.name.toLowerCase().includes(query.toLowerCase()));
        let _apporteursOthers = apporteursOrigin.filter((s) => !s.name.toLowerCase().includes(query.toLowerCase()));

        setApporteurs(_apporteurs.concat(_apporteursOthers));
    };


    return (
        <BlockUi tag="div" blocking={blocking}>
            <Box width={'full'} style={{ marginTop: 20 }}>
                <Box width="100%" background="brand" pad="5px 20px" style={{ borderRadius: "8px" }}>
                    <Text color="white" weight={600}>{apporteur ? "Modification d'apporteur" : "Ajouter un apporteur"}</Text>
                </Box>

                <Text margin={"xsmall"} size='xsmall' >{"(La source de la vente changera à APP)"}</Text>
                <Grid columns={["1/2", "1/2"]} gap="small" width="calc(100% - 15px)">
                    <Select
                        options={apporteurs}
                        value={selectedApporteur?.name || 'none'}
                        onChange={({ option }) => {
                            console.log(option);
                            setSelectedApporteur(option)
                        }}
                        labelKey={'name'}
                        valueKey={{ key: 'name', reduce: true }}
                        onSearch={(text) => {
                            const escapedText = text.replace(
                                /[-\\^$*+?.()|[\]{}]/g,
                                "\\$&"
                            );
                            searchContact(escapedText);
                        }}
                    />
                    <PrimaryButton
                        label={`Modifier`}
                        background="#E82C70"
                        onClick={saveApporteurTransaction}
                    />
                </Grid>
            </Box>
        </BlockUi>
    )
}


export default ApporteurModification;

import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box, Card, Text } from 'grommet';
import moment from 'moment';
import React from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Bar } from 'react-chartjs-2';
import adminService from '../../services/admin_service';
import { getCommissionTauxConfig } from '../../services/dashboard';
import { CONTRAT_CLOTURE, EN_ATTENTE_BANQUE } from '../../services/devis_contrat_status';
import { getMonthOptions, toCurrency } from '../../services/utils';
import { ClosedStatusReport } from '../dashboard/reports_closed';
import CustomSelect from '../elements/select_fill';
import { products } from '../outils/client_util';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'left',
        },
        datalabels: {
            color: '#707070',
            anchor: 'end',
            align: 'end',
            offset: 0,
            formatter: value => value ? toCurrency(value) : '',
            font: {
                weight: 'bold',
            },
            rotation: -50
        }
    }
};

class AdminCommissionReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            commission: {
                totalCommission: undefined,
                graphData: [],
                reports: []
            },
            filter: {
                commercial_id: 'All',
                startDate: moment().startOf('month').toDate(),
                endDate: new Date(),
                sort: 'Produit',
                status: `${CONTRAT_CLOTURE},${EN_ATTENTE_BANQUE}`
            },
            selectedMonth: 'Mois en cours',
            selectedProduct: undefined,
            originalCommission: {
                totalCommission: undefined,
                graphData: [],
                reports: []
            },
            originalFilter: {
                commercial_id: 'All',
                startDate: moment().startOf('month').toDate(),
                endDate: new Date(),
                sort: 'Produit',
                status: `${CONTRAT_CLOTURE}`
            },
            commission_taux: null,
            blocking: false
        }
    }

    getCommercialName = (commercial_id) => {
        let commerciaux = this.props.commerciaux;
        if (commerciaux) {
            let commercial = commerciaux.find(c => c.id === commercial_id);
            return commercial?.name;
        }
    }

    componentDidMount() {
        this.loadData();

        getCommissionTauxConfig()
            .then(res => {
                this.setState({ commission_taux: res.data });
            })
    }

    loadData = () => {
        this.setState({ blocking: true });

        adminService.getAdminCommission(this.state.filter)
            .then(res => {
                console.log(res.data);
                this.setState({ commission: res.data, originalCommission: Object.assign({}, res.data) }, this.updateProductFilter);
                this.setState({ blocking: false });
            })
            .catch(err => {
                this.setState({ blocking: false });
            })
    }

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter: Object.assign({}, filter) }, () => this.loadData())
    }

    updateProductFilter = () => {
        let option = this.state.selectedProduct;
        let originalCommission = this.state.originalCommission;
        let commission = this.state.commission;

        if (option) {
            commission.reports = originalCommission.reports.filter(s => s.product === option).map(s => Object.assign({}, s));
            commission.graphData = originalCommission.graphData.map(s => Object.assign({}, s));
            commission.graphData.forEach(s => {
                if (s.product === option) return;
                s.value = 0;
                s.frais = 0;
                s.accompt = 0;
                s.com = 0;
                s.totalTrustiway = 0;
            })
            let totalCommission = 0;
            commission.graphData.forEach(s => totalCommission += s.value);
            commission.totalCommission = totalCommission;
        } else {
            commission.reports = originalCommission.reports.map(s => Object.assign({}, s));
            commission.graphData = originalCommission.graphData.map(s => Object.assign({}, s));
            commission.totalCommission = originalCommission.totalCommission;
            commission.totalTrustiway = originalCommission.totalTrustiway;
        }

        this.setState({ commission: Object.assign({}, commission) });
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.blocking}>
                <Box width="full" justify="center" align='center'>
                    <Box width="xlarge" justify="center" align='center'>
                        <Box justify="center" alignSelf='center'>

                            <Card width='xlarge' justify='around' margin='medium' pad='small' direction='row'>
                                <Box gap='xsmall'>
                                    <Text>Selection d'un mois</Text>
                                    <CustomSelect
                                        options={getMonthOptions().filter(s => s.name !== '12 derniers mois')}
                                        defaultValue={getMonthOptions()[0].name}
                                        labelKey={"name"}
                                        valueKey={{ key: "name", reduce: true }}
                                        onChange={({ option }) => {
                                            let filter = { ...this.state.originalFilter };
                                            filter.startDate = option.startDate;
                                            filter.endDate = option.endDate;
                                            filter.sort = option.name === '12 derniers mois' ? 'Mois' : 'Produit';
                                            filter.commercial_id = this.state.filter.commercial_id || 'All';

                                            this.setState({ filter: Object.assign({}, filter), selectedMonth: option.name }, () => this.loadData());
                                        }}
                                    />
                                </Box>
                                <Box gap='xsmall'>
                                    <Text>Filtrage sur Produit</Text>
                                    <CustomSelect key={2} options={products} multiple={false} onChange={({ option }) => {
                                        this.setState({ selectedProduct: option === 'Tous' ? '' : option }, () => this.updateProductFilter());
                                    }} />
                                </Box>
                                {this.props.commerciaux &&
                                    <Box gap='xsmall'>
                                        <Text>Commercial</Text>
                                        <CustomSelect
                                            key={3}
                                            options={this.props.commerciaux}
                                            onChange={({ option }) => this.updateFilter('commercial_id', option.id)}
                                            labelKey={'name'}
                                            valueKey={{ key: 'name', reduce: true }}
                                        />
                                    </Box>
                                }
                            </Card>
                        </Box>
                        <Box justify="center" alignSelf='center' margin="xsmall" gap='medium' direction='row'>
                            <Card pad="small" >
                                <Text textAlign="center">Total de commission du {this.state.selectedMonth}</Text>
                                <Text textAlign="center" size="large" style={{ fontWeight: 'bold', color: '#E82C70' }}>{toCurrency(this.state.commission.totalCommission)}</Text>
                                <Text size='small'>Commisison : <span><strong style={{ color: '#E82C70' }}>{getTotalCommission(this.state.commission.graphData).com}</strong></span></Text>
                                <Text size='small'>Frais : <span><strong style={{ color: '#E82C70' }}>{getTotalCommission(this.state.commission.graphData).frais}</strong></span></Text>
                            </Card>
                            <Card pad="small" >
                                <Text textAlign="center">Total Trustiway du {this.state.selectedMonth}</Text>
                                <Text textAlign="center" size="large" style={{ fontWeight: 'bold', color: '#E82C70' }}>
                                    {toCurrency(
                                        this.state.commission.graphData.map(s => s.totalTrustiway).reduce((s, a) => s + a, 0)
                                    )}
                                </Text>
                            </Card>
                        </Box>
                        {this.state.commission.graphData &&
                            <Box width="xlarge" justify="center" align="center" alignSelf="center" margin="medium">
                                <Bar
                                    options={options}
                                    data={getGraphData(this.state.commission.graphData)}
                                    plugins={[ChartDataLabels]}
                                />
                            </Box>
                        }
                    </Box>
                </Box>

                <Box gap='small' pad='small' width={'full'}>
                        <Text style={{ fontWeight: 'bold' }} size='large' textAlign="center">Mes commissions</Text>

                        <ClosedStatusReport showMessage={() => { }}
                            reports={this.state.commission.reports}
                            reloadAll={this.loadData}
                            commerciaux={this.props.commerciaux}
                            commission_taux={this.state.commission_taux}
                        />
                    </Box>
            </BlockUi>
        )
    }
}

const getTotalCommission = data => {
    if (!data) return {
        com: toCurrency(),
        frais: toCurrency()
    };
    let com = 0;
    let frais = 0;
    data.forEach(s => {
        com += s.com;
        frais += s.frais + s.accompt;
    })
    return {
        com: toCurrency(com),
        frais: toCurrency(frais)
    }
}

const getGraphData = graphData => {
    let labels = graphData.map(s => s.product);
    const backgroundColors = [
        "#E52764", "#33BC00", "#FC0000", "#73AAAA",
    ]
    let datasets = ['frais', 'com', 'accompt', 'totalTrustiway'].map((label, index) => ({
        label,
        backgroundColor: backgroundColors[index],
        data: graphData.map(s => s[label])
    }));
    return {
        labels, datasets
    }
}

export default AdminCommissionReport;
import { Box, Card } from "grommet";
import { Text } from "grommet/components/Text";
import moment from "moment";
import React from "react";
import { getGestionCollabs } from "../../services/dashboard";
import { getUser, hasAdministrativeRole, hasClassiqueManagerRole, isCommercialClassique } from "../../services/storage_service";
import { getMonthOptions } from "../../services/utils";
import CustomSelect from "../elements/select_fill";
import StatusReport from "./status_report";
import { getCreditsDashboard } from "../../services/credit_service";
import { ABONDONS, ARCHIVE, PERDU } from "../../services/credit_status";

class PerduReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commerciaux: [],
            all_commerciaux: props.all_commerciaux,
            filter: {
                startDate: moment(new Date())
                    .subtract(11, "year")
                    .startOf("month")
                    .toDate(),
                endDate: new Date(),
                status_in: [ABONDONS, PERDU, ARCHIVE],
                commercial_ids: props.commerciaux && !props.personal ? "All" : undefined,
            },
            reports: []
        };
    }

    componentDidMount() {
        let without = { name: 'Sans commercial', id: 'EMPTY' };
        getGestionCollabs()
            .then(res => {
                const onlyCommerciaux = res.data.filter(c => isCommercialClassique(c) || hasClassiqueManagerRole(c))
                this.setState({ commerciaux: [without].concat(onlyCommerciaux) });
                this.loadData();
            });
    }

    loadData = async () => {
        console.log('loadData:', Date());
        getCreditsDashboard(this.state.filter).then((res) => {
            this.setState({ reports: res.data })
        });

    };

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;
        if (value === "Tous") filter[field] = "";
        this.setState({ filter: Object.assign({}, filter) }, () => this.loadData());
    };

    render() {
        return (
            <Box
                style={{ width: "100%" }}
                margin="medium"
                pad="medium"
                gap="medium"
                justify="center"
                alignContent="center"
                alignSelf="center"
                align="center"
            >
                <Card
                    width="xlarge"
                    justify="around"
                    margin="medium"
                    pad="small"
                    direction="row"
                >
                    <Box gap="xsmall">
                        <Text>Select d'un mois</Text>
                        <CustomSelect
                            options={getMonthOptions().filter(
                                (s) => s.name !== "12 derniers mois"
                            )}
                            labelKey={"name"}
                            valueKey={{ key: "name", reduce: true }}
                            onChange={({ option }) => {
                                let filter = this.state.filter;
                                filter.startDate = option.startDate;
                                filter.endDate = option.endDate;
                                this.setState(
                                    {
                                        filter: Object.assign({}, filter),
                                        selectedMonth: option.name,
                                    },
                                    () => this.loadData()
                                );
                            }}
                        />
                    </Box>
                    <Box gap="xsmall" >
                        <Text >Produit de crédit</Text>
                        <CustomSelect
                            size="xsmall"
                            options={[
                                "Crédit Immobilier",
                                "Regroupement de crédit",
                                "Crédit à la consommation",
                                "Prêt hypothécaire",
                            ]}
                            value={this.state.filter.sub_product}
                            onChange={({ option }) => {
                                let filter = this.state.filter;
                                filter.sub_product = option;
                                this.setState(
                                    {
                                        filter: Object.assign({}, filter),
                                    },
                                    () => this.loadData()
                                );
                            }}
                            clear
                        />
                    </Box>
                    <Box gap='xsmall' >
                        <Text >Sous produit de crédit</Text>
                        <CustomSelect
                            size="xsmall"
                            options={[
                                "Regroupement de crédits",
                                "Regroupement de crédits projet"
                            ]}
                            value={this.state.filter.sub_product_detail}
                            onChange={({ option }) => {
                                let filter = this.state.filter;
                                filter.sub_product_detail = option;
                                this.setState(
                                    {
                                        filter: Object.assign({}, filter),
                                    },
                                    () => this.loadData()
                                );
                            }}
                            clear
                        />
                    </Box>
                    {(!hasClassiqueManagerRole() && !hasAdministrativeRole() && getUser()?.binome_id) && (
                        <Box gap="xsmall">
                            <Text>Binôme</Text>
                            <CustomSelect
                                options={[
                                    { id: getUser().id, name: 'Vous' },
                                    { id: getUser().binome_id, name: 'Votre binôme' },
                                    { id: 'All', name: 'Tous' }
                                ]}
                                onChange={({ value: nextValue }) => {
                                    let ids = nextValue === 'All' ? [getUser().id, getUser().binome_id] : [nextValue];
                                    this.updateFilter("commercial_ids", ids);
                                }}
                                labelKey={'name'}
                                valueKey={{ key: 'id', reduce: true }}
                                value={
                                    this.state.filter.commercial_ids?.length === 2 ? 'All' :
                                        this.state.filter.commercial_ids?.length === 1 ? this.state.filter.commercial_ids[0] : undefined
                                }
                            />
                        </Box>
                    )}


                    {this.state.commerciaux && !this.props.personal && (
                        <Box gap="xsmall">
                            <Text>Commercial</Text>
                            <CustomSelect
                                options={this.state.commerciaux}
                                onChange={({ option }) =>
                                    this.updateFilter("commercial_ids", [option.id])
                                }
                                labelKey={"name"}
                                valueKey={{ key: "name", reduce: true }}
                            />
                        </Box>
                    )}
                </Card>

                <Box direction="column" gap="medium" justify="center" width={'full'}>
                    <StatusReport showMessage={this.props.showMessage}
                        reports={this.state.reports}
                        status={`${PERDU}, ${ARCHIVE}, ${ABONDONS}`}
                        reloadAll={this.loadData}
                        commerciaux={this.state.commerciaux}
                        all_commerciaux={this.state.all_commerciaux}
                    />
                </Box>
            </Box>
        );
    }
}

export default PerduReports;

import React from "react";
import { Box } from "grommet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Info from "./info";
import PrimaryButton from "../../../elements/primary_button";
import service from "../service";
import {
  Checkmark,
  Close,
  Compare,
  DocumentText,
  Edit,
  Previous,
  Send,
} from "grommet-icons";
import Recap from "./recap";
import { CONTRAT_CLOTURE, isDealClosable } from "../../../../services/devis_contrat_status";
import { updateClient } from "../../../outils/client_util";
import { clearContextBot, getCommercialId, saveContextBot } from "../../../../services/storage_service";
import { isSubscribed } from "../../../elements/contract_details";
import ContratView from "../../enligne-components/contrat_view";
import DealStatusChange from "../../../elements/deal_status_change";
import { sendPresentation } from "../../../../services/common_service";
import ApporteurModification from "../../../elements/apporteur_gestionaire/modification";

const showMessage = (message, type) => {
  toast[type](message);
};

const verifyData = (devis) => {
  let result = true;
  if (!devis.same_address) {
    result = result && !!devis.zip && !!devis.address && !!devis.city;
  }
  result =
    result &&
    !!devis.email &&
    !!devis.phone &&
    !!devis.address &&
    !!devis.zip &&
    !!devis.city &&
    !!devis.civility &&
    !!devis.effectiveDate;

  if (devis.civility === "Société") {
    result = result && !!devis.raisonSocial;
  } else {
    result = result && !!devis.firstname && !!devis.lastname;
  }

  return result;
};

class TrustiMHTempo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: props.devis.id ? true : false,
      loading: false,
      devis: props.devis.id
        ? props.devis
        : {
          civility: "",
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          address: "",
          zip: "",
          location_city: "",
          location_address: "",
          location_zip: "",
          city: "",
          begin_date: undefined,
          effectiveDate: new Date(),
        },
    };
    saveContextBot(props.devis);
  }

  updateDevis = (field, value) => {
    let devis = this.state.devis;
    if(field === 'client') {
      let client = devis.client || {};
      value = {...client, ...value};
    }
    devis[field] = value;
    this.setState({ devis: Object.assign({}, devis) });
  };

  submit = async () => {
    let handler,
      devis = Object.assign({}, this.state.devis);
    let updatedClient = await updateClient(devis, this.props.showMessage);
    if (!updatedClient) {
      this.props.showMessage("Client introuvable", "error");
      return;
    }
    if (devis.apporteur && devis.apporteur.id) {
      devis.apporteur = devis.apporteur.id;
      devis.apporteur_id = devis.apporteur.id;
    }
    devis.date_effet = devis.effectiveDate;
    if (devis.id) {
      handler = service.updateDevis(devis);
    } else {
      devis.commercial_id = getCommercialId();
      handler = service.createDevis(devis);
    }
    await handler
      .then((res) => {
        this.props.showMessage("Info a été sauvegardé avec succès", "success");
        this.setState({ devis: {...res.data, need_generate_appel: 0}, viewMode: true });
        this.props.reload();
        this.getPrice({...res.data, need_generate_appel: 0}, true);
      })
      .catch((err) => {
        console.log(err.response?.data);
        this.props.showMessage("Erreur inattendu", "error");
        devis.client = updatedClient;
      });
  };

  toRecap = () => {
    if (!verifyData(this.state.devis)) {
      showMessage("Veuillez remplir tous les champs", "error");
      return;
    }
    this.setState({ viewMode: true });
  };

  sendDevis = async (devis) => {
    console.log('details: ', devis.emailEventIds)
    service
      .sendForm(this.state.devis)
      .then((res) =>{
        this.props.showMessage(
          "Le formulaire a été envoyé avec succès",
          "success"
        );
        this.setState({devis: {...res.data, need_generate_appel: 0}});
      })
      .catch((err) => this.props.showMessage("Erreur inattendu", "error"));
  };

  getPrice = async (devis) => {
    this.setState({ loading: true });
    service
      .estimateDevis(devis)
      .then((res) => {
        let devis = {...res.data, need_generate_appel: 0};
        this.setState({ devis, loading: false });
        this.props.reload();
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        this.props.showMessage("Prix introuvable", "error");
      });
  };

  isEstimatable = () => {
    let devis = this.state.devis;
    if (!devis.id) return false;
    return devis.capitalMobilier !== undefined && devis.bienType;
  };

  sendPresentation = () => {
    sendPresentation(this.state.devis)
      .then((res) => {
        this.props.showMessage("Présentation a été envoyé", "success");
        this.props.reload();
        this.setState({devis: res.data})
      })
      .catch((err) =>
        this.props.showMessage("Echec d'envoyer présentation", "error")
      );
  };

  render() {
    return (
      isSubscribed(this.state.devis?.status) ?
        <ContratView
          sub={this.state.devis}
          title="Souscription assurance MH tempo"
          assurance_type="Trusti MH tempo enligne"
          showMessage={this.props.showMessage}
          onClose={this.props.onClose}
          onReload={this.props.loadTrustiMHTempos}
        /> :
        <BlockUi tag="div" blocking={this.state.loading}>
          <Box
            style={{ width: "100%" }}
            justify="center"
            alignContent="center"
            align="center"
          >

            <Box style={{ width: "100%" }}>
              <Box pad="small" width="xsmall" >
                <PrimaryButton
                  icon={<Previous />}
                  onClick={() => {
                    clearContextBot();
                    this.props.onClose && this.props.onClose();
                  }}
                />
              </Box>
            </Box>
            <Box  pad={'medium'}>

              {this.state.viewMode === false ? (
                <Info
                  devis={this.state.devis}
                  updateDevis={this.updateDevis}
                  toRecap={this.toRecap}
                />
              ) : (
                <Recap
                  devis={this.state.devis}
                  sendDevis={this.sendDevis}
                  submit={this.submit}
                  toEditMode={() => this.setState({ viewMode: false })}
                  updateDevis={this.updateDevis}
                  onSave={this.submit}
                />
              )}
            </Box>

            <Box style={{ height: 40 }} />
            <ApporteurModification
							devis={this.state.devis}
							apporteur={this.state.devis.apporteur}
							showMessage={this.props.showMessage}
							onReload={this.props.reload}
						/>

            <Box pad={'medium'}>
              <Box direction="row" align="center" justify="center" gap="medium">
                {this.state.viewMode === false && (
                  <PrimaryButton
                    label={`Valider`}
                    icon={<Checkmark />}
                    background="#59A4F4"
                    onClick={this.submit}
                  />
                )}
                {this.state.viewMode === true && (
                  <PrimaryButton
                    label={`Modifier`}
                    icon={<Edit />}
                    background="#59A4F4"
                    onClick={() => this.setState({ viewMode: false })}
                  />
                )}
                <DealStatusChange
                  deal={this.state.devis}
                  showMessage={this.props.showMessage}
                  onReload={this.props.reload}
                  path={'trusti-mh-tempo'}
                />
                <PrimaryButton
                  label={`Fermer`}
                  icon={<Close />}
                  background={"#6c757d"}
                  onClick={() => {
                    clearContextBot();
                    this.props.onClose && this.props.onClose();
                  }}
                />
              </Box>
            </Box>

            <Box style={{ height: 20 }} />
            <Box pad={'medium'}>
              {this.state.devis.status !== CONTRAT_CLOTURE && this.state.viewMode && (
                <Box direction="row" align="center" justify="center" gap="medium">
                  {this.state.devis.id && !this.state.devis.estimatedPrice && (
                    <PrimaryButton
                      icon={<Send />}
                      onClick={() => this.sendDevis(this.state.devis)}
                      background="#28a745"
                      label="Envoyer formulaire"
                    />
                  )}
                  {this.state.devis.id && this.state.devis.estimatedPrice &&  !isDealClosable(this.state.devis) && (
                    <PrimaryButton
                      onClick={() => this.sendDevis(this.state.devis)}
                      label={`Envoyer un devis`}
                      icon={<DocumentText />}
                      background="#64AEF6"
                    />
                  )}
                  {this.state.devis.id && (
                    <PrimaryButton
                      onClick={this.sendPresentation}
                      label={`Envoyer une présentation`}
                      icon={<Send />}
                    />
                  )}
                  {this.isEstimatable() && (
                    <PrimaryButton
                      icon={<Compare />}
                      onClick={() => this.getPrice(this.state.devis)}
                      label="Estimer prix"
                    />
                  )}
                </Box>
              )}
            </Box>

            <Box style={{ height: 100 }} />
          </Box>
        </BlockUi >
    );
  }
}
export default TrustiMHTempo;

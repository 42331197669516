const CHIEN_RACES = [
    "Affenpinscher",
    "Airedale Terrier",
    "Akita Américain",
    "Akita Inu",
    "American Staffordshire Terrier",
    "Ancien chien d’arrêt danois",
    "Anglo-Français de Petite Vènerie",
    "Ariégeois",
    "Barbet",
    "Barbu Tchèque",
    "Barzoï",
    "Basenji",
    "Basset Artésien-Normand",
    "Basset Bleu de Gascogne",
    "Basset de Westphalie",
    "Basset des Alpes",
    "Basset Fauve de Bretagne",
    "Basset Hound",
    "Beagle",
    "Beagle-Harrier",
    "Bearded Collie",
    "Beauceron",
    "Bedlington Terrier",
    "Berger Allemand",
    "Berger Australien",
    "Berger Belge Groenendael",
    "Berger Belge Laekenois",
    "Berger Belge Malinois",
    "Berger Belge Tervueren",
    "Berger Blanc Suisse",
    "Berger Catalan",
    "Berger d’Anatolie",
    "Berger d’Asie Centrale",
    "Berger de Bergame",
    "Berger de Bohême",
    "Berger de Brie",
    "Berger de l’Atlas",
    "Berger de Maremme et des Abruzzes",
    "Berger de Picardie",
    "Berger de Russie",
    "Berger de Savoie",
    "Berger des Pyrénées",
    "Berger des Shetland",
    "Berger du Caucase",
    "Berger du massif du Karst",
    "Berger finnois de Laponie",
    "Berger Hollandais",
    "Berger Islandais",
    "Berger Polonais de Plaine",
    "Berger Polonais de Podhale",
    "Berger Portugais",
    "Berger Yougoslave",
    "Bichon à poil frisé",
    "Bichon Bolonais",
    "Bichon Havanais",
    "Bichon Maltais",
    "Biewer Yorkshire",
    "Billy",
    "Black and Tan Coonhound",
    "Bobtail",
    "Boerbull",
    "Border Collie",
    "Border Terrier",
    "Boston Terrier",
    "Bouledogue Américain",
    "Bouledogue Français",
    "Bouvier Australien",
    "Bouvier Bernois",
    "Bouvier d’Appenzell",
    "Bouvier de l’Entlebuch",
    "Bouvier des Ardennes",
    "Bouvier des Flandres",
    "Boxer",
    "Brachet Allemand",
    "Brachet autrichien noir et feu",
    "Brachet de Styrie à poil dur",
    "Brachet Polonais",
    "Brachet Tyrolien",
    "Braque allemand à poil court",
    "Braque allemand à poil dur",
    "Braque d’Auvergne",
    "Braque de Burgos",
    "Braque de l’Ariège",
    "Braque de Weimar",
    "Braque du Bourbonnais",
    "Braque Français",
    "Braque Hongrois à poil court",
    "Braque hongrois à poil dur",
    "Braque Italien",
    "Braque Saint-Germain",
    "Braque Slovaque à poil dur",
    "Briquet Griffon Vendéen",
    "Broholmer",
    "Buhund Norvégien",
    "Bull Terrier",
    "Bulldog Anglais",
    "Bulldog Continental",
    "Bullmastiff",
    "Cairn Terrier",
    "Cane Corso",
    "Caniche",
    "Cao de Castro Laboreiro",
    "Carlin",
    "Cavalier King Charles Spaniel",
    "Chesapeake Bay Retriever",
    "Chien chinois à crête",
    "Chien courant d’Istrie à poil dur",
    "Chien courant d’Istrie à poil ras",
    "Chien courant de Bosnie",
    "Chien courant de Halden",
    "Chien courant de Hamilton",
    "Chien courant de Hygen",
    "Chien courant de Posavatz",
    "Chien courant de Schiller",
    "Chien courant de Transylvanie",
    "Chien courant du Småland",
    "Chien courant espagnol",
    "Chien courant Finnois",
    "Chien courant grec",
    "Chien courant italien",
    "Chien courant norvégien",
    "Chien courant serbe",
    "Chien courant slovaque",
    "Chien courant suisse",
    "Chien courant yougoslave de montagne",
    "Chien courant yougoslave tricolore",
    "Chien d’arrêt frison",
    "Chien d’arrêt portugais",
    "Chien d’eau américain",
    "Chien d’eau espagnol",
    "Chien d’eau frison",
    "Chien d’eau irlandais",
    "Chien d’Eau Portugais",
    "Chien d’eau romagnol",
    "Chien d’ours de Carélie",
    "Chien d’Oysel",
    "Chien de berger de Croatie",
    "Chien de berger de Majorque",
    "Chien de Berger Roumain de Mioritza",
    "Chien de Berger Roumain des Carpathes",
    "Chien de Canaan",
    "Chien de montagne des Pyrénées",
    "Chien de montagne portugais",
    "Chien de Saint Hubert",
    "Chien du Groenland",
    "Chien du pharaon",
    "Chien d’arrêt allemand à poil long",
    "Chien d’Artois",
    "Chien d’élan norvégien gris",
    "Chien d’Élan Norvégien Noir",
    "Chien d’élan suédois",
    "Chien Finnois de Laponie",
    "Chien Jindo Coréen",
    "Chien Loup de Saarloos",
    "Chien Loup Tchécoslovaque",
    "Chien norvégien de Macareux",
    "Chien nu du Pérou",
    "Chien nu mexicain",
    "Chien rouge de Bavière",
    "Chien rouge de Hanovre",
    "Chien suédois de Laponie",
    "Chien thaïlandais",
    "Chihuahua",
    "Chow Chow",
    "Cirneco de l’Etna",
    "Clumber-Spaniel",
    "Cocker Américain",
    "Cocker Anglais",
    "Colley à poil court",
    "Colley à poil long",
    "Coton de Tuléar",
    "Curly Coated Retriever",
    "Cursinu",
    "Dalmatien",
    "Dandie-Dinmont-Terrier",
    "Dobermann",
    "Dogo Canario",
    "Dogue Allemand",
    "Dogue Argentin",
    "Dogue de Bordeaux",
    "Dogue de Majorque",
    "Dogue du Tibet",
    "Drever",
    "English Toy Terrier",
    "Epagneul à perdrix de Drente",
    "Epagneul Bleu de Picardie",
    "Epagneul Breton",
    "Epagneul de Pont-Audemer",
    "Epagneul Français",
    "Epagneul Japonais",
    "Epagneul Nain Continental",
    "Epagneul Picard",
    "Epagneul Tibétain",
    "Eurasier",
    "Field-Spaniel",
    "Fila Brasileiro",
    "Fila de Sao Miguel",
    "Flat-Coated Retriever",
    "Fox Terrier",
    "Foxhound américain",
    "Foxhound anglais",
    "Golden Retriever",
    "Goldendoodle",
    "Grand Anglo-Français blanc et noir",
    "Grand Anglo-Français blanc et orange",
    "Grand Anglo-Français tricolore",
    "Grand Basset Griffon vendéen",
    "Grand Bouvier Suisse",
    "Grand Épagneul de Münster",
    "Grand Gascon saintongeois",
    "Grand Griffon Vendéen",
    "Greyhound",
    "Griffon Belge",
    "Griffon Bleu de Gascogne",
    "Griffon Bruxellois",
    "Griffon fauve de Bretagne",
    "Griffon Korthals",
    "Griffon Nivernais",
    "Harrier",
    "Hokkaïdo Ken",
    "Hovawart",
    "Husky Sibérien",
    "Irish Glen of Imaal Terrier",
    "Irish Terrier",
    "Irish Terrier à poil doux",
    "Jack Russell Terrier",
    "Jagdterrier",
    "Kai",
    "Kelpie",
    "Kerry Blue Terrier",
    "King Charles Spaniel",
    "Kishu",
    "Komondor",
    "Kromfohrländer",
    "Kuvasz Hongrois",
    "Labradoodle",
    "Labrador Retriever",
    "Laïka de Sibérie occidentale",
    "Laïka de Sibérie orientale",
    "Laïka Russo-Européen",
    "Lakeland Terrier",
    "Landseer",
    "Leonberger",
    "Lévrier Afghan",
    "Lévrier Azawakh",
    "Lévrier Écossais",
    "Lévrier Espagnol",
    "Lévrier Hongrois",
    "Lévrier Irlandais",
    "Lévrier Polonais",
    "Lhassa Apso",
    "Malamute de l’Alaska",
    "Mastiff",
    "Mâtin de l’Alentejo",
    "Mâtin de Naples",
    "Mâtin des Pyrénées",
    "Mâtin Espagnol",
    "Mudi",
    "Norfolk Terrier",
    "Norwich Terrier",
    "Otterhound",
    "Parson Russell Terrier",
    "Pékinois",
    "Petit Basset Griffon vendéen",
    "Petit Brabançon",
    "Petit Chien Courant Suisse",
    "Petit chien hollandais de chasse au gibier d’eau",
    "Petit Chien Lion",
    "Petit Epagneul de Münster",
    "Petit Lévrier Italien",
    "Pinscher",
    "Pinscher autrichien à poil court",
    "Pinscher Nain",
    "Pitbull",
    "Podenco Canario",
    "Podenco Ibicenco",
    "Podengo portugais",
    "Pointer Anglais",
    "Poitevin",
    "Pomsky",
    "Porcelaine",
    "Pudelpointer",
    "Puli",
    "Pumi",
    "Ratonero Bodeguero Andaluz",
    "Retriever de la Nouvelle-Écosse",
    "Rhodesian-Ridgeback",
    "Rottweiler",
    "Saint-Bernard",
    "Saluki",
    "Samoyède",
    "Schapendoes",
    "Schipperke",
    "Schnauzer",
    "Sealyham Terrier",
    "Setter Anglais",
    "Setter Gordon",
    "Setter Irlandais Rouge",
    "Shar-Pei",
    "Shiba Inu",
    "Shih Tzu",
    "Shikoku",
    "Silky Terrier",
    "Skye Terrier",
    "Sloughi",
    "Smous des Pays-Bas",
    "Spinone",
    "Spitz Allemand",
    "Spitz de Norrbotten",
    "Spitz des Wisigoths",
    "Spitz Finlandais",
    "Spitz Japonais",
    "Springer Anglais",
    "Staffordshire Bull Terrier",
    "Sussex-Spaniel",
    "Tchouvatch Slovaque",
    "Teckel",
    "Terre-Neuve",
    "Terrier australien",
    "Terrier brésilien",
    "Terrier de Manchester",
    "Terrier Ecossais",
    "Terrier japonais",
    "Terrier Noir Russe",
    "Terrier tchèque",
    "Terrier Tibétain",
    "Tosa",
    "Volpino Italien",
    "Welsh Corgi Cardigan",
    "Welsh Corgi Pembroke",
    "Welsh Springer Spaniel",
    "Welsh Terrier",
    "West Highland White Terrier",
    "Whippet",
    "Yorkshire Terrier"
]

const CHAT_RACES = [
    "Abyssin",
    "American curl",
    "American shorthair",
    "American wirehair",
    "Anatoli",
    "Angora turc",
    "Asian",
    "Brume australienne",
    "Balinais",
    "Bengal",
    "Bleu russe",
    "Bobtail américain",
    "Bobtail des Kouriles",
    "Bobtail japonais",
    "Bombay",
    "Brazilian shorthair",
    "British longhair",
    "British shorthair",
    "Burmese",
    "Burmilla",
    "Californian rex",
    "Californian spangled",
    "Ceylan",
    "Chartreux",
    "Chantilly",
    "Chausie",
    "Colorpoint shorthair",
    "Cornish rex",
    "Cymric",
    "Devon rex",
    "Donskoy",
    "European shorthair",
    "Exotic shorthair",
    "German rex",
    "Havana brown",
    "Highlander",
    "Highland fold11",
    "Himalayen",
    "Khao Manee",
    "Korat",
    "LaPerm",
    "Maine coon",
    "Mandarin",
    "Manx",
    "Mau arabe",
    "Mau égyptien",
    "Minskin",
    "Munchkin",
    "Nebelung",
    "Norvégien ou Skogkatt",
    "Ocicat",
    "Ojos azules",
    "Oriental shorthair",
    "Persan",
    "Peterbald",
    "Pixie-bob",
    "Ragamuffin",
    "Ragdoll",
    "Sacré de Birmanie",
    "Safari",
    "Savannah",
    "Scottish fold",
    "Selkirk rex",
    "Serengeti",
    "Seychellois",
    "Siamois",
    "Sibérien",
    "Singapura",
    "Skookum",
    "Snowshoe",
    "Sokoké",
    "Somali",
    "Sphynx",
    "Thaï",
    "Tiffany",
    "Tonkinois",
    "Toyger",
    "Turc de Van",
    "Ural rex",
    "York chocolat"
]

module.exports = {
    CHIEN_RACES,
    CHAT_RACES
}
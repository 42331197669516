import {
  Box, Grid, Text
} from "grommet";
import React from "react";

const InfoVenteMark = ({ text }) => {
  if (!text)
    return null;
  return (
    <Box margin="6px 0" background="brand" pad="5px 25px" width="max-content" style={{ borderRadius: "10px" }}>
      <Text color="white" weight={600} textAlign="center">{text}</Text>
    </Box>
  );
};

const InfoEmprunteur = ({ emprunteur }) => {
  if (!emprunteur)
    return null;
  return (
    <Box width="full">
      <Grid
        columns={["1/2", "1/2"]} gap="small" pad={"small"} width="100%"
      >
        <Box>
          <Text color="grey">Nom de naissance</Text>
          <Text color="grey" weight={700}>{emprunteur?.birth_name}</Text>
        </Box>
        <Box>
          <Text color="grey">Nationalité</Text>
          <Text color="grey" weight={700}>{emprunteur?.nationality}</Text>
        </Box>
        <Box>
          <Text color="grey">Pays de naissance</Text>
          <Text color="grey" weight={700}>{emprunteur?.birth_country}</Text>
        </Box>
        <Box>
          <Text color="grey">Ville de naissance</Text>
          <Text color="grey" weight={700}>{emprunteur?.birth_city}</Text>
        </Box>
        <Box>
          <Text color="grey">Sport à risque</Text>
          <Text color="grey" weight={700}>{emprunteur?.risk_sports}</Text>
        </Box>
        <Box>
          <Text color="grey">Code postal de résidence principale</Text>
          <Text color="grey" weight={700}>{emprunteur?.zip_fiscal}</Text>
        </Box>
        <Box>
          <Text color="grey">Statut professionnel</Text>
          <Text color="grey" weight={700}>{emprunteur?.professional_status}</Text>
        </Box>
        <Box>
          <Text color="grey">Profession à risque</Text>
          <Text color="grey" weight={700}>{emprunteur?.risk_profession}</Text>
        </Box>
        <Box>
          <Text color="grey">Êtes vous fumeur ?</Text>
          <InfoVenteMark text={emprunteur?.fumeur ? "Oui" : "Non"} />
        </Box>
      </Grid>
    </Box>
  );
}

export default InfoEmprunteur;

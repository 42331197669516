import React from "react";
import { Box } from 'grommet';
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import SelectEntreprise from "../../../contacts/entreprises/entreprise/select_entreprise";
import Info from "./info";
import { sendPresentation } from '../../../../services/common_service';
import { createProSante, sendForm, updateProSante } from "../services";
import { getAllCompanies, sendContratEmail, sendDevisEmail } from '../../../../services/contact_service';
import { CONTRAT_ENVOYE, DEVIS } from '../../../../services/devis_contrat_status';
import Recap from "./recap";
import PrimaryButton from "../../../elements/primary_button";
import { Previous, Send } from "grommet-icons";
import StandardText from "../../../elements/standard_text";
import RecapStatus from "../../../elements/devis_status";
import DevisContratForm from './devis_contrat';

const STEP = {
    entreprise: 'entreprise',
    info: 'info',
    recap: 'recap',
    devis: 'devis',
    contrat: 'contrat',
}
class ProSante extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deal: this.props.deal || {},
            step: this.props.deal?.id ? STEP.recap : STEP.entreprise
        }
    }

    componentDidMount = () => {
        getAllCompanies()
            .then((res) => {
                let _allComs = res.data?.options;
                this.setState({ allCompanies: _allComs });
            })
            .catch((err) => console.log("err = ", err));
    }

    onEntrepriseCreated = async entreprise => {
        let deal = this.state.deal;
        deal.entreprise = entreprise.id;
        let handler = deal.id ? updateProSante : createProSante;
        try {
            let res = await handler(deal);
            deal = res.data;
            this.setState({ deal: Object.assign({}, deal), step: STEP.info })
            this.props.onRefresh();
        } catch (error) {
            console.log(error);
            this.props.showMessage('Error lors de création/mis à jour entreprise', 'error');
            deal.entreprise = entreprise;
            this.setState({ deal })
        }

    }

    onDealChange = (field, value) => {
        let deal = this.state.deal;
        deal[field] = value;
        this.setState({ deal: Object.assign({}, deal) });
    }

    onDealSave = async () => {
        let deal = this.state.deal;
        let entreprise = deal.entreprise;
        deal.entreprise = entreprise.id;
        try {
            let res = await updateProSante(deal);
            deal = res.data;
            this.setState({ deal: Object.assign({}, deal), step: STEP.recap })
            this.props.onRefresh();
        } catch (error) {
            console.log(error);
            this.props.showMessage('Error lors de création/mis à jour entreprise', 'error');
            deal.entreprise = entreprise;
            this.setState({ deal })
        }
    }

    setLoading = val => this.setState({loading: val});

    sendEmailDevis = (devis) => {
        if (!devis.date_effet || (new Date(devis.date_effet) < new Date())) {
            this.props.showMessage("La date d'effet doit être supérieure à la date du jour", "error");
            return;
        }
        devis.status = DEVIS;
        devis.date_devis = new Date();
        devis.begin_date = devis.date_effet;
        devis.frais_dossier = devis.frais_dossier || 0;
        updateProSante(devis)
            .then((res) => {
                this.setLoading(false);
                this.setState({ step: STEP.recap, devis: res.data });
                sendDevisEmail(devis)
                    
                    .then((res) => {
                        this.props.showMessage("Devis a été envoyé", "success");
                        this.setState({ deal: res.data })
                    })
                    .catch((err) =>
                        this.props.showMessage("Echec d'envoyer devis", "error")
                    );
            })
            .catch((err) => {
                console.log(err);
                this.props.showMessage("Erreur inattendu", "error");
                this.setLoading(false);
            });
    };

    sendEmailContrat = (devis) => {
        if (!devis.date_effet || (new Date(devis.date_effet) < new Date())) {
            this.props.showMessage("La date d'effet doit être supérieure à la date du jour", "error");
            return;
        }
        if(!devis.file_contrat) {
            this.props.showMessage("Le fichier de contrat manquant", "error");
            return;
        }
        devis.status = CONTRAT_ENVOYE;
        devis.date_contrat = new Date();
        updateProSante(devis)
            .then(async (res) => {
                this.setState({ step: STEP.recap, devis: res.data });
                sendContratEmail(res.data)
                    .then((res) => {
                        this.props.showMessage("Contrat a été envoyé", "success");
                        this.setState({ deal: res.data })
                    })
                    .catch((err) =>
                        this.props.showMessage("Echec d'envoyer contrat", "error")
                    );
            })
            .catch((err) => {
                console.log(err);
                this.props.showMessage("Erreur inattendu", "error");
                this.setLoading(false);
            });
    };

    onDealReload = deal => this.setState({ deal });

    onSendForm = () => {
        let deal = this.state.deal;
        sendForm(deal)
            .then(res => {
                this.props.showMessage("Formulaire envoyé avec succès", "success");
                this.setState({ deal: res.data });
            })
            .catch(err => {
                console.log(err);
                this.props.showMessage("Error lors de l'envoie de formulaire");
            })
    }

    sendPresentation = () => {
        sendPresentation(this.state.deal)
            .then((res) => {
                this.props.showMessage("Présentation a été envoyé", "success");
                this.props.onRefresh();
                this.setState({ deal: res.data })
            })
            .catch((err) =>
                this.props.showMessage("Echec d'envoyer présentation", "error")
            );
    };

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box
                    style={{ width: "100%" }}
                    justify="center"
                    alignContent="center"
                    align="center"
                    pad='medium'
                >
                    <StandardText style={{ fontWeight: 'bold' }} size='large' label='Santé Professionelle' />
                    <Box width="full" justify="between" direction="row">
                        <Box pad="small" width={'xsmall'}>
                            <PrimaryButton icon={<Previous />} onClick={() => {
                                this.props.onClose && this.props.onClose();
                            }} />
                        </Box>
                        {this.state.deal.id &&
                            <Box width={'small'}>
                                <RecapStatus status={this.state.deal.status} />
                            </Box>
                        }
                    </Box>
                    <Box
                        width={'xxlarge'}
                        justify="center"
                        alignContent="center"
                        align="center"
                    >
                        {this.state.step === STEP.entreprise &&
                            <SelectEntreprise
                                entreprise={this.state.deal?.entreprise}
                                onCancel={() => {
                                    if (this.state.deal?.id) {
                                        this.setState({ step: STEP.recap })
                                    } else {
                                        this.props.onClose();
                                    }
                                }}
                                onSubmit={this.onEntrepriseCreated}
                                showMessage={this.props.showMessage}
                            />
                        }

                        {this.state.step === STEP.info &&
                            <Info
                                deal={this.state.deal}
                                onDealChange={this.onDealChange}
                                prev={() => this.setState({ step: STEP.entreprise })}
                                save={this.onDealSave}
                            />
                        }

                        {this.state.step === STEP.recap &&
                            <Recap
                                deal={this.state.deal}
                                onEdit={() => this.setState({ step: STEP.entreprise })}
                                showMessage={this.props.showMessage}
                                toDevis={() => this.setState({ step: STEP.devis })}
                                toContrat={() => this.setState({ step: STEP.contrat })}
                                onDealReload ={this.onDealReload}
                                reload={this.props.onRefresh}
                                onSendForm={this.onSendForm}
                            />
                        }

                        {(this.state.step === STEP.devis || this.state.step === STEP.contrat) &&
                            <DevisContratForm
                                devis={this.state.deal}
                                updateDevis={this.onDealChange}
                                onEdit={() => this.setState({ step: STEP.entreprise })}
                                showMessage={this.props.showMessage}
                                toDevis={() => this.setState({ step: STEP.devis })}
                                submitModification={this.onDealSave}
                                isModeContrat={this.state.step === STEP.contrat}
                                allCompanies={this.state.allCompanies}
                                sendEmailDevisContrat={
                                    this.state.step === STEP.contrat
                                        ? this.sendEmailContrat
                                        : this.sendEmailDevis
                                }
                            />
                        }

                        <Box
                            direction="row"
                            margin="small"
                            align="center"
                            justify="center"
                            gap="medium"
                        >

                            {this.state.step === STEP.recap &&
                                <PrimaryButton
                                    onClick={this.sendPresentation}
                                    label={`Envoyer une présentation`}
                                    icon={<Send />}
                                />
                            }
                        </Box>


                    </Box>
                </Box>
            </BlockUi>
        )
    }
}

export default ProSante;
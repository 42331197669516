
import React, { useState } from "react";
import { Box, Text, Heading, TextInput, Card, Layer, Button } from "grommet";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import CustomSelect from "../select_fill";
import { Checkmark, Close } from "grommet-icons";
import PrimaryButton from "../primary_button";
import { changDealOwner } from "../../../services/dashboard";
import { changeCommercialOwner } from "../../../services/contact_service";

const CommercialDealChange = ({ deal, commerciaux, refreshReports, showMessage, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [newCommercialId, setNewCommercialId] = useState(deal?.commercial_id);

    const changeOwner = async () => {
        if (!newCommercialId || newCommercialId === deal.commercial_id) {
            showMessage("Pas de changement", "warning");
            return;
        }
        setLoading(true);
        try {
            //await changDealOwner(deal, newCommercialId);
            const clientId = deal.client?.id;
            if(!clientId) {
                setLoading(false);
                showMessage("Le client n'existe pas", "warning");
                return;
            }
            await changeCommercialOwner(newCommercialId, clientId);
            deal.commercial_id = newCommercialId;
            refreshReports();
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
        onClose();
        showMessage("Changé avec succès", "success");
    }

    return (
        <BlockUi tag="div" blocking={loading}>
            <Card width={'large'} pad='medium' gap="small">
                <Box style={{ position: 'absolute', right: 10, top: 10 }}>
                    <Close onClick={onClose} style={{ cursor: 'pointer' }} />
                </Box>

                <Text size="small" style={{ fontStyle: 'italic' }} color='red'>
                    Le nouveau commercial s'occupera tous les deals du client {`${deal?.client?.firstname} ${deal?.client?.lastname}`}
                </Text>
                <Box direction="row" gap="medium" justify="between">
                    <Box >
                        <CustomText label={"Propriétaire du deal"} />
                    </Box>
                    <Box width={'full'}>
                        <CustomSelect
                            placeholder={'Commercial'}
                            options={commerciaux}
                            onChange={({ option }) => setNewCommercialId(option.id)}
                            labelKey={'name'}
                            valueKey={{ key: 'name', reduce: true }}
                            value={commerciaux.find(c => c.id === (newCommercialId || deal?.commercial_id))?.name}
                        />
                    </Box>
                    <PrimaryButton label={'Valider'} icon={<Checkmark />} onClick={changeOwner} />
                </Box>
            </Card>
        </BlockUi>
    )
}


const CustomText = ({ label }) => (
    <Text style={{ fontWeight: "bold" }} size="small" textAlign="end">
        {label}
    </Text>
);
export default CommercialDealChange;
import React from "react";
import { Box, Button, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { getAffilieByUser } from "../service";
import { getSimpleDateFR, getSorted } from "../../../services/utils";
import AppUserInfo from "./user_info";
import AppUserDetail from "./user_detail";


class AppAffilieByUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appUsers: [],
      popupOpen: false,
      selectedUser: {},
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    };
  }
  componentDidMount = () => {
    this.loadAppUsers();
  };

  loadAppUsers = () => {
    this.setState({ appUsers: [] }, () => {
      getAffilieByUser(this.props.userId)
        .then((res) => {
          this.setState({ appUsers: res.data });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false });
  };

  openUser = (user) => {
    this.setState({ selectedUser: user, popupOpen: true });
  };

  addUser = () => this.openUser({});

  search = (query) => {
    getAffilieByUser(this.props.userId, query)
      .then(res => {
        if (res.data?.results) {
          this.setState({ appUsers: res.data?.results })
        } else if (res.data) {
          this.setState({ appUsers: res.data })
        }
      })
      .catch(err => {
        console.log('err', err);
        this.props.showMessage('Erreur inattendu');
      })
  }

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  render() {
    return this.state.popupOpen ? (
      // <Text>App User</Text>
      <AppUserDetail
        showMessage={this.props.showMessage}
        user={this.state.selectedUser}
        onClose={this.onClose}
        reload={this.loadAppUsers}
      />
    ) : (
      <Box gap="small">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>
                <Text size="small">Création/<br />Modification</Text>
              </TableCell>
              <TableCell>
                <Text size="small">Nom</Text>
              </TableCell>
              <TableCell>
                <Text size="small">Prénom</Text>
              </TableCell>
              <TableCell size="small">Email</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHeader>
          {this.state.appUsers.length > 0 && <TableBody>
            {getSorted(this.state.appUsers, this.state.filter.sort).map((appUser, index) => (
              <TableRow key={index + 1}>
                <TableCell>
                  <Text size="small"><strong>{getSimpleDateFR(appUser.createdAt)}</strong><br />{getSimpleDateFR(appUser.updatedAt)}</Text>
                </TableCell>
                <TableCell>
                  <Text>{appUser.nom}</Text>
                </TableCell>
                <TableCell>
                  <Text>{appUser.prenom}</Text>
                </TableCell>
                <TableCell>
                  <Text>{appUser.email}</Text>
                </TableCell>
                <TableCell>
                  <Button
                    label="voir"
                    primary
                    onClick={() => this.openUser(appUser)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>}
        </Table>
        {this.state.appUsers.length === 0 && <Box alignContent="center" align="center"><Text size="small">Aucun affilié</Text></Box>}
      </Box>
    );
  }
}

export default AppAffilieByUser;

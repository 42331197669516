import {
    Box,
    Card, Text
} from "grommet";
import React from "react";
import { getCommissionTauxConfig } from "../../services/dashboard";
import TrustiPERs from "./trusti-per/trusti_pers";

const TAB = {
    enligne: 'enligne',
    horsligne: 'horsligne'
}

const MENU = {
    online: {

    },
    offline: {
        per: 'off_per',
        mrp: 'off_mh',
        immeuble: 'off_pno',
        sante: 'off_sante',
        cyber_risque: 'off_auto',
    }
}

class VenteEpargnes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: props.contact || {},
            commission_taux: undefined,
            tab: TAB.horsligne,
            currentProd: MENU.offline.per
        };
    }
    componentDidMount() {
        getCommissionTauxConfig()
            .then(res => {
                this.setState({ commission_taux: res.data });
            })
    }

    isActive = tab => this.state.tab === tab;

    getContent = () => {
        switch (this.state.currentProd) {
            case MENU.offline.per:
                return (
                    <TrustiPERs
                        showMessage={this.props.showMessage}
                        contact={this.state.contact}
                        commission_taux={this.state.commission_taux}
						commerciaux={this.props.commerciaux}
                    />
                )
            default:
                return null;
        }
    }

    to = prod => this.setState({ currentProd: prod });

    toTab = tab => {
        if (tab === this.state.tab) return;
        let defaultProd;
        if (tab === TAB.enligne) defaultProd = MENU.online.mh;
        if (tab === TAB.horsligne) defaultProd = MENU.offline.per;
        this.setState({ tab, currentProd: defaultProd })
    }

    render() {
        return (
            <Box width={'full'} justify="center" align="center">
                <Box width={'full'} style={{ height: 40 }} justify="center" align="center" background={'#F8F8F8'}>
                    <Box style={{ height: 40, position: 'absolute', left: 50 }} align="center" justify="center" direction="row">
                        <Card round={{ corner: "left", size: 'medium' }}
                            style={{ height: 40 }} pad='small'
                            onClick={() => this.toTab(TAB.enligne)}
                            background={this.isActive(TAB.enligne) ? '#70A4EC' : '#fff'}
                            justify='center'
                        >
                            <Text size="small" color={'#707070'}>{this.isActive(TAB.enligne) ? <b>En ligne</b> : "En ligne"}</Text>
                        </Card>
                        <Card round={{ corner: "right", size: 'medium' }}
                            style={{ height: 40 }} pad='small'
                            onClick={() => this.toTab(TAB.horsligne)}
                            background={this.isActive(TAB.horsligne) ? '#70A4EC' : '#fff'}
                            justify='center'
                        >
                            <Text size="small" color={'#707070'}>{this.isActive(TAB.horsligne) ? <b>Hors ligne</b> : "Hors ligne"}</Text>
                        </Card>
                    </Box>
                    {this.state.tab === TAB.enligne &&
                        <Box width={'full'} style={{ height: 40 }} justify="center" align="center" direction="row" gap="large">
                            <SubItem
                                onClick={() => this.to(MENU.online)}
                                label='Aucun produit disponible'
                                isActive={this.state.currentProd === MENU.online}
                            />

                        </Box>
                    }
                    {this.state.tab === TAB.horsligne &&
                        <Box width={'full'} style={{ height: 40 }} justify="center" align="center" direction="row" gap="large">
                            <SubItem
                                onClick={() => this.to(MENU.offline.per)}
                                label='Trusti PER'
                                isActive={this.state.currentProd === MENU.offline.per}
                            />

                        </Box>
                    }
                </Box>
                <Box style={{ height: 40 }} />
                {this.getContent()}
            </Box>
        )
    }
}

const SubItem = ({ label, onClick, isActive }) => (
    <Box onClick={onClick} style={{ boxShadow: 'none' }}>
        <Text textAlign="center" size="small" color={'#707070'} style={{ fontWeight: isActive ? 'bold' : 'normal' }}>
            {label.split('::').map(s => <span>{s}<br /></span>)}
        </Text>
    </Box>
)

export default VenteEpargnes;
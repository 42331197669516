import { Box, Button, Card, Grid, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import { AddCircle, StatusGoodSmall } from "grommet-icons";
import moment from 'moment';
import React from "react";
import { getGestionCollabs } from '../../../services/dashboard';
import { getUserId } from '../../../services/storage_service';
import { getSimpleDateFR, toCurrency } from '../../../services/utils';
import CustomPagination from '../../elements/custom_pagination';
import StandardText from '../../elements/standard_text';
import Search from '../components/search';
import ViewMode, { DISPLAY_MODE } from '../components/view_mode';
import { SINISTRES_STATUS } from '../utils/types';
import { countSinistres, createSinistre, getSinistres, sendSinistreForm, updateSinistre } from './service';
import PopupNewSinistre from './sinistre-popup';
import SinistreV2 from './sinistre';

class Sinistres extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sinistres: [],
            displayMode: DISPLAY_MODE.line,
            query: '',
            selectedSinistre: undefined,
            searchId: '',
            startIndex: 0,
            total: 0,
            users: [],
            newSinistre: undefined
        };
    }

    componentDidMount() {
        this.reload();
        getGestionCollabs()
            .then(res => this.setState({ users: res.data }))
            .catch(err => console.log(err));
    }

    reload = () => {
        this.setState({ startIndex: 0 }, () => getSinistres({ agence: this.props.agence, query: this.state.query })
            .then(res => {
                this.setState({ sinistres: res.data });
            }))
        countSinistres({ agence: this.props.agence, query: this.state.query })
            .then(res => this.setState({ total: res.data }));
    }

    onNextPage = ({ startIndex }) => {
        this.setState({ startIndex }, () => {
            getSinistres({ agence: this.props.agence, query: this.state.query, start: startIndex })
                .then(res => {
                    this.setState({ sinistres: res.data });
                })
        });
        countSinistres({ agence: this.props.agence, query: this.state.query })
            .then(res => this.setState({ total: res.data }));
    }

    selectSub = sub => this.setState({ selectedSinistre: sub })

    search = q => {
        this.setState({ query: q }, () => this.reload());
    }

    onSaveSinistre = (values) => {
        values.commercial_id = getUserId();
        createSinistre(values)
            .then(res => {
                this.setState({
                    newSinistre: null,
                    sinistres: [...this.state.sinistres, res.data],
                    selectedSinistre: res.data
                });
                this.props.showMessage('Sinistre est créé avec succès', 'success');
            })
            .catch(err => this.props.showMessage('Erreur inattendu', 'error'));

    }

    handleSinistreChanged = values => {
        const sinistre = values;
        const sinistres = this.state.sinistres;
        const index = sinistres.map(i => i.id).indexOf(sinistre.id);
        sinistres[index] = { ...sinistres[index], ...sinistre };
        this.setState({ sinistres: [...sinistres], selectedSinistre: { ...sinistre } });
    }

    render() {
        return (
            <Box
                style={{ width: "100%" }}
                justify="center"
                alignContent="center"
                align="center"
                pad='medium'
            >
                {this.state.newSinistre &&
                    <PopupNewSinistre
                        onClose={() => this.setState({ newSinistre: null })}
                        onSave={this.onSaveSinistre}
                    />
                }
                {this.state.selectedSinistre &&
                    <SinistreV2 sinistre={this.state.selectedSinistre}
                        onReturn={() => this.setState({ selectedSinistre: null })}
                        showMessage={this.props.showMessage}
                        onSinistreChange={this.handleSinistreChanged}
                    />
                }
                {!this.state.selectedSinistre &&
                    <Box
                        style={{ width: "100%" }}
                        justify="center"
                        alignContent="center"
                        align="center"
                        pad='medium'
                        id='header-id'
                    >
                        <Box direction="row" pad={'small'} gap='small' style={{ width: "100%" }} align='center' justify='start'>
                            <ViewMode
                                currentMode={this.state.displayMode}
                                toLineMode={() => this.setState({ displayMode: DISPLAY_MODE.line })}
                                toGridMode={() => this.setState({ displayMode: DISPLAY_MODE.grid })}
                            />
                            <Box align='center'>
                                <Search search={query => this.search(query)} size='small' />
                            </Box>
                            <Box justify='center' direction='row' gap='xsmall'>
                                <Button
                                    onClick={() => this.setState({ newSinistre: {} })}
                                >
                                    <AddCircle color="brand" />
                                </Button>
                                <StandardText label="Ouvrir un sinistre" />
                            </Box>
                        </Box>

                        {this.state.displayMode === DISPLAY_MODE.line ?
                            <LineView
                                sinistres={this.state.sinistres}
                                selectSub={this.selectSub}
                                onNextPage={this.onNextPage}
                                total={this.state.total}
                                startIndex={this.state.startIndex}
                                users={this.state.users}
                            /> :
                            <GridView
                                sinistres={this.state.sinistres}
                                selectSub={this.selectSub}
                                onNextPage={this.onNextPage}
                                total={this.state.total}
                                startIndex={this.state.startIndex}
                            />
                        }
                    </Box>

                }

            </Box>
        );
    }
}

const GridView = ({ sinistres = [], selectSub, total, startIndex, onNextPage }) => {

    return (
        <Box style={{ width: "100%" }}>
            <CustomPagination total={total} onNextPage={onNextPage} startIndex={startIndex} />
            <Box style={{ height: 10 }} />
            <Grid
                columns={{
                    count: SINISTRES_STATUS.length,
                    size: 'auto',
                }}
                gap="xsmall"
            >
                {SINISTRES_STATUS.map((name, idx) => {
                    let productSubs = sinistres.filter(sinistre => sinistre.status === name);
                    return (
                        <Box key={idx} gap='xsmall' border='right'>
                            <ProductTitle title={name} />
                            {
                                productSubs
                                    .map((s, id2) => (
                                        <SinistreCard sinistre={s} key={id2} selectSub={selectSub} />
                                    ))
                            }
                            <Box style={{ height: 100 }} />
                            <Box style={{ position: 'absolute', bottom: 0 }} background='#F7F7F7' pad={'small'} align="center" justify="center" alignContent="center" alignSelf="center" >
                                <Box direction="row" gap="small" justify="center" align="center">
                                    <Text size="xsmall" style={{ fontWeight: 'bold' }}>nb: {productSubs.length}</Text>
                                    <Text size="xsmall" style={{ fontWeight: 'bold' }}>Total: {toCurrency(productSubs.map(s => s.montant_sinistre).reduce((a, s) => a + s, 0), true)}</Text>
                                </Box>
                            </Box>
                        </Box>
                    )
                })

                }
            </Grid>
        </Box >
    )
}

const ProductTitle = ({ title }) => (
    <Box round={'xsmall'} background='#F7F7F7' pad={'xsmall'} align='center' justify="center">
        <Text textAlign="center" size="xsmall" style={{ fontWeight: 'bold' }}>{title.replace('Trusti ', '')}</Text>
    </Box>
)

const SinistreCard = ({ sinistre, selectSub }) => (
    <Box pad='xsmall'>
        <Card pad={'xsmall'}>
            <Box direction="row" gap="small" justify='between'>
                <Box>
                    <Text size="xsmall">{`${sinistre.prenom_du_locataire} ${sinistre.nom_locataire}`}</Text>
                    <Text size="xsmall">{`${sinistre.agence?.raisonSocial}`}</Text>
                </Box>
                <Box style={{ minWidth: 35 }} justify="center" align='center'>
                    <Text size="xsmall">{`${toCurrency(sinistre.montant_sinistre, true)}`}</Text>
                    <Box background="brand" round={{ size: 'small' }} onClick={() => selectSub(sinistre)} style={{ minWidth: 35 }} >
                        <Text size="xsmall" textAlign="center" style={{ fontWeight: 'bold' }}>Voir</Text>
                    </Box>
                </Box>
            </Box>
            <StatusSinistre status={sinistre.status} />
        </Card>
    </Box>
)

const LineView = ({ sinistres = [], selectSub, onNextPage, total, startIndex, users }) => {
    return (
        <Box style={{ width: "100%" }}>
            <CustomPagination total={total} onNextPage={onNextPage} startIndex={startIndex} />
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell>
                            <Text size="xsmall"><b>Etat</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Réference</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Produit</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Partenaire</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Propriétaire</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Nom</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Prénom</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Date du sinistre</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Montant du sinistre</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Moyen de paiement</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Type</b></Text>
                        </TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        sinistres.map((sub, index) => {
                            let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                            return (
                                <TableRow key={index + 1} style={{ backgroundColor: _bgColor }}>
                                    <TableCell>
                                        <StatusSinistre status={sub.status} />
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{sub.reference}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{sub.produit}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{sub.agence?.raisonSocial}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{users.find(u => u.id === sub.commercial_id)?.name}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{sub.nom_locataire}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{sub.prenom_du_locataire}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{getSimpleDateFR(sub.date_survenance)}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{toCurrency(sub.montant_sejour)}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{sub.mode_de_payment}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{sub.type}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Box background="brand" round={{ size: 'xsmall' }} onClick={() => selectSub(sub)} pad={'xsmall'} >
                                            <Text size="xsmall" textAlign="center" style={{ fontWeight: 'bold' }}>Voir</Text>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </Box>
    )
}

const StatusSinistre = ({ status }) => (
    <Box direction="row" gap="xsmall" pad="xsmall" align="center">
        <StatusGoodSmall size="small" color={getStatusColor(status)} />
        <Text size="xsmall">
            <strong>{status}</strong>
        </Text>
    </Box>
)
const getStatusColor = status => {
    switch (status) {
        case "Nouveau":
            return "#70A4EC";
        case "En cours - Attente de documents":
        case "En cours - Gestion du dossier":
        case "En attente de RBT Compagnie":
        case "Clos refus":
            return "#FF9D19";
        case "Clos remboursé":

            return "#33BC00";
        default:
            return "#33BC00";
    }
}

export default Sinistres;

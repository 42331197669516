import { Box, Card } from "grommet";
import React from "react";
import { TextLabel } from "../text-label";
import CustomTextInput from "../text-input";

const LineTextContainer = ({ children }) => {
    return <Box
        style={{
            backgroundColor: 'primary',
            paddingLeft: "0.375rem",
            paddingTop: "0.25rem",
            paddingBottom: "0.25rem",
            borderBottom: "1px solid #E4E4E7",
            minHeight: "auto"
        }}
    >{children}</Box>;
}

const CellContainer = ({ children }) => {
    return <Box
        style={{
            backgroundColor: 'primary',
            paddingBottom: "0.125rem",
            paddingLeft: "0.375rem",
            borderBottom: "1px solid #E4E4E7",
            minHeight: "auto"
        }}
    >{children}</Box>;
}
const TitleContainer = ({ children }) => {
    return <Box
        justify="center"
        style={{
            paddingBottom: "0.125rem",
            paddingTop: "0.125rem",
            paddingLeft: "0.375rem",
            paddingRight: "0.375rem",
            backgroundColor: "#E82C70",
            color: "white !important",
            minHeight: 'auto'
        }}
    >{children}</Box>;
}

const CardContainer = ({ children }) => {
    return <Card
        style={{
            backgroundColor: 'white',
            padding: "1rem",
            borderRadius: "0.5rem",
            borderColor: "#ECE8F0",
            boxShadow: "0 0 0 1px #ECE8F0",
            minHeight: 'auto'
        }}
    >{children}</Card>;
}

const Container = ({ children }) => {
    return <Box
        width={'full'}
        style={{
            backgroundColor: '#F8F8F8',
            paddingTop: "1rem",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            paddingBottom: "3rem",
            minHeight: 'auto'
        }}
    >{children}</Box>;
}

const LineReadOnlyText = ({ label, value }) => (
    <LineTextContainer>
        <Box direction="row" align="center" gap="small">
            <TextLabel variant={"body1-bold"} label={label + " : "} />
            <CustomTextInput
                value={value || '-'}
            />
        </Box>
    </LineTextContainer>
)

export {
    LineTextContainer,
    TitleContainer,
    CardContainer,
    Container,
    CellContainer,
    LineReadOnlyText
}
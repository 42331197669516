import { Box, Tab, Tabs } from 'grommet';
import React from 'react';
import { getGestionCollabs } from '../../../services/dashboard';
import Stats from '../stats';
import AdminAppelReport from '../stats/appels/admin_appel_reports';

class GestionStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            commerciaux: []
        }
    }

    componentDidMount() {
        getGestionCollabs()
            .then(res => {
                this.setState({ commerciaux: [{ name: 'Tous', id: 'All' }, ...res.data] })
            });
    }

    render() {
        return (
            <Box style={{ width: "100%", paddingTop: 20 }}
                align="center" justify="center"
                alignSelf="center"
                alignContent="center" gap="medium">
                <Tabs>
                    <Tab title="Indicateur de performance">
                        <Stats showMessage={this.props.showMessage}
                            commerciaux={this.state.commerciaux}
                        />
                    </Tab>
                    {/* <Tab title="Stat appel">
                        <AdminAppelReport showMessage={this.props.showMessage} commerciaux={this.state.commerciaux} />
                    </Tab> */}
                </Tabs>
            </Box>
        )
    }
}

export default GestionStats;
import moment from "moment";

export function calculateValeurRetenue(dommage, vestute){
    if (dommage.date_achat) {
        const valeurResiduelle = calculateValeurResiduelle(dommage, vestute);
        const prixReparations = parseFloat(dommage.montant_reparations) || 0;
        const min = prixReparations ? Math.min(valeurResiduelle, prixReparations) : valeurResiduelle;

        return parseFloat(dommage.prix_achat_origine) - min;
    }
    const equipement = vestute?.find(c => c.equipement === dommage.categorie);
    if (!equipement) return 0;
    const residuelle = equipement.residuelle;
    return parseFloat(dommage.prix_achat_origine) * (100 - parseFloat(residuelle.replace('%', ''))) / 100;
}

export const calculateVetuste = (dommage, vestute) => {
    if (!dommage.date_achat) return 0;
    const equipement = vestute?.find(c => c.equipement === dommage.categorie);
    if (!equipement) return 0;
    const abattementValue = parseFloat(equipement.abattement.replace('%', '')) / 100;
    const diffDate = moment(dommage.date_survenance).diff(moment(dommage.date_achat), 'days');
    const vetusteValue = diffDate / 365 * abattementValue;


    return Math.max(abattementValue, vetusteValue)
}

export const calculateValeurResiduelle = (dommage, vestute) => {
    if (!dommage.prix_achat_origine) return 0;
    const equipement = vestute?.find(c => c.equipement === dommage.categorie);
    if (!equipement) return 0;
    const residuelle = equipement.residuelle;
    const _vestureValue = calculateVetuste(dommage, vestute);

    return Math.max(
        parseFloat(dommage.prix_achat_origine) * parseFloat(residuelle.replace('%', '')) / 100,
        parseFloat(dommage.prix_achat_origine) * (1 - _vestureValue)
    );
}

export const calculateTotal = (dommage, vestute) => {
    if (dommage.date_achat) {
        return (!dommage.montant_reparations || !parseFloat(dommage.montant_reparations)) ?
            calculateValeurResiduelle(dommage, vestute) :
            Math.min(calculateValeurResiduelle(dommage, vestute), parseFloat(dommage.montant_reparations));
    }
    const equipement = vestute?.find(c => c.equipement === dommage.categorie);
    if (!equipement) return 0;
    const residuelle = equipement.residuelle;
    return calculateValeurResiduelle(dommage, vestute) * parseFloat(residuelle.replace('%', '')) / 100;
}
import React from 'react';

import {
    Box,
    Select,
    Grommet,
} from 'grommet';
import { TextLabel } from '../text-label';
import { getSimpleDateFR } from '../../../../services/utils';
import { CaretDownFill, CaretUpFill } from 'grommet-icons';

const customTheme = {
    global: {
        control: {
            border: {
                radius: '16px'
            },
        },
        input: {
            width: '400px',
        }
    },
    select: {
        options: {
            text: {
                size: '0.75rem',
                lineHeight: '1.2rem',
            }
        },
        icons: {
            color: "rgba(100,116,139,1)",
            down: CaretDownFill ,
            up: CaretUpFill,
            color: "#64748B"
        },
    },
};

export default function CustomSelect({ value, onChange, options, editMode, disabled }) {
    return (
        editMode ?
            <Grommet theme={customTheme}>
                <Box style={{ maxWidth: 500 }} align="center" justify="center">
                    <Select
                        value={value}
                        onChange={onChange}
                        options={options}
                        disabled={disabled}
                        style={{
                            fontSize: "0.75rem",
                            padding: "0.5rem",
                            lineHeight: "1.2rem",
                            color: "rgba(100,116,139,1)",
                        }}
                    />
                </Box>
            </Grommet>
            :
            <TextLabel label={value} variant={"body1"} />
    )
}
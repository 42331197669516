import {
	SET_FILES,
	CLEAR_FILES,
	SET_LOADING
} from "../types";

export default (state, action) => {
	switch (action.type) {
		case SET_FILES:
			return {
				...state,
				files: action.payload,
				loading: false,
			};
			case CLEAR_FILES:
				return {
					...state,
					files: [],
					loading: false,
				};
				case SET_LOADING:
					return {
						...state,
						loading: true,
					};
			
		default:
			return state;
	}
};

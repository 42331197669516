import { Box, Text, Heading, TextInput, Button, Image } from "grommet";
import DateInput from "../../../elements/date_input";
import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { toCurrency } from "../../../../services/utils";
import CustomInput from "../../../elements/custom_input";
import long_duree from "../../../../images/long_duree.png";
import cour_duree from "../../../../images/cour_duree.png";
import house from "../../../../images/house.png";
import appt from "../../../../images/appt.png";

import ClickableCards from "../../../elements/clickable_cards";
import ToggleButton from "../../../elements/toggle_button/toggle_button";
import ContactSelect, { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import ApporteurSelect from "../../../elements/apporteur_select";

const activeStyle = {
  background: "#59a4f4",
  border: "none",
  cursor: "pointer",
  color: "#fff",
};
const inactiveStyle = {
  background: "#EDEDED",
  border: "none",
  cursor: "pointer",
  color: "#a2a2a3",
};
const LOCATION_DURATION = {
  short: "Location en courte durée",
  long: "Location à l'année",
};
const BIEN_TYPE = {
  maison: "Maison",
  appartement: "Appartement",
};

const CustomText = ({ label }) => (
  <Text style={{ fontWeight: "bold" }} size="small" textAlign="end">
    {label}
  </Text>
);

const Line = ({ leftLabel, rightChildren }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "30%" }}>
      <CustomText label={leftLabel + " :"} />
    </Box>
    <Box style={{ width: "70%" }}>{rightChildren}</Box>
  </Box>
);

const Info = ({ devis, updateDevis, toRecap }) => {
  const locationAddress = usePlacesWidget({
    apiKey: "AIzaSyBa3n60yKzDhlgz67vh9YUTwHSlrRzaa4g",
    onPlaceSelected: (place) => {
      let parts = place.address_components;
      let address = parts
        .filter(
          (s) =>
            s.types &&
            (s.types.includes("street_number") || s.types.includes("route"))
        )
        .map((s) => s.long_name)
        .join(" ");
      let postal_code = parts
        .filter((s) => s.types && s.types.includes("postal_code"))
        .map((s) => s.long_name)
        .join("");
      let city = parts
        .filter((s) => s.types && s.types.includes("locality"))
        .map((s) => s.long_name)
        .join("");

      updateDevis("address", address);
      updateDevis("zip", postal_code);
      updateDevis("city", city);
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "fr" },
    },
  });

  
  if (!devis.effectiveDate) {
    devis.effectiveDate = new Date();
  }
  return (
    <Box
      gap="small"
      style={{ width: "100%" }}
      alignSelf="center"
      align="center"
      justify="center"
    >
      <Heading level="2" color="#59A4F4" textAlign="center">
        Trusti PNO
      </Heading>
      <Box
        width="xlarge"
        gap="medium"
        alignSelf="center"
        align="center"
        pad="medium"
        justify="center"
      >
        <ContactSelectV2
          contact={devis.client || {}}
          updateContact={updateDevis}
          addressIncluded={true}
        />
        <ApporteurSelect
          apporteur={devis.apporteur || {}}
          onDealUpdate={updateDevis}
        />
        <Box width="large" gap="medium">
          <Text style={{ fontWeight: "bold" }} textAlign="center">
            Informations de votre bien en location
          </Text>

          <Box style={{ width: "100%" }} gap="small">
            <Line
              leftLabel="Quel type de logement"
              rightChildren={
                <ClickableCards
                  cards={[
                    {
                      img: house,
                      text: BIEN_TYPE.maison,
                      selected:
                        devis.bienType === BIEN_TYPE.maison ? "solid" : "none",
                      onClick: () => updateDevis("bienType", BIEN_TYPE.maison),
                    },
                    {
                      img: appt,
                      text: BIEN_TYPE.appartement,
                      selected:
                        devis.bienType === BIEN_TYPE.appartement
                          ? "solid"
                          : "none",
                      onClick: () =>
                        updateDevis("bienType", BIEN_TYPE.appartement),
                    },
                  ]}
                />
              }
            />
            <Line
              leftLabel="Adresse de location"
              rightChildren={
                <TextInput
                  size={"small"}
                  ref={locationAddress.ref}
                  style={{
                    background: "#EDEDED",
                    fontSize: 16,
                    borderRadius: 10,
                    border: "none",
                  }}
                  value={devis.address}
                  onChange={(event) =>
                    updateDevis("address", event.target.value)
                  }
                />
              }
            />
            <Line
              leftLabel="Code postal"
              rightChildren={
                <CustomInput
                  value={devis.zip}
                  onChange={(event) =>
                    updateDevis("zip", event.target.value)
                  }
                />
              }
            />
            <Line
              leftLabel="Ville"
              rightChildren={
                <CustomInput
                  value={devis.city}
                  onChange={(event) =>
                    updateDevis("city", event.target.value)
                  }
                />
              }
            />
            <Line
              leftLabel={`Votre ${devis.bienType} est loué`}
              rightChildren={
                <Box gap="medium">
                  <Button
                    style={
                      devis.locationDuration === LOCATION_DURATION.long
                        ? activeStyle
                        : inactiveStyle
                    }
                    onClick={() =>
                      updateDevis("locationDuration", LOCATION_DURATION.long)
                    }
                    icon={<Image style={{ height: 30 }} src={long_duree} />}
                    size="small"
                    label="À l’année au titre de la résidence principale du locataire ou des colocataire"
                  />
                  <Button
                    style={
                      devis.locationDuration === LOCATION_DURATION.short
                        ? activeStyle
                        : inactiveStyle
                    }
                    onClick={() =>
                      updateDevis("locationDuration", LOCATION_DURATION.short)
                    }
                    icon={<Image style={{ height: 30 }} src={cour_duree} />}
                    size="small"
                    label="En courte durée (location saisonnière avec renonciation à recours contre le locataire)"
                  />
                </Box>
              }
            />
            <Line
              leftLabel={"Nombre de pièces"}
              rightChildren={
                <ToggleButton
                  groups={Array(9)
                    .fill()
                    .map((v, index) => index + 1)
                    .map((value) => ({
                      label: value,
                      selected: devis.totalRooms === value,
                      onClick: () => updateDevis("totalRooms", value),
                    }))}
                />
              }
            />

            <Line
              leftLabel={`Indiquer le nombre de nombre de m2`}
              rightChildren={
                <Box style={{ width: "30%" }}>
                  <CustomInput
                    value={devis.surface}
                    placeholder="m2"
                    onChange={(event) =>
                      updateDevis("surface", event.target.value)
                    }
                  />
                </Box>
              }
            />
            <Line
              leftLabel={`Choisissez un capital mobilier`}
              rightChildren={
                <ToggleButton
                  groups={
                    devis.bienType === "Appartement"
                      ? [
                          {
                            label: "0 €",
                            selected: devis.capitalMobilier === 0,
                            onClick: () => updateDevis("capitalMobilier", 0),
                          },
                          {
                            label: "10 000 €",
                            selected: devis.capitalMobilier === 10000,
                            onClick: () =>
                              updateDevis("capitalMobilier", 10000),
                          },
                          {
                            label: "20 000 €",
                            selected: devis.capitalMobilier === 20000,
                            onClick: () =>
                              updateDevis("capitalMobilier", 20000),
                          },
                        ]
                      : [
                          {
                            label: "10 000 €",
                            selected: devis.capitalMobilier === 10000,
                            onClick: () =>
                              updateDevis("capitalMobilier", 10000),
                          },
                          {
                            label: "20 000 €",
                            selected: devis.capitalMobilier === 20000,
                            onClick: () =>
                              updateDevis("capitalMobilier", 20000),
                          },
                        ]
                  }
                />
              }
            />

            <Line
              leftLabel={`Débuter l'assurance`}
              rightChildren={
                <DateInput
                  placeholder="Date début jj/mm/aaaa"
                  value={devis.effectiveDate}
                  onChange={(date) => updateDevis("effectiveDate", date)}
                />
              }
            />

            <Line
              leftLabel={`Date de résiliation`}
              rightChildren={
                <DateInput
                  placeholder="Date début jj/mm/aaaa"
                  value={devis.date_resiliation}
                  onChange={(date) => updateDevis("date_resiliation", date)}
                />
              }
            />
            <Line
              leftLabel={`Locale`}
              rightChildren={
                <ToggleButton
                  groups={
                    [
                      {
                        label: "fr",
                        selected: devis.locale === 'fr',
                        onClick: () =>
                          updateDevis("locale", 'fr'),
                      },
                      {
                        label: "en",
                        selected: devis.locale === 'en',
                        onClick: () =>
                          updateDevis("locale", 'en'),
                      }
                    ]
                  }
                />
              }
            />
          </Box>
          {devis.price && (
            <Text
              textAlign="center"
              size={"large"}
              style={{ fontWeight: "bold", color: "#707070", marginTop: 30 }}
            >
              L'ssurance s’élève à{" "}
              <span style={{ color: "#6AA5EE", fontWeight: "bold" }}>
                {toCurrency(devis.price)}
              </span>
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Info;

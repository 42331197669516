import {
    Box, FileInput
} from "grommet";
import { DocumentDownload, Trash } from "grommet-icons";
import React, { useState } from 'react';
import { uploadFile } from "../../../../services/storage_service";
import ClickableCards from '../../../elements/clickable_cards';
import CustomInput from "../../../elements/custom_input";
import DateInput from "../../../elements/date_input";
import Line from "../../../elements/line_container";
import StandardText from "../../../elements/standard_text";
import ToggleButton from "../../../elements/toggle_button/toggle_button";

const CIVILITY = {
    monsieur: 'Monsieur',
    madame: 'Madame'
}

const doUploadFile = (files, callback, showError) => {
    const fileList = files;
    if (fileList) {
        for (let i = 0; i < fileList.length; i += 1) {
            const file = fileList[i];
            console.log(file.name);
            uploadFile(file)
                .then((result) => {
                    console.log("result = ", result.data);
                    callback(result.data);
                })
                .catch((err) => {
                    showError("Erreur inattendu télécharger.", "error");
                    callback([]);
                });
        }
    }
};

const LocataireInfo = ({ devis, locataires = [], onDevisChange, showMessage }) => {

    const [numberLocataire, setNumberLocataire] = useState();

    const onLocataireInfoChange = (locataire, field, value) => {
        locataire[field] = value;
        onDevisChange('locataires', locataires.map(s => s));
    }

    return (
        <Box align='center' justify='center' gap='small' pad={'small'}>
            <Box width={'large'} gap='small'>
                <StandardText size="xlarge" style={{ fontWeight: 'bold' }} label="Informations du ou des locataire(s)" />
                <StandardText label={'Renseigner les informations du ou des locataires :'} style={{ fontWeight: 'bold' }} />
                <Line
                    leftLabel={'Combien de locataire'}
                    rightChildren={
                        <ToggleButton
                            groups={
                                [1, 2].map(val => ({
                                    label: val + "",
                                    selected: numberLocataire === val,
                                    onClick: () => {
                                        if (val === numberLocataire) return;
                                        setNumberLocataire(val);
                                        if (locataires.length > val) {
                                            locataires = [locataires[0]];
                                        } else if (locataires.length === 0) {
                                            locataires = val === 2 ? [{}, {}] : [{}];
                                        } else {
                                            locataires.push({});
                                        }
                                        onDevisChange('locataires', locataires.map(l => l));
                                    }
                                }))
                            }
                        />
                    }
                />

                <Box style={{ height: 10 }} />
                {
                    locataires.map((locataire, index) => (
                        <Box width={'large'} gap='small' key={index + 1}>
                            <StandardText label={`Renseigner les informations du ${index === 0 ? '1er' : '2ème'} locataire :`} style={{ fontWeight: 'bold' }} />
                            <Line
                                leftLabel={'Vous êtes ?'}
                                direction='column'
                                rightChildren={
                                    <ToggleButton
                                        groups={
                                            ["Étudiant", "Employé", "Indépendant", "Dirigeant d’entreprise", "Société"].map(val => ({
                                                label: val,
                                                selected: locataire.titulaire === val,
                                                onClick: () => onLocataireInfoChange(locataire, 'titulaire', val)
                                            }))
                                        }
                                    />
                                }
                            />
                            <Box gap='small'>
                                <Line
                                    leftLabel={'Nom'}
                                    rightChildren={
                                        <CustomInput
                                            value={locataire.lastname}
                                            onChange={event => onLocataireInfoChange(locataire, 'lastname', event.target.value)}
                                        />
                                    }
                                />
                                <Line
                                    leftLabel={'Prénom'}
                                    rightChildren={
                                        <CustomInput
                                            value={locataire.firstname}
                                            onChange={event => onLocataireInfoChange(locataire, 'firstname', event.target.value)}
                                        />
                                    }
                                />
                            </Box>
                            <Line
                                leftLabel={"Email"}
                                rightChildren={
                                    <CustomInput
                                        value={locataire.email}
                                        onChange={event => onLocataireInfoChange(locataire, 'email', event.target.value)}
                                    />
                                }
                            />
                            <Line
                                leftLabel={"Phone"}
                                rightChildren={
                                    <CustomInput
                                        value={locataire.phone}
                                        onChange={event => onLocataireInfoChange(locataire, 'phone', event.target.value)}
                                    />
                                }
                            />
                            <Line
                                leftLabel={"Date de naissance"}
                                rightChildren={
                                    <DateInput
                                        style={{ width: '100%' }}
                                        size={'small'}
                                        value={locataire.date_de_naissance}
                                        onChange={(date) => onLocataireInfoChange(locataire, "date_de_naissance", date)}
                                    />
                                }
                            />
                            <Line
                                leftLabel={"Nationalité"}
                                rightChildren={
                                    <CustomInput
                                        value={locataire.nationality}
                                        onChange={event => onLocataireInfoChange(locataire, 'nationality', event.target.value)}
                                    />
                                }
                            />
                            <Line
                                leftLabel={"Salaire mensuel net"}
                                rightChildren={
                                    <CustomInput
                                        placeholder={'€'}
                                        value={locataire.salaire_net}
                                        onChange={event => onLocataireInfoChange(locataire, 'salaire_net', event.target.value)}
                                    />
                                }
                            />
                            <Line
                                leftLabel={"Autre à préciser"}
                                rightChildren={
                                    <CustomInput
                                        placeholder={'€'}
                                        value={locataire.autre_revenu}
                                        onChange={event => onLocataireInfoChange(locataire, 'autre_revenu', event.target.value)}
                                    />
                                }
                            />
                            <Line
                                leftLabel={"Revenu net global mensuel"}
                                rightChildren={
                                    <CustomInput
                                        placeholder={'€'}
                                        value={locataire.net_global}
                                        onChange={event => onLocataireInfoChange(locataire, 'net_global', event.target.value)}
                                    />
                                }
                            />
                            {locataire.titulaire === "Étudiant" &&
                                <EtudiantFiles
                                    locataire={locataire}
                                    onLocataireInfoChange={onLocataireInfoChange}
                                    showMessage={showMessage}
                                />
                            }
                            {locataire.titulaire === "Employé" &&
                                <EmployeFiles
                                    locataire={locataire}
                                    onLocataireInfoChange={onLocataireInfoChange}
                                    showMessage={showMessage}
                                />
                            }
                            {locataire.titulaire === "Indépendant" &&
                                <IndependantFiles
                                    locataire={locataire}
                                    onLocataireInfoChange={onLocataireInfoChange}
                                    showMessage={showMessage}
                                />
                            }
                            {locataire.titulaire === "Dirigeant d’entreprise" &&
                                <DirigeantFiles
                                    locataire={locataire}
                                    onLocataireInfoChange={onLocataireInfoChange}
                                    showMessage={showMessage}
                                />
                            }
                            {locataire.titulaire === "Société" &&
                                <SocieteFiles
                                    locataire={locataire}
                                    onLocataireInfoChange={onLocataireInfoChange}
                                    showMessage={showMessage}
                                />
                            }

                            <Line
                                leftLabel="Date de résiliation"
                                rightChildren={
                                    <DateInput
                                        style={{ width: '100%' }}
                                        size={'small'}
                                        value={devis.date_resiliation}
                                        onChange={(date) => onDevisChange("date_resiliation", date)}
                                    />
                                }
                            />

                            <Box style={{ height: 10 }} />

                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}

const EmployeFiles = ({ locataire, onLocataireInfoChange, showMessage }) => {
    return (
        <Box gap="small" width={'full'}>
            <CarteIdentite
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
            <Impot
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
            <AttestationEmploi
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
            <BulletinSalaires
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
        </Box>
    )
}

const EtudiantFiles = ({ locataire, onLocataireInfoChange, showMessage }) => {
    return (
        <Box gap="small" width={'full'}>
            <CarteIdentite
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
            <CarteEtudiant
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
        </Box>
    )
}

const IndependantFiles = ({ locataire, onLocataireInfoChange, showMessage }) => {
    return (
        <Box gap="small" width={'full'}>
            <CarteIdentite
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
            <DeuxDernierImpot
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
            <CarteProfessionnelle
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
        </Box>
    )
}

const SocieteFiles = ({ locataire, onLocataireInfoChange, showMessage }) => {
    return (
        <Box gap="small" width={'full'}>
            <KBIS
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
            <DeuxDernierBilans
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
            <DeuxCompteExploitation
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
        </Box>
    )
}

const DirigeantFiles = ({ locataire, onLocataireInfoChange, showMessage }) => {
    return (
        <Box gap="small" width={'full'}>
            <CarteIdentite
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
            <KBIS
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
            <Impot
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
            <BulletinSalaires
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
            <RevenuComptable
                locataire={locataire}
                onLocataireInfoChange={onLocataireInfoChange}
                showMessage={showMessage}
            />
        </Box>
    )
}

const CarteIdentite = ({ locataire, onLocataireInfoChange, showMessage }) => (
    <GenericUploadFile
        locataire={locataire}
        onLocataireInfoChange={onLocataireInfoChange}
        showMessage={showMessage}
        title={"Carte d'identité ou passport"}
        field={"id_card"}
    />
)

const Impot = ({ locataire, onLocataireInfoChange, showMessage }) => (
    <GenericUploadFile
        locataire={locataire}
        onLocataireInfoChange={onLocataireInfoChange}
        showMessage={showMessage}
        title={"Votre dernier avis d’impôt"}
        field={"impot"}
    />
)

const AttestationEmploi = ({ locataire, onLocataireInfoChange, showMessage }) => (
    <GenericUploadFile
        locataire={locataire}
        onLocataireInfoChange={onLocataireInfoChange}
        showMessage={showMessage}
        title={"Votre attestation d’emploi"}
        field={"attestation_emploi"}
    />
)

const BulletinSalaires = ({ locataire, onLocataireInfoChange, showMessage }) => (
    <GenericUploadFile
        locataire={locataire}
        onLocataireInfoChange={onLocataireInfoChange}
        showMessage={showMessage}
        title={"Vos 3 derniers bulletins de salaire"}
        field={"bulletin_salaires"}
    />
)

const CarteEtudiant = ({ locataire, onLocataireInfoChange, showMessage }) => (
    <GenericUploadFile
        locataire={locataire}
        onLocataireInfoChange={onLocataireInfoChange}
        showMessage={showMessage}
        title={"Carte d’étudiant"}
        field={"carte_etudiant"}
    />
)

const CarteProfessionnelle = ({ locataire, onLocataireInfoChange, showMessage }) => (
    <GenericUploadFile
        locataire={locataire}
        onLocataireInfoChange={onLocataireInfoChange}
        showMessage={showMessage}
        title={"Carte professionnelle"}
        field={"carte_professionnelle"}
    />
)

const DeuxDernierImpot = ({ locataire, onLocataireInfoChange, showMessage }) => (
    <GenericUploadFile
        locataire={locataire}
        onLocataireInfoChange={onLocataireInfoChange}
        showMessage={showMessage}
        title={"2 derniers avis d’imposition"}
        field={"2_derniers_impots"}
    />
)

const KBIS = ({ locataire, onLocataireInfoChange, showMessage }) => (
    <GenericUploadFile
        locataire={locataire}
        onLocataireInfoChange={onLocataireInfoChange}
        showMessage={showMessage}
        title={"KBIS de moins de 3 mois"}
        field={"kbis"}
    />
)

const RevenuComptable = ({ locataire, onLocataireInfoChange, showMessage }) => (
    <GenericUploadFile
        locataire={locataire}
        onLocataireInfoChange={onLocataireInfoChange}
        showMessage={showMessage}
        title={"Attestation de revenus d’expert-comptable pour l’année en cours "}
        field={"revenus_expert_comtable"}
    />
)

const DeuxDernierBilans = ({ locataire, onLocataireInfoChange, showMessage }) => (
    <GenericUploadFile
        locataire={locataire}
        onLocataireInfoChange={onLocataireInfoChange}
        showMessage={showMessage}
        title={"2 derniers bilans"}
        field={"deux_bilans"}
    />
)

const DeuxCompteExploitation = ({ locataire, onLocataireInfoChange, showMessage }) => (
    <GenericUploadFile
        locataire={locataire}
        onLocataireInfoChange={onLocataireInfoChange}
        showMessage={showMessage}
        title={"Vos 2 derniers comptes d’exploitation"}
        field={"deux_comptes_exploitation"}
    />
)

const GenericUploadFile = ({ locataire, onLocataireInfoChange, showMessage, title, field }) => (

    <Box width={'full'} direction='row' justify="between" align="center" gap="small">
        <FileInput
            messages={{
                browse: "Choisir",
                dropPrompt: title,
            }}
            multiple={false}
            onChange={(event) => {
                doUploadFile(
                    event.target.files,
                    async (data) => {
                        if (data.length > 0) {
                            let _uploaded = data[0];
                            onLocataireInfoChange(locataire, `${field}`, _uploaded.url);
                        }
                    },
                    showMessage
                );
            }}
        />

        {locataire[field] && <Box gap='small' direction="row" align="center">
            <a target={"_blank"} rel="noreferrer" href={`${locataire[field] || '#'}`}>
                <DocumentDownload />
                {title}
            </a>
            <Box onClick={() => onLocataireInfoChange(locataire, `${field}`, undefined)}>
                <Trash color="brand" />
            </Box>
        </Box>
        }
    </Box>
)

export default LocataireInfo;
import {
  Accordion,
  AccordionPanel,
  Box,
  Card,
  Grid,
  Select,
  Text,
  TextInput,
} from "grommet";
import React, { useState } from "react";
import {
  uploadContractPDF,
  uploadFile,
} from "../../../../services/storage_service";
import { FRAIS_DOSSIER } from "../../../elements/code_promo";
import CustomInput from "../../../elements/custom_input";
import DateInput from "../../../elements/date_input";
import DateRecap from "../../../elements/date_recap";
import RecapStatus from "../../../elements/devis_status";
import PrimaryButton from "../../../elements/primary_button";
import CustomSelect from "../../../elements/select_fill";
import { InfoDocuments } from "./recap";

const inputStyle = {
  background: "#EDEDED",
  fontSize: 14,
  fontWeight: 400,
  borderRadius: 10,
  border: "none",
};
const Line = ({ leftLabel, rightChildren, widthLeft, widthRight, size }) => (
  <Box>
    <Text color="grey">{leftLabel}</Text>
    <Box width="100%">{rightChildren}</Box>
  </Box>
);

const FormTitle = ({ title }) => (
  <Box margin="20px 0 0 0">
    <Text weight="bold" color="grey">
      {title}
    </Text>
    <Box width="50px" height="3px" background="brand" />
  </Box>
);

const doUploadContratPDF = (files, callback, showError) => {
  const fileList = files;
  for (let i = 0; i < fileList.length; i += 1) {
    const file = fileList[i];
    console.log(file.name);
    uploadContractPDF(file)
      .then((result) => {
        // console.log("doUploadContratPDF = ", result.data);
        callback(result.data);
      })
      .catch((err) => {
        showError(
          "Erreur: Le fichier téléchargé n'est pas un PDF modifiable.",
          "error"
        );
        callback([]);
      });
  }
};

const doUploadFile = (files, callback, showError) => {
  const fileList = files;
  for (let i = 0; i < fileList.length; i += 1) {
    const file = fileList[i];
    console.log(file.name);
    uploadFile(file)
      .then((result) => {
        console.log("result = ", result.data);
        callback(result.data);
      })
      .catch((err) => {
        showError("Erreur inattendu télécharger.");
        callback([]);
      });
  }
};

const onAmountChanged = (event, callback, showError, callbackNoChanged) => {
  if (!event.target.value) {
    callback(0);
    return;
  }
  event.target.value = event.target.value
    .replace(/[^0-9.,]/g, "")
    .replace(/,/g, ".")
    .replace(/(\..*?)\..*/g, "$1");
  if (event.target.value.slice(-1) === ".") {
    // skip callback to next step
    callbackNoChanged(event.target.value);
    return;
  }
  const _arrA = event.target.value.split(".");
  if (_arrA.length > 1) {
    if (_arrA[1].replace(/0/g, "") === "") {
      // skip callback to next step
      callbackNoChanged(event.target.value);
      return;
    }
  }
  const value = parseFloat(event.target.value || "0");
  if (isNaN(value)) {
    showError("Valeur non valid");
    return;
  } else {
    callback(value);
  }
};

const DevisContratForm = ({
  devis,
  updateDevis,
  isModeContrat,
  allCompanies,
  sendEmailDevisContrat,
  submitModification,
  showMessage,
  onClose
}) => {
  const [fraisDossier, setFraisDossier] = useState(devis.frais_dossier || 0);
  const [fraisDossierPreinput, setFraisDossierPreinput] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // array from 0% to 99%
  const _optionsPercents = [...Array(100).keys()].map((i) => {
    return `${i}`;
  });
  const [optionsSurCommission, setOptionsSurCommission] =
    useState(_optionsPercents);
  const [optionsCommissionPeriodique, setOptionsCommissionPeriodique] =
    useState(_optionsPercents);
  const [optionsCommissionInitial, setOptionsCommissionInitial] =
    useState(_optionsPercents);
  const [activeIndex, setActiveIndex] = useState([0, 1, 2]);

  const onSaveData = (_options) => {
    updateDevis("need_generate_appel", 0);
    submitModification(_options);
  };

  return (
    <Box gap="medium" align="center" alignSelf="center" pad="small" fill>
      <Card
        background={"white"}
        direction={"row"}
        justify="between"
        alignContent="start"
        align="start"
        pad="small"
        style={{ width: "100%" }}
      >
        <Box alignContent="start" align="start">
          <Text weight={"bold"} size="medium">
            Assurance de vie
          </Text>
          <Text size="small">{devis.sub_product}</Text>
        </Box>
        <Box alignContent="start" align="start">
          <DateRecap deal={devis} />
          <Text textAlign="center">
            <b>ID:</b> {devis.id}
          </Text>
        </Box>
        <Box direction="row" alignContent="center" align="center">
          <Text textAlign="center">Statut: </Text>
          <RecapStatus status={devis.status} />
        </Box>
      </Card>

      <Accordion
        multiple
        fill
        activeIndex={activeIndex}
        gap="small"
        onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}
      >
        <AccordionPanel
          header={renderPanelHeader(
            "Informations du devis",
            activeIndex.includes(0)
          )}
        >
          <Box gap="small" background={"white"}>
            <SectionHeader title={"Investissement"} />
            <Box direction="row">
              <Box pad={"small"} fill gap="xsmall">
                <>
                  <Text weight={400} size={"14px"} margin="10px 0 10px 0">
                    Versement initiale
                  </Text>
                  <Box direction="row" align="center" width="30%">
                    <TextInput
                      type="number"
                      style={inputStyle}
                      icon={<span>€</span>}
                      defaultValue={devis.first_payment}
                      reverse
                      onChange={(event) =>
                        updateDevis("first_payment", event.target.value)
                      }
                    />
                  </Box>
                </>
              </Box>
              <Box pad={"small"} fill gap="xsmall">
                <>
                  <Text weight={400} size={"14px"} margin="10px 0 10px 0">
                    Versement périodique
                  </Text>
                  <Box direction="row" align="center" width="30%">
                    <TextInput
                      type="number"
                      style={inputStyle}
                      icon={<span>€</span>}
                      defaultValue={devis.monthly_payment}
                      reverse
                      onChange={(event) =>
                        updateDevis("monthly_payment", event.target.value)
                      }
                    />
                  </Box>
                </>
              </Box>
            </Box>
          </Box>
          <Box gap="small" background={"white"}>
            <SectionHeader title={"Comptabilité"} />
            <Box width={"full"} pad={"small"}>
              <Grid columns={["1/2", "1/2"]} gap="small" width={"full"}>
                <Line
                  leftLabel="Compagnie"
                  rightChildren={
                    <CustomSelect
                      style={{ width: "100%" }}
                      placeholder="Compagnie"
                      options={
                        allCompanies ? allCompanies.map((e) => e.label) : []
                      }
                      value={devis.compagnie}
                      onChange={({ option }) =>
                        updateDevis("compagnie", option)
                      }
                    />
                  }
                />
                <Box />
                <Line
                  leftLabel="Taux de com versement initial"
                  rightChildren={
                    <Select
                      options={optionsCommissionInitial}
                      value={`${devis.taux_commission_initial || 0}`}
                      onChange={({ option }) =>
                        updateDevis("taux_commission_initial", parseInt(option))
                      }
                      onSearch={(text) => {
                        const escapedText = text.replace(
                          /[-\\^$*+?.()|[\]{}]/g,
                          "\\$&"
                        );
                        const exp = new RegExp(escapedText, "i");
                        setOptionsCommissionInitial(
                          optionsCommissionInitial.filter((o) => exp.test(o))
                        );
                      }}
                      icon={<Text>%</Text>}
                    />
                  }
                />

                <Line
                  leftLabel="Taux de com versement périodique"
                  rightChildren={
                    <Select
                      options={optionsCommissionPeriodique}
                      value={`${devis.taux_commission_periodique || 0}`}
                      onChange={({ option }) =>
                        updateDevis(
                          "taux_commission_periodique",
                          parseInt(option)
                        )
                      }
                      onSearch={(text) => {
                        const escapedText = text.replace(
                          /[-\\^$*+?.()|[\]{}]/g,
                          "\\$&"
                        );
                        const exp = new RegExp(escapedText, "i");
                        setOptionsCommissionPeriodique(
                          optionsCommissionPeriodique.filter((o) => exp.test(o))
                        );
                      }}
                      icon={<Text>%</Text>}
                    />
                  }
                />

                <Line
                  leftLabel="Taux de sur-commission"
                  rightChildren={
                    <Select
                      options={optionsSurCommission}
                      value={`${devis.taux_sur_commission || 0}`}
                      onChange={({ option }) =>
                        updateDevis("taux_sur_commission", parseInt(option))
                      }
                      onSearch={(text) => {
                        const escapedText = text.replace(
                          /[-\\^$*+?.()|[\]{}]/g,
                          "\\$&"
                        );
                        const exp = new RegExp(escapedText, "i");
                        setOptionsSurCommission(
                          optionsSurCommission.filter((o) => exp.test(o))
                        );
                      }}
                      icon={<Text>%</Text>}
                    />
                  }
                />
                <Box />
                <Line
                  leftLabel={`Frais de dossier / an`}
                  rightChildren={
                    <FRAIS_DOSSIER
                      devis={devis}
                      setFraisDossier={setFraisDossier}
                      setFraisDossierPreinput={setFraisDossierPreinput}
                      updateDevis={updateDevis}
                      fraisDossier={fraisDossier}
                      fraisDossierPreinput={fraisDossierPreinput}
                    />
                  }
                />
              </Grid>
            </Box>
          </Box>
          <Box gap="small" background={"white"}>
            <SectionHeader title={"Information contrat"} />
            <Box direction="row" width={"full"} pad={"small"}>
              <Grid columns={["1/2", "1/2"]} gap="small" width={"full"}>
                {isModeContrat && (
                  <Line
                    leftLabel="Numéro de contrat"
                    rightChildren={
                      <CustomInput
                        onChange={(event) => {
                          updateDevis("numero_contrat", event.target.value);
                        }}
                        value={devis.numero_contrat || ""}
                      />
                    }
                  />
                )}
                <Line
                  leftLabel="Date à laquelle vous souhaitez débuter votre assurance"
                  rightChildren={
                    <DateInput
                      value={devis.date_effet || null}
                      onChange={(date) => {
                        console.log(date);
                        updateDevis("date_effet", date);
                      }}
                    />
                  }
                />
              </Grid>
            </Box>
          </Box>
        </AccordionPanel>
        <AccordionPanel
          header={renderPanelHeader(
            "Information bancaire",
            activeIndex.includes(1)
          )}
        >
          <Box gap="small" background={"white"}>
            <Box direction="row" pad={"small"} width={"full"} gap="small">
              <Box width={"full"}>
                <Line
                  leftLabel="IBAN"
                  rightChildren={
                    <CustomInput
                      value={devis.iban}
                      onChange={(event) => {
                        updateDevis("iban", event.target.value);
                      }}
                    />
                  }
                />
              </Box>
              <Box width={"full"}>
                <Line
                  leftLabel="BIC"
                  rightChildren={
                    <CustomInput
                      value={devis.bic}
                      onChange={(event) => {
                        updateDevis("bic", event.target.value);
                      }}
                    />
                  }
                />
              </Box>
            </Box>
          </Box>
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader(
            "Gestion électronique documentaire (GED)",
            activeIndex.includes(2)
          )}
        >
          <InfoDocuments
            devis={devis}
            updateDevis={updateDevis}
            onSave={onSaveData}
            showMessage={showMessage}
            setIsLoading={setIsLoading}
          />
        </AccordionPanel>
      </Accordion>
      <Box
        align="center"
        margin="medium"
        direction="row"
        gap="small"
        justify="center"
      >
        <PrimaryButton
          label={`Fermer`}
          icon={<span>❌</span>}
          background="#C8C8C8"
          onClick={onClose}
        />
        <PrimaryButton
          disabled={isLoading}
          label={`Sauvegarder`}
          icon={<span>💾</span>}
          background="#59A4F4"
          onClick={() => {
            setIsLoading(true);
            submitModification(devis, () => setIsLoading(false));
          }}
        />
        <PrimaryButton
          disabled={isLoading}
          label={`Valider & Envoyer`}
          icon={<span>✅</span>}
          onClick={() => {
            setIsLoading(true);
            sendEmailDevisContrat(devis, () => setIsLoading(false));
          }}
        />
      </Box>
    </Box>
  );
};

const renderPanelHeader = (title, active) => (
  <Box
    fill
    direction="row"
    justify="between"
    pad="xsmall"
    background={"#CECECE"}
    style={{
      paddingLeft: 20,
      paddingRight: 20,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    }}
  >
    <strong>
      <Text>{title}</Text>
    </strong>
    <Text>{active ? "Fermez ce volet  ►" : "Ouvriez ce volet  ▼"}</Text>
  </Box>
);

const SectionHeader = ({ title, color, round }) => {
  return (
    <Box
      fill
      gap="small"
      pad="xxsmall"
      background={color || "brand"}
      style={round ? { borderRadius: 12 } : {}}
    >
      <Text color="white" style={{ fontWeight: "bold", marginLeft: 20 }}>
        {title}
      </Text>
    </Box>
  );
};

export default DevisContratForm;

import React from "react";
import { Box, Heading, Text, Card, Stack } from "grommet";
import { getSimpleDateFR, toCurrency } from "../../../../services/utils";
import RecapStatus from "../../../elements/devis_status";
import { DocumentDownload } from "grommet-icons";
import StatusDateAppel from "../../../elements/status_date_appel";
import PanelAppel from "../../../elements/panel_appel";
import EmailEventTracker from "../../../elements/email_tracker";
import StripeCustomer from "../../../elements/stripe_customer";
import CodePromo from "../../../elements/code_promo";
import ApporteurGestionnaire from "../../../elements/apporteur_gestionaire";
import TrustiBot from "../../../trustibot";
import DateRecap from "../../../elements/date_recap";

const FullWidthBox = ({ children, direction, gap }) => (
  <Box
    direction={direction}
    gap={gap}
    style={{ width: "100%" }}
    alignSelf="center"
  >
    {children}
  </Box>
);

const Recap = ({ devis, submit, toEditMode, close, sendForm, updateDevis, onSave }) => {
  console.log(devis);
  return (
    <Box
      direction="row"
      gap='small'
      style={{ width: "100vw" }}
    >
      <TrustiBot></TrustiBot>
      <Box
        gap="medium"
        // style={{ width: "100%" }}
        align="center"
        alignSelf="center"
        pad="small"
      >
        <Heading level="2" color="#59A4F4" textAlign="center">
          Trusti PNO Gîte
        </Heading>
        <Box direction="row" fill="horizontal" justify="center" alignContent="center">
          <Box align="center">
            <StatusDateAppel devis={devis} />
          </Box>
          <Box>
            <Box alignSelf="end">
              <RecapStatus status={devis.status} />
              <PanelAppel devis={devis} updateDevis={updateDevis} onSave={onSave} />
            </Box>
          </Box>
        </Box>
        <Box  gap="small" width={'full'} justify='center' align="center">
          <Card  pad="small" gap="small" width={'full'} >
            <Text textAlign="center"><b>ID:</b> {devis.id}</Text> 
          </Card>
        </Box>
        <Box  gap="small" width={'full'}>
          <DateRecap deal={devis} />
        </Box>
        <CodePromo devis={devis} />
        <Box width={'xlarge'}>
          <StripeCustomer devisId={devis.id} />
        </Box>
        <Box width="xlarge" direction="row" gap="small">
          <FullWidthBox gap="small">
            <Card style={{ width: "100%" }} pad="small">
              <Assure assure={devis.client || {}} />
            </Card>
            <Card style={{ width: "100%" }} pad="small">
              <AssuranceLocation devis={devis} />
            </Card>
          </FullWidthBox>

          <Card style={{ width: "100%" }} pad="small">
            <LocationDetails devis={devis} />
          </Card>
        </Box>

        <Box width="xlarge" direction="row" gap="small">
          <Card style={{ width: "100%" }} pad="medium">
            {devis.file_tableau_amortissement && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_tableau_amortissement}`}
              >
                <DocumentDownload />
                Tableau d’amortissement
              </a>
            )}
            {devis.file_offre_de_pret && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_offre_de_pret}`}
              >
                <DocumentDownload />
                Offre de prêt
              </a>
            )}
            {devis.file_devis && (
              <a target={"_blank"} rel="noreferrer" href={`${devis.file_devis}`}>
                <DocumentDownload />
                Devis
              </a>
            )}
            {devis.file_rib && (
              <a target={"_blank"} rel="noreferrer" href={`${devis.file_rib}`}>
                <DocumentDownload />
                RIB
              </a>
            )}
            {devis.file_contrat && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_contrat}`}
              >
                <DocumentDownload />
                Contrat
              </a>
            )}
            {devis.file_contrat_signed && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_contrat_signed}`}
              >
                <DocumentDownload />
                Contrat signé
              </a>
            )}
            {devis.file_others ? (
              devis.file_others.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                    <DocumentDownload />
                    {e && e.split("/").pop()}
                  </a>
                );
              })
            ) : (
              <></>
            )}
          </Card>
        </Box>
        {(devis.apporteur || devis.affiliation) && <Card pad={"small"} width={'xlarge'} >
          <ApporteurGestionnaire apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
        </Card>}
        <Box width={'xlarge'}>
          <EmailEventTracker messageIds={devis.emailEventIds} />
        </Box>
        <Box style={{ height: 100 }} />
      </Box>
    </Box>
  );
};

const LocationDetails = ({ devis }) => {
  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label="Informations de l'assurance" />
      <Line
        leftLabel="Véranda / balcon fermé"
        rightLabel={devis.location?.veranda ? "Oui" : "Non"}
      />
      <Line
        leftLabel="Cheminée à foyer fermé ou un poêle"
        rightLabel={devis.location?.cheminee ? "Oui" : "Non"}
      />
      <Line
        leftLabel="Spa et/ou un Jacuzzi"
        rightLabel={devis.location?.jacuzzi ? "Oui" : "Non"}
      />
      <Line
        leftLabel="Énergies renouvelables"
        rightLabel={devis.location?.energieRenouvelable ? "Oui" : "Non"}
      />
      <Line
        leftLabel="Piscine"
        rightLabel={devis.location?.piscine ? "Oui" : "Non"}
      />
      <Line
        leftLabel="Alarme avec télésurveillance"
        rightLabel={devis.location?.teleSurveillance ? "Oui" : "Non"}
      />
      <Line
        leftLabel="Superficie des dépendances"
        rightLabel={devis.location?.dependence}
      />
      <Line
        leftLabel="Capital mobilier"
        rightLabel={
          isNaN(parseFloat(devis.location?.capital))
            ? ""
            : toCurrency(parseFloat(devis.location?.capital))
        }
      />
      <Line
        leftLabel="Niveau de franchise"
        rightLabel={devis.location?.franchise}
      />
      <Line leftLabel="Fractionnement" rightLabel={devis.location?.abonnement} />
      <Line
        leftLabel="Date de d'effet"
        rightLabel={devis.begin_date ? getSimpleDateFR(devis.begin_date) : null}
      />
    </Box>
  );
};

const CustomText = ({ label, textAlign }) => (
  <Text
    style={{ fontWeight: "bold" }}
    size="small"
    textAlign={textAlign || "end"}
  >
    {label}
  </Text>
);
const Line = ({ leftLabel, rightLabel }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "50%" }}>
      <CustomText label={leftLabel + " :"} />
    </Box>
    <Box style={{ width: "50%" }}>
      <CustomText label={rightLabel} textAlign="start" />
    </Box>
  </Box>
);
const Assure = ({ assure }) => {
  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label="Informations de l'assuré" />
      <Line leftLabel="Civilité" rightLabel={assure.civilite} />
      {assure.civilite !== "Société" && (
        <Line leftLabel="Prénom" rightLabel={assure.firstname} />
      )}
      {assure.civilite !== "Société" && (
        <Line leftLabel="Nom" rightLabel={assure.lastname} />
      )}
      {assure.civilite === "Société" && (
        <Line leftLabel="Raison social" rightLabel={assure.raisonSocial} />
      )}
      <Line leftLabel="Mail" rightLabel={assure.email} />
      <Line leftLabel="Tel" rightLabel={assure.phone} />
      <Line
        leftLabel="Adresse postale"
        rightLabel={`${assure.address}, ${assure.zip}, ${assure.city}`}
      />
    </Box>
  );
};

const getLocationAddress = (location) => {
  return `${location.location_address}, ${location.location_zip}, ${location.location_city}`;
};
const getTotalRooms = (devis) => {
  let nombrePieces = parseInt(devis.location?.totalRooms);
  let btw40and80 = parseInt(devis.location?.btw40and80);
  let btw80and120 = parseInt(devis.location?.btw80and120);
  let moreThan120 = parseInt(devis.location?.moreThan120);

  return (
    (isNaN(nombrePieces) ? 0 : nombrePieces) +
    (isNaN(btw40and80) ? 0 : btw40and80) +
    (isNaN(btw80and120) ? 0 : 2 * btw80and120) +
    (isNaN(moreThan120) ? 0 : 3 * moreThan120)
  );
};
const AssuranceLocation = ({ devis }) => (
  <Box gap="small" style={{ width: "100%" }}>
    <SubHeading label="Informations de location" />
    <Line leftLabel="Type de logement" rightLabel={devis.bien_type} />
    <Line
      leftLabel="Adresse de la location"
      rightLabel={getLocationAddress(devis)}
    />
    <Line leftLabel="Nombre de niveaux" rightLabel={devis.location?.level} />
    <Line
      leftLabel="Année de construction"
      rightLabel={devis.location?.constructionYear}
    />
    <Line
      leftLabel="Nature de construction"
      rightLabel={devis.location?.constructionNature}
    />
    <Line
      leftLabel="Nombre total de pièces tarifées au contrat"
      rightLabel={getTotalRooms(devis)}
    />
  </Box>
);

const SubHeading = ({ label }) => (
  <Text textAlign="center" style={{ fontWeight: "bold" }}>
    {label}
  </Text>
);

export default Recap;


import React, { useState } from "react";
import { Box, Button, Card, Text, } from "grommet";
import LineModification from "../../elements/line_container/line_modification";
import PrimaryButton from "../../elements/primary_button";
import { Previous } from "grommet-icons";
import { createAffilieInvitation, getAppUsersValidated } from "../service";
import { SmallLine } from "../../elements/line_container";
import CustomSelect from "../../elements/select_fill";

class AppUserCreate extends React.Component {

    state = {
        user: {},
        apporteurs: []
    }

    componentDidMount = () => {
        getAppUsersValidated()
            .then((res) => {
                this.setState({
                    apporteurs: res.data.map((e) => {
                        return { ...e, name: `${e.prenom} ${e.nom}` };
                    })
                });
            })
            .catch((err) => {
                console.log("err", err);
                this.props.showMessage("Erreur inattendu", 'error');
            });

    }

    onUserChange = (_field, _value) => {
        let _user = this.state.user;
        _user[_field] = _value;
        this.setState({ user: _user });
    }

    saveInvitation = () => {
        createAffilieInvitation(this.state.user).then((res) => {
            this.props.showMessage && this.props.showMessage('Invitation envoyé.', 'success');
            this.props.onClose && this.props.onClose() ;
        })
            .catch((err) => {
                console.log("err", err);
                this.props.showMessage && this.props.showMessage("Erreur inattendu", 'error');
            });

    };

    render() {

        return (
            <Box
                style={{ width: "100%" }}
                gap="small"
                width="xlarge"
                alignSelf="center"
                align="center"
                pad="small"
                justify="center"
            >
                <Box direction="row" align="between">
                    <Box pad="small" width="xsmall">
                        <PrimaryButton icon={<Previous />} onClick={() => {
                            this.props.onClose && this.props.onClose();
                        }} />
                    </Box>
                    <Text size="xxlarge" style={{ padding: 10 }}><b> Création d'un utilisateur </b></Text>
                </Box>

                <Box gap='small' width={'large'} direction="row" >
                    <LineModification text="Nom" field='nom'
                        value={this.state.user.nom}
                        direction='column'
                        onChange={(field, value) => this.onUserChange(field, value)}
                    />
                    <LineModification text="Prénom" field='prenom'
                        value={this.state.user.prenom}
                        direction='column'
                        onChange={(field, value) => this.onUserChange(field, value)}
                    />

                </Box>
                <LineModification text="Email" field='email'
                    value={this.state.user.email}
                    direction='column'
                    onChange={(field, value) => this.onUserChange(field, value)}
                />
                <LineModification text="Date de naissance" field='phone'
                    value={this.state.user.birthday}
                    direction='column'
                    onChange={(field, value) => this.onUserChange(field, value)}
                />

                <Box direction={'column'} gap="xsmall" justify="start" align="center" width={'large'}>
                    <Box style={{ width: '100%' }}>
                        <Text size="xsmall" color={'#707070'}>Apporteur</Text>
                    </Box>
                    <Box style={{ width: '100%' }}>
                        <CustomSelect
                            options={this.state.apporteurs}
                            value={this.state.selectedApporteur?.name}
                            onChange={({ option }) => {
                                console.log(option);
                                this.onUserChange('invited_by', option.id);
                                this.onUserChange('created_with_code_parrain', option.code_parrain);
                            }}
                            labelKey={'name'}
                            valueKey={{ key: 'name', reduce: true }}
                        />
                    </Box>
                </Box>
                <LineModification text="Code Parrainage" field='created_with_code_parrain'
                    value={this.state.user.created_with_code_parrain}
                    direction='column'
                    onChange={(field, value) => this.onUserChange(field, value)}
                />
                <Button
                    label="Envoyer le mail d’inscription"
                    primary
                    onClick={() => {
                        console.log(this.state.user);
                        console.log('Envoyer')
                        this.saveInvitation();
                    }}
                />
            </Box>
        )
    }
}

export default AppUserCreate;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { styled } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

export default function MarksSlider({marks, value, step, min, max, onChange, valueLabelDisplay, disabled}) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{width: "calc(100% - 20px)", padding: "0 10px"}}>
      <Typography id="discrete-slider-custom" gutterBottom>
      </Typography>
      <Slider
        disabled={disabled}
        style={{"& .MuiSliderMark": {
          backgroundColor: "currentColor",
          height: 12,
          width: 12,
          "&.MuiSliderMarkActive": {
            opacity: 1,
            backgroundColor: "currentColor"
          }
        }, width: "100%"}}
        step={step}
        min={min}
        // color={"#fff"}
        // primaryColor={"#fff"}
        max={max}
        value={value}
        onChange={onChange}
        valueLabelDisplay={valueLabelDisplay || "auto"}
        marks={marks}
      />
    </div>
  );
}

const ColorMarksSlider = styled(Slider)(({ theme }) => ({
  height: 2,
  padding: "15px 0",
  color: '#E82C70',
  "& .MuiSliderMark": {
    backgroundColor: "#bfbfbf",
    height: 12,
    border: '1px solid #000',
    width: 12,
    marginTop: -5,
    marginLeft: -6,
    borderRadius: 6,
    "&.MuiSliderMarkActive": {
      opacity: 1,
      backgroundColor: "currentColor"
    }
  }
}))

export {
  ColorMarksSlider
}
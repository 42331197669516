import React from "react";
import {
	Box,
	Layer,
	Text,
} from "grommet";
import TrustiBot from ".";
import { Close } from "grommet-icons";
import PrimaryButton from "../elements/primary_button";


const TrustiBotButtonPopup = () => {
	const [showModal, setShowModal] = React.useState(false);
	const [dialogOpened, setDialogOpened] = React.useState(false);

	return (

		<Box >
			<PrimaryButton label={<Text weight={'bold'} >Ouvrir <b>TRUSTIBOT</b></Text>} onClick={() => {
				console.log('open trustibot');
				setShowModal(true);
			}} />
			{showModal && (
				<Layer pad="medium" onClickOutside={() => {
					if (!dialogOpened) setShowModal(false)
				}} onEsc={() => setShowModal(false)}>
					<Box fill pad='small' gap="small"  >
						<TrustiBot onOpenedDialog={(isOpen) => setDialogOpened(isOpen)}></TrustiBot>
					</Box>
				</Layer>
			)}
		</Box>

	);
};

export default TrustiBotButtonPopup;


import { Box } from 'grommet';
import React from 'react';

import service from '../../../services/emprunteur_horsligne_service';
import Refresh from '../../elements/refresh';
import Emprunteur from './emprunteur-horsligne/index';

import { getSorted } from '../../../services/utils';
import AddDevis from '../../elements/add_devis';
import DateSort from '../../elements/date_sort';
import SearchComponent from '../../elements/search';
import StatusFilter from '../../elements/status_filter';
import ViewTable from '../../elements/view_table';

class EmprunteurHorslignes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: this.props.contact || {},
            emprunteurs: [],
            popupOpen: false,
            selectedEmprunteur: {},
            filter: {
                _query: undefined,
                status: undefined,
                sort: undefined
            }
        }
    }

    componentDidMount = () => {
        this.loadEmprunteurHorslignes();
    };

    loadEmprunteurHorslignes = () => {
        this.setState({ emprunteurs: [] }, () => {
            service.getEmprunteurHorslignes(this.state.contact.id)
                .then(res => {
                    this.setState({ emprunteurs: res.data })
                })
                .catch(err => {
                    console.log('err', err);
                    this.props.showMessage('Erreur inattendu');
                })
        })
    }

    onClose = () => { this.setState({ popupOpen: false }) }

    openEmprunteur = (emprunteur) => {
        this.setState({ selectedEmprunteur: emprunteur, popupOpen: true });
    }

    addEmprunteur = () => this.openEmprunteur({ conducteur_principal: {} })

    search = (query, status) => {
        service.getEmprunteurHorslignes(this.state.contact.id, query, status)
            .then(res => {
                if (res.data?.results) {
                    this.setState({ emprunteurs: res.data?.results })
                } else if (res.data) {
                    this.setState({ emprunteurs: res.data })
                }
            })
            .catch(err => {
                console.log('err', err);
                this.props.showMessage('Erreur inattendu');
            })
    }

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter: Object.assign({}, filter) });
    }

    render() {
        return (
            this.state.popupOpen ?
                <Emprunteur
                    showMessage={this.props.showMessage}
                    devis={this.state.selectedEmprunteur}
                    onClose={this.onClose}
                    reload={this.loadEmprunteurHorslignes}
                />
                :
                <Box style={{ width: "100%" }}
                    align="center" justify="center"
                    alignSelf="center"
                    alignContent="center" gap="medium">
                    <Box width="full" gap="medium" pad={'small'} align='center' justify='center'>
                        <Box width="xlarge" gap="medium" align='center' justify='center'>

                            {!this.state.contact.id &&
                                <>
                                    <Box justify="between" direction='row' alignSelf="center" align="center" gap="medium">
                                        <Refresh refresh={this.loadEmprunteurHorslignes} />
                                        <SearchComponent
                                            search={value => {
                                                this.search(value, this.state.filter.status);
                                            }}
                                            query={this.state.filter._query}
                                            updateQuery={value => this.updateFilter('_query', value)}
                                        />
                                        <AddDevis onAdd={this.addEmprunteur} />
                                    </Box>
                                    <Box justify="between" direction='row' align="center" gap="medium" alignSelf="center">
                                        <StatusFilter
                                            status={this.state.filter.status}
                                            search={value => {
                                                this.updateFilter('status', value);
                                                this.search(this.state.filter._query, value);
                                            }}
                                        />
                                        <DateSort
                                            onChange={value => this.updateFilter('sort', value)}
                                        />

                                    </Box>
                                </>
                            }
                        </Box>
                        {this.state.contact.id && <Box style={{ height: 30 }} />}

                        <ViewTable
                            reports={getSorted(this.state.emprunteurs, this.state.filter.sort)}
                            commission_taux={this.props.commission_taux}
                            onDevisSelected={this.openEmprunteur}
                            commerciaux={this.props.commerciaux}
                        />
                    </Box>
                </Box>
        )
    }
}

export default EmprunteurHorslignes;
import React from "react";
import {
  Box,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  Button,
  Card,
} from "grommet";
import { toCurrency } from "../../services/utils";
import { Checkmark, Close, Trash, Add } from "grommet-icons";
import CustomInput from "../elements/custom_input";
import CustomSelect from "../elements/select_fill";
import service from "../../services/admin_service";

const validateNewItem = (item) => {
  if (
    isNaN(parseFloat(item.valeur_inferieure)) ||
    isNaN(parseFloat(item.valeur_superieure)) ||
    isNaN(parseFloat(item.pourcentage))
  ) {
    return "Veuillez remplir tous les valeurs numériques";
  }

  if (parseFloat(item.valeur_superieure) < parseFloat(item.valeur_inferieure)) {
    return "Hmmm, la valeur inférieure est supérieure que la valeur supérieure";
  }
};

class CustomizationCommission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCommercial: undefined,
      pourcentage_commission: [],
      creationMode: false,
      newItem: {},
      produit: props.produit,
    };
  }

  componentDidMount = () => {
    this.reloadData();
  };

  reloadData = () => {
    if (!this.state.selectedCommercial) return;
    service
      .getUserCommissionTaux(this.state.selectedCommercial.id, this.state.produit)
      .then((res) => {
        let commissions = res.data;
        commissions = commissions.sort((s1, s2) =>
          s1.valeur_inferieure < s2.valeur_inferieure ? -1 : 1
        );
        console.log(commissions);
        this.setState({ pourcentage_commission: commissions });
      });
  };

  updateNewItem = (field, value) => {
    let item = this.state.newItem;
    item[field] = value.replace(",", ".");
    this.setState({ newItem: { ...item } });
  };

  createNewItem = () => {
    let item = this.state.newItem;
    let message = validateNewItem(item);
    if (message) {
      this.props.showMessage(message, "error");
      return;
    }
    this.state.pourcentage_commission.push(item);
    const body = {
        commercial_id: this.state.selectedCommercial.id,
    };
    if(this.state.produit) {
        body.pourcentage_commission_credit = this.state.pourcentage_commission;
    } else {
        body.pourcentage_commission = this.state.pourcentage_commission;
    }
    
    service
      .updateUserCommissionTaux(body)
      .then((res) => {
        this.setState({ newItem: {} });
        this.reloadData();
        this.props.showMessage("Ajouté avec succès", "success");
      })
      .catch((err) => this.reloadData());
  };

  delete = (item) => {
    const newPourcentage = this.state.pourcentage_commission.filter(
        (s) => s !== item
      )
    let body = {
      commercial_id: this.state.selectedCommercial.id,
    };
    if(this.state.produit) {
        body.pourcentage_commission_credit = newPourcentage;
    } else {
        body.pourcentage_commission = newPourcentage;
    }
    service.updateUserCommissionTaux(body).then((res) => this.reloadData());
  };

  render() {
    return (
      <Box
        width={"xlarge"}
        margin={"medium"}
        justify="center"
        align="center"
        alignSelf="center"
        alignContent="center"
      >
        <Heading level={2} textAlign="center">
          Pourcentage de commission {this.props.produit ? ` ${this.props.produit}` : ''}
        </Heading>
        {this.props.commerciaux && (
          <Box gap="xsmall" style={{ marginBottom: 20 }}>
            <Text>Cherche un commercial</Text>
            <CustomSelect
              options={this.props.commerciaux}
              onChange={({ option }) => {
                this.setState({ selectedCommercial: option }, () =>
                  this.reloadData()
                );
              }}
              labelKey={"name"}
              valueKey={{ key: "name", reduce: true }}
            />
          </Box>
        )}
        {!this.state.creationMode && this.state.selectedCommercial && (
          <Box round="full" overflow="hidden" pad="medium">
            <Button
              primary
              icon={<Add />}
              onClick={() => this.setState({ creationMode: true })}
            />
          </Box>
        )}
        {this.state.creationMode && (
          <Box
            width={"xlarge"}
            margin={"medium"}
            justify="center"
            align="center"
            alignSelf="center"
            alignContent="center"
          >
            <Card pad="small" gap="xsmall">
              <Box
                pad={"xsmall"}
                gap="xsmall"
                justify="between"
                direction="row"
              >
                <FieldItem
                  label={"À partir de (€)"}
                  value={this.state.newItem.valeur_inferieure}
                  onChange={(e) =>
                    this.updateNewItem("valeur_inferieure", e.target.value)
                  }
                />
                <FieldItem
                  label={"À inférieure de (€)"}
                  value={this.state.newItem.valeur_superieure}
                  onChange={(e) =>
                    this.updateNewItem("valeur_superieure", e.target.value)
                  }
                />
                <FieldItem
                  label={"Pourcentage (%)"}
                  value={this.state.newItem.pourcentage}
                  onChange={(e) =>
                    this.updateNewItem("pourcentage", e.target.value)
                  }
                />
              </Box>
              <Box direction="row" justify="center" alignContent="center">
                <Box round="full" overflow="hidden" pad="medium">
                  <Button
                    primary
                    icon={<Checkmark />}
                    onClick={() => this.createNewItem()}
                  />
                </Box>
                <Box round="full" overflow="hidden" pad="medium">
                  <Button
                    icon={<Close />}
                    onClick={() =>
                      this.setState({ newItem: {}, creationMode: false })
                    }
                  />
                </Box>
              </Box>
            </Card>
          </Box>
        )}

        {this.state.selectedCommercial && (
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Text>À partir de</Text>
                </TableCell>
                <TableCell>
                  <Text>À inférieure de</Text>
                </TableCell>
                <TableCell>
                  <Text>Pourcentage commission</Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {this.state.pourcentage_commission.map((interval, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Text>{toCurrency(interval.valeur_inferieure)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{toCurrency(interval.valeur_superieure)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{interval.pourcentage} %</Text>
                  </TableCell>
                  <TableCell>
                    <Button
                      primary
                      icon={<Trash />}
                      onClick={() => this.delete(interval)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    );
  }
}

const FieldItem = ({ label, value, onChange }) => (
  <Box pad={"small"} gap="xsmall">
    <Text>{label}</Text>
    <CustomInput value={value} onChange={onChange} />
  </Box>
);

export default CustomizationCommission;

import React from "react";
import { Box, Heading, Text, Card, Stack } from "grommet";
import { getSimpleDateFR, toCurrency } from "../../../../services/utils";
import { BIEN_TYPE, LEVEL } from "../../../../services/types";
import RecapStatus from "../../../elements/devis_status";
import { DocumentDownload } from "grommet-icons";
import StatusDateAppel from "../../../elements/status_date_appel";
import PanelAppel from "../../../elements/panel_appel";
import EmailEventTracker from "../../../elements/email_tracker";
import StripeCustomer from "../../../elements/stripe_customer";
import CodePromo from "../../../elements/code_promo";
import ApporteurGestionnaire from "../../../elements/apporteur_gestionaire";
import TrustiBot from "../../../trustibot";
import DateRecap from "../../../elements/date_recap";

const FullWidthBox = ({ children, direction, gap }) => (
  <Box
    direction={direction}
    gap={gap}
    style={{ width: "100%" }}
    alignSelf="center"
  >
    {children}
  </Box>
);

const Recap = ({ devis, submit, toEditMode, close, sendForm, onSave, updateDevis }) => {
  console.log("devis = ", devis);
  return (
    <Box
      direction="row"
      gap='small'
      style={{ width: "100vw" }}
    >
      <TrustiBot></TrustiBot>
      <Box
        gap="medium"
        // style={{ width: "100%" }}
        align="center"
        alignSelf="center"
        pad="small"
      >
        <Heading level="2" color="#59A4F4" textAlign="center">
          Trusti PNO
        </Heading>
        <Box direction="row" fill="horizontal" justify="center" alignContent="center">
          <Box align="center">
            <StatusDateAppel devis={devis} />
          </Box>
          <Box>
            <Box alignSelf="end">
              <RecapStatus status={devis.status} />
              <PanelAppel devis={devis} updateDevis={updateDevis} onSave={onSave} />
            </Box>
          </Box>
        </Box>
        <Box  gap="small" width={'full'} justify='center' align="center">
          <Card  pad="small" gap="small" width={'full'} >
            <Text textAlign="center"><b>ID:</b> {devis.id}</Text> 
          </Card>
        </Box>
        <Box  gap="small" width={'full'}>
          <DateRecap deal={devis} />
        </Box>
        <CodePromo devis={devis} />
        <Box width={'xlarge'}>
          <StripeCustomer devisId={devis.id} />
        </Box>
        <Box width="xlarge" direction="row" gap="small">
          <FullWidthBox gap="small">
            <Card style={{ width: "100%" }} pad="small">
              <Assure assure={devis.client || {}} />
            </Card>
            <Card style={{ width: "100%" }} pad="small">
              <AssuranceLocation devis={devis} />
            </Card>
          </FullWidthBox>

          <Card style={{ width: "100%" }} pad="small">
            <LocationDetails devis={devis} />
          </Card>
        </Box>
        <Box width="xlarge" direction="row" gap="small">
          <Card style={{ width: "100%" }} pad="medium">
            {devis.file_tableau_amortissement && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_tableau_amortissement}`}
              >
                <DocumentDownload />
                Tableau d’amortissement
              </a>
            )}
            {devis.file_offre_de_pret && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_offre_de_pret}`}
              >
                <DocumentDownload />
                Offre de prêt
              </a>
            )}
            {devis.file_devis && (
              <a target={"_blank"} rel="noreferrer" href={`${devis.file_devis}`}>
                <DocumentDownload />
                Devis
              </a>
            )}
            {devis.file_rib && (
              <a target={"_blank"} rel="noreferrer" href={`${devis.file_rib}`}>
                <DocumentDownload />
                RIB
              </a>
            )}
            {devis.file_contrat && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_contrat}`}
              >
                <DocumentDownload />
                Contrat
              </a>
            )}
            {devis.file_contrat_signed && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${devis.file_contrat_signed}`}
              >
                <DocumentDownload />
                Contrat signé
              </a>
            )}
            {devis.file_others ? (
              devis.file_others.map((e, _idx) => {
                return (
                  <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                    <DocumentDownload />
                    {e && e.split("/").pop()}
                  </a>
                );
              })
            ) : (
              <></>
            )}
          </Card>
        </Box>
        {(devis.apporteur || devis.affiliation) && <Card pad={"small"} width={'xlarge'} >
          <ApporteurGestionnaire apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
        </Card>}
        <Box width={'xlarge'}>
          <EmailEventTracker messageIds={devis.emailEventIds} />
        </Box>
      </Box>
    </Box>
  );
};

const getFoyerDetail = (devis, field, val_field) => {
  return devis[field] ? `Oui, il y en ${devis[val_field]}` : "Non";
};

const getValueDetail = (devis, field, val_field) => {
  return devis[field]
    ? `Oui, avec un capital de ${toCurrency(devis[val_field], true)}`
    : "Non";
};

const getSurfaceDetail = (devis, field, val_field) => {
  return devis[field] ? `Oui, de ${devis[val_field]} m2` : "Non";
};

const LocationDetails = ({ devis }) => {
  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label="Informations de l'assurance" />
      <Line
        leftLabel="La cliente effectue-t-elle de la location saisonnière"
        rightLabel={devis.location_saisonniere ? "Oui" : "Non"}
      />
      <Line
        leftLabel="Y a-t-il un foyer ouvert"
        rightLabel={getFoyerDetail(
          devis,
          "opened_foyer",
          "opened_foyer_number"
        )}
      />
      <Line
        leftLabel="Y a-t-il un foyer fermé"
        rightLabel={getFoyerDetail(
          devis,
          "closed_foyer",
          "closed_foyer_number"
        )}
      />
      <Line
        leftLabel="Y a-t-il une piscine"
        rightLabel={getValueDetail(devis, "piscine", "piscine_capital")}
      />
      <Line
        leftLabel="Y a-t-il des installations extérieures ou terrasse"
        rightLabel={getValueDetail(
          devis,
          "external_installation",
          "external_installation_capital"
        )}
      />
      <Line
        leftLabel="Y a-t-il des installations énergie développement durable"
        rightLabel={getValueDetail(
          devis,
          "energy_installation",
          "energy_installation_capital"
        )}
      />
      <Line
        leftLabel="Y a-t-il une véranda"
        rightLabel={getSurfaceDetail(devis, "veranda", "veranda_surface")}
      />
      <Line
        leftLabel="Y a-t-il une cave ou un box ou un garage"
        rightLabel={getSurfaceDetail(devis, "box", "box_surface")}
      />
      {(devis.house_level === LEVEL.one_level_wth_ss ||
        devis.house_level === LEVEL.sev_level_wth_ss) && (
          <Line
            leftLabel="Y-a-t-il sous-sol non aménagé"
            rightLabel={getSurfaceDetail(
              devis,
              "sous_sol_non_amenage",
              "sous_sol_non_amenage_surface"
            )}
          />
        )}
      {devis.bien_type === BIEN_TYPE.appartement && (
        <Line
          leftLabel="Surface dépendance(s) mitoyenne(s)"
          rightLabel={getSurfaceDetail(
            devis,
            "dependence_mitoyenne",
            "dependence_mitoyenne_surface"
          )}
        />
      )}
      {devis.bien_type === BIEN_TYPE.appartement && (
        <Line
          leftLabel="Année de construction"
          rightLabel={devis.construction_year}
        />
      )}
      <Line
        leftLabel="Quel type de remplacement du mobilier souhaites le client"
        rightLabel={devis.remplacement_type}
      />
    </Box>
  );
};

const CustomText = ({ label, textAlign, isBold }) => (
  <Text
    style={{ fontWeight: isBold ? "bold" : "normal" }}
    size="small"
    textAlign={textAlign || "end"}
  >
    {label}
  </Text>
);
const Line = ({ leftLabel, rightLabel }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "50%" }}>
      <CustomText label={leftLabel + " :"} />
    </Box>
    <Box style={{ width: "50%" }}>
      <CustomText label={rightLabel} textAlign="start" isBold={true} />
    </Box>
  </Box>
);
const Assure = ({ assure }) => {
  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label="Informations de l'assuré" />
      <Line leftLabel="Civilité" rightLabel={assure.civilite} />
      {assure.civilite !== "Société" && (
        <Line leftLabel="Prénom" rightLabel={assure.firstname} />
      )}
      {assure.civilite !== "Société" && (
        <Line leftLabel="Nom" rightLabel={assure.lastname} />
      )}
      {assure.civilite === "Société" && (
        <Line leftLabel="Raison social" rightLabel={assure.raisonSocial} />
      )}
      <Line leftLabel="Mail" rightLabel={assure.email} />
      <Line leftLabel="Tel" rightLabel={assure.phone} />
      <Line
        leftLabel="Adresse postale"
        rightLabel={`${assure.address}, ${assure.zip}, ${assure.city}`}
      />
    </Box>
  );
};

const getLocationAddress = (devis) => {
  return `${devis.location_address}, ${devis.location_zip}, ${devis.location_city}`;
};
const AssuranceLocation = ({ devis }) => (
  <Box gap="small" style={{ width: "100%" }}>
    <SubHeading label="Informations de location" />
    <Line
      leftLabel="Adresse de la location"
      rightLabel={getLocationAddress(devis)}
    />
    <Line leftLabel="Type de logement" rightLabel={devis.bien_type} />
    {devis.bien_type === BIEN_TYPE.appartement && (
      <Line leftLabel="Etage" rightLabel={devis.appt_floor} />
    )}
    {devis.bien_type !== BIEN_TYPE.appartement && (
      <Line leftLabel="Niveau" rightLabel={devis.house_level} />
    )}
    <Line leftLabel="Nombre de pièces" rightLabel={devis.total_rooms} />
    <Line leftLabel="Surface en m2" rightLabel={devis.surface} />
    {devis.location_meublee && (
      <Line
        leftLabel="Capital mobilier"
        rightLabel={`${toCurrency(devis.capital, true)} dont ${toCurrency(
          devis.value_object,
          true
        )} d'objets de valeur`}
      />
    )}
    <Line leftLabel="Type de location" rightLabel={devis.location_type} />
    <Line
      leftLabel="Type de construction"
      rightLabel={devis.construction_type}
    />
    <Line leftLabel="Type de couverture" rightLabel={devis.cover_type} />
    <Line
      leftLabel="Le client a-t-il déjà eu des sinistres"
      rightLabel={devis.sinistre ? "Oui" : "Non"}
    />
    <Line
      leftLabel="Date début d’effet demandé"
      rightLabel={getSimpleDateFR(devis.begin_date)}
    />
    <Line
      leftLabel="Type de fractionnement"
      rightLabel={devis.fractionnement_type}
    />
  </Box>
);

const SubHeading = ({ label }) => (
  <Text textAlign="center" style={{ fontWeight: "bold" }}>
    {label}
  </Text>
);

export default Recap;

import { Box, Card, FileInput, Heading, Text, Button, Grid, TextInput } from "grommet";
import React, { useState } from "react";
import PrimaryButton from "../../../elements/primary_button";
import { Checkmark, DocumentDownload, Trash } from "grommet-icons";
import ContactSelect, { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import Line from "../../../elements/line_container";
import CustomSelect from "../../../elements/select_fill";
import DateInput from "../../../elements/date_input";
import CustomInput from "../../../elements/custom_input";
import ToggleButton, { ToggleButtonV2 } from "../../../elements/toggle_button/toggle_button";
import { EMPRUNTEUR_TYPE } from "../../../../services/types";
import Assures from "../assure";
import { uploadFile } from "../../../../services/storage_service";
import {
  MOBILITY,
  PROFESSIONAL_STATUS,
  RISK_PROFESSIONS,
  RISK_SPORTS,
} from "../../../../types/emprunteur_type";
import { DashboardRecapTitle, InfoClientCard } from "../../../elements/new_design_elements";
import ChoiceSelect from "../../trusti-per/trusti_per/ChoiceSelect";
import moment from "moment";

const isRiskProfession = (value) => {
  return value !== null && value !== RISK_PROFESSIONS[0].description;
};

const doUploadFile = (files, callback, showError) => {
  const fileList = files;
  if (fileList) {
    for (let i = 0; i < fileList.length; i += 1) {
      const file = fileList[i];
      console.log(file.name);
      uploadFile(file)
        .then((result) => {
          console.log("result = ", result.data);
          callback(result.data);
        })
        .catch((err) => {
          showError("Erreur inattendu télécharger.");
          callback([]);
        });
    }
  }
};

const CIVILITY = {
  madame: "Madame",
  monsieur: "Monsieur",
  societe: "Société",
};

const inputStyle = { background: '#EDEDED', fontSize: 14, fontWeight: 400, borderRadius: 10, border: 'none' };

const EmprunteurInfo = ({
  devis,
  updateDevis,
  onNext,
  contact,
  showMessage,
}) => {
  const [assures, setAssures] = useState(devis?.emprunteurs || [{}]);
  const [isLoading, setIsLoading] = useState(false);

  const _numberCoEmprunteur = (_num) => {
    var _ems = devis.emprunteurs;
    if (!_ems) {
      devis.emprunteurs = [];
      _ems = [];
    }
    console.log("_ems = ", _ems);
    const _assures = [];

    if (_ems) {
      for (let i = 0; i < _ems.length; i++) {
        const element = _ems[i];
        console.log(i, " - el num = ", _num);
        if (i < _num) {
          console.log(i, " - el = ", element);
          _assures.push(element);
        }
      }
      if (_num > _ems.length) {
        for (let j = _ems.length; j < _num; j++) {
          _assures.push({ civility: "Monsieur" });
        }
      }
    }

    setAssures(_assures);
    updateDevis("emprunteurs", _assures);
    console.log("emprunteurs = ", _assures);
  };
  const isFilled = value => value !== undefined && value !== null && value !== "";
  const validateDevis = () => {
    let client = devis.client;
    if (!isFilled(client)) return 'Votre information';
    if (!isFilled(client.civilite)) return 'Civilité';
    if (!isFilled(client.firstname) && client.civilite !== CIVILITY.societe) return 'Prénom'
    if (!isFilled(client.lastname) && client.civilite !== CIVILITY.societe) return 'Nom'
    // if (!isFilled(client.raisonSocial) && client.civilite === CIVILITY.societe) return 'Raison social';
    if (!isFilled(client.address)) return 'Adresse';
    if (!isFilled(client.city)) return 'Ville';
    if (!isFilled(client.zip)) return 'Code postal';
    if (!isFilled(client.email)) return 'Email';
    if (!isFilled(client.phone)) return 'Téléphone';
    if (!isFilled(client.date_of_birth)) return 'Date de naissance';

    if (!isFilled(devis.birth_name)) return 'Nom de naissance';
    if (!isFilled(devis.nationality)) return 'Nationalité';
    if (!isFilled(devis.birth_country)) return 'Pays de naissance';
    if (!isFilled(devis.birth_city)) return 'Ville de naissance';
    if (!isFilled(devis.risk_sports)) return 'Sport à risque';
    if (!isFilled(devis.zip_fiscal)) return 'Code postal résidence principale';
    if (!isFilled(devis.professional_status)) return 'Statut professionnel';
    if (!isFilled(devis.risk_profession)) return 'Profession à risque';
    if (!isFilled(devis.fumeur)) return 'Fumeur';

    if (!isFilled(devis.objet_financement)) return 'Objet du financement';
    if (!isFilled(devis.type_projet)) return 'Type de projet';
    if (!isFilled(devis.montant)) return 'Montant du crédit';
    if (!isFilled(devis.date_du_pret)) return 'Date du prêt';
    if (!isFilled(devis.duree)) return 'Durée du crédit';
    if (!isFilled(devis.taux)) return 'Taux du crédit';
  }

  const updateAssure = (field, value, index) => {
    let _assures = assures;
    _assures[index][field] = value;
    setAssures(_assures);
    updateDevis("emprunteurs", _assures);
    console.log("emprunteurs = ", _assures);
  };

  // console.log("devis = ", devis);
  return (
    <Box
      gap="small"
      width="xlarge"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Box justify="center" alignSelf="center">
        <Heading level={3} textAlign="center">Trusti EMP</Heading>
      </Box>

      <InfoClientCard devis={devis} updateDevis={updateDevis} />
      <DashboardRecapTitle title="Projet du client" />
      <Box width="100%" pad="small">
        <ChoiceSelect
          columns={["1/2", "1/2"]}
          choices={["Je souhaite changer d'assurance", "Je souhaite assurer un nouveau prêt"]}
          onChange={(choice) => {
            updateDevis("type_projet", choice)
          }}
          default={devis.type_projet}
        />
      </Box>
      <DashboardRecapTitle title="Informations personnelles complémentaires du co-emprunteur n°1" />
      <Grid columns={["1/2", "1/2"]} gap="small" width="full" pad="small">
        <Box>
          <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner le nom de naissance</Text>
          <TextInput
            style={inputStyle}
            defaultValue={devis.birth_name}
            reverse
            onChange={(event) =>
              updateDevis("birth_name", event.target.value)
            }
          />
        </Box>
        <Box>
          <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner la nationalité</Text>
          <TextInput
            style={inputStyle}
            defaultValue={devis.nationality}
            reverse
            onChange={(event) =>
              updateDevis("nationality", event.target.value)
            }
          />
        </Box>
        <Box>
          <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner le pays de naissance</Text>
          <TextInput
            style={inputStyle}
            defaultValue={devis.birth_country}
            reverse
            onChange={(event) =>
              updateDevis("birth_country", event.target.value)
            }
          />
        </Box>
        <Box>
          <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner la ville de naissance</Text>
          <TextInput
            style={inputStyle}
            defaultValue={devis.birth_city}
            reverse
            onChange={(event) =>
              updateDevis("birth_city", event.target.value)
            }
          />
        </Box>
        <Box>
          <Text weight={400} size={"14px"} margin="10px 0 10px 0">Exerce t'il un sport à risque ?</Text>
          <CustomSelect
            multiple={true}
            style={{ width: '100%' }}
            options={RISK_SPORTS.map(s => s.description)}
            value={(devis.risk_sports || null)}
            onChange={(event) => updateDevis("risk_sports", event.value)}
          />
        </Box>
        <Box>
          <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner le code postal de residence principale</Text>
          <TextInput
            style={inputStyle}
            defaultValue={devis.zip_fiscal}
            reverse
            onChange={(event) =>
              updateDevis("zip_fiscal", event.target.value)
            }
          />
        </Box>
        <Box>
          <Text weight={400} size={"14px"} margin="10px 0 10px 0">Quel est votre statut professionnel ? </Text>
          <CustomSelect
            style={{ width: '100%' }}
            options={PROFESSIONAL_STATUS.map(s => s.description)}
            value={devis.professional_status || null}
            onChange={(event) => updateDevis("professional_status", event.value)}
          />
        </Box>
        <Box>
          <Text weight={400} size={"14px"} margin="10px 0 10px 0">Exercez-vous une profession à risque ?</Text>
          <CustomSelect
            style={{ width: '100%' }}
            options={RISK_PROFESSIONS.map(s => s.description)}
            value={devis.risk_profession || null}
            onChange={(event) => updateDevis("risk_profession", event.value)}
          />
        </Box>
        <Box>
          <Text weight={400} size={"14px"} margin="10px 0 10px 0">Êtes vous fumeur ?</Text>
          <ChoiceSelect
            choices={["Oui", "Non"]}
            default={devis.fumeur !== undefined ? (devis.fumeur ? "Oui" : "Non") : null}
            freeGrid
            onChange={(choice) => {
              updateDevis("fumeur", choice === "Oui")
            }}
          />
        </Box>
      </Grid>

      <DashboardRecapTitle title="Informations vente" />
      <Box width="100%" pad="small">
        <ChoiceSelect
          title="Avez-vous emprunté a plusieurs ?"
          choices={["Oui", "Non"]}
          freeGrid
          onChange={(choice) => {
            if (choice === "Oui") {
              _numberCoEmprunteur(1);
              updateDevis(
                "emprunteur_type",
                EMPRUNTEUR_TYPE.co_emprunteur
              );
            } else {
              _numberCoEmprunteur(0);
              updateDevis("emprunteur_type", EMPRUNTEUR_TYPE.seul);
            }
            updateDevis("emprunteur_type", choice === "Oui" ? EMPRUNTEUR_TYPE.co_emprunteur : "Seul")
          }}
          default={devis.emprunteur_type === EMPRUNTEUR_TYPE.co_emprunteur ? "Oui" : "Non"}
        />

        {devis.emprunteur_type === EMPRUNTEUR_TYPE.co_emprunteur && <ChoiceSelect
          title="Combien y a-t-il de co-emprunteur ?"
          choices={["2", "3", "4"]}
          freeGrid
          onChange={(choice) => {
            _numberCoEmprunteur(Number(choice) - 1);
          }}
          default={devis.emprunteurs ? (devis.emprunteurs.length + 1).toString() : "1"}
        />}

      </Box>

      {assures.map((assure, index) => (
        <>
          <DashboardRecapTitle title={`Informations personnelles complémentaires du co-emprunteur n°${index + 2}`} />
          <Box width="50%" alignSelf="start" gap="small" pad="small">
            <Text margin="15px 0">Informations personnelles</Text>
            <Grid columns={["30%", "70%"]}>
              <Text margin="15px 0">Civilité</Text>
              <ToggleButton
                fontSize="14px"
                groups={[
                  {
                    label: CIVILITY.madame,
                    selected: assures[index].civilite === CIVILITY.madame,
                    onClick: () =>
                      updateAssure("civilite", CIVILITY.madame, index),
                  },
                  {
                    label: CIVILITY.monsieur,
                    selected: assures[index].civilite === CIVILITY.monsieur,
                    onClick: () =>
                      updateAssure("civilite", CIVILITY.monsieur, index),
                  },
                ]}
              />
            </Grid>
            <Grid columns={["30%", "70%"]}>
              <Text margin="15px 0">Nom</Text>
              <CustomInput
                value={assures[index].lastname}
                onChange={(ev) => updateAssure("lastname", ev.target.value, index)}
              />
            </Grid>
            <Grid columns={["30%", "70%"]}>
              <Text margin="15px 0">Prénom</Text>
              <CustomInput
                value={assures[index].firstname}
                onChange={(ev) => updateAssure("firstname", ev.target.value, index)}
              />
            </Grid>
            <Grid columns={["30%", "70%"]}>
              <Text margin="15px 0">Date de naissance</Text>
              <CustomInput
                type="date"
                value={assures[index].date_of_birth}
                onChange={(ev) => updateAssure("date_of_birth", ev.target.value, index)}
              />
            </Grid>
            <Grid columns={["30%", "70%"]}>
              <Text margin="15px 0">Téléphone</Text>
              <CustomInput
                value={assures[index].phone}
                onChange={(ev) => updateAssure("phone", ev.target.value, index)}
              />
            </Grid>
            <Grid columns={["30%", "70%"]}>
              <Text margin="15px 0">Email</Text>
              <CustomInput
                value={assures[index].email}
                onChange={(ev) => updateAssure("email", ev.target.value, index)}
              />
            </Grid>
            <Text margin="15px 0">Informations personnelles complémentaires</Text>
          </Box>
          <Grid columns={["1/2", "1/2"]} gap="small" width="full" pad="small">
            <Box>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner le nom de naissance</Text>
              <TextInput
                style={inputStyle}
                defaultValue={assures[index].birth_name}
                reverse
                onChange={(event) =>
                  updateAssure("birth_name", event.target.value, index)
                }
              />
            </Box>
            <Box>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner la nationalité</Text>
              <TextInput
                style={inputStyle}
                defaultValue={assures[index].nationality}
                reverse
                onChange={(event) =>
                  updateAssure("nationality", event.target.value, index)
                }
              />
            </Box>
            <Box>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner le pays de naissance</Text>
              <TextInput
                style={inputStyle}
                defaultValue={assures[index].birth_country}
                reverse
                onChange={(event) =>
                  updateAssure("birth_country", event.target.value, index)
                }
              />
            </Box>
            <Box>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner la ville de naissance</Text>
              <TextInput
                style={inputStyle}
                defaultValue={assures[index].birth_city}
                reverse
                onChange={(event) =>
                  updateAssure("birth_city", event.target.value, index)
                }
              />
            </Box>
            <Box>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">Exerce t'il un sport à risque ?</Text>
              <CustomSelect
                multiple={true}
                style={{ width: '100%' }}
                options={RISK_SPORTS.map(s => s.description)}
                value={(assures[index].risk_sports || null)}
                onChange={(event) => updateAssure("risk_sports", event.value, index)}
              />
            </Box>
            <Box>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">Renseigner le code postal de residence principale</Text>
              <TextInput
                style={inputStyle}
                defaultValue={assures[index].zip_fiscal}
                reverse
                onChange={(event) =>
                  updateAssure("zip_fiscal", event.target.value, index)
                }
              />
            </Box>
            <Box>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">Quel est votre statut professionnel ? </Text>
              <CustomSelect
                style={{ width: '100%' }}
                options={PROFESSIONAL_STATUS.map(s => s.description)}
                value={assures[index].professional_status || null}
                onChange={(event) => updateAssure("professional_status", event.value, index)}
              />
            </Box>
            <Box>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">Exercez-vous une profession à risque ?</Text>
              <CustomSelect
                style={{ width: '100%' }}
                options={RISK_PROFESSIONS.map(s => s.description)}
                value={assures[index].risk_profession || null}
                onChange={(event) => updateAssure("risk_profession", event.value, index)}
              />
            </Box>
            <Box>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">Le co-emprunteur est il un fumeur ?</Text>
              <ChoiceSelect
                choices={["Oui", "Non"]}
                default={assures[index].fumeur ? (assures[index].fumeur ? "Oui" : "Non") : null}
                freeGrid
                onChange={(choice) => {
                  updateAssure("fumeur", choice === "Oui", index)
                }}
              />
            </Box>
          </Grid>
        </>
      ))}
      <DashboardRecapTitle title="Informations assurance emprunteur" />

      <Box width="full">
        <ChoiceSelect
          title="Quel est le crédit à assurer ?"
          choices={["Crédit immobilier", "Crédit immobilier locatif", "Crédit pro", "Regroupement de crédit"]}
          onChange={(choice) => {
            updateDevis("objet_financement", choice)
          }}
          default={devis.objet_financement}
        />
        <ChoiceSelect
          title="Le total de tous vos crédits restant à date est-il supérieur à 200 000 € ?"
          choices={["Oui", "Non"]}
          onChange={(choice) => {
            updateDevis("credit_gt_200k", choice === "Oui");
          }}
          default={devis.credit_gt_200k ? (devis.credit_gt_200k ? "Oui" : "Non") : null}
        />
        <Grid columns={["1/2", "1/2"]} gap="small" width="full" pad="small">
          <Box>
            <Text weight={400} size={"14px"} margin="10px 0 10px 0">Indiquer le montant total du prêt (Interêt compris)</Text>
            <TextInput
              style={inputStyle}
              type="number"
              icon={<span>€</span>}
              defaultValue={devis.montant}
              reverse
              onChange={(event) =>
                updateDevis("montant", event.target.value)
              }
            />
          </Box>
          <Box>
            <Text weight={400} size={"14px"} margin="10px 0 10px 0">Date de début du prêt</Text>
            <TextInput
              style={inputStyle}
              type="date"
              defaultValue={devis.date_du_pret?.split("T")[0]}
              onChange={(event) =>
                updateDevis("date_du_pret", event.target.value)
              }
            />
          </Box>
          <Box>
            <Text weight={400} size={"14px"} margin="10px 0 10px 0">Indiquer le taux d’intérêt annuel</Text>
            <TextInput
              style={inputStyle}
              type="number"
              reverse
              icon={<span>%</span>}
              defaultValue={devis.taux}
              onChange={(event) =>
                updateDevis("taux", event.target.value)
              }
            />
          </Box>
          <Box>
            <Text weight={400} size={"14px"} margin="10px 0 10px 0">Indiquer la durée du prêt</Text>
            <TextInput
              style={inputStyle}
              type="number"
              reverse
              icon={<span>An(s)</span>}
              defaultValue={devis.duree}
              onChange={(event) =>
                updateDevis("duree", event.target.value)
              }
            />
          </Box>
          {devis.type_projet === "Je souhaite changer d'assurance" && <Box>
            <Text weight={400} size={"14px"} margin="10px 0 10px 0">Connaissez-vous le montant de votre assurance ?</Text>
            <ChoiceSelect
              columns={["1/2", "1/2"]}
              choices={["Oui", "Non"]}
              onChange={(choice) => {
                updateDevis("montant_assurance_connu", choice === "Oui");
              }}
              default={devis.montant_assurance_connu !== undefined ? (devis.montant_assurance_connu ? "Oui" : "Non") : null}
            />
          </Box> }
          {devis.type_projet === "Je souhaite changer d'assurance" && <Box>
            <Text weight={400} size={"14px"} margin="10px 0 10px 0">Date de résiliation ?</Text>
            <TextInput
              style={inputStyle}
              type="date"
              defaultValue={devis.date_resiliation ? moment(devis.date_resiliation).toISOString().split("T")[0] : undefined}
              onChange={(event) =>
                updateDevis("date_resiliation", event.target.value)
              }
            />
          </Box>}
          {devis.type_projet === "Je souhaite changer d'assurance" && devis.montant_assurance_connu && (
            <Box>
              <Text weight={400} size={"14px"} margin="10px 0 10px 0">Indiquer le montant total de l’assurance</Text>
              <TextInput
                style={inputStyle}
                type="number"
                reverse
                icon={<span>€</span>}
                defaultValue={devis.montant_total_assurance}
                onChange={(event) =>
                  updateDevis("montant_total_assurance", event.target.value)
                }
              />
            </Box>
          )} 
        </Grid>
      </Box>

      <DashboardRecapTitle title="Documents" />

      <Box direction="column" pad="small" gap="small" width="full">
        <Box direction="row" pad="small" gap="large">
          {devis.file_offre_de_pret && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_offre_de_pret}`}
            >
              <DocumentDownload />
              Offre de prêt
            </a>
          )}
          <FileInput
            messages={{
              browse: "Choisir",
              dropPrompt: "Offre de prêt",
            }}
            multiple={false}
            onChange={(event) => {
              setIsLoading(true);
              doUploadFile(
                event.target.files,
                async (data) => {
                  if (data.length > 0) {
                    let _uploaded = data[0];
                    updateDevis("file_offre_de_pret", _uploaded.url);
                  }
                  setIsLoading(false);
                },
                showMessage
              );
            }}
          />
        </Box>
        <Box direction="row" pad="small" gap="large">
          {devis.file_tableau_amortissement && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_tableau_amortissement}`}
            >
              <DocumentDownload />
              Tableau d’amortissement
            </a>
          )}
          <FileInput
            messages={{
              browse: "Choisir",
              dropPrompt: "Tableau d’amortissement",
            }}
            multiple={false}
            onChange={(event) => {
              setIsLoading(true);
              doUploadFile(
                event.target.files,
                (data) => {
                  if (data.length > 0) {
                    let _uploaded = data[0];
                    updateDevis(
                      "file_tableau_amortissement",
                      _uploaded.url
                    );
                  }
                  setIsLoading(false);
                },
                showMessage
              );
            }}
          />
        </Box>
        {devis.type_projet === "Je souhaite changer d'assurance" &&  <Box direction="row" pad="small" gap="large">
          {devis.file_attestation_assurance && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${devis.file_attestation_assurance}`}
            >
              <DocumentDownload />
              Attestation d’assurance emprunteur
            </a>
          )}
          <FileInput
            messages={{
              browse: "Choisir",
              dropPrompt: "Attestation d’assurance emprunteur (Facultatif)",
            }}
            multiple={false}
            onChange={(event) => {
              setIsLoading(true);
              doUploadFile(
                event.target.files,
                (data) => {
                  if (data.length > 0) {
                    let _uploaded = data[0];
                    updateDevis(
                      "file_attestation_assurance",
                      _uploaded.url
                    );
                  }
                  setIsLoading(false);
                },
                showMessage
              );
            }}
          />
        </Box> }
        {devis.file_others ? (
          devis.file_others.map((e, _idx) => {
            return (
              <Box
                key={`file_${_idx}`}
                direction="row"
                pad="small"
                gap="small"
                justify="between"
              >
                <Box direction="row" gap="small" overflow="hidden">
                  {e && (
                    <Button
                      onClick={() => {
                        console.log("remove index = ", _idx);
                        let _files = devis.file_others;
                        _files.splice(_idx, 1);
                        updateDevis("file_others", _files);
                      }}
                    >
                      <Trash color="red" />
                    </Button>
                  )}
                  {e && (
                    <a target={"_blank"} rel="noreferrer" href={`${e}`}>
                      <DocumentDownload />
                      {e.split("/").pop()}
                    </a>
                  )}
                </Box>
                <FileInput
                  messages={{
                    browse: "Choisir",
                    dropPrompt: "Ajouter un autre document",
                  }}
                  multiple={false}
                  onChange={(event) => {
                    setIsLoading(true);
                    doUploadFile(
                      event.target.files,
                      async (data) => {
                        if (data.length > 0) {
                          let _uploaded = data[0];
                          let _files = devis.file_others || [];
                          _files[_idx] = _uploaded.url;
                          updateDevis("file_others", _files);
                        }
                        setIsLoading(false);
                      },
                      showMessage
                    );
                  }}
                />
              </Box>
            );
          })
        ) : (
          <></>
        )}
        <FileInput
          messages={{
            browse: "Choisir",
            dropPrompt: "Ajouter un autre document",
          }}
          multiple={false}
          onChange={(event) => {
            setIsLoading(true);
            doUploadFile(
              event.target.files,
              async (data) => {
                if (data.length > 0) {
                  let _uploaded = data[0];
                  let _files = devis.file_others || [];
                  _files.push(_uploaded.url);
                  updateDevis("file_others", _files);
                }
                setIsLoading(false);
              },
              showMessage
            );
          }}
        />
      </Box>

      {/* <Box width="large" gap="xsmall">
        <Text
          style={{ fontSize: 20, padding: 20, fontWeight: "bold" }}
          textAlign="center"
        >
          Informations personnelles complémentaires
        </Text>
        <Line
          leftLabel="Nom de naissance"
          rightChildren={
            <CustomInput
              value={devis.birth_name}
              onChange={(ev) => updateDevis("birth_name", ev.target.value)}
            />
          }
        />
        <Line
          leftLabel="Nationalité"
          rightChildren={
            <CustomInput
              value={devis.nationality}
              onChange={(ev) => updateDevis("nationality", ev.target.value)}
            />
          }
        />
        <Line
          leftLabel="Pays de naissance"
          rightChildren={
            <CustomInput
              value={devis.birth_country}
              onChange={(ev) => updateDevis("birth_country", ev.target.value)}
            />
          }
        />
        <Line
          leftLabel="Ville de Naissance"
          rightChildren={
            <CustomInput
              value={devis.birth_city}
              onChange={(ev) => updateDevis("birth_city", ev.target.value)}
            />
          }
        />
        <Line
          leftLabel="SPORTS À RISQUE*"
          rightChildren={
            <CustomSelect
              multiple={true}
              style={{ width: "100%" }}
              options={RISK_SPORTS.map((s) => s.description)}
              value={devis.risk_sports ? devis.risk_sports : []}
              onChange={({ value: nextValue }) =>
                updateDevis("risk_sports", nextValue)
              }
            />
          }
        />
        {devis.risk_sports &&
          devis.risk_sports.map((r, index) => (
            <Text size="small" key={index + 1}>
              {r}
            </Text>
          ))}
        <Line
          leftLabel="CODE POSTAL DE RÉSIDENCE FISCALE*"
          rightChildren={
            <CustomInput
              value={devis.zip_fiscal}
              onChange={(event) =>
                updateDevis("zip_fiscal", event.target.value)
              }
            />
          }
        />
        <Line
          leftLabel="STATUT PROFESSIONNEL*"
          rightChildren={
            <CustomSelect
              style={{ width: "100%" }}
              options={PROFESSIONAL_STATUS.map((s) => s.description)}
              value={devis.professional_status}
              onChange={({ option }) =>
                updateDevis("professional_status", option)
              }
            />
          }
        />
        <Line
          leftLabel="PROFESSION À RISQUE*"
          rightChildren={
            <CustomSelect
              style={{ width: "100%" }}
              options={RISK_PROFESSIONS.map((s) => s.description)}
              value={devis.risk_profession}
              onChange={({ option }) => updateDevis("risk_profession", option)}
            />
          }
        />

        {!isRiskProfession(devis.risk_profession) && (
          <Line
            leftLabel="PROFESSION*"
            rightChildren={
              <CustomInput
                value={devis.profession}
                onChange={(event) =>
                  updateDevis("profession", event.target.value)
                }
              />
            }
          />
        )}
        {!isRiskProfession(devis.risk_profession) && (
          <Line
            leftLabel="PROFESSION MANUELLE*"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: "Oui",
                    selected: devis.is_manual_profession === true,
                    onClick: () => updateDevis("is_manual_profession", true),
                  },
                  {
                    label: "Non",
                    selected: devis.is_manual_profession === false,
                    onClick: () => updateDevis("is_manual_profession", false),
                  },
                ]}
              />
            }
          />
        )}
        {!isRiskProfession(devis.risk_profession) && (
          <Line
            leftLabel="TRAVAUX EN HAUTEUR*"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: "Oui",
                    selected: devis.is_working_from_height === true,
                    onClick: () => updateDevis("is_working_from_height", true),
                  },
                  {
                    label: "Non",
                    selected: devis.is_working_from_height === false,
                    onClick: () => updateDevis("is_working_from_height", false),
                  },
                ]}
              />
            }
          />
        )}
        {!isRiskProfession(devis.risk_profession) && (
          <Line
            leftLabel="DÉPLACEMENTS PROFESSIONNELS / AN*"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: "- de 20000 km",
                    selected: devis.mobility === MOBILITY.less,
                    onClick: () => updateDevis("mobility", MOBILITY.less),
                  },
                  {
                    label: "+ de 20000 km",
                    selected: devis.mobility === MOBILITY.more,
                    onClick: () => updateDevis("mobility", MOBILITY.more),
                  },
                ]}
              />
            }
          />
        )}
        <Line
          leftLabel="FUMEUR*"
          rightChildren={
            <ToggleButton
              groups={[
                {
                  label: "Oui",
                  selected: devis.fumeur === true,
                  onClick: () => updateDevis("fumeur", true),
                },
                {
                  label: "Non",
                  selected: devis.fumeur === false,
                  onClick: () => updateDevis("fumeur", false),
                },
              ]}
            />
          }
        />
         <Line
          leftLabel="Le total de tous vos crédits est-il supérieur à 200 000 € ? *"
          rightChildren={
            <ToggleButton
              groups={[
                {
                  label: "Oui",
                  selected: devis.credit_gt_200k === true,
                  onClick: () => updateDevis("credit_gt_200k", true),
                },
                {
                  label: "Non",
                  selected: devis.credit_gt_200k === false,
                  onClick: () => updateDevis("credit_gt_200k", false),
                },
              ]}
            />
          }
        />
      </Box>
      <Box justify="center" alignSelf="center">
        <Heading
          level={3}
          textAlign="center"
        >{`Renseigner les informations du prêt`}</Heading>
      </Box>

      <Box width="large" gap="xsmall">
        <Line
          leftLabel="Objet du financement"
          rightChildren={
            <CustomSelect
              style={{ width: "100%" }}
              options={[
                "Autres",
                "Investissement locatif",
                "Prêt habitat",
                "Prêt personnel",
                "Résidence principale",
                "Résidence secondaire",
              ]}
              value={devis.objet_financement}
              onChange={({ option }) =>
                updateDevis("objet_financement", option)
              }
            />
          }
        />
        <Line
          leftLabel="De quel banque êtes-vous"
          rightChildren={
            <CustomInput
              value={devis.banque}
              onChange={(ev) => updateDevis("banque", ev.target.value)}
            />
          }
        />

        <Card margin={"small"} pad={"medium"} gap="small">
          <Line
            leftLabel="Avez-vous emprunté a plusieurs ?"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: "Oui",
                    selected:
                      devis.emprunteur_type === EMPRUNTEUR_TYPE.co_emprunteur,
                    onClick: () => {
                      _numberCoEmprunteur(1);
                      updateDevis(
                        "emprunteur_type",
                        EMPRUNTEUR_TYPE.co_emprunteur
                      );
                    },
                  },
                  {
                    label: "Non",
                    selected: devis.emprunteur_type === EMPRUNTEUR_TYPE.seul,
                    onClick: () => {
                      updateDevis("emprunteur_type", EMPRUNTEUR_TYPE.seul);
                    },
                  },
                ]}
              />
            }
          />
          {devis.emprunteur_type === EMPRUNTEUR_TYPE.co_emprunteur && (
            <Line
              leftLabel="Combien y a-t-il de co-emprunteur ?"
              rightChildren={
                <ToggleButton
                  groups={[
                    {
                      label: "2",
                      selected: assures.length === 1,
                      onClick: () => {
                        _numberCoEmprunteur(1);
                      },
                    },
                    {
                      label: "3",
                      selected: assures.length === 2,
                      onClick: () => {
                        _numberCoEmprunteur(2);
                      },
                    },
                    {
                      label: "4",
                      selected: assures.length === 3,
                      onClick: () => {
                        _numberCoEmprunteur(3);
                      },
                    },
                  ]}
                />
              }
            />
          )}
          <Card align="center" pad="small">
            <strong>Télécharger les documents</strong>
            <br />
            Glissez-déposez des fichiers ici
            
          </Card>
        </Card>
        {devis.emprunteur_type === EMPRUNTEUR_TYPE.co_emprunteur && (
          <Assures
            assures={assures}
            contacts={contact}
            updateDevis={updateDevis}
          />
        )}
      </Box> */}

      <Box style={{ height: 40 }} />
      <Box direction="row" align="center" justify="center" gap="medium">
        <PrimaryButton
          disabled={isLoading}
          label={`Sauvegarder`}
          onClick={() => {
            onNext();
          }}
        />
        <PrimaryButton
          disabled={isLoading}
          label={`Valider`}
          icon={<Checkmark />}
          onClick={() => {
            let message = validateDevis();
            if (message) {
              showMessage(`${message} est obligatoire.`, 'error');
              return;
            }
            onNext();
          }}
        />
      </Box>
    </Box>
  );
};

export default EmprunteurInfo;

const { PRODUCT_NAMES } = require("../../utils/types");

const getTaux = (prodName, classiqueBaseTaux = 2.8, rcBaseTaux = 1.5, rcLuxTaux = 1.8, retro = 0) => {
	classiqueBaseTaux = parseFloat(classiqueBaseTaux);
	rcBaseTaux = parseFloat(rcBaseTaux);
	rcLuxTaux = parseFloat(rcLuxTaux);
	let taux = classiqueBaseTaux;
	let taux_displayed = classiqueBaseTaux;
	let part_compagnie = 1.64;
	switch (prodName) {
		case PRODUCT_NAMES.annulation_interruption:
			taux_displayed = classiqueBaseTaux;
			part_compagnie = 1.64;
			break;
		case PRODUCT_NAMES.annulation_interruption_neige:
			taux_displayed = classiqueBaseTaux + configs.taux_defaut_exces_neige;
			part_compagnie = 1.64;
			break;
		case PRODUCT_NAMES.voyage:
			taux_displayed = classiqueBaseTaux + configs.taux_responsabilite;
			part_compagnie = 1.93;
			break;
		case PRODUCT_NAMES.voyage_neige:
			taux_displayed =
				classiqueBaseTaux +
				configs.taux_responsabilite +
				configs.taux_defaut_exces_neige;
			part_compagnie = 1.93;
			break;
		case PRODUCT_NAMES.voyage_luxe:
			taux = parseFloat((classiqueBaseTaux + 1.0).toFixed(2));
			taux_displayed = taux;
			part_compagnie = 3.74;
			break;
		case PRODUCT_NAMES.voyage_luxe_neige:
			taux = parseFloat((classiqueBaseTaux + 1.0).toFixed(2));
			taux_displayed = parseFloat((taux + configs.taux_defaut_exces_neige).toFixed(2));
			part_compagnie = 3.74;
			break;
		case PRODUCT_NAMES.rc_dommage:
			taux = rcBaseTaux;
			taux_displayed = rcBaseTaux;
			part_compagnie = 0.18;
			break;
		case PRODUCT_NAMES.rc_dommage_lux:
			taux = rcLuxTaux;
			taux_displayed = rcLuxTaux;
			part_compagnie = 0.18;
			break;
		default:
			taux = classiqueBaseTaux;
			part_compagnie = 1.64;
	}
	let part_trustiway = parseFloat((taux_displayed - part_compagnie).toFixed(2));
	taux_displayed = taux_displayed || 0;
	return {
		product: prodName,
		taux: parseFloat(taux.toFixed(2)),
		taux_displayed: parseFloat(taux_displayed.toFixed(2)),
		taux_de_tax: 1.09,
		part_compagnie,
		part_trustiway: part_trustiway,
		taux_annulation_vol: parseFloat(taux_displayed.toFixed(2)),
		taux_prestation_annexe: parseFloat(taux_displayed.toFixed(2)),
		max_retro: parseFloat(((part_trustiway / taux_displayed / 2) * 100).toFixed(2)),
		commission_compagnie: 25,
		taux_retro_agence: retro,
		taux_defaut_exces_neige: configs.taux_defaut_exces_neige,
		taux_responsabilite: configs.taux_responsabilite,
		defaut_exces_neige: getDefaultNeigeValue(prodName),
		responsabilite_dommange: getDefaultReponsabiliteValue(prodName),
		taux_vent: taux_displayed
	};
};

const configs = {
	taux_responsabilite: 0.5,
	taux_defaut_exces_neige: 0.8,
};

const getDisplayedTaux = product => {
	let calculatedTaux = getTaux(product.product, product.taux, product.taux);
	return calculatedTaux.taux_displayed;
}

const getDefaultNeigeValue = prodName => {
	switch (prodName) {
		case PRODUCT_NAMES.annulation_interruption_neige:
		case PRODUCT_NAMES.voyage_neige:
		case PRODUCT_NAMES.voyage_luxe_neige:
			return true;
		default:
			return false;
	}
}

const getDefaultReponsabiliteValue = prodName => {
	switch (prodName) {
		case PRODUCT_NAMES.voyage:
		case PRODUCT_NAMES.voyage_neige:
			return true;
		default:
			return false;
	}
}


module.exports = {
	getTaux,
	getDisplayedTaux,
};

import { Box, Card } from 'grommet';
import { DocumentDownload, DocumentText, DocumentVerified, Edit, Send, Update } from 'grommet-icons';
import React from "react";
import { convertToDealPossible } from '../../../../services/common_service';
import { CONTRAT_CLOTURE, CONTRAT_ENVOYE, DEAL_POSSIBLE, DEVIS_VALIDE, DEAL_POSSIBLE_REMPLI, DEVIS, PROSPECT } from '../../../../services/devis_contrat_status';
import { getSimpleDateFR, toCurrency } from '../../../../services/utils';
import EntrepriseRecap from '../../../contacts/entreprises/entreprise/recap';
import ApporteurGestionnaire from '../../../elements/apporteur_gestionaire';
import ApporteurModification from '../../../elements/apporteur_gestionaire/modification';
import DateRecap from '../../../elements/date_recap';
import DealStatusChange from '../../../elements/deal_status_change';
import EmailEventTracker from '../../../elements/email_tracker';
import { IdDisplay } from '../../../elements/line_container';
import PrimaryButton from '../../../elements/primary_button';
import StandardText from '../../../elements/standard_text';
import SectionDivider from '../../../voyages/components/session_devider';

const Recap = ({ deal, onEdit, showMessage, toDevis, toContrat, onDealReload, reload, onSendForm }) => {

    return (
        <Box
            style={{ width: "100%" }}
            justify="center"
            alignContent="center"
            align="center"
        >
            <Box
                width={'large'}
                justify="center"
                alignContent="center"
                align="center"
                gap="medium"
            >
                <IdDisplay id={deal.id} />
                <Box gap="small" width={'full'}>
                    <DateRecap deal={deal} />
                </Box>
                <EntrepriseRecap
                    entreprise={deal.entreprise || {}}
                    action={false}
                />

                <DealRecap
                    deal={deal}
                />

            </Box>
            {(deal.apporteur || deal.affiliation) && <Card pad={"small"} width={'xlarge'} >
                <ApporteurGestionnaire apporteur={deal.apporteur} affiliation={deal.affiliation} source={deal.source} />
            </Card>}
            <Box width={'xlarge'}>
                <EmailEventTracker messageIds={deal.emailEventIds} />
            </Box>
            <ApporteurModification
                devis={deal}
                apporteur={deal.apporteur}
                showMessage={showMessage}
                onReload={reload}
            />
            <Box direction='row' gap='medium' pad={'medium'}>
                <PrimaryButton icon={<Edit />} label='Modifier' onClick={onEdit} />
                {[DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS].includes(deal.status) &&
                    <PrimaryButton icon={<Send />} label='Envoyer formulaire' background={'#70A4EC'} onClick={onSendForm} />
                }
                {[DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS, DEVIS_VALIDE].includes(deal.status) &&
                    <PrimaryButton icon={<DocumentText />} label='Envoyer devis' background={'#70A4EC'} onClick={toDevis} />
                }
                {[DEVIS_VALIDE, CONTRAT_ENVOYE, CONTRAT_CLOTURE].includes(deal.status) &&
                    <PrimaryButton
                        label={`Envoyer un contrat`}
                        icon={<DocumentVerified />}
                        background="#64AEF6"
                        onClick={toContrat}
                    />
                }
                {deal.status === PROSPECT &&
                    <PrimaryButton
                        icon={<Update />}
                        onClick={() => {
                            deal.status = DEAL_POSSIBLE;
                            convertToDealPossible(deal, 'pro-sante')
                                .then(res => onDealReload(res.data));
                        }}
                        label="Convertir à Deal possible"
                    />
                }
                <DealStatusChange
                    deal={deal}
                    showMessage={showMessage}
                    onReload={reload}
                    path={'pro-sante'}
                />
            </Box>
        </Box>
    )
}

const DealRecap = ({ deal }) => {

    return (
        <Box
            width={'large'}
            justify="center"
            alignContent="center"
            align="center"
            gap="medium"
            pad={'xsmall'}
        >
            <Box >
                <StandardText style={{ fontWeight: 'bold' }} label='Informations sur votre activité' />
                <SectionDivider />
            </Box>
            <Box gap="xsmall" align="center" width={'full'}>
                <LineDisplay
                    label={"Précision sur votre activité"}
                    value={`${deal.precision_activite}`}
                />
            </Box>
            <Box >
                <StandardText style={{ fontWeight: 'bold' }} label='Informations sur votre entreprise' />
                <SectionDivider />
            </Box>
            <Box gap="xsmall" align="center" width={'full'}>
                <LineDisplay
                    label={"Effectif de l’entreprise"}
                    value={`${deal.effectif_entreprise}`}
                />
                <LineDisplay
                    label={"Effectif salariés AGIRC"}
                    value={`${deal.effectif_agirc}`}
                />
                <LineDisplay
                    label={"Effectif salariés non AGIRC"}
                    value={`${deal.effectif_non_agirc}`}
                />
                <LineDisplay
                    label={"Société représentée par"}
                    value={`
                        ${deal.director_nom} ${deal.director_prenom}<br/>
                        ${deal.director_tel}<br/>
                        ${deal.director_mail}
                    `}
                />
                <LineDisplay
                    label={"Chiffre d’affaires annuel HT"}
                    value={`${toCurrency(deal.ca_ht)}`}
                />
            </Box>
            <Box >
                <StandardText style={{ fontWeight: 'bold' }} label='Garanties souhaitées' />
                <SectionDivider />
            </Box>
            <Box gap="xsmall" align="center" width={'full'}>
                <LineDisplay
                    label={"Structure tarifaire"}
                    value={deal.structure_tarifaire}
                />
                <LineDisplay
                    label={"Niveau de garantie"}
                    value={deal.niveau_garantie}
                />
                <LineDisplay
                    label={"Date de début"}
                    value={deal.date_effet ? getSimpleDateFR(deal.date_effet) : 'Inconnu'}
                />
            </Box>
            <Box >
                <StandardText style={{ fontWeight: 'bold' }} label='Document' />
                <SectionDivider />
            </Box>
            <Box width="xlarge" direction="row" gap="small">
                <Card style={{ width: "100%" }} pad="medium">
                    {deal.entreprise?.file_kbis && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${deal.entreprise?.file_kbis}`}
                        >
                            <DocumentDownload />
                            KBIS
                        </a>
                    )}
                    {deal.entreprise?.file_statut && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${deal.entreprise?.file_statut}`}
                        >
                            <DocumentDownload />
                            Statut
                        </a>
                    )}
                    {deal.file_others ? (
                        deal.file_others.map((e, _idx) => {
                            return (
                                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                                    <DocumentDownload />
                                    {e && e.split("/").pop()}
                                </a>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </Card>
            </Box>
        </Box>
    )
}

const LineDisplay = ({ label, value }) => (
    <Box gap="xxsmall" direction='row' width={'full'}>
        <StandardText size={'small'} label={label + ' : '} />
        {
            (value ? value + '' : '').includes('<br/>') ?
                <Box>
                    {value.split('<br/>').map(text => (
                        <StandardText size={'small'} style={{ fontWeight: 'bold' }} label={text} />
                    ))}
                </Box> :
                <StandardText size={'small'} style={{ fontWeight: 'bold' }} label={value} />
        }

    </Box>
)

export default Recap;
import { Box, Button, Heading, Layer, Text } from "grommet";
import React, { useState } from "react";
import {
  BIEN_TYPE,
  CLIENT_TYPE,
  RESIDENCE_TYPE,
} from "../../../../services/types";
import ContactSelect, { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import CustomInput from "../../../elements/custom_input";
import CustomToggle from "../../../elements/custom_toggle";
import Line from "../../../elements/line_container";
import CustomSelect from "../../../elements/select_fill";
import AutoComplete from "react-google-autocomplete";
import ToggleButton from "../../../elements/toggle_button/toggle_button";
import ApporteurSelect from "../../../elements/apporteur_select";
import DateInput from "../../../elements/date_input";

const HabitationInfo = ({ devis, updateDevis, showMessage }) => {
  const [popupChildren, setPopupChildren] = useState(false);
  const [childFirstName, setChildFirstName] = useState("");
  const [childLastName, setChildLastName] = useState("");

  const getEstimatedPrice = () => {
    if (devis.annualPrice) {
      return devis.annualPrice;
    }
    return "";
  };

  const addChildren = () => {
    let children = devis.children || [];
    if (!childFirstName || !childLastName) {
      showMessage("Il faut remplir ces champs", "error");
      return;
    }
    children.push({ nom: childLastName, prenom: childFirstName });
    setChildFirstName("");
    setChildLastName("");
    updateDevis("children", children);
  };

  return (
    <Box
      gap="small"
      width="xlarge"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      {popupChildren && (
        <Layer style={{ overflowY: "scroll" }} pad="medium">
          <Box
            width="large"
            pad="small"
            style={{ paddingTop: 10 }}
            gap={"small"}
            justify="center"
          >
            <Heading level={2}>Ajouter un enfant</Heading>
            <Box direction="row" pad="small" gap="small">
              <CustomInput
                size={"small"}
                placeholder="Nom"
                value={childLastName}
                onChange={(event) => setChildLastName(event.target.value)}
              />
              <CustomInput
                size={"small"}
                placeholder="Prénom"
                value={childFirstName}
                onChange={(event) => setChildFirstName(event.target.value)}
              />
            </Box>
            <Box direction="row" pad="small" justify="end" gap={"small"}>
              <Button label="Fermer" onClick={() => setPopupChildren(false)} />
              <Button primary label="Ajouter" onClick={addChildren} />
            </Box>
          </Box>
        </Layer>
      )}
      <Box justify="center" alignSelf="center">
        <Heading level={3} textAlign="center">{`Informations du bien`}</Heading>
        {getEstimatedPrice() && (
          <Heading
            level={3}
            style={{ color: "#E92C70" }}
            textAlign="center"
          >{`Prix estimé à ${getEstimatedPrice()
            .toString()
            .replace(".", ",")} € / an`}</Heading>
        )}
      </Box>

      <ContactSelectV2
        contact={devis.client || {}}
        updateContact={updateDevis}
        addressIncluded={true}
      />
      <ApporteurSelect
        apporteur={devis.apporteur || {}}
        onDealUpdate={updateDevis}
      />
      <Box width="large" gap="medium" margin="medium">
        <Box gap="xsmall">
          <Text style={{ fontWeight: "bold" }} textAlign="center">
            Renseigner l'information du bien
          </Text>
          <Line
            leftLabel="Type d'habitation"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: CLIENT_TYPE.locataire,
                    selected: devis.clientType === CLIENT_TYPE.locataire,
                    onClick: () =>
                      updateDevis("clientType", CLIENT_TYPE.locataire),
                  },
                  {
                    label: CLIENT_TYPE.proprietaire,
                    selected: devis.clientType === CLIENT_TYPE.proprietaire,
                    onClick: () =>
                      updateDevis("clientType", CLIENT_TYPE.proprietaire),
                  },
                ]}
              />
            }
          />
          <Line
            leftLabel="Type de résidence"
            rightChildren={
              <ToggleButton
                groups={
                  [
                    {
                      label: RESIDENCE_TYPE.principale,
                      selected: devis.residenceType === RESIDENCE_TYPE.principale,
                      onClick: () =>
                        updateDevis("residenceType", RESIDENCE_TYPE.principale),
                    },
                    {
                      label: RESIDENCE_TYPE.secondaire,
                      selected: devis.residenceType === RESIDENCE_TYPE.secondaire,
                      onClick: () =>
                        updateDevis("residenceType", RESIDENCE_TYPE.secondaire),
                    }
                  ]
                }
              />
            }
          />

          <Line
            leftLabel="Nature de bien"
            rightChildren={
              <ToggleButton
                groups={[
                  {
                    label: BIEN_TYPE.maison,
                    selected: devis.bienType === BIEN_TYPE.maison,
                    onClick: () => updateDevis("bienType", BIEN_TYPE.maison),
                  },
                  {
                    label: BIEN_TYPE.appartement,
                    selected: devis.bienType === BIEN_TYPE.appartement,
                    onClick: () =>
                      updateDevis("bienType", BIEN_TYPE.appartement),
                  },
                ]}
              />
            }
          />

          <Box style={{ width: "100%" }} gap="xsmall">
            <Line
              leftLabel="Adresse d'assurance"
              rightChildren={
                <AutoComplete
                  options={{
                    types: ["address"],
                    componentRestrictions: { country: "fr" },
                  }}
                  apiKey={"AIzaSyBa3n60yKzDhlgz67vh9YUTwHSlrRzaa4g"}
                  value={devis.address}
                  style={{
                    background: "#EDEDED",
                    borderRadius: 10,
                    border: "none",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "inherit",
                    padding: 11,
                    width: "100%",
                    fontFamily: "inherit",
                  }}
                  onPlaceSelected={(place) => {
                    let parts = place.address_components;
                    let address = parts
                      .filter(
                        (s) =>
                          s.types &&
                          (s.types.includes("street_number") ||
                            s.types.includes("route"))
                      )
                      .map((s) => s.long_name)
                      .join(" ");
                    let zip = parts
                      .filter((s) => s.types && s.types.includes("postal_code"))
                      .map((s) => s.long_name)
                      .join("");
                    let city = parts
                      .filter((s) => s.types && s.types.includes("locality"))
                      .map((s) => s.long_name)
                      .join("");
                    updateDevis("address", address);
                    updateDevis("zip", zip);
                    updateDevis("city", city);
                  }}
                />
              }
            />
            <Line
              leftLabel="Code postal"
              rightChildren={
                <CustomInput
                  value={devis.zip}
                  onChange={(event) =>
                    updateDevis("zip", event.target.value)
                  }
                />
              }
            />
            <Line
              leftLabel="Ville"
              rightChildren={
                <CustomInput
                  value={devis.city}
                  onChange={(event) =>
                    updateDevis("city", event.target.value)
                  }
                />
              }
            />
          </Box>
          <Line
            leftLabel="Nombre de pièces"
            rightChildren={
              <CustomSelect
                style={{ width: "100%" }}
                placeholder="Nombre de pièce"
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                value={devis.totalRooms}
                onChange={({ option }) => updateDevis("totalRooms", option)}
              />
            }
          />
          <Line
            leftLabel="Surface"
            rightChildren={
              <CustomInput
                size={"small"}
                placeholder="m2"
                value={devis.surface}
                onChange={(event) => updateDevis("surface", event.target.value)}
              />
            }
          />

          <Line
            leftLabel="Le client a-t-il eu des sinistres"
            rightChildren={
              <Box width="small">
                <CustomToggle
                  checked={devis.hasSinistre}
                  onChange={(event) =>
                    updateDevis("hasSinistre", event.target.checked)
                  }
                />
              </Box>
            }
          />
          {devis.hasSinistre && (
            <Line
              leftLabel="Nombre des sinistres"
              rightChildren={
                <CustomSelect
                  style={{ width: "100%" }}
                  placeholder="Nombre de sinistre"
                  options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                  value={devis.sinistre}
                  onChange={({ option }) => updateDevis("sinistre", option)}
                />
              }
            />
          )}
          <Line
            leftLabel="Indice de confiance produit"
            rightChildren={
              <CustomSelect
                style={{ width: "100%" }}
                options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                value={devis.productConfiance}
                onChange={({ option }) =>
                  updateDevis("productConfiance", option)
                }
              />
            }
          />

          <Line
            leftLabel="Date de résiliation"
            rightChildren={
              <DateInput
                size={"small"}
                value={devis.date_resiliation}
                onChange={(date) => updateDevis("date_resiliation", date)}
              />
            }
          />
          <Line
            leftLabel={`Locale`}
            rightChildren={
              <ToggleButton
                groups={
                  [
                    {
                      label: "fr",
                      selected: devis.locale === 'fr',
                      onClick: () =>
                        updateDevis("locale", 'fr'),
                    },
                    {
                      label: "en",
                      selected: devis.locale === 'en',
                      onClick: () =>
                        updateDevis("locale", 'en'),
                    }
                  ]
                }
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HabitationInfo;

const metiers = {
    "Alimentaire": [
        "Alcools de bouche >= 25 degrés (stockage et/ou négoce de gros)",
        "Alimentation générale (stockage et/ou négoce de gros)",
        "Apéritifs à base de vins (production), fabrication de liqueurs et spiritueux",
        "Bière (fabrication)",
        "Biscuiterie artisanale (commerce de détail)",
        "Biscuits et biscottes (stockage et/ou négoce de gros)",
        "Boissons diverses de capacité réduite (< 25 degrés) (stockage et/ou négoce de gros)",
        "Boucherie charcuterie traiteur (commerce de détail)",
        "Boulangerie et/ou boulangerie pâtisserie non industrielle",
        "Boulangerie industrielle",
        "Café, thé (commerce de détail)",
        "Charcuterie industrielle",
        "Charcuterie traiteur",
        "Chocolaterie (commerce de détail)",
        "Chocolaterie (stockage et/ou négoce de gros)",
        "Chocolaterie confiserie avec ou sans laboratoire (commerce de détail)",
        "Confiserie (commerce de détail)",
        "Confiserie (stockage et ou négoce de gros)",
        "Confiseries (fabrication)",
        "Conserveries et industrie de plats préparés",
        "Conserves et plats préparés (stockage et/ou négoce de gros)",
        "Dépôt de pains terminal de cuisson",
        "Distillerie d'eaux de vies",
        "Eaux minérales (production), jus de fruits (fabrication)",
        "Epicerie fine (commerce de détail)",
        "Fromagerie, crèmerie (commerce de détail)",
        "Fruits et légumes (commerce de détail)",
        "Fruits et légumes (stockage et/ou négoce de gros)",
        "Glacier (commerce de détail)",
        "Hypermarché (> 2 500 m²)",
        "Industrie du poisson",
        "Industrie laitière (laiterie, beurrerie, fromagerie, glaces et fabrication de dérivés)",
        "Pâtes alimentaires (fabrication)",
        "Pâtes alimentaires (stockage et/ou négoce de gros)",
        "Pâtisserie artisanale",
        "Pâtisserie industrielle",
        "Poissonnerie (commerce de détail)",
        "Produits alimentaires diététiques (fabrication)",
        "Produits diététiques ou bio (commerce de détail)",
        "Produits diététiques ou bio (stockage et/ou négoce de gros)",
        "Produits surgelés (commerce de détail)",
        "Superette (< 400 m²)",
        "Supermarché (< 2 500 m²)",
        "Torréfaction de café, chicorée et autres fruits et graines",
        "Vinaigre, moutarde et conserve de fruits et légumes condimentaires (fabrication)",
        "Vinaigre, moutarde et conserve de fruits et légumes condimentaires (stockage et/ou négoce de gros)",
        "Vins (producteurs, négociants)",
        "Vins effervescents (producteurs, négociants)",
        "Vins et spiritueux (commerce de détail)"
    ],
    "Aménagement décoration": [
        "Abat-jour (fabricant restaurateur)",
        "Antiquaire",
        "Artiste peintre",
        "Arts de la table (commerce de détail)",
        "Arts de la table (stockage et/ou négocede gros)",
        "Cuisiniste, aménagement de salle de bains et de placard (Magasin d'exposition sans stockage)",
        "Décorateur étalagiste",
        "Galerie d'art",
        "Horloges carillons (fabrication restauration)",
        "Linge de maison (commerce de détail)",
        "Linge de maison (stockage et/ou négoce de gros)",
        "Loisirs créatifs (commerce de détail)",
        "Loisirs créatifs (stockage et/ou négocede gros)",
        "Lustres et luminaires (commerce de détail)",
        "Objets de décoration (commerce de détail)",
        "Objets de décoration artisanaux (commerce de détail)",
        "Objets en tissus , patchwork tissus appliques coussins drap (commerce de détail)",
        "Objets en tissus, patchwork tissus appliques coussins drap (fabrication)",
        "Objets en tissus, patchwork tissus appliques coussins drap (stockage et/ou négoce de gros)",
        "Peintre en lettres avec ou sans P.A.O",
        "Peintre sur faïence",
        "Poteries, céramiques Artisanales (commerce de détail)",
        "Restaurateur de tableaux",
        "Tapis moquettes (commerce de détail)",
        "Tapis moquettes (stockage et/ou négoce de gros)",
        "Tapis tapisserie (fabrication et restauration)",
        "Tapissier d'ameublement, tapissier décorateur",
        "Tapissier garnissage de siège, matelas (artisan)",
        "Textiles, rideaux voilages (commerce dedétail)",
        "Ustensiles et petits appareillages pourcuisine (commerce de détail)",
        "Ustensiles et petits appareillages pourcuisine (stockage et/ou négoce de gros)"
    ],
    "Autos cycles bateaux aéronautique": [
        "Atelier d'entretien des véhicules de l'entreprise",
        "Atelier maintenance aéronautique et remisage d'aéronefs",
        "Bateaux de plaisance (construction, entretien, réparation et gardiennage hors parcs)",
        "Bateaux de plaisance (vente)",
        "Bateaux, construction et réparation navale",
        "Cycles (location)",
        "Cycles et équipements pour cycle (stockage et/ou négoce de gros)",
        "Cycles et équipements pour cycle (vente, réparation)",
        "Equipements automobiles (commerce de détail sans pose)",
        "Equipements motos (commerce de détail sans pose)",
        "Hangars pour aéronefs au sol et moteur à l'arrêt",
        "Parcs de stationnement",
        "Parking pour propre compte sans réparation",
        "Shipchandler (fournitures pour bateauxsans entretien, hivernage, réparation)",
        "Station de lavage en libre service",
        "Station service en libre service"
    ],
    "Bar restaurant": [
        "Bar à vins",
        "Brasserie",
        "Café bar avec tabac",
        "Café bar sans tabac",
        "Crêperie",
        "Location de salle pour manifestations privées avec présence de cuisine",
        "Location de salle pour manifestations privées sans présence de cuisine",
        "Pizzeria avec four à bois",
        "Pizzeria sans four à bois",
        "Restauration de type rapide avec friteuse",
        "Restauration de type rapide sans friteuse",
        "Restauration de type traditionnelle",
        "Saladerie sandwicherie",
        "Salon de thé, glacier avec consommationsur place",
        "Sandwicherie, viennoiserie, croissanterie",
        "Traiteur avec organisation d'événement",
        "Traiteur sans organisation d'événement"
    ],
    "Bâtiment": [
        "Agencement de lieux de vente",
        "Appareils de chauffage et de climatisation (commerce de détail)",
        "Carrelage, mosaïque, céramique (vente)",
        "Chauffagiste Climatisation",
        "Cheminées (vente)",
        "Couvreur, zingueur",
        "Electricien",
        "Entreprise de maçonnerie",
        "Fabrication de produits en béton,",
        "Fournitures pour plomberie, électricitéet chauffage sans pose ni réparation (commerce de détail)",
        "Fournitures pour plomberie, électricitéet chauffage sans pose ni réparation (stockage et/ou négoce de gros)",
        "Location de matériel de construction avec opérateur",
        "Matériaux de construction (stockage et/ou négoce de gros)",
        "Matériels de prévention protection (commerce de détail)",
        "Matériels de prévention protection (stockage et/ou négoce de gros)",
        "Matériels et matériaux de travaux publics et du bâtiment (stockage)",
        "Monuments funéraires, marbrier",
        "Mosaïste (avec pose sur bâtiment)",
        "Mosaïste (sans pose sur bâtiment)",
        "Panneaux photovoltaïque (stockage et/ounégoce de gros)",
        "Peintre en bâtiment, plâtrier",
        "Plombier, sanitaire",
        "Store, bannes (commerce de détail avec pose)",
        "Store, bannes (commerce de détail sans pose)",
        "Store, bannes (stockage et/ou négoce degros)",
        "Travaux d'étanchéité"
    ],
    "Beauté Hygiène Esthétique": [
        "Blanchiment de dents",
        "Centre de bronzage",
        "Coiffeur",
        "Cosmétique (commerce de détail)",
        "Cosmétique (Fabrication artisanale)",
        "Esthéticienne, manucure, institut de beauté",
        "Fish pédicure",
        "Herboristerie (commerce de détail)",
        "Industrie de la parfumerie (mélange et conditionnement)",
        "Onglerie",
        "Parfumerie",
        "Parfums, produits de beauté (stockage et/ou négoce de gros)",
        "Perruquier posticheur",
        "Produits capillaires professionnels etmatériels de coiffure (commerce de)",
        "Produits capillaires professionnels etmatériels de coiffure (stockage et/ou négoce de gros)",
        "Savon de lessives et produits à récurer(stockage et/ou négoce de gros)",
        "Savon, préparation de lessives et produits à récurer (fabrication)",
        "Savons, produits cosmétiques artisanaux(commerce de détail)",
        "Tatoueur"
    ],
    "Bien-être": [
        "Analyse transactionnelle",
        "Aromathérapie, Phytothérapie, Oligothérapie, Fleurs de Bach, Rescue",
        "Arthérapie, Musicothérapie, Danse thérapie",
        "Biodanza",
        "Coaching de vie",
        "Drainage lymphatique manuel",
        "Education somatique, technique Alexander, Hygiène somatique",
        "Equithérapie",
        "Fasciapulsologie",
        "Kinésiologie",
        "Pilates (méthode), Gestalt thérapie",
        "Playground",
        "Pressothérapie",
        "Qi gong, Tai chi chuan, Do in",
        "Rebirth",
        "Reiki",
        "Relaxation : Relaxation Evolutive, Relaxologie, Biosynergie,Training Autogène",
        "Shiatsu, Reflexologie,Tuina",
        "Sophrologie",
        "Thérapie par toucher léger, Ayurveda, Esalen, Rolfing, Trager",
        "Yoga, Eutonie",
        "Zoothérapie"
    ],
    "Bois": [
        "Charpentier",
        "Ebéniste, restauration de meubles",
        "Encadreur",
        "Jouets en bois (fabrication)",
        "Menuisier",
        "Meubles autre que de cuisine et de salle de bain (fabrication)",
        "Meubles de cuisine et de salle de bain (fabrication avec pose)",
        "Meubles de cuisine et de salle de bain (fabrication sans pose)",
        "Objets en bois hors ameublement (fabrication)",
        "Objets en bois hors ameublement (stockage et/ou négoce de gros)",
        "Peintre sur bois décorateur sur bois",
        "Pipier",
        "Sculpteur sur bois",
        "Tonneaux (fabrication négoce)"
    ],
    "Bricolage": [
        "Droguerie des couleurs, vernis et papier peint (commerce de détail)",
        "Droguerie des couleurs, vernis et papier peint (stockage et/ou négoce de gros)",
        "Location d'outillage, d'outils de bricolage",
        "Papiers peints, peinture, revêtements de sol et murs (stockage et/ou négoce de gros)",
        "Papiers peints, peinture, revêtements de sol et murs, avec pose (commerces de détail)",
        "Papiers peints, peinture, revêtements de sol et murs, sans pose (commerces de détail)",
        "Quincaillerie, outillage, bricolage jardinage (commerce de détail)",
        "Quincaillerie, outillage, bricolage jardinage (stockage et/ou négoce de gros)"
    ],
    "Bureaux seuls": [
        "Locaux à usage de bureau"
    ],
    "Commerces et services divers": [
        "Blanchisserie industrielle",
        "Blanchisserie non industrielle",
        "Cigarette électronique (commerce de détail)",
        "Cinéma et autre salle de projections ouverte au public",
        "Cordonnerie clés talons minutes",
        "Entreprise de nettoyage",
        "Laverie automatique",
        "Machine à coudre ou a tricoter (commerce de détail)",
        "Parc des expositions, foires expositions, salons à vocation commerciale",
        "Pompes funèbres hors marbrier",
        "Pressing",
        "Produits régionaux et souvenirs",
        "Salles de séminaires, centre d'affaires",
        "Souvenirs (commerce de détail d'articles de)",
        "Tabac ou tabac presse",
        "Théâtre, espace scénique"
    ],
    "Comptabilité - Finance": [
        "Audit d'assurances sans intermédiation",
        "Expert comptable"
    ],
    "Conseil en entreprise": [
        "Agence de publicité avec organisation d'événements",
        "Agence de publicité sans organisation d'événements",
        "Agence et conseil en communication, Relations publiques avec organisation d'évènement",
        "Agence et conseil en communication, Relations publiques sans organisation d'évènement",
        "Conseil en développement durable et/ou en environnement, Conseil en gestion desdéchets",
        "Conseil en ergonomie",
        "Conseil en hygiène hors salle blanche",
        "Conseil en hygiène y compris salle blanche",
        "Conseil en management, en organisation, à la conduite du changement",
        "Conseil en marketing, étude de marché, merchandising",
        "Conseil en ressources humaines",
        "Conseil en restaurant d'entreprise",
        "Conseil en sécurité incendie limité auxprescriptions règlementaires",
        "Conseil qualité sans délivrance de certification",
        "Conseil en commerce extérieur",
        "Conseiller agricole (hors agriculture durable)",
        "Dessinateur industriel (hors bâtiment)",
        "Dessinateur publicitaire",
        "Etalagiste",
        "Gestion de supports publicitaires, Achats d'espaces publicitaires",
        "Management de transition",
        "Nettoyage industriel",
        "Régisseur ou Maître de chais indépendant non oenologue",
        "Secrétariat d'entreprise, Centre d'appels"
    ],
    "Electronique électricité": [
        "Appareils électriques (commerce de détail)",
        "Appareils électriques (fabrication assemblage réparation)",
        "Appareils électriques (stockage et/ou négoce de gros)",
        "Appareils électroniques ou à courants faibles (fabrication assemblage réparation)",
        "Appareils électroniques ou à courants faibles (stockage et/ou négoce de gros)",
        "Composants électroniques (fabrication)",
        "Composants électroniques (stockage et/ou négoce de gros)",
        "Jouets électroniques ou électriques (fabrication)",
        "Machines et équipements pour l'industrie (fabrication)"
    ],
    "Enseignement": [
        "Collège",
        "Cours privés",
        "Crèche",
        "Ecole",
        "Lycée d'enseignement général",
        "Lycée d'enseignement technique"
    ],
    "Evénementiels, Spectacles": [
        "Agence de mannequins",
        "Agence d'hôtesses d'accueil",
        "Animateur, Speaker, Conférencier",
        "Artiste",
        "Disc Jockey sans organisation d'événements",
        "Location et/ou montage de chapiteaux, tribunes",
        "Mannequin indépendant",
        "Organisation d'évènements familiaux",
        "Organisation d'événements pour compte d'autrui hors évènements familiaux et sportifs",
        "Organisation d'événements professionnelsnon sportifs pour propre compte sans exploitation de salle",
        "Organisation d'évènements sportifs"
    ],
    "Expertise": [
        "Expert automobile",
        "Expert bateaux de plaisance",
        "Expert dommages aux biens immobiliers,mobiliers (hors objets d'art, de collection)",
        "Expert en oeuvres d'art et objets de collection",
        "Expert équin",
        "Expert maritime",
        "Expert valeur vénale immeuble et fonds de commerce"
    ],
    "Floral et Animaux": [
        "Accessoires et aliments pour animaux (commerce de détail)",
        "Aliments pour animaux (fabrication)",
        "Aliments pour animaux (stockage et/ou négoce de gros)",
        "Animalerie, vente d'animaux de compagnie pour particuliers",
        "Fleuriste, fleurs artificielles (commerce de détail)",
        "Fleuriste, fleurs artificielles (stockage et/ou négoce de gros)",
        "Fleurs artificielles (fabrication)",
        "Grainetier, vente de graine de fleurs pour particulier",
        "Jardinerie",
        "Jardinier sans réalisation d'aires de jeu, de travaux du bâtiment de travaux publics",
        "Paysagiste, Jardinier avec réalisation daires de jeu, de travaux du bâtiment de travaux publics",
        "Paysagiste, Jardinier sans réalisation d'aires de jeux, de travaux du bâtiment,de travaux publics",
        "Pension pour animaux de compagnie (horséquidés)",
        "Taxidermiste, naturaliste",
        "Toilettage d'animaux"
    ],
    "Habillement mode et accessoire": [
        "Accessoires de l'habillement hors bijoux (commerce de détail)",
        "Bâches, voiles (confection)",
        "Bijouterie fantaisie (commerce de détail)",
        "Bijouterie fantaisie (fabrication artisanale)",
        "Bijouterie horlogerie (commerce de détail)",
        "Bijouterie horlogerie (fabrication)",
        "Blanchiment, teinture, impression, apprêts de textiles divers",
        "Brodeur",
        "Chapelier",
        "Chaussures bottier (fabrication artisanale)",
        "Chaussures et articles chaussants (commerce de détail)",
        "Chaussures et articles chaussants (stockage et/ou négoce de gros)",
        "Chemiserie (commerce de détail)",
        "Costumier de spectacle",
        "Cuirs et peaux (commerce de détail)",
        "Fourrure, pelleterie sans confection (commerce de détail)",
        "Industrie de l'habillement et de la maille",
        "Lingerie bonneterie (commerce de détail)",
        "Maroquinerie et articles de voyage (commerce de détail)",
        "Maroquinerie et d'articles en cuir saufvêtement (stockage et/ou négoce de gros)",
        "Maroquinerie, fabrication d'articles encuir (sauf vêtement)",
        "Mercerie, magasin de laine",
        "Montres (commerce de détail avec ou sans réparation)",
        "Retouche et/ou repassage de vêtements",
        "Tailleur, couturier, retoucheur.",
        "Tee-shirt (commerce de détail)",
        "Tissus, linge, vêtements, laines et voiles (stockage et/ou négoce de gros)",
        "Vente robes de mariées (commerce de détail)",
        "Vêtements (commerce de détail)",
        "Vêtements (location de)",
        "Vêtements de travail (commerce de détail)",
        "Vêtements pour enfants (commerce de détail)",
        "Vêtements pour enfants et articles de puericulture (Commerce de détail)"
    ],
    "Hôtel hébergement": [
        "Centre de thalassothérapie",
        "Chambres d'hôtes",
        "Colonie de vacances",
        "Gite meublé de tourisme",
        "Hôtel avec restaurant",
        "Hôtel sans restaurant",
        "Maison de retraite non médicalisée",
        "Résidence de vacances"
    ],
    "Informatique Electroménager Téléphonie": [
        "Accessoires de téléphonie (commerce de détail)",
        "Cartouches d'encre (commerce de détail)",
        "Electroménager, radio, tv hifi (commerce de détail et service après vente avec pose)",
        "Electroménager, radio, tv hifi (commerce de détail et service après vente sans pose)",
        "Electroménager, radio, tv hifi (réparation sans récupération)",
        "Electroménager, radio, tv hifi (stockage et commerce de gros)",
        "Frigoriste",
        "Informatique, bureautique (réparation sans récupération)",
        "Informatique, bureautique (stockage et commerce de gros)",
        "Informatique, bureautique avec service après vente (commerce de détail)",
        "Informatique, bureautique sans service après vente (commerce de détail)",
        "Téléphonie mobile (commerce de détail)"
    ],
    "Juridique": [
        "Avocat",
        "Avoué",
        "Conseiller juridique",
        "Huissier"
    ],
    "Loisirs musique": [
        "Accessoires et produits de piscines (commerce de détail)",
        "Accessoires et produits de piscines (stockage et/ou négoce de gros)",
        "Armurerie, coutellerie (commerce de détail)",
        "Articles de pêche, aquarium (commerce de détail)",
        "Articles de pêche, aquarium (stockage et/ou négoce de gros)",
        "Articles de sport et de loisirs (commerce de détail et réparation)",
        "Cours de danse",
        "Disquaire",
        "Facteur de pianos",
        "Facteur d'orgue",
        "Golf indoor avec restauration",
        "Instruments de musique (réparation)",
        "Instruments de musique (stockage et/ou négoce de gros)",
        "Instruments de musique avec atelier de réparation (commerce de détail)",
        "Instruments de musique sans atelier de réparation (commerce de détail)",
        "Instruments d'optique (commerce de détail)",
        "Jeux vidéos, console (commerce de détail)",
        "Jeux, jouets, modèles réduits (commercede détail)",
        "Jeux, jouets, modèles réduits (stockageet/ou négoce de gros)",
        "Laboratoires photos sans vente d'appareils",
        "Luthier",
        "Matériels photographiques avec ou sans laboratoire (commerce de détail)",
        "Numismate",
        "Philatéliste",
        "Photographe avec studio de prise de vues",
        "Piscines, saunas, jacuzzi (commerce de détail)",
        "Salle de sport",
        "Sellerie d'équitation (vente)",
        "Station de radiodiffusion, télévision",
        "Studios de prises de vues, de radio ou télé, d'enregistrement ou de reproduction"
    ],
    "Médicales - Paramédicales": [
        "Ambulancier avec garage de véhicules",
        "Ambulancier sans garage de véhicules",
        "Cabinet médical ou paramédical",
        "Chiropracteur",
        "Chirurgien dentiste avec implants",
        "Chirurgien dentiste sans implants",
        "Coaching non sportif",
        "Diététicien",
        "Ergothérapeute",
        "Ethiopathe",
        "Infirmier en puériculture",
        "Infirmier garde-malade",
        "Infirmier non spécialisé",
        "Masseur-kinésithérapeute avec ostéopathie",
        "Masseur-kinésithérapeute du sport avec ou sans ostéopathie",
        "Masseur-kinésithérapeute sans ostéopathie",
        "Médecin Acuponcture, Auriculothérapie, Neurostimulation électrique transcutanée",
        "Médecin allergologue",
        "Médecin Biofeedback",
        "Médecin Biokinergie",
        "Médecin Conseil",
        "Médecin du sport",
        "Médecin du travail, Médecin salarié",
        "Médecin EMDR (Intégration neuro-émotionnelle par mouvements oculaires)",
        "Médecin généraliste",
        "Médecin généraliste pratiquant la lumière pulsée",
        "Médecin Homéopathe",
        "Médecin Hydrothérapeute",
        "Médecin Hypnothérapeute",
        "Médecin légiste",
        "Médecin Luminothérapeute",
        "Médecin Luxopuncture",
        "Médecin Mésothérapeute",
        "Médecin nutritionniste",
        "Orthodontiste",
        "Orthopédiste, Orthésiste",
        "Orthophoniste",
        "Orthoptiste",
        "Ostéopathie avec ou sans ostéo crânienne",
        "Pédicure, Podologue",
        "Psychanalyste",
        "Psychologue avec ou sans neuropsychologie",
        "Psychomotricien",
        "Psychopraticien",
        "Psychothérapeute"
    ],
    "Métal": [
        "Appareils de pesage métallique (fabrication)",
        "Articles de robinetterie à usage grand public (fabrication)",
        "Articles métalliques (fabrication)",
        "Chaudronnerie tuyauterie",
        "Coutellerie (fabrication)",
        "Décolletage",
        "Décorateur sur métal",
        "Découpage emboutissage",
        "Ferronnier",
        "Fonderie de moulage",
        "Forgeron",
        "Jouets en métal (fabrication)",
        "Maréchal ferrant",
        "Mécanique générale (usinage de métaux)",
        "Métallier serrurier",
        "Outillage à main (fabrication)",
        "Outillage mécanique (fabrication)",
        "Pompes et compresseurs (fabrication)",
        "Potier d'étain",
        "Serrures (fabrication)",
        "Traitement mécanique des métaux",
        "Travail des métaux exclusivement par changement de forme du métal",
        "Travail des métaux exclusivement par enlèvement de métal",
        "Travail des métaux stockage des matières premières et produits finis"
    ],
    "Métiers de l'audiovisuel et de l'écriture": [
        "Agence de presse",
        "Auteur de mots croisés et de sudoku",
        "Auteur et/ou Dessinateur de bandes dessinées",
        "Cadreur, Cameraman",
        "Chorégraphe",
        "Correcteur, Lecteur dans l'édition, Documentaliste télé et cinéma",
        "Directeur artistique",
        "Directeur de production audiovisuelle",
        "Ecrivain, Auteur de pièces de théâtre",
        "Edition sans travaux d'imprimerie",
        "Journaliste indépendant, Pigiste, Reporter d'images",
        "Maquilleur d'artistes, comédiens (hors maquillage permanent)",
        "Metteur en scène de théâtre, de cinéma",
        "Opérateur, Assistant opérateur",
        "Photographe sans studio (hors photographes people)",
        "Producteur de films institutionnels et publicitaires",
        "Producteur de théâtre, danse, spectacles et programmes de télévision",
        "Réalisateur de films institutionnels etpublicitaires",
        "Régisseur pour le compte de productionsaudiovisuelles et évènementielles",
        "Scénariste",
        "Scripte",
        "Sous-titrage de films",
        "Technicien son et lumière"
    ],
    "Papier Impression": [
        "Articles de Papeterie (commerce de détail)",
        "Assemblage, brochage, reliage",
        "Carteries et articles de papeterie (commerce de détail)",
        "Enlumineur",
        "Impression sur papier, carton, plastique sans transformation de ces matières, sans flexographie",
        "Imprimeur d'estampe, lithographie, typographie",
        "Librairie, presse, papeterie",
        "Livres, journaux, périodiques (stockageet/ou négoce de gros)",
        "Photogravure",
        "Reprographie en libre service",
        "Sérigraphie sur papier, carton, plastique, vêtement sans transformation de ces matières"
    ],
    "Pierre": [
        "Pierre de construction, articles en pierre (production)",
        "Pierre de construction, articles en pierre (stockage et/ou négoce de gros)",
        "Sculpteur sur pierre",
        "Travail artisanal de la pierre, marbrier (hors bâtiment)"
    ],
    "Plastique": [
        "Articles moules en matières plastiques à partir de résines liquides (fabrication)",
        "Atelier de fabrication par impression en 3 dimensions",
        "Matières plastiques non alvéolaires : stockage matières premières et produits finis",
        "Menuiserie PVC aluminium bois (fabrication)",
        "Menuiserie PVC aluminium bois (stockageet/ou négoce de gros)",
        "Menuiserie PVC aluminium bois (vente sans pose sous-traitée ou non, sans atelier)",
        "Plaques, feuilles, tubes et profilés en plastique (fabrication)",
        "Transformation de matière plastique nonalvéolaire"
    ],
    "Santé": [
        "Articles médicaux et orthopédiques (fabrication)",
        "Articles médicaux et orthopédiques (stockage et/ou négoce de gros)",
        "Articles médicaux et orthopédiques (vente)",
        "Audioprothésiste",
        "Cabinet de radiologie",
        "Clinique",
        "Laboratoire d'analyse médicale",
        "Lunettes et instruments d'optique (fabrication)",
        "Lunettes et instruments d'optiques (stockage et/ou négoce de gros)",
        "Maison de retraite médicalisée",
        "Opticien",
        "Parapharmacie",
        "Parapharmacie (stockage et/ou négoce degros)",
        "Pharmacie",
        "Produits pharmaceutiques (stockage et/ou négoce de gros)",
        "Prothésiste dentaire"
    ],
    "Services à la personne": [
        "Agence matrimoniale",
        "Coiffeur à domicile",
        "Conciergerie d'entreprise",
        "Conseiller en relooking",
        "Cours de cuisine avec ou sans restauration à domicile",
        "Cours particuliers de soutien scolaire (hors établissement), de loisirs non sportifs",
        "Dressage d'animaux domestiques pour compte de tiers sans pension",
        "Ecrivain public",
        "Esthéticienne à domicile",
        "Formation de chien guide sans pension",
        "Garde d'animaux à domicile avec promenade (hors loi Borloo)",
        "Garde malade, Aide soignant (hors loi Borloo)",
        "Garde occasionnelle d'enfants, Baby sitting (hors loi Borloo)",
        "Gouvernante (hors loi Borloo)",
        "Guide interprète hors traduction écrite, Accompagnateur de groupe hors activités sportives",
        "Livraison repas à domicile avec ou sanspréparation, de courses ménagères (hors loi Borloo)",
        "Location équidés sans centre équestre",
        "Onglerie à domicile",
        "Professeur de musique ou cours de musique",
        "Repassage, ménage, couture à domicile ou chez les particuliers (hors loi Borloo)",
        "\"Services à la personne \"\"Loi Borloo\"\" soumis à agrément\"",
        "\"Services à la personne \"\"Loi Borloo\"\" soumis à dispositif déclaratif\"",
        "Services aux expatriés et/ou aux migrants sans hébergement",
        "Services d'aide sociale avec exonération Taxes Assurances (hors association)",
        "Services divers pour résidences secondaires sauf gardiennage",
        "Thanatopracteur, Soins aux défunts"
    ],
    "Services au particulier et/ou professionnel et entreprise": [
        "Abattage, Elagage d'arbres avec ou sansutilisation de nacelle",
        "Abattage, Elagage d'arbres sans élagueur grimpeur, ni utilisation de nacelle",
        "Agence privée de recherche",
        "Agent commercial",
        "Agent commercial mandaté par agence immobilière",
        "Art graphique avec conception de logo",
        "Art graphique sans conception de logo",
        "Auto école - Bateau école",
        "Chasseur d'appartement sauf agent immobilier",
        "Conciergerie privée, conciergerie de luxe pour particuliers",
        "Conseil en recrutement, aide à la recherche d'emploi",
        "Décorateur d'intérieur (hors architecte), Paysagiste d'intérieur, Home staging",
        "Dégustateur de produits alimentaires (non oenologue)",
        "Entretien d'espaces verts, jardiniers (sans élagage)",
        "Ergonome",
        "Etat des lieux pour le compte de propriétaires ou de leurs représentants",
        "Exploitation d'étangs de pêche sans autres activités de loisirs",
        "Formateur indépendant",
        "Formation professionnelle ou continue (hors établissement scolaire sans atelier)",
        "Généalogiste",
        "Graphologue",
        "Lavage de vitres sans travaux acrobatiques",
        "Nettoyage après sinistres (hors travauxde démolition)",
        "Nettoyage de bureaux ou locaux professionnels à usage commercial",
        "Nettoyage de locaux domestiques hors loi Borloo",
        "Oenologue : activités d'analyses et deconseils",
        "Oenologue : activités d'analyses sans conseils",
        "Sommelier indépendant",
        "Taxi ou exploitant de voiture de tourisme avec chauffeur (VTC)",
        "Traduction de documents",
        "Travail temporaire (secteur tertiaire)",
        "Travail temporaire (tous secteurs)"
    ],
    "Soins aux animaux": [
        "Ostéopathe animalier non équin",
        "Ostéopathe animalier y compris équin",
        "Technicien dentaire équin non vétérinaire",
        "Vétérinaire rural non spécialisé équin",
        "Vétérinaire spécialisé équin sans clinique",
        "Vétérinaire urbain"
    ],
    "Sport": [
        "Accompagnateur de moyenne montagne (altitude maxi : 1000 m)",
        "Guide de randonnée équestre, moniteur d'équitation",
        "Guide de randonnée pédestre et/ou en raquettes",
        "Maître nageur",
        "Moniteur d'arts martiaux",
        "Moniteur de golf",
        "Moniteur de ski",
        "Moniteur de ski nautique",
        "Moniteur de voile",
        "Moniteur d'escalade en salle sans sortie en montagne",
        "Moniteur en eaux vives (hors canyoning)",
        "Professeur de danse",
        "Professeur de tennis",
        "Randonnée en moyenne montagne (altitudemaxi : 2000 m)"
    ],
    "SSII": [
        "SSII sans activité d'hébergement et d'infogérance - Chiffre d'affaires inférieur ou égal à 150 000 euros",
        "SSII sans activité d'infogérance"
    ],
    "Verre": [
        "Graveur sur verre",
        "Maitre verrier, vitrailliste, restaurateur de vitraux",
        "Miroiterie et négoce de verres plats sans pose sous-traitée ou non",
        "Miroitier d'art",
        "Objets de décoration artisanal en verre(commerce de détail)",
        "Souffleur de verre",
        "Tailleur de verre",
        "Tissage du verre",
        "Verre (stockage et/ou négoce de gros)"
    ]
}

export default metiers;
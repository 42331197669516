import moment from "moment";
import React from 'react';
import { getSimpleDateFR } from "../../../services/utils";
import DateInput from "../date_input";

const DateInputString = ({ strValueInFRFormat, onChange, placeholder, size }) => {
    return (
        <DateInput
            value={strValueInFRFormat ? moment(strValueInFRFormat, 'YYYY-MM-DD').toDate() : null}
            onChange={date => onChange(moment(date).format('YYYY-MM-DD'))}
            placeholder={placeholder}
            size={size}
        />
    )
}

export default DateInputString;
import React from 'react';
import { Box, Tabs, Tab } from 'grommet';
import Indicator from './indicator';
import service from '../../services/dashboard';
import Reports from './reports';
import { navigate } from 'gatsby-link';
import { verifyConnected, logout } from '../../services/login_service';
import CommissionReport from './commission_report';
import { hasAdministrativeRole, hasClassiqueManagerRole, hasCreditManagerRole, isCommercialClassique } from '../../services/storage_service';
import ReportsClosed from './reports_closed';
import moment from 'moment';
import AdminAppelReport from '../admin/stats/appels/admin_appel_reports';
import PerduReports from './reports_perdu';


class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indicator: undefined,
            commerciaux: [],
            all_commerciaux: []
        }
    }

    componentDidMount() {
        verifyConnected()
            .then(res => {
            })
            .catch(err => {
                let res = err.response;
                if (res && res.status === 401) {
                    logout();
                    navigate("/login");
                }
            })
        service.getUsers()
            .then(res => {
                const users = res.data;
                const commerciaux = users.filter(c => c.role === 'Commercial Classique' || c.role === 'Manager Classique');
                this.setState({ commerciaux, all_commerciaux : users });
            });
    }

    render() {
        return (

            <Box style={{ width: "100%" }}
                align="center" justify="center"
                alignSelf="center"
                pad={'medium'}
                alignContent="center" gap="medium">
                <Tabs style={{ width: '100%' }}>
                    {!hasAdministrativeRole() ?
                        <Tab title="Vos statistiques">
                            <Reports key={`2_${this.state.commerciaux.length}`}
                                commerciaux={this.state.commerciaux} indicator personal={true}
                                showMessage={this.props.showMessage}
                                all_commerciaux={this.state.all_commerciaux}
                            />
                        </Tab>
                        :
                        <Tab title="Statistique de l'équipe">
                            <Reports key={`${this.state.commerciaux.length}`} commerciaux={this.state.commerciaux}
                                showMessage={this.props.showMessage}
                                indicator apptHidden={true}
                                all_commerciaux={this.state.all_commerciaux} />
                        </Tab>
                    }
                    {hasClassiqueManagerRole() &&
                        <Tab title="Statistique de l'équipe">
                            <Reports key={`${this.state.commerciaux.length}`} commerciaux={this.state.commerciaux}
                                showMessage={this.props.showMessage}
                                indicator apptHidden={true}
                                all_commerciaux={this.state.all_commerciaux}
                            />
                        </Tab>
                    }
                    {(hasAdministrativeRole() || hasClassiqueManagerRole()) &&
                        <Tab title="Les contrats en cours">
                            <ReportsClosed
                                showMessage={this.props.showMessage}
                                commerciaux={this.state.commerciaux} indicator apptHidden={true} />
                        </Tab>
                    }
                    {
                        <Tab title="Les deals perdus">
                            <PerduReports
                                showMessage={this.props.showMessage}
                                commerciaux={this.state.commerciaux} indicator apptHidden={true} />
                        </Tab>
                    }
                </Tabs>

            </Box>
        )
    }
}

export default Dashboard;
import React from 'react';
import { Box, FileInput, Grommet, TextInput } from 'grommet';
import { TextLabel } from '../text-label';

const customTheme = {
    fileInput: {
        message: {
            color: 'rgb(232, 44, 112)',
            textAlign: 'center',
            browse: {
                fontSize: '12px',
            },
            dropPromptMultiple: {
                fontSize: '12px',
                
            }
        },
        label: {
            size: "small"
        }
    }
};

export default function CustomFileInput({ value, onChange, editMode }) {
    return (
        <Grommet theme={customTheme}>
            <Box align="center" justify="center">
                <FileInput
                    size={"small"}
                    style={{
                        fontSize: "12px",
                    }}
                    messages={{
                        browse: "...",
                        dropPromptMultiple: "Déposez ou cliquer pour télécharger vos fichiers",
                    }}
                    multiple
                    maxSize={10}
                    onChange={onChange}
                />
            </Box>
        </Grommet>
    )
}
import {
  Box,
  Text
} from "grommet";
import Iframe from 'react-iframe'
import React from "react";

class FactureVoyage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {

    var iframe = document.getElementById('trusti-iframe');
    var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

    var scriptSource = `
    window.addEventListener('ready', function() {
        var pass = document.getElementById('pass'); 
        pass.value = "abc";
    });
`;
    var script = iframeDocument.createElement("script");
    var source = iframeDocument.createTextNode(scriptSource);
    script.appendChild(source);
    iframeDocument.body.appendChild(script);



  };


  render() {
    return (
      <Box
        style={{ width: "100%" }}
        align="center"
        justify="center"
        alignSelf="center"
        alignContent="center"
        gap="medium"
      >
        <Iframe url="https://form.jotform.com/222752126214043"
          styles={{ width: "100vw", height: "88vh" }}
          id="trusti-iframe"
          display="block"
          position="relative"
          // onMouseOver={() => {
          //   var iframe = document.getElementById('trusti-iframe');
          //   var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
          //   var pass = iframeDocument.getElementById('pass');
          //   console.log(document);
          //   console.log(pass);
          //   if (pass)
          //     pass.value = "abc";
          // }}
        />
      </Box>
    );
  }
}

export default FactureVoyage;

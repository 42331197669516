import { Box, RadioButtonGroup, Text } from 'grommet';
import React, { useState } from 'react'
import {
    CIVILITY,
} from '../../../../types/emprunteur_type';
import CustomInput from '../../../elements/custom_input';
import ToggleButton from '../../../elements/toggle_button/toggle_button';
import { Checkmark } from 'grommet-icons';
import PrimaryButton from '../../../elements/primary_button';
import moment from 'moment';
import DateInput from '../../../elements/date_input';


const initBirthDate = date => {
    if (!date) return undefined;
    return date;
}

const Assure = ({ assure, updateDevis, onSave }) => {

    const [dataChanged, setDataChanged] = useState(false);

    const updateData = (_data) => {
        setDataChanged(true);
        updateDevis(_data);
    }


    const [civility, _setCivility] = useState(assure?.civilite);
    const setCivility = (value) => {
        _setCivility(value);
        assure.civilite = value;
        updateData(assure);
    }

    const [firstname, _setFirstname] = useState(assure?.firstname);
    const setFirstname = (value) => {
        _setFirstname(value);
        assure.firstname = value;
        updateData(assure);
    }

    const [lastname, _setLastname] = useState(assure?.lastname);
    const setLastname = (value) => {
        _setLastname(value);
        assure.lastname = value;
        updateData(assure);
    }

    const [phone, _setPhone] = useState(assure?.phone);
    const setPhone = (value) => {
        _setPhone(value);
        assure.phone = value;
        updateData(assure);
    }

    const [phone_home, _setPhoneHome] = useState(assure?.phone_home);
    const setPhoneHome = (value) => {
        _setPhoneHome(value);
        assure.phone_home = value;
        updateData(assure);
    }

    const [email, _setEmail] = useState(assure?.email);
    const setEmail = (value) => {
        _setEmail(value);
        assure.email = value;
        updateData(assure);
    }

    const [birthDate, _setBirthDate] = useState(initBirthDate(assure?.date_of_birth));
    const setBirthDate = (value) => {
        console.log(value);
        _setBirthDate(value);
        assure.date_of_birth = value;
        updateData(assure);

    }

    const [_setZip] = useState(assure?.zip);
    const setZip = (value) => {
        _setZip(value);
        assure.zip = value;
        updateData(assure);
    }

    const [profession, _setProfession] = useState(assure?.profession);
    const setProfession = (value) => {
        _setProfession(value);
        assure.profession = value;
        updateData(assure);
    }

    const [contractType, _setContractType] = useState(assure?.contract_type);
    const setContractType = (value) => {
        _setContractType(value);
        assure.contract_type = value;
        updateData(assure);
    }

    const [professionSeniorityDate, _setProfessionSeniorityDate] = useState(assure?.profession_seniority_date);
    const setProfessionSeniorityDate = (value) => {
        _setProfessionSeniorityDate(value);
        assure.profession_seniority_date = value;
        updateData(assure);
    }

    const [employerName, _setEmployerName] = useState(assure?.employer_name);
    const setEmployerName = (value) => {
        _setEmployerName(value);
        assure.employer_name = value;
        updateData(assure);
    }

    const [employerPhone, _setEmployerPhone] = useState(assure?.employer_phone);
    const setEmployerPhone = (value) => {
        _setEmployerPhone(value);
        assure.employer_phone = value;
        updateData(assure);
    }

    const [employerStatus, _setEmployerStatus] = useState(assure?.employer_status);
    const setEmployerStatus = (value) => {
        _setEmployerStatus(value);
        assure.employer_status = value;
        updateData(assure);
    }

    const [revenueSalary, _setRevenueSalary] = useState(assure?.revenue_salary);
    const setRevenueSalary = (value) => {
        _setRevenueSalary(value);
        assure.revenue_salary = value;
        updateData(assure);
    }

    const [revenueCaf, _setRevenueCaf] = useState(assure?.revenue_caf);
    const setRevenueCaf = (value) => {
        _setRevenueCaf(value);
        assure.revenue_caf = value;
        updateData(assure);
    }

    const [revenueFoncier, _setRevenueFoncier] = useState(assure?.revenue_foncier);
    const setRevenueFoncier = (value) => {
        _setRevenueFoncier(value);
        assure.revenue_foncier = value;
        updateData(assure);
    }

    const [revenuePension, _setRevenuePension] = useState(assure?.revenue_pension);
    const setRevenuePension = (value) => {
        _setRevenuePension(value);
        assure.revenue_pension = value;
        updateData(assure);
    }

    const [revenueOther, _setRevenueOther] = useState(assure?.revenue_other);
    const setRevenueOther = (value) => {
        _setRevenueOther(value);
        assure.revenue_other = value;
        updateData(assure);
    }

    const [taxOnRevenue, _setTaxOnRevenue] = useState(assure?.tax_on_revenue);
    const setTaxOnRevenue = (value) => {
        _setTaxOnRevenue(value);
        assure.tax_on_revenue = value;
        updateData(assure);
    }

    const [birthName, _setBirthName] = useState(assure?.birth_name);
    const setBirthName = (value) => {
        _setBirthName(value);
        assure.birth_name = value;
        updateData(assure);
    }

    const [nationality, _setNationality] = useState(assure?.nationality);
    const setNationality = (value) => {
        _setNationality(value);
        assure.nationality = value;
        updateData(assure);
    }

    const [birthCountry, _setBirthCountry] = useState(assure?.birth_country);
    const setBirthCountry = (value) => {
        _setBirthCountry(value);
        assure.birth_country = value;
        updateData(assure);
    }

    const [birthCity, _setBirthCity] = useState(assure?.birth_city);
    const setBirthCity = (value) => {
        _setBirthCity(value);
        assure.birth_city = value;
        updateData(assure);
    }

    const SectionHeader = ({ title, color, round }) => {
        return (
            <Box fill gap="small" pad='xxsmall' background={color || 'brand'} style={round ? { borderRadius: 12 } : {}}>
                <Text color='white' style={{ fontWeight: "bold", marginLeft: 20 }}>
                    {title}
                </Text>
            </Box>
        )
    }

    const submitModification = () => {
        setDataChanged(false);
        onSave({ skipValidation: true });
    }

    const getAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        if(birthDate){
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return `${age} ans`;
        }else{
            return '--'
        }
        
    }

    return (
        <Box fill background={"white"}>
            {dataChanged && <Box fill background={'yellow'} justify="end" direction="row" pad='xxsmall'>
                <PrimaryButton background="transparent" icon={<Checkmark color="green" />} reverse onClick={submitModification} label={'Valider'} color='green'></PrimaryButton>
            </Box>}
            <SectionHeader title={"1) Etat Civil"} />
            <Box direction='row' >
                <Box pad={'small'} fill gap='xsmall'>
                    <Box direction='row' fill align='center' justify='between'>
                        <Text >Civilité</Text>
                        <ToggleButton
                            groups={[
                                {
                                    label: CIVILITY.madame,
                                    selected: civility === CIVILITY.madame,
                                    onClick: () => setCivility(CIVILITY.madame)
                                },
                                {
                                    label: CIVILITY.monsieur,
                                    selected: civility === CIVILITY.monsieur,
                                    onClick: () => setCivility(CIVILITY.monsieur)
                                },
                            ]}
                        />
                    </Box>
                    <Box direction='row' fill align='center' justify='between'>
                        <Box fill><Text  >Nom</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={lastname}
                                onChange={event => setLastname(event.target.value)}
                            /></Box>
                    </Box>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box fill><Text  >Prénom</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={firstname}
                                onChange={event => setFirstname(event.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box fill><Text  >Nom de naissance</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={birthName}
                                onChange={(ev) => setBirthName(ev.target.value)}
                            />
                        </Box>
                    </Box>
                    <Box direction='row' fill align='center' justify='between'>
                        <Box fill><Text >Nationalité</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={nationality}
                                onChange={(ev) => setNationality(ev.target.value)}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box pad={'small'} style={{ width: 200 }}></Box>
                <Box pad={'small'} fill gap='xsmall'>
                    <Box direction='row' fill align='center' justify='between'>
                        <Box fill><Text  >Date de naissance</Text></Box>
                        <DateInput size='small' value={birthDate} onChange={_date => setBirthDate(_date)} style={{ background: '#EDEDED' }}
                            calendarProps={{
                                locale: "fr-FR",
                                size: "small",
                            }}
                        />
                    </Box>
                    <Box direction='row' fill align='center' justify='between'>
                        <Box fill><Text  >Age</Text></Box>
                        {birthDate && <Box fill><Text  textAlign='end'><b>{getAge(birthDate)}</b></Text></Box>}
                    </Box>
                    <Box direction='row' fill align='center' justify='between'>
                        <Box fill><Text  >Ville de naissance</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={birthCity}
                                onChange={(ev) => setBirthCity(ev.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box fill><Text  >Pays de naissance</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={birthCountry}
                                onChange={(ev) => setBirthCountry(ev.target.value)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <SectionHeader title={"2) Coordonnées"} />
            <Box direction='row'>
                <Box pad={'small'} fill gap='xsmall'>
                    <Box direction='row' fill align='center' justify='between'>
                        <Box fill><Text  >Tél portable</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={phone}
                                onChange={event => setPhone(event.target.value)}
                            />
                        </Box>
                    </Box>
                    <Box direction='row' fill align='center' justify='between'>
                        <Box fill><Text  >Tél domicile</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={phone_home}
                                onChange={event => setPhoneHome(event.target.value)}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box pad={'small'} style={{ width: 200 }}></Box>
                <Box pad={'small'} fill gap='xsmall'>
                    <Box direction='row' fill align='center' justify='between'>
                        <Box fill><Text  >Email</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <SectionHeader title={"3) Situation professionnel"} />
            <Box direction='row'>
                <Box pad={'small'} fill gap='xsmall'>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box width={'small'}><Text  >Profession : </Text></Box>
                        <Box fill>
                            <CustomInput
                                value={profession}
                                onChange={event => setProfession(event.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box width={'small'}><Text  >Contrat de « type » :  </Text></Box>
                        <Box fill>
                            <RadioButtonGroup
                                direction='row'
                                name="radio"
                                options={[{ "label": "CDI", "value": "CDI" }, { "label": "CDD", "value": "CDD" }, { "label": "TNS", "value": "TNS" }, { "label": "Retraité", "value": "Retraité" }, { "label": "Autres", "value": "Autres" }]}
                                value={contractType}
                                onChange={(event) => setContractType(event.value)}
                            />
                        </Box>
                    </Box>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box width={'small'}><Text  >Date ancienneté emploi : </Text></Box>
                        <Box fill>
                            <CustomInput
                                value={professionSeniorityDate}
                                onChange={event => setProfessionSeniorityDate(event.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box width={'small'}><Text  >Nom/adresse employeur :</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={employerName}
                                onChange={event => setEmployerName(event.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box width={'small'}><Text  >Tél employeur : </Text></Box>
                        <Box fill>
                            <CustomInput
                                value={employerPhone}
                                onChange={event => setEmployerPhone(event.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box width={'medium'}><Text  >Société en cours ou non radiée ? Autre ?</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={employerStatus}
                                onChange={event => setEmployerStatus(event.target.value)}
                            />
                        </Box>
                    </Box>

                </Box>
            </Box>

            <SectionHeader title={"4) Revenue"} />
            <Box direction='row'>
                <Box pad={'small'} fill gap='xsmall'>
                    <Box direction='row' fill align='center' justify='between'>
                        <Box width={'small'}><Text  >Salaire, retraite, pension … </Text></Box>
                        <Box fill>
                            <CustomInput
                                value={revenueSalary}
                                onChange={event => setRevenueSalary(event.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box width={'small'}><Text  >CAF</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={revenueCaf}
                                onChange={event => setRevenueCaf(event.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box width={'small'}><Text  >Revenus fonciers</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={revenueFoncier}
                                onChange={event => setRevenueFoncier(event.target.value)}
                            />
                        </Box>
                    </Box>

                </Box>

                <Box pad={'small'} style={{ width: 200 }}></Box>

                <Box pad={'small'} fill gap='xsmall'>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box width={'small'}><Text  >Pension alimentaire reçue</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={revenuePension}
                                onChange={event => setRevenuePension(event.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box width={'small'}><Text  >Autres revenus</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={revenueOther}
                                onChange={event => setRevenueOther(event.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box direction='row' fill align='center' justify='between'>
                        <Box width={'small'}><Text  >Imposable sur les revenus</Text></Box>
                        <Box fill>
                            <CustomInput
                                value={taxOnRevenue}
                                onChange={event => setTaxOnRevenue(event.target.value)}
                            />
                        </Box>
                    </Box>


                </Box>
            </Box>

        </Box>
    )
}

export default Assure;
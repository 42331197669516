import { Box, Text } from "grommet";
import React from "react";

import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import service from "../../../services/assurance_vie_service";
import { getCommissionTauxConfig } from "../../../services/dashboard";
import { getSorted } from "../../../services/utils";
import AddDevis from "../../elements/add_devis";
import DateSort from "../../elements/date_sort";
import Refresh from "../../elements/refresh";
import SearchComponent from "../../elements/search";
import StatusFilter from '../../elements/status_filter';
import AssuranceVie from "./assurance-vie/index";
import ViewTable from "../../elements/view_table";
const storageService = require("../../../services/storage_service");

class AssuranceVies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: this.props.contact || {},
      assurance_vies: [],
      popupOpen: false,
      selectedAssuree: {},
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined,
      },
      loading: false,
    };
  }

  componentDidMount = () => {
    this.loadAssuranceVies();
  };

  isAdmin = () => {
    if (
      storageService.hasAdministrativeRole() ||
      storageService.hasCreditManagerRole() ||
      storageService.hasClassiqueManagerRole()
    ) {
      return true;
    }
    return false;
  };

  loadAssuranceVies = () => {
    this.setState({ assurance_vies: [], loading: true }, () => {
      service
        .getAssuranceVies(
          this.state.contact.id,
          this.state.filter._query,
          this.state.filter.status
        )
        .then((res) => {
          if (this.isAdmin()) {
            this.setState({ assurance_vies: res.data, loading: false });
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
          this.setState({ loading: false });
        });
      getCommissionTauxConfig().then((res) => {
        this.setState({ commission_taux: res.data });
      });
    });
  };

  onClose = () => {
    this.setState({ popupOpen: false });
  };

  openDeal = (assure) => {
    this.setState({ selectedAssuree: assure, popupOpen: true });
  };

  addDeal = () => this.openDeal({ conducteur_principal: {} });

  search = (query, status) => {
    this.setState({ loading: true });
    service
      .getAssuranceVies(this.state.contact.id, query, status)
      .then((res) => {
        if (res.data?.results) {
            this.setState({
                assurance_vies: res.data?.results,
                loading: false,
              });
        } else if (res.data) {
            this.setState({ assurance_vies: res.data, loading: false });
        }
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ loading: false });
        this.props.showMessage("Erreur inattendu");
      });
  };

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  };

  render() {
    return this.state.popupOpen ? (
      <AssuranceVie
        showMessage={this.props.showMessage}
        devis={this.state.selectedAssuree}
        onClose={this.onClose}
        reload={this.loadAssuranceVies}
      />
    ) : (
      <BlockUi tag="div" blocking={this.state.loading}>
        <Box
          style={{ width: "100%" }}
          align="center"
          justify="center"
          alignSelf="center"
          alignContent="center"
          gap="medium"
        >
          <Box
            width={"full"}
            style={{ height: 40 }}
            justify="center"
            align="center"
            background={"#F8F8F8"}
          >
            <Box
              width={"full"}
              style={{ height: 40 }}
              justify="center"
              align="center"
              direction="row"
              gap="large"
            >
              <SubItem
                label="Assurance de vie"
                isActive={true}
              />
            </Box>
          </Box>
          <Box
            width="full"
            gap="medium"
            pad={"small"}
            align="center"
            justify="center"
          >
            <Box width="xlarge" gap="medium" align="center" justify="center">
              {!this.state.contact.id && (
                <>
                  <Box
                    justify="between"
                    direction="row"
                    alignSelf="center"
                    align="center"
                    gap="medium"
                  >
                    <Refresh refresh={this.loadAssuranceVies} />
                    <SearchComponent
                      search={(value) => {
                        this.search(
                          value,
                          this.state.filter.status
                        );
                      }}
                      query={this.state.filter._query}
                      updateQuery={(value) =>
                        this.updateFilter("_query", value)
                      }
                    />
                    <AddDevis onAdd={this.addDeal} />
                  </Box>
                  <Box
                    justify="between"
                    direction="row"
                    align="center"
                    gap="medium"
                    alignSelf="center"
                  >
                    <StatusFilter
                      status={this.state.filter.status}
                      search={value => {
                        this.updateFilter('status', value);
                        this.search(this.state.filter._query, value);
                      }}
                    />
                    <DateSort
                      onChange={(value) => this.updateFilter("sort", value)}
                    />
                  </Box>
                </>
              )}
            </Box>
            {this.state.contact.id && <Box style={{ height: 30 }} />}
            <ViewTable
              reports={getSorted(
                this.state.assurance_vies,
                this.state.filter.sort
              )}
              commission_taux={this.state.commission_taux}
              onDevisSelected={this.openDeal}
              commerciaux={this.props.commerciaux}
            />
          </Box>
        </Box>
      </BlockUi>
    );
  }
}

const SubItem = ({ label, onClick, isActive }) => (
  <Box onClick={onClick} style={{ boxShadow: "none" }}>
    <Text
      textAlign="center"
      size="small"
      color={"#707070"}
      style={{
        fontWeight: isActive ? "bold" : "normal",
        textDecoration: isActive ? "underline" : "none",
      }}
    >
      {label}
    </Text>
  </Box>
);
export default AssuranceVies;

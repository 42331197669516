import { Box, Card } from 'grommet';
import { DocumentDownload, DocumentText, DocumentVerified, Edit, Send, Update } from 'grommet-icons';
import React from "react";
import { convertToDealPossible } from '../../../../services/common_service';
import { CONTRAT_CLOTURE, CONTRAT_ENVOYE, DEAL_POSSIBLE, DEVIS_VALIDE, DEAL_POSSIBLE_REMPLI, DEVIS, PROSPECT } from '../../../../services/devis_contrat_status';
import { getSimpleDateFR } from '../../../../services/utils';
import EntrepriseRecap from '../../../contacts/entreprises/entreprise/recap';
import ApporteurGestionnaire from '../../../elements/apporteur_gestionaire';
import ApporteurModification from '../../../elements/apporteur_gestionaire/modification';
import DateRecap from '../../../elements/date_recap';
import DealStatusChange from '../../../elements/deal_status_change';
import EmailEventTracker from '../../../elements/email_tracker';
import { IdDisplay } from '../../../elements/line_container';
import PrimaryButton from '../../../elements/primary_button';
import StandardText from '../../../elements/standard_text';
import SectionDivider from '../../../voyages/components/session_devider';

const Recap = ({ deal, onEdit, showMessage, toDevis, toContrat, onDealReload, reload, onSendForm }) => {

    return (
        <Box
            style={{ width: "100%" }}
            justify="center"
            alignContent="center"
            align="center"
        >
            <Box
                width={'large'}
                justify="center"
                alignContent="center"
                align="center"
                gap="medium"
            >
                <IdDisplay id={deal.id} />
                <Box gap="small" width={'full'}>
                    <DateRecap deal={deal} />
                </Box>
                <EntrepriseRecap
                    entreprise={deal.entreprise || {}}
                    action={false}
                />

                <DealRecap
                    deal={deal}
                />

            </Box>
            {(deal.apporteur || deal.affiliation) && <Card pad={"small"} width={'xlarge'} >
                <ApporteurGestionnaire apporteur={deal.apporteur} affiliation={deal.affiliation} source={deal.source} />
            </Card>}
            <Box width={'xlarge'}>
                <EmailEventTracker messageIds={deal.emailEventIds} />
            </Box>
            <ApporteurModification
                devis={deal}
                apporteur={deal.apporteur}
                showMessage={showMessage}
                onReload={reload}
            />
            <Box direction='row' gap='medium' pad={'medium'}>
                <PrimaryButton icon={<Edit />} label='Modifier' onClick={onEdit} />
                {[DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS].includes(deal.status) &&
                    <PrimaryButton icon={<Send />} label='Envoyer formulaire' background={'#70A4EC'} onClick={onSendForm} />
                }
                {[DEAL_POSSIBLE, DEAL_POSSIBLE_REMPLI, DEVIS, DEVIS_VALIDE].includes(deal.status) &&
                    <PrimaryButton icon={<DocumentText />} label='Envoyer devis' background={'#70A4EC'} onClick={toDevis} />
                }
                {[DEVIS_VALIDE, CONTRAT_ENVOYE, CONTRAT_CLOTURE].includes(deal.status) &&
                    <PrimaryButton
                        label={`Envoyer un contrat`}
                        icon={<DocumentVerified />}
                        background="#64AEF6"
                        onClick={toContrat}
                    />
                }
                {deal.status === PROSPECT &&
                    <PrimaryButton
                        icon={<Update />}
                        onClick={() => {
                            deal.status = DEAL_POSSIBLE;
                            convertToDealPossible(deal, 'pro-pno')
                                .then(res => onDealReload(res.data));
                        }}
                        label="Convertir à Deal possible"
                    />
                }
                <DealStatusChange
                    deal={deal}
                    showMessage={showMessage}
                    onReload={reload}
                    path={'pro-pno'}
                />
            </Box>
        </Box>
    )
}

const DealRecap = ({ deal }) => {

    return (
        <Box
            width={'large'}
            justify="center"
            alignContent="center"
            align="center"
            gap="medium"
            pad={'xsmall'}
        >
            <Box >
                <SectionDivider title={'Description de vos locaux'} />
            </Box>
            <Box gap="xsmall" align="center" width={'full'} pad='xsmall'>

                <LineDisplay
                    label={"Adresse"}
                    value={`${deal.address}, ${deal.zip}, ${deal.city}`}
                />
                {(deal.lots || [{}]).map((lot, i) => (
                    <Box gap="xsmall" align="center" width={'full'} pad='xsmall'>
                        <StandardText size={'small'} label={`Lot N° ${i + 1}`} />
                        <LineDisplay
                            label={"Superficie des locaux"}
                            value={`${lot.surface_local}`}
                        />
                        <LineDisplay
                            label={"Etage"}
                            value={`${lot.etage}`}
                        />
                        <LineDisplay
                            label={"Précision de l’activité"}
                            value={`${lot.precision_activite}`}
                        />
                    </Box>
                ))}
                <Box gap="xxsmall" align="center" width={'full'} pad='xsmall'>
                    <LineDisplay label={"Les bâtiments sont-ils construits et couverts en matériaux dur pour plus de 75%"}
                        value={deal.materiaux_dur}
                    />
                    <LineDisplay label={"Les lots sont-ils occupés"}
                        value={deal.lots_occupes}
                    />
                    <LineDisplay label={"Les bâtiments sont-ils en cours de réhabilitation"}
                        value={deal.encours_rehabilitation}
                    />
                    <LineDisplay label={"Les bâtiments sont-ils soumis à un arrêté de péril"}
                        value={deal.arrete_peril}
                    />
                    <LineDisplay label={"Les bâtiments sont-ils un château, un manoir, un risque classé ou répertorié"}
                        value={deal.risque_classe}
                    />
                    <LineDisplay label={"Les lots sont-ils occupés par une activité aggravante ?"}
                        value={deal.occupe_aggravant}
                    />
                </Box>
            </Box>
            <Box >
                <SectionDivider title={'Antécédents du site'} />
            </Box>
            <Box gap="xsmall" align="center" width={'full'} pad='xsmall'>
                <LineDisplay
                    label={"Votre précédent contrat a-t-il été résilié par le précédent assureur"}
                    value={deal.resilie_assureur}
                />
                {deal.resilie_assureur === 'Oui' &&
                    <LineDisplay
                        label={"Motif de résiliation"}
                        value={deal.motif_resiliation}
                    />
                }
                <LineDisplay
                    label={"L’ensemble des canalisations équipées de clapets anti-retours"}
                    value={deal.canalisations_equipees}
                />
                <LineDisplay
                    label={"Tableau électrique et compteur installés à plus de 1,20 m"}
                    value={deal.compteur_installe_plus_12}
                />
                <LineDisplay
                    label={"Equipements collectifs (chauffage, téléphone, climatisation) protégés de l’eau"}
                    value={deal.equipements_proteges}
                />
                <LineDisplay
                    label={"Dispositif de protection des sous-sols et RDC"}
                    value={deal.protection_sous_sol}
                />
            </Box>
            <Box >
                <SectionDivider title={'Les capitaux assurés & Engagement'} />
            </Box>
            <Box gap="xsmall" align="center" width={'full'} pad='xsmall'>
                <LineDisplay
                    label={"Valeur du mobilier à usage collectif "}
                    value={`${deal.valeur_mobilier} €`}
                />
            </Box>
            <Box >
                <SectionDivider title={'Franchise'} />
            </Box>
            <Box gap="xsmall" align="center" width={'full'} pad='xsmall'>
                <LineDisplay
                    label={"Franchises dommages"}
                    value={`${deal.franchise_dommage}`}
                />
            </Box>
            <Box >
                <SectionDivider title={'Document'} />
            </Box>
            <Box width="xlarge" direction="row" gap="small" pad='xsmall'>
                <Card style={{ width: "100%" }} pad="medium">
                    {deal.entreprise?.file_kbis && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${deal.entreprise?.file_kbis}`}
                        >
                            <DocumentDownload />
                            KBIS
                        </a>
                    )}
                    {deal.entreprise?.file_statut && (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={`${deal.entreprise?.file_statut}`}
                        >
                            <DocumentDownload />
                            Statut
                        </a>
                    )}
                    {deal.file_others ? (
                        deal.file_others.map((e, _idx) => {
                            return (
                                <a target={"_blank"} key={_idx} rel="noreferrer" href={`${e}`}>
                                    <DocumentDownload />
                                    {e && e.split("/").pop()}
                                </a>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </Card>
            </Box>
        </Box>
    )
}

const LineDisplay = ({ label, value }) => (
    <Box gap="xxsmall" direction='row' width={'full'}>
        <StandardText size={'small'} label={label + ' : '} />
        <StandardText size={'small'} style={{ fontWeight: 'bold' }} label={value} />
    </Box>
)

export default Recap;
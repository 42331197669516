const axios = require('axios');
const storageService = require('../../../services/storage_service');
const { baseAPI } = require('../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const getAppConfig = () => {
    return axios.get(`${baseAPI}/app_configs?_limit=1`, { headers: HEADERS });
}

const getDashboardStats = () => {
    return axios.get(`${baseAPI}/app/dashboard_stats`, { headers: HEADERS });
}

const saveAppConfig = (appConfig) => {
    return axios.put(`${baseAPI}/app_configs/${appConfig.id}`, appConfig, { headers: HEADERS });
}

const deleteAppUsers = (userId) => {
    return axios.delete(`${baseAPI}/users/${userId}`, { headers: HEADERS });
}

const getAppUsers = (params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    console.log('params', params);
    return axios.get(`${baseAPI}/app/users?_limit=-1`, { params, headers: HEADERS });
}

const getAppUsersValidated = (params) => {
    if (!params) {
        params = {};
    }
    console.log('params', params);
    return axios.get(`${baseAPI}/app/users?_limit=-1`, { params, headers: HEADERS });
}

const getAppUsersNotValidated = (params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    console.log('params', params);
    return axios.get(`${baseAPI}/app/users?_limit=-1&app_validated=0`, { params, headers: HEADERS });
}

const getAppUsersTeams = (params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    console.log('params', params);
    return axios.get(`${baseAPI}/app/teams`, { params, headers: HEADERS });
}

const getRemunerations = (_startDate, _endDate, query) => {
    let params = {
        createdAt_gte: _startDate,
        createdAt_lte: _endDate
    };
    if (query) {
        params["_q"] = query;
    }
    return axios.get(`${baseAPI}/remunerations?_limit=-1`, { params, headers: HEADERS });
}

const getRequestPayOut = (params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    return axios.get(`${baseAPI}/remunerations?type=PayOut&_limit=-1`, { params, headers: HEADERS });
}

const putRequestPayOut = (id, params) => {
    return axios.put(`${baseAPI}/remunerations/${id}`, params, { headers: HEADERS });
}

const putPayOutPaid = (params) => {
    return axios.post(`${baseAPI}/remunerations/paid`, params, { headers: HEADERS });
}

const getAffilieByUser = (userid, params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    return axios.get(`${baseAPI}/affilies-invitations?invited_by=${userid}&_sort=createdAt:desc`, { params, headers: HEADERS });
}

const getAffilieByApporteur = (apporteurId, params) => {
    if (!params) {
        params = {};
    } else {
        params = { _q: params };
    }
    return axios.get(`${baseAPI}/affilies-invitations?apporteur_pro=${apporteurId}&_sort=createdAt:desc`,
        params, {
            headers: {
                'Authorization': `Bearer ${storageService.getToken()}`
            }
    });
}

const createApporteurUsers = (params) => {
    return axios.post(`${baseAPI}/public/apporteurs/create_users`, params, { headers: HEADERS });
}

const validateEmailsInvitation = (params) => {
    return axios.post(`${baseAPI}/public/apporteurs/validate_emails`, params, { headers: HEADERS });
}

const createAffilieInvitation = (params) => {
    return axios.post(`${baseAPI}/affilies-invitations`, params, { headers: HEADERS });
}

const saveAppUser = (params) => {
    console.log('params', params);
    return axios.put(`${baseAPI}/app/users`, params, { headers: HEADERS });
}

const getAbonnementByUser = (userid, params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    return axios.get(`${baseAPI}/prepayments?objectId=${userid}&objectType=app_abonnement&_sort=createdAt:desc`, { params, headers: HEADERS });
}

const getRemunerationByUser = (userid, params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    return axios.get(`${baseAPI}/remunerations?_limit=-1&user=${userid}&type=PayIn&_sort=createdAt:desc`, { params, headers: HEADERS });
}

const getLeadImmobiliers = (params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    console.log('params', params);
    return axios.get(`${baseAPI}/mise-en-relations?_where[_or][0][produit]=Lead Mandat Immobilier&_where[_or][1][produit]=Recommandation Immo&_sort=createdAt:desc`, { params, headers: HEADERS });
}

const updateApporteurTransaction = (devis, apporteur) => {
    return axios.post(`${baseAPI}/apporteur-change`, { devis, apporteur }, { headers: HEADERS });
}

const resendEmailInscription = (id) => {
    return axios.post(`${baseAPI}/apporteur-pros/resend_inscription`, { id }, { headers: HEADERS });
}
const createAccountAdmin = (id) => {
    return axios.post(`${baseAPI}/apporteur-pros/create_admin`, { id }, { headers: HEADERS });
}
const resetPasswordPartenaire = (id) => {
    return axios.post(`${baseAPI}/apporteur-pros/reset_password`, { id }, { headers: HEADERS });
}

const getPushNotifications = (_startDate, _endDate, params = {}) => {
    return axios.get(`${baseAPI}/bot-push-notifications?_sort=createdAt:desc`, { params, headers: HEADERS });
}

const createPushNotifications = (params) => {
    return axios.post(`${baseAPI}/bot-push-notifications`, params, { headers: HEADERS });
}
const deletePushNotification = (id) => {
    return axios.delete(`${baseAPI}/bot-push-notifications/${id}`, { headers: HEADERS });
}


module.exports = {
    getAppConfig,
    saveAppConfig,
    getDashboardStats,
    getAppUsers,
    getRemunerations,
    getAppUsersNotValidated,
    getAppUsersValidated,
    getAppUsersTeams,
    getAffilieByUser,
    saveAppUser,
    getAbonnementByUser,
    getRemunerationByUser,
    getRequestPayOut,
    getLeadImmobiliers,
    putRequestPayOut,
    createAffilieInvitation,
    updateApporteurTransaction,
    resendEmailInscription,
    resetPasswordPartenaire,
    createAccountAdmin,
    getPushNotifications,
    createPushNotifications,
    deletePushNotification,
    putPayOutPaid,
    createApporteurUsers,
    getAffilieByApporteur,
    deleteAppUsers,
    validateEmailsInvitation
}
import { saveAs } from "file-saver";
import {
  Accordion,
  AccordionPanel,
  Box,
  Card,
  FileInput,
  Stack,
  Text,
} from "grommet";
import {
  Checkmark,
  CloudUpload,
  DocumentPdf,
  Download,
  DownloadOption,
  Trash,
  View,
} from "grommet-icons";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import React, { useState } from "react";
import { getCreditsById } from "../../../../services/credit_service";
import { uploadFilesMulti } from "../../../../services/storage_service";
import { getSimpleDateFR, toCurrency, toCurrencyWithZero } from "../../../../services/utils";
import ContactButtonPopup from "../../../contacts/contact_modal/button_popup";
import { getCommercialCommission, getCommission, getPrimeTTC, getTotalTT, getTotalTT_HT } from "../../../elements/contract_details";
import CustomInput from "../../../elements/custom_input";
import DateRecap from "../../../elements/date_recap";
import RecapStatus from "../../../elements/devis_status";
import EmailEventTracker from "../../../elements/email_tracker";
import PanelAppel, { PanelAppelV2 } from "../../../elements/panel_appel";
import PrimaryButton from "../../../elements/primary_button";
import CustomSelect from "../../../elements/select_fill";
import DealInfo from "./deal-info";
import QuestionsLegales from "./deal-info/question-legales";
import CreditStatusDateAppel from "./status_date_appel";

const Recap = ({
  devis,
  onSave,
  updateDevis,
  showMessage,
  needToSave,
  setIsLoading,
  reload,
  children,
}) => {
  const [activeIndex, setActiveIndex] = useState([0, 1, 2, 7]);
  console.log(devis);
  console.log("activeIndex = ", activeIndex);

  const onSaveData = (_options) => {
    updateDevis("need_generate_appel", 0);
    onSave(_options);
  };

  return (
    <Box gap="medium" align="center" alignSelf="center" pad="small" fill>
      {needToSave && (
        <Box
          fill
          background={"yellow"}
          justify="end"
          direction="row"
          pad="xxsmall"
        >
          <PrimaryButton
            background="transparent"
            icon={<Checkmark color="green" />}
            reverse
            onClick={onSaveData}
            label={"Valider"}
            color="green"
          ></PrimaryButton>
        </Box>
      )}
      <Card
        background={"white"}
        direction={"row"}
        justify="between"
        alignContent="start"
        align="start"
        pad="small"
        style={{ width: "100%" }}
      >
        <Box alignContent="start" align="start">
          <Text weight={"bold"} size="medium">
            Assurance de vie
          </Text>
          <Text size="small">{devis.sub_product}</Text>
        </Box>
        <Box alignContent="start" align="start">
          <DateRecap deal={devis} />
          <Text textAlign="center">
            <b>ID:</b> {devis.id}
          </Text>
        </Box>
        <Box direction="row" alignContent="center" align="center">
          <Text textAlign="center">Statut: </Text>
          <RecapStatus status={devis.status} />
        </Box>
      </Card>

      <Accordion
        multiple
        fill
        activeIndex={activeIndex}
        gap="small"
        onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}
      >
        <AccordionPanel
          header={renderPanelHeader(
            "Suivi Prospection",
            activeIndex.includes(0)
          )}
        >
          <Box gap="small" pad="small" background={"white"}>
            <PanelAppelV2
              devis={devis}
              updateDevis={updateDevis}
              onSave={onSave}
              showMessage={showMessage}
            />
            <CreditStatusDateAppel devis={devis} />
          </Box>
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader(
            "Informations client",
            activeIndex.includes(1)
          )}
        >
          <Box gap="small" pad="small" background={"white"}>
            <ClientInfo
              devis={devis}
              updateDevis={updateDevis}
              reload={() => {
                getCreditsById(devis.id).then((res) => {
                  reload(res.data);
                });
              }}
              showMessage={showMessage}
              size={"small"}
            />
          </Box>
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader(
            "Informations de la vente",
            activeIndex.includes(2)
          )}
        >
          <Box gap="small" background={"white"}>
            <DealInfo devis={devis} updateDevis={updateDevis} onSave={onSave} />
          </Box>
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader(
            "Questions légales",
            activeIndex.includes(3)
          )}
        >
          <Box gap="small" background={"white"}>
            <QuestionsLegales
              devis={devis}
              updateDevis={updateDevis}
              onSave={onSave}
            />
          </Box>
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader(
            "Gestion électronique documentaire (GED)",
            activeIndex.includes(4)
          )}
        >
          <InfoDocuments
            devis={devis}
            updateDevis={updateDevis}
            onSave={onSaveData}
            showMessage={showMessage}
            setIsLoading={setIsLoading}
          />
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader("Comptabilité", activeIndex.includes(5))}
        >
          <Comptabilite devis={devis} />
        </AccordionPanel>

        <AccordionPanel
          header={renderPanelHeader("Suivi de mail", activeIndex.includes(6))}
        >
          <EmailEventTracker messageIds={devis.emailEventIds} />
        </AccordionPanel>
      </Accordion>

      {children}
    </Box>
  );
};

const renderPanelHeader = (title, active) => (
  <Box
    fill
    direction="row"
    justify="between"
    pad="xsmall"
    background={"#CECECE"}
    style={{
      paddingLeft: 20,
      paddingRight: 20,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    }}
  >
    <strong>
      <Text>{title}</Text>
    </strong>
    <Text>{active ? "Fermez ce volet  ►" : "Ouvriez ce volet  ▼"}</Text>
  </Box>
);

const ClientInfo = ({ devis, updateDevis, reload, showMessage }) => {
  let assureInfo = devis.client || {};
  return (
    <Box gap="small" direction="row">
      <Box gap="small" fill>
        <Line
          textAlign="start"
          leftLabel="Civilité"
          rightLabel={assureInfo.civilite || assureInfo.civility}
        />
        {assureInfo.civilite !== "Société" && (
          <Line
            textAlign="start"
            leftLabel="Prénom"
            rightLabel={assureInfo.firstname}
          />
        )}
        {assureInfo.civilite !== "Société" && (
          <Line
            textAlign="start"
            leftLabel="Nom"
            rightLabel={assureInfo.lastname}
          />
        )}
        {assureInfo.civilite === "Société" && (
          <Line
            textAlign="start"
            leftLabel="Raison social"
            rightLabel={assureInfo.raisonSocial}
          />
        )}
        <Line
          textAlign="start"
          leftLabel="Mail"
          rightLabel={assureInfo.email}
        />
        <Line
          textAlign="start"
          leftLabel="Téléphone"
          rightLabel={assureInfo.phone}
        />

        <Line
          textAlign="start"
          leftLabel="Date de naissance"
          rightLabel={`${
            assureInfo.date_of_birth
              ? getSimpleDateFR(assureInfo.date_of_birth)
              : getSimpleDateFR(assureInfo.birthDate)
          }`}
        />
      </Box>
      <Box gap="small" fill align="center" direction="column" justify="between">
        <Line
          leftLabel="Adresse postale"
          rightLabel={`${assureInfo.address}, ${assureInfo.zip}, ${assureInfo.city}`}
        />

        <Box style={{ height: 50 }}></Box>

        <ContactButtonPopup
          contact={devis.client}
          updateDevis={(field, value) => {
            let _client = devis.client;
            _client[field] = value;
            updateDevis("client", _client);
          }}
          reload={reload}
          showMessage={showMessage}
        />
      </Box>
    </Box>
  );
};
const Comptabilite = ({ devis, commission_taux }) => {
  const SectionHeader = ({ title, color, round }) => {
    return (
      <Box
        fill
        gap="small"
        pad="xxsmall"
        background={color || "brand"}
        style={round ? { borderRadius: 12 } : {}}
      >
        <Text color="white" style={{ fontWeight: "bold", marginLeft: 20 }}>
          {title}
        </Text>
      </Box>
    );
  };

  return (
    <Box fill background={"white"}>
      <SectionHeader title={"Trustiway"} />
      <Box gap="small" direction="row">
        <Box gap="small" fill>
        <Box gap="small" fill>
          <Box pad="small">
            <Line
              textAlign="start"
              leftLabel="Prime TTC/an"
              rightLabel={toCurrencyWithZero(getPrimeTTC(devis))}
            />
            <Line
              textAlign="start"
              leftLabel="Premier versement"
              rightLabel={toCurrencyWithZero(devis.first_payment)}
            />
            <Line
              textAlign="start"
              leftLabel="Trustiway com"
              rightLabel={toCurrencyWithZero(getCommission(devis))}
            />
            <Line
              textAlign="start"
              leftLabel="Total Trustiway"
              rightLabel={toCurrencyWithZero(getTotalTT(devis))}
            />
            <Line
              textAlign="start"
              leftLabel="Total Trustiway"
              rightLabel={toCurrencyWithZero(getCommercialCommission(devis, commission_taux))}
            />
            <Line
              textAlign="start"
              leftLabel="TT Trustiway HT"
              rightLabel={toCurrencyWithZero(toCurrency(getTotalTT_HT(devis, commission_taux)))}
            />
          </Box>
        </Box>
        </Box>
      </Box>
      <SectionHeader title={"Client"} />
      <Box gap="small" direction="row">
        <Box gap="small" fill>
          <Box pad="small">
            <Line
              textAlign="start"
              leftLabel="Premier versement"
              rightLabel={toCurrencyWithZero(devis.first_payment)}
            />
            <Line
              textAlign="start"
              leftLabel="Versement mensuel"
              rightLabel={toCurrencyWithZero(devis.monthly_payment)}
            />
            <Line
              textAlign="start"
              leftLabel="Date d'effet"
              rightLabel={devis.date_effet ? new Date(devis.date_effet).toLocaleDateString() : ''}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const CustomText = ({ label, textAlign, isBold }) => (
  <Text
    style={{ fontWeight: isBold ? "bold" : "normal" }}
    size="small"
    textAlign={textAlign || "end"}
  >
    {label || ""}
  </Text>
);
const Line = ({ leftLabel, rightLabel, textAlign }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "50%" }}>
      <CustomText label={leftLabel + " :"} textAlign={textAlign || "end"} />
    </Box>
    <Box style={{ width: "50%" }}>
      <CustomText label={rightLabel} textAlign="start" isBold={true} />
    </Box>
  </Box>
);

const SectionHeader = ({ title, color, round, actions }) => {
  return (
    <Box
      fill
      direction="row"
      gap="small"
      pad="xxsmall"
      justify="between"
      background={color || "brand"}
      style={round ? { borderRadius: 12 } : {}}
    >
      <Text color="white" style={{ fontWeight: "bold", marginLeft: 20 }}>
        {title}
      </Text>
      {actions}
    </Box>
  );
};

const InfoDocuments = ({
  devis,
  onSave,
  updateDevis,
  setIsLoading,
  showMessage,
}) => {
  const [dataChanged, setDataChanged] = useState(false);

  const updateAndSave = (field, value) => {
    console.log(value);
    updateDevis(field, value);
    setDataChanged(true);
  };

  const submitModification = () => {
    setDataChanged(false);
    onSave({ skipValidation: true });
  };
  return (
    <Box>
      {dataChanged && (
        <Box
          fill
          background={"yellow"}
          justify="end"
          direction="row"
          pad="xxsmall"
        >
          <PrimaryButton
            background="transparent"
            icon={<Checkmark color="green" />}
            reverse
            onClick={submitModification}
            label={"Valider"}
            color="green"
          ></PrimaryButton>
        </Box>
      )}
      <Box fill background={"white"} direction="column">
        <SectionHeader title={"Document"} />
        <Box fill>
          <Box
            direction="row"
            fill
            background={"#F0F0F0"}
            margin={"small"}
            style={{ borderRadius: 12, paddingLeft: 20 }}
          >
            <Box width={"small"}>
              <Text>Document</Text>
            </Box>
            <Box width={"small"}>
              <Text>Le nom de votre fichier</Text>
            </Box>
          </Box>
        </Box>

        {devis.files_dossier ? (
          devis.files_dossier.map((e, _idx) => {
            return (
              <Box key={`file_${_idx}`} gap="small" justify="between">
                {e && e.file_url && (
                  <Box
                    direction="row"
                    gap="small"
                    overflow="hidden"
                    align="center"
                  >
                    <Box width={"small"} style={{ paddingLeft: 20 }}>
                      <CustomSelect
                        style={{ width: "100%" }}
                        placeholder="Type de document"
                        options={[
                          "Devis",
                          "CG",
                          "IPID"
                        ]}
                        value={e.type}
                        onChange={({ option }) => {
                          let _files = devis["files_dossier"];
                          _files[_idx]["type"] = option;
                          updateAndSave("files_dossier", _files);
                        }}
                      />
                    </Box>
                    <Box width={"medium"}>
                      <CustomInput
                        value={e.name}
                        onChange={(event) => {
                          let _files = devis["files_dossier"];
                          _files[_idx]["name"] = event.target.value;
                          updateAndSave("files_dossier", _files);
                        }}
                      />
                    </Box>

                    <PrimaryButton
                      background="#72ACFF"
                      icon={<View />}
                      onClick={() => {
                        window.open(e.file_url, "_blank");
                      }}
                      label={"Visualiser"}
                    ></PrimaryButton>

                    <PrimaryButton
                      background="#72ACFF"
                      icon={<Download />}
                      onClick={() => {
                        var filename = e.name?.includes(".")
                          ? e.name
                          : `${e.name}.${
                              e.file_url &&
                              e.file_url.split("/").pop().split(".")?.pop()
                            }`;
                        // loading a file and add it in a zip file
                        console.log(e.file_url);
                        console.log(filename);

                        saveAs(e.file_url, filename);
                      }}
                      label={"Télécharger"}
                    ></PrimaryButton>

                    <PrimaryButton
                      onClick={() => {
                        if (
                          window.confirm(
                            "Êtes-vous sûr de vouloir supprimer ce fichier ?"
                          )
                        ) {
                          console.log("remove index = ", _idx);
                          let _files = devis["files_dossier"];
                          _files.splice(_idx, 1);
                          updateDevis("files_dossier", _files);
                          onSave();
                        }
                      }}
                      icon={<Trash />}
                      label={"Supprimer"}
                    ></PrimaryButton>
                  </Box>
                )}
              </Box>
            );
          })
        ) : (
          <></>
        )}
        <Box
          fill
          style={{
            marginBottom: 20,
            marginTop: 30,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Stack fill style={{ background: "#eee" }}>
            <Box fill align="center" justify="center" style={{ height: 60 }}>
              <CloudUpload size="medium" />
            </Box>

            <FileInput
              key={(devis["files_dossier"] || []).length}
              messages={{
                browse: "Déposez ou cliquer pour télécharger vos fichiers",
                dropPrompt: "Déposez ou cliquer pour télécharger vos fichiers",
              }}
              multiple
              maxSize={10}
              onChange={(event, { files }) => {
                setIsLoading(true);
                doUploadFile(
                  event.target.files,
                  async (_data) => {
                    if (_data.length > 0) {
                      let _files = devis["files_dossier"] || [];
                      for (let i = 0; i < _data.length; i++) {
                        const _uploaded = _data[i];
                        _files.push({
                          type: "",
                          file_url: _uploaded.url,
                          name: _uploaded.url.split("/").pop(),
                        });
                      }
                      updateDevis("files_dossier", _files);
                      onSave();
                    }
                    setIsLoading(false);
                  },
                  showMessage
                );
              }}
            />
          </Stack>
          <Box pad={"xsmall"}>
            <Text textAlign="center">
              Pour télécharger vos documents dans la GED, veuillez vous assurer
              que vos fichiers sont au format PDF. Si vos fichiers ne sont pas
              en PDF, convertissez-les facilement en utilisant l'option
              "modifier PDF".
            </Text>
          </Box>
          <Box
            direction="row"
            align="center"
            justify="stretch"
            margin={"small"}
            gap="small"
          >
            <Box fill>
              <PrimaryButton
                disabled={devis.files_dossier?.length > 0 ? false : true}
                background="#72ACFF"
                icon={<DownloadOption />}
                onClick={() => {
                  var zip = new JSZip();
                  var count = 0;
                  var zipFilename = `Dossier-${devis.client?.firstname}-${devis.client?.lastname}-${devis.id}.zip`;
                  var urls = devis.files_dossier;

                  urls.forEach(function (file) {
                    var filename = file.name?.includes(".")
                      ? file.name
                      : `${file.name}.${
                          file.file_url &&
                          file.file_url.split("/").pop().split(".")?.pop()
                        }`;
                    // loading a file and add it in a zip file
                    JSZipUtils.getBinaryContent(
                      file.file_url,
                      function (err, data) {
                        if (err) {
                          throw err; // or handle the error
                        }
                        zip.file(`${file.type}/${filename}`, data, {
                          binary: true,
                        });
                        count++;
                        if (count == urls.length) {
                          zip
                            .generateAsync({ type: "blob" })
                            .then(function (content) {
                              saveAs(content, zipFilename);
                            });
                        }
                      }
                    );
                  });
                }}
                label={"Télécharger tous les documents"}
              ></PrimaryButton>
            </Box>
            <Box width={"small"}>
              <PrimaryButton
                background="#72ACFF"
                icon={<DocumentPdf />}
                onClick={() => {
                  window.open("https://www.ilovepdf.com/fr", "_blank");
                }}
                label={"Modifier PDF"}
              ></PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const doUploadFile = (files, callback, showError) => {
  // for (let i = 0; i < fileList.length; i += 1) {
  //   const file = fileList[i];
  //   console.log(file.name);
  uploadFilesMulti(files)
    .then((result) => {
      console.log("result = ", result.data);
      callback(result.data);
    })
    .catch((err) => {
      showError("Erreur inattendu télécharger.");
      callback([]);
    });
  // }
};

export default Recap;
export {
  InfoDocuments
};


import React from "react";
import { Box, Button, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { getAffilieByApporteur } from "../service";
import AppUserDetail from "./user_detail";
import { getSimpleDateFR, getSorted } from "../../../services/utils";


class AppAffilieByApporteur extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appUsers: [],
      popupOpen: false,
      selectedUser: {},
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    };
  }
  componentDidMount = () => {
    this.loadAppUsers();
  };

  loadAppUsers = () => {
    this.setState({ appUsers: [] }, () => {
      getAffilieByApporteur(this.props.apporteurId)
        .then((res) => {
          this.setState({ appUsers: res.data });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false });
  };

  openUser = (user) => {
    this.setState({ selectedUser: user, popupOpen: true });
  };

  addUser = () => this.openUser({});

  search = (query) => {
    getAffilieByApporteur(this.props.apporteurId, query)
      .then(res => {
        if (res.data?.results) {
          this.setState({ appUsers: res.data?.results })
        } else if (res.data) {
          this.setState({ appUsers: res.data })
        }
      })
      .catch(err => {
        console.log('err', err);
        this.props.showMessage('Erreur inattendu');
      })
  }

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  render() {
    console.log(this.state.appUsers);
    return this.state.popupOpen ? (
      // <Text>App User</Text>
      <AppUserDetail
        showMessage={this.props.showMessage}
        user={this.state.selectedUser}
        onClose={this.onClose}
        reload={this.loadAppUsers}
      />
    ) : (
      <Box gap="small">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>
                <Text size="small"><b>Création</b><br />Modification</Text>
              </TableCell>
              <TableCell>
                <Text><b>Type</b></Text>
              </TableCell>
              <TableCell>
                <Text size="small"><b>Nom</b></Text>
              </TableCell>
              <TableCell>
                <Text size="small"><b>Prénom</b></Text>
              </TableCell>
              <TableCell ><Text size="small"><b>Email</b></Text></TableCell>
              <TableCell ><Text size="small"><b>Accepté / Connecté</b></Text></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHeader>
          {this.state.appUsers.length > 0 && <TableBody>
            {getSorted(this.state.appUsers, this.state.filter.sort).map((appUser, index) => (
              <TableRow key={index + 1}>
                <TableCell>
                  <Text size="small"><strong>{getSimpleDateFR(appUser.createdAt)}</strong><br />{getSimpleDateFR(appUser.updatedAt)}</Text>
                </TableCell>
                <TableCell>
                  <Text>{appUser.apporteur_pro?.user_id === appUser.user_created?.id ? "Administrateur" : "Apporteur"}</Text>
                </TableCell>
                <TableCell>
                  <Text>{appUser.nom}</Text>
                </TableCell>
                <TableCell>
                  <Text>{appUser.prenom}</Text>
                </TableCell>
                <TableCell>
                  <Text>{appUser.email}</Text>
                </TableCell>
                <TableCell>
                  <Text>{appUser.user_created?.need_change ? "❌" : "✅"}</Text>
                </TableCell>
                <TableCell>
                  {appUser.apporteur_pro?.user_id !== appUser.user_created?.id && <Button
                    label="Renvoyer mail inscription"
                    primary
                    onClick={() => this.props.resendEmail(appUser)}
                  />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>}
        </Table>
        {this.state.appUsers.length === 0 && <Box alignContent="center" align="center"><Text size="small">Aucun affilié</Text></Box>}
      </Box>
    );
  }
}

export default AppAffilieByApporteur;

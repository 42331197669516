import { Box, Button, Grid, Paragraph, Text } from "grommet"
import React, { useEffect, useState } from "react";

const QuizSelect = (props) => {
  const [selected, setSelected] = useState(null);

  const selectChoice = (choice) => {
    setSelected(choice);
    props.onChange(choice);
  }

  useEffect(() => {
    if (props.default)
      selectChoice(props.default);
  }, [props.default]);

  return (
    <Box >
      <Text weight={400} size={"14px"} margin="20px 0 10px 0">{props.title}</Text>
      <Box width="50%" gap="small">
        {props.choices.map((choice, index) => (
          <Button
            key={choice}
            label={<Box direction="row">
              <Text size="small">{choice}</Text>
            </Box>}
            onClick={() => selectChoice(choice)}
            primary
            style={{
              opacity: selected === choice ? "1" : "0.5",
              boxShadow: selected === choice ? "0px 3px 10px #00000022" : "none",
              width: props.freeGrid ? "fit-content" : "100%",
              fontSize: "14px",
              padding: "0px 20px",
              fontWeight: 600,
              textAlign: "left",
              borderRadius: "10px",
              margin: props.freeGrid ? "10px 10px 0 0" : "0",
            }}
          />
        ))}
    </Box>
    </Box>
  )
}

export default QuizSelect;
import { Box, Button, Card, Table, TableBody, TableCell, TableHeader, TableRow } from "grommet";
import { Add, CaretDown, CaretUp, Checkmark, Close, Edit, Trash, Validate } from "grommet-icons";
import { Text } from "grommet/components/Text";
import React, { useState } from "react";
import { toCurrency } from "../../../../services/utils";
import CustomInput from "../../../elements/custom_input";
import PrimaryButton from "../../../elements/primary_button";
import CustomSelect from "../../../elements/select_fill";


const products = [
    'Emprunteur',
    'Plan épargne retraite',
    'Crédit',
    'Santé',
    'Prévoyance',
    'Contrats par jour',
    'Mis en relation par mois',
    "Autre"
]

const IndicateurConfig = ({ objectives = [], onObjectivesUpdate, commerciaux, onCommercialSelected }) => {

    const [ordering, setOrdering] = useState(false);
    const [adding, setAdding] = useState(false);

    const onAddProduit = produit => {
        const index = objectives.map(s => s.produit).indexOf(produit.produit);
        if(index >= 0) {
            objectives[index] = produit;
        } else {
            produit.order = objectives.length + 1;
            objectives.push(produit);
        }
        onObjectivesUpdate([...objectives]);
    }

    const onDelete = obj => {
        let index = objectives.indexOf(obj);
        let newObjs = objectives.filter((o, i) => i !== index);
        for (let i = index; i < newObjs.length; i++) {
            newObjs[i].order -= 1;
        }
        onObjectivesUpdate(newObjs);
    }

    const onOrderChange = (obj, val) => {
        let currentOrder = obj.order;
        let swappable = objectives.find(o => o.order === (currentOrder + val));
        if (swappable) {
            obj.order = currentOrder + val;
            swappable.order = swappable.order - val;
            onObjectivesUpdate(objectives);
        }
    }

    return (
        <Box width={'xlarge'} align="center" pad={'small'} gap='medium' background={'#fff'} round={{ size: 'small' }}>
            <Box pad={'small'}>
                <Text style={{ fontWeight: "bold" }} size="large" textAlign="center">
                    Objectifs
                </Text>
            </Box>

            {commerciaux && commerciaux.length &&
                <Box gap='xsmall'>
                    <CustomSelect
                        placeholder={'Commercial'}
                        options={commerciaux}
                        onChange={({ option }) => onCommercialSelected(option)}
                        labelKey={'name'}
                        valueKey={{ key: 'name', reduce: true }}
                    />
                </Box>
            }

            {!adding && !ordering && <Box width={'xlarge'} gap='medium' justify="center" align="center" direction="row">
                <PrimaryButton label={'Ajouter un objectif'}
                    icon={<Add />}
                    onClick={() => {
                        setAdding(true);
                    }}
                />
                {objectives.length > 0 && <PrimaryButton label={'Modifier l\'ordre'}
                    icon={<Edit />}
                    onClick={() => {
                        setOrdering(true);
                    }}
                />
                }
            </Box>
            }
            {adding &&
                <AddObjectif onClose={() => setAdding(false)}
                    onUpdate={obj => {
                        onAddProduit(obj);
                        setAdding(false);
                    }}
                    obj={adding === true ? undefined : adding}
                />
            }
            {ordering &&
                <Card round={{ size: 'small' }} onClick={() => setOrdering(false)} pad='small'>
                    <Close />
                </Card>
            }

            <Box width={'large'} gap='medium' justify="center" align="center">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableCell><Text>Ordre</Text></TableCell>
                            <TableCell><Text>Nom</Text></TableCell>
                            <TableCell><Text>Objectif</Text></TableCell>
                            <TableCell><Text>Objectif chiffré</Text></TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            (objectives.sort((o1, o2) => o1.order > o2.order ? 1 : -1)).map((obj, index) => {
                                let _bgColor = index % 2 === 0 ? "#fff" : "#eee";
                                return (
                                    <TableRow key={index + 1} style={{ background: _bgColor }}>
                                        <TableCell>
                                            <Box justify="center" align="center" direction="row" gap="small">
                                                {ordering && <Box >
                                                    <CaretUp size="small" onClick={() => onOrderChange(obj, -1)} style={{ cursor: 'pointer' }} />
                                                    <CaretDown size="small" onClick={() => onOrderChange(obj, 1)} style={{ cursor: 'pointer' }} />
                                                </Box>
                                                }
                                                <Text size="small" textAlign="center">{obj.order}</Text>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Text size="small" textAlign="center">{obj.produit}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text size="small" textAlign="center">{obj.objectif}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text size="small" textAlign="center">{toCurrency(obj.objectif_chiffre, true)}</Text>
                                        </TableCell>

                                        <TableCell>
                                            <Button icon={<Edit color="#E82C70" />} onClick={() => setAdding(obj)} />
                                        </TableCell>

                                        <TableCell>
                                            <Button icon={<Trash color="#E82C70" />} onClick={() => onDelete(obj)} />
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </Box>

        </Box>
    )
}

const Field = ({ label, value, onChange }) => {
    return (
        <Box width={'full'} gap='small'>
            <Text size="small">{label}</Text>
            <CustomInput onChange={event => onChange(event.target.value)} value={value} size='small' />
        </Box>
    )
}

const LineContainer = ({ first, second, third }) => {
    return (
        <Box direction="row" gap="small" width={'full'}>
            {first && <Box style={{ width: '30%' }} gap='small'>
                {first}
            </Box>}
            {second && <Box style={{ width: '30%' }} gap='small'>
                {second}
            </Box>}
            {third && <Box style={{ width: '30%' }} gap='small'>
                {third}
            </Box>}
        </Box>
    )
}

const AddObjectif = ({ obj, onUpdate, onClose }) => {

    const [config, setConfig] = useState(obj || {});

    const onChange = (field, value) => {
        config[field] = value;
        setConfig(Object.assign({}, config));
    }

    return (
        <Card pad={'large'} round={{ size: 'medium' }} justify="center" gap="medium">
            <LineContainer
                first={
                    <Box width={'full'} gap='small'>
                        <Text size="small">Nom</Text>
                        <CustomSelect
                            options={products}
                            value={config.produit}
                            onChange={({ option }) => onChange('produit', option)}
                        />
                    </Box>
                }
                second={
                    <Field
                        value={config.objectif}
                        onChange={val => onChange('objectif', val)}
                        label='Objectif'
                    />
                }
                third={
                    <Field
                        value={config.objectif_chiffre}
                        onChange={val => onChange('objectif_chiffre', val)}
                        label='Objectif chiffré'
                    />
                }
            />
            <Box justify="center" align="center" gap="medium" direction="row">
                <Card round={{ size: 'small' }} onClick={() => onUpdate(config)} pad='small' background='#E82C70'>
                    <Checkmark />
                </Card>
                <Card round={{ size: 'small' }} onClick={onClose} pad='small'>
                    <Close />
                </Card>
            </Box>
        </Card>
    )
}

export default IndicateurConfig;
import React, { useEffect, useState } from 'react';
import { Box, Text, Button } from "grommet";
import { Mail, Trash, View } from 'grommet-icons';
import { getContactById } from '../../../../../../services/contact_service';

const Interlocuteur = ({ iter, idx, remove, onReceivingMailChange, disabled, onSelect }) => {
    let key = idx + 1;
    let pos = key === 1 ? '1er' : `${key}ème`;
    const [_iter, setIter] = useState(iter);

    useEffect(() => {
        if(typeof iter === 'string') {
            getContactById(iter)
                .then(res => setIter(res.data))
        }
    }, iter)

    return (
        <Box direction="row" gap="small">
            <Box gap="xxsmall">
                <Text size="small">{pos} interlocuteur :</Text>
                <Text size="small" style={{ fontWeight: 'bold' }}>{_iter.firstname} {_iter.lastname?.toUpperCase()}</Text>
                <Text size="small" style={{ fontWeight: 'bold' }}>{_iter.intitule}</Text>
                <Text size="small" style={{ fontWeight: 'bold' }}>{_iter.phone}</Text>
                <Text size="small" style={{ fontWeight: 'bold' }}>{_iter.email}</Text>
                {onSelect && 
                <Box onClick={() => onSelect(_iter)}>
                    <View color='brand'/>
                </Box> 
                }
            </Box>

            <Box gap='small' justify='between'>
                {remove &&
                    <Box round={'full'}>
                        <Button primary icon={<Trash size="small" />} onClick={remove} disabled={disabled}/>
                    </Box>
                }
                {onReceivingMailChange &&
                    <Box round={'full'} overflow="hidden" style={{ background: _iter.receiving_mail ? '#70A4EC' : '#F4F4F4' }}>
                        <Button icon={<Mail size="small" color={_iter.receiving_mail ? '#fff' : '#707070'} />}
                            onClick={onReceivingMailChange}
                            disabled={disabled} />
                    </Box>
                }

            </Box>
        </Box>
    )
}

export default Interlocuteur;
import { Box, Heading, Select } from "grommet";
import React from "react";
import PrimaryButton from "../../../elements/primary_button";
import { Checkmark } from "grommet-icons";
import ContactSelect, { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import Line from "../../../elements/line_container";
import CustomInput from "../../../elements/custom_input";
import ToggleButton from "../../../elements/toggle_button/toggle_button";
import ClickableCards from "../../../elements/clickable_cards";
import chien from "../../../../images/dog.png";
import chat from "../../../../images/chat.png";
import DateInput from "../../../elements/date_input";
import { CHAT_RACES, CHIEN_RACES } from "./races";

const PetInfo = ({ devis, updateDevis, onNext }) => {
  const [races, setRaces] = React.useState(devis.animal_type === 'Chien' ? CHIEN_RACES : CHAT_RACES);
  
  if (!devis.pet_date_of_birth) {
    devis.pet_date_of_birth = new Date();
  }
  return (
    <Box
      gap="small"
      width="xlarge"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Box justify="center" alignSelf="center">
        <Heading
          level={3}
          textAlign="center"
        >{`Informations de l'assuré principal`}</Heading>
      </Box>

      <ContactSelectV2
        contact={devis.client || {}}
        updateContact={updateDevis}
        addressIncluded={true}
        birthDayIncluded={true}
      />
      <Box justify="center" alignSelf="center">
        <Heading
          level={3}
          textAlign="center"
        >{`Information de votre compagnon`}</Heading>
      </Box>
      <Box width="large" gap="medium" margin="medium">
        <Line
          leftLabel="Quel animal à le client"
          rightChildren={
            <ClickableCards
              cards={[
                {
                  img: chien,
                  text: "Chien",
                  selected: devis.animal_type === "Chien" ? "solid" : "none",
                  onClick: () => {
                    updateDevis("animal_type", "Chien");
                    setRaces(CHIEN_RACES);
                  },
                },
                {
                  img: chat,
                  text: "Chat",
                  selected: devis.animal_type === "Chat" ? "solid" : "none",
                  onClick: () => {
                    updateDevis("animal_type", "Chat");
                    setRaces(CHAT_RACES);
                  },
                },
              ]}
            />
          }
        />
        <Line
          leftLabel="Indiquer son genre"
          rightChildren={
            <ToggleButton
              groups={["Mâle", "Femelle"].map((value) => ({
                label: value,
                selected: devis.genre === value,
                onClick: () => updateDevis("genre", value),
              }))}
            />
          }
        />
        {devis.animal_type === "Chien" && <Line
          leftLabel="S’agit-il d’un chien guide d’aveugle ou d’un handichien ?"
          rightChildren={
            <ToggleButton
              groups={[true, false].map((value) => ({
                label: value === true ? "Oui" : "Non",
                selected: devis.handicape === value,
                onClick: () => updateDevis("handicape", value),
              }))}
            />
          }
        />}
        <Line
          leftLabel="Date de naissance"
          rightChildren={
            <DateInput
              value={devis.pet_date_of_birth}
              onChange={(date) => updateDevis("pet_date_of_birth", date)}
            />
          }
        />

        <Line
          leftLabel="Comment s'appelle-t-il"
          rightChildren={
            <CustomInput
              value={devis.name}
              onChange={(event) => updateDevis("name", event.target.value)}
            />
          }
        />
        <Line
          leftLabel="Indiquer sa race"
          rightChildren={
            <Select
              options={races}
              value={devis.race}
              onChange={({ option }) => updateDevis("race", option)}
              onSearch={(text) => {
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );
                const exp = new RegExp(escapedText, "i");
                setRaces(races.filter((o) => exp.test(o)));
              }}
            />
          }
        />
        <Line
          leftLabel="Comment souhaitez-vous protéger votre ami ?"
          rightChildren={
            <ToggleButton
              groups={["En cas d’accident", "En cas d’accident & maladie"].map(
                (value) => ({
                  label: value,
                  selected: devis.assurance_type === value,
                  onClick: () => updateDevis("assurance_type", value),
                })
              )}
            />
          }
        />

        <Line
          leftLabel="Date de résiliation"
          rightChildren={
            <DateInput
              value={devis.date_resiliation}
              onChange={(date) => updateDevis("date_resiliation", date)}
            />
          }
        />
      </Box>

      <Box style={{ height: 40 }} />

      <Box direction="row" align="center" justify="center" gap="medium">
        <PrimaryButton
          label={`Valider`}
          icon={<Checkmark />}
          onClick={onNext}
        />
      </Box>
    </Box>
  );
};

export default PetInfo;

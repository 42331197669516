const axios = require('axios');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = { 
    'Authorization': `Bearer ${storageService.getToken()}`
};


const createBlocGed = (blocnote) => {
    return axios.post(`${baseAPI}/bloc_geds`, blocnote, {headers: HEADERS});
}

const updateBlocGed = (blocnote) => {
    return axios.put(`${baseAPI}/bloc_geds/${blocnote.id}`, blocnote, {headers: HEADERS});
}

const deleteBlocGed = (blocnote) => {
    return axios.delete(`${baseAPI}/bloc_geds/${blocnote.id}`, {headers: HEADERS});
}

const getBlocGeds = (client, contract_id, commercial_id) => {
    let params = client ? {client} : {};
    if(contract_id){
		if(contract_id.includes(',')){
			params.contract_id_in = contract_id.split(',');
		}else{
			params.contract_id = contract_id;
		}
	}
	if(commercial_id){
		params.commercial = commercial_id;
	}
    if(!storageService.hasAdministrativeRole()){
        params["commercial"] = storageService.getUserId();
    }
    return axios.get(`${baseAPI}/bloc_geds`, {params, headers: HEADERS});
}


module.exports = {
    createBlocGed,
    updateBlocGed,
    getBlocGeds,
    deleteBlocGed
}
import { Box, Text, Heading, TextInput } from "grommet";
import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { BIEN_TYPE } from "../../../../services/types";
import ContactSelect, { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import CustomInput from "../../../elements/custom_input";
import DateInput from "../../../elements/date_input";
import Line from "../../../elements/line_container";
import PrimaryButton from "../../../elements/primary_button";

const Info = ({ devis, updateDevis, next }) => {
  const locationAddress = usePlacesWidget({
    apiKey: "AIzaSyBa3n60yKzDhlgz67vh9YUTwHSlrRzaa4g",
    onPlaceSelected: (place) => {
      let parts = place.address_components;
      let address = parts
        .filter(
          (s) =>
            s.types &&
            (s.types.includes("street_number") || s.types.includes("route"))
        )
        .map((s) => s.long_name)
        .join(" ");
      let zip = parts
        .filter((s) => s.types && s.types.includes("postal_code"))
        .map((s) => s.long_name)
        .join("");
      let city = parts
        .filter((s) => s.types && s.types.includes("locality"))
        .map((s) => s.long_name)
        .join("");

      updateDevis("location_address", address);
      updateDevis("location_zip", zip);
      updateDevis("location_city", city);
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "fr" },
    },
  });

  return (
    <Box
      gap="small"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Heading level="2" color="#59A4F4" textAlign="center">
        Assurance PNO Gîte
      </Heading>

      <ContactSelectV2
        contact={devis.client || {}}
        updateContact={updateDevis}
        addressIncluded={true}
      />

      <Box width="large" gap="medium">
        <Box gap="xsmall">
          <Text style={{ fontWeight: "bold" }}>
            Informations de votre bien en location
          </Text>

          <Box style={{ height: 20 }} />
          <Box style={{ width: "100%" }} gap="small">
            <Line
              leftLabel="Adresse de location"
              rightChildren={
                <TextInput
                  size={"small"}
                  ref={locationAddress.ref}
                  style={{
                    background: "#EDEDED",
                    fontSize: 16,
                    borderRadius: 10,
                    border: "none",
                  }}
                  value={devis.location_address}
                  onChange={(event) =>
                    updateDevis("location_address", event.target.value)
                  }
                />
              }
            />
            <Line
              leftLabel="Code postal"
              rightChildren={
                <CustomInput
                  value={devis.location_zip}
                  onChange={(event) =>
                    updateDevis("location_zip", event.target.value)
                  }
                />
              }
            />
            <Line
              leftLabel="Ville"
              rightChildren={
                <CustomInput
                  value={devis.location_city}
                  onChange={(event) =>
                    updateDevis("location_city", event.target.value)
                  }
                />
              }
            />
          </Box>
        </Box>
      </Box>

      <Box
        width="medium"
        align="center"
        alignSelf="center"
        margin="medium"
        gap="medium"
      >
        <PrimaryButton
          label="Suivant"
          background="#59A4F4"
          onClick={() => {
            if (!devis.begin_date) {
              updateDevis("begin_date", new Date());
            }
            if (!devis.bien_type) {
              updateDevis("bien_type", BIEN_TYPE.appartement);
            }
            next();
          }}
        />
      </Box>
    </Box>
  );
};

export default Info;

import { Box, Button, Text } from "grommet";
import React from "react";

export default function RoundedButton({ label, icon, onClick }) {
    if (label) {
        return (
            <Button
                icon={icon}
                onClick={onClick}
                label={<Text
                    style={{
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        lineHeight: '1rem',
                        color: '#64748B'
                    }}
                >{label}</Text>}
            />
        );
    }
    return (
        <Box round="full" background={"brand"}>
            <Button
                hoverIndicator
                icon={icon}
                onClick={onClick}
            />
        </Box>
    );
}
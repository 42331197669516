import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
    Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box, Card, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import { StatusGoodSmall } from "grommet-icons";
import moment from 'moment';
import React from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { Bar } from 'react-chartjs-2';
import { getGestionCollabs } from '../../../services/dashboard';
import { getMonthOptions, toCurrency } from '../../../services/utils';
import CustomPagination from '../../elements/custom_pagination';
import CustomSelect from '../../elements/select_fill';
import { getAgences } from '../agences/service';
import products from '../utils/types';
import { getGraphData } from './service';
import { CircularProgress } from '@material-ui/core';
import { getSubscriptions } from '../souscriptions/service';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const toFRDate = date => {
    if (!date) return date;
    let d = moment(date, 'YYYY-MM-DD');
    return d.isValid() ? d.format('DD/MM/YYYY') : date;
}

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'left',
        },
        datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: value => value ? toCurrency(value) : '',
            font: {
                weight: 'bold'
            }
        }
    }
};
const backgroundColors = [
    "#E52764", "#33BC00", "#FC0000", "#73AAAA", "#73A6EC"
]
class Comptabilite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                startDate: moment().startOf('month').toDate(),
                endDate: new Date(),
                product: undefined,
                commercial_id: undefined,
                startIndex: 0,
                agence: this.props.agence_domain
            },
            commerciaux: [],
            agences: [],
            loading: false,
            data: {},
            agence: this.props.agence_domain
        };
    }

    componentDidMount() {
        this.reload();
        getGestionCollabs()
            .then(res => this.setState({ commerciaux: res.data }))
            .catch(err => console.log(err));
        getAgences()
            .then(res => this.setState({ agences: res.data }));
    }

    reload = () => {
        getGraphData(this.state.filter)
            .then(res => {
                this.setState({ data: res.data });
                this.onNextPage();
            })
             .catch(err => console.log(err));

    }

    onNextPage = () => {
        const filter = {
            ...this.state.filter,
            sort: 'date_de_souscription:desc'
        }
        getSubscriptions(filter)
                .then(res => {
                    this.setState({ data: {...this.state.data, subscriptions: res.data}, loading: false });
                }).catch(err => this.setState({loading: false}))
    }



    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box
                    style={{ width: "100%" }}
                    justify="center"
                    alignContent="center"
                    align="center"
                    pad='medium'
                >
                    <Box pad={'medium'} gap='medium'>
                        <Box direction='row' justify='between' align='center' gap='medium'>
                            <CustomSelect
                                placeholder={'Sélectionner un mois'}
                                options={getMonthOptions().filter(s => s.name !== '12 derniers mois')}
                                labelKey={"name"}
                                valueKey={{ key: "name", reduce: true }}
                                onChange={({ option }) => {
                                    let filter = this.state.filter;
                                    filter.startIndex = 0;
                                    filter.startDate = option.startDate;
                                    filter.endDate = option.endDate;
                                    this.setState({ filter: Object.assign({}, filter) }, () => this.reload());
                                }}
                            />
                            <CustomSelect
                                placeholder={'Produit'}
                                options={products}
                                onChange={({ option }) => {
                                    let filter = this.state.filter;
                                    filter.startIndex = 0;
                                    filter.product = option
                                    this.setState({ filter: Object.assign({}, filter) }, () => this.reload());
                                }}
                            />
                            <CustomSelect
                                options={this.state.commerciaux.map(s => s.name)}
                                placeholder='Commercial'
                                onChange={({ option }) => {
                                    let filter = this.state.filter;
                                    filter.startIndex = 0;
                                    filter.commercial_id = this.state.commerciaux.find(s => s.name === option).id;
                                    console.log(filter);
                                    this.setState({ filter: Object.assign({}, filter) }, () => this.reload());
                                }}
                            />
                            {!this.state.agence && <CustomSelect
                                options={this.state.agences.map(a => a.raisonSocial)}
                                placeholder='Agence'
                                onChange={({ option }) => {
                                    let filter = this.state.filter;
                                    filter.startIndex = 0;
                                    filter.agence = this.state.agences.find(s => s.raisonSocial === option).domainName;
                                    this.setState({ filter: Object.assign({}, filter) }, () => this.reload());
                                }}
                            />}
                        </Box>
                        <Box direction='row' justify='center' align='center' gap='medium'>
                            <Card pad={'small'}>
                                <Text size='small' textAlign='center'>
                                    Total Trustiway du mois <br />
                                    {this.state.data.totalTrustiway !== undefined ? <b>{toCurrency(this.state.data.totalTrustiway)}</b> : <CircularProgress size={20} />}
                                </Text>
                            </Card>
                            <Card pad={'small'}>
                                <Text size='small' textAlign='center'>
                                    Total Prime du mois <br />
                                    {this.state.data.totalPrime !== undefined ? <b>{toCurrency(this.state.data.totalPrime)}</b> : <CircularProgress size={20} />}
                                </Text>
                            </Card>
                        </Box>
                    </Box>

                    {this.state.data.graphData &&
                        <Box width="xlarge" justify="center" align="center" alignSelf="center" margin="medium">
                            <Bar
                                options={options}
                                data={{
                                    labels: this.state.data.graphData.keyLabels,
                                    datasets: this.state.data.graphData.data.map((d, index) => (
                                        {
                                            label: this.state.data.graphData.labels[index],
                                            backgroundColor: backgroundColors[index],
                                            data: d
                                        }
                                    ))
                                }}
                                plugins={[ChartDataLabels]}
                            />
                        </Box>
                    }
                    {this.state.data.subscriptions &&
                        <LineView
                            subscriptions={this.state.data.subscriptions}
                            startIndex={this.state.filter.startIndex}
                            total={this.state.data.total}
                            onNextPage={({ startIndex }) => {
                                let filter = this.state.filter;
                                filter.startIndex = startIndex
                                this.setState({ filter: Object.assign({}, filter) }, () => this.onNextPage());
                            }}
                        />
                    }
                </Box>
            </BlockUi>
        );
    }
}
const LineView = ({ subscriptions = [], total = 0, onNextPage, startIndex = 0 }) => {
    return (
        <Box style={{ width: "100%" }}>
            <CustomPagination total={total} onNextPage={onNextPage} startIndex={startIndex} />
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell>
                            <Text size="xsmall"><b>Etat</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Date de souscription</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Partenaire</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Produit</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Nom</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Prénom</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Compagnie</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Prix loc.</b></Text>
                        </TableCell>
                        <TableCell>
                            <Text size="xsmall"><b>Contrat</b></Text>
                        </TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        subscriptions.map((sub, index) => {
                            let _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
                            return (
                                <TableRow key={index + 1} style={{ backgroundColor: _bgColor }}>
                                    <TableCell>
                                        <StatusPayment status={sub.status_payment} />
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{toFRDate(sub.date_de_souscription)}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{sub.owner?.raisonSocial}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{sub.produit}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{sub.nom_locataire}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{sub.prenom_du_locataire}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{sub.compagnie}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text size="xsmall">{toCurrency(sub.prix_sejour)}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <ContractDetails subscription={sub} />
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </Box>
    )
}

const ContractDetails = ({ subscription }) => {
    return (
        <Box border round={{ size: 'small' }} pad='xsmall'>
            <LineContainer label='Prime TTC' value={toCurrency(subscription.prime_ttc)}
                border borderColor={backgroundColors[0]} />
            <LineContainer label='Prime HT' value={toCurrency(subscription.prime_ht)} />
            <LineContainer label='Taxe' value={toCurrency(subscription.taxe)} />

            <LineContainer label='Part Trustiway' value={toCurrency(subscription.part_trustiway)} top={5} />
            <LineContainer label='Commission' value={toCurrency(subscription.commission)} />
            <LineContainer label='Part Compagnie' value={toCurrency(subscription.part_compagnie)} top={5} />
            <LineContainer label='Part Compagnie' value={toCurrency(subscription.retrocession_client)} bottom={5} />
            <LineContainer label='Total Trustiway' value={toCurrency(subscription.total_trustiway)}
                border borderColor={backgroundColors[1]} />

        </Box>
    )
}

const LineContainer = ({ label, value, border, borderColor, top = 0, bottom }) => (
    <Box border={border} style={{ borderColor: borderColor, paddingTop: top, paddingBottom: bottom, paddingLeft: 5 }} round={{ size: 'xsmall' }}>
        <Text size='xsmall'>{label} : <b>{value}</b></Text>
    </Box>
)

const StatusPayment = ({ status }) => (
    <Box direction="row" gap="xsmall" pad="xsmall" align="center">
        <StatusGoodSmall size="small" color={getStatusColor(status)} />
        <Text size="xsmall">
            <strong>{status === 'En attente de paiement' ? 'En attente' : status || 'Payé'}</strong>
        </Text>
    </Box>
)
const getStatusColor = status => {
    switch (status) {
        case "Payé":
            return "#33BC00";
        case "En attente de paiement":
            return "#FF9D19";
        default:
            return "#33BC00";
    }
}

export default Comptabilite;

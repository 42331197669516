import React from "react";
import { Box, Card, Menu, Text } from "grommet";
import { StatusGoodSmall, Update } from "grommet-icons";
import PrimaryButton from "../../../../../elements/primary_button";
import { updateAgence } from "../../../service";

const AgenceStatus = ({ convention, status }) => (
	
		<Card pad={"small"} justify="end" alignSelf="end" margin={"xsmall"}>
			{status && <Box direction="row" gap="xsmall" pad="xsmall" align="center">
				<StatusGoodSmall color={getStatusColor(status)} />
				<Text size="xsmall">
					<strong>{status}</strong>
				</Text>
			</Box>}
			{convention && <Box direction="row" gap="xsmall" pad="xsmall" align="center">
				<StatusGoodSmall color={getStatusColor(convention)} />
				<Text size="xsmall">
					<strong>{convention}</strong>
				</Text>
			</Box>}
		</Card>
	
);

const getStatusColor = (status) => {
	switch (status) {
		case AGENCE_STATUS.client:
		case CONVENTION_STATUS.signed:
			return "#33BC00";
		case AGENCE_STATUS.prospect:
		case CONVENTION_STATUS.attent_sign:
			return "#FF9D19";
		case AGENCE_STATUS.en_negociation:
			return "#73AAAA";
		case AGENCE_STATUS.encours_signature:
			return "#FFD77C";
		case AGENCE_STATUS.refus:
			return "#000";
		default:
			return "#FF9D19";
	}
};

const CONVENTION_STATUS = {
	attent_sign: "En attente de signature",
	signed: "Signée",
}

const AGENCE_STATUS = {
	client: "Client",
	prospect: "Prospect",
	refus: "Refus",
	en_negociation: "En négociations",
	encours_signature: "En cours de signature",

};

const getNextStatus = agence => {
	switch(agence.status) {
		case AGENCE_STATUS.prospect:
			return [
				AGENCE_STATUS.en_negociation,
				AGENCE_STATUS.refus
			]
		case AGENCE_STATUS.refus:
			return [
				AGENCE_STATUS.prospect,
				AGENCE_STATUS.client,
				AGENCE_STATUS.en_negociation,
				AGENCE_STATUS.encours_signature
			]
		case AGENCE_STATUS.client:
			return [
				AGENCE_STATUS.prospect,
				AGENCE_STATUS.en_negociation,
				AGENCE_STATUS.encours_signature,
				AGENCE_STATUS.refus
			]
		case AGENCE_STATUS.en_negociation:
			return [
				AGENCE_STATUS.prospect,
				AGENCE_STATUS.encours_signature,
				AGENCE_STATUS.client,
				AGENCE_STATUS.refus
			]
		case AGENCE_STATUS.encours_signature:
			return [
				AGENCE_STATUS.prospect,
				AGENCE_STATUS.en_negociation,
				AGENCE_STATUS.client,
				AGENCE_STATUS.refus
			]
		default: return []
	}
}
export default AgenceStatus;

export function AgenceStatusChange({ agence, onReload, showMessage }) {
    const changeStatus = s => {
		agence.status = s;
        updateAgence(agence)
            .then(_ => {
                showMessage('Statut changé avec succès', 'success');
                onReload(agence);
            })
            .catch(err => {
                console.log(err);
                showMessage('Error inattendu', 'error');
            })
    }
    return (
        <Menu
            dropProps={{
                align: { top: 'bottom', left: 'left' },
            }}
            items={
                getNextStatus(agence).map((s, i) => ({
                    label: <Text size="small" color={s !== AGENCE_STATUS.refus ? '#707070' : "red"} key={i}>{s}</Text>,
                    onClick: () => changeStatus(s)
                }))
            }
        >
            <Box direction="row" align="center"
                justify="center" gap="small">
                <PrimaryButton background="#707070" icon={<Update />} label={'Change statut'} />
            </Box>
        </Menu>
    )
}

export {
	AGENCE_STATUS,
	getStatusColor
};

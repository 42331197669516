import { Box, Heading, Text } from "grommet";
import React from "react";
import PrimaryButton from "../../../elements/primary_button";
import DateInput from "../../../elements/date_input";
import { ColorMarksSlider } from "../../../elements/slider/marks_slider";
import {
  AddCircle,
  Checkmark,
  Trash,
} from "grommet-icons";
import ContactSelect, { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import Line from "../../../elements/line_container";
import CustomSelect from "../../../elements/select_fill";
import CustomInput from "../../../elements/custom_input";
import ToggleButton from "../../../elements/toggle_button/toggle_button";

const REGIMES = [
  "Assuré social",
  "TNS",
  "TNS en cours d'immat.",
  "Profession libérale",
  "MSA exploitant agricole",
  "MSA salarié agricole",
  "Alsace / Moselle"
]

const ASSURE_STATUTS = [
  "Agent assurance",
  "Cadre",
  "Artison",
  "Conjoint collaborateur",
  "Commercçant",
  "Demandeur d’emploi",
  "Employé",
  "Etudiant",
  "Exploitant agricole",
  "Fonctionnaire",
  "Gérant de société",
  "Ouvrier",
  "Profession libérale",
  "Professions médicales et para médicales Non salariées",
  "Professions médicales et para médicales salariées",
  "Retraité",
  "Sans profession",
];

const SanteInfo = ({ devis, updateDevis, onNext }) => {
  const onAddAssure = () => {
    let assures = devis.assures;
    assures.push(
      {
        hospitalisation: 1,
        soins_courants: 1,
        dentaire: 1,
        optique: 1
      }
    );
    updateDevis("assures", assures);
  };

  const remove = (index) => {
    let assures = devis.assures;
    assures.splice(index, 1);
    updateDevis("assures", assures);
  };

  const updateAssure = (field, value, index) => {
    let assures = devis.assures;
    let assure = assures[index];
    assure[field] = value;
    updateDevis("assures", assures);
  };

  return (
    <Box
      gap="small"
      width="xlarge"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Box justify="center" alignSelf="center">
        <Heading
          level={3}
          textAlign="center"
        >{`Informations de l'assuré principal`}</Heading>
      </Box>

      <ContactSelectV2
        contact={devis.client || {}}
        updateContact={updateDevis}
        addressIncluded={true}
        birthDayIncluded={true}
      />
      <Box justify="center" alignSelf="center">
        <Heading
          level={3}
          textAlign="center"
        >{`Informations complémentaires`}</Heading>
      </Box>
      <Box width="large" gap="medium" margin="medium">
        <Line
          leftLabel="Régime obligatoire"
          rightChildren={
            <Box justify="center" gap="small">
              <CustomSelect
                options={REGIMES}
                value={devis.regime}
                onChange={({ option }) => updateDevis('regime', option)}
              />
            </Box>
          }
        />
        <Line
          leftLabel="Statut"
          rightChildren={
            <CustomSelect
              options={ASSURE_STATUTS}
              value={devis.assure_statut}
              onChange={({ option }) => updateDevis("assure_statut", option)}
            />
          }
        />
      </Box>
      <Box width="large" gap="medium" margin="medium">
        {devis.assures.map((ass, index) => (
          <Assure
            key={index}
            onRemove={() => remove(index)}
            assure={ass}
            updateAssure={(field, value) => updateAssure(field, value, index)}
          />
        ))}

        <Line
          leftLabel="Date de résiliation"
          rightChildren={
            <DateInput
              value={devis.date_resiliation}
              onChange={(date) => updateDevis("date_resiliation", date)}
            />
          }
        />
      </Box>
      <Box style={{ height: 20 }} />
      <Box direction="row" align="center" justify="center" gap="medium">
        <PrimaryButton
          icon={<AddCircle />}
          label={`Ajouter un assuré`}
          background="#59A4F4"
          onClick={onAddAssure}
        />
      </Box>
      <Box style={{ height: 40 }} />
      <Box direction="row" align="center" justify="center" gap="medium">
        <PrimaryButton
          label={`Valider`}
          icon={<Checkmark />}
          onClick={onNext}
        />
      </Box>
    </Box>
  );
};

const Assure = ({ assure, updateAssure, onRemove }) => {

  return (
    <Box width="large" gap="medium" margin="xsmall">
      <Box justify="center" alignSelf="center">
        <Heading
          level={4}
          textAlign="center"
        >{`Informations assurées`}</Heading>
      </Box>
      <Box width="large" gap="xsmall" margin="xsmall">
        <Box justify="center" align="center" alignSelf="center">
          <ToggleButton
            groups={["Conjoint", "Enfant"].map((value) => ({
              label: value,
              selected: assure.type === value,
              onClick: () => updateAssure("type", value),
            }))}
          />
        </Box>
        <Line
          leftLabel="Nom"
          rightChildren={
            <CustomInput
              value={assure.lastname}
              onChange={(event) => updateAssure("lastname", event.target.value)}
            />
          }
        />
        <Line
          leftLabel="Prénom"
          rightChildren={
            <CustomInput
              value={assure.firstname}
              onChange={(event) =>
                updateAssure("firstname", event.target.value)
              }
            />
          }
        />
        <Line
          leftLabel="Date de naissance"
          rightChildren={
            <DateInput
              value={assure.date_of_birth}
              onChange={(date) => updateAssure("date_of_birth", date)}
            />
          }
        />
        <Line
          leftLabel="Régime obligatoire"
          rightChildren={
            <Box justify="center" gap="small">
              <CustomSelect
                options={REGIMES}
                value={assure.regime}
                onChange={({ option }) => updateAssure('regime', option)}
              />
            </Box>
          }
        />
        <Line
          leftLabel="Statut"
          rightChildren={
            <CustomSelect
              options={ASSURE_STATUTS}
              value={assure.assure_statut}
              onChange={({ option }) => updateAssure("assure_statut", option)}
            />
          }
        />
        <Line
          leftLabel="Projet"
        />

        <Box width={"100%"} gap="small" style={{ marginTop: 20 }}>
          <ProtectionLevel
            label="Hospitalisation"
            value={assure.hospitalisation}
            onChange={value => updateAssure('hospitalisation', value)}
          />
          <ProtectionLevel
            label="Soins courants"
            value={assure.soins_courants}
            onChange={value => updateAssure('soins_courants', value)}
          />
          <ProtectionLevel
            label="Dentaire"
            value={assure.dentaire}
            onChange={value => updateAssure('dentaire', value)}
          />
          <ProtectionLevel
            label="Optique"
            value={assure.optique}
            onChange={value => updateAssure('optique', value)}
          />
        </Box>
      </Box>
      <Box direction="row" align="center" justify="center" gap="medium">
        <PrimaryButton
          icon={<Trash />}
          label={`Supprimer cette adhérent`}
          onClick={onRemove}
        />
      </Box>
    </Box>
  );
};

const ProtectionLevel = ({ label, value, onChange }) => {
  return (
    <Box justify="center" alignSelf="center" align="center" width="medium">
      <Text>{label}</Text>
      <Text size="xsmall">Niveau de garantie</Text>
      <ColorMarksSlider
        step={1}
        max={4}
        min={1}
        value={value}
        valueLabelDisplay="off"
        marks={[
          { value: 1.0, label: "" },
          { value: 2.0, label: "" },
          { value: 3.0, label: "" },
          { value: 4.0, label: "" },
        ]}
        onChange={(event, newValue) =>
          onChange(newValue)
        }
      />
      <Box justify="between" direction="row" width={"100%"}>
        <Text size="xsmall">Faible</Text>
        <Text size="xsmall">Très bonne</Text>
      </Box>
    </Box>
  )
}

export default SanteInfo;

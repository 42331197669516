import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";
import {
  CONTRAT_CLOTURE,
  CONTRAT_ENVOYE,
  DEAL_POSSIBLE,
  DEAL_POSSIBLE_REMPLI,
  DEVIS,
  DEVIS_VALIDE,
  EDITION,
  EN_ATTENTE_BANQUE,
  EN_ATTENTE_DE_FORMALITE_MEDICALE,
  EN_ATTENTE_DE_RESILIATION,
  ETUDE,
  PROSPECT,
} from "../../services/devis_contrat_status";
import { getSimpleDateFR, getSimpleTimeFR } from "../../services/utils";
import BlockUi from "react-block-ui";
import { getAppelsByStatus } from "../../services/appointment_service";
import PrimaryButton from "./primary_button";
import { Refresh } from "grommet-icons";

const StatusDateAppel = ({ devis }) => {

  const [appels, setAppels] = useState([]);
  const [blocking, setBlocking] = useState(false);

  useEffect(() => {
    loadAppels();
  }, devis);

  const loadAppels = () => {
    setBlocking(true);

    getAppelsByStatus(devis).then(res => {
      setAppels(res.data);
      setBlocking(false);
    })
      .catch(err => setBlocking(false));
  }

  return (
    <Box
      direction="row" gap="small" pad={"small"}
    >
      <Card >
        <Box background={"#ddd"}>
          <Text margin={"xsmall"} size="small" weight={"bold"} >  Tableau des statuts :</Text>
        </Box>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell><Text size="small" weight="bold">
                Statut
              </Text></TableCell>
              <TableCell>
                <Text size="small" weight="bold">
                  Date
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="small" weight="bold">
                  Nbr Appel
                </Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody >
            <TableRow>
              <TableCell align="left">
                <Text size="xsmall" weight="bold" align="left">
                  {PROSPECT}
                </Text>
              </TableCell>
              <TableCell align="left">
                <Text size="small" align="left">
                  {getSimpleDateFR(devis.createdAt) || "-"}
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="small" weight={"bold"}>
                  {devis.nbr_appel_prospect}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <Text size="xsmall" weight="bold" align="left">
                  {DEAL_POSSIBLE}
                </Text>
              </TableCell>
              <TableCell align="left">
                <Text size="small" align="left">
                  {getSimpleDateFR(devis.createdAt) || "-"}
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="small" weight={"bold"}>
                  {devis.nbr_appel_deal_possible}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <Text size="xsmall" weight="bold" align="left">
                  {DEAL_POSSIBLE_REMPLI}
                </Text>
              </TableCell>
              <TableCell align="left">
                <Text size="small" align="left">
                  {getSimpleDateFR(devis.date_deal_rempli) || "-"}
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="small" weight={"bold"}>
                  {devis.nbr_appel_deal_rempli}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <Text size="xsmall" weight="bold" align="left">
                  {DEVIS}
                </Text>
              </TableCell>
              <TableCell align="left">
                <Text size="small" align="left">
                  {getSimpleDateFR(devis.date_devis) || "-"}
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="small" weight={"bold"}>
                  {devis.nbr_appel_devis}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <Text size="xsmall" weight="bold" align="left">
                  {DEVIS_VALIDE}
                </Text>
              </TableCell>
              <TableCell align="left">
                <Text size="small" align="left">
                  {getSimpleDateFR(devis.date_devis_valide) || "-"}
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="small" weight={"bold"}>
                  {devis.nbr_appel_devis_valide}
                </Text>
              </TableCell>
            </TableRow>
            {devis.model_type === 'emprunteur_horsligne' &&
              <TableRow>
                <TableCell align="left">
                  <Text size="xsmall" weight="bold" align="left">
                    {ETUDE}
                  </Text>
                </TableCell>
                <TableCell align="left">
                  <Text size="small" align="left">
                    {getSimpleDateFR(devis.date_etude) || "-"}
                  </Text>
                </TableCell>
                <TableCell align="right">
                  <Text size="small" weight={"bold"}>
                    {devis.date_etude || 0}
                  </Text>
                </TableCell>
              </TableRow>
            }
            {devis.model_type === 'emprunteur_horsligne' &&
              <TableRow>
                <TableCell align="left">
                  <Text size="xsmall" weight="bold" align="left">
                    {EDITION}
                  </Text>
                </TableCell>
                <TableCell align="left">
                  <Text size="small" align="left">
                    {getSimpleDateFR(devis.date_edition) || "-"}
                  </Text>
                </TableCell>
                <TableCell align="right">
                  <Text size="small" weight={"bold"}>
                    {devis.date_edition || 0}
                  </Text>
                </TableCell>
              </TableRow>
            }
            <TableRow>
              <TableCell align="left">
                <Text size="xsmall" weight="bold" align="left">
                  {EN_ATTENTE_BANQUE}
                </Text>
              </TableCell>
              <TableCell align="left">
                <Text size="small" align="left">
                  {getSimpleDateFR(devis.date_attente_banque) || "-"}
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="small" weight={"bold"}>
                  {devis.nbr_appel_attente_banque || 0}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <Text size="xsmall" weight="bold" align="left">
                  {EN_ATTENTE_DE_FORMALITE_MEDICALE}
                </Text>
              </TableCell>
              <TableCell align="left">
                <Text size="small" align="left">
                  {getSimpleDateFR(devis.date_formalite_medicale) || "-"}
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="small" weight={"bold"}>
                  {devis.nbr_appel_formalite_medicale}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <Text size="xsmall" weight="bold" align="left">
                  {EN_ATTENTE_DE_RESILIATION}
                </Text>
              </TableCell>
              <TableCell align="left">
                <Text size="small" align="left">
                  {getSimpleDateFR(devis.date_resiliation) || "-"}
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="small" weight={"bold"}>
                  {devis.nbr_appel_resiliation}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <Text size="xsmall" weight="bold" align="left">
                  {CONTRAT_ENVOYE}
                </Text>
              </TableCell>
              <TableCell align="left">
                <Text size="small" align="left">
                  {getSimpleDateFR(devis.date_contrat) || "-"}
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="small" weight={"bold"}>
                  {devis.nbr_appel_contrat}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <Text size="xsmall" weight="bold" align="left">
                  {CONTRAT_CLOTURE}
                </Text>
              </TableCell>
              <TableCell align="left">
                <Text size="small" align="left">
                  {getSimpleDateFR(devis.date_cloture) || "-"}
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="small" weight={"bold"}>
                  {devis.nbr_appel_cloture}
                </Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>

      <Card >
        <Box background={"#ddd"} direction="row" justify="between" alignContent="between" >
          <Text margin={"xsmall"} size="small" weight={"bold"} > Tableau des appels :</Text>
          <PrimaryButton icon={<Refresh />} reverse onClick={loadAppels}></PrimaryButton>
        </Box>
        <BlockUi tag="div" blocking={blocking}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell><Text size="small" weight="bold">
                  N°
                </Text></TableCell>
                <TableCell>
                  <Text size="small" weight="bold">
                    Statut
                  </Text>
                </TableCell>
                <TableCell>
                  <Text size="small" weight="bold">
                    Date
                  </Text>
                </TableCell>
                <TableCell>
                  <Text size="small" weight="bold">
                    Heure
                  </Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {appels.map((a, i) => (
                <TableRow>
                  <TableCell align="left">
                    <Text size="small" weight="bold" align="left">
                      {i + 1}
                    </Text>
                  </TableCell>
                  <TableCell align="left">
                    <Text size="small" align="left">
                      {a.for_status}
                    </Text>
                  </TableCell>
                  <TableCell align="right">
                    <Text size="small" weight={"bold"}>
                      {getSimpleDateFR(a.begin)}
                    </Text>
                  </TableCell>
                  <TableCell align="right">
                    <Text size="small" weight={"bold"}>
                      {getSimpleTimeFR(a.begin)}
                    </Text>
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </BlockUi>
      </Card>

    </Box>
  );
}

export const StatusDateAppelV2 = ({ devis }) => {

  const [appels, setAppels] = useState([]);
  const [blocking, setBlocking] = useState(false);

  useEffect(() => {
    loadAppels();
  }, devis);

  const loadAppels = () => {
    setBlocking(true);

    getAppelsByStatus(devis).then(res => {
      setAppels(res.data);
      setBlocking(false);
    })
      .catch(err => setBlocking(false));
  }

  return (
    <Grid
      columns={["1/2", "1/2"]} gap="small" pad={"small"} width="100%"
    >
      <Box>
        <Box background={"brand"} direction="row" justify="between" align="center" style={{ borderRadius: "10px 10px 0 0" }}>
          <Text color="white" margin={"xsmall"} size="small" weight={"bold"}>Historique des appels</Text>
          <Button icon={<Refresh size="15px" />} color="white" reverse onClick={loadAppels} />
        </Box>
        <BlockUi tag="div" blocking={blocking}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell><Text size="small" weight="bold">
                  N°
                </Text></TableCell>
                <TableCell>
                  <Text size="small" weight="bold">
                    Statut
                  </Text>
                </TableCell>
                <TableCell>
                  <Text size="small" weight="bold">
                    Date
                  </Text>
                </TableCell>
                <TableCell>
                  <Text size="small" weight="bold">
                    Heure
                  </Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {appels.map((a, i) => (
                <TableRow>
                  <TableCell align="left">
                    <Text size="small" weight="bold" align="left">
                      {i + 1}
                    </Text>
                  </TableCell>
                  <TableCell align="left">
                    <Text size="small" align="left">
                      {a.for_status}
                    </Text>
                  </TableCell>
                  <TableCell align="right">
                    <Text size="small" weight={"bold"}>
                      {getSimpleDateFR(a.begin)}
                    </Text>
                  </TableCell>
                  <TableCell align="right">
                    <Text size="small" weight={"bold"}>
                      {getSimpleTimeFR(a.begin)}
                    </Text>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </BlockUi>
      </Box>
      <Box>
        <Box background={"brand"} style={{ borderRadius: "10px 10px 0 0" }}>
          <Text color="white" margin={"xsmall"} size="small" weight={"bold"}>Historique statuts</Text>
        </Box>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell><Text size="small" weight="bold">
                Statut
              </Text></TableCell>
              <TableCell>
                <Text size="small" weight="bold">
                  Date
                </Text>
              </TableCell>
              <TableCell>
                <Text size="small" weight="bold">
                  Nbr Appel
                </Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <Text size="small" weight="bold" align="left">
                  {PROSPECT}
                  <br />
                  {DEAL_POSSIBLE}
                  <br />
                  {DEAL_POSSIBLE_REMPLI}
                  <br />
                  {DEVIS}
                  <br />
                  {DEVIS_VALIDE}
                  <br />
                  {EN_ATTENTE_BANQUE}
                  <br />
                  {EN_ATTENTE_DE_FORMALITE_MEDICALE}
                  <br />
                  {EN_ATTENTE_DE_RESILIATION}
                  <br />
                  {CONTRAT_ENVOYE}
                  <br />
                  {CONTRAT_CLOTURE}
                </Text>
              </TableCell>
              <TableCell align="left">
                <Text size="small" align="left">
                  {getSimpleDateFR(devis.createdAt) || "-"}
                  <br />
                  {getSimpleDateFR(devis.createdAt) || "-"}
                  <br />
                  {getSimpleDateFR(devis.date_deal_rempli) || "-"}
                  <br />
                  {getSimpleDateFR(devis.date_devis) || "-"}
                  <br />
                  {getSimpleDateFR(devis.date_devis_valide) || "-"}
                  <br />
                  {getSimpleDateFR(devis.date_attente_banque) || "-"}
                  <br />
                  {getSimpleDateFR(devis.date_formalite_medicale) || "-"}
                  <br />
                  {getSimpleDateFR(devis.date_resiliation) || "-"}
                  <br />
                  {getSimpleDateFR(devis.date_contrat) || "-"}
                  <br />
                  {getSimpleDateFR(devis.date_cloture) || "-"}
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="small" weight={"bold"}>
                  {devis.nbr_appel_prospect}
                  <br />
                  {devis.nbr_appel_deal_possible}
                  <br />
                  {devis.nbr_appel_deal_rempli}
                  <br />
                  {devis.nbr_appel_devis}
                  <br />
                  {devis.nbr_appel_devis_valide}
                  <br />
                  {devis.nbr_appel_attente_banque}
                  <br />
                  {devis.nbr_appel_formalite_medicale}
                  <br />
                  {devis.nbr_appel_resiliation}
                  <br />
                  {devis.nbr_appel_contrat}
                  <br />
                  {devis.nbr_appel_cloture}
                </Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Grid>
  );
}


export default StatusDateAppel;

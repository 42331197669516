const axios = require('axios');
const storageService = require('./storage_service');
const { baseAPI } = require('../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const convertToDealPossible = (deal, path) => {
    return axios.put(`${baseAPI}/public/${path}/${deal.id}`, deal, { headers: HEADERS })
}

const modifyDeal = (deal, path) => {
    return axios.put(`${baseAPI}/public/${path}/${deal.id}`, deal, { headers: HEADERS })
}

const getEmailEventReports = (messageIds) => {
    return axios.post(`${baseAPI}/public/email/event/search`, messageIds, { headers: HEADERS })
}

const sendPresentation = (devis, params = {}) => {
    return axios.post(`${baseAPI}/public/email/presentation`, devis, { params, headers: HEADERS })
}

const sendEnvelopEmp = (devis, params = {}) => {
    return axios.post(`${baseAPI}/public/email/envelop`, devis, { params, headers: HEADERS })
}

const getCustomerId = (devisId) => {
    return axios.post(`${baseAPI}/public/payment/customer`, {id: devisId}, { headers: HEADERS })
}

const verifyPromoCode = (code, type) => {
    return axios.get(`${baseAPI}/public/promo/status`, {
        headers: HEADERS, params: { code, type }
    });
  }

const updateDeal = (id, model_type, data) => {
    return axios.patch(`${baseAPI}/public/deal/${id}`, {model_type, data}, {
        headers: HEADERS
    });
}

module.exports = {
    convertToDealPossible,
    getEmailEventReports,
    sendPresentation,
    sendEnvelopEmp,
    modifyDeal,
    getCustomerId,
    verifyPromoCode,
    updateDeal
}
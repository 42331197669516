import { Box, Grid, RadioButtonGroup, Select, Text, TextInput } from "grommet";
import React, { useState } from "react";
import CustomInput from "../../../../elements/custom_input";
import { Checkmark } from "grommet-icons";
import PrimaryButton from "../../../../elements/primary_button";
import DateInput from "../../../../elements/date_input";
import ChoiceSelect from "../../../trusti-per/trusti_per/ChoiceSelect";
import ToggleButton from "../../../../elements/toggle_button/toggle_button";
import QuizSelect from "./QuizSelect";

const inputStyle = {
  background: "#EDEDED",
  fontSize: 14,
  fontWeight: 400,
  borderRadius: 10,
  border: "none",
};

const QuestionsLegales = ({ devis, updateDevis, onSave }) => {
  const [dataChanged, setDataChanged] = useState(false);

  const updateData = (field, value) => {
    setDataChanged(true);
    updateDevis(field, value);
  };

  const SectionHeader = ({ title, color, round }) => {
    return (
      <Box
        fill
        gap="small"
        pad="xxsmall"
        background={color || "brand"}
        style={round ? { borderRadius: 12 } : {}}
      >
        <Text color="white" style={{ fontWeight: "bold", marginLeft: 20 }}>
          {title}
        </Text>
      </Box>
    );
  };

  const submitModification = () => {
    setDataChanged(false);
    onSave({ skipValidation: true });
  };

  return (
    <Box fill background={"white"}>
      {dataChanged && (
        <Box
          fill
          background={"yellow"}
          justify="end"
          direction="row"
          pad="xxsmall"
        >
          <PrimaryButton
            background="transparent"
            icon={<Checkmark color="green" />}
            reverse
            onClick={submitModification}
            label={"Valider"}
            color="green"
          ></PrimaryButton>
        </Box>
      )}
      <SectionHeader title={"Situation familiale"} />
      <Box direction="row">
        <Box pad={"small"} fill gap="xsmall">
          <QuizSelect
            title="Supposons que votre investissement perde 10% en 6 mois, comment réagissez-vous ?"
            choices={[
              "A - J'effectue un nouveau virement",
              "B - Je récupère une partie du montant investi",
              "C - Je récupère la totalité du montant investi",
              "D - Je patiente sans paniquer",
            ]}
            onChange={(choice) => {
              updateData("quiz_1", choice);
            }}
            default={devis.quiz_1}
          />
          <QuizSelect
            title="Sur l'assurance-vie, le capital placé est bloqué pendant 8 ans ?"
            choices={["A - Vrai", "B - Faux", "C - Je ne sais pas"]}
            onChange={(choice) => {
              updateData("quiz_2", choice);
            }}
            default={devis.quiz_2}
          />

          <QuizSelect
            title="Un tracker (ETF ou fonds indiciel) est un placement à capital garanti ?"
            choices={["A - Vrai", "B - Faux", "C - Je ne sais pas"]}
            onChange={(choice) => {
              updateData("quiz_3", choice);
            }}
            default={devis.quiz_3}
          />

          <QuizSelect
            title="Via un mandat d'arbitrage, vous déléguez les décisions d'investissement à Trustiway ?"
            choices={["A - Vrai", "B - Faux", "C - Je ne sais pas"]}
            onChange={(choice) => {
              updateData("quiz_4", choice);
            }}
            default={devis.quiz_4}
          />

          <QuizSelect
            title="Avez-vous déjà investi dans des actions ou des obligations, en direct ou via des fonds d'investissement (SICAV, FCP ou ETF) ?"
            choices={["A - Oui", "B - Non"]}
            onChange={(choice) => {
              updateData("quiz_5", choice);
            }}
            default={devis.quiz_5}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionsLegales;

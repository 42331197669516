import React from "react";
import { Box, Button, Card, Image, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import { deletePushNotification, getPushNotifications } from "../service";
import { getMonthOptions, getSimpleDateFR, getSimpleDateTimeFR, getSorted } from "../../../services/utils";
import Refresh from "../../elements/refresh";
import SearchComponent from "../../elements/search";
import DateSort from "../../elements/date_sort";
import CustomSelect from '../../elements/select_fill';
import moment from 'moment';
import PrimaryButton from "../../elements/primary_button";
import { AddCircle, StatusGoodSmall, Trash } from "grommet-icons";
import AppPushNotificationCreate from "./create";

class AppPushNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pushNotifs: [],
      popupOpen: false,
      selectedPartenaire: {},
      totalCommission: 0,
      totalCommissionPaid: 0,
      numContrats: 0,
      startDate: moment(new Date()).startOf('month').toDate(),
      endDate: new Date(),
      query: null,
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined,
      }
    };
  }

  componentDidMount = () => {
    this.loadAppPushNotifications();
  };

  loadAppPushNotifications = (_startDate, _endDate, _query = null) => {
    if (!_startDate || !_endDate) {
      _startDate = this.state.startDate;
      _endDate = this.state.endDate;
    }
    this.setState({ pushNotifs: [] }, () => {
      getPushNotifications(_startDate, _endDate, _query)
        .then((res) => {
          this.setState({
            pushNotifs: res.data
          });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  deletePushNotif = (_id) => {
    deletePushNotification(_id).then((res) => {
      this.props.showMessage("Supprimé", "success");
      this.loadAppPushNotifications();
    })
      .catch((err) => {
        console.log("err", err);
        this.props.showMessage("Erreur inattendu");
      });
  }

  onClose = () => {
    this.setState({ popupOpen: false, showCreateNew: false });
    this.loadAppPushNotifications();
  };

  openPartenaire = (partenaire) => {
    this.setState({ selectedPartenaire: partenaire, popupOpen: true });
  };

  addPartenaire = () => this.openPartenaire({});

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }


  render() {
    return (
      this.state.showCreateNew ? (
        <AppPushNotificationCreate
          showMessage={this.props.showMessage}
          onClose={this.onClose} />
      ) : <Box style={{ width: "100%" }} margin={{ top: "medium" }}
        align="center" justify="center"
        alignSelf="center"
        alignContent="center" gap="medium">
        <Text size="xlarge" style={{ padding: 10 }}><b>Push Notification</b></Text>
        <Box width="xlarge" gap="medium">
          <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
            <Refresh refresh={this.loadAppPushNotifications} />
            {/* <Box direction='row' gap='small'
              alignSelf="center" >
              <Box pad="xsmall" justify="center" alignContent="center"
                gap="small"
                align="center" direction="row">
                <Text size="xsmall">Select d'un mois</Text>
                <CustomSelect
                  options={getMonthOptions().filter(
                    (s) => s.name !== "12 derniers mois"
                  )}
                  labelKey={"name"}
                  valueKey={{ key: "name", reduce: true }}
                  placeholder='mois en cours'
                  defaultValue={getMonthOptions()[0]}
                  onChange={({ option }) => {
                    this.setState({
                      startDate: option.startDate,
                      endDate: option.endDate,
                      query: null
                    });
                    this.loadAppPushNotifications(option.startDate, option.endDate);
                  }}
                />
              </Box>
            </Box> */}
            <SearchComponent
              search={value => {
                this.setState({
                  query: value
                });
                console.log("query = ", value);
                this.loadAppPushNotifications(this.state.startDate, this.state.endDate, {_q : value});
              }}
              query={this.state.query}
              updateQuery={value => this.setState({ query: value })}
            />
            <DateSort
              onChange={value => this.updateFilter('sort', value)}
            />
            <PrimaryButton
              label={`Ajouter un push notification`}
              icon={<AddCircle />}
              background='#E82C70'
              onClick={() => this.setState({ showCreateNew: true })}
            />
          </Box>

          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Text size="small">Création/<br />Modification</Text>
                </TableCell>
                <TableCell>
                  <Text size="small">Titre</Text>
                </TableCell>
                <TableCell><Text size="small">Contenu</Text></TableCell>
                <TableCell><Text size="small">Status</Text></TableCell>
                <TableCell>
                  <Text size="small">Date</Text>
                </TableCell>
                <TableCell><Text size="small">Utilisateur</Text></TableCell>
                <TableCell><Text size="small">Récurrente</Text></TableCell>
                <TableCell><Text size="small">Dernière envoi</Text></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getSorted(this.state.pushNotifs, this.state.filter.sort).map((pushNotif, index) => (
                <TableRow key={index + 1}>
                  <TableCell>
                    <Text size="small"><strong>{getSimpleDateTimeFR(pushNotif.createdAt)}</strong><br />{getSimpleDateTimeFR(pushNotif.updatedAt)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text size="small">{pushNotif.title}</Text>
                  </TableCell>
                  <TableCell>
                    <Text size="small">{pushNotif.content}</Text>
                  </TableCell>
                  <TableCell>
                    <Box direction="row" gap="xsmall" pad="xsmall" align="center">
                      <StatusGoodSmall color={pushNotif.status === 'Envoyé' ? "#33BC00" : (pushNotif.status === "Erreur" ? "red" : "#FFD77C")} />
                      <Text size="small">
                        <strong>
                          {pushNotif.status}
                        </strong>
                      </Text>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Text size="small">{getSimpleDateTimeFR(pushNotif.date_to_send)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text size="small">{pushNotif.to_user ? pushNotif.to_user.prenom : ""} {pushNotif.to_user ? pushNotif.to_user.nom : ""}</Text>
                  </TableCell>
                  <TableCell>
                    <Text size="small">{pushNotif.repeat}</Text>
                  </TableCell>
                  <TableCell>
                    <Text size="small">{pushNotif.repeat !== "Une seule fois" && pushNotif.last_sent ? getSimpleDateTimeFR(pushNotif.last_sent) : ""}</Text>
                  </TableCell>
                  <TableCell>
                    <PrimaryButton
                      icon={<Trash />}
                      label=""
                      onClick={() => this.deletePushNotif(pushNotif.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  }
}



export default AppPushNotification;

import { Box, Grid, Stack } from "grommet";
import React, { useEffect, useState } from "react";
import { uploadFilesMulti } from '../../../../services/storage_service';
import { CardContainer, LineTextContainer, TitleContainer } from '../../../elements/refont/containers/text-containser';
import CustomSelect from '../../../elements/refont/custom-select';
import CustomFileInput from '../../../elements/refont/file-input';
import CustomTextInput from '../../../elements/refont/text-input';
import { TextLabel } from '../../../elements/refont/text-label';
import { updateSinistre } from '../service';
import { Checkmark, Close, Download, Edit, Trash } from "grommet-icons";
import JSZip from "jszip";
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';

export const InfoDocuments = ({ sinistre, onUploadFiles, onChangeSubmit }) => {
    const [values, setValues] = useState({ ...sinistre });

    useEffect(() => {
        setValues({ ...sinistre });
    }, [sinistre]);

    const downloadAll = () => {
        var zip = new JSZip();
        var count = 0;
        var zipFilename = `Dossier-${sinistre.nom_locataire}-${sinistre.prenom_du_locataire}-${sinistre.id}.zip`;
        var urls = sinistre.files_dossier;

        urls.forEach(function (file) {
            var filename = file.name?.includes(".") ? file.name : `${file.name}.${file.file_url && (file.file_url.split("/").pop()).split(".")?.pop()}`;
            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(file.file_url, function (err, data) {
                if (err) {
                    throw err; // or handle the error
                }
                zip.file(`${file.type}/${filename}`, data, { binary: true });
                count++;
                if (count == urls.length) {
                    zip.generateAsync({ type: 'blob' }).then(function (content) {
                        saveAs(content, zipFilename);
                    });
                }
            });
        });
    }

    const removeFile = (_idx) => { 
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce fichier ?")) {
            console.log("remove index = ", _idx);
            let _files = sinistre['files_dossier'];
            _files.splice(_idx, 1);
            values.files_dossier = _files;
            onChangeSubmit(values);
        }
    }

    const download = (e) => {
        var filename = e.name?.includes(".") ? e.name : `${e.name}.${e.file_url && (e.file_url.split("/").pop()).split(".")?.pop()}`;
        // loading a file and add it in a zip file
        console.log(e.file_url);
        console.log(filename);

        saveAs(e.file_url, filename);
    }

    return (
        <CardContainer>
            <Box direction={'row'} gap={'small'} justify="between" align="center">
                <Box fill background={"white"} direction="column">
                    <Box direction={'row'} gap={'small'} justify="between" align="center">
                        <TextLabel label={"Gestion électronique documentaire"} variant={"header3"} />
                        {sinistre.files_dossier && sinistre.files_dossier.length > 0 && (
                            <Download size="small" color="brand" onClick={downloadAll} />)
                        }
                    </Box>
                    <Box pad={{ top: '1rem' }} style={{ minHeight: 'auto' }}>
                        <TitleContainer>
                            <TextLabel variant={"title1"} label={"Documents"} color={'white'} />
                        </TitleContainer>
                        <Box pad={{ vertical: '0.5rem' }} style={{ minHeight: 'auto' }}>
                            <Grid
                                columns={['1/4', '3/4']}
                                gap="small"
                                style={{
                                    padding: "0.375rem",
                                    paddingTop: "0.25rem",
                                    paddingBottom: "0.25rem",
                                    backgroundColor: '#F0F0F0',
                                }}
                            >
                                <Box>
                                    <TextLabel label={"Document"} variant={"body1"} />
                                </Box>
                                <Box >
                                    <TextLabel label={"Le nom de votre fichier"} variant={"body1"} />
                                </Box>

                            </Grid>
                            {
                                (values.files_dossier || []).map((file, _idx) => (
                                    <DocumentRow
                                        key={_idx}
                                        file={file}
                                        removeFile={() => removeFile(_idx)}
                                        download={() => download(file)}
                                        onChange={(_key, _value) => {
                                            let _files = values['files_dossier'];
                                            _files[_idx][_key] = _value;
                                            setValues({ ...values, files_dossier: _files });
                                        }}
                                        onSave={(callback) => onChangeSubmit(values, callback)}
                                        onReset={() => setValues({ ...sinistre })}
                                    />
                                ))
                            }
                        </Box>

                        <Box fill style={{ marginBottom: 20, marginTop: 30, paddingLeft: 20, paddingRight: 20, minHeight: 'auto', minWidth: 'auto' }}>

                            <CustomFileInput
                                key={(values['files_dossier'] || []).length}
                                multiple
                                maxSize={10}
                                onChange={(event, { files }) => {
                                    onUploadFiles(
                                        event.target.files,
                                        async (_data) => {
                                            if (_data.length > 0) {
                                                let _files = values['files_dossier'] || [];
                                                for (let i = 0; i < _data.length; i++) {
                                                    const _uploaded = _data[i];
                                                    _files.push({ __component: "upload-documents.upload-documents", type: '', file_url: _uploaded.url, name: _uploaded.url.split("/").pop() });
                                                }
                                                values.files_dossier = _files.map(e => { return { ...e, __component: "upload-documents.upload-documents" } });
                                                onChangeSubmit(values);
                                            }
                                        }
                                    );
                                }}
                            />

                            <Box pad={'xsmall'}>
                                <TextLabel variant={"body1"} label={"Pour télécharger vos documents dans la GED, veuillez vous assurer que vos fichiers sont au format PDF. Si vos fichiers ne sont pas en PDF, convertissez-les facilement en utilisant l'option \"modifier PDF\""} />
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </CardContainer>
    );

}



const DocumentRow = ({ file, onChange, onSave, onReset, removeFile, download }) => {
    const [isEdit, setIsEdit] = useState(false);
    return (
        <LineTextContainer>
            <Grid
                columns={['1/4', '3/4']}
                gap="small"
            >
                <Box>
                    <CustomSelect
                        editMode={isEdit}
                        options={["Contrat de location",
                            "Medicale",
                            "Quest. médicale",
                            "Évènement aléatoires",
                            "Facture dommages",
                            "Achat origine",
                            "Effet personnel",
                            "Procès verbal",
                            "Mail client"
                        ]}
                        value={file.type}
                        onChange={({ option }) => {
                            onChange('type', option);
                        }}
                    />
                </Box>
                <Box direction="row" justify="between" align="center" pad={{ right: 'small' }}>
                    <CustomTextInput
                        editMode={isEdit}
                        value={file.name}
                        onChange={event => {
                            onChange('name', event.target.value);
                        }}
                    />
                    {!isEdit ? (
                        <Box direction="row" justify="center" align="center" gap="xsmall">
                            <Edit size="small" onClick={() => setIsEdit(true)} />
                            <Download size="small" onClick={download} />
                            <Trash size="small" onClick={removeFile} />
                        </Box>
                    ) : <Box direction="row" justify="center" align="center" gap="xsmall">
                        <Checkmark color="brand" size="small" onClick={() => onSave(() => setIsEdit(false))} />
                        <Close size="small" onClick={() => {
                            onReset();
                            setIsEdit(false);
                        }} />
                    </Box>}
                </Box>
            </Grid>
        </LineTextContainer>
    );
}
const axios = require('axios');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};


const createBlocNote = (blocnote) => {
    return axios.post(`${baseAPI}/bloc_notes`, blocnote, { headers: HEADERS });
}

const updateBlocNote = (blocnote) => {
    return axios.put(`${baseAPI}/bloc_notes/${blocnote.id}`, blocnote, { headers: HEADERS });
}

const deleteBlocNote = (blocnote) => {
    return axios.delete(`${baseAPI}/bloc_notes/${blocnote.id}`, { headers: HEADERS });
}

const getBlocNotes = (client, contract_id, commercial_id) => {
    let params = client ? { client } : {};
    if (contract_id) {
        if (contract_id.includes(',')) {
            params.contract_id_in = contract_id.split(',');
        } else {
            params.contract_id = contract_id;
        }
    } else {
        if (commercial_id) {
            params.commercial = commercial_id;
        }
        if (!storageService.hasAdministrativeRole()) {
            params["commercial"] = storageService.getUserId();
        }
    }

    return axios.get(`${baseAPI}/bloc_notes`, { params, headers: HEADERS });
}


module.exports = {
    createBlocNote,
    updateBlocNote,
    getBlocNotes,
    deleteBlocNote
}
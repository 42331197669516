import React from "react";
import { Box, Heading, Text, Card, Stack } from "grommet";
import {
  Close,
  Compare,
  DocumentText,
  Edit,
  Send,
} from "grommet-icons";
import {
  getSimpleDateTimeFR,
  toCurrency,
} from "../../../../services/utils";
import PrimaryButton from "../../../elements/primary_button";
import RecapStatus from "../../../elements/devis_status";
import { CONTRAT_CLOTURE, isDealClosable } from "../../../../services/devis_contrat_status";
import StatusDateAppel from "../../../elements/status_date_appel";
import PanelAppel from "../../../elements/panel_appel";
import DealStatusChange from "../../../elements/deal_status_change";
import EmailEventTracker from "../../../elements/email_tracker";
import StripeCustomer from "../../../elements/stripe_customer";
import ApporteurGestionnaire from "../../../elements/apporteur_gestionaire";
import ApporteurModification from "../../../elements/apporteur_gestionaire/modification";
import TrustiBot from "../../../trustibot";
import MHConversion from "../../enligne-components/conversion";
import DateRecap from "../../../elements/date_recap";

const getClientClickTime = (time) => {
  if (!time) return "Pas encore";
  return getSimpleDateTimeFR(new Date(time));
};

const isEstimable = (devis) => {
  return (
    devis.status !== CONTRAT_CLOTURE &&
    devis.residenceType &&
    devis.clientType &&
    devis.bienType &&
    devis.address &&
    devis.zip &&
    devis.surface &&
    devis.totalRooms
  );
};

const Recap = ({
  devis,
  toEditMode,
  close,
  sendDevis,
  estimateDevis,
  sendForm,
  sendPresentation,
  onSave,
  updateDevis,
  showMessage,
  onReload,
  blockingHandler
}) => {
  const getEstimatedPrice = () => {
    return devis.estimatedPrice;
  };
  const getFraisDossier = () => {

    if (devis.model_type === 'pno_seul') {
      return devis.rawResponse?.frais_dossier || 0;
    }
    return devis.frais_dossier;
  };
  const getTotal = () => {
    return (parseFloat(getEstimatedPrice()) + parseFloat(getFraisDossier()));
  };

  return (
    <Box
      direction="row"
      gap='small'
      style={{ width: "100vw" }}
    >
      <TrustiBot></TrustiBot>
      <Box
        gap="medium"
        // style={{ width: "100%" }}
        align="center"
        alignSelf="center"
        pad="small"
      >
        <Heading level="2" color="#59A4F4" textAlign="center">
          Trusti Habitation
        </Heading>
        <Box direction="row" fill="horizontal" justify="center" alignContent="center">
          <Box align="center">
            <StatusDateAppel devis={devis} />
          </Box>
          <Box>
            <Box alignSelf="end">
              <RecapStatus status={devis.status} />
              <PanelAppel devis={devis} updateDevis={updateDevis} onSave={onSave} showMessage={showMessage} />
            </Box>
          </Box>
        </Box>
        <Box width="xlarge" gap="small" align="center" justify="center">
          <Card style={{ width: "100%" }} pad="small" gap="small" direction="row" justify="between">
            {devis.agence_name && <Line leftLabel="Partenaire" rightLabel={devis.agence_name} />}
            <Line leftLabel="ID" rightLabel={devis.id} />
          </Card>
        </Box>
        <Box width="xlarge" gap="small" align="center" justify="center">
          <DateRecap deal={devis} />
        </Box>
        <Box width={'xlarge'}>
          <StripeCustomer devisId={devis.id} />
        </Box>
        {getEstimatedPrice() && (
          <Box>
            <Box gap="xxsmall" pad="medium">
              <Text style={{ color: "#E92C70" }}>
                {`Prix total estimé à ${getTotal()
                  .toString()
                  .replace(".", ",")} € / an`}
              </Text>
              <Text size="small">Dont:
                <span> Prix d'assurance estimé à <strong>{toCurrency(devis.estimatedPrice)} </strong></span>
                & <br />
                <span> frais de dossier:  <strong>{toCurrency(devis.frais_dossier)}</strong> </span>
              </Text>
            </Box>
            {devis.last_sent_at && (
              <Text textAlign="center" size="small">
                {`Devis envoyé le : `}
                <strong>{`${getSimpleDateTimeFR(devis.last_sent_at)}`}</strong>
              </Text>
            )}
            {devis.last_sent_at && (
              <Text textAlign="center" size="small">
                {`Client a visité le devis : `}
                <strong>{`${getClientClickTime(devis.last_opened_at)}`}</strong>
              </Text>
            )}
          </Box>
        )}
        <Box width="xlarge" gap="small">
          <Box width="xlarge" direction="row" gap="small" justify="center">
            <Card pad="small" width="large">
              <Assure assure={devis.client || {}} apporteur={devis.apporteur} />
            </Card>
          </Box>
          <Box width="xlarge" direction="row" gap="small" justify="center">
            <Card pad="small" width="large">
              <Assurance devis={devis} />
            </Card>
          </Box>
        </Box>
        {(devis.apporteur || devis.affiliation) && <Card pad={"small"} width={'xlarge'} >
          <ApporteurGestionnaire apporteur={devis.apporteur} affiliation={devis.affiliation} source={devis.source} />
        </Card>}
        <Box width={'xlarge'}>
          <EmailEventTracker messageIds={devis.emailEventIds} />
        </Box>

        <Box style={{ height: 40 }} />
        <ApporteurModification
          devis={devis}
          apporteur={devis.apporteur}
          showMessage={showMessage}
          onReload={onReload}
        />
        <Box direction="row" align="center" justify="center" gap="medium">
          <PrimaryButton
            label={`Modifier`}
            icon={<Edit />}
            background="#59A4F4"
            onClick={toEditMode}
          />

          <DealStatusChange
            deal={devis}
            showMessage={showMessage}
            onReload={onReload}
            path={'devis-habitation'}
          />
          <PrimaryButton
            label={`Fermer`}
            icon={<Close />}
            background={"#6c757d"}
            onClick={close}
          />
        </Box>

        {devis.status !== CONTRAT_CLOTURE &&
          <Box direction="row" align="center" justify="center" gap="medium">
             {!isDealClosable(devis) && getEstimatedPrice() && (
              <PrimaryButton
                onClick={sendDevis}
                label={`Envoyer un devis`}
                icon={<DocumentText />}
                background="#64AEF6"
              />
            )}
            <PrimaryButton
              label={`Envoyer formulaire`}
              background="#59A4F4"
              icon={<Send />}
              onClick={sendForm}
            />
            <PrimaryButton
              label={`Envoyer une présentation`}
              icon={<Send />}
              onClick={sendPresentation}
            />
            {isEstimable(devis) && (
              <PrimaryButton
                label={`Estimer`}
                icon={<Compare />}
                background="#28a745"
                onClick={estimateDevis}
              />
            )}
            <MHConversion
              deal={devis}
              showMessage={showMessage}
              blockingHandler={blockingHandler}
            />
          </Box>
        }
      </Box>
    </Box>
  );
};

const CustomText = ({ label, textAlign, isBold }) => (
  <Text
    style={{ fontWeight: isBold ? "bold" : "normal" }}
    size="small"
    textAlign={textAlign || "end"}
  >
    {label}
  </Text>
);
const Line = ({ leftLabel, rightLabel }) => (
  <Box
    direction="row"
    gap="medium"
    style={{ width: "100%" }}
    align="center"
    alignSelf="center"
  >
    <Box style={{ width: "50%" }}>
      <CustomText label={leftLabel + " :"} />
    </Box>
    <Box style={{ width: "50%" }}>
      <CustomText label={rightLabel} textAlign="start" isBold={true} />
    </Box>
  </Box>
);

const SubHeading = ({ label }) => (
  <Text textAlign="center" style={{ fontWeight: "bold" }}>
    {label}
  </Text>
);
const Assure = ({ assure, apporteur = {} }) => {
  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label={`Informations de l'assuré`} />
      <Line leftLabel="Civilité" rightLabel={assure.civilite} />
      {assure.civilite !== "Société" && (
        <Line leftLabel="Prénom" rightLabel={assure.firstname} />
      )}
      {assure.civilite !== "Société" && (
        <Line leftLabel="Nom" rightLabel={assure.lastname} />
      )}
      {assure.civilite === "Société" && (
        <Line leftLabel="Raison social" rightLabel={assure.raisonSocial} />
      )}
      <Line leftLabel="Mail" rightLabel={assure.email} />
      <Line leftLabel="Tel" rightLabel={assure.phone} />
      <Line
        leftLabel="Adresse postale"
        rightLabel={`${assure.address}, ${assure.zip}, ${assure.city}`}
      />
      {apporteur &&
        <Line
          leftLabel="Apporteur"
          rightLabel={`${apporteur?.prenom} ${apporteur?.nom}`}
        />
      }
    </Box>
  );
};

const Assurance = ({ devis }) => {
  return (
    <Box gap="small" style={{ width: "100%" }}>
      <SubHeading label={`Informations de ${devis.residenceType}`} />
      <Line leftLabel="Type résidence" rightLabel={devis.residenceType} />
      <Line leftLabel="Type propriétaire" rightLabel={devis.clientType} />
      <Line leftLabel="Type du bien" rightLabel={devis.bienType} />
      <Line
        leftLabel="Adresse"
        rightLabel={`${devis.address}, ${devis.zip}, ${devis.city}`}
      />
      <Line leftLabel="Surface" rightLabel={devis.surface} />
      <Line leftLabel="Nombre de pièces" rightLabel={devis.totalRooms} />
      <Line
        leftLabel="Nombre de sinistre"
        rightLabel={`${devis.sinistre || 0}`}
      />
      <Line
        leftLabel="Indice confiane"
        rightLabel={`${devis.productConfiance}`}
      />
      <Line
        leftLabel="Locale"
        rightLabel={`${devis.locale || 'fr'}`}
      />
    </Box>
  );
};
export default Recap;

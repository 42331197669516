import { Box, Tab, Tabs } from 'grommet';
import moment from 'moment';
import React from 'react';
import 'react-block-ui/style.css';
import BlockUi from 'react-block-ui';
import { getGestionCollabs, getIndicator } from '../../../services/dashboard';
import Indicator from '../../dashboard/indicator';
import { getEquipes } from '../../../services/admin_service';
import { hasClassiqueManagerRole, isCommercialClassique } from '../../../services/storage_service';
import IndicatorCumul from '../../dashboard/indicator_cumul';

const assuranceProducts = [
    'Emprunteur',
    'Plan épargne retraite',
    "Santé",
    "Prévoyance",
    "Autre"
]
class ObjectifCA extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indicator: undefined,
            commerciaux: [],
            loading: false,
            equipes: [],
            cumul: true,
            currentProducts: [...assuranceProducts, 'Crédit']
        }
    }

    componentDidMount() {
        getGestionCollabs()
            .then(res => {
                let commerciaux = res.data.filter(c => isCommercialClassique(c) || hasClassiqueManagerRole(c));
                this.setState({ commerciaux });
                let startDate = moment().startOf("month").toDate();
                let endDate = moment().endOf("month").toDate();
                this.getIndicator(startDate, endDate, commerciaux.map(c => c.id), this.state.currentProducts, true);
            });

        getEquipes()
            .then(res => this.setState({ equipes: res.data }))
            .catch(err => console.log(err));
    }

    getIndicator = (startDate, endDate, commercial_id, produits, cumul) => {
        produits = produits?.length ? produits : this.state.currentProducts;
        this.setState({ loading: true });
        if (!commercial_id || commercial_id.length === 0) {
            commercial_id = this.state.commerciaux.map(c => c.id);
        }
        getIndicator(startDate, endDate, commercial_id, produits)
            .then(res => {
                if (cumul) {
                    // check all objectif
                    let _objectif = res.data?.objectif_chiffre;
                    // chart_data
                    let _assuranceObj = 0;
                    let _assuranceSigned = 0;
                    let _creditObj = 0;
                    let _creditSigned = 0;
                    let _totalObj = 0;
                    let _totalSigned = 0;
                    for (let i = 0; i < _objectif.chart_data.keys.length; i++) {
                        const _key = _objectif.chart_data.keys[i];
                        if (_key === 'total') {
                            _totalObj += (_objectif.chart_data.data[0][i] || 0);
                            _totalSigned += (_objectif.chart_data.data[1][i] || 0);
                        } else if (_key === 'credit') {
                            _creditObj += (_objectif.chart_data.data[0][i] || 0);
                            _creditSigned += (_objectif.chart_data.data[1][i] || 0);
                        } else {
                            _assuranceObj += (_objectif.chart_data.data[0][i] || 0);
                            _assuranceSigned += (_objectif.chart_data.data[1][i] || 0);
                        }

                    }
                    _objectif.chart_data.keyLabels = ["Assurance", "Crédit", "TOTAL"];
                    _objectif.chart_data.keys = ["assurance", "credit", "total"];
                    _objectif.chart_data.labels = [ "Contrat clôturé", "Objectif" ]
                    _objectif.objectif.assurance = _assuranceObj;
                    _objectif.realise.assurance = _assuranceSigned;
                    _objectif.chart_data.data = [ [_assuranceSigned, _creditSigned, _totalSigned], [_assuranceObj, _creditObj, _totalObj],];


                    this.setState({ indicator: { ...res.data, objectif_chiffre: _objectif }, loading: false });
                } else {
                    this.setState({ indicator: res.data, loading: false });
                }
            })
            .catch(err => this.setState({ loading: false }))
    }

    changeProduct = (products, cumul = false) => {
        this.setState({ currentProducts: products });
        let startDate = moment().startOf("month").toDate();
        let endDate = moment().endOf("month").toDate();
        this.getIndicator(startDate, endDate, this.state.commerciaux.map(c => c.id), products, cumul);
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box style={{ width: "100%", paddingTop: 20 }}
                    align="center" justify="center"
                    alignSelf="center"
                    alignContent="center" gap="medium">
                    {this.state.indicator &&
                        <Tabs >
                            <Tab title='Cumul' onClick={() => this.changeProduct([...assuranceProducts, 'Crédit'], true)} key={'cumul_tab'}>
                                <IndicatorCumul indicator={this.state.indicator.objectif_chiffre} updateIndicator={this.getIndicator}
                                    commerciaux={this.state.commerciaux}
                                    isCA={true}
                                    equipes={this.state.equipes}
                                    products={this.state.currentProducts}
                                    key={'Cumul'}
                                />
                            </Tab>
                            <Tab title='Assurance' onClick={() => this.changeProduct(assuranceProducts)}>
                                <Indicator indicator={this.state.indicator.objectif_chiffre} updateIndicator={this.getIndicator}
                                    commerciaux={this.state.commerciaux}
                                    isCA={true}
                                    equipes={this.state.equipes}
                                    products={this.state.currentProducts}
                                    key={1}
                                />
                            </Tab>
                            <Tab title='Crédit' onClick={() => this.changeProduct(['Crédit'])}>
                                <Indicator indicator={this.state.indicator.objectif_chiffre} updateIndicator={this.getIndicator}
                                    commerciaux={this.state.commerciaux}
                                    isCA={true}
                                    equipes={this.state.equipes}
                                    products={this.state.currentProducts}
                                    key={2}
                                />
                            </Tab>
                        </Tabs>
                    }

                </Box>
            </BlockUi>
        )
    }
}

export default ObjectifCA;
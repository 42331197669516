import React from 'react';
import { Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, Card } from 'grommet';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import CustomSelect from '../elements/select_fill';
import { getMonthOptions, toCurrency } from '../../services/utils';
import { hasAdministrativeRole, hasClassiqueManagerRole, isCommercialClassique } from '../../services/storage_service';
import { ClosedStatusReport } from './reports_closed';
import DateInput from '../elements/date_input';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'left',
        },
        datalabels: {
            color: '#fff',
            anchor: 'end',
            align: 'start',
            formatter: value => value ? !isNaN(parseFloat(value)) ? parseFloat(value).toFixed(0) : value : '',
            font: {
                weight: 'bold',
            }
        }
    },
    interaction: {
        intersect: false,
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: false,
            ticks: {
                beginAtZero: true
            }

        },
    }
};

const backgroundColors = [
    "#33BC00", "#E52764", "#FC0000", "#73AAAA", "#73A6EC"
]

const IndicatorCumul = ({
    indicator,
    updateIndicator,
    commerciaux,
    hideFilter = false,
    isCA = false,
    isDaily = false,
    equipes
}) => {

    const [startDate, setStartDate] = React.useState(isDaily ? moment().startOf('day').toDate() : moment().startOf('month').toDate());
    const [endDate, setEndDate] = React.useState(moment().endOf('day').toDate());
    const [selectedPeriod, setSelectedPeriod] = React.useState('Mois en cours');

    const [commercial_id, setCommercialId] = React.useState('');
    const [produits, setProduits] = React.useState([]);
    const [equipe, setEquipe] = React.useState('');

    const allCommerciaux = [
        { name: "Commerciaux Classiques", id: commerciaux.filter(isCommercialClassique).map(c => c.id) },
        { name: "Managers Classiques", id: commerciaux.filter(c => c.role?.name === "Manager Classique").map(c => c.id) },
        ...commerciaux,
    ]

    const dealFilters = d => {
        let produitFilter = true;
        if (produits.length) {
            let per = false, emprunteur = false, other = false, sante = false;
            if (produits.includes('Emprunteur')) {
                emprunteur = d.product === "Trusti Emprunteur"
            }
            if (produits.includes("Plan épargne retraite")) {
                per = d.product === "Trusti PER";
            }
            if (produits.includes("Santé")) {
                sante = d.product === 'Trusti Santé';
            }
            if (produits.includes("Autre")) {
                other = d.product !== "Trusti PER" &&
                    d.product !== "Trusti Emprunteur" &&
                    d.product !== "Trusti Santé";
            }
            return per || emprunteur || sante || other;
        }
        let commercialFilter = true;
        if (commercial_id) {
            if (typeof commercial_id.push === 'function') {
                commercialFilter = commercial_id.includes(d.commercial_id);
            } else {
                commercialFilter = commercial_id === d.commercial_id;
            }
        }
        let equipeFilter = true;
        if (equipe) {
            let ids = [equipe.manager.id];
            equipe.membres.forEach(m => ids.push(m.id));
            equipeFilter = ids.includes(d.commercial_id);
        }
        return produitFilter && commercialFilter && equipeFilter;
    }

    console.log('indicator = ', indicator);
    return (
        <Box width='full' style={{ paddingBottom: 100, paddingTop: 30 }} gap='small' justify='center' align='center'>
            <Box width='xlarge' gap='small'>
                <Box width={'xlarge'} justify="center" align='center'>
                    {!hideFilter &&
                        <Card width='xlarge' justify='around' pad='small' direction='row'>
                            {!isDaily &&
                                <Box gap='xsmall'>
                                    <Text>Select d'un mois</Text>
                                    <CustomSelect
                                        options={getMonthOptions().filter(
                                            (s) => s.name !== "12 derniers mois"
                                        )}
                                        labelKey={"name"}
                                        valueKey={{ key: "name", reduce: true }}
                                        placeholder='mois en cours'
                                        defaultValue={getMonthOptions()[0]}
                                        onChange={({ option }) => {
                                            setStartDate(option.startDate);
                                            setEndDate(option.endDate);
                                            setSelectedPeriod(option.name);
                                            updateIndicator(option.startDate, option.endDate, commercial_id, produits, true);
                                        }}
                                    />
                                </Box>
                            }
                            {isDaily && <Box gap='xsmall'>
                                <Text>Select d'un jour</Text>
                                <DateInput
                                    size={'small'}
                                    placeholder="Aujourd'hui"
                                    value={startDate}
                                    onChange={(date) => {
                                        const _start = moment(date).startOf('day').toDate();
                                        const _end = moment(date).endOf('day').toDate();
                                        setStartDate(_start);
                                        setEndDate(_end);
                                        updateIndicator(_start, _end, commercial_id, produits, true);
                                    }}
                                />
                            </Box>}
                            {(hasAdministrativeRole() || hasClassiqueManagerRole()) &&
                                <Box gap='xsmall'>
                                    <Text>Equipe</Text>
                                    <CustomSelect
                                        options={equipes}
                                        onChange={({ option }) => {
                                            setEquipe(option);
                                            if (!option) {
                                                updateIndicator(startDate, endDate, undefined, produits, true);
                                                return;
                                            }
                                            let ids = [];
                                            option.membres.forEach(m => ids.push(m.id));
                                            updateIndicator(startDate, endDate, ids, produits, true);
                                        }}
                                        labelKey={'name'}
                                        valueKey={{ key: 'name', reduce: true }}
                                        clear
                                    />
                                </Box>
                            }
                            {(hasAdministrativeRole() || hasClassiqueManagerRole()) && !equipe?.id &&
                                <Box gap='xsmall'>
                                    <Text>Commercial</Text>
                                    <CustomSelect
                                        options={allCommerciaux}
                                        onChange={({ option }) => {
                                            option = option || {};
                                            setCommercialId(option.id)
                                            if (typeof option.id?.push === 'function') {
                                                updateIndicator(startDate, endDate, option.id, produits, true);
                                            } else {
                                                updateIndicator(startDate, endDate, [option.id], produits, true);
                                            }
                                        }}
                                        labelKey={'name'}
                                        valueKey={{ key: 'name', reduce: true }}
                                        clear
                                    />
                                </Box>
                            }
                        </Card>
                    }
                </Box>
                <Box direction='row' gap="small" justify="center" align='center'>
                    <Card style={{ width: 350, background: "#72ACFF" }} pad='small' align='center' alignSelf='center'>
                        <Text textAlign='center' size='xlarge'>⛱️</Text>
                        <Text textAlign='center' color={"white"}>Résultat assurance</Text>
                        {isDaily ?
                            <Text textAlign='center' color={"white"}>Jour {moment(startDate).format('DD/MM/YYYY')}</Text> :
                            <Text textAlign='center' color={"white"}>{selectedPeriod}</Text>}
                        <Text size='large' textAlign='center' color={"white"} style={{ fontWeight: 'bold' }}>{isCA ? toCurrency(indicator.realise.assurance) : indicator.realise.assurance} / {isCA ? toCurrency(indicator.objectif.assurance) : indicator.objectif.assurance} ({((indicator.realise.assurance / indicator.objectif.assurance) * 100).toFixed(2)} % )</Text>
                    </Card>

                    <Card style={{ width: 350, background: "#72ACFF" }} pad='small' align='center' alignSelf='center'>
                        <Text textAlign='center' size='xlarge'>💰</Text>
                        <Text textAlign='center' color={"white"}>Résultat crédit</Text>
                        {isDaily ?
                            <Text textAlign='center'>Jour {moment(startDate).format('DD/MM/YYYY')}</Text> :
                            <Text textAlign='center' color={"white"}>{selectedPeriod}</Text>}
                        <Text size='large' textAlign='center' color={"white"} style={{ fontWeight: 'bold' }}>{isCA ? toCurrency(indicator.realise.credit) : indicator.realise.credit} / {isCA ? toCurrency(indicator.objectif.credit) : indicator.objectif.credit} ({((indicator.realise.credit / indicator.objectif.credit) * 100).toFixed(2)} % )</Text>
                    </Card>

                    <Card style={{ width: 350, background: "#72ACFF" }} pad='small' align='center' alignSelf='center'>
                        <Text textAlign='center' color={"white"} size='xlarge'>⛱️ + 💰</Text>
                        <Text textAlign='center' color={"white"}>Cumul du</Text>
                        {isDaily ?
                            <Text textAlign='center'>Jour {moment(startDate).format('DD/MM/YYYY')}</Text> :
                            <Text textAlign='center' color={"white"}>{selectedPeriod}</Text>}
                        <Text size='large' textAlign='center' color={"white"} style={{ fontWeight: 'bold' }}>{isCA ? toCurrency(indicator.realise.total) : indicator.realise.total} / {isCA ? toCurrency(indicator.objectif.total) : indicator.objectif.total} ({indicator.chart_data.percentage} % )</Text>

                    </Card>
                </Box>
                <Box width={'xlarge'} justify="center" align='center'>
                    <Bar
                        height={600}
                        width={1134}
                        options={options}
                        data={{
                            labels: indicator.chart_data.keyLabels,
                            datasets: indicator.chart_data.data.map((d, index) => (
                                {
                                    label: indicator.chart_data.labels[index],
                                    backgroundColor: backgroundColors[index],
                                    data: d,
                                    categoryPercentage: index === 0 ? 0.75 : 1,
                                }
                            ))
                        }}
                        plugins={[ChartDataLabels]}
                    />
                </Box>
            </Box>
            <Box style={{ width: "100%", marginTop: 100 }}
                align="center" justify="center"
                alignSelf="center"
                overflow={{ horizontal: "scroll" }}
                alignContent="center" gap="medium">
                <Box width="full">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableCell justify="center" alignSelf="center" align="center"><Text></Text></TableCell>
                                {
                                    indicator.chart_data?.keyLabels.map((k, i) => (

                                        <TableCell key={i} justify="center" alignSelf="center" align="center"><Text >{k}</Text></TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow style={{ marginTop: 15 }} >
                                <TableCell justify="end" alignSelf="end" align="end">
                                    <CustomText label={"Objectif"} />
                                </TableCell>
                                {
                                    indicator.chart_data.keys.map((key, i) => (
                                        <TableCell key={key} justify="center" alignSelf="center" align="center" style={{ fontWeight: 'bold' }} >
                                            {isCA ? toCurrency(indicator.objectif[key], true) : indicator.objectif[key]}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan="100%"><hr /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell justify="end" alignSelf="end" align="end">
                                    <CustomText label={""} />
                                </TableCell>
                                {
                                    indicator.chart_data.keys.map((key, i) => (
                                        <TableCell key={key} justify="center" alignSelf="center" align="center" style={{ fontWeight: 'bold' }} >
                                            {isCA ? toCurrency(indicator.realise[key], true) : indicator.realise[key]}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>

                <Box width={'full'} pad='medium'>
                    <ClosedStatusReport
                        reports={indicator.all_devis.filter(dealFilters)}
                        commerciaux={commerciaux}
                        reloadAll={() => { }}
                        showMessage={() => { }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

const CustomText = ({ label }) => <Text textAlign="end">{label}</Text>

export default IndicatorCumul;